import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/knowledge-base/calculators",
    name: "kb.calculators",
    component: route("knowledgeBase/calculators/index.vue"),
    meta: {
      title: "Calculators",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/knowledge-base/videos",
    name: "kb.videos",
    component: route("knowledgeBase/videos/index.vue"),
    meta: {
      title: "Videos",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/knowledge-base/device-ifus",
    name: "kb.device-ifus",
    component: route("knowledgeBase/deviceIfus/index.vue"),
    meta: {
      title: "Device IFUs",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/knowledge-base/downloads",
    name: "kb.downloads",
    component: route("knowledgeBase/downloads/index.vue"),
    meta: {
      title: "Downloads",
      layout: "app",
      middleware: [auth, profile],
    },
  }
]