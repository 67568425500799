<template>
  <a-layout-content>
    <page-header>
      <template #title> Product Report </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card size="small" hoverable :loading="cardLoading">
      <a-space direction="vertical" style="width: 100%" size="large">
        <a-select
          v-model:value="product_ids"
          placeholder="Select product"
          optionFilterProp="label"
          mode="multiple"
          allowClear
          :showSearch="true"
          width="100%"
        >
          <a-select-option
            v-for="option in products"
            :key="option.id"
            :label="`${option.manufacturer.name} | ${option.name} | ${option.sku}`"
            :value="option.id"
          >
            {{ option.manufacturer.name }} | {{ option.name }} |
            {{ option.sku }}
          </a-select-option>
        </a-select>

        <a-button
          type="primary"
          :loading="downloading"
          @click="handleExcelExport"
        >
          <a-space>
            <i class="far fa-file-excel"></i>
            Download
          </a-space>
        </a-button>
      </a-space>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      downloading: false,
      products: [],
      product_ids: [],
    };
  },

  computed: {
    //
  },

  async mounted() {
    await this.getProducts();
    this.cardLoading = false;
  },

  methods: {
    getProducts() {
      return commonService.get(this.$constants.getProductsUrl).then((res) => {
        this.products = res.data.filter((item) => item.status == 1);
      });
    },

    handleExcelExport() {
      let url = this.$constants.hospitalWiseProductInventory;
      let fileName = "hospital-wise-product-inventory.xlsx";

      var params =
        this.product_ids.length > 0
          ? {
              product_ids: this.product_ids.join(","),
            }
          : {};

      this.downloading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>
