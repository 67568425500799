<template>
  <a-layout-content>
    <page-header>
      <template #title> Employee Time Tracking </template>

      <template #buttons>
        <router-link :to="{ name: 'create-employee-time-track' }">
          <a-button type="primary">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add New </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #was_on_call="{ text }">
          <a-tag v-if="text == 0" color="red">No</a-tag>
          <a-tag v-else color="green">Yes</a-tag>
        </template>

        <template #action="{ record }">
          <a-tooltip title="View">
          <a-button
            type="link"
            size="small"
            class="pl-sm-0 primary-color"
            @click="handleShowTimeTrackingEvent(record)"
          >
            <i class="ti ti-eye ti-lg"></i>
          </a-button>
        </a-tooltip>

          <template v-if="record.status == 1">
            <router-link
              :to="{
                name: 'edit-employee-time-track',
                params: { id: record.id },
              }"
            >
            <a-tooltip title="Edit">
              <a-button type="link" size="small">
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>
            </router-link>

            <a-popconfirm
              title="Are you sure you want to delete this record?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteTimeTrackingEvent(record)"
            >
            <a-tooltip title="Delete">
              <a-button size="small" type="link" danger>
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.employeeTimeTrackDatatableUrl,
      deleteRecordUrl: this.$constants.employeeTimeTrackDeleteUrl,
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          customRender: ({ record }) => record.shift_type?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
        },
        {
          title: "Total Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        {
          title: " On-call?",
          dataIndex: "was_on_call",
          key: "was_on_call",
          slots: { customRender: "was_on_call" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          // fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.timeTrackingStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
        sortField: "start_date",
        sortOrder: "descend",
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowTimeTrackingEvent(record) {
      this.open({
        title: "Show Time Tracking Details",
        path: "employeeTimeTrack.show",
        callback: this.refresh,
        record: record,
      });
    },

    handleDeleteTimeTrackingEvent(record) {
      commonService
        .store(this.deleteRecordUrl, { id: record.id })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },
  },
};
</script>
