<template>
  <div>
    <a-spin tip="Loading..." :spinning="loading">
      <a-card title="Address Information">
        <a-form
          ref="aiRef"
          :layout="formLayout"
          :model="model"
          :rules="rules"
          @finish="handleFinish"
        >
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="workOption"
                label="Work Options"
                name="workOption"
              >
                <a-select
                  v-model:value="model.workOption"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="location in locations"
                    :key="location.id"
                    :label="location.description"
                    :value="location.id"
                  >
                    {{ location.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="18"></a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="workAddress"
                label="Work Address"
                name="workAddress"
              >
                <a-input
                  v-model:value="model.workAddress"
                  placeholder="Enter work address here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item ref="workCity" label="Work City" name="workCity">
                <a-input
                  v-model:value="model.workCity"
                  placeholder="Enter work city here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item ref="workState" label="Work State" name="workState">
                <a-select
                  v-model:value="model.workState"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="state in $constants.states"
                    :key="state.name"
                    :label="state.name"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item ref="workZip" label="Work Zip" name="workZip">
                <a-input
                  v-model:value="model.workZip"
                  placeholder="Enter work zip here..."
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider type="horizontal" />
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="reportingOption"
                label="Reporting Options"
                name="reportingOption"
              >
                <a-select
                  v-model:value="model.reportingOption"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="deliveryLocations in deliveryLocations"
                    :key="deliveryLocations.id"
                    :label="deliveryLocations.description"
                    :value="deliveryLocations.id"
                  >
                    {{ deliveryLocations.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="18"></a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="reportingAddress"
                label="Reporting Address"
                name="reportingAddress"
              >
                <a-input
                  v-model:value="model.reportingAddress"
                  placeholder="Enter reporting address here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="reportingCity"
                label="Reporting City"
                name="reportingCity"
              >
                <a-input
                  v-model:value="model.reportingCity"
                  placeholder="Enter reporting city here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="reportingState"
                label="Reporting State"
                name="reportingState"
              >
                <a-select
                  v-model:value="model.reportingState"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="state in $constants.workState"
                    :key="state.value"
                    :label="state.text"
                    :value="state.value"
                  >
                    {{ state.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="reportingZip"
                label="Reporting Zip"
                name="reportingZip"
              >
                <a-input
                  v-model:value="model.reportingZip"
                  placeholder="Enter reporting zip here..."
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider type="horizontal" />
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="deliveryOption"
                label="Delivery Options"
                name="deliveryOption"
              >
                <a-select
                  v-model:value="model.deliveryOption"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="deliveryLocation in deliveryLocations"
                    :key="deliveryLocation.id"
                    :label="deliveryLocation.description"
                    :value="deliveryLocation.id"
                  >
                    {{ deliveryLocation.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="18"></a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="deliveryAddress"
                label="Delivery Address"
                name="deliveryAddress"
              >
                <a-input
                  v-model:value="model.deliveryAddress"
                  placeholder="Enter delivery address here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="deliveryCity"
                label="Delivery City"
                name="deliveryCity"
              >
                <a-input
                  v-model:value="model.deliveryCity"
                  placeholder="Enter delivery city here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="deliveryState"
                label="Delivery State"
                name="deliveryState"
              >
                <a-select
                  v-model:value="model.deliveryState"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="state in $constants.states"
                    :key="state.name"
                    :label="state.name"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="deliveryZip"
                label="Delivery Zip"
                name="deliveryZip"
              >
                <a-input
                  v-model:value="model.deliveryZip"
                  placeholder="Enter delivery zip here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item ref="overrideAddressValidation" name="override">
                <a-checkbox> Override Address Validation </a-checkbox>
              </a-form-item>
            </a-col>
          </a-row>
          <a-button type="danger" class="mr-xs-1 width-125" html-type="submit">
            SAVE
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="resetAiForm">
            CANCEL
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="back"> BACK </a-button>
          <a-button type="primary" class="width-125" @click="next">
            SAVE & NEXT
          </a-button>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { WORK_LOCATION } from "../../helper/insperityConst";
import { commonService, insperityService } from "../../services";

export default {
  props: ["rules", "model", "personId"],
  data() {
    return {
      formLayout: "vertical",
      loading: false,
      locations: [],
      reportingLocations: [],
      deliveryLocations: [],
    };
  },
  mounted() {
    this.loadData();
    // this.getWorkLocationFromIns();
    this.$refs.workOption.$el
      .getElementsByClassName("ant-select-selection-search-input")[0]
      .focus();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getWorkLocation(),
        this.getDeliveryLocation(),
        this.getReportingLocation(),
        false,
      ])
        .then((values) => {
          [this.locations, this.deliveryLocations, this.reportingLocations] =
            values;
        })
        .catch(() => this.$message.error("Somthing went wrong!"));
    },
    getWorkLocationFromIns() {
      insperityService.get(WORK_LOCATION()).then((res) => console.log(res));
    },
    getWorkLocation() {
      return commonService
        .get(this.$constants.insLocationsUrl)
        .then((res) => {
          return res;
        })
        .catch((err) => err);
    },
    getDeliveryLocation() {
      return commonService
        .get(this.$constants.insDeliveryLocationsUrl)
        .then((res) => res)
        .catch((err) => err);
    },
    getReportingLocation() {
      return commonService
        .get(this.$constants.insReportingLocationsUrl)
        .then((res) => res)
        .catch((err) => err);
    },
    handleFinish(value) {
      this.handleSubmit(value);
    },
    resetAiForm() {
      Object.keys(this.model).forEach((el) => {
        if (
          el == "workOption" ||
          el == "reportingOption" ||
          el == "deliveryOption"
        ) {
          this.model[el] = null;
        } else {
          this.model[el] = "";
        }
      });
    },
    next() {
      this.$refs.aiRef.validate().then((val) => {
        this.handleSubmit(val, "pushToNextStep");
      });
    },
    back() {
      this.$emit("callback", false, -1, this.personId);
    },
    handleSubmit(value, whereToGo = "") {
      this.loading = true;
      value.onboarding_person_id = this.personId;
      commonService
        .store(this.$constants.onboardingAddressUrl, value)
        .then((res) => {
          this.loading = false;
          if (whereToGo == "pushToNextStep") {
            this.$message.success(res.message);
            this.$emit(
              "callback",
              false,
              +1,
              res.onboarding_person_id,
              res.data
            );
          } else {
            this.$emit("callback", true);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped>
.width-125 {
  width: 125px;
}
@media (max-width: 767px) {
  .width-125 {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
