import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/performance-evaluations",
    name: "performance-evaluation",
    component: route("performanceAppraisals/index"),
    meta: {
      title: "Performance Evaluations",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/performance-evaluation/show/:id",
    name: "show-performance-evaluation",
    component: route("performanceAppraisals/show"),
    meta: {
      title: "Show Performance Evaluation",
      layout: "app",
      middleware: [auth, profile],
    },
  },

];