import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";

export default [
  {
    path: "/asset/manufacturers",
    name: "asset.manufacturer",
    component: route("asset/manufacturer/index"),
    meta: {
      title: "Asset Manufacturers",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/asset/categories",
    name: "asset.category",
    component: route("asset/category/index"),
    meta: {
      title: "Asset Categories",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/asset/products",
    name: "asset.product",
    component: route("asset/product/index"),
    meta: {
      title: "Asset Products",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/asset/inventory",
    name: "asset.inventory",
    component: route("asset/inventory/index"),
    meta: {
      title: "Asset Inventory",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/asset/inventory/bulk-create",
    name: "asset.bulk-create-inventory",
    component: route("asset/inventory/bulkStore"),
    meta: {
      title: "Bulk Create Asset Inventory",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
];
