export const api = {
    //'host': 'http://localhost:8080/healthcare_backend/public/',
    'host': 'https://app.keystoneperfusion.com/',
    //'host': 'https://app1.keystoneperfusion.com/',
}

export const timezone = "America/New_York";
export const PUSHER_API_KEY = "c88827a7a566f6719db7";
export const PUSHER_APP_CLUSTER = "mt1";

// export const PUSHER = {
//     ID: 649111,
//     KEY: "0defdc2f276f5d6b6824",
//     SECRET: "58fbdb14ce5b1eec95cb",
//     CLUSTER: 'ap2',
//     HOST: 'app1.keystoneperfusion.com',
//     PORT: 6001
// }