<template>
  <a-layout-content>
    <page-header>
      <template #title> Cell Saver QI Report</template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-form
      ref="caseReportForm"
      :model="model"
      layout="vertical"
      :rules="rules"
    >
      <a-row :gutter="[10, 10]">
        <a-col :span="12">
          <a-card size="small" hoverable :loading="cardLoading">
            <a-row :gutter="[16, 0]">
              <a-col :span="12">
                <a-form-item
                  ref="date_range"
                  label="Date Range"
                  name="date_range"
                >
                  <a-range-picker
                    :allowClear="false"
                    v-model:value="model.date_range"
                    :format="$constants.datepickerFormat"
                    inputReadOnly
                    :ranges="ranges"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item
                  ref="hospital_id"
                  label="Hospital"
                  name="hospital_id"
                >
                  <a-select
                    v-model:value="model.hospital_id"
                    show-search
                    optionFilterProp="label"
                    allowClear
                    placeholder="Select Hospital"
                  >
                    <a-select-option
                      v-for="option in hospitals"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item ref="comments" name="comments">
                  <template #label>
                    Comment
                    <a-popover overlayClassName="overlay-popover-class">
                      <template #content>
                        Please write in any comment that <br />
                        you would like to display <br />
                        on the report face sheet.
                      </template>
                      <a-button type="link" size="small">
                        <i class="fa fa-info-circle"></i>
                      </a-button>
                    </a-popover>
                  </template>
                  <a-textarea
                    v-model:value="model.comments"
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-col :span="24">
              <a-space
                align="center"
                direction="vertical"
                style="width: 100%; margin-bottom: 16px"
              >
                <a-space direction="vertical">
                  <a-typography-title :level="5">
                    <span class="danger-color"> * </span>
                    Signature
                  </a-typography-title>
                  <vue-signature-pad
                    ref="signaturePad"
                    :options="{
                      onEnd: save,
                    }"
                    :customStyle="$constants.signPadCustomCss"
                  />
                </a-space>
              </a-space>
            </a-col>

            <a-space>
              <a-button
                v-if="model.signature"
                htmlType="button"
                type="primary"
                danger
                ghost
                @click="clearSignature"
              >
                Clear Signature
              </a-button>
              <a-button
                html-type="button"
                :loading="previewBtnLoader"
                @click="previewPDF"
              >
                Preview PDF
              </a-button>
              <a-button
                type="primary"
                html-type="button"
                :loading="submitBtnLoader"
                @click="exportExcel"
              >
                Export PDF
              </a-button>
            </a-space>
          </a-card>
        </a-col>

        <a-col :span="12">
          <a-card v-if="previewBtnLoader" :loading="true" />

          <template v-else>
            <a-card v-if="!pdfPreviewURL">
              <a-empty>
                <template #description>
                  <span> Preview will be shown here </span>
                </template>
              </a-empty>
            </a-card>

            <iframe
              v-else
              :src="pdfPreviewURL + '#toolbar=0&navpanes=0&scrollbar=0'"
              type="application/pdf"
              width="100%"
              height="500"
              @click.right.stop.prevent
            />
          </template>
        </a-col>
      </a-row>
    </a-form>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      previewBtnLoader: false,
      pdfPreviewURL: null,
      model: {
        date_range: [null, null],
        hospital_id: undefined,
        comments: null,
        signature: null,
        signatured_at: null,
      },
      ranges: {
        ...this.getLastFourQuarters(),
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "array",
            len: 2,
            fields: {
              0: {
                type: "date",
                required: true,
                message: "This field is required!",
              },
              1: { type: "date", required: true, message: "" },
            },
          },
        ],
        hospital_id: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "number",
          },
        ],
        comments: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
          },
        ],
      },
      hospitals: [],
    };
  },

  computed: {
    //
  },

  async mounted() {
    await this.getHospitals();
    this.cardLoading = false;
  },

  methods: {
    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => this.$message.error(error));
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.signature = data;
      this.model.signatured_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      return Promise.resolve();
    },

    formData() {
      const start_date = this.$customDate.ymd(this.model.date_range[0]);
      const end_date = this.$customDate.ymd(this.model.date_range[1]);
      return {
        start_date,
        end_date,
        comments: this.model.comments,
        signature: this.model.signature,
        signatured_at: this.model.signatured_at,
        hospital_id: this.model.hospital_id,
      };
    },

    dateString() {
      return (
        this.model.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.model.date_range[1].clone().format("MMDDYY")
      );
    },

    getLastFourQuarters() {
      const quarters = {};
      let currentDate = moment().subtract(3, "quarter");

      for (let i = 0; i < 4; i++) {
        const start = currentDate.clone().startOf("quarter");
        const end = currentDate.clone().endOf("quarter");
        const quarterName = `Q${currentDate.quarter()} ${currentDate.year()}`;
        quarters[quarterName] = [start, end];
        currentDate = currentDate.add(1, "quarter");
      }

      return quarters;
    },

    clearSignature() {
      this.model.signature = null;
      this.model.signatured_at = null;
      this.$refs.signaturePad.clearSignature();
    },

    exportExcel() {
      this.$refs.caseReportForm.validate().then(() => {
        if (this.model.signature == null) {
          this.$message.error("Signature is required!");
          return;
        }
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.cellSaverQualityReport,
            this.formData(),
            "cell-saver-report-" + this.dateString() + ".pdf"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    previewPDF() {
      this.$refs.caseReportForm.validate().then(() => {
        if (this.model.signature == null) {
          this.$message.error("Signature is required!");
          return;
        }
        this.previewBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.cellSaverQualityReport,
            this.formData(),
            "cell-saver-report-" + this.dateString() + ".pdf",
            true
          )
          .then((res) => {
            this.pdfPreviewURL = window.URL.createObjectURL(
              new Blob([res], {
                type: "application/pdf",
              })
            );
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.previewBtnLoader = false;
          });
      });
    },
  },
};
</script>
