<template>
  <a-card
    :loading="loading"
    title="Products"
    size="small"
    class="product-prices"
  >
    <a-table
      size="small"
      :columns="columns"
      :data-source="hospitalProductPrices"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChangeEvent"
      :scroll="{ x: true, y: 0 }"
    >
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>

      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #qbo_mapping="{ record }">
        <i
          v-if="record?.product?.quickbooks_item_id"
          class="ti ti-check ti-lg"
          style="color: green"
        />
        <i v-else class="ti ti-x ti-lg" style="color: red" />
      </template>

      <template #is_discontinued="{ record }">
        <a-tag v-if="record.product.is_discontinued == 1" color="success">
          Yes
        </a-tag>
        <a-tag v-else color="error">No</a-tag>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import { commonService } from "../../../../services";
import { SearchOutlined } from "@ant-design/icons-vue";

export default {
  name: "hospital-product-pricing",

  props: {
    hospital: {
      required: true,
    },
  },

  components: { SearchOutlined },

  data() {
    return {
      loading: true,
      excelExportLoading: false,
      excelImportLoading: false,
      products: [],
      hospitalProductPrices: [],
      inlineRecord: null,
      columns: [
        {
          title: "Product Name",
          dataIndex: "product_name",
          customRender: ({ record }) => record?.product?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.product.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "QBO Mapping",
          dataIndex: "qbo_mapping",
          slots: {
            customRender: "qbo_mapping",
          },
        },
        {
          title: "Stock Qty/Box",
          dataIndex: "stock_qty",
          customRender: ({ record }) => {
            let noOfItems = record.product?.uom?.no_of_items ?? 1;
            noOfItems = noOfItems == 0 ? 1 : noOfItems;
            let box = Math.ceil(record.stock_qty / noOfItems);
            return `${record.stock_qty}/${box}`;
          },
        },
        {
          title: "UOM",
          dataIndex: "uom",
          customRender: ({ record }) => record.product?.uom?.name ?? "N/A",
        },
        {
          title: "Min. Par Level",
          dataIndex: "minimum_par_level_qty",
        },
        {
          title: "Replenish Qty (As Per UOM)",
          dataIndex: "replenish_uom",
        },
        {
          title: "Is Discontinued?",
          dataIndex: "is_discontinued",
          key: "is_discontinued",
          slots: { customRender: "is_discontinued" },
        },
      ],
      pagination: {
        current: 1,
        size: "normal",
        pageSize: 0,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },
    };
  },

  async mounted() {
    await this.getProducts();
    await this.getHospitalProductPrices();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getHospitalProductPrices() {
      this.loading = true;
      return commonService
        .get(this.$constants.hospitalProductPricesUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.hospitalProductPrices = res.data;
          this.loading = false;
        });
    },

    getProducts() {
      return commonService.get(this.$constants.getProductsUrl).then((res) => {
        this.products = res.data;
      });
    },

    addNewCaseService() {
      this.open({
        title: "Add new hospital product price",
        path: "hospital.drawerForm.storeProduct",
        callback: this.getHospitalProductPrices,
        record: { hospital_id: this.hospital.id },
        extra: { products: this.remainingProducts() },
      });
    },

    editCaseService(record) {
      this.open({
        title: "Edit hospital product price",
        path: "hospital.drawerForm.storeProduct",
        callback: this.getHospitalProductPrices,
        record,
        extra: {
          products: this.remainingProducts(record.product_id),
        },
      });
    },

    deleteHospitalProduct(id) {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalProductPriceDeleteUrl, { id })
        .then((res) => {
          this.$message.success(res.message);
          this.getHospitalProductPrices();
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },

    remainingProducts(selectedId = null) {
      let hospitalProductIds = this.hospitalProductPrices
        .map((item) => item.product_id)
        .filter((item) => item != selectedId);

      return this.products.filter(
        (item) => !hospitalProductIds.includes(item.id)
      );
    },

    handleHospitalProductExcelExportEvent() {
      this.excelExportLoading = true;
      commonService
        .downloadFile(
          this.$constants.hospitalProductExcelExportUrl,
          {
            hospital_id: this.hospital.id,
          },
          "Hospital products.xlsx"
        )
        .then((res) => {
          this.$message.success(res);
          this.excelExportLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.excelExportLoading = false;
        });
    },

    handleHospitalProductExcelImportEvent() {
      this.open({
        title: "Import Hospital Products",
        path: "hospital.drawerForm.importProductPrice",
        callback: this.getHospitalProductPrices,
        record: { hospital_id: this.hospital.id },
      });
    },

    handleBinToBinClickEvent() {
      this.open({
        title: "Bin to Bin Stock",
        path: "hospital.drawerForm.binToBinStore",
        callback: this.getHospitalProductPrices,
        width: "1000px",
        record: {
          hospital_id: this.hospital.id,
          hospital_products: this.hospitalProductPrices,
        },
      });
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
    },

    handleReset(clearFilters) {
      clearFilters();
    },

    handleTableChangeEvent(pagination) {
      this.pagination = pagination;
    },
  },
};
</script>

<style scoped>
.product-prices div.ant-typography,
.product-prices .ant-typography p {
  margin-bottom: 0;
}
</style>
