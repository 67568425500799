<template>
  <template v-if="loading">
    <a-card size="small" :loading="loading"> </a-card>
  </template>

  <a-table
    v-else
    :dataSource="documents"
    :columns="columns"
    :loading="loading"
    size="small"
    :rowKey="(record, index) => record.id"
    :pagination="false"
  >
    <template #file="{ record: attach }">
      <a :href="attach.full_file_path" target="_blank">
        {{ attach.file_name }}
      </a>
    </template>
  </a-table>
</template>

<script>
import { commonService } from "../../services";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: true,
      documents: [],
      columns: [
        {
          title: "Title",
          dataIndex: "title",
        },
        {
          title: "File",
          dataIndex: "file",
          key: "file",
          sorter: false,
          slots: {
            customRender: "file",
          },
        },
        {
          title: "Uploaded At",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
        },
      ],
    };
  },

  props: ["companies"],

  mounted() {
    this.fetchData();
  },

  methods: {
    ...mapActions("auth", ["login"]),
    fetchData() {
      this.loading = true;
      commonService
        .get(this.$constants.employeeDocuments)
        .then(res => {
          this.documents = res.data;
        })
        .catch(err => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
