<template>
  <div>
    <a-descriptions class="mb-sm-2" title="" bordered size="small" :column="1">
      <a-descriptions-item label="Product Name">
        {{ record.asset_product?.name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Mfg. Name">
        {{ record.asset_manufacturer?.name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Model No">
        {{ record.model_no ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Serial No">
        {{ record.serial_no ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Owned By">
        <a-tag
          :color="
            $comman.getValueFromObject(
              record,
              'owned_by',
              $constants.assetInventoryOwnedBy,
              'color'
            )
          "
        >
          {{
            $comman.getValueFromObject(
              record,
              "owned_by",
              $constants.assetInventoryOwnedBy
            )
          }}
        </a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="Owner">
        <template v-if="record.owned_by == 1">
          {{ record.company?.name ?? "N/A" }}
        </template>
        <template v-else>
          {{ record.hospital?.name ?? "N/A" }}
        </template>
      </a-descriptions-item>
    </a-descriptions>

    <a-form :model="formState" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="allocated_date"
            label="Allocated Date"
            name="allocated_date"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'date',
            }"
          >
            <a-date-picker
              :allowClear="false"
              v-model:value="formState.allocated_date"
              :placeholder="$constants.datePickerPlaceholder"
              :format="$constants.datepickerFormat"
              :disabledDate="$comman.disabledFutureDate"
            />
          </a-form-item>
        </a-col>

        <template v-if="record.owned_by == 1">
          <a-col :span="24">
            <a-form-item
              ref="hospital_id"
              label="Facility"
              name="hospital_id"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'integer',
              }"
            >
              <a-select
                v-model:value="formState.hospital_id"
                placeholder="Select One..."
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="hospital in hospitals"
                  :key="hospital.id"
                  :label="hospital.name"
                  :value="hospital.id"
                >
                  {{ hospital.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </template>
      </a-row>

      <side-drawer-buttons name="Create" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        allocated_date: null,
        hospital_id: undefined,
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    hospitals() {
      return this.extra?.hospitals ?? [];
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      let data = {
        hospital_id:
          this.record.owned_by == 1
            ? this.formState.hospital_id
            : this.record.hospital_id,
        allocated_date: this.$customDate.ymd(this.formState.allocated_date),
        items: [
          {
            asset_inventory_id: this.record.id,
          },
        ],
      };

      this.loadingStart();
      commonService
        .store(this.$constants.assetInventoryHospitalSingleUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
