<template>
  <a-row :gutter="[20, 0]">
    <template v-if="isCati">
      <a-col :span="24">
        <a-alert type="warning" class="mb-sm-1" show-icon>
          <template #message>
            Informational Notes
          </template>
          <template #description>
            If Carolina Autotransfusion owned disposable products were used,
            please use this section to charge for the products that were
            utilized.
            <br />
            If this section does not apply to this procedure, simply hit "next"
            below.
          </template>
        </a-alert>
      </a-col>

      <template
        v-for="(pcId, index) in catiProductCategories"
        :key="`cati_product_category_${index}`"
      >
        <a-col :span="24">
          <a-divider orientation="left">
            {{ productCategory(pcId) }} (CATI Owned Products Only)
          </a-divider>

          <a-row :gutter="[20, 0]">
            <a-col :span="10">
              <b>Item Name</b>
            </a-col>
            <a-col :span="10">
              <b>Quantity Used</b>
            </a-col>
            <a-col :span="4"></a-col>

            <template v-for="(v, i) in model.products[pcId]" :key="i">
              <a-col :span="10">
                <a-form-item :name="['products', pcId, i, 'product_id']">
                  <a-select
                    v-model:value="v.product_id"
                    placeholder="Select any one"
                    show-search
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="product in categoryWiseProducts(pcId)"
                      :key="product.id"
                      :label="product.name"
                      :value="product.id"
                    >
                      {{ product.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="10">
                <a-form-item
                  :name="['products', pcId, i, 'qty']"
                  :rules="[
                    {
                      validator: $validation.numeric,
                      message: 'must be in numbers!',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <a-input
                    v-model:value="v.qty"
                    placeholder="Enter used quantity here"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-button
                  v-if="model.products[pcId].length > 1"
                  type="link"
                  class="danger-color font-20"
                  @click="handleMinusEvent(pcId, i)"
                >
                  <MinusCircleOutlined />
                </a-button>

                <a-button
                  v-if="model.products[pcId].length == i + 1"
                  type="link"
                  class="font-20"
                  @click="handlePlusEvent(pcId)"
                >
                  <PlusCircleOutlined />
                </a-button>
              </a-col>
            </template>
          </a-row>
        </a-col>
      </template>
    </template>

    <template v-if="isKeystone">
      <a-col :span="24">
        <a-alert type="warning" class="mb-sm-1" show-icon>
          <template #message>
            Informational Notes
          </template>
          <template #description>
            If Keystone Perfusion owned disposable products were used, please
            use this section to charge for the products that were utilized.
            <br />
            If this section does not apply to this procedure, simply hit "next"
            below.
          </template>
        </a-alert>
      </a-col>

      <template
        v-for="(pcId, index) in keystoneProductCategories"
        :key="`keytsone_product_category_${index}`"
      >
        <a-col :span="24">
          <a-divider orientation="left">
            {{ productCategory(pcId) }}
          </a-divider>

          <a-row :gutter="[20, 0]">
            <a-col :span="10">
              <b>Item Name</b>
            </a-col>
            <a-col :span="10">
              <b>Quantity Used</b>
            </a-col>
            <a-col :span="4"></a-col>

            <template v-for="(v, i) in model.products[pcId]" :key="i">
              <a-col :span="10">
                <a-form-item :name="['products', pcId, i, 'product_id']">
                  <a-select
                    v-model:value="v.product_id"
                    placeholder="Select any one"
                    show-search
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="product in categoryWiseProducts(pcId)"
                      :key="product.id"
                      :label="product.name"
                      :value="product.id"
                    >
                      {{ product.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="10">
                <a-form-item
                  :name="['products', pcId, i, 'qty']"
                  :rules="[
                    {
                      validator: $validation.numeric,
                      message: 'must be in numbers!',
                      trigger: ['change', 'blur'],
                    },
                  ]"
                >
                  <a-input
                    v-model:value="v.qty"
                    placeholder="Enter used quantity here"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-button
                  v-if="model.products[pcId].length > 1"
                  type="link"
                  class="danger-color font-20"
                  @click="handleMinusEvent(pcId, i)"
                >
                  <MinusCircleOutlined />
                </a-button>

                <a-button
                  v-if="model.products[pcId].length == i + 1"
                  type="link"
                  class="font-20"
                  @click="handlePlusEvent(pcId)"
                >
                  <PlusCircleOutlined />
                </a-button>
              </a-col>
            </template>
          </a-row>
        </a-col>
      </template>

      <template v-if="hospitalIdIs(66)">
        <a-col :span="12">
          <a-form-item
            ref="nacl_bags"
            label="0.9 % NaCl Bags"
            name="nacl_bags"
            :validateStatus="validate('nacl_bags', 'status')"
            :help="validate('nacl_bags', 'msg')"
          >
            <a-input
              v-model:value="model.nacl_bags"
              placeholder="Enter qty here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="heparin_vials"
            label="Heparin Vials"
            name="heparin_vials"
            :validateStatus="validate('heparin_vials', 'status')"
            :help="validate('heparin_vials', 'msg')"
          >
            <a-input
              v-model:value="model.heparin_vials"
              placeholder="Enter qty here"
            />
          </a-form-item>
        </a-col>
      </template>
    </template>
  </a-row>
</template>

<script>
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    MinusCircleOutlined,
    PlusCircleOutlined,
  },

  props: {
    model: { require: true, type: Object },
    hospitals: { require: true, type: Array },
    productCategories: { require: true, type: Array },
    products: { require: true, type: Array },
    isCati: { require: true, type: Boolean },
    isKeystone: { require: true, type: Boolean },
    catiProductCategories: { require: true, type: Array },
    keystoneProductCategories: { require: true, type: Array },
  },

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },

    hospitalIdIs(params) {
      if (typeof params == "number") {
        params = [params];
      }

      return params.includes(this.model.hospital_id);
    },

    handleMinusEvent(key, i) {
      this.model.products[key].splice(i, 1);
    },

    handlePlusEvent(key) {
      this.model.products[key].push({
        product_id: null,
        qty: null,
      });
    },

    productCategory(id) {
      return this.productCategories.find((v) => v.id == id).name;
    },

    categoryWiseProducts(categoryId) {
      return this.products.filter((v) => v.product_category_id == categoryId);
    },
  },
};
</script>
