<template>
  <a-layout-content>
    <page-header>
      <template #title> Device IFUs </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col :span="12">
        <a-select
          v-model:value="searchAssetCategory"
          placeholder="Search by device category"
          show-search
          allowClear
          option-filter-prop="label"
          @change="fetchDocuments"
        >
          <a-select-option
            v-for="item in assetCategories"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>

      <a-col :span="12">
        <a-input-search
          v-model:value="searchName"
          placeholder="Search by device name"
          enter-button
          @search="fetchDocuments"
        />
      </a-col>

      <template v-if="loading">
        <a-col
          :md="12"
          :lg="8"
          :xxl="6"
          v-for="document in [1, 2, 3, 4, 5, 6]"
          :key="document"
        >
          <a-card size="small" loading hoverable />
        </a-col>
      </template>

      <template v-else>
        <template v-if="documents.length > 0">
          <a-col
            :md="12"
            :lg="8"
            :xxl="6"
            v-for="document in documents"
            :key="document.id"
          >
            <a-card size="small" hoverable @click="openPdfInNewTab(document)">
              <a-card-meta
                :description="document?.asset_category?.name ?? 'N/A'"
              >
                <template #title>
                  <span style="white-space: normal">
                    {{ document.name }}
                  </span>
                </template>
                <template #avatar>
                  <i class="ti ti-file-text ti-4x" />
                </template>
              </a-card-meta>
            </a-card>
          </a-col>
        </template>

        <template v-else>
          <a-col :span="24">
            <a-card style="text-align: center">
              <a-empty />
            </a-card>
          </a-col>
        </template>
      </template>
    </a-row>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";
import { FilePdfOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    FilePdfOutlined,
  },

  data() {
    return {
      loading: false,
      searchName: "",
      searchAssetCategory: undefined,
      documents: [],
      assetCategories: [],
    };
  },

  mounted() {
    this.getAssetCategories();
    this.fetchDocuments();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getAssetCategories() {
      commonService
        .get(this.$constants.getAssetCategoriesUrl)
        .then((res) => {
          if (res.success) {
            this.assetCategories = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    fetchDocuments() {
      this.loading = true;
      commonService
        .get(constants.kbDeviceIfusUrl, {
          name: this.searchName,
          asset_category_id: this.searchAssetCategory,
        })
        .then((res) => {
          this.documents = res.data;
        })
        .finally(() => (this.loading = false));
    },

    openPdfInNewTab(document) {
      window.open(document.device_info_pdf_file_path, "_blank");
    },
  },
};
</script>

<style>
.iframe_thumb {
  position: relative;
  overflow: hidden;
}
</style>
