<template>
  <a-layout-content>
    <page-header>
      <template #title> QuickBooks Settings </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <component :is="comp">
        <router-view />
      </component>
    </a-card>
  </a-layout-content>
</template>

<script>
import { route } from "../../../helper/route";
export default {
  data() {
    return {
      activeTabKey: "quickbooks",
      tabList: [
        {
          key: "quickbooks",
          tab: "Quickbooks",
        },
        {
          key: "payroll",
          tab: "Payroll",
        },
        {
          key: "adp",
          tab: "ADP",
        },
        {
          key: "thirdPartyLogin",
          tab: "3rd Party Logins",
        },
      ],
    };
  },

  computed: {
    comp() {
      return route("admin/setting/" + this.activeTabKey + ".vue");
    },
  },
};
</script>
