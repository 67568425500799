<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>Edit Calendar Event</template>

        <template #buttons>
          <back-button />
        </template>
      </page-header>

      <a-card :loading="cardLoading">
        <a-form
          ref="edit_calendar_event_form"
          layout="vertical"
          :model="model"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-row :gutter="[16, 0]">
            <a-col :span="24" :md="12">
              <a-form-item
                ref="hospital_id"
                label="Hospital"
                name="hospital_id"
              >
                <a-select
                  v-model:value="model.hospital_id"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  @change="fetchServices"
                  showSearch
                >
                  <a-select-option
                    v-for="hospital in hospitals"
                    :key="hospital.id"
                    :label="hospital.name"
                    :value="hospital.id"
                  >
                    {{ hospital.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item ref="title" label="Title" name="title">
                <a-input
                  v-model:value="model.title"
                  placeholder="Enter event title here..."
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="case_service_id"
                label="Select Service"
                name="case_service_id"
              >
                <a-select
                  v-model:value="model.case_service_id"
                  placeholder="Select service"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="caseService in caseServices"
                    :key="caseService.case_service.id"
                    :label="caseService.case_service.name"
                    :value="caseService.case_service.id"
                  >
                    {{ caseService.case_service.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-item ref="date" label="Date" name="date">
                <a-date-picker
                  v-model:value="model.date"
                  :allowClear="false"
                  :disabled-date="$comman.disabledPastDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="is_full_day_event"
                label="Is Full Day Event?"
                name="is_full_day_event"
              >
                <a-radio-group v-model:value="model.is_full_day_event">
                  <a-radio :value="1">Yes</a-radio>
                  <a-radio :value="0">No</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col v-if="model.is_full_day_event == 0" :span="12">
              <a-form-item
                ref="start_time"
                label="Start Time"
                name="start_time"
              >
                <a-time-picker
                  v-model:value="model.start_time"
                  use12Hours
                  :minuteStep="5"
                  valueFormat="HH:mm"
                  :defaultOpenValue="startOfDay"
                  format="hh:mm a"
                  inputReadOnly
                />
              </a-form-item>
            </a-col>

            <a-col v-if="model.is_full_day_event == 0" :span="12">
              <a-form-item ref="end_time" label="End Time" name="end_time">
                <a-time-picker
                  v-model:value="model.end_time"
                  use12Hours
                  :minuteStep="5"
                  valueFormat="HH:mm"
                  :defaultOpenValue="startOfDay"
                  format="hh:mm a"
                  inputReadOnly
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item ref="notes" label="Notes" name="notes">
                <template #extra>
                  <span class="danger-color">
                    Be sure to include patient last name or medical record
                    number. Please DO NOT include any other patient
                    information/identifier. Although our form is HIPAA
                    compliant, we do not require any other patient information.
                  </span>
                </template>

                <a-textarea
                  v-model:value="model.notes"
                  placeholder="Enter event notes here..."
                  :autoSize="{ minRows: 5 }"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-button
            type="primary"
            :loading="loading"
            html-type="submit"
            class="mr-sm-1"
          >
            Update
          </a-button>
        </a-form>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import moment from "moment-timezone";
import backButton from "../../components/backButton.vue";
import { comman } from "../../helper/comman";
import { appointmentEventRules } from "../../helper/formRules";
import router from "../../router";
import { commonService } from "../../services";
moment.tz.setDefault("UTC");

export default {
  components: { backButton },

  data() {
    return {
      loading: false,
      cardLoading: false,
      model: {
        id: this.$route.params.id,
        title: null,
        hospital_id: null,
        case_service_id: null,
        date: null,
        is_full_day_event: 1,
        start_time: null,
        end_time: null,
        notes: null,
      },
      rules: appointmentEventRules,
      startOfDay: moment().startOf("day"),
      caseServices: [],
      hospitals: [],
    };
  },

  mounted() {
    this.fetchAppointment();
    comman.getHospitals().then((data) => (this.hospitals = data));
  },

  methods: {
    fetchAppointment() {
      this.cardLoading = true;
      commonService
        .store(this.$constants.calendarEventViewUrl, {
          id: this.model.id,
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.status == 1) {
              this.model.title = res.data.title;
              this.model.hospital_id = res.data.hospital_id;
              this.model.case_service_id = res.data.case_service_id;
              this.model.date = moment(res.data.date);
              this.model.is_full_day_event = res.data.is_full_day_event;
              this.model.start_time = res.data.start_time;
              this.model.end_time = res.data.end_time;
              this.model.notes =
                res.data.notes == null
                  ? this.$constants.calendarEventNotesString
                  : res.data.notes;

              this.cardLoading = false;

              this.fetchServices();
              return;
            }
          }

          this.$message.error(err);
          this.$router.push({ name: "events" });
        })
        .catch((err) => {
          this.$message.error(err);
          this.$router.push({ name: "events" });
        });
    },

    fetchServices() {
      comman
        .getHospitalWiseCaseServices({
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.caseServices = res;
        });
    },

    handleSubmit() {
      this.loading = true;
      commonService
        .store(this.$constants.calendarEventUpdateUrl, this.model)
        .then((res) => {
          this.loading = false;
          this.$message.success(res.message);
          router.push({ name: "events" });
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
