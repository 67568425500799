<template>
  <div>
    <a-date-picker
      v-model:value="date"
      class="invoice-creation-datepicker"
      format="MMMM DD, YYYY"
      :disabled="loading"
      :loading="loading"
      :allowClear="false"
      @change="updateDate"
    />
  </div>
</template>

<script setup>
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import moment from "moment";
import { computed, ref } from "vue";

const props = defineProps({
  billing_date: String,
  record: Object,
});

const emit = defineEmits(["update:billing_date"]);

const date = computed({
  get: () => moment(props.billing_date, "YYYY-MM-DD"),
  set: (value) => {
    emit("update:billing_date", value.format("YYYY-MM-DD"));
  },
});

const loading = ref(false);

function updateDate() {
  loading.value = true;
  commonService
    .store(constants.monthlyBillingUpdate, {
      column: "billing_date",
      value: date.value.format("YYYY-MM-DD"),
      id: props.record.id,
    })
    .then((res) => {})
    .finally(() => {
      loading.value = false;
    });
}
</script>

<style lang="less">
.invoice-creation-datepicker {
  .ant-input {
    border: none !important;
  }
}
</style>
