<template>
  <a-alert
    description="The Protamine dose calculator uses a standard calculation of 80% of the total heparin dose. Please refer to your facility’s policy on the actual calculation. This calculator is to be used as a guide only."
  />

  <a-divider></a-divider>

  <a-form
    :model="model"
    @finish="onSubmit"
    @reset="onReset"
    :labelCol="{ span: 6 }"
    :wrapperCol="{ span: 16 }"
  >
    <a-form-item
      label="Heparin Dose"
      name="heparine_dose"
      :rules="{
        required: true,
        message: 'This field is required',
        type: 'number',
      }"
    >
      <a-input-number
        v-model:value="model.heparine_dose"
        placeholder="heparine dose..."
        :min="0"
      />
    </a-form-item>

    <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
      <a-space>
        <a-button type="primary" html-type="submit"> Calculate </a-button>

        <a-button type="ghost" html-type="reset" danger> Reset </a-button>
      </a-space>
    </a-form-item>
  </a-form>

  <template v-if="result">
    <a-divider></a-divider>
    <a-row :gutter="[8, 0]" style="font-size: 18px">
      <a-col :span="4" style="text-align: right; font-weight: bold">
        Result :
      </a-col>
      <a-col :span="16">
        <span>{{ `${result}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} mg</span>
      </a-col>
    </a-row>
  </template>
</template>

<script>
export default {
  data() {
    return {
      model: {
        heparine_dose: null,
        percentage: 0.8,
      },
      result: null,
    };
  },

  methods: {
    onSubmit() {
      let heparine_dose = this.model.heparine_dose;
      let percentage = this.model.percentage;
      this.result = Number((heparine_dose * percentage) / 100).toFixed(1);
    },

    onReset() {
      this.model.heparine_dose = null;
      this.result = null;
    },
  },
};
</script>
