<template>
  <a-form
    name="live_charting_blood_gas_form"
    ref="live_charting_blood_gas_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <status-form
      :model="model"
      @update="(value, key) => (model[key] = value)"
    />

    <a-divider orientation="left" style="margin-top: 0 !important">
      Blood Gas
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="ph"
          name="ph"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <template #label> <sub>p</sub>H </template>
          <a-input-number type="number" :min="0" v-model:value="model.ph" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pco2"
          name="pco2"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <template #label> PCO<sub>2</sub> </template>
          <a-input-number type="number" :min="0" v-model:value="model.pco2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="po2"
          name="po2"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <template #label> PO<sub>2</sub> </template>
          <a-input-number type="number" :min="0" v-model:value="model.po2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hco3"
          name="hco3"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <template #label> HCO<sub>3</sub> </template>
          <a-input-number type="number" :min="0" v-model:value="model.hco3" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="base"
          label="BASE"
          name="base"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.base" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="o2_sat"
          name="o2_sat"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <template #label> O<sub>2</sub>&nbsp; SET </template>
          <a-input-number type="number" :min="0" v-model:value="model.o2_sat" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="Hb"
          label="Hb"
          name="Hb"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.Hb" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hct"
          label="HCT"
          name="hct"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.hct" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ica"
          label="iCa"
          name="ica"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.ica" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="kplus"
          name="kplus"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <template #label> K<sup>+</sup> </template>
          <a-input-number type="number" :min="0" v-model:value="model.kplus" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="glu"
          label="GLU"
          name="glu"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.glu" />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import statusForm from "../components/status.vue";

export default {
  components: { statusForm },

  data() {
    return {
      model: {
        time: moment().tz(this.$constants.timezone).format("HH:mm"),
        type: "Blood Gas",
        art_pressure: null,
        cvp: null,
        pa: null,
        blood_flow: null,
        line_pressure: null,
        venous_temp: null,
        core_temp: null,
        fio2: null,
        sweep: null,
        anesthetic_gas: null,
        act: null,
        svo2: null,

        // blood_gas
        ph: null,
        pco2: null,
        po2: null,
        hco3: null,
        base: null,
        o2_sat: null,
        hb: null,
        hct: null,
        ica: null,
        kplus: null,
        glu: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      console.log("submitted::", this.model);
      return;

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
