<template>
  <a-popover
    v-model:visible="isVisible"
    trigger="click"
    overlayClassName="overlay-popover-class"
  >
    <template #content>
      <div class="status-list">
        <span
          v-for="status in statuses"
          :key="status.value"
          @click="() => onStatusClick(status)"
        >
          <div
            :style="{
              backgroundColor: status.color,
              filter: loading ? 'brightness(0.8)' : 'brightness(1)',
            }"
            class="status-select-cell"
          >
            <span>{{ status.label }}</span>
          </div>
        </span>
      </div>
    </template>

    <div :style="style" class="status-cell">
      <span>{{ activeStatus.label }}</span>
    </div>
  </a-popover>
</template>

<script setup>
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import { computed, ref } from "vue";

const props = defineProps({
  status: Number,
  record: Object,
  isChild: Boolean,
  parent: Number,
});
const emit = defineEmits(["update:status", "update:parent"]);

const loading = ref(false);
const onStatusClick = (status) => {
  loading.value = true;
  const req = {
    column: "status",
    value: status.value,
    id: props.record.id,
  };

  if (!props.isChild) {
    req.main = true;
  }

  commonService
    .store(constants.monthlyBillingUpdate, req)
    .then(({ data }) => {
      isVisible.value = false;
      emit("update:status", status.value);
      emit("update:parent", data.status);
    })
    .finally(() => {
      isVisible.value = false;
      loading.value = false;
    });
  // isVisible.value = false;
};

const isVisible = ref(false);

const CustomerInvoiceStatus = {
  NotStarted: 0,
  WorkingOnIt: 1,
  Stuck: 2,
  Done: 3,
  // NoActivity: 4,
  NoBilling: 4,
};

const statuses = [
  {
    label: "Done",
    value: CustomerInvoiceStatus.Done,
    color: "#04B96F",
  },
  {
    label: "Working On It",
    value: CustomerInvoiceStatus.WorkingOnIt,
    color: "#E9A03D",
  },
  {
    label: "Stuck",
    value: CustomerInvoiceStatus.Stuck,
    color: "#CD2F48",
  },
  {
    label: "Not Started",
    value: CustomerInvoiceStatus.NotStarted,
    color: "#B4B5B6",
  },
  // {
  //   label: "No Activity",
  //   value: CustomerInvoiceStatus.NoActivity,
  //   color: "#0476A8",
  // },
  {
    label: "No Billing",
    value: CustomerInvoiceStatus.NoBilling,
    color: "#485696",
  },
];
const activeStatus = computed(() =>
  statuses.find((status) => status.value === props.status)
);

const style = computed(() => {
  const status = activeStatus.value;
  return {
    backgroundColor: status.color,
  };
});
</script>

<style scoped lang="less">
.status-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  flex-direction: column;

  .status-select-cell {
    height: 35px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.9;
    }

    span {
      text-align: center;
      display: block;
      font-size: 13px;
      select: none;
      color: #fff;
    }
  }
}

.status-cell {
  color: #fff;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }

  span {
    text-align: center;
    display: block;
    font-size: 13px;
    select: none;
    line-height: 1;
  }
}
</style>
