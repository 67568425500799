<template>
  <div>
    <a-form
      name="asset_product_form"
      ref="asset_product_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
              @change="resetErrors('name')"
            />
          </a-form-item>

          <a-form-item
            ref="asset_category_id"
            label="Category"
            name="asset_category_id"
            :validateStatus="validate('asset_category_id', 'status')"
            :help="validate('asset_category_id', 'msg')"
          >
            <a-select
              v-model:value="formState.asset_category_id"
              placeholder="Select category"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in assetCategories"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            ref="device_info_pdf"
            label="Device IFU PDF"
            name="device_info_pdf"
          >
            <a-upload
              :fileList="formState.device_info_pdf"
              :remove="handleRemoveAttachment"
              :before-upload="beforeUploadAttachment"
              accept="application/pdf"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>

          <a-form-item
            ref="is_required_maintenance"
            label=""
            name="is_required_maintenance"
          >
            <a-checkbox v-model:checked="formState.is_required_maintenance">
              Does product require preventative maintenance?
            </a-checkbox>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
    UploadOutlined,
  },
  data() {
    return {
      formState: {
        name: "",
        asset_category_id: undefined,
        is_required_maintenance: false,
        device_info_pdf: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "this field is required!",
            transform(value) {
              return value.trim();
            },
          },
        ],
        asset_category_id: [
          {
            required: true,
            message: "this field is required!",
            type: "number",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
    assetCategories() {
      return this.extra?.assetCategories ?? [];
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        asset_category_id: this.record.asset_category_id ?? undefined,
        is_required_maintenance: this.record.is_required_maintenance
          ? true
          : false,
        device_info_pdf: this.record.device_info_pdf
          ? [
              {
                id: this.record.id,
                uid: this.record.device_info_pdf,
                name: this.record.device_info_pdf,
                url: this.record.device_info_pdf_file_path,
              },
            ]
          : [],
      };
    },

    onSubmit() {
      this.loadingStart();

      const formData = new FormData();
      if (this.record.id) {
        formData.append("id", this.record.id);
      }

      Object.keys(this.formState).forEach((key) => {
        if (this.formState[key] == null) {
          this.formState[key] = "";
        }

        if (key == "device_info_pdf") {
          if (this.formState[key][0]) {
            Object.values(this.formState[key]).forEach((attach) => {
              if ("id" in attach) {
                formData.append(`old_${key}`, attach.id);
              } else {
                formData.append(`${key}`, attach);
              }
            });
          }
        } else if (key == "is_required_maintenance") {
          formData.append(key, this.formState[key] == true ? 1 : 0);
        } else {
          formData.append(key, this.formState[key]);
        }
      });

      commonService
        .store(this.$constants.assetProductStoreUrl, formData)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },

    handleRemoveAttachment() {
      this.formState.device_info_pdf = [];
    },

    beforeUploadAttachment(file) {
      if (file.size < 15 * 1024 * 1024) {
        this.formState.device_info_pdf = [file];
      } else {
        this.$message.error(`${file.name} size is more then 15MB`);
      }
      return false;
    },
  },
};
</script>
