<template>
  <a-layout-content>
    <page-header>
      <template #title> Accounts Payable</template>

      <template #buttons>
        <a-space>
          <a-button
            type="primary"
            ghost
            :loading="downloadExcelBtnLoading"
            @click="downloadExcel"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> Excel </a-space>
          </a-button>
        </a-space>
      </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col
        v-for="(widget, index) in widgets"
        :xs="24"
        :lg="12"
        :key="`widgets_${index}`"
      >
        <a-card>
          <a-spin :spinning="loading">
            <a-statistic :value="$comman.withCurrency(widget.value)">
              <template #title>
                {{ widget.title }}
              </template>
            </a-statistic>
          </a-spin>
        </a-card>
      </a-col>

      <a-col :span="24">
        <a-card size="small">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => record.Id"
            :dataSource="bills"
            :loading="loading"
            :pagination="{
              size: 'normal',
            }"
            size="small"
          >
            <template #filterDropdown="filterDropdown">
              <xFilterInputSearchDropdown
                :filterDropdown="filterDropdown"
                @handleSearch="handleDatatableSearch"
                @handleReset="handleDatatableReset"
              />
            </template>

            <template #filterIcon="filterIcon">
              <xFilterIcon :filterIcon="filterIcon" />
            </template>

            <template #status="{ record }">
              <a-tag
                v-if="moment().isAfter(moment(record.DueDate))"
                color="error"
              >
                Overdue
              </a-tag>
              <a-tag v-else color="processing"> Due </a-tag>
            </template>

            <template #docNumber="{ record }">
              <a
                :href="`https://qbo.intuit.com/app/bill?&txnId=${record.Id}`"
                target="_blank"
              >
                {{ record.DocNumber ?? "N/A" }}
              </a>
            </template>

            <template #vendor="{ record }">
              <a
                :href="`https://qbo.intuit.com/app/vendordetail?&nameId=${record.VendorRef.value}`"
                target="_blank"
              >
                {{ record.VendorRef.name ?? "N/A" }}
              </a>
            </template>

            <template #inDays="{ record }">
              <template v-if="moment().isAfter(moment(record.DueDate))">
                {{ moment().diff(moment(record.DueDate), "days") }} days ago
              </template>
              <template v-else>
                In {{ moment(record.DueDate).diff(moment(), "days") + 1 }} days
              </template>
            </template>

            <template #action="{ record }">
              <a-button
                type="link"
                size="small"
                class="pl-sm-0"
                @click="handleShowBillEvent(record)"
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import xFilterDateRangeDropdown from "../../components/table/filterDateRangeDropdown.vue";
import xFilterIcon from "../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../components/table/filterInputSearchDropdown.vue";
import { commonService } from "../../services";

export default {
  components: {
    xFilterIcon,
    xFilterInputSearchDropdown,
    xFilterDateRangeDropdown,
  },

  data() {
    return {
      bills: [],
      loading: true,
      downloadExcelBtnLoading: false,
      moment,
      columns: [
        {
          title: "Bill No",
          dataIndex: "DocNumber",
          key: "DocNumber",
          defaultSortOrder: "ascend",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "docNumber",
          },
          sorter: (a, b) => a.DocNumber - b.DocNumber,
          onFilter: (value, record) =>
            record.DocNumber.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Vendor",
          dataIndex: "VendorRef.name",
          key: "VendorRef.name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "vendor",
          },
          onFilter: (value, record) =>
            record.VendorRef.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Due Date",
          dataIndex: "DueDate",
          key: "DueDate",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => this.$customDate.mdy(text),
          sorter: (a, b) =>
            moment(a.DueDate).isAfter(moment(b.DueDate)) ? 1 : -1,
          onFilter: (value, record) =>
            this.$customDate
              .mdy(record.DueDate)
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Bill Amount",
          dataIndex: "TotalAmt",
          key: "TotalAmt",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => this.$comman.withCurrency(text),
          sorter: (a, b) => a.TotalAmt - b.TotalAmt,
          onFilter: (value, record) =>
            record.TotalAmt.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Open Balance",
          dataIndex: "Balance",
          key: "Balance",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => this.$comman.withCurrency(text),
          sorter: (a, b) => a.Balance - b.Balance,
          onFilter: (value, record) =>
            record.Balance.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "In Days",
          dataIndex: "inDays",
          key: "inDays",
          slots: {
            customRender: "inDays",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  computed: {
    widgets() {
      return [
        {
          title: "Due Amount",
          value:
            this.bills
              ?.filter((e) => moment(e.DueDate).isAfter(moment()))
              ?.reduce((pre, curr) => pre + curr.Balance, 0) ?? 0,
        },
        {
          title: "Overdue Amount",
          value:
            this.bills
              ?.filter((e) => moment().isAfter(moment(e.DueDate)))
              ?.reduce((pre, curr) => pre + curr.Balance, 0) ?? 0,
        },
      ];
    },
  },

  mounted() {
    this.getBills();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getBills() {
      commonService.store(this.$constants.financeUnpaidBills).then((res) => {
        if (res.success) {
          this.bills = res.data;
        } else {
          this.bills = [];
        }
        this.loading = false;
      });
    },

    handleShowBillEvent(record) {
      this.open({
        title: "Show Bill Details",
        path: "finance.components.showBill",
        record: record,
      });
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
    },

    downloadExcel() {
      this.downloadExcelBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.financeUnpaidBills,
          {
            export_excel: true,
          },
          `Account Payable.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadExcelBtnLoading = false;
        });
    },
  },
};
</script>
