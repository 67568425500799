<template>
  <a-card title="Hospitals" size="small" :loading="cardLoading">
    <template #extra>
      <a-select
        :key="selectedTemplate"
        v-model:value="selectedTemplate"
        placeholder="Select a Template"
        optionFilterProp="label"
        allow-clear
        ref="templateSelect"
        size="small"
        showSearch
      >
        <a-select-option
          v-for="temp in hospitalTemplates"
          :key="temp.id"
          :label="temp.name"
          :value="temp.id"
        >
          {{ temp.name }}
        </a-select-option>
      </a-select>
    </template>

    <a-form
      ref="employee_hospital_form"
      :model="employeeHospitals"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[16, 0]">
        <a-col :span="16">Hospital Name</a-col>
        <a-col :span="4">State</a-col>
        <a-col :span="2">Primary?</a-col>
        <a-col :span="2">Action</a-col>
      </a-row>

      <a-row class="mt-sm-1" :gutter="[16, 0]">
        <template v-for="(v, i) in employeeHospitals" :key="i">
          <a-col :span="16">
            <a-form-item
              ref="hospital_id"
              :name="[i, 'hospital_id']"
              :rules="{
                required: true,
                message: 'field is required!',
                trigger: 'change',
                type: 'number',
              }"
              :wrapperCol="{ style: { width: '100%' } }"
            >
              <a-select
                v-model:value="v.hospital_id"
                placeholder="Select hospital"
                optionFilterProp="label"
                :showSearch="true"
                width="100%"
                @change="handleHospitalSelect(i)"
              >
                <a-select-option
                  v-for="hospital in unSelectedHospitals(v)"
                  :key="hospital.id"
                  :label="`${hospital.name} | Cost Center: ${hospital.id}`"
                  :value="hospital.id"
                >
                  {{ hospital.name }} | Cost Center: {{ hospital.id }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item ref="hospital_state" name="hospital_state">
              <a-input
                v-model:value="v.hospital_state"
                placeholder="Hospital state"
                :disabled="true"
              />
            </a-form-item>
          </a-col>

          <a-col :span="2">
            <a-checkbox
              v-model:checked="v.is_primary"
              :disabled="v.is_primary"
              @change="handleIsPrimaryChange(v)"
            />
          </a-col>

          <a-col :span="2">
            <a-button
              type="link"
              class="danger-color"
              @click="handleFieldRemove(i)"
            >
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-col>
        </template>
      </a-row>

      <a-button class="mt-sm-1" type="dashed" block @click="addField()">
        <i class="fas fa-plus mr-sm-1"></i>
        Add field
      </a-button>

      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          class="mt-sm-1"
          :loading="submitBtnLoading"
        >
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { commonService } from "../../../services";

export default {
  props: {
    employee: {
      required: true,
      defualt: null,
    },
  },

  data() {
    return {
      cardLoading: false,
      submitBtnLoading: false,
      selectedTemplate: null,
      employeeHospitals: [],
      hospitalTemplates: [],
      hospitals: [],
      hospitalIds: [],
      row: {
        id: 0,
        employee_id: this.$route.params.id,
        hospital_id: null,
        hospital_state: null,
      },
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    handleIsPrimaryChange(row) {
      this.employeeHospitals.forEach((v) => {
        if (v.id != row.id) {
          v.is_primary = false;
        }
      });
    },

    fetchData() {
      this.cardLoading = true;

      Promise.all([
        this.$comman.getHospitals(),
        this.fetchEmployeeHospitals(),
        this.fetchHospitalTemplates(),
      ]).then(([hospitals, employeeHospitals, hospitalTemplates]) => {
        this.cardLoading = false;
        this.hospitals = hospitals;
        this.employeeHospitals = employeeHospitals;
        this.hospitalTemplates = hospitalTemplates;
        this.employeeHospitals.forEach((employee) => {
          employee.hospital_state = this.hospitals.find(
            (v) => v.id == employee.hospital_id
          ).state;
          employee.is_primary = !!employee.is_primary;
        });
        this.updateHospitalList();
      });
    },

    handleTemplateSelect() {
      const value = this.selectedTemplate;

      if (!value) {
        return;
      }

      this.hospitalIds = this.hospitalTemplates.find(
        (v) => v.id == value
      ).hospital_ids;

      const selectedHospitalIds = this.employeeHospitals.map(
        (v) => v.hospital_id
      );

      this.hospitalIds.forEach((v) => {
        if (!selectedHospitalIds.includes(v)) {
          this.employeeHospitals.push({
            id: 0,
            employee_id: this.$route.params.id,
            hospital_id: v,
            hospital_state: this.hospitals.find((h) => h.id == v).state,
          });
        }
      });

      // this.$refs['templateSelect'].;

      this.$nextTick(() => {
        this.selectedTemplate = null;
      });
    },

    fetchHospitalTemplates() {
      return commonService
        .get(this.$constants.getHospitalTemplates)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "employee" });
          return Promise.reject();
        });
    },

    fetchEmployeeHospitals() {
      return commonService
        .get(this.$constants.employeeHospitalShowUrl, {
          id: this.$route.params.id,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "employee" });
          return Promise.reject();
        });
    },

    addField() {
      this.employeeHospitals.push(Object.assign({}, this.row));
    },

    handleHospitalSelect(i) {
      var row = this.employeeHospitals[i];
      row.hospital_state = this.hospitals.find(
        (v) => v.id == row.hospital_id
      ).state;
      this.updateHospitalList();
    },

    updateHospitalList() {
      let hospitalIds = [];
      this.employeeHospitals.forEach((v) => {
        if (v.hospital_id != null) {
          hospitalIds.push(v.hospital_id);
        }
      });
      this.hospitals.map((v) => {
        if (hospitalIds.includes(v.id)) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });
    },

    handleFieldRemove(i) {
      this.employeeHospitals.splice(i, 1);
    },

    handleFinish() {
      let data = {
        employee_id: this.$route.params.id,
        hospital_ids: {},
      };

      this.employeeHospitals.forEach((v) => {
        if (!v.hospital_id) return;
        data.hospital_ids[v.hospital_id] = {
          is_primary: v.is_primary ? 1 : 0,
        };
      });

      this.submitBtnLoading = true;
      commonService
        .store(this.$constants.employeeHospitalStoreUrl, data)
        .then((res) => {
          this.submitBtnLoading = false;
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.submitBtnLoading = false;
          this.$message.error(err);
        });
    },

    unSelectedHospitals(value) {
      return this.hospitals.filter((v) => {
        if (v.id == value.hospital_id || v.selected != true) {
          return v;
        }
      });
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.fetchData();
      }
    },
    selectedTemplate: function (val) {
      if (val) {
        this.handleTemplateSelect();
      }
    },
  },
};
</script>
