import axios from "axios";
import { api } from "../config/config";
import { store } from "@/store";
import { constants } from "./constants";

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    if (config.url && config.url.includes("apistage.insperity.com")) {
      return config;
    }
    if (config.url && config.url.includes("maps.googleapis.com")) {
      return config;
    }
    let token = store.getters["auth/token"];
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      // Add extra parameters here
      config.data = config.data || {};
      // config.data.auth_data = {
      //   user_id: store.getters["auth/user"].id,
      //   employee_id: store.getters["auth/employee"].id,
      //   employee_hospital_ids: store.getters["auth/employee_hospital_ids"],
      // };
    }
    config.headers["Accept"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      store.dispatch("auth/logout");
    }
    // const originalRequest = error.config;
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   let token = localStorage.getItem("token");
    //   if (token) {
    //     return axios
    //       .post(api.host + constants.refreshTokenUrl, {}, originalRequest)
    //       .then((res) => {
    //         if (res.status == 200 || res.status == 201) {
    //           // 1) put token to LocalStorage
    //           localStorage.setItem("token", JSON.stringify(res.data.token));
    //           store.dispatch("auth/login", { isInitStore: false });
    //           // 2) Change Authorization header
    //           axios.defaults.headers.common["Authorization"] =
    //             "Bearer " + res.data.token.access_token;

    //           // 3) return originalRequest object with Axios.
    //           return axios(originalRequest);
    //         } else {
    //         }
    //       })
    //       .catch((err) => {
    //         store.dispatch("auth/logout");
    //       });
    //   }
    // }
    // return Error object with Promise
    return Promise.reject(error);
  }
);
