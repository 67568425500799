<template>
  <a-skeleton :loading="cardLoading">
    <a-form
      name="payroll_form"
      ref="payroll_form"
      :model="model"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="payroll_date"
            :name="['payroll', 'date']"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'date',
            }"
          >
            <template #label>
              <a-space> Payroll Date </a-space>
            </template>

            <a-date-picker
              v-model:value="model.payroll.date"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
              :allow-clear="false"
              :disabled="!model.payroll.is_date_editable"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="payroll_days"
            :name="['payroll', 'days']"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'integer',
            }"
          >
            <template #label>
              <a-space> Payroll Days </a-space>
            </template>

            <a-input-number
              type="number"
              v-model:value="model.payroll.days"
              placeholder="Enter days"
              :min="1"
              :step="1"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
        Save
      </a-button>
    </a-form>
  </a-skeleton>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      quickbookGetAccountLoading: false,
      submitBtnLoader: false,
      accounts: [],
      model: {
        payroll: {
          date: null,
          is_date_editable: true,
          days: null,
        },
      },
    };
  },

  mounted() {
    this.fetchSetting();
  },

  methods: {
    fetchSetting() {
      this.cardLoading = true;

      commonService
        .store(this.$constants.getSettingUrl)
        .then((res) => {
          if (res.success) {
            if (res.data.payroll) {
              let payroll = JSON.parse(res.data.payroll);
              this.model.payroll.date = moment(payroll.date);
              this.model.payroll.is_date_editable = payroll.is_date_editable;
              this.model.payroll.days = payroll.days;
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    handleFinish() {
      this.submitBtnLoader = true;

      let payroll = JSON.stringify({
        ...this.model.payroll,
        date: this.$customDate.ymd(this.model.payroll.date),
      });

      commonService
        .store(this.$constants.settingStoreUrl, { payroll })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
