<template>
  <a-alert
    description="The Heparin dose calculator uses a standard calculation of 400 units per kg.
    Please refer to your facility’s policy on the actual calculation. This
    calculator is to be used as a guide only."
  />

  <a-divider></a-divider>

  <a-form
    :model="model"
    @finish="onSubmit"
    @reset="onReset"
    :labelCol="{ span: 6 }"
    :wrapperCol="{ span: 16 }"
  >
    <a-form-item
      label="Weight (kg)"
      name="weight"
      :rules="{
        required: true,
        message: 'This field is required',
        type: 'number',
      }"
    >
      <a-input-number v-model:value="model.weight" placeholder="Kg" :min="0"/>
    </a-form-item>

    <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
      <a-space>
        <a-button type="primary" html-type="submit"> Calculate </a-button>

        <a-button type="ghost" html-type="reset" danger> Reset </a-button>
      </a-space>
    </a-form-item>
  </a-form>

  <template v-if="result">
    <a-divider></a-divider>
    <a-row :gutter="[8, 0]" style="font-size: 18px">
      <a-col :span="4" style="text-align: right; font-weight: bold">
        Result :
      </a-col>
      <a-col :span="16">
        <span>
          {{ `${result}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} Units
        </span>
      </a-col>
    </a-row>
  </template>
</template>

<script>
export default {
  data() {
    return {
      model: {
        weight: null,
        unit_per_kg: 400,
      },
      result: null,
    };
  },

  methods: {
    onSubmit() {
      let weight = this.model.weight;
      let unit_per_kg = this.model.unit_per_kg;
      this.result = Number(weight * unit_per_kg);
    },

    onReset() {
      this.model.weight = null;
      this.result = null;
    },
  },
};
</script>
