<template>
  <a-form
    name="bmi_calculator"
    ref="bmi_calculator"
    :model="model"
    @finish="onSubmit"
    @reset="onReset"
    :labelCol="{ span: 6 }"
    :wrapperCol="{ span: 16 }"
  >
    <a-form-item
      label="Weight (kg)"
      name="weight"
      :rules="{
        required: true,
        message: 'This field is required',
        type: 'number',
      }"
    >
      <a-input-number v-model:value="model.weight" placeholder="Kg" :min="0"/>
    </a-form-item>

    <a-form-item
      label="Height (cm)"
      name="height"
      :rules="{
        required: true,
        message: 'This field is required',
        type: 'number',
      }"
    >
      <a-input-number v-model:value="model.height" placeholder="Cm" :min="0"/>
    </a-form-item>

    <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
      <a-space>
        <a-button type="primary" html-type="submit"> Calculate </a-button>

        <a-button type="ghost" html-type="reset" danger> Reset </a-button>
      </a-space>
    </a-form-item>
  </a-form>

  <template v-if="result">
    <a-divider></a-divider>
    <a-row :gutter="[8, 0]" style="font-size: 18px">
      <a-col :span="8" style="text-align: right; font-weight: bold">
        BSA Result :
      </a-col>
      <a-col :span="12">
        <a-space>
          <span>{{ result.bsa }} m<sup>2</sup></span>
        </a-space>
      </a-col>
    </a-row>
  </template>
</template>

<script>
export default {
  data() {
    return {
      model: {
        weight: null,
        height: null,
      },
      result: null,
      types: [
        {
          range: [0.0, 18.5],
          name: "Underweight",
          color: "orange",
          description: "less then 18.5",
        },
        {
          range: [18.5, 25.0],
          name: "Normal",
          color: "green",
          description: "18.5 – 24.9",
        },
        {
          range: [25.0, 30.0],
          name: "Overweight",
          color: "yellow",
          description: "25 – 29.9",
        },
        {
          range: [30.0, 100.0],
          name: "Obesity",
          color: "red",
          description: "greater then 30",
        },
      ],
    };
  },

  computed: {
    type() {
      let res = { name: "N/A", color: "purple" };
      if (!this.result) {
        return res;
      }
      return this.types.find(
        (e) => e.range[0] <= this.result.bmi && this.result.bmi < e.range[1]
      );
    },
  },

  methods: {
    onSubmit() {
      let weight = this.model.weight;
      let height = this.model.height / 100;
      this.result = {
        bmi: Number(weight / Math.pow(height, 2)).toFixed(1),
        bsa: Number(Math.sqrt((weight * height) / 36)).toFixed(2),
      };
    },

    onReset() {
      this.model.weight = null;
      this.model.height = null;
      this.result = null;
    },
  },
};
</script>
