<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      ref="formI9"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col v-if="!hasBasicInfoFilled" :span="24" class="mb-sm-1">
          <a-alert type="warning" show-icon>
            <template #description>
              Please fill out
              <a-typography-link @click="goToBasicInfoTab" size="small">
                Basic Details
              </a-typography-link>
              before proceeding with this form.
            </template>
          </a-alert>
        </a-col>

        <a-col v-if="oldData" :span="24" class="mb-sm-1">
          <a-alert
            type="info"
            description="We have succesfully received Section-1 of your Form I-9. Please do not change any information unless you receive any notification from Employer."
          />
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="Last Name (Family Name)"
            name="Last Name (Family Name)"
            :validateStatus="validate('Last Name (Family Name)', 'status')"
            :help="validate('Last Name (Family Name)', 'msg')"
          >
            <a-input
              v-model:value="formState['Last Name (Family Name)']"
              placeholder="Enter here..."
              @change="resetErrors('Last Name (Family Name)')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="First Name (Given Name)"
            name="First Name Given Name"
            :validateStatus="validate('First Name Given Name', 'status')"
            :help="validate('First Name Given Name', 'msg')"
          >
            <a-input
              v-model:value="formState['First Name Given Name']"
              placeholder="Enter here..."
              @change="resetErrors('First Name (Given Name')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="Employee Middle Initial (if any)"
            name="Employee Middle Initial (if any)"
            :validateStatus="
              validate('Employee Middle Initial (if any)', 'status')
            "
            :help="validate('Employee Middle Initial (if any)', 'msg')"
          >
            <a-input
              v-model:value="formState['Employee Middle Initial (if any)']"
              placeholder="Enter here..."
              @change="resetErrors('Employee Middle Initial (if any)')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="Employee Other Last Names Used (if any)"
            name="Employee Other Last Names Used (if any)"
            :validateStatus="
              validate('Employee Other Last Names Used (if any)', 'status')
            "
            :help="validate('Employee Other Last Names Used (if any)', 'msg')"
          >
            <a-input
              v-model:value="
                formState['Employee Other Last Names Used (if any)']
              "
              placeholder="Enter here..."
              @change="resetErrors('Employee Other Last Names Used (if any)')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="U.S. Social Security Number"
            name="US Social Security Number"
            :validateStatus="validate('US Social Security Number', 'status')"
            :help="validate('US Social Security Number', 'msg')"
          >
            <a-input
              v-model:value="formState['US Social Security Number']"
              v-maska="'###-##-####'"
              placeholder="Enter here..."
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="Employee's E-mail Address"
            name="Employees E-mail Address"
            :validateStatus="validate('Employees E-mail Address', 'status')"
            :help="validate('Employees E-mail Address', 'msg')"
          >
            <a-input
              v-model:value="formState['Employees E-mail Address']"
              placeholder="Enter here..."
              @change="resetErrors('Employees E-mail Address')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="Address (Street Number and Name)"
            name="Address Street Number and Name"
            :validateStatus="
              validate('Address Street Number and Name', 'status')
            "
            :help="validate('Address Street Number and Name', 'msg')"
          >
            <a-input
              v-model:value="formState['Address Street Number and Name']"
              placeholder="Enter here..."
              @change="resetErrors('Address Street Number and Name')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="Apt. Number (if any)"
            name="Apt Number (if any)"
            :validateStatus="validate('Apt Number (if any)', 'status')"
            :help="validate('Apt Number (if any)', 'msg')"
          >
            <a-input
              v-model:value="formState['Apt Number (if any)']"
              placeholder="Enter here..."
              @change="resetErrors('Apt Number (if any)')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="City or Town"
            name="City or Town"
            :validateStatus="validate('City or Town', 'status')"
            :help="validate('City or Town', 'msg')"
          >
            <a-input
              v-model:value="formState['City or Town']"
              placeholder="Enter here..."
              @change="resetErrors('City or Town')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="State"
            name="State"
            :validateStatus="validate('State', 'status')"
            :help="validate('State', 'msg')"
          >
            <a-select
              v-model:value="formState['State']"
              placeholder="Select State"
              show-search
              optionFilterProp="label"
              :options="states"
              @change="resetErrors('State')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="12" :sm="8">
          <a-form-item
            label="ZIP Code"
            name="ZIP Code"
            :validateStatus="validate('ZIP Code', 'status')"
            :help="validate('ZIP Code', 'msg')"
          >
            <a-input
              v-model:value="formState['ZIP Code']"
              placeholder="Enter here..."
              @change="resetErrors('ZIP Code')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="Telephone Number"
            name="Telephone Number"
            :validateStatus="validate('Telephone Number', 'status')"
            :help="validate('Telephone Number', 'msg')"
          >
            <a-input
              v-model:value="formState['Telephone Number']"
              placeholder="Enter here..."
              v-maska="$constants.contactNumberMask"
              @change="resetErrors('Telephone Number')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="12">
          <a-form-item
            label="Date of Birth (mm/dd/yyyy)"
            name="Date of Birth mmddyyyy"
            :validateStatus="validate('Date of Birth mmddyyyy', 'status')"
            :help="validate('Date of Birth mmddyyyy', 'msg')"
          >
            <a-date-picker
              v-model:value="formState['Date of Birth mmddyyyy']"
              :placeholder="$constants.datePickerPlaceholder"
              :format="$constants.datepickerFormat"
              :disabledDate="$comman.disabledFutureDate"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            label="Check one of the following boxes to attest to your citizenship or immigration status"
            name="citizenship_or_immigration_status"
          >
            <a-radio-group
              v-model:value="formState.citizenship_or_immigration_status"
              :options="$constants.citizenshipStatus"
              size="large"
            />
          </a-form-item>
        </a-col>

        <a-col
          :span="12"
          v-if="formState.citizenship_or_immigration_status === 'CB_3'"
        >
          <a-form-item
            name="lawful_perm_resident"
            label="Enter USCIS or A-Number"
          >
            <a-input
              v-model:value="formState.lawful_perm_resident"
              placeholder="Enter here..."
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-row :gutter="[10, 10]">
            <a-col
              :span="24"
              v-if="formState.citizenship_or_immigration_status === 'CB_4'"
            >
              <a-form-item
                name="noncitizen_exp_date"
                label="Expiry Date (if any)"
              >
                <a-date-picker
                  v-model:value="formState.noncitizen_exp_date"
                  :placeholder="$constants.datePickerPlaceholder"
                  :format="$constants.datepickerFormat"
                  :disabledDate="$comman.disabledPastDate"
                />
              </a-form-item>
            </a-col>

            <a-col
              :span="12"
              v-if="formState.citizenship_or_immigration_status === 'CB_4'"
            >
              <a-form-item name="noncitizen_uscis" label="USCIS A-Number">
                <a-input
                  v-model:value="formState.noncitizen_uscis"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </a-col>

            <a-col
              :span="12"
              v-if="formState.citizenship_or_immigration_status === 'CB_4'"
            >
              <a-form-item
                name="noncitizen_form_admission"
                label="Form I-94 Admission No."
              >
                <a-input
                  v-model:value="formState.noncitizen_admission_no"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </a-col>

            <a-col
              :span="24"
              v-if="formState.citizenship_or_immigration_status === 'CB_4'"
            >
              <a-form-item
                name="noncitizen_pasport"
                label="Foreign Passport No. & Country of Issuance"
              >
                <a-input
                  v-model:value="formState.noncitizen_pasport"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>

        <a-col :span="24">
          <a-alert
            message="By submitting this form, you are attesting to the following"
            :description="message"
            type="error"
            style="margin-bottom: 20px"
          />
        </a-col>

        <a-col :span="24">
          <a-descriptions
            :column="2"
            size="small"
            bordered
            style="margin-bottom: 20px"
            :labelStyle="{ width: isMobile ? '100px' : '250px' }"
          >
            <!-- <a-descriptions-item label="Signature of Employee">
              {{ employee.fl_name }}
            </a-descriptions-item> -->
            <a-descriptions-item label="Today's Date (mm/dd/yyyy)">
              {{ formState["Today's Date mmddyyy"] }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="24">
          <a-row type="flex" justify="center">
            <a-space direction="vertical">
              <vue-signature-pad
                :options="{ onEnd: save }"
                ref="signaturePad"
                :customStyle="{
                  ...$constants.signPadCustomCss,
                  height: '80px',
                }"
              />

              <a-row type="flex" justify="center">
                <a-col>
                  <a-typography-paragraph>
                    Employee signature indicates that the information reported
                    is complete and accurate.
                  </a-typography-paragraph>
                </a-col>
              </a-row>
              <a-row v-if="formState.signature" justify="center">
                <a-space>
                  <a-button
                    htmlType="button"
                    type="primary"
                    danger
                    ghost
                    @click="clear"
                    class="mb-sm-1"
                  >
                    Clear
                  </a-button>
                </a-space>
              </a-row>
            </a-space>
          </a-row>
        </a-col>

        <a-col v-if="oldData" :span="24" class="mb-sm-1">
          <a-alert
            type="info"
            description="We have succesfully received Section-1 of your Form I-9. Please do not change any information unless you receive any notification from Employer."
          />
        </a-col>

        <a-col v-if="!hasBasicInfoFilled" :span="24" class="mb-sm-1">
          <a-alert type="warning" show-icon>
            <template #description>
              Please fill out
              <a-typography-link @click="goToBasicInfoTab" size="small">
                Basic Details
              </a-typography-link>
              before proceeding with this form.
            </template>
          </a-alert>
        </a-col>
      </a-row>

      <a-space>
        <a-button :loading="pdfLoading" @click="previewPDF"> Preview</a-button>

        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
          :disabled="!hasBasicInfoFilled"
        >
          Submit
        </a-button>
      </a-space>
    </a-form>
  </div>
</template>

<script>
import { useWindowSize } from "@/composition/useWindowSize";
import { commonService, companyService } from "@/services";
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    CheckOutlined,
  },
  emits: ["changeTab"],
  mixins: [formMixins],
  data() {
    return {
      loading: false,
      moment,
      pdfLoading: false,
      formData: [],
      formState: {
        "Last Name (Family Name)": null,
        "First Name Given Name": null,
        "Employee Middle Initial (if any)": null,
        "Employee Other Last Names Used (if any)": null,
        "Address Street Number and Name": null,
        "Apt Number (if any)": null,
        "City or Town": null,
        State: null,
        "ZIP Code": null,
        "Date of Birth mmddyyyy": null,
        "US Social Security Number": null,
        "Employees E-mail Address": null,
        "Telephone Number": null,
        signature: null,
        signatured_at: null,
        "Today's Date mmddyyy": null,
      },
      oldData: null,
      message:
        "I am aware that federal law " +
        "provides for imprisonment and/or " +
        "fines for false statements, or the " +
        "use of false documents, in " +
        "connection with the completion of " +
        "this form. I attest, under penalty " +
        "of perjury, that this information, " +
        "including my selection of the box " +
        "attesting to my citizenship or " +
        "immigration status, is true and " +
        "correct.",
    };
  },

  mounted() {
    this.getFormData();
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    states() {
      const data = this.formData;
      const state = data.find((item) => item.FieldName === "State") ?? [];

      return (
        state.FieldStateOption?.filter((i) => !!i.trim().length).map(
          (item) => ({
            label: item,
            value: item,
          })
        ) ?? []
      );
    },
    rules() {
      return {
        "Last Name (Family Name)": [
          { required: true, message: "This field is required" },
        ],
        "First Name Given Name": [
          { required: true, message: "This field is required" },
        ],
        // "Employee Middle Initial (if any)": [
        //   { required: true, message: "This field is required" },
        // ],
        // "Employee Other Last Names Used (if any)": [
        //   { required: true, message: "This field is required" },
        // ],
        "Address Street Number and Name": [
          { required: true, message: "This field is required" },
        ],
        // "Apt Number (if any)": [
        //   { required: true, message: "This field is required" },
        // ],
        "City or Town": [{ required: true, message: "This field is required" }],
        State: [{ required: true, message: "This field is required" }],
        "ZIP Code": [
          {
            required: true,
            message: "This field is required",
            validator: this.$validation.zipcode,
          },
        ],
        "Date of Birth mmddyyyy": [
          { required: true, message: "This field is required" },
        ],
        "US Social Security Number": [
          {
            required: true,
            validator: (rule, value) => {
              const reg = /^\d{3}-?\d{2}-?\d{4}$/;
              value = value.trim();
              if (value == "") {
                return Promise.reject("This field is required");
              }
              if (value && !reg.test(value)) {
                return Promise.reject("Invalid social security number");
              }
              return Promise.resolve();
            },
          },
        ],
        "Employees E-mail Address": [
          { required: true, message: "This field is required" },
        ],
        "Telephone Number": [
          { required: true, message: "This field is required" },
        ],
        lawful_perm_resident: [
          { required: true, message: "This field is required" },
        ],
        noncitizen_uscis: [
          {
            required:
              !this.formState.noncitizen_form_admission &&
              !this.formState.noncitizen_pasport,
            message: "This field is required",
          },
        ],
        noncitizen_form_admission: [
          {
            required:
              !this.formState.noncitizen_uscis &&
              !this.formState.noncitizen_pasport,
            message: "This field is required",
          },
        ],
        noncitizen_pasport: [
          {
            required:
              !this.formState.noncitizen_uscis &&
              !this.formState.noncitizen_form_admission,
            message: "This field is required",
          },
        ],
        citizenship_or_immigration_status: [
          { required: true, message: "This field is required" },
        ],
      };
    },
    hasBasicInfoFilled() {
      const employee = this.employee;

      const socialSecurity = employee.social_security_no;
      const license = employee.license_no;

      return !!socialSecurity && !!license;
    },
  },

  methods: {
    ...mapActions("auth", ["set_form_i9"]),

    goToBasicInfoTab() {
      this.$emit("changeTab", "basic_info");
    },

    prepareDataForSubmit(data) {
      const holder = {
        "First Name Given Name": data["First Name Given Name"],
        "Last Name (Family Name)": data["Last Name (Family Name)"],
        "Employee Middle Initial (if any)":
          data["Employee Middle Initial (if any)"],
        "Employee Other Last Names Used (if any)":
          data["Employee Other Last Names Used (if any)"],
        "Address Street Number and Name":
          data["Address Street Number and Name"],
        "Apt Number (if any)": data["Apt Number (if any)"],
        "City or Town": data["City or Town"],
        State: data.State,
        "ZIP Code": data["ZIP Code"],
        "US Social Security Number": data["US Social Security Number"],
        "Employees E-mail Address": data["Employees E-mail Address"],
        "Telephone Number": data["Telephone Number"],
      };

      holder["Date of Birth mmddyyyy"] = data["Date of Birth mmddyyyy"]
        ? moment(data["Date of Birth mmddyyyy"]).format("MM/DD/YYYY")
        : null;

      switch (data.citizenship_or_immigration_status) {
        case "CB_1":
          holder["CB_1"] = "On";
          break;
        case "CB_2":
          holder["CB_2"] = "On";
          break;
        case "CB_3":
          holder["CB_3"] = "On";
          holder["3 A lawful permanent resident Enter USCIS or ANumber"] =
            data.lawful_perm_resident;
          break;
        case "CB_4":
          holder["CB_4"] = "On";

          holder["Exp Date mmddyyyy"] = moment(data.noncitizen_exp_date).format(
            "MM/DD/YYYY"
          );

          holder["USCIS ANumber"] = data.noncitizen_uscis;
          holder["Form I94 Admission Number"] = data.noncitizen_form_admission;
          holder["Foreign Passport Number and Country of IssuanceRow1"] =
            data.noncitizen_pasport;
          break;
      }

      holder["Signature of Employee"] = this.employee.fl_name;
      holder["Today's Date mmddyyy"] = data["Today's Date mmddyyy"];
      holder["employee_id"] = this.employee.id;
      holder["signature"] = data.signature;
      holder["signatured_at"] = data.signatured_at ?? null;

      return holder;
    },

    onSubmit() {
      if (!this.formState.signature) {
        this.$message.error("Signature is required for submission.");
        return;
      }

      this.loading = true;
      this.errors = {};
      companyService
        .store(
          this.$constants.formI9Store,
          this.prepareDataForSubmit(this.formState)
        )
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.oldData = {
              data: true,
            };
            this.set_form_i9(res.data);
          }
        })
        .catch((err) => {
          this.errors = err.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    previewPDF() {
      if (!this.formState.signature) {
        this.$message.error("Signature is required for submission.");
        return;
      }

      this.$refs.formI9.validate().then(() => {
        this.pdfLoading = true;
        commonService
          .renderFile(
            this.$constants.formI9Preview,
            this.prepareDataForSubmit(this.formState),
            "application/pdf;base64"
          )
          .then((res) => {
            console.log({ res });
            // this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.pdfLoading = false;
          });
      });
    },

    getFormData() {
      return commonService
        .get(this.$constants.formI9, {})
        .then((res) => {
          this.formData = res.data["i-9"];
          this.oldData = res.data.user_data;

          if (this.oldData) {
            this.formState = this.oldData.data;

            this.formState["Date of Birth mmddyyyy"] = moment(
              this.oldData.data["Date of Birth mmddyyyy"]
            );

            if (this.oldData.data["CB_1"]) {
              this.formState.citizenship_or_immigration_status = "CB_1";
            } else if (this.oldData.data["CB_2"]) {
              this.formState.citizenship_or_immigration_status = "CB_2";
            } else if (this.oldData.data["CB_3"]) {
              this.formState.citizenship_or_immigration_status = "CB_3";
            } else if (this.oldData.data["CB_4"]) {
              this.formState.citizenship_or_immigration_status = "CB_4";
            }

            this.formState.lawful_perm_resident =
              this.oldData.data[
                "3 A lawful permanent resident Enter USCIS or ANumber"
              ];
            if (this.oldData.data["Exp Date mmddyyyy"]) {
              this.formState.noncitizen_exp_date = moment(
                this.oldData.data["Exp Date mmddyyyy"]
              );
            }
            this.formState.noncitizen_uscis =
              this.oldData.data["USCIS ANumber"];
            this.formState.noncitizen_form_admission =
              this.oldData.data["Form I94 Admission Number"];
            this.formState.noncitizen_pasport =
              this.oldData.data[
                "Foreign Passport Number and Country of IssuanceRow1"
              ];
            this.formState.signature = this.oldData.data["signature"];
            this.formState.signatured_at =
              this.oldData.data["signatured_at"] ?? null;

            setTimeout(() => {
              this.$refs.signaturePad.fromDataURL(this.formState.signature);
            }, 500);
          } else {
            let state = null;
            if (this.employee?.state) {
              if (this.employee.state.length == 2) {
                state = this.employee.state;
              } else {
                let employeeState = Object.values(this.$constants.states).find(
                  (v) => {
                    if (v.name == this.employee.state) {
                      return v;
                    }
                  }
                );
                state = employeeState?.value ?? null;
              }
            }

            this.formState["First Name Given Name"] = this.employee?.first_name;
            this.formState["Last Name (Family Name)"] =
              this.employee?.last_name;
            this.formState["Address Street Number and Name"] =
              this.employee?.street_address;
            this.formState["State"] = state;
            this.formState["City or Town"] = this.employee?.city;
            this.formState["ZIP Code"] = +this.employee?.zipcode;
            this.formState["Date of Birth mmddyyyy"] = this.employee?.birthdate
              ? moment(this.employee?.birthdate, "YYYY-MM-DD")
              : null;
            this.formState["US Social Security Number"] =
              this.employee?.social_security_no;
            this.formState["Employees E-mail Address"] = this.employee?.email;
            this.formState["Telephone Number"] = this.employee?.contact_no;
          }
          this.formState["Today's Date mmddyyy"] = moment(
            this.employee?.hire_date
          ).format("MM/DD/YYYY");
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        });
    },

    clear() {
      this.formState.signature = null;
      this.formState.signatured_at = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.formState.signature = data;
      this.formState.signatured_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      return Promise.resolve();
    },
  },
  setup() {
    const { width } = useWindowSize();

    return {
      isMobile: computed(() => width.value < 500),
    };
  },
};
</script>
