<template>
  <a-layout-content>
    <page-header>
      <template #title> Activity Logs </template>
    </page-header>

    <a-card size="small">
      <template #title>
        <a-row type="flex" :gutter="[16, 0]">
          <a-col flex="auto">
            <a-range-picker
              v-model:value="extraFilters.date_range"
              :format="$constants.datepickerFormat"
              :placeholder="[
                $constants.datePickerPlaceholder,
                $constants.datePickerPlaceholder,
              ]"
              @change="refresh()"
            />
          </a-col>
        </a-row>
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #os="{ record }">
          <template v-if="record.browser.includes('Windows')">
            <i class="ti ti-brand-windows ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Linux')">
            <i class="ti ti-brand-ubuntu ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Mac OS')">
            <i class="ti ti-brand-apple ti-lg"></i>
          </template>
        </template>

        <template #browser="{ record }">
          <template v-if="record.browser.includes('Edg')">
            <i class="ti ti-brand-edge ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Chrome')">
            <i class="ti ti-brand-chrome ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Firefox')">
            <i class="ti ti-brand-firefox ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Safari')">
            <i class="ti ti-brand-safari ti-lg"></i>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment-timezone";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.activityLogDatatableUrl,
      columns: [
        {
          title: "Module",
          dataIndex: "module",
          key: "module",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.module
            ? [this.$route.query?.module]
            : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => text ?? "N/A",
          defaultFilteredValue: this.$route.query?.action
            ? [this.$route.query?.action]
            : undefined,
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => text ?? "N/A",
          defaultFilteredValue: this.$route.query?.message
            ? [this.$route.query?.message]
            : undefined,
        },
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record?.employee?.full_name ?? "N/A",
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "IP Address",
          dataIndex: "ip_address",
          key: "ip_address",
        },
        {
          title: "Location",
          dataIndex: "country",
          key: "country",
          customRender: ({ record }) =>
            record.ip_info?.city
              ? `${record.ip_info.city}, ${record.ip_info.region}, ${record.ip_info.country}`
              : "N/A",
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: this.$route.query?.sortOrder
            ? this.$route.query.sortOrder
            : "descend",
          customRender: ({ text }) => `${this.$customDate.mdyhis(text)}`,
        },
      ],
      defaultFilterParams: {
        module: this.$route.query?.module
          ? [this.$route.query?.module]
          : undefined,
        action: this.$route.query?.action
          ? [this.$route.query?.action]
          : undefined,
        message: this.$route.query?.message
          ? [this.$route.query?.message]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : "created_at",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : "descend",
      },
    };
  },

  mounted() {
    if (this.$route?.query?.date_range) {
      let dateRange = this.$route.query.date_range;
      this.extraFilters.date_range = [
        moment(parseInt(dateRange[0])),
        moment(parseInt(dateRange[1])),
      ];
    }
  },

  methods: {
    //
  },
};
</script>
