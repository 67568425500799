<template>
  <div>
    <a-descriptions :column="2" size="small" bordered>
      <a-descriptions-item :span="2" label="Employee Name">
        <a-space>
          {{ record.employee?.full_name ?? "N/A" }}
          <a-tag
            :color="
              $comman.getValueFromObject(
                record.employee,
                'employment_type',
                $constants.employment_types,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record.employee,
                "employment_type",
                $constants.employment_types
              )
            }}
          </a-tag>
        </a-space>
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Hospital Name">
        {{ record.hospital?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Shift Name">
        {{ record.shift_type?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Start Date & Time"
        >{{ $customDate.mdy(record.start_date) }}
        {{ $customDate.hi(record.start_time) }}
      </a-descriptions-item>

      <a-descriptions-item label="End Date & Time">
        {{ $customDate.mdy(record.end_date) }}
        {{ $customDate.hi(record.end_time) }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Total Time (In Hours)">
        {{ parseFloat(record.total_time).toFixed(2) }}
      </a-descriptions-item>

      <template
        v-if="$constants.timeTrackingOnCall.includes(record.shift_type_id)"
      >
        <a-descriptions-item
          :span="2"
          label="Was on-call coverage also provided on the same day?"
        >
          {{ record.was_on_call == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <template v-if="record.was_on_call">
          <a-descriptions-item label="Total On-Call Time (In Hours)">
            {{ parseFloat(record.total_on_call_time).toFixed(2) }}
          </a-descriptions-item>

          <a-descriptions-item label="On-Call End Date & Time">
            {{ $customDate.mdy(record.on_call_end_date) }}
            {{ $customDate.hi(record.on_call_end_time) }}
          </a-descriptions-item>
        </template>
      </template>

      <template v-if="record.was_travel">
        <a-descriptions-item
          :span="2"
          label="Is travel compensation available for hospital?"
        >
          {{ record.was_travel == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <a-descriptions-item label="Home to Hospital">
          {{ $customDate.hi(record.home_departure) }} -
          {{ $customDate.hi(record.hospital_arrival) }}
        </a-descriptions-item>

        <a-descriptions-item label="Hospital to Home">
          {{ $customDate.hi(record.hospital_departure) }} -
          {{ $customDate.hi(record.home_arrival) }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="Total Travel Time (In Hours)">
          {{ parseFloat(record.total_travel_time).toFixed(2) }}
        </a-descriptions-item>
      </template>

      <template v-if="record.shift_type_id == 4">
        <a-descriptions-item :span="2" label="Number of patients on ECMO">
          {{ record.no_of_ecmo ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="MR no(s) of patients on ECMO">
          {{ record.mr_ecmo ?? "N/A" }}
        </a-descriptions-item>
      </template>

      <a-descriptions-item :span="2" label="Notes">
        {{ record.notes ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>

    <template v-if="record.status != 1">
      <a-divider orientation="left">Status Info</a-divider>

      <a-descriptions :column="2" size="small" bordered>
        <a-descriptions-item
          :label="record.status == 3 ? 'Rejected By' : 'Approved By'"
        >
          {{ record.approver?.full_name ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item
          :label="record.status == 3 ? 'Rejected At' : 'Approved At'"
        >
          {{ $customDate.ll(record.approved_at) }}
        </a-descriptions-item>

        <a-descriptions-item label="Remarks" :span="2">
          {{ record.remarks ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item
          v-if="record.status == 2"
          label="Quickbooks Class"
          :span="2"
        >
          {{
            record.quickbooks_class_info?.Name ??
            (record.hospital?.quickbooks_class_info
              ? JSON.parse(record.hospital.quickbooks_class_info).Name
              : "N/A") ??
            "N/A"
          }}
        </a-descriptions-item>
      </a-descriptions>

      <template v-if="record.status != 3">
        <a-divider orientation="left">Payment Info</a-divider>
        <a-table
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="false"
          size="small"
          :scroll="scroll"
        >
        </a-table>

        <a-descriptions bordered :column="1" size="small">
          <a-descriptions-item
            label="Total:"
            :contentStyle="{ width: '125px', paddingLeft: '8px' }"
            :labelStyle="{ textAlign: 'right', fontWeight: 'bold' }"
          >
            <span class="text-bold">
              {{ $comman.withCurrency(record.total_amount) }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </template>

    <template v-else>
      <a-divider orientation="left">Update Status</a-divider>

      <a-form
        name="time_tracking_update_status_form"
        ref="time_tracking_update_status_form"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="model.status == 2 ? 12 : 24">
            <a-form-item
              ref="status"
              label="Status"
              name="status"
              :validateStatus="validate('status', 'status')"
              :help="validate('status', 'msg')"
            >
              <a-select
                v-model:value="model.status"
                placeholder="Select status"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="status in statuses"
                  :key="status.value"
                  :label="status.text"
                  :value="status.value"
                >
                  {{ status.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-if="model.status == 2" :span="12">
            <a-form-item
              ref="quickbooks_class_id"
              label="QuickBooks Class"
              name="quickbooks_class_id"
            >
              <a-select
                v-model:value="model.quickbooks_class_id"
                placeholder="Select quickbooks class"
                optionFilterProp="label"
                allowClear
                showSearch
              >
                <a-select-option
                  v-for="option in computedClasses"
                  :key="option.Id"
                  :label="option.Name"
                  :value="option.Id"
                >
                  {{ option.Name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <template v-if="model.status == 2">
            <template v-for="(payment, index) in model.payments" :key="index">
              <a-col :span="record.employee.employment_type == 1 ? 12 : 8">
                <a-form-item
                  :label="payment.label"
                  :name="['payments', index, 'job_id']"
                  :rules="{
                    required: true,
                    message: 'field is required!',
                    trigger: 'change',
                    type: 'number',
                  }"
                >
                  <a-select
                    v-model:value="payment.job_id"
                    placeholder="Select job"
                    show-search
                    optionFilterProp="label"
                    @change="paymentCalculate('job_id')"
                  >
                    <a-select-option
                      v-for="option in employeeJobs"
                      :key="option.id"
                      :label="option.title"
                      :value="option.id"
                    >
                      {{ option.title }} ({{
                        $comman.withCurrency(option.rate)
                      }}
                      -
                      {{
                        $comman.getValueFromObject(
                          option,
                          "payment_unit",
                          $constants.employeeJobPaymentUnit
                        )
                      }})
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-form-item label="Hours" :name="['payments', index, 'hours']">
                  <a-input
                    v-model:value="payment.hours"
                    placeholder="Enter remarks here..."
                    disabled
                  />
                </a-form-item>
              </a-col>

              <a-col :span="4" v-if="record.employee.employment_type == 2">
                <a-form-item
                  label="Rate"
                  :name="['payments', index, 'rate']"
                  @change="paymentCalculateUsingRate"
                >
                  <a-input-number
                    v-model:value="payment.rate"
                    placeholder="Enter Rate"
                    :min="0"
                    :step="0.01"
                    :formatter="$comman.currencyformatter"
                    :parser="$comman.currencyParser"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-form-item
                  label="Additional Pay"
                  :name="['payments', index, 'additional_amount']"
                  @change="paymentCalculate('additional_amount')"
                >
                  <a-input-number
                    v-model:value="payment.additional_amount"
                    placeholder="Enter Amount "
                    :min="0"
                    :step="0.01"
                    :formatter="$comman.currencyformatter"
                    :parser="$comman.currencyParser"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-form-item
                  label="Amount"
                  :name="['payments', index, 'total']"
                >
                  <a-input-number
                    v-model:value="payment.total"
                    placeholder="Enter Amount"
                    :min="0"
                    :step="0.01"
                    :formatter="$comman.currencyformatter"
                    :parser="$comman.currencyParser"
                    disabled
                  />
                </a-form-item>
              </a-col>
            </template>
          </template>

          <a-col :span="24">
            <a-form-item
              ref="remarks"
              label="Remarks"
              name="remarks"
              :validateStatus="validate('remarks', 'status')"
              :help="validate('remarks', 'msg')"
            >
              <a-textarea
                v-model:value="model.remarks"
                placeholder="Enter remarks here..."
                :auto-size="{ minRows: 3, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <side-drawer-buttons name="Update Status" />
      </a-form>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],

  data() {
    return {
      model: {},
      statuses: [
        {
          text: "Approve",
          value: 2,
        },
        {
          text: "Reject",
          value: 3,
        },
      ],
      rules: {
        status: [
          {
            required: true,
            message: "Status is required!",
            type: "number",
          },
        ],
        quickbooks_class_id: {
          required: true,
          message: "This is required!",
        },
      },
      employeeJobs: [],
      columns: [
        {
          title: "Job Name",
          dataIndex: "job_name",
          key: "job_name",
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
        },
        {
          title: "Subtotal",
          dataIndex: "total",
          key: "total",
        },
        {
          title: "Additional Amount",
          dataIndex: "additional_amount",
          key: "additional_amount",
        },
        {
          title: "Total Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          width: 125,
        },
      ],
      scroll: { x: true, y: 0 },
      classes: [],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
    dataSource() {
      if (this.record.payments) {
        return this.record.payments.map((item) => {
          return {
            id: item.id,
            job_name: `${item.json?.employee_job?.title ?? ""} - (${
              item.json?.employee_job?.payment_unit ?? ""
            })`,
            rate: this.$comman.withCurrency(item.rate),
            hours: item.hour,
            total: this.$comman.withCurrency(item.amount),
            additional_amount: this.$comman.withCurrency(
              item.additional_amount
            ),
            total_amount: this.$comman.withCurrency(item.total_amount),
          };
        });
      }
      return [];
    },

    quickbooksClassId() {
      return this.record?.hospital?.quickbooks_class_id ?? null;
    },

    quickbooksClassInfo() {
      return this.record?.hospital?.quickbooks_class_info
        ? JSON.parse(this.record.hospital.quickbooks_class_info)
        : null;
    },

    computedClasses() {
      if (this.quickbooksClassId) {
        return this.classes.filter(
          (e) =>
            e.Id == this.quickbooksClassId ||
            (e.ParentRef?.value && e.ParentRef.value == this.quickbooksClassId)
        );
      }
      return [];
    },
  },

  async mounted() {
    this.getmodel();
    if (this.record.status == 1) {
      this.getQuickbooksData();
    }
    commonService
      .get(this.$constants.getEmployeeJobs, {
        employee_id: this.record.employee_id,
      })
      .then((res) => {
        this.employeeJobs = res.data;
      });
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getmodel() {
      let payments = [
        {
          label: "Primary Shift",
          job_id: undefined,
          hours: this.record.total_time,
          rate: null,
          amount: 0,
          additional_amount: 0,
          total: 0,
          payment_unit: undefined,
        },
      ];

      if (this.record.was_on_call) {
        payments.push({
          label: "On Call Shift",
          job_id: undefined,
          hours: this.record.total_on_call_time,
          rate: null,
          amount: 0,
          additional_amount: 0,
          total: 0,
          payment_unit: undefined,
        });
      }

      if (this.record.was_travel) {
        payments.push({
          label: "Travel",
          job_id: undefined,
          hours: this.record.total_travel_time,
          rate: null,
          amount: 0,
          additional_amount: 0,
          total: 0,
          payment_unit: undefined,
        });
      }

      this.model = {
        id: this.record.id,
        status: null,
        remarks: null,
        payments: payments,
        quickbooks_class_id: this.quickbooksClassId,
        quickbooks_class_info: this.quickbooksClassInfo,
      };
    },

    handleFinish() {
      this.model.quickbooks_class_info = this.computedClasses.find(
        (e) => e.Id == this.model.quickbooks_class_id
      );

      this.loadingStart();
      commonService
        .store(this.$constants.adminEmployeeTimeTrackChangeStatusUrl, {
          ...this.model,
          payments: this.model.status == 2 ? this.model.payments : [],
        })
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    paymentCalculate(type) {
      this.model.payments = this.model.payments.map((item) => {
        if (item.job_id) {
          let job = this.employeeJobs.find((e) => item.job_id == e.id);
          if (job) {
            if (type == "additional_amount") {
              job.rate = item.rate;
            }
            item.payment_unit = job.payment_unit;
            item.rate = ["Year", "Year+Overtime"].includes(item.payment_unit)
              ? 0
              : job.rate;
            item.amount = item.rate * item.hours;
            item.total = item.amount + item.additional_amount;
          }
        }
        return item;
      });
    },

    paymentCalculateUsingRate() {
      this.model.payments = this.model.payments.map((item) => {
        if (item.job_id) {
          item.amount = ["Year", "Year+Overtime"].includes(item.payment_unit)
            ? 0
            : item.rate * item.hours;
          item.total = item.amount + item.additional_amount;
        }
        return item;
      });
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, {
          names: ["classes"],
        })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "classes") {
                this.classes = JSON.parse(v.data);
              }
            });
          }
        });
    },
  },
};
</script>
