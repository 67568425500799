<template>
  <a-card title="AR Aging Summary (YTD)" size="small">
    <a-table
      :columns="columns"
      :rowKey="(record, index) => index"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      size="small"
      :scroll="{ x: true, y: 0 }"
    />
  </a-card>
</template>

<script>
export default {
  name: "ar-aging-summary",

  props: ["data", "loading"],

  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
          customRender: () => "Amount",
        },
        {
          title: "Current",
          dataIndex: "current",
          key: "current",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "1-30 Days",
          dataIndex: "1_30_days",
          key: "1_30_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "31-60 Days",
          dataIndex: "31_60_days",
          key: "31_60_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "61-90 Days",
          dataIndex: "61_90_days",
          key: "61_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Over 90 Days",
          dataIndex: "over_90_days",
          key: "over_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
      ],
    };
  },

  computed: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
