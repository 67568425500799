<template>
  <a-descriptions
    title=""
    bordered
    size="small"
    :column="1"
    :labelStyle="{ width: '150px' }"
  >
    <a-descriptions-item label="Name">{{ record.name }}</a-descriptions-item>
    <a-descriptions-item label="Created By">
      {{ record.creator.full_name }}
    </a-descriptions-item>
  </a-descriptions>

  <a-table
    class="mt-sm-2"
    :dataSource="record.items"
    :rowKey="(record, index) => index"
    size="small"
    :columns="columns"
    :pagination="false"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  emits: ["callback"],

  data() {
    return {
      columns: [
        {
          title: "Product",
          dataIndex: "product.name",
          customRender: ({ record }) =>
            `${record.product.name} | ${record.product.sku}`,
        },
        {
          title: "Qty",
          dataIndex: "qty",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
