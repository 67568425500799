<template>
  <a-form
    name="certificate_form"
    ref="certificate_form"
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :span="24" v-if="record.certification_type.is_expirable">
        <a-form-item
          ref="expiry_date"
          label="Expiration Date"
          name="expiry_date"
        >
          <a-date-picker
            v-model:value="formState.expiry_date"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :disabled-date="disabledDate"
            :allow-clear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24" v-if="record.certification_type.has_state">
        <a-form-item ref="state" label="State" name="state">
          <a-select
            v-model:value="formState.state"
            placeholder="Select One..."
            optionFilterProp="label"
            showSearch
          >
            <a-select-option
              v-for="state in $constants.states"
              :key="state.name"
              :label="state.name"
              :value="state.name"
            >
              {{ state.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          ref="attachment"
          label="Attachment"
          name="attachment"
          :validateStatus="attachmentError.hasError"
          :help="attachmentError.errorMessage"
          @change="
            () =>
              (attachmentError.hasError = '') &&
              (attachmentError.errorMessage = '')
          "
        >
          <a-upload
            :file-list="formState.attachment"
            :before-upload="
              (_, fileList) =>
                beforeUpload(
                  fileList,
                  record.certification_type.filetype_supported
                )
            "
            :multiple="
              record.certification_type.attachment_limit == 1 ? true : false
            "
            :accept="record.certification_type.filetype_supported"
            :remove="onFileRemove"
          >
            <div>
              <a-button>
                <UploadOutlined />
                Upload
              </a-button>
            </div>
          </a-upload>
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Update" />
  </a-form>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },

  emits: ["callback"],

  data() {
    return {
      formState: {},
      attachmentError: {
        hasError: "",
        errorMessage: "",
      },
      rules: {
        attachment: [
          {
            required: true,
            type: "array",
            message: "Attachment is required",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    this.getFormState();
    if (this.record.certification_type.is_expirable == 1) {
      this.rules.expiry_date = [
        {
          required: true,
          type: "date",
          message: "expiration date is required!",
        },
      ];
    }
    if (this.record.certification_type.has_state == 1) {
      this.rules.state = [
        {
          required: true,
          message: "state field is required!",
        },
      ];
    }
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    ...mapActions("auth", ["login"]),

    getFormState() {
      this.formState = {
        expiry_date: this.record.expiry_date
          ? moment(this.record.expiry_date)
          : null,
        state: this.record.state,
        attachment: this.record.attachments.map((v) => ({
          id: v.id ?? v.uid,
          uid: v.file_name ?? v.uid,
          name: v.file_name_with_ext ?? v.name,
          url: v.full_file_path ?? v.url,
        })),
      };
    },

    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    onFileRemove(file) {
      const items = this.formState.attachment;
      const idx = items.findIndex((v) => v.uid === file.uid);
      if (idx > -1) items.splice(idx, 1);
    },

    beforeUpload(fileList, types) {
      const acceptedTypes = types.split(",");

      this.formState.attachment = fileList.filter((file) => {
        const isCorrectType = acceptedTypes.includes(file.type);
        const isCorrectSize = file.size / 1024 / 1024 < 5;

        if (!isCorrectType) {
          this.attachmentError.hasError = "error";
          this.attachmentError.errorMessage = `File type of ${file.type} is not supported.`;
          return false;
        }
        if (!isCorrectSize) {
          this.attachmentError.hasError = "error";
          this.attachmentError.errorMessage = `File size of ${file.name} is too large. Max: 5MB`;
          return false;
        }
        return true;
      });
      return false;
    },

    onSubmit() {
      if (
        this.formState.attachment.length == 0 ||
        this.formState.attachment == ""
      ) {
        this.$refs.certificate_form.fields.forEach((v, i) => {
          if (v.fieldName == "attachment") {
            v.validateState = "error";
            v.validateMessage = "Attachment is required";
          }
        });
      } else {
        const data = new FormData();
        const form = this.formState;

        data.append("id", this.record.id);
        if (this.record.certification_type.is_expirable == 1) {
          data.append(
            "expiry_date",
            form.expiry_date ? form.expiry_date.format("DD-MM-YYYY") : null
          );
        }
        if (this.record.certification_type.has_state == 1) {
          data.append("state", form.state);
        }

        form.attachment.forEach((file) => {
          if ("id" in file) {
            data.append(`old_attachments[]`, file.id);
          } else {
            data.append(`attachment[]`, file);
          }
        });

        this.loadingStart();
        commonService
          .store(this.$constants.credentialApprovalsUpdateUrl, data)
          .then(async (res) => {
            this.$message.success(res.message);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.loadingStop();
            this.close();
            this.$emit("callback");
          });
      }
    },
  },
};
</script>
