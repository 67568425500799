<template>
  <div>
    <a-spin tip="Loading..." :spinning="loading">
      <a-card title="Pay Information">
        <a-form
          ref="payiRef"
          :layout="formLayout"
          :model="model"
          :rules="rules"
          @finish="handleFinish"
        >
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="classification"
                label="Classification"
                name="classification"
              >
                <a-select
                  v-model:value="model.classification"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="classification in $constants.classificationOption"
                    :key="classification.value"
                    :label="classification.text"
                    :value="classification.value"
                  >
                    {{ classification.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="temporaryIndicator"
                label="Temporary Employee?"
                name="temporaryIndicator"
              >
                <a-radio-group v-model:value="model.temporaryIndicator">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}TemporaryIndicator`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="isExemptFromOvertime"
                label="Is the employee exempt from overtime?"
                name="isExemptFromOvertime"
              >
                <a-radio-group v-model:value="model.isExemptFromOvertime">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}IsExemptFromOvertime`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="isPaidTips"
                label="Is the employee paid via tips?"
                name="isPaidTips"
              >
                <a-radio-group v-model:value="model.isPaidTips">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}IsPaidTips`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="isPaidPiecework"
                label="Is the employee paid via piece work?"
                name="isPaidPiecework"
              >
                <a-radio-group v-model:value="model.isPaidPiecework">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}IsPaidPiecework`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="employeePaid"
                label="Employee is paid (check all that apply)?"
                name="employeePaid"
              >
                <a-checkbox-group v-model:value="model.employeePaid">
                  <a-row>
                    <a-col
                      :span="24"
                      v-for="(v, i) in $constants.employeePaidOption"
                      :key="`${i}EmployeePaid`"
                    >
                      <a-checkbox :value="v.value">
                        {{ v.text }}
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="isAllowanceProvided"
                label="Do you provide allowances?"
                name="isAllowanceProvided"
              >
                <a-radio-group v-model:value="model.isAllowanceProvided">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}IsAllowanceProvided`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider type="horizontal" />
          <p>
            Upon completion of this page, upi will be sent a confimation email.
            Your new employee, if an email address is provided, will receive an
            instructional email to create an account to begin their new hire
            enrollment.
          </p>
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="adminEmail"
                label="Administrator Email Address"
                name="adminEmail"
              >
                <a-input
                  v-model:value="model.adminEmail"
                  placeholder="Enter administrator email address here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="16"></a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="employeeEmail"
                label="Employee Email Address"
                name="employeeEmail"
              >
                <a-input
                  v-model:value="model.employeeEmail"
                  placeholder="Enter employee email address here..."
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-button class="mr-xs-1 width-125" @click="resetPayiForm">
            CANCEL
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="back">
            BACK
          </a-button>
          <a-button type="danger" class="width-125" html-type="submit">
            SUBMIT
          </a-button>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { commonService } from "../../services";

export default {
  props: ["rules", "model", "personId"],
  data() {
    return {
      formLayout: "vertical",
      loading: false,
    };
  },
  mounted() {
    this.$refs.classification.$el
      .getElementsByClassName("ant-select-selection-search-input")[0]
      .focus();
  },
  methods: {
    handleFinish(value) {
      this.handleSubmit(value);
    },
    resetPayiForm() {
      Object.keys(this.model).forEach((el) => {
        if (el == "classification") {
          this.model[el] = null;
        } else if (el == "employeePaid") {
          this.model[el] = [];
        } else {
          this.model[el] = "";
        }
      });
    },
    back() {
      this.$emit("callback", false, -1, this.personId);
    },
    handleSubmit(value) {
      this.loading = true;
      value.onboarding_person_id = this.personId;
      commonService
        .store(this.$constants.onboardingPayUrl, value)
        .then((res) => {
          this.loading = false;
          this.$message.success(res.message);
          this.$emit("callback", true);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped>
.width-125 {
  width: 125px;
}
@media (max-width: 767px) {
  .width-125 {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
