import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";
import hasRoles from "./middleware/hasRoles";

export default [
  {
    path: "/master-handbooks",
    name: "masterHandbooks",
    component: route("masterHandbook/index"),
    meta: {
      title: "Master Handbook",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1
    },
  },
  {
    path: "/employee-handbook",
    name: "employeeHandbook",
    component: route("masterHandbook/show"),
    meta: {
      title: "Employee Handbook",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
