<template>
  <div>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0"> </a-breadcrumb>
      <div
        :style="{
          padding: '24px',
          background: '#fff',
          minHeight: '360px',
          margin: '16px 0',
        }"
      >
        <a-result status="404" title="404" sub-title="Sorry, Page Not Found.">
          <template #extra>
            <a-button
              @click="$router.go(-1)"
              type="primary"
              style="margin-top: 10px"
            >
              Back
            </a-button>
          </template>
        </a-result>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
export default {
  //
};
</script>
