import { isMoment } from "moment-timezone";
import xFilterDateRangeDropdown from "../components/table/filterDateRangeDropdown.vue";
import xFilterIcon from "../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../components/table/filterInputSearchDropdown.vue";
import { tableService } from "../services/table.service";

const countPerPage = 30;

export default {
  components: {
    xFilterIcon,
    xFilterInputSearchDropdown,
    xFilterDateRangeDropdown
  },

  data() {
    return {
      url: null,
      columns: [],
      datatableUrl: null,

      // Optionals
      statusChangeUrl: null,
      datatableLoading: true,
      scroll: { x: true, y: 0 },
      dataSource: [],
      tabList: [],
      originalResponse: null,
      activeTabKey: null,
      pageSize: this.$route.query && this.$route.query.pageSize ? Number(this.$route?.query?.pageSize) : countPerPage,
      pagination: {
        current: this.$route.query && this.$route.query.page ? Number(this.$route?.query?.page) : 1,
        size: "normal",
        pageSize: this.$route.query && this.$route.query.pageSize ? Number(this.$route?.query?.pageSize) : countPerPage,
        total: 0,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },
      defaultFilterParams: null,
      filterParams: this.$route?.query ?? {},
      modifyUrlQuery: true,
      extraFilters: {},
    };
  },

  mounted() {
    if (this.activeTabKey) {
      this.filterParams = { ...this.filterParams, status: Number(this.activeTabKey) };
    }

    if (this.pageSize && this.pagination.pageSize == 0) {
      this.pagination.pageSize = this.pageSize;
      this.filterParams = { ...this.filterParams, pageSize: this.pageSize };
    }

    if (this.defaultFilterParams) {
      Object.keys(this.defaultFilterParams).forEach(k => {
        if (['sortField', 'sortOrder'].includes(k)) {
          this.filterParams[k] = this.defaultFilterParams[k];
        } else {
          // if (this.filterParams[k]) {
          this.filterParams[k] = this.defaultFilterParams[k];
          // }
        }
      })
    }

    this.refresh();
  },

  watch: {
    activeTabKey() {
      this.pagination = { ...this.pagination, current: 1 };
      this.filterParams.status = Number(this.activeTabKey);
      this.filterParams.page = 1;
      this.refresh();
    },

    filterParams: {
      handler: function (val) {
        if (this.modifyUrlQuery) {
          this.$router.replace({ query: val })
        }
      },
      deep: true
    }
  },

  methods: {
    handleTableChange(pagination, filters, sorter) {
      let fil = {};
      Object.entries(filters).filter(([k, v]) => v[0] != null).forEach(item => {
        if (Array.isArray(item[1]) && isMoment(item[1][0][0])) {
          if (item[1][0].length == 2) {
            fil[item[0]] = [`${item[1][0][0].format('YYYY-MM-DD')} - ${item[1][0][1].format('YYYY-MM-DD')}`];
          } else {
            fil[item[0]] = [item[1][0][0].format('YYYY-MM-DD')];
          }
        } else {
          fil[item[0]] = item[1];
        }
      });

      this.pagination = pagination;
      this.filterParams = {
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortField: sorter.order ? sorter.field : undefined,
        sortOrder: sorter.order,
        ...fil,
      };
      if (this.activeTabKey) {
        this.filterParams.status = Number(this.activeTabKey);
      }
      this.refresh();
    },

    refresh(updateParams = {}, filterParamsWithoutChangeUrlQueryParams = {}) {
      this.filterParams = {
        ...this.filterParams,
        ...this.extraFilters,
        ...updateParams,
      };

      this.datatableLoading = true;

      tableService
        .get({ ...this.filterParams, ...filterParamsWithoutChangeUrlQueryParams }, this.datatableUrl, this.pageSize)
        .then((response) => {
          this.pagination = { ...this.pagination, total: response.data.total };
          if (response.counts) {
            this.tabItemCounts(response.counts);
          }
          this.dataSource = response.data.data;
          this.originalResponse = response;
          this.datatableLoading = false;
        })
        .catch((error) => {
          this.$message.error("Something went wrong.");
          console.log(error);
        });
    },

    tabItemCounts(counts) {
      if (this.tabList) {
        this.tabList.forEach((v, i) => {
          v.counts = counts[v.status] ?? 0;
        });
      }
    },

    handleStatusChangeEvent(val) {
      tableService
        .statusChange(this.statusChangeUrl, val)
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
    },
  },
};