<template>
  <a-layout-content>
    <page-header>
      <template #title> Bills </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-form :model="model" layout="vertical">
      <a-card size="small" hoverable :loading="cardLoading">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item ref="date_range" name="date_range">
              <a-select
                v-model:value="model.date_range"
                placeholder="payroll date range"
                show-search
                optionFilterProp="label"
                allowClear
              >
                <a-select-option
                  v-for="option in billPayrollDates"
                  :key="option"
                  :label="option"
                  :value="option"
                >
                  {{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-space>
              <a-button
                :disabled="!model.date_range"
                type="primary"
                html-type="button"
                :loading="allBillExcelExporting"
                @click="allBillExcelExport"
              >
                Export Excel
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-card>
    </a-form>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-row class="mb-sm-1" justify="space-between">
        <a-space v-if="activeTabKey == 0">
          <a-button
            size="small"
            type="primary"
            ghost
            htmlType="button"
            :disabled="tabList[0].counts == 0"
            @click="updateTimesheetFromQuickbooks"
            :loading="updateTimesheetFromQuickbooksLoading"
          >
            <a-space>
              <i class="ti ti-refresh ti-1x"></i>
              Update bills from QuickBooks
            </a-space>
          </a-button>
        </a-space>
      </a-row>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #bill_number="{ record }">
          <a-button
            type="link"
            size="small"
            class="pl-sm-0"
            @click="handleShowBillEvent(record)"
          >
            {{ record.bill_number }}
          </a-button>
        </template>

        <template #action="{ record }">
          <a-tooltip>
            <template #title>Export Excel</template>
            <a-button
              type="link"
              @click="exportExcel(record)"
              :loading="excelExporting(record)"
              style="color: #1d6f42"
            >
              <template #icon>
                <i class="far fa-file-excel" style="font-size: 18px"></i>
              </template>
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminBillDatatableUrl,
      columns: [
        {
          title: "QuickBooks Bill No",
          dataIndex: "bill_number",
          key: "bill_number",
          sorter: true,
          slots: {
            customRender: "bill_number",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.bill_number
            ? [this.$route.query?.bill_number]
            : undefined,
        },
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_full_name
            ? [this.$route.query?.employee_full_name]
            : undefined,
        },
        {
          title: "Bill Amount",
          dataIndex: "bill_amount",
          key: "bill_amount",
          customRender: ({ text }) => this.$comman.withCurrency(text),
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.bill_amount
            ? [this.$route.query?.bill_amount]
            : undefined,
        },
        {
          title: "Payroll Dates",
          dataIndex: "payroll_dates",
          key: "payroll_dates",
          customRender: ({ record }) =>
            `${this.$customDate.mdy(
              record.start_date
            )} - ${this.$customDate.mdy(record.end_date)}`,
        },
        {
          title: "QuickBooks Payment Id",
          dataIndex: "quickbooks_payment_ids",
          key: "quickbooks_payment_ids",
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            customRender: "quickbooks_payment_ids",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.quickbooks_payment_ids
            ? [this.$route.query?.quickbooks_payment_ids]
            : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: [
        {
          key: "0",
          counts: 0,
          text: "Pending",
          status: 0,
          slots: { tab: "customRender" },
        },
        {
          key: "1",
          counts: 0,
          text: "Paid",
          status: 1,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        bill_number: this.$route.query?.bill_number
          ? [this.$route.query?.bill_number]
          : undefined,
        employee_full_name: this.$route.query?.employee_full_name
          ? [this.$route.query?.employee_full_name]
          : undefined,
        bill_amount: this.$route.query?.bill_amount
          ? [this.$route.query?.bill_amount]
          : undefined,
        quickbooks_payment_ids: this.$route.query?.quickbooks_payment_ids
          ? [this.$route.query?.quickbooks_payment_ids]
          : undefined,
      },

      billPayrollDates: [],
      updateTimesheetFromQuickbooksLoading: false,
      excelExports: [],

      model: {
        date_range: null,
      },
      cardLoading: false,
      allBillExcelExporting: false,
    };
  },

  mounted() {
    commonService
      .get(this.$constants.adminBillPayrollDatesUrl)
      .then((res) => (this.billPayrollDates = res.data));
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    updateTimesheetFromQuickbooks() {
      this.updateTimesheetFromQuickbooksLoading = true;

      commonService
        .store(this.$constants.updateTimesheetFromQuickbooksUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.updateTimesheetFromQuickbooksLoading = false;
          this.refresh();
        });
    },

    handleShowBillEvent(record) {
      this.open({
        title: "Show Bill Details",
        path: "admin.bill.show",
        record: record,
      });
    },

    excelExporting(bill) {
      return this.excelExports.includes(bill.id);
    },

    exportExcel(rec) {
      this.excelExports.push(rec.id);
      commonService
        .downloadFile(
          this.$constants.adminBillExportExcelUrl,
          { bill_id: rec.id },
          `Bill-${rec.quickbooks_bill_id}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.excelExports = this.excelExports.filter((e) => e != rec.id);
        });
    },

    allBillExcelExport() {
      this.allBillExcelExporting = true;
      commonService
        .downloadFile(
          this.$constants.adminAllBillsExportExcelUrl,
          this.model,
          `Bills-${this.model.date_range}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.allBillExcelExporting = false;
        });
    },
  },
};
</script>
