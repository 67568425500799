<template>
  <div>
    <a-layout-content>
      <a-page-header
        class="page-header"
        :title="title"
        style="margin-bottom: 24px; background-color: #fff"
        @back="() => $router.go(-1)"
      >
        <template #extra>
          <!--  -->
        </template>
      </a-page-header>

      <a-card :loading="loading" size="small">
        <a-form
          :model="model"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit"
        >
          <a-row :gutter="[20, 0]">
            <a-col :span="12">
              <a-form-item ref="name" label="Name" name="name">
                <a-input
                  v-model:value="model.name"
                  placeholder="Enter name here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item name="date">
                <template #label>
                  <a-space>
                    Date
                    <a-tooltip>
                      <template #title>
                        Show policy document to employee if their hiring date is
                        on or after this date
                      </template>
                      <a-button type="link" size="small">
                        <i class="ti ti-info-circle ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-space>
                </template>
                <a-date-picker
                  v-model:value="model.date"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="12">
              <a-form-item ref="type" label="Type" name="type">
                <a-radio-group v-model:value="model.type">
                  <a-radio
                    v-for="(v, i) in $constants.onboardingDocTypes"
                    :key="`${i}TemporaryIndicator`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :sm="12">
              <a-form-item
                ref="is_for_all_position"
                label="Does this apply to all positions?"
                name="is_for_all_position"
              >
                <a-radio-group v-model:value="model.is_for_all_position">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}_is_for_all_position`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                label="Policy Group"
                name="policy_group_id"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="model.policy_group_id"
                  placeholder="Select Policy Group"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="option in policyGroups"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="12">
              <a-form-item label="Employment Type" name="employment_type">
                <a-radio-group v-model:value="model.employment_type">
                  <a-radio
                    v-for="(v, i) in $constants.policyRuleEmploymentTypes"
                    :key="`${i}_employment_type`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :sm="24" v-if="model.is_for_all_position == 'No'">
              <a-form-item
                ref="employee_position_ids"
                label="Employee Positions"
                name="employee_position_ids"
              >
                <a-select
                  v-model:value="model.employee_position_ids"
                  placeholder="Select Any..."
                  optionFilterProp="label"
                  showSearch
                  mode="multiple"
                >
                  <a-select-option
                    v-for="positon in employeePositions"
                    :key="positon.id"
                    :label="positon.name"
                    :value="positon.id"
                  >
                    {{ positon.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="acknowledgement_message"
                label="Acknowledgement/Signature Message"
                name="acknowledgement_message"
              >
                <a-textarea
                  v-model:value="model.acknowledgement_message"
                  placeholder="Enter Acknowledgement Message/Signature here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="description"
                label="Description"
                name="description"
              >
                <ckeditor
                  :editor="editor"
                  v-model="model.description"
                  :config="editorConfig"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24">
              <a-form-item
                label="Issue Certificate?"
                name="is_issue_certificate"
              >
                <a-radio-group v-model:value="model.is_issue_certificate">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}_is_issue_certificate`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="24" v-if="model.is_issue_certificate === 'Yes'">
              <a-form-item
                label="Certificate Contents"
                name="certificate_content"
              >
                <a-textarea
                  v-model:value="model.certificate_content"
                  placeholder="Enter certificate contents here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="attachment_file"
                label="Attachment"
                name="attachment_file"
              >
                <a-upload
                  :fileList="model.attachment_file"
                  :remove="handleRemoveAttachment"
                  :before-upload="beforeUploadAttachment"
                  accept=".pdf,.ppt*"
                >
                  <div>
                    <a-button>
                      <UploadOutlined />
                      Upload
                    </a-button>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>

          <a-space>
            <a-button
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
            >
              Save
            </a-button>

            <a-button
              v-if="model.is_issue_certificate === 'Yes'"
              :loading="previewLoader"
              @click="showCertificatePreview"
            >
              Preview Certificate
            </a-button>
          </a-space>
        </a-form>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons-vue";

export default {
  components: { UploadOutlined },
  data() {
    return {
      loading: true,
      previewLoader: false,
      model: {
        id: this.$route.params.id,
        name: null,
        description: "",
        type: 0,
        acknowledgement_message: null,
        certificate_content: null,
        policy_group_id: null,
        employment_type: 0,
        date: null,
        is_for_all_position: "Yes",
        is_issue_certificate: "No",
        employee_position_ids: [],
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
      },
      submitBtnLoader: false,

      rules: {
        name: {
          required: true,
          message: "This field is required!",
        },
        description: {
          required: true,
          message: "This field is required!",
        },
        acknowledgement_message: {
          required: true,
          message: "This field is required!",
        },
        certificate_content: {
          required: true,
          message: "This field is required!",
        },
        employee_position_ids: {
          required: true,
          message: "This field is required!",
          type: "array",
        },
      },

      employeePositions: [],
      policyGroups: [],
    };
  },

  computed: {
    title() {
      let res = this.model.id ? "Update" : "Create";
      res += " Policy Rule Documents";
      return res;
    },
  },

  mounted() {
    Promise.all([
      this.$comman.getEmployeePositions(),
      this.getPolicyGroups(),
    ]).then(([res, { data: policyGroups }]) => {
      this.employeePositions = res;
      this.policyGroups = policyGroups;
    });
    if (this.model.id) {
      this.getPolicyGroupDocument();
    } else {
      this.loading = false;
    }
  },

  methods: {
    handleRemoveAttachment() {
      this.model.attachment_file = [];
    },

    beforeUploadAttachment(file) {
      if (file.size < 5 * 1024 * 1024) {
        this.model.attachment_file = [file];
      } else {
        this.$message.error(`${file.name} size is more then 5MB`);
      }
      return false;
    },

    getPolicyGroupDocument() {
      commonService
        .get(this.$constants.policyRuleDocumentShowUrl, { id: this.model.id })
        .then((res) => {
          let data = res.data;
          this.model = {
            id: data.id,
            name: data.name,
            description: data.description,
            policy_group_id: data.policy_group_id,
            employment_type: data.employment_type,
            date: data.date ? moment(data.date, "YYYY-MM-DD") : null,
            acknowledgement_message: data.acknowledgement_message,
            certificate_content: data.certificate_content,
            type: data.type,
            is_for_all_position: data.is_for_all_position ? "Yes" : "No",
            is_issue_certificate: data.is_issue_certificate ? "Yes" : "No",
            employee_position_ids:
              data.employee_positions.map((e) => e.id) ?? [],
            attachment_file: data.attachment_file
              ? [
                  {
                    id: data.id,
                    uid: data.attachment_file,
                    name: data.attachment_file,
                    url: data.attachment_file_path,
                  },
                ]
              : [],
          };
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.loading = false));
    },

    getPolicyGroups() {
      return commonService
        .get(this.$constants.getPolicyGroups)
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.loading = false));
    },

    showCertificatePreview() {
      this.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.policyRuleDocumentCertificatePreviewUrl,
          {
            certificate_content: this.model.certificate_content,
            certificate_name: this.model.name,
          },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.previewLoader = false;
        });
    },

    onSubmit() {
      this.submitBtnLoader = true;

      const formData = new FormData();

      formData.append("id", this.model.id ?? "");
      formData.append("name", this.model.name);
      formData.append("description", this.model.description);
      formData.append(
        "acknowledgement_message",
        this.model.acknowledgement_message
      );
      formData.append(
        "is_issue_certificate",
        this.model.is_issue_certificate == "No" ? 0 : 1
      );
      formData.append(
        "certificate_content",
        this.model.is_issue_certificate ? this.model.certificate_content : ""
      );
      formData.append("type", this.model.type);
      formData.append(
        "is_for_all_position",
        this.model.is_for_all_position == "No" ? 0 : 1
      );
      formData.append("policy_group_id", this.model.policy_group_id);
      formData.append("employment_type", this.model.employment_type);
      formData.append(
        "date",
        this.model.date ? this.model.date.format("YYYY-MM-DD") : ""
      );

      if (this.model.is_for_all_position === "No") {
        this.model.employee_position_ids.forEach((id, idx) => {
          formData.append(`employee_position_ids[${idx}]`, id);
        });
      }

      if (this.model.attachment_file?.[0]) {
        const attach = this.model.attachment_file[0];

        if ("id" in attach) {
          formData.append("old_attachment_file", attach.id);
        } else {
          formData.append("attachment_file", attach);
        }
      }

      commonService
        .store(this.$constants.policyRuleDocumentStoreUrl, formData)
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          this.submitBtnLoader = false;
          this.$router.push({ name: "policy-rule-documents" });
        });
    },
  },
};
</script>
