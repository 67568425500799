<template>
  <a-layout-content>
    <page-header>
      <template #title> Facilities </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #address="{ record }">
          {{ record.address_line_1 }}, {{ record.address_line_2 }}
        </template>

        <template #quickbooks="{ record }">
          <i
            v-if="record.quickbooks_class_id && record.quickbooks_customer_id"
            class="ti ti-check ti-lg"
            style="color: green"
          />
          <i v-else class="ti ti-x ti-lg" style="color: red" />
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <router-link
              :to="{ name: 'show-facility', params: { id: record.id } }"
            >
            <a-tooltip title="View">
              <a-button type="link" size="small" class="pl-sm-0">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
            </router-link>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.facilityDatatableUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "City",
          dataIndex: "city",
          key: "city",
          sorter: true,
        },
        {
          title: "State",
          dataIndex: "state",
          key: "state",
          sorter: true,
        },
        {
          title: "Zip Code",
          dataIndex: "zipcode",
          key: "zipcode",
          sorter: true,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
        sortField: this.$route.query?.sortField ?? "name",
        sortOrder: this.$route.query?.sortOrder ?? "ascend",
      },
    };
  },

  mounted() {
    //
  },

  methods: {
    ...mapActions("drawer", ["open"]),
  },
};
</script>
