import { route } from "@/helper/route";
import auth from "./middleware/auth";
import accessible from "./middleware/accessible";
import profile from "./middleware/profile";

export default [
  {
    path: "/calendar-events",
    name: "calendar-event",
    component: route("calendarEvent/index"),
    meta: {
      title: "Calendar Events",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/events",
    name: "events",
    component: route("calendarEvent/list"),
    meta: {
      title: "Appointment List View",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/calendar-event/create",
    name: "create-calendar-event",
    component: route("calendarEvent/create"),
    meta: {
      title: "Create New Event",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/calendar-event/edit/:id",
    name: "edit-calendar-event",
    component: route("calendarEvent/edit"),
    meta: {
      title: "Edit Event",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
];
