<template>
  <div>
    <a-form :model="model" :rules="rules" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input v-model:value="model.name" placeholder="Enter name here" />
          </a-form-item>

          <a-form-item ref="percentage" label="Percentage" name="percentage">
            <a-input-number
              v-model:value="model.percentage"
              placeholder="Enter percentage here"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { constants } from "../../../../helper/constants";
import formMixins from "../../../../mixins/formMixins";
import { commonService } from "../../../../services";

export default {
  mixins: [formMixins],

  data() {
    return {
      model: {},
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
          },
        ],
        percentage: [
          {
            required: true,
            message: "percentage is required!",
            type: "integer",
            max: 100,
            min: 0,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.model = {
        name: this.record.name ?? "",
        percentage: this.record.percentage ?? "",
      };
    },

    onSubmit() {
      if (this.record.id) {
        this.model.id = this.record.id;
      }

      this.loadingStart();
      commonService
        .store(constants.crmStageOfLeadStoreUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.errors = err.errors;
          }
          console.log("error", err);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
