<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="24">
      <a-form-item
        ref="was_bypass_machine_cleaned"
        label="Was the heart lung bypass machine cleaned/disinfected after the procedure?"
        name="was_bypass_machine_cleaned"
        :validateStatus="validate('was_bypass_machine_cleaned', 'status')"
        :help="validate('was_bypass_machine_cleaned', 'msg')"
      >
        <a-radio-group v-model:value="model.was_bypass_machine_cleaned">
          <a-row>
            <template
              v-for="(yesNoNotApplicable,
              index) in $constants.yesNoNotApplicable"
              :key="`first_${index}`"
            >
              <a-col :span="24">
                <a-radio :value="yesNoNotApplicable.value">
                  {{ yesNoNotApplicable.text }}
                </a-radio>
              </a-col>
            </template>
          </a-row>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col v-if="wasBypassMachineCleanedIsYes" :span="12">
      <a-form-item
        ref="heart_machine_device_number"
        label="Heart Lung Machine Device Identifier/Serial Number"
        name="heart_machine_device_number"
        :validateStatus="validate('heart_machine_device_number', 'status')"
        :help="validate('heart_machine_device_number', 'msg')"
      >
        <a-input
          v-model:value="model.heart_machine_device_number"
          placeholder="Enter heart lung machine device identifier/serial number here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="was_heater_cooler_machine_cleaned"
        label="Was the heater cooler machine cleaned/disinfected after the procedure?"
        name="was_heater_cooler_machine_cleaned"
        :validateStatus="
          validate('was_heater_cooler_machine_cleaned', 'status')
        "
        :help="validate('was_heater_cooler_machine_cleaned', 'msg')"
      >
        <a-radio-group v-model:value="model.was_heater_cooler_machine_cleaned">
          <a-row>
            <template
              v-for="(yesNoNotApplicable,
              index) in $constants.yesNoNotApplicable"
              :key="`second_${index}`"
            >
              <a-col :span="24">
                <a-radio :value="yesNoNotApplicable.value">
                  {{ yesNoNotApplicable.text }}
                </a-radio>
              </a-col>
            </template>
          </a-row>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col v-if="wasHeaterCoolerMachineCleanedIsYes" :span="12">
      <a-form-item
        ref="heater_cooler_device_number"
        label="Heater Cooler Device Identifier/Serial Number"
        name="heater_cooler_device_number"
        :validateStatus="validate('heater_cooler_device_number', 'status')"
        :help="validate('heater_cooler_device_number', 'msg')"
      >
        <a-input
          v-model:value="model.heater_cooler_device_number"
          placeholder="Enter heater cooler device identifier/serial number here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="was_cell_saver_device_cleaned"
        label="Was the cell saver device cleaned/disinfected after the procedure?"
        name="was_cell_saver_device_cleaned"
        :validateStatus="validate('was_cell_saver_device_cleaned', 'status')"
        :help="validate('was_cell_saver_device_cleaned', 'msg')"
      >
        <a-radio-group v-model:value="model.was_cell_saver_device_cleaned">
          <a-row>
            <template
              v-for="(yesNoNotApplicable,
              index) in $constants.yesNoNotApplicable"
              :key="`third_${index}`"
            >
              <a-col :span="24">
                <a-radio :value="yesNoNotApplicable.value">
                  {{ yesNoNotApplicable.text }}
                </a-radio>
              </a-col>
            </template>
          </a-row>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col v-if="wasCellSaverDeviceCleanedIsYes" :span="12">
      <a-form-item
        ref="cell_saver_device_number"
        label="Cell Saver Device Identifier/Serial Number"
        name="cell_saver_device_number"
        :validateStatus="validate('cell_saver_device_number', 'status')"
        :help="validate('cell_saver_device_number', 'msg')"
      >
        <a-input
          v-model:value="model.cell_saver_device_number"
          placeholder="Enter cell saver device identifier/serial number here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-alert
        message="IMPORTANT: Please review your entry for accuracy before advancing to the next page."
        type="error"
        class="mb-sm-1"
      />
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: { require: true, type: Object },
    wasHeaterCoolerMachineCleanedIsYes: { require: true, type: Boolean },
    wasCellSaverDeviceCleanedIsYes: { require: true, type: Boolean },
    wasBypassMachineCleanedIsYes: { require: true, type: Boolean },
  },

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },
  },
};
</script>
