<template>
  <a-layout-content>
    <page-header>
      <template #title> Order Services </template>

      <template #buttons>
        <router-link
          v-if="$comman.hasRole(1)"
          :to="{ name: 'create-service' }"
        >
          <a-button type="primary">
            <a-space>
              <i class="fas fa-plus mr-lg-1"></i> Create New Order Service
            </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <router-link
              v-if="$comman.hasRole(1)"
              :to="{ name: 'edit-service', params: { id: record.id } }"
            >
              <a-button size="small" type="link" class="pl-sm-0">
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </router-link>

            <a-popconfirm
              v-if="$comman.hasRole(1)"
              title="Are you sure delete this order service?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
              <a-button size="small" type="link" danger>
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              v-if="$comman.hasRole(1)"
              title="Are you sure restored this order service?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
              <a-button size="small" type="link" class="pl-sm-0">
                <i class="ti ti-rotate ti-lg"></i>
              </a-button>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.serviceDatatableUrl,
      statusChangeUrl: this.$constants.serviceChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          sorter: true,
          customRender: ({ text }) => text ?? "-",
        },
        {
          title: "Products Count",
          dataIndex: "products_count",
          key: "products_count",
          sorter: true,
        },
        {
          title: "Products Quantity",
          dataIndex: "products_sum_quantity",
          key: "products_sum_quantity",
          sorter: true,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
      },
    };
  },

  methods: {
    //
  },
};
</script>
