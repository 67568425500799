<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Cell Saver Quality Control Detail"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card :loading="cardLoader" size="small">
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Date Sample Drawn">
          {{ $customDate.mdy(record.date) }}
        </a-descriptions-item>
        <a-descriptions-item label="MR Number">
          {{ record.mr_number ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Product Name">
          {{ record.asset_inventory_data?.asset_product?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Manufacturer Name">
          {{ record.asset_inventory_data?.asset_manufacturer?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Owned By">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'owned_by',
                $constants.assetInventoryOwnedBy,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "owned_by",
                $constants.assetInventoryOwnedBy
              )
            }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Owned Name">
          <template v-if="record.owned_by == 1">
            {{ record.asset_inventory_data?.company?.name ?? "N/A" }}
          </template>
          <template v-else>
            {{ record.asset_inventory_data?.hospital?.name ?? "N/A" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item
          v-if="record.asset_inventory_data?.asset_inventory_hospital"
          label="Allocated To"
          :span="2"
        >
          {{
            record.asset_inventory_data?.asset_inventory_hospital?.hospital
              ?.name ?? "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Record No">
          {{ record.asset_inventory_data?.record_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Serial No">
          {{ record.asset_inventory_data?.serial_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Asset ID Tag">
          {{ record.asset_inventory_data?.asset_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Secondary ID Tag">
          {{ record.asset_inventory_data?.secondary_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Description" :span="2">
          {{ record.asset_inventory_data?.description ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Employee Name">
          {{ record.employee?.full_name ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider orientation="left">Sources</a-divider>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        :dataSource="record.sources"
        :pagination="false"
        size="small"
        class="ant-table-vertical-align-baseline"
        :scroll="{ x: true, y: 0 }"
      >
      </a-table>

      <a-descriptions
        title=""
        :column="1"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Signature">
          <img :width="200" :src="record.signature" />
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: { backButton },
  data() {
    return {
      cardLoader: false,
      id: this.$route.params.id ?? 0,
      record: {},
      columns: [
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
        },
        {
          title: "Parameter",
          dataIndex: "parameter",
          key: "parameter",
        },
        {
          title: "Result",
          dataIndex: "result",
          key: "result",
        },
      ],
    };
  },

  mounted() {
    this.fetchCellSaverQC();
  },

  methods: {
    fetchCellSaverQC() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.cellSaverQCShowUrl, { id: this.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          this.record = res.data;
          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style></style>
