<template>
  <template v-if="model.service_category == 1">
    <a-divider orientation="left" class="mt-sm-0">
      Cardiac Procedure Info
    </a-divider>

    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :md="12">
        <a-form-item
          label="Re-Operation"
          name="reop"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.reop"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.yesNo"
              :key="option.value"
              :label="option.text"
              :value="option.value"
            >
              {{ option.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12"> </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Coronary Artery Bypass"
          name="coronary_artery_bypass"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.coronary_artery_bypass"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.yesNo"
              :key="option.value"
              :label="option.text"
              :value="option.value"
            >
              {{ option.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-row :gutter="[16, 0]" v-if="model.coronary_artery_bypass == 'Yes'">
          <a-col :xs="24" :md="12">
            <a-form-item
              label="CAB Technique"
              name="cab_technique"
              :rules="[
                {
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                },
              ]"
            >
              <a-select
                v-model:value="model.cab_technique"
                placeholder="Select One"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="option in $constants.cellSaverCabTechniques"
                  :key="option"
                  :label="option"
                  :value="option"
                >
                  {{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xs="24" :md="12">
            <a-form-item
              label="Number of Grafts"
              name="number_of_grafts"
              :rules="[
                {
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                  type: 'integer',
                },
              ]"
            >
              <a-input-number
                type="number"
                v-model:value="model.number_of_grafts"
                placeholder="Enter here..."
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Aortic Valve"
          name="aortic"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.aortic"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.cellSaverAortics"
              :key="option"
              :label="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Mitral Valve"
          name="mitral"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.mitral"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.cellSaverMitrals"
              :key="option"
              :label="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Tricuspid Valve"
          name="tricuspid"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.tricuspid"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.cellSaverTricuspids"
              :key="option"
              :label="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Pulmonic Valve"
          name="pulmonic"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.pulmonic"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.cellSaverPulmonics"
              :key="option"
              :label="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Other Cardiac Procedure"
          name="other_cardiac_procedure"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.other_cardiac_procedure"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.yesNo"
              :key="option.value"
              :label="option.text"
              :value="option.value"
            >
              {{ option.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          v-if="model.other_cardiac_procedure == 'Yes'"
          label="Cardiac Procedure"
          name="cardiac_procedure"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.cardiac_procedure"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.cellSaverCardiacProcedures"
              :key="option"
              :label="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          label="Other Non-Cardiac Procedure"
          name="other_non_cardiac_procedure"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.other_non_cardiac_procedure"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.yesNo"
              :key="option.value"
              :label="option.text"
              :value="option.value"
            >
              {{ option.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item
          v-if="model.other_non_cardiac_procedure == 'Yes'"
          label="Non-Cardiac Procedure"
          name="non_cardiac_procedure"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.non_cardiac_procedure"
            placeholder="Select One"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="option in $constants.cellSaverNonCardiacProcedures"
              :key="option"
              :label="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </template>

  <a-divider orientation="left" class="mt-sm-0"> Surgery Info </a-divider>

  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12">
      <a-form-item
        label="Patient In Room Time"
        name="patient_in_room_time"
        :rules="[
          {
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.patient_in_room_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Surgery Start Time"
        name="surgery_start_time"
        :rules="[
          {
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.surgery_start_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Surgery End Time"
        name="surgery_end_time"
        :rules="[
          {
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.surgery_end_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Patient Out Of Room Time"
        name="patient_out_of_room_time"
        :rules="[
          {
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.patient_out_of_room_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <template v-if="model.services.includes(7) || model.services.includes(14)">
    <a-divider orientation="left" class="mt-sm-0" />
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :md="12">
        <a-form-item
          label="Would you like to record STS data?"
          name="is_sts_data"
          :rules="{
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-select
            v-model:value="model.is_sts_data"
            placeholder="Select One..."
          >
            <a-select-option
              v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
              :key="yesNoNotApplicable.value"
              :label="yesNoNotApplicable.text"
              :value="yesNoNotApplicable.value"
            >
              {{ yesNoNotApplicable.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-space
      v-if="model.is_sts_data == 'Yes'"
      direction="vertical"
      style="width: 100%"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :md="12">
          <a-form-item
            label="Endoscopic Vein Harvest Time"
            name="endoscopic_vein_harvest_time"
            :rules="{
              message: 'This field is required!',
              type: 'integer',
            }"
          >
            <a-input-number
              type="number"
              v-model:value="model.endoscopic_vein_harvest_time"
              placeholder="Mins"
              :min="0"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12">
          <a-form-item
            label="Radial Artery Harvest Time"
            name="radial_artery_harvest_time"
            :rules="{
              message: 'This field is required!',
              type: 'integer',
            }"
          >
            <a-input-number
              type="number"
              v-model:value="model.radial_artery_harvest_time"
              placeholder="Mins"
              :min="0"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-button type="primary" @click="handleRowAddEvent">
        <a-space>
          <i class="ti ti-plus ti-lg"></i>
          Add Coronary Bypass
        </a-space>
      </a-button>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        :dataSource="model.coronary_bypasses"
        :pagination="false"
        size="small"
        class="ant-table-vertical-align-baseline"
        :scroll="{ x: true, y: 0 }"
      >
        <template #graft="{ record, index }">
          <a-form-item
            :name="['coronary_bypasses', index, 'graft']"
            :rules="{
              required: true,
              message: 'This field is required!',
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <a-input
              v-model:value="record.graft"
              placeholder="Enter graft here"
            />
          </a-form-item>
        </template>

        <template #flow_down_graft="{ record, index }">
          <a-form-item :name="['coronary_bypasses', index, 'flow_down_graft']">
            <a-input
              v-model:value="record.flow_down_graft"
              placeholder="Enter flow down graft here"
            />
          </a-form-item>
        </template>

        <template #action="{ index }">
          <a-button
            size="small"
            type="link"
            danger
            @click="handleRowDeleteEvent(index)"
          >
            <i class="ti ti-trash ti-lg"></i>
          </a-button>

          <a-button
            v-if="model.coronary_bypasses.length == index + 1"
            size="small"
            type="link"
            @click="handleRowAddEvent"
          >
            <i class="ti ti-plus ti-lg"></i>
          </a-button>
        </template>
      </a-table>

      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :md="12">
          <a-form-item label="Proximal Technique" name="proximal_techniques">
            <a-checkbox-group v-model:value="model.proximal_techniques">
              <template
                v-for="option in $constants.proximalTechniques"
                :key="option"
              >
                <a-row>
                  <a-col :span="24">
                    <a-checkbox :value="option">
                      {{ option }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </template>
            </a-checkbox-group>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12">
          <a-form-item label="Cardioplegia Type" name="cardioplegia_type">
            <a-checkbox-group v-model:value="model.cardioplegia_types">
              <template
                v-for="option in $constants.cardioplegiaTypes"
                :key="option"
              >
                <a-row>
                  <a-col :span="24">
                    <a-checkbox :value="option">
                      {{ option }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </template>
            </a-checkbox-group>
          </a-form-item>
        </a-col>
      </a-row>
    </a-space>
  </template>
</template>

<script>
export default {
  name: "case-report-step-6",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      columns: [
        {
          title: "Graft",
          dataIndex: "graft",
          key: "graft",
          slots: { customRender: "graft" },
          width: "45%",
        },
        {
          title: "Flow Down Graft",
          dataIndex: "flow_down_graft",
          key: "flow_down_graft",
          slots: { customRender: "flow_down_graft" },
          width: "45%",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 150,
          fixed: "right",
          slots: { customRender: "action" },
          width: "10%",
        },
      ],
    };
  },

  mounted() {
    //
  },

  methods: {
    handleRowDeleteEvent(index) {
      this.model.coronary_bypasses = this.model.coronary_bypasses.filter(
        (_, i) => index != i
      );
    },

    handleRowAddEvent() {
      this.model.coronary_bypasses.push({
        graft: null,
        flow_down_graft: null,
      });
    },
  },
};
</script>
