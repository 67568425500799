<template>
  <div>
    <a-descriptions
      :column="2"
      size="small"
      bordered
      :labelStyle="{ width: '160px' }"
    >
      <a-descriptions-item :span="2" label="Vendor Name">
        {{ record.VendorRef?.name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="QuickBooks Bill No">
        {{ record.DocNumber ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="QuickBooks Bill Id">
        {{ record.Id ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Date">
        {{ $customDate.mdy(record.TxnDate) }}
      </a-descriptions-item>
      <a-descriptions-item label="Due Date">
        {{ $customDate.mdy(record.DueDate) }}
      </a-descriptions-item>
      <a-descriptions-item label="Bill Amount">
        {{ $comman.withCurrency(record.TotalAmt) }}
      </a-descriptions-item>
      <a-descriptions-item label="Balance Amount">
        {{ $comman.withCurrency(record.Balance) }}
      </a-descriptions-item>
    </a-descriptions>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="record.Line"
      :pagination="false"
      size="small"
      class="mt-sm-1"
      :scroll="scroll"
    >
    </a-table>

    <template v-if="attachments.length > 0">
      <a-divider orientation="left">Attachments</a-divider>
      <template v-for="atta in attachments" :key="atta.Id">
        <a-typography-link
          :href="atta.TempDownloadUri"
          target="_blank"
        >
          {{ atta.FileName }}
        </a-typography-link>
        <br />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  data() {
    return {
      attachments: [],
      columns: [
        {
          title: "Category",
          dataIndex: "AccountBasedExpenseLineDetail.AccountRef.name",
        },
        {
          title: "Description",
          dataIndex: "Description",
        },
        {
          title: "Amount",
          dataIndex: "Amount",
          customRender: ({ text }) => this.$comman.withCurrency(text),
        },
      ],
      scroll: { x: true, y: 0 },
      excelExporting: false,
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    this.getAttachments();
  },

  methods: {
    getAttachments() {
      commonService
        .store(this.$constants.financeUnpaidBillAttachments, {
          id: this.record.Id,
        })
        .then((res) => {
          this.attachments = res.data;
        });
    },
  },
};
</script>
