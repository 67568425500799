<template>
  <a-layout-content>
    <page-header>
      <template #title>Virtual HR Assistant</template>
      <template #buttons>
        <a-button :loading="downloadExcelBtnLoading" @click="downloadTXT">
          <a-space>
            <i class="ti ti-download ti-lg"></i> Export History
          </a-space>
        </a-button>
      </template>
    </page-header>

    <div class="chat-container">
      <div class="chat-body">
        <div
          v-if="hasMessages"
          class="chat-message-list"
          ref="chatBody"
          @scroll="handleScroll"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="['chat-message-item', message.class]"
          >
            <article class="message">
              <div class="message-meta">
                <time class="">
                  {{ message.created_at }}
                </time>
                <span class="name">
                  {{ message.name }}
                </span>
              </div>
              <div class="message-content">
                <div v-html="parseMarkdown(message.text)"/>
                <template v-if="message.show_copy_button">
                  <a-typography-paragraph :copyable="{ text: message.text }">
                    <template #copyableIcon="{ copied }">
                      <i v-if="!copied" class="ti ti-copy ti-lg"></i>
                      <i v-else class="ti ti-check ti-lg"></i>
                    </template> 
                  </a-typography-paragraph>
                </template>
              </div>
            </article>
          </div>
          <div v-if="loading" class="chat-message-item item-left">
            <article class="message inactive">
              <div class="message-meta">
                <span class="name">HR Assistant</span>
              </div>
              <div class="message-content">
                <div class="message-loading">
                  <a-space>
                    <div class="message-text">
                      {{
                        waitingMessageIndex
                          ? waitingMessages[waitingMessageIndex]
                          : "Loading"
                      }}
                    </div>
                    <div class="type8">
                      <div class="ball1"></div>
                      <div class="ball2"></div>
                      <div class="ball3"></div>
                      <div class="ball4"></div>
                    </div>
                  </a-space>
                </div>
              </div>
            </article>
          </div>

          <div ref="scrollAnchor"></div>
        </div>
        <div v-else class="chat-message-empty">
          <a-result title="AskHR Support">
            <template #subTitle>
              I help you answer day to day questions about HR &amp; Work
              <span style="color: #000">🙂</span>
            </template>
            <template #icon>
              <img class="result-icon" :src="chatbot" alt="" />
            </template>
          </a-result>
        </div>
        <div v-if="showScrollToBottomButton" class="scroll-to-bottom-btn">
          <a-button
            type="default"
            shape="circle"
            style="background-color: white; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); border-radius: 50%;"
            @click="scrollToBottom"
            class="bottom-btn"
          >
            <ArrowDownOutlined style="font-size: 20px;" />
          </a-button>
        </div>
      </div>

      <div class="chat-footer">
        <div v-if="!hasMessages" class="chat-suggestions">
          <div class="chat-suggestion-list">
            <a-tag
              v-for="(tag, index) in suggestedTags"
              :key="index"
              color="blue"
              class="chat-tag"
              @click="handleTagClick(tag)"
            >
              {{ tag }}
            </a-tag>
          </div>
        </div>
        <div class="chat-input">
          <a-input
            v-model:value="newMessage"
            placeholder="Type your message here..."
            @keyup.enter="sendMessage"
            size="large"
          >
            <template #suffix>
              <a-button
                type="default"
                shape="circle"
                :style="{
                  backgroundColor: newMessage.trim() || loading ? 'black' : '#d7d7d7',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                  borderRadius: '50%',
                }"
                :loading=false
                @click="sendMessage"
                :class="{ disabled: !newMessage.trim() }"
              >  
                <ArrowUpOutlined v-if="!loading" style="font-size: 20px; color: white;" />
                <div v-if="loading" class="white-square"></div>
              </a-button>

            </template>
          </a-input>
        </div>
      </div>
    </div>
  </a-layout-content>
</template>

<script setup>
import {
  DownCircleOutlined,
  LoadingOutlined,
  SendOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import moment from "moment";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import chatbot from "../assets/chat-bot.svg";
import { constants } from "../helper/constants";
import { commonService } from "../services";
import { auth } from "../store/auth1.module";
import { Remarkable } from 'remarkable';


const md = new Remarkable();

const parseMarkdown = (text) => {
  return md.render(text);
};

const id = auth.state.user.id;
const employeeName = auth.state.employee.fl_name;
const messages = ref([]);
const newMessage = ref("");
const loading = ref(false);
const historyLoading = ref(false);
const scrollAnchor = ref(null);
const threadId = ref(null);
const waitingMessageIndex = ref(null);
const chatBody = ref();
const downloadExcelBtnLoading = ref(false);
const waitingMessages = ref([
  "Initializing, please wait ",
  "Processing your request ",
  "Preparing responses ",
  "Almost ready  ", 
]);
const waitingInterval = ref(null);
const showScrollToBottomButton = ref(false);

const suggestedTags = [
  "How is Paid Time Off (PTO) accrued?",
  "Procedures for addressing workplace harassment",
  "Reimbursements for business travel expenses",
];

const incrementWaitingMessageIndex = () => {
  if (waitingMessageIndex == null) {
    clearInterval(waitingInterval.value);
  }

  if (waitingMessageIndex.value < waitingMessages.value.length - 1) {
    waitingMessageIndex.value++;
  }
};

const processMessages = (data, roleName = "HR Assistant") => {
  return data
    .sort((a, b) => (moment(a.created_at).isBefore(b.created_at) ? -1 : 1))
    .map((item) =>
      item.content.map((contentItem) => {
        const annotationsText = contentItem.text?.annotations
          ? contentItem.text.annotations.map((annotation) => annotation?.text)
          : [];
        let updatedText = contentItem.text?.value || "";
        annotationsText.forEach((annotation) => {
          updatedText = updatedText.replace(annotation, " ");
        });
        return {
          thread_id: item.thread_id,
          id: item.id,
          text: updatedText,
          source: annotationsText,
          role: item.role,
          name: item.role === "user" ? employeeName : roleName,
          class: item.role === "user" ? "item-right" : "item-left",
          created_at: moment(item.created_at * 1000)
            .tz(constants.timezone)
            .format("HH:mm:ss"),
          show_copy_button: item.role !== "user",
        };
      })
    )
    .flat();
};
const sendMessage = async () => {
  loading.value = true;
  if (newMessage.value.trim() !== "") {
    messages.value.push({
      text: newMessage.value,
      role: "user",
      name: employeeName,
      class: "item-right",
      created_at: moment().tz(constants.timezone).unix(),
    });

    if (!showScrollToBottomButton.value) {
      scrollToBottom();
    }
    const payload = {
      question: newMessage.value,
      thread_id: threadId.value,
    };

    newMessage.value = "";
    waitingMessageIndex.value = 0;
    waitingInterval.value = setInterval(incrementWaitingMessageIndex, 3000);
    try {
      const response = await commonService.store(constants.chatGPT, payload);
      waitingInterval.value = setInterval(incrementWaitingMessageIndex, 1000);
      waitingMessageIndex.value = null;
      if (response.success) {
        threadId.value = response.data.data[0].thread_id;
        messages.value = processMessages(response.data.data);
        // if (!showScrollToBottomButton.value) {
        //   scrollToBottom();
        // }
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again later.");
    } finally {
      clearInterval(waitingInterval.value);
      waitingMessageIndex.value = null;
      loading.value = false;
    }
  } else {
    loading.value = false;
    console.log("input value  emty");
  }
};

const handleTagClick = (tag) => {
  newMessage.value = tag;
  sendMessage();
};

async function chatHistory() {
  historyLoading.value = true;
  try {
    const response = await commonService.get(constants.chatHistory);
    if (response.success && response.data.data) {
      threadId.value = response.data.data[0]?.thread_id ?? undefined;
      const rawMessages = processMessages(response.data.data);
      threadId.value = rawMessages[rawMessages.length - 1]?.thread_id;
      messages.value = rawMessages;
    }
  } catch (error) {
    console.error("Error fetching chat history:", error);
  } finally {
    historyLoading.value = false;
  }
}

const scrollToBottom = () => {
  nextTick(() => {
    if (scrollAnchor.value) {
      scrollAnchor.value.scrollIntoView({ behavior: "smooth" });
    }
  });
};

onMounted(() => {
  chatHistory().then(scrollToBottom);
});

onUnmounted(() => {
  clearInterval(waitingInterval.value);
});

watch(
  () => messages.value,
  () => {
    if (!showScrollToBottomButton.value) {
      scrollToBottom();
    }
  }
);

const hasMessages = computed(() => messages.value.length != 0);

const handleScroll = () => {
  const { scrollTop, scrollHeight, clientHeight } = chatBody.value;
  showScrollToBottomButton.value =
    scrollHeight - (scrollTop + clientHeight) > 1;
};

function downloadTXT() {
  downloadExcelBtnLoading.value = true;
  commonService
    .downloadFile(
      `${constants.chatGPTExportHistory}`,
      {},
      `virtual-hr-assistant-chat-history.txt`
    )
    .then((res) => {
      message.success(res);
    })
    .catch((err) => {
      message.error(err);
    })
    .finally(() => {
      downloadExcelBtnLoading.value = false;
    });
}
</script>

<style lang="less">
.white-square {
  width: 10px; 
  height: 10px;
  background-color: white;
  margin-left: 10px;
}
.chat-list {
  position: relative;
  .chat-list-item {
    display: flex;
    flex-direction: row;
    padding: 0.25em 0;
    &.chat-list-item-center {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &.chat-list-item-right {
      flex-direction: row-reverse;
      .chat-list-item-message-container {
        .chat-message-content {
          background-color: #eee;
          padding: 1em;
          border-radius: 0.9em;
          // max-width: 80%;
        }
        position: relative;
        .chat-message-timestamp {
          text-align: right;
        }
      }
    }
    .chat-message-timestamp {
      font-size: 0.9em;
      color: #999;
      margin-top: 0.5em;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.chat-container {
  table {
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
  }

  table th,  table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
  }

  table th {
    background-color: #f4f4f4;
    text-align: left;
  }

  table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  table td {
    font-size: 14px;
    text-align: left;
  }

  .chat-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //padding:1em;
    .chat-message-empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100vh - 374px);
      overflow: hidden;
    }
    .chat-message-list {
      height: calc(100vh - 337px);
      overflow: hidden;
      overflow-y: scroll;
      padding: 0 1em;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 50px;
      }

      &::-webkit-scrollbar-thumb {
        background: #dddddd;
        border-radius: 50px;
      }
      .chat-message-item {
        display: flex;
        gap: 12px;
        .message {
          position: relative;
          display: flex;
          padding: 1.2em 0;
          max-width: 80%;
          .message-meta {
            position: absolute;
            top: 0;
            font-size: 12px;
            color: #888888;
            display: flex;
            gap: 1em;
            time {
              position: relative;
              &::after {
                content: "●";
                position: absolute;
                right: -8px;
                top: 2px;
                font-size: 8px;
                color: #888;
              }
            }
            & > * {
              white-space: nowrap;
            }
          }
          .message-content {
            padding: 1em 0;
            // background-color: #fff;
            border-radius: 8px;
            //box-shadow:0 4px 4px rgba(0,0,0,.25);
            p {
              margin: 0;
          }
          }
        }
        &.item-right {
          flex-direction: row-reverse;
          .message {
            .message-meta {
              flex-direction: row-reverse;
              right: 0;
              time {
                position: relative;
                &::after {
                  content: "●";
                  position: absolute;
                  left: -8px;
                  top: 2px;
                  font-size: 8px;
                  color: #888;
                }
              }
            }
            .message-content {
              padding: 1em;
              background-color: #dedede;
            }
          }
        }
        &.item-left {
          flex-direction: row;
          .message {
            .message-time {
              left: 1.2em;
            }
          }
        }
      }
    }
  }
  .scroll-to-bottom-btn {
    position: fixed;
    bottom: 160px;
    right: 45%;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s;

    .bottom-btn:hover , .bottom-btn:focus{
        border-color: #d9d9d9; 
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
        color: #000;
      }
  }
  
  .chat-footer {
    .chat-suggestions {
      .chat-suggestion-list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          height: 0px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 0px;
        }

        .chat-tag {
          padding: 0.6em 1em;
          font-size: 14px;
          border-radius: 5rem;
          cursor: pointer;
        }
      }
    }

    .chat-input {
      bottom: 0;
      padding: 8px 0;
      border-radius: 4px;
      box-sizing: border-box;
      box-shadow: #00000014 #0000001f #0000000d;

      .ant-input-affix-wrapper {
        border: none;
        flex-grow: 1;
        margin-right: 8px;
        border-radius: 50rem;
        height: 48px;
        border-color: #b9b7b7;

        .disabled {
          color: #ccc;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .chat-container {
    .chat-body {
      .chat-message-empty {
        .ant-result-title {
          font-size: 20px;
        }
        .ant-result-icon {
          margin-bottom: 0%;
        }
        .result-icon {
          width: 80px;
          margin-bottom: 10px;
        }
      }
    }
    .chat-footer {
      .chat-suggestions {
        .chat-suggestion-list {
          .chat-tag {
            padding: 0.3em 0.5em;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .chat-container {
    .chat-body {
      .chat-message-empty {
        .ant-result-title {
          font-size: 18px;
        }
        .ant-result-icon {
          margin-bottom: 0%;
        }
        .result-icon {
          width: 70px;
          margin-bottom: 10px;
        }
      }
    }
    .chat-footer {
      .chat-suggestions {
        .chat-suggestion-list {
          .chat-tag {
            padding: 0.3em 0.5em;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .chat-container {
    .chat-body {
      .chat-message-empty {
        .ant-result-title {
          font-size: 16px;
        }
        .ant-result-icon {
          margin-bottom: 0%;
        }
        .result-icon {
          width: 60px;
          margin-bottom: 10px;
        }
      }
    }
    .chat-footer {
      .chat-suggestions {
        .chat-suggestion-list {
          .chat-tag {
            padding: 0.2em 0.5em;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.message-loading {
  display: flex;
  .message-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #aaa;
    white-space: nowrap;
  }
}
.message-loading .type8 {
  min-width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 16px;
}
.message-loading .type8 * {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.message-loading .type8 .ball1 {
  background-color: #aaa;
  animation: jump 1.4s 0s ease infinite;
}
.message-loading .type8 .ball2 {
  background-color: #aaa;
  animation: jump 1.4s 0.35s ease infinite;
}
.message-loading .type8 .ball3 {
  background-color: #aaa;
  animation: jump 1.4s 0.7s ease infinite;
}
.message-loading .type8 .ball4 {
  background-color: #aaa;
  animation: jump 1.4s 1.05s ease infinite;
}
@keyframes jump {
  30% {
    margin-bottom: 8px;
  }
  35% {
    margin-bottom: 8px;
  }
  65% {
    margin-bottom: 0;
  }
  to {
    margin-bottom: 0;
  }
}
</style>
