<template>
  <a-layout-content>
    <page-header>
      <template #title> Dispatched Orders </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #order_no="{ record }">
          <router-link
            :to="{
              name: 'view-order',
              params: { order_no: record.order.order_no },
            }"
          >
            <a-button type="link" class="pl-sm-0">
              {{ record.order.order_no_with_hash }}
            </a-button>
          </router-link>
        </template>

        <template #tracking_no="{ record }">
          <a-typography-link
            target="_blank"
            v-if="
              $comman.getTrackingURL(record.carrier_partner, record.tracking_no)
            "
            :href="
              $comman.getTrackingURL(record.carrier_partner, record.tracking_no)
            "
          >
            {{ record.tracking_no }}
          </a-typography-link>
          <span v-else>
            {{ record.tracking_no }}
          </span>
        </template>

        <template #action="{ record }">
          <a-tooltip title="View">
            <a-button
              class="pl-sm-0"
              type="link"
              size="small"
              @click="showDispatchInfo(record)"
            >
              <i class="ti ti-eye ti-lg"></i>
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.dispatchedOrdersDatatableUrl,
      columns: [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          slots: {
            customRender: "order_no",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.order_no
            ? [this.$route.query?.order_no]
            : undefined,
        },
        {
          title: "Mfg. Order",
          dataIndex: "mfg_order_no",
          key: "mfg_order_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.mfg_order_no
            ? [this.$route.query?.mfg_order_no]
            : undefined,
        },
        {
          title: "Carrier Partner",
          dataIndex: "carrier_partner",
          key: "carrier_partner",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.carrier_partner
            ? [this.$route.query?.carrier_partner]
            : undefined,
        },
        {
          title: "Tracking No",
          dataIndex: "tracking_no",
          key: "tracking_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "tracking_no",
          },
          defaultFilteredValue: this.$route.query?.tracking_no
            ? [this.$route.query?.tracking_no]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.order?.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Dispatched/Received",
          dataIndex: "dispatched_products_count",
          key: "dispatched_products_count",
          customRender: ({ record }) =>
            `${record.products.length}/${
              record.products.filter((v) => v.status == 1).length
            }`,
        },
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          customRender: ({ record }) =>
            this.$customDate.mdy(record.order.order_date),
        },
        {
          title: "Shipment Date",
          dataIndex: "shipment_date",
          key: "shipment_date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ record }) =>
            this.$customDate.mdy(record.shipment_date),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.dispatchedOrderStatus.map((item) => {
        return {
          key: item.value.toString(),
          counts: 0,
          text: item.text,
          status: item.value,
          slots: { tab: "customRender" },
        };
      }),
      defaultFilterParams: {
        order_no: this.$route.query?.order_no
          ? [this.$route.query?.order_no]
          : undefined,
        mfg_order_no: this.$route.query?.mfg_order_no
          ? [this.$route.query?.mfg_order_no]
          : undefined,
        carrier_partner: this.$route.query?.carrier_partner
          ? [this.$route.query?.carrier_partner]
          : undefined,
        tracking_no: this.$route.query?.tracking_no
          ? [this.$route.query?.tracking_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleStatusChangeEvent(record) {
      commonService
        .store(this.statusChangeUrl, { id: record.id })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    showDispatchInfo(record) {
      this.open({
        title: "Show Order Dispatch Info",
        path: "order.dispatchInfo",
        callback: this.refresh,
        record: record,
        extra: { hospital_name: record?.order?.hospital?.name ?? "N/A" },
        width: "1000px",
      });
    },
  },
};
</script>
