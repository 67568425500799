<template>
  <a-descriptions
    :column="1"
    size="small"
    bordered
    :label-style="{ width: '200px' }"
    class="mb-sm-1"
  >
    <a-descriptions-item label="Order No">
      {{ record?.order?.order_no ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Order Date">
      {{ $customDate.dmy(record?.order?.order_date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Hospital">
      {{ record?.order?.hospital?.name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Product">
      {{ record?.product?.product?.name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Dispatched Qty">
      {{ record?.qty ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Missing Qty">
      {{ record?.missing_qty ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Damaged Qty">
      {{ record?.damaged_qty ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Received Qty">
      {{ record?.received_qty ?? "N/A" }}
    </a-descriptions-item>
  </a-descriptions>

  <a-form :model="formState" layout="vertical" @finish="onSubmit">
    <a-row :gutter="[20, 0]">
      <a-col :xs="24">
        <a-form-item
          label="Move Qty from Missing to Received"
          name="qty"
          :rules="{
            required: true,
            message:
              'This field is required and must be less then or equal to missing qty.',
            type: 'integer',
            min: 0,
            max: record.missing_qty,
          }"
        >
          <a-input-number
            v-model:value="formState.qty"
            placeholder="Enter here..."
          />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Update" />
  </a-form>

  <a-descriptions
    :column="1"
    size="small"
    bordered
    :label-style="{ width: '200px' }"
    class="mb-sm-1"
  >
    <a-descriptions-item label="New Received Qty">
      {{ (record?.received_qty ?? 0) + (formState.qty ?? 0) }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {},
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id,
        qty: null,
      };
    },

    onSubmit() {
      this.loadingStart();
      companyService
        .store(this.$constants.adminMissingItemOrderUpdateUrl, this.formState)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
