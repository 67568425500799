<template>
  <div>
    <a-form
      name="expense_update_status_form"
      ref="expense_update_status_form"
      :model="model"
      :rules="rules"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="status"
            label="Status"
            name="status"
            :validateStatus="validate('status', 'status')"
            :help="validate('status', 'msg')"
          >
            <a-select
              v-model:value="model.status"
              placeholder="Select expense status"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="status in statuses"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              >
                {{ status.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="remarks"
            label="Remarks"
            name="remarks"
            :validateStatus="validate('remarks', 'remarks')"
            :help="validate('remarks', 'msg')"
          >
            <a-textarea
              v-model:value="model.remarks"
              placeholder="Enter rmarks here..."
              :auto-size="{ minRows: 3, maxRows: 6 }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons name="Update Status" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      model: {},
      statuses: [
        {
          text: "Approve",
          value: 2,
        },
        {
          text: "Reject",
          value: 3,
        },
      ],
      rules: {
        status: [
          {
            required: true,
            message: "Status is required!",
            trigger: "blur",
            type: "number",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  mounted() {
    this.getmodel();
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getmodel() {
      this.model = {
        id: this.record.id,
        status: null,
        remarks: null,
      };
    },

    handleFinish() {
      this.loadingStart();
      commonService
        .store(this.$constants.expenseChangeStatusUrl, this.model)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>

<style></style>
