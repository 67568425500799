<template>
  <a-card>
    <template v-if="followups.length == 0">
      <a-empty />
    </template>

    <template v-else>
      <a-spin :spinning="loading">
        <a-timeline>
          <template v-for="followup in followups" :key="followup.id">
            <a-timeline-item>
              <template #dot>
                <i v-if="followup.type == 1" class="ti ti-mail ti-lg" />
                <i v-if="followup.type == 2" class="ti ti-phone ti-lg" />
                <i
                  v-if="followup.type == 3"
                  class="ti ti-calendar-stats ti-lg"
                />
                <i v-if="followup.type == 4" class="ti ti-message ti-lg" />
                <i
                  v-if="followup.type == 5"
                  class="ti ti-status-change ti-lg"
                />
              </template>

              <a-row type="flex">
                <a-col flex="calc(100% - 100px)">
                  <span style="font-size: 15px">
                    <span
                      v-html="followup.comment?.replaceAll('\n', '<br/>')"
                    />

                    <template v-if="followup.new_stages_of_lead_id">
                      <br v-if="followup.comment" />
                      <br v-if="followup.comment" />
                      {{ followup.old_stages_of_lead.name }} >>>
                      {{ followup.new_stages_of_lead.name }}
                    </template>
                  </span>
                  <br />
                  <a-typography-text type="secondary">
                    Added By
                    <strong> {{ followup.creator?.full_name ?? "N/A" }} </strong
                    >,
                    {{ $customDate.ll(followup.date) }}
                  </a-typography-text>
                </a-col>

                <a-col flex="100px" style="text-align: right">
                  <a-space v-if="followup.type != 5">
                    <a-tooltip title="Edit">
                    <a-button
                      type="link"
                      size="small"
                      @click="followUpRecord(followup)"
                    >
                      <i class="ti ti-pencil ti-lg"></i>
                    </a-button>
                  </a-tooltip>

                    <a-popconfirm
                      title="Are you sure you want to delete this followup?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="deleteFollowupRecord(followup)"
                    >
                    <a-tooltip title="Delete">
                      <a-button type="link" size="small" danger>
                        <i class="ti ti-trash ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                    </a-popconfirm>
                  </a-space>
                </a-col>
              </a-row>
            </a-timeline-item>
          </template>
        </a-timeline>
      </a-spin>
    </template>
  </a-card>
</template>

<script>
import { commonService } from "@/services";
import { InboxOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";

export default {
  components: { InboxOutlined },

  props: ["lead"],

  data() {
    return {
      loading: false,
      followups: [],
      id: this.$route.params.id,
    };
  },

  mounted() {
    this.fetchLeadFollowUp();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchLeadFollowUp() {
      this.loading = true;
      commonService
        .store(this.$constants.crmGetLeadFollowUpUrl, { id: this.id })
        .then((res) => {
          this.followups = res.data;
        })
        .finally(() => (this.loading = false));
    },

    followUpRecord(record) {
      if (record.type == 4) {
        this.open({
          title: "Comment",
          path: "crm.lead.drawerForms.comment",
          callback: () => {
            this.fetchLeadFollowUp();
            this.$emit("fetchLead");
          },
          record: record,
          extra: this.lead,
        });
      } else {
        this.open({
          title: "Follow Up",
          path: "crm.lead.drawerForms.followup",
          callback: () => {
            this.fetchLeadFollowUp();
            this.$emit("fetchLead");
          },
          record: record,
          extra: this.lead,
        });
      }
    },

    deleteFollowupRecord(record) {
      this.loading = true;
      commonService
        .store(this.$constants.crmLeadFollowUpRemoveUrl, { id: record.id })
        .then((res) => {
          this.followups = res.data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
