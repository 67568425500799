<template>
  <a-layout-content>
    <page-header>
      <template #title> Sales Team </template>

      <template #buttons>
        <a-button
          v-if="$comman.hasRole(1)"
          type="primary"
          @click="createRecord"
        >
          <a-space> <i class="ti ti-plus ti-lg"></i> Add </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #action="{ record }">
          <a-popconfirm
            v-if="$comman.hasRole(1)"
            title="Are you sure you want to remove this employee from sales team?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleStatusChangeEvent({ id: record.id })"
          >
          <a-tooltip title="Remove">
            <a-button size="small" type="link" danger>
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../../mixins/datatableMixins";
import { commonService } from "../../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.crmSalesTeamDatatableUrl,
      statusChangeUrl: this.$constants.crmSalesTeamRemoveUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "first_name",
          key: "first_name",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.full_name,
          defaultFilteredValue: this.$route.query?.first_name
            ? [this.$route.query?.first_name]
            : undefined,
          defaultSortOrder: this.$route.query?.sortField
            ? this.$route.query?.sortField == "first_name"
              ? this.$route.query.sortOrder
              : undefined
            : "ascend",
        },
        {
          title: "Employee Code",
          dataIndex: "employee_code",
          key: "employee_code",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_code
            ? [this.$route.query?.employee_code]
            : undefined,
          defaultSortOrder:
            this.$route.query?.sortField == "employee_code"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    createRecord() {
      this.open({
        title: "Add New Sales Person",
        path: "crm.setting.salesTeam.store",
        callback: this.refresh,
      });
    },
  },
};
</script>
