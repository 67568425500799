<template>
  <template v-if="!onLine">
    <a-modal
      visible
      centered
      :closable="false"
      :maskClosable="false"
      :footer="false"
      :keyboard="false"
    >
      <a-space
        :size="20"
        direction="vertical"
        align="center"
        style="width: 100%"
      >
        <a-typography-title type="danger" :level="3" class="mb-sm-0">
          <close-circle-outlined />
        </a-typography-title>

        <a-space :size="1" direction="vertical" align="center">
          <a-typography-title :level="3">
            No internet connection.
          </a-typography-title>
          <a-typography-text :level="3">
            Please connect to the internet to access the system
          </a-typography-text>
        </a-space>
      </a-space>

      <div class="text-center mt-sm-2">
        <a-button type="primary" @click="reloadPage"> Retry </a-button>
      </div>
    </a-modal>
  </template>

  <template v-else>
    <component :is="layout">
      <router-view />
    </component>
  </template>
</template>

<script>
import { CloseCircleOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    CloseCircleOutlined,
  },

  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },

  computed: {
    layout() {
      return (this.$route.meta?.layout || "default") + "-layout";
    },
    isOnline() {
      return navigator.onLine;
    },
  },

  methods: {
    updateOnlineStatus(e) {
      console.log(e);
      const { type } = e;
      this.onLine = type === "online";
    },
  },

  watch: {
    onLine(val) {
      if (val) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
  },

  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
