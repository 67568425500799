<template>
  <a-divider orientation="left" style="margin-top: 0 !important">
    Pressure (mmHg)
  </a-divider>

  <a-row :gutter="[16, 0]">
    <slot
      name="timeField"
      :time="model.time"
      :date="model.date"
      :change="(val, field) => $emit('update', val, field)"
    />

    <a-col :span="12">
      <a-form-item
        ref="art_pressure"
        label="Art Pressure"
        name="art_pressure"
        :rules="{
          // /^[\d\/]+$/
          pattern: new RegExp(/^(\/)?([0-9]{2,3}\/[0-9]{2,3})|([0-9]{2,3})$/),
          message: 'This field must be valid input.',
        }"
      >
        <a-input
          :value="model.art_pressure"
          @change="(e) => $emit('update', e.target.value, 'art_pressure')"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="vacuum"
        label="Vacuum"
        name="vacuum"
        :rules="{
          type: 'integer',
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          :value="model.vacuum"
          @change="(val) => $emit('update', val, 'vacuum')"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="cvp"
        label="CVP"
        name="cvp"
        :rules="{
          type: 'integer',
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.cvp"
          @change="(val) => $emit('update', val, 'cvp')"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-divider orientation="left" style="margin-top: 0 !important">
    Pump
  </a-divider>

  <a-row :gutter="[16, 0]">
    <a-col :span="8">
      <a-form-item
        ref="blood_flow"
        label="Blood Flow (Art Flow)"
        name="blood_flow"
        :rules="{
          pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.blood_flow"
          :step="0.01"
          @change="(val) => $emit('update', val, 'blood_flow')"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="rpm"
        label="RPM"
        name="rpm"
        :rules="{
          type: 'integer',
          min: 1000,
          max: 9999,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.rpm"
          @change="(val) => $emit('update', val, 'rpm')"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="line_pressure"
        label="Line Pressure"
        name="line_pressure"
        :rules="{
          type: 'integer',
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.line_pressure"
          @change="(val) => $emit('update', val, 'line_pressure')"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="hc_temp"
        label="H/C Temp"
        name="hc_temp"
        :rules="{
          pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.hc_temp"
          @change="(val) => $emit('update', val, 'hc_temp')"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="core_temp"
        label="Core Temp"
        name="core_temp"
        :rules="{
          pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.core_temp"
          @change="(val) => $emit('update', val, 'core_temp')"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-divider orientation="left" style="margin-top: 0 !important">
    Gas
  </a-divider>

  <a-row :gutter="[16, 0]">
    <a-col :span="12">
      <a-form-item
        ref="fio2"
        name="fio2"
        :rules="{
          type: 'integer',
          min: 21,
          max: 100,
          message: 'This field must be valid input.',
        }"
      >
        <template #label> FiO<sub>2</sub> </template>
        <a-input-number
          :value="model.fio2"
          @change="(val) => $emit('update', val, 'fio2')"
          :formatter="$comman.percentageFormatter"
          :parser="$comman.percentageParser"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="sweep"
        label="Sweep"
        name="sweep"
        :rules="{
          pattern: new RegExp(/^\d+(\.\d{1})*$/),
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.sweep"
          :step="0.1"
          @change="(val) => $emit('update', val, 'sweep')"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-divider orientation="left" style="margin-top: 0 !important">
    ACT
  </a-divider>

  <a-row :gutter="[16, 0]">
    <a-col :span="12">
      <a-form-item
        ref="act"
        label="ACT"
        name="act"
        :rules="{
          type: 'integer',
          min: 10,
          max: 999,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number
          type="number"
          :value="model.act"
          @change="(val) => $emit('update', val, 'act')"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "nrp-status-form",
  props: { model: { required: true }, showPaCvpActField: { default: false } },
  emits: ["update"],
};
</script>
