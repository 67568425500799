<template>
  <div>
    <a-descriptions :column="1" size="small" bordered>
      <a-descriptions-item label="Name">
        {{ record.name }}
      </a-descriptions-item>
      <a-descriptions-item label="Email">
        {{ record.email ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Address Line 1">
        {{ record.address_line_1 }}
      </a-descriptions-item>
      <a-descriptions-item label="Address Line 2">
        {{ record.address_line_2 ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="City">
        {{ record.city }}
      </a-descriptions-item>
      <a-descriptions-item label="State">
        {{ record.state }}
      </a-descriptions-item>
      <a-descriptions-item label="Zipcode">
        {{ record.zipcode }}
      </a-descriptions-item>
      <a-descriptions-item label="Website">
        <template v-if="record.website">
          <a-typography-link :href="record.website" target="_blank">
            {{ record.website }}
          </a-typography-link>
        </template>
        <template v-else> N/A </template>
      </a-descriptions-item>
      <a-descriptions-item label="Contact Number">
        {{ record.contact_no ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Fax Number">
        {{ record.fax_no ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item v-if="record.logo" label="Logo">
        <img :width="200" :src="record.logo_full_path" />
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("drawer", ["record"]),
  },
};
</script>
