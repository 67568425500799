import axios from "axios";
import { api } from "../config/config";

export const insperityService = {
  get: (url, val = {}) => {
    return axios
      .get(url, val)
      .then((res) => {
        try {
          return res.data;
        } catch {
          return Promise.reject("Something went wrong!");
        }
      })
      .catch((err) => {
        try {
          if (typeof err.response.data == "string") {
            return Promise.reject(err.response.data);
          } else {
            if (
              err.response.data &&
              err.response.data.message &&
              err.response.data.message != ""
            ) {
              return Promise.reject(err.response.data.message);
            } else {
              return Promise.reject("Something went wrong!");
            }
          }
        } catch {
          return Promise.reject("Something went wrong!");
        }
      });
  },
  post: (url, val) => {
    return axios
      .post(url, val)
      .then((res) => {
        try {
          return res.data;
        } catch {
          return Promise.reject("Something went wrong!");
        }
      })
      .catch((err) => {
        try {
          if (typeof err.response.data == "string") {
            return Promise.reject(err.response.data);
          } else {
            if (
              err.response.data &&
              err.response.data.message &&
              err.response.data.message != ""
            ) {
              return Promise.reject(err.response.data.message);
            } else {
              return Promise.reject("Something went wrong!");
            }
          }
        } catch {
          return Promise.reject("Something went wrong!");
        }
      });
  },
};
