import { route } from "@/helper/route";
import auth from "./../middleware/auth";
import hasRoles from "./../middleware/hasRoles";
import profile from "./../middleware/profile";

export default [
  {
    path: "/crm/setting/stages-of-lead",
    name: "crm.setting.sol",
    component: route("crm/setting/stagesOfLead/index.vue"),
    meta: {
      title: "CRM | Stages of Lead",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },
  {
    path: "/crm/setting/sales-team",
    name: "crm.setting.sales-team",
    component: route("crm/setting/salesTeam/index.vue"),
    meta: {
      title: "CRM | Sales Team",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  }
]