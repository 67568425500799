<template>
  <a-layout-content>
    <page-header>
      <template #title> Login Sliders</template>

      <template #buttons>
        <a-button @click="handleCreateNewLoginSliderEvent" type="primary">
          <a-space><i class="ti ti-plus ti-lg"></i> Add Login Slider</a-space>
        </a-button>
      </template>
    </page-header>

    <a-spin :spinning="loading">
      <a-card v-if="dataSource.length > 0" size="small">
        <draggable
          @change="handleDragChangeEvent"
          :list="dataSource"
          handle=".handle"
          item-key="id"
        >
          <template #item="{ element }">
            <a-card class="announcement-card mb-sm-1" hoverable size="small">
              <a-row type="flex">
                <a-col flex="50px">
                  <div class="handle">
                    <DragOutlined :style="{ fontSize: '25px' }" />
                  </div>
                </a-col>
                <a-col flex="cacl(100% - 50px)">
                  <div class="header">
                    <a v-if="element.url" :href="element.url" target="_blank">
                      {{ element.title }}
                    </a>
                    <template v-else>
                      {{ element.title }}
                    </template>
                  </div>
                  <a-typography-text type="secondary">
                    {{ element.description }}
                  </a-typography-text>
                </a-col>
              </a-row>

              <div class="card-footer">
                <a-tooltip title="Edit">
                  <a-button
                    @click="handleEditLoginSliderEvent(element)"
                    size="small"
                    type="link"
                  >
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>

                <a-popconfirm
                  @confirm="handleDeleteLoginSliderEvent(element)"
                  cancel-text="No"
                  ok-text="Yes"
                  title="Are you sure you want to delete this announcement?"
                >
                  <a-tooltip placement="bottom" title="Delete">
                    <a-button danger size="small" type="link">
                      <i class="ti ti-trash ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </a-popconfirm>
              </div>
            </a-card>
          </template>
        </draggable>
      </a-card>

      <a-card v-else>
        <a-empty />
      </a-card>
    </a-spin>
  </a-layout-content>
</template>

<script setup>
import { useStore } from "vuex";
import draggable from "vuedraggable";
import { DragOutlined } from "@ant-design/icons-vue";
import { commonService } from "@/services";
import { ref } from "vue";
import { constants } from "@/helper/constants";
import { message } from "ant-design-vue";

const store = useStore();
const loading = ref(false);
const dataSource = ref([]);

const fetchLoginSlider = () => {
  commonService
    .get(constants.getAnnouncements)
    .then((res) => (dataSource.value = res.data))
    .finally(() => (loading.value = false));
};
fetchLoginSlider();

const handleCreateNewLoginSliderEvent = () => {
  store.dispatch("drawer/open", {
    title: "Add Login Slider",
    path: "admin.loginSlider.store",
    callback: fetchLoginSlider,
  });
};

const handleEditLoginSliderEvent = (record) => {
  store.dispatch("drawer/open", {
    title: "Edit Login Slider",
    path: "admin.loginSlider.store",
    callback: fetchLoginSlider,
    record: record,
  });
};

const handleDeleteLoginSliderEvent = (announcement) => {
  loading.value = true;
  commonService
    .store(constants.announcementChangeStatusUrl, { id: announcement.id })
    .then((res) => message.success(res.message))
    .finally((err) => fetchLoginSlider());
};

const handleDragChangeEvent = () => {
  loading.value = true;
  commonService
    .store(constants.announcementChangeOrderUrl, dataSource.value)
    .then((res) => message.success(res.message))
    .finally((err) => fetchLoginSlider());
};
</script>

<style lang="less">
.announcement-card {
  .handle {
    width: 50px;
  }

  .header {
    font-size: 18px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .card-footer {
    margin-left: 50px;
    display: none;
  }

  &:hover .card-footer {
    display: block;
  }
}
</style>
