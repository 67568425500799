import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";
import hasRoles from "./middleware/hasRoles";

export default [
  {
    path: "/hr/dashboard",
    name: "hr.dashboard",
    component: route("humanResource/dashboard"),
    meta: {
      title: "HR Dashboard",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },

  {
    path: "/hr-incidents",
    name: "hr-incident.index",
    component: route("humanResource/hrIncident/index"),
    meta: {
      title: "HR Incidents",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/hr-incident/store",
    name: "hr-incident.create",
    component: route("humanResource/hrIncident/store"),
    meta: {
      title: "HR Incident - Create",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/hr-incident/edit/:id",
    name: "hr-incident.edit",
    component: route("humanResource/hrIncident/store"),
    meta: {
      title: "HR Incident - Edit",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/hr-incident/show/:id",
    name: "hr-incident.show",
    component: route("humanResource/hrIncident/show"),
    meta: {
      title: "HR Incident - Show",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];