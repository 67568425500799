<template>
  <a-card title="Hires By Source" size="small">
    <div class="" id="hires-by-source" />
  </a-card>
</template>

<script setup>
import { Pie } from "@antv/g2plot";
import { onMounted, reactive, ref } from "vue";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";

const loading = ref(false);
const chart = ref("");
const data = ref([]);
const config = reactive({
  appendPadding: 10,
  angleField: "count",
  colorField: "name",
  radius: 0.9,
  // color: ["#f0595e", "#63DAAB"],
  legend: {
    position: "top",
    maxRow: 4,
    flipPage: false,
    itemValue: {
      formatter: (text) => {
        let item = data.value.find((e) => e.name == text);
        return item?.count ? `(${item.count})` : "";
      },
      style: {
        opacity: 0.65,
      },
    },
  },
  label: {
    type: "inner",
    offset: "10%",
    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: "center",
    },
  },
  interactions: [{ type: "element-active" }],
});

const renderChart = () => {
  loading.value = true;
  commonService
    .store(constants.hrHiresBySourceUrl)
    .then((res) => {
      data.value = res.data;
      chart.value.changeData(data.value);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  chart.value = new Pie("hires-by-source", {
    data: data.value,
    ...config,
  });
  chart.value.render();
  renderChart();
});
</script>
