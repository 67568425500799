<template>
  <div>
    <a-form
      name="manufacturer_form"
      ref="manufacturer_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item ref="email" label="Email" name="email">
            <a-select
              v-model:value="formState.email"
              mode="tags"
              style="width: 100%"
              placeholder="Enter email here"
              :options="options"
              @change="handleChange"
            >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item ref="number" label="Contact No" name="number">
            <a-input
              v-model:value="formState.number"
              v-maska="$constants.contactNumberMask"
              :placeholder="$constants.contactNumberPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="account_number"
            label="Account Number"
            name="account_number"
          >
            <a-input
              v-model:value="formState.account_number"
              placeholder="Enter account number here"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24">
          <a-form-item
            ref="vendor_address"
            label="Vendor Address"
            name="vendor_address"
          >
            <a-textarea
              v-model:value="formState.vendor_address"
              placeholder="Enter vendor address here"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24">
          <a-form-item
            ref="payment_terms"
            label="Payment Terms"
            name="payment_terms"
          >
            <a-textarea
              v-model:value="formState.payment_terms"
              placeholder="Enter payment terms here"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="quickbooks_vendor_id"
            label="QuickBooks Vendor"
            name="quickbooks_vendor_id"
          >
            <a-select
              v-model:value="formState.quickbooks_vendor_id"
              placeholder="Select quickbooks class"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in vendors"
                :key="option.Id"
                :label="option.DisplayName"
                :value="option.Id"
              >
                {{ option.DisplayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        name: "",
        email: [],
        number: "",
        vendor_address: "",
        payment_terms: "",
      },
      options: [],
      rules: {
        name: [
          {
            required: true,
            message: "this field is required!",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
        email: [
          {
            required: true,
            message: "this field is required!",
            type: "array",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        account_number: [
          {
            required: true,
            message: "this field is required!",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },

    vendors() {
      return this.extra.vendors;
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        email: this.record.email ? this.record.email.split(",") : [],
        number: this.record.number ?? "",
        account_number: this.record.account_number ?? "",
        vendor_address: this.record.vendor_address ?? "",
        payment_terms: this.record.payment_terms ?? "",
        quickbooks_vendor_id: this.record.quickbooks_vendor_id ?? undefined,
      };
    },

    onSubmit() {
      if (this.record.id) {
        this.formState.id = this.record.id;
      }

      this.loadingStart();
      this.formState.quickbooks_vendor_info = this.vendors.find(
        (item) => item.Id == this.formState.quickbooks_vendor_id
      );
      companyService
        .store(this.$constants.manufacturerStoreUrl, this.formState)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChange() {
      let emailLength = this.formState.email.length;
      if (emailLength > 0) {
        this.rules.email[0].fields = [];
        for (let index = 0; index < emailLength; index++) {
          this.rules.email[0].fields.push({
            type: "email",
            message: `invalid email!`,
          });
        }
      } else {
        this.rules.email[0].fields = [];
      }
    },
  },
};
</script>
