<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
    title="Time Tracking"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-row class="mb-sm-1" justify="space-between">
      <div v-if="activeTabKey == 2" style="display: flex">
        <a-select
          v-model:value="bulkActionValue"
          placeholder="Select any one"
          style="width: 150px; margin-right: 20px"
        >
          <a-select-option value="4"> Mark As Paid </a-select-option>
        </a-select>

        <a-button
          html-type="button"
          type="primary"
          :disabled="
            this.bulkActionValue == null || this.selectedRowKeys.length == 0
          "
          @click="bulkAction"
        >
          Submit
        </a-button>
      </div>
    </a-row>

    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="fetchData"
      size="small"
      :scroll="scroll"
    >
      <template #employment_type="{ record }">
        <a-tag
          :color="
            $comman.getValueFromObject(
              record.employee,
              'employment_type',
              $constants.employment_types,
              'color'
            )
          "
        >
          {{
            $comman.getValueFromObject(
              record.employee,
              "employment_type",
              $constants.employment_types
            )
          }}
        </a-tag>
      </template>

      <template #action="{ record }">
        <a-tooltip title="View">
        <a-button
          type="link"
          size="small"
          class="pl-sm-0 primary-color"
          @click="handleShowTimeTrackingEvent(record)"
        >
          <i class="ti ti-eye ti-lg"></i>
        </a-button>
      </a-tooltip>

        <template v-if="[1, 2].includes(record.status)">
          <router-link
            target="_blank"
            :to="{
              name: 'edit-employee-time-track',
              params: { id: record.id },
            }"
          >
          <a-tooltip title="Edit">
            <a-button type="link" size="small">
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>
          </router-link>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import { commonService } from "../../services";

export default {
  data() {
    return {
      filters: {
        date_range: [],
        search_text: "",
        page: 1,
        pageSize: 10,
        sortField: "start_date",
        sortOrder: "descend",
      },
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => record.employee?.full_name,
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",

          customRender: ({ record }) => record.hospital?.name,
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          slots: {
            customRender: "employment_type",
          },
        },
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          customRender: ({ record }) => record.shift_type?.name,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
        },
        {
          title: "Total Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        {
          title: " Total Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          customRender: ({ record }) =>
            this.$comman.withCurrency(
              parseFloat(
                record.payments.reduce(
                  (pre, curr) => pre + curr.total_amount,
                  0
                )
              ).toFixed(2)
            ),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: "1",
      tabList: this.$constants.timeTrackingStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      datatableLoading: true,
      dataSource: [],
      scroll: { x: true, y: 0 },
      pagination: {
        current: 1,
        size: "normal",
        pageSize: 10,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },

      selectedRowKeys: [],
      bulkActionValue: null,
    };
  },

  mounted() {
    this.fetchData();
  },

  computed: {
    rowSelection() {
      return ["2"].includes(this.activeTabKey)
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchData() {
      let data = {
        ...this.filters,
        status: this.activeTabKey,
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
      };

      this.datatableLoading = true;
      commonService
        .get(this.$constants.adminEmployeeTimeTrackDatatableUrl, data)
        .then((response) => {
          let res = response.data;
          this.pagination = { ...this.pagination, total: res.data.total };
          this.tabItemCounts(res.counts);
          this.dataSource = res.data.data;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.datatableLoading = false));
    },

    tabItemCounts(counts) {
      if (this.tabList) {
        this.tabList.forEach((v, i) => {
          v.counts = counts[v.status] ?? 0;
        });
      }
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination;
      this.filters = {
        ...this.filters,
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortField: sorter.order ? sorter.field : undefined,
        sortOrder: sorter.order,
      };
      this.fetchData();
    },

    reset() {
      this.filters.date_range = [];
      this.filters.search_text = "";
      this.filters.page = 1;
      this.fetchData();
    },

    handleShowTimeTrackingEvent(record) {
      this.open({
        title: "Show Time Tracking Details",
        path: "admin.employeeTimeTrack.show",
        callback: this.fetchData,
        record: record,
      });
    },

    handleExportEvent(type) {
      let url = this.$constants.adminEmployeeTimeTrackExportPdfUrl;
      let fileName = "Employee Time Tracking.pdf";
      if (type == "excel") {
        url = this.$constants.adminEmployeeTimeTrackExportExcelUrl;
        fileName = "Employee Time Tracking.xlsx";
      }

      var params = {
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
      };

      this.datatableLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    bulkAction() {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminEmployeeTimeTrackBulkActionUrl, {
          ids: this.selectedRowKeys,
          status: this.bulkActionValue,
        })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.fetchData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    activeTabKey() {
      this.pagination = { ...this.pagination, current: 1 };
      this.filters.page = 1;
      this.fetchData();
    },
  },
};
</script>
