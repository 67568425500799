<template>
  <div>
    <a-descriptions
      :column="{ sm: 2, xs: 1 }"
      size="small"
      bordered
      :labelStyle="{ width: '18%' }"
      :contentStyle="{ width: '32%' }"
    >
      <a-descriptions-item :span="2" label="Hospital Name">
        {{ record.hospital?.name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Employee Name">
        {{ record.employee?.full_name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Expense Type">
        {{ record.expense_type?.name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Date">
        {{ $customDate.ll(record.date) }}
      </a-descriptions-item>
      <a-descriptions-item label="Exp. Start Date">
        {{ $customDate.ll(record.start_date) }}
      </a-descriptions-item>
      <a-descriptions-item label="Exp. End Date">
        {{ $customDate.ll(record.end_date) }}
      </a-descriptions-item>
      <a-descriptions-item label="Amount">
        {{
          record.amount || record.amount == 0
            ? $comman.withCurrency(record.amount)
            : "N/A"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Description">
        {{ record.description ?? "N/A" }}
      </a-descriptions-item>
      <template v-if="record.expense_type_id == 1 && extraInfo">
        <a-descriptions-item label="Name of Organization / Sponsor">
          {{ extraInfo.organization_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Subject / Purpose">
          {{ extraInfo.subject }}
        </a-descriptions-item>
        <a-descriptions-item label="City">
          {{ extraInfo.city }}
        </a-descriptions-item>
        <a-descriptions-item label="State">
          {{ extraInfo.state }}
        </a-descriptions-item>
      </template>
      <template v-if="record.expense_type_id == 9 && extraInfo">
        <a-descriptions-item label="Origin Address">
          {{ extraInfo.origin_addresses[0] }}
        </a-descriptions-item>
        <a-descriptions-item label="Destination Address">
          {{ extraInfo.destination_addresses[0] }}
        </a-descriptions-item>
        <a-descriptions-item label="Distance In Miles">
          {{ distanceInMiles }}
        </a-descriptions-item>
        <a-descriptions-item label="Time In Minutes">
          {{ extraInfo.rows[0].elements[0].duration.text }}
        </a-descriptions-item>
        <a-descriptions-item label="Trip Type">
          {{ tripTypeText }}
        </a-descriptions-item>
        <a-descriptions-item label="Mileage">
          <strong>{{ distanceInMiles * extraInfo.trip_type }}</strong>
        </a-descriptions-item>
      </template>
    </a-descriptions>
    <template v-if="record.receipts?.length > 0">
      <a-divider orientation="left">Receipts</a-divider>
      <template v-for="receipt in record.receipts" :key="receipt.id">
        <a :href="receipt.full_file_path" target="_blank">
          {{ receipt.file_name_with_ext }}
        </a>
        <br />
      </template>
    </template>
    <template v-if="record.status != 1">
      <a-divider orientation="left">Status</a-divider>
      Your Expense has been {{ record.status == 3 ? "rejected" : "approved" }}.
      Please direct any questions to expenses@keystoneperfusion.com.
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    extraInfo() {
      if (this.record.extra_info) {
        let res = JSON.parse(this.record.extra_info);
        if (this.record.expense_type_id == 9 && !("trip_type" in res)) {
          res.trip_type = 2;
        }
        return res;
      }
      return null;
    },

    distanceInMiles() {
      return this.$comman.getDistanceInMiles(
        this.extraInfo.rows[0].elements[0].distance.value
      );
    },

    tripTypeText() {
      return this.$comman.getValueFromObject(
        this.extraInfo,
        "trip_type",
        this.$constants.expenseTripTypes
      );
    },
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
