<template>
  <a-layout-content>
    <page-header>
      <template #title>
        {{ title }}
      </template>
      <template #buttons>
        <back-button
          :listeners="true"
          @handleClick="$router.push({ name: 'paid-time-off' })"
        />
      </template>
    </page-header>

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date_range"
              label="Start & End Dates"
              name="date_range"
            >
              <a-range-picker
                :allowClear="false"
                v-model:value="model.date_range"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                @change="calculateHours"
                inputReadOnly
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item ref="type" label="Type of Policy" name="type">
              <a-select
                v-model:value="model.type"
                placeholder="Select Type"
                show-search
                optionFilterProp="label"
                :loading="spinning"
              >
                <template v-if="spinning" #notFoundContent>
                  <a-spin size="small" />
                </template>

                <a-select-option
                  v-for="type in ptoOptions"
                  :key="type.value"
                  :label="`${type.label} [Balance: ${type.hours}]`"
                  :value="type.value"
                >
                  {{ type.label }} [Balance: {{ type.hours }}]
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!-- <a-col :span="12">
            <a-form-item ref="start_time" label="Start Time" name="start_time">
              <a-input
                v-model:value="model.start_time"
                v-maska="$constants.timeMaska"
                placeholder="HH:mm"
              />
            </a-form-item>
          </a-col> -->

          <a-col :sm="12">
            <a-form-item
              ref="hours_per_day"
              label="Hours Per Day"
              name="hours_per_day"
            >
              <a-input-number
                v-model:value="model.hours_per_day"
                placeholder="Enter here..."
                type="number"
                disabled
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item ref="comments" label="Comments" name="comments">
              <a-textarea
                v-model:value="model.comments"
                placeholder="Enter comment here..."
                :auto-size="{ minRows: 3, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
          {{ submitBtnText }}
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { validation } from "../../helper/validation";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton },

  data() {
    return {
      cardLoader: true,
      submitBtnLoader: false,
      spinning: false,
      model: {
        id: this.$route.params.id ?? 0,
        employee_id: 0,
        date_range: [],
        type: undefined,
        start_time: "10:00",
        hours_per_day: null,
        hours: 0,
        comments: null,
      },
      ptoHoursPerDay: null,
      ptoOptions: [],
      rules: {
        date_range: [
          {
            required: true,
            message: "Start and end date field is required!",
            type: "array",
          },
        ],
        type: [
          {
            required: true,
            message: "Type field is required!",
          },
        ],
        start_time: [
          {
            required: true,
            message: "Start time field is required!",
          },
          {
            message: "Start time field is required!",
            validator: validation.timeFormat,
          },
        ],
        hours_per_day: [
          {
            required: true,
            message: "Type field is required!",
            type: "number",
          },
        ],
        comments: [
          {
            required: false,
            message: "Comment field is required!",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Add New Paid Time Off";
      }
      return "Edit Paid Time Off";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  async mounted() {
    if (this.model.id != 0) {
      await this.fetchPaidTimeOff();
    }
    this.cardLoader = false;
  },

  methods: {
    fetchPaidTimeOff() {
      return commonService
        .get(this.$constants.paidTimeOffShowUrl, { id: this.model.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.push({
              name: "paid-time-off",
            });
            return;
          }

          let data = res.data;

          this.model = {
            id: this.model.id,
            employee_id: data.employee_id,
            date_range: [moment(data.start_date), moment(data.end_date)],
            type: data.type,
            start_time: data.start_time,
            hours_per_day: data.hours_per_day,
            hours: data.hours,
            comments: data.comments,
          };
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    async fetchRemainingPto() {
      this.spinning = true;
      this.ptoOptions = [];
      if (this.model.id == 0) {
        this.model.type = undefined;
      }
      return commonService
        .get(this.$constants.getRemainingPtoUrl, {
          asofdate: this.$customDate.ymd(this.model.date_range[0]),
        })
        .then((res) => {
          this.ptoHoursPerDay = res.pto_hours_per_day;
          this.model.hours_per_day = this.ptoHoursPerDay;
          console.log(this.model);
          this.ptoOptions = res.data.map((e) => {
            let name =
              e.policyBalances[0]?.balanceType?.labelName ?? "As required";
            let hours = e.policyBalances[0]?.totalQuantity?.valueNumber ?? 0;

            return {
              label: "PTO",
              value: "PTO",
              code: e.paidTimeOffPolicy.code,
              hours,
            };
          });
        })
        .finally(() => (this.spinning = false));
    },

    calculateHours() {
      if (
        this.model.date_range.length == 2 &&
        this.model.hours_per_day != null
      ) {
        this.model.hours =
          (this.model.date_range[1].diff(this.model.date_range[0], "days") +
            1) *
          this.model.hours_per_day;
      } else {
        this.model.hours = 0;
      }
    },

    handleFinish() {
      this.calculateHours();

      let selectedPtoOption = this.ptoOptions.find(
        (e) => this.model.type == e.value
      );

      let data = { ...this.model };
      data.employee_id = this.employee.id;
      data.start_date = this.$customDate.ymd(this.model.date_range[0]);
      data.end_date = this.$customDate.ymd(this.model.date_range[1]);
      delete data.date_range;

      if (selectedPtoOption) {
        data.policy_code = selectedPtoOption.code;
        if (selectedPtoOption.hours < data.hours) {
          this.$message.error("Requested hours is more then balance hours!");
          return;
        }
      }

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.paidTimeOffStoreUrl, data)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.push({ name: "paid-time-off" });
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        })
        .finally(() => (this.submitBtnLoader = false));
    },
  },

  watch: {
    "model.date_range": {
      handler: function (val) {
        if (val[0]) {
          this.fetchRemainingPto();
        } else {
          this.ptoOptions = [];
        }
      },
      deep: true,
    },
  },
};
</script>
