<template>
  <a-popover
    v-model:visible="visible"
    trigger="click"
    placement="bottomRight"
    overlayClassName="overlay-popover-class"
  >
    <template #title>
      <a-row :gutter="[20]" justify="space-between">
        <a-col style="padding: 0"> Select Columns</a-col>
        <a-col style="padding: 0">
          <a-tooltip title="Close">
            <a-button
              class="btn-text-red"
              size="small"
              type="link"
              @click="hide"
            >
              <CloseOutlined />
            </a-button>
          </a-tooltip>
        </a-col>
      </a-row>
    </template>

    <template #content>
      <a-checkbox
        :checked="isAllColumnShow"
        :indeterminate="indeterminate"
        @change="onCheckAllChange"
      >
        Check all
      </a-checkbox>
      <a-divider style="margin: 5px 0" />
      <a-row v-for="column in columns" :key="column.title">
        <a-checkbox
          v-model:checked="column.show"
          @change="() => emit('update:modelValue', columns)"
        >
          {{ column.title }}
        </a-checkbox>
      </a-row>
    </template>

    <a-button class="btn-amber" type="primary">
      <SettingOutlined />
      Columns
    </a-button>
  </a-popover>
</template>

<script setup>
import { ColumnProps } from "ant-design-vue/lib/table/interface";
import { ref, computed } from "vue";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  modelValue: { required: true },
});
const visible = ref(false);
const hide = () => (visible.value = false);
const emit = defineEmits(["update:modelValue"]);

const columns = computed({
  get() {
    return props.modelValue.map((e) => ({ ...e, show: e.show ?? true }));
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const isAllColumnShow = computed(() => {
  return columns.value.reduce((bool, curr) => bool && curr.show, true);
});

const indeterminate = computed(() => {
  let filtered = columns.value.filter((e) => e.show);
  return filtered.length > 0 && filtered.length != columns.value.length;
});

const onCheckAllChange = (val) => {
  columns.value = columns.value.map((e) => ({
    ...e,
    show: val.target.checked,
  }));
};
</script>
