<template>
  <a-card title="Email Notification" size="small" :loading="loading">
    <a-row :gutter="[16, 16]">
      <template v-for="(data, index) in settingData" :key="data.type">
        <setting-vue
          :notificationSettings="notificationSettings"
          :title="data.title"
          :description="data.description"
          :type="data.type"
          :employeeId="parseInt($route.params.id)"
          @updateSettings="(data) => (notificationSettings = data)"
        />

        <a-divider v-if="index + 1 != settingData.length" class="my-sm-0" />
      </template>
    </a-row>
  </a-card>
</template>

<script>
import { commonService } from "../../../services";
import settingVue from "../components/setting.vue";

export default {
  components: { settingVue },

  data() {
    return {
      loading: true,
      notificationSettings: [],
      settingData: this.$constants.employeeNotificationSettings,
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      commonService
        .store(this.$constants.employeeGetNotificationUrl, {
          employee_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.notificationSettings = res.data;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.loading = false));
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.fetchData();
      }
    },
  },
};
</script>
