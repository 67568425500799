<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          Employee Handbook
        </template>
        <template #buttons>
          <a-button
            v-if="handbook"
            type="primary"
            :href="handbook.full_file_path"
            target="_blank"
          >
            Open in new tab
          </a-button>
        </template>
      </page-header>
      <iframe
        v-if="handbook"
        :src="handbook.full_file_path"
        width="100%"
        height="600"
      >
      </iframe>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "../../services";
export default {
  data() {
    return {
      handbook: null,
    };
  },

  mounted() {
    this.fetchLastHandbook();
  },

  methods: {
    fetchLastHandbook() {
      commonService.get(this.$constants.employeeHandbookUrl).then((res) => {
        this.handbook = res.data;
      });
    },
  },
};
</script>
