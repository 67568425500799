<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24">
      <a-form-item
        name="blood_sequestered"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required and must be between 10 to 9999.',
            min: 10,
            max: 9999,
          },
        ]"
      >
        <template #label>
          Blood Sequestered
          <a-popover placement="topRight" :overlayStyle="{ maxWidth: '600px' }">
            <template #title>
              <b>Calculating volume of blood to be collected:</b>
            </template>

            <template #content>
              <ol>
                <li>
                  The patient's ideal body weight in kilograms (kg) is needed in
                  order to calculate the estimated blood volume (EBV). This
                  reflects the total circulating blood volume in the vascular
                  tree. This is approximately 70 cc/kg for adult females and 75
                  cc/kg in adult males. For example, a 90 kg male patient might
                  be expected to have an EBV of 90 X 75 cc = 6750 cc
                </li>
                <li>
                  The baseline, initial or starting hemoglobin (Hb)
                  concentration.
                </li>
                <li>
                  The target Hb for ANH. This is typically a value that reflects
                  a level of Hb that is safe and tolerated by the patient
                  without adverse effects.
                </li>
                <li>
                  Average Hb = the baseline and target Hb summed and divided by
                  a factor of 2.
                </li>
                <li>
                  <b>
                    Standard ANH equation: Volume of Blood that may be
                    sequestered using ANH =
                    <br />
                    <br />
                    Baseline Hb - target Hb/ Average Hb X EBV
                  </b>
                </li>
              </ol>
              Example, take a male patient weighing 90 kg with a starting Hb of
              14 g/dl. We elect that a Hb of 10 g/dl is a safe end-point for
              ANH. The volume of blood that may be collected = 14-10/12 X 6750 =
              2,250 cc.
            </template>
            <a-button size="small" type="link">
              <i class="ti ti-info-circle ti-lg"> </i>
            </a-button>
          </a-popover>
        </template>
        <a-input-number
          v-model:value="model.blood_sequestered"
          placeholder="Blood Sequestered"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24">
      <a-form-item
        label="What type of anticoagulation used?"
        name="anticoagulation_type"
        :rules="[
          {
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-select
          v-model:value="model.anticoagulation_type"
          placeholder="Anticoagulation type"
          show-search
          optionFilterProp="label"
        >
          <a-select-option
            v-for="option in $constants.typeOfAnticoagulations"
            :key="option.value"
            :label="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "case-report-step-12",
  inheritAttrs: false,
  props: ["step", "model", "hospitals"],
  emits: ["validate"],

  data() {
    return {
      uuid: this.$route?.query?.uuid ?? null,
    };
  },

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },
  },
};
</script>
