<template>
  <div class="birthdate-comp">
    <a-card>
      <Splide
        :options="{
          type: 'loop',
          rewind: false,
          pagination: true,
          autoplay: true,
        }"
        aria-label="My Favorite Images"
        :has-track="false"
      >
        <div style="position: relative">
          <SplideTrack>
            <SplideSlide
              v-for="(value, index) in birthdayNames"
              :key="`birthdate_${index}`"
            >
              <a-row>
                <div class="img">
                  <img :src="birthdateImage(index)" alt="" width="200" />
                </div>
                <div class="text">
                  Don't forget to wish <strong>{{ value["name"] }}</strong> a
                  Happy Birthday today!
                </div>
              </a-row>
            </SplideSlide>
            <SplideSlide
              v-for="(value, index) in workAnniversaryNames"
              :key="`anniversary_${index}`"
            >
              <a-row>
                <div class="img">
                  <img :src="workAnniversaryImage(index)" alt="" width="200" />
                </div>
                <div class="text">
                  <template v-if="totalYear(value['hire_date'])">
                    Show <strong>{{ value["name"] }}</strong> your appreciation
                    and congratulate them on their
                    <strong>
                      {{ ordinalSuffixOf(totalYear(value["hire_date"])) }}
                    </strong>
                    anniversary!
                  </template>
                  <template v-else>
                    <strong>{{ value["name"] }}</strong> is starting today! Make
                    sure to say "Hi" and welcome them to the team.
                  </template>
                </div>
              </a-row>
            </SplideSlide>
          </SplideTrack>
        </div>

        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </Splide>
    </a-card>
  </div>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import moment from "moment";
import { mapGetters } from "vuex";
import birthdate1 from "./../../assets/birthday-1.svg";
import birthdate2 from "./../../assets/birthday-2.svg";
import workAnniversary1 from "./../../assets/work-anniversary-1.svg";
import workAnniversary2 from "./../../assets/work-anniversary-2.svg";

export default {
  components: {
    Splide,
    SplideSlide,
    SplideTrack,
  },

  computed: {
    ...mapGetters("auth", ["birthdayNames", "workAnniversaryNames"]),
  },

  methods: {
    totalYear(date) {
      return moment().diff(date, "years");
    },

    ordinalSuffixOf(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    birthdateImage(i) {
      return i % 2 == 0 ? birthdate1 : birthdate2;
    },

    workAnniversaryImage(i) {
      return i % 2 == 0 ? workAnniversary1 : workAnniversary2;
    },
  },
};
</script>

<style lang="less">
.birthdate-comp {
  margin-bottom: 10px;

  .ant-card {
    .ant-card-body {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .splide {
      .splide__pagination {
        .splide__pagination__page.is-active {
          background: var(--ck-color-base-active);
        }
      }
    }
  }
  .ant-row {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    .text {
      font-size: 18px;
      text-align: center;
      strong {
        color: var(--ck-color-base-active);
      }
    }
  }
}
</style>
