import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/expenses",
    name: "expenses",
    component: route("expense/index"),
    meta: {
      title: "Expenses",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/expense/create",
    name: "create-expense",
    component: route("expense/store"),
    meta: {
      title: "Create Expense",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/expense/edit/:id",
    name: "edit-expense",
    component: route("expense/store"),
    meta: {
      title: "Edit Expense",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/expense/show/:id",
    name: "show-expense",
    component: route("expense/show"),
    meta: {
      title: "Show Expense",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
