<template>
  <div class="profile-page">
    <a-layout-content>
      <page-header>
        <template #title> Profile</template>
      </page-header>

      <!--      <a-alert
        v-if="employee.hippa_compliance_agreed_at"
        type="success"
        show-icon
        class="mb-sm-1"
      >
        <template #description>
          <a-row type="flex" justify="space-between">
            <a-typography-text>
              You have agreed to the HIPAA Employee Confidentiality Agreement
              on 
              <strong>
                {{ $customDate.mdyhis(employee.hippa_compliance_agreed_at) }}
              </strong>
            </a-typography-text>
            <a-button
              size="small"
              type="link"
              :loading="baaDownloading"
              @click="downloadBAA"
            >
              <i class="ti ti-download ti-lg"></i>
            </a-button>
          </a-row>
        </template>
      </a-alert>

      <a-alert
        v-if="employee.handbook_agreed_at"
        type="success"
        show-icon
        class="mb-sm-1"
      >
        <template #description>
          <a-row type="flex" justify="space-between">
            <a-typography-text>
              You have successfully acknowledged the Employee Handbook on
              <strong>
                {{ $customDate.mdyhis(employee.handbook_agreed_at) }}
              </strong>
            </a-typography-text>
            <a-button
              size="small"
              type="link"
              :loading="handbookDownloading"
              @click="downloadHandbook"
            >
              <i class="ti ti-download ti-lg"></i>
            </a-button>
          </a-row>
        </template>
      </a-alert>-->

      <a-alert
        v-if="employee.agreement_accepted_at"
        type="success"
        show-icon
        class="mb-sm-1"
      >
        <template #description>
          <a-row type="flex" justify="space-between">
            <a-typography-text>
              You have agreed to the Acceptable Use Agreement for KeyPort on
              <strong>
                {{ $customDate.mdyhis(employee.agreement_accepted_at) }}
              </strong>
            </a-typography-text>
          </a-row>
        </template>
      </a-alert>

      <a-alert
        v-if="pendingTasks.length > 0 && employee.agreement_accepted_at"
        type="warning"
        show-icon
        class="mb-sm-1"
      >
        <template #message>
          <a-typography-text strong> Notice</a-typography-text>
        </template>

        <template #description>
          Following items are pending and need to be completed before you can
          access your account. All below documents are subject to approval.

          <ul class="mb-sm-0">
            <li v-for="task in pendingTasks">
              <span v-if="task.includes('*')">
                {{ task.split("*")[0] }}
                <a-typography-text type="danger" strong>*</a-typography-text>
                {{ task.split("*")[1] }}
              </span>
              <span v-else>{{ task }}</span>
            </li>
          </ul>
        </template>
      </a-alert>

      <a-card title="" class="profile-card">
        <a-row>
          <a-col :xs="24" :lg="0">
            <a-menu
              v-model:selectedKeys="selectedKeys"
              theme="light"
              mode="horizontal"
            >
              <a-menu-item
                v-for="m in menu"
                :key="m.key"
                :class="menuItemStyle(m)"
              >
                {{ m.text }}
                <span class="alert-warning-pulse">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(235,0,0,1)"
                  >
                    <path
                      d="M4.00001 20V14C4.00001 9.58172 7.58173 6 12 6C16.4183 6 20 9.58172 20 14V20H21V22H3.00001V20H4.00001ZM6.00001 14H8.00001C8.00001 11.7909 9.79087 10 12 10V8C8.6863 8 6.00001 10.6863 6.00001 14ZM11 2H13V5H11V2ZM19.7782 4.80761L21.1924 6.22183L19.0711 8.34315L17.6569 6.92893L19.7782 4.80761ZM2.80762 6.22183L4.22183 4.80761L6.34315 6.92893L4.92894 8.34315L2.80762 6.22183Z"
                    ></path>
                  </svg>
                </span>
              </a-menu-item>
            </a-menu>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xs="0" :lg="4">
            <a-menu
              v-model:selectedKeys="selectedKeys"
              theme="light"
              mode="inline"
            >
              <a-menu-item
                v-for="m in menu"
                :key="m.key"
                :class="menuItemStyle(m)"
              >
                {{ m.text }}
                <span class="alert-warning-pulse">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(235,0,0,1)"
                  >
                    <path
                      d="M4.00001 20V14C4.00001 9.58172 7.58173 6 12 6C16.4183 6 20 9.58172 20 14V20H21V22H3.00001V20H4.00001ZM6.00001 14H8.00001C8.00001 11.7909 9.79087 10 12 10V8C8.6863 8 6.00001 10.6863 6.00001 14ZM11 2H13V5H11V2ZM19.7782 4.80761L21.1924 6.22183L19.0711 8.34315L17.6569 6.92893L19.7782 4.80761ZM2.80762 6.22183L4.22183 4.80761L6.34315 6.92893L4.92894 8.34315L2.80762 6.22183Z"
                    ></path>
                  </svg>
                </span>
              </a-menu-item>
            </a-menu>
          </a-col>
          <a-col :xs="24" :lg="20">
            <div class="profile-content">
              <a-row>
                <a-col :xs="0" :lg="24">
                  <h2>{{ selectedMenu }}</h2>
                  <a-divider />
                </a-col>
              </a-row>
              <component
                :is="selectedKeys[0]"
                :companies="companies"
                @refreshCertificateData="updateCertificates"
              />
            </div>
          </a-col>
        </a-row>
      </a-card>

      <a-modal
        v-model:visible="showRequiredDocumentModal"
        centered
        :closable="false"
        :maskClosable="false"
        :footer="false"
        :keyboard="false"
      >
        <a-space
          :size="20"
          direction="vertical"
          align="center"
          style="width: 100%"
        >
          <a-typography-title type="warning" :level="3" class="mb-sm-0">
            <exclamation-circle-outlined />
          </a-typography-title>

          <a-space :size="1" direction="vertical" align="center">
            <a-typography-title
              type="danger"
              :level="5"
              style="text-align: center"
            >
              {{ formattedModalMessage }}
            </a-typography-title>
          </a-space>
        </a-space>

        <a-divider />

        <div class="model-footer" style="text-align: center">
          <a-button
            type="primary"
            @click="() => (showRequiredDocumentModal = false)"
          >
            Ok
          </a-button>
        </div>
      </a-modal>

      <a-modal
        v-model:visible="showSomeCertificatePending"
        centered
        :closable="false"
        :maskClosable="false"
        :footer="false"
        :keyboard="false"
      >
        <a-space
          :size="20"
          direction="vertical"
          align="center"
          style="width: 100%"
        >
          <a-typography-title type="warning" :level="3" class="mb-sm-0">
            <exclamation-circle-outlined />
          </a-typography-title>

          <a-space :size="1" direction="vertical" align="center">
            <a-typography-title
              type="danger"
              :level="5"
              style="text-align: center"
            >
              The uploaded credentials are pending approval. Once approved, we
              will notify you via email, and you will gain full access.
            </a-typography-title>
          </a-space>
        </a-space>

        <a-divider />

        <div class="model-footer" style="text-align: center">
          <a-button
            type="primary"
            @click="() => (showSomeCertificatePending = false)"
          >
            Ok
          </a-button>
        </div>
      </a-modal>
    </a-layout-content>
  </div>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { commonService, companyService } from "../../services";
import basic_info from "./basic_info";
import certifications from "./certificate/index";
import change_password from "./change_password";
import documents from "./documents";
import fw9 from "./fw9.vue";
import i9 from "./i9.vue";
import master_settings from "./master_settings";
import notifications from "./notifications";
import onboarding from "./onboarding";
import social_security from "./social_security";

export default {
  components: {
    ExclamationCircleOutlined,
    basic_info,
    social_security,
    change_password,
    master_settings,
    certifications,
    notifications,
    documents,
    onboarding,
    fw9,
    i9,
  },
  data() {
    return {
      baaDownloading: false,
      handbookDownloading: false,
      selectedKeys: ["basic_info"],
      companies: {},

      certificates: [],
      certificateTypes: [],
      formState: {},

      sidebar: false,
      documentWidth: 0,
      showRequiredDocumentModal: false,
      isDoneLoading: false,
      showSomeCertificatePending: false,
    };
  },

  computed: {
    ...mapGetters("auth", [
      "employee",
      "isAdmin",
      "canShowOnboardingTab",
      "formI9",
      "formW9",
    ]),

    formattedModalMessage() {
      if (!this.isDoneLoading) return "Loading...";

      let msg = "There are ";

      // demo
      // msg +=
      //   "outstanding credentialing documents that need to be uploaded, unsigned items in Onboarding, pending " +
      //   "Form W-9 submission, pending Form I-9 submission, which need to be fulfilled before you can " +
      //   "proceed. Click on credentialing tab to begin uploading your required documents.";
      //
      // return msg;

      if (!this.showApprovalPendingNotice) {
        msg += "outstanding credentialing documents that need to be uploaded";
      }

      if (this.canShowOnboardingTab) {
        if (!this.showApprovalPendingNotice) {
          msg += ", ";
        }
        msg += "unsigned items in Onboarding";
      }

      if (this.isContractor && !this.formW9) {
        if (!this.showApprovalPendingNotice || this.canShowOnboardingTab) {
          msg += ", ";
        }
        msg += "pending Form W-9 submission";
      }

      if (!this.formI9) {
        if (
          !this.showApprovalPendingNotice ||
          this.canShowOnboardingTab ||
          (this.isContractor && !this.formW9)
        ) {
          msg += ", ";
        }
        msg += "pending Form I-9 submission";
      }

      msg += ", which need to be fulfilled before you can proceed.";

      if (this.showApprovalPendingNotice) {
        msg +=
          "The uploaded credentials are pending approval. Once approved, we will notify you via email.";
      }

      if (!this.isAllRequiredDocumentsUploaded) {
        msg +=
          "Click on credentialing tab to begin uploading your required documents.";
      }

      return msg;
    },

    pendingTasks() {
      const isFormI9Pending = !this.formI9;
      const isFormW9Pending = this.isContractor && !this.formW9;
      const hasRequiredDocumentMissingUpload =
        !this.isAllRequiredDocumentsUploaded;
      const isOnboardingPending = this.canShowOnboardingTab;
      const isCredentialApprovalPending = this.showApprovalPendingNotice;

      const messagesHolder = {
        isFormI9Pending: "Form I-9 is pending for submission",
        isFormW9Pending: "Form W-9 is pending for submission",
        isAllRequiredDocumentsUploaded:
          "Required credentialing documents(marked with *) are pending for submission",
        isOnboardingPending:
          "Onboarding tab have items to be signed and submitted",
        isCredentialApprovalPending:
          "Credentialing documents are pending approval. You will be notified via email once approved",
      };

      const holder = [];
      if (isFormI9Pending) holder.push(messagesHolder.isFormI9Pending);
      if (isFormW9Pending) holder.push(messagesHolder.isFormW9Pending);
      if (hasRequiredDocumentMissingUpload)
        holder.push(messagesHolder.isAllRequiredDocumentsUploaded);
      if (isOnboardingPending) holder.push(messagesHolder.isOnboardingPending);
      if (isCredentialApprovalPending)
        holder.push(messagesHolder.isCredentialApprovalPending);

      return holder;
    },

    // hasSubmittedAndPendingForApproval() {},

    isSomeCertificatesPending() {
      return this.certificates.some((certificate) => {
        return certificate.approval_status === 0;
      });
    },

    isAllRequiredDocumentsUploaded() {
      const requiredCerts = this.certificateTypes?.filter((certificate) => {
        return this.checkRequiredCertificate(certificate);
      });

      let bool = requiredCerts.every((certificate) => {
        return (
          certificate.employeeCertificates?.filter((cert) => {
            return [0, 1].includes(cert.approval_status);
          }).length > 0
        );
      });

      return bool;
    },

    showUploadDocumentNotice() {
      let bool =
        // this.employee?.hippa_compliance_agreed_at &&
        // this.employee?.handbook_agreed_at &&
        this.employee?.agreement_accepted_at &&
        this.employee?.profile_status === 0 &&
        !this.isAdmin &&
        (!this.isAllRequiredDocumentsUploaded || !this.formI9);

      if (this.isContractor) {
        bool = bool || !this.formW9;
      }

      return bool;
    },

    isEmployeeIdOne() {
      return this.employee?.id === 1;
    },

    isContractor() {
      return this.employee.employment_type === 2;
    },

    showApprovalPendingNotice() {
      return (
        // this.isAllRequiredDocumentsUploaded &&
        this.isSomeCertificatesPending && this.employee?.profile_status === 0
      );
    },

    menu() {
      return [
        { key: "basic_info", text: "Basic Details" },
        { key: "change_password", text: "Change Password" },
        { key: "certifications", text: "Credentialing" },
        { key: "notifications", text: "Email Notifications" },
        ...(this.canShowOnboardingTab
          ? [{ key: "onboarding", text: "Onboarding" }]
          : []),
        { key: "documents", text: "Documents" },
        ...(this.isEmployeeIdOne
          ? [{ key: "master_settings", text: "Master Settings" }]
          : []),
        ...(this.isContractor && !this.formW9
          ? [{ key: "fw9", text: "Form W-9" }]
          : []),
        ...(!this.formI9 ? [{ key: "i9", text: "Form I-9" }] : []),
      ];
    },

    selectedMenu() {
      const that = this;
      let text = "";
      this.menu.forEach((e) => {
        if (e.key == that.selectedKeys[0]) {
          text = e.text;
        }
      });
      return text;
    },
  },

  created() {
    this.$comman.get_companies().then((res) => {
      console.log({ res });
      this.companies = res;
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.checkRequiredDocumentModal();
    this.$comman
      .get_certification_types()
      .then((res) => {
        this.certificateTypes = res;
        this.certificateTypes.forEach((v) => {
          this.formState[v.id] = {
            expiry_date: "",
            attachment: "",
          };
        });
      })
      .then(() => {
        this.getEmployeeCertificates().then(() => {
          this.isDoneLoading = true;
          this.checkRequiredDocumentModal();
        });
      });
  },

  methods: {
    checkForPendingCertificateModal() {
      if (this.showApprovalPendingNotice) {
        this.showRequiredDocumentModal = false;
        this.showSomeCertificatePending = true;
        this.isDoneLoading = true;
      }
    },

    updateCertificates(certificates) {
      if (!certificates) return;
      this.certificates = certificates.filter((cert) => {
        if (!cert.expiry_date) return true;
        return moment(cert.expiry_date).isAfter(moment().subtract(1, "days"));
      });

      this.certificateTypes.forEach((value, index) => {
        this.certificateTypes[index].employeeCertificates =
          this.certificates.filter((d) => d.certification_type_id === value.id);
      });

      // this.checkForPendingCertificateModal();
    },

    checkRequiredCertificate(cer) {
      const employeeTypesCols = this.$constants.employee_types.reduce(
        (holder, currentEmpType) => {
          holder[currentEmpType.value] = currentEmpType.key;
          return holder;
        },
        {}
      );

      // const employeeTypesCols = {
      //   1: 'for_clinical',
      //   2: 'for_non_clinical',
      //   ...
      // }

      return cer[employeeTypesCols[this.employee.employee_type]];
    },

    getEmployeeCertificates() {
      return companyService
        .store(this.$constants.getEmployeeCertificates, {})
        .then((res) => {
          if (res.success) {
            this.updateCertificates(res.data.credentials);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleResize() {
      this.documentWidth = window.innerWidth;
    },

    downloadBAA() {
      this.baaDownloading = true;
      commonService
        .downloadFile(
          this.$constants.exportHippaComplincePdf,
          null,
          "hippa_compliance.pdf"
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.baaDownloading = false));
    },

    downloadHandbook() {
      this.handbookDownloading = true;
      commonService
        .downloadFile(
          this.$constants.exportHandbookPdf,
          null,
          "handbook_agreement.pdf"
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.handbookDownloading = false));
    },

    checkRequiredDocumentModal() {
      if (this.showUploadDocumentNotice) {
        this.showRequiredDocumentModal = true;
      } else {
        // this.checkForPendingCertificateModal();
      }
    },

    menuItemStyle({ key }) {
      switch (key) {
        case "certifications":
          return !this.isAllRequiredDocumentsUploaded
            ? "ant-menu-item-highlight"
            : "";
        case "fw9":
          return this.isContractor && !this.formW9
            ? "ant-menu-item-highlight"
            : "";
        case "i9":
          return !this.formI9 ? "ant-menu-item-highlight" : "";
        default:
          return "";
      }
    },
  },

  watch: {
    documentWidth(val) {
      this.sidebar = val > 1139;
    },

    canShowOnboardingTab(val) {
      if (!val) {
        this.selectedKeys = ["documents"];
      }
    },

    showRequiredDocumentModal(val) {
      if (val) {
        this.showSomeCertificatePending = false;
      }
    },
  },
};
</script>

<style lang="less">
ul.ant-menu {
  li.ant-menu-item {
    & > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:not(.ant-menu-item-highlight) {
      .alert-warning-pulse {
        display: none;
      }
    }
  }

  li.ant-menu-item.ant-menu-item-highlight {
    background-color: #ff000044;
    color: red;

    &.ant-menu-item-selected {
      &::after {
        border-color: red;
      }
    }
  }
}

.alert-warning-pulse {
  display: inline-flex;
  margin: 0 0 0 10px;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (min-width: 992px) {
  .alert-warning-pulse {
    margin: 0 10px 0 0;
  }
}
</style>
