<template>
  <div class="customer">
    <div class="name">
      <span>{{ record.hospital?.name ?? null }}</span>
      <a-tag size="small">{{ record.items.length }}</a-tag>
    </div>

    <div class="discussion">
      <a-badge
        :count="record.comments.length"
        :number-style="{
          backgroundColor: '#fff',
          color: '#999',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }"
        :offset="[-5, 0]"
      >
        <a-button size="small" type="text" @click="onAddCommentClick">
          <i class="ti ti-lg ti-message-plus" style="color: #616373"></i>
        </a-button>
      </a-badge>
    </div>
  </div>
</template>

<script setup>
defineProps({
  record: Object,
});

const emit = defineEmits(["onCommentAdd"]);

const onAddCommentClick = () => {
  emit("onCommentAdd");
};
</script>

<style scoped lang="less">
.customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .ant-tag {
      border: none;
      background: #f2f2f2;
    }
  }

  .discussion {
    border-left: 1px solid #f0f0f0;
  }
}

.ant-tag {
  padding: 0 3px;
}
</style>
