<template>
  <a-card
    :loading="cardLoading"
    style="width: 100%"
    :tab-list="tabList"
    :active-tab-key="activeKey"
    @tabChange="(key) => (activeKey = key)"
  >
    <template v-if="!cardLoading" #tabBarExtraContent>
      <a-space>
        <a-button
          v-if="activeKey == 'tab1'"
          type="primary"
          ghost
          @click="
            downloadCombinedRecordPdf(
              {
                ids: selectedRowKeys,
              },
              'combined-biomed-record.pdf'
            )
          "
        >
          <a-space size="small">
            <i class="ti ti-download ti-lg"></i>
            Combined Biomed Records
          </a-space>
        </a-button>
      </a-space>
    </template>

    <a-table
      v-if="activeKey == 'tab1'"
      :row-selection="rowSelection"
      :columns="signleAssetColumns"
      :rowKey="(record, index) => record.id"
      :dataSource="singleHospitalAssets"
      size="small"
    />

    <a-table
      v-if="activeKey == 'tab2'"
      :columns="createCartColumns"
      :rowKey="(record, index) => index"
      :dataSource="cartAssets"
      size="small"
    >
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.items"
          :rowKey="(record, index) => index"
          :pagination="false"
          size="small"
        />
      </template>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../../services";
export default {
  data() {
    return {
      cardLoading: true,
      hospitalId: this.$route.params.id,
      activeKey: "tab1",
      tabList: [
        {
          key: "tab1",
          tab: "Single",
        },
        {
          key: "tab2",
          tab: "Cart",
        },
      ],
      assetInventory: [],
      assetInventoryHospitals: [],

      signleAssetColumns: [
        {
          title: "Product Name",
          dataIndex: "product_name",
          key: "product_name",
          // sorter: true,
          // sorter: (a, b) => a.product_name.length - b.product_name.length,
        },
        {
          title: "Model No",
          dataIndex: "model_no",
          key: "model_no",
          // sorter: true,
          // sorter: (a, b) => a.model_no.length - b.model_no.length,
        },
        {
          title: "Serial No",
          dataIndex: "serial_no",
          key: "serial_no",
          // sorter: true,
          // sorter: (a, b) => a.serial_no.length - b.serial_no.length,
        },
        {
          title: "Asset ID Tag",
          dataIndex: "asset_id_tag",
          key: "asset_id_tag",
          // sorter: true,
          // sorter: (a, b) => a.asset_id_tag.length - b.asset_id_tag.length,
        },
        {
          title: "Secondary ID Tag",
          dataIndex: "secondary_id_tag",
          key: "secondary_id_tag",
          // sorter: true,
          // sorter: (a, b) =>
          //   a.secondary_id_tag.length - b.secondary_id_tag.length,
        },
        {
          title: "Allocated Date",
          dataIndex: "allocated_date",
          key: "allocated_date",
          sorter: true,
          defaultSortOrder: "descend",
          sorter: (a, b) => {
            return moment(a.allocated_date).isAfter(moment(b.allocated_date))
              ? 1
              : -1;
          },
        },
      ],
      createCartColumns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Cart Allocated Date",
          dataIndex: "cart_allocated_date",
          key: "cart_allocated_date",
          sorter: true,
          defaultSortOrder: "descend",
          sorter: (a, b) => {
            return moment(a.cart_allocated_date).isAfter(
              moment(b.cart_allocated_date)
            )
              ? 1
              : -1;
          },
        },
      ],
      innerColumns: [
        {
          title: "Product Name",
          dataIndex: "product_name",
          key: "product_name",
        },
        {
          title: "Model No",
          dataIndex: "model_no",
          key: "model_no",
        },
        {
          title: "Serial No",
          dataIndex: "serial_no",
          key: "serial_no",
        },
        {
          title: "Asset ID Tag",
          dataIndex: "asset_id_tag",
          key: "asset_id_tag",
        },
        {
          title: "Secondary ID Tag",
          dataIndex: "secondary_id_tag",
          key: "secondary_id_tag",
        },
        {
          title: "Allocated Date",
          dataIndex: "allocated_date",
          key: "allocated_date",
        },
      ],

      selectedRowKeys: [],
    };
  },

  mounted() {
    this.fetchData();
  },

  computed: {
    rowSelection() {
      return this.activeKey == "tab1"
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    singleHospitalAssets() {
      return this.assetInventoryHospitals
        .filter((item) => item.type == 1)
        .map((item) => {
          return {
            id: item.id,
            asset_inventory_id: item.asset_inventory_id,
            type: 1,
            product_name: item.asset_inventory?.asset_product?.name ?? "N/A",
            model_no: item.asset_inventory?.model_no ?? "N/A",
            serial_no: item.asset_inventory?.serial_no ?? "N/A",
            asset_id_tag: item.asset_inventory?.asset_id_tag ?? "N/A",
            secondary_id_tag: item.asset_inventory?.secondary_id_tag ?? "N/A",
            allocated_date: this.$customDate.mdy(item.allocated_date),
            last_biomed_service_record_id:
              item.last_biomed_service_record?.id ?? null,
            last_cell_saver_quality_control_id:
              item.last_cell_saver_quality_control?.id ?? null,
            last_heater_cooler_maintenance_id:
              item.last_heater_cooler_maintenance?.id ?? null,
          };
        });
    },

    cartAssets() {
      let assets = this.assetInventoryHospitals
        .filter((item) => item.type == 2)
        .reduce((pre, curr) => {
          if (curr.unique_code in pre) {
            pre[curr.unique_code].items.push({
              id: curr.id,
              asset_inventory_id: curr.asset_inventory_id,
              type: 2,
              product_name: curr.asset_inventory?.asset_product?.name ?? "N/A",
              model_no: curr.asset_inventory?.model_no ?? "N/A",
              serial_no: curr.asset_inventory?.serial_no ?? "N/A",
              asset_id_tag: curr.asset_inventory?.asset_id_tag ?? "N/A",
              secondary_id_tag: curr.asset_inventory?.secondary_id_tag ?? "N/A",
              allocated_date: this.$customDate.mdy(curr.allocated_date),
              last_biomed_service_record_id:
                curr.last_biomed_service_record?.id ?? null,
              last_cell_saver_quality_control_id:
                curr.last_cell_saver_quality_control?.id ?? null,
              last_heater_cooler_maintenance_id:
                curr.last_heater_cooler_maintenance?.id ?? null,
            });

            return pre;
          } else {
            pre[curr.unique_code] = {
              unique_code: curr.unique_code,
              name: curr.name,
              cart_allocated_date: this.$customDate.mdy(
                curr.cart_allocated_date
              ),
              items: [
                {
                  id: curr.id,
                  asset_inventory_id: curr.asset_inventory_id,
                  type: 2,
                  product_name:
                    curr.asset_inventory?.asset_product?.name ?? "N/A",
                  model_no: curr.asset_inventory?.model_no ?? "N/A",
                  serial_no: curr.asset_inventory?.serial_no ?? "N/A",
                  asset_id_tag: curr.asset_inventory?.asset_id_tag ?? "N/A",
                  secondary_id_tag:
                    curr.asset_inventory?.secondary_id_tag ?? "N/A",
                  allocated_date: this.$customDate.mdy(curr.allocated_date),
                  last_biomed_service_record_id:
                    curr.last_biomed_service_record?.id ?? null,
                  last_cell_saver_quality_control_id:
                    curr.last_cell_saver_quality_control?.id ?? null,
                  last_heater_cooler_maintenance_id:
                    curr.last_heater_cooler_maintenance?.id ?? null,
                },
              ],
            };
            return pre;
          }
        }, {});

      var values = [];
      for (var number in assets) {
        values.push(assets[number]);
      }
      return values;
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    getAssetInventory() {
      return commonService
        .get(this.$constants.getAssetInventoryUrl)
        .then((res) => {
          if (res.success) {
            this.assetInventory = res.data.filter(
              (v) =>
                v.owned_by == 1 ||
                (v.owned_by == 2 && v.hospital_id == this.hospitalId)
            );
          }
        })
        .catch((err) => this.$message.error(err));
    },

    async fetchData() {
      this.cardLoading = true;
      await this.getAssetInventory();

      commonService
        .get(this.$constants.assetInventoryHospitalUrl, {
          hospital_id: this.hospitalId,
        })
        .then((res) => {
          this.assetInventoryHospitals = res.data.data;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => {
          this.cardLoading = false;
        });
    },

    handleCreateCartEvent() {
      this.open({
        title: "Create Cart Inventory",
        path: "hospital.drawerForm.createCart",
        callback: this.fetchData,
        extra: {
          hospitalId: this.hospitalId,
          assetInventory: this.assetInventory,
        },
      });
    },

    handleAssignSingleEvent() {
      this.open({
        title: "Assign Single Inventory",
        path: "hospital.drawerForm.assignSingle",
        callback: this.fetchData,
        extra: {
          hospitalId: this.hospitalId,
          assetInventory: this.assetInventory,
        },
      });
    },

    handleReplaceDeviceEvent(record) {
      this.open({
        title: "Replace Device",
        path: "hospital.drawerForm.replaceDevice",
        callback: this.fetchData,
        record,
        extra: {
          hospitalId: this.hospitalId,
          assetInventory: this.assetInventory,
        },
      });
    },

    handleCartReplaceDeviceEvent(record, rec) {
      this.open({
        title: "Replace Device",
        path: "hospital.drawerForm.replaceDevice",
        callback: this.fetchData,
        record: rec,
        extra: {
          hospitalId: this.hospitalId,
          assetInventory: this.assetInventory,
          cart: record,
        },
      });
    },

    handleReturnDeviceEvent(record) {
      this.open({
        title: "Return Device",
        path: "hospital.drawerForm.returnDevice",
        callback: this.fetchData,
        record: record,
        extra: {
          hospitalId: this.hospitalId,
        },
      });
    },

    handleCartReturnDeviceEvent(record, rec) {
      this.open({
        title: "Return Device",
        path: "hospital.drawerForm.returnDevice",
        callback: this.fetchData,
        record: rec,
        extra: {
          hospitalId: this.hospitalId,
          cart: record,
        },
      });
    },

    handleCartReturnEvent(record) {
      this.open({
        title: "Return Cart",
        path: "hospital.drawerForm.returnCart",
        callback: this.fetchData,
        record: record,
        extra: {
          hospitalId: this.hospitalId,
        },
      });
    },

    handleAddDeviceInCartEvent(record) {
      this.open({
        title: "Add More Device in Cart",
        path: "hospital.drawerForm.addDeviceInCart",
        callback: this.fetchData,
        record: record,
        extra: {
          hospitalId: this.hospitalId,
          assetInventory: this.assetInventory,
        },
      });
    },

    downloadBiomedServiceRecordPdf(data) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.biomedServiceRecordExportPdfUrl,
          { id: data.last_biomed_service_record_id },
          `biomed-service-record.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    downloadCellSaverQualityControlPdf(data) {
      commonService
        .downloadFile(
          this.$constants.cellSaverQCExportPdfUrl,
          { id: data.last_cell_saver_quality_control_id },
          `cell-saver-quality-control.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    downloadHeaterCoolerMaintenancePdf(data) {
      commonService
        .downloadFile(
          this.$constants.equipmentMaintenanceExportPdfUrl,
          { id: data.last_heater_cooler_maintenance_id },
          `heater-coolor-maintenance-report-${data.serial_no}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    downloadCombinedRecordPdf(data, name) {
      if ("ids" in data && data.ids.length == 0) {
        this.$message.error("Please select aleast one row!");
        return;
      }

      commonService
        .downloadFile(
          this.$constants.cartCartPdfExportAssetInventoryHospitalUrl,
          data,
          name
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
