<template>
  <div>
    <a-form
      name="reject_order_form"
      ref="reject_order_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-alert
        v-if="showOrderProductError"
        message="Need to select atleast one product for reject order."
        type="error"
        class="mb-sm-1"
      />

      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="record.products"
        :pagination="false"
        class="ant-table-vertical-align-baseline"
        size="small"
        :scroll="{ x: true, y: 0 }"
      >
        <template #reject_box="{ record, index }">
          <a-form-item
            :key="`${record.id}_reject_box`"
            :name="['products', index, 'reject_box']"
            :rules="{
              trigger: ['change', 'blur'],
              type: 'integer',
              message: 'Required!',
              validator: async (rule, value) =>
                qtyValidation(rule, value, record),
            }"
          >
            <a-input-number
              type="number"
              v-model:value="record.reject_box"
              placeholder="Box"
              :min="1"
              :max="calculateMaxDispatchBox(record)"
              :disabled="!formState.order_product_ids.includes(record.id)"
            />
          </a-form-item>
        </template>
      </a-table>

      <side-drawer-buttons name="Add" />
    </a-form>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },

  data() {
    return {
      showOrderProductError: false,
      formState: { order_product_ids: [] },
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
        },
        {
          title: "Ref Code",
          dataIndex: "ref_code",
          key: "ref_code",
        },
        {
          title: "Qty/Box",
          dataIndex: "qty",
          key: "qty",
          customRender: ({ record }) => `${record.qty}/${record.set}`,
        },
        {
          title: "Dispatched Box",
          dataIndex: "dispatched_box",
          key: "dispatched_box",
          customRender: ({ record }) => {
            return this.record.dispatched_products
              .filter((v) => v.order_product_id == record.id)
              .reduce((pre, curr) => pre + curr.set, 0);
          },
        },
        {
          title: "Rejected Box",
          dataIndex: "rejected_box",
          key: "rejected_box",
          customRender: ({ record }) => record.rejected_set,
        },
        {
          title: "Reject Box",
          dataIndex: "reject_box",
          key: "reject_box",
          slots: {
            customRender: "reject_box",
          },
          width: 125,
        },
      ],
      rules: {},
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record"]),

    rowSelection() {
      return {
        selectedRowKeys: this.formState.order_product_ids,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => {
          return {
            disabled:
              this.record.dispatched_products
                .filter((v) => v.order_product_id == record.id)
                .reduce((pre, curr) => pre + curr.set, 0) +
                record.rejected_set -
                record.set ==
              0,
          };
        },
      };
    },
  },

  created() {
    //
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        order_id: this.record.id,
        order_product_ids: [],
        products: this.record.products,
      };
    },

    onSubmit() {
      this.$refs.reject_order_form
        .validate()
        .then(() => {
          if (this.formState.order_product_ids.length == 0) {
            this.showOrderProductError = true;
            return;
          }

          const formData = new FormData();
          formData.append("order_id", this.formState.order_id);
          this.formState.products
            .filter((item) =>
              this.formState.order_product_ids.includes(item.id)
            )
            .forEach((item, index) => {
              formData.append(`products[${index}][order_product_id]`, item.id);
              formData.append(
                `products[${index}][rejected_box]`,
                item.reject_box
              );
              formData.append(
                `products[${index}][rejected_qty]`,
                item.reject_box * (item.qty / item.set)
              );
            });

          this.loadingStart();
          commonService
            .store(this.$constants.adminOrderRejectUrl, formData)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              this.loadingStop();
              if ("errors" in err) {
                this.errors = err.errors;
              } else {
                this.$message.error(err);
              }
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    onSelectChange(selectedRowKeys) {
      let difference = [
        ...this.formState.order_product_ids.filter(
          (x) => !selectedRowKeys.includes(x)
        ),
      ];
      if (difference[0]) {
        let index = this.formState.products.findIndex(
          (v) => v.id == difference[0]
        );
        this.$refs.reject_order_form.resetFields([
          ["products", index, "reject_box"],
        ]);
      }

      this.formState.order_product_ids = selectedRowKeys;
      if (this.formState.order_product_ids.length == 0) {
        this.showOrderProductError = true;
      } else {
        this.showOrderProductError = false;
      }
    },

    qtyValidation(rule, value, record) {
      if (
        this.formState.order_product_ids.includes(record.id) &&
        (value === undefined || value === "" || value === null)
      ) {
        return Promise.reject(rule.message);
      }
      return Promise.resolve();
    },

    calculateMaxDispatchBox(item) {
      let dispatchedProducts = this.record.dispatched_products.filter(
        (v) => v.order_product_id == item.id
      );
      return (
        item.set -
        item.rejected_set -
        dispatchedProducts.reduce((pre, curr) => pre + curr.set, 0)
      );
    },

    updateProducts() {
      this.formState.products = this.formState.products.map((item) => {
        if (this.formState.order_product_ids.includes(item.id)) {
          item.reject_box =
            item.reject_box == null
              ? this.calculateMaxDispatchBox(item) ?? 1
              : item.reject_box;
          return item;
        } else {
          item.reject_box = null;
          return item;
        }
      });
    },
  },

  watch: {
    "formState.order_product_ids": {
      deep: true,
      handler: function () {
        this.updateProducts();
      },
    },
  },
};
</script>
