<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Incident Details"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card :loading="cardLoader" size="small">
      <a-descriptions
        v-if="incident"
        size="small"
        :column="2"
        bordered
        :labelStyle="{ width: '300px' }"
      >
        <a-descriptions-item label="Employee Name" :span="1">
          {{ incident.employee?.full_name }}
        </a-descriptions-item>

        <a-descriptions-item label="Report Number" :span="1">
          {{ incident.report_number ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Date of Report" :span="1">
          {{ $customDate.ll(incident.report_date) }}
        </a-descriptions-item>

        <a-descriptions-item label="Position of Reporting Person" :span="1">
          {{ incident.position ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Hospital Name" :span="1">
          {{ incident.hospital?.name }}
        </a-descriptions-item>

        <a-descriptions-item label="Date of Incident" :span="1">
          {{ $customDate.ll(incident.incident_date) }}
        </a-descriptions-item>

        <a-descriptions-item label="Device Type" :span="1">
          {{ incident.device_type ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Model" :span="1">
          {{ incident.model ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Manufacturer" :span="1">
          {{ incident.manufacturer ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Reference Number" :span="1">
          {{ incident.reference_number ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Lot or Batch Number" :span="1">
          {{ incident.batch_number ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Date Manufactured" :span="1">
          {{
            incident.manufactured_date
              ? $customDate.ll(incident.manufactured_date)
              : "-"
          }}
        </a-descriptions-item>

        <a-descriptions-item label="Expiration Date" :span="1">
          {{
            incident.expiration_date
              ? $customDate.ll(incident.expiration_date)
              : "-"
          }}
        </a-descriptions-item>

        <a-descriptions-item label="Current location of the device" :span="1">
          {{ incident.device_location ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item
          label="Has the manufacturer/supplier been contacted?"
          :span="1"
        >
          {{ incident.is_contact == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <a-descriptions-item label="Any resulting injury?" :span="1">
          {{ incident.result_injury ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item
          label="Details of incident/Nature of defect"
          :span="2"
        >
          {{ incident.incident_detail ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item
          label="Details of injury(to patient/healthcare professional)"
          :span="2"
        >
          {{ incident.injury_detail ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item
          label="Action Taken (includes any action by patient, healthcare professional, or by the manufacturer or suppier)"
          :span="2"
        >
          {{ incident.action_detail ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Signature" :span="2">
          <img :width="200" :src="incident.signature" />
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: { backButton },

  data() {
    return {
      id: this.$route.params.id,
      cardLoader: false,
      incident: null,
    };
  },

  mounted() {
    this.fetchIncident();
  },

  methods: {
    fetchIncident() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.incidentShowUrl, { id: this.id })
        .then((res) => {
          if (res.success) {
            this.incident = res.data;
            this.cardLoader = false;
          } else {
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
