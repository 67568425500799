<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form :model="model" layout="vertical" @finish="handleFinish">
        <a-row :gutter="[20, 0]">
          <a-col :span="8">
            <a-form-item label="Reported By">
              <a-input :value="employee.full_name" disabled />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item
              ref="date_of_report"
              label="Date of Report"
              name="date_of_report"
              :rules="{
                required: true,
                message: 'This field is required!',
                type: 'date',
                trigger: $constants.ruleTrigger,
              }"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.date_of_report"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item
              ref="date_of_incident"
              label="Date of Incident"
              name="date_of_incident"
              :rules="{
                required: true,
                message: 'This field is required!',
                type: 'date',
                trigger: $constants.ruleTrigger,
              }"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.date_of_incident"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="incident_type"
              label="Incident Type"
              name="incident_type"
              :rules="{
                required: true,
                message: 'This field is required!',
                trigger: $constants.ruleTrigger,
              }"
            >
              <a-select
                v-model:value="model.incident_type"
                placeholder="Select any type"
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="option in $constants.hrIncidentTypes"
                  :key="option"
                  :label="option"
                  :value="option"
                >
                  {{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="hospital_id"
              label="Facility"
              name="hospital_id"
              :rules="{
                required: true,
                message: 'This field is required!',
                type: 'integer',
                trigger: $constants.ruleTrigger,
              }"
            >
              <a-select
                v-model:value="model.hospital_id"
                placeholder="Select any facility"
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="option in hospitals"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="incident_location"
              label="Specific area of location"
              name="incident_location"
            >
              <a-textarea
                v-model:value="model.incident_location"
                placeholder="Enter here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="description"
              label="Description"
              name="description"
              :rules="{
                required: true,
                message: 'This field is required!',
                trigger: $constants.ruleTrigger,
              }"
            >
              <a-textarea
                v-model:value="model.description"
                placeholder="Enter here..."
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider orientation="left">
          Name / Role / Contact of Parties Involved
        </a-divider>
        <template v-for="(party, index) in model.parties" :key="index">
          <a-row type="flex" align="middle">
            <a-col flex="auto">
              <a-form-item
                :name="['parties', index, 'name']"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                }"
              >
                <a-input v-model:value="party.name"></a-input>
              </a-form-item>
            </a-col>

            <a-col flex="100px">
              <a-space :size="1">
                <a-button
                  v-if="model.parties.length > 1"
                  size="small"
                  type="link"
                  danger
                  @click="handleRowDeleteEvent(index, 'parties')"
                >
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>

                <a-button
                  v-if="model.parties.length == index + 1"
                  size="small"
                  type="link"
                  @click="handleRowAddEvent('parties')"
                >
                  <i class="ti ti-plus ti-lg"></i>
                </a-button>
              </a-space>
            </a-col>
          </a-row>
        </template>

        <a-divider orientation="left">
          Name / Role / Contact of Witnesses
        </a-divider>
        <template v-for="(witness, index) in model.witnesses" :key="index">
          <a-row type="flex" align="middle">
            <a-col flex="auto">
              <a-form-item
                :name="['witnesses', index, 'name']"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                }"
              >
                <a-input v-model:value="witness.name"></a-input>
              </a-form-item>
            </a-col>

            <a-col flex="100px">
              <a-space :size="1">
                <a-button
                  v-if="model.witnesses.length > 1"
                  size="small"
                  type="link"
                  danger
                  @click="handleRowDeleteEvent(index, 'witnesses')"
                >
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>

                <a-button
                  v-if="model.witnesses.length == index + 1"
                  size="small"
                  type="link"
                  @click="handleRowAddEvent('witnesses')"
                >
                  <i class="ti ti-plus ti-lg"></i>
                </a-button>
              </a-space>
            </a-col>
          </a-row>
        </template>

        <a-divider />

        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-checkbox v-model:checked="model.is_filed_police_report">
              Police Report Filed?
            </a-checkbox>
          </a-col>

          <template v-if="model.is_filed_police_report">
            <a-col :span="12">
              <a-form-item
                ref="precinct"
                label="Precinct"
                name="precinct"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                }"
              >
                <a-input
                  v-model:value="model.precinct"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="reporting_officer"
                label="Reporting Officer"
                name="reporting_officer"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                }"
              >
                <a-input
                  v-model:value="model.reporting_officer"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="reporting_officer_phone"
                label="Phone"
                name="reporting_officer_phone"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                }"
              >
                <a-input
                  v-model:value="model.reporting_officer_phone"
                  v-maska="$constants.leadContactNumberMask"
                  :placeholder="$constants.leadContactNumberPlaceholder"
                />
              </a-form-item>
            </a-col>
          </template>
        </a-row>

        <signature-box v-model:sign="model.sign" />

        <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
          {{ submitBtnText }}
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import backButton from "../../../components/backButton.vue";
import signatureBox from "../../../components/signatureBox.vue";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: { backButton, signatureBox },

  data() {
    return {
      cardLoader: true,
      submitBtnLoader: false,
      hospitals: [],
      model: {
        id: this.$route.params.id ?? 0,
        hospital_id: undefined,
        date_of_incident: moment(),
        date_of_report: moment(),
        incident_type: undefined,
        incident_location: "",
        description: null,
        is_filed_police_report: false,
        precinct: "",
        reporting_officer: "",
        reporting_officer_phone: "",
        sign: "",
        parties: [{ name: "" }],
        witnesses: [{ name: "" }],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Create New Human Resource Incident Report";
      }
      return "Edit Human Resource Incident Report";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  async mounted() {
    this.hospitals = await this.$comman.getHospitals();

    if (this.model.id != 0) {
      await this.fetchHrIncident();
    }

    this.cardLoader = false;
    // setTimeout(() => {
    //   this.$refs.signaturePad.fromDataURL(this.model.sign);
    // }, 500);
  },

  methods: {
    fetchHrIncident() {
      return commonService
        .get(this.$constants.hrIncidentShowUrl, { id: this.model.id })
        .then(async (res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;
          this.model.hospital_id = data.hospital_id;
          this.model.date_of_incident = moment(data.date_of_incident);
          this.model.date_of_report = moment(data.date_of_report);
          this.model.incident_type = data.incident_type;
          this.model.incident_location = data.incident_location;
          this.model.description = data.description;
          this.model.is_filed_police_report =
            data.is_filed_police_report == 1 ? true : false;
          this.model.precinct = data.precinct;
          this.model.reporting_officer = data.reporting_officer;
          this.model.reporting_officer_phone = data.reporting_officer_phone;
          this.model.parties = data.contacts
            .filter((e) => e.type == 1)
            .map((e) => ({ name: e.name }));
          this.model.witnesses = data.contacts
            .filter((e) => e.type == 2)
            .map((e) => ({ name: e.name }));
          this.model.sign = data.sign;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    handleFinish() {
      if (this.model.sign == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      if (this.model.id == 0) {
        this.model.employee_id = this.employee.id;
      }

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.hrIncidentStoreUrl, {
          ...this.model,
          date_of_incident: this.$customDate.ymd(this.model.date_of_incident),
          date_of_report: this.$customDate.ymd(this.model.date_of_report),
        })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleRowDeleteEvent(index, type) {
      this.model[type] = this.model[type].filter((_, i) => index != i);
    },

    handleRowAddEvent(type) {
      this.model[type].push({ name: "" });
    },

    clear() {
      this.model.sign = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.sign = data;
      return Promise.resolve();
    },
  },
};
</script>
