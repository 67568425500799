import { store } from "@/store";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import VueSplide from "@splidejs/vue-splide";
import Antd from "ant-design-vue"; // Ant vue design
import "ant-design-vue/dist/antd.css"; // Ant vue design
import Maska from 'maska';
import VueGoogleMaps from "vue-google-maps-community-fork";
import VueSignaturePad from 'vue-signature-pad';
import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";

import "@/assets/style.less";
import "@/assets/tabler-icons.min.css";
import { api } from "@/config/config";
import { comman } from "@/helper/comman";
import { constants } from "@/helper/constants";
import { date } from "@/helper/date";
import "@/helper/headers.js";
import { validation } from "@/helper/validation";
import '@splidejs/vue-splide/dist/css/splide.min.css';

// Layouts
import AppLayout from "@/layouts/app";
import AuthLayout from "@/layouts/auth";
import DefaultLayout from "@/layouts/default";
import hospitalUserLayout from "@/layouts/hospitalUser";

// Components
import SideDrawer from "@/components/drawer";
import SideDrawerButtons from "@/components/drawerButtons";
import ModalButtons from "@/components/modalButtons";
import PageHeader from "@/components/pageHeader";
import CustomTable from "@/components/table";

store.dispatch('init').finally(() => {
  const app = createApp(App);

  app.config.productionTip = true;
  app.config.globalProperties.$api = api;
  app.config.globalProperties.$customDate = date;
  app.config.globalProperties.$validation = validation;
  app.config.globalProperties.$constants = constants;
  app.config.globalProperties.$comman = comman;

  app
    .use(store)
    .use(router)
    .use(Antd)
    .use(CKEditor)
    .use(VueClipboard)
    .use(Maska)
    .use(VueSignaturePad)
    .use(VueSplide)
    .use(VueGoogleMaps, {
      load: {
        key: constants.googleApiKey,
        libraries: "places",
      },
    })
    .component("default-layout", DefaultLayout)
    .component("auth-layout", AuthLayout)
    .component("app-layout", AppLayout)
    .component("hospital-user-layout", hospitalUserLayout)
    .component("side-drawer", SideDrawer)
    .component("side-drawer-buttons", SideDrawerButtons)
    .component("modal-buttons", ModalButtons)
    .component("custom-table", CustomTable)
    .component("page-header", PageHeader)
    .mount("#app");
});
