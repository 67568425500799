<template>
  <a-space direction="vertical" style="width: 100%">
    <a-card v-if="employee" size="small" :loading="fetchingEmployee">
      <a-row type="flex" align="middle" justify="start">
        <a-col flex="650px">
          <a-form layout="inline" :model="model" @finish="handleFinish">
            <a-form-item>
              <a-select
                v-model:value="model.employee_id"
                placeholder="Select employee"
                show-search
                optionFilterProp="label"
                style="width: 500px"
              >
                <a-select-option
                  v-for="option in filteredEmployees"
                  :key="option.id"
                  :label="`${option.full_name} | ${
                    option.company?.name ?? 'N/A'
                  }`"
                  :value="option.id"
                >
                  {{ option.full_name }} | {{ option.company?.name ?? "N/A" }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item>
              <a-space>
                <a-button
                  type="primary"
                  html-type="submit"
                  :disabled="!model.employee_id"
                  :loading="loading"
                >
                  {{ model.id ? "Update" : "Add" }}
                </a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </a-col>

        <a-col flex="175px">
          <a-switch
            checked-children="Yes"
            un-checked-children="No"
            v-model:checked="employee.is_show_time_tracking_on_dashboard"
            :loading="timeTrackingSwitchLoading"
            @click="
              dashboardWidget(
                'timeTrackingSwitchLoading',
                'is_show_time_tracking_on_dashboard'
              )
            "
          />
          Time Tracking
        </a-col>

        <a-col flex="175px">
          <a-switch
            checked-children="Yes"
            un-checked-children="No"
            v-model:checked="employee.is_show_case_reporting_on_dashboard"
            :loading="caseReportingSwitchLoading"
            @click="
              dashboardWidget(
                'caseReportingSwitchLoading',
                'is_show_case_reporting_on_dashboard'
              )
            "
          />
          Case Reporting
        </a-col>
      </a-row>
    </a-card>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        size="small"
        :loading="tableLoading"
        :scroll="{ x: true, y: 0 }"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #employment_type="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'employment_type',
                $constants.employment_types,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "employment_type",
                $constants.employment_types
              )
            }}
          </a-tag>
        </template>

        <template #employee_type="{ record }">
          <a-tag
            :color="$comman.get_employee_type(record.employee_type, 'color')"
          >
            {{ $comman.get_employee_type(record.employee_type, "text") }}
          </a-tag>
        </template>

        <template #action="{ record }">
          <a-popconfirm
            title="Are you sure you want to remove this employee?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="removeEmployee(record)"
          >
            <a-button size="small" type="link" danger>
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
  </a-space>
</template>

<script>
import { commonService } from "@/services";
import { message } from "ant-design-vue";
import xFilterDateRangeDropdown from "../../../components/table/filterDateRangeDropdown.vue";
import xFilterIcon from "../../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../../components/table/filterInputSearchDropdown.vue";

export default {
  components: {
    xFilterIcon,
    xFilterInputSearchDropdown,
    xFilterDateRangeDropdown,
  },

  data() {
    return {
      fetchingEmployee: false,
      loading: false,
      tableLoading: false,
      timeTrackingSwitchLoading: false,
      caseReportingSwitchLoading: false,
      employee: null,
      employee_id: this.$route.params.id,
      model: {
        employee_id: undefined,
      },
      dataSource: [],
      employees: [],

      columns: [
        {
          title: "Name",
          dataIndex: "first_name",
          key: "first_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.full_name,
          onFilter: (value, record) =>
            record.full_name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Employee Code",
          dataIndex: "employee_code",
          key: "employee_code",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.employee_code
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types,
          filterMultiple: false,
          slots: {
            customRender: "employment_type",
          },
          onFilter: (value, record) => value == record.employment_types,
        },
        {
          title: "Profession Type",
          dataIndex: "employee_type",
          key: "employee_type",
          filters: this.$constants.employee_types,
          filterMultiple: false,
          slots: {
            customRender: "employee_type",
          },
          onFilter: (value, record) => value == record.employee_type,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  mounted() {
    this.fetchEmployee();
    this.fetchEmployeeManagers();
    this.fetchEmployees();
  },

  updated() {
    if (this.employee_id != this.$route.params.id) {
      this.loading = true;
      this.employee = null;
      this.employee_id = this.$route.params.id;
      this.fetchEmployee();
      this.fetchEmployeeManagers();
    }
  },

  computed: {
    filteredEmployees() {
      return this.employees.filter(
        (e) =>
          ![this.employee?.id, ...this.dataSource.map((i) => i.id)].includes(
            e.id
          )
      );
    },
  },

  methods: {
    fetchEmployee() {
      commonService
        .get(this.$constants.employeeShowUrl, { id: this.employee_id })
        .then((res) => {
          this.employee = res.data.employee;
          this.employee.is_show_time_tracking_on_dashboard =
            this.employee.is_show_time_tracking_on_dashboard == 1
              ? true
              : false;
          this.employee.is_show_case_reporting_on_dashboard =
            this.employee.is_show_case_reporting_on_dashboard == 1
              ? true
              : false;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          this.fetchingEmployee = false;
        });
    },

    fetchEmployeeManagers() {
      this.tableLoading = true;
      commonService
        .get(this.$constants.getEmployeeManagersUrl, {
          id: this.$route.params.id,
        })
        .then((res) => {
          this.dataSource = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    fetchEmployees() {
      commonService
        .get(this.$constants.getEmployeesUrl, {
          select: ["id", "first_name", "last_name", "company_id"],
          with: ["company:id,name"],
        })
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    handleFinish() {
      this.loading = true;
      commonService
        .store(this.$constants.employeeManagerStoreUrl, {
          ...this.model,
          manager_id: this.employee.id,
        })
        .then((res) => {
          message.success(res.message);
          this.dataSource = res.data;
          this.model.employee_id = undefined;
        })
        .finally(() => (this.loading = false));
    },

    removeEmployee(record) {
      this.tableLoading = true;
      commonService
        .store(this.$constants.employeeManagerRemoveUrl, {
          employee_id: record.id,
          manager_id: this.employee.id,
        })
        .then((res) => {
          message.success(res.message);
          this.dataSource = res.data;
        })
        .finally(() => (this.tableLoading = false));
    },

    dashboardWidget(loading, key) {
      this[loading] = true;
      commonService
        .store(this.$constants.employeeManagerDashboardWidgetUrl, {
          [key]: this.employee[key],
          employee_id: this.employee.id,
        })
        .then((res) => {
          message.success(res.message);
          // this.dataSource = res.data;
          // this.model.employee_id = undefined;
        })
        .finally(() => (this[loading] = false));
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
    },
  },
};
</script>
