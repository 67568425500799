<template>
  <div>
    <a-form
      name="assign_single_form"
      ref="assign_single_form"
      :model="formState"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
            :rules="{
              required: true,
              message: 'This field is required',
            }"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter here..."
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider class="mt-sm-0 mb-sm-1"></a-divider>

      <a-table
        :dataSource="formState.products"
        size="small"
        :columns="columns"
        :pagination="false"
      >
        <template #product="{ record, index }">
          <a-form-item
            :name="['products', index, 'product_id']"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'number',
            }"
          >
            <a-select
              style="width:400px"
              v-model:value="record.product_id"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="option in unSelectedProducts(record)"
                :key="option.product_id"
                :label="option.product_name"
                :value="option.product_id"
              >
                {{ option.product_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>

        <template #qty="{ record, index }">
          <a-form-item
            :name="['products', index, 'qty']"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'number',
            }"
          >
            <a-input-number
              v-model:value="record.qty"
              placeholder="Enter here..."
              :min="0"
            />
          </a-form-item>
        </template>

        <template #action="{ index }">
          <a-tooltip title="Delete" v-if="formState.products.length > 1">
            <a-button
              size="small"
              type="link"
              class="danger-color"
              @click="removeItem(index)"
            >
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>

          <a-tooltip title="Add" v-if="formState.products.length - 1 === index">
            <a-button size="small" type="link" @click="addItem">
              <i class="ti ti-plus ti-lg"></i>
            </a-button>
          </a-tooltip>
        </template>
      </a-table>

      <side-drawer-buttons :name="extra.record?.id ? 'Update' : 'Create'" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],

  components: {
    PlusOutlined,
    MinusOutlined,
  },

  data() {
    return {
      formState: {
        name: null,
        products: [
          {
            product_id: null,
            qty: null,
          },
        ],
      },
      hospitalProducts: [],
      columns: [
        {
          title: "Product",
          slots: { customRender: "product" },
        },
        {
          title: "Qty",
          slots: { customRender: "qty" },
        },
        {
          title: "Action",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    hospitalId() {
      return this.extra?.hospitalId ?? null;
    },
  },

  async mounted() {
    this.fetchHospitalProducts();
    const { record } = this.extra;
    if (record) {
      this.formState = {
        ...record,
        products: record.items.map((item) => {
          return {
            product_id: item.product_id,
            qty: item.qty,
          };
        }),
      };
    }
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    fetchHospitalProducts() {
      commonService
        .get(this.$constants.getHospitalWiseProductsUrl, {
          hospital_id: this.hospitalId,
        })
        .then((res) => {
          let products = res.data.map((item) => {
            return {
              ...item,
              id: item.id,
              product_id: item.product_id,
              product_name: `${item.product.manufacturer.name} | ${item.product.name} | ${item.product.sku}`,
            };
          });
          this.hospitalProducts = products;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addItem() {
      this.formState.products.push({
        product_id: null,
        qty: null,
      });
    },

    removeItem(index) {
      this.formState.products.splice(index, 1);
    },

    unSelectedProducts(product) {
      const selectedProductIDs = this.formState.products.map(
        (item) => item.product_id
      );
      return this.hospitalProducts.filter(
        (item) =>
          product.product_id === item.product_id ||
          !selectedProductIDs.includes(item.product_id)
      );
    },

    onSubmit() {
      let data = {
        hospital_id: this.hospitalId,
        name: this.formState.name,
        items: this.formState.products.map((item) => {
          return {
            product_id: item.product_id,
            qty: item.qty,
          };
        }),
      };

      if (this.extra.record?.id) {
        data.id = this.extra.record?.id;
      }

      this.loadingStart();
      commonService
        .store(this.$constants.hospitalDisposableTemplateStore, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
