<!-- THIS COMPONENT IS WORKING FOR ADMIN AND MANAGER -->
<template>
  <a-layout-content>
    <page-header>
      <template #title> Dispatched Orders</template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #order_no="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: isAdmin ? 'admin-view-order' : 'view-order',
                params: { order_no: record.order.order_no },
              }"
            >
              <a-button type="link" class="pl-sm-0">
                {{ record.order.order_no_with_hash }}
              </a-button>
            </router-link>
            <a-tooltip
              v-if="'is_classes_mapped' in record && !record.is_classes_mapped"
              :title="record.class_not_mapped_message"
            >
              <InfoCircleOutlined style="color: red" />
            </a-tooltip>
            <a-tooltip
              v-if="record.quickbooks_purchase_order"
              :title="
                record.quickbooks_purchase_order.quickbooks_purchase_order_no
              "
            >
              <InfoCircleOutlined style="color: #1890ff" />
            </a-tooltip>
          </a-space>
        </template>

        <template #tracking_no="{ record }">
          <a-typography-link
            target="_blank"
            v-if="
              $comman.getTrackingURL(record.carrier_partner, record.tracking_no)
            "
            :href="
              $comman.getTrackingURL(record.carrier_partner, record.tracking_no)
            "
          >
            {{ record.tracking_no }}
          </a-typography-link>
          <span v-else>
            {{ record.tracking_no }}
          </span>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="View">
              <a-button
                type="link"
                size="small"
                @click="showDispatchInfo(record)"
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              v-if="canCreatePurchaseOrder(record)"
              title="Are you sure you want to create a purcahse order on QuickBooks?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="createPurchaseOrder(record)"
            >
              <a-tooltip title="Create Purchase Order On QuickBooks">
                <a-button type="link" size="small"> PO </a-button>
              </a-tooltip>
            </a-popconfirm>

            <template v-if="record.status === 0">
              <a-popconfirm
                title="Are you sure you want to resend a dispatched order reminder mail?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="sendProcessingMail(record)"
              >
                <a-tooltip title="Dispatch Reminder Email">
                  <a-button type="link" size="small">
                    <i class="ti ti-mail ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  components: {
    InfoCircleOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminDispatchedOrdersDatatableUrl,
      columns: [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          slots: {
            customRender: "order_no",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.order_no
            ? [this.$route.query?.order_no]
            : undefined,
        },
        {
          title: "Mfg. Order",
          dataIndex: "mfg_order_no",
          key: "mfg_order_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.mfg_order_no
            ? [this.$route.query?.mfg_order_no]
            : undefined,
        },
        {
          title: "Carrier Partner",
          dataIndex: "carrier_partner",
          key: "carrier_partner",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.carrier_partner
            ? [this.$route.query?.carrier_partner]
            : undefined,
        },
        {
          title: "Tracking No",
          dataIndex: "tracking_no",
          key: "tracking_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "tracking_no",
          },
          defaultFilteredValue: this.$route.query?.tracking_no
            ? [this.$route.query?.tracking_no]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.order?.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Dispatched By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record.creator?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.creator_name
            ? [this.$route.query?.creator_name]
            : undefined,
        },
        {
          title: "Dispatched/Received",
          dataIndex: "dispatched_products_count",
          key: "dispatched_products_count",
          customRender: ({ record }) =>
            `${record.products.length}/${
              record.products.filter((v) => v.status == 1).length
            }`,
        },
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          width: 100,
          customRender: ({ record }) =>
            this.$customDate.mdy(record.order.order_date),
        },
        {
          title: "Shipment Date",
          dataIndex: "shipment_date",
          key: "shipment_date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ record }) =>
            this.$customDate.mdy(record.shipment_date),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.dispatchedOrderStatus.map((item) => {
        return {
          key: item.value.toString(),
          counts: 0,
          text: item.text,
          status: item.value,
          slots: { tab: "customRender" },
        };
      }),
      defaultFilterParams: {
        order_no: this.$route.query?.order_no
          ? [this.$route.query?.order_no]
          : undefined,
        mfg_order_no: this.$route.query?.mfg_order_no
          ? [this.$route.query?.mfg_order_no]
          : undefined,
        carrier_partner: this.$route.query?.carrier_partner
          ? [this.$route.query?.carrier_partner]
          : undefined,
        tracking_no: this.$route.query?.tracking_no
          ? [this.$route.query?.tracking_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        creator_name: this.$route.query?.creator_name
          ? [this.$route.query?.creator_name]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleStatusChangeEvent(record) {
      commonService
        .store(this.statusChangeUrl, { id: record.id })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    showDispatchInfo(record) {
      this.open({
        title: "Show Order Dispatch Info",
        path: "admin.order.dispatchInfo",
        callback: this.refresh,
        record: record,
        extra: { hospital_name: record?.order?.hospital?.name ?? "N/A" },
        width: "1000px",
      });
    },

    sendProcessingMail(order) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminDispatchOrderReminderMail, {
          id: order.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refresh();
          this.datatableLoading = false;
        });
    },

    createPurchaseOrder(dispatch) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminDispatchedOrderCreatePurchaseOrderUrl, {
          id: dispatch.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err);
        })
        .finally(() => {
          this.refresh();
          this.datatableLoading = false;
        });
    },

    canCreatePurchaseOrder(rec) {
      return (
        !rec.quickbooks_purchase_order &&
        rec.status == 0 &&
        !("is_classes_mapped" in rec && !rec.is_classes_mapped) &&
        moment(rec.created_at).isAfter(moment("2024-02-13"))
      );
    },
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),
  },
};
</script>
