<template>
  <a-layout-content>
    <page-header>
      <template #title> Time Off </template>

      <template #buttons>
        <!-- <router-link :to="{ name: 'create-employee-time-track' }"> -->
        <a-button type="primary">
          <a-space> <i class="ti ti-plus ti-lg"></i> Add New </a-space>
        </a-button>
        <!-- </router-link> -->
      </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col v-for="(value, title) in statistics" :span="6" :key="title">
        <a-card size="small" :loading="loading">
          <a-statistic :title="title" :value="value" />
        </a-card>
      </a-col>

      <a-col :span="24">
        <a-card
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="(key) => (activeTabKey = key)"
          size="small"
        >
          <template #customRender="item">
            {{ item.text }} ({{ item.counts }})
          </template>

          <a-table
            :columns="columns"
            :rowKey="(record, index) => record.id"
            :dataSource="dataSource"
            :pagination="false"
            :loading="loading"
            size="small"
          >
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import { commonService } from "../../services";
export default {
  data() {
    return {
      loading: true,
      statistics: {
        Balance: 0,
        Taken: 0,
        Scheduled: 0,
        Earned: 0,
      },

      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.timeOffStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      dataSource: [],
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "State Date",
          dataIndex: "state_date",
          key: "state_date",
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
        },
        {
          title: "Applied Date",
          dataIndex: "applied_date",
          key: "applied_date",
        },
      ],
    };
  },

  mounted() {
    this.fetchStatistics();
  },

  methods: {
    fetchStatistics() {
      commonService
        .get(this.$constants.timeOffStatisticsUrl)
        .then((res) => {
          this.statistics.Balance = res.data["Balance"];
          this.statistics.Taken = res.data["Taken"];
          this.statistics.Scheduled = res.data["Scheduled"];
          this.statistics.Earned = res.data["Earned"];
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
