<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="24">
      <a-form-item
        ref="proccessed_all_required_license_and_certificates"
        label="Employee possesses all required licenses and/or certifications?"
        name="proccessed_all_required_license_and_certificates"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <a-radio-group
          v-model:value="model.proccessed_all_required_license_and_certificates"
          @change="$emit('update:model', model)"
        >
          <a-radio
            v-for="option in $constants.yesNoNotApplicable"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="employee_attendance_meets_department_standards"
        name="employee_attendance_meets_department_standards"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <template #label>
          Employee's attendance meets department standards?
          <small>(attendance may affect overall score)</small>
        </template>

        <a-radio-group
          v-model:value="model.employee_attendance_meets_department_standards"
          @change="$emit('update:model', model)"
        >
          <a-radio
            v-for="option in $constants.yesNoNotApplicable"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="performance_forwared_to_hr"
        name="performance_forwared_to_hr"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <template #label>
          For Clinical Personnel: Employee’s age and department specific
          assessment has been performed this year and forwarded to Human
          Resources.
        </template>

        <a-radio-group
          v-model:value="model.performance_forwared_to_hr"
          @change="$emit('update:model', model)"
        >
          <a-radio
            v-for="option in $constants.yesNoNotApplicable"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
  },

  data() {
    return {
      //
    };
  },

  methods: {
    validate(...arg) {
      this.$emit("validate", ...arg);
    },
  },
};
</script>
