import { route } from "@/helper/route";
import auth from "./middleware/auth";
import accessible from "./middleware/accessible";
import profile from "./middleware/profile";

export default [
  {
    path: "/services",
    name: "service",
    component: route("service/index"),
    meta: {
      title: "Services",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/service/create",
    name: "create-service",
    component: route("service/store"),
    meta: {
      title: "Add New Service",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/service/edit/:id",
    name: "edit-service",
    component: route("service/store"),
    meta: {
      title: "Edit Service",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
];
