<template>
  <div class="footer-container">
    <a-divider style="margin: 0" />
    <div class="footer-buttons">
      <span v-if="currentStep === 0" />
      <a-button
        v-else
        type="primary"
        :disabled="disableBack"
        :loading="loading"
        @click="emit('previousStep')"
        class="back-button"
      >
        Back
      </a-button>

      <div class="right-section">
        <slot name="extraButtons" />

        <a-button
          v-if="currentStep !== 0 && !hideSaveAndNext"
          type="primary"
          :loading="loading"
          html-type="submit"
          class="next-button"
        >
          Save & Next
        </a-button>

        <a-button
          v-if="currentStep !== steps.length - 1"
          :disabled="disableNext"
          type="primary"
          :loading="loading"
          @click="emit('nextStep')"
          class="next-button"
        >
          Next
        </a-button>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  currentStep: Number,
  steps: Array,
  loading: Boolean,
  hideSaveAndNext: Boolean,
  disableNext: Boolean,
  disableBack: Boolean,
});
const emit = defineEmits(["update:currentStep", "nextStep", "previousStep"]);
</script>

<style scoped lang="less">
.footer-container {
  display: flex;
  flex-direction: column;

  .footer-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .right-section {
      display: flex;
      gap: 12px;
    }

    .back-button {
      margin-right: 10px;
    }
  }
}
</style>
