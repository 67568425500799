<template>
  <a-layout-content>
    <page-header>
      <template #title> Missing Item Orders</template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card hoverable size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-tooltip title="Edit">
            <a-button
              type="link"
              size="small"
              @click="editMissingItemRecord(record)"
            >
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminMissingItemOrdersUrl,
      columns: [
        {
          title: "Order No",
          dataIndex: "order_no",
          key: "order_no",
          customRender: ({ record }) => record?.order?.order_no ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.order_no
            ? [this.$route.query?.order_no]
            : undefined,
        },
        {
          title: "Date",
          dataIndex: "order_date",
          key: "order_date",
          width: 100,
          defaultSortOrder: this.$route.query?.sortOrder
            ? this.$route.query.sortOrder
            : "descend",
          customRender: ({ record }) =>
            record?.order?.order_date
              ? this.$customDate.mdy(record.order.order_date)
              : "N/A",
        },
        {
          title: "Hispital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record?.order?.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Product",
          dataIndex: "product_name",
          key: "product_name",
          customRender: ({ record }) => record?.product?.product?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.product_name
            ? [this.$route.query?.product_name]
            : undefined,
        },
        {
          title: "Dispatched Qty",
          dataIndex: "qty",
          key: "qty",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "qty",
          },
          defaultFilteredValue: this.$route.query?.qty
            ? [this.$route.query?.qty]
            : undefined,
        },
        {
          title: "Missing Qty",
          dataIndex: "missing_qty",
          key: "missing_qty",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "missing_qty",
          },
          defaultFilteredValue: this.$route.query?.missing_qty
            ? [this.$route.query?.missing_qty]
            : undefined,
        },
        {
          title: "Order By",
          dataIndex: "order_by",
          key: "order_by",
          customRender: ({ record }) =>
            record?.order?.creator?.full_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.order_by
            ? [this.$route.query?.order_by]
            : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
      defaultFilterParams: {
        sortField: this.$route.query?.sortField
          ? this.$route.query?.sortField
          : "id",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query?.sortOrder
          : "descend",
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    editMissingItemRecord(record) {
      this.open({
        title: "Edit Missing Item Order Qty",
        path: "admin.missingItemOrders.update",
        callback: this.refresh,
        record: record,
      });
    },
  },
};
</script>
