<template>
  <div class="parties">
    <a-avatar-group>
      <a-tooltip
        v-for="emp in getItemEmployees"
        :key="emp.id"
        :title="emp.full_name"
      >
        <a-avatar style="cursor: pointer" :src="emp.profile_pic_url">
          {{ emp.last_name.charAt(0) }}{{ emp.first_name.charAt(0) }}
        </a-avatar>
      </a-tooltip>
    </a-avatar-group>

    <a-popover
      v-model:visible="isVisible"
      trigger="click"
      placement="bottom"
      :overlayStyle="{ width: '350px' }"
      overlayClassName="overlay-popover-class"
      @visibleChange="visibleChange"
    >
      <a-tooltip title="Add Employee">
        <a-avatar
          style="
            color: #1890ff;
            background-color: #fff;
            border: 1px dashed #1890ff;
            cursor: pointer;
          "
        >
          <template #icon>
            <UserOutlined />
          </template>
        </a-avatar>
      </a-tooltip>

      <template #content>
        <a-row :gutter="[10, 10]">
          <a-col :span="24">
            <a-input v-model:value="query" placeholder="Search employees" />
          </a-col>
          <a-col :span="24">
            <a-list
              :dataSource="employeesFiltered"
              class="employees-list"
              size="small"
            >
              <template #renderItem="{ item }">
                <a-list-item
                  class="employee-item"
                  :class="{ selected: selectedEmployeeIds.includes(item.id) }"
                  @click="() => toggleEmployeeSelection(item.id)"
                >
                  <div class="employee">
                    <a-avatar size="small" :src="item.profile_pic_url">
                      {{ item.last_name.charAt(0)
                      }}{{ item.first_name.charAt(0) }}
                    </a-avatar>
                    <a-typography-text>
                      {{ item.full_name }}
                    </a-typography-text>
                  </div>

                  <template #actions>
                    <i
                      v-if="selectedEmployeeIds.includes(item.id)"
                      class="ti ti-check ti-lg"
                      style="color: rgba(0, 0, 255, 0.6)"
                    />
                  </template>
                </a-list-item>
              </template>
            </a-list>
          </a-col>
          <a-col :span="24" v-if="selectedEmployees.length > 0">
            <a-row :gutter="[10, 10]" justify="space-between" align="middle">
              <a-col>
                <a-avatar-group>
                  <a-tooltip
                    v-for="emp in selectedEmployees"
                    :key="emp.id"
                    :title="emp.full_name"
                  >
                    <a-avatar
                      style="cursor: pointer"
                      @click="() => removeEmployee(emp)"
                      :src="emp.profile_pic_url"
                    >
                      {{ emp.last_name.charAt(0)
                      }}{{ emp.first_name.charAt(0) }}
                    </a-avatar>
                  </a-tooltip>
                </a-avatar-group>
              </a-col>

              <a-col>
                <a-tooltip title="Add selected employees" placement="top">
                  <a-button
                    size="small"
                    @click="addEmployees"
                    :loading="employeeAddLoading"
                  >
                    <i class="ti ti-check ti-lg" style="color: green" />
                  </a-button>
                </a-tooltip>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </template>
    </a-popover>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { PlusOutlined, UserOutlined } from "@ant-design/icons-vue";
import { companyService } from "@/services";
import { constants } from "@/helper/constants";

const props = defineProps({
  record: Object,
  employees: Array,
});

const isVisible = ref(false);

const emit = defineEmits(["update:record"]);
const query = ref(null);

const recordWritable = computed({
  get: () => props.record,
  set: (value) => emit("update:record", value),
});

const getItemEmployees = computed(() => {
  const holder = new Map();

  props.record.employees.forEach(({ employee }) => {
    holder.set(employee.id, employee);
  });

  return Array.from(holder.values());
});

const employeesFiltered = computed(() => {
  if (!query.value) return props.employees;

  return props.employees.filter((employee) => {
    const isInQuery = employee.fl_name
      .toLowerCase()
      .includes(query.value.toLowerCase());

    return isInQuery;
  });
});

const selectedEmployeeIds = ref([]);
const selectedEmployees = computed(() => {
  return props.employees.filter((emp) =>
    selectedEmployeeIds.value.includes(emp.id)
  );
});
function toggleEmployeeSelection(employeeId) {
  if (selectedEmployeeIds.value.includes(employeeId)) {
    selectedEmployeeIds.value = selectedEmployeeIds.value.filter(
      (id) => id !== employeeId
    );
  } else {
    selectedEmployeeIds.value = [...selectedEmployeeIds.value, employeeId];
  }
}
function removeEmployee(employee) {
  selectedEmployeeIds.value = selectedEmployeeIds.value.filter(
    (id) => id !== employee.id
  );
}

const employeeAddLoading = ref(false);
function addEmployees() {
  employeeAddLoading.value = true;

  companyService
    .store(constants.monthlyBillingAddUsers, {
      id: props.record.id,
      employee_ids: selectedEmployeeIds.value,
    })
    .then(() => {
      recordWritable.value = {
        ...recordWritable.value,
        employees: [
          ...selectedEmployeeIds.value.map((id) => {
            const emp = props.employees.find((emp) => emp.id === id);

            return {
              employee: emp,
              employee_id: emp.id,
            };
          }),
        ],
      };
      isVisible.value = false;
    })
    .finally(() => {
      employeeAddLoading.value = false;
    });
}

function visibleChange(visible) {
  if (!visible) {
    query.value = null;
    // selectedEmployeeIds.value = [];
  } else {
    selectedEmployeeIds.value = props.record.employees.map(
      (emp) => emp.employee_id
    );
  }
}
</script>

<style lang="less">
.parties {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.employees-list {
  height: 300px;
  overflow-y: auto;

  .employee {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.ant-list-item {
  padding: 8px 6px !important;
}

.employee-item {
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
}

.selected {
  background-color: rgba(0, 0, 255, 0.1);

  .employee {
    font-weight: bold;
  }

  &:hover {
    background-color: rgba(0, 0, 255, 0.2);
  }
}
</style>
