<template>
  <div>
    <a-form
      name="certificate_form"
      ref="certificate_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :sm="24">
          <a-form-item
            ref="certificate_id"
            label="Certificate Type"
            name="certificate_id"
            :extra="certificateExtraText"
          >
            <a-select
              v-model:value="formState.certificate_id"
              placeholder="Select certificate type"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="option in certificates"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24" v-if="certificate && certificate.is_expirable">
          <a-form-item
            ref="expiry_date"
            label="Expiration Date"
            name="expiry_date"
          >
            <a-date-picker
              v-model:value="formState.expiry_date"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
              :disabled-date="disabledDate"
              :allow-clear="false"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24" v-if="certificate && certificate.has_state">
          <a-form-item ref="state" label="State" name="state">
            <a-select
              v-model:value="formState.state"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="state in $constants.states"
                :key="state.name"
                :label="state.name"
                :value="state.name"
              >
                {{ state.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24" v-if="certificate">
          <a-form-item ref="attachment" label="Attachment" name="attachment">
            <a-upload
              :before-upload="
                (_, fileList) =>
                  beforeUpload(
                    fileList,
                    certificate && certificate.filetype_supported
                  )
              "
              :file-list="formState.attachment"
              :multiple="
                certificate && certificate.attachment_limit == 1 ? true : false
              "
              :accept="certificate && certificate.filetype_supported"
              :remove="onFileRemove"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons name="Create" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },
  data() {
    return {
      formState: {
        certificate_id: undefined,
        expiry_date: "",
        state: undefined,
        attachment: [],
      },
      rules: {
        certificate_id: [
          {
            required: true,
            type: "integer",
            message: "This field is required!",
          },
        ],
        attachment: [
          {
            required: true,
            type: "array",
            message: "This field is required!",
          },
        ],
        expiry_date: [
          {
            required: true,
            type: "date",
            message: "This field is required!",
          },
        ],
        state: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    certificates() {
      return this.extra.certificationTypes ?? [];
    },
    certificate() {
      return (
        this.certificates.find(
          (item) => this.formState.certificate_id == item.id
        ) ?? null
      );
    },
    certificateExtraText() {
      return this.certificate?.description ?? "";
    },
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    handleChange(info) {
      var isJPG = false;
      var isLt2M = false;
      var validEx = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
      if (info.file != null) {
        isJPG = validEx.indexOf(info.file.type) == -1 ? false : true;
        isLt2M = info.file.size / 1024 / 1024 < 5;
        if (!isJPG) {
          this.$message.error(
            `You can only upload ${validEx.toString(",")} file!`
          );
        } else if (!isLt2M) {
          this.$message.error("File must be smaller than 5MB.");
        }
        if (isJPG && isLt2M) {
          info.fileList.forEach((element) => {
            if (element.originFileObj != null) {
              this.$comman.getBase64(element.originFileObj, (base64Url) => {
                element.thumbUrl = base64Url.match(/.{1,250000}/g);
              });
            }
          });
          if (info.file.originFileObj != null) {
            this.$comman.getBase64(info.file.originFileObj, (base64Url) => {
              info.file.thumbUrl = base64Url.match(/.{1,250000}/g);
            });
          }
          this.formState.attachment =
            this.certificate.attachment_limit == 0
              ? [info.fileList.pop()]
              : info.fileList;
        } else {
          this.formState.attachment =
            this.certificate.attachment_limit == 0
              ? info.fileList[1]
                ? [info.fileList[0]]
                : []
              : info.fileList;
        }
      }
    },
    beforeUpload(fileList, types) {
      const acceptedTypes =
        types?.split(",") ||
        "image/jpeg,image/jpg,image/png,application/pdf".split(",");

      this.formState.attachment = fileList.filter((file) => {
        const isCorrectType = acceptedTypes.includes(file.type);
        const isCorrectSize = file.size / 1024 / 1024 < 5;

        if (!isCorrectType) {
          this.$message.error(`File type of ${file.type} is not supported.`);
          return false;
        }
        if (!isCorrectSize) {
          this.$message.error(
            `File size of ${file.name} is too large. Max: 5MB`
          );
          return false;
        }
        return true;
      });
      return false;
    },
    onFileRemove(file) {
      const items = this.formState.attachment;
      const idx = items.findIndex((v) => v.uid === file.uid);
      if (idx > -1) items.splice(idx, 1);
    },
    onSubmit() {
      this.loadingStart();
      const isExpirable = this.certificate.is_expirable == 1;
      const hasState = this.certificate.has_state == 1;

      const data = new FormData();
      data.append("employee_id", this.extra.employee_id);
      data.append("certificate_id", this.formState.certificate_id);
      hasState && data.append("state", this.formState.state);
      isExpirable && data.append("expiry_date", this.formState.expiry_date);

      // const allowMultiple = !!this.certificate.attachment_limit;
      // if (allowMultiple) {
      this.formState.attachment.forEach((file) => {
        data.append(`attachment[]`, file);
      });
      // } else data.append("attachment", this.formState.attachment[0]);

      commonService
        .store(this.$constants.storeEmployeeCertificate, data, true)
        .then(async (res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
  },

  watch: {
    "formState.certificate_id": function () {
      this.formState.attachment = [];
    },
  },
};
</script>
