<template>
  <div>
    <a-form :model="model" :rules="rules" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item
            ref="type"
            label="Type"
            name="type"
            :validateStatus="validate('type', 'status')"
            :help="validate('type', 'msg')"
          >
            <a-select
              v-model:value="model.type"
              placeholder="Select via"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in $constants.leadTypes.filter((e) =>
                  [1, 2, 3].includes(e.value)
                )"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              >
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="date"
            label="Date"
            name="date"
            :validateStatus="validate('date', 'status')"
            :help="validate('date', 'msg')"
          >
            <a-date-picker
              v-model:value="model.date"
              :disabled-date="$comman.disabledFutureDate"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="comment"
            label="Comment"
            name="comment"
            :validateStatus="validate('comment', 'status')"
            :help="validate('comment', 'msg')"
          >
            <a-textarea
              v-model:value="model.comment"
              placeholder="Enter comment here"
              :rows="5"
            />
          </a-form-item>
        </a-col>

        <a-col v-if="!record.id" :span="12">
          <a-form-item
            ref="new_stages_of_lead_id"
            label="Stage Of Lead"
            name="new_stages_of_lead_id"
            :validateStatus="validate('new_stages_of_lead_id', 'status')"
            :help="validate('new_stages_of_lead_id', 'msg')"
          >
            <a-select
              v-model:value="model.new_stages_of_lead_id"
              placeholder="Select stage of lead"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in stagesOfLead"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../../mixins/formMixins";
import { commonService } from "../../../../services";

export default {
  mixins: [formMixins],

  components: {
    UploadOutlined,
  },

  data() {
    return {
      model: {},
      rules: {
        type: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
          },
        ],
        date: [
          {
            required: true,
            message: "This field is required!",
            type: "date",
          },
        ],
        comment: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        new_stages_of_lead_id: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
          },
        ],
      },

      stagesOfLead: [],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },

    lead() {
      return this.extra;
    },
  },

  mounted() {
    this.fetchStagesOfLead();
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    fetchStagesOfLead() {
      commonService.get(this.$constants.crmGetStagesOfLeadUrl).then((res) => {
        this.stagesOfLead = res.data;
      });
    },

    getFormState() {
      this.model = {
        id: this.record.id ?? undefined,
        lead_id: this.lead.id,
        type: this.record.type ?? undefined,
        date: this.record.date ? moment(this.record.date) : null,
        comment: this.record.comment ?? "",
        new_stages_of_lead_id:
          this.record?.new_stages_of_lead_id ?? this.lead.stages_of_lead_id,
      };
    },

    onSubmit() {
      this.loadingStart();

      commonService
        .store(this.$constants.crmLeadFollowUpStoreUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.errors = err.errors;
          }
          console.log("error", err);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
