<template>
  <a-layout-content>
    <page-header>
      <template #title> Manufacturers</template>

      <template #buttons>
        <a-button type="primary" @click="handleCreateNewManufacturerEvent">
          <a-space><i class="ti ti-plus ti-lg"></i> Add Manufacturer</a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #email="{ record }">
          <span v-html="updateEmailFormat(record.email)"></span>
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                class="pl-sm-0"
                @click="handleEditManufacturerEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this manufacturer?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure you want to restore this manufacturer?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
              <a-tooltip title="Restore">
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.manufacturerDatatableUrl,
      statusChangeUrl: this.$constants.manufacturerChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "QuickBooks Name",
          dataIndex: "quickbooks_name",
          key: "quickbooks_name",
          customRender: ({ record }) =>
            record.quickbooks_vendor_info
              ? JSON.parse(record.quickbooks_vendor_info)?.DisplayName ?? "N/A"
              : "N/A",
        },
        {
          title: "Account Number",
          dataIndex: "account_number",
          key: "account_number",
          sorter: true,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          sorter: true,
          slots: {
            customRender: "email",
          },
        },
        {
          title: "Contact No",
          dataIndex: "number",
          key: "number",
          customRender: ({ text }) => text ?? "N/A",
          sorter: true,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 80,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
        sortField: this.$route.query?.sortField ?? "name",
        sortOrder: this.$route.query?.sortOrder ?? "ascend",
      },

      vendors: [],
    };
  },

  mounted() {
    this.getQuickbooksData();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleEditManufacturerEvent(record) {
      this.open({
        title: "Edit Manufacturer",
        path: "manufacturer.store",
        callback: this.refresh,
        record: record,
        extra: {
          vendors: this.vendors,
        },
      });
    },

    handleCreateNewManufacturerEvent() {
      this.open({
        title: "Add Manufacturer",
        path: "manufacturer.store",
        callback: this.refresh,
        extra: {
          vendors: this.vendors,
        },
      });
    },

    updateEmailFormat(emails) {
      if (emails != null) {
        return emails.replaceAll(",", ",<br />");
      }
      return "-";
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, {
          names: ["vendors"],
        })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "vendors") {
                this.vendors = JSON.parse(v.data);
              }
            });
          }
        });
    },
  },
};
</script>
