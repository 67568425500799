<template>
  <template v-for="route in routes">
    <template v-if="route.type == 0">
      <tr :key="`${parent_id}_${route.id}`">
        <td :style="depth_calculation(depth)">
          <b>{{ route.name }}</b><br>
          <small><b>{{ route.description }}</b></small>
        </td>
        <td class="text-center">
          <i class="fas fa-check" @click="big_checkbox" :data-routes="get_routes_id(route, 'c')" data-checked="false"></i>
        </td>
        <td class="text-center">
          <i class="fas fa-check" @click="big_checkbox" :data-routes="get_routes_id(route, 'r')" data-checked="false"></i>
        </td>
        <td class="text-center">
          <i class="fas fa-check" @click="big_checkbox" :data-routes="get_routes_id(route, 'u')" data-checked="false"></i>
        </td>
        <td class="text-center">
          <i class="fas fa-check" @click="big_checkbox" :data-routes="get_routes_id(route, 'd')" data-checked="false"></i>
        </td>
      </tr>
      <template v-if="route.children">
        <child-routes :key="`child_routes_${parent_id}_${route.id}`" :routes="route.children" :parent_id="route.id" :depth="depth+1" @big_checkbox_checked="big_checkbox_checked" :default_selected_routes="default_selected_routes" />
      </template>
    </template>
    <template v-else>
      <template v-if="parent_id == route.parent_id">
        <tr :key="`${parent_id}_${route.id}`">
          <td :style="depth_calculation(depth)">
            {{ route.name }}
          </td>
          <td class="text-center">
            <template v-if="route.c">
              <a-checkbox :value="route.c.id" :disabled="check_routes_id(route.c.id)"></a-checkbox>
            </template>
            <template v-else>
              -
            </template>
          </td>
          <td class="text-center">
            <template v-if="route.r != null">
              <a-checkbox :value="route.r.id" :disabled="check_routes_id(route.r.id)"></a-checkbox>
            </template>
            <template v-else>
              -
            </template>
          </td>
          <td class="text-center">
            <template v-if="route.u != null">
              <a-checkbox :value="route.u.id" :disabled="check_routes_id(route.u.id)"></a-checkbox>
            </template>
            <template v-else>
              -
            </template>
          </td>
          <td class="text-center">
            <template v-if="route.d != null">
              <a-checkbox :value="route.d.id" :disabled="check_routes_id(route.d.id)"></a-checkbox>
            </template>
            <template v-else>
              -
            </template>
          </td>
        </tr>
      </template>
    </template>
  </template>
</template>

<script>
import ChildRoutes from '@/components/childRoutes'

export default {
	name: 'child-routes',
	components: {
		'child-routes': ChildRoutes
  },
  emits: {
    big_checkbox_checked: () =>{
      return true
    }
  }, 
	props: {
		routes: Array,
		parent_id: Number,
    depth: Number,
    default_selected_routes: Array
  },
	methods: {
		depth_calculation(depth) {
			const cal = depth * 15;
			return `padding-left: calc(${cal}px + 8px)`;
		},
		get_routes_id(route, type) {
			const ids = [];
			if (route.children) {
				route.children.forEach((v) => {
					if (v[type]) {
            var index = this.default_selected_routes.indexOf(v[type]['id']);
            if (index == -1) {
              ids.push(v[type]['id']);
            }
					}
				});
			}
			return ids.join(',');
		},
		big_checkbox(e) {
      const routes = e.currentTarget.dataset.routes;
			const checked = e.currentTarget.dataset.checked;
			if (checked == 'false') {
				e.currentTarget.dataset.checked = 'true';
				var type = 'checked';
			} else {
				e.currentTarget.dataset.checked = 'false';
				var type = 'unchecked';
			}
			if (routes != '') {
				const ids = Array.from(routes.split(","), Number);
				this.$emit('big_checkbox_checked', ids, type);
			}
    },
    big_checkbox_checked(ids, type) {
      this.$emit('big_checkbox_checked', ids, type);
    },
    check_routes_id(id) {
      var index = this.default_selected_routes.indexOf(id);
      if (index == -1) {
        return false;
      }
      return true;
    }
  },
  watch: { 
    default_selected_routes: function(newVal, oldVal) {
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    }
  }
}
</script>

<style>

</style>