<template>
  <a-layout-content>
    <page-header>
      <template #title>
        Create New Order
      </template>
      <template #buttons>
        <back-button />
      </template>
    </page-header>
    <a-row :gutter="[16, 0]">
      <a-col :span="24" :md="12" class="product-listing">
        <product-list />
      </a-col>
      <a-col :span="24" :md="12" class="cart">
        <cart />
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import productList from "../../components/order/productList.vue";
import cart from "@/components/order/cart.vue";
export default {
  components: {
    backButton,
    productList,
    cart,
  },
};
</script>

<style scoped></style>
