<template>
  <a-row :gutter="[20, 0]">
    <template v-if="isCati">
      <a-col :span="24">
        <a-alert type="warning" class="mb-sm-1" show-icon>
          <template #message>
            Informational Notes
          </template>
          <template #description>
            If Carolina Autotransfusion owned disposable products were used,
            please use this section to charge for the products that were
            utilized.
            <br />
            If this section does not apply to this procedure, simply hit "next"
            below.
          </template>
        </a-alert>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Reservoirs (CATI Owned Products Only)
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.reservoirs" :key="i">
            <a-col :span="10">
              <a-form-item :name="['reservoirs', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['reservoirs', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.reservoirs.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('reservoirs', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.reservoirs.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('reservoirs')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Processing Sets (CATI Owned Products Only)
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.processing_sets" :key="i">
            <a-col :span="10">
              <a-form-item :name="['processing_sets', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['processing_sets', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.processing_sets.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('processing_sets', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.processing_sets.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('processing_sets')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Suction Lines (CATI Owned Products Only)
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.section_lines" :key="i">
            <a-col :span="10">
              <a-form-item :name="['section_lines', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['section_lines', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.section_lines.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('section_lines', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.section_lines.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('section_lines')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Filters (CATI Owned Products Only)
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.filters" :key="i">
            <a-col :span="10">
              <a-form-item :name="['filters', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['filters', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.filters.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('filters', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.filters.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('filters')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Platelet Gel Disposables (CATI Owned Products Only)
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.platelet_gel_disposables" :key="i">
            <a-col :span="10">
              <a-form-item
                :name="['platelet_gel_disposables', i, 'product_id']"
              >
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['platelet_gel_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.platelet_gel_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('platelet_gel_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.platelet_gel_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('platelet_gel_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Miscellaneous (CATI Owned Products Only)
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.miscellaneous" :key="i">
            <a-col :span="10">
              <a-form-item :name="['miscellaneous', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['miscellaneous', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.miscellaneous.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('miscellaneous', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.miscellaneous.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('miscellaneous')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>
    </template>

    <template v-if="hospitalIdIs([24, 66])">
      <a-col :span="24">
        <a-alert type="warning" class="mb-sm-1" show-icon>
          <template #message>
            Informational Notes
          </template>
          <template #description>
            If Keystone Perfusion owned disposable products were used, please
            use this section to charge for the products that were utilized.
            <br />
            If this section does not apply to this procedure, simply hit "next"
            below.
          </template>
        </a-alert>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Cell Saver Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.cell_saver_disposables" :key="i">
            <a-col :span="10">
              <a-form-item :name="['cell_saver_disposables', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['cell_saver_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.cell_saver_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('cell_saver_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.cell_saver_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('cell_saver_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>
    </template>

    <template v-if="isKeystone">
      <a-col :span="24">
        <a-alert type="warning" class="mb-sm-1" show-icon>
          <template #message>
            Informational Notes
          </template>
          <template #description>
            If Keystone Perfusion owned disposable products were used, please
            use this section to charge for the products that were utilized.
            <br />
            If this section does not apply to this procedure, simply hit "next"
            below.
          </template>
        </a-alert>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Tubing Packs and Oxygenators
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template
            v-for="(v, i) in model.tubing_packs_and_oxygenators"
            :key="i"
          >
            <a-col :span="10">
              <a-form-item
                :name="['tubing_packs_and_oxygenators', i, 'product_id']"
              >
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['tubing_packs_and_oxygenators', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.tubing_packs_and_oxygenators.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('tubing_packs_and_oxygenators', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.tubing_packs_and_oxygenators.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('tubing_packs_and_oxygenators')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Arterial Cannula
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.arterial_cannula" :key="i">
            <a-col :span="10">
              <a-form-item :name="['arterial_cannula', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['arterial_cannula', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.arterial_cannula.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('arterial_cannula', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.arterial_cannula.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('arterial_cannula')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Venous Cannula
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.venous_cannula" :key="i">
            <a-col :span="10">
              <a-form-item :name="['venous_cannula', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['venous_cannula', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.venous_cannula.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('venous_cannula', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.venous_cannula.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('venous_cannula')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Cardioplegia Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.cardioplegia_disposables" :key="i">
            <a-col :span="10">
              <a-form-item
                :name="['cardioplegia_disposables', i, 'product_id']"
              >
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['cardioplegia_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.cardioplegia_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('cardioplegia_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.cardioplegia_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('cardioplegia_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col v-if="hospitalIdIs([7, 8])" :span="24">
        <a-divider orientation="left">
          Vents, Pump Suckers, and Sumps
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template
            v-for="(v, i) in model.vents_pump_suckers_and_sumps"
            :key="i"
          >
            <a-col :span="10">
              <a-form-item
                :name="['vents_pump_suckers_and_sumps', i, 'product_id']"
              >
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['vents_pump_suckers_and_sumps', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.vents_pump_suckers_and_sumps.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('vents_pump_suckers_and_sumps', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.vents_pump_suckers_and_sumps.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('vents_pump_suckers_and_sumps')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Platelet Gel Therapy Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template
            v-for="(v, i) in model.platelet_gel_therapy_disposables"
            :key="i"
          >
            <a-col :span="10">
              <a-form-item
                :name="['platelet_gel_therapy_disposables', i, 'product_id']"
              >
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['platelet_gel_therapy_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.platelet_gel_therapy_disposables.length > 1"
                type="link"
                @click="handleMinusEvent('platelet_gel_therapy_disposables', i)"
                class="danger-color font-20"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.platelet_gel_therapy_disposables.length == i + 1"
                type="link"
                @click="handlePlusEvent('platelet_gel_therapy_disposables')"
                class="font-20"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col v-if="hospitalIdIs([7, 8, 77])" :span="24">
        <a-divider orientation="left">
          Cell Saver Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.cell_saver_disposables" :key="i">
            <a-col :span="10">
              <a-form-item :name="['cell_saver_disposables', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['cell_saver_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.cell_saver_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('cell_saver_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.cell_saver_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('cell_saver_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Connectors Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.connectors_disposables" :key="i">
            <a-col :span="10">
              <a-form-item :name="['connectors_disposables', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['connectors_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.connectors_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('connectors_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.connectors_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('connectors_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Tubing Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.tubing_disposables" :key="i">
            <a-col :span="10">
              <a-form-item :name="['tubing_disposables', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['tubing_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.tubing_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('tubing_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.tubing_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('tubing_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col :span="24">
        <a-divider orientation="left">
          Miscellaneous Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.miscellaneous_disposables" :key="i">
            <a-col :span="10">
              <a-form-item
                :name="['miscellaneous_disposables', i, 'product_id']"
              >
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['miscellaneous_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.miscellaneous_disposables.length > 1"
                type="link"
                @click="handleMinusEvent('miscellaneous_disposables', i)"
                class="danger-color font-20"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.miscellaneous_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('miscellaneous_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <a-col v-if="hospitalIdIs(7)" :span="24">
        <a-divider orientation="left">
          ECMO Disposables
        </a-divider>
        <a-row :gutter="[20, 0]">
          <a-col :span="10">
            <b>Item Name</b>
          </a-col>
          <a-col :span="10">
            <b>Quantity Used</b>
          </a-col>
          <a-col :span="4"></a-col>

          <template v-for="(v, i) in model.mcmo_disposables" :key="i">
            <a-col :span="10">
              <a-form-item :name="['mcmo_disposables', i, 'product_id']">
                <a-select
                  v-model:value="v.product_id"
                  placeholder="Select any one"
                  show-search
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="product in products"
                    :key="product.id"
                    :label="product.name"
                    :value="product.id"
                  >
                    {{ product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="10">
              <a-form-item
                :name="['mcmo_disposables', i, 'qty']"
                :rules="[
                  {
                    validator: $validation.numeric,
                    message: 'must be in numbers!',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <a-input
                  v-model:value="v.qty"
                  placeholder="Enter used quantity here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-button
                v-if="model.mcmo_disposables.length > 1"
                type="link"
                class="danger-color font-20"
                @click="handleMinusEvent('mcmo_disposables', i)"
              >
                <MinusCircleOutlined />
              </a-button>

              <a-button
                v-if="model.mcmo_disposables.length == i + 1"
                type="link"
                class="font-20"
                @click="handlePlusEvent('mcmo_disposables')"
              >
                <PlusCircleOutlined />
              </a-button>
            </a-col>
          </template>
        </a-row>
      </a-col>

      <template v-if="hospitalIdIs(66)">
        <a-col :span="12">
          <a-form-item
            ref="nacl_bags"
            label="0.9 % NaCl Bags"
            name="nacl_bags"
            :validateStatus="validate('nacl_bags', 'status')"
            :help="validate('nacl_bags', 'msg')"
          >
            <a-input
              v-model:value="model.nacl_bags"
              placeholder="Enter qty here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="heparin_vials"
            label="Heparin Vials"
            name="heparin_vials"
            :validateStatus="validate('heparin_vials', 'status')"
            :help="validate('heparin_vials', 'msg')"
          >
            <a-input
              v-model:value="model.heparin_vials"
              placeholder="Enter qty here"
            />
          </a-form-item>
        </a-col>
      </template>
    </template>
  </a-row>
</template>

<script>
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    MinusCircleOutlined,
    PlusCircleOutlined,
  },

  props: {
    model: { require: true, type: Object },
    hospitals: { require: true, type: Array },
    products: { require: true, type: Array },
    isCati: { require: true, type: Boolean },
    isKeystone: { require: true, type: Boolean },
  },

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },

    hospitalIdIs(params) {
      if (typeof params == "number") {
        params = [params];
      }

      return params.includes(this.model.hospital_id);
    },

    handleMinusEvent(key, i) {
      this.model[key].splice(i, 1);
    },

    handlePlusEvent(key) {
      this.model[key].push({
        product_id: null,
        qty: null,
      });
    },
  },
};
</script>
