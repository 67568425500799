<template>
  <div>
    <a-spin tip="Loading..." :spinning="loading">
      <a-card title="Position Information">
        <a-form
          ref="poiRef"
          :layout="formLayout"
          :model="model"
          :rules="rules"
          @finish="handleFinish"
        >
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="workersCompensationCode"
                label="Worker's Comp Code"
                name="workersCompensationCode"
              >
                <a-select
                  v-model:value="model.workersCompensationCode"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="code in workersCompCode"
                    :key="code.id"
                    :label="code.description"
                    :value="code.id"
                  >
                    {{ code.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="16"></a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="jobFunctionID"
                label="Job Function"
                name="jobFunctionID"
              >
                <a-select
                  v-model:value="model.jobFunctionID"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="jobFun in jobFucntions"
                    :key="jobFun.id"
                    :label="jobFun.description"
                    :value="jobFun.id"
                  >
                    {{ jobFun.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="16"></a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="jobTitle" label="Job Title" name="jobTitle">
                <a-input
                  v-model:value="model.jobTitle"
                  placeholder="Enter job title here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="16"></a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="supervisesOthers"
                label="Supervises Others"
                name="supervisesOthers"
              >
                <a-radio-group v-model:value="model.supervisesOthers">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}SupervisesOthers`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="supervisorName"
                label="Supervisor Name"
                name="supervisorName"
              >
                <a-input
                  v-model:value="model.supervisorName"
                  placeholder="Enter supervisor name here..."
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-button type="danger" class="mr-xs-1 width-125" html-type="submit">
            SAVE
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="resetPoiForm">
            CANCEL
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="back">
            BACK
          </a-button>
          <a-button type="primary" class="width-125" @click="next">
            SAVE & NEXT
          </a-button>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { commonService } from "../../services";

export default {
  props: ["rules", "model", "personId"],
  data() {
    return {
      formLayout: "vertical",
      loading: false,
      workersCompCode: [],
      jobFucntions: [],
    };
  },
  mounted() {
    this.loadData();
    this.$refs.workersCompensationCode.$el
      .getElementsByClassName("ant-select-selection-search-input")[0]
      .focus();
  },
  methods: {
    loadData() {
      Promise.all([this.getWorkersCompCode(), this.getJobFunctions(), false])
        .then((values) => {
          [this.workersCompCode, this.jobFucntions] = values;
        })
        .catch(() => this.$message.error("Somthing went wrong!"));
    },
    getWorkersCompCode() {
      return commonService
        .get(this.$constants.insWorkersCompCodeUrl)
        .then((res) => res);
    },
    getJobFunctions() {
      return commonService
        .get(this.$constants.insJobFunctionsUrl)
        .then((res) => res);
    },
    handleFinish(value) {
      this.handleSubmit(value);
    },
    resetPoiForm() {
      Object.keys(this.model).forEach((el) => {
        if (el == "workersCompensationCode" || el == "jobFunctionID") {
          this.model[el] = null;
        } else {
          this.model[el] = "";
        }
      });
    },
    next() {
      this.$refs.poiRef.validate().then((val) => {
        this.handleSubmit(val, "pushToNextStep");
      });
    },
    back() {
      this.$emit("callback", false, -1, this.personId);
    },
    handleSubmit(value, whereToGo = "") {
      this.loading = true;
      value.onboarding_person_id = this.personId;
      commonService
        .store(this.$constants.onboardingPositionUrl, value)
        .then((res) => {
          this.loading = false;
          if (whereToGo == "pushToNextStep") {
            this.$message.success(res.message);
            this.$emit(
              "callback",
              false,
              +1,
              res.onboarding_person_id,
              res.data
            );
          } else {
            this.$emit("callback", true);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped>
.width-125 {
  width: 125px;
}
@media (max-width: 767px) {
  .width-125 {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
