import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";
import hasRoles from './middleware/hasRoles';

export default [
  {
    path: "/biomed-service-records",
    name: "biomed-service-record",
    component: route("biomedServiceRecord/index"),
    meta: {
      title: "Biomed Service Record",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/biomed-service-record/create",
    name: "create-biomed-service-record",
    component: route("biomedServiceRecord/store"),
    meta: {
      title: "Create Biomed Service Record",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: [1, 4]
    },
  },
  {
    path: "/biomed-service-record/edit/:id",
    name: "edit-biomed-service-record",
    component: route("biomedServiceRecord/store"),
    meta: {
      title: "Edit Biomed Service Record",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: [1, 4]
    },
  },
  {
    path: "/biomed-service-record/show/:id",
    name: "show-biomed-service-record",
    component: route("biomedServiceRecord/show"),
    meta: {
      title: "Show Biomed Service Record",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
