<template>
  <div>
    <a-space direction="vertical" style="width: 100%">
      <a-descriptions
        :column="1"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Delivered to">
          {{ extra.hospital_name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Mfg Order Id">
          {{ record.mfg_order_no }}
        </a-descriptions-item>
        <a-descriptions-item label="Carrier Partner">
          {{ record.carrier_partner }}
        </a-descriptions-item>
        <a-descriptions-item label="Tracking No">
          <a-typography-link
            target="_blank"
            v-if="
              $comman.getTrackingURL(record.carrier_partner, record.tracking_no)
            "
            :href="
              $comman.getTrackingURL(record.carrier_partner, record.tracking_no)
            "
          >
            {{ record.tracking_no }}
          </a-typography-link>
          <span v-else>
            {{ record.tracking_no }}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label="Shipment Date">
          {{ $customDate.mdy(record.shipment_date) }}
        </a-descriptions-item>
        <a-descriptions-item label="Notes">
          {{ record.notes }}
        </a-descriptions-item>
        <a-descriptions-item label="Attachment">
          <template v-if="record.attachment">
            <a :href="record.attachment_full_path" target="_blank">File</a>
          </template>
          <template v-else> N/A </template>
        </a-descriptions-item>
      </a-descriptions>

      <a-alert
        v-if="showOrderProductError"
        message="Need to select at least one product for received item."
        type="error"
      />

      <a-form
        name="received_order_item_form"
        ref="received_order_item_form"
        :model="formState"
        layout="vertical"
        @finish="onSubmit()"
      >
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="record.products"
          :pagination="false"
          size="small"
          class="ant-table-vertical-align-baseline"
          :scroll="{ x: true, y: 0 }"
        >
          <template #damaged_qty="{ record, index }">
            <template v-if="record.status == 1">
              {{ record.damaged_qty }}
            </template>

            <template v-else>
              <a-form-item
                :key="`${record.id}_damaged_qty`"
                :name="['products', index, 'damaged_qty']"
                :rules="{
                  trigger: ['change', 'blur'],
                  type: 'integer',
                  message: 'Required!',
                  validator: async (rule, value) =>
                    damagedQtyValidation(rule, value, record),
                }"
              >
                <a-input-number
                  type="number"
                  v-model:value="record.damaged_qty"
                  placeholder="qty"
                  :disabled="!formState.ids.includes(record.id)"
                  @change="updateDamagedQtyProduct(record)"
                />
              </a-form-item>
            </template>
          </template>

          <template #missing_qty="{ record, index }">
            <template v-if="record.status == 1">
              {{ record.missing_qty }}
            </template>

            <template v-else>
              <a-form-item
                :key="`${record.id}_missing_qty`"
                :name="['products', index, 'missing_qty']"
                :rules="{
                  trigger: ['change', 'blur'],
                  type: 'integer',
                  message: 'Required!',
                  validator: async (rule, value) =>
                    missingQtyValidation(rule, value, record),
                }"
              >
                <a-input-number
                  type="number"
                  v-model:value="record.missing_qty"
                  placeholder="qty"
                  :disabled="!formState.ids.includes(record.id)"
                  @change="updateMissingQtyProduct(record)"
                />
              </a-form-item>
            </template>
          </template>

          <template #received_qty="{ record, index }">
            <template v-if="record.status == 1">
              {{ record.received_qty }}
            </template>

            <template v-else>
              <a-form-item
                :key="`${record.id}_received_qty`"
                :name="['products', index, 'received_qty']"
                :rules="{
                  trigger: ['change', 'blur'],
                  type: 'integer',
                  message: 'Required!',
                  validator: async (rule, value) =>
                    receivedQtyValidation(rule, value, record),
                }"
              >
                <a-input-number
                  type="number"
                  v-model:value="record.received_qty"
                  placeholder="qty"
                  :min="0"
                  disabled
                />
              </a-form-item>
            </template>
          </template>
        </a-table>

        <side-drawer-buttons
          v-if="record.products.filter((v) => v.status == 0).length > 0"
          name="Received"
        />
      </a-form>
    </a-space>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";

export default {
  data() {
    return {
      showOrderProductError: false,
      formState: { ids: [], products: [] },
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          customRender: ({ record }) => record.product?.name ?? "N/A",
        },
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
          customRender: ({ record }) => record.product?.sku ?? "N/A",
        },
        {
          title: "Ref Code",
          dataIndex: "ref_code",
          key: "ref_code",
          customRender: ({ record }) => record.product?.ref_code ?? "N/A",
        },
        {
          title: "Qty/Box",
          dataIndex: "qty",
          key: "qty",
          customRender: ({ record }) => `${record.qty}/${record.set}`,
        },
        {
          title: "Damaged Qty",
          dataIndex: "damaged_qty",
          key: "damaged_qty",
          slots: {
            customRender: "damaged_qty",
          },
          width: 100,
        },
        {
          title: "Missing Qty",
          dataIndex: "missing_qty",
          key: "missing_qty",
          slots: {
            customRender: "missing_qty",
          },
          width: 100,
        },
        {
          title: "Received Qty",
          dataIndex: "received_qty",
          key: "received_qty",
          slots: {
            customRender: "received_qty",
          },
          width: 100,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    rowSelection() {
      if (this.record.products.filter((v) => v.status == 0).length > 0) {
        return {
          selectedRowKeys: this.formState.ids,
          onChange: this.onSelectChange,
          getCheckboxProps: (record) => {
            return {
              disabled: record.status == 1,
            };
          },
        };
      }
      return null;
    },
  },

  mounted() {
    this.formState.products = this.record.products;
    this.updateProducts();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      if (this.formState.ids.length == 0) {
        this.showOrderProductError = true;
        return;
      }

      let data = {
        ...this.formState,
        order_id: this.record.order_id,
        dispatch_id: this.record.id,
      };

      this.loadingStart();
      commonService
        .store(this.$constants.dispatchedOrderItemReceivedUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    onSelectChange(selectedRowKeys) {
      this.formState.ids = selectedRowKeys;
      if (this.formState.ids.length == 0) {
        this.showOrderProductError = true;
      } else {
        this.showOrderProductError = false;
      }
    },

    updateDamagedQtyProduct(record) {
      if (record.damaged_qty == "" || record.damaged_qty < 0) {
        record.damaged_qty = 0;
      }
      if (record.damaged_qty > (record.qty - record.missing_qty ?? 0)) {
        record.damaged_qty = record.qty - record.missing_qty ?? 0;
      }
      record.received_qty =
        record.qty - record.damaged_qty - record.missing_qty;
    },

    updateMissingQtyProduct(record) {
      if (record.missing_qty == "" || record.missing_qty < 0) {
        record.missing_qty = 0;
      }
      if (record.missing_qty > (record.qty - record.damaged_qty ?? 0)) {
        record.missing_qty = record.qty - record.damaged_qty ?? 0;
      }
      record.received_qty =
        record.qty - record.damaged_qty - record.missing_qty;
    },

    updateProducts() {
      this.formState.products = this.formState.products.map((item) => {
        if (this.formState.ids.includes(item.id) || item.status == 1) {
          item.missing_qty = item.missing_qty ?? 0;
          item.damaged_qty = item.damaged_qty ?? 0;
          item.received_qty = item.qty - item.damaged_qty - item.missing_qty;
          return item;
        } else {
          item.missing_qty = null;
          item.damaged_qty = null;
          item.received_qty = null;
          return item;
        }
      });
    },

    damagedQtyValidation(...params) {
      return this.qtyValidation(...params);
    },

    missingQtyValidation(...params) {
      return this.qtyValidation(...params);
    },

    receivedQtyValidation(...params) {
      return this.qtyValidation(...params);
    },

    qtyValidation(rule, value, record) {
      if (
        this.formState.ids.includes(record.id) &&
        (value === undefined || value === "" || value === null)
      ) {
        return Promise.reject(rule.message);
      }
      return Promise.resolve();
    },
  },

  watch: {
    "formState.ids": {
      deep: true,
      handler: function () {
        this.updateProducts();
      },
    },
  },
};
</script>
