<template>
  <div class="parties">
    <a-avatar-group>
      <a-tooltip
        v-for="emp in getItemEmployees"
        :key="emp.id"
        :title="emp.employee.full_name"
      >
        <a-avatar style="cursor: pointer" :src="emp.employee.profile_pic_url">
          {{ emp.employee.last_name.charAt(0)
          }}{{ emp.employee.first_name.charAt(0) }}
        </a-avatar>
      </a-tooltip>
    </a-avatar-group>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { PlusOutlined, UserOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  record: Object,
  employees: Array,
});

const getItemEmployees = computed(() => {
  const holder = new Map();

  props.record.items.forEach(({ employees }) => {
    employees.forEach((employee) => {
      holder.set(employee.employee_id, employee);
    });
  });

  return Array.from(holder.values());
});
</script>

<style lang="less">
.parties {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.employees-list {
  height: 300px;
  overflow-y: auto;

  .employee {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.ant-list-item {
  padding: 8px 2px !important;
}
</style>
