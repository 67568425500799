<template>
  <a-form
    name="ecmo_checklist_form"
    ref="ecmo_checklist_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row type="flex">
          <a-col flex="35px">
            <a-tooltip title="Select all N/A option">
              <a-button
                type="link"
                size="small"
                class="p-sm-0"
                @click="selectAllOption(setupChecklist, 'na_setup')"
              >
                N/A
              </a-button>
            </a-tooltip>
          </a-col>
          <a-col flex="35px">
            <a-tooltip title="Select all option">
              <a-button
                type="link"
                size="small"
                class="p-sm-0"
                @click="selectAllOption(setupChecklist, 'setup')"
              >
                <CheckOutlined />
              </a-button>
            </a-tooltip>
          </a-col>
          <a-col flex="auto">
            <strong>SETUP CHECKLIST</strong>
          </a-col>
        </a-row>

        <template v-for="option in setupChecklist" :key="option">
          <a-row type="flex">
            <a-col flex="35px">
              <a-checkbox
                v-model:checked="model.na_setup[option]"
                @click="(e) => optionChecked(e, option, 'na_setup')"
              />
            </a-col>
            <a-col flex="35px">
              <a-checkbox
                v-model:checked="model.setup[option]"
                @click="(e) => optionChecked(e, option, 'setup')"
              />
            </a-col>
            <a-col flex="calc(100% - 70px)">
              <span v-html="option" />

              <template v-if="option == 'Full tank of O<sub>2</sub>'">
                <a-input
                  v-model:value="model.o2"
                  size="small"
                  style="width: 100px; margin-left: 10px"
                />
              </template>
            </a-col>
          </a-row>
        </template>
      </a-col>

      <a-col :span="12">
        <a-row type="flex">
          <a-col flex="35px">
            <a-tooltip title="Select all N/A option">
              <a-button
                type="link"
                size="small"
                class="p-sm-0"
                @click="selectAllOption(preChecklist, 'na_pre_prime')"
              >
                N/A
              </a-button>
            </a-tooltip>
          </a-col>
          <a-col flex="35px">
            <a-tooltip title="Select all option">
              <a-button
                type="link"
                size="small"
                class="p-sm-0"
                @click="selectAllOption(preChecklist, 'pre_prime')"
              >
                <CheckOutlined />
              </a-button>
            </a-tooltip>
          </a-col>
          <a-col flex="auto">
            <strong>PRE-PRIME CHECKLIST</strong>
          </a-col>
        </a-row>

        <template v-for="option in preChecklist" :key="option">
          <a-row type="flex">
            <a-col flex="35px">
              <a-checkbox
                v-model:checked="model.na_pre_prime[option]"
                @click="(e) => optionChecked(e, option, 'na_pre_prime')"
              />
            </a-col>
            <a-col flex="35px">
              <a-checkbox
                v-model:checked="model.pre_prime[option]"
                @click="(e) => optionChecked(e, option, 'pre_prime')"
              />
            </a-col>
            <a-col flex="calc(100% - 70px)">
              <span v-html="option" />
            </a-col>
          </a-row>
        </template>

        <br /><br />

        <a-row type="flex">
          <a-col flex="35px">
            <a-tooltip title="Select all N/A option">
              <a-button
                type="link"
                size="small"
                class="p-sm-0"
                @click="selectAllOption(postChecklist, 'na_post_prime')"
              >
                N/A
              </a-button>
            </a-tooltip>
          </a-col>
          <a-col flex="35px">
            <a-tooltip title="Select all option">
              <a-button
                type="link"
                size="small"
                class="p-sm-0"
                @click="selectAllOption(postChecklist, 'post_prime')"
              >
                <CheckOutlined />
              </a-button>
            </a-tooltip>
          </a-col>
          <a-col flex="auto">
            <strong>POST-PRIME CHECKLIST</strong>
          </a-col>
        </a-row>

        <template v-for="option in postChecklist" :key="option">
          <a-row type="flex">
            <a-col flex="35px">
              <a-checkbox
                v-model:checked="model.na_post_prime[option]"
                @click="(e) => optionChecked(e, option, 'na_post_prime')"
              />
            </a-col>
            <a-col flex="35px">
              <a-checkbox
                v-model:checked="model.post_prime[option]"
                @click="(e) => optionChecked(e, option, 'post_prime')"
              />
            </a-col>
            <a-col flex="calc(100% - 70px)">
              <span v-html="option" />
            </a-col>
          </a-row>
        </template>
      </a-col>

      <a-col :span="24">
        <a-form-item ref="notes" label="Notes" name="notes" class="mt-sm-1">
          <a-textarea v-model:value="model.notes" :autoSize="{ minRows: 2 }" />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row type="flex" justify="center" class="mt-sm-2">
      <a-space direction="vertical">
        <vue-signature-pad
          :options="{ onEnd: saveSign }"
          ref="signPad"
          :customStyle="$constants.signPadCustomCss"
        />

        <a-row type="flex" justify="center">
          <a-col>
            <a-typography-paragraph>
              Signature indicates that the information reported is complete and
              accurate.
            </a-typography-paragraph>
          </a-col>
        </a-row>
        <a-row v-if="model.sign" justify="center">
          <a-space>
            <a-button
              htmlType="button"
              type="primary"
              danger
              ghost
              @click="clearSign"
            >
              Clear
            </a-button>
          </a-space>
        </a-row>
      </a-space>
    </a-row>

    <modal-buttons name="Save" />
  </a-form>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  components: { CheckOutlined },

  data() {
    return {
      model: {
        uuid: null,
        setup: [],
        pre_prime: [],
        post_prime: [],
        na_setup: [],
        na_pre_prime: [],
        na_post_prime: [],
        o2: "",
        notes: "",
        sign: null,
        submitted_at: null,
      },
    };
  },

  computed: {
    ...mapGetters("modal", ["record"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    checklist() {
      return this.record?.live_chart?.ecmo?.checklist ?? null;
    },

    setupChecklist() {
      return this.$constants.ecmoSetupChecklist;
    },

    preChecklist() {
      return this.$constants.ecmoPrePrimeChecklist;
    },

    postChecklist() {
      return this.$constants.ecmoPostPrimeChecklist;
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      if (this.checklist) {
        let oldSetup = this.checklist?.setup ?? [];
        let oldnaSetup = this.checklist?.na_setup ?? [];
        let setup = {};
        let na_setup = {};
        this.setupChecklist.forEach((e) => {
          setup[e] = oldSetup.includes(e) ? true : false;
          na_setup[e] = oldnaSetup.includes(e) ? true : false;
        });

        let oldPrePrime = this.checklist?.pre_prime ?? [];
        let oldnaPrePrime = this.checklist?.na_pre_prime ?? [];
        let pre_prime = {};
        let na_pre_prime = {};
        this.preChecklist.forEach((e) => {
          pre_prime[e] = oldPrePrime.includes(e) ? true : false;
          na_pre_prime[e] = oldnaPrePrime.includes(e) ? true : false;
        });

        let oldPostPrime = this.checklist?.post_prime ?? [];
        let oldnaPostPrime = this.checklist?.na_post_prime ?? [];
        let post_prime = {};
        let na_post_prime = {};
        this.postChecklist.forEach((e) => {
          post_prime[e] = oldPostPrime.includes(e) ? true : false;
          na_post_prime[e] = oldnaPostPrime.includes(e) ? true : false;
        });

        this.model.setup = setup ?? [];
        this.model.na_setup = na_setup ?? [];
        this.model.pre_prime = pre_prime ?? [];
        this.model.na_pre_prime = na_pre_prime ?? [];
        this.model.post_prime = post_prime ?? [];
        this.model.na_post_prime = na_post_prime ?? [];
        this.model.o2 = this.checklist?.o2 ?? "";
        this.model.notes = this.checklist?.notes ?? "";
        this.model.sign = this.checklist?.sign ?? null;
        this.model.submitted_at = this.checklist?.submitted_at ?? null;
        setTimeout(() => {
          this.$refs.signPad.fromDataURL(this.model.sign);
        }, 500);
      }
    },

    onSubmit() {
      if (this.model.sign == null) {
        this.$message.error("Signature must be required.");
        return;
      }

      let newSetup = [];
      for (const [key, value] of Object.entries(this.model.setup)) {
        if (value) {
          newSetup.push(key);
        }
      }
      let newnaSetup = [];
      for (const [key, value] of Object.entries(this.model.na_setup)) {
        if (value) {
          newnaSetup.push(key);
        }
      }
      let newPrePrime = [];
      for (const [key, value] of Object.entries(this.model.pre_prime)) {
        if (value) {
          newPrePrime.push(key);
        }
      }
      let newnaPrePrime = [];
      for (const [key, value] of Object.entries(this.model.na_pre_prime)) {
        if (value) {
          newnaPrePrime.push(key);
        }
      }
      let newPostPrime = [];
      for (const [key, value] of Object.entries(this.model.post_prime)) {
        if (value) {
          newPostPrime.push(key);
        }
      }
      let newnaPostPrime = [];
      for (const [key, value] of Object.entries(this.model.na_post_prime)) {
        if (value) {
          newnaPostPrime.push(key);
        }
      }

      let data = {
        uuid: this.record.uuid,
        setup: newSetup,
        na_setup: newnaSetup,
        pre_prime: newPrePrime,
        na_pre_prime: newnaPrePrime,
        post_prime: newPostPrime,
        na_post_prime: newnaPostPrime,
        o2: this.model.o2,
        notes: this.model.notes,
        employee_id: this.checklist
          ? this.checklist.employee_id
          : this.employee.id,
        employee_name: this.checklist
          ? this.checklist.employee_name
          : this.employee.full_name,
        sign: this.model.sign,
        submitted_at:
          this.model.submitted_at ??
          moment().tz(this.timezone).format("MM-DD-YYYY HH:mm:ss"),
      };

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreECMOChecklistUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },

    clearSign() {
      this.model.sign = null;
      this.$refs.signPad.clearSignature();
    },

    saveSign() {
      const { isEmpty, data } = this.$refs.signPad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.sign = data;
      return Promise.resolve();
    },

    optionChecked(e, option, key) {
      if (e.target.checked) {
        if (key.includes("na_")) {
          this.model[`${key.replace("na_", "")}`][option] = false;
        } else {
          this.model[`na_${key}`][option] = false;
        }
      }
    },

    selectAllOption(options, key) {
      let allChecked = options.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      options.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key = key.includes("na_") ? `${key.replace("na_", "")}` : `na_${key}`;
      options.forEach((option) => (this.model[key][option] = false));
    },
  },
};
</script>
