<template>
  <a-card title="Recruitment Funnel" size="small">
    <div class="" id="candidate-status" />
  </a-card>
</template>

<script setup>
import { Funnel } from "@antv/g2plot";
import { onMounted, reactive, ref } from "vue";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";

const loading = ref(false);
const chart = ref("");
const data = ref([]);
const config = reactive({
  xField: "stage",
  yField: "count",
  interactions: [{ type: "element-active" }],
  label: null,
  legend: {
    position: "top",
    offsetX: 8,
    itemValue: {
      formatter: (text) => {
        const stage = data.value.find((e) => e.stage === text);
        return `(${stage.count})`;
      },
    },
  },
});

const renderChart = () => {
  loading.value = true;
  commonService
    .store(constants.hrCandidateStatusUrl)
    .then((res) => {
      data.value = res.data;
      chart.value.changeData(data.value);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  chart.value = new Funnel("candidate-status", {
    data: data.value,
    ...config,
  });
  chart.value.render();
  renderChart();
});
</script>
