<template>
  <a-descriptions
    size="small"
    :column="{ xl: 2, xs: 1 }"
    bordered
    :labelStyle="{ width: '200px' }"
  >
    <a-descriptions-item label="Name">
      {{ record.full_name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Employee Code">
      {{ record.employee_code ?? "N/A" }}
    </a-descriptions-item>
  </a-descriptions>

  <br />

  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="termination_date"
          label="Termination Date"
          name="termination_date"
        >
          <a-date-picker
            v-model:value="formState.termination_date"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allow-clear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="last_work_date"
          label="Last Work Date"
          name="last_work_date"
        >
          <a-date-picker
            v-model:value="formState.last_work_date"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allow-clear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="rehire_eligible_indicator"
          label="Rehire eligible indicator?"
          name="rehire_eligible_indicator"
        >
          <a-radio-group v-model:value="formState.rehire_eligible_indicator">
            <a-radio :value="1">Yes</a-radio>
            <a-radio :value="0">No</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="severance_eligible_indicator"
          label="Severance eligible indicator?"
          name="severance_eligible_indicator"
        >
          <a-radio-group v-model:value="formState.severance_eligible_indicator">
            <a-radio :value="1">Yes</a-radio>
            <a-radio :value="0">No</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :xs="24">
        <a-form-item
          ref="termination_comment"
          label="Comment"
          name="termination_comment"
        >
          <a-textarea
            v-model:value="formState.termination_comment"
            placeholder="Ex: Looking for better growth and opportunities"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { PlusOutlined },

  emits: ["callback"],

  data() {
    return {
      formState: {
        termination_date: null,
        last_work_date: null,
        rehire_eligible_indicator: null,
        severance_eligible_indicator: null,
        termination_comment: null,
      },
      rules: {
        termination_date: {
          required: true,
          message: "This field is required!",
          type: "date",
        },
        last_work_date: {
          required: true,
          message: "This field is required!",
          type: "date",
        },
        rehire_eligible_indicator: {
          required: true,
          message: "This field is required!",
        },
        severance_eligible_indicator: {
          required: true,
          message: "This field is required!",
        },
        comment: {
          required: true,
          message: "This field is required!",
        },
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
  },

  async mounted() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    disabledDate(current) {
      return current < this.$comman.momentTz().add(1, "day").startOf("day");
    },

    onSubmit() {
      this.loadingStart();
      companyService
        .store(this.$constants.employeeTerminateUrl, {
          ...this.formState,
          termination_date: this.$customDate.ymd(
            this.formState.termination_date
          ),
          last_work_date: this.$customDate.ymd(this.formState.last_work_date),
          id: this.record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
