<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />
    <a-row :gutter="[20, 20]">
      <a-col v-if="showDuplicateEntryInfoMessage" :span="24">
        <a-alert type="info">
          <template #message>
            It appears that you have already submitted an expense with the same
            date and type. Please ensure that you are not entering duplicate
            information.
          </template>
        </a-alert>
      </a-col>

      <a-col :xs="24" :xl="18">
        <a-card size="small" :loading="cardLoader" hoverable>
          <a-form
            name="expense_form"
            ref="expense_form"
            :model="model"
            :rules="rules"
            layout="vertical"
          >
            <a-row :gutter="[20, 0]">
              <a-col :xs="24" :lg="12">
                <a-form-item
                  ref="hospital_id"
                  label="Associated Hospital"
                  name="hospital_id"
                >
                  <a-select
                    v-model:value="model.hospital_id"
                    placeholder="Select hospital"
                    show-search
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="hospital in hospitals"
                      :key="hospital.id"
                      :label="hospital.name"
                      :value="hospital.id"
                    >
                      {{ hospital.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="12">
                <a-form-item
                  ref="expense_type_id"
                  label="Expense Type"
                  name="expense_type_id"
                >
                  <a-select
                    v-model:value="model.expense_type_id"
                    placeholder="Select Expense Type"
                    show-search
                    optionFilterProp="label"
                    @change="handleExpenseTypeChange"
                  >
                    <a-select-option
                      v-for="expenseType in expenseTypes"
                      :key="expenseType.id"
                      :label="expenseType.name"
                      :value="expenseType.id"
                    >
                      {{ expenseType.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="12">
                <a-form-item
                  ref="date_range"
                  label="Expense start and end date"
                  name="date_range"
                >
                  <a-range-picker
                    :allowClear="false"
                    v-model:value="model.date_range"
                    :disabled-date="$comman.disabledFutureDate"
                    :format="$constants.datepickerFormat"
                    :placeholder="[
                      $constants.datePickerPlaceholder,
                      $constants.datePickerPlaceholder,
                    ]"
                    @change="checkDuplicateEntry"
                  />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="12">
                <a-row :gutter="[20, 0]">
                  <a-col :xs="24" :lg="model.expense_type_id == 9 ? 12 : 24">
                    <a-form-item
                      ref="amount"
                      label="Expense Amount (USD)"
                      name="amount"
                      :validateStatus="validate('amount', 'status')"
                      :help="validate('amount', 'msg')"
                    >
                      <a-input-number
                        v-model:value="model.amount"
                        placeholder="Enter Expense Amount"
                        :min="0"
                        :step="0.01"
                        :formatter="$comman.currencyParserformatter"
                        :parser="$comman.currencyParser"
                        :disabled="model.expense_type_id == 9"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col v-if="model.expense_type_id == 9" :lg="12">
                    <a-form-item
                      ref="trip_type"
                      label="Trip Type"
                      name="trip_type"
                    >
                      <a-radio-group
                        v-model:value="model.trip_type"
                        @change="
                          model.amount = $comman.getRoundTripExpenseAmount(
                            distanceInMiles * model.trip_type
                          )
                        "
                      >
                        <a-radio-button
                          v-for="option in $constants.expenseTripTypes"
                          :key="option.value"
                          :value="option.value"
                        >
                          {{ option.text }}
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>

              <template v-if="model.expense_type_id == 1">
                <a-col :xs="24" :lg="12">
                  <a-form-item
                    ref="organization_name"
                    label="Name of Organization / Sponsor"
                    name="organization_name"
                  >
                    <a-input
                      v-model:value="model.organization_name"
                      placeholder="Enter organization name here..."
                    />
                  </a-form-item>
                </a-col>

                <a-col :xs="24" :lg="12">
                  <a-form-item
                    ref="subject"
                    label="Subject / Purpose"
                    name="subject"
                  >
                    <a-input
                      v-model:value="model.subject"
                      placeholder="Enter subject here..."
                    />
                  </a-form-item>
                </a-col>

                <a-col :xs="24" :lg="12">
                  <a-form-item ref="city" label="City" name="city">
                    <a-input
                      v-model:value="model.city"
                      placeholder="Enter city here..."
                    />
                  </a-form-item>
                </a-col>

                <a-col :xs="24" :lg="12">
                  <a-form-item ref="state" label="State" name="state">
                    <a-select
                      v-model:value="model.state"
                      placeholder="Select state"
                      show-search
                      optionFilterProp="label"
                    >
                      <a-select-option
                        v-for="state in $constants.states"
                        :key="state.name"
                        :label="state.name"
                        :value="state.name"
                      >
                        {{ state.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </template>

              <template v-if="model.expense_type_id == 9">
                <a-col :span="24">
                  <a-spin :spinning="spinning" tip="Loading...">
                    <a-row :gutter="[20, 0]">
                      <a-col :xs="24" :lg="12" class="mb-sm-2">
                        <a-form-item
                          ref="origin_address"
                          label="Origin Address"
                          name="origin_address"
                        >
                          <GMapAutocomplete
                            :value="model.origin_address"
                            placeholder="Search Origin Location"
                            @place_changed="
                              (place) => setPlace(place, 'origin_address')
                            "
                            class="ant-input"
                          >
                          </GMapAutocomplete>
                        </a-form-item>

                        <GMapMap
                          :center="originCenter"
                          :zoom="16"
                          map-type-id="terrain"
                          class="expense-google-map"
                        >
                          <GMapCluster :zoomOnClick="true">
                            <GMapMarker
                              :key="index"
                              v-for="(m, index) in originMarker"
                              :position="m.position"
                              :clickable="true"
                              :draggable="true"
                              @click="center = m.position"
                            />
                          </GMapCluster>
                        </GMapMap>
                      </a-col>
                      <a-col :xs="24" :lg="12">
                        <a-form-item
                          ref="destination_address"
                          label="Destination Address"
                          name="destination_address"
                        >
                          <GMapAutocomplete
                            :value="model.destination_address"
                            placeholder="Search Destination Location"
                            @place_changed="
                              (place) => setPlace(place, 'destination_address')
                            "
                            class="ant-input"
                          >
                          </GMapAutocomplete>
                        </a-form-item>

                        <GMapMap
                          :center="destinationCenter"
                          :zoom="16"
                          map-type-id="terrain"
                          class="expense-google-map"
                        >
                          <GMapCluster>
                            <GMapMarker
                              :key="index"
                              v-for="(m, index) in destinationMarker"
                              :position="m.position"
                              :clickable="true"
                              :draggable="true"
                              @click="center = m.position"
                            />
                          </GMapCluster>
                        </GMapMap>
                      </a-col>
                      <a-col :xs="24">
                        <a-descriptions
                          :column="2"
                          title=""
                          layout="vertical"
                          size="small"
                          bordered
                          class="mb-sm-1"
                        >
                          <a-descriptions-item
                            v-if="'origin_addresses' in model.distance"
                            label="Origin Address"
                          >
                            {{ model.distance.origin_addresses[0] }}
                          </a-descriptions-item>
                          <a-descriptions-item
                            v-if="'destination_addresses' in model.distance"
                            label="Destination Address"
                          >
                            {{ model.distance.destination_addresses[0] }}
                          </a-descriptions-item>
                          <template v-if="'rows' in model.distance">
                            <a-descriptions-item label="Distance In Miles">
                              {{ distanceInMiles }}
                            </a-descriptions-item>
                            <a-descriptions-item label="Time In Minutes">
                              {{
                                model.distance.rows[0].elements[0].duration.text
                              }}
                            </a-descriptions-item>
                            <a-descriptions-item
                              :label="`${tripTypeText} Mileage`"
                            >
                              {{ distanceInMiles * model.trip_type }}
                            </a-descriptions-item>
                            <a-descriptions-item
                              :label="`${tripTypeText} Amount`"
                            >
                              {{
                                $comman.withCurrency(
                                  $comman.getRoundTripExpenseAmount(
                                    distanceInMiles * model.trip_type
                                  )
                                )
                              }}
                            </a-descriptions-item>
                          </template>
                        </a-descriptions>
                      </a-col>
                    </a-row>
                  </a-spin>
                </a-col>
              </template>

              <a-col :xs="24">
                <a-form-item ref="description" label="Notes" name="description">
                  <a-textarea
                    v-model:value="model.description"
                    placeholder="Enter notes here..."
                    :auto-size="{ minRows: 3, maxRows: 6 }"
                  />
                </a-form-item>
              </a-col>

              <a-col v-if="model.expense_type_id != 2" :xs="24">
                <a-form-item
                  ref="receipts"
                  label="Receipts"
                  name="receipts"
                  :validateStatus="validate('receipts', 'status')"
                  :help="validate('receipts', 'msg')"
                >
                  <a-upload-dragger
                    :fileList="model.receipts"
                    :multiple="true"
                    :remove="handleRemoveReceipt"
                    :before-upload="beforeUploadReceipt"
                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                  >
                    <p class="ant-upload-drag-icon">
                      <inbox-outlined></inbox-outlined>
                    </p>
                    <p class="ant-upload-text">
                      Drag & drop files to upload or Click to select file.
                    </p>
                  </a-upload-dragger>
                </a-form-item>
              </a-col>

              <template v-if="model.expense_type_id == 12">
                <a-col :span="24">
                  <a-descriptions
                    title="Meal Reimbursement Policy"
                    size="small"
                  >
                    <a-descriptions-item label="">
                      “Effective Jan. 1, 2021, the meal reimbursement rate shall
                      be up to $35 per day. <br />
                      Meal expenses are ONLY reimbursed if your work assignment
                      is overnight or long enough that you need to stop for
                      substantial sleep or rest to properly perform your
                      duties.<br />
                      The Internal Revenue Service (IRS) and the Department of
                      Labor (DOL) require itemized receipts that detail every
                      individual item ordered on the bill. This requirement is
                      not satisfied with just the credit card charge slip – our
                      policy is that you must provide both the credit card
                      charge slip (which should include the tip) and the
                      detailed itemized receipt. Failure to submit
                      receipts/documentation to substantiate your expenses may
                      result in the expense being deemed ineligible for
                      reimbursement.”
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
              </template>
            </a-row>

            <a-button
              type="primary"
              html-type="button"
              :loading="submitBtnLoader"
              @click="handleFinish"
            >
              {{ submitBtnText }}
            </a-button>
          </a-form>
        </a-card>
      </a-col>
      <a-col :xs="24" :xl="6">
        <a-card>
          <a-descriptions
            title="Expenses Notes"
            layout="vertical"
            bordered
            size="small"
            :column="1"
          >
            <a-descriptions-item :label="expenseType.name ?? 'N/A'">
              <div v-html="expenseType.help_text ?? 'N/A'" />
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import { InboxOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { expenseRules } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton, InboxOutlined },

  data() {
    return {
      id: this.$route.params.id ?? 0,
      cardLoader: false,
      submitBtnLoader: false,
      spinning: false,
      showDuplicateEntryInfoMessage: false,
      hospitals: [],
      expenseTypes: [],
      model: {
        date: null,
        hospital_id: null,
        expense_type_id: null,
        amount: null,
        trip_type: null,
        description: null,
        date_range: [],
        receipts: [],
        organization_name: null,
        subject: null,
        city: null,
        state: null,
        origin_address: null,
        destination_address: null,
        distance: {},
        distanceInKM: 0,
      },
      rules: expenseRules,

      originCenter: { lat: 51.093048, lng: 6.84212 },
      destinationCenter: { lat: 51.093048, lng: 6.84212 },
      originMarker: [{ position: { lat: 51.093048, lng: 6.84212 } }],
      destinationMarker: [{ position: { lat: 51.093048, lng: 6.84212 } }],
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.id == 0) {
        return "Add New Expense";
      }
      return "Edit Expense";
    },

    submitBtnText() {
      if (this.id == 0) {
        return "Create";
      }
      return "Update";
    },

    expenseType() {
      return (
        this.expenseTypes.find(
          (item) => item.id == this.model.expense_type_id
        ) ?? {}
      );
    },

    distanceInMiles() {
      return this.$comman.getDistanceInMiles(
        this.model?.distance?.rows && this.model?.distance?.rows[0]?.elements
          ? this.model?.distance?.rows[0]?.elements[0]?.distance?.value
          : 0
      );
    },

    tripTypeText() {
      return this.$comman.getValueFromObject(
        this.model,
        "trip_type",
        this.$constants.expenseTripTypes
      );
    },
  },

  async mounted() {
    await this.fetchHospitals();
    await this.fetchExpenseTypes();

    if (this.id != 0) {
      this.fetchExpense();
    } else {
      this.model.date = moment();
    }
    // this.geolocate();
  },

  methods: {
    fetchExpense() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.expenseShowUrl, { id: this.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;
          let extraInfo = data.extra_info ? JSON.parse(data.extra_info) : null;
          let extraData = {};
          let receipts = [];
          if (data.receipts.length > 0) {
            data.receipts.forEach((v) => {
              receipts.push({
                id: v.id,
                uid: v.file_name,
                name: v.file_name_with_ext,
                url: v.full_file_path,
              });
            });
          }

          if (extraInfo) {
            if (data.expense_type_id == 1) {
              extraData = {
                organization_name: extraInfo.organization_name,
                subject: extraInfo.subject,
                city: extraInfo.city,
                state: extraInfo.state,
              };
            } else if (data.expense_type_id == 9) {
              extraData = {
                distance: extraInfo,
                origin_address: extraInfo.origin_addresses[0],
                destination_address: extraInfo.destination_addresses[0],
                trip_type: extraInfo.trip_type ?? 2,
              };

              this.originCenter = extraInfo.origin_centers[0];
              this.destinationCenter = extraInfo.destination_centers[0];
              this.originMarker = [{ position: this.originCenter }];
              this.destinationMarker = [{ position: this.destinationCenter }];
            }
          }

          this.model = {
            ...this.model,
            date: moment(data.date),
            hospital_id: data.hospital_id,
            expense_type_id: data.expense_type_id,
            amount: data.amount,
            description: data.description,
            date_range: [moment(data.start_date), moment(data.end_date)],
            receipts: receipts,
            ...extraData,
          };

          this.handleExpenseTypeChange();
          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    fetchHospitals() {
      commonService.get(this.$constants.getHospitalsUrl).then((res) => {
        this.hospitals = res.data;
      });
    },

    fetchExpenseTypes() {
      commonService.get(this.$constants.getExpenseTypesUrl).then((res) => {
        this.expenseTypes = res.data;
      });
    },

    handleDateChangeEvent() {
      if (this.model.date_range.length == 0) {
        this.model.date_range = [this.model.date, this.model.date];
      }
    },

    handleRemoveReceipt(file) {
      const index = this.model.receipts.indexOf(file);
      const newFileList = this.model.receipts.slice();
      newFileList.splice(index, 1);
      this.model.receipts = newFileList;
    },

    beforeUploadReceipt(file) {
      if (file.size < 5 * 1024 * 1024) {
        this.model.receipts = [...this.model.receipts, file];
      } else {
        this.$message.error(`${file.name} size is more than 5MB.`);
      }
      return false;
    },

    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        let center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.originCenter = this.destinationCenter = center;
      });
    },

    setPlace(place, field) {
      this.model[field] = place.formatted_address;
      this.$refs.expense_form.validateFields([field]);
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      if (field == "origin_address") {
        this.originMarker = [{ position: marker }];
        this.originCenter = marker;
      } else {
        this.destinationMarker = [{ position: marker }];
        this.destinationCenter = marker;
      }

      this.getDistance();
    },

    pinChange(center) {
      console.log(center);
    },

    getDistance() {
      this.spinning = true;

      if (
        this.model.origin_address != null &&
        this.model.destination_address != null
      ) {
        commonService
          .get(this.$constants.getDistanceUrl, {
            origins: `${this.originCenter.lat},${this.originCenter.lng}`,
            destinations: `${this.destinationCenter.lat},${this.destinationCenter.lng}`,
          })
          .then((res) => {
            this.model.distance = res;
            this.model.distance.origin_centers = [this.originCenter];
            this.model.distance.destination_centers = [this.destinationCenter];

            let miles = this.$comman.getDistanceInMiles(
              res.rows[0].elements[0].distance.value
            );
            this.model.amount = this.$comman.getRoundTripExpenseAmount(
              miles * this.model.trip_type
            );
            this.spinning = false;
          });
      } else {
        this.spinning = false;
      }
    },

    handleFinish() {
      this.$refs.expense_form
        .validate()
        .then(() => {
          const formData = new FormData();
          formData.append("id", this.id);
          formData.append(
            "employee_id",
            "id" in this.employee ? this.employee.id : 1
          );

          Object.keys(this.model).forEach((key) => {
            if (this.model[key] == null) {
              this.model[key] = "";
            }

            if (key == "receipts") {
              if (this.model[key][0]) {
                Object.values(this.model[key]).forEach((img) => {
                  if ("id" in img) {
                    formData.append(`old_${key}[]`, img.id);
                  } else {
                    formData.append(`${key}[]`, img);
                  }
                });
              }
            }

            if (key == "distance") {
              if (
                this.model.expense_type_id == 9 &&
                this.model[key] != {} &&
                "origin_addresses" in this.model[key]
              ) {
                formData.append(
                  "extra_info",
                  JSON.stringify({
                    ...this.model[key],
                    trip_type: this.model.trip_type,
                  })
                );
              } else if (this.model.expense_type_id == 1) {
                let extraInfo = {
                  organization_name: this.model.organization_name,
                  subject: this.model.subject,
                  city: this.model.city,
                  state: this.model.state,
                };
                formData.append("extra_info", JSON.stringify(extraInfo));
              } else {
                formData.append("extra_info", null);
              }
            } else if (key == "date") {
              formData.append(key, this.$customDate.ymd(this.model[key]));
            } else if (key == "date_range") {
              formData.append(
                "start_date",
                this.$customDate.ymd(this.model[key][0])
              );
              formData.append(
                "end_date",
                this.$customDate.ymd(this.model[key][1])
              );
            } else if (
              [
                "organization_name",
                "subject",
                "city",
                "state",
                "distanceInKM",
                "trip_type",
              ].includes(key)
            ) {
              //
            } else if (
              !["origin_address", "destination_address", "receipts"].includes(
                key
              )
            ) {
              formData.append(key, this.model[key]);
            }
          });

          this.submitBtnLoader = true;
          let receiptLength = this.model.receipts.length;

          commonService
            .store(this.$constants.expenseStoreUrl, formData)
            .then((res) => {
              if (res.success) {
                this.submitBtnLoader = false;
                this.$message.success(res.message);
              } else {
                this.$message.error(res.message);
              }
              this.$router.go(-1);
            })
            .catch((err) => {
              this.submitBtnLoader = false;
              if ("errors" in err) {
                this.errors = err.errors;
                for (let i = 0; i < receiptLength; i++) {
                  if (`receipts.${i}` in this.errors) {
                    this.$message.error(this.errors[`receipts.${i}`]);
                  }
                }
              } else {
                this.$message.error(err);
              }
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    handleExpenseTypeChange() {
      if (this.model.expense_type_id == 9) {
        this.rules.receipts[0].required = false;
        this.rules.receipts[1].min = 0;
        if (this.model.trip_type == null) {
          this.model.trip_type = 2;
        }
      } else {
        this.rules.receipts[0].required = true;
        this.rules.receipts[1].min = 1;
        this.model.trip_type = null;
      }

      this.checkDuplicateEntry();
    },

    checkDuplicateEntry() {
      this.showDuplicateEntryInfoMessage = false;
      if (this.model.date_range[0] && this.model.expense_type_id) {
        commonService
          .store(this.$constants.expenseCheckDateUrl, {
            date: this.$customDate.ymd(this.model.date_range[0]),
            expense_type_id: this.model.expense_type_id,
            id: this.id,
          })
          .then((res) => {
            this.showDuplicateEntryInfoMessage = res.data > 0;
          });
      }
    },
  },
};
</script>

<style scoped>
.vue-map-container.expense-google-map {
  height: 300px;
}
</style>
