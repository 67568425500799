<template>
  <div>
    <!-- <a-card title="Login Page"> -->
    <a-form
      name="login_form"
      ref="login_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <template v-if="mailSent">
        <div class="section-heading">
          <h2>2FA</h2>
          <p>One Time Password (OTP) has been sent on your contact number.</p>
        </div>

        <a-form-item ref="username" label="Username" name="username">
          <a-input
            v-model:value="formState.username"
            placeholder="Enter username here"
            disabled
          />
        </a-form-item>

        <a-form-item
          ref="otp"
          label="One-time code "
          name="otp"
          extra="Example: 123456"
        >
          <a-input-number type="number" v-model:value="formState.otp" />
        </a-form-item>

        <template v-if="timer > 0">
          <a-typography-text type="danger">
            One Time Password (OTP) is valid till {{ countdown }}
          </a-typography-text>
        </template>

        <template v-else>
          <a-button :loading="sendingOtp" type="link" @click="resendOtp">
            Resend OTP
          </a-button>
        </template>

        <a-button
          size="large"
          type="primary"
          html-type="submit"
          :loading="loading"
          block
        >
          Submit
        </a-button>

        <a-button
          htmlType="button"
          size="large"
          type="link"
          block
          danger
          @click="resetForm"
        >
          Reset
        </a-button>
      </template>

      <template v-else>
        <div class="section-heading">
          <h2>Sign In</h2>
          <p>Please enter your credentials to proceed.</p>
        </div>

        <a-form-item ref="username" label="Username" name="username">
          <a-input
            v-model:value="formState.username"
            placeholder="Enter username here"
          />
        </a-form-item>

        <a-form-item ref="password" label="Password" name="password">
          <a-input-password
            v-model:value="formState.password"
            placeholder="Enter password here"
          />
        </a-form-item>

        <a-button
          size="large"
          type="primary"
          html-type="submit"
          :loading="loading"
          block
        >
          Sign In
        </a-button>
        <router-link :to="{ name: 'forgot_password' }" class="btn-link">
          Reset Password
        </router-link>
      </template>
    </a-form>
    <!-- </a-card> -->
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import { authService, commonService } from "../../services";
import moment from "moment-timezone";

export default {
  data() {
    return {
      loading: false,
      sendingOtp: false,
      mailSent: false,
      otpValidSeconds: 300,
      timer: 0,
      timerOn: null,
      formState: {
        username: "",
        password: "",
        otp: null,
      },
      rules: {
        username: [
          {
            required: true,
            message: "Username is required!",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Username must be a valid email!",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required!",
          },
        ],
        otp: [
          {
            required: true,
            message: "OTP is required!",
            validator: this.$validation.otpValidation,
          },
        ],
      },
    };
  },

  computed: {
    countdown() {
      return moment.utc(this.timer * 1000).format("mm:ss");
    },
  },

  unmounted() {
    notification.destroy();
  },

  methods: {
    onSubmit() {
      this.$refs.login_form
        .validate()
        .then((val) => {
          if (this.mailSent && this.timer == 0) {
            this.$message.error("OTP is expired. Try to resend OTP.");
            return;
          }

          this.loading = true;
          let data = {
            username: this.formState.username,
            password: this.formState.password,
          };
          if (this.mailSent) {
            data.otp = this.formState.otp;
          }
          authService
            .login(data)
            .then((res) => {
              this.$message.success(res.message);
              this.mailSent = true;
              this.loading = false;
              this.startTimer();
            })
            .catch((err) => {
              this.loading = false;
              if (!err.status) {
                // this.$message.error(err.message);
                notification.error({
                  message: "Error",
                  description: err.message,
                  duration: 0,
                });
                return;
              }
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetForm() {
      this.loading = false;
      this.mailSent = false;
      this.formState = {
        username: "",
        password: "",
        otp: null,
      };
    },

    startTimer() {
      this.timer = this.otpValidSeconds;
      this.timerOn = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        }
        if (this.timer == 0) {
          clearInterval(this.timerOn);
        }
      }, 1000);
    },

    resendOtp() {
      this.sendingOtp = true;
      commonService
        .store(this.$constants.resendOtpUrl, {
          email: this.formState.username,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.startTimer();
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => (this.sendingOtp = false));
    },
  },
};
</script>
