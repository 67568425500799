<template>
  <a-layout-content>
    <page-header>
      <template #title> Human Resource Incident Reports </template>

      <template #buttons>
        <router-link :to="{ name: 'hr-incident.create' }">
          <a-button type="primary">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add New </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'hr-incident.show',
                params: { id: record.id },
              }"
            >
              <a-tooltip title="View">
                <a-button size="small" type="link" class="primary-color">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <template v-if="record.status != 0">
              <a-tooltip title="Download">
                <a-button type="link" size="small" @click="downloadPdf(record)">
                  <i class="ti ti-download ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>

            <template v-if="record.status == 1">
              <router-link
                :to="{
                  name: 'hr-incident.edit',
                  params: { id: record.id },
                }"
              >
                <a-tooltip title="Edit">
                  <a-button size="small" type="link">
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <a-popconfirm
                title="Are you sure you want to delete this HR incident?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteIncident(record.id)"
              >
                <a-tooltip title="Delete">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.hrIncidentDatatableUrl,
      columns: [
        {
          title: "Report No",
          dataIndex: "incident_no",
          key: "incident_no",
          sorter: true,
        },
        {
          title: "Report Date",
          dataIndex: "date_of_report",
          key: "date_of_report",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Incident Date",
          dataIndex: "date_of_incident",
          key: "date_of_incident",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.hospital?.name ?? "-",
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Employee Name",
          dataIndex: "employee_name",
          key: "employee_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.employee?.full_name ?? "-",
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Type",
          dataIndex: "incident_type",
          key: "type",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.type
            ? [this.$route.query?.type]
            : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],

      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Reported",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "Followup Added",
          status: 2,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Deleted",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],

      defaultFilterParams: {
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
        type: this.$route.query?.type ? [this.$route.query?.type] : undefined,
      },
    };
  },

  methods: {
    deleteIncident(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.hrIncidentDeleteUrl, { id: id })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },

    downloadPdf(data) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.hrIncidentDownloadPdfUrl,
          { id: data.id },
          `hr-incident.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },
  },
};
</script>
