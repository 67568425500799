<template>
  <a-row justify="center"
    ><!-- :style="{ height: '100vh' }" -->
    <div v-if="loading" :style="{ height: '100vh' }">
      <a-row type="flex" justify="space-between" align="middle">
        <a-spin size="large" />
      </a-row>
    </div>
    <a-col :xs="{ span: 24, order: 2 }" :lg="{ span: 16, order: 1 }">
      <div id="auth-carousel">
        <!-- https://github.com/gs-shop/vue-slick-carousel/blob/master/docs/API.md#props -->
        <a-carousel autoplay :autoplaySpeed="10000">
          <template v-for="(announcement, index) in announcements" :key="index">
            <div>
              <div class="post-item">
                <img :src="announcement.full_image_path" alt="img" />
                <div class="content">
                  <h3>
                    <a :href="announcement.url ?? '#'" target="_blank">
                      {{ announcement.title }}
                    </a>
                  </h3>
                  <p>
                    {{ announcement.description }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </a-carousel>
      </div>
    </a-col>
    <a-col :xs="{ span: 24, order: 1 }" :lg="{ span: 8, order: 2 }">
      <div class="section-login">
        <div class="company-logo">
          <img
            src="@/assets/keyport-header-logo.svg"
            alt=""
            class="img-fluid"
          />
        </div>
        <slot></slot>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { notification } from "ant-design-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../services";

export default {
  data() {
    return {
      loading: true,
      announcements: [],
      interval: null,
    };
  },

  async mounted() {
    await this.fetchAnnouncement();

    this.loading = false;

    if (this.sessionLogout) {
      notification.error({
        message: "Info",
        description: "Your session has been logged out due to inactivity.",
        duration: 0,
        onClose: this.set_session_logout(false),
      });
    }

    // this.interval = setInterval(() => {
    //   if (localStorage.getItem("token")) {
    //     location.reload();
    //   }
    // }, 1000);
  },

  unmounted() {
    // clearInterval(this.interval);
  },

  computed: {
    ...mapGetters("auth", ["sessionLogout"]),
  },

  methods: {
    ...mapActions("auth", ["set_session_logout"]),

    fetchAnnouncement() {
      return commonService
        .get(this.$constants.getAnnouncements)
        .then((res) => (this.announcements = res.data))
        .catch((err) => this.$message.error(err));
    },
  },
};
</script>

<style lang="less">
#auth-carousel {
  .slick-slide {
    height: 100vh;
    background: #364d79;
    overflow: hidden;
    .post-item {
      position: relative;
      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
      .content {
        position: absolute;
        bottom: 0;
        padding: 50px 24px 50px 24px;
        background: rgb(0, 21, 41);
        background: linear-gradient(
          180deg,
          rgba(0, 21, 41, 0) 0%,
          rgba(0, 21, 41, 1) 100%
        );
        width: 100%;
        h3 {
          font-size: 18px;
          color: #fff;
          a {
            color: #fff;
            &:hover {
              color: #1890ff;
            }
          }
        }
        p {
          font-size: 16px;
          font-weight: 300;
          color: #ddd;
        }
      }
    }
  }
}
body {
  background-color: aliceblue;
}
.section-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
  background-color: #ffffff;
  .company-logo {
    text-align: center;
    margin-bottom: 3em;
  }
  .ant-form {
    .ant-btn-primary {
      margin-bottom: 1em;
    }
    .btn-link {
      display: block;
      text-align: center;
    }
  }
  @media only screen and (min-width: 992px) {
    height: 100vh;
  }
}
</style>
