import moment from "moment";
export const date = {
  ll(date) {
    if (date != null) {
      return moment(String(date)).format("ll");
    } else {
      return "N/A";
    }
  },
  dmy(date) {
    if (date != null) {
      if (moment.isMoment(date)) {
        return date.format("DD-MM-YYYY");
      }
      return moment(String(date)).format("DD-MM-YYYY");
    } else {
      return "N/A";
    }
  },
  mdy(date) {
    if (date != null) {
      if (moment.isMoment(date)) {
        return date.format("MM-DD-YYYY");
      }
      return moment(String(date)).format("MM-DD-YYYY");
    } else {
      return "N/A";
    }
  },
  ymd(date) {
    if (date != null) {
      if (moment.isMoment(date)) {
        return date.format("YYYY-MM-DD");
      }
      return moment(String(date)).format("YYYY-MM-DD");
    } else {
      return "N/A";
    }
  },
  dmyhis(date) {
    if (date != null) {
      return moment(String(date)).format("DD-MM-YYYY HH:mm:ss");
    } else {
      return "N/A";
    }
  },
  mdyhis(date) {
    if (date != null) {
      if (moment.isMoment(date)) {
        return date.format("MM-DD-YYYY HH:mm:ss");
      }
      return moment(String(date)).format("MM-DD-YYYY HH:mm:ss");
    } else {
      return "N/A";
    }
  },
  his(date) {
    if (date != null) {
      return moment(String(date)).format("HH:mm:00");
    } else {
      return "N/A";
    }
  },
  hia(date) {
    if (date != null) {
      if (moment.isMoment(date)) {
        return date.format("hh:mm a");
      }
      return moment(String(date), "hh:mm:ss").format("hh:mm a");
    } else {
      return "N/A";
    }
  },
  hi(date) {
    if (date != null) {
      return moment(String(date), "hh:mm:ss").format("HH:mm");
    } else {
      return "N/A";
    }
  },
  monthDY(date) {
    if (date != null) {
      return moment(String(date)).format("MMMM DD, YYYY");
    } else {
      return "N/A";
    }
  },
  monthDYHIS(date) {
    if (date != null) {
      return moment(String(date)).format("MMM DD, YYYY HH:mm:ss");
    } else {
      return "N/A";
    }
  },
  momentUTC(date) {
    if (date != null) {
      return moment.utc(date * 1000).format("HH:mm:ss");
    } else {
      return "N/A";
    }
  },

};
