<template>
  <a-layout-content>
    <page-header>
      <template #title> Competency Assessment </template>

      <template #buttons>
        <a-button type="primary" @click="handleAddCompetencyAssessment">
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add Competency Assessment
          </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        :rowClassName="(record) => (record.is_flagged ? 'flagged-row' : '')"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-popconfirm
              v-if="[0, 1].includes(record.status)"
              title="Are you sure you want to send this assessment?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleSendCompetencyAssessment(record)"
            >
              <a-tooltip title="Send Mail">
                <a-button type="link" size="small">
                  <i class="ti ti-mail ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <!-- <a-tooltip title="View">
              <a-button
                type="link"
                size="small"
                @click="handleShowCompetencyAssessment"
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip> -->

            <template v-if="record.status === 0">
              <a-tooltip title="Edit">
                <a-button
                  type="link"
                  size="small"
                  @click="handleEditCompetencyAssessment(record)"
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-popconfirm
                title="Are you sure you want to delete this assessment?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleAssessmentDeleteEvent(record)"
              >
                <a-tooltip title="Delete" placement="bottom">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <a-tooltip v-if="record.status != 0" title="Mail Logs">
              <a-button
                type="link"
                size="small"
                @click="showOfferMailLog(record)"
              >
                <i class="ti ti-history ti-lg"></i>
              </a-button>
            </a-tooltip>

            <template v-if="record.status == 2">
              <a-tooltip title="Download">
                <a-button type="link" size="small" @click="downloadPdf(record)">
                  <i class="ti ti-download ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-tooltip title="Print">
                <a-button type="link" size="small" @click="renderPdf(record)">
                  <i class="ti ti-printer ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.employeeCompetencyAssessmentUrl,
      deleteRecordUrl: this.$constants.employeeCompetencyAssessmentDelete,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee.fl_name",
          key: "full_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital.name",
          key: "hospital_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Date Range",
          dataIndex: "assessor_email",
          key: "assessor_email",
          customRender: ({ record }) =>
            `${this.$customDate.dmy(record.start_date)}
            to
            ${this.$customDate.dmy(record.end_date)}`,
        },
        {
          title: "Assessor Name",
          dataIndex: "assessor_name",
          key: "assessor_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.assessor_name
            ? [this.$route.query?.assessor_name]
            : undefined,
        },
        {
          title: "Assessor Email",
          dataIndex: "assessor_email",
          key: "assessor_email",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.assessor_email
            ? [this.$route.query?.assessor_email]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record?.creator?.fl_name ?? "",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.competenceAssessmentStatuses
        .slice(0, 3)
        .map((item) => ({
          key: item.value.toString(),
          counts: 0,
          text: item.text,
          status: item.value,
          slots: { tab: "customRender" },
        })),
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleSendCompetencyAssessment(record) {
      commonService
        .store(this.$constants.employeeCompetencyAssessmentSendMail, {
          id: record.id,
        })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    handleAssessmentDeleteEvent(record) {
      commonService
        .store(this.deleteRecordUrl, { id: record.id })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    handleAddCompetencyAssessment() {
      this.open({
        title: "Add Competency Assessment",
        path: "admin.competenceAssessments.store",
        callback: this.refresh,
      });
    },

    handleEditCompetencyAssessment(record) {
      this.open({
        title: "Edit Competency Assessment",
        path: "admin.competenceAssessments.store",
        callback: this.refresh,
        record: record,
      });
    },

    handleShowCompetencyAssessment(record) {
      this.open({
        title: "Competency Assessment Details",
        path: "admin.competenceAssessments.show",
        callback: this.refresh,
        record: record,
      });
    },

    downloadPdf(record) {
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.employeeCompetencyAssessmentPdfPreview,
          { id: record.id },
          `${record.employee.fl_name} assessment document.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    renderPdf(record) {
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.employeeCompetencyAssessmentPdfPreview,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    showOfferMailLog(record) {
      this.open({
        title: "Mail Logs",
        path: "admin.competenceAssessments.mailLog",
        callback: this.refresh,
        record,
      });
    },
  },
};
</script>
