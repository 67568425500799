<template>
  <div class="parties">
    <a-avatar-group
      v-if="selectedEmployees.length && !isCommonEmployeeSelection"
    >
      <a-tooltip
        v-for="employee in selectedEmployees"
        :key="employee?.id"
        :title="employee?.full_name"
      >
        <a-avatar style="cursor: pointer" :src="employee?.profile_pic_url">
          {{ employee?.last_name.charAt(0)
          }}{{ employee?.first_name.charAt(0) }}
        </a-avatar>
      </a-tooltip>
    </a-avatar-group>

    <a-popover
      v-model:visible="isVisible"
      trigger="click"
      placement="bottom"
      :overlayStyle="{ width: '350px' }"
      @visibleChange="visibleChange"
    >
      <a-tooltip
        :title="
          isCommonEmployeeSelection ? 'Add Employee to All' : 'Add Employee'
        "
      >
        <a-avatar
          style="
            color: #1890ff;
            background-color: #fff;
            border: 1px dashed #1890ff;
            cursor: pointer;
          "
        >
          <template #icon>
            <UserOutlined />
          </template>
        </a-avatar>
      </a-tooltip>

      <template #content>
        <a-row :gutter="[10, 10]">
          <a-col :span="24">
            <a-input v-model:value="query" placeholder="Search employees" />
          </a-col>
          <a-col :span="24">
            <a-list
              :dataSource="monthlyBillingEmployees"
              class="employees-list"
              size="small"
            >
              <template #renderItem="{ item }">
                <a-list-item
                  class="employee-item"
                  :class="{ selected: employee_ids.includes(item.id) }"
                  @click="() => toggleEmployeeSelection(item.id)"
                >
                  <div class="employee">
                    <a-avatar size="small" :src="item.profile_pic_url">
                      {{ item.last_name.charAt(0)
                      }}{{ item.first_name.charAt(0) }}
                    </a-avatar>
                    <a-typography-text>
                      {{ item.full_name }}
                    </a-typography-text>
                  </div>

                  <template #actions>
                    <i
                      v-if="employee_ids.includes(item.id)"
                      class="ti ti-check ti-lg"
                      style="color: rgba(0, 0, 255, 0.6)"
                    />
                  </template>
                </a-list-item>
              </template>
            </a-list>
          </a-col>
          <a-col :span="24" v-if="selectedEmployees.length > 0">
            <a-avatar-group>
              <a-tooltip
                v-for="emp in selectedEmployees"
                :key="emp.id"
                :title="emp.full_name"
              >
                <a-avatar
                  style="cursor: pointer"
                  @click="() => removeEmployee(emp)"
                  :src="emp.profile_pic_url"
                >
                  {{ emp.last_name.charAt(0) }}{{ emp.first_name.charAt(0) }}
                </a-avatar>
              </a-tooltip>
            </a-avatar-group>
          </a-col>
        </a-row>
      </template>
    </a-popover>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { PlusOutlined, UserOutlined } from "@ant-design/icons-vue";
import { companyService } from "@/services";
import { constants } from "@/helper/constants";

const props = defineProps({
  employee_ids: Array,
  monthlyBillingEmployees: Array,
  isCommonEmployeeSelection: Boolean,
});

const emit = defineEmits(["update:employee_ids"]);

const isVisible = ref(false);
const query = ref(null);

const employeesWritable = computed({
  get: () => props.employee_ids,
  set: (value) => emit("update:employee_ids", value),
});

const selectedEmployees = computed(() => {
  return props.employee_ids.map((id) =>
    props.monthlyBillingEmployees.find((emp) => emp.id === id)
  );
});

function toggleEmployeeSelection(employeeId) {
  const isCommonEmployeeSelection = props.isCommonEmployeeSelection;
  const currentEmployees = employeesWritable.value; // [emp_id, emp_id]

  const isSelected = currentEmployees.includes(employeeId);

  const newState = isCommonEmployeeSelection
    ? isSelected
      ? { removeIds: [employeeId], addIds: [] }
      : { removeIds: [], addIds: [employeeId] }
    : isSelected
    ? currentEmployees.filter((id) => id !== employeeId)
    : [...currentEmployees, employeeId];

  employeesWritable.value = newState; // { removeIds: [], addIds: [emp_id] } | [emp_id, emp_id]
}

function removeEmployee(employee) {
  if (props.isCommonEmployeeSelection) {
    employeesWritable.value = {
      removeIds: [employee.id],
      addIds: [],
    };
  } else {
    employeesWritable.value = employeesWritable.value.filter(
      (id) => id !== employee.id
    );
  }
}

function visibleChange(visible) {
  if (!visible) {
    query.value = null;
  } else {
    employeesWritable.value = props.isCommonEmployeeSelection
      ? {
          removeIds: [],
          addIds: props.employee_ids,
        }
      : props.employee_ids;
  }
}
</script>

<style lang="less">
.parties {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
}

.employees-list {
  height: 250px;
  overflow-y: auto;

  .employee {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.ant-list-item {
  padding: 8px 6px !important;
}

.employee-item {
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
}

.selected {
  background-color: rgba(0, 0, 255, 0.1);

  .employee {
    font-weight: bold;
  }

  &:hover {
    background-color: rgba(0, 0, 255, 0.2);
  }
}
</style>
