<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>

      <template #date="{ record, text }">
        {{ $customDate.mdy(text) }}
      </template>

      <template #action="{ record }">
        <router-link
          :to="{ name: 'show-case-report', params: { uuid: record.uuid } }"
        >
        <a-tooltip title="View">
          <a-button type="link" size="small">
            <i class="ti ti-eye ti-lg"></i>
          </a-button>
        </a-tooltip>
        </router-link>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import datatableMixins from "../../../../mixins/datatableMixins";

export default {
  components: {
    PlusOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.managerCaseReportDatatableUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            customRender: "date",
          },
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.caseReportStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        hospital_id: parseInt(this.$route.params.id),
      },
    };
  },

  methods: {
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },
  },
};
</script>
