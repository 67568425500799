<template>
  <div>
    <a-form
      name="performance_evaluation_comment_form"
      ref="performance_evaluation_comment_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="comment"
            label="Comment"
            name="comment"
            :validateStatus="validate('comment', 'status')"
            :help="validate('comment', 'msg')"
          >
            <a-textarea
              v-model:value="formState.comment"
              placeholder="Enter comment here"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider
        style="margin-bottom: 0"
        v-if="comments.length"
        orientation="left"
      >
        Past Comments
      </a-divider>

      <a-list
        v-if="comments.length"
        :data-source="comments"
        header=""
        item-layout="horizontal"
        size="small"
      >
        <template #renderItem="{ item }">
          <a-list-item v-if="!item.hide">
            <a-list-item-meta
              :title="item.comment"
              :description="formatTime(item.created_at)"
            >
              <template #avatar>
                <a-avatar :src="item.employee.profile_pic_url">
                  {{ item.employee.last_name.charAt(0)
                  }}{{ item.employee.first_name.charAt(0) }}
                </a-avatar>
              </template>
            </a-list-item-meta>

            <template #actions>
              <a-button
                danger
                :loading="item.loading"
                type="link"
                size="small"
                @click="handleDelete(item)"
              >
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </template>
          </a-list-item>
        </template>
      </a-list>
      <side-drawer-buttons name="Add" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../../mixins/formMixins";
import moment from "moment";

export default {
  components: {
    PlusOutlined,
    DeleteOutlined,
  },

  data() {
    return {
      formState: {
        comment: null,
      },
      rules: {
        comment: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    comments() {
      return this.record.comments.sort((a, b) => {
        return moment(b.created_at).diff(moment(a.created_at));
      });
    },
  },

  created() {
    //
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id,
        comment: this.formState.comment,
      };
    },

    handleDelete(item) {
      item.loading = true;
      companyService
        .store(this.$constants.monthlyBillingDeleteComment, {
          id: item.id,
        })
        .then((res) => {
          if (res.success) {
            item.hide = true;
            this.$message.success(res.message);
            this.$emit("callback", this.comments);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          item.loading = false;
        });
    },

    formatTime(time) {
      return moment(time).format("MMMM DD, YYYY - hh:mm A");
    },

    onSubmit() {
      this.loadingStart();
      const val = {
        ...this.formState,
      };
      if (this.extra.isMain) {
        val.main = true;
      }
      companyService
        .store(this.$constants.monthlyBillingAddComment, val)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback", res.data.comments);
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
