<template>
  <strong>Employee:</strong> {{ record.employee.full_name }}
  <br />
  <strong>Category:</strong> {{ record.category_name }}
  <br />
  <strong>Severity:</strong> {{ record.severity_name }}
  <br />
  <strong>Description:</strong> {{ record.message }}
  <br />
  <template v-if="record.attachments.length > 0">
    <template v-for="(item, idx) in record.attachments" :key="item.id">
      <a-typography-link :href="item.full_file_path" target="_blank">
        {{ idx + 1 }}. Attachment
      </a-typography-link>
      <br v-if="idx !== record.attachments.length - 1" />
    </template>
    <br />
  </template>
  <br />
  <strong>Created At:</strong> {{ $customDate.mdyhis(record.created_at) }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  async mounted() {
    //
  },

  created() {
    //
  },

  methods: {
    //
  },
};
</script>
