<template>
  <a-descriptions
    :column="2"
    size="small"
    bordered
    :labelStyle="{ width: '18%' }"
    :contentStyle="{ width: '32%' }"
  >
    <a-descriptions-item :span="2" label="Hospital Name">
      {{ record.hospital?.name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item :span="2" label="Employee Name">
      {{ record.employee?.full_name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Expense Type">
      {{ record.expense_type?.name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Date">
      {{ $customDate.ll(record.date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Exp. Start Date">
      {{ $customDate.ll(record.start_date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Exp. End Date">
      {{ $customDate.ll(record.end_date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Amount">
      {{
        record.amount || record.amount == 0
          ? $comman.withCurrency(record.amount)
          : "N/A"
      }}
    </a-descriptions-item>
    <a-descriptions-item label="Description">
      {{ record.description ?? "N/A" }}
    </a-descriptions-item>
    <template v-if="record.expense_type_id == 1 && extraInfo">
      <a-descriptions-item label="Name of Organization / Sponsor">
        {{ extraInfo.organization_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Subject / Purpose">
        {{ extraInfo.subject }}
      </a-descriptions-item>
      <a-descriptions-item label="City">
        {{ extraInfo.city }}
      </a-descriptions-item>
      <a-descriptions-item label="State">
        {{ extraInfo.state }}
      </a-descriptions-item>
    </template>
    <template v-if="record.expense_type_id == 9 && extraInfo">
      <a-descriptions-item label="Origin Address">
        {{ extraInfo.origin_addresses[0] }}
      </a-descriptions-item>
      <a-descriptions-item label="Destination Address">
        {{ extraInfo.destination_addresses[0] }}
      </a-descriptions-item>
      <a-descriptions-item label="Distance In Miles">
        {{ distanceInMiles }}
      </a-descriptions-item>
      <a-descriptions-item label="Time In Minutes">
        {{ extraInfo.rows[0].elements[0].duration.text }}
      </a-descriptions-item>
      <a-descriptions-item label="Trip Type">
        {{ tripTypeText }}
      </a-descriptions-item>
      <a-descriptions-item label="Mileage">
        <strong>{{ distanceInMiles * extraInfo.trip_type }}</strong>
      </a-descriptions-item>
    </template>
  </a-descriptions>

  <a-form
    :model="model"
    :rules="rules"
    layout="vertical"
    @finish="handleFinish"
    class="mt-sm-2"
  >
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-form-item
          ref="quickbooks_class_id"
          label="QuickBooks Class"
          name="quickbooks_class_id"
        >
          <a-select
            v-model:value="model.quickbooks_class_id"
            placeholder="Select quickbooks class"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in computedClasses"
              :key="option.Id"
              :label="option.Name"
              :value="option.Id"
            >
              {{ option.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Update" />
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],

  emits: ["callback"],

  data() {
    return {
      model: {},
      rules: {
        quickbooks_class_id: {
          required: true,
          message: "This is required!",
        },
      },
      classes: [],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    extraInfo() {
      if (this.record.extra_info) {
        let res = JSON.parse(this.record.extra_info);
        if (this.record.expense_type_id == 9 && !("trip_type" in res)) {
          res.trip_type = 2;
        }
        return res;
      }
      return null;
    },

    distanceInMiles() {
      return this.$comman.getDistanceInMiles(
        this.extraInfo.rows[0].elements[0].distance.value
      );
    },

    tripTypeText() {
      return this.$comman.getValueFromObject(
        this.extraInfo,
        "trip_type",
        this.$constants.expenseTripTypes
      );
    },

    quickbooksClassId() {
      return this.record?.hospital?.quickbooks_class_id ?? null;
    },

    quickbooksClassInfo() {
      return this.record?.hospital?.quickbooks_class_info
        ? JSON.parse(this.record.hospital.quickbooks_class_info)
        : null;
    },

    computedClasses() {
      if (this.quickbooksClassId) {
        return this.classes.filter(
          (e) =>
            e.Id == this.quickbooksClassId ||
            (e.ParentRef?.value && e.ParentRef.value == this.quickbooksClassId)
        );
      }
      return [];
    },
  },

  mounted() {
    this.getmodel();
    this.getQuickbooksData();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getmodel() {
      this.model = {
        id: this.record.id,
        quickbooks_class_id:
          this.record?.quickbooks_class_id ?? this.quickbooksClassId,
        quickbooks_class_info:
          this.record?.quickbooks_class_info ?? this.quickbooksClassInfo,
      };
    },

    handleFinish() {
      this.model.quickbooks_class_info = this.computedClasses.find(
        (e) => e.Id == this.model.quickbooks_class_id
      );

      this.loadingStart();

      commonService
        .store(this.$constants.adminExpenseChangeQuickbooksClassUrl, this.model)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, {
          names: ["classes"],
        })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "classes") {
                this.classes = JSON.parse(v.data);
              }
            });
          }
        });
    },
  },
};
</script>
