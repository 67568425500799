<template>
  <div>
    <a-form
      name="product_category_form"
      ref="product_category_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { api } from "@/config/config";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  created() {
    //
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
      };
    },

    onSubmit() {
      this.$refs.product_category_form
        .validate()
        .then((val) => {
          if (this.record.id) {
            val.id = this.record.id;
          }
          this.loadingStart(),
            companyService
              .store(this.$constants.productCategoryStoreUrl, val)
              .then((res) => {
                this.loadingStop();
                if (res.success) {
                  this.$message.success(res.message);
                  this.close();
                  this.$emit("callback");
                }
              })
              .catch((err) => {
                this.loadingStop();
                if ("errors" in err) {
                  this.errors = err.errors;
                } else {
                  this.$message.error(err);
                }
              });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style></style>
