<template>
  <a-layout-content>
    <page-header>
      <template #title> Incidents </template>

      <template #buttons>
        <router-link :to="{ name: 'incidentCreate' }">
          <a-button type="primary">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add Incident </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{ name: 'incidentShow', params: { id: record.id } }"
            >
            <a-tooltip title="View">
              <a-button type="link" size="small">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
            </router-link>

            <template v-if="record.status == 1">
              <a-tooltip title="Download">
              <a-button type="link" size="small" @click="downloadPdf(record)">
                <a-space>
                  <i class="ti ti-download ti-lg"></i>
                </a-space>
              </a-button>
            </a-tooltip>

              <router-link
                :to="{ name: 'incidentEdit', params: { id: record.id } }"
              >
              <a-tooltip title="Edit">
                <a-button type="link" size="small">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
              </router-link>

              <a-popconfirm
                title="Are you sure you want to delete this incident?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '0' })
                "
              >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-else>
              <a-popconfirm
                title="Are you sure you want to restore this incident?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '1' })
                "
              >
              <a-tooltip title="Restore">
                <a-button size="small" type="link">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.incidentDatatableUrl,
      statusChangeUrl: this.$constants.incidentChangeStatusUrl,
      columns: [
        {
          title: "Report Number",
          dataIndex: "report_number",
          key: "report_number",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.report_number
            ? [this.$route.query?.report_number]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Incident Date",
          dataIndex: "incident_date",
          key: "incident_date",
          sorter: true,
          customRender: ({ record }) =>
            this.$customDate.ll(record.incident_date),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        report_number: this.$route.query?.report_number
          ? [this.$route.query?.report_number]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
      },
    };
  },

  methods: {
    downloadPdf(record) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.incidentPdfDownloadUrl,
          { id: record.id },
          `incident_${record.report_number}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },
  },
};
</script>
