<template>
  <a-card
    :loading="loading"
    title="Case Services"
    size="small"
    class="case-services"
  >
    <template #extra>
      <a-space v-if="!loading" size="small">
        <a-button type="primary" @click="addNewCaseService">
          <a-space size="small">
            <i class="ti ti-plus ti-lg"></i>
            Add New
          </a-space>
        </a-button>
        <a-dropdown type="primary">
          <a-button><i class="ti ti-dots-vertical ti-lg"></i></a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a-button
                  type="link"
                  @click="downloadPdf"
                  :loading="pdfLoading"
                >
                  <a-space>
                    <i class="ti ti-download ti-lg"></i>
                    Export PDF
                  </a-space>
                </a-button>
              </a-menu-item>

              <a-menu-item>
                <a-button
                  type="link"
                  :loading="excelExporting"
                  @click="exportExcel"
                >
                  <a-space>
                    <i class="ti ti-file-spreadsheet ti-lg"></i>
                    Export Excel
                  </a-space>
                </a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-space>
    </template>

    <a-table
      size="small"
      :columns="columns"
      :data-source="hospitalCaseServices"
      :rowKey="(record) => record.id"
      :pagination="false"
    >
      <template #case_service_name="{ record }">
        {{ record?.case_service?.name ?? "N/A" }}
        <a-tooltip v-if="record.has_conditional_billing">
          <template #title>
            Conditional Billing
            <br />
            Price: {{ $comman.withCurrency(record.conditional_price) }}
            <br />
            Initial Cases per Month: {{ record.conditional_initial_case_count }}
          </template>
          <a-button type="link" size="small">
            <i class="ti ti-affiliate"></i>
          </a-button>
        </a-tooltip>
      </template>

      <template #actions="{ record }">
        <a-tooltip title="Edit">
          <a-button
            type="link"
            size="small"
            class="edit-color pl-sm-0"
            @click="editCaseService(record)"
          >
            <i class="ti ti-pencil ti-lg"></i>
          </a-button>
        </a-tooltip>

        <a-popconfirm
          title="Are you sure delete this hospital case service?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteHospitalCaseReport(record.id)"
        >
          <a-tooltip title="Delete">
            <a-button type="link" size="small" class="danger-color">
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-popconfirm>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "hospital-service-pricing",

  props: {
    hospital: {
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      caseServices: [],
      hospitalCaseServices: [],
      quickbooksServices: [],

      pdfLoading: false,
      excelExporting: false,

      columns: [
        {
          title: "Case Service Name",
          dataIndex: "case_service_name",
          slots: {
            customRender: "case_service_name",
          },
        },
        {
          title: "Billing Code",
          dataIndex: "billing_code",
        },
        {
          title: "QuickBooks Name",
          dataIndex: "quickbooks_name",
          key: "quickbooks_name",
          customRender: ({ record }) => {
            return record.quickbooks_item_info?.Name ?? "N/A";
          },
        },
        {
          title: "Type",
          dataIndex: "type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "type",
              this.$constants.hospitalCaseServiceTypes
            ),
        },
        {
          title: "Hospital Price",
          dataIndex: "price",
          customRender: ({ text }) => this.$comman.withCurrency(text),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
    };
  },

  async mounted() {
    await this.getServices();
    await this.getHospitalCaseServices();
    await this.getQuickbooksData();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getHospitalCaseServices() {
      this.loading = true;
      return commonService
        .get(this.$constants.hospitalCaseServicesUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.hospitalCaseServices = res.data;
          this.loading = false;
        });
    },

    getServices() {
      return commonService
        .get(this.$constants.getCaseServicesUrl)
        .then((res) => {
          this.caseServices = res.data;
        });
    },

    getQuickbooksData() {
      return commonService
        .store(this.$constants.getQuickbooksData, { names: "services" })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "services") {
                this.quickbooksServices = JSON.parse(v.data);
              }
            });
          }
        });
    },

    downloadPdf() {
      this.pdfLoading = false;

      commonService
        .downloadFile(
          this.$constants.hospitalCaseServicePdfExport,
          { hospital_id: this.hospital.id },
          `${this.hospital.name} service pricing.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.pdfLoading = false;
        });
    },

    exportExcel() {
      this.excelExporting = true;

      commonService
        .downloadFile(
          this.$constants.hospitalCaseServiceExcelExport,
          { hospital_id: this.hospital.id },
          `${this.hospital.name} service pricing.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.excelExporting = false;
        });
    },

    addNewCaseService() {
      this.open({
        title: "Add new hospital case service",
        path: "hospital.drawerForm.storeService",
        callback: this.getHospitalCaseServices,
        record: { hospital_id: this.hospital.id },
        extra: {
          caseServices: this.remainingCaseServices(),
          quickbooksServices: this.quickbooksServices,
        },
      });
    },

    editCaseService(record) {
      this.open({
        title: "Edit hospital case service",
        path: "hospital.drawerForm.storeService",
        callback: this.getHospitalCaseServices,
        record,
        extra: {
          caseServices: this.remainingCaseServices(record.case_service_id),
          quickbooksServices: this.quickbooksServices,
        },
      });
    },

    deleteHospitalCaseReport(id) {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalCaseServiceDeleteUrl, { id })
        .then((res) => {
          if (res.success) {
            this.getHospitalCaseServices();
          }
        });
    },

    remainingCaseServices(selectedId = null) {
      let hospitalCaseServiceIds = this.hospitalCaseServices
        .map((item) => item.case_service_id)
        .filter((item) => item != selectedId);

      return this.caseServices.filter(
        (item) => !hospitalCaseServiceIds.includes(item.id)
      );
    },
  },
};
</script>
