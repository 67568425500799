<template>
  <a-form
    name="post_procedure_form"
    ref="post_procedure_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row type="flex">
      <a-col flex="35px">
        <a-tooltip title="Select all N/A option">
          <a-button
            type="link"
            size="small"
            class="p-sm-0"
            @click="selectAllOption('na_post_checklist')"
          >
            N/A
          </a-button>
        </a-tooltip>
      </a-col>
      <a-col flex="35px">
        <a-tooltip title="Select all option">
          <a-button
            type="link"
            size="small"
            class="p-sm-0"
            @click="selectAllOption('post_checklist')"
          >
            <CheckOutlined />
          </a-button>
        </a-tooltip>
      </a-col>
      <a-col flex="auto">
        <strong>SETUP CHECKLIST</strong>
      </a-col>
    </a-row>

    <template v-for="option in postChecklist" :key="option">
      <a-row type="flex">
        <a-col flex="35px">
          <a-checkbox
            v-model:checked="model.na_post_checklist[option]"
            @click="(e) => optionChecked(e, option, 'na_post_checklist')"
          />
        </a-col>
        <a-col flex="35px">
          <a-checkbox
            v-model:checked="model.post_checklist[option]"
            @click="(e) => optionChecked(e, option, 'post_checklist')"
          />
        </a-col>
        <a-col flex="calc(100% - 70px)">
          {{ option }}
        </a-col>
      </a-row>
    </template>

    <modal-buttons name="Save" />
  </a-form>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  components: { CheckOutlined },

  data() {
    return {
      model: {
        uuid: null,
        post_checklist: [],
        na_post_checklist: [],
        submitted_at: null,
      },
    };
  },

  computed: {
    ...mapGetters("modal", ["record"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    postChecklist() {
      return this.$constants.hipecPostProcedureChecklist;
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      let oldCheckedOption =
        this.record?.live_chart?.hipec?.post_checklist?.checked ?? [];
      let oldNotApplicableOption =
        this.record?.live_chart?.hipec?.post_checklist?.not_applicable ?? [];
      let checklist = {};
      let not_applicable = {};
      this.postChecklist.forEach((e) => {
        checklist[e] = oldCheckedOption.includes(e) ? true : false;
        not_applicable[e] = oldNotApplicableOption.includes(e) ? true : false;
      });

      this.model.post_checklist = checklist;
      this.model.na_post_checklist = not_applicable;
      this.model.submitted_at =
        this.record?.live_chart?.hipec?.post_checklist?.submitted_at ?? null;
    },

    onSubmit() {
      let newPostChecklist = [];
      for (const [key, value] of Object.entries(this.model.post_checklist)) {
        if (value) {
          newPostChecklist.push(key);
        }
      }
      let newnaPostChecklist = [];
      for (const [key, value] of Object.entries(this.model.na_post_checklist)) {
        if (value) {
          newnaPostChecklist.push(key);
        }
      }

      let data = {
        uuid: this.record.uuid,
        post_checklist: newPostChecklist,
        na_post_checklist: newnaPostChecklist,
        submitted_at:
          this.model.submitted_at ??
          moment().tz(this.timezone).format("MM-DD-YYYY HH:mm:ss"),
      };

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreHIPECPostChecklistUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },

    optionChecked(e, option, key) {
      if (e.target.checked) {
        if (key == "na_post_checklist") {
          this.model.post_checklist[option] = false;
        } else {
          this.model.na_post_checklist[option] = false;
        }
      }
    },

    selectAllOption(key) {
      let allChecked = this.postChecklist.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      this.postChecklist.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key = key == "na_post_checklist" ? "post_checklist" : "na_post_checklist";
      this.postChecklist.forEach((option) => (this.model[key][option] = false));
    },
  },
};
</script>
