<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Scheduled OR Date"
        name="nrp_2_scheduled_or_date"
        :rules="{
          required: true,
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.nrp_2_scheduled_or_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Scheduled OR Time"
        name="nrp_2_scheduled_or_time"
        :rules="{
          required: true,
          message: 'Invalid time.',
          validator: $validation.fullTimeRequired,
        }"
      >
        <a-input
          v-model:value="model.nrp_2_scheduled_or_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team Arrival Date"
        name="nrp_2_kps_team_arrival_date"
        :rules="{
          required: true,
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.nrp_2_kps_team_arrival_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team Arrival Time"
        name="nrp_2_kps_team_arrival_time"
        :rules="{
          required: true,
          message: 'Invalid time.',
          validator: $validation.fullTimeRequired,
        }"
      >
        <a-input
          v-model:value="model.nrp_2_kps_team_arrival_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Setup Complete Date"
        name="nrp_2_kps_setup_complete_date"
        :rules="{
          required: true,
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.nrp_2_kps_setup_complete_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Setup Complete Time"
        name="nrp_2_kps_setup_complete_time"
        :rules="{
          required: true,
          message: 'Invalid time.',
          validator: $validation.fullTimeRequired,
        }"
      >
        <a-input
          v-model:value="model.nrp_2_kps_setup_complete_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="OR OPO Personnel in Room Date"
        name="nrp_2_or_opo_personnel_in_room_date"
        :rules="{
          required: true,
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.nrp_2_or_opo_personnel_in_room_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="OR OPO Personnel in Room Time"
        name="nrp_2_or_opo_Personnel_in_room_time"
        :rules="{
          required: true,
          message: 'Invalid time.',
          validator: $validation.fullTimeRequired,
        }"
      >
        <a-input
          v-model:value="model.nrp_2_or_opo_Personnel_in_room_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Donor in Room Date"
        name="nrp_2_donor_in_room_date"
        :rules="{
          required: true,
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.nrp_2_donor_in_room_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Donor in Room Time"
        name="nrp_2_donor_in_room_time"
        :rules="{
          required: true,
          message: 'Invalid time.',
          validator: $validation.fullTimeRequired,
        }"
      >
        <a-input
          v-model:value="model.nrp_2_donor_in_room_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team Departure Date"
        name="nrp_2_kps_team_depature_date"
        :rules="{
          required: true,
          message: 'Invalid date.',
          type: 'date',
        }"
      >
        <a-date-picker
          v-model:value="model.nrp_2_kps_team_depature_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team Departure Time"
        name="nrp_2_kps_team_depature_time"
        :rules="{
          required: true,
          message: 'Invalid time.',
          validator: $validation.fullTimeRequired,
        }"
      >
        <a-input
          v-model:value="model.nrp_2_kps_team_depature_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "case-report-step-6-for-nrp-2",
  inheritAttrs: false,
  props: ["step", "model", "record"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  mounted() {
    //
  },

  computed: {
    //
  },

  methods: {
    //
  },
};
</script>
