<template>
  <a-table
    :loading="loading"
    :columns="columns"
    :rowKey="(rec) => rec.id"
    :dataSource="data"
    :pagination="false"
    size="small"
    :rowClassName="
      ({ DueDate }) =>
        moment(DueDate).isBefore(moment(), 'day') ? 'flagged-row' : ''
    "
  />
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      moment,
      data: [],
      loading: true,
      columns: [
        {
          title: "Invoice No",
          dataIndex: "DocNumber",
          key: "DocNumber",
        },
        {
          title: "Invoice Due Date",
          dataIndex: "DueDate",
          key: "DueDate",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Amount",
          dataIndex: "Balance",
          key: "Balance",
          customRender: ({ text }) => this.$comman.withCurrency(text),
        },
      ],
    };
  },

  mounted() {
    this.getHospitalAnalytics();
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    hospitaId() {
      return this.record.id;
    },
  },

  methods: {
    getHospitalAnalytics() {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalAnalyticDueInvoiceListUrl, {
          hospital_id: this.hospitaId,
        })
        .then((res) => {
          this.data = res.data.QueryResponse?.Invoice ?? [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
