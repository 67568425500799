<template>
  <div>
    <a-form
      name="asset_inventory_form"
      ref="asset_inventory_form"
      :model="formState"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item
            ref="asset_product_id"
            label="Product"
            name="asset_product_id"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'number',
            }"
          >
            <a-select
              v-model:value="formState.asset_product_id"
              placeholder="Select product"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in assetProducts"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="asset_manufacturer_id"
            label="Manufacturer"
            name="asset_manufacturer_id"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'number',
            }"
          >
            <a-select
              v-model:value="formState.asset_manufacturer_id"
              placeholder="Select product"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in assetManufacturers"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="model_no"
            label="Model No"
            name="model_no"
            :rules="{
              required: true,
              message: 'This field is required',
            }"
          >
            <a-input
              v-model:value="formState.model_no"
              placeholder="Enter model no here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="serial_no"
            label="Serial No"
            name="serial_no"
            :rules="{
              required: true,
              message: 'This field is required',
            }"
          >
            <a-input
              v-model:value="formState.serial_no"
              placeholder="Enter serial no here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="asset_id_tag"
            label="Asset ID Tag"
            name="asset_id_tag"
          >
            <a-input
              v-model:value="formState.asset_id_tag"
              placeholder="Enter asset id tag here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="secondary_id_tag"
            label="Secondary ID Tag"
            name="secondary_id_tag"
          >
            <a-input
              v-model:value="formState.secondary_id_tag"
              placeholder="Enter secondary id tag here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="owned_by"
            label="Owned By"
            name="owned_by"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'number',
            }"
          >
            <a-radio-group name="radioGroup" v-model:value="formState.owned_by">
              <a-radio
                v-for="option in $constants.assetInventoryOwnedBy"
                :value="option.value"
                :key="option.value"
              >
                {{ option.text }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            v-if="formState.owned_by == 1"
            ref="company_id"
            label="Company"
            name="company_id"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'number',
            }"
          >
            <a-select
              v-model:value="formState.company_id"
              placeholder="Select company"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in companies"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="formState.owned_by == 2"
            ref="hospital_id"
            label="Hospital"
            name="hospital_id"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'number',
            }"
          >
            <a-select
              v-model:value="formState.hospital_id"
              placeholder="Select hospital"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in hospitals"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="description" label="Description" name="description">
            <a-textarea
              v-model:value="formState.description"
              placeholder="Enter Description here"
              :rows="4"
            />
          </a-form-item>
        </a-col>

        <template
          v-if="selectedProduct && selectedProduct.is_required_maintenance"
        >
          <a-col :span="12">
            <a-form-item
              ref="frequency_of_preventive_measure"
              label="Frequency of Preventative Maintenance"
              name="frequency_of_preventive_measure"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'number',
              }"
            >
              <a-select
                v-model:value="formState.frequency_of_preventive_measure"
                placeholder="Select any one"
                optionFilterProp="label"
                allowClear
                showSearch
              >
                <a-select-option
                  v-for="option in $constants.frequencyOfPreventiveMeasurements"
                  :key="option.value"
                  :label="option.text"
                  :value="option.value"
                >
                  {{ option.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="last_preventive_measure_date"
              label="Last Preventative Maintenance Date"
              name="last_preventive_measure_date"
              :rules="{
                required: formState.frequency_of_preventive_measure != 5,
                message: 'This field is required',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="formState.last_preventive_measure_date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>
        </template>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {},
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
    companies() {
      return this.extra?.companies ?? [];
    },
    hospitals() {
      return this.extra?.hospitals ?? [];
    },
    assetProducts() {
      return this.extra?.assetProducts ?? [];
    },
    assetManufacturers() {
      return this.extra?.assetManufacturers ?? [];
    },
    selectedProduct() {
      return this.assetProducts.find(
        (item) => item.id == this.formState.asset_product_id
      );
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id ?? 0,
        asset_product_id: this.record.asset_product_id ?? undefined,
        asset_manufacturer_id: this.record.asset_manufacturer_id ?? undefined,
        company_id: this.record.company_id ?? undefined,
        hospital_id: this.record.hospital_id ?? undefined,
        model_no: this.record.model_no ?? null,
        serial_no: this.record.serial_no ?? null,
        asset_id_tag: this.record.asset_id_tag ?? null,
        secondary_id_tag: this.record.secondary_id_tag ?? null,
        description: this.record.description ?? null,
        owned_by: this.record.owned_by ?? null,
        frequency_of_preventive_measure:
          this.record.frequency_of_preventive_measure ?? undefined,
        last_preventive_measure_date: this.record.last_preventive_measure_date
          ? moment(this.record.last_preventive_measure_date)
          : null,
      };
    },

    onSubmit() {
      this.loadingStart();
      let data = { ...this.formState };
      if (
        this.selectedProduct &&
        this.selectedProduct.is_required_maintenance
      ) {
        data.last_preventive_measure_date = data.last_preventive_measure_date
          ? this.$customDate.ymd(data.last_preventive_measure_date)
          : null;
      } else {
        data.frequency_of_preventive_measure = null;
        data.last_preventive_measure_date = null;
      }

      companyService
        .store(this.$constants.assetInventoryUpdateUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
