<template>
  <div class="offer-letter-mail-logs">
    <template v-for="log in record.mail_logs" :key="log.id">
      <a-descriptions
        :column="1"
        :labelStyle="{ width: '250px' }"
        bordered
        size="small"
        class="mb-sm-1"
      >
        <a-descriptions-item label="Email">
          {{ log.to }}
        </a-descriptions-item>
      </a-descriptions>

      <mail-log :record="filterRecord(log)" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mailLog from "../../../components/mailLog.vue";

export default {
  emits: ["callback"],

  components: { mailLog },

  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    //
  },

  methods: {
    filterRecord(rec) {
      let data = JSON.parse(JSON.stringify(this.record));
      data.mail_logs = data.mail_logs.filter((e) => e.id == rec.id);
      return data;
    },
  },
};
</script>
