<template>
  <a-modal
    v-model:visible="showDialog"
    centered
    closable
    :maskClosable="false"
    width="1000px"
    wrapClassName="handbook-modal"
    :bodyStyle="{ height: '500px', overflowY: 'scroll' }"
    :keyboard="false"
  >
    <template #title>
      <a-typography-title :level="3" style="margin-bottom: -4px">
        {{ agreement.onboarding_document.name }}
      </a-typography-title>
    </template>

    <template #footer>
      <template v-if="agreement.status === 0">
        <a-button
          v-if="showSignPad && handbookSign"
          htmlType="button"
          type="primary"
          danger
          ghost
          @click="handbookSignClear"
        >
          Clear Signature
        </a-button>

        <a-button
          type="primary"
          @click="handleHandbookAgreedButtonClick"
          :loading="handbookAgreeBtnLoading"
          :disabled="
            (showSignPad && handbookSign == null) ||
            (!showSignPad && !handbookAgreed.includes(true))
          "
        >
          Agree
        </a-button>
      </template>
      <a-button v-else htmlType="button" @click="closeDialog(false)">
        Close
      </a-button>
    </template>

    <a-space direction="vertical" style="width: 100%">
      <a-typography-text>
        <p class="agreement-description" v-html="docContentParsed" />
      </a-typography-text>

      <template v-if="agreement.onboarding_document.attachment_file">
        <ADivider style="margin: 0" />

        <a-form layout="vertical">
          <a-form-item label="Attachment">
            <a-typography-link
              :href="agreement.onboarding_document.attachment_file_path"
              target="_blank"
            >
              Download {{ agreement.onboarding_document.name }}
            </a-typography-link>
          </a-form-item>
        </a-form>
      </template>

      <a-space
        v-if="showSignPad"
        class="mt-sm-2"
        align="center"
        direction="vertical"
        style="width: 100%"
      >
        <template v-if="agreement.status === 0">
          <vue-signature-pad
            :options="{ onEnd: handbookSignSave }"
            ref="signaturePad"
            :customStyle="$constants.signPadCustomCss"
          />
        </template>
        <img v-else :width="200" :src="agreement.document_sign" />
      </a-space>

      <a-space class="mt-sm-1" direction="vertical" style="width: 100%">
        <a-checkbox-group
          v-if="!showSignPad && agreement.status === 0"
          v-model:value="handbookAgreed"
        >
          <a-checkbox :value="true">
            {{ agreement.onboarding_document.acknowledgement_message }}
          </a-checkbox>
        </a-checkbox-group>

        <a-typography-paragraph
          v-else-if="agreement.status === 0"
          style="text-align: center"
        >
          {{ agreement.onboarding_document.acknowledgement_message }}
        </a-typography-paragraph>
      </a-space>
    </a-space>
  </a-modal>
</template>

<script>
import { message } from "ant-design-vue";
import { defineComponent } from "vue";
import { commonService } from "@/services";
import moment from "moment";

export default defineComponent({
  data() {
    return {
      handbookAgreeBtnLoading: false,
      handbookSign: null,
      handbookAgreed: [this.agreement.status === 1],
    };
  },
  props: ["agreement", "handbookModal", "closeDialog", "employee"],
  emits: ["handbookAgreed", "update:handbookModal"],
  computed: {
    showSignPad() {
      return this.agreement.onboarding_document.type === 1;
    },
    showDialog: {
      get() {
        return this.handbookModal;
      },
      set(val) {
        this.$emit("update:handbookModal", val);
      },
    },
    docContentParsed() {
      const data = this.agreement.document_content;

      const variables = {
        "{{employee_name}}": this.employee.fl_name,
        "{{date}}": moment().format("Do [day of] MMM, YYYY"),
      };

      const regex = new RegExp(Object.keys(variables).join("|"), "gi");
      return data.replaceAll(regex, (matched) => variables[matched]);
    },
  },
  methods: {
    handbookSignSave() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.handbookSign = data;
      return Promise.resolve();
    },
    handbookSignClear() {
      this.handbookSign = null;
      this.$refs.signaturePad.clearSignature();
    },

    handleHandbookAgreedButtonClick() {
      const isAgreed = this.handbookAgreed.includes(true);
      if (!this.showSignPad && !isAgreed) {
        message.error("Please agree to the terms and conditions.");
        return;
      }

      this.handbookAgreeBtnLoading = true;
      commonService
        .store(this.$constants.employeeOnboardingLogsSign, {
          document_sign: this.handbookSign,
          id: this.agreement.id,
        })
        .then(async (res) => {
          this.$message.success(res.message);
          await this.closeDialog(true);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.handbookAgreeBtnLoading = false;
        });
    },
  },
});
</script>

<style scoped>
.agreement-description:deep p {
  margin-bottom: 0;
}
</style>
