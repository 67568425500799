<template>
  <a-layout-content>
    <page-header>
      <template #title>
        Add Services Into Cart
      </template>
      <template #buttons>
        <back-button />
      </template>
    </page-header>

    <a-card>
      <a-table
        :rowKey="(record, index) => record.id"
        :dataSource="data"
        :columns="columns"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
      >
        <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`Search ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />

            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon><search-outlined /></template>
              Search
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
        </template>

        <template #filterIcon="filtered">
          <search-outlined
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </template>

        <template #expandedRowRender="{record}">
          <a-table
            :rowKey="(record, index) => `${record.id} innerTable`"
            :columns="innerColumns"
            :data-source="record.products"
            :pagination="false"
            size="small"
          >
          </a-table>
        </template>

        <template #action="{record}">
          <a-button
            type="primary"
            size="small"
            @click="addProductToCart(record)"
          >
            <i class="fas fa-cart-plus"></i>
          </a-button>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import backButton from "../../components/backButton.vue";
import { tableService } from "../../services";
import { SearchOutlined } from "@ant-design/icons-vue";

export default {
  components: { backButton, SearchOutlined },

  data() {
    return {
      data: [],
      pagination: {
        size: "normal",
        pageSize: 10,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },
      loading: false,
      defaultParams: { sortField: "created_at", sortOrder: "descend" },
      params: {},
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          sorter: true,
          customRender: ({ text }) => (text == null ? "-" : ""),
        },
        {
          title: "Products Count",
          dataIndex: "products_count",
          key: "products_count",
          sorter: true,
        },
        {
          title: "Products Quantity",
          dataIndex: "products_sum_quantity",
          key: "products_sum_quantity",
          sorter: true,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],

      innerColumns: [
        {
          title: "Product Name",
          dataIndex: "name",
          key: "name",
          customRender: ({ record }) => record.product?.name ?? "-",
        },
        {
          title: "Manufacturer",
          dataIndex: "manufacturer_id",
          key: "manufacturer",
          customRender: ({ record }) =>
            record.product?.manufacturer?.name ?? "-",
        },
        {
          title: "Product Category",
          dataIndex: "product_category_id",
          key: "product_category",
          customRender: ({ record }) =>
            record.product?.product_category?.name ?? "-",
        },
        {
          title: "UOM",
          dataIndex: "uom_id",
          key: "uom",
          customRender: ({ record }) => record.product?.uom?.name ?? "-",
        },
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
          customRender: ({ record }) => record.product?.sku ?? "-",
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
        },
      ],
    };
  },

  created() {
    this.refresh();
  },

  methods: {
    ...mapActions("cart", ["addProduct"]),

    // DEFAULT TABLE SETUP START
    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination;
      this.defaultParams = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        status: 1,
        ...filters,
      };
      this.refresh();
    },

    refresh() {
      this.loading = true;

      if (!this.params) {
        this.params = {};
      }

      var params = {
        ...this.params,
        ...this.defaultParams,
        ...this.pagination,
        status: 1,
        search_text: "",
        page: this.pagination.current,
      };

      tableService
        .get(params, this.$constants.serviceDatatableUrl, 10)
        .then((response) => {
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          this.pagination = pagination;
          this.data = response.data.data;
          this.loading = false;

          this.data.map((v) => {
            v.qty = 1;
            return v;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleNameSearch(selectedKeys, confirm) {
      confirm();
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
    },

    handleReset(clearFilters) {
      clearFilters();
    },
    // DEFAULT TABLE SETUP END

    addProductToCart(record) {
      record.products.forEach((v) => {
        let qty = v.quantity;
        let product = JSON.parse(JSON.stringify(v.product));
        product.qty = qty;
        this.addProduct(product);
      });
    },
  },

  watch: {
    params: {
      handler: function(newValue) {
        this.refresh();
      },
      deep: true,
    },

    pageSize() {
      const pagination = { ...this.pagination };
      pagination.pageSize = parseInt(this.pageSize);
      this.pagination = pagination;
      this.refresh();
    },
  },
};
</script>
