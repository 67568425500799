<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Edit Performance Evaluation"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-form
      name="update_performance_evaluation_form"
      ref="update_performance_evaluation_form"
      :model="model"
      :rules="rules"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-card size="small" hoverable>
        <a-steps progressDot :current="current">
          <template v-for="(item, index) in steps" :key="item.title">
            <a-step :title="item.title" v-show="stepCondition(index)" />
          </template>
        </a-steps>
      </a-card>

      <a-card size="small" hoverable :loading="cardLoading">
        <template #title>
          {{ title }}
          <a-tooltip v-if="showScoringTooltip" placement="top">
            <template #title>
              SCORING<br />
              0=Performs well below expected performance<br />
              1=Below expected performance<br />
              2=Expected performance<br />
              3=Above expected performance<br />
              4=Exceeds expected performance
            </template>
            <a-button type="link">
              <i class="fa fa-info-circle fa-1x"> </i>
            </a-button>
          </a-tooltip>
        </template>

        <component
          :is="is"
          v-bind="{ employees, hospitals, desc }"
          :model="model"
          @validate="validate"
        >
          <router-view />
        </component>
      </a-card>

      <a-card size="small" hoverable :loading="cardLoading">
        <a-space>
          <a-button v-if="current > 0" htmlType="button" @click="prev">
            Previous
          </a-button>

          <a-button
            v-if="current < steps.length - 1"
            type="primary"
            htmlType="button"
            @click="next"
          >
            Next
          </a-button>

          <a-button
            v-if="current == steps.length - 1"
            type="primary"
            htmlType="submit"
          >
            Done
          </a-button>
        </a-space>
      </a-card>
    </a-form>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { route } from "../../../helper/route";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],

  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      performance_id: this.$route.params.id,
      current: 0,
      model: {
        id: this.$route.params.id,
        employee_id: undefined,
        evaluation_completed_date: null,
        hospital_id: undefined,
        next_performance_review_date: null,
        job_type: 2,
        appraisal_type: 1,

        // section 2
        proccessed_all_required_license_and_certificates: "Yes",
        employee_attendance_meets_department_standards: "Yes",
        performance_forwared_to_hr: "Yes",

        // section 3
        section3: {
          a: 5,
          b: 5,
          c: 5,
          d: 5,
          comments: "N/A",
          avg_score: 0,
          overall_weightage: 0,
        },

        // section 4
        section4: {
          a: 5,
          b: 5,
          c: 5,
          d: 5,
          e: 5,
          f: 5,
          g: 5,
          comments: "N/A",
          avg_score: 0,
          overall_weightage: 0,
        },

        // section 5
        section5: {
          is_old_employee: "No",
          last_year_goals: [],
          avg_score: 0,
          overall_weightage: 0,
        },

        section6: [
          {
            goal: "N/A",
            weightage: 100,
          },
        ],
        section7: [
          {
            type: "Weighted Performance Factors",
            percentage: 20,
            weightage: 0,
          },
          {
            type: "Weighted Job Competency",
            percentage: 80,
            weightage: 0,
          },
          {
            type: "Weighted Previous Year Goal",
            percentage: 0,
            weightage: 0,
          },
        ],
        section8: [
          {
            type: 1,
            sign: null,
            sign_at: null,
          },
          {
            type: 2,
            sign: null,
            sign_at: null,
          },
        ],
      },
      rules: {},
      employees: [],
      hospitals: [],
      steps: [
        {
          step: "Section 1",
          title: "Employee Info",
          show_scoring_tooltip: false,
          content: "section1",
        },
        {
          step: "Section 2",
          title: "Certifications",
          show_scoring_tooltip: false,
          content: "section2",
        },
        {
          step: "Section 3",
          title: "Performance Factors",
          show_scoring_tooltip: false,
          content: "section3",
        },
        {
          step: "Section 4",
          title: "Job Specific Competencies",
          show_scoring_tooltip: false,
          content: "section4",
        },
        {
          step: "Section 5",
          title: "Goal Achievement",
          show_scoring_tooltip: false,
          content: "section5",
        },
        {
          step: "Section 6",
          title: "Performance Planning",
          show_scoring_tooltip: false,
          content: "section6",
        },
        {
          step: "Section 7",
          title: "Performance Factors Summary",
          show_scoring_tooltip: false,
          content: "section7",
        },
        // {
        //   step: "Section 8",
        //   title: "Signatures",
        //   show_scoring_tooltip: false,
        //   content: "section8",
        // },
      ],
      record: null,
      desc: this.$constants.performanceEvaluationStarDesc,
    };
  },

  async mounted() {
    await this.fetchEmployees();
    await this.getData();
  },

  computed: {
    is() {
      return route(
        `admin/performanceAppraisals/${this.steps[this.current].content}.vue`
      );
    },

    title() {
      return this.steps[this.current].title;
    },

    showScoringTooltip() {
      return this.steps[this.current].show_scoring_tooltip;
    },

    section5() {
      return this.formData?.section5 ?? [];
    },
  },

  watch: {
    "model.employee_id": function (val) {
      this.fetchHospitals();
    },

    "model.evaluation_completed_date": function (val) {
      this.model.next_performance_review_date = val
        .clone()
        .add(1, "year")
        .subtract(1, "day");
    },

    "model.section3": {
      handler: function (val) {
        let totalScore = val.a + val.b + val.c + val.d - 4;
        val.avg_score = Number(Number(totalScore / 4).toFixed(2));
        val.overall_weightage = Number(Number(val.avg_score * 0.2).toFixed(2));
        this.model.section7[0].weightage = val.overall_weightage;
      },
      deep: true,
    },

    "model.section4": {
      handler: function (val) {
        if (this.model.job_type == 1) {
          val.avg_score = Number(
            Number(
              (val.a - 1) * 0.7 +
                (val.b - 1) * 0.05 +
                (val.c - 1) * 0.05 +
                (val.d - 1) * 0.2
            ).toFixed(2)
          );
        } else if (this.model.job_type == 2) {
          val.avg_score = Number(
            Number(
              (val.a - 1) * 0.25 +
                (val.b - 1) * 0.2 +
                (val.c - 1) * 0.15 +
                (val.d - 1) * 0.15 +
                (val.e - 1) * 0.05 +
                (val.f - 1) * 0.1 +
                (val.g - 1) * 0.1
            ).toFixed(2)
          );
        }
        let overall_weightage = 0.8;
        if (
          this.model.appraisal_type == 1 &&
          this.model.section5.is_old_employee == "Yes"
        ) {
          overall_weightage = 0.7;
        }
        val.overall_weightage = Number(
          Number(val.avg_score * overall_weightage).toFixed(2)
        );
        this.model.section7[1].weightage = val.overall_weightage;
      },
      deep: true,
    },

    "model.section5.is_old_employee": {
      handler: function () {
        this.model.section5.avg_score = 0;
        this.model.section5.overall_weightage = 0;
        this.model.section5.last_year_goals = [];

        let overall_weightage = 0.8;
        if (
          this.model.appraisal_type == 1 &&
          this.model.section5.is_old_employee == "Yes"
        ) {
          overall_weightage = 0.7;
          this.fetchLastEvaluationRecord();
        }

        this.model.section4.overall_weightage = Number(
          Number(this.model.section4.avg_score * overall_weightage).toFixed(2)
        );
        this.model.section7[1].weightage =
          this.model.section4.overall_weightage;
        this.model.section7[1].percentage = overall_weightage * 100;
        if (overall_weightage == 0.7) {
          this.model.section7[2].percentage = 10;
        } else {
          this.model.section7[2].percentage = 0;
        }
      },
      deep: true,
    },

    "model.section5.last_year_goals": {
      handler: function () {
        this.model.section5.avg_score = Number(
          Number(
            this.model.section5.last_year_goals
              .reduce((pre, curr) => {
                return pre + (curr.rating - 1) * (curr.weightage / 100);
              }, 0)
              .toFixed(2)
          )
        );
        this.model.section5.overall_weightage = Number(
          Number(this.model.section5.avg_score * 0.1).toFixed(2)
        );
        this.model.section7[2].weightage =
          this.model.section5.overall_weightage;
      },
      deep: true,
    },
  },

  methods: {
    getData() {
      commonService
        .get(this.$constants.adminPerformanceEvaluationShowUrl, {
          id: this.performance_id,
        })
        .then((res) => {
          this.loading = false;
          this.record = JSON.parse(JSON.stringify(res.data));
          let data = res.data;
          let json = data.json;
          let section3 = json.section3 ?? this.model.section3;
          let section4 = json.section4 ?? this.model.section4;
          let section5 = json.section5 ?? this.model.section5;
          let section6 = json.section6 ?? this.model.section6;
          let section7 = json.section7 ?? this.model.section7;
          let section8 = json.section8 ?? this.model.section8;

          this.model = {
            id: this.$route.params.id,
            employee_id: data.employee_id,
            evaluation_completed_date: moment(data.evaluation_completed_date),
            hospital_id: data.hospital_id,
            next_performance_review_date: data.next_performance_review_date
              ? moment(data.next_performance_review_date)
              : null,
            job_type: data.job_type,
            appraisal_type: data.appraisal_type,

            // section 2
            proccessed_all_required_license_and_certificates:
              json.proccessed_all_required_license_and_certificates ?? null,
            employee_attendance_meets_department_standards:
              json.employee_attendance_meets_department_standards ?? null,
            performance_forwared_to_hr: json.performance_forwared_to_hr ?? null,

            // section 3
            section3: {
              a: section3.a ?? 1,
              b: section3.b ?? 1,
              c: section3.c ?? 1,
              d: section3.d ?? 1,
              comments: section3.comments ?? 1,
              avg_score: section3.avg_score ?? 0,
              overall_weightage: section3.overall_weightage ?? 0,
            },

            // section 4
            section4: {
              a: section4.a ?? 1,
              b: section4.b ?? 1,
              c: section4.c ?? 1,
              d: section4.d ?? 1,
              e: section4.e ?? 1,
              f: section4.f ?? 1,
              g: section4.g ?? 1,
              comments: section4.comments ?? 1,
              avg_score: section4.avg_score ?? 0,
              overall_weightage: section4.overall_weightage ?? 0,
            },

            section5,
            section6,
            section7,
            section8,
          };
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "admin-performance-evaluation" });
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    fetchEmployees() {
      commonService
        .get(this.$constants.getEmployeesUrl)
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          //
        });
    },

    fetchHospitals() {
      commonService
        .get(this.$constants.getHospitalsUrl, {
          employee_id: this.model.employee_id,
        })
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    stepCondition(index) {
      return (
        this.model.appraisal_type == 1 ||
        (this.model.appraisal_type == 2 && ![4, 5].includes(index))
      );
    },

    handleFinish() {
      this.$refs.update_performance_evaluation_form
        .validate()
        .then(() => {
          let val = { ...this.model };
          if (val.appraisal_type == 1) {
            val.hospital_id = null;
            val.next_performance_review_date = this.$customDate.ymd(
              val.next_performance_review_date
            );
          } else {
            val.next_performance_review_date = null;
          }
          val.evaluation_completed_date = this.$customDate.ymd(
            val.evaluation_completed_date
          );

          // if (val.section8[0].sign == null) {
          //   this.$message.error("Signature can not be empty.");
          //   return;
          // }

          this.submitBtnLoader = true;
          commonService
            .store(this.$constants.adminPerformanceEvaluationStoreUrl, val)
            .then((res) => {
              this.submitBtnLoader = false;
              this.$message.success(res.message);
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$message.error(err);
              this.submitBtnLoader = false;
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    next() {
      this.$refs.update_performance_evaluation_form.validate().then(() => {
        if (this.current == 5) {
          let totalWeightage = this.model.section6.reduce((pre, curr) => {
            if (curr.weightage && !isNaN(curr.weightage)) {
              return curr.weightage + pre;
            }
            return pre;
          }, 0);
          if (totalWeightage != 100) {
            this.$message.error("Total weightage must be 100%.");
            return;
          }
        }
        if (this.current == 3 && this.model.appraisal_type == 2) {
          this.current++;
          this.current++;
        }
        this.current++;
      });
    },

    prev() {
      if (this.current == 6 && this.model.appraisal_type == 2) {
        this.current--;
        this.current--;
      }
      this.current--;
    },

    fetchLastEvaluationRecord() {
      commonService
        .get(this.$constants.getEmployeeLastEvaluationUrl, {
          employee_id: this.model.employee_id,
        })
        .then((res) => {
          if (res.data?.json?.section6) {
            let section5 = this.record.json.section5;
            this.model.section5.last_year_goals = res.data.json.section6.map(
              (v, i) => {
                let goal = section5.last_year_goals.find(
                  (_, index) => i == index
                );
                return {
                  ...v,
                  rating: goal && goal.rating ? goal.rating : 1,
                };
              }
            );
          }
        });
    },
  },
};
</script>
