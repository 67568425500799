<template>
  <a-form
    name="live_charting_minus_volume_form"
    ref="live_charting_minus_volume_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="autologous_prime"
          label="Autologous Prime"
          name="autologous_prime"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.autologous_prime" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ultrafiltration"
          label="Ultrafiltration"
          name="ultrafiltration"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.ultrafiltration" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="urine"
          label="Urine"
          name="urine"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.urine" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cpb_volume_to_cell_saver"
          label="CPB Volume to Cell Saver"
          name="cpb_volume_to_cell_saver"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number"
            :min="0"
            v-model:value="model.cpb_volume_to_cell_saver"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="residual_cpb_volume"
          label="Residual CPB Volume"
          name="residual_cpb_volume"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.residual_cpb_volume" />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      model: {
        time: moment().tz(this.$constants.timezone).format("HH:mm"),
        type: "- Volume",
        autologous_prime: null,
        ultrafiltration: null,
        urine: null,
        cpb_volume_to_cell_saver: null,
        residual_cpb_volume: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      let data = Object.entries(this.model)
        .map((e) => {
          if (
            [
              "autologous_prime",
              "ultrafiltration",
              "urine",
              "cpb_volume_to_cell_saver",
              "residual_cpb_volume",
            ].includes(e[0]) &&
            e[1]
          ) {
            e[1] = -e[1];
          }
          return e;
        })
        .reduce((pre, curr) => {
          pre[curr[0]] = curr[1];
          return pre;
        }, {});
      console.log("submitted::", data);
      return;

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
