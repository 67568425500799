<template>
  <div>
    <a-form
      name="product_form"
      ref="product_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="manufacturer_id"
            label="Manufacturer"
            name="manufacturer_id"
          >
            <a-select
              v-model:value="formState.manufacturer_id"
              placeholder="Select manufacturer"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="manufacturer in manufacturers"
                :key="manufacturer.id"
                :label="manufacturer.name"
                :value="manufacturer.id"
              >
                {{ manufacturer.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="sku" label="SKU" name="sku">
            <a-input
              v-model:value="formState.sku"
              placeholder="Enter sku here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="price" label="Price" name="price">
            <a-input-number
              v-model:value="formState.price"
              placeholder="Enter price here"
              :min="0.01"
              :step="0.01"
              :formatter="$comman.currencyParserformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="ref_code" label="Ref Code" name="ref_code">
            <a-input
              v-model:value="formState.ref_code"
              placeholder="Enter ref code here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="uom_id" label="UOM" name="uom_id">
            <a-select
              v-model:value="formState.uom_id"
              placeholder="Select uom"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="uom in uoms"
                :key="uom.id"
                :label="uom.name"
                :value="uom.id"
              >
                {{ uom.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="formState.uom_id == 'Other'">
          <a-form-item ref="uom_name" label="New UOM" name="uom_name">
            <a-input
              v-model:value="formState.uom_name"
              placeholder="Enter new uom here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="product_category_id"
            label="Product Category"
            name="product_category_id"
          >
            <a-select
              v-model:value="formState.product_category_id"
              placeholder="Select product category"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="product_category in product_categories"
                :key="product_category.id"
                :label="product_category.name"
                :value="product_category.id"
              >
                {{ product_category.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="quickbooks_item_id"
            label="QuickBooks Item"
            name="quickbooks_item_id"
          >
            <a-select
              v-model:value="formState.quickbooks_item_id"
              placeholder="Select quickBooks item"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="inventory in inventories"
                :key="inventory.Id"
                :label="inventory.Name"
                :value="inventory.Id"
              >
                {{ inventory.Name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item ref="is_discontinued" name="is_discontinued">
            <a-checkbox v-model:checked="formState.is_discontinued">
              Is this product discontinued?
            </a-checkbox>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            ref="description"
            label="Description (Optional)"
            name="description"
          >
            <a-textarea
              v-model:value="formState.description"
              placeholder="Enter product description here"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item
            ref="image"
            label="Image"
            name="image"
            :validateStatus="attachmentError.hasError"
            :help="attachmentError.errorMessage"
            @change="
              () =>
                (attachmentError.hasError = '') &&
                (attachmentError.errorMessage = '')
            "
          >
            <a-upload
              :file-list="fileList"
              :before-upload="
                (_, files) => beforeUpload(files, 'image/jpeg,image/png')
              "
              :remove="onFileRemove"
              :multiple="true"
              accept="image/jpeg,image/png"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="specs_document"
            label="Specs Document"
            name="specs_document"
          >
            <a-upload
              v-model:file-list="formState.specs_document"
              :before-upload="beforeUploadSpecDocument"
              @change="handleSpecDocumentChangeEvent"
              :remove="() => (formState.specs_document = [])"
              accept="application/pdf"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { validation } from "../../helper/validation";

export default {
  components: {
    UploadOutlined,
  },
  data() {
    return {
      fileList: [],
      attachmentError: {
        hasError: "",
        errorMessage: "",
      },
      previewVisible: false,
      previewImage: "",
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
        manufacturer_id: [
          {
            required: true,
            type: "number",
            message: "manufacturer is required!",
            trigger: "blur",
          },
        ],
        sku: [
          {
            required: true,
            message: "sku is required!",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
        price: [
          {
            required: true,
            message: "price have max upto 2 decimal points!",
            validator: validation.decimal,
          },
        ],
        uom_id: [
          {
            required: true,
            type: "any",
            message: "uom is required!",
            trigger: "blur",
          },
        ],
        uom_name: [
          {
            validator: this.uom_name_validation,
            trigger: "blur",
          },
        ],
        product_category_id: [
          {
            required: true,
            type: "number",
            message: "product category is required!",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    manufacturers() {
      return this.extra.manufacturers;
    },
    product_categories() {
      return this.extra.product_categories;
    },
    uoms() {
      return this.extra.uoms;
    },
    inventories() {
      return this.extra.inventories;
    },
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },
  created() {
    if (this.record.images != null) {
      this.record.images.forEach((e) => {
        const obj = {
          uid: e.id,
          status: "Done",
          url: e.url,
          thumbUrl: e.url,
          name: e.image_name,
        };
        this.fileList.push(obj);
      });
    }
  },
  mounted() {
    this.getFormState();
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        manufacturer_id: this.record.manufacturer_id ?? null,
        product_category_id: this.record.product_category_id ?? null,
        uom_id: this.record.uom_id ?? null,
        sku: this.record.sku ?? "",
        price: this.record.price ? Number(this.record.price) : "",
        ref_code: this.record.ref_code ?? "",
        description: this.record.description ?? "",
        quickbooks_item_id: this.record.quickbooks_item_id ?? undefined,
        is_discontinued: this.record.is_discontinued == 1 ? true : false,
        specs_document: this.record.specs_document
          ? [
              {
                id: this.record.id,
                status: "Done",
                url: this.record.specs_document_url,
                thumbUrl: this.record.specs_document_url,
                name: this.record.specs_document,
              },
            ]
          : [],
      };
    },
    onSubmit() {
      this.$refs.product_form
        .validate()
        .then((val) => {
          if (this.record.id) {
            val.id = this.record.id;
          }
          if (val.quickbooks_item_id) {
            let selectedQuickbookItem = this.inventories.find(
              (v) => v.Id == val.quickbooks_item_id
            );
            if (selectedQuickbookItem) {
              val.quickbooks_item_info = JSON.stringify(selectedQuickbookItem);
            }
          }
          val.images = this.fileList;
          const formData = new FormData();
          Object.keys(val).forEach((key) => {
            if (key === "specs_document") {
              if (val[key].length == 1) {
                if (val[key][0].id) {
                  formData.append("old_pdf", 1);
                } else if (val[key][0].uid) {
                  formData.append("pdf", val[key][0]);
                }
              }
            } else if (key === "images") {
              const oldImages = this.fileList.filter(
                (v) => v.status === "Done"
              );
              if (oldImages.length) {
                oldImages.forEach((v) => {
                  formData.append("old_images[]", v.uid);
                });
              }
              const newImages = this.fileList.filter(
                (v) => v.status !== "Done"
              );
              if (newImages.length) {
                newImages.forEach((v) => {
                  formData.append("images[]", v);
                });
              }
            } else if (key == "is_discontinued") {
              formData.append(key, val[key] == true ? 1 : 0);
            } else {
              formData.append(key, val[key]);
            }
          });
          this.loadingStart();
          companyService
            .store(this.$constants.productStoreUrl, formData, true)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    onFileRemove(file) {
      const items = this.fileList;
      const idx = items.findIndex((v) => v.uid === file.uid);
      if (idx > -1) items.splice(idx, 1);
    },
    beforeUpload(fileList, types) {
      const acceptedTypes = types.split(",");

      this.fileList = fileList.filter((file) => {
        const isCorrectType = acceptedTypes.includes(file.type);
        const isCorrectSize = file.size / 1024 / 1024 < 5;

        if (!isCorrectType) {
          this.attachmentError.hasError = "error";
          this.attachmentError.errorMessage = `File type of ${file.type} is not supported.`;
          return false;
        }
        if (!isCorrectSize) {
          this.attachmentError.hasError = "error";
          this.attachmentError.errorMessage = `File size of ${file.name} is too large. Max: 5MB`;
          // this.$message.error(
          //   `File size of ${file.name} is too large. Max: 5MB`
          // );
          return false;
        }
        return true;
      });
      return false;
    },
    handleSpecDocumentChangeEvent({ file }) {
      if (this.formState.specs_document.length > 0) {
        if (file.type != "application/pdf") {
          this.$message.error(`File type of ${file.type} is not supported.`);
          this.formState.specs_document.pop();
          return false;
        }
        if (file.size / 1024 / 1024 > 5) {
          this.$message.error(
            `File size of ${file.name} is too large. Max: 5MB`
          );
          this.formState.specs_document.pop();
          return false;
        }
      }
    },
    beforeUploadSpecDocument(file) {
      this.formState.specs_document.pop();
      this.formState.specs_document.push(file);
      return false;
    },
    uom_name_validation(rule, value) {
      if (!value && this.formState.uom_id == "Other") {
        return Promise.reject("this field is required!");
      }
      return Promise.resolve();
    },
  },
};
</script>
