<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-form-item
        ref="job_type"
        label="Job Type"
        name="job_type"
        :validateStatus="validate('job_type', 'status')"
        :help="validate('job_type', 'msg')"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-select
          v-model:value="model.job_type"
          placeholder="Select job type"
          show-search
          optionFilterProp="label"
          @change="$emit('update:model', model)"
        >
          <a-select-option
            v-for="option in $constants.performanceEvaluationJobs"
            :key="option.value"
            :label="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="appraisal_type"
        label="Appraisal Type"
        name="appraisal_type"
        :validateStatus="validate('appraisal_type', 'status')"
        :help="validate('appraisal_type', 'msg')"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-select
          v-model:value="model.appraisal_type"
          placeholder="Select appraisal type"
          show-search
          optionFilterProp="label"
          @change="$emit('update:model', model)"
        >
          <a-select-option
            v-for="option in $constants.performanceEvaluationTypes"
            :key="option.value"
            :label="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="employee_id"
        label="Employee Name"
        name="employee_id"
        :validateStatus="validate('employee_id', 'status')"
        :help="validate('employee_id', 'msg')"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-select
          v-model:value="model.employee_id"
          placeholder="Select employee"
          show-search
          optionFilterProp="label"
          @change="$emit('update:model', model)"
        >
          <a-select-option
            v-for="option in employees"
            :key="option.id"
            :label="option.full_name"
            :value="option.id"
          >
            {{ option.full_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="evaluation_completed_date"
        label="Evaluation Date"
        name="evaluation_completed_date"
        :validateStatus="validate('evaluation_completed_date', 'status')"
        :help="validate('evaluation_completed_date', 'msg')"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'date',
        }"
      >
        <a-date-picker
          :allowClear="false"
          v-model:value="model.evaluation_completed_date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>

    <a-col v-if="model.appraisal_type == 2" :span="8">
      <a-form-item
        ref="hospital_id"
        label="Facility Name"
        name="hospital_id"
        :validateStatus="validate('hospital_id', 'status')"
        :help="validate('hospital_id', 'msg')"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-select
          v-model:value="model.hospital_id"
          placeholder="Select facility"
          show-search
          optionFilterProp="label"
          @change="$emit('update:model', model)"
        >
          <a-select-option
            v-for="option in hospitals"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          >
            {{ option.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col v-if="model.appraisal_type == 1" :span="8">
      <a-form-item
        ref="next_performance_review_date"
        label="Next Evaluation Date"
        name="next_performance_review_date"
        :validateStatus="validate('next_performance_review_date', 'status')"
        :help="validate('next_performance_review_date', 'msg')"
      >
        <a-date-picker
          :allowClear="false"
          v-model:value="model.next_performance_review_date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledPastDate"
          disabled
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
  },

  data() {
    return {
      //
    };
  },

  methods: {
    validate(...arg) {
      this.$emit("validate", ...arg);
    },
  },
};
</script>
