<template>
  <a-layout-content>
    <page-header>
      <template #title> Live Charting Audit Logs </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #os="{ record }">
          <template v-if="record.browser.includes('Windows')">
            <i class="ti ti-brand-windows ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Linux')">
            <i class="ti ti-brand-ubuntu ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Mac OS')">
            <i class="ti ti-brand-apple ti-lg"></i>
          </template>
        </template>

        <template #browser="{ record }">
          <template v-if="record.browser.includes('Edg')">
            <i class="ti ti-brand-edge ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Chrome')">
            <i class="ti ti-brand-chrome ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Firefox')">
            <i class="ti ti-brand-firefox ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Safari')">
            <i class="ti ti-brand-safari ti-lg"></i>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.caseReportAuditLogsUrl,
      columns: [
        {
          title: "Case Report No",
          dataIndex: "report_no",
          key: "report_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.case_report.report_no,
          defaultFilteredValue: this.$route.query?.report_no
            ? [this.$route.query?.report_no]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital",
          key: "hospital",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.case_report.hospital.name,
          defaultFilteredValue: this.$route.query?.hospital
            ? [this.$route.query?.hospital]
            : undefined,
        },
        {
          title: "Employee",
          dataIndex: "employee",
          key: "employee",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) => record.case_report.employee.full_name,
          defaultFilteredValue: this.$route.query?.employee
            ? [this.$route.query?.employee]
            : undefined,
        },
        {
          title: "Remarks",
          dataIndex: "remarks",
          key: "remarks",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.remarks
            ? [this.$route.query?.remarks]
            : undefined,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: this.$route.query?.sortOrder
            ? this.$route.query.sortOrder
            : "descend",
          customRender: ({ text }) => `${this.$customDate.mdyhis(text)}`,
        },
      ],
      defaultFilterParams: {
        report_no: this.$route.query?.report_no
          ? [this.$route.query?.report_no]
          : undefined,
        hospital: this.$route.query?.hospital
          ? [this.$route.query?.hospital]
          : undefined,
        employee: this.$route.query?.employee
          ? [this.$route.query?.employee]
          : undefined,
        remarks: this.$route.query?.remarks
          ? [this.$route.query?.remarks]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : "created_at",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : "descend",
      },
    };
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
