<template>
  <a-table
    :columns="columns"
    :rowKey="(rec, index) => rec.id"
    :dataSource="record"
    :pagination="false"
    size="small"
  >
    <template #employment_type="{ record }">
      <a-tag
        :color="
          $comman.getValueFromObject(
            record?.employee,
            'employment_type',
            $constants.employment_types,
            'color'
          )
        "
      >
        {{
          $comman.getValueFromObject(
            record?.employee,
            "employment_type",
            $constants.employment_types
          )
        }}
      </a-tag>
    </template>
  </a-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          customRender: ({ record }) => record?.employee?.full_name ?? "N/A",
        },
        {
          title: "Position",
          dataIndex: "position",
          key: "position",
          customRender: ({ record }) =>
            record?.employee?.position?.name ?? "N/A",
        },
        {
          title: "Type",
          dataIndex: "employment_type",
          key: "employment_type",
          slots: {
            customRender: "employment_type",
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },
};
</script>
