<template>
  <a-layout-content>
    <a-card
      size="small"
      class="page-header"
      v-if="loading"
      :loading="loading"
    />

    <a-page-header
      v-else
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="$router.go(-1)"
    >
      <template #title>
        <template v-if="employee.profile_pic">
          <a-avatar :src="employee.profile_pic_url">
            {{ employee?.last_name?.charAt(0) }}
            {{ employee?.first_name?.charAt(0) }}
          </a-avatar>
        </template>
        {{ employee.full_name }}
      </template>

      <template #tags>
        <a-tag
          :color="$comman.get_employee_type(employee.employee_type, 'color')"
        >
          {{ $comman.get_employee_type(employee.employee_type, "text") }}
        </a-tag>

        <a-tag v-if="employee.is_manager" color="purple"> Manager </a-tag>

        <a-tooltip
          :title="
            isProfileCredentialsComplete
              ? 'All required credentials submitted.'
              : 'Some of required credentials have expired or are missing.'
          "
        >
          <a-badge
            :status="isProfileCredentialsComplete ? 'success' : 'error'"
            dot
          />
        </a-tooltip>
      </template>

      <template #extra>
        <!--  -->
      </template>

      <template #footer>
        <a-tabs
          v-model:activeKey="activeTabKey"
          style="margin-bottom: 0"
          @tabChange="(key) => (activeTabKey = key)"
        >
          <a-tab-pane v-for="tab in tabList" :key="tab.key" :tab="tab.tab" />
        </a-tabs>
      </template>

      <a-row class="content" type="flex" :gutter="[16, 16]">
        <!-- <a-col> <i class="ti ti-user ti-lg"></i> Perfusionist </a-col> -->

        <a-col>
          <a-space>
            <i class="ti ti-building ti-lg"></i>
            {{ employee?.company?.name ?? "N/A" }}
          </a-space>
        </a-col>

        <a-col>
          <a-space>
            <i class="ti ti-mail ti-lg"></i>
            <a-typography-link :href="`mailto:${employee.email}`">
              {{ employee.email }}
            </a-typography-link>
          </a-space>
        </a-col>

        <a-col v-if="employee.contact_no">
          <a-space>
            <i class="ti ti-device-mobile ti-lg"></i>
            <a-typography-link :href="`tel:${employee.contact_no}`">
              {{ employee.contact_no }}
            </a-typography-link>
          </a-space>
        </a-col>
      </a-row>
    </a-page-header>

    <component v-if="is" :is="is" v-bind="{ employee }">
      <router-view />
    </component>
  </a-layout-content>
</template>

<script>
import { route } from "../../../helper/route";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      loading: true,
      gustoImportLoading: false,
      employee: null,
      nextPrev: null,
      employee_id: this.$route.params.id,
      activeTabKey: this.$route.query?.tab ?? "certificates",
      tabList: [
        {
          key: "certificates",
          tab: "Credentialing",
        },
      ],
    };
  },

  mounted() {
    this.getData();
  },

  updated() {
    if (this.employee_id != this.$route.params.id) {
      this.loading = true;
      this.employee = null;
      this.employee_id = this.$route.params.id;
      this.getData();
    }
  },

  computed: {
    is() {
      if (this.activeTabKey != "") {
        return route(`manager/employee/showTabs/${this.activeTabKey}`);
      } else {
        return "";
      }
    },

    isProfileCredentialsComplete() {
      if (!this.employee) {
        return false;
      } else {
        return !!this.employee.profile_status;
      }
    },
  },

  watch: {
    activeTabKey: {
      handler: function (val) {
        this.$router.replace({ query: { tab: val } });
      },
    },
  },

  methods: {
    getData() {
      commonService
        .get(this.$constants.managerEmployeeShowUrl, { id: this.employee_id })
        .then((res) => {
          this.loading = false;
          this.employee = res.data.employee;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "employee" });
        });
    },
  },
};
</script>
