import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";
import profile from "./middleware/profile";

export default [
  // For Employees Start
  {
    path: "/paid-time-offs",
    name: "paid-time-off",
    component: route("paidTimeOff/index"),
    meta: {
      title: "Paid Time Offs",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/paid-time-off/create",
    name: "create-paid-time-off",
    component: route("paidTimeOff/store"),
    meta: {
      title: "Create Paid Time Off",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/paid-time-off/edit/:id",
    name: "edit-paid-time-off",
    component: route("paidTimeOff/store"),
    meta: {
      title: "Edit Paid Time Off",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  // For Employees End

  // For Admin Start
  {
    path: "/admin/paid-time-offs",
    name: "admin.paid-time-off",
    component: route("admin/paidTimeOff/index"),
    meta: {
      title: "Admin | Paid Time Offs",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  // For Admin End

  // For Manager Start
  {
    path: "/manager/paid-time-offs",
    name: "manager.paid-time-off",
    component: route("manager/paidTimeOff/index"),
    meta: {
      title: "Manager | Paid Time Offs",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  // For Manager End
];
