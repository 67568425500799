<template>
  <a-card size="small" :loading="loading">
    <a-row :gutter="[16, 16]">
      <a-col :span="24" v-for="perm in permissions" :key="perm.key">
        <div style="display: flex; gap: 8px">
          <a-switch v-model:checked="perm.isSelected" />
          <a-typography-text>
            {{ perm.title }}
          </a-typography-text>
        </div>
      </a-col>

      <a-col :span="24">
        <div style="display: flex; gap: 8px">
          <a-switch v-model:checked="is_associated_with_monthly_billing" />
          <a-typography-text>
            Is associated with monthly billing?
          </a-typography-text>
        </div>
      </a-col>

      <a-col>
        <a-button
          :loading="saveLoading"
          type="primary"
          @click="savePermissions"
        >
          Save
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { commonService } from "../../../services";
import settingVue from "../components/setting.vue";

export default {
  components: { settingVue },

  data() {
    return {
      loading: false,
      saveLoading: false,
      permissionStore: this.$constants.employeePermissionsStore,
      is_associated_with_monthly_billing: false,
      permissions: [
        {
          key: "admin-order",
          title: "Can Access Admin > Orders Page",
          isSelected: false,
        },
        {
          key: "admin-dispatched-orders",
          title: "Can Access Admin > Dispatched Orders Page",
          isSelected: false,
        },
        {
          key: "facility-product-edit",
          title: "Can Access Facilities > Show > Products Tab > Edit Products",
          isSelected: false,
        },
        {
          key: "facility-product-b2b",
          title: "Can Access Facilities > Show > Products Tab > B2B Transfer",
          isSelected: false,
        },
        {
          key: "admin-credentialing-approvals",
          title: "Can Access Admin > Credentialing Approvals",
          isSelected: false,
        },
        {
          key: "users-employee",
          title: "Can Manage Employee Credentialing",
          isSelected: false,
        },
      ],
      employee: {},
    };
  },

  mounted() {
    this.getEmployee();
  },

  computed: {
    employeeId() {
      return parseInt(this.$route.params.id);
    },
  },

  methods: {
    getEmployee() {
      this.loading = true;
      if (this.employeeId !== 0) {
        return commonService
          .get(this.$constants.employeeShowUrl, { id: this.employeeId })
          .then((res) => {
            if (!res.data.employee.can_access) {
              res.data.employee.can_access = [];
            }
            this.permissions.forEach((perm) => {
              perm.isSelected = res.data.employee.can_access.includes(perm.key);
            });
            this.is_associated_with_monthly_billing = res.data.employee
              .is_associated_with_monthly_billing
              ? true
              : false;
          })
          .catch((err) => {
            this.$message.error(err || "Something went wrong.");
            this.$router.push({ name: "employee" });
            return Promise.reject();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        return Promise.resolve();
      }
    },

    savePermissions() {
      this.saveLoading = true;
      const selectedPerms = this.permissions
        .filter((perm) => perm.isSelected)
        .map((perm) => perm.key);
      commonService
        .store(this.permissionStore, {
          id: this.employeeId,
          can_access: selectedPerms,
          is_associated_with_monthly_billing:
            this.is_associated_with_monthly_billing,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    // fetchData() {
    //   this.loading = true;
    //   commonService
    //   .store(this.permissionStore)
    //   .then((res) => {
    //     this.notificationSettings = res.data;
    //   })
    //   .catch((err) => this.$message.error(err))
    //   .finally(() => (this.loading = false));
    // },
  },

  watch: {
    // "$route.params.id": function (val) {
    //   if (val) {
    //     this.fetchData();
    //   }
    // },
  },
};
</script>
