<template>
  <a-descriptions
    title="Basic Information"
    size="small"
    bordered
    :column="2"
    :labelStyle="{ width: '170px' }"
  >
    <a-descriptions-item label="Date" :span="2">
      {{ $customDate.mdy(record.date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Facility" :span="2">
      {{ record.hospital.name ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="MR Number">
      {{ record.mr_number }}
    </a-descriptions-item>
    <a-descriptions-item label="Account Number">
      {{ record.data.account_number }}
    </a-descriptions-item>
    <a-descriptions-item label="Patient First Name">
      {{ record.patient_first_name }}
    </a-descriptions-item>
    <a-descriptions-item label="Patient Last Name">
      {{ record.patient_last_name }}
    </a-descriptions-item>
    <a-descriptions-item label="Allergies" :span="2">
      {{ record.data.allergies ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Height (cm)">
      {{ record.data.height ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Weight (kg)">
      {{ record.data.weight ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item>
      <template #label> Body Surface (m<sup>2</sup>): </template>
      {{ record.data.body_surface_area ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Blood Type">
      {{ record.data.blood_type ?? "N/A" }}
    </a-descriptions-item>
  </a-descriptions>

  <a-table
    v-if="showCI"
    :columns="columns"
    :rowKey="(record, index) => record.ci"
    :dataSource="dataSource"
    :pagination="false"
    size="small"
    class="mt-sm-2"
  />

  <modal-buttons cancelBtnText="Close" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      columns: [
        { title: "CI", dataIndex: "ci", key: "ci" },
        { title: "LPM", dataIndex: "lmp", key: "lmp" },
      ],
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),

    showCI() {
      return this.extra.dontShowCI ? false : true;
    },

    dataSource() {
      return this.$constants.caseReportlpm.map((item) => {
        return {
          ci: parseFloat(item).toFixed(1),
          lmp: parseFloat(item * this.record.data.body_surface_area).toFixed(2),
        };
      });
    },
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),
  },
};
</script>
