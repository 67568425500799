<template>
  <a-collapse
    accordion
    v-model:activeKey="activeKey"
    expand-icon-position="left"
  >
    <a-collapse-panel
      v-for="cer in certification_types"
      :key="cer.id.toString()"
      :header="cer.name"
    >
      <template
        v-if="
          cer.employeeCertificates != null &&
          Object.keys(cer.employeeCertificates).length > 0
        "
      >
        <div
          class="ant-table ant-table-scroll-position-left ant-table-small ant-table-bordered"
        >
          <div class="ant-table-content">
            <div class="ant-table-body" style="overflow-x: scroll">
              <table>
                <thead class="ant-table-thead">
                  <tr>
                    <th>Uploaded At</th>
                    <th>Expiration Date</th>
                    <th>Attachment</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody class="ant-table-tbody">
                  <template
                    v-for="employeeCertificate in cer.employeeCertificates"
                    :key="employeeCertificate.id"
                  >
                    <template v-if="cer.id == activeKey">
                      <tr>
                        <td>
                          {{ $customDate.ll(employeeCertificate.created_at) }}
                        </td>
                        <td>
                          <template
                            v-if="employeeCertificate.expiry_date != null"
                          >
                            {{
                              $customDate.ll(employeeCertificate.expiry_date)
                            }}
                          </template>
                          <template v-else>N/A</template>
                        </td>
                        <td>
                          <a-upload
                            :action="$constants.upload_action_url"
                            list-type="picture"
                            :default-file-list="employeeCertificate.attachments"
                            :showUploadList="{ showRemoveIcon: false }"
                          >
                          </a-upload>
                        </td>
                        <!-- <td>
                          <a-popconfirm
                            title="Are you sure for delete ?"
                            placement="top"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="handleDelete(employeeCertificate)"
                          >
                            <a-button type="danger"> Delete </a-button>
                          </a-popconfirm>
                        </td> -->
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center text-weight-bold">No data found.</div>
      </template>
      <!-- <template #extra>
        <a-button
          type="primary"
          @click="
            handleClick({
              title: `Add New ${cer.name}`,
              path: `profile.certificate.store`,
              extra: { certificate: cer },
              callback: refreshCertificateData,
            })
          "
          size="small"
        >
          <PlusOutlined /> Add
        </a-button>
      </template> -->
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { companyService } from "@/services";
import {
PlusOutlined,
SettingOutlined,
UploadOutlined,
} from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "certifications",

  props: ["id"],

  components: {
    UploadOutlined,
    SettingOutlined,
    PlusOutlined,
  },

  data() {
    return {
      certification_types: {},
      formState: {},
      activeKey: [],
      activeTableData: null,
    };
  },

  mounted() {
    this.$comman.get_certification_types().then((res) => {
      console.log("res::", res);
      this.certification_types = res;
      this.certification_types.forEach((v) => {
        this.formState[v.id] = {
          expiry_date: "",
          attachment: "",
        };
      });
      this.getEmployeeCertificates();
    });
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    handleChangeLicenseAttachment(info) {
      var isJPG = false;
      var isLt2M = false;
      var validEx = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
      if (info.file.status == "done") {
        isJPG = validEx.indexOf(info.file.type) == -1 ? false : true;
        isLt2M = info.file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error(
            `You can only upload ${validEx.toString(",")} file!`
          );
        } else if (!isLt2M) {
          this.$message.error("Image must smaller than 2MB!");
        }
        if (isJPG && isLt2M) {
          this.$comman.getBase64(info.file.originFileObj, (base64Url) => {
            info.file.thumbUrl = base64Url;
          });
          this.formState.social_security_attachment = [info.file];
        } else {
          this.formState.social_security_attachment = info.fileList[1]
            ? [info.fileList[0]]
            : [];
        }
      }
    },
    handleClick(data) {
      window.event.stopPropagation();
      this.open(data);
      this.activeKey = data.extra.certificate.id;
    },
    refreshCertificateData() {
      this.getEmployeeCertificates();
    },
    getEmployeeCertificates() {
      companyService
        .store(this.$constants.getEmployeeCertificates, {
          employee_id: this.id,
        })
        .then((res) => {
          if (res.success) {
            const data = res.data.credentials;
            const that = this;
            this.certification_types.forEach(function (value, index) {
              that.certification_types[index].employeeCertificates =
                data.filter((d) => d.certification_type_id === value.id);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelete(certificate) {
      this.$message.loading("Action is processing", 0);
      companyService
        .store(this.$constants.deleteEmployeeCertificates, {
          id: certificate.id,
        })
        .then((res) => {
          if (res.success) {
            this.$message.destroy();
            this.$message.success(res.message);
            this.getEmployeeCertificates();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    activeKey(val) {
      console.log(this.certification_types);
      console.log(val);
    },
  },
};
</script>
