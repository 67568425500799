<template>
  <a-card
    :loading="cardLoading"
    style="width: 100%"
    size="small"
    title="Templates"
  >
    <template #extra>
      <a-space size="small">
        <a-button type="primary">
          <a-space size="small" @click="createNewTemplate">
            <i class="ti ti-plus ti-lg"></i>
            Add New
          </a-space>
        </a-button>
      </a-space>
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => index"
      :dataSource="templates"
      size="small"
    >
      <template #action="{ record }">
        <a-space :size="1">
          <a-tooltip title="Show">
            <a-button
              type="link"
              size="small"
              class="primary-color"
              @click="showRecord(record)"
            >
              <i class="ti ti-eye ti-lg"></i>
            </a-button>
          </a-tooltip>

          <a-tooltip title="Edit">
            <a-button type="link" size="small" @click="editRecord(record)">
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>

          <a-popconfirm
            title="Are you sure you want to delete this template?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
          >
            <a-tooltip title="Delete">
              <a-button size="small" type="link" class="danger-color">
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </a-tooltip>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import { commonService, tableService } from "../../../services";
import { comman as $comman } from "@/helper/comman";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      cardLoading: true,
      hospitalId: this.$route.params.id,
      activeKey: "tab1",
      templates: [],
      tabList: [
        {
          key: "tab1",
          tab: "Single",
        },
        {
          key: "tab2",
          tab: "Cart",
        },
      ],
      columns: [
        {
          title: "Template",
          dataIndex: "name",
        },
        {
          title: "Total Products",
          customRender: ({ record }) => {
            return record.items.length;
          },
        },
        {
          title: "Total Units",
          customRender: ({ record }) => {
            return record.items.reduce((acc, cur) => acc + cur.qty, 0);
          },
        },
        {
          title: "Created By",
          dataIndex: "creator.full_name",
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  mounted() {
    this.fetchData();
  },

  computed: {
    $comman() {
      return $comman;
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    async fetchData() {
      this.cardLoading = true;

      commonService
        .get(this.$constants.hospitalDisposableTemplate, {
          hospital_id: this.hospitalId,
        })
        .then((res) => {
          this.templates = res.data;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => {
          this.cardLoading = false;
        });
    },

    createNewTemplate() {
      this.open({
        title: "Create Template",
        path: "hospital.drawerForm.templateStore",
        callback: this.fetchData,
        extra: {
          hospitalId: this.hospitalId,
        },
      });
    },

    editRecord(record) {
      this.open({
        title: "Create Template",
        path: "hospital.drawerForm.templateStore",
        callback: this.fetchData,
        extra: {
          hospitalId: this.hospitalId,
          record,
        },
      });
    },

    showRecord(record) {
      this.open({
        title: "Show Template",
        path: "hospital.drawerForm.templateShow",
        record,
      });
    },

    handleStatusChangeEvent(val) {
      tableService
        .statusChange(this.$constants.hospitalDisposableTemplateDelete, val)
        .then((res) => {
          if (res.success) {
            this.fetchData();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },
  },
};
</script>
