<template>
  <a-popover
    placement="bottomLeft"
    trigger="click"
    overlayClassName="overlay-popover-class"
  >
    <template #content>
      <a-textarea
        placeholder="Enter here..."
        v-model:value="textWritable"
        :auto-size="{ minRows: 5 }"
      />
    </template>
    <div class="notes">
      <a-tooltip v-bind="!!text ? {} : { visible: false }" placement="topLeft">
        <template #title>
          <span>{{ text }}</span>
        </template>
        {{ !!text ? text : "-" }}
      </a-tooltip>
    </div>
  </a-popover>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { commonService } from "@/services";
import { constants } from "@/helper/constants";

const props = defineProps({
  text: String,
  recordKey: String,
  record: Object,
  isChild: Boolean,
});

const emit = defineEmits(["update:text"]);

const textWritable = computed({
  get: () => props.text,
  set: (value) => emit("update:text", value),
});

function callUpdate() {
  const req = {
    column: props.recordKey,
    value: props.text,
    id: props.record.id,
  };

  if (!props.isChild) {
    req.main = true;
  }

  try {
    commonService.store(constants.monthlyBillingUpdate, req);
  } catch (e) {
    console.log({ e });
  }
}

// watch props.text and update after 1000ms. if already exist timer clear it.
const timer = ref(null);
watch(
  () => props.text,
  () => {
    if (timer.value) {
      clearTimeout(timer.value);
    }
    timer.value = setTimeout(() => {
      callUpdate();
    }, 1000);
  }
);

// const textSpan = ref();
// const resizeObserver = ref(null);
// const currentWidth = ref(0);
//
// onMounted(() => {
//   resizeObserver.value = new ResizeObserver((entries) => {
//     const entry = entries[0];
//     const width = entry.contentRect.width;
//     if (currentWidth.value !== width) {
//       currentWidth.value = width;
//       if (textSpan.value.offsetWidth > width) {
//         textSpan.value.style.width = `${width}px`;
//       }
//     }
//   });
//   resizeObserver.value.observe(textSpan.value);
// });
//
// watch(
//   () => props.text,
//   () => {
//     console.log("text changed", currentWidth.value);
//   }
// );
</script>

<style lang="less">
.notes {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 230px;
}
</style>
