<template>
  <a-form
    name="pre_procedure_form"
    ref="pre_procedure_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row type="flex">
      <a-col flex="35px">
        <a-tooltip title="Select all N/A option">
          <a-button
            type="link"
            size="small"
            class="p-sm-0"
            @click="selectAllOption('na_pre_checklist')"
          >
            N/A
          </a-button>
        </a-tooltip>
      </a-col>
      <a-col flex="35px">
        <a-tooltip title="Select all option">
          <a-button
            type="link"
            size="small"
            class="p-sm-0"
            @click="selectAllOption('pre_checklist')"
          >
            <CheckOutlined />
          </a-button>
        </a-tooltip>
      </a-col>
      <a-col flex="auto">
        <strong>SETUP CHECKLIST</strong>
      </a-col>
    </a-row>

    <template v-for="option in preChecklist" :key="option">
      <a-row type="flex">
        <a-col flex="35px">
          <a-checkbox
            v-model:checked="model.na_pre_checklist[option]"
            @click="(e) => optionChecked(e, option, 'na_pre_checklist')"
          />
        </a-col>
        <a-col flex="35px">
          <a-checkbox
            v-model:checked="model.pre_checklist[option]"
            @click="(e) => optionChecked(e, option, 'pre_checklist')"
          />
        </a-col>
        <a-col flex="calc(100% - 70px)">
          {{ option }}
        </a-col>
      </a-row>
    </template>

    <a-form-item
      ref="agreed"
      name="agreed"
      :rules="{
        required: true,
        message: 'This field is required',
        validator: $validation.boolRequired,
      }"
    >
      <a-checkbox class="mt-sm-1" v-model:checked="model.agreed">
        Verification of Chemotherapeutic Agent By Nurse and Perfusionist
      </a-checkbox>
    </a-form-item>

    <template v-if="model.agreed">
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item
            label="Nurse Name"
            name="nurse_name"
            :rules="{ required: true, message: 'This field is required' }"
          >
            <a-input v-model:value="model.nurse_name" />
          </a-form-item>

          <a-row type="flex" justify="center" class="mt-sm-2">
            <a-space direction="vertical">
              <vue-signature-pad
                :options="{ onEnd: saveNSign }"
                ref="nSignPad"
                :customStyle="$constants.signPadCustomCss"
              />

              <a-row type="flex" justify="center">
                <a-col>
                  <a-typography-paragraph>
                    Nurse signature indicates that the information reported is
                    complete and accurate.
                  </a-typography-paragraph>
                </a-col>
              </a-row>
              <a-row v-if="model.nurse_sign" justify="center">
                <a-space>
                  <a-button
                    htmlType="button"
                    type="primary"
                    danger
                    ghost
                    @click="clearNSign"
                  >
                    Clear
                  </a-button>
                </a-space>
              </a-row>
            </a-space>
          </a-row>
        </a-col>

        <a-col :span="12">
          <a-form-item
            label="Perfusionist Name"
            name="perfusionist_name"
            :rules="{ required: true, message: 'This field is required' }"
          >
            <a-input v-model:value="model.perfusionist_name" />
          </a-form-item>

          <a-row type="flex" justify="center" class="mt-sm-2">
            <a-space direction="vertical">
              <vue-signature-pad
                :options="{ onEnd: savePSign }"
                ref="pSignPad"
                :customStyle="$constants.signPadCustomCss"
              />

              <a-row type="flex" justify="center">
                <a-col>
                  <a-typography-paragraph>
                    Perfusionist signature indicates that the information
                    reported is complete and accurate.
                  </a-typography-paragraph>
                </a-col>
              </a-row>
              <a-row v-if="model.perfusionist_sign" justify="center">
                <a-space>
                  <a-button
                    htmlType="button"
                    type="primary"
                    danger
                    ghost
                    @click="clearPSign"
                  >
                    Clear
                  </a-button>
                </a-space>
              </a-row>
            </a-space>
          </a-row>
        </a-col>
      </a-row>
    </template>

    <modal-buttons name="Save" />
  </a-form>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  components: { CheckOutlined },

  data() {
    return {
      model: {
        uuid: null,
        pre_checklist: [],
        na_pre_checklist: [],
        agreed: false,
        nurse_name: "",
        nurse_sign: null,
        perfusionist_name: "",
        perfusionist_sign: null,
        submitted_at: null,
      },
    };
  },

  computed: {
    ...mapGetters("modal", ["record"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    preChecklist() {
      return this.$constants.hipecPreProcedureChecklist;
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      let oldCheckedOption =
        this.record?.live_chart?.hipec?.pre_checklist?.checked ?? [];
      let oldNotApplicableOption =
        this.record?.live_chart?.hipec?.pre_checklist?.not_applicable ?? [];
      let checklist = {};
      let not_applicable = {};
      this.preChecklist.forEach((e) => {
        checklist[e] = oldCheckedOption.includes(e) ? true : false;
        not_applicable[e] = oldNotApplicableOption.includes(e) ? true : false;
      });

      this.model.pre_checklist = checklist;
      this.model.na_pre_checklist = not_applicable;
      this.model.nurse_name =
        this.record?.live_chart?.hipec?.pre_checklist?.nurse_name ?? "";
      this.model.perfusionist_name =
        this.record?.live_chart?.hipec?.pre_checklist?.perfusionist_name ?? "";
      this.model.nurse_sign =
        this.record?.live_chart?.hipec?.pre_checklist?.nurse_sign ?? null;
      this.model.perfusionist_sign =
        this.record?.live_chart?.hipec?.pre_checklist?.perfusionist_sign ??
        null;
      this.model.submitted_at =
        this.record?.live_chart?.hipec?.pre_checklist?.submitted_at ?? null;
    },

    onSubmit() {
      if (
        this.model.nurse_sign == null ||
        this.model.perfusionist_sign == null
      ) {
        this.$message.error(
          "Nurse and Perfusionist Signature must be required."
        );
        return;
      }

      let newPreChecklist = [];
      for (const [key, value] of Object.entries(this.model.pre_checklist)) {
        if (value) {
          newPreChecklist.push(key);
        }
      }
      let newnaPreChecklist = [];
      for (const [key, value] of Object.entries(this.model.na_pre_checklist)) {
        if (value) {
          newnaPreChecklist.push(key);
        }
      }

      let data = {
        uuid: this.record.uuid,
        pre_checklist: newPreChecklist,
        na_pre_checklist: newnaPreChecklist,
        nurse_name: this.model.nurse_name,
        nurse_sign: this.model.nurse_sign,
        perfusionist_name: this.model.perfusionist_name,
        perfusionist_sign: this.model.perfusionist_sign,
        submitted_at:
          this.model.submitted_at ??
          moment().tz(this.timezone).format("MM-DD-YYYY HH:mm:ss"),
      };

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreHIPECPreChecklistUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },

    clearNSign() {
      this.model.nurse_sign = null;
      this.$refs.nSignPad.clearSignature();
    },

    saveNSign() {
      const { isEmpty, data } = this.$refs.nSignPad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.nurse_sign = data;
      return Promise.resolve();
    },

    clearPSign() {
      this.model.perfusionist_sign = null;
      this.$refs.pSignPad.clearSignature();
    },

    savePSign() {
      const { isEmpty, data } = this.$refs.pSignPad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.perfusionist_sign = data;
      return Promise.resolve();
    },

    optionChecked(e, option, key) {
      if (e.target.checked) {
        if (key == "na_pre_checklist") {
          this.model.pre_checklist[option] = false;
        } else {
          this.model.na_pre_checklist[option] = false;
        }
      }
    },

    selectAllOption(key) {
      let allChecked = this.preChecklist.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      this.preChecklist.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key = key == "na_pre_checklist" ? "pre_checklist" : "na_pre_checklist";
      this.preChecklist.forEach((option) => (this.model[key][option] = false));
    },
  },

  watch: {
    "model.agreed": function (curr, pre) {
      if (curr) {
        setTimeout(() => {
          this.$refs.nSignPad.fromDataURL(this.model.nurse_sign);
          this.$refs.pSignPad.fromDataURL(this.model.perfusionist_sign);
        }, 500);
      }
    },
  },
};
</script>
