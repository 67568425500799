export default function profile({ to, next, store }) {
  if (store.getters["auth/isHospitalUser"]) {
    return next({
      name: "hospital-user-dashboard",
    });
  }
  if (store.getters["auth/isAdmin"]) {
    return next();
  }

  const profileStatus = store.getters["auth/profileStatus"];
  if (profileStatus === 0) {
    const { quickbooks_vendor_id, employment_type } =
      store.getters["auth/employee"] || {};
    const formI9 = store.getters["auth/formI9"];
    const formW9 = store.getters["auth/formW9"];
    const onboardingDocCounts = store.getters["auth/onboardingDocCounts"];

    if (!quickbooks_vendor_id) {
      document.title = "Healthcare | Onboarding";
      return next({ name: "onboarding" });
    }

    if (employment_type === 2 && (!formW9 || formW9.status === 0)) {
      document.title = "Healthcare | Onboarding";
      return next({ name: "onboarding" });
    }

    if (!formI9 || formI9.status === 0) {
      document.title = "Healthcare | Onboarding";
      return next({ name: "onboarding" });
    }

    if (onboardingDocCounts > 0) {
      document.title = "Healthcare | Onboarding";
      return next({ name: "onboarding" });
    }

    document.title = "Healthcare | Credentials Update";
    return next({ name: "credentials-update" });
  }
  return next();
}
