<template>
  <a-layout-content>
    <page-header>
      <template #title> Hospital Templates</template>

      <template #buttons>
        <a-button type="primary" @click="handleCreateNewHospitalTemplateEvent">
          <a-space><i class="ti ti-plus ti-lg"></i> Add Hospital Template</a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-tooltip title="Show">
            <a-button
              type="link"
              size="small"
              class="primary-color"
              @click="showRecord(record)"
            >
              <i class="ti ti-eye ti-lg"></i>
            </a-button>
          </a-tooltip>

          <template v-if="record.status == 1">
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                @click="handleEditHospitalTemplateEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this hospital template?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure you want to restore this hospital template?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
              <a-tooltip title="Restore">
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from 'vuex';
import datatableMixins from '../../mixins/datatableMixins';

export default {
  mixins: [datatableMixins],

  data () {
    return {
      datatableUrl: this.$constants.hospitalTemplatesDatatableUrl,
      statusChangeUrl: this.$constants.hospitalTemplatesChangeStatusUrl,
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          sorter: true,
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          slots: { customRender: 'action' },
        },
      ],
      activeTabKey: this.$route.query?.status ?? '1',
      tabList: [
        {
          key: '1',
          counts: 0,
          text: 'Active',
          status: 1,
          slots: { tab: 'customRender' },
        },
        {
          key: '0',
          counts: 0,
          text: 'Inactive',
          status: 0,
          slots: { tab: 'customRender' },
        },
      ],
      defaultFilterParams: {
        sortField: this.$route.query?.sortField ?? 'name',
        sortOrder: this.$route.query?.sortOrder ?? 'ascend',
      },
    };
  },

  methods: {
    ...mapActions('drawer', ['open']),

    handleEditHospitalTemplateEvent (record) {
      this.open({
        title: 'Edit Hospital Template',
        path: 'hospitalTemplates.store',
        callback: this.refresh,
        record: record,
      });
    },

    handleCreateNewHospitalTemplateEvent () {
      this.open({
        title: 'Add New Hospital Template',
        path: 'hospitalTemplates.store',
        callback: this.refresh,
      });
    },

    showRecord (record) {
      this.open({
        title: 'Hospital Template Details',
        path: 'hospitalTemplates.show',
        callback: this.refresh,
        record: record,
      });
    },
  },
};
</script>
