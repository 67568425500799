<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Time Tracking </template>

        <template #buttons>
          <!--  -->
        </template>
      </page-header>

      <a-card
        :tabList="tabList"
        :activeTabKey="activeTabKey"
        @tabChange="(key) => (activeTabKey = key)"
        size="small"
      >
        <template #customRender="item">
          {{ item.text }} ({{ item.counts }})
        </template>

        <a-row class="mb-sm-1" justify="space-between">
          <div>
            <!-- <div v-if="activeTabKey == 1" style="display: flex">
              <a-select
                v-model:value="bulkActionValue"
                placeholder="Select any one"
                style="width: 150px; margin-right: 20px"
              >
                <template v-if="activeTabKey == 1">
                  <a-select-option value="2"> Approve </a-select-option>
                  <a-select-option value="3"> Reject </a-select-option>
                </template>
              </a-select>

              <a-button
                html-type="button"
                type="primary"
                :disabled="
                  this.bulkActionValue == null ||
                  this.selectedRowKeys.length == 0
                "
                @click="bulkAction"
              >
                Submit
              </a-button>
            </div> -->
          </div>

          <div>
            <a-tooltip>
              <template #title>Export PDF</template>
              <a-button
                type="link"
                @click="handleExportEvent('pdf')"
                style="color: #f40f02"
                :loading="datatableLoading"
              >
                <template #icon>
                  <i class="far fa-file-pdf" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>Export Excel</template>
              <a-button
                type="link"
                @click="handleExportEvent('excel')"
                style="color: #1d6f42"
                :loading="datatableLoading"
              >
                <template #icon>
                  <i class="far fa-file-excel" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>
          </div>
        </a-row>

        <!-- :row-selection="rowSelection" -->
        <a-table
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="datatableLoading"
          @change="handleTableChange"
          @refresh="refresh"
          size="small"
          :scroll="scroll"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #employment_type="{ record }">
            <a-tag
              :color="
                $comman.getValueFromObject(
                  record.employee,
                  'employment_type',
                  $constants.employment_types,
                  'color'
                )
              "
            >
              {{
                $comman.getValueFromObject(
                  record.employee,
                  "employment_type",
                  $constants.employment_types
                )
              }}
            </a-tag>
          </template>

          <template #action="{ record }">
            <a-button
              v-if="$comman.access('view-employee-time-track')"
              type="link"
              size="small"
              class="pl-sm-0 primary-color"
              @click="handleShowTimeTrackingEvent(record)"
            >
              <i class="ti ti-eye ti-lg"></i>
            </a-button>

            <template v-if="record.status == 1">
              <router-link
                :to="{
                  name: 'admin-update-employee-time-track',
                  params: { id: record.id },
                }"
              >
                <a-button
                  v-if="$comman.access('update-employee-time-track')"
                  type="link"
                  size="small"
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </router-link>
            </template>
          </template>
        </a-table>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminEmployeeTimeTrackDatatableUrl,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          defaultFilteredValue: this.$route.query?.employee_full_name
            ? [this.$route.query.employee_full_name]
            : undefined,
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query.hospital_name]
            : undefined,
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types,
          defaultFilteredValue: this.$route.query?.employment_type
            ? [Number(this.$route.query.employment_type)]
            : undefined,
          filterMultiple: false,
          slots: {
            customRender: "employment_type",
          },
        },
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          defaultFilteredValue: this.$route.query?.shift_type_name
            ? [this.$route.query.shift_type_name]
            : undefined,
          customRender: ({ record }) => record.shift_type?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
        },
        {
          title: "Total Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        {
          title: " Total Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          customRender: ({ record }) =>
            this.$comman.withCurrency(
              parseFloat(
                record.payments.reduce(
                  (pre, curr) => pre + curr.total_amount,
                  0
                )
              ).toFixed(2)
            ),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.timeTrackingStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        employee_full_name: this.$route.query?.employee_full_name
          ? [this.$route.query?.employee_full_name]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        employment_type: this.$route.query?.employment_type
          ? [this.$route.query?.employment_type]
          : undefined,
        shift_type_name: this.$route.query?.shift_type_name
          ? [this.$route.query?.shift_type_name]
          : undefined,
        total_time: this.$route.query?.total_time
          ? [this.$route.query?.total_time]
          : undefined,
        total_on_call_time: this.$route.query?.total_on_call_time
          ? [this.$route.query?.total_on_call_time]
          : undefined,
      },

      selectedRowKeys: [],
      bulkActionValue: null,
      bulkActionUrl: this.$constants.adminEmployeeTimeTrackBulkActionUrl,
      pdfExportUrl: this.$constants.adminEmployeeTimeTrackExportPdfUrl,
      excelExportUrl: this.$constants.adminEmployeeTimeTrackExportExcelUrl,
    };
  },

  computed: {
    rowSelection() {
      return this.activeTabKey == 1
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowTimeTrackingEvent(record) {
      this.open({
        title: "Show Time Tracking Details",
        path: "admin.employeeTimeTrack.show",
        callback: this.refresh,
        record: record,
      });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    handleExportEvent(type) {
      let url = this.pdfExportUrl;
      let fileName = "Employee Time Tracking.pdf";
      if (type == "excel") {
        url = this.excelExportUrl;
        fileName = "Employee Time Tracking.xlsx";
      }

      var params = {
        ...this.filterParams,
        status: this.activeTabKey,
      };

      this.datatableLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },

    bulkAction() {
      this.datatableLoading = true;
      commonService
        .store(this.bulkActionUrl, {
          ids: this.selectedRowKeys,
          status: this.bulkActionValue,
        })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
