<template>
  <div>
    <a-layout-content>
      <a-page-header
        class="page-header"
        title="Show Onboarding Document"
        style="margin-bottom: 24px; background-color: #fff"
        @back="() => $router.go(-1)"
      >
        <template #extra>
          <a-button
            type="primary"
            @click="previewPDF(record)"
            :loading="record?.previewLoader"
          >
            <a-space> <i class="ti ti-file-text ti-lg"></i> Preview </a-space>
          </a-button>
        </template>
      </a-page-header>

      <a-card :loading="loading" size="small">
        <a-descriptions
          :column="1"
          size="small"
          bordered
          :labelStyle="{ width: '150px' }"
        >
          <a-descriptions-item label="Name">
            {{ record.name }}
          </a-descriptions-item>
          <a-descriptions-item label="Type">
            {{
              $comman.getValueFromObject(
                record,
                "type",
                $constants.onboardingDocTypes
              )
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Does this apply to all positions?">
            {{ record.is_for_all_position ? "Yes" : "No" }}
          </a-descriptions-item>
          <a-descriptions-item label="Acknowledgement/Signature Message">
            {{ record.acknowledgement_message ?? "-" }}
          </a-descriptions-item>
          <a-descriptions-item
            v-if="record.is_for_all_position == 0"
            label="Positions"
          >
            {{
              record?.employee_positions?.map((e) => e.name)?.join(", ") ?? ""
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Description">
            <div class="text-content" v-html="record.description ?? 'N/A'" />
          </a-descriptions-item>
          <a-descriptions-item
            label="Should this document be sent with the job offer letter?"
          >
            {{
              record.need_to_send_attachment_on_offer_letter_mail ? "Yes" : "No"
            }}
          </a-descriptions-item>
          <a-descriptions-item v-if="record.attachment_file" label="Attachment">
            <a-typography-link
              :href="record.attachment_file_path"
              target="_blank"
            >
              {{ record.attachment_file }}
            </a-typography-link>
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";

export default {
  data() {
    return {
      loading: true,
      record: null,
    };
  },

  computed: {
    //
  },

  mounted() {
    this.getOnboardingDocument();
  },

  methods: {
    getOnboardingDocument() {
      commonService
        .get(this.$constants.onboardingDocumentShowUrl, {
          id: this.$route.params.id,
        })
        .then((res) => {
          this.record = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.loading = false));
    },
    previewPDF(record) {
      record.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.onboardingDocumentPDFPreviewUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          delete record.previewLoader;
        });
    },
  },
};
</script>

<style scoped>
.text-content:deep p {
  margin-bottom: 0;
}
</style>
