<template>
  <a-card
    :title="cardTitle"
    :tab-list="this.tab_view ? tabList : []"
    :active-tab-key="active_tab"
    @tabChange="(key) => (active_tab = key)"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-row v-if="show_hide_columns" class="mb-sm-1" justify="space-between">
      <div>
        <div style="display: flex" v-if="bulkAccess">
          <a-select
            v-model:value="bulkActionValue"
            placeholder="Select any one"
            style="width: 150px; margin-right: 20px"
          >
            <template v-if="url == $constants.expenseDatatableUrl">
              <a-select-option v-if="['1', '3'].includes(active_tab)" value="2">
                Approve
              </a-select-option>
              <template v-if="['1', '2'].includes(active_tab)">
                <a-select-option value="3"> Reject </a-select-option>
              </template>
              <template v-if="['2'].includes(active_tab)">
                <a-select-option value="4"> Paid </a-select-option>
              </template>
            </template>

            <template v-else-if="url == $constants.paidTimeOffDatatableUrl">
              <a-select-option v-if="['1', '3'].includes(active_tab)" value="2">
                Approve
              </a-select-option>
              <a-select-option v-if="['1', '2'].includes(active_tab)" value="3">
                Reject
              </a-select-option>
            </template>

            <template v-else-if="url == $constants.calendarEventDatatableUrl">
              <a-select-option v-if="['1'].includes(active_tab)" value="0">
                Cancel
              </a-select-option>
            </template>

            <template v-else>
              <a-select-option v-if="active_tab == 1" value="0">
                Trashed
              </a-select-option>
              <a-select-option v-if="active_tab == 0" value="1">
                Restore
              </a-select-option>
              <a-select-option v-if="active_tab == 0" value="2">
                Parmenent Delete
              </a-select-option>
            </template>
          </a-select>

          <a-button
            html-type="button"
            type="primary"
            :disabled="
              this.bulkActionValue == null || this.selectedRowKeys.length == 0
            "
            @click="bulkAction"
          >
            Submit
          </a-button>
        </div>
      </div>

      <div>
        <a-tooltip v-if="exportPdf != null">
          <template #title>Export PDF</template>
          <a-button
            type="link"
            @click="handleExportEvent(exportPdf)"
            style="color: #f40f02"
            :loading="loading"
          >
            <template #icon>
              <i class="far fa-file-pdf" style="font-size: 18px"></i>
            </template>
          </a-button>
        </a-tooltip>

        <a-tooltip v-if="exportExcel != null">
          <template #title>Export Excel</template>
          <a-button
            type="link"
            @click="handleExportEvent(exportExcel)"
            style="color: #1d6f42"
            :loading="loading"
          >
            <template #icon>
              <i class="far fa-file-excel" style="font-size: 18px"></i>
            </template>
          </a-button>
        </a-tooltip>

        <a-dropdown v-model:visible="dropdown_visibility">
          <a class="ant-dropdown-link" @click.prevent>
            <a-button type="link" size="large">
              <i class="fa-x2 fas fa-cog"></i>
            </a-button>
          </a>
          <template #overlay>
            <a-menu class="table-column-visible">
              <a-menu-item>
                <a-checkbox
                  v-model:checked="all_column_visible"
                  :indeterminate="indeterminate"
                  @change="all_column_visible_checked"
                >
                  Column Display
                </a-checkbox>
              </a-menu-item>
              <a-divider type="horizontal" class="my-sm-0"></a-divider>
              <a-checkbox-group
                v-model:value="visible_column_list"
                :options="column_list"
              />
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </a-row>

    <a-table
      :row-selection="visible_columns.length > 0 ? rowSelection : null"
      :columns="visible_columns"
      :rowKey="(record, index) => record.id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>

      <template
        #dateRangeDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-range-picker
            :value="selectedKeys[0]"
            :format="$constants.datepickerFormat"
            style="width: 250px; margin-bottom: 8px; display: block"
            :allow-clear="false"
            :ranges="ranges"
            @change="(e) => setSelectedKeys(e ? [e] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>

      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template v-slot:[`${field.key}`]="value" v-for="field in columns">
        <template v-if="field.key == 'expandedRowRender'">
          <slot :name="field.key" v-bind="value"></slot>
        </template>

        <template v-else>
          <slot :name="field.key" v-bind="value.record">
            <div v-if="field.html" :key="field.key" v-html="value"></div>
            <div :key="field.key" v-else></div>
          </slot>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { companyService, tableService } from "@/services";
import { SearchOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { commonService } from "../services";

export default {
  name: "CustomTable",

  components: {
    SearchOutlined,
  },

  emits: ["handleTabChangeEvent", "refreshCallback"],

  props: {
    show_hide_columns: { default: true },
    tab_view: { default: true },
    bulkAccess: { default: false },
    columns: { required: true },
    url: { required: true },
    tabList: {
      type: Array,
      default: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Trashed",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
    },
    active_tab: {
      type: String,
      default: "1",
    },
    params: { required: true },
    pageSize: { default: 10 },
    defaultRowSelection: {
      default: false,
    },
    scroll: {
      default: function () {
        return { x: 0, y: 0 };
      },
    },
    displaySearchCard: {
      default: true,
    },
    cardTitle: {
      default: null,
    },
    exportPdf: {
      default: null,
      type: Object,
    },
    exportExcel: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      data: [],
      visible_columns: [],
      column_list: [],
      visible_column_list: [],
      all_column_visible: true,
      dropdown_visibility: false,
      indeterminate: false,
      loading: false,
      search_input: "",
      pagination: {
        // position: 'both',
        size: "normal",
        pageSize: this.pageSize,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },
      defaultParams: { sortField: "created_at", sortOrder: "descend" },
      selectedRowKeys: [],
      bulkActionValue: null,
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "This Month": [moment(), moment().endOf("month")],
      },
    };
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    rowSelection() {
      if (this.defaultRowSelection) {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
          getCheckboxProps: (record) => this.getCheckboxProps(record),
        };
      } else {
        return null;
      }
    },
  },

  created() {
    this.refresh();
    this.visible_columns = JSON.parse(JSON.stringify(this.columns));
    this.columns.forEach((col) => {
      this.column_list.push(col.title);
    });
    this.visible_column_list = this.column_list;
  },

  watch: {
    // params: {
    //   handler: function(newValue) {
    //     this.refresh();
    //   },
    //   deep: true,
    // },
    pageSize() {
      const pagination = { ...this.pagination };
      pagination.pageSize = parseInt(this.pageSize);
      this.pagination = pagination;
      this.refresh();
    },
    active_tab() {
      this.selectedRowKeys = [];
      this.bulkActionValue = null;
      const pagination = { ...this.pagination };
      pagination.current = 1;
      this.pagination = pagination;
      this.refresh();
      this.$emit("handleTabChangeEvent", this.active_tab);
    },
    visible_column_list(new_val, old_val) {
      this.indeterminate =
        !!new_val.length && new_val.length < this.column_list.length;
      this.all_column_visible = new_val.length === this.column_list.length;
      this.visible_columns = [];
      this.columns.forEach((col, i) => {
        if (new_val.indexOf(col.title) != -1) {
          this.visible_columns.splice(i, 0, col);
        }
      });
    },
  },

  methods: {
    refresh() {
      this.loading = true;

      Object.keys(this.params).forEach((v) => {
        this.defaultParams[v] = this.params[v];
        if (this.url == this.$constants.calendarEventDatatableUrl) {
          if (v != "hospital_id") {
            delete this.params[v];
          }
        } else {
          delete this.params[v];
        }
      });

      // Merge params
      var params = {
        ...this.params,
        ...this.defaultParams,
        ...this.pagination,
        status: this.active_tab,
        search_text: this.search_input,
        page: this.pagination.current,
      };

      tableService
        .get(params, this.url, 10)
        .then((response) => {
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          this.pagination = pagination;
          this.data = response.data.data;
          this.loading = false;
          if (response.counts) {
            this.status_count(response.counts);
          }
          this.$emit("refreshCallback");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination;
      this.defaultParams = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        status: this.active_tab,
        ...filters,
      };
      this.refresh();
    },

    handleNameSearch(selectedKeys, confirm) {
      confirm();
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
    },

    handleReset(clearFilters) {
      clearFilters();
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    status_count(counts) {
      if (this.tabList) {
        this.tabList.forEach((v, i) => {
          v.counts = counts[v.status] ?? 0;
        });
      }
    },

    bulkAction() {
      const url = this.url + "/bulk-action";
      const val = {
        ids: this.selectedRowKeys,
        status: this.bulkActionValue,
      };
      this.loading = true;
      companyService
        .store(url, val)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    all_column_visible_checked(e) {
      this.visible_column_list = e.target.checked ? this.column_list : [];
      this.visible_columns = e.target.checked
        ? JSON.parse(JSON.stringify(this.columns))
        : [];
      this.indeterminate = false;
    },

    getCheckboxProps(record) {
      if (this.url == this.$constants.productCategoryDatatableUrl) {
        return {
          disabled: record.name === "Uncategorized",
          name: record.name,
        };
      } else if (this.url == this.$constants.roleDatatableUrl) {
        return {
          disabled: record.name === "Admin",
          name: record.name,
        };
      } else if (this.url == "api/employees") {
        return {
          disabled: record.id === 1,
          name: record.name,
        };
      } else if (this.url == this.$constants.uomDatatableUrl) {
        return {
          disabled: record.id === 1,
          name: record.name,
        };
      } else {
        return {
          name: record.name,
        };
      }
    },

    handleExportEvent(obj) {
      const url = `${this.url}/${obj.params}`;

      var params = {
        ...this.params,
        ...this.defaultParams,
        ...this.pagination,
        status: this.active_tab,
      };

      this.loading = true;
      commonService
        .downloadFile(url, params, obj.fileName)
        .then((res) => {
          this.$message.success(res);
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
  },
};
</script>
