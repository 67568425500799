<template>
  <a-layout-content>
    <page-header>
      <template #title> Employee Handbooks </template>

      <template #buttons>
        <a-button type="primary" @click="handleCreateNewMasterHandbookEvent">
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add Employee Handbook
          </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #full_file_path="{ record }">
          <a :href="record.full_file_path" target="_blank">
            {{ record.file_name }}
          </a>
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <a-tooltip title="Edit">
            <a-button
              type="link"
              size="small"
              class="pl-sm-0"
              @click="handleEditMasterHandbookEvent(record)"
            >
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>

            <a-popconfirm
              title="Are you sure delete this master handbook?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
            <a-tooltip title="Delete">
              <a-button size="small" type="link" danger>
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure restored this master handbook?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
            <a-tooltip title="Restore">
              <a-button size="small" type="link" class="pl-sm-0">
                <i class="ti ti-rotate ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.masterHandbookDatatableUrl,
      statusChangeUrl: this.$constants.masterHandbookChangeStatusUrl,
      columns: [
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          sorter: true,
        },
        {
          title: "File",
          dataIndex: "full_file_path",
          key: "full_file_path",
          slots: { customRender: "full_file_path" },
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleEditMasterHandbookEvent(record) {
      this.open({
        title: "Edit Master Handbook",
        path: "masterHandbook.store",
        callback: this.refresh,
        record: record,
      });
    },

    handleCreateNewMasterHandbookEvent() {
      this.open({
        title: "Add New Master Handbook",
        path: "masterHandbook.store",
        callback: this.refresh,
      });
    },
  },
};
</script>
