<template>
  <a-form
    name="live_charting_medication_form"
    ref="live_charting_medication_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="drug"
          label="Drug"
          name="drug"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-select
            v-model:value="model.drug"
            style="width: 100%"
            show-search
            :options="
              $constants.medicationDrugs.filter(
                (e) => !['Normal Saline (0.9%)', 'Plasmalyte'].includes(e.label)
              )
            "
            @change="medicationDrugChange"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="Dose">
          <a-input-group compact>
            <a-form-item
              style="width: 75%"
              ref="dose"
              name="dose"
              :rules="[
                {
                  required: true,
                  message: 'This field is required!',
                },
                {
                  message: 'This field is required!',
                  type: 'number',
                  validator: $validation.decimal,
                  trigger: $constants.ruleTrigger,
                },
              ]"
            >
              <a-input-number
                type="number"
                :step="0.01"
                v-model:value="model.dose"
              />
            </a-form-item>
            <a-form-item style="width: 25%" ref="doseUnit" name="doseUnit">
              <a-input v-model:value="model.doseUnit" disabled />
            </a-form-item>
          </a-input-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="administered_by"
          label="Administered By"
          name="administered_by"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-radio-group v-model:value="model.administered_by">
            <a-radio value="Perfusion (CPB-Prime)">
              Perfusion (CPB-Prime)
            </a-radio>
            <a-radio value="Perfusion">Perfusion</a-radio>
            <a-radio value="Anesthesia">Anesthesia</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="lot_no"
          label="Lot No#"
          name="lot_no"
          :rules="{
            message: 'This field is required.',
          }"
        >
          <a-input v-model:value="model.lot_no" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="expiry_date"
          label="Expiry Date"
          name="expiry_date"
          :rules="{
            type: 'date',
            message: 'This field is required.',
          }"
        >
          <a-date-picker
            v-model:value="model.expiry_date"
            :placeholder="$constants.datePickerPlaceholder"
            :format="$constants.datepickerFormat"
            @change="$comman.pastDateWarningModal(model.expiry_date)"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Medication",
        drug: undefined,
        dose: null,
        doseUnit: undefined,
        administered_by: "",
        lot_no: null,
        expiry_date: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.drug = this.extra.record.drug;
      this.model.dose = this.extra.record.dose;
      this.model.doseUnit = this.extra.record.doseUnit;
      this.model.administered_by = this.extra.record.administered_by;
      this.model.lot_no = this.extra.record.lot_no;
      this.model.expiry_date = this.extra.record.expiry_date
        ? moment(this.extra.record.expiry_date, "MM-DD-YYYY")
        : null;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    medicationDrugChange(_, obj) {
      this.model.doseUnit = obj.uom;
    },

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.expiry_date = this.model.expiry_date
        ? this.$customDate.mdy(this.model.expiry_date)
        : null;
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreNRPRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
