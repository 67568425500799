<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          Change Password
        </template>
      </page-header>

      <a-card>
        <a-form
          name="change_password_form"
          ref="change_password_form"
          :model="formState"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit"
        >
          <a-row :gutter="[20, 0]">
            <a-col :span="24">
              <a-form-item ref="password" label="Password" name="password">
                <a-input-password
                  v-model:value="formState.password"
                  placeholder="Enter password here"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                ref="password_confirmation"
                label="Confirm Password"
                name="password_confirmation"
              >
                <a-input-password
                  v-model:value="formState.password_confirmation"
                  placeholder="Enter confirm password here"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-button type="primary" html-type="submit" :loading="loading">
                Update
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "../../services";

export default {
  name: "change_password",

  data() {
    return {
      loading: false,
      formState: {
        password: "",
        password_confirmation: "",
      },

      rules: {
        password: [
          {
            required: true,
            message: "password is required!",
            trigger: "change",
          },
          {
            validator: this.password_validation,
            trigger: "change",
          },
        ],
        password_confirmation: [
          {
            required: true,
            message: "confirmed password is required!",
            trigger: "change",
          },
          {
            validator: this.password_confirmation_validation,
            trigger: "change",
          },
        ],
      },
    };
  },

  methods: {
    onSubmit(val) {
      this.loading = true;
      commonService
        .store("api/employee/password-update", val)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.change_password_form.fields.forEach((v, i) => {
            if (err.errors[v.fieldName]) {
              v.validateState = "error";
              v.validateMessage = err.errors[v.fieldName]["0"];
            }
          });
          this.loading = false;
        });
    },

    password_validation(rule, value) {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/;
      if (value === "") {
        return Promise.reject("password is required!");
      } else if (value.length < 8) {
        return Promise.reject("password length must be more then 8 character!");
      } else if (!reg.test(value)) {
        return Promise.reject(
          "password must at least one uppercase letter, one lowercase letter, one number and one special character!"
        );
      } else {
        if (this.formState.password_confirmation !== "") {
          this.$refs.change_password_form.validateField(
            "password_confirmation"
          );
        }
        return Promise.resolve();
      }
    },

    password_confirmation_validation(rule, value) {
      if (value === "") {
        return Promise.reject("confirm password is required!");
      } else if (value !== this.formState.password) {
        return Promise.reject("confirm password don't match with password!");
      } else {
        return Promise.resolve();
      }
    },
  },
};
</script>
