<template>
  <a-card :loading="loading" title="Profile" size="small" class="employee-info">
    <a-descriptions
      size="small"
      :column="{ xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }"
      bordered
      :labelStyle="{ width: '200px' }"
    >
      <a-descriptions-item label="ADP AO Id">
        {{ employee.adp_ao_id ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="ADP Worker Id">
        {{ employee.adp_worker_id ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="ADP Job Title">
        {{ employee.adp_job_title ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="ADP Job Code">
        {{ employee.adp_job_code ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="ADP Department Title">
        {{ employee.adp_department_title ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="ADP Department Code">
        {{ employee.adp_department_code ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="ADP Position Id">
        {{ employee.adp_position_id ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Full Name">
        {{ employee.full_name }}
      </a-descriptions-item>

      <a-descriptions-item label="Employee Code">
        {{ employee.employee_code }}
      </a-descriptions-item>

      <a-descriptions-item label="Company">
        {{ employee.company?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Profession Type">
        <a-tag
          :color="
            $comman.getValueFromObject(
              employee,
              'employee_type',
              $constants.employee_types,
              'color'
            )
          "
        >
          {{
            $comman.getValueFromObject(
              employee,
              "employee_type",
              $constants.employee_types
            )
          }}
        </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="Employment Type">
        <a-tag
          :color="
            $comman.getValueFromObject(
              employee,
              'employment_type',
              $constants.employment_types,
              'color'
            )
          "
        >
          {{
            $comman.getValueFromObject(
              employee,
              "employment_type",
              $constants.employment_types
            )
          }}
        </a-tag>
        <a-tag v-if="employee.employment_type == 1">
          <span class="text-medium">Gusto Code:</span>
          {{ employee.gusto_employee_code }}
        </a-tag>
        <a-tag v-if="employee.employment_type == 2">
          <span class="text-medium">DBA:</span> {{ employee.dba }}
        </a-tag>
        <a-tag v-if="employee.quickbooks_vendor_id">
          <span class="text-medium">QuickBooks Vendor Id:</span>
          {{ employee.quickbooks_vendor_id }}
        </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="Email">
        <a-space>
          <a-typography-link :href="`mailto:${employee.email}`">
            {{ employee.email }}
          </a-typography-link>

          <a-tooltip
            v-if="isAdmin && employee.status == 1"
            title="Edit Email Address"
          >
            <a-button size="small" type="link" @click="editEmailAddress">
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-space>
      </a-descriptions-item>

      <a-descriptions-item label="Mobile">
        <a-typography-link
          v-if="employee.contact_no"
          :href="`tel:${employee.contact_no}`"
        >
          {{ employee.contact_no }}
        </a-typography-link>
        <a-typography-text v-else> N/A </a-typography-text>
      </a-descriptions-item>

      <a-descriptions-item label="Gender">
        {{ employee.gender ?? "N/A" }}
      </a-descriptions-item>

      <!-- <a-descriptions-item label="Blood Group">
          {{ employee.blood_group ?? "N/A" }}
        </a-descriptions-item> -->

      <a-descriptions-item label="Birthdate">
        <a-space>
          {{ $customDate.ll(employee.birthdate) }}
          <a-tag>
            {{ moment().diff(moment(employee.birthdate), "years", false) }}
            Years
          </a-tag>
        </a-space>
      </a-descriptions-item>

      <a-descriptions-item label="Hire Date">
        {{ employee.hire_date ? $customDate.ll(employee.hire_date) : "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Position">
        {{ employee.position?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Address">
        {{ employee_address }}
      </a-descriptions-item>

      <a-descriptions-item label="Social Security Number">
        <template v-if="employee.social_security_attachment_url">
          <a-typography-link
            :href="employee.social_security_attachment_url"
            target="_blank"
          >
            {{ employee.social_security_no ?? "N/A" }}
          </a-typography-link>
        </template>
        <template v-else>
          {{ employee.social_security_no ?? "N/A" }}
        </template>
      </a-descriptions-item>

      <a-descriptions-item label="Driver's License">
        <template v-if="employee.license_attachment_url">
          <a-typography-link
            :href="employee.license_attachment_url"
            target="_blank"
            class="mr-sm-1"
          >
            {{ employee.license_no ?? "N/A" }}
          </a-typography-link>
        </template>
        <template v-else>
          <span class="mr-sm-1">
            {{ employee.license_no ?? "N/A" }}
          </span>
        </template>
        <a-tag class="mr-sm-1">{{ employee.license_state ?? "N/A" }}</a-tag>
        <a-tag>
          Expiration Date:
          {{
            employee.license_expiry_date
              ? $customDate.mdy(employee.license_expiry_date)
              : "N/A"
          }}
        </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="Emergency Contact">
        <span class="mr-sm-1">
          {{ employee.emergency_name ?? "N/A" }}
        </span>
        <a-tag class="mr-sm-1">
          {{ employee.emergency_relation ?? "N/A" }}
        </a-tag>
        <a-typography-link :href="`tel:${employee.emergency_contact}`">
          {{ employee.emergency_contact }}
        </a-typography-link>
      </a-descriptions-item>

      <!-- <a-descriptions-item label="Is associated with monthly billing?">
        {{ !!employee.is_associated_with_monthly_billing ? "Yes" : "No" }}
      </a-descriptions-item> -->

      <a-descriptions-item label="PTO Hours per Day">
        {{ employee.pto_hours_per_day ?? "N/A" }}
      </a-descriptions-item>

      <template v-if="employee.signature">
        <a-descriptions-item label="Signature">
          <a-typography-link
            :href="employee.signature_file_path"
            target="_blank"
            class="mr-sm-1"
          >
            {{
              employee.signature.split("/")[
                employee.signature.split("/").length - 1
              ]
            }}
          </a-typography-link>
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </a-card>

  <a-card
    v-if="isAdmin"
    :loading="loading"
    title="Compensations & Jobs"
    size="small"
    class="employee-job-compensation mt-sm-1"
  >
    <template #extra>
      <a-button type="primary" size="small" @click="handleStoreJobEvent">
        <a-space> <i class="ti ti-plus ti-lg"></i> Add New </a-space>
      </a-button>
    </template>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="jobLoading"
      size="small"
      :rowKey="(record, index) => record.id"
      :pagination="false"
    >
      <template #is_primary="{ record }">
        <a-tag v-if="record.is_primary == 1" color="green">Yes</a-tag>
        <a-tag v-if="record.is_primary == 0" color="red">No</a-tag>
      </template>

      <template #action="{ record }">
        <a-space :size="1" direction="horizontal">
          <a-button
            type="link"
            size="small"
            @click="handleUpdateJobEvent(record)"
          >
            <i class="ti ti-pencil ti-lg"></i>
          </a-button>

          <a-popconfirm
            title="Are you sure you want to delete this employee job?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteJobEvent(record)"
          >
            <a-button type="link" danger size="small">
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "employee-overview",
  props: {
    employee: {
      required: true,
    },
  },

  emits: ["updateEmployeeData"],

  data() {
    return {
      moment,
      data: [],
      jobLoading: true,
      email_change_notice: null,
      columns: [
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "Payment Unit",
          dataIndex: "payment_unit",
          key: "payment_unit",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "payment_unit",
              this.$constants.employeeJobPaymentUnit
            ),
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          customRender: ({ text }) =>
            text ? this.$comman.withCurrency(text) : "N/A",
        },
        {
          title: "Default Hours Per Pay Period",
          dataIndex: "hours_per_pay_period",
          key: "hours_per_pay_period",
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Overtime Pay Per Hour",
          dataIndex: "overtime_pay_per_hour",
          key: "overtime_pay_per_hour",
          customRender: ({ text }) =>
            text ? this.$comman.withCurrency(text) : "N/A",
        },
        {
          title: "Is Primary?",
          dataIndex: "is_primary",
          key: "is_primary",
          slots: {
            customRender: "is_primary",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),

    loading() {
      return this.employee == null;
    },

    employee_address() {
      if (this.employee) {
        let res = "";
        if (this.employee.street_address) {
          res += this.employee.street_address;
        }
        if (this.employee.city) {
          res += `${res ? ", " : ""} ${this.employee.city}`;
        }
        if (this.employee.state) {
          res += `${res ? ", " : ""} ${this.employee.state}`;
        }
        if (this.employee.zipcode) {
          res += `${res ? ", " : ""} ${this.employee.zipcode}`;
        }
        return res.length > 0 ? res : "N/A";
      }
    },
  },

  mounted() {
    this.getEmployeeJobs();
    this.fetchSetting();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getEmployeeJobs() {
      commonService
        .get(this.$constants.getEmployeeJobs, {
          employee_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.success) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.jobLoading = false));
    },

    handleStoreJobEvent() {
      this.open({
        title: "Create New Job",
        path: "employee.drawerForm.storeJob",
        extra: { employee: this.employee, jobs: this.data },
        callback: this.getEmployeeJobs,
      });
    },

    handleUpdateJobEvent(record) {
      this.open({
        title: "Edit Job",
        path: "employee.drawerForm.storeJob",
        record,
        extra: { employee: this.employee, jobs: this.data },
        callback: this.getEmployeeJobs,
      });
    },

    handleDeleteJobEvent(record) {
      this.jobLoading = true;
      commonService
        .store(this.$constants.employeeJobDeleteUrl, { id: record.id })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.getEmployeeJobs());
    },

    reloadPage() {
      setTimeout(location.reload(), 500);
    },

    fetchSetting() {
      commonService
        .store(this.$constants.getSettingUrl, { key: "email_change_notice" })
        .then((res) => {
          this.email_change_notice = res?.data?.email_change_notice ?? null;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    editEmailAddress() {
      this.open({
        title: "Edit Email Address",
        path: "employee.drawerForm.editEmail",
        record: this.employee,
        extra: this.email_change_notice,
        callback: this.updateEmployeeData,
      });
    },

    updateEmployeeData(data) {
      this.$emit("updateEmployeeData", data);
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.getEmployeeJobs();
      }
    },
  },
};
</script>

<style scoped>
.employee-info div.ant-typography,
.employee-info .ant-typography p {
  margin-bottom: 0;
}
</style>
