<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>Calendar Events</template>
        <template #buttons>
          <router-link
            v-if="$comman.access('create-calendar-event')"
            :to="{ name: 'create-calendar-event' }"
          >
            <a-button type="primary">
              <i class="fas fa-plus mr-sm-1"></i>
              Add New Event
            </a-button>
          </router-link>
        </template>
      </page-header>
      <a-card>
        <a-calendar v-model:value="value" @panelChange="onPanelChange">
          <template #dateCellRender="{ current: value }">
            <ul class="events">
              <li v-for="item in getListData(value)" :key="item.title">
                <a-badge
                  @click="
                    open({
                      title: 'Event Details',
                      path: 'calendarEvent.show',
                      callback: refreshEvent,
                      record: item,
                      extra: { employees },
                    })
                  "
                  :color="item.color"
                  :text="item.title"
                />
              </li>
            </ul>
          </template>
        </a-calendar>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import moment from "moment";
import { commonService } from "../../services";
import { constants } from "../../helper/constants";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      value: moment(),
      events: [],
      employees: [],
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    loadData(value = this.value) {
      this.loading = true;
      Promise.all([this.loadEvents(value), this.$comman.getEmployees(), false])
        .then((val) => {
          this.loading = false;
          [this.events, this.employees] = val;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    loadEvents(value = this.value) {
      return commonService
        .get(this.$constants.calendarEventsUrl, { date: value })
        .then((res) => res.data);
    },

    refreshEvent(value = this.value) {
      this.loading = true;
      this.loadEvents(value).then((data) => {
        this.events = data;
        this.loading = false;
      });
    },

    onPanelChange(value, mode) {
      this.loading = true;
      this.value = value;
      this.loadEvents(value).then((res) => {
        this.events = res;
        this.loading = false;
      });
    },

    getListData(date) {
      let data = this.events.filter(
        (v) => moment(v.date).format("YYYYMMDD") == date.format("YYYYMMDD")
      );
      data = data.map((v) => {
        v.color = constants.calendarEventStatus.filter(
          (status) => status.value == v.status
        )[0].color;
        return v;
      });
      return data;
    },
  },
};
</script>

<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
