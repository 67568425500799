<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Human Resource Incident Report Details"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <template v-if="record.status != 0">
          <a-button :loading="downloading" type="primary" @click="downloadPdf">
            <a-space> <i class="ti ti-download ti-lg"></i> Download </a-space>
          </a-button>
        </template>
      </template>
    </a-page-header>

    <a-card :loading="cardLoader" size="small">
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Reported By">
          {{ record.employee?.full_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Date of Report">
          {{ $customDate.mdy(record.date_of_report) }}
        </a-descriptions-item>
        <a-descriptions-item label="Incident Report Number">
          {{ record.incident_no }}
        </a-descriptions-item>
        <a-descriptions-item label="Date of Incident">
          {{ $customDate.mdy(record.date_of_incident) }}
        </a-descriptions-item>
        <a-descriptions-item label="Type">
          {{ record.incident_type }}
        </a-descriptions-item>
        <a-descriptions-item label="Facility">
          {{ record.hospital?.name ?? "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="Specific area of location" :span="2">
          {{ record.incident_location ?? "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="Description" :span="2">
          {{ record.description }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider orientation="left">
        Name / Role / Contact of Parties Involved
      </a-divider>
      <a-list
        bordered
        size="small"
        :data-source="record?.contacts?.filter((e) => e.type == 1) ?? []"
      >
        <template #renderItem="{ item }">
          <a-list-item>{{ item.name }}</a-list-item>
        </template>
      </a-list>

      <a-divider orientation="left">
        Name / Role / Contact of Witnesses
      </a-divider>
      <a-list
        bordered
        size="small"
        :data-source="record?.contacts?.filter((e) => e.type == 2) ?? []"
      >
        <template #renderItem="{ item }">
          <a-list-item>{{ item.name }}</a-list-item>
        </template>
      </a-list>

      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
        class="mt-sm-2"
      >
        <a-descriptions-item
          label="Is Filed Police Report?"
          :span="record.is_filed_police_report == 1 ? 1 : 2"
        >
          {{ record.is_filed_police_report == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <template v-if="record.is_filed_police_report == 1">
          <a-descriptions-item label="Precinct">
            {{ record.precinct ?? "-" }}
          </a-descriptions-item>

          <a-descriptions-item label="Reporting Officer">
            {{ record.reporting_officer ?? "-" }}
          </a-descriptions-item>

          <a-descriptions-item label="Phone">
            {{ record.reporting_officer_phone ?? "-" }}
          </a-descriptions-item>
        </template>

        <a-descriptions-item label="Signature" :span="2">
          <img :width="200" :src="record.sign" />
        </a-descriptions-item>

        <template v-if="record.status == 2">
          <a-descriptions-item label="Follow-up Action" :span="2">
            {{ record.followup }}
          </a-descriptions-item>
          <a-descriptions-item label="Follow-up By" :span="2">
            {{ record.followup_by_employee?.full_name }}
          </a-descriptions-item>
          <a-descriptions-item label="Signature" :span="2">
            <img :width="200" :src="record.sign_by_followup" />
          </a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>

    <a-card
      v-if="record.status == 1 && $comman.hasRole(1)"
      class="mt-sm-2"
      :loading="cardLoader"
      size="small"
    >
      <a-form :model="model" layout="vertical" @finish="handleFinish">
        <a-row :gutter="[20, 0]">
          <a-col :span="24">
            <a-form-item
              ref="followup"
              label="Follow-up Action"
              name="followup"
              :rules="{
                required: true,
                message: 'This field is required!',
                trigger: $constants.ruleTrigger,
              }"
            >
              <a-textarea
                v-model:value="model.followup"
                placeholder="Enter here..."
              />
            </a-form-item>
          </a-col>
        </a-row>

        <signature-box v-model:sign="model.sign_by_followup" />

        <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
          Add Follow-up
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapGetters } from "vuex";
import backButton from "../../../components/backButton.vue";
import signatureBox from "../../../components/signatureBox.vue";
import { commonService } from "../../../services";

export default {
  components: { backButton, signatureBox },
  data() {
    return {
      cardLoader: false,
      downloading: false,
      submitBtnLoader: false,
      id: this.$route.params.id ?? 0,
      record: {},
      model: {
        id: this.$route.params.id,
        followup: "",
        sign_by_followup: null,
      },
    };
  },

  mounted() {
    this.fetchHrIncident();
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
  },

  methods: {
    fetchHrIncident() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.hrIncidentShowUrl, { id: this.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          this.record = res.data;
          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    handleFinish() {
      if (this.model.sign_by_followup == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      this.model.followup_by = this.employee.id;

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.hrIncidentStoreFollowupUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.fetchHrIncident();
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    downloadPdf() {
      this.downloading = true;
      commonService
        .downloadFile(
          this.$constants.hrIncidentDownloadPdfUrl,
          { id: this.record.id },
          `hr-incident.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>
