<template>
  <a-form
    name="video_form"
    ref="video_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-form-item
          ref="title"
          label="Title"
          name="title"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
          :validateStatus="validate('title', 'status')"
          :help="validate('title', 'msg')"
        >
          <a-input v-model:value="model.title" placeholder="Enter title here" />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          ref="video_url"
          label="Video URL"
          name="video_url"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
          :validateStatus="validate('video_url', 'status')"
          :help="validate('video_url', 'msg')"
        >
          <a-textarea
            v-model:value="model.video_url"
            placeholder="Enter youtube video url here"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons :name="submitButtonText" />
  </a-form>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters, useStore } from "vuex";
import { commonService } from "../../../services";
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { constants } from "../../../helper/constants";
import { message } from "ant-design-vue";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],

  components: {
    PlusOutlined,
  },

  emits: ["callback"],

  data() {
    return {
      model: {
        id: 0,
        title: null,
        video_url: null,
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.model.id = this.record.id ?? 0;
      this.model.title = this.record.title ?? null;
      this.model.video_url = this.record.video_url ?? null;
    },

    onSubmit() {
      this.loadingStart();
      commonService
        .store(constants.kbVideoStoreUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.errors = err.errors;
          }
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
