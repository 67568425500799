<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12" :xl="8">
      <a-form-item
        label="Date"
        name="date"
        :rules="[
          {
            required: true,
            type: 'date',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-date-picker
          v-model:value="model.date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :xl="8">
      <a-form-item
        label="Facility"
        name="hospital_id"
        :rules="[
          {
            required: true,
            type: 'number',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-select
          v-model:value="model.hospital_id"
          placeholder="Facility"
          show-search
          optionFilterProp="label"
          :disabled="uuid != null"
        >
          <a-select-option
            v-for="option in hospitals"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          >
            {{ option.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col v-if="showIsDisposableOnlyCaseCheckbox" :xs="24" :md="12" :xl="8">
      <a-form-item ref="is_disposable_only_case" name="is_disposable_only_case">
        <template #label>
          <p style="line-height: 31px; margin-bottom: 0"></p>
        </template>
        <a-checkbox
          v-model:checked="model.is_disposable_only_case"
          @change="handleDisposableOnlyCheckboxChange"
        >
          Is this submission for disposable only billing?
        </a-checkbox>
      </a-form-item>
    </a-col>

    <a-col v-if="model.is_disposable_only_case" :xs="24" :md="12" :lg="8">
      <a-form-item
        label="PO Number"
        name="po_number"
        :rules="{
          required: false,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
          transform: (value) => (value ? value.trim() : ''),
        }"
      >
        <a-input v-model:value="model.po_number" placeholder="PO Number" />
      </a-form-item>
    </a-col>

    <template v-if="!isDisposableOnlyCase">
      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item
          :label="mrNumber"
          name="mr_number"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
              transform: (value) => (value ? value.trim() : ''),
            },
          ]"
        >
          <a-input v-model:value="model.mr_number" :placeholder="mrNumber" />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item
          label="Account Number"
          name="account_number"
          :rules="{
            required: !$comman.isCanReceiveOrgansHospital(hospital),
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
            transform: (value) => (value ? value.trim() : ''),
          }"
        >
          <a-input
            v-model:value="model.account_number"
            placeholder="Account Number"
          />
        </a-form-item>
      </a-col>

      <a-col
        :xs="24"
        :md="12"
        :xl="8"
        v-if="!$comman.isCanReceiveOrgansHospital(hospital)"
      >
        <a-form-item
          name="patient_first_name"
          :rules="[
            {
              required: !$comman.isCanReceiveOrgansHospital(hospital),
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
              transform: (value) => (value ? value.trim() : ''),
            },
          ]"
        >
          <template #label>
            {{
              $comman.isCanReceiveOrgansHospital(hospital) ? "Donor" : "Patient"
            }}
            First Name
          </template>
          <a-input
            v-model:value="model.patient_first_name"
            placeholder="Enter first name here..."
          />
        </a-form-item>
      </a-col>

      <a-col
        :xs="24"
        :md="12"
        :xl="8"
        v-if="!$comman.isCanReceiveOrgansHospital(hospital)"
      >
        <a-form-item
          name="patient_last_name"
          :rules="[
            {
              required: !$comman.isCanReceiveOrgansHospital(hospital),
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
              transform: (value) => (value ? value.trim() : ''),
            },
          ]"
        >
          <template #label>
            {{
              $comman.isCanReceiveOrgansHospital(hospital) ? "Donor" : "Patient"
            }}
            Last Name
          </template>
          <a-input
            v-model:value="model.patient_last_name"
            placeholder="Enter last name here..."
          />
        </a-form-item>
      </a-col>

      <template v-if="$comman.isCanReceiveOrgansHospital(hospital)">
        <a-col :xs="24" :md="12" :xl="8">
          <a-form-item
            label="Donor Hospital"
            name="donor_hospital"
            :rules="[
              {
                required: true,
                message: 'This field is required!',
                trigger: $constants.ruleTrigger,
                transform: (value) => (value ? value.trim() : ''),
              },
            ]"
          >
            <a-input
              v-model:value="model.donor_hospital"
              placeholder="Donor Hospital"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :xl="8">
          <a-form-item
            label="Organ(s) Considered"
            name="organs_considered"
            :rules="{
              required: true,
              type: 'array',
              min: 1,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-select
              v-model:value="model.organs_considered"
              mode="multiple"
              style="width: 100%"
              :token-separators="[',']"
              placeholder="Organ(s) Considered"
              :options="$constants.organs"
              @change="this.$emit('handleOrganConsiderChange')"
            />
          </a-form-item>
        </a-col>
      </template>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item label="Allergies" name="allergies">
          <a-input v-model:value="model.allergies" placeholder="Allergies" />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item
          label="Height (cm)"
          name="height"
          :rules="[
            {
              type: 'number',
              message: 'This field is required!',
              validator: $validation.decimal,
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-input-number
            type="number"
            v-model:value="model.height"
            placeholder="cm"
            :min="0.1"
            :step="0.1"
            @change="handleHeightChangeEvent"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item
          label="Weight (kg)"
          name="weight"
          :rules="[
            {
              type: 'number',
              message: 'This field is required!',
              validator: $validation.decimal,
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-input-number
            class="noscroll"
            type="number"
            v-model:value="model.weight"
            placeholder="kg"
            :min="0.1"
            :step="0.1"
            @change="handleWeightChangeEvent"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item name="body_surface_area">
          <template #label> Body Surface (m<sup>2</sup>): </template>
          <a-input
            v-model:value="model.body_surface_area"
            placeholder="Body surface"
            :disabled="true"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item ref="blood_type" label="Blood Type" name="blood_type">
          <a-select
            v-model:value="model.blood_type"
            placeholder="Select employee blood group"
            show-search
            style="width: 100%"
            :options="
              $constants.bloodTypes.map((e) => ({
                label: e,
                value: e,
              }))
            "
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item
          label="Date of Birth"
          name="dob"
          :rules="[
            {
              type: 'date',
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-date-picker
            v-model:value="model.dob"
            :placeholder="$constants.datePickerPlaceholder"
            :format="$constants.datepickerFormat"
            :disabledDate="$comman.disabledFutureDate"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :xl="8">
        <a-form-item
          ref="gender"
          label="Gender"
          name="gender"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-radio-group name="radioGroup" v-model:value="model.gender">
            <a-radio
              v-for="(v, i) in $constants.gender"
              :key="`${i}Gender`"
              :value="v.value"
            >
              {{ v.text }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <template v-if="$comman.isCanReceiveOrgansHospital(hospital)">
        <a-col :xs="24" :md="12" :xl="8">
          <a-form-item
            label="Age (In Years)"
            name="age"
            :rules="[
              {
                required: true,
                type: 'number',
                message: 'This field is required!',
                trigger: $constants.ruleTrigger,
              },
            ]"
          >
            <a-input-number
              class="noscroll"
              type="number"
              v-model:value="model.age"
              placeholder="Age"
              :max="120"
              :min="0"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :xl="8">
          <a-form-item
            label="Accepting Center"
            name="accepting_center"
            :rules="{
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-input
              v-model:value="model.accepting_center"
              placeholder="Enter acccepting center"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :xl="8">
          <a-form-item
            label="Additional Accepting Center"
            name="additional_accepting_center"
          >
            <a-input
              v-model:value="model.additional_accepting_center"
              placeholder="Enter additional acccepting center"
            />
          </a-form-item>
        </a-col>
      </template>
    </template>
  </a-row>
</template>

<script>
export default {
  name: "case-report-step-1",
  inheritAttrs: false,
  props: [
    "step",
    "model",
    "hospitals",
    "hospital",
    "caseServices",
    "isDisposableOnlyCase",
  ],
  emits: ["validate", "handleOrganConsiderChange"],

  data() {
    return {
      uuid: this.$route?.query?.uuid ?? null,
    };
  },

  computed: {
    mrNumber() {
      return this.$comman.mrNumber(this.hospital);
    },

    showIsDisposableOnlyCaseCheckbox() {
      return this.caseServices.find(
        (e) => e.value == this.$constants.disposableOnlyCase
      );
    },
  },

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },

    handleHeightChangeEvent() {
      this.calculateBSA();
    },

    handleWeightChangeEvent() {
      this.calculateBSA();
      this.calculateEstimatedBloodVolume();
    },

    calculateBSA() {
      this.model.body_surface_area = Number(
        Math.sqrt(((this.model.weight ?? 0) * (this.model.height ?? 0)) / 3600)
      ).toFixed(2);
    },

    calculateEstimatedBloodVolume() {
      this.model.estimated_blood_volume = this.model.weight
        ? this.model.weight * 70
        : "";
    },

    handleDisposableOnlyCheckboxChange() {
      this.model.services = [this.$constants.disposableOnlyCase];
    },
  },
};
</script>
