<template>
  <div>
    <a-form
      name="announcement_form"
      ref="announcement_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="title" label="Title" name="title">
            <a-textarea
              v-model:value="formState.title"
              placeholder="Enter title here"
              showCount
              :maxlength="200"
              autoSize
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="description" label="Description" name="description">
            <a-textarea
              v-model:value="formState.description"
              placeholder="Enter description here"
              showCount
              :maxlength="400"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="url" label="URL" name="url">
            <a-input
              v-model:value="formState.url"
              placeholder="Enter url here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="image" label="Image" name="image">
            <a-upload-dragger
              :fileList="formState.image"
              :remove="handleRemoveReceipt"
              :before-upload="beforeUploadReceipt"
              accept="image/*"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">Max file size: 10MB<br /></p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],

  components: {
    PlusOutlined,
    InboxOutlined,
  },
  data() {
    return {
      formState: {
        title: "",
        description: "",
        url: "",
        image: [],
      },
      options: [],
      rules: {
        title: [
          {
            required: true,
            message: "This field is required!",
            transform(value) {
              return value.trim();
            },
          },
        ],
        url: [
          {
            message: "This field is must be valid url!",
            type: "url",
          },
        ],
        image: [
          {
            required: true,
            message: "This field is required!",
            type: "array",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        title: this.record.title ?? "",
        description: this.record.description ?? "",
        url: this.record.url ?? "",
        image: this.record.id
          ? [
              {
                id: this.record.id,
                uid: this.record.image,
                name: this.record.image,
                url: this.record.full_image_path,
              },
            ]
          : [],
      };
    },

    onSubmit() {
      const formData = new FormData();
      if (this.record.id) {
        formData.append("id", this.record.id);
      }
      formData.append("title", this.formState.title);
      formData.append("description", this.formState.description);
      formData.append("url", this.formState.url);
      formData.append("image", this.formState.image[0]);

      this.loadingStart();
      commonService
        .store(this.$constants.announcementStoreUrl, formData)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },

    handleRemoveReceipt() {
      this.formState.image = [];
    },

    beforeUploadReceipt(file) {
      if (file.size < 10 * 1024 * 1024) {
        this.formState.image = [file];
      } else {
        this.$message.error(`${file.name} size is more then 10MB`);
      }
      return false;
    },
  },
};
</script>
