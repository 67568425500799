<template>
  <div class="mb-sm-2">
    <a-descriptions
      bordered
      :column="1"
      size="small"
      :contentStyle="{ paddingLeft: '8px' }"
      :labelStyle="{ width: '125px', fontWeight: 'bold' }"
    >
      <a-descriptions-item label="Name:">
        {{ record.full_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Email:">
        {{ record.email }}
      </a-descriptions-item>
    </a-descriptions>
  </div>

  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-form-item ref="password" label="New Password" name="password">
          <a-row :gutter="[20, 0]">
            <a-col :span="12">
              <a-input
                v-model:value="formState.password"
                placeholder="Enter password here"
              />
            </a-col>

            <a-col :span="6">
              <a-button type="primary" @click="generatePassword">
                Auto Generate Password
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Reset Password" />
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";
export default {
  data() {
    return {
      formState: {
        password: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "This field is required.",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    autoGeneratePassword() {
      const alpha = "abcdefghijklmnopqrstuvwxyz";
      const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const num = "1234567890";
      const specials = ",.!@#$%^&*";
      const options = [alpha, alpha, alpha, alpha, alpha, num, num, num];
      let opt, choose;
      let pass = "";
      for (let i = 0; i < options.length; i++) {
        // opt = Math.floor(Math.random() * options.length);
        choose = Math.floor(Math.random() * options[i].length);
        pass = pass + options[i][choose];
        // options.splice(opt, 1);
      }
      return pass;
    },

    generatePassword() {
      this.formState.password = this.autoGeneratePassword();
    },

    onSubmit() {
      let data = {
        password: this.formState.password,
        email: this.record.email,
      };

      this.loadingStart();

      commonService
        .store(this.$constants.employeePasswordReset, data)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
