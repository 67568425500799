<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #employment_type="{ record }">
        <a-tag
          :color="
            $comman.getValueFromObject(
              record.employee,
              'employment_type',
              $constants.employment_types,
              'color'
            )
          "
        >
          {{
            $comman.getValueFromObject(
              record.employee,
              "employment_type",
              $constants.employment_types
            )
          }}
        </a-tag>
      </template>

      <template #action="{ record }">
        <a-tooltip title="View">
        <a-button
          type="link"
          size="small"
          class="pl-sm-0 primary-color"
          @click="handleShowTimeTrackingEvent(record)"
        >
          <i class="ti ti-eye ti-lg"></i>
        </a-button>
      </a-tooltip>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.managerTimesheetDatatableUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          defaultFilteredValue: this.$route.query?.shift_type_name
            ? [this.$route.query.shift_type_name]
            : undefined,
          customRender: ({ record }) => record.shift_type?.name,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "start_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
          defaultSortOrder:
            this.$route.query.sortField == "end_date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Total Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.timeTrackingStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        hospital_id: parseInt(this.$route.params.id),
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowTimeTrackingEvent(record) {
      this.open({
        title: "Show Time Tracking Details",
        path: "employeeTimeTrack.show",
        callback: this.refresh,
        record: record,
      });
    },
  },
};
</script>
