<template>
  <a-layout-content class="employee-benefit-show">
    <page-header>
      <template #title> Employee Benefits</template>
    </page-header>

    <div v-if="highlightText" class="sticky-search">
      <a-card size="small">
        <div class="search-container">
          <a-input
            v-model:value="query"
            allow-clear
            placeholder="Search text"
            @input="onQueryChange"
          />
          <a-space v-if="!!query.trim()">
            <a-button @click="previousResult" :disabled="!hasPrevious">
              Previous
            </a-button>
            <a-button @click="nextResult" :disabled="!hasNext">Next</a-button>
            <span v-if="totalMatches > 0">
              {{ currentIndex + 1 }} of {{ totalMatches }}
            </span>
          </a-space>
        </div>
      </a-card>
    </div>

    <a-card size="small" hover class="mt-sm-1" :loading="loading">
      <a-empty v-if="!highlightText" description="No data" />
      <p v-else v-html="highlightText" ref="contentRef" />
    </a-card>
  </a-layout-content>
</template>

<script setup>
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import { message } from "ant-design-vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";

const employeeBenefits = ref("");
const loading = ref(false);

function fetchSetting() {
  loading.value = true;
  return commonService
    .store(constants.getSettingUrl, { key: "employee_benefits" })
    .then((res) => {
      if (res.data.employee_benefits) {
        employeeBenefits.value = res.data.employee_benefits;
      }
    })
    .catch((err) => {
      message.error(err.message);
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(fetchSetting);

const query = ref("");
const currentIndex = ref(0);

const text = computed(() => employeeBenefits.value);

const matches = computed(() => {
  if (!query.value) return [];
  const regex = new RegExp(query.value, "gi");
  return [...text.value.matchAll(regex)];
});

const totalMatches = computed(() => matches.value.length);

const highlightText = computed(() => {
  if (!query.value || matches.value.length === 0) return text.value;

  const match = matches.value[currentIndex.value];
  const start = match.index;
  const end = start + match[0].length;

  return (
    text.value.slice(0, start) +
    `<span class="highlight">${text.value.slice(start, end)}</span>` +
    text.value.slice(end)
  );
});

const hasNext = computed(() => currentIndex.value < totalMatches.value - 1);
const hasPrevious = computed(() => currentIndex.value > 0);

function nextResult() {
  if (hasNext.value) {
    currentIndex.value++;
  }
}

function previousResult() {
  if (hasPrevious.value) {
    currentIndex.value--;
  }
}

function resetIndex() {
  currentIndex.value = 0;
}

const contentRef = ref(null);

function scrollToHighlight() {
  nextTick(() => {
    const highlightElement = contentRef.value.querySelector(".highlight");
    if (highlightElement) {
      highlightElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  });
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function onQueryChange(event) {
  resetIndex();
  if (!event.target.value) {
    scrollToTop();
  }
}

watch([currentIndex, query], scrollToHighlight);
</script>

<style lang="less">
.sticky-search {
  position: sticky;
  top: 0;
  z-index: 1;
  padding-top: 16px;
}

.content {
  margin-top: 16px;
}

.search-container {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.highlight {
  background-color: yellow;
}

// add default styles for table
employee-benefit-show.table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #f2f2f2;
  }

  th {
    text-align: left;
  }

  td {
    text-align: left;
  }

  th:first-child,
  td:first-child {
    text-align: center;
  }

  th:last-child,
  td:last-child {
    text-align: center;
  }

  th {
    font-weight: bold;
  }

  td {
    font-weight: normal;
  }

  th,
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    cursor: pointer;
  }

  th:hover {
    background-color: #f2f2f2;
  }

  th:active {
    background-color: #f2f2f2;
  }
}
</style>
