import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/incidents",
    name: "incidents",
    component: route("incident/index"),
    meta: {
      title: "Incidents",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/incident/create",
    name: "incidentCreate",
    component: route("incident/store"),
    meta: {
      title: "Create New Incident",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/incident/edit/:id",
    name: "incidentEdit",
    component: route("incident/store"),
    meta: {
      title: "Edit Incident",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/incident/show/:id",
    name: "incidentShow",
    component: route("incident/show"),
    meta: {
      title: "Show Incident",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
