<template>
  <a-layout-content>
    <page-header>
      <template #title> Payroll </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card size="small" hoverable :loading="cardLoading">
      <div class="mb-sm-1" v-if="showSettingError">
        <a-alert type="warning">
          <template #message>
            To sync bills on QuickBooks, Contractor account has not been set.
            Please go to Admin > Settings > QuickBooks
          </template>
        </a-alert>
      </div>

      <a-space direction="vertical" style="width: 100%" size="large">
        <a-range-picker
          :allowClear="false"
          v-model:value="date_range"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          inputReadOnly
          :ranges="ranges"
        />

        <a-space>
          <a-button
            type="primary"
            :loading="gustoPayrollSheetLoading"
            @click="getGustoPayrollSheet"
            :disabled="!date_range[0]"
          >
            <a-space>
              <i class="far fa-file-excel"></i>
              Gusto Payroll
            </a-space>
          </a-button>

          <a-button
            type="primary"
            :loading="adpPayrollSheetLoading"
            @click="getAdpPayrollSheet"
            :disabled="!date_range[0]"
          >
            <a-space>
              <i class="far fa-file-excel"></i>
              ADP Payroll
            </a-space>
          </a-button>

          <a-button
            type="primary"
            :loading="employeeTimeTrackLoading"
            @click="getEmployeeTimeTrackSheet"
            :disabled="!date_range[0]"
          >
            <a-space>
              <i class="far fa-file-excel"></i>
              Employee Time Tracking
            </a-space>
          </a-button>

          <a-button
            type="primary"
            ghost
            :loading="contractorTimeTrackLoading"
            @click="getContractorTimeTrackSheet"
            :disabled="!date_range[0]"
          >
            <a-space>
              <i class="far fa-file-excel"></i>
              Contractor Time Tracking
            </a-space>
          </a-button>

          <a-button
            type="primary"
            ghost
            :loading="createContractorBillOnQuickBooksLoading"
            @click="createContractorBillOnQuickBooks"
            :disabled="!date_range[0] || showSettingError"
          >
            <a-space>
              <i class="ti ti-refresh ti-1x"></i>
              Create contractor bills on QBO
            </a-space>
          </a-button>
        </a-space>
      </a-space>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      gustoPayrollSheetLoading: false,
      adpPayrollSheetLoading: false,
      employeeTimeTrackLoading: false,
      contractorTimeTrackLoading: false,
      createContractorBillOnQuickBooksLoading: false,
      date_range: [null, null],
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
      cardLoading: true,
      settings: [],
    };
  },

  computed: {
    showSettingError() {
      return !Object.keys(this.settings).includes("contractor_payment_account");
    },
  },

  mounted() {
    this.fetchSetting();
  },

  methods: {
    fetchSetting() {
      this.cardLoading = true;

      commonService
        .store(this.$constants.getSettingUrl)
        .then((res) => {
          if (res.success) {
            this.settings = res.data;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    getGustoPayrollSheet() {
      let url = this.$constants.reportPayroll;
      let dateString =
        this.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.date_range[1].clone().format("MMDDYY");

      let fileName = "payroll-gusto-" + dateString + ".xlsx";
      var params = {
        dates:
          this.$customDate.ymd(this.date_range[0]) +
          " - " +
          this.$customDate.ymd(this.date_range[1]),
        type: 1,
      };

      this.gustoPayrollSheetLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.gustoPayrollSheetLoading = false;
        });
    },

    getAdpPayrollSheet() {
      let url = this.$constants.reportAdpPayroll;
      let dateString = this.date_range[1].clone().format("MMDDYY");

      let fileName = "EPI20T" + dateString + ".csv";
      var params = {
        dates:
          this.$customDate.ymd(this.date_range[0]) +
          " - " +
          this.$customDate.ymd(this.date_range[1]),
      };

      this.adpPayrollSheetLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.adpPayrollSheetLoading = false;
        });
    },

    getEmployeeTimeTrackSheet() {
      let url = this.$constants.reportPayroll;
      let dateString =
        this.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.date_range[1].clone().format("MMDDYY");

      let fileName = "employee-payroll-time-tracking-" + dateString + ".xlsx";
      var params = {
        dates:
          this.$customDate.ymd(this.date_range[0]) +
          " - " +
          this.$customDate.ymd(this.date_range[1]),
        type: 2,
      };

      this.employeeTimeTrackLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.employeeTimeTrackLoading = false;
        });
    },

    getContractorTimeTrackSheet() {
      let url = this.$constants.reportPayroll;
      let dateString =
        this.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.date_range[1].clone().format("MMDDYY");

      let fileName = "contractor-payroll-time-tracking-" + dateString + ".xlsx";
      var params = {
        dates:
          this.$customDate.ymd(this.date_range[0]) +
          " - " +
          this.$customDate.ymd(this.date_range[1]),
        type: 3,
      };

      this.contractorTimeTrackLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.contractorTimeTrackLoading = false;
        });
    },

    createContractorBillOnQuickBooks() {
      this.createContractorBillOnQuickBooksLoading = true;

      commonService
        .store(this.$constants.uploadTimesheetOnQuickbooksUrl, {
          dates: `${this.date_range[0].format(
            "YYYY-MM-DD"
          )} - ${this.date_range[1].format("YYYY-MM-DD")}`,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.createContractorBillOnQuickBooksLoading = false;
        });
    },
  },
};
</script>
