<template>
  <a-layout-content>
    <page-header>
      <template #title> Login Logs </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #os="{ record }">
          <template v-if="record.browser.includes('Windows')">
            <i class="ti ti-brand-windows ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Linux')">
            <i class="ti ti-brand-ubuntu ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Mac OS')">
            <i class="ti ti-brand-apple ti-lg"></i>
          </template>
        </template>

        <template #browser="{ record }">
          <template v-if="record.browser.includes('Edg')">
            <i class="ti ti-brand-edge ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Chrome')">
            <i class="ti ti-brand-chrome ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Firefox')">
            <i class="ti ti-brand-firefox ti-lg"></i>
          </template>
          <template v-else-if="record.browser.includes('Safari')">
            <i class="ti ti-brand-safari ti-lg"></i>
          </template>
        </template>

        <template #attempt_status="{ text }">
          <a-tag v-if="text == 1" color="green">Allow</a-tag>
          <a-tag v-else color="red">Block</a-tag>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.loginLogDatatableUrl,
      columns: [
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.email
            ? [this.$route.query?.email]
            : undefined,
          defaultSortOrder:
            this.$route.query?.sortField == "email"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Name",
          dataIndex: "full_name",
          key: "full_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => text ?? "N/A",
          defaultFilteredValue: this.$route.query?.full_name
            ? [this.$route.query?.full_name]
            : undefined,
        },
        {
          title: "IP Address",
          dataIndex: "ip_address",
          key: "ip_address",
        },
        {
          title: "Location",
          dataIndex: "country",
          key: "country",
          customRender: ({ record }) =>
            record.ip_info?.city
              ? `${record.ip_info.city}, ${record.ip_info.region}, ${record.ip_info.country}`
              : "N/A",
        },
        {
          title: "Browser",
          dataIndex: "browser",
          key: "browser",
          slots: { customRender: "browser" },
        },
        {
          title: "OS",
          dataIndex: "os",
          key: "os",
          slots: { customRender: "os" },
        },
        {
          title: "Attempt Status",
          dataIndex: "attempt_status",
          key: "attempt_status",
          filters: this.$constants.loginLogAttemptStatuses,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.attempt_status
            ? [parseInt(this.$route.query?.attempt_status)]
            : undefined,
          slots: { customRender: "attempt_status" },
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: this.$route.query?.sortOrder
            ? this.$route.query.sortOrder
            : "descend",
          customRender: ({ text }) => `${this.$customDate.mdyhis(text)}`,
        },
      ],
      defaultFilterParams: {
        email: this.$route.query?.email
          ? [this.$route.query?.email]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
        attempt_status: this.$route.query?.attempt_status
          ? [this.$route.query?.attempt_status]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : "created_at",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : "descend",
      },
    };
  },

  methods: {
    //
  },
};
</script>
