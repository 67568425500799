<template>
  <a-layout-content>
    <page-header>
      <template #title> Certification Types </template>

      <template #buttons>
        <a-button
          type="primary"
          @click="handleCreateNewCertificateTypeEvent"
        >
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add Certificate Type
          </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #attachment_limit="{ record }">
          {{ $constants.attachment_limit[record.attachment_limit].type }}
        </template>

        <template #is_expirable="{ record }">
          <a-tag v-if="record.is_expirable == 1" color="green">Yes</a-tag>
          <a-tag v-if="record.is_expirable == 0" color="red">No</a-tag>
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <a-tooltip title="Edit">
            <a-button
              type="link"
              size="small"
              class="pl-sm-0"
              @click="handleEditCertificateTypeEvent(record)"
            >
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this certificate type?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
            <a-tooltip title="Delete">
              <a-button size="small" type="link" danger>
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure you want to restore this certificate type?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
            <a-tooltip title="Restore">
              <a-button size="small" type="link" class="pl-sm-0">
                <i class="ti ti-rotate ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.certificationTypeDatatableUrl,
      statusChangeUrl: this.$constants.certificationTypeChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
        },
        {
          title: "Attachment Limit",
          dataIndex: "attachment_limit",
          key: "attachment_limit",
          sorter: true,
          slots: { customRender: "attachment_limit" },
        },
        {
          title: "File Type Support",
          dataIndex: "filetype_supported",
          key: "filetype_supported",
          sorter: true,
        },
        {
          title: "Is Expirable",
          dataIndex: "is_expirable",
          key: "is_expirable",
          sorter: true,
          slots: { customRender: "is_expirable" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        sortField: this.$route.query?.sortField ?? "name",
        sortOrder: this.$route.query?.sortOrder ?? "ascend",
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleEditCertificateTypeEvent(record) {
      this.open({
        title: "Edit Case Service",
        path: "certificationType.store",
        callback: this.refresh,
        record: record,
      });
    },

    handleCreateNewCertificateTypeEvent() {
      this.open({
        title: "Add Case Service",
        path: "certificationType.store",
        callback: this.refresh,
      });
    },
  },
};
</script>
