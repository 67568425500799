<template>
  <a-layout-content>
    <page-header>
      <template #title> Paid Time Off</template>
      <template #buttons>
        <router-link :to="{ name: 'create-paid-time-off' }">
          <a-button type="primary"> <PlusOutlined /> Add New </a-button>
        </router-link>
      </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <template v-for="e in widgets" :key="e">
        <a-col :span="6">
          <a-card size="small">
            <a-statistic :title="e" :value="e in ptoData ? ptoData[e] : 0" />
          </a-card>
        </a-col>
      </template>

      <a-col :span="24">
        <a-card
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="handleTabChange"
          size="small"
        >
          <template #customRender="item">
            {{ item.text }} ({{ item.counts }})
          </template>

          <a-space direction="vertical" style="width: 100%">
            <!-- <a-row class="mb-sm-1" justify="space-between">
              <div style="display: flex">
                <a-select
                  v-model:value="bulkActionValue"
                  placeholder="Select One"
                  style="width: 120px; margin-right: 20px"
                >
                  <a-select-option value="2"> Approve </a-select-option>
                  <a-select-option value="3"> Reject </a-select-option>
                </a-select>

                <a-button
                  html-type="button"
                  type="primary"
                  :disabled="bulkActionBtnDisabled"
                  @click="bulkAction"
                >
                  Submit
                </a-button>
              </div>
            </a-row> -->

            <!-- :row-selection="rowSelection" -->
            <a-table
              :columns="columns.filter((e) => e.show ?? true)"
              :rowKey="(record) => record.id"
              :dataSource="dataSource"
              :pagination="pagination"
              :loading="datatableLoading"
              @change="handleTableChange"
              @refresh="refresh"
              size="small"
              :scroll="scroll"
              :rowClassName="
                (record) => (record.is_flagged ? 'flagged-row' : '')
              "
            >
              <template #filterDropdown="filterDropdown">
                <xFilterInputSearchDropdown
                  :filterDropdown="filterDropdown"
                  @handleSearch="handleDatatableSearch"
                  @handleReset="handleDatatableReset"
                />
              </template>

              <template #filterIcon="filterIcon">
                <xFilterIcon :filterIcon="filterIcon" />
              </template>

              <template #action="{ record }">
                <a-space :size="1">
                  <a-tooltip title="Show">
                    <a-button
                      @click="handleShowPaidTimeOffEvent(record)"
                      type="link"
                      size="small"
                      class="primary-color"
                    >
                      <i class="ti ti-eye ti-lg"></i>
                    </a-button>
                  </a-tooltip>

                  <a-tooltip title="Edit">
                    <router-link
                      :to="{
                        name: 'edit-paid-time-off',
                        params: { id: record.id },
                      }"
                    >
                      <a-button type="link" size="small">
                        <i class="ti ti-pencil ti-lg"></i>
                      </a-button>
                    </router-link>
                  </a-tooltip>

                  <a-popconfirm
                    v-if="[1].includes(record.status)"
                    @confirm="handleDeletePtoEvent(record)"
                    cancel-text="No"
                    ok-text="Yes"
                    title="Are you sure you want to Delete this PTO?"
                  >
                    <a-tooltip title="Delete">
                      <a-button danger size="small" type="link">
                        <i class="ti ti-trash ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>

                  <a-popconfirm
                    v-if="[2].includes(record.status)"
                    @confirm="handleCancelPtoEvent(record)"
                    cancel-text="No"
                    ok-text="Yes"
                    title="Are you sure you want to cancel this PTO?"
                  >
                    <a-tooltip title="Cancel">
                      <a-button danger size="small" type="link">
                        <i class="ti ti-circle-x ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </a-space>
              </template>
            </a-table>
          </a-space>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import xFilterIcon from "@/components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "@/components/table/filterInputSearchDropdown.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  components: {
    PlusOutlined,
    xFilterInputSearchDropdown,
    xFilterIcon,
  },

  mixins: [datatableMixins],

  data() {
    return {
      widgets: ["Balance", "Taken", "Scheduled", "Earned"],
      ptoData: {},
      datatableUrl: this.$constants.paidTimeOffDatatableUrl,
      statusChangeUrl: "",
      selectedRowKeys: [],
      bulkActionValue: null,
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.paidTimeOffStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      columns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          sorter: true,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
          sorter: true,
          customRender: ({ text }) => (text ? text : "-"),
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    rowSelection() {
      return ["1", "2"].includes(this.activeTabKey)
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    bulkActionBtnDisabled() {
      return this.bulkActionValue == null || this.selectedRowKeys.length == 0;
    },
  },

  mounted() {
    this.fetchRemainingPto();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleTabChange(key) {
      this.activeTabKey = key;
      this.selectedRowKeys = [];
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    resetFilters() {
      this.refresh();
    },

    bulkAction() {
      this.$comman.caseReportLogStore({
        id: this.selectedRowKeys,
        action: "Approve Record",
      });
      this.handleStatusChangeEvent({ id: this.selectedRowKeys, status: "3" });
    },

    handleShowPaidTimeOffEvent(record) {
      this.open({
        title: "Paid Time Off Details",
        path: "paidTimeOff.show",
        callback: this.refresh,
        record: record,
      });
    },

    fetchRemainingPto() {
      this.spinning = true;
      this.ptoData = [];
      commonService.get(this.$constants.getRemainingPtoUrl).then((res) => {
        res.data[0]?.policyBalances
          ? res.data[0]?.policyBalances
              .map((e) => {
                return {
                  label: e.balanceType?.labelName ?? "As required",
                  hours: e.totalQuantity?.valueNumber ?? 0,
                };
              })
              .filter((e) => this.widgets.includes(e.label))
              .forEach((e) => {
                this.ptoData[e["label"]] = e.hours;
              })
          : [];
      });
    },

    handleDeletePtoEvent(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.paidTimeOffDeleteUrl, {
          id: record.id,
        })
        .then((res) => {
          this.refresh();
          this.$message.success(res["message"]);
        });
    },

    handleCancelPtoEvent(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.paidTimeOffCancelUrl, {
          id: record.id,
        })
        .then((res) => {
          this.refresh();
          this.$message.success(res["message"]);
        });
    },
  },

  watch: {
    activeTabKey() {
      //
    },
  },
};
</script>
