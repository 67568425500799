<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>Create New Appointment</template>

        <template #buttons>
          <back-button />
        </template>
      </page-header>

      <a-spin :spinning="loading">
        <a-card>
          <a-form
            ref="create_appointment_form"
            layout="vertical"
            :model="model"
            :rules="rules"
          >
            <a-row :gutter="[16, 0]">
              <a-col :span="12">
                <a-form-item ref="title" label="Title" name="title">
                  <a-input
                    v-model:value="model.title"
                    placeholder="Enter event title here..."
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item
                  ref="case_service_id"
                  label="Select Service"
                  name="case_service_id"
                >
                  <a-select
                    v-model:value="model.case_service_id"
                    placeholder="Select service"
                    show-search
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="caseService in caseServices"
                      :key="caseService.case_service.id"
                      :label="caseService.case_service.name"
                      :value="caseService.case_service.id"
                    >
                      {{ caseService.case_service.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24" :md="12">
                <a-form-item
                  ref="dateRange"
                  label="Date Range"
                  name="dateRange"
                >
                  <a-range-picker
                    v-model:value="model.dateRange"
                    :allowClear="false"
                    :ranges="ranges"
                    :disabled-date="$comman.disabledPastDate"
                    :format="$constants.datepickerFormat"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="24" :md="12">
                <a-form-item ref="days" label="Days" name="days">
                  <a-select
                    v-model:value="model.days"
                    mode="multiple"
                    placeholder="Select multiple days"
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="eventDay in $constants.eventDays"
                      :key="eventDay.value"
                      :label="eventDay.text"
                      :value="eventDay.value"
                    >
                      {{ eventDay.text }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item
                  ref="is_full_day_event"
                  label="Is Full Day Event?"
                  name="is_full_day_event"
                >
                  <a-radio-group v-model:value="model.is_full_day_event">
                    <a-radio :value="1">Yes</a-radio>
                    <a-radio :value="0">No</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>

              <a-col v-if="model.is_full_day_event == 0" :span="12">
                <a-form-item
                  ref="start_time"
                  label="Start Time"
                  name="start_time"
                >
                  <a-time-picker
                    v-model:value="model.start_time"
                    use12Hours
                    :minuteStep="5"
                    valueFormat="HH:mm"
                    :defaultOpenValue="startOfDay"
                    format="hh:mm a"
                    inputReadOnly
                  />
                </a-form-item>
              </a-col>

              <a-col v-if="model.is_full_day_event == 0" :span="12">
                <a-form-item ref="end_time" label="End Time" name="end_time">
                  <a-time-picker
                    v-model:value="model.end_time"
                    use12Hours
                    valueFormat="HH:mm"
                    inputReadOnly
                    :defaultOpenValue="startOfDay"
                    format="hh:mm a"
                    :minuteStep="5"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item ref="notes" label="Notes" name="notes">
                  <template #extra>
                    <span class="danger-color">
                      Be sure to include patient last name or medical record
                      number. Please DO NOT include any other patient
                      information/identifier. Although our form is HIPAA
                      compliant, we do not require any other patient
                      information.
                    </span>
                  </template>

                  <a-textarea
                    v-model:value="model.notes"
                    placeholder="Enter event notes here..."
                    :autoSize="{ minRows: 5 }"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-button
              type="primary"
              :loading="loading"
              @click="handleSubmit(false)"
              class="mr-sm-1"
            >
              Save
            </a-button>
            <a-button type="primary" :loading="loading" @click="handleSubmit">
              Save And Create New
            </a-button>
          </a-form>
        </a-card>
      </a-spin>
    </a-layout-content>
  </div>
</template>

<script>
import moment from "moment-timezone";
import backButton from "../../../components/backButton.vue";
import { comman } from "../../../helper/comman";
import { appointmentEventRules } from "../../../helper/formRules";
import router from "../../../router";
import { commonService } from "../../../services";
moment.tz.setDefault("UTC");

export default {
  components: { backButton },
  data() {
    return {
      loading: false,
      model: {
        title: null,
        hospital_id: this.$store.getters["auth/hospital"].id,
        case_service_id: null,
        dateRange: null,
        days: [],
        is_full_day_event: 1,
        start_time: null,
        end_time: null,
        notes: this.$constants.calendarEventNotesString,
      },
      rules: appointmentEventRules,
      ranges: {
        Today: [moment(), moment()],
        "This Month": [moment(), moment().endOf("month")],
        "Next 30 Days": [
          moment(),
          moment()
            .add(1, "month")
            .subtract(1, "day"),
        ],
        "Next Month": [
          moment()
            .add(1, "month")
            .startOf("month"),
          moment()
            .add(1, "month")
            .endOf("month"),
        ],
      },
      startOfDay: moment().startOf("day"),
      caseServices: [],
    };
  },

  mounted() {
    comman
      .getHospitalWiseCaseServices({ hospital_id: this.model.hospital_id })
      .then((res) => {
        this.caseServices = res;
      });
  },

  methods: {
    handleSubmit(redirectOnSamePage = true) {
      this.$refs.create_appointment_form.validate().then(() => {
        this.loading = true;
        commonService
          .store(this.$constants.calendarEventStoreUrl, this.model)
          .then((res) => {
            this.loading = false;
            this.$message.success(res.message);
            if (redirectOnSamePage) {
              this.model.days = [];
              this.model.is_full_day_event = 1;
              this.model.start_time = null;
              this.model.end_time = null;
              this.model.notes = this.$constants.calendarEventNotesString;
            } else {
              router.push({ name: "hospital-user-appointments" });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },
  },
};
</script>
