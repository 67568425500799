<template>
  <a-row :gutter="[20, 20]" justify="space-between">
    <a-col :span="24">
      <div class="welcome-container">
        <div class="texts">
          <a-result
            status="success"
            title="Onboarding Process Pending Review. Thank you!"
          />

          <p>
            Your onboarding process is nearly complete. Thank you for submitting
            your documents. Our team is currently reviewing your uploaded
            information. You will receive an email once this review is finished.
          </p>

          <p>
            During this period, you can review the submitted documents,
            credentials, and other details. If necessary, you can make any
            changes.
          </p>

          <p>
            Additionally, please be aware that a criminal background screening
            and pre-employment drug screening are required. If you have not
            completed these screenings yet, you can proceed by clicking
            <a-typography-link
              href="https://www.nydrugtesting.com/customerportal/Candidate.aspx/CAF/PU1D2ASEBD503D54"
              target="_blank"
              >here</a-typography-link
            >, or complete them after your document review.
          </p>

          <p>
            If you have any questions, please contact your HR representative.
          </p>
        </div>
      </div>
    </a-col>

    <a-col :span="24">
      <footer-buttons
        :current-step="currentStepComputed"
        :steps="steps"
        hide-save-and-next
        @previous-step="currentStepComputed -= 1"
      />
    </a-col>
  </a-row>
</template>

<script setup>
import FooterButtons from "@/components/newUserOnboarding/FooterButtons.vue";
import { computed } from "vue";

const props = defineProps({
  currentStep: Number,
  steps: Array,
});
const emit = defineEmits(["update:currentStep"]);

const currentStepComputed = computed({
  get: () => props.currentStep,
  set: (value) => emit("update:currentStep", value),
});
</script>

<style lang="less" scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .texts {
    width: 60%;

    span,
    p {
      display: block;
      text-align: center;
    }
  }
}
</style>
