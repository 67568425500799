<template>
  <a-card size="small">
    <a-form :model="model" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="attachments" name="attachments">
            <a-upload-dragger
              :fileList="model.attachments"
              :before-upload="beforeUploadAttachments"
              accept="image/*,application/pdf"
              :multiple="true"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">Max file size: 15MB<br /></p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>

      <a-list
        class="demo-loadmore-list"
        :loading="loading"
        item-layout="horizontal"
        :data-source="attachments"
        size="small"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <template #actions>
              <a-popconfirm
                title="Are you sure you want to delete this file?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="removeAttachment(item)"
              >
              <a-tooltip title="Delete">
                <a-button type="link" size="small" danger>
                  <i class="ti ti-trash ti-lg" />
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>

            <a-list-item-meta>
              <template #title>
                <a :href="item.attachment_file_path" target="_blank">
                  {{ item.attachment }}
                </a>
              </template>

              <template #avatar>
                <img :src="getImgUrl(item)" width="40" />
              </template>

              <template #description>
                Added By
                <strong> {{ item.creator?.full_name ?? "N/A" }} </strong>,
                {{ $customDate.ll(item.created_at) }}
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-form>
  </a-card>
</template>

<script>
import { commonService } from "@/services";
import { InboxOutlined } from "@ant-design/icons-vue";

export default {
  components: { InboxOutlined },

  data() {
    return {
      loading: false,
      attachments: [],
      model: {
        id: this.$route.params.id,
        attachments: [],
      },
    };
  },

  mounted() {
    this.fetchLeadAttachments();
  },

  methods: {
    fetchLeadAttachments() {
      this.loading = true;
      commonService
        .store(this.$constants.crmGetLeadAttachmentUrl, { id: this.model.id })
        .then((res) => {
          this.attachments = res.data;
        })
        .finally(() => (this.loading = false));
    },

    onSubmit() {
      const formData = new FormData();
      formData.append("id", this.model.id);
      this.model.attachments.forEach((element) => {
        formData.append("attachments[]", element);
      });

      this.loading = true;
      commonService
        .store(this.$constants.crmLeadAttachmentStoreUrl, formData)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.model.attachments = [];
          this.fetchLeadAttachments();
        });
    },

    removeAttachment(e) {
      this.loading = true;
      commonService
        .store(this.$constants.crmLeadAttachmentRemoveUrl, { id: e.id })
        .then((res) => {
          this.attachments = res.data;
        })
        .finally(() => (this.loading = false));
    },

    beforeUploadAttachments(file) {
      if (file.size < 15 * 1024 * 1024) {
        this.model.attachments.push(file);
      } else {
        this.$message.error(`${file.name} size is more then 15MB`);
      }
      return false;
    },

    getImgUrl(e) {
      let fileType = e.attachment.split(".")[1];
      if (["png", "jpeg", "jpg", "svg"].includes(fileType)) {
        fileType = "png";
      }
      let file = `${fileType}_file_icon`;
      var images = require.context("@/assets/", false, /\.png$/);
      return images("./" + file + ".png");
    },
  },

  watch: {
    "model.attachments": {
      deep: true,
      handler: function (val) {
        if (val.length > 0) {
          this.onSubmit();
        }
      },
    },
  },
};
</script>
