<template>
  <div>
    <a-form
      @finish="onSubmit"
      ref="announcement_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      name="announcement_form"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="title" label="Title" name="title">
            <a-textarea
              v-model:value="formState.title"
              :maxlength="200"
              autoSize
              placeholder="Enter title here"
              showCount
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item label="Content" name="content">
            <ckeditor
              v-model="formState.content"
              :config="editorConfig"
              :editor="editor"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-alert :message="infoText" class="mb-sm-1" type="info" />
        </a-col>

        <a-col :span="24">
          <a-form-item label="Employment Type" name="employment_type">
            <a-select
              v-model:value="formState.employment_type"
              allow-clear
              optionFilterProp="label"
              placeholder="Select Employment Type"
              style="width: 100%"
            >
              <a-select-option
                v-for="option in employmentType"
                :key="option"
                :label="option.text"
                :value="option.value"
              >
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item label="Roles" name="role_ids">
            <a-select
              v-model:value="formState.role_ids"
              allow-clear
              mode="multiple"
              optionFilterProp="label"
              placeholder="Select Roles"
              style="width: 100%"
            >
              <a-select-option
                v-for="option in roles"
                :key="option"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item label="Position" name="position_ids">
            <a-select
              v-model:value="formState.position_ids"
              allow-clear
              mode="multiple"
              optionFilterProp="label"
              placeholder="Position"
              style="width: 100%"
            >
              <a-select-option
                v-for="option in employeePositions"
                :key="option"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            :rules="{
              required: true,
              message: 'Invalid date.',
              type: 'date',
            }"
            label="Expiry Date"
            name="expiry_date"
          >
            <a-date-picker
              v-model:value="formState.expiry_date"
              :allowClear="false"
              :disabled-date="$comman.disabledPastDate"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            :rules="{
              required: true,
              message: 'Invalid time.',
              validator: $validation.timeWithSecFormat,
            }"
            label="Expiry Time"
            name="expiry_time"
          >
            <a-input
              v-maska="$constants.timeWithSecMaska"
              v-model:value="formState.expiry_time"
              placeholder="HH:mm:ss"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-alert
            message="Images uploaded in ratio of 16/4 are ideal."
            class="mb-sm-1"
            type="info"
          />
        </a-col>

        <a-col :span="24">
          <a-form-item ref="images" label="Images" name="images">
            <a-upload-dragger
              :before-upload="beforeUploadReceipt"
              :fileList="formState.images"
              :remove="handleRemoveReceipt"
              accept="image/jpeg,image/png"
              multiple
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined />
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">Max file size: 10MB<br /></p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons-vue";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

// `title
// content/desc
// images
// employment type (multi select) -> employee, contractor
// role selection(web access)(multi select) ->  role_id
// position selection(from employee edit) (multi select)
// add notes: if nothing selected, send to all
// expiry date
// expiry time (maska)`
export default {
  mixins: [formMixins],

  components: {
    PlusOutlined,
    InboxOutlined,
  },
  data() {
    return {
      formState: {
        title: null,
        content: "",
        employment_type: null,
        role_ids: [],
        position_ids: [],
        expiry_date: null,
        expiry_time: null,
        images: [],
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...",
      },
      employmentType: this.$constants.employment_types,
      roles: [],
      employeePositions: [],
      options: [],
      infoText:
        "If no Employment Type, Role or Position is selected, the announcement will be displayed to all employees.",
      rules: {
        title: [
          {
            required: true,
            message: "This field is required!",
            transform(value) {
              return value.trim();
            },
          },
        ],
        content: [
          {
            required: true,
            message: "This field is required!",
            transform(value) {
              return value.trim();
            },
          },
        ],
        // images: [
        //   {
        //     required: true,
        //     message: "This field is required!",
        //     type: "array",
        //   },
        // ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  async mounted() {
    this.getFormState();
    [this.roles, this.employeePositions] = await Promise.all([
      this.$comman.getRoles(),
      this.$comman.getEmployeePositions(),
    ]);
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        title: this.record.title ?? null,
        content: this.record.content ?? "",
        employment_type: this.record?.employment_type ?? null,
        role_ids: this.record.roles?.map((i) => i.id) ?? [],
        position_ids: this.record?.employee_positions?.map((i) => i.id) ?? [],
        expiry_date: this.record.expiry_datetime
          ? moment(this.record.expiry_datetime)
          : null,
        expiry_time: this.record.expiry_datetime
          ? moment(this.record.expiry_datetime).format("HH:mm:ss")
          : null,
        images: this.record.id
          ? this.record.images.map((image) => {
              return {
                id: image.id,
                status: "Done",
                url: image.full_file_path,
                thumbUrl: image.full_file_path,
                name: image.file_name_with_ext,
              };
            })
          : [],
      };
    },

    onSubmit() {
      const formData = new FormData();
      if (this.record.id) {
        formData.append("id", this.record.id);
      }
      formData.append("title", this.formState.title);
      formData.append("content", this.formState.content);
      formData.append("employment_type", this.formState.employment_type ?? "");
      this.formState.position_ids.forEach((position_id, idx) => {
        formData.append(`position_ids[${idx}]`, position_id);
      });
      this.formState.role_ids.forEach((role_id, idx) => {
        formData.append(`role_ids[${idx}]`, role_id);
      });
      formData.append(
        "expiry_date",
        this.formState.expiry_date.format("YYYY-MM-DD")
      );
      formData.append("expiry_time", this.formState.expiry_time);

      const oldImages = [];
      const images = [];

      this.formState.images.forEach((image, idx) => {
        if (image.id) {
          oldImages.push(image.id);
        } else {
          images.push(image);
        }
      });

      oldImages.forEach((oldImage, idx) => {
        formData.append(`old_images[${idx}]`, oldImage);
      });

      images.forEach((image, idx) => {
        formData.append(`images[${idx}]`, image);
      });

      this.loadingStart();
      commonService
        .store(this.$constants.adminAnnouncementStoreUrl, formData)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },

    handleRemoveReceipt(file) {
      console.log({ file });
      this.formState.images = this.formState.images.filter(
        (image) => image.id !== file.id
      );
    },

    beforeUploadReceipt(file) {
      if (file.size < 10 * 1024 * 1024) {
        this.formState.images.push(file);
      } else {
        this.$message.error(`${file.name} size is more then 10MB`);
      }
      return false;
    },
  },
};
</script>
