<template>
  <a-card
    title="Employee Agreements Needing Review"
    size="small"
    :bodyStyle="{ height: '300px', overflowY: 'scroll' }"
  >
    <a-table
      :columns="columns"
      :rowKey="(record) => record.hospital_id"
      :dataSource="data"
      :pagination="paginationSettings"
      size="small"
      :scroll="{ x: true, y: 0 }"
    >
      <template #action="{ record }">
        <a-space :size="1">
          <a-tooltip
            :title="`${
              record.employee_position_id != null ? 'Update' : 'Create'
            } Offer Letter`"
          >
            <router-link
              target="_blank"
              :to="{
                name: 'candidate.create-offer-letter',
                params: { id: record.id },
              }"
            >
              <a-button type="link" size="small">
                <i class="ti ti-file-certificate ti-lg"></i>
              </a-button>
            </router-link>
          </a-tooltip>

          <a-tooltip title="Approve & Send Offer Letter">
            <a-button
              :loading="record.isMailSending"
              type="link"
              size="small"
              @click="sendOfferLetter(record)"
            >
              <i class="ti ti-mail ti-lg"></i>
            </a-button>
          </a-tooltip>

          <a-tooltip title="Offer Letter Preview">
            <a-button
              :loading="record.previewLoader"
              type="link"
              size="small"
              @click="offerLetterPreview(record)"
            >
              <i class="ti ti-file-text ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { commonService } from "../../services";
export default {
  props: {
    data: {
      default: [],
      type: Object,
    },
    paginationSettings: {
      type: Object,
    },
  },

  emits: ["update"],

  data() {
    return {
      columns: [
        {
          title: "Name",
          dataIndex: "full_name",
          key: "full_name",
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  methods: {
    sendOfferLetter(record) {
      record.isMailSending = true;
      commonService
        .store(this.$constants.candidateSendOfferLetterUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          delete record.isMailSending;
          if (record.status == 7) {
            this.$emit(
              "update",
              this.data.filter((e) => e.id != record.id)
            );
          }
        });
    },

    offerLetterPreview(record) {
      record.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.candidateOfferLetterPreviewUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          delete record.previewLoader;
        });
    },
  },
};
</script>
