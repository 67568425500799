<template>
  <a-layout-content>
    <a-card
      size="small"
      class="page-header"
      v-if="loading"
      :loading="loading"
    />

    <a-page-header
      v-else
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="$router.go(-1)"
    >
      <template #extra>
        <a-space>
          <a-dropdown-button v-if="activeTabKey == 'timeline'">
            Add
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" @click="followUpRecord">
                  Follow Up
                </a-menu-item>
                <a-menu-item key="2" @click="commentRecord">
                  Comment
                </a-menu-item>
                <!-- <a-menu-item key="3" @click="statusRecord">
                  Status
                </a-menu-item> -->
              </a-menu>
            </template>
          </a-dropdown-button>

          <a-button
            v-if="$comman.hasRole(1)"
            type="primary"
            @click="editRecord"
          >
            Edit
          </a-button>
        </a-space>
      </template>

      <template #title> {{ lead.first_name }} {{ lead.last_name }} </template>

      <template #tags>
        <a-tag color="pink"> {{ lead.lead_category?.name ?? "N/A" }} </a-tag>
        <a-tag color="red"> {{ lead.lead_source?.name ?? "N/A" }} </a-tag>
        <a-tag :color="leadStatusColor">
          {{ lead?.stages_of_lead?.name ?? "N/A" }}
        </a-tag>
      </template>

      <template #footer>
        <a-tooltip title="Probability">
          <a-progress
            :percent="lead?.stages_of_lead?.percentage ?? 0"
            stroke-color="#52c41a"
          />
        </a-tooltip>
        <a-tabs
          v-model:activeKey="activeTabKey"
          style="margin-bottom: 0"
          @tabChange="(key) => (activeTabKey = key)"
        >
          <a-tab-pane v-for="tab in tabList" :key="tab.key" :tab="tab.tab" />
        </a-tabs>
      </template>

      <a-row class="content" type="flex" :gutter="[16, 16]">
        <a-col>
          <a-space>
            <i class="ti ti-building ti-lg"></i>
            {{ lead.company_name ?? "N/A" }}
          </a-space>
        </a-col>

        <a-col>
          <a-space>
            <i class="ti ti-mail ti-lg"></i>
            <a-typography-link :href="`mailto:${lead.email}`">
              {{ lead.email }}
            </a-typography-link>
          </a-space>
        </a-col>

        <a-col>
          <a-space>
            <i class="ti ti-device-mobile ti-lg"></i>
            <a-typography-link :href="`tel:${lead.contact_no}`">
              {{ lead.contact_no }}
            </a-typography-link>
          </a-space>
        </a-col>

        <a-col v-if="lead.expected_close_date">
          <a-tooltip title="Expected Close Date">
            <a-space>
              <i class="ti ti-clock ti-lg"></i>
              {{ $customDate.mdy(lead.expected_close_date) }}
            </a-space>
          </a-tooltip>
        </a-col>

        <a-col v-if="lead.expected_value">
          <a-tooltip title="Est. Value">
            <a-space>
              <i class="ti ti-currency-dollar ti-lg"></i>
              {{
                lead.expected_value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </a-space>
          </a-tooltip>
        </a-col>

        <a-col v-if="lead.attachment">
          <a-space>
            <i class="ti ti-paperclip ti-lg"></i>
            <a
              :href="lead.attachment_file_path"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ lead.attachment }}
            </a>
          </a-space>
        </a-col>
      </a-row>
      <div class="mt-sm-1">
        <template v-if="lead.description">
          <b>"</b>{{ lead.description }}<b>"</b>
          <br />
        </template>
        <a-typography-text type="secondary">
          Created By
          <strong> {{ lead?.creator?.full_name ?? "N/A" }} </strong> | Sales
          Person
          <strong> {{ lead?.sales_person?.full_name ?? "N/A" }} </strong>
        </a-typography-text>
      </div>
    </a-page-header>

    <component
      v-if="is"
      :is="is"
      :lead="lead"
      @fetchLead="fetchLead"
      ref="child"
    >
      <router-view />
    </component>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import { route } from "../../../helper/route";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      loading: true,
      lead_id: this.$route.params.id,
      lead: {},
      activeTabKey: this.$route.query?.tab ?? "timeline",
      tabList: [
        {
          key: "timeline",
          tab: "Timeline",
        },
        {
          key: "tasks",
          tab: "Tasks",
        },
        {
          key: "files",
          tab: "Files",
        },
      ],
    };
  },

  mounted() {
    this.fetchLead();
  },

  computed: {
    leadStatusColor() {
      let per = this.lead?.stages_of_lead?.percentage ?? 0;
      if (per == 0) {
        return "error";
      } else if (per == 100) {
        return "success";
      } else {
        return "processing";
      }
    },

    is() {
      if (this.activeTabKey != "") {
        return route(`crm/lead/showTabs/${this.activeTabKey}`);
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchLead() {
      commonService
        .get(this.$constants.crmLeadShowUrl, { id: this.lead_id })
        .then((res) => {
          this.lead = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "crm.leads" });
        })
        .finally(() => (this.loading = false));
    },

    editRecord() {
      this.open({
        title: "Edit Lead",
        path: "crm.lead.store",
        callback: this.fetchLead,
        record: this.lead,
      });
    },

    followUpRecord() {
      this.open({
        title: "Follow Up",
        path: "crm.lead.drawerForms.followup",
        callback: () => {
          this.fetchLead();
          this.$refs.child.fetchLeadFollowUp();
        },
        extra: this.lead,
      });
    },

    commentRecord() {
      this.open({
        title: "Comment",
        path: "crm.lead.drawerForms.comment",
        callback: () => {
          this.fetchLead();
          this.$refs.child.fetchLeadFollowUp();
        },
        extra: this.lead,
      });
    },

    statusRecord() {
      this.open({
        title: "Status Change",
        path: "crm.lead.drawerForms.statusChange",
        callback: () => {
          this.fetchLead();
          this.$refs.child.fetchLeadFollowUp();
        },
        extra: this.lead,
      });
    },
  },
};
</script>
