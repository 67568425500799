<template>
  <a-layout-content>
    <page-header>
      <template #title> Employee Benefits </template>
    </page-header>
  </a-layout-content>

  <a-card size="small">
    <a-form :model="model" @finish="onSubmit">
      <a-form-item label="" name="employee_benefits">
        <ckeditor
          :editor="editor"
          v-model="model.employee_benefits"
          :config="editorConfig"
          :disabled="cardLoading"
        />
      </a-form-item>

      <a-button html-type="submit" type="primary" :loading="cardLoading">
        Submit
      </a-button>
    </a-form>
  </a-card>
</template>

<script setup>
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import { message } from "ant-design-vue";
import editor from "ckeditor5-custom-build/build/ckeditor";
import { onMounted, reactive, ref } from "vue";

const model = reactive({
  employee_benefits: "",
});
const cardLoading = ref(false);

const editorConfig = {
  placeholder: "Enter here...",
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "fontSize",
      "fontFamily",
      "|",
      "bulletedList",
      "insertTable",
      "numberedList",
      "|",
      "undo",
      "redo",
      "alignment",
      "removeFormat",
      "codeBlock",
      "selectAll",
      "findAndReplace",
    ],
  },
  table: {
    defaultHeadings: { rows: 1, columns: 1 },
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
    tableToolbar: ["bold", "italic", "alignment", "insertImage"],
  },
};

function fetchSetting() {
  return commonService
    .store(constants.getSettingUrl, { key: "employee_benefits" })
    .then((res) => {
      if (res.data.employee_benefits) {
        model.employee_benefits = res.data.employee_benefits;
      }
    })
    .catch((err) => {
      message.error(err.message);
    })
    .finally(() => {
      cardLoading.value = false;
    });
}

function onSubmit() {
  cardLoading.value = true;

  commonService
    .store(constants.settingStoreUrl, {
      employee_benefits: model.employee_benefits,
    })
    .then((res) => {
      message.success(res.message);
    })
    .catch((err) => {
      message.error(err.message);
    })
    .finally(() => (cardLoading.value = false));
}

onMounted(fetchSetting);
</script>

<style>
div > .ck-editor__editable {
  height: 400px;
}

/*.table {
  width: 100% !important;
}*/
</style>
