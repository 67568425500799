<template>
  <a-form
    name="live_charting_comment_form"
    ref="live_charting_comment_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          ref="comment"
          label="Event"
          name="comment"
          :rules="{
            required: true,
            message: 'This field must be required.',
            transform(value) {
              return value.trim();
            },
          }"
        >
          <a-textarea
            v-model:value="model.comment"
            :autoSize="{ minRows: 3 }"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Comment",
        comment: "",
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra?.item?.time) {
      this.model.unique_code = this.extra.item.unique_code;
      this.model.date = moment(this.extra.item.date, "MM-DD-YYYY");
      this.model.time = this.extra.item.time;
      this.model.comment = this.extra.item.comment;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      this.model.uuid = this.record.uuid;

      let data = JSON.parse(JSON.stringify(this.model));
      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.employee_id = !this.extra.item
        ? this.employee.id
        : this.extra.item.employee_id;
      data.employee_name = !this.extra.item
        ? this.employee.full_name
        : this.extra.item.employee_name;

      commonService
        .store(this.$constants.caseReportStoreHIPECRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
