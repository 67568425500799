<template>
  <div>
    Below is the list of credentialing documents that are required to be
    uploaded to your profile.
    <br />
    <br />
    <b>Note:</b>
    <br />
    Please upload all required credentialing documents to your KeyPort profile.
    Documents marked with an asterisk (<span style="color: red">*</span>) are
    required for full access. A document is considered satisfactory if
    associated with a green indicator, signifying compliance with our standards.
    If a document is linked to a red indicator, it indicates that the document
    is either missing or fails to meet the required standards and needs to be
    uploaded for review.
    <!-- Document types with an asterisk "<span style="color: red">*</span>" are mandatory to gain full access to KeyPort. -->
    <br />
    <br />

    <a-collapse v-model:activeKey="activeKey" expand-icon-position="left">
      <a-collapse-panel
        v-for="cer in certification_types"
        :key="cer.id"
        isActive
        :style="checkCertificateStatus(cer)"
      >
        <template #header>
          <a-typography-text
            type="danger"
            strong
            v-if="checkRequiredCertificate(cer)"
          >
            *
          </a-typography-text>
          {{ cer.name }}
          <a-popover
            v-if="cer.description"
            overlayClassName="overlay-popover-class"
          >
            <template #content>
              {{ cer.description }}
            </template>
            <a-button type="link" size="small">
              <i class="fa fa-info-circle"></i>
            </a-button>
          </a-popover>
        </template>

        <template v-if="cer.text_with_file_link">
          <div v-html="cer.text_with_file_link" />
        </template>

        <template
          v-if="
            cer.employeeCertificates != null &&
            Object.keys(cer.employeeCertificates).length > 0
          "
        >
          <div
            class="ant-table ant-table-scroll-position-left ant-table-small ant-table-bordered"
          >
            <div class="ant-table-content">
              <div class="ant-table-body" style="overflow-x: scroll">
                <table>
                  <thead class="ant-table-thead">
                    <tr>
                      <th>Uploaded At</th>
                      <th>State</th>
                      <th>Expiration Date</th>
                      <th>Approval Status</th>
                      <th>Attachment</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody class="ant-table-tbody">
                    <template
                      v-for="employeeCertificate in cer.employeeCertificates"
                      :key="employeeCertificate.id"
                    >
                      <tr>
                        <td>
                          {{ $customDate.ll(employeeCertificate.created_at) }}
                        </td>
                        <td>
                          {{ employeeCertificate.state ?? "N/A" }}
                        </td>
                        <td>
                          <template
                            v-if="employeeCertificate.expiry_date != null"
                          >
                            {{
                              $customDate.ll(employeeCertificate.expiry_date)
                            }}
                          </template>
                          <template v-else>N/A</template>
                        </td>
                        <td>
                          <a-space :gap="1">
                            <span>
                              {{
                                $comman.getValueFromObject(
                                  employeeCertificate,
                                  "approval_status",
                                  $constants.credentialApprovalStatuses
                                )
                              }}
                            </span>
                            <a-tooltip
                              :title="employeeCertificate.remarks ?? 'N/A'"
                            >
                              <InfoCircleOutlined
                                style="color: red"
                                v-if="employeeCertificate.approval_status === 2"
                              />
                            </a-tooltip>
                          </a-space>
                        </td>
                        <td>
                          <template
                            v-for="attach in employeeCertificate.attachments"
                            :key="attach.id"
                          >
                            <a
                              href="#"
                              @click.prevent="$comman.downloadItem(attach)"
                            >
                              {{ attach.name }}
                            </a>
                            <br />
                          </template>
                        </td>
                        <td>
                          <a-popconfirm
                            v-if="employeeCertificate.approval_status !== 1"
                            title="Are you sure you want to delete this document?"
                            placement="top"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="handleDelete(employeeCertificate)"
                          >
                            <a-button danger> Delete</a-button>
                          </a-popconfirm>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center text-weight-bold">No data found.</div>
        </template>
        <template #extra>
          <a-button
            type="primary"
            @click="
              handleClick({
                title: `Add New ${cer.name}`,
                path: `profile.certificate.store`,
                extra: { certificate: cer },
                callback: refreshCertificateData,
              })
            "
            size="small"
          >
            <PlusOutlined />
            Add
          </a-button>
        </template>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { companyService } from "@/services";
import {
  InfoCircleOutlined,
  PlusOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "certifications",

  components: {
    UploadOutlined,
    SettingOutlined,
    InfoCircleOutlined,
    PlusOutlined,
  },

  data() {
    return {
      certification_types: {},
      formState: {},
      activeKey: [],
      activeTableData: null,
    };
  },

  created() {
    const that = this;
    this.$comman.get_certification_types().then((res) => {
      that.certification_types = res;
      that.certification_types.forEach((v) => {
        that.formState[v.id] = {
          expiry_date: "",
          attachment: "",
        };
      });
      // this.activeKey = Object.keys(this.formState);
      this.getEmployeeCertificates();
    });
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
  },

  emits: ["refreshCertificateData"],

  methods: {
    ...mapActions("drawer", ["open"]),
    ...mapActions("auth", ["login"]),

    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    handleChangeLicenseAttachment(info) {
      var isJPG = false;
      var isLt2M = false;
      var validEx = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
      if (info.file.status == "done") {
        isJPG = validEx.indexOf(info.file.type) == -1 ? false : true;
        isLt2M = info.file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error(
            `You can only upload ${validEx.toString(",")} file!`
          );
        } else if (!isLt2M) {
          this.$message.error("Image must smaller than 2MB!");
        }
        if (isJPG && isLt2M) {
          this.$comman.getBase64(info.file.originFileObj, (base64Url) => {
            info.file.thumbUrl = base64Url;
          });
          this.formState.social_security_attachment = [info.file];
        } else {
          this.formState.social_security_attachment = info.fileList[1]
            ? [info.fileList[0]]
            : [];
        }
      }
    },

    handleClick(data) {
      window.event.stopPropagation();
      this.open(data);
      this.activeKey = data.extra.certificate.id;
    },

    refreshCertificateData() {
      this.getEmployeeCertificates().then((res) => {
        this.$emit("refreshCertificateData", { credentials: res });
      });
    },

    getEmployeeCertificates() {
      return companyService
        .store(this.$constants.getEmployeeCertificates, {})
        .then((res) => {
          if (res.success) {
            const data = res.data.credentials.filter((cert) => {
              // approval_status === 2 means rejected
              if (cert.approval_status === 2) return false;
              if (!cert.expiry_date) return true;
              return moment(cert.expiry_date).isAfter(
                moment().subtract(1, "days")
              );
            });
            const that = this;
            this.certification_types.forEach(function (value, index) {
              that.certification_types[index].employeeCertificates =
                data.filter((d) => d.certification_type_id === value.id);
            });

            this.certification_types.sort((a, b) => {
              if (
                this.checkRequiredCertificate(a) &&
                !a.employeeCertificates.length // has not uploaded or has expired
              )
                return -1;
              if (
                this.checkRequiredCertificate(b) &&
                !b.employeeCertificates.length // has not uploaded or has expired
              )
                return 1;

              return 0;
            });

            // open required and empty panels
            this.activeKey = this.certification_types
              .filter(
                (cer) =>
                  this.checkRequiredCertificate(cer) &&
                  !cer.employeeCertificates.length
              )
              .map((cer) => cer.id);

            return data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleDelete(certificate) {
      this.$message.loading("Action is processing", 0);
      companyService
        .store(this.$constants.deleteEmployeeCertificates, {
          id: certificate.id,
        })
        .then((res) => {
          if (res.success) {
            this.$message.destroy();
            this.$message.success(res.message);
            this.getEmployeeCertificates().then((res) => {
              this.$emit("refreshCertificateData", { credentials: res });
            });
            this.login({ isInitStore: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    checkRequiredCertificate(cer) {
      const employeeTypesCols = this.$constants.employee_types.reduce(
        (holder, currentEmpType) => {
          holder[currentEmpType.value] = currentEmpType.key;
          return holder;
        },
        {}
      );

      // const employeeTypesCols = {
      //   1: 'for_clinical',
      //   2: 'for_non_clinical',
      //   ...
      // }

      return cer[employeeTypesCols[this.employee.employee_type]];
    },

    checkCertificateStatus(cer) {
      let bool =
        cer.employeeCertificates != null &&
        Object.keys(cer.employeeCertificates).length > 0;

      if (bool) {
        let empCer = cer.employeeCertificates.sort((a, b) => {
          if (a.expiry_date && b.expiry_date) {
            return moment(a.expiry_date).isBefore(b.expiry_date) ? -1 : 1;
          }
          return 1;
        });

        let lastEmpCer = empCer[empCer.length - 1];
        if (lastEmpCer && lastEmpCer.expiry_date) {
          bool = bool && moment().isBefore(lastEmpCer.expiry_date);
        }
      }

      const certificates = cer.employeeCertificates;

      if (!certificates)
        return {
          "background-color": "#e6aaaa",
        };

      function checkStatus(type) {
        return certificates.some((certificate) => {
          return certificate.approval_status === type;
        });
      }

      const isPending = checkStatus(0);
      const isApproved = checkStatus(1);
      const isRejected = checkStatus(2);
      const isExpiredAndRequired = !(
        bool || !this.checkRequiredCertificate(cer)
      );

      if (isApproved) {
        return "background-color: #8dde98";
      } else if (isPending) {
        return "background-color: #e6e6aa";
      } else if (isExpiredAndRequired || isRejected) {
        return "background-color: #e6aaaa";
      } else {
        return "background-color: #8dde98";
      }
    },
  },
  watch: {
    activeKey(val) {
      // console.log(this.certification_types);
      // console.log(val);
    },
  },
};
</script>

<style></style>
