<template>
  <a-descriptions
    title="Basic Information"
    size="small"
    bordered
    :column="2"
    :labelStyle="{ width: '160px' }"
  >
    <a-descriptions-item label="Date" :span="2">
      {{ $customDate.mdy(record.date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Facility" :span="2">
      {{ record.facility }}
    </a-descriptions-item>
    <a-descriptions-item label="MR Number">
      {{ record.mr_number }}
    </a-descriptions-item>
    <a-descriptions-item label="Account Number">
      {{ record.account_number }}
    </a-descriptions-item>
    <a-descriptions-item label="Patient First Name">
      {{ record.patient_first_name }}
    </a-descriptions-item>
    <a-descriptions-item label="Patient Last Name">
      {{ record.patient_last_name }}
    </a-descriptions-item>
    <a-descriptions-item label="Allergies" :span="2">
      {{ record.allergies ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Height (cm)">
      {{ record.height ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Weight (kg)">
      {{ record.weight ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Body Surface">
      {{ record.body_surface ?? "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Blood Type">
      {{ record.blood_type ?? "N/A" }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
  },
};
</script>
