<template>
  <div>
    <a-descriptions :column="2" size="small" bordered>
      <a-descriptions-item :span="2" label="Hospital Name">
        {{ record.hospital?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Shift Name">
        {{ record.shift_type?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Start Date & Time">
        {{ $customDate.mdy(record.start_date) }}
        {{ $customDate.hi(record.start_time) }}
      </a-descriptions-item>

      <a-descriptions-item label="End Date & Time">
        {{ $customDate.mdy(record.end_date) }}
        {{ $customDate.hi(record.end_time) }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Total Time (In Hours)">
        {{ parseFloat(record.total_time).toFixed(2) }}
      </a-descriptions-item>

      <template
        v-if="$constants.timeTrackingOnCall.includes(record.shift_type_id)"
      >
        <a-descriptions-item
          :span="2"
          label="Was on-call coverage also provided on the same day?"
        >
          {{ record.was_on_call == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <template v-if="record.was_on_call">
          <a-descriptions-item label="Total On-Call Time (In Hours)">
            {{ parseFloat(record.total_on_call_time).toFixed(2) }}
          </a-descriptions-item>

          <a-descriptions-item label="On-Call End Date & Time">
            {{ $customDate.mdy(record.on_call_end_date) }}
            {{ $customDate.hi(record.on_call_end_time) }}
          </a-descriptions-item>
        </template>
      </template>

      <template v-if="record.was_travel">
        <a-descriptions-item
          :span="2"
          label="Is travel compensation available for hospital?"
        >
          {{ record.was_travel == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <a-descriptions-item label="Home to Hospital">
          {{ $customDate.hi(record.home_departure) }} -
          {{ $customDate.hi(record.hospital_arrival) }}
        </a-descriptions-item>

        <a-descriptions-item label="Hospital to Home">
          {{ $customDate.hi(record.hospital_departure) }} -
          {{ $customDate.hi(record.home_arrival) }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="Total Travel Time (In Hours)">
          {{ parseFloat(record.total_travel_time).toFixed(2) }}
        </a-descriptions-item>
      </template>

      <template v-if="record.shift_type_id == 4">
        <a-descriptions-item :span="2" label="Number of patients on ECMO">
          {{ record.no_of_ecmo ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="MR no(s) of patients on ECMO">
          {{ record.mr_ecmo ?? "N/A" }}
        </a-descriptions-item>
      </template>

      <a-descriptions-item :span="2" label="Notes">
        {{ record.notes ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>

    <template v-if="record.status != 1">
      <a-divider orientation="left">Status Info</a-divider>
      Your Time Entry has been
      {{ record.status == 3 ? "rejected" : "approved" }}. Please direct any
      questions to timetracking@keystoneperfusion.com.

      <template v-if="record.status != 3">
        <a-divider orientation="left">Payment Info</a-divider>
        <a-table
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="false"
          size="small"
          :scroll="scroll"
        >
        </a-table>

        <a-descriptions bordered :column="1" size="small">
          <a-descriptions-item
            label="Total:"
            :contentStyle="{ width: '125px', paddingLeft: '8px' }"
            :labelStyle="{ textAlign: 'right', fontWeight: 'bold' }"
          >
            <span class="text-bold">
              {{ $comman.withCurrency(record.total_amount) }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      columns: [
        {
          title: "Job Name",
          dataIndex: "job_name",
          key: "job_name",
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
        },
        {
          title: "Subtotal",
          dataIndex: "total",
          key: "total",
        },
        {
          title: "Additional Amount",
          dataIndex: "additional_amount",
          key: "additional_amount",
        },
        {
          title: "Total Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          width: 125,
        },
      ],
      scroll: { x: true, y: 0 },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
    dataSource() {
      if (this.record.payments) {
        return this.record.payments.map((item) => {
          console.log(item);
          return {
            id: item.id,
            job_name: item.json?.employee_job?.title ?? "",
            rate: this.$comman.withCurrency(item.rate),
            hours: item.hour,
            total: this.$comman.withCurrency(item.amount),
            additional_amount: this.$comman.withCurrency(
              item.additional_amount
            ),
            total_amount: this.$comman.withCurrency(item.total_amount),
          };
        });
      }
      return [];
    },
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
