<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-form
      ref="caseReportForm"
      :model="model"
      layout="vertical"
      :rules="rules"
    >
      <a-card size="small" hoverable :loading="cardLoading">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item ref="date_range" label="Date Range" name="date_range">
              <a-range-picker
                :allowClear="false"
                v-model:value="model.date_range"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                inputReadOnly
                :ranges="ranges"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="hospital_ids"
              label="Hospitals"
              name="hospital_ids"
            >
              <a-select
                v-model:value="model.hospital_ids"
                show-search
                optionFilterProp="label"
                allowClear
                placeholder="Select any..."
                mode="multiple"
              >
                <a-select-option
                  v-for="option in hospitals"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="Does this facility have NRP enabled?"
              name="only_nrp"
            >
              <a-radio-group v-model:value="model.only_nrp">
                <a-radio :value="true">Yes</a-radio>
                <a-radio :value="false">No</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

        <a-space>
          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="exportExcel"
          >
            Export Excel
          </a-button>

          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="exportCsv"
          >
            Export CSV
          </a-button>
        </a-space>
      </a-card>
    </a-form>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      model: {
        date_range: [null, null],
        hospital_ids: [],
        only_nrp: null,
      },
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "array",
            len: 2,
            fields: {
              0: {
                type: "date",
                required: true,
                message: "This field is required!",
              },
              1: { type: "date", required: true, message: "" },
            },
          },
        ],
        only_nrp: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "boolean",
          },
        ],
        hospital_ids: {
          required: true,
          message: "This field is required!",
          trigger: "change",
          type: "array",
        },
      },
      hospitals: [],
    };
  },

  computed: {
    //
  },

  async mounted() {
    await this.getHospitals();
    this.cardLoading = false;
  },

  methods: {
    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => this.$message.error(error));
    },

    formData() {
      return {
        dates: [
          this.$customDate.ymd(this.model.date_range[0]),
          this.$customDate.ymd(this.model.date_range[1]),
        ],
        hospital_ids: this.model.hospital_ids,
        only_nrp: this.model.only_nrp,
      };
    },

    dateString() {
      return (
        this.model.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.model.date_range[1].clone().format("MMDDYY")
      );
    },

    exportExcel() {
      this.$refs.caseReportForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportCaseReport,
            { ...this.formData(), excel_export: true },
            "case-report-" + this.dateString() + ".xlsx"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    exportCsv() {
      this.$refs.caseReportForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportCaseReport,
            { ...this.formData(), csv_export: true },
            "case-report-" + this.dateString() + ".csv"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },
  },
};
</script>
