<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Time Tracking </template>

        <template #buttons>
          <template v-if="showNotificationButton">
            <a-popconfirm
              title="Are you sure you want to send payroll notification to all employees?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="sendPayrollNotification"
            >
              <a-button type="primary" :loading="btnLoading">
                Send Payroll Notification
              </a-button>
            </a-popconfirm>
          </template>
          <template v-else-if="next_payroll_notification_date">
            <a-tag color="blue">
              Next payroll notification sending date is:
              <b>
                {{ $customDate.mdy(next_payroll_notification_date) }}
              </b>
            </a-tag>
          </template>
        </template>
      </page-header>

      <a-spin :spinning="datatableLoading">
        <a-card
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="(key) => (activeTabKey = key)"
          size="small"
        >
          <template #title>
            <a-row :gutter="[16, 0]">
              <a-col :span="6">
                <a-range-picker
                  v-model:value="filters.date_range"
                  :format="$constants.datepickerFormat"
                  :placeholder="[
                    $constants.datePickerPlaceholder,
                    $constants.datePickerPlaceholder,
                  ]"
                />
              </a-col>
              <a-col :span="6">
                <a-input
                  v-model:value="filters.search_text"
                  placeholder="Search hospital or employee here..."
                  allowClear
                />
              </a-col>
              <a-col :span="12">
                <a-space>
                  <a-button type="primary" @click="fetchData()">
                    Search
                  </a-button>
                  <a-button ghost danger @click="reset()">Reset</a-button>
                  <a-button type="primary" @click="handleExportEvent('pdf')">
                    Export PDF
                  </a-button>
                  <a-button type="primary" @click="handleExportEvent('excel')">
                    Export Excel
                  </a-button>
                </a-space>
              </a-col>
            </a-row>
          </template>

          <template #customRender="item">
            {{ item.text }} ({{ item.counts }})
          </template>

          <a-row class="mb-sm-1" justify="space-between">
            <div v-if="activeTabKey == 2" style="display: flex">
              <a-select
                v-model:value="bulkActionValue"
                placeholder="Select any one"
                style="width: 150px; margin-right: 20px"
              >
                <a-select-option value="4"> Mark As Paid </a-select-option>
              </a-select>

              <a-button
                html-type="button"
                type="primary"
                :disabled="
                  this.bulkActionValue == null ||
                  this.selectedRowKeys.length == 0
                "
                @click="bulkAction"
              >
                Submit
              </a-button>
            </div>
          </a-row>

          <a-table
            :row-selection="rowSelection"
            :columns="columns"
            :rowKey="(record, index) => record.id"
            :dataSource="dataSource"
            :pagination="pagination"
            :loading="datatableLoading"
            @change="handleTableChange"
            @refresh="fetchData"
            size="small"
            :scroll="scroll"
          >
            <template #filterDropdown="filterDropdown">
              <xFilterInputSearchDropdown
                :filterDropdown="filterDropdown"
                @handleSearch="handleDatatableSearch"
                @handleReset="handleDatatableReset"
              />
            </template>

            <template #filterIcon="filterIcon">
              <xFilterIcon :filterIcon="filterIcon" />
            </template>

            <template #employment_type="{ record }">
              <a-tag
                :color="
                  $comman.getValueFromObject(
                    record.employee,
                    'employment_type',
                    $constants.employment_types,
                    'color'
                  )
                "
              >
                {{
                  $comman.getValueFromObject(
                    record.employee,
                    "employment_type",
                    $constants.employment_types
                  )
                }}
              </a-tag>
            </template>

            <template #action="{ record }">
              <a-space :size="1">
                <a-tooltip title="View">
                  <a-button
                    type="link"
                    size="small"
                    class="pl-sm-0 primary-color"
                    @click="handleShowTimeTrackingEvent(record)"
                  >
                    <i class="ti ti-eye ti-lg"></i>
                  </a-button>
                </a-tooltip>

                <template v-if="[1, 2].includes(record.status)">
                  <router-link
                    :to="{
                      name: 'admin-update-employee-time-track',
                      params: { id: record.id },
                    }"
                  >
                    <a-tooltip title="Edit">
                      <a-button type="link" size="small">
                        <i class="ti ti-pencil ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </router-link>
                </template>

                <a-popconfirm
                  v-if="record.status == 2"
                  title="Are you sure you want to revert this record to pending state?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="revertToPending(record.id)"
                >
                  <a-tooltip title="Revert to Pending">
                    <a-button type="link" size="small">
                      <i class="ti ti-rotate ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </a-popconfirm>
              </a-space>
            </template>
          </a-table>
        </a-card>
      </a-spin>
    </a-layout-content>
  </div>
</template>

<script>
import xFilterIcon from "@/components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "@/components/table/filterInputSearchDropdown.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  components: { xFilterInputSearchDropdown, xFilterIcon },
  data() {
    return {
      btnLoading: false,
      next_payroll_notification_date: null,
      shiftTypes: [],
      filters: {
        date_range: [],
        search_text: "",
        page:
          this.$route.query && this.$route.query.page
            ? Number(this.$route?.query?.page)
            : 1,
        pageSize:
          this.$route.query && this.$route.query.pageSize
            ? Number(this.$route?.query?.pageSize)
            : 10,
        sortField: this.$route.query?.sortField ?? "start_date",
        sortOrder: this.$route.query?.sortOrder ?? "descend",
      },
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types,
          slots: {
            customRender: "employment_type",
          },
        },
        {
          title: "Shift Type",
          dataIndex: "shift_type",
          key: "shift_type",
          customRender: ({ record }) => record.shift_type?.name,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          width: 100,
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "start_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
          defaultSortOrder:
            this.$route.query.sortField == "end_date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Total Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        {
          title: " Total Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          sorter: true,
          customRender: ({ record }) =>
            this.$comman.withCurrency(
              parseFloat(
                record.payments.reduce(
                  (pre, curr) => pre + curr.total_amount,
                  0
                )
              ).toFixed(2)
            ),
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.timeTrackingStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      datatableLoading: true,
      dataSource: [],
      scroll: { x: true, y: 0 },
      pagination: {
        current:
          this.$route.query && this.$route.query.page
            ? Number(this.$route?.query?.page)
            : 1,
        size: "normal",
        pageSize:
          this.$route.query && this.$route.query.pageSize
            ? Number(this.$route?.query?.pageSize)
            : 10,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50", "500", "1000"],
        showSizeChanger: true,
      },

      selectedRowKeys: [],
      bulkActionValue: null,
    };
  },

  mounted() {
    Promise.all([this.fetchShiftTypes(), this.fetchData()]);
  },

  computed: {
    rowSelection() {
      return ["2"].includes(this.activeTabKey)
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    showNotificationButton() {
      return moment(this.next_payroll_notification_date).isSame(
        moment().format("YYYY-MM-DD")
      );
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchShiftTypes() {
      commonService.get(this.$constants.getShiftTypesUrl).then((res) => {
        this.shiftTypes = res.data;
        this.columns[3].filters = this.shiftTypes.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      });
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
      delete this.filters.employee_name;
      delete this.filters.hospital_name;
      delete this.filters.employment_type;
      delete this.filters.shift_type;

      console.log({ filters: this.filters });
      this.fetchData();
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    fetchData() {
      let data = {
        ...this.filters,
        status: this.activeTabKey,
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
      };
      this.$router.replace({ query: data });
      this.datatableLoading = true;
      commonService
        .get(this.$constants.adminEmployeeTimeTrackDatatableUrl, data)
        .then((response) => {
          let res = response.data;
          this.pagination = { ...this.pagination, total: res.data.total };
          this.tabItemCounts(res.counts);
          this.dataSource = res.data.data;
          this.next_payroll_notification_date =
            res.next_payroll_notification_date ?? null;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.datatableLoading = false));
    },

    tabItemCounts(counts) {
      if (this.tabList) {
        this.tabList.forEach((v, i) => {
          v.counts = counts[v.status] ?? 0;
        });
      }
    },

    handleTableChange(pagination, filtr, sorter) {
      this.pagination = pagination;

      const cleanedFilters = Object.keys(filtr).reduce((acc, key) => {
        if (!filtr[key].length) {
          delete this.filters[key];
        }

        if (filtr[key] !== undefined && filtr[key].length > 0) {
          acc[key] = filtr[key];
        }
        return acc;
      }, {});

      this.filters = {
        ...this.filters,
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortField: sorter.order ? sorter.field : undefined,
        sortOrder: sorter.order,
        ...cleanedFilters,
      };
      this.fetchData();
    },

    reset() {
      this.filters.date_range = [];
      this.filters.search_text = "";
      this.filters.page = 1;
      this.fetchData();
    },

    handleShowTimeTrackingEvent(record) {
      this.open({
        title: "Show Time Tracking Details",
        path: "admin.employeeTimeTrack.show",
        callback: this.fetchData,
        record: record,
      });
    },

    handleExportEvent(type) {
      let url = this.$constants.adminEmployeeTimeTrackExportPdfUrl;
      let fileName = "Employee Time Tracking.pdf";
      if (type == "excel") {
        url = this.$constants.adminEmployeeTimeTrackExportExcelUrl;
        fileName = "Employee Time Tracking.xlsx";
      }

      var params = {
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
        status: this.activeTabKey,
      };

      if (this.filters.employee_name) {
        params.employee_name = this.filters.employee_name;
      }
      if (this.filters.hospital_name) {
        params.hospital_name = this.filters.hospital_name;
      }
      if (this.filters.employment_type) {
        params.employment_type = this.filters.employment_type;
      }
      if (this.filters.shift_type) {
        params.shift_type = this.filters.shift_type;
      }

      this.datatableLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    bulkAction() {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminEmployeeTimeTrackBulkActionUrl, {
          ids: this.selectedRowKeys,
          status: this.bulkActionValue,
        })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.fetchData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendPayrollNotification() {
      this.btnLoading = true;
      commonService
        .store(this.$constants.adminEmployeeTimeTrackSendPayrollNotificationUrl)
        .then((res) => {
          this.$message.success(res.message);
          this.next_payroll_notification_date = res.data.date;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.btnLoading = false));
    },

    revertToPending(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminEmployeeTimeTrackStatusChangeToPendingUrl, { id })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.fetchData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    activeTabKey() {
      this.pagination = { ...this.pagination, current: 1 };
      this.filters.page = 1;
      this.fetchData();
    },
  },
};
</script>
