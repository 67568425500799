<template>
  <a-layout-content>
    <page-header>
      <template #title> Employees </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #role_name="{ record }">
          <a-tag color="cyan">
            {{ role_name(record.user ? record.user.role_id : "N/A") }}
          </a-tag>
        </template>

        <template #employee_name="{ record }">
          <a-space>
            <a-tooltip
              :title="
                !!record.profile_status
                  ? 'All required credentials submitted.'
                  : 'Some of required credentials have expired or are missing.'
              "
            >
              <a-badge
                :status="!!record.profile_status ? 'success' : 'error'"
                dot
              />
            </a-tooltip>
            <span>{{ record.full_name }}</span>
          </a-space>
        </template>

        <template #employment_type="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'employment_type',
                $constants.employment_types,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "employment_type",
                $constants.employment_types
              )
            }}
          </a-tag>
        </template>

        <template #employee_type="{ record }">
          <a-tag
            :color="$comman.get_employee_type(record.employee_type, 'color')"
          >
            {{ $comman.get_employee_type(record.employee_type, "text") }}
          </a-tag>
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <router-link
              :to="{
                name: 'manager.show-employee',
                params: { id: record.id },
              }"
            >
              <a-tooltip title="View">
                <a-button type="link" size="small">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>
          </template>

          <template v-else>
            <!--  -->
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.managerEmployeeUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "first_name",
          key: "first_name",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "employee_name",
          },
          defaultFilteredValue: this.$route.query?.first_name
            ? [this.$route.query?.first_name]
            : undefined,
          defaultSortOrder: this.$route.query?.sortField
            ? this.$route.query?.sortField == "first_name"
              ? this.$route.query.sortOrder
              : undefined
            : "ascend",
        },
        {
          title: "Employee Code",
          dataIndex: "employee_code",
          key: "employee_code",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_code
            ? [this.$route.query?.employee_code]
            : undefined,
          defaultSortOrder:
            this.$route.query?.sortField == "employee_code"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Web Access",
          dataIndex: "role_name",
          key: "role_name",
          sorter: false,
          slots: {
            customRender: "role_name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.role_name
            ? [this.$route.query?.role_name]
            : undefined,
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types,
          filterMultiple: false,
          slots: {
            customRender: "employment_type",
          },
          defaultFilteredValue: this.$route.query?.employment_type
            ? [parseInt(this.$route.query?.employment_type)]
            : undefined,
        },
        {
          title: "Profession Type",
          dataIndex: "employee_type",
          key: "employee_type",
          filters: this.$constants.employee_types,
          filterMultiple: false,
          slots: {
            customRender: "employee_type",
          },
          defaultFilteredValue: this.$route.query?.employee_type
            ? [parseInt(this.$route.query?.employee_type)]
            : undefined,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
          defaultSortOrder:
            this.$route.query?.sortField == "created_at"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        first_name: this.$route.query?.first_name
          ? [this.$route.query?.first_name]
          : undefined,
        employee_code: this.$route.query?.employee_code
          ? [this.$route.query?.employee_code]
          : undefined,
        role_name: this.$route.query?.role_name
          ? [this.$route.query?.role_name]
          : undefined,
        employment_type: this.$route.query?.employment_type
          ? [this.$route.query?.employment_type]
          : undefined,
        employee_type: this.$route.query?.employee_type
          ? [this.$route.query?.employee_type]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : "first_name",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : "ascend",
      },
      gustoImportLoading: false,
      quickbooksSyncLoading: false,
      roles: [],
      routes: [],
    };
  },

  mounted() {
    Promise.all([this.$comman.getRoutes(), this.$comman.getRoles()]).then(
      (res) => {
        this.routes = res[0];
        this.roles = res[1];
      }
    );
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    role_name(id) {
      let name = "N/A";
      if (this.roles.length > 0) {
        this.roles.forEach((role) => {
          if (role.id == id) {
            name = role.name;
          }
        });
      }
      return name;
    },

    handleEmployeeRoleEditEvent(record) {
      this.open({
        title: "Edit Employee Role and Rights",
        path: "employee.roleAndRights",
        callback: this.refresh,
        record: record,
        extra: { routes: this.routes, roles: this.roles },
      });
    },

    resetPassword(record) {
      this.open({
        title: "Reset Password",
        path: "employee.resetPassword",
        callback: this.refresh,
        record: record,
      });
    },

    gustoEmployeeImport() {
      this.gustoImportLoading = true;
      commonService
        .store(this.$constants.importEmployeeFromGustoUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.gustoImportLoading = false;
          this.refresh();
        });
    },

    quickbooksEmployeeSync() {
      this.quickbooksSyncLoading = true;
      commonService
        .store(this.$constants.syncVendorFromQuickbooksUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.quickbooksSyncLoading = false;
          this.refresh();
        });
    },

    sendWelcomeEmail(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.employeeSendWelcomeEmailUrl, { id: record.id })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },

    sendCredentialRemainderMail(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.employeeSendCredentialRemainderMailUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },

    handleExportExcelEvent() {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.employeeExportExcelUrl,
          {},
          "Employees.xlsx"
        )
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },
  },
};
</script>
