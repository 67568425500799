<template>
  <a-card title="Coverage Map" size="small">
    <template #extra>
      <a-radio-group v-model:value="activeViewRadio">
        <a-radio value="hospitals"> Hospitals</a-radio>
        <a-radio value="employees"> Employees</a-radio>
        <a-radio value="contractors"> Contractors</a-radio>
      </a-radio-group>
    </template>

    <div style="padding: 14px; width: 100%">
      <a-space direction="vertical" align="center" style="width: 100%">
        <a-spin v-if="loading" />
      </a-space>
      <div ref="chartContainer" id="chartContainer"></div>
    </div>
  </a-card>
</template>

<script setup>
import { map as am5geodata_usaLow } from "@/components/dashboard/perStateMapData.js";
import { constants } from "@/helper/constants";
import StatesData from "@/helper/dashboard_state_wise_chart_country.json";
import { commonService } from "@/services";
import * as am5 from "@amcharts/amcharts5";
import { Theme as am5themes_Animated } from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import { computed, onMounted, ref, watch } from "vue";

const data = ref([]);
const activeViewRadio = ref("hospitals");
const activeViews = computed(() => [activeViewRadio.value]);

function getFormattedTooltipText() {
  return (
    "{name}:\n" +
    activeViews.value
      .map((view) => {
        const title = view.charAt(0).toUpperCase() + view.slice(1);

        return `[bold]{${activeViewRadio.value}} ${title}[/]`;
      })
      .join("\n")
  );
}

const classState = ref(null);

function prepareResponseData({ master_cities, employees, hospitals }) {
  return StatesData.map((state) => {
    const emp = employees.filter(
      (emp) => emp.state === state.name && emp.employment_type === 1
    );
    const con = employees.filter(
      (emp) => emp.state === state.name && emp.employment_type === 2
    );
    const hos = hospitals.filter((hos) => hos.state === state.name);

    state["data"] = {
      hospitals: hos.length,
      employees: emp.length,
      contractors: con.length,
    };

    const empCities = emp.map(({ city }) => city);
    const conCities = con.map(({ city }) => city);
    const hosCities = hos.map(({ city }) => city);

    const cities = [...empCities, ...conCities, ...hosCities].reduce(
      (acc, city) => {
        if (!acc.includes(city)) {
          acc.push(city);
        }
        return acc;
      },
      []
    );

    state["markerData"] = cities.map((city) => {
      const masterCity = master_cities.find(
        (masterCity) =>
          masterCity.name === city && masterCity.state === state.name
      );

      return {
        target: city,
        type: "city",
        name: city,
        state: state.name,
        data: {
          employees: emp.filter((emp) => emp.city === city).length,
          contractors: con.filter((con) => con.city === city).length,
          hospitals: hos.filter((hos) => hos.city === city).length,
        },
        geometry: {
          type: "Point",
          coordinates: [
            masterCity ? masterCity.lng : null,
            masterCity ? masterCity.lat : null,
          ],
        },
      };
    });

    return state;
  });
}

function fetchData() {
  loading.value = true;
  return commonService
    .store(constants.getDashboardStateWiseDataUrl, { raw_data: true })
    .then((res) => {
      data.value = prepareResponseData(res.data);
      loading.value = false;
      am5.ready(() => {
        if (document.getElementById("chartContainer")) {
          classState.value = new MapChartManager("chartContainer", data.value);
        }
      });
    })
    .catch((err) => {
      console.log({ err });
    })
    .finally(() => {});
}

const loading = ref(false);
onMounted(fetchData);

watch(activeViews, (currentView) => {
  if (classState.value) {
    classState.value.updateSeries();
  }
});

class MapChartManager {
  constructor(containerId, data) {
    this.root = am5.Root.new(containerId);
    this.chartContainer = document.getElementById(containerId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.setupChart();
    this.setupZoomControls();
    this.setupStores(data);
    this.selectedState = null;
    this.currentSeries = this.regionalSeries.US.series;
  }

  setupChart() {
    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        panX: "rotateX",
        projection: am5map.geoAlbersUsa(),
      })
    );

    // Create polygon series
    this.polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        geoJSON: am5geodata_usaLow,
      })
    );

    this.polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
    });

    this.polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x297373),
    });
  }

  setupZoomControls() {
    this.zoomOutButton = this.root.tooltipContainer.children.push(
      am5.Button.new(this.root, {
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        dx: -20,
        dy: 20,
        themeTags: ["zoom"],
        icon: am5.Graphics.new(this.root, {
          themeTags: ["button", "icon"],
          strokeOpacity: 0.7,
          draw: function (display) {
            display.moveTo(0, 0);
            display.lineTo(12, 0);
          },
        }),
      })
    );

    this.zoomOutButton.get("background").setAll({
      cornerRadiusBL: 40,
      cornerRadiusBR: 40,
      cornerRadiusTL: 40,
      cornerRadiusTR: 40,
    });

    this.zoomOutButton.events.on("click", () => {
      // if (this.currentSeries) {
      //   this.currentSeries.hide();
      // }
      //
      // if (this.selectedState) {
      //   this.selectedState = null;
      // }
      //
      // this.chart.goHome();
      // this.zoomOutButton.hide();
      // this.currentSeries = this.regionalSeries.US.series;
      // this.currentSeries.show();

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root.dom.id == "chartContainer") {
          root.dispose();
        }
      });

      classState.value = new MapChartManager("chartContainer", data.value);
    });

    this.zoomOutButton.hide();
  }

  setupStores(inpData, key = activeViewRadio.value) {
    const data = inpData.filter(
      (state) => state.data[activeViewRadio.value] > 0
    );
    // Assuming `this.regionalSeries` is defined at the class level to hold the series data
    if (!this.regionalSeries) {
      this.regionalSeries = {};
    }

    // Clear any existing series data to avoid duplicates
    if (this.regionalSeries.US && this.regionalSeries.US.series) {
      this.regionalSeries.US.series.data.clear();
    } else {
      // Initialize US series if not already done
      this.regionalSeries.US = {
        markerData: [],
        series: null, // Will be initialized later when needed
      };
    }

    // Process each state from the API response
    am5.array.each(data, (stateData) => {
      // Check if state series exists, if not, initialize it
      if (!this.regionalSeries[stateData.target]) {
        this.regionalSeries[stateData.target] = {
          target: stateData.target,
          type: "state",
          name: stateData.name,
          state: stateData.target,
          data: stateData.data, // Store detailed data
          hospitals: stateData.data.hospitals,
          employees: stateData.data.employees,
          contractors: stateData.data.contractors,
          markerData: [], // Initialize empty array for cities
          geometry: stateData.geometry,
        };
        this.regionalSeries.US.markerData.push(
          this.regionalSeries[stateData.target]
        ); // Add state to US markerData
      }

      // Process each city within the state
      am5.array.each(stateData.markerData, (cityData) => {
        // Convert coordinates from string to number
        const coords = cityData.geometry.coordinates.map(Number);
        // const dynamicSum = this.calculateDynamicSum(
        //   cityData,
        //   activeViews.value
        // );

        // if (dynamicSum <= 0) return;

        // Check if city series exists, if not, initialize it
        if (!this.regionalSeries[cityData.target]) {
          this.regionalSeries[cityData.target] = {
            target: cityData.target,
            type: "city",
            name: cityData.name,
            state: cityData.state,
            data: cityData.data, // Store detailed data
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            markerData: cityData.markerData,
            hospitals: cityData.data.hospitals,
            employees: cityData.data.employees,
            contractors: cityData.data.contractors,
          };
          this.regionalSeries[stateData.target].markerData.push(
            this.regionalSeries[cityData.target]
          ); // Add city to state markerData
        }
      });
    });

    // Update US series data
    if (!this.regionalSeries.US.series) {
      this.regionalSeries.US.series = this.createSeries(key);
    }
    this.regionalSeries.US.series.data.setAll(
      this.regionalSeries.US.markerData
    );
  }

  getPolygon(id) {
    let found;
    this.polygonSeries.mapPolygons.each(function (polygon) {
      if (polygon.dataItem.get("id") == id) {
        found = polygon;
      }
    });
    return found;
  }

  createSeries(heatfield) {
    // Create point series
    const pointSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: heatfield,
        calculateAggregates: true,
      })
    );

    // Add store bullet
    const circleTemplate = am5.Template.new(this.root);
    pointSeries.bullets.push((a) => {
      const container = am5.Container.new(this.root, {});

      const circle = container.children.push(
        am5.Circle.new(
          this.root,
          {
            radius: 10,
            fill: am5.color(0x000000),
            fillOpacity: 0.7,
            cursorOverStyle: "pointer",
            tooltipText: getFormattedTooltipText(),
          },
          circleTemplate
        )
      );

      const label = container.children.push(
        am5.Label.new(this.root, {
          text: `{${activeViewRadio.value}}`, // Ensure this matches your data structure
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center",
        })
      );

      // Set up drill-down
      circle.events.on("click", (ev) => {
        const data = ev.target.dataItem.dataContext;

        if (data.type === "city") {
          return;
        }

        this.selectedState = data;

        // Check if the clicked item has a 'target' indicating it's a state or city
        if (!data.target) {
          return; // Do nothing if it's just a store without further drill-down
        }

        // Hide current series if it exists
        if (this.currentSeries) {
          this.currentSeries.hide();
        }

        // Zoom control based on the type of target
        if (data.type === "state") {
          const statePolygon = this.getPolygon("US-" + data.state);
          if (statePolygon) {
            this.polygonSeries.zoomToDataItem(statePolygon.dataItem);
          }
          this.zoomOutButton.show(); // Show zoom out button

          // Prepare and show city series if not already created
          if (!this.regionalSeries[data.target].series) {
            this.regionalSeries[data.target].series =
              this.createSeries(heatfield); // Adjust according to your data structure
          }

          const filteredData = data.markerData.filter(
            (data) => data[activeViewRadio.value] > 0
          );

          this.regionalSeries[data.target].series.data.setAll(filteredData);

          this.currentSeries = this.regionalSeries[data.target].series;
          this.currentSeries.show();
        }
      });
      return am5.Bullet.new(this.root, {
        sprite: container,
      });
    });

    // Additional configurations...

    return pointSeries;
  }

  updateSeries() {
    const selectedState = this.selectedState;
    this.setupStores(data.value, activeViewRadio.value);

    if (selectedState) {
      const filteredData = selectedState.markerData.filter(
        (data) => data[activeViewRadio.value] > 0
      );

      this.currentSeries.data.setAll(filteredData);
    } else {
      am5.array.each(am5.registry.rootElements, function (root) {
        if (root.dom.id == "chartContainer") {
          root.dispose();
        }
      });

      classState.value = new MapChartManager("chartContainer", data.value);
    }
  }
}
</script>

<style scoped>
#chartContainer {
  width: 100%;
  height: 70vh;
}
</style>
