<template>
  <a-form
    name="ecmo_blood_gas_form"
    ref="ecmo_blood_gas_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-divider orientation="left" style="margin-top: 0 !important">
      Blood Gas
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ph"
          name="ph"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> <sub>p</sub>H </template>
          <a-input-number type="number" v-model:value="model.ph" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pco2"
          name="pco2"
          :rules="{
            type: 'integer',
            min: 10,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> PCO<sub>2</sub> (mmHg) </template>
          <a-input-number type="number" v-model:value="model.pco2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="po2"
          name="po2"
          :rules="{
            type: 'integer',
            min: 10,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> PO<sub>2</sub> (mmHg) </template>
          <a-input-number type="number" v-model:value="model.po2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="temp"
          name="temp"
          :rules="{
            min: 0,
            type: 'integer',
            message: 'This field must be valid input.',
          }"
        >
          <template #label> Temp (°C) </template>
          <a-input-number type="number" v-model:value="model.temp" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hco3"
          name="hco3"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> HCO<sub>3</sub> </template>
          <a-input-number type="number" v-model:value="model.hco3" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="base"
          label="BASE"
          name="base"
          :rules="{
            pattern: new RegExp(/^-?\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.base" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hb"
          label="Hb"
          name="hb"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.hb" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hct"
          label="HCT (%)"
          name="hct"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.hct" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="sao2"
          name="sao2"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> SaO<sub>2</sub> (%) </template>
          <a-input-number type="number" v-model:value="model.sao2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="svo2"
          name="svo2"
          :rules="{
            type: 'integer',
            min: 0,
            max: 100,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> S<sub>v</sub>O<sub>2</sub> </template>
          <a-input-number type="number" v-model:value="model.svo2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="kplus"
          name="kplus"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> K<sup>+</sup> </template>
          <a-input-number type="number" v-model:value="model.kplus" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ventilator"
          name="ventilator"
          :rules="{
            pattern: new RegExp(/^[0-9]*$/),
            message: 'This field must be valid input.',
          }"
        >
          <template #label> Ventilator FiO<sub>2</sub> </template>
          <a-input-number type="number" v-model:value="model.ventilator" />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Blood Gas",
        ph: null,
        pco2: null,
        po2: null,
        temp: null,
        hco3: null,
        base: null,
        hb: null,
        hct: null,
        sao2: null,
        svo2: null,
        kplus: null,
        ventilator: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    let item = null;
    if (this.extra.lastRecord) {
      item = this.extra.lastRecord;
    } else if (this.extra.record) {
      item = this.extra.record;
      this.model.unique_code = item.unique_code;
      this.model.date = moment(item.date, "MM-DD-YYYY");
      this.model.time = item.time;
    }
    if (item) {
      this.model.ph = item.ph;
      this.model.pco2 = item.pco2;
      this.model.po2 = item.po2;
      this.model.temp = item.temp;
      this.model.hco3 = item.hco3;
      this.model.base = item.base;
      this.model.hb = item.hb;
      this.model.hct = item.hct;
      this.model.sao2 = item.sao2;
      this.model.svo2 = item.svo2;
      this.model.kplus = item.kplus;
      this.model.ventilator = item.ventilator;
    }
    if (!this.extra.record) {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreECMORecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
