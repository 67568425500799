import { api } from "../config/config";
import axios from "axios";
import { store } from "../store/index";
import { constants } from "../helper/constants";

export const authService = {
  login: (val) => {
    return axios
      .post(api.host + constants.loginUrl, {
        email: val.username,
        password: val.password,
        otp: val.otp ?? undefined,
      })
      .then(async (res) => {
        if (res.data.success) {
          if (val.otp) {
            await store.dispatch("auth/set_idle_lockscreen_status", false);
            localStorage.removeItem("IDLE_LOCK");
            localStorage.setItem("token", res.data.token.access_token);
            localStorage.setItem("lastSeen", parseInt(Date.now() / 1000));
            await store.dispatch("auth/login", { isInitStore: true });
          } else {
            return Promise.resolve(res.data);
          }
        } else {
          const error =
            (res.data && res.data.message) || "Invalid username or password!";
          return Promise.reject(error);
        }
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
  logout: () => {
    return axios.post(api.host + constants.logoutUrl).then((res) => {
      if (res.data.success) {
        localStorage.removeItem("token");
        return res.data;
      }
    });
  },
  forgot_password: (val) => {
    return axios
      .post(api.host + constants.forgotPasswordUrl, {
        email: val.username,
      })
      .then((res) => {
        if (res.data.success) {
          return res.data;
        }
        const error =
          (res.data && res.data.errors) || "User not found in our database!";
        return Promise.reject(error);
      });
  },

  reset_password: (val) => {
    return axios
      .post(api.host + constants.resetPasswordUrl, { ...val })
      .then((res) => {
        if (res.data.success) {
          return res.data;
        }
        const error = (res.data && res.data.errors) || "Invalid request!";
        return Promise.reject(error);
      });
  },
  me: () => {
    return axios.post(api.host + constants.me).then((res) => {
      if (res.data.success) {
        return res.data;
      }
      const error = (res.data && res.data.errors) || "Invalid request!";
      return Promise.reject(error);
    });
  },
};
