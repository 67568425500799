<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #title>
      <a-row type="flex" :gutter="[16, 0]">
        <a-col flex="auto">
          <a-select
            v-model:value="extraFilters.case_service_ids"
            :options="caseServices"
            mode="multiple"
            allowClear
            placeholder="Search using case service type"
            optionFilterProp="label"
          />
        </a-col>
        <a-col flex="250px">
          <a-range-picker
            v-model:value="extraFilters.date_range"
            :format="$constants.datepickerFormat"
            :placeholder="[
              $constants.datePickerPlaceholder,
              $constants.datePickerPlaceholder,
            ]"
          />
        </a-col>
        <a-col flex="100px">
          <a-space>
            <a-button type="primary" @click="refresh"> Search </a-button>
            <a-button type="ghost" @click="resetFilters"> Reset </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>

    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>

      <template #date="{ text }">
        {{ $customDate.mdy(text) }}
      </template>

      <template #action="{ record }">
        <router-link
          :to="{ name: 'show-case-report', params: { uuid: record.uuid } }"
        >
          <a-tooltip title="View">
            <a-button type="link" size="small">
              <i class="ti ti-eye ti-lg"></i>
            </a-button>
          </a-tooltip>
        </router-link>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.caseReportDatatableUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            customRender: "date",
          },
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Surgeon",
          dataIndex: "surgeon",
          key: "surgeon",
          customRender: ({ record }) => {
            let personnel = record.data.personnel;
            return personnel.find((e) => e.type == "Surgeon")?.name ?? "-";
          },
        },
        {
          title: "Service Category",
          dataIndex: "service_category",
          key: "service_category",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record.data,
              "service_category",
              this.$constants.serviceCategories
            ),
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.caseReportStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      extraFilters: {
        hospital_id: parseInt(this.$route.params.id),
      },

      caseServices: [],
    };
  },

  async mounted() {
    await this.fetchCaseServices();
  },

  methods: {
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },

    resetFilters() {
      this.extraFilters.case_service_ids = [];
      this.refresh();
    },

    fetchCaseServices() {
      return commonService
        .get(this.$constants.getCaseServicesUrl)
        .then((res) => {
          this.caseServices = res.data.map((item) => ({
            text: item.name,
            label: item.name,
            value: item.id,
          }));
        });
    },

    getServiceCategory(rec) {
      let services = Object.values(rec.data.services);
      let caseServices = this.caseServices
        .filter((e) => services.includes(e.value))
        .map((e) => e.text);
      return caseServices.join(", ");
    },
  },
};
</script>
