export default function guest({ to, next, store }) {
  // console.log('this is guest middleware')
  // console.log(to.name);
  if (store.getters["auth/isLoggedIn"]) {
    if (to.query?.redirectTo) {
      return next(to.query.redirectTo)
    } else {
      return next({
        name: "home",
      });
    }
  }
  return next();
}
