<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-form-item
        ref="anesthesia_volumn_pre_bypass"
        label="Anesthesia Volume Pre-Bypass (mLs)"
        name="anesthesia_volumn_pre_bypass"
        :validateStatus="validate('anesthesia_volumn_pre_bypass', 'status')"
        :help="validate('anesthesia_volumn_pre_bypass', 'msg')"
      >
        <a-input
          v-model:value="model.anesthesia_volumn_pre_bypass"
          placeholder="Enter anesthesia volumn in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="net_prime_volume"
        label="Net Prime Volume (NPV) (mLs)"
        name="net_prime_volume"
        :validateStatus="validate('net_prime_volume', 'status')"
        :help="validate('net_prime_volume', 'msg')"
      >
        <template #extra>
          <b v-html="`Net Prime Volume < 1000 mL`"></b><br />
          Reported as Percent Compliance <br />
          Target > 80%
        </template>

        <a-input
          v-model:value="model.net_prime_volume"
          placeholder="Enter net prime volumn in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="ultrafiltration_on_cpb"
        label="Ultrafiltration on CPB (mLs)"
        name="ultrafiltration_on_cpb"
        :validateStatus="validate('ultrafiltration_on_cpb', 'status')"
        :help="validate('ultrafiltration_on_cpb', 'msg')"
      >
        <a-input
          v-model:value="model.ultrafiltration_on_cpb"
          placeholder="Enter ultrafiltration on cpb here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="modified_ultrafiltration"
        label="Modified Ultrafiltration (MUF) (mLs) - Post Bypass"
        name="modified_ultrafiltration"
        :validateStatus="validate('modified_ultrafiltration', 'status')"
        :help="validate('modified_ultrafiltration', 'msg')"
      >
        <a-input
          v-model:value="model.modified_ultrafiltration"
          placeholder="Enter modified ultrafiltration here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="act_seconds_prior_to_initiation"
        label="Activated Clotting Time (ACT) >400 seconds prior to initiation of CPB"
        name="act_seconds_prior_to_initiation"
        :validateStatus="validate('act_seconds_prior_to_initiation', 'status')"
        :help="validate('act_seconds_prior_to_initiation', 'msg')"
      >
        <template #extra>
          <b>
            Activated Clotting Time (ACT) Prior to CPB Initiation will be > 400
            seconds
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 90%
        </template>

        <a-select
          v-model:value="model.act_seconds_prior_to_initiation"
          placeholder="Select any one"
        >
          <a-select-option
            v-for="yesNo in $constants.yesNo"
            :key="yesNo.value"
            :label="yesNo.text"
            :value="yesNo.value"
          >
            {{ yesNo.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="act_seconds_while_on_cpb"
        label="Activated Clotting Time (ACT) >400 seconds while on CPB"
        name="act_seconds_while_on_cpb"
        :validateStatus="validate('act_seconds_while_on_cpb', 'status')"
        :help="validate('act_seconds_while_on_cpb', 'msg')"
      >
        <template #extra>
          <b>
            Activated Clotting Time (ACT) during CPB will be > 400 seconds
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 90%
        </template>

        <a-select
          v-model:value="model.act_seconds_while_on_cpb"
          placeholder="Select any one"
        >
          <a-select-option
            v-for="yesNo in $constants.yesNo"
            :key="yesNo.value"
            :label="yesNo.text"
            :value="yesNo.value"
          >
            {{ yesNo.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="lowest_act_on_cpb"
        label="Lowest ACT on CPB (seconds)"
        name="lowest_act_on_cpb"
        :validateStatus="validate('lowest_act_on_cpb', 'status')"
        :help="validate('lowest_act_on_cpb', 'msg')"
      >
        <a-input
          v-model:value="model.lowest_act_on_cpb"
          placeholder="Enter lowest act on cpb here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="post_induction_hematocrit"
        label="Post Induction Hematocrit (%)"
        name="post_induction_hematocrit"
        :validateStatus="validate('post_induction_hematocrit', 'status')"
        :help="validate('post_induction_hematocrit', 'msg')"
      >
        <a-input
          v-model:value="model.post_induction_hematocrit"
          placeholder="Enter post induction hematocrit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="first_cpb_hematocrit"
        label="First CPB Hematocrit (%)"
        name="first_cpb_hematocrit"
        :validateStatus="validate('first_cpb_hematocrit', 'status')"
        :help="validate('first_cpb_hematocrit', 'msg')"
      >
        <a-input
          v-model:value="model.first_cpb_hematocrit"
          placeholder="Enter first cpb hematocrit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="percent_decrease_in_hemtocrit"
        label="Percent Decrease in Hematocrit (%)"
        name="percent_decrease_in_hemtocrit"
        :validateStatus="validate('percent_decrease_in_hemtocrit', 'status')"
        :help="validate('percent_decrease_in_hemtocrit', 'msg')"
      >
        <template #extra>
          <b
            v-html="
              `Change in Hematocrit (from 1st in OR to 1st on CPB) < 8 percent`
            "
          >
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 85%
        </template>

        <a-input
          v-model:value="model.percent_decrease_in_hemtocrit"
          placeholder="Enter percent decrease in hematocrit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="lowest_cpb_hematocrit"
        label="Lowest CPB Hematocrit (%)"
        name="lowest_cpb_hematocrit"
        :validateStatus="validate('lowest_cpb_hematocrit', 'status')"
        :help="validate('lowest_cpb_hematocrit', 'msg')"
      >
        <template #extra>
          <b>Lowest HCT on CPB > 24%</b>
          <br />
          Reported as Percent Compliance <br />
          Target > 60%
        </template>

        <a-input
          v-model:value="model.lowest_cpb_hematocrit"
          placeholder="Enter lowest cpb hematocrit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="last_cpb_hematocrit"
        label="Last CPB Hematocrit (%)"
        name="last_cpb_hematocrit"
        :validateStatus="validate('last_cpb_hematocrit', 'status')"
        :help="validate('last_cpb_hematocrit', 'msg')"
      >
        <a-input
          v-model:value="model.last_cpb_hematocrit"
          placeholder="Enter last cpb hematocrit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="last_in_room_hematocrit"
        label="Last In Room Hematocrit (%)"
        name="last_in_room_hematocrit"
        :validateStatus="validate('last_in_room_hematocrit', 'status')"
        :help="validate('last_in_room_hematocrit', 'msg')"
      >
        <a-input
          v-model:value="model.last_in_room_hematocrit"
          placeholder="Enter last in room hematocrit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="lowest_arterian_pressure_on_cpb"
        label="Lowest Arterial Pressure on CPB (mmHg)"
        name="lowest_arterian_pressure_on_cpb"
        :validateStatus="validate('lowest_arterian_pressure_on_cpb', 'status')"
        :help="validate('lowest_arterian_pressure_on_cpb', 'msg')"
      >
        <a-input
          v-model:value="model.lowest_arterian_pressure_on_cpb"
          placeholder="Enter lowest arterial pressure on cpb here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="lowest_svo2_on_cpb"
        label="Lowest SvO2 on CPB (%)"
        name="lowest_svo2_on_cpb"
        :validateStatus="validate('lowest_svo2_on_cpb', 'status')"
        :help="validate('lowest_svo2_on_cpb', 'msg')"
      >
        <template #extra>
          <b>Lowest SvO2 during CPB > 60%</b> <br />
          Reported as Percent Compliance <br />
          Target > 70%
        </template>

        <a-input
          v-model:value="model.lowest_svo2_on_cpb"
          placeholder="Enter lowest SvO2 on cpb here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="lowest_cpb_base_excess_deficit"
        label="Lowest CPB Base Excess/Deficit (mEq/L)"
        name="lowest_cpb_base_excess_deficit"
        :validateStatus="validate('lowest_cpb_base_excess_deficit', 'status')"
        :help="validate('lowest_cpb_base_excess_deficit', 'msg')"
      >
        <template #extra>
          <b>Lowest Base Excess (BE) during CPB > -4</b> <br />
          Reported as Percent Compliance <br />
          Target > 90%
        </template>

        <a-input
          v-model:value="model.lowest_cpb_base_excess_deficit"
          placeholder="Enter lowest cpb base excess/deficit here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="highest_blood_glucose_level"
        label="Highest Blood Glucose Level (mg/dL)"
        name="highest_blood_glucose_level"
        :validateStatus="validate('highest_blood_glucose_level', 'status')"
        :help="validate('highest_blood_glucose_level', 'msg')"
      >
        <template #extra>
          <b v-html="`Highest Intraoperative Glucose < 180 mg/dL`"></b>
          <br />
          Reported as Percent Compliance <br />
          Target > 70%
        </template>

        <a-input
          v-model:value="model.highest_blood_glucose_level"
          placeholder="Enter highest blood glucose level here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="highest_arterial_blood_temperature"
        label="Highest Venous Blood Temperature (degrees C)"
        name="highest_arterial_blood_temperature"
        :validateStatus="
          validate('highest_arterial_blood_temperature', 'status')
        "
        :help="validate('highest_arterial_blood_temperature', 'msg')"
      >
        <template #extra>
          <b v-html="`Highest Venous Blood Temperature < 37C`"></b>
          <br />
          Reported as Percent Compliance <br />
          Target > 85%
        </template>

        <a-input
          v-model:value="model.highest_arterial_blood_temperature"
          placeholder="Enter highest arterial blood temperature here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="lowest_core_body_temperature"
        label="Lowest Core Body Temperature (degrees C)"
        name="lowest_core_body_temperature"
        :validateStatus="validate('lowest_core_body_temperature', 'status')"
        :help="validate('lowest_core_body_temperature', 'msg')"
      >
        <a-input
          v-model:value="model.lowest_core_body_temperature"
          placeholder="Enter lowest core body temperature here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="minimum_cardiac_index_during_cpb"
        label="Minimum Cardiac Index (CI) During CPB"
        name="minimum_cardiac_index_during_cpb"
        :validateStatus="validate('minimum_cardiac_index_during_cpb', 'status')"
        :help="validate('minimum_cardiac_index_during_cpb', 'msg')"
      >
        <template #extra>
          <b>
            Minimum Cardiac Index (CI) during CPB > 1.8 LPM/M2 BSA
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 85%
        </template>

        <a-input
          v-model:value="model.minimum_cardiac_index_during_cpb"
          placeholder="Enter minimum cardiac index during cpb here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="prbc_transfusion_during_cpb"
        label="Packed Red Blood Cell (PRBC) Transfusion during CPB"
        name="prbc_transfusion_during_cpb"
        :validateStatus="validate('prbc_transfusion_during_cpb', 'status')"
        :help="validate('prbc_transfusion_during_cpb', 'msg')"
      >
        <template #extra>
          <b>
            Packed Red Blood Cell (PRBC) Transfusion during CPB (includes prime)
          </b>
          <br />
          Reported as Percent Patients Receiving PRBC <br />
          <span v-html="`Target < 15%`"></span>
        </template>

        <a-input
          v-model:value="model.prbc_transfusion_during_cpb"
          placeholder="Enter packed red blood cell transfusion during cpb here"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: { require: true, type: Object },
  },

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },
  },
};
</script>
