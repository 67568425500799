import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";

export default [
  // EMPLOYEES
  {
    path: "/employees",
    name: "employee",
    component: route("employee"),
    meta: {
      title: "Employees",
      extra_access: "users-employee",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/employee/create",
    name: "create-employee",
    component: route("employee/create"),
    meta: {
      title: "Create New Employee",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/employee/edit/:id",
    name: "edit-employee",
    component: route("employee/create"),
    meta: {
      title: "Update Employee Details",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/employee/show/:id",
    name: "show-employee",
    component: route("employee/show"),
    meta: {
      title: "Show Employee Details",
      extra_access: "users-employee",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  // ROLES
  {
    path: "/roles",
    name: "role",
    component: route("role"),
    meta: {
      title: "Roles",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  // Form I-9
  {
    path: "/form-i9",
    name: "form-i9",
    component: route("admin/formI9"),
    meta: {
      title: "Form I-9",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/form-i9/:id/approve",
    name: "form-i9-approve",
    component: route("admin/formI9/approve"),
    meta: {
      title: "Approve Form I-9",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/form-w9",
    name: "form-w9",
    component: route("admin/formW9"),
    meta: {
      title: "Form W-9",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  // EMPLOYEE POSITIONS
  {
    path: "/employee-positions",
    name: "employee-positions",
    component: route("admin/employeePosition"),
    meta: {
      title: "Employee Positions",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/employee-position/offter-letter-template/:id",
    name: "employee-position.offer-letter-template",
    component: route("admin/employeePosition/storeOfferLetter"),
    meta: {
      title: "Offer Letter Template",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/employee-position/contractor-offer-letter-template/:id",
    name: "employee-position.contractor-offer-letter-template",
    component: route("admin/employeePosition/storeContractorOfferLetter"),
    meta: {
      title: "Offer Letter Template",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  // ONBOARDING DOCUMENTS
  {
    path: "/onboarding-documents",
    name: "onboarding-documents",
    component: route("admin/onboardingDocument"),
    meta: {
      title: "Onboarding Documents",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/onboarding-document/store",
    name: "onboarding-document.store",
    component: route("admin/onboardingDocument/store"),
    meta: {
      title: "Onboarding Document Store",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/onboarding-document/edit/:id",
    name: "onboarding-document.edit",
    component: route("admin/onboardingDocument/store"),
    meta: {
      title: "Onboarding Document Update",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/onboarding-document/show/:id",
    name: "onboarding-document.show",
    component: route("admin/onboardingDocument/show"),
    meta: {
      title: "Onboarding Document Show",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  // Policy Rule Documents
  {
    path: "/policy-rule-documents",
    name: "policy-rule-documents",
    component: route("admin/policyRuleDocuments/index"),
    meta: {
      title: "Policy Rule Documents",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/policy-rule-documents/store",
    name: "policy-rule-documents.store",
    component: route("admin/policyRuleDocuments/store"),
    meta: {
      title: "Policy Rule Documents Store",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/policy-rule-documents/edit/:id",
    name: "policy-rule-documents.edit",
    component: route("admin/policyRuleDocuments/store"),
    meta: {
      title: "Policy Rule Documents Update",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/policy-rule-documents/show/:id",
    name: "policy-rule-documents.show",
    component: route("admin/policyRuleDocuments/show"),
    meta: {
      title: "Policy Rule Documents Show",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  // CANDIDATES
  {
    path: "/candidates",
    name: "candidates",
    component: route("admin/candidate"),
    meta: {
      title: "Candidates",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/candidate/create-offer-letter/:id",
    name: "candidate.create-offer-letter",
    component: route("admin/candidate/createOffer"),
    meta: {
      title: "Candidate | Create Offer",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/candidate/sign-offer-letter/:id",
    name: "candidate.sign-offer-letter",
    component: route("admin/candidate/signOfferLetter"),
    meta: {
      title: "Candidate | Sign Offer Letter",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
];
