import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";
import profile from "./middleware/profile";

export default [
  {
    path: "/logs/login",
    name: "logs.login",
    component: route("logs/login/index"),
    meta: {
      title: "Login Logs",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },
  {
    path: "/logs/asset-inventory",
    name: "logs.asset-inventory",
    component: route("logs/assetInventoryHospital/index"),
    meta: {
      title: "Asset Inventory Logs",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },
  {
    path: "/logs/case-report",
    name: "logs.case-report",
    component: route("logs/caseReport/index"),
    meta: {
      title: "Case Report Logs",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },
  {
    path: "/logs/activity-log",
    name: "logs.activity-log",
    component: route("logs/activityLog/index"),
    meta: {
      title: "Activity Logs",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },
  {
    path: "/logs/audit-logs",
    name: "logs.audit-logs",
    component: route("logs/auditLog/index"),
    meta: {
      title: "Audit Logs",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1
    },
  },
];
