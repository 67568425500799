<template>
  <a-form
    name="ecmo_status_form"
    ref="ecmo_status_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider orientation="left" style="margin-top: 0; margin-bottom: 0">
      Vital Signs
    </a-divider>
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="arterial_bp"
          label="Arterial BP (mmHg)"
          name="arterial_bp"
          :rules="{
            pattern: new RegExp(/^(\/)?([0-9]{2,3}\/[0-9]{2,3})$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input v-model:value="model.arterial_bp" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cvp_pa"
          label="CVP/RA (mmHG)"
          name="cvp_pa"
          :rules="{
            pattern: new RegExp(/^[0-9]*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.cvp_pa" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hr"
          label="HR (BPM)"
          name="hr"
          :rules="{
            min: 0,
            max: 999,
            type: 'integer',
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.hr" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="spo2"
          name="spo2"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            max: 100,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> SpO<sub>2</sub> </template>
          <a-input-number type="number" v-model:value="model.spo2" />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider orientation="left" style="margin-top: 0; margin-bottom: 0">
      Pump
    </a-divider>
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="pump_flow"
          label="Pump Flow (L/min)"
          name="pump_flow"
          :rules="{
            pattern: new RegExp(/^\d{1,2}(\.\d{1,2})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.pump_flow" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pump_rpm"
          label="Pump RPM"
          name="pump_rpm"
          :rules="{
            pattern: new RegExp(/^[0-9]*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.pump_rpm" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="gas_flow"
          label="Gas Flow (L/min)"
          name="gas_flow"
          :rules="{
            pattern: new RegExp(/^\d{1,2}(\.\d{1,2})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.gas_flow" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="fio2"
          name="fio2"
          :rules="{
            type: 'integer',
            min: 21,
            max: 100,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> FiO<sub>2</sub> (%) </template>
          <a-input-number type="number" v-model:value="model.fio2" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="pre_oxy"
          label="Pre Oxy (mmHg)"
          name="pre_oxy"
          :rules="{
            type: 'integer',
            min: 11,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.pre_oxy"
            @change="calculateDeltaOxy"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="post_oxy"
          label="Post Oxy (mmHg)"
          name="post_oxy"
          :rules="{
            type: 'integer',
            min: 11,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.post_oxy"
            @change="calculateDeltaOxy"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item ref="delta_oxy" label="Δ P (mmHg)" name="delta_oxy">
          <a-input-number
            type="number"
            v-model:value="model.delta_oxy"
            disabled
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider orientation="left" style="margin-top: 0; margin-bottom: 0">
      Temperature (°C)
    </a-divider>
    <a-row :gutter="[16, 0]">
      <a-col :span="8">
        <a-form-item
          ref="core"
          label="Core"
          name="core"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.core" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="post_exchanger"
          label="Post-Exchanger"
          name="post_exchanger"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.post_exchanger" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="water_bath"
          label="Water Bath"
          name="water_bath"
          :rules="{
            pattern: new RegExp(/^\d{2}(\.\d{1,2})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.water_bath" />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Status",
        arterial_bp: "",
        cvp_pa: null,
        hr: null,
        spo2: null,
        pump_flow: null,
        pump_rpm: null,
        gas_flow: null,
        fio2: null,
        pre_oxy: null,
        post_oxy: null,
        delta_oxy: null,
        core: null,
        post_exchanger: null,
        water_bath: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    let item = null;
    if (this.extra.lastRecord) {
      item = this.extra.lastRecord;
    } else if (this.extra.record) {
      item = this.extra.record;
      this.model.unique_code = item.unique_code;
      this.model.date = moment(item.date, "MM-DD-YYYY");
      this.model.time = item.time;
    }
    if (item) {
      this.model.arterial_bp = item.arterial_bp;
      this.model.cvp_pa = item.cvp_pa;
      this.model.hr = item.hr;
      this.model.spo2 = item.spo2;
      this.model.pump_flow = item.pump_flow;
      this.model.pump_rpm = item.pump_rpm;
      this.model.gas_flow = item.gas_flow;
      this.model.fio2 = item.fio2;
      this.model.pre_oxy = item.pre_oxy ?? null;
      this.model.post_oxy = item.post_oxy ?? null;
      this.model.delta_oxy = item.delta_oxy ?? null;
      this.model.core = item.core;
      this.model.post_exchanger = item.post_exchanger;
      this.model.water_bath = item.water_bath;
    }
    if (!this.extra.record) {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    calculateDeltaOxy() {
      this.model.delta_oxy =
        (this.model.pre_oxy ?? 0) - (this.model.post_oxy ?? 0);
    },

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreECMORecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
