<template>
  <a-alert
    v-if="data.missing_credentials.length > 0"
    type="error"
    show-icon
    message="Missing required documents"
    :description="data.missing_credentials.join(', ')"
    class="mb-sm-1"
  />

  <a-card
    :loading="loading"
    size="small"
    class="employee-certificates"
    :tab-list="tabs"
    :active-tab-key="activeTab"
    @tabChange="onTabClick"
  >
    <template #title> Credentialing</template>

    <template #extra>
      <a-space v-if="!tableLoading">
        <a-button
          type="primary"
          size="small"
          :loading="downloadingAttestationPdf"
          @click="handleDownloadAttestationClickEvent"
        >
          <a-space>
            <i class="ti ti-file-star ti-lg"></i>
            Attestation
          </a-space>
        </a-button>

        <a-button
          type="primary"
          size="small"
          :loading="downloadingPdfFile"
          @click="handleDownloadPdfFileClickEvent"
        >
          <a-space>
            <i class="ti ti-certificate ti-lg"></i>
            PDF
          </a-space>
        </a-button>

        <a-button
          type="primary"
          size="small"
          :loading="downloadingZipFile"
          @click="handleDownloadZipFileClickEvent"
        >
          <a-space>
            <i class="ti ti-file-zip ti-lg"></i>
            ZIP
          </a-space>
        </a-button>

        <a-button
          type="primary"
          size="small"
          @click="handleAddCertificateClickEvent"
        >
          <a-space>
            <i class="ti ti-plus ti-lg"></i>
            Add New
          </a-space>
        </a-button>
      </a-space>
    </template>

    <a-table
      :loading="tableLoading"
      :columns="columns"
      :data-source="activeArchivedDataSplit[activeTab]"
      size="small"
      :rowKey="(record, index) => record.id"
      :pagination="false"
      :scroll="{ x: true, y: 0 }"
    >
      <template #attachments="{ record }">
        <template v-for="(attach, idx) in record.attachments" :key="attach.id">
          <a-typography-link :href="attach.url" target="_blank">
            {{ attach.name }}
          </a-typography-link>
          <br v-if="idx !== record.attachments.length - 1" />
        </template>
      </template>

      <template #certificate_name="{ record }">
        {{ record.certification_type?.name ?? "N/A" }}
        <a-popover
          v-if="record.certification_type?.description"
          placement="top"
          :overlayStyle="{ width: '400px' }"
        >
          <template #content>
            {{ record.certification_type.description }}
          </template>

          <a-button type="link" size="small">
            <i class="fa fa-info-circle"></i>
          </a-button>
        </a-popover>
      </template>

      <template #action="{ record }">
        <a-space :size="1">
          <a-tooltip title="Edit">
            <a-button type="link" @click="editCredential(record)">
              <template #icon>
                <i class="ti ti-pencil" style="font-size: 18px"></i>
              </template>
            </a-button>
          </a-tooltip>
          <a-popconfirm
            title="Are you sure you want to delete this credentialing?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteAttachment(record.id)"
          >
            <a-button size="small" type="link" danger>
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "employee-certificates",
  props: {
    employee: {
      required: true,
      default: null,
    },
  },

  emits: ["updateEmployeeData"],

  data() {
    return {
      moment,
      tableLoading: true,
      downloadingZipFile: false,
      downloadingPdfFile: false,
      downloadingAttestationPdf: false,
      certificationTypes: [],
      data: {
        credentials: [],
        missing_credentials: [],
      },
      columns: [
        {
          title: "Certificate Name",
          dataIndex: "certificate_name",
          key: "certificate_name",
          fixed: "left",
          width: 220,
          slots: {
            customRender: "certificate_name",
          },
        },
        {
          title: "Uploaded At",
          dataIndex: "created_at",
          key: "created_at",
          customRender: ({ text }) => this.$customDate.ll(text),
          width: 130,
        },
        {
          title: "State",
          dataIndex: "state",
          key: "state",
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Expiration Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          customRender: ({ text }) => this.$customDate.ll(text),
          width: 130,
        },
        {
          title: "Approval Status",
          dataIndex: "approval_status",
          key: "approval_status",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "approval_status",
              this.$constants.credentialApprovalStatuses
            ),
        },
        {
          title: "Attachment",
          dataIndex: "attachments",
          key: "attachments",
          slots: {
            customRender: "attachments",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
      activeTab: "active",
    };
  },

  computed: {
    loading() {
      return this.employee == null;
    },
    activeArchivedDataSplit() {
      const credentials = this.data.credentials;
      const active = [];
      const archived = [];

      const today = moment().subtract(1, "day");

      credentials.forEach((credential) => {
        const expiryDate = moment(credential.expiry_date, "YYYY-MM-DD");
        if (expiryDate.isBefore(today)) {
          archived.push(credential);
        } else {
          active.push(credential);
        }
      });

      return {
        active,
        archived,
      };
    },

    tabs() {
      const activeCount = this.activeArchivedDataSplit["active"].length;
      const archivedCount = this.activeArchivedDataSplit["archived"].length;

      return [
        {
          tab: `Active (${activeCount})`,
          key: "active",
        },
        {
          tab: `Archived (${archivedCount})`,
          key: "archived",
        },
      ];
    },
  },

  mounted() {
    this.getEmployeeCertificates();
    this.$comman.get_certification_types().then((res) => {
      this.certificationTypes = res;
    });
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    editCredential(record) {
      this.open({
        title: "Edit Credential",
        path: "admin.approvals.edit",
        callback: this.getEmployeeCertificates,
        record,
      });
    },

    onTabClick(key) {
      this.activeTab = key;
    },

    getEmployeeCertificates() {
      commonService
        .get(this.$constants.getEmployeeCertificates, {
          employee_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.success) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.tableLoading = false));
    },

    handleAddCertificateClickEvent() {
      this.open({
        title: `Add New Certificate`,
        path: `employee.drawerForm.addCertificate`,
        extra: {
          certificationTypes: this.certificationTypes,
          employee_id: this.$route.params.id,
        },
        callback: this.getEmployeeCertificates,
      });
    },

    handleDownloadZipFileClickEvent() {
      this.downloadingZipFile = true;
      commonService
        .downloadFile(
          this.$constants.employeeDownloadCredentialingZipUrl,
          { id: this.employee.id },
          `${this.employee.first_name}-${
            this.employee.last_name
          }-credentialing-${this.$customDate.mdy(moment())}.zip`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.downloadingZipFile = false));
    },

    handleDownloadPdfFileClickEvent() {
      this.downloadingPdfFile = true;
      commonService
        .downloadFile(
          this.$constants.employeeDownloadCredentialingPdfUrl,
          { id: this.employee.id },
          `${this.employee.first_name}-${
            this.employee.last_name
          }-credentialing-${this.$customDate.mdy(moment())}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.downloadingPdfFile = false));
    },

    handleDownloadAttestationClickEvent() {
      this.downloadingAttestationPdf = true;
      commonService
        .downloadFile(
          this.$constants.employeeDownloadAttestationPdfUrl,
          { id: this.employee.id },
          `${this.employee.first_name}-${
            this.employee.last_name
          }-attestation-${this.$customDate.mdy(moment())}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.downloadingAttestationPdf = false));
    },

    deleteAttachment(id) {
      this.tableLoading = true;
      commonService
        .get(this.$constants.deleteEmployeeCertificates, {
          id,
          employee_id: this.$route.params.id,
        })
        .then((res) => {
          this.data = res.data;
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
          his.$message.error(err);
        })
        .finally(() => (this.tableLoading = false));
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.getEmployeeCertificates();
      }
    },
  },
};
</script>
