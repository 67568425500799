<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Performance Evaluations"
      style="margin-bottom: 24px; background-color: #fff"
    >
      <template #extra>
        <!--  -->
      </template>
    </a-page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <router-link
            :to="{
              name: 'show-performance-evaluation',
              params: { id: record.id },
            }"
          >
            <a-tooltip title="View">
              <a-button type="link" size="small" class="pl-sm-0">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
          </router-link>

          <a-tooltip title="Download">
            <a-button type="link" size="small" @click="downloadPdf(record)">
              <i class="ti ti-download ti-lg"></i>
            </a-button>
          </a-tooltip>

          <template v-if="[2, 3].includes(record.status)">
            <a-tooltip title="Send Reminder">
              <a-button
                type="link"
                size="small"
                @click="sendRemainderMail(record)"
              >
                <i class="ti ti-mail ti-lg"></i>
              </a-button>
            </a-tooltip>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.performanceEvaluationsUrl,
      statusChangeUrl: this.$constants.performanceEvaluationChangeStatusUrl,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => record.employee?.full_name ?? "N/A",
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "N/A",
        },
        // {
        //   title: "PF",
        //   dataIndex: "pf",
        //   key: "pf",
        //   customRender: ({ record }) =>
        //     record.json?.section7[0]?.weightage ?? "0",
        // },
        // {
        //   title: "JC",
        //   dataIndex: "jc",
        //   key: "jc",
        //   customRender: ({ record }) =>
        //     record.json?.section7[1]?.weightage ?? "0",
        // },
        // {
        //   title: "PYG",
        //   dataIndex: "pyg",
        //   key: "pyg",
        //   customRender: ({ record }) =>
        //     record.json?.section7[2]?.weightage ?? "0",
        // },
        {
          title: "Evaluation Completed Date",
          dataIndex: "evaluation_completed_date",
          key: "evaluation_completed_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Next Performance Review Date",
          dataIndex: "next_performance_review_date",
          key: "next_performance_review_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Job Type",
          dataIndex: "job_type",
          key: "job_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "job_type",
              this.$constants.performanceEvaluationJobs
            ),
        },
        {
          title: "Appraisal Type",
          dataIndex: "appraisal_type",
          key: "appraisal_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "appraisal_type",
              this.$constants.performanceEvaluationTypes
            ),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Under Review",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "Disputed",
          status: 2,
          slots: { tab: "customRender" },
        },
        {
          key: "3",
          counts: 0,
          text: "Signed",
          status: 3,
          slots: { tab: "customRender" },
        },
        {
          key: "4",
          counts: 0,
          text: "Completed",
          status: 4,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        sortField: this.$route.query?.sortField ?? "evaluation_completed_date",
        sortOrder: this.$route.query?.sortOrder ?? "descend",
      },
    };
  },

  methods: {
    // ...mapActions("drawer", ["open"]),

    downloadPdf(record) {
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.performanceEvaluationExportPdfUrl,
          { id: record.id },
          `${record.employee.full_name.replace(
            ", ",
            "_"
          )}_performance_evaluation.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    sendRemainderMail(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminPerformanceEvaluationSendRemainderUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },
  },
};
</script>
