<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <!-- $router.push({
            name: $route.name.replace('create-case-report', 'case-report'),
          }) -->
      <template #title> Case Reporting </template>

      <template #extra>
        <!--  -->
      </template>
    </a-page-header>

    <a-form
      name="case_report_form"
      ref="case_report_form"
      :model="model"
      :rules="rules"
      layout="vertical"
    >
      <a-progress
        class="case-report-progress"
        :percent="percentage"
        status="active"
        :format="() => `${step} of ${computedSteps.length}`"
      />

      <a-card size="small">
        <template #title>
          <a-typography-title :level="4">
            {{ stepTitle }}
          </a-typography-title>
        </template>

        <a-skeleton :loading="cardLoading">
          <component
            v-if="is"
            :is="is"
            v-bind="{
              step,
              model,
              reportNo,
              hospitals,
              caseServices,
              isCatiInvoiceAmountShow,
              record,
              hospital,
              isDisposableOnlyCase,
            }"
            @validate="validate"
            @calculateCatiTotalTime="calculateCatiTotalTime"
            @handleOrganConsiderChange="handleOrganConsiderChange"
          >
            <router-view />
          </component>
        </a-skeleton>

        <template #actions>
          <a-button htmlType="button" @click="back" danger type="primary">
            <a-space v-if="Number(step) > 1">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              Back
            </a-space>
          </a-button>

          <a-space>
            <template
              v-if="
                showLiveChartingButton &&
                record?.status &&
                [1, 2].includes(record.status)
              "
            >
              <a-button
                htmlType="button"
                @click="
                  $route.name == 'admin.create-case-report'
                    ? next('admin.case-report-live-charting')
                    : next('case-report-live-charting')
                "
                type="primary"
                :loading="submitBtnLoader"
              >
                <a-space>
                  Save & Live Charting
                  <i class="ti ti-device-heart-monitor ti-lg"></i>
                </a-space>
              </a-button>
            </template>

            <template v-if="Number(step) < computedSteps.length">
              <a-button
                htmlType="button"
                @click="next()"
                type="primary"
                :loading="submitBtnLoader"
              >
                <a-space>
                  Save & Next
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </a-space>
              </a-button>

              <a-button
                v-if="stepTitle != 'Preview'"
                htmlType="button"
                @click="
                  $route.name == 'admin.create-case-report'
                    ? next('admin.case-report')
                    : next('case-report')
                "
                type="primary"
                :loading="submitBtnLoader"
              >
                <a-space>
                  Save & Close
                  <i class="fa fa-close" aria-hidden="true"></i>
                </a-space>
              </a-button>
            </template>

            <template v-if="Number(step) == computedSteps.length">
              <template
                v-for="status in record?.status == 1 ? [1, 2] : [2]"
                :key="status"
              >
                <a-button
                  htmlType="button"
                  @click="saveAndSubmit(status)"
                  type="primary"
                  :loading="submitBtnLoader"
                >
                  <a-space>
                    Save & {{ status == 1 ? "Close" : "Submit" }}
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </a-space>
                </a-button>
              </template>
            </template>
          </a-space>
        </template>
      </a-card>
    </a-form>
  </a-layout-content>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { createVNode } from "vue";
import { mapGetters } from "vuex";
import { route } from "../../helper/route";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      step: this.$route?.query?.step ?? 1,
      uuid: this.$route?.query?.uuid ?? null,
      reportNo: null,
      record: null,
      model: {
        // Basic Information
        date: null,
        hospital_id: undefined,
        mr_number: null,
        account_number: null,
        patient_first_name: null,
        patient_last_name: null,
        allergies: null,
        height: null,
        weight: null,
        body_surface_area: null,
        blood_type: undefined,
        dob: null,
        gender: null,
        donor_hospital: null,
        organs_considered: [],
        is_disposable_only_case: false,
        age: undefined,
        accepting_center: null,
        additional_accepting_center: null,
        po_number: null,

        // Operative Data
        personnel: [
          {
            type: "Primary Perfusionist",
            name: null,
          },
          {
            type: "Surgeon",
            name: null,
          },
        ],
        procedure: null,
        case_status: undefined,
        emergent_request_date: null,
        emergent_request_time: null,
        arrival_date_into_facility: null,
        arrival_time_into_facility: null,
        emergent_response_time: null,
        services: [],
        service_category: undefined,
        patient_in_room_time: null,
        surgery_start_time: null,
        surgery_end_time: null,
        patient_out_of_room_time: null,
        cpb_time: null,
        cross_clamp_time: null,
        circulatory_arrest_time: null,
        cati_invoice_amount: null,
        purchase_order_number: null,
        organs_targeted_for_recovery: undefined,
        cannulation_stratege: undefined,

        // ECMO
        start_date_of_ecmo_run: null,
        start_time_of_ecmo_run: null,
        end_date_of_ecmo_run: null,
        end_time_of_ecmo_run: null,
        total_ecmo_run_time: null,
        lowest_hct_on_ecmo: null,
        lowest_ptt_on_ecmo: null,
        lowest_arterial_bp_on_ecmo: null,

        // Disposible Products
        products: [],

        // Acute Normovolemic Hemodilution (ANH)
        blood_sequestered: null,
        anticoagulation_type: undefined,

        // Quality Indicators
        first_blood_glucose_level: null,
        last_blood_glucose_level: null,
        anesthesia_volumn_pre_bypass: null,
        net_prime_volume: null,
        ultrafiltration_on_cpb: null,
        modified_ultrafiltration: 0,
        act_seconds_prior_to_initiation: null,
        act_seconds_while_on_cpb: null,
        lowest_act_on_cpb: null,
        post_induction_hematocrit: null,
        first_cpb_hematocrit: null,
        percent_decrease_in_hemtocrit: null,
        lowest_cpb_hematocrit: null,
        last_cpb_hematocrit: null,
        last_in_room_hematocrit: null,
        lowest_arterian_pressure_on_cpb: null,
        lowest_svo2_on_cpb: null,
        lowest_cpb_base_excess_deficit: null,
        highest_blood_glucose_level: null,
        highest_arterial_blood_temperature: null,
        lowest_core_body_temperature: null,
        minimum_cardiac_index_during_cpb: null,
        prbc_transfusion_during_cpb: null,
        additional_crystalloid_volume_during_cpb: null,
        estimated_blood_volume: null,
        predicted_cpb_hematocrit: null,
        diff_predicted_cpb_hct_vs_actual_cpb_hct: null,
        lowest_hb: null,
        qi_prbc: 0,
        qi_ffp: 0,
        qi_platelets: 0,
        qi_cryo: 0,

        // Cell Saver Operative Data
        washed_rbcs_reinfused: null,
        type_of_anticoagulant_used: null,
        total_processed_volumn: null,
        anticoagulant_volumn_used: null,
        wash_volumn_used: null,
        estimated_blood_loss: null,
        clear_effluent_wash_line: null,
        autologous_blood_transfused_within_4_hours: null,
        cell_saver_hct: null,
        was_a_qc_sample_obtained_for_this_procedure: null,
        air_removed_from_bag: null,
        maximum_vaccum_less_than_150: null,
        sources: [],

        // Procedure Info
        // Cardiac Procedure Info
        reop: "No",
        coronary_artery_bypass: "No",
        cab_technique: undefined,
        number_of_grafts: null,
        aortic: "No",
        mitral: "No",
        tricuspid: "No",
        pulmonic: "No",
        other_cardiac_procedure: "No",
        other_non_cardiac_procedure: "No",
        is_sts_data: undefined,
        endoscopic_vein_harvest_time: null,
        radial_artery_harvest_time: null,
        proximal_techniques: [],
        cardioplegia_types: [],
        coronary_bypasses: [],
        cati_arrival_date: null,
        cati_arrival_time: null,
        cati_departure_date: null,
        cati_departure_time: null,
        cati_total_time: 0,
        cati_total_time_in_seconds: 0,

        // Procedure info for NRP QI Indicators
        nrp_is_dry_run_case: false,
        nrp_total_time: null,
        nrp_initiation_date: null,
        nrp_initiation_time: null,
        nrp_end_date: null,
        nrp_end_time: null,
        nrp_withdrawal_of_life_sustaining_therapy_date: null,
        nrp_withdrawal_of_life_sustaining_therapy_time: null,
        nrp_circulatory_cessation_date: null,
        nrp_circulatory_cessation_time: null,
        nrp_declaration_of_death_date: null,
        nrp_declaration_of_death_time: null,
        nrp_incision_date: null,
        nrp_incision_time: null,
        nrp_heart_flush_date: null,
        nrp_heart_flush_time: null,
        nrp_lung_flush_date: null,
        nrp_lung_flush_time: null,
        nrp_head_vessels_ligated_date: null,
        nrp_head_vessels_ligated_time: null,
        nrp_aortic_cannulation_date: null,
        nrp_aortic_cannulation_time: null,
        nrp_venous_cannulation_date: null,
        nrp_venous_cannulation_time: null,
        nrp_aortic_and_ivc_clamp_date: null,
        nrp_aortic_and_ivc_clamp_time: null,
        nrp_cold_aortic_flush_post_date: null,
        nrp_cold_aortic_flush_post_time: null,
        nrp_functional_warm_ischemic_date: null,
        nrp_functional_warm_ischemic_time: null,
        nrp_end_of_observation_period_date: null,
        nrp_end_of_observation_period_time: null,
        nrp_time_from_withdrawal_of_care: null,
        nrp_time_from_incision: null,
        nrp_time_from_functional_warm_ischemic: null,
        nrp_functional_warm_ischemic_time_params: null,
        nrp_minimum_flow: null,
        nrp_maximum_flow: null,
        nrp_first_lac: null,
        nrp_last_lac: null,
        nrp_max_lac: null,
        nrp_min_lac: null,
        nrp_percentage_lac: null,
        nrp_first_base: null,
        nrp_last_base: null,
        nrp_percentage_base: null,
        organs_recovered: [],
        nrp_medicines: [],
        nrp_blood_products: [],
        nrp_venous_cannula_location: null,
        nrp_arterial_cannula_location: null,
        nrp_venous_clamp_location: null,
        nrp_aortic_clamp_locations: null,
        nrp_blended_technology_case: false,
        nrp_blended_organ_1: undefined,
        nrp_blended_method_1: null,
        nrp_blended_other_method_1: null,
        nrp_blended_organ_2: undefined,
        nrp_blended_method_2: null,
        nrp_blended_other_method_2: null,
        nrp_blended_organ_3: undefined,
        nrp_blended_method_3: null,
        nrp_blended_other_method_3: null,

        // Procedure info for NRP Procedure Info
        nrp_2_scheduled_or_date: null,
        nrp_2_scheduled_or_time: null,
        nrp_2_kps_team_arrival_date: null,
        nrp_2_kps_team_arrival_time: null,
        nrp_2_kps_setup_complete_date: null,
        nrp_2_kps_setup_complete_time: null,
        nrp_2_or_opo_personnel_in_room_date: null,
        nrp_2_or_opo_personnel_in_room_time: null,
        nrp_2_donor_in_room_date: null,
        nrp_2_donor_in_room_time: null,
        nrp_2_kps_team_depature_date: null,
        nrp_2_kps_team_depature_time: null,

        // DBD Organ recovery QI Indicators
        dbdor_declaration_of_death_date: null,
        dbdor_declaration_of_death_time: null,
        dbdor_incision_date: null,
        dbdor_incision_time: null,
        dbdor_cross_clamp_date: null,
        dbdor_cross_clamp_time: null,
        dbdor_heparin_date: null,
        dbdor_heparin_time: null,
        dbdor_heparin: null,
        dbdor_organs: {},

        // DBD Organ recovery Procedure Info
        dbdor_2_scheduled_or_date: null,
        dbdor_2_scheduled_or_date: null,
        dbdor_2_kps_team_flight_mission_take_off_date: null,
        dbdor_2_kps_team_flight_mission_take_off_time: null,
        dbdor_2_kps_team_initial_touch_down_date: null,
        dbdor_2_kps_team_initial_touch_down_time: null,
        dbdor_2_kps_team_arrival_at_donor_facility_date: null,
        dbdor_2_kps_team_arrival_at_donor_facility_time: null,
        dbdor_2_kps_setup_complete_date: null,
        dbdor_2_kps_setup_complete_time: null,
        dbdor_2_or_opo_personnel_in_room_date: null,
        dbdor_2_or_opo_personnel_in_room_time: null,
        dbdor_2_donor_in_room_date: null,
        dbdor_2_donor_in_room_time: null,
        dbdor_2_kps_team_donor_facility_departure_date: null,
        dbdor_2_kps_team_donor_facility_departure_time: null,
        dbdor_2_kps_team_wheels_up_to_recipient_facility_date: null,
        dbdor_2_kps_team_wheels_up_to_recipient_facility_time: null,
        dbdor_2_kps_team_destination_touch_down_date: null,
        dbdor_2_kps_team_destination_touch_down_time: null,
        dbdor_2_kps_team_arrival_at_recipient_facility_date: null,
        dbdor_2_kps_team_arrival_at_recipient_facility_time: null,
        dbdor_2_hand_off_complete_date: null,
        dbdor_2_hand_off_complete_time: null,

        // Preservation Service
        ps_type_of_perservation_solution: undefined,
        ps_organ_perfused: [],
        ps_volumn: null,
        ps_delivery_pressure: null,
        ps: {},

        // Platelet Gel Therapy
        pgt_na_pre_procedure_checklist: [],
        pgt_pre_procedure_checklist: [],
        pgt_na_post_procedure_checklist: [],
        pgt_post_procedure_checklist: [],
        pgt_volume_processed: null,
        pgt_platelet_rich_plasma: null,
        pgt_platelet_poor_plasma: null,
        pgt_anticoag_volume: null,
        pgt_volume_plasma_loss: null,
        pgt_reco_thrombin: null,
        pgt_cacl2_10: null,
        pgt_centrifuge_serial_number: null,
        pgt_blood_withdrawal_date: null,
        pgt_blood_withdrawal_time: null,
        pgt_blood_withdrawal_site: null,
        pgt_blood_withdrawal_performed_by: null,
        pgt_prp_product_given_to: null,
        pgt_prp_product_given_date: null,
        pgt_prp_product_given_time: null,
        pgt_first_prp_application_date: null,
        pgt_first_prp_application_time: null,

        // HIPEC Quality Indicators
        cytotoxic_drug_type_and_dose: null,
        prime_type_and_amount: null,
        average_inflow_temperature: null,
        average_outflow_temperature: null,
        pump_flow_rate: null,
        average_reservoir_volume: null,
        additional_comments: null,

        // Post Procedure Maintenance
        is_heart_lung: undefined,
        heart_lung_device: undefined,
        heart_lung_device_data: undefined,
        is_heater_cooler: undefined,
        heater_cooler_device: undefined,
        is_cell_saver: undefined,
        cell_saver_device: undefined,
        is_hyperthermic_pump: undefined,
        hyperthermic_pump_device: undefined,

        // Preview

        // File Upload Or Capture Image
        additional_procedural_notes: "",
        additional_surgical_notes: "",
        additional_time_charge: null,
        attachments: [],
        sign: null,
        signed_at: null,
        extra_sign: {
          first_name: null,
          last_name: null,
          title: null,
          sign: null,
          signed_at: null,
          is_added: false,
        },
      },

      rules: null,
      hospitals: [],
      caseServices: [],
      stepsTitle: [
        { file: "step1", title: "Basic Information", onlyShow: () => true },
        {
          file: "step2",
          title: "Operative Data",
          onlyShow: () => !this.isDisposableOnlyCase,
        },
        {
          file: "step3",
          title: "Disposable Products",
          onlyShow: () =>
            this.$constants.disposableProductsStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step12",
          title: "Acute Normovolemic Hemodilution (ANH)",
          onlyShow: () =>
            this.$constants.acuteNormovolemicHemodilutionStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step4",
          title: "Quality Indicators",
          onlyShow: () =>
            this.$constants.qualityIndicatorsStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ) &&
            !this.$comman.nrpServiceSelectedWithOrganHospital(
              this.model.services,
              this.hospital
            ),
        },
        {
          file: "step6",
          title: "Procedure Info",
          onlyShow: () =>
            this.$constants.procedureInfoStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step6ForNrp",
          title: "NRP QI Indicators",
          onlyShow: () =>
            !this.isDisposableOnlyCase &&
            this.$comman.nrpServiceSelectedWithOrganHospital(
              this.model.services,
              this.hospital
            ),
        },
        {
          file: "step6ForNrp2",
          title: "NRP Procedure Info",
          onlyShow: () =>
            !this.isDisposableOnlyCase &&
            this.$comman.nrpServiceSelectedWithOrganHospital(
              this.model.services,
              this.hospital
            ),
        },
        {
          file: "organRecoveryQiIndicator",
          title: "DBD Organ Recovery QI Indicators",
          onlyShow: () =>
            this.$constants.organRecoveryQiIndicatorStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "organRecoveryProcedure",
          title: "DBD Organ Recovery Procedure Info",
          onlyShow: () =>
            this.$constants.organRecoveryProcedureInfoStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "preservationServiceStep",
          title: "Preservation Details",
          onlyShow: () =>
            this.$constants.preservationServiceStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step13",
          title: "Platelet Gel Therapy",
          onlyShow: () =>
            this.$constants.plateletGelTherapyStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step7",
          title: "HIPEC Quality Indicators",
          onlyShow: () =>
            this.$constants.qualityIndicatorsForHIPECStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step11",
          title: "ECMO Quality Indicators",
          onlyShow: () =>
            this.$constants.qualityIndicatorsForECMOStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step5",
          title: "Cell Saver Operative Data",
          onlyShow: () =>
            this.$constants.cellSaverOperativeDataStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        {
          file: "step8",
          title: "Post Procedure Maintenance",
          onlyShow: () =>
            this.$constants.postProcedureMaintenanceStep.reduce(
              (bool, curr) => bool || this.model.services.includes(curr),
              false
            ),
        },
        { file: "step9", title: "Preview", onlyShow: () => true },
        {
          file: "step10",
          title: "File Upload Or Capture Image",
          onlyShow: () => true,
        },
      ],
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    hospital() {
      return this.hospitals.find((e) => e.id == this.model.hospital_id);
    },

    submittedAt() {
      return moment().tz(this.timezone).format("YYYY-MM-DD HH:mm:ss");
    },

    computedSteps() {
      return this.stepsTitle.filter((v) => v.onlyShow());
    },

    is() {
      let find = this.computedSteps.find((_, i) => i + 1 == this.step);
      if (find) {
        return route(`newCaseReport/steps/${find.file}`);
      } else {
        return route(`newCaseReport/steps/step1`);
      }
    },

    stepTitle() {
      let find = this.computedSteps.find((_, i) => i + 1 == this.step);
      if (find) {
        return find.title;
      }
      return "N/A";
    },

    percentage() {
      let stepLength = this.computedSteps.length;
      let totalSteps = stepLength / 100;
      return parseInt(this.step) / totalSteps > 100
        ? 100
        : Number(parseFloat(parseInt(this.step) / totalSteps).toFixed(2));
    },

    isCatiInvoiceAmountShow() {
      if (this.model.hospital_id) {
        let selectedHospitals = this.hospitals.find(
          (v) => v.id == this.model.hospital_id
        );
        return selectedHospitals && selectedHospitals.company_id == 2;
      }
      return false;
    },

    showLiveChartingButton() {
      let stepsTitleToShow = this.stepsTitle
        .filter((e) => !["step1", "step10"].includes(e.file))
        .reduce((pre, curr) => {
          pre.push(curr.title);
          return pre;
        }, []);

      let selectedHospitals = this.hospitals.find(
        (v) => v.id == this.model.hospital_id
      );
      if (
        !selectedHospitals ||
        selectedHospitals.is_live_charting_enable == 0
      ) {
        return false;
      }
      return (
        stepsTitleToShow.includes(this.stepTitle) &&
        this.$constants.caseServicesIdForCPB
          .concat(this.$constants.caseServicesIdForATS)
          .concat(this.$constants.caseServicesIdForHIPEC)
          .concat(this.$constants.caseServicesIdForECMO)
          .concat(this.$constants.caseServicesIdForNRP)
          .filter((value, index, array) => array.indexOf(value) === index)
          .reduce(
            (bool, curr) => bool || this.model.services.includes(curr),
            false
          )
      );
    },

    pgtPreChecklist() {
      return this.$constants.pgtPreProcedureChecklist;
    },

    pgtPostChecklist() {
      return this.$constants.pgtPostProcedureChecklist;
    },

    isDisposableOnlyCase() {
      return (
        this.model.is_disposable_only_case &&
        this.model.services.includes(this.$constants.disposableOnlyCase)
      );
    },
  },

  async mounted() {
    if (
      (this.uuid == null && this.step != 1) ||
      (this.step < 1 && this.step > this.stepsTitle.length + 1)
    ) {
      return this.$router.replace({
        name: this.$route.name,
        query: { step: 1 },
      });
    }

    await this.fetchHospitals();
    if (this.uuid) {
      await commonService
        .get(this.$constants.caseReportShowUrl, { uuid: this.uuid })
        .then((res) => {
          this.modifyModelData(res.data);
        })
        .catch((err) => {
          this.$message.error(err);
          return this.$router.replace({
            name: this.$route.name,
            query: { step: 1 },
          });
        });

      this.fetchCaseServices();
    }

    if (this.$route?.query?.info == "copy-record") {
      let copyRecord = localStorage.getItem("copy-record");
      localStorage.removeItem("copy-record");
      if (copyRecord) {
        copyRecord = JSON.parse(copyRecord);
        this.model = {
          ...this.model,

          // Basic Information
          ...copyRecord,
        };
      }
    }

    this.handleOrganConsiderChange();

    this.cardLoading = false;
  },

  updated() {
    this.step = this.$route?.query?.step ?? 1;
  },

  methods: {
    modifyModelData(report) {
      this.reportNo = report.report_no;
      this.uuid = report.uuid;
      this.record = report;
      let attachments = [];
      if (report.attachments.length > 0) {
        attachments = report.attachments.map((v) => ({
          id: v.id,
          uid: v.file_name,
          name: v.file_name_with_ext,
          url: v.full_file_path,
        }));
      }

      let oldPgtNaPreOption =
        report?.data?.pgt_na_pre_procedure_checklist ?? [];
      let oldPgtPreOption = report?.data?.pgt_pre_procedure_checklist ?? [];
      let oldPgtNaPreProcedureChecklist = {};
      let oldPgtPreProcedureChecklist = {};
      this.pgtPreChecklist.forEach((e) => {
        oldPgtNaPreProcedureChecklist[e] = oldPgtNaPreOption.includes(e)
          ? true
          : false;
        oldPgtPreProcedureChecklist[e] = oldPgtPreOption.includes(e)
          ? true
          : false;
      });

      let oldPgtNaPostOption =
        report?.data?.pgt_na_post_procedure_checklist ?? [];
      let oldPgtPostOption = report?.data?.pgt_post_procedure_checklist ?? [];
      let oldPgtNaPostProcedureChecklist = {};
      let oldPgtPostProcedureChecklist = {};
      this.pgtPostChecklist.forEach((e) => {
        oldPgtNaPostProcedureChecklist[e] = oldPgtNaPostOption.includes(e)
          ? true
          : false;
        oldPgtPostProcedureChecklist[e] = oldPgtPostOption.includes(e)
          ? true
          : false;
      });

      let dbdor_organs = {};
      if ("dbdor_organs" in report.data) {
        let dbdorOrgansKeys = Object.keys(report.data.dbdor_organs);
        dbdorOrgansKeys.map((e) => {
          dbdor_organs[e] = report.data.dbdor_organs[e];

          [
            "start_of_cold_flush_date",
            "end_of_cold_flush_date",
            "out_of_body_date",
          ].map((el) => {
            dbdor_organs[e][el] = report.data.dbdor_organs[e][el]
              ? moment(report.data.dbdor_organs[e][el])
              : null;
          });
        });
      }

      this.model = {
        ...report.data,
        hospital_id: report.hospital_id,
        account_number: report.data.account_number ?? null,
        date: moment(report.date),
        dob: report.data.dob ? moment(report.data.dob) : null,
        start_date_of_ecmo_run: report.data.start_date_of_ecmo_run
          ? moment(report.data.start_date_of_ecmo_run)
          : null,
        end_date_of_ecmo_run: report.data.end_date_of_ecmo_run
          ? moment(report.data.end_date_of_ecmo_run)
          : null,
        is_disposable_only_case:
          report?.is_disposable_only_case == 1 ? true : false,

        // Cardiac Procedure Info
        reop: report?.data?.reop ?? "No",
        coronary_artery_bypass: report?.data?.coronary_artery_bypass ?? "No",
        aortic: report?.data?.aortic ?? "No",
        mitral: report?.data?.mitral ?? "No",
        tricuspid: report?.data?.tricuspid ?? "No",
        pulmonic: report?.data?.pulmonic ?? "No",
        other_cardiac_procedure: report?.data?.other_cardiac_procedure ?? "No",
        other_non_cardiac_procedure:
          report?.data?.other_non_cardiac_procedure ?? "No",
        attachments,

        // Quality Indicators
        lowest_hb: report?.data?.lowest_hb ?? null,
        qi_prbc: report?.data?.qi_prbc ?? 0,
        qi_ffp: report?.data?.qi_ffp ?? 0,
        qi_platelets: report?.data?.qi_platelets ?? 0,
        qi_cryo: report?.data?.qi_cryo ?? 0,

        // NRP PROCEDURE INFO
        nrp_is_dry_run_case: report?.data?.nrp_is_dry_run_case ?? false,
        nrp_initiation_date: report?.data?.nrp_initiation_date
          ? moment(report.data.nrp_initiation_date)
          : null,
        nrp_end_date: report?.data?.nrp_end_date
          ? moment(report.data.nrp_end_date)
          : null,
        nrp_aortic_and_ivc_clamp_date: report?.data
          ?.nrp_aortic_and_ivc_clamp_date
          ? moment(report.data.nrp_aortic_and_ivc_clamp_date)
          : null,
        nrp_aortic_cannulation_date: report?.data?.nrp_aortic_cannulation_date
          ? moment(report.data.nrp_aortic_cannulation_date)
          : null,
        nrp_circulatory_cessation_date: report?.data
          ?.nrp_circulatory_cessation_date
          ? moment(report.data.nrp_circulatory_cessation_date)
          : null,
        nrp_cold_aortic_flush_post_date: report?.data
          ?.nrp_cold_aortic_flush_post_date
          ? moment(report.data.nrp_cold_aortic_flush_post_date)
          : null,
        nrp_declaration_of_death_date: report?.data
          ?.nrp_declaration_of_death_date
          ? moment(report.data.nrp_declaration_of_death_date)
          : null,
        nrp_heart_flush_date: report?.data?.nrp_heart_flush_date
          ? moment(report.data.nrp_heart_flush_date)
          : null,
        nrp_incision_date: report?.data?.nrp_incision_date
          ? moment(report.data.nrp_incision_date)
          : null,
        nrp_lung_flush_date: report?.data?.nrp_lung_flush_date
          ? moment(report.data.nrp_lung_flush_date)
          : null,
        nrp_withdrawal_of_life_sustaining_therapy_date: report?.data
          ?.nrp_withdrawal_of_life_sustaining_therapy_date
          ? moment(report.data.nrp_withdrawal_of_life_sustaining_therapy_date)
          : null,
        nrp_venous_cannulation_date: report?.data?.nrp_venous_cannulation_date
          ? moment(report.data.nrp_venous_cannulation_date)
          : null,
        nrp_functional_warm_ischemic_date: report?.data
          ?.nrp_functional_warm_ischemic_date
          ? moment(report.data.nrp_functional_warm_ischemic_date)
          : null,
        nrp_end_of_observation_period_date: report?.data
          ?.nrp_end_of_observation_period_date
          ? moment(report.data.nrp_end_of_observation_period_date)
          : null,
        nrp_medicines: report?.data?.nrp_medicines ?? [],
        nrp_blood_products: report?.data?.nrp_blood_products ?? [],
        organs_considered: report?.data?.organs_considered
          ? report?.data?.organs_considered.split(", ")
          : [],
        organs_recovered: report?.data?.organs_recovered
          ? report?.data?.organs_recovered.split(", ")
          : [],
        nrp_venous_cannula_location:
          report?.data?.nrp_venous_cannula_location ?? null,
        nrp_arterial_cannula_location:
          report?.data?.nrp_arterial_cannula_location ?? null,
        nrp_venous_clamp_location:
          report?.data?.nrp_venous_clamp_location ?? null,
        nrp_aortic_clamp_locations:
          report?.data?.nrp_aortic_clamp_locations ?? null,
        nrp_blended_technology_case:
          report?.data?.nrp_blended_technology_case ?? false,
        nrp_blended_technology_case:
          report?.data?.nrp_blended_technology_case ?? false,
        nrp_blended_organ_1: report?.data?.nrp_blended_technology_case
          ? report?.data?.nrp_blended_organ_1
          : undefined,
        nrp_blended_method_1: report?.data?.nrp_blended_technology_case
          ? report?.data?.nrp_blended_method_1
          : null,
        nrp_blended_other_method_1:
          report?.data?.nrp_blended_technology_case &&
          report?.data?.nrp_blended_method_1 == "Other"
            ? report?.data?.nrp_blended_other_method_1
            : null,
        nrp_blended_organ_2: report?.data?.nrp_blended_technology_case
          ? report?.data?.nrp_blended_organ_2
          : undefined,
        nrp_blended_method_2: report?.data?.nrp_blended_technology_case
          ? report?.data?.nrp_blended_method_2
          : null,
        nrp_blended_other_method_2:
          report?.data?.nrp_blended_technology_case &&
          report?.data?.nrp_blended_method_2 == "Other"
            ? report?.data?.nrp_blended_other_method_2
            : null,
        nrp_blended_organ_3: report?.data?.nrp_blended_technology_case
          ? report?.data?.nrp_blended_organ_3
          : undefined,
        nrp_blended_method_3: report?.data?.nrp_blended_technology_case
          ? report?.data?.nrp_blended_method_3
          : null,
        nrp_blended_other_method_3:
          report?.data?.nrp_blended_technology_case &&
          report?.data?.nrp_blended_method_3 == "Other"
            ? report?.data?.nrp_blended_other_method_3
            : null,

        // DBD Organ Recovery QI Indicators
        dbdor_declaration_of_death_date: report?.data
          ?.dbdor_declaration_of_death_date
          ? moment(report.data.dbdor_declaration_of_death_date)
          : null,
        dbdor_incision_date: report?.data?.dbdor_incision_date
          ? moment(report.data.dbdor_incision_date)
          : null,
        dbdor_cross_clamp_date: report?.data?.dbdor_cross_clamp_date
          ? moment(report.data.dbdor_cross_clamp_date)
          : null,
        dbdor_heparin_date: report?.data?.dbdor_heparin_date
          ? moment(report.data.dbdor_heparin_date)
          : null,
        dbdor_organs,

        // DBD Organ recovery Procedure Info
        dbdor_2_scheduled_or_date: report?.data?.dbdor_2_scheduled_or_date
          ? moment(report.data.dbdor_2_scheduled_or_date)
          : null,
        dbdor_2_kps_team_flight_mission_take_off_date: report?.data
          ?.dbdor_2_kps_team_flight_mission_take_off_date
          ? moment(report.data.dbdor_2_kps_team_flight_mission_take_off_date)
          : null,
        dbdor_2_kps_team_initial_touch_down_date: report?.data
          ?.dbdor_2_kps_team_initial_touch_down_date
          ? moment(report.data.dbdor_2_kps_team_initial_touch_down_date)
          : null,
        dbdor_2_kps_team_arrival_at_donor_facility_date: report?.data
          ?.dbdor_2_kps_team_arrival_at_donor_facility_date
          ? moment(report.data.dbdor_2_kps_team_arrival_at_donor_facility_date)
          : null,
        dbdor_2_kps_setup_complete_date: report?.data
          ?.dbdor_2_kps_setup_complete_date
          ? moment(report.data.dbdor_2_kps_setup_complete_date)
          : null,
        dbdor_2_or_opo_personnel_in_room_date: report?.data
          ?.dbdor_2_or_opo_personnel_in_room_date
          ? moment(report.data.dbdor_2_or_opo_personnel_in_room_date)
          : null,
        dbdor_2_donor_in_room_date: report?.data?.dbdor_2_donor_in_room_date
          ? moment(report.data.dbdor_2_donor_in_room_date)
          : null,
        dbdor_2_kps_team_donor_facility_departure_date: report?.data
          ?.dbdor_2_kps_team_donor_facility_departure_date
          ? moment(report.data.dbdor_2_kps_team_donor_facility_departure_date)
          : null,
        dbdor_2_kps_team_wheels_up_to_recipient_facility_date: report?.data
          ?.dbdor_2_kps_team_wheels_up_to_recipient_facility_date
          ? moment(
              report.data.dbdor_2_kps_team_wheels_up_to_recipient_facility_date
            )
          : null,
        dbdor_2_kps_team_destination_touch_down_date: report?.data
          ?.dbdor_2_kps_team_destination_touch_down_date
          ? moment(report.data.dbdor_2_kps_team_destination_touch_down_date)
          : null,
        dbdor_2_kps_team_arrival_at_recipient_facility_date: report?.data
          ?.dbdor_2_kps_team_arrival_at_recipient_facility_date
          ? moment(
              report.data.dbdor_2_kps_team_arrival_at_recipient_facility_date
            )
          : null,
        dbdor_2_hand_off_complete_date: report?.data
          ?.dbdor_2_hand_off_complete_date
          ? moment(report.data.dbdor_2_hand_off_complete_date)
          : null,

        // Preservation Service
        ps_organ_perfused: report?.data?.ps_organ_perfused ?? [],
        ps: report?.data?.ps ?? {},

        // Operator Timings
        cati_arrival_date: report?.data?.cati_arrival_date
          ? moment(report.data.cati_arrival_date)
          : null,
        cati_arrival_time: report?.data?.cati_arrival_time ?? "",
        cati_departure_date: report?.data?.cati_departure_date
          ? moment(report.data.cati_departure_date)
          : null,
        cati_departure_time: report?.data?.cati_departure_time ?? "",
        cati_total_time: report?.data?.cati_total_time ?? 0,
        cati_total_time_in_seconds:
          report?.data?.cati_total_time_in_seconds ?? 0,

        // Platelet Gel Therapy
        pgt_na_pre_procedure_checklist: oldPgtNaPreProcedureChecklist,
        pgt_pre_procedure_checklist: oldPgtPreProcedureChecklist,
        pgt_na_post_procedure_checklist: oldPgtNaPostProcedureChecklist,
        pgt_post_procedure_checklist: oldPgtPostProcedureChecklist,
        pgt_blood_withdrawal_date: report?.data?.pgt_blood_withdrawal_date
          ? moment(report.data.pgt_blood_withdrawal_date)
          : null,
        pgt_prp_product_given_date: report?.data?.pgt_prp_product_given_date
          ? moment(report.data.pgt_prp_product_given_date)
          : null,
        pgt_first_prp_application_date: report?.data
          ?.pgt_first_prp_application_date
          ? moment(report.data.pgt_first_prp_application_date)
          : null,

        // Extra signs
        extra_sign: report?.data?.extra_sign ?? {
          first_name: null,
          last_name: null,
          title: null,
          sign: null,
          signed_at: null,
          is_added: false,
        },
        additional_time_charge: report.data.additional_time_charge ?? null,

        // EMERGENT CASE STATUS
        emergent_request_date: report.data.emergent_request_date
          ? moment(report.data.emergent_request_date)
          : null,
        arrival_date_into_facility: report.data.arrival_date_into_facility
          ? moment(report.data.arrival_date_into_facility)
          : null,
      };

      if (report.employee_id != this.employee.id) {
        this.hospitals = [
          { ...report.hospital, company_id: report.hospital.companies[0].id },
        ];
      }
    },

    fetchHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl, { with_companies: true })
        .then((res) => {
          let hospitals = res.data.map((item) => ({
            ...item,
            company_id: item.companies[0].id,
          }));
          this.hospitals = hospitals;
        });
    },

    fetchCaseServices() {
      if (this.model.hospital_id) {
        commonService
          .get(this.$constants.getCaseServicesUrl, {
            hospital_id: this.model.hospital_id,
          })
          .then((res) => {
            this.caseServices = res.data.map((item) => ({
              text: item.name,
              value: item.id,
            }));
          });
      }
    },

    back() {
      let query = this.$route?.query ?? {};
      this.$router.replace({
        name: this.$route.name,
        query: { ...query, step: parseInt(this.step) - 1 },
      });
    },

    next(redirect = null, status = 1) {
      this.$refs.case_report_form.validate().then(async () => {
        let query = this.$route?.query ?? {};

        let data = null;
        if (this.step < this.computedSteps.length) {
          // if (
          //   this.step == this.computedSteps.length - 1 &&
          //   this.model.sign == null
          // ) {
          //   this.$message.error(
          //     "Employee signature is required for submission."
          //   );
          //   return;
          // }

          let newPgtPreProcedureChecklist = [];
          for (const [key, value] of Object.entries(
            this.model.pgt_pre_procedure_checklist
          )) {
            if (value) {
              newPgtPreProcedureChecklist.push(key);
            }
          }
          let newPgtNaPreProcedureChecklist = [];
          for (const [key, value] of Object.entries(
            this.model.pgt_na_pre_procedure_checklist
          )) {
            if (value) {
              newPgtNaPreProcedureChecklist.push(key);
            }
          }
          let newPgtPostProcedureChecklist = [];
          for (const [key, value] of Object.entries(
            this.model.pgt_post_procedure_checklist
          )) {
            if (value) {
              newPgtPostProcedureChecklist.push(key);
            }
          }
          let newPgtNaPostProcedureChecklist = [];
          for (const [key, value] of Object.entries(
            this.model.pgt_na_post_procedure_checklist
          )) {
            if (value) {
              newPgtNaPostProcedureChecklist.push(key);
            }
          }

          let dbdor_organs = {};
          if ("dbdor_organs" in this.model) {
            let dbdorOrgansKeys = Object.keys(this.model.dbdor_organs);
            dbdorOrgansKeys.map((e) => {
              dbdor_organs[e] = this.model.dbdor_organs[e];

              [
                "start_of_cold_flush_date",
                "end_of_cold_flush_date",
                "out_of_body_date",
              ].map((el) => {
                dbdor_organs[e][el] = this.model.dbdor_organs[e][el]
                  ? this.$customDate.ymd(this.model.dbdor_organs[e][el])
                  : null;
              });
            });
          }

          data = {
            ...this.model,
            uuid: this.uuid,
            date: this.$customDate.ymd(this.model.date),
            patient_in_room_time: this.timeFormat(
              this.model.patient_in_room_time
            ),
            surgery_start_time: this.timeFormat(this.model.surgery_start_time),
            surgery_end_time: this.timeFormat(this.model.surgery_end_time),
            patient_out_of_room_time: this.timeFormat(
              this.model.patient_out_of_room_time
            ),
            start_date_of_ecmo_run: this.model.start_date_of_ecmo_run
              ? this.$customDate.ymd(this.model.start_date_of_ecmo_run)
              : null,
            start_time_of_ecmo_run: this.timeFormat(
              this.model.start_time_of_ecmo_run
            ),
            end_date_of_ecmo_run: this.model.end_date_of_ecmo_run
              ? this.$customDate.ymd(this.model.end_date_of_ecmo_run)
              : null,
            end_time_of_ecmo_run: this.timeFormat(
              this.model.end_time_of_ecmo_run
            ),
            cati_arrival_date: this.model.cati_arrival_date
              ? this.$customDate.ymd(this.model.cati_arrival_date)
              : null,
            cati_arrival_time: this.model.cati_arrival_time
              ? this.timeFormat(this.model.cati_arrival_time)
              : null,
            cati_departure_date: this.model.cati_departure_date
              ? this.$customDate.ymd(this.model.cati_departure_date)
              : null,
            cati_departure_time: this.model.cati_departure_time
              ? this.timeFormat(this.model.cati_departure_time)
              : null,
            pgt_na_pre_procedure_checklist: newPgtNaPreProcedureChecklist,
            pgt_pre_procedure_checklist: newPgtPreProcedureChecklist,
            pgt_na_post_procedure_checklist: newPgtNaPostProcedureChecklist,
            pgt_post_procedure_checklist: newPgtPostProcedureChecklist,

            // Operative Data
            emergent_request_date: this.model.emergent_request_date
              ? this.$customDate.ymd(this.model.emergent_request_date)
              : null,
            arrival_date_into_facility: this.model.arrival_date_into_facility
              ? this.$customDate.ymd(this.model.arrival_date_into_facility)
              : null,

            // NRP PROCEDURE INFO
            nrp_initiation_date: this.model.nrp_initiation_date
              ? this.$customDate.ymd(this.model.nrp_initiation_date)
              : null,
            nrp_end_date: this.model.nrp_end_date
              ? this.$customDate.ymd(this.model.nrp_end_date)
              : null,
            nrp_aortic_and_ivc_clamp_date: this.model
              .nrp_aortic_and_ivc_clamp_date
              ? this.$customDate.ymd(this.model.nrp_aortic_and_ivc_clamp_date)
              : null,
            nrp_aortic_cannulation_date: this.model.nrp_aortic_cannulation_date
              ? this.$customDate.ymd(this.model.nrp_aortic_cannulation_date)
              : null,
            nrp_circulatory_cessation_date: this.model
              .nrp_circulatory_cessation_date
              ? this.$customDate.ymd(this.model.nrp_circulatory_cessation_date)
              : null,
            nrp_cold_aortic_flush_post_date: this.model
              .nrp_cold_aortic_flush_post_date
              ? this.$customDate.ymd(this.model.nrp_cold_aortic_flush_post_date)
              : null,
            nrp_declaration_of_death_date: this.model
              .nrp_declaration_of_death_date
              ? this.$customDate.ymd(this.model.nrp_declaration_of_death_date)
              : null,
            nrp_heart_flush_date: this.model.nrp_heart_flush_date
              ? this.$customDate.ymd(this.model.nrp_heart_flush_date)
              : null,
            nrp_incision_date: this.model.nrp_incision_date
              ? this.$customDate.ymd(this.model.nrp_incision_date)
              : null,
            nrp_lung_flush_date: this.model.nrp_lung_flush_date
              ? this.$customDate.ymd(this.model.nrp_lung_flush_date)
              : null,
            nrp_withdrawal_of_life_sustaining_therapy_date: this.model
              .nrp_withdrawal_of_life_sustaining_therapy_date
              ? this.$customDate.ymd(
                  this.model.nrp_withdrawal_of_life_sustaining_therapy_date
                )
              : null,
            nrp_venous_cannulation_date: this.model.nrp_venous_cannulation_date
              ? this.$customDate.ymd(this.model.nrp_venous_cannulation_date)
              : null,
            nrp_functional_warm_ischemic_date: this.model
              .nrp_functional_warm_ischemic_date
              ? this.$customDate.ymd(
                  this.model.nrp_functional_warm_ischemic_date
                )
              : null,
            nrp_end_of_observation_period_date: this.model
              .nrp_end_of_observation_period_date
              ? this.$customDate.ymd(
                  this.model.nrp_end_of_observation_period_date
                )
              : null,
            organs_considered: this.model?.organs_considered
              ? this.model?.organs_considered.join(", ")
              : null,
            organs_recovered: this.model?.organs_recovered
              ? this.model?.organs_recovered.join(", ")
              : null,
            nrp_blended_organ_1: this.model.nrp_blended_technology_case
              ? this.model.nrp_blended_organ_1
              : undefined,
            nrp_blended_method_1: this.model.nrp_blended_technology_case
              ? this.model.nrp_blended_method_1
              : undefined,
            nrp_blended_other_method_1:
              this.model.nrp_blended_technology_case &&
              this.model.nrp_blended_method_1 == "Other"
                ? this.model.nrp_blended_other_method_1
                : undefined,
            nrp_blended_organ_2: this.model.nrp_blended_technology_case
              ? this.model.nrp_blended_organ_2
              : undefined,
            nrp_blended_method_2: this.model.nrp_blended_technology_case
              ? this.model.nrp_blended_method_2
              : undefined,
            nrp_blended_other_method_2:
              this.model.nrp_blended_technology_case &&
              this.model.nrp_blended_method_2 == "Other"
                ? this.model.nrp_blended_other_method_2
                : undefined,
            nrp_blended_organ_3: this.model.nrp_blended_technology_case
              ? this.model.nrp_blended_organ_3
              : undefined,
            nrp_blended_method_3: this.model.nrp_blended_technology_case
              ? this.model.nrp_blended_method_3
              : undefined,
            nrp_blended_other_method_3:
              this.model.nrp_blended_technology_case &&
              this.model.nrp_blended_method_3 == "Other"
                ? this.model.nrp_blended_other_method_3
                : undefined,

            // NRP PROCEDURE INFO 2
            nrp_2_scheduled_or_date: this.model.nrp_2_scheduled_or_date
              ? this.$customDate.ymd(this.model.nrp_2_scheduled_or_date)
              : null,
            nrp_2_kps_team_arrival_date: this.model.nrp_2_kps_team_arrival_date
              ? this.$customDate.ymd(this.model.nrp_2_kps_team_arrival_date)
              : null,
            nrp_2_kps_setup_complete_date: this.model
              .nrp_2_kps_setup_complete_date
              ? this.$customDate.ymd(this.model.nrp_2_kps_setup_complete_date)
              : null,
            nrp_2_or_opo_personnel_in_room_date: this.model
              .nrp_2_or_opo_personnel_in_room_date
              ? this.$customDate.ymd(
                  this.model.nrp_2_or_opo_personnel_in_room_date
                )
              : null,
            nrp_2_donor_in_room_date: this.model.nrp_2_donor_in_room_date
              ? this.$customDate.ymd(this.model.nrp_2_donor_in_room_date)
              : null,
            nrp_2_kps_team_depature_date: this.model
              .nrp_2_kps_team_depature_date
              ? this.$customDate.ymd(this.model.nrp_2_kps_team_depature_date)
              : null,

            // DBD Organ Recovery QI Indicators
            dbdor_declaration_of_death_date: this.model
              .dbdor_declaration_of_death_date
              ? this.$customDate.ymd(this.model.dbdor_declaration_of_death_date)
              : null,
            dbdor_incision_date: this.model.dbdor_incision_date
              ? this.$customDate.ymd(this.model.dbdor_incision_date)
              : null,
            dbdor_cross_clamp_date: this.model.dbdor_cross_clamp_date
              ? this.$customDate.ymd(this.model.dbdor_cross_clamp_date)
              : null,
            dbdor_heparin_date: this.model.dbdor_heparin_date
              ? this.$customDate.ymd(this.model.dbdor_heparin_date)
              : null,
            dbdor_organs,

            // DBD Organ recovery Procedure Info
            dbdor_2_scheduled_or_date: this.model.dbdor_2_scheduled_or_date
              ? this.$customDate.ymd(this.model.dbdor_2_scheduled_or_date)
              : null,
            dbdor_2_kps_team_flight_mission_take_off_date: this.model
              .dbdor_2_kps_team_flight_mission_take_off_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_kps_team_flight_mission_take_off_date
                )
              : null,
            dbdor_2_kps_team_initial_touch_down_date: this.model
              .dbdor_2_kps_team_initial_touch_down_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_kps_team_initial_touch_down_date
                )
              : null,
            dbdor_2_kps_team_arrival_at_donor_facility_date: this.model
              .dbdor_2_kps_team_arrival_at_donor_facility_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_kps_team_arrival_at_donor_facility_date
                )
              : null,
            dbdor_2_kps_setup_complete_date: this.model
              .dbdor_2_kps_setup_complete_date
              ? this.$customDate.ymd(this.model.dbdor_2_kps_setup_complete_date)
              : null,
            dbdor_2_or_opo_personnel_in_room_date: this.model
              .dbdor_2_or_opo_personnel_in_room_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_or_opo_personnel_in_room_date
                )
              : null,
            dbdor_2_donor_in_room_date: this.model.dbdor_2_donor_in_room_date
              ? this.$customDate.ymd(this.model.dbdor_2_donor_in_room_date)
              : null,
            dbdor_2_kps_team_donor_facility_departure_date: this.model
              .dbdor_2_kps_team_donor_facility_departure_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_kps_team_donor_facility_departure_date
                )
              : null,
            dbdor_2_kps_team_wheels_up_to_recipient_facility_date: this.model
              .dbdor_2_kps_team_wheels_up_to_recipient_facility_date
              ? this.$customDate.ymd(
                  this.model
                    .dbdor_2_kps_team_wheels_up_to_recipient_facility_date
                )
              : null,
            dbdor_2_kps_team_destination_touch_down_date: this.model
              .dbdor_2_kps_team_destination_touch_down_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_kps_team_destination_touch_down_date
                )
              : null,
            dbdor_2_kps_team_arrival_at_recipient_facility_date: this.model
              .dbdor_2_kps_team_arrival_at_recipient_facility_date
              ? this.$customDate.ymd(
                  this.model.dbdor_2_kps_team_arrival_at_recipient_facility_date
                )
              : null,
            dbdor_2_hand_off_complete_date: this.model
              .dbdor_2_hand_off_complete_date
              ? this.$customDate.ymd(this.model.dbdor_2_hand_off_complete_date)
              : null,

            // Platelet Gel Therapy
            pgt_blood_withdrawal_date: this.model.pgt_blood_withdrawal_date
              ? this.$customDate.ymd(this.model.pgt_blood_withdrawal_date)
              : null,
            pgt_prp_product_given_date: this.model.pgt_prp_product_given_date
              ? this.$customDate.ymd(this.model.pgt_prp_product_given_date)
              : null,
            pgt_first_prp_application_date: this.model
              .pgt_first_prp_application_date
              ? this.$customDate.ymd(this.model.pgt_first_prp_application_date)
              : null,
          };
          if (this.uuid == null) {
            data.is_can_receive_organs =
              this.$comman.isCanReceiveOrgansHospital(this.hospital);
          }

          delete data.attachments;
        } else {
          if (this.model.sign == null) {
            this.$message.error(
              "Employee signature is required for submission."
            );
            return;
          }

          if (
            this.model.extra_sign.is_added &&
            this.model.extra_sign.sign == null
          ) {
            this.$message.error(
              "Additional signature is required for submission."
            );
            return;
          }

          if (
            this.model.attachments.length > 0 ||
            this.record.attachments.length > 0
          ) {
            let attachments = new FormData();
            attachments.append("_method", "POST");
            attachments.append("uuid", this.uuid);
            this.model.attachments.forEach((img) => {
              if ("id" in img) {
                attachments.append(`old_attachments[]`, img.id);
              } else {
                attachments.append(`attachments[]`, img);
              }
            });

            let oldAttachmentLength = attachments.has(`old_attachments[]`)
              ? attachments.getAll(`old_attachments[]`).length
              : 0;

            if (
              attachments.has(`attachments[]`) ||
              this.record.attachments.length != oldAttachmentLength
            ) {
              this.submitBtnLoader = true;
              await commonService.store(
                this.$constants.caseReportUploadAttachmentsUrl,
                attachments
              );
            }
          }

          data = {
            is_last_step: true,
            uuid: this.uuid,
            submitted_at: this.submittedAt,
            sign: this.model.sign,
            signed_at: this.model.signed_at,
            extra_sign: this.model.extra_sign,
            status: status,
            additional_procedural_notes: this.model.additional_procedural_notes,
            additional_surgical_notes: this.model.additional_surgical_notes,
            cati_arrival_date: this.model.cati_arrival_date
              ? this.$customDate.ymd(this.model.cati_arrival_date)
              : null,
            cati_arrival_time: this.model.cati_arrival_time
              ? this.timeFormat(this.model.cati_arrival_time)
              : null,
            cati_departure_date: this.model.cati_departure_date
              ? this.$customDate.ymd(this.model.cati_departure_date)
              : null,
            cati_departure_time: this.model.cati_departure_time
              ? this.timeFormat(this.model.cati_departure_time)
              : null,
            cati_total_time: this.model.cati_total_time,
            cati_total_time_in_seconds:
              this.model.cati_total_time_in_seconds ?? 0,
            additional_time_charge: this.model.additional_time_charge ?? null,
          };
        }

        this.submitBtnLoader = true;
        commonService
          .store(this.$constants.caseReportStoreUrl, data)
          .then((res) => {
            this.$message.success(res.message);
            this.modifyModelData(res.data);
            if (redirect) {
              if (["admin.case-report", "case-report"].includes(redirect)) {
                this.$router.go(-1);
              } else {
                this.$router.push({
                  name: redirect,
                  params: { uuid: this.uuid },
                });
              }
            } else {
              if (this.step == this.computedSteps.length) {
                return this.$router.push({
                  name:
                    this.$route.name == "admin.create-case-report"
                      ? "admin.case-report"
                      : "case-report",
                });
              }
              this.$router.replace({
                name: this.$route.name,
                query: {
                  ...query,
                  step: parseInt(this.step) + 1,
                  uuid: this.uuid,
                },
              });
            }
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    timeFormat(time) {
      return time ? moment(time, "HH:mm").format("HH:mm") : null;
    },

    saveAndSubmit(status) {
      if (
        this.isCatiInvoiceAmountShow &&
        this.model.cati_total_time_in_seconds < 0
      ) {
        this.$message.error("Total time must be greater then 0!");
        return;
      }

      if (status == 1) {
        return this.next(null, status);
      }

      let that = this;
      Modal.confirm({
        title: () => `Are you sure you want to mark this procedure complete?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          return that.next(null, status);
        },
        okText: "Confirm",
        okType: "danger",
        keyboard: false,
        onCancel() {
          return;
        },
      });
    },

    calculateCatiTotalTime() {
      if (
        this.model.cati_arrival_date &&
        this.model.cati_arrival_time &&
        this.model.cati_departure_date &&
        this.model.cati_departure_time
      ) {
        let startDatetime = moment(
          `${this.$customDate.mdy(this.model.cati_arrival_date)} ${
            this.model.cati_arrival_time
          }`
        );
        let endDatetime = moment(
          `${this.$customDate.mdy(this.model.cati_departure_date)} ${
            this.model.cati_departure_time
          }`
        );
        this.model.cati_total_time_in_seconds = endDatetime.diff(
          startDatetime,
          "seconds"
        );
        this.model.cati_total_time = this.$comman.secondsToHms(
          this.model.cati_total_time_in_seconds
        );
      } else {
        this.model.cati_total_time_in_seconds = 0;
        this.model.cati_total_time = 0;
      }
    },

    handleOrganConsiderChange() {
      let psKeys = Object.keys(this.model.ps);
      let dbdorOrgansKeys = Object.keys(this.model.dbdor_organs);
      this.model.organs_considered.forEach((e) => {
        if (!psKeys.includes(e)) {
          this.model.ps[e] = {
            is_details_not_provided: false,
            removed: {
              type_of_perservation_solution: undefined,
              cannula_location: null,
              solution_volumn: null,
              delivery_pressure: null,
            },
            placed: {
              type_of_perservation_solution: undefined,
              cannula_location: null,
              solution_volumn: null,
              delivery_pressure: null,
            },
          };
        }

        if (!dbdorOrgansKeys.includes(e)) {
          this.model.dbdor_organs[e] = {
            start_of_cold_flush_date: null,
            start_of_cold_flush_time: null,
            end_of_cold_flush_date: null,
            end_of_cold_flush_time: null,
            out_of_body_date: null,
            out_of_body_time: null,
          };
        }
      });

      psKeys = Object.keys(this.model.ps);
      psKeys.forEach((e) => {
        if (!this.model.organs_considered.includes(e)) {
          delete this.model.ps[e];
        }
      });

      dbdorOrgansKeys = Object.keys(this.model.dbdor_organs);
      dbdorOrgansKeys.forEach((e) => {
        if (!this.model.organs_considered.includes(e)) {
          delete this.model.dbdor_organs[e];
        }
      });
    },
  },

  watch: {
    "model.hospital_id": {
      handler: function (_, pre) {
        if (pre) {
          this.model.services = [];
          this.model.products = [];
          this.model.is_disposable_only_case = false;
        }
        this.fetchCaseServices();
      },
    },
    "model.nrp_is_dry_run_case": {
      handler: function (curr) {
        this.model.organs_recovered = this.model.organs_recovered.filter(
          (e) => e != "None"
        );
        if (curr) {
          this.model.organs_recovered.push("None");
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
h4.ant-typography,
.ant-typography.h4 {
  margin-bottom: 0px !important;
}
</style>

<style lang="less">
.case-report-progress .ant-progress-text {
  width: 4em;
}

.case-report-progress.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-4em - 8px);
  padding-right: calc(4em + 8px);
}
</style>
