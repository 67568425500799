import axios from 'axios';
import { api } from "../config/config";

export const tableService = {
  get: (params, dataTableURL, results) => {
    return axios
      .post(api.host + dataTableURL, {
        results,
        pageSize: results,
        ...params
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  statusChange: (url, val) => {
    return axios
      .post(api.host + url, { ...val })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return Promise.reject(err.response.data);
      });
  }
}