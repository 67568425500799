<template>
  <div>
    <a-form
      name="certification_type_form"
      ref="certification_type_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="attachment_limit"
            label="Attachment Selection"
            name="attachment_limit"
          >
            <a-radio-group
              v-model:value="formState.attachment_limit"
              v-for="type in $constants.attachment_limit"
              :key="type.value"
            >
              <a-radio-button :value="type.value"
                >{{ type.type }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="filetype_supported"
            label="File Type Support"
            name="filetype_supported"
          >
            <a-select
              v-model:value="formState.filetype_supported"
              mode="multiple"
              placeholder="select filetype"
            >
              <a-select-option
                v-for="type in $constants.filetype_select"
                :key="type"
                :value="type"
              >
                {{ type }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="is_expirable"
            label="Is Expirable ?"
            name="is_expirable"
          >
            <a-radio-group v-model:value="formState.is_expirable">
              <a-radio-button :value="1">Yes</a-radio-button>
              <a-radio-button :value="0">No</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="description"
            label="Description (Optional)"
            name="description"
          >
            <a-textarea
              v-model:value="formState.description"
              placeholder="Enter description here"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24">
          <a-space direction="vertical">
            <div>
              <a-switch v-model:checked="formState.has_state" />
              Is certificate/license depend on state?
            </div>

            <div v-for="item in employeeTypeChecks" :key="item.key">
              <a-switch v-model:checked="formState[item.key]" />
              Is this category required and applicable to {{ item.text }}?
            </div>
          </a-space>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {},
      employeeTypeChecks: this.$constants.employee_types,
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
        attachment_limit: [
          {
            required: true,
            type: "number",
            message: "attachment limit is required!",
            trigger: "blur",
          },
        ],
        filetype_supported: [
          {
            required: true,
            type: "array",
            message: "file type support is required!",
            trigger: "blur",
          },
        ],
        is_expirable: [
          {
            required: true,
            type: "number",
            message: "Please select! certificate is expirable or not",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getFormState();
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        description: this.record.description ?? "",
        attachment_limit: this.record.attachment_limit
          ? this.record.attachment_limit
          : 0,
        filetype_supported: this.record.filetype_supported
          ? this.record.filetype_supported.split(",")
          : [],
        is_expirable: this.record.is_expirable ? this.record.is_expirable : 0,
        has_state: !!this.record.has_state,
      };
      this.$constants.employee_types.forEach((item) => {
        this.formState[item.key] = !!this.record[item.key];
      });
    },
    onSubmit() {
      this.$refs.certification_type_form
        .validate()
        .then(() => {
          if (this.record.id) {
            this.formState.id = this.record.id;
          }
          this.loadingStart();
          companyService
            .store(this.$constants.certificationTypeStoreUrl, this.formState)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style></style>
