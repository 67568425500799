<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          Dashboard
        </template>
      </page-header>
    </a-layout-content>
  </div>
</template>

<script>
export default {
  //
};
</script>
