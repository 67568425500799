<template>
  <div class="mt-sm-2">
    <div
      :style="{
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        html-type="button"
        :style="{ marginRight: '8px' }"
        @click="close()"
      >
        Cancel
      </a-button>
      <a-button type="primary" html-type="submit" :loading="loading">
        {{ name }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["name"],
  computed: {
    ...mapGetters("drawer", ["loading"]),
  },
  methods: {
    ...mapActions("drawer", ["close"]),
  },
};
</script>

<style></style>
