<template>
  <a-skeleton :loading="cardLoading">
    <a-form
      name="quickbooks_form"
      ref="quickbooks_form"
      :model="model"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" class="mb-sm-1">
          <a-button
            size="small"
            type="primary"
            ghost
            htmlType="button"
            @click="quickbooksGetAccount"
            :loading="quickbookGetAccountLoading"
          >
            <a-space>
              <i class="ti ti-refresh ti-1x"></i>
              Get accounts from QuickBooks
            </a-space>
          </a-button>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="vendor_bill_account"
            :name="['vendor_bill_account', 'id']"
            :rules="{
              required: true,
              message: 'This field is required!',
            }"
          >
            <template #label>
              <a-space> Expense Account </a-space>
            </template>

            <a-select
              v-model:value="model.vendor_bill_account.id"
              placeholder="Select category"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in accounts"
                :key="option.Id"
                :label="option.FullyQualifiedName"
                :value="option.Id"
              >
                {{ option.FullyQualifiedName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="contractor_payment_account"
            :name="['contractor_payment_account', 'id']"
            :rules="{
              required: true,
              message: 'This field is required!',
            }"
          >
            <template #label>
              <a-space> Contractor Account </a-space>
            </template>

            <a-select
              v-model:value="model.contractor_payment_account.id"
              placeholder="Select category"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in accounts"
                :key="option.Id"
                :label="option.FullyQualifiedName"
                :value="option.Id"
              >
                {{ option.FullyQualifiedName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
        Save
      </a-button>
    </a-form>
  </a-skeleton>
</template>

<script>
import { route } from "../../../helper/route";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      quickbookGetAccountLoading: false,
      submitBtnLoader: false,
      accounts: [],
      model: {
        vendor_bill_account: {
          id: undefined,
          name: null,
        },
        contractor_payment_account: {
          id: undefined,
          name: null,
        },
      },
    };
  },

  computed: {
    comp() {
      return route("admin/setting/" + this.activeTabKey + ".vue");
    },
  },

  mounted() {
    this.getQuickbooksData();
    this.fetchSetting();
  },

  methods: {
    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, { names: "accounts" })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "accounts") {
                this.accounts = JSON.parse(v.data);
              }
            });
          }
        });
    },

    fetchSetting() {
      this.cardLoading = true;

      commonService
        .store(this.$constants.getSettingUrl)
        .then((res) => {
          if (res.success) {
            if (res.data.vendor_bill_account) {
              let account = JSON.parse(res.data.vendor_bill_account);
              this.accounts.push({
                Id: account.id,
                Name: account.name,
              });
              this.model.vendor_bill_account = account;
            }
            if (res.data.contractor_payment_account) {
              let contractorPaymentAccount = JSON.parse(
                res.data.contractor_payment_account
              );
              if (
                !this.accounts.find((v) => v.Id == contractorPaymentAccount.id)
              ) {
                this.accounts.push({
                  Id: contractorPaymentAccount.id,
                  Name: contractorPaymentAccount.name,
                });
              }
              this.model.contractor_payment_account = contractorPaymentAccount;
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    quickbooksGetAccount() {
      this.quickbookGetAccountLoading = true;
      commonService
        .store(this.$constants.getAccountFromQuickbooksUrl)
        .then((res) => {
          this.accounts = res.data?.accounts ?? [];
          this.$message.success("New options available.");
          // this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.quickbookGetAccountLoading = false;
        });
    },

    handleFinish() {
      this.submitBtnLoader = true;

      let data = [];
      let account = this.accounts.find(
        (item) => item.Id == this.model.vendor_bill_account.id
      );
      if (account) {
        data.vendor_bill_account = JSON.stringify({
          id: this.model.vendor_bill_account.id,
          name: account.Name,
        });
      }
      let contractorPaymentAccount = this.accounts.find(
        (item) => item.Id == this.model.contractor_payment_account.id
      );
      if (contractorPaymentAccount) {
        data.contractor_payment_account = JSON.stringify({
          id: this.model.contractor_payment_account.id,
          name: contractorPaymentAccount.Name,
        });
      }

      commonService
        .store(this.$constants.settingStoreUrl, { ...data })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
