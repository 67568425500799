<template>
  <a-form
    name="live_charting_medication_form"
    ref="live_charting_medication_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item ref="time" label="Time" name="time">
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeMaska"
            placeholder="HH:mm"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item ref="drug" label="Drug" name="drug">
          <a-input v-model:value="model.drug" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item ref="dose" label="Dose" name="dose">
          <a-input v-model:value="model.dose" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="administered_by"
          label="Adminsitered By"
          name="administered_by"
        >
          <a-radio-group v-model:value="model.administered_by">
            <a-radio value="Perfusion">Perfusion</a-radio>
            <a-radio value="Anesthesia">Anesthesia</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      model: {
        time: moment().tz(this.$constants.timezone).format("HH:mm"),
        drug: "",
        dose: "",
        administered_by: "",
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      console.log("submitted::", this.model);
      return;

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
