<template>
  <a-skeleton active :loading="loading">
    <a-form ref="manufacturersList" :model="manufacturerFields">
      <a-table
        size="small"
        :columns="columns"
        :data-source="manufacturerFields.rows"
        :rowKey="(index) => index"
        bordered
        :pagination="false"
      >
        <template #manufacturer_id="{ record, index }">
          <a-form-item
            ref="manufacturer_id"
            :name="['rows', index, 'manufacturer_id']"
            :rules="{
              required: true,
              message: 'field is required!',
              trigger: 'change',
              type: 'number',
            }"
            :wrapperCol="{ style: { width: '100%' } }"
          >
            <a-select
              v-model:value="record.manufacturer_id"
              placeholder="select manufacturer"
              optionFilterProp="label"
              :showSearch="true"
              width="100%"
              @change="handleManufacturerSelect(index)"
            >
              <a-select-option
                v-for="manufacturer in unSelectedManufacturers(record)"
                :key="manufacturer.id"
                :label="`${manufacturer.name}`"
                :value="manufacturer.id"
              >
                {{ manufacturer.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>

        <template #actions="{ index }">
          <a-button
            v-if="manufacturerFields.rows.length > 1"
            type="link"
            class="danger-color"
            @click="handleFieldRemove(index)" style="margin-bottom: 24px;"
          >
          <i class="ti ti-trash ti-lg"></i>
          </a-button>
        </template>

        <template #footer>
          <a-button type="dashed" block @click="addField()">
            <i class="fas fa-plus mr-sm-1"></i>
            Add field
          </a-button>
        </template>
      </a-table>

      <a-button
        type="primary"
        html-type="submit"
        class="mt-sm-1"
        @click="handleFinish"
      >
        Submit
      </a-button>
    </a-form>
  </a-skeleton>
</template>

<script>
import { commonService } from "../../services";

export default {
  name: "hospital-manufacturer",
  props: {
    hospitalId: {
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      manufacturers: [],
      manufacturerMaps: [],
      manufacturerFields: {
        rows: [],
      },
      manufacturerField: {
        manufacturer_id: null,
      },
      manufacturerIds: [],

      columns: [
        {
          title: "Manufacturer Name",
          dataIndex: "manufacturer_id",
          slots: {
            customRender: "manufacturer_id",
          },
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
    };
  },

  async mounted() {
    await this.getManufacturers();
    await this.getHospitalDetails();
  },

  methods: {
    getHospitalDetails() {
      this.loading = true;
      return commonService
        .get(this.$constants.hospitalDetailUrl, {
          id: this.hospitalId,
          with_manufacturers: true,
        })
        .then((res) => {
          this.manufacturerMaps = res.data.manufacturers;
          if (this.manufacturerMaps) {
            var test = this.manufacturerMaps.map((v) => {
              let newValue = {};
              newValue.manufacturer_id = v.id;
              return newValue;
            });
            this.manufacturerFields.rows = test;
            this.updateManufactureList();
          }
        })
        .finally(() => (this.loading = false));
    },

    getManufacturers() {
      return commonService
        .get(this.$constants.getManufacturersUrl)
        .then((res) => {
          this.manufacturers = res.data;
        });
    },

    addField() {
      this.manufacturerFields.rows.push(
        Object.assign({}, this.manufacturerField)
      );
    },

    handleManufacturerSelect(i) {
      this.updateManufactureList();
    },

    updateManufactureList() {
      let manufacturerIds = [];
      this.manufacturerFields.rows.forEach((v) => {
        if (v.manufacturer_id != null) {
          manufacturerIds.push(v.manufacturer_id);
        }
      });
      this.manufacturers.map((v) => {
        if (manufacturerIds.includes(v.id)) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });
    },

    handleFieldRemove(i) {
      this.manufacturerFields.rows.splice(i, 1);
      this.updateManufactureList();
    },

    handleFinish() {
      this.$refs.manufacturersList.validate().then(() => {
        let val = {
          manufacturers: this.manufacturerFields.rows,
          id: this.hospitalId,
        };
        this.loading = true;
        commonService
          .store(this.$constants.hospitalManufacturersUrl, val)
          .then((res) => {
            this.loading = false;
            this.$message.success(res.message);
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    unSelectedManufacturers(value) {
      return this.manufacturers.filter((v) => {
        if (v.id == value.manufacturer_id || v.selected != true) {
          return v;
        }
      });
    },
  },
};
</script>