export default {
  data() {
    return {
      errors: {},
    };
  },
  methods: {
    validate(fieldName, type) {
      if (this.errors) {
        if (fieldName in this.errors) {
          if (type == "status") {
            return "error";
          } else if (type == "msg") {
            return this.errors[fieldName][0];
          }
        }
      }
    },
    resetErrors(fieldName, extra = {}) {
      if ("toUpperCase" in extra && extra.toUpperCase) {
        this.model[fieldName] = this.model[fieldName].toUpperCase();
      }
      if (this.errors) {
        if (fieldName in this.errors) {
          delete this.errors[fieldName];
        }
      }
    },
  },
};
