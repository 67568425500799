<template>
  <div class="theme-modal">
    <a-modal
      :title="title"
      :width="width"
      :closable="false"
      :visible="showModal"
      :maskClosable="false"
      :destroyOnClose="true"
      :bodyStyle="{
        maxHeight: '500px',
        overflowY: 'scroll',
        padding: '14px',
      }"
      style="top: 20px"
      :footer="null"
    >
      <component v-if="is" :is="is" @callback="this.callback">
        <router-view />
      </component>
    </a-modal>
  </div>
</template>

<script>
import { route } from "@/helper/route";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("modal", ["path", "title", "width", "showModal", "callback"]),

    is() {
      var path = this.path;
      if (path != "") {
        path = path.replaceAll(".", "/");
        return route(path);
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("modal", ["close"]),
  },
};
</script>
