<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #title>
        Sign Offer Letter | {{ candidate?.full_name ?? "" }}
      </template>

      <template #extra>
        <!--  -->
      </template>
    </a-page-header>

    <a-card :loading="loading" size="small">
      <a-row>
        <a-col :span="12">
          <a-empty v-if="!pdfPreviewURL">
            <template #description>
              <span> Offer letter preview will be shown here </span>
            </template>
          </a-empty>

          <template v-else>
            <iframe
              :src="pdfPreviewURL + '#toolbar=0&navpanes=0&scrollbar=0'"
              type="application/pdf"
              width="100%"
              @click.right.stop.prevent
              style="height: 100vh"
            />
          </template>
        </a-col>

        <a-col :span="12">
          <signature-box v-model:sign="sign">
            <template #note>
              <a-typography-paragraph
                style="max-width: 600px; text-align: justify"
              >
                By signing below, I, {{ employee.first_name }}
                {{ employee.last_name }}, in my capacity as Administration at
                Keystone Perfusion Services, PC, acknowledge my acceptance and
                delivery of the terms and conditions outlined in this employment
                offer letter/employment agreement.
              </a-typography-paragraph>
            </template>

            <template #buttons>
              <a-button
                type="primary"
                html-type="button"
                @click="submitSign"
                :loading="submitBtnLoader"
              >
                Submit
              </a-button>
            </template>
          </signature-box>
        </a-col>
      </a-row>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { mapGetters } from "vuex";
import signatureBox from "../../../components/signatureBox.vue";

export default {
  components: { signatureBox },

  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      candidate: null,
      pdfPreviewURL: null,
      submitBtnLoader: false,
      sign: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
  },

  mounted() {
    this.getCandidate();
    this.previewPDF();
  },

  methods: {
    getCandidate() {
      commonService
        .get(this.$constants.candidateShowUrl, { id: this.id })
        .then((res) => {
          this.candidate = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "candidates" });
        });
    },

    previewPDF() {
      commonService
        .downloadFile(
          this.$constants.offerLetterPreviewUrl,
          { id: this.id },
          "Offer Letter.pdf",
          true
        )
        .then((res) => {
          this.pdfPreviewURL = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.loading = false));
    },

    submitSign() {
      if (this.sign == null) {
        this.$message.error("Employee signature is required for submission.");
        return;
      }

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.candidateSignOfferLetterUrl, {
          id: this.id,
          sign: this.sign,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally((err) => {
          this.submitBtnLoader = false;
        });
    },
  },
};
</script>
