<template>
  <a-row type="flex">
    <a-col flex="35px">
      <a-tooltip title="Select all N/A option">
        <a-button
          type="link"
          size="small"
          class="p-sm-0"
          @click="preSelectAllOption('pgt_na_pre_procedure_checklist')"
        >
          N/A
        </a-button>
      </a-tooltip>
    </a-col>
    <a-col flex="35px">
      <a-tooltip title="Select all option">
        <a-button
          type="link"
          size="small"
          class="p-sm-0"
          @click="preSelectAllOption('pgt_pre_procedure_checklist')"
        >
          <CheckOutlined />
        </a-button>
      </a-tooltip>
    </a-col>
    <a-col flex="auto">
      <strong>Pre-Procedure Checklist</strong>
    </a-col>
  </a-row>

  <template v-for="option in preChecklist" :key="option">
    <a-row type="flex">
      <a-col flex="35px">
        <a-checkbox
          v-model:checked="model.pgt_na_pre_procedure_checklist[option]"
          @click="
            (e) => preOptionChecked(e, option, 'pgt_na_pre_procedure_checklist')
          "
        />
      </a-col>
      <a-col flex="35px">
        <a-checkbox
          v-model:checked="model.pgt_pre_procedure_checklist[option]"
          @click="
            (e) => preOptionChecked(e, option, 'pgt_pre_procedure_checklist')
          "
        />
      </a-col>
      <a-col flex="calc(100% - 70px)">
        {{ option }}
      </a-col>
    </a-row>
  </template>

  <a-divider class="my-sm-1" />

  <a-row type="flex">
    <a-col flex="35px">
      <a-tooltip title="Select all N/A option">
        <a-button
          type="link"
          size="small"
          class="p-sm-0"
          @click="postSelectAllOption('pgt_na_post_procedure_checklist')"
        >
          N/A
        </a-button>
      </a-tooltip>
    </a-col>
    <a-col flex="35px">
      <a-tooltip title="Select all option">
        <a-button
          type="link"
          size="small"
          class="p-sm-0"
          @click="postSelectAllOption('pgt_post_procedure_checklist')"
        >
          <CheckOutlined />
        </a-button>
      </a-tooltip>
    </a-col>
    <a-col flex="auto">
      <strong>Post-Procedure Checklist</strong>
    </a-col>
  </a-row>

  <template v-for="option in postChecklist" :key="option">
    <a-row type="flex">
      <a-col flex="35px">
        <a-checkbox
          v-model:checked="model.pgt_na_post_procedure_checklist[option]"
          @click="
            (e) =>
              postOptionChecked(e, option, 'pgt_na_post_procedure_checklist')
          "
        />
      </a-col>
      <a-col flex="35px">
        <a-checkbox
          v-model:checked="model.pgt_post_procedure_checklist[option]"
          @click="
            (e) => postOptionChecked(e, option, 'pgt_post_procedure_checklist')
          "
        />
      </a-col>
      <a-col flex="calc(100% - 70px)">
        {{ option }}
      </a-col>
    </a-row>
  </template>

  <a-divider class="my-sm-1" />

  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12">
      <a-form-item
        label="Volume Processed (mL)"
        name="pgt_volume_processed"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <a-input-number
          v-model:value="model.pgt_volume_processed"
          placeholder="Volume Processed"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Platelet Rich Plasma (mL)"
        name="pgt_platelet_rich_plasma"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <a-input-number
          v-model:value="model.pgt_platelet_rich_plasma"
          placeholder="Platelet Rich Plasma"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Platelet Poor Plasma (mL)"
        name="pgt_platelet_poor_plasma"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <a-input-number
          v-model:value="model.pgt_platelet_poor_plasma"
          placeholder="Platelet Poor Plasma"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Anticoag Volume (mL)"
        name="pgt_anticoag_volume"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <a-input-number
          v-model:value="model.pgt_anticoag_volume"
          placeholder="Anticoag Volume"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Volume Plasma Loss (mL)"
        name="pgt_volume_plasma_loss"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <a-input-number
          v-model:value="model.pgt_volume_plasma_loss"
          placeholder="Volume Plasma Loss"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Reco Thrombin (units)"
        name="pgt_reco_thrombin"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <a-input-number
          v-model:value="model.pgt_reco_thrombin"
          placeholder="Reco Thrombin"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        name="pgt_cacl2_10"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
          },
        ]"
      >
        <template #label> CaCI <sup>2</sup> &nbsp; 10% (mL) </template>
        <a-input-number
          v-model:value="model.pgt_cacl2_10"
          placeholder="CaCI"
          type="number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Centrifuge Serial Number"
        name="pgt_centrifuge_serial_number"
        :rules="[
          {
            required: true,
            message: 'This field is required!',
          },
        ]"
      >
        <a-input
          v-model:value="model.pgt_centrifuge_serial_number"
          placeholder="Centrifuge Serial Number"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Blood Withdrawal Date"
        name="pgt_blood_withdrawal_date"
      >
        <a-date-picker
          v-model:value="model.pgt_blood_withdrawal_date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Blood Withdrawal Time"
        name="pgt_blood_withdrawal_time"
        :rules="[
          {
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.pgt_blood_withdrawal_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Blood Withdrawal Site"
        name="pgt_blood_withdrawal_site"
      >
        <a-input
          v-model:value="model.pgt_blood_withdrawal_site"
          placeholder="Enter here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Blood Withdrawal Performed By"
        name="pgt_blood_withdrawal_performed_by"
      >
        <a-input
          v-model:value="model.pgt_blood_withdrawal_performed_by"
          placeholder="Enter here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="8">
      <a-form-item label="PRP Product Given To" name="pgt_prp_product_given_to">
        <a-input
          v-model:value="model.pgt_prp_product_given_to"
          placeholder="Enter here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="8">
      <a-form-item
        label="PRP Product Given Date"
        name="pgt_prp_product_given_date"
      >
        <a-date-picker
          v-model:value="model.pgt_prp_product_given_date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="8">
      <a-form-item
        label="PRP Product Given Time"
        name="pgt_prp_product_given_time"
      >
        <a-input
          v-model:value="model.pgt_prp_product_given_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="First PRP Application Date"
        name="pgt_first_prp_application_date"
      >
        <a-date-picker
          v-model:value="model.pgt_first_prp_application_date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="First PRP Application Time"
        name="pgt_first_prp_application_time"
      >
        <a-input
          v-model:value="model.pgt_first_prp_application_time"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";

export default {
  name: "case-report-step-13",
  inheritAttrs: false,
  props: ["step", "model", "hospitals"],
  emits: ["validate"],
  components: { CheckOutlined },

  data() {
    return {
      uuid: this.$route?.query?.uuid ?? null,
    };
  },

  computed: {
    preChecklist() {
      return this.$constants.pgtPreProcedureChecklist;
    },

    postChecklist() {
      return this.$constants.pgtPostProcedureChecklist;
    },
  },

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },

    preOptionChecked(e, option, key) {
      if (e.target.checked) {
        if (key == "pgt_pre_procedure_checklist") {
          this.model.pgt_na_pre_procedure_checklist[option] = false;
        } else {
          this.model.pgt_pre_procedure_checklist[option] = false;
        }
      }
    },

    preSelectAllOption(key) {
      let allChecked = this.preChecklist.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      this.preChecklist.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key =
        key == "pgt_pre_procedure_checklist"
          ? "pgt_na_pre_procedure_checklist"
          : "pgt_pre_procedure_checklist";
      this.preChecklist.forEach((option) => (this.model[key][option] = false));
    },

    postOptionChecked(e, option, key) {
      if (e.target.checked) {
        if (key == "pgt_post_procedure_checklist") {
          this.model.pgt_na_post_procedure_checklist[option] = false;
        } else {
          this.model.pgt_post_procedure_checklist[option] = false;
        }
      }
    },

    postSelectAllOption(key) {
      let allChecked = this.postChecklist.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      this.postChecklist.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key =
        key == "pgt_post_procedure_checklist"
          ? "pgt_na_post_procedure_checklist"
          : "pgt_post_procedure_checklist";
      this.postChecklist.forEach((option) => (this.model[key][option] = false));
    },
  },
};
</script>
