<template>
  <a-layout-content>
    <page-header>
      <template #title> Feedback </template>
      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        tableLayout="fixed"
      >
        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="View">
              <a-button type="link" size="small" @click="showFeedback(record)">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this feedback?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteFeedback(record)"
            >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { } from "@ant-design/icons-vue";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminFeedbackDatatableUrl,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee.full_name",
          key: "employee.full_name",
          width: 200,
        },
        {
          title: "Category",
          dataIndex: "category_name",
          key: "category_name",
          width: 200,
        },
        {
          title: "Severity",
          dataIndex: "severity_name",
          key: "severity_name",
          width: 100,
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
        },
        {
          title: "Submitted At",
          dataIndex: "created_at",
          key: "created_at",
          width: 200,
          customRender: ({ text }) => this.$customDate.mdyhis(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 100,
          slots: { customRender: "action" },
        },
      ],
    };
  },

  methods: {
    showFeedback(record) {
      this.$store.dispatch("drawer/open", {
        title: "Feedback",
        path: "admin.feedback.show",
        record,
        callback: this.refresh,
      });
    },

    deleteFeedback(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminFeedbackDeleteUrl, { id: record.id })
        .then((res) => this.$message.success(res.message))
        .catch((err) => this.$message.error(err))
        .finally(() => this.refresh());
    },
  },
};
</script>
