<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Case Report Live Charting"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <a-space size="small">
          <template v-if="showCompleteButtonForAdmin">
            <a-popconfirm
              title="Are you sure you want to complete live charting for this procedure?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="markAsCompleted"
            >
              <a-button type="primary" danger :loading="markAsCompleting">
                <a-space>
                  <i class="ti ti-check ti-lg"></i>
                  Mark As Complete For Admin
                </a-space>
              </a-button>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              v-if="caseReport?.live_chart?.completed_at && !loading"
              title="Are you sure you want to uncomplete live charting for this procedure?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="markAsUncompleteModal = true"
            >
              <a-button type="primary" danger :loading="markAsUncompleting">
                <a-space>
                  <i class="ti ti-alert-circle ti-lg"></i>
                  Mark As UnComplete
                </a-space>
              </a-button>
            </a-popconfirm>
          </template>

          <template v-if="showCompleteButton">
            <a-popconfirm
              title="Are you sure you want to complete live charting for this procedure?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="markAsCompleted"
            >
              <a-button type="primary" danger :loading="markAsCompleting">
                <a-space>
                  <i class="ti ti-check ti-lg"></i>
                  Mark As Complete
                </a-space>
              </a-button>
            </a-popconfirm>
          </template>

          <template v-else>
            <router-link
              v-if="isCompleted"
              :to="{
                name:
                  $route.name == 'admin.case-report-live-charting'
                    ? 'admin.create-case-report'
                    : 'create-case-report',
                query: { uuid: caseReport.uuid, step: 3 },
              }"
            >
              <a-button type="primary">
                <a-space>
                  <i class="ti ti-device-heart-monitor ti-lg"></i>
                  Case Report
                </a-space>
              </a-button>
            </router-link>
          </template>

          <template v-if="activeTabKey == 'atx'">
            <a-button
              v-if="!atx?.checklist"
              type="primary"
              @click="
                open({
                  title: 'ATS Pre-Procedure Checklist',
                  width: '90%',
                  path: 'newCaseReport.drawerForm.atxChecklist',
                  callback: updateCaseReport,
                  record: caseReport,
                })
              "
            >
              <a-space>
                <i class="ti ti-checkbox ti-lg"></i>
                Checkpoints for ATS
              </a-space>
            </a-button>
          </template>

          <template v-if="activeTabKey == 'cpb'">
            <a-button
              v-if="!cpb?.checklist"
              type="primary"
              @click="
                open({
                  title: 'Pre-bypass Checklist',
                  width: '90%',
                  path: 'newCaseReport.drawerForm.cpbChecklist',
                  callback: updateCaseReport,
                  record: caseReport,
                })
              "
            >
              <a-space>
                <i class="ti ti-checkbox ti-lg"></i>
                Checkpoints for CPB
              </a-space>
            </a-button>
          </template>

          <template v-if="activeTabKey == 'hipec'">
            <a-button
              v-if="!hipec?.pre_checklist"
              type="primary"
              @click="
                open({
                  title: 'Pre-Procedure Checklist',
                  width: '90%',
                  path: 'newCaseReport.drawerForm.hipecPreProcedureChecklist',
                  callback: updateCaseReport,
                  record: caseReport,
                })
              "
            >
              <a-space>
                <i class="ti ti-checkbox ti-lg"></i>
                Checkpoints for {{ hipecText }}
              </a-space>
            </a-button>
          </template>

          <template v-if="activeTabKey == 'ecmo'">
            <a-button
              v-if="!ecmo?.checklist"
              type="primary"
              @click="
                open({
                  title: 'ECMO Checklist',
                  width: '90%',
                  path: 'newCaseReport.drawerForm.ecmoChecklist',
                  callback: updateCaseReport,
                  record: caseReport,
                })
              "
            >
              <a-space>
                <i class="ti ti-checkbox ti-lg"></i>
                Checkpoints for ECMO
              </a-space>
            </a-button>
          </template>

          <template v-if="activeTabKey == 'nrp'">
            <a-button
              v-if="!nrp?.pre_procedure_checklist"
              type="primary"
              @click="
                open({
                  title: 'NRP Pre-Procedure Checklist',
                  path: 'newCaseReport.drawerForm.nrpPreProcedureChecklist',
                  callback: updateCaseReport,
                  record: caseReport,
                })
              "
            >
              <a-space>
                <i class="ti ti-checkbox ti-lg"></i>
                NRP Pre-Procedure Checklist
              </a-space>
            </a-button>
          </template>

          <a-button
            type="primary"
            :loading="downloadLoading"
            @click="downloadPdf"
          >
            <a-space><i class="ti ti-printer ti-lg"></i> Charting PDF </a-space>
          </a-button>
        </a-space>
      </template>

      <template v-if="isCompleted" #tags>
        <a-tag color="green">
          <strong>Completed At:</strong>
          {{ caseReport.live_chart.completed_at }}
        </a-tag>
      </template>

      <template #footer>
        <a-tabs
          v-model:activeKey="activeTabKey"
          style="margin-bottom: 0"
          @change="tabChangeEventHandler"
        >
          <a-tab-pane v-for="tab in tabList" :key="tab.key" :tab="tab.tab" />
        </a-tabs>
      </template>
    </a-page-header>

    <template v-if="loading">
      <a-card size="small" :loading="loading" />
    </template>

    <template v-else>
      <atx-tab
        v-if="activeTabKey == 'atx'"
        ref="atxTabRef"
        :caseReport="caseReport"
        :atx="atx"
        :uuid="uuid"
        :isCompleted="isCompleted"
        :isAdminLiveChartingPage="isAdminLiveChartingPage"
        @updateCaseReport="updateCaseReport"
        @closeCaseClickEvent="closeCaseClickEvent"
      />

      <cpb-tab
        v-if="activeTabKey == 'cpb'"
        ref="cpbTabRef"
        :caseReport="caseReport"
        :cpb="cpb"
        :uuid="uuid"
        :isCompleted="isCompleted"
        :isAdminLiveChartingPage="isAdminLiveChartingPage"
        @updateCaseReport="updateCaseReport"
        @closeCaseClickEvent="closeCaseClickEvent"
      />

      <hipec-tab
        v-if="activeTabKey == 'hipec'"
        :caseReport="caseReport"
        ref="hipecTabRef"
        :hipec="hipec"
        :hipecText="hipecText"
        :uuid="uuid"
        :isCompleted="isCompleted"
        :isAdminLiveChartingPage="isAdminLiveChartingPage"
        @updateCaseReport="updateCaseReport"
        @closeCaseClickEvent="closeCaseClickEvent"
      />

      <ecmo-tab
        v-if="activeTabKey == 'ecmo'"
        ref="ecmoTabRef"
        :caseReport="caseReport"
        :ecmo="ecmo"
        :uuid="uuid"
        :isCompleted="isCompleted"
        :isAdminLiveChartingPage="isAdminLiveChartingPage"
        @updateCaseReport="updateCaseReport"
        @closeCaseClickEvent="closeCaseClickEvent"
      />

      <nrp-tab
        v-if="activeTabKey == 'nrp'"
        ref="nrpTabRef"
        :caseReport="caseReport"
        :nrp="nrp"
        :uuid="uuid"
        :isCompleted="isCompleted"
        :isAdminLiveChartingPage="isAdminLiveChartingPage"
        @updateCaseReport="updateCaseReport"
        @closeCaseClickEvent="closeCaseClickEvent"
      />
    </template>

    <a-modal
      v-model:visible="markAsUncompleteModal"
      title="Mark as Uncomplete this Live Charting"
      @ok="markAsUncompleted"
      :maskClosable="false"
      :closable="!markAsUncompleting"
      :ok-button-props="{
        disabled: remarks.trim() == '',
        loading: markAsUncompleting,
      }"
      :cancel-button-props="{
        loading: markAsUncompleting,
      }"
    >
      <a-textarea
        v-model:value="remarks"
        placeholder="Enter your remarks here..."
        :auto-size="{ minRows: 2, maxRows: 5 }"
        :disabled="markAsUncompleting"
      />
    </a-modal>
  </a-layout-content>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { createVNode } from "vue";
import { mapActions } from "vuex";
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";
import atxTab from "./tabs/atx.vue";
import cpbTab from "./tabs/cpb.vue";
import ecmoTab from "./tabs/ecmo.vue";
import hipecTab from "./tabs/hipec.vue";
import nrpTab from "./tabs/nrp.vue";

export default {
  components: {
    backButton,
    atxTab,
    cpbTab,
    hipecTab,
    ecmoTab,
    nrpTab,
  },

  data() {
    return {
      caseReport: {},
      loading: false,
      downloadLoading: false,
      markAsCompleting: false,
      markAsUncompleting: false,
      tableLoading: false,
      uuid: this.$route?.params?.uuid,
      activeTabKey: "",
      markAsUncompleteModal: false,
      remarks: "",
    };
  },

  mounted() {
    this.fetchCaseReport();
  },

  computed: {
    timezone() {
      return this.caseReport?.hospital?.timezone ?? this.$constants.timezone;
    },

    hipecText() {
      let res = [];
      if (this.caseReport?.data?.services?.includes(10)) {
        res.push("HIPEC");
      }
      if (this.caseReport?.data?.services?.includes(11)) {
        res.push("ILI");
      }
      return res.join(" / ");
    },

    tabList() {
      let res = [];
      if (this.isShowAtxTab) {
        res.push({
          key: "atx",
          tab: "ATS",
        });
      }
      if (this.isShowCpbTab) {
        res.push({
          key: "cpb",
          tab: "CPB",
        });
      }
      if (this.isShowHipecTab) {
        res.push({
          key: "hipec",
          tab: this.hipecText,
        });
      }
      if (this.isShowEcmoTab) {
        res.push({
          key: "ecmo",
          tab: "ECMO",
        });
      }
      if (this.isShowNRPTab) {
        res.push({
          key: "nrp",
          tab: "NRP",
        });
      }
      return res;
    },

    isShowAtxTab() {
      return this.$constants.caseServicesIdForATS.reduce(
        (bool, curr) =>
          bool || (this.caseReport?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowCpbTab() {
      return this.$constants.caseServicesIdForCPB.reduce(
        (bool, curr) =>
          bool || (this.caseReport?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowHipecTab() {
      return this.$constants.caseServicesIdForHIPEC.reduce(
        (bool, curr) =>
          bool || (this.caseReport?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowEcmoTab() {
      return this.$constants.caseServicesIdForECMO.reduce(
        (bool, curr) =>
          bool || (this.caseReport?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowNRPTab() {
      return this.$constants.caseServicesIdForNRP.reduce(
        (bool, curr) =>
          bool || (this.caseReport?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    atx() {
      return this.caseReport?.live_chart?.atx ?? null;
    },

    cpb() {
      return this.caseReport?.live_chart?.cpb ?? null;
    },

    hipec() {
      return this.caseReport?.live_chart?.hipec ?? null;
    },

    ecmo() {
      return this.caseReport?.live_chart?.ecmo ?? null;
    },

    nrp() {
      return this.caseReport?.live_chart?.nrp ?? null;
    },

    showCompleteButtonForAdmin() {
      return (
        !this.loading &&
        this.caseReport?.live_chart?.completed_at != null &&
        this.isAdminLiveChartingPage
      );
    },

    showCompleteButton() {
      if (
        this.loading ||
        this.caseReport?.status != 1 ||
        this.caseReport?.live_chart?.completed_at
      ) {
        return false;
      }
      let bool = true;
      if (this.isShowAtxTab) {
        bool = bool && (this.atx?.checklist ?? false);
      }
      if (this.isShowCpbTab && bool) {
        bool = bool && (this.cpb?.checklist ?? false);
      }
      if (this.isShowHipecTab && bool) {
        bool =
          bool &&
          (this.hipec?.pre_checklist ?? false) &&
          (this.hipec?.post_checklist ?? false);
      }
      if (this.isShowEcmoTab && bool) {
        bool =
          bool &&
          (this.ecmo?.checklist ?? false) &&
          (this.ecmo?.timers?.ecmo[0]?.stop_time ?? false);
      }
      if (this.isShowNRPTab && bool) {
        bool =
          bool &&
          (this.nrp?.pre_procedure_checklist ?? false) &&
          (this.nrp?.initiation_checklist ?? false);
      }
      return bool;
    },

    isCompleted() {
      return this.caseReport?.live_chart?.completed_at ?? false;
    },

    isAdminLiveChartingPage() {
      return ['admin.case-report-live-charting', 'manager.case-report-live-charting'].includes(this.$route.name);
    },
  },

  methods: {
    ...mapActions("modal", ["open"]),

    fetchCaseReport() {
      this.loading = true;
      commonService
        .get(this.$constants.caseReportShowUrl, { uuid: this.uuid })
        .then((res) => {
          this.caseReport = res.data;

          if (
            this.caseReport.status == 3 &&
            this.caseReport.quickbooks_invoice_id != null
          ) {
            return this.$router.go(-1);
          }

          if (this.isShowAtxTab) {
            this.activeTabKey = "atx";
          } else if (this.isShowCpbTab) {
            this.activeTabKey = "cpb";
          } else if (this.isShowHipecTab) {
            this.activeTabKey = "hipec";
          } else if (this.isShowEcmoTab) {
            this.activeTabKey = "ecmo";
          } else if (this.isShowNRPTab) {
            this.activeTabKey = "nrp";
          }

          this.scrollTables();
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.loading = false));
    },

    updateCaseReport(e) {
      this.caseReport = e;
    },

    tabChangeEventHandler(key) {
      this.activeTabKey = key;
      this.scrollTables();
    },

    scrollTables() {
      setTimeout(() => {
        [
          { ref1: "cpbTabRef", ref2: "cpbDatatableRef" },
          { ref1: "ecmoTabRef", ref2: "ecmoDatatableRef" },
          { ref1: "hipecTabRef", ref2: "hipecDatatableRef" },
          { ref1: "atxTabRef", ref2: "atxDatatableRef" },
          { ref1: "nrpTabRef", ref2: "nrpDatatableRef" },
        ].forEach((item) => {
          if (
            this.$refs[item.ref1]?.$refs[item.ref2]?.querySelector(
              ".event-table"
            )
          ) {
            let table =
              this.$refs[item.ref1].$refs[item.ref2].querySelector(
                ".event-table"
              );
            table.scrollTo({
              left: 0,
              top: table.clientHeight,
              behavior: "smooth",
            });
          }
        });
      }, 500);
    },

    updateTableLoading(bool) {
      this.tableLoading = bool;
    },

    markAsCompleted() {
      let timers;
      let bool = true;

      // START FOR CHECKING ALL CBP TIMERS COMPLETED OR NOT
      if (this.isShowCpbTab) {
        timers = [];
        let cpbActiveRecords = this.cpb?.data
          ? this.cpb?.data
              .filter((item) => !item.deleted_at)
              .sort((a, b) =>
                moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
                  moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
                )
                  ? 1
                  : -1
              )
          : [];

        ["Bypass", "X-Clamp", "Cir. Arrest"].forEach(async (element) => {
          timers[element] = cpbActiveRecords
            .filter((e) => e.event == element)
            .reduce((pre, curr) => {
              let last = {};
              if (pre.length > 0) {
                last = pre[pre.length - 1];
                if (last.start_time && last.stop_time) {
                  last = {};
                }
              }

              let obj = {};
              if (["Start", "On"].includes(curr.note)) {
                obj.start_time = curr.datetime;
              } else if (["Stop", "Off"].includes(curr.note)) {
                obj.stop_time = curr.datetime;
              }

              if (last.start_time || last.stop_time) {
                last = { ...last, ...obj };
                pre[pre.length - 1] = last;
              } else {
                pre.push(obj);
              }

              return pre;
            }, []);

          bool = Object.values(timers).reduce((pre, curr) => {
            return (
              pre &&
              curr.filter((item) => {
                let arr = Object.values(item);
                return (
                  arr.length == 1 ||
                  moment(item.stop_time, "MM-DD-YYYY HH:mm:ss").isBefore(
                    moment(item.start_time, "MM-DD-YYYY HH:mm:ss")
                  )
                );
              }).length == 0
            );
          }, bool);
        });

        if (!bool) {
          Modal.warning({
            title: () =>
              "To mark this live charting as complete, you need to stop Bypass, X-Clamp or Cir. Arrest timer.",
          });
          return;
        }
      }
      // END FOR CHECKING ALL CBP TIMERS COMPLETED OR NOT

      // START FOR CHECKING ALL NRP TIMERS COMPLETED OR NOT
      if (this.isShowNRPTab) {
        timers = [];
        let nrpActiveRecords = this.nrp?.data
          ? this.nrp.data
              .filter((item) => !item.deleted_at)
              .sort((a, b) =>
                moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
                  moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
                )
                  ? 1
                  : -1
              )
          : [];

        ["NRP"].forEach(async (element) => {
          timers[element] = nrpActiveRecords
            .filter((e) => e.event == element)
            .reduce((pre, curr) => {
              let last = {};
              if (pre.length > 0) {
                last = pre[pre.length - 1];
                if (last.start_time && last.stop_time) {
                  last = {};
                }
              }

              let obj = {};
              if (["Start", "On"].includes(curr.note)) {
                obj.start_time = curr.datetime;
              } else if (["Stop", "Off"].includes(curr.note)) {
                obj.stop_time = curr.datetime;
              }

              if (last.start_time || last.stop_time) {
                last = { ...last, ...obj };
                pre[pre.length - 1] = last;
              } else {
                pre.push(obj);
              }

              return pre;
            }, []);

          bool = Object.values(timers).reduce((pre, curr) => {
            return (
              pre &&
              curr.filter((item) => {
                let arr = Object.values(item);
                return (
                  arr.length == 1 ||
                  moment(item.stop_time, "MM-DD-YYYY HH:mm:ss").isBefore(
                    moment(item.start_time, "MM-DD-YYYY HH:mm:ss")
                  )
                );
              }).length == 0
            );
          }, bool);
        });

        if (!bool) {
          Modal.warning({
            title: () =>
              "To mark this live charting as complete, you need to stop NRP timer.",
          });
        }
      }
      // END FOR CHECKING ALL NRP TIMERS COMPLETED OR NOT

      if (bool) {
        this.tableLoading = true;
        this.markAsCompleting = true;
        commonService
          .store(this.$constants.caseReportCompleteLiveChartingUrl, {
            uuid: this.uuid,
            completed_at: moment()
              .tz(this.timezone)
              .format("MM-DD-YYYY HH:mm:ss"),
          })
          .then((res) => {
            this.$message.success(res.message);
            this.caseReport = res.data;
          })
          .catch((err) => this.$message.error(err))
          .finally(() => {
            this.tableLoading = false;
            this.markAsCompleting = false;
          });
      }
    },

    markAsUncompleted() {
      if (!this.showCompleteButton) {
        this.tableLoading = true;
        this.markAsUncompleting = true;
        commonService
          .store(this.$constants.caseReportMarkAsUncompleteLiveChartingUrl, {
            uuid: this.uuid,
            remarks: this.remarks,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.caseReport = res.data;
            this.markAsUncompleteModal = false;
            this.remarks = "";
          })
          .catch((err) => this.$message.error(err))
          .finally(() => {
            this.tableLoading = false;
            this.markAsUncompleting = false;
          });
      }
    },

    closeCaseClickEvent() {
      let router = this.$router;
      Modal.confirm({
        title: () => "Save & Close?",
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          router.push({ name: "case-report" });
        },
        okText: "Confirm",
        okType: "danger",
        keyboard: false,
        onCancel() {},
      });
    },

    downloadPdf() {
      this.downloadLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportPdfUrl,
          { id: this.caseReport.id, for_only_live_charting: true },
          "case-reporting.pdf"
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.pre-procedure-checklist .ant-typography,
.pre-bypass-checklist .ant-typography {
  margin-bottom: 0 !important;
}
</style>
