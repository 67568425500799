<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="24">
      <a-form-item
        label="Was the employee hired greater than 12 months ago and has set goals from the previous year?"
        :name="['section5', 'is_old_employee']"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <a-radio-group
          v-model:value="model.section5.is_old_employee"
          @change="$emit('update:model', model)"
        >
          <a-radio
            v-for="option in $constants.yesNoNotApplicable.filter(
              (item) => item.value != 'No'
            )"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col v-if="model.section5.is_old_employee == 'Yes'" :span="24">
      <a-empty
        v-if="model.section5.last_year_goals.length == 0"
        description="Last year goal will be shown here..."
      />

      <a-row :gutter="[20, 0]">
        <a-col
          v-for="(goal, index) in model.section5.last_year_goals"
          :key="index"
          :span="24"
        >
          <a-form-item
            :name="['section5', 'last_year_goals', index, 'rating']"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'number',
              min: 1,
            }"
          >
            <template #label>
              <a-space direction="vertical" :size="0">
                {{ goal.goal }} (Weight {{ goal.weightage }}%)
              </a-space>
            </template>

            <a-rate
              v-model:value="goal.rating"
              :tooltips="desc"
              @change="$emit('update:model', model)"
            />
            <span class="ant-rate-text">{{ desc[goal.rating - 1] }}</span>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            :name="['section5', 'avg_score']"
            label="Goal Achievement Score"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'number',
            }"
          >
            <a-input-number type="number"
              v-model:value="model.section5.avg_score"
              disabled
              @change="$emit('update:model', model)"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            :name="['section5', 'overall_weightage']"
            label="Overall Weightage Score"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'number',
            }"
          >
            <a-input-number type="number"
              v-model:value="model.section5.overall_weightage"
              disabled
              @change="$emit('update:model', model)"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
    desc: {
      require: true,
    },
  },

  data() {
    return {
      //
    };
  },

  methods: {
    validate(...arg) {
      this.$emit("validate", ...arg);
    },
  },
};
</script>
