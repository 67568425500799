<template>
  <div style="padding: 8px">
    <a-input
      @change="
        (e) =>
          filterDropdown.setSelectedKeys(e.target.value ? [e.target.value] : [])
      "
      @pressEnter="
        handleSearch({
          selectedKeys: filterDropdown.selectedKeys,
          confirm: filterDropdown.confirm,
          dataIndex: filterDropdown.column.dataIndex,
        })
      "
      ref="searchInput"
      :placeholder="`Search ${filterDropdown.column.title}`"
      :value="filterDropdown.selectedKeys[0]"
      style="width: 188px; margin-bottom: 8px; display: block"
    />

    <a-button
      @click="
        handleSearch({
          selectedKeys: filterDropdown.selectedKeys,
          confirm: filterDropdown.confirm,
          dataIndex: filterDropdown.column.dataIndex,
        })
      "
      type="primary"
      size="small"
      style="width: 90px; margin-right: 8px"
    >
      Search
    </a-button>

    <a-button
      @click="
        handleReset({
          clearFilters: filterDropdown.clearFilters,
        })
      "
      size="small"
      style="width: 90px"
    >
      Reset
    </a-button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    filterDropdown: {
      type: Object,
      required: true,
    },
  },

  emits: ["handleSearch", "handleReset"],

  methods: {
    handleSearch(payload) {
      this.$emit("handleSearch", payload);
    },
    handleReset(payload) {
      this.$emit("handleReset", payload);
    },
  },
};
</script>