<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>Show Appointment</template>

        <template #buttons>
          <back-button />
        </template>
      </page-header>

      <a-card :loading="cardLoading">
        <a-descriptions size="small" title="" :column="1">
          <a-descriptions-item label="Title">
            {{ appointment.title }}
          </a-descriptions-item>

          <a-descriptions-item label="Service">
            {{ appointment.case_service?.name ?? "-" }}
          </a-descriptions-item>

          <a-descriptions-item label="Date">
            {{ $customDate.mdy(appointment.date) }}
          </a-descriptions-item>

          <a-descriptions-item label="Is Full Day Event?">
            <a-tag v-if="appointment.is_full_day_event == 1" color="green">
              Yes
            </a-tag>
            <a-tag v-if="appointment.is_full_day_event == 0" color="red">
              No
            </a-tag>
          </a-descriptions-item>

          <a-descriptions-item label="Start Time">
            {{ $customDate.hia(appointment.start_time) }}
          </a-descriptions-item>

          <a-descriptions-item label="End Time">
            {{ $customDate.hia(appointment.end_time) }}
          </a-descriptions-item>

          <a-descriptions-item label="Notes">
            <br />
            <span v-html="notes"></span>
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { commonService } from "../../../services";
import backButton from "../../../components/backButton.vue";
moment.tz.setDefault("UTC");

export default {
  components: { backButton },
  data() {
    return {
      loading: false,
      cardLoading: false,
      appointment: {},
      notes: null,
    };
  },

  mounted() {
    this.fetchAppointment();
  },

  methods: {
    fetchAppointment() {
      this.cardLoading = true;
      commonService
        .store(this.$constants.calendarEventViewUrl, {
          id: this.$route.params.id,
          hospital_id: this.$store.getters["auth/hospital"].id,
        })
        .then((res) => {
          if (res.data) {
            this.appointment = res.data;
            this.cardLoading = false;
            if (this.appointment.notes == null) {
              this.notes = this.$constants.calendarEventNotesString;
            } else {
              this.notes = this.appointment.notes;
            }
            this.notes = this.notes.replaceAll("\n", "<br />");
            return;
          }

          this.$message.error(err);
          this.$router.push({ name: "hospital-user-appointments" });
        })
        .catch((err) => {
          this.$message.error(err);
          this.$router.push({ name: "hospital-user-appointments" });
        });
    },
  },
};
</script>
