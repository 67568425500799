<template>
  <a-form
    name="nrp_blood_gas_form"
    ref="nrp_blood_gas_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-divider orientation="left" style="margin-top: 0 !important">
      Blood Gas
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="type"
          label="Type"
          name="type"
          :rules="{
            required: true,
            message: 'This field must be required.',
          }"
        >
          <a-radio-group v-model:value="model.type">
            <a-radio value="Arterial">Arterial</a-radio>
            <a-radio value="Venous">Venous</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ph"
          name="ph"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> <sub>p</sub>H </template>
          <a-input-number type="number" v-model:value="model.ph" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pco2"
          name="pco2"
          :rules="{
            type: 'integer',
            min: 10,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> PCO<sub>2</sub> </template>
          <a-input-number type="number" v-model:value="model.pco2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="po2"
          name="po2"
          :rules="{
            type: 'integer',
            min: 10,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> PO<sub>2</sub> </template>
          <a-input-number type="number" :min="0" v-model:value="model.po2" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hco3"
          name="hco3"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> HCO<sub>3</sub> </template>
          <a-input-number type="number" v-model:value="model.hco3" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="base"
          label="BASE"
          name="base"
          :rules="{
            pattern: new RegExp(/^-?\d+(\.\d{1,2})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.base" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="o2_sat"
          name="o2_sat"
          :rules="{
            type: 'integer',
            min: 0,
            max: 100,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> O<sub>2</sub>&nbsp; SAT (%) </template>
          <a-input-number type="number" v-model:value="model.o2_sat" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hb"
          label="Hb"
          name="hb"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.hb" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="hct"
          label="HCT (%)"
          name="hct"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.hct" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ica"
          label="iCa"
          name="ica"
          :rules="{
            pattern: new RegExp(/^\d{1,2}(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.ica"
            :step="0.01"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="kplus"
          name="kplus"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> K<sup>+</sup> </template>
          <a-input-number type="number" v-model:value="model.kplus" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="naplus"
          name="naplus"
          :rules="{
            type: 'integer',
            min: 0,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <template #label> Na<sup>+</sup> </template>
          <a-input-number type="number" v-model:value="model.naplus" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="glu"
          label="GLU"
          name="glu"
          :rules="{
            type: 'integer',
            min: 0,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.glu" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="lac"
          label="Lac"
          name="lac"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
            min: 0,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            :step="0.01"
            :max="99.99"
            v-model:value="model.lac"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="ast"
          label="AST"
          name="ast"
          :rules="{
            type: 'integer',
            min: 0,
            max: 2000,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" :max="2000" v-model:value="model.ast" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="alt"
          label="ALT"
          name="alt"
          :rules="{
            type: 'integer',
            min: 0,
            max: 2000,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" :max="2000" v-model:value="model.alt" />
        </a-form-item>
      </a-col>
    </a-row>

    <status-form
      :model="model"
      @update="(value, key) => (model[key] = value)"
    />

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";
import statusForm from "../../liveCharting/components/nrpStatus.vue";

export default {
  components: { statusForm },

  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Blood Gas",
        art_pressure: null,
        cvp: null,
        vacuum: null,
        blood_flow: null,
        rpm: null,
        line_pressure: null,
        hc_temp: null,
        core_temp: null,
        fio2: null,
        sweep: null,
        act: null,

        // blood_gas
        type: null,
        ph: null,
        pco2: null,
        po2: null,
        hco3: null,
        base: null,
        o2_sat: null,
        hb: null,
        hct: null,
        ica: null,
        kplus: null,
        naplus: null,
        glu: null,
        lac: null,
        ast: null,
        alt: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra.lastRecord) {
      this.model.blood_flow = this.extra.lastRecord.blood_flow;
      this.model.fio2 = this.extra.lastRecord.fio2;
      this.model.sweep = this.extra.lastRecord.sweep;
    }
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.art_pressure = this.extra.record.art_pressure;
      this.model.cvp = this.extra.record.cvp;
      this.model.vacuum = this.extra.record.vacuum;
      this.model.blood_flow = this.extra.record.blood_flow;
      this.model.rpm = this.extra.record.rpm;
      this.model.line_pressure = this.extra.record.line_pressure;
      this.model.hc_temp = this.extra.record.hc_temp;
      this.model.core_temp = this.extra.record.core_temp;
      this.model.fio2 = this.extra.record.fio2;
      this.model.sweep = this.extra.record.sweep;
      this.model.act = this.extra.record.act;

      // blood_gas
      this.model.type = this.extra.record.type;
      this.model.ph = this.extra.record.ph;
      this.model.pco2 = this.extra.record.pco2;
      this.model.po2 = this.extra.record.po2;
      this.model.hco3 = this.extra.record.hco3;
      this.model.base = this.extra.record.base;
      this.model.o2_sat = this.extra.record.o2_sat;
      this.model.hb = this.extra.record.hb;
      this.model.hct = this.extra.record.hct;
      this.model.ica = this.extra.record.ica;
      this.model.kplus = this.extra.record.kplus;
      this.model.naplus = this.extra.record.naplus;
      this.model.glu = this.extra.record.glu;
      this.model.lac = this.extra.record.lac;
      this.model.ast = this.extra.record.ast;
      this.model.alt = this.extra.record.alt;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreNRPRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
