<template>
  <div>
    <a-form
      name="hospital_personnel_form"
      ref="hospital_personnel_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here..."
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24">
          <a-form-item ref="type" label="Type" name="type">
            <a-select
              v-model:value="formState.type"
              placeholder="Select one..."
              showSearch
              :options="
                $constants.caseReportPersonnelTypes.map((item) => ({
                  label: item,
                  value: item,
                }))
              "
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        type: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id ?? 0,
        hospital_id: this.record.hospital_id,
        name: this.record.name ?? null,
        type: this.record.type ?? null,
      };
    },

    onSubmit() {
      this.loadingStart();

      commonService
        .store(this.$constants.hospitalPersonnelStoreUrl, this.formState)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
