<template>
  <a-card size="small" :bodyStyle="{ height: '300px', overflowY: 'scroll' }">
    <template #title>
      Due and Upcoming Performance Evaluations

      <a-tooltip placement="top">
        <template #title>
          Due within 7 days <br />
          <span v-html="dueWithin7Days" />
        </template>
        <a-button size="small" type="link">
          <i class="ti ti-info-circle ti-lg"> </i>
        </a-button>
      </a-tooltip>
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record) => record.id"
      :dataSource="data"
      :pagination="paginationSettings"
      size="small"
      :scroll="{ x: true, y: 0 }"
    >
      <template #days="{ record }">
        <a-tag v-if="diffInDays(record) == 0" color="processing"> Today </a-tag>
        <a-tag v-else-if="diffInDays(record) > 0" color="error">
          Overdue by {{ diffInDays(record) }}
        </a-tag>
        <a-tag v-else color="warning"> Due in {{ -diffInDays(record) }} </a-tag>
      </template>

      <template #action="{ record }">
        <router-link
          target="_blank"
          :to="{
            name: isAdmin
              ? 'admin-create-performance-evaluation'
              : 'manager.create-performance-evaluation',
            query: {
              employee_id: record.employee_id,
              evalution_date: record.next_performance_review_date,
            },
          }"
        >
          <a-tooltip title="Create Performance Evaluation">
            <a-button type="link" size="small">
              <i class="ti ti-plus ti-lg"></i>
            </a-button>
          </a-tooltip>
        </router-link>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { commonService } from "../../services";
import { constants } from "../../helper/constants";

export default {
  props: {
    paginationSettings: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      data: [],
      moment,
      constants,
      columns: [
        {
          title: "Name",
          dataIndex: "full_name",
          key: "full_name",
          customRender: ({ record }) => record?.employee?.full_name ?? "N/A",
        },
        {
          title: "Next Performance Review Date",
          dataIndex: "next_performance_review_date",
          key: "next_performance_review_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Days",
          dataIndex: "days",
          key: "days",
          slots: {
            customRender: "days",
          },
        },
        {
          title: "Job Type",
          dataIndex: "job_type",
          key: "job_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "job_type",
              constants.performanceEvaluationJobs
            ),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),

    dueWithin7Days() {
      let startDate = moment().tz(constants.timezone);
      let endDate = moment().tz(constants.timezone).add(7, "days");

      return this.data
        .filter((e) => {
          return moment(e.next_performance_review_date)
            .tz(constants.timezone)
            .isBetween(startDate, endDate, "day", "[]");
        })
        .map((e) => {
          return `${e.employee.full_name} (${-this.diffInDays(e)})`;
        })
        .join("<br />");
    },
  },

  mounted() {
    this.loading = true;
    commonService
      .store(constants.getDashboardDueAndUpcomingPeUrl)
      .then((res) => {
        this.data = res.data;
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    diffInDays(rec) {
      return moment()
        .tz(constants.timezone)
        .diff(
          moment(rec.next_performance_review_date).tz(constants.timezone),
          "day"
        );
    },
  },
};
</script>
