<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="key => (activeTabKey = key)"
    :loading="datatableLoading"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(rec, index) => rec.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
      :rowClassName="record => (record.is_flagged ? 'flagged-row' : '')"
    >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>

      <template #action="{ record }">
        <a-space :size="1">
          <router-link
            :to="{
              name: isAdmin ? 'admin-view-order' : 'view-order',
              params: { order_no: record.order_no },
            }"
            target="_blank"
          >
            <a-tooltip title="View">
              <a-button type="link" size="small">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
          </router-link>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { mapGetters } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],
  name: "hospital-orders",

  props: {
    hospital: {
      type: Object,
      required: true,
    },
  },

  components: { SearchOutlined },

  data() {
    return {
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.orderStatuses.map(item => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      extraFilters: {
        hospital_id: this.hospital.id,
      },
      modifyUrlQuery: false,
      inlineRecord: null,
      datatableUrl: this.$constants.orderDatatableUrl,
      columns: [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          customRender: ({ record }) => record.order_no_with_hash,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record.creator?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Products/Dispatched/Received",
          dataIndex: "product_info",
          key: "product_info",
          customRender: ({ record }) => {
            return `${record.products.length}/${
              record.products.filter(v => v.status >= 1).length
            }/${record.products.filter(v => v.status >= 2).length}`;
          },
        },
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.order_date),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      pagination: {
        current: 1,
        size: "normal",
        pageSize: 0,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        showSizeChanger: true,
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),
  },

  async mounted() {
    // this.loading = true;
    // await this.getOrders();
    // this.loading = false;
  },

  methods: {},
};
</script>
