<template>
  <a-layout-content>
    <page-header>
      <template #title> Asset Products </template>

      <template #buttons>
        <a-button type="primary" @click="handleCreateNewAssetProductEvent">
          <a-space> <i class="ti ti-plus ti-lg"></i> Add Product </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #is_required_maintenance="{ text }">
          <a-tag v-if="text == 1" color="green">Yes</a-tag>
          <a-tag v-else color="red">No</a-tag>
        </template>

        <template #device_info_pdf="{ record }">
          <template v-if="record.device_info_pdf">
            <a
              :href="record.device_info_pdf_file_path"
              target="_blank"
              rel="device_info_pdf"
            >
              {{ record.device_info_pdf }}
            </a>
          </template>
          <template v-else> N/A </template>
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <a-tooltip title="Edit">
            <a-button
              type="link"
              size="small"
              class="pl-sm-0"
              @click="handleEditAssetProductEvent(record)"
            >
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this asset product?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
            <a-tooltip title="Delete">
              <a-button size="small" type="link" danger>
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure you want to restore this asset product?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
            <a-tooltip title="Restore">
              <a-button size="small" type="link" class="pl-sm-0">
                <i class="ti ti-rotate ti-lg"></i>
              </a-button>
            </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.assetProductDatatableUrl,
      statusChangeUrl: this.$constants.assetProductChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "Category",
          dataIndex: "category_name",
          key: "category_name",
          customRender: ({ record }) => record.asset_category?.name ?? "N/A",
        },
        {
          title: "Device IFU",
          dataIndex: "device_info_pdf",
          key: "device_info_pdf",
          slots: {
            customRender: "device_info_pdf",
          },
        },
        {
          title: "Is required maintenance?",
          dataIndex: "is_required_maintenance",
          key: "is_required_maintenance",
          slots: {
            customRender: "is_required_maintenance",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
        sortField: this.$route.query?.sortField ?? "name",
        sortOrder: this.$route.query?.sortOrder ?? "ascend",
      },

      assetCategories: [],
    };
  },

  mounted() {
    this.getAssetCategories();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getAssetCategories() {
      commonService
        .get(this.$constants.getAssetCategoriesUrl)
        .then((res) => {
          if (res.success) {
            this.assetCategories = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    handleEditAssetProductEvent(record) {
      this.open({
        title: "Edit Asset Product",
        path: "asset.product.store",
        callback: this.refresh,
        record: record,
        extra: { assetCategories: this.assetCategories },
      });
    },

    handleCreateNewAssetProductEvent() {
      this.open({
        title: "Add Asset Product",
        path: "asset.product.store",
        callback: this.refresh,
        extra: { assetCategories: this.assetCategories },
      });
    },
  },
};
</script>
