<template>
  <a-form
    name="live_charting_cardioplegia_form"
    ref="live_charting_cardioplegia_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <status-form
      :model="model"
      @update="(value, key) => (model[key] = value)"
    />

    <a-divider orientation="left" style="margin-top: 0 !important">
      Cardioplegia
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item ref="route" label="Route" name="route">
          <a-radio-group v-model:value="model.route">
            <a-radio value="Antegrade">Antegrade</a-radio>
            <a-radio value="Retrograde">Retrograde</a-radio>
            <a-radio value="Ostial">Ostial</a-radio>
            <a-radio value="Graft">Graft</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item ref="radio" label="Radio" name="radio">
          <a-radio-group v-model:value="model.radio">
            <a-radio value="4:1">4:1</a-radio>
            <a-radio value="1:4">1:4</a-radio>
            <a-radio value="Blood">Blood</a-radio>
            <a-radio value="Other">Other</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col v-if="model.radio == 'Other'" :span="24">
        <a-form-item ref="other_radio" label="Other Radio" name="other_radio">
          <a-input v-model:value="model.other_radio" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="flow"
          label="Flow"
          name="flow"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input v-model:value="model.flow" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pressure"
          label="Pressure"
          name="pressure"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input v-model:value="model.pressure" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="volume_delivered"
          label="Volume Delivered"
          name="volume_delivered"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input v-model:value="model.volume_delivered" />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import statusForm from "../components/status.vue";

export default {
  components: { statusForm },

  data() {
    return {
      model: {
        time: moment().tz(this.$constants.timezone).format("HH:mm"),
        type: "Cardioplegia",
        art_pressure: null,
        cvp: null,
        pa: null,
        blood_flow: null,
        line_pressure: null,
        venous_temp: null,
        core_temp: null,
        fio2: null,
        sweep: null,
        anesthetic_gas: null,
        act: null,
        svo2: null,

        // Cardioplegia
        route: null,
        radio: null,
        other_radio: null,
        flow: null,
        pressure: null,
        volume_delivered: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      console.log("submitted::", this.model);
      return;

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
