<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="handleTabChange"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-space direction="vertical" style="width: 100%">
      <!-- <a-row class="mb-sm-1" justify="space-between">
        <div style="display: flex">
          <a-select
            v-model:value="bulkActionValue"
            placeholder="Select One"
            style="width: 120px; margin-right: 20px"
          >
            <a-select-option v-if="activeTabKey != 2" value="2">
              Approve
            </a-select-option>
            <a-select-option v-if="activeTabKey != 3" value="3">
              Reject
            </a-select-option>
            <a-select-option v-if="activeTabKey != 4" value="4">
              Cancel
            </a-select-option>
          </a-select>

          <a-button
            html-type="button"
            type="primary"
            :disabled="bulkActionBtnDisabled"
            @click="bulkAction"
          >
            Submit
          </a-button>
        </div>
      </a-row> -->

      <!-- :row-selection="rowSelection" -->
      <a-table
        :columns="columns.filter((e) => e.show ?? true)"
        :rowKey="(record) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        :rowClassName="(record) => (record.is_flagged ? 'flagged-row' : '')"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="Show">
              <a-button
                @click="handleShowPaidTimeOffEvent(record)"
                type="link"
                size="small"
                class="primary-color"
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </a-space>
  </a-card>
</template>

<script>
import xFilterIcon from "@/components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "@/components/table/filterInputSearchDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import datatableMixins from "../../../../mixins/datatableMixins";
import { commonService } from "../../../../services";

export default {
  components: {
    xFilterInputSearchDropdown,
    xFilterIcon,
  },

  mixins: [datatableMixins],

  data() {
    return {
      ptoData: {},
      datatableUrl: this.$constants.managerPaidTimeOffDatatableUrl,
      statusChangeUrl: this.$constants.managerPaidTimeOffBulkActionsUrl,
      selectedRowKeys: [],
      bulkActionValue: null,
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.paidTimeOffStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => {
            return record.employee?.full_name;
          },
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          sorter: true,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
          sorter: true,
          customRender: ({ text }) => (text ? text : "-"),
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    rowSelection() {
      return ["1", "2"].includes(this.activeTabKey)
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    bulkActionBtnDisabled() {
      return (
        this.bulkActionValue == null ||
        this.selectedRowKeys.length == 0 ||
        this.datatableLoading
      );
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleTabChange(key) {
      this.activeTabKey = key;
      this.selectedRowKeys = [];
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    resetFilters() {
      this.refresh();
    },

    bulkAction() {
      this.handleStatusChangeEvent({
        ids: this.selectedRowKeys,
        status: this.bulkActionValue,
      });

      this.bulkAction = undefined;
    },

    handleShowPaidTimeOffEvent(record) {
      this.open({
        title: "Paid Time Off Details",
        path: "paidTimeOff.show",
        callback: this.refresh,
        record: record,
      });
    },

    handleCancelPtoEvent(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.paidTimeOffCancelUrl, {
          id: record.id,
        })
        .then((res) => {
          this.refresh();
          this.$message.success(res["message"]);
        });
    },
  },

  watch: {
    activeTabKey() {
      //
    },
  },
};
</script>
