<template>
  <a-form
    name="live_charting_minus_volume_form"
    ref="live_charting_minus_volume_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="autologous_prime"
          label="Autologous Prime (mL)"
          name="autologous_prime"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.autologous_prime"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="ultrafiltration"
          label="Ultrafiltration (mL)"
          name="ultrafiltration"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.ultrafiltration" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="urine"
          label="Urine (mL)"
          name="urine"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.urine" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cpb_volume_to_cell_saver"
          label="CPB Volume to Cell Saver (mL)"
          name="cpb_volume_to_cell_saver"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.cpb_volume_to_cell_saver"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="residual_cpb_volume"
          label="Residual CPB Volume (mL)"
          name="residual_cpb_volume"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.residual_cpb_volume"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "- Volume",
        autologous_prime: null,
        ultrafiltration: null,
        urine: null,
        cpb_volume_to_cell_saver: null,
        residual_cpb_volume: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.autologous_prime = this.extra.record.autologous_prime
        ? -this.extra.record.autologous_prime
        : null;
      this.model.ultrafiltration = this.extra.record.ultrafiltration
        ? -this.extra.record.ultrafiltration
        : null;
      this.model.urine = this.extra.record.urine
        ? -this.extra.record.urine
        : null;
      this.model.cpb_volume_to_cell_saver = this.extra.record
        .cpb_volume_to_cell_saver
        ? -this.extra.record.cpb_volume_to_cell_saver
        : null;
      this.model.residual_cpb_volume = this.extra.record.residual_cpb_volume
        ? -this.extra.record.residual_cpb_volume
        : null;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.model.uuid = this.record.uuid;

      let rawData = JSON.parse(JSON.stringify(this.model));

      rawData.date = this.$customDate.mdy(this.model.date);
      rawData.datetime = `${rawData.date} ${rawData.time}`;
      rawData.uuid = this.record.uuid;
      rawData.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      rawData.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;

      let data = Object.entries(rawData)
        .map((e) => {
          if (
            [
              "autologous_prime",
              "ultrafiltration",
              "urine",
              "cpb_volume_to_cell_saver",
              "residual_cpb_volume",
            ].includes(e[0]) &&
            e[1]
          ) {
            e[1] = -e[1];
          }
          return e;
        })
        .reduce((pre, curr) => {
          pre[curr[0]] = curr[1];
          return pre;
        }, {});

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreCPBRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
