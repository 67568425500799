<template>
  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 10]">
      <a-col :span="24">
        <a-form-item
          ref="onboarding_document_ids"
          label="Onboarding Document"
          name="onboarding_document_ids"
        >
          <a-select
            v-model:value="formState.onboarding_document_ids"
            placeholder="select document"
            optionFilterProp="label"
            showSearch
            mode="multiple"
            :options="onboardingDocuments"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Make Request" />
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      onboardingDocuments: [],
      formState: {
        onboarding_document_ids: [],
      },
    };
  },

  mounted() {
    commonService
      .get(this.$constants.getOnboardingDocuments, {
        employee_id: this.extra.employee_id,
      })
      .then((res) => {
        this.onboardingDocuments = res.data.map((item) => ({
          ...item,
          text: item.name,
          label: item.name,
          value: item.id,
        }));
      });
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    rules() {
      return {
        onboarding_document_ids: {
          required: true,
          type: "array",
          message: "This field is required!",
        },
      };
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();

      let data = {
        onboarding_document_ids: this.formState.onboarding_document_ids,
        employee_id: this.extra.employee_id,
      };

      commonService
        .store(this.$constants.employeeOnboardingStore, data, true)
        .then(async (res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
