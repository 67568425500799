<template>
  <a-card size="small">
    <a-row type="flex" :gutter="[16, 16]">
      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Total Expenses (YTD)"
            :value="this.$comman.withCurrency(data?.total_expense_amount ?? 0)"
          >
            <template #suffix>
              <i class="ti ti-receipt" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Total Payments (YTD)"
            :value="this.$comman.withCurrency(data?.total_payment_amount ?? 0)"
          >
            <template #suffix>
              <i class="ti ti-file-dollar" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Due Amount (YTD)"
            :value="this.$comman.withCurrency(data?.total_due_amount ?? 0)"
          >
            <template #suffix>
              <i class="ti ti-database-dollar" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="24">
        <a-spin :spinning="loading">
          <a-card title="Expenses by Month ($)" size="small">
            <a-space size="large" direction="vertical" style="width: 100%">
              <div
                class="employee-finance-chart"
                id="expenseByMonthChart"
              ></div>
            </a-space>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="24">
        <a-spin :spinning="loading">
          <a-card title="Payments by Month ($)" size="small">
            <a-space size="large" direction="vertical" style="width: 100%">
              <div class="employee-finance-chart" id="paymentByMonthChart" />
            </a-space>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="24">
        <a-card title="AP Aging Summary (YTD)" size="small">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => index"
            :dataSource="apAgingSummary"
            :pagination="false"
            :loading="loading"
            size="small"
            :scroll="{ x: true, y: 0 }"
          />
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import {
ContactsFilled,
DollarCircleFilled,
PlusCircleFilled,
SearchOutlined,
SettingFilled,
ShoppingFilled,
} from "@ant-design/icons-vue";
import { Column } from "@antv/g2plot";
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "contractor-analytics",

  components: {
    SearchOutlined,
    ShoppingFilled,
    DollarCircleFilled,
    SettingFilled,
    ContactsFilled,
    PlusCircleFilled,
  },

  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
          customRender: () => "Amount",
        },
        {
          title: "Current",
          dataIndex: "current",
          key: "current",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "1-30 Days",
          dataIndex: "1_30_days",
          key: "1_30_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "31-60 Days",
          dataIndex: "31_60_days",
          key: "31_60_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "61-90 Days",
          dataIndex: "61_90_days",
          key: "61_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Over 90 Days",
          dataIndex: "over_90_days",
          key: "over_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
      ],
      data: null,
      apAgingSummary: [],
      loading: true,

      expenseByMonth: null,
      expenseByMonthData: [],
      expenseByMonthConfig: {
        xField: "month",
        yField: "amount",
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: {
          label: {
            formatter: (v) => this.$comman.withCurrency(v),
          },
        },
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Amount",
          },
        },
        tooltip: {
          formatter: (v) => ({
            name: "Amount",
            value: this.$comman.withCurrency(v.amount),
          }),
        },
        label: {
          position: "top",
          formatter: (v) => this.$comman.withCurrency(v.amount),
        },
        legend: false,
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        color: "#023958",
      },

      paymentByMonth: null,
      paymentByMonthData: [],
      paymentByMonthConfig: {
        xField: "month",
        yField: "amount",
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: {
          label: {
            formatter: (v) => this.$comman.withCurrency(v),
          },
        },
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Amount",
          },
        },
        tooltip: {
          formatter: (v) => ({
            name: "Amount",
            value: this.$comman.withCurrency(v.amount),
          }),
        },
        label: {
          position: "top",
          formatter: (v) => this.$comman.withCurrency(v.amount),
        },
        legend: false,
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        color: "#023958",
      },
    };
  },

  computed: {},

  mounted() {
    this.getEmployeeAnalytics();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getEmployeeAnalytics() {
      this.loading = true;
      commonService
        .get(this.$constants.employeeAnalyticsPdfUrl, {
          id: this.$route.params.id,
        })
        .then((res) => {
          this.data = res.data;
          this.expenseByMonthData = this.data.expense_by_month;
          this.paymentByMonthData = this.data.payment_by_month;
          this.apAgingSummary = [this.data.ap_aging_summary];

          this.setExpenseByMonthChart();
          this.setPaymentByMonthChart();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setExpenseByMonthChart() {
      let amounts = this.expenseByMonthData.map((e) => e.amount);
      let maxAmount = Math.max(...amounts);
      let max = Math.round(maxAmount + maxAmount * 0.1);
      if (this.expenseByMonth) {
        this.expenseByMonth.changeData(this.expenseByMonthData);
      } else {
        this.expenseByMonth = new Column("expenseByMonthChart", {
          data: this.expenseByMonthData,
          ...this.expenseByMonthConfig,
          yAxis: {
            max,
          },
        });
        this.expenseByMonth.render();
      }
    },

    setPaymentByMonthChart() {
      let amounts = this.paymentByMonthData.map((e) => e.amount);
      let maxAmount = Math.max(...amounts);
      let max = Math.round(maxAmount + maxAmount * 0.1);
      if (this.paymentByMonth) {
        this.paymentByMonth.changeData(this.paymentByMonthData);
      } else {
        this.paymentByMonth = new Column("paymentByMonthChart", {
          data: this.paymentByMonthData,
          ...this.paymentByMonthConfig,
          yAxis: {
            max,
          },
        });
        this.paymentByMonth.render();
      }
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.getEmployeeAnalytics();
      }
    },
  },
};
</script>

<style lang="less">
.employee-finance-chart {
  :not(div:last-child) {
    display: none;
  }
}
</style>
