<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report Billing </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-form
      ref="caseReportBillingForm"
      :model="model"
      layout="vertical"
      :rules="rules"
    >
      <a-card size="small" hoverable :loading="cardLoading">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item ref="date_range" label="Date Range" name="date_range">
              <a-range-picker
                :allowClear="false"
                v-model:value="model.date_range"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormatmat"
                inputReadOnly
                :ranges="ranges"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="employee_id"
              label="Created by"
              name="employee_id"
            >
              <a-select
                v-model:value="model.employee_id"
                show-search
                optionFilterProp="label"
                allowClear
              >
                <a-select-option
                  v-for="option in employees"
                  :key="option.id"
                  :label="option.full_name"
                  :value="option.id"
                >
                  {{ option.full_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item ref="company_id" label="Company" name="company_id">
              <a-select
                v-model:value="model.company_id"
                show-search
                optionFilterProp="label"
                allowClear
              >
                <a-select-option
                  v-for="option in companies"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item ref="hospital_id" label="Hospital" name="hospital_id">
              <a-select
                v-model:value="model.hospital_id"
                show-search
                optionFilterProp="label"
                allowClear
              >
                <a-select-option
                  v-for="option in hospitals"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-space>
          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="fetchData"
          >
            Fetch Records
          </a-button>

          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="exportExcel"
          >
            Export Excel
          </a-button>

          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="exportPdf"
          >
            Export PDF
          </a-button>
        </a-space>
      </a-card>
    </a-form>

    <a-card v-if="data" size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="data"
        :pagination="false"
        size="small"
        :scroll="{ x: true, y: 0 }"
      />
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      model: {
        date_range: [null, null],
        employee_id: undefined,
        company_id: undefined,
        hospital_id: undefined,
      },
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "array",
            len: 2,
            fields: {
              0: {
                type: "date",
                required: true,
                message: "This field is required!",
              },
              1: { type: "date", required: true, message: "" },
            },
          },
        ],
      },
      hospitals: [],
      data: null,

      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
        },
        {
          title: "MR Number",
          dataIndex: "mr_number",
          key: "mr_number",
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name,
        },
      ],
    };
  },

  computed: {
    //
  },

  async mounted() {
    await this.getHospitals();
    await this.getCompanies();
    await this.getEmployees();
    this.cardLoading = false;
  },

  methods: {
    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => this.$message.error(error));
    },

    getCompanies() {
      return commonService
        .get(this.$constants.getCompaniesUrl)
        .then((res) => {
          if (res.success) {
            this.companies = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getEmployees() {
      return commonService
        .get(this.$constants.getEmployeesUrl)
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => this.$message.error(err));
    },

    formData() {
      return {
        dates: [
          this.$customDate.ymd(this.model.date_range[0]),
          this.$customDate.ymd(this.model.date_range[1]),
        ],
        hospital_id: this.model.hospital_id,
        hospital_id: this.model.hospital_id,
        employee_id: this.model.employee_id,
        company_id: this.model.company_id,
      };
    },

    dateString() {
      return (
        this.model.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.model.date_range[1].clone().format("MMDDYY")
      );
    },

    fetchData() {
      this.$refs.caseReportBillingForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .get(this.$constants.reportCaseReportBilling, this.formData())
          .then((res) => {
            this.data = res.data;
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    exportExcel() {
      this.$refs.caseReportBillingForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportCaseReportBilling,
            { ...this.formData(), excel_export: true },
            "case-report-billing-" + this.dateString() + ".xlsx"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    exportPdf() {
      this.$refs.caseReportBillingForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportCaseReportBilling,
            { ...this.formData(), pdf_export: true },
            "case-report-billing-" + this.dateString() + ".pdf"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },
  },
};
</script>
