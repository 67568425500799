export const map = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-134.6803, 58.1617],
              [-134.2401, 58.1441],
              [-134.3069, 58.0344],
              [-134.2671, 57.8845],
              [-133.937, 57.5816],
              [-133.9737, 57.4513],
              [-133.9253, 57.3368],
              [-134.1, 57.3001],
              [-134.2602, 57.1468],
              [-134.4353, 57.057],
              [-134.5915, 57.0919],
              [-134.4868, 57.482],
              [-134.6599, 57.6381],
              [-134.8369, 58.3201],
              [-134.6803, 58.1617],
            ],
          ],
          [
            [
              [-174.6774, 52.035],
              [-175.1176, 52.047],
              [-174.3061, 52.2161],
              [-174.4355, 52.3172],
              [-174.1689, 52.4202],
              [-174.0184, 52.3318],
              [-174.1206, 52.1352],
              [-174.3435, 52.0778],
              [-174.6774, 52.035],
            ],
          ],
          [
            [
              [-172.7422, 60.4575],
              [-172.5261, 60.3918],
              [-172.6358, 60.3288],
              [-173.074, 60.4932],
              [-172.8602, 60.5057],
              [-172.7422, 60.4575],
            ],
          ],
          [
            [
              [-176.5933, 51.8667],
              [-176.4373, 51.7543],
              [-176.7709, 51.6299],
              [-176.8744, 51.7905],
              [-176.7364, 51.84],
              [-176.6984, 51.986],
              [-176.5499, 51.9441],
              [-176.5933, 51.8667],
            ],
          ],
          [
            [
              [-165.8416, 54.0707],
              [-166.0364, 54.0472],
              [-166.0877, 54.1691],
              [-165.8928, 54.207],
              [-165.7042, 54.1199],
              [-165.8416, 54.0707],
            ],
          ],
          [
            [
              [-171.463, 63.64],
              [-171.0349, 63.5855],
              [-170.4304, 63.6988],
              [-170.1218, 63.6175],
              [-170.0174, 63.4917],
              [-169.6242, 63.4305],
              [-169.4276, 63.3483],
              [-168.996, 63.3473],
              [-168.716, 63.3106],
              [-168.8524, 63.1712],
              [-169.3647, 63.1711],
              [-169.6763, 62.9561],
              [-169.7778, 63.0938],
              [-170.1896, 63.1963],
              [-170.2727, 63.2843],
              [-170.5271, 63.3793],
              [-170.8484, 63.4444],
              [-171.0613, 63.4459],
              [-171.4012, 63.3392],
              [-171.6318, 63.3512],
              [-171.791, 63.4247],
              [-171.7464, 63.7031],
              [-171.463, 63.64],
            ],
          ],
          [
            [
              [-147.6583, 60.4505],
              [-147.7321, 60.222],
              [-147.8714, 60.2297],
              [-147.7945, 60.4599],
              [-147.6583, 60.4505],
            ],
          ],
          [
            [
              [-152.4169, 58.3602],
              [-152.3163, 58.4135],
              [-151.9825, 58.2443],
              [-152.1091, 58.1612],
              [-152.5377, 58.101],
              [-152.7815, 58.016],
              [-152.9826, 57.9971],
              [-153.3813, 58.0872],
              [-152.9762, 58.2969],
              [-152.7719, 58.2786],
              [-152.8411, 58.4164],
              [-152.6747, 58.4505],
              [-152.4169, 58.3602],
            ],
          ],
          [
            [
              [-134.9697, 57.3515],
              [-134.8232, 57.1565],
              [-134.6207, 56.7183],
              [-134.6571, 56.5233],
              [-134.6301, 56.3025],
              [-134.7503, 56.2408],
              [-134.9806, 56.5189],
              [-135.0178, 56.6601],
              [-135.159, 56.7254],
              [-135.1631, 56.8241],
              [-135.3306, 56.8218],
              [-135.3151, 56.9318],
              [-135.1996, 57.0273],
              [-135.3413, 57.0816],
              [-135.3753, 57.1885],
              [-135.5019, 57.2438],
              [-135.6089, 57.0715],
              [-135.7677, 57.1004],
              [-135.7871, 57.3172],
              [-135.5805, 57.39],
              [-135.4872, 57.5164],
              [-135.3463, 57.5332],
              [-134.9697, 57.3515],
            ],
          ],
          [
            [
              [-159.873, 55.1287],
              [-160.0384, 55.0445],
              [-160.1721, 55.1231],
              [-159.8983, 55.2213],
              [-159.873, 55.1287],
            ],
          ],
          [
            [
              [-141.0022, 69.6508],
              [-141.0022, 68.7742],
              [-141.0022, 68.1897],
              [-141.0022, 67.3132],
              [-141.0022, 66.1443],
              [-141.0022, 65.5599],
              [-141.0022, 64.3911],
              [-141.0022, 63.2223],
              [-141.0022, 62.0535],
              [-141.0022, 61.1768],
              [-141.0022, 60.3002],
              [-140.5254, 60.2183],
              [-140.4529, 60.2998],
              [-139.9733, 60.1832],
              [-139.6763, 60.3283],
              [-139.0793, 60.3436],
              [-139.1852, 60.0835],
              [-139.0434, 59.9933],
              [-138.7054, 59.9013],
              [-138.6323, 59.7783],
              [-137.5933, 59.2262],
              [-137.4386, 58.9032],
              [-137.2776, 58.9882],
              [-136.8133, 59.15],
              [-136.5788, 59.1523],
              [-136.4667, 59.28],
              [-136.4664, 59.4591],
              [-136.2472, 59.533],
              [-136.3218, 59.6048],
              [-135.9347, 59.6627],
              [-135.4759, 59.7932],
              [-135.051, 59.5786],
              [-135.0713, 59.4414],
              [-134.9437, 59.2882],
              [-134.4407, 59.0854],
              [-134.2185, 58.8498],
              [-133.8207, 58.705],
              [-133.4011, 58.4109],
              [-133.4226, 58.337],
              [-133.2753, 58.2229],
              [-132.5505, 57.4999],
              [-132.2322, 57.1986],
              [-132.3379, 57.0794],
              [-132.0315, 57.0265],
              [-132.1043, 56.8568],
              [-131.8662, 56.7929],
              [-131.8243, 56.5899],
              [-131.5751, 56.5988],
              [-131.0829, 56.4048],
              [-130.7416, 56.3408],
              [-130.6491, 56.2637],
              [-130.4771, 56.2306],
              [-130.4132, 56.1225],
              [-130.0229, 56.0145],
              [-130.0251, 55.8883],
              [-130.1465, 55.6545],
              [-130.0365, 55.2979],
              [-130.2141, 55.0259],
              [-130.4932, 54.8342],
              [-130.6158, 54.7909],
              [-130.8496, 54.8076],
              [-131.0478, 55.1577],
              [-130.856, 55.3551],
              [-130.9185, 55.736],
              [-131.1277, 55.9601],
              [-131.2876, 56.0121],
              [-131.7842, 55.8765],
              [-131.8694, 55.6472],
              [-131.9834, 55.535],
              [-132.119, 55.5697],
              [-132.2234, 55.7211],
              [-132.0058, 55.9301],
              [-131.8438, 56.16],
              [-132.0219, 56.3801],
              [-132.182, 56.4206],
              [-132.3577, 56.6258],
              [-132.476, 56.6497],
              [-132.4871, 56.7664],
              [-132.6395, 56.7964],
              [-132.8022, 56.8951],
              [-132.8143, 57.0406],
              [-133.4658, 57.1721],
              [-133.4367, 57.3368],
              [-133.539, 57.5542],
              [-133.6487, 57.6423],
              [-133.5542, 57.6951],
              [-133.1171, 57.5662],
              [-133.6573, 57.841],
              [-133.7441, 57.8546],
              [-134.0311, 58.0722],
              [-134.0633, 58.2111],
              [-134.2088, 58.2329],
              [-134.4854, 58.3672],
              [-134.6636, 58.3847],
              [-134.7761, 58.4538],
              [-134.9425, 58.6463],
              [-134.9648, 58.7422],
              [-135.1319, 58.8428],
              [-135.2174, 59.0766],
              [-135.3303, 59.2391],
              [-135.5023, 59.2024],
              [-135.3862, 59.0876],
              [-135.334, 58.9096],
              [-135.0497, 58.3068],
              [-135.1415, 58.2335],
              [-135.3025, 58.2559],
              [-135.5718, 58.412],
              [-135.8735, 58.3942],
              [-135.8617, 58.5771],
              [-136.0455, 58.7891],
              [-136.2258, 58.7655],
              [-136.4511, 58.8463],
              [-136.5662, 58.9409],
              [-136.831, 58.9838],
              [-136.8791, 58.8815],
              [-136.5682, 58.7863],
              [-136.4101, 58.7006],
              [-136.4838, 58.6177],
              [-136.2246, 58.6023],
              [-136.1029, 58.5063],
              [-136.1296, 58.3504],
              [-136.4624, 58.3279],
              [-136.5826, 58.2453],
              [-137.072, 58.3951],
              [-137.544, 58.5812],
              [-137.75, 58.7071],
              [-138.0269, 58.9414],
              [-138.3525, 59.0873],
              [-139.3409, 59.3757],
              [-139.7991, 59.5463],
              [-139.6116, 59.6103],
              [-139.5056, 59.7263],
              [-139.5822, 59.8484],
              [-139.5189, 60.0172],
              [-139.8502, 59.8307],
              [-140.2167, 59.7266],
              [-140.4199, 59.7107],
              [-140.8432, 59.7488],
              [-141.4083, 59.9028],
              [-141.2946, 59.98],
              [-141.3295, 60.0828],
              [-141.6702, 59.9698],
              [-142.5486, 60.0861],
              [-142.9457, 60.097],
              [-143.5061, 60.055],
              [-143.805, 60.0129],
              [-144.1472, 60.0163],
              [-144.0885, 60.0843],
              [-144.3326, 60.191],
              [-144.643, 60.2246],
              [-144.9013, 60.3351],
              [-144.8245, 60.5336],
              [-144.984, 60.537],
              [-145.2483, 60.3801],
              [-145.8989, 60.4782],
              [-145.6749, 60.6511],
              [-146.1491, 60.6607],
              [-146.251, 60.7491],
              [-146.6385, 60.8973],
              [-146.5828, 61.1278],
              [-146.874, 61.0048],
              [-147.255, 60.9782],
              [-147.321, 60.9255],
              [-147.5926, 60.9795],
              [-147.6557, 60.9095],
              [-147.8911, 60.8899],
              [-148.0052, 60.9686],
              [-147.8448, 61.1864],
              [-148.2086, 61.0299],
              [-148.3444, 60.8536],
              [-148.5574, 60.8029],
              [-148.2679, 60.6997],
              [-148.2964, 60.532],
              [-148.0507, 60.5671],
              [-147.9641, 60.4849],
              [-148.1817, 60.393],
              [-148.1976, 60.1678],
              [-148.3331, 60.122],
              [-148.4307, 59.9892],
              [-148.8427, 59.9512],
              [-149.1216, 60.0335],
              [-149.4323, 60.0011],
              [-149.5491, 59.8943],
              [-149.7948, 59.8559],
              [-149.7825, 59.7503],
              [-150.198, 59.5666],
              [-150.5815, 59.5645],
              [-150.6775, 59.427],
              [-150.9608, 59.2439],
              [-151.3664, 59.2456],
              [-151.7382, 59.1885],
              [-151.9641, 59.2852],
              [-151.8499, 59.4064],
              [-151.3996, 59.5164],
              [-151.1894, 59.6377],
              [-151.7638, 59.7],
              [-151.8532, 59.7821],
              [-151.7345, 59.9884],
              [-151.4515, 60.2027],
              [-151.3127, 60.4665],
              [-151.3218, 60.7429],
              [-150.9538, 60.8413],
              [-150.4413, 61.0236],
              [-149.9975, 60.9352],
              [-149.6325, 60.9519],
              [-149.1728, 60.8805],
              [-149.1422, 60.9357],
              [-149.5925, 60.9939],
              [-149.9677, 61.1218],
              [-149.9452, 61.2943],
              [-150.4718, 61.26],
              [-150.6123, 61.3012],
              [-150.9455, 61.1982],
              [-151.1502, 61.0859],
              [-151.5935, 60.9796],
              [-151.734, 60.9108],
              [-151.7504, 60.7549],
              [-151.9962, 60.6822],
              [-152.2707, 60.5281],
              [-152.2603, 60.4095],
              [-152.3689, 60.3364],
              [-152.6539, 60.2385],
              [-152.6286, 60.0411],
              [-152.7595, 59.9209],
              [-153.106, 59.8751],
              [-153.0482, 59.73],
              [-153.364, 59.6598],
              [-153.4144, 59.7402],
              [-153.8141, 59.4737],
              [-154.0883, 59.3634],
              [-154.1783, 59.1556],
              [-154.1298, 59.1198],
              [-153.6564, 59.0386],
              [-153.4183, 58.9599],
              [-153.3344, 58.8579],
              [-153.6173, 58.6547],
              [-153.8619, 58.5878],
              [-154.0199, 58.4929],
              [-154.0859, 58.3658],
              [-154.2081, 58.2887],
              [-154.247, 58.1594],
              [-154.5706, 58.118],
              [-154.5849, 58.0556],
              [-155.0069, 58.0161],
              [-155.0993, 57.9133],
              [-155.3128, 57.8071],
              [-155.5903, 57.7336],
              [-155.7779, 57.5683],
              [-156.0002, 57.545],
              [-156.0554, 57.4476],
              [-156.2422, 57.4493],
              [-156.4784, 57.3279],
              [-156.3976, 57.2406],
              [-156.4751, 57.1052],
              [-156.629, 57.0099],
              [-156.7799, 57.0057],
              [-157.2058, 56.812],
              [-157.3902, 56.8099],
              [-157.5784, 56.6345],
              [-157.8691, 56.6452],
              [-157.9783, 56.5432],
              [-158.4144, 56.4358],
              [-158.5374, 56.3354],
              [-158.3861, 56.3015],
              [-158.2757, 56.1962],
              [-158.5233, 56.0724],
              [-158.6267, 56.1547],
              [-158.7898, 55.987],
              [-159.5232, 55.81],
              [-159.743, 55.8438],
              [-159.9623, 55.7949],
              [-160.4627, 55.5578],
              [-160.8967, 55.5136],
              [-161.0995, 55.4057],
              [-161.3819, 55.3713],
              [-161.4767, 55.465],
              [-161.3727, 55.5563],
              [-161.4588, 55.6291],
              [-161.6836, 55.53],
              [-161.7416, 55.3912],
              [-162.074, 55.1393],
              [-162.3329, 55.0503],
              [-162.5419, 55.2427],
              [-162.6441, 55.218],
              [-162.6143, 55.0714],
              [-162.6744, 54.9965],
              [-162.865, 54.9546],
              [-163.1196, 55.0647],
              [-163.1311, 54.9166],
              [-163.2887, 54.8376],
              [-163.2788, 55.1219],
              [-163.1145, 55.194],
              [-162.962, 55.1839],
              [-162.5133, 55.45],
              [-162.1571, 55.7195],
              [-161.6973, 55.9072],
              [-161.2156, 56.0214],
              [-160.8513, 55.7718],
              [-160.7584, 55.8547],
              [-160.3085, 55.8645],
              [-160.5391, 56.0063],
              [-160.302, 56.3142],
              [-159.7851, 56.5617],
              [-159.2831, 56.6886],
              [-158.9904, 56.86],
              [-158.6752, 56.7949],
              [-158.6608, 57.0394],
              [-158.3209, 57.2979],
              [-158.0457, 57.4095],
              [-157.8944, 57.5114],
              [-157.6076, 57.6015],
              [-157.684, 57.7439],
              [-157.6109, 58.0508],
              [-157.4427, 58.1722],
              [-157.5236, 58.4213],
              [-157.4609, 58.503],
              [-157.2288, 58.6409],
              [-156.9747, 58.7363],
              [-157.142, 58.8777],
              [-157.6657, 58.7485],
              [-158.0219, 58.6402],
              [-158.3026, 58.6418],
              [-158.5032, 58.8504],
              [-158.4256, 58.9993],
              [-158.5144, 59.0728],
              [-158.7606, 58.9501],
              [-158.8614, 58.7187],
              [-158.7721, 58.5203],
              [-158.7886, 58.4409],
              [-158.9507, 58.4046],
              [-159.0827, 58.4697],
              [-159.3583, 58.7213],
              [-159.6702, 58.9111],
              [-159.9202, 58.8199],
              [-160.1526, 58.9059],
              [-160.3631, 59.0511],
              [-160.8171, 58.8718],
              [-161.2159, 58.801],
              [-161.3613, 58.6695],
              [-161.7555, 58.6121],
              [-162.1449, 58.6443],
              [-161.8564, 58.7171],
              [-161.7244, 58.7943],
              [-161.7805, 58.8974],
              [-161.7945, 59.1094],
              [-161.9811, 59.1461],
              [-162.0233, 59.284],
              [-161.9201, 59.3654],
              [-161.8287, 59.5886],
              [-162.1382, 59.98],
              [-162.2425, 60.1783],
              [-162.4213, 60.284],
              [-162.1389, 60.6143],
              [-162.2651, 60.5953],
              [-162.5996, 60.297],
              [-162.5005, 60.1266],
              [-162.5707, 59.9897],
              [-162.7326, 59.9937],
              [-162.8779, 59.9228],
              [-163.2194, 59.8456],
              [-163.6804, 59.8015],
              [-163.9069, 59.8068],
              [-164.1428, 59.8968],
              [-164.1316, 59.9943],
              [-164.4705, 60.1493],
              [-164.6623, 60.3039],
              [-164.7999, 60.3073],
              [-165.0611, 60.4125],
              [-165.0265, 60.5006],
              [-165.3538, 60.5412],
              [-165.016, 60.7401],
              [-164.8051, 60.892],
              [-164.37, 60.7959],
              [-164.2656, 60.7247],
              [-163.8949, 60.7451],
              [-163.73, 60.5899],
              [-163.5287, 60.6645],
              [-163.421, 60.7575],
              [-163.6554, 60.8775],
              [-163.749, 60.9697],
              [-163.9946, 60.8647],
              [-164.4415, 60.8699],
              [-164.754, 60.9313],
              [-165.0657, 60.9207],
              [-165.1755, 60.9656],
              [-165.0771, 61.0942],
              [-165.3921, 61.2123],
              [-165.3808, 61.1063],
              [-165.5659, 61.1023],
              [-165.6914, 61.2999],
              [-165.9063, 61.4037],
              [-165.7971, 61.4912],
              [-166.1005, 61.6451],
              [-165.8346, 61.6793],
              [-166.0788, 61.8031],
              [-165.6128, 61.8693],
              [-165.7439, 62.0117],
              [-165.7073, 62.1005],
              [-165.1946, 62.4735],
              [-164.7927, 62.6232],
              [-164.8454, 62.801],
              [-164.6775, 63.0205],
              [-164.409, 63.2151],
              [-164.1076, 63.2617],
              [-163.7363, 63.1928],
              [-163.3588, 63.0458],
              [-163.0622, 63.0797],
              [-162.6215, 63.2658],
              [-162.2828, 63.5292],
              [-162.1125, 63.5342],
              [-161.974, 63.453],
              [-161.5054, 63.4682],
              [-161.0997, 63.5579],
              [-160.8265, 63.7293],
              [-160.7786, 63.8189],
              [-160.9876, 64.2513],
              [-161.2201, 64.3966],
              [-161.3857, 64.44],
              [-160.932, 64.5791],
              [-160.836, 64.6819],
              [-160.887, 64.7955],
              [-161.1869, 64.9241],
              [-161.4663, 64.7948],
              [-161.7594, 64.8162],
              [-161.8684, 64.7427],
              [-162.1722, 64.6781],
              [-162.807, 64.3742],
              [-162.8764, 64.5164],
              [-163.0517, 64.5197],
              [-163.1444, 64.4238],
              [-163.4862, 64.5498],
              [-163.7131, 64.5883],
              [-164.3039, 64.5839],
              [-164.8995, 64.4607],
              [-166.1428, 64.5828],
              [-166.3251, 64.6257],
              [-166.4814, 64.7281],
              [-166.4152, 64.9265],
              [-166.5509, 64.953],
              [-166.8269, 65.0961],
              [-166.531, 65.1547],
              [-166.4516, 65.2473],
              [-166.6654, 65.3383],
              [-167.404, 65.4221],
              [-167.9873, 65.5678],
              [-168.0884, 65.6578],
              [-167.58, 65.7583],
              [-167.4053, 65.8593],
              [-167.0742, 65.8771],
              [-166.7477, 66.0518],
              [-166.2146, 66.1703],
              [-165.7237, 66.1125],
              [-165.5602, 66.1671],
              [-165.8403, 66.245],
              [-165.7762, 66.319],
              [-165.4494, 66.4099],
              [-165.0639, 66.4378],
              [-164.6741, 66.555],
              [-164.0583, 66.6108],
              [-163.7755, 66.5311],
              [-163.9029, 66.3784],
              [-163.894, 66.2869],
              [-164.0337, 66.2156],
              [-163.6954, 66.0839],
              [-163.1715, 66.0754],
              [-162.8865, 66.0992],
              [-162.5868, 66.0509],
              [-162.2143, 66.071],
              [-161.8163, 66.0536],
              [-161.5569, 66.2505],
              [-161.2011, 66.2193],
              [-161.1203, 66.3343],
              [-161.5444, 66.4071],
              [-161.8281, 66.3709],
              [-161.8876, 66.4931],
              [-162.1912, 66.6932],
              [-162.4675, 66.7357],
              [-162.6075, 66.8944],
              [-162.3617, 66.9473],
              [-162.1314, 66.8014],
              [-161.9096, 66.5596],
              [-161.591, 66.4595],
              [-161.3359, 66.4964],
              [-161.0481, 66.4742],
              [-160.6505, 66.3731],
              [-160.2317, 66.4203],
              [-160.2625, 66.5724],
              [-160.6438, 66.605],
              [-160.864, 66.6709],
              [-161.398, 66.5519],
              [-161.8566, 66.7004],
              [-161.8787, 66.804],
              [-161.6222, 66.9793],
              [-161.9655, 67.0495],
              [-162.3915, 67.0199],
              [-163.0017, 67.0273],
              [-163.5318, 67.1025],
              [-163.7206, 67.1955],
              [-163.9427, 67.4776],
              [-164.1252, 67.6067],
              [-165.386, 68.0456],
              [-165.9596, 68.1559],
              [-166.236, 68.278],
              [-166.5745, 68.3203],
              [-166.3805, 68.4251],
              [-166.182, 68.7972],
              [-166.2091, 68.8853],
              [-165.5095, 68.8676],
              [-165.0439, 68.8825],
              [-164.3024, 68.9365],
              [-163.868, 69.0367],
              [-163.5356, 69.1701],
              [-163.1615, 69.388],
              [-163.0936, 69.6107],
              [-162.9521, 69.7581],
              [-161.881, 70.3318],
              [-161.639, 70.2346],
              [-160.9963, 70.3046],
              [-160.6341, 70.4464],
              [-160.1172, 70.5912],
              [-159.9632, 70.5682],
              [-160.095, 70.3333],
              [-159.8552, 70.3242],
              [-159.7463, 70.5305],
              [-159.9618, 70.6341],
              [-159.6809, 70.7868],
              [-159.3145, 70.8785],
              [-158.9963, 70.8016],
              [-158.4844, 70.8411],
              [-157.9985, 70.8453],
              [-157.6056, 70.9412],
              [-157.1953, 71.0933],
              [-156.7833, 71.3189],
              [-155.8112, 71.1885],
              [-155.5795, 71.1211],
              [-155.8043, 70.9955],
              [-156.1466, 70.9279],
              [-155.8722, 70.8346],
              [-155.5794, 70.8943],
              [-155.1669, 71.0993],
              [-154.9438, 71.0831],
              [-154.6737, 70.9871],
              [-154.7852, 70.8943],
              [-154.1953, 70.8011],
              [-153.9182, 70.8773],
              [-153.2329, 70.9326],
              [-152.7849, 70.876],
              [-152.4912, 70.881],
              [-152.2329, 70.8103],
              [-152.4705, 70.6536],
              [-152.1729, 70.5567],
              [-151.769, 70.5602],
              [-151.9447, 70.4521],
              [-151.2248, 70.4188],
              [-150.6627, 70.5099],
              [-150.4032, 70.4439],
              [-150.1525, 70.4437],
              [-149.8701, 70.5096],
              [-149.2695, 70.5008],
              [-148.6884, 70.4163],
              [-148.4793, 70.3179],
              [-148.2487, 70.3567],
              [-147.8695, 70.3033],
              [-147.7054, 70.2172],
              [-147.0629, 70.1704],
              [-146.7449, 70.1917],
              [-145.8232, 70.16],
              [-145.1974, 70.0087],
              [-144.6192, 69.9821],
              [-144.4169, 70.039],
              [-143.7465, 70.102],
              [-143.2183, 70.1162],
              [-142.7079, 70.0338],
              [-142.2969, 69.8699],
              [-141.6992, 69.7704],
              [-141.4079, 69.6534],
              [-141.0022, 69.6508],
            ],
          ],
          [
            [
              [-134.3127, 58.2289],
              [-134.594, 58.2431],
              [-134.52, 58.3326],
              [-134.3127, 58.2289],
            ],
          ],
          [
            [
              [-132.8623, 54.8944],
              [-132.6173, 54.8925],
              [-132.6469, 54.7562],
              [-132.8073, 54.7091],
              [-133.1227, 54.9698],
              [-133.2511, 55.1751],
              [-133.418, 55.2107],
              [-133.2966, 55.3258],
              [-133.0974, 55.2137],
              [-132.8623, 54.8944],
            ],
          ],
          [
            [
              [-160.919, 58.5771],
              [-161.0703, 58.5691],
              [-161.1315, 58.6681],
              [-160.9863, 58.7364],
              [-160.7151, 58.7953],
              [-160.919, 58.5771],
            ],
          ],
          [
            [
              [-167.9644, 53.345],
              [-168.2707, 53.2381],
              [-168.446, 53.0844],
              [-168.6986, 52.9634],
              [-169.0659, 52.834],
              [-168.7959, 53.0449],
              [-168.7596, 53.175],
              [-168.639, 53.2558],
              [-168.4367, 53.2569],
              [-168.3572, 53.4576],
              [-168.1931, 53.5333],
              [-167.9857, 53.5581],
              [-167.8047, 53.4849],
              [-167.9644, 53.345],
            ],
          ],
          [
            [
              [-166.1354, 60.3835],
              [-166.0436, 60.3339],
              [-165.7297, 60.3142],
              [-165.7123, 60.0694],
              [-165.5918, 59.9131],
              [-165.9467, 59.89],
              [-166.1876, 59.7738],
              [-166.343, 59.8345],
              [-166.6276, 59.8647],
              [-167.1389, 60.0085],
              [-167.4364, 60.2067],
              [-167.2517, 60.2335],
              [-166.8363, 60.2169],
              [-166.7844, 60.2965],
              [-166.4757, 60.3827],
              [-166.1354, 60.3835],
            ],
          ],
          [
            [
              [-177.1482, 51.7167],
              [-177.5776, 51.6942],
              [-177.2098, 51.8414],
              [-177.1482, 51.7167],
            ],
          ],
          [
            [
              [-146.394, 60.4496],
              [-146.1283, 60.3926],
              [-146.6184, 60.2737],
              [-146.7026, 60.4085],
              [-146.5603, 60.4805],
              [-146.394, 60.4496],
            ],
          ],
          [
            [
              [-147.7358, 59.8132],
              [-147.8143, 59.902],
              [-147.4658, 60.097],
              [-147.12, 60.3631],
              [-146.9867, 60.2544],
              [-147.3184, 60.0753],
              [-147.4993, 59.8902],
              [-147.7358, 59.8132],
            ],
          ],
          [
            [
              [-177.8791, 51.6497],
              [-178.0589, 51.6726],
              [-177.9864, 51.7643],
              [-178.1945, 51.8822],
              [-177.9538, 51.9184],
              [-177.7706, 51.7779],
              [-177.8791, 51.6497],
            ],
          ],
          [
            [
              [-135.7304, 58.2443],
              [-135.5875, 58.1468],
              [-135.6133, 57.9919],
              [-135.3466, 58.124],
              [-135.0021, 58.0511],
              [-134.928, 57.9528],
              [-134.9706, 57.8173],
              [-135.2312, 57.8158],
              [-135.2495, 57.7326],
              [-134.9789, 57.7243],
              [-134.8731, 57.5893],
              [-134.9315, 57.4811],
              [-135.0848, 57.511],
              [-135.4979, 57.6623],
              [-135.6086, 57.6507],
              [-135.6178, 57.4804],
              [-135.692, 57.4199],
              [-135.9108, 57.4465],
              [-136.0766, 57.6745],
              [-136.4599, 57.8731],
              [-136.5686, 57.9722],
              [-136.5123, 58.0959],
              [-136.1424, 58.1539],
              [-135.7871, 58.2684],
              [-135.7304, 58.2443],
            ],
          ],
          [
            [
              [-166.6153, 53.9009],
              [-166.4975, 53.8835],
              [-166.3724, 53.999],
              [-166.2309, 53.9326],
              [-166.4887, 53.7855],
              [-166.5492, 53.701],
              [-166.4442, 53.6518],
              [-166.7704, 53.476],
              [-167.1537, 53.4078],
              [-167.5225, 53.2762],
              [-167.6695, 53.2599],
              [-167.8088, 53.3238],
              [-167.5302, 53.3937],
              [-167.1361, 53.5265],
              [-167.0157, 53.6984],
              [-167.1182, 53.8726],
              [-166.9781, 53.9629],
              [-166.6274, 53.9957],
              [-166.6153, 53.9009],
            ],
          ],
          [
            [
              [-131.3398, 55.0798],
              [-131.232, 54.9037],
              [-131.4062, 54.8943],
              [-131.4314, 54.9965],
              [-131.5951, 55.0908],
              [-131.5778, 55.2009],
              [-131.4046, 55.2133],
              [-131.3398, 55.0798],
            ],
          ],
          [
            [
              [-152.898, 57.8239],
              [-152.5115, 57.8514],
              [-152.4115, 57.6462],
              [-152.2163, 57.5771],
              [-152.4122, 57.4547],
              [-152.7814, 57.4534],
              [-152.679, 57.3452],
              [-152.879, 57.3208],
              [-153.0516, 57.2377],
              [-153.2744, 57.2263],
              [-153.5036, 57.138],
              [-153.6465, 57.0295],
              [-153.7572, 56.8584],
              [-153.9727, 56.7742],
              [-154.0709, 56.8207],
              [-153.7932, 56.9895],
              [-153.9994, 57.05],
              [-154.0653, 57.1336],
              [-154.3244, 57.1318],
              [-154.1843, 57.0053],
              [-154.3389, 56.9209],
              [-154.4988, 57.0365],
              [-154.5693, 57.2059],
              [-154.706, 57.3353],
              [-154.6732, 57.4461],
              [-154.5353, 57.5595],
              [-154.2814, 57.6381],
              [-154.0298, 57.6307],
              [-153.8054, 57.3582],
              [-153.8184, 57.5956],
              [-153.6931, 57.6634],
              [-153.9044, 57.8199],
              [-153.6627, 57.8579],
              [-153.5245, 57.731],
              [-153.2003, 57.82],
              [-153.1605, 57.972],
              [-152.8504, 57.8968],
              [-152.898, 57.8239],
            ],
          ],
          [
            [
              [-163.4761, 54.9808],
              [-163.3581, 54.7356],
              [-163.583, 54.6256],
              [-164.0733, 54.621],
              [-164.2346, 54.5713],
              [-164.4635, 54.4273],
              [-164.8234, 54.4191],
              [-164.8877, 54.6078],
              [-164.7062, 54.692],
              [-164.5298, 54.8808],
              [-164.2737, 54.9],
              [-163.868, 55.0392],
              [-163.6075, 55.0508],
              [-163.4761, 54.9808],
            ],
          ],
          [
            [
              [-133.9896, 56.845],
              [-133.8309, 56.7813],
              [-133.7384, 56.6505],
              [-133.8553, 56.5822],
              [-133.8846, 56.2921],
              [-133.9497, 56.1277],
              [-134.1896, 56.0769],
              [-134.245, 56.2032],
              [-134.1955, 56.4134],
              [-134.0844, 56.4564],
              [-134.2902, 56.58],
              [-134.3844, 56.724],
              [-134.3737, 56.8387],
              [-134.1433, 56.9323],
              [-133.9896, 56.845],
            ],
          ],
          [
            [
              [-133.5661, 56.3393],
              [-133.203, 56.3198],
              [-133.0818, 56.1942],
              [-133.0966, 56.09],
              [-132.7576, 55.9949],
              [-132.5977, 55.8951],
              [-132.4302, 55.687],
              [-132.5916, 55.4644],
              [-132.4178, 55.4829],
              [-132.2153, 55.3835],
              [-132.2066, 55.2244],
              [-131.9764, 55.2085],
              [-132.0004, 55.0338],
              [-131.9809, 54.8048],
              [-132.1343, 54.7126],
              [-132.3413, 54.9073],
              [-132.5494, 54.9525],
              [-132.5885, 55.0524],
              [-132.7823, 55.0485],
              [-133.103, 55.3602],
              [-132.9589, 55.3956],
              [-133.0824, 55.504],
              [-133.0896, 55.6126],
              [-133.2983, 55.6069],
              [-133.369, 55.689],
              [-133.5533, 55.6912],
              [-133.6802, 55.7851],
              [-133.4469, 55.7971],
              [-133.2415, 55.9209],
              [-133.3712, 56.0359],
              [-133.6842, 55.9428],
              [-133.7552, 55.9995],
              [-133.5308, 56.1456],
              [-133.5944, 56.2164],
              [-133.5661, 56.3393],
            ],
          ],
          [
            [
              [-133.3051, 55.5438],
              [-133.2817, 55.4979],
              [-133.6502, 55.2693],
              [-133.6342, 55.5393],
              [-133.3051, 55.5438],
            ],
          ],
          [
            [
              [-132.7799, 56.2472],
              [-133.0351, 56.3409],
              [-132.9021, 56.4537],
              [-132.7061, 56.4485],
              [-132.6694, 56.2873],
              [-132.7799, 56.2472],
            ],
          ],
          [
            [
              [-153.007, 57.1248],
              [-153.3746, 57.0519],
              [-153.2852, 57.185],
              [-153.007, 57.1248],
            ],
          ],
          [
            [
              [-133.3662, 57.0035],
              [-133.0708, 56.9743],
              [-132.9541, 56.8803],
              [-132.954, 56.713],
              [-133.1442, 56.5669],
              [-133.2126, 56.4646],
              [-133.6028, 56.464],
              [-133.6493, 56.5168],
              [-133.681, 56.7975],
              [-133.9794, 57.0096],
              [-133.866, 57.0687],
              [-133.3662, 57.0035],
            ],
          ],
          [
            [
              [-130.9791, 55.4892],
              [-131.0828, 55.2668],
              [-131.1879, 55.2063],
              [-131.4207, 55.2759],
              [-131.4745, 55.3735],
              [-131.811, 55.2231],
              [-131.8461, 55.4163],
              [-131.6476, 55.5855],
              [-131.625, 55.8316],
              [-131.2692, 55.9554],
              [-131.1207, 55.8566],
              [-130.966, 55.6695],
              [-130.9791, 55.4892],
            ],
          ],
          [
            [
              [-160.6849, 55.3148],
              [-160.5832, 55.3077],
              [-160.4875, 55.1848],
              [-160.8255, 55.174],
              [-160.8465, 55.3114],
              [-160.6849, 55.3148],
            ],
          ],
          [
            [
              [-132.1123, 56.1094],
              [-132.1329, 55.9432],
              [-132.2873, 55.9294],
              [-132.4511, 56.0564],
              [-132.6599, 56.0782],
              [-132.6991, 56.1982],
              [-132.5987, 56.2416],
              [-132.3798, 56.4987],
              [-132.3165, 56.4875],
              [-132.0669, 56.2442],
              [-132.1123, 56.1094],
            ],
          ],
          [
            [
              [-132.7468, 56.5257],
              [-132.9308, 56.5245],
              [-132.8425, 56.7948],
              [-132.6559, 56.6847],
              [-132.568, 56.5758],
              [-132.7468, 56.5257],
            ],
          ],
        ],
      },
      properties: {
        name: "Alaska",
        id: "US-AK",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-AK",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.6235, 35.0009],
            [-85.3859, 33.8507],
            [-85.2009, 32.9562],
            [-85.1565, 32.8042],
            [-85.0151, 32.5261],
            [-84.986, 32.3609],
            [-84.9297, 32.2466],
            [-85.0346, 32.1474],
            [-85.1241, 31.8804],
            [-85.1248, 31.7768],
            [-85.0549, 31.5726],
            [-85.0926, 31.2957],
            [-85.0675, 31.1176],
            [-85.0073, 31.0016],
            [-85.9769, 31.0009],
            [-86.6236, 31.0009],
            [-87.5937, 31.0008],
            [-87.6011, 30.8605],
            [-87.4216, 30.6716],
            [-87.4294, 30.4778],
            [-87.4896, 30.3777],
            [-87.6222, 30.2647],
            [-87.7903, 30.2919],
            [-87.9243, 30.4496],
            [-87.9489, 30.627],
            [-88.0325, 30.6812],
            [-88.1355, 30.3666],
            [-88.3996, 30.3709],
            [-88.4443, 31.1397],
            [-88.4862, 31.8904],
            [-88.3397, 32.9988],
            [-88.1688, 34.292],
            [-88.0847, 34.9331],
            [-88.1733, 34.999],
            [-88.1905, 35.0249],
            [-86.9071, 35.0129],
            [-85.6235, 35.0009],
          ],
        ],
      },
      properties: {
        name: "Alabama",
        id: "US-AL",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-AL",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.7047, 36.0015],
            [-89.7037, 35.9069],
            [-89.7796, 35.8057],
            [-89.9436, 35.6791],
            [-89.9674, 35.5033],
            [-90.066, 35.4141],
            [-90.0929, 35.2036],
            [-90.1423, 35.1142],
            [-90.2938, 35.0012],
            [-90.3198, 34.8992],
            [-90.5392, 34.7281],
            [-90.5841, 34.5037],
            [-90.718, 34.3917],
            [-90.8172, 34.2522],
            [-90.9097, 34.1888],
            [-90.9431, 34.0297],
            [-91.0702, 33.9743],
            [-91.0528, 33.8276],
            [-91.2076, 33.6756],
            [-91.2146, 33.5834],
            [-91.1411, 33.4386],
            [-91.1633, 33.2944],
            [-91.1068, 33.2179],
            [-91.15, 33.0159],
            [-92.4243, 33.0139],
            [-93.3227, 33.0128],
            [-94.0413, 33.012],
            [-94.0472, 33.5544],
            [-94.2947, 33.5875],
            [-94.3778, 33.5661],
            [-94.4841, 33.6485],
            [-94.4616, 34.5178],
            [-94.4391, 35.3872],
            [-94.6184, 36.5009],
            [-93.7829, 36.5009],
            [-92.3904, 36.5009],
            [-91.5548, 36.5009],
            [-90.1623, 36.5009],
            [-90.0744, 36.3719],
            [-90.0758, 36.2967],
            [-90.303, 36.0993],
            [-90.3811, 35.9927],
            [-89.7047, 36.0015],
          ],
        ],
      },
      properties: {
        name: "Arkansas",
        id: "US-AR",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-AR",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.0467, 37.0009],
            [-109.0468, 36.2918],
            [-109.0469, 35.5829],
            [-109.0469, 34.5194],
            [-109.0472, 33.4558],
            [-109.0473, 32.3922],
            [-109.0478, 31.328],
            [-110.3351, 31.3255],
            [-111.0421, 31.3243],
            [-111.9905, 31.6202],
            [-112.939, 31.9162],
            [-113.8875, 32.2123],
            [-114.836, 32.5083],
            [-114.7248, 32.7154],
            [-114.5822, 32.7347],
            [-114.4775, 32.8419],
            [-114.4908, 32.9836],
            [-114.6481, 33.0718],
            [-114.7045, 33.1698],
            [-114.7017, 33.4179],
            [-114.6026, 33.47],
            [-114.4992, 33.6858],
            [-114.5091, 33.904],
            [-114.3293, 34.142],
            [-114.1255, 34.2865],
            [-114.1589, 34.3552],
            [-114.371, 34.4885],
            [-114.398, 34.5897],
            [-114.5575, 34.7946],
            [-114.6105, 34.9912],
            [-114.5909, 35.3528],
            [-114.6485, 35.4759],
            [-114.6452, 35.6305],
            [-114.6871, 35.9173],
            [-114.7414, 36.0136],
            [-114.6691, 36.1218],
            [-114.4061, 36.1484],
            [-114.1833, 36.0303],
            [-114.0429, 36.1817],
            [-114.0402, 37.0042],
            [-113.104, 37.0035],
            [-111.8556, 37.0026],
            [-111.2313, 37.0022],
            [-109.9829, 37.0014],
            [-109.0467, 37.0009],
          ],
        ],
      },
      properties: {
        name: "Arizona",
        id: "US-AZ",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-AZ",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-120.0008, 42.0005],
              [-120.0006, 40.6879],
              [-120.0001, 39.5627],
              [-120.0001, 39.0002],
              [-119.2974, 38.5098],
              [-118.5948, 38.0193],
              [-118.1262, 37.6924],
              [-117.2097, 37.0033],
              [-116.6596, 36.5899],
              [-115.8978, 35.9955],
              [-115.3901, 35.5991],
              [-114.6105, 34.9912],
              [-114.5575, 34.7946],
              [-114.398, 34.5897],
              [-114.371, 34.4885],
              [-114.1589, 34.3552],
              [-114.1255, 34.2865],
              [-114.3293, 34.142],
              [-114.5091, 33.904],
              [-114.4992, 33.6858],
              [-114.6026, 33.47],
              [-114.7017, 33.4179],
              [-114.7045, 33.1698],
              [-114.6481, 33.0718],
              [-114.4908, 32.9836],
              [-114.4775, 32.8419],
              [-114.5822, 32.7347],
              [-114.7248, 32.7154],
              [-115.6975, 32.6404],
              [-117.1282, 32.5334],
              [-117.1374, 32.6491],
              [-117.2436, 32.6641],
              [-117.2629, 32.9389],
              [-117.3188, 33.1],
              [-117.4675, 33.2955],
              [-117.7885, 33.5385],
              [-117.9521, 33.6196],
              [-118.0806, 33.7221],
              [-118.2644, 33.7586],
              [-118.4105, 33.7439],
              [-118.3929, 33.8583],
              [-118.5062, 34.0175],
              [-118.832, 34.0245],
              [-119.1437, 34.112],
              [-119.2358, 34.1641],
              [-119.2676, 34.2575],
              [-119.606, 34.418],
              [-119.8533, 34.412],
              [-120.0529, 34.4692],
              [-120.4812, 34.4716],
              [-120.6446, 34.58],
              [-120.6249, 34.812],
              [-120.663, 34.9494],
              [-120.6336, 35.0765],
              [-120.707, 35.1577],
              [-120.8574, 35.2096],
              [-120.8603, 35.3654],
              [-121.0228, 35.4809],
              [-121.138, 35.6071],
              [-121.2839, 35.6764],
              [-121.3438, 35.7923],
              [-121.6644, 36.154],
              [-121.8775, 36.331],
              [-121.9186, 36.5724],
              [-121.79, 36.7322],
              [-121.8074, 36.8513],
              [-121.8806, 36.9389],
              [-122.1642, 36.991],
              [-122.3948, 37.2075],
              [-122.4084, 37.3731],
              [-122.4993, 37.5426],
              [-122.5142, 37.772],
              [-122.3842, 37.7886],
              [-122.3698, 37.6559],
              [-122.2287, 37.5639],
              [-122.2222, 37.7321],
              [-122.296, 37.7904],
              [-122.3854, 37.9606],
              [-122.217, 38.0407],
              [-121.7168, 38.0341],
              [-122.0315, 38.1235],
              [-122.1538, 38.0655],
              [-122.3933, 38.1447],
              [-122.4839, 38.1088],
              [-122.4669, 37.8381],
              [-122.7604, 37.9456],
              [-122.9682, 38.097],
              [-122.9865, 38.2771],
              [-123.1212, 38.4493],
              [-123.2897, 38.5359],
              [-123.4248, 38.6757],
              [-123.7011, 38.9073],
              [-123.7195, 39.111],
              [-123.8202, 39.3684],
              [-123.7835, 39.6187],
              [-123.8845, 39.8608],
              [-124.1085, 40.0945],
              [-124.324, 40.2519],
              [-124.3717, 40.4912],
              [-124.2837, 40.7106],
              [-124.1331, 40.9697],
              [-124.14, 41.1558],
              [-124.0685, 41.3841],
              [-124.1177, 41.6218],
              [-124.2446, 41.7879],
              [-124.2284, 42.0008],
              [-123.4283, 42.0005],
              [-122.3737, 42.0005],
              [-121.0554, 42.0005],
              [-120.0008, 42.0005],
            ],
          ],
          [
            [
              [-119.8824, 34.0797],
              [-119.5622, 34.0065],
              [-119.8095, 33.9677],
              [-119.8824, 34.0797],
            ],
          ],
          [
            [
              [-120.0436, 33.9188],
              [-120.1672, 33.9181],
              [-120.2519, 34.0138],
              [-120.0719, 34.0265],
              [-120.0436, 33.9188],
            ],
          ],
        ],
      },
      properties: {
        name: "California",
        id: "US-CA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-CA",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-102.0245, 40.0011],
            [-102.0209, 39.0636],
            [-102.018, 38.3135],
            [-102.0128, 37.0009],
            [-103.0008, 37.0009],
            [-103.7566, 37.0009],
            [-104.8901, 37.0009],
            [-106.0238, 37.0009],
            [-106.7795, 37.0009],
            [-107.9131, 37.0009],
            [-109.0467, 37.0009],
            [-109.0465, 38.0009],
            [-109.0465, 39.2509],
            [-109.0465, 40.0008],
            [-109.0464, 41.0009],
            [-108.1044, 41.0009],
            [-107.1622, 41.0009],
            [-105.906, 41.0009],
            [-104.9638, 41.0009],
            [-104.0217, 41.0009],
            [-103.273, 41.0007],
            [-102.025, 41.0006],
            [-102.0245, 40.0011],
          ],
        ],
      },
      properties: {
        name: "Colorado",
        id: "US-CO",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-CO",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.8009, 42.0119],
            [-71.7958, 41.5199],
            [-71.8423, 41.3354],
            [-72.2653, 41.2917],
            [-72.8471, 41.2659],
            [-73.1823, 41.1758],
            [-73.6305, 40.9918],
            [-73.7231, 41.1045],
            [-73.4841, 41.2189],
            [-73.5447, 41.296],
            [-73.4806, 42.0556],
            [-72.8078, 42.0341],
            [-71.8017, 42.0227],
            [-71.8009, 42.0119],
          ],
        ],
      },
      properties: {
        name: "Connecticut",
        id: "US-CT",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-CT",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.0303, 38.8893],
            [-77.1221, 38.9435],
            [-77.0421, 39.0118],
            [-76.9312, 38.8882],
            [-77.0303, 38.8893],
          ],
        ],
      },
      properties: {
        name: "District of Columbia",
        id: "US-DC",
        CNTRY: "United States of America",
        TYPE: "Federal District",
      },
      id: "US-DC",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.4211, 39.8154],
            [-75.5877, 39.6407],
            [-75.5738, 39.477],
            [-75.4127, 39.2813],
            [-75.3921, 39.0927],
            [-75.1851, 38.8194],
            [-75.0887, 38.7776],
            [-75.1284, 38.6325],
            [-75.0377, 38.4556],
            [-75.7071, 38.455],
            [-75.7847, 39.7223],
            [-75.6346, 39.8394],
            [-75.4211, 39.8154],
          ],
        ],
      },
      properties: {
        name: "Delaware",
        id: "US-DE",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-DE",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.504, 30.7313],
            [-81.4572, 30.6407],
            [-81.3858, 30.2699],
            [-81.2495, 29.7938],
            [-81.1045, 29.4569],
            [-80.8999, 29.0498],
            [-80.5241, 28.4861],
            [-80.6229, 28.3203],
            [-80.7317, 28.4629],
            [-80.6885, 28.5785],
            [-80.766, 28.6328],
            [-80.7487, 28.3809],
            [-80.6501, 28.1809],
            [-80.2261, 27.207],
            [-80.1257, 27.0829],
            [-80.05, 26.8077],
            [-80.0413, 26.5686],
            [-80.1107, 26.1316],
            [-80.1264, 25.8335],
            [-80.3008, 25.6186],
            [-80.3669, 25.3312],
            [-80.4846, 25.2299],
            [-80.5577, 25.2325],
            [-80.7366, 25.1564],
            [-80.8622, 25.1761],
            [-81.1104, 25.138],
            [-81.1675, 25.2285],
            [-81.0977, 25.3191],
            [-81.2272, 25.5834],
            [-81.3452, 25.7319],
            [-81.3649, 25.831],
            [-81.5683, 25.8916],
            [-81.7155, 25.9832],
            [-81.8114, 26.1461],
            [-81.8665, 26.435],
            [-82.0396, 26.5521],
            [-82.0778, 26.7043],
            [-82.067, 26.8915],
            [-82.1807, 26.8401],
            [-82.2901, 26.8708],
            [-82.4413, 27.0596],
            [-82.6204, 27.4011],
            [-82.6359, 27.5246],
            [-82.4006, 27.8353],
            [-82.5206, 27.8778],
            [-82.6609, 27.7185],
            [-82.7429, 27.7094],
            [-82.8435, 27.846],
            [-82.7485, 28.2368],
            [-82.6607, 28.4858],
            [-82.6515, 28.8875],
            [-82.7693, 29.0515],
            [-83.2905, 29.4519],
            [-83.6944, 29.926],
            [-84.0443, 30.1037],
            [-84.3098, 30.0648],
            [-84.3828, 29.9074],
            [-84.55, 29.8979],
            [-84.8005, 29.7731],
            [-85.0292, 29.7212],
            [-85.3188, 29.6802],
            [-85.3069, 29.7978],
            [-85.3537, 29.8757],
            [-85.6758, 30.1218],
            [-85.6035, 30.2868],
            [-85.7408, 30.2444],
            [-85.7557, 30.167],
            [-86.1752, 30.3325],
            [-86.2574, 30.493],
            [-86.4479, 30.4956],
            [-86.6796, 30.4028],
            [-87.2011, 30.3393],
            [-86.9858, 30.4308],
            [-87.1705, 30.5388],
            [-87.1847, 30.4537],
            [-87.2811, 30.3393],
            [-87.4758, 30.2942],
            [-87.4896, 30.3777],
            [-87.4294, 30.4778],
            [-87.4216, 30.6716],
            [-87.6011, 30.8605],
            [-87.5937, 31.0008],
            [-86.6236, 31.0009],
            [-85.9769, 31.0009],
            [-85.0073, 31.0016],
            [-84.8839, 30.7209],
            [-84.1528, 30.6777],
            [-82.8775, 30.603],
            [-82.2399, 30.5657],
            [-82.1942, 30.3935],
            [-82.0618, 30.4046],
            [-82.0219, 30.5598],
            [-82.0382, 30.7319],
            [-81.9356, 30.8195],
            [-81.6733, 30.7403],
            [-81.504, 30.7313],
          ],
        ],
      },
      properties: {
        name: "Florida",
        id: "US-FL",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-FL",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.8724, 32.0295],
            [-80.9234, 31.9449],
            [-81.1133, 31.8787],
            [-81.065, 31.8135],
            [-81.1979, 31.7042],
            [-81.1754, 31.5313],
            [-81.295, 31.3712],
            [-81.2885, 31.2639],
            [-81.4532, 31.0883],
            [-81.5204, 30.8746],
            [-81.504, 30.7313],
            [-81.6733, 30.7403],
            [-81.9356, 30.8195],
            [-82.0382, 30.7319],
            [-82.0219, 30.5598],
            [-82.0618, 30.4046],
            [-82.1942, 30.3935],
            [-82.2399, 30.5657],
            [-82.8775, 30.603],
            [-84.1528, 30.6777],
            [-84.8839, 30.7209],
            [-85.0073, 31.0016],
            [-85.0675, 31.1176],
            [-85.0926, 31.2957],
            [-85.0549, 31.5726],
            [-85.1248, 31.7768],
            [-85.1241, 31.8804],
            [-85.0346, 32.1474],
            [-84.9297, 32.2466],
            [-84.986, 32.3609],
            [-85.0151, 32.5261],
            [-85.1565, 32.8042],
            [-85.2009, 32.9562],
            [-85.3859, 33.8507],
            [-85.6235, 35.0009],
            [-84.3247, 34.988],
            [-83.1149, 35.0015],
            [-83.3167, 34.8057],
            [-83.3557, 34.7083],
            [-83.0528, 34.5109],
            [-82.8478, 34.4369],
            [-82.8187, 34.3661],
            [-82.5894, 34.0176],
            [-82.2566, 33.7493],
            [-82.2084, 33.6636],
            [-82.0057, 33.5229],
            [-81.9329, 33.3899],
            [-81.6994, 33.1267],
            [-81.5783, 33.0686],
            [-81.4897, 32.9356],
            [-81.377, 32.6075],
            [-81.2903, 32.5573],
            [-81.1714, 32.3801],
            [-81.1351, 32.1818],
            [-81.0748, 32.1097],
            [-80.8724, 32.0295],
          ],
        ],
      },
      properties: {
        name: "Georgia",
        id: "US-GA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-GA",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-156.4868, 20.9326],
              [-156.2775, 20.9514],
              [-156.1484, 20.8855],
              [-155.9899, 20.7572],
              [-156.1072, 20.6448],
              [-156.31, 20.5988],
              [-156.4383, 20.6179],
              [-156.4801, 20.8012],
              [-156.6155, 20.8218],
              [-156.6897, 20.9014],
              [-156.6568, 21.0244],
              [-156.5855, 21.0343],
              [-156.4868, 20.9326],
            ],
          ],
          [
            [
              [-157.2136, 21.2155],
              [-156.7423, 21.1635],
              [-156.8599, 21.0563],
              [-157.0209, 21.0978],
              [-157.2903, 21.1126],
              [-157.2136, 21.2155],
            ],
          ],
          [
            [
              [-156.8496, 20.7727],
              [-156.9734, 20.7575],
              [-157.0506, 20.9126],
              [-156.9418, 20.9301],
              [-156.8094, 20.8311],
              [-156.8496, 20.7727],
            ],
          ],
          [
            [
              [-155.5813, 19.012],
              [-155.6808, 18.9676],
              [-155.8813, 19.0704],
              [-155.9056, 19.1258],
              [-155.8907, 19.3825],
              [-155.9659, 19.5908],
              [-156.0487, 19.7499],
              [-155.8203, 20.0143],
              [-155.8928, 20.1674],
              [-155.8316, 20.2758],
              [-155.6221, 20.1633],
              [-155.1988, 19.9944],
              [-155.0861, 19.8756],
              [-155.0659, 19.7481],
              [-154.8042, 19.5245],
              [-154.8503, 19.4541],
              [-155.0535, 19.3192],
              [-155.3097, 19.2601],
              [-155.5352, 19.109],
              [-155.5813, 19.012],
            ],
          ],
          [
            [
              [-160.1801, 21.841],
              [-160.2209, 21.8972],
              [-160.1006, 22.0153],
              [-160.08, 21.9075],
              [-160.1801, 21.841],
            ],
          ],
          [
            [
              [-157.7993, 21.4567],
              [-157.6355, 21.3076],
              [-157.7988, 21.2687],
              [-157.9018, 21.3405],
              [-158.1104, 21.3186],
              [-158.2391, 21.4893],
              [-158.2732, 21.5851],
              [-158.1231, 21.6002],
              [-157.9625, 21.7014],
              [-157.7993, 21.4567],
            ],
          ],
          [
            [
              [-159.3728, 21.9323],
              [-159.4607, 21.8761],
              [-159.6089, 21.9094],
              [-159.7892, 22.0419],
              [-159.7267, 22.1402],
              [-159.5792, 22.2232],
              [-159.3521, 22.2196],
              [-159.3007, 22.1052],
              [-159.3728, 21.9323],
            ],
          ],
        ],
      },
      properties: {
        name: "Hawaii",
        id: "US-HI",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-HI",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.6506, 42.5129],
            [-90.4848, 42.3833],
            [-90.3466, 42.2148],
            [-90.1675, 42.1003],
            [-90.1524, 41.9816],
            [-90.2177, 41.813],
            [-90.4367, 41.5603],
            [-90.7541, 41.4498],
            [-90.9387, 41.4255],
            [-91.0482, 41.369],
            [-91.0665, 41.2059],
            [-90.9638, 41.0825],
            [-91.0001, 40.9047],
            [-91.1021, 40.7498],
            [-91.2151, 40.6496],
            [-91.3391, 40.604],
            [-91.4419, 40.3794],
            [-91.682, 40.5519],
            [-91.7315, 40.6246],
            [-93.2451, 40.616],
            [-94.5063, 40.6087],
            [-95.765, 40.6018],
            [-95.859, 40.7451],
            [-95.8341, 40.8895],
            [-95.8836, 41.2876],
            [-95.9329, 41.4254],
            [-96.0586, 41.5458],
            [-96.0688, 41.7343],
            [-96.1543, 41.9652],
            [-96.2954, 42.1329],
            [-96.3837, 42.4177],
            [-96.4809, 42.5113],
            [-96.5388, 42.6587],
            [-96.6218, 42.7296],
            [-96.4627, 43.0992],
            [-96.5555, 43.2317],
            [-96.5416, 43.3735],
            [-96.5985, 43.4976],
            [-96.4536, 43.5012],
            [-95.478, 43.5013],
            [-94.5023, 43.5014],
            [-93.5267, 43.5016],
            [-92.2258, 43.5018],
            [-91.2453, 43.5024],
            [-91.2187, 43.3953],
            [-91.1172, 43.3311],
            [-91.1614, 43.1023],
            [-91.0695, 42.7885],
            [-90.9599, 42.7204],
            [-90.7887, 42.6769],
            [-90.6879, 42.6104],
            [-90.6506, 42.5129],
          ],
        ],
      },
      properties: {
        name: "Iowa",
        id: "US-IA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-IA",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.0514, 44.4989],
            [-111.051, 43.7182],
            [-111.0508, 43.0938],
            [-111.0503, 42.0009],
            [-111.9855, 42.0009],
            [-112.7333, 42.001],
            [-114.0426, 42.001],
            [-115.1578, 42.0009],
            [-115.9014, 42.0008],
            [-117.0166, 42.0005],
            [-117.019, 43.1165],
            [-117.0205, 43.8132],
            [-116.9113, 44.1574],
            [-116.9531, 44.2453],
            [-117.1398, 44.2903],
            [-117.2017, 44.4362],
            [-117.1189, 44.5783],
            [-116.9489, 44.7701],
            [-116.8396, 44.9851],
            [-116.5492, 45.4567],
            [-116.4771, 45.6411],
            [-116.6168, 45.7992],
            [-116.7647, 45.873],
            [-116.8965, 46.0021],
            [-116.943, 46.2321],
            [-117.025, 46.4291],
            [-117.0317, 47.7115],
            [-117.039, 48.993],
            [-116.0485, 48.993],
            [-116.0491, 48.0047],
            [-115.7337, 47.7064],
            [-115.6697, 47.4979],
            [-115.7024, 47.4377],
            [-115.3495, 47.2612],
            [-114.9357, 46.9229],
            [-114.7537, 46.7345],
            [-114.6015, 46.6603],
            [-114.3368, 46.6592],
            [-114.3459, 46.5506],
            [-114.4853, 46.1582],
            [-114.4784, 46.0356],
            [-114.4051, 45.8742],
            [-114.5338, 45.7819],
            [-114.4958, 45.6859],
            [-114.5292, 45.5757],
            [-114.2976, 45.4875],
            [-113.9426, 45.6815],
            [-113.8023, 45.601],
            [-113.697, 45.305],
            [-113.4711, 45.0729],
            [-113.4693, 44.93],
            [-113.3389, 44.8081],
            [-113.1872, 44.7986],
            [-113.0933, 44.7154],
            [-112.9998, 44.4803],
            [-112.8156, 44.406],
            [-112.7159, 44.498],
            [-112.3929, 44.468],
            [-112.2313, 44.5607],
            [-111.7972, 44.5399],
            [-111.4998, 44.5448],
            [-111.4481, 44.7111],
            [-111.3374, 44.7449],
            [-111.1976, 44.5906],
            [-111.0514, 44.4989],
          ],
        ],
      },
      properties: {
        name: "Idaho",
        id: "US-ID",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-ID",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.8121, 42.4971],
            [-87.7975, 42.2114],
            [-87.6946, 42.0844],
            [-87.5212, 41.7277],
            [-87.5254, 40.8615],
            [-87.5283, 40.2619],
            [-87.533, 39.3749],
            [-87.611, 39.2914],
            [-87.6472, 39.1688],
            [-87.5304, 38.9089],
            [-87.5295, 38.7423],
            [-87.6359, 38.5427],
            [-87.784, 38.4191],
            [-87.8271, 38.3356],
            [-87.9805, 38.2492],
            [-88.0425, 37.9366],
            [-88.0953, 37.8756],
            [-88.042, 37.7888],
            [-88.1289, 37.6995],
            [-88.1075, 37.5343],
            [-88.1906, 37.4646],
            [-88.4947, 37.3642],
            [-88.4533, 37.1782],
            [-88.4717, 37.0883],
            [-88.8726, 37.1856],
            [-89.0462, 37.183],
            [-89.1345, 37.1025],
            [-89.1543, 36.9921],
            [-89.2725, 37.08],
            [-89.414, 37.1023],
            [-89.503, 37.3005],
            [-89.462, 37.4342],
            [-89.5234, 37.6445],
            [-89.6233, 37.7472],
            [-89.823, 37.8841],
            [-90.1737, 38.069],
            [-90.299, 38.1739],
            [-90.374, 38.3097],
            [-90.3165, 38.4578],
            [-90.1268, 38.8325],
            [-90.2201, 38.8977],
            [-90.4781, 38.9481],
            [-90.5771, 38.9096],
            [-90.6635, 38.9875],
            [-90.7372, 39.1818],
            [-90.9195, 39.3869],
            [-91.2099, 39.6029],
            [-91.3787, 39.7493],
            [-91.4669, 39.9428],
            [-91.4998, 40.226],
            [-91.4419, 40.3794],
            [-91.3391, 40.604],
            [-91.2151, 40.6496],
            [-91.1021, 40.7498],
            [-91.0001, 40.9047],
            [-90.9638, 41.0825],
            [-91.0665, 41.2059],
            [-91.0482, 41.369],
            [-90.9387, 41.4255],
            [-90.7541, 41.4498],
            [-90.4367, 41.5603],
            [-90.2177, 41.813],
            [-90.1524, 41.9816],
            [-90.1675, 42.1003],
            [-90.3466, 42.2148],
            [-90.4848, 42.3833],
            [-90.6506, 42.5129],
            [-89.2905, 42.5052],
            [-87.8121, 42.4971],
          ],
        ],
      },
      properties: {
        name: "Illinois",
        id: "US-IL",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-IL",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.7961, 41.7014],
            [-84.803, 41.0506],
            [-84.8133, 40.0745],
            [-84.8216, 39.092],
            [-84.8541, 38.9129],
            [-84.8007, 38.8194],
            [-85.1699, 38.6903],
            [-85.3068, 38.7372],
            [-85.4447, 38.6831],
            [-85.4248, 38.5966],
            [-85.6464, 38.3473],
            [-85.8549, 38.2151],
            [-85.9611, 38.0139],
            [-86.1035, 37.9918],
            [-86.2838, 38.1298],
            [-86.4297, 38.1397],
            [-86.5487, 37.9171],
            [-86.6731, 37.889],
            [-86.7703, 37.9598],
            [-86.9694, 37.938],
            [-87.1411, 37.8052],
            [-87.3724, 37.9234],
            [-87.5995, 37.9465],
            [-87.7145, 37.8997],
            [-87.9059, 37.9019],
            [-87.9125, 37.8177],
            [-88.042, 37.7888],
            [-88.0953, 37.8756],
            [-88.0425, 37.9366],
            [-87.9805, 38.2492],
            [-87.8271, 38.3356],
            [-87.784, 38.4191],
            [-87.6359, 38.5427],
            [-87.5295, 38.7423],
            [-87.5304, 38.9089],
            [-87.6472, 39.1688],
            [-87.611, 39.2914],
            [-87.533, 39.3749],
            [-87.5283, 40.2619],
            [-87.5254, 40.8615],
            [-87.5212, 41.7277],
            [-87.4124, 41.6564],
            [-87.2532, 41.6377],
            [-87.0726, 41.6695],
            [-86.8627, 41.7607],
            [-85.4026, 41.76],
            [-84.7964, 41.7597],
            [-84.7961, 41.7014],
          ],
        ],
      },
      properties: {
        name: "Indiana",
        id: "US-IN",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-IN",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.6181, 37.0009],
            [-95.5424, 37.0009],
            [-96.4668, 37.0009],
            [-97.1601, 37.0009],
            [-98.0844, 37.0009],
            [-99.0086, 37.0009],
            [-99.9329, 37.0009],
            [-101.0885, 37.0009],
            [-102.0128, 37.0009],
            [-102.018, 38.3135],
            [-102.0209, 39.0636],
            [-102.0245, 40.0011],
            [-100.7669, 40.0011],
            [-99.2997, 40.0011],
            [-97.8325, 40.0011],
            [-96.7845, 40.0011],
            [-95.3466, 40.0015],
            [-95.1162, 39.8721],
            [-94.9841, 39.8911],
            [-94.8933, 39.7818],
            [-95.0754, 39.5974],
            [-95.058, 39.509],
            [-94.9525, 39.4264],
            [-94.7778, 39.194],
            [-94.6225, 39.1245],
            [-94.623, 38.5647],
            [-94.62, 37.6525],
            [-94.6181, 37.0009],
          ],
        ],
      },
      properties: {
        name: "Kansas",
        id: "US-KS",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-KS",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.6129, 38.4482],
            [-82.5784, 38.272],
            [-82.5887, 38.0998],
            [-82.2802, 37.6872],
            [-82.1035, 37.5706],
            [-81.9652, 37.5396],
            [-82.3431, 37.2969],
            [-82.6816, 37.1381],
            [-82.7319, 37.048],
            [-82.8945, 36.9064],
            [-83.1502, 36.7624],
            [-83.6676, 36.605],
            [-83.7304, 36.5868],
            [-84.4247, 36.6062],
            [-85.8133, 36.6453],
            [-86.5076, 36.6648],
            [-87.8392, 36.6439],
            [-88.0964, 36.693],
            [-88.0591, 36.4997],
            [-89.4503, 36.5],
            [-89.3974, 36.5878],
            [-89.203, 36.6256],
            [-89.1225, 36.9116],
            [-89.1543, 36.9921],
            [-89.1345, 37.1025],
            [-89.0462, 37.183],
            [-88.8726, 37.1856],
            [-88.4717, 37.0883],
            [-88.4533, 37.1782],
            [-88.4947, 37.3642],
            [-88.1906, 37.4646],
            [-88.1075, 37.5343],
            [-88.1289, 37.6995],
            [-88.042, 37.7888],
            [-87.9125, 37.8177],
            [-87.9059, 37.9019],
            [-87.7145, 37.8997],
            [-87.5995, 37.9465],
            [-87.3724, 37.9234],
            [-87.1411, 37.8052],
            [-86.9694, 37.938],
            [-86.7703, 37.9598],
            [-86.6731, 37.889],
            [-86.5487, 37.9171],
            [-86.4297, 38.1397],
            [-86.2838, 38.1298],
            [-86.1035, 37.9918],
            [-85.9611, 38.0139],
            [-85.8549, 38.2151],
            [-85.6464, 38.3473],
            [-85.4248, 38.5966],
            [-85.4447, 38.6831],
            [-85.3068, 38.7372],
            [-85.1699, 38.6903],
            [-84.8007, 38.8194],
            [-84.8541, 38.9129],
            [-84.8216, 39.092],
            [-84.7069, 39.1289],
            [-84.5946, 39.0858],
            [-84.43, 39.0909],
            [-84.2904, 38.9747],
            [-84.2272, 38.8652],
            [-84.1133, 38.7984],
            [-83.9484, 38.7743],
            [-83.6898, 38.6495],
            [-83.5384, 38.6998],
            [-83.357, 38.6274],
            [-83.2035, 38.6389],
            [-83.0001, 38.7141],
            [-82.8805, 38.7102],
            [-82.7582, 38.5412],
            [-82.6129, 38.4482],
          ],
        ],
      },
      properties: {
        name: "Kentucky",
        id: "US-KY",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-KY",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-91.7938, 29.5007],
              [-91.9963, 29.5731],
              [-91.8754, 29.6409],
              [-91.7677, 29.5847],
              [-91.7938, 29.5007],
            ],
          ],
          [
            [
              [-89.521, 30.1926],
              [-89.5884, 30.166],
              [-89.9543, 30.2688],
              [-90.0452, 30.3514],
              [-90.2252, 30.3794],
              [-90.332, 30.2776],
              [-90.4131, 30.1403],
              [-90.1753, 30.0291],
              [-89.9942, 30.0593],
              [-89.8941, 30.1258],
              [-89.7373, 30.172],
              [-89.6651, 30.117],
              [-89.8152, 30.0073],
              [-89.7438, 29.9299],
              [-89.5895, 29.9151],
              [-89.4945, 30.0582],
              [-89.3578, 29.9211],
              [-89.3545, 29.8202],
              [-89.5307, 29.7722],
              [-89.7209, 29.6193],
              [-89.6748, 29.5387],
              [-89.5137, 29.42],
              [-89.2456, 29.3331],
              [-89.0158, 29.2028],
              [-89.1555, 29.0166],
              [-89.2362, 29.0811],
              [-89.3535, 29.0702],
              [-89.4431, 29.1942],
              [-89.6202, 29.3024],
              [-89.7923, 29.3331],
              [-89.8772, 29.458],
              [-90.0523, 29.4314],
              [-90.1013, 29.1817],
              [-90.2129, 29.1049],
              [-90.3016, 29.2558],
              [-90.3792, 29.2951],
              [-90.5863, 29.2715],
              [-90.6775, 29.1506],
              [-90.7511, 29.1309],
              [-91.0027, 29.1935],
              [-91.2901, 29.289],
              [-91.1555, 29.3507],
              [-91.331, 29.5136],
              [-91.5142, 29.5554],
              [-91.6724, 29.7461],
              [-91.8243, 29.7506],
              [-91.8932, 29.8361],
              [-92.0173, 29.8003],
              [-92.1355, 29.6995],
              [-92.0841, 29.5928],
              [-92.2608, 29.5569],
              [-92.6712, 29.5971],
              [-92.9525, 29.7142],
              [-93.1757, 29.7789],
              [-93.6948, 29.7698],
              [-93.8265, 29.7251],
              [-93.8839, 29.81],
              [-93.7731, 29.9141],
              [-93.794, 29.9772],
              [-93.7106, 30.1128],
              [-93.7504, 30.3448],
              [-93.7202, 30.5583],
              [-93.5792, 30.8238],
              [-93.5306, 31.0462],
              [-93.5577, 31.1804],
              [-93.6659, 31.3226],
              [-93.7376, 31.5138],
              [-93.8206, 31.604],
              [-93.8269, 31.7503],
              [-93.905, 31.877],
              [-94.0434, 31.9992],
              [-94.0413, 33.012],
              [-93.3227, 33.0128],
              [-92.4243, 33.0139],
              [-91.15, 33.0159],
              [-91.0961, 32.9376],
              [-91.1424, 32.6611],
              [-91.1038, 32.4905],
              [-90.9447, 32.3307],
              [-90.9477, 32.2401],
              [-91.0887, 32.1042],
              [-91.1566, 31.9707],
              [-91.2864, 31.884],
              [-91.3725, 31.7736],
              [-91.4146, 31.6399],
              [-91.529, 31.4488],
              [-91.529, 31.3451],
              [-91.6307, 31.2176],
              [-91.5963, 31.1099],
              [-91.6565, 31.0018],
              [-91.1769, 31.0012],
              [-89.7353, 31.0011],
              [-89.8354, 30.7308],
              [-89.82, 30.6112],
              [-89.6858, 30.4627],
              [-89.5917, 30.223],
              [-89.521, 30.1926],
            ],
          ],
        ],
      },
      properties: {
        name: "Louisiana",
        id: "US-LA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-LA",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.5099, 41.3763],
              [-70.7854, 41.3275],
              [-70.6737, 41.4486],
              [-70.5099, 41.3763],
            ],
          ],
          [
            [
              [-70.8062, 42.8767],
              [-70.8291, 42.8254],
              [-70.7357, 42.6692],
              [-71.0462, 42.3311],
              [-70.7383, 42.2289],
              [-70.5488, 41.9386],
              [-70.5147, 41.8033],
              [-70.2954, 41.729],
              [-70.0014, 41.8262],
              [-69.9486, 41.6772],
              [-70.4047, 41.6268],
              [-70.657, 41.5343],
              [-70.7012, 41.7149],
              [-70.9742, 41.5485],
              [-71.1885, 41.5164],
              [-71.2338, 41.7065],
              [-71.3407, 41.7978],
              [-71.3871, 42.0169],
              [-71.8009, 42.0119],
              [-71.8017, 42.0227],
              [-72.8078, 42.0341],
              [-73.4806, 42.0556],
              [-73.5072, 42.08],
              [-73.2533, 42.7522],
              [-72.4669, 42.7303],
              [-71.3296, 42.7024],
              [-70.974, 42.8716],
              [-70.8062, 42.8767],
            ],
          ],
        ],
      },
      properties: {
        name: "Massachusetts",
        id: "US-MA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MA",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.7847, 39.7223],
            [-75.7071, 38.455],
            [-75.0377, 38.4556],
            [-75.1342, 38.3843],
            [-75.376, 38.025],
            [-75.62, 37.9993],
            [-75.6592, 37.954],
            [-75.8508, 37.9716],
            [-75.7953, 38.0866],
            [-75.928, 38.1693],
            [-75.864, 38.2613],
            [-76.0513, 38.2795],
            [-76.2116, 38.3613],
            [-76.2948, 38.4947],
            [-76.2641, 38.6],
            [-76.0569, 38.6212],
            [-76.3003, 38.8183],
            [-76.1682, 38.8527],
            [-76.2357, 39.1917],
            [-76.1532, 39.3151],
            [-75.9547, 39.4596],
            [-75.959, 39.5851],
            [-76.0629, 39.5611],
            [-76.0972, 39.433],
            [-76.2764, 39.3228],
            [-76.4056, 39.3038],
            [-76.4208, 39.225],
            [-76.5739, 39.2543],
            [-76.42, 39.0739],
            [-76.5196, 38.8982],
            [-76.5368, 38.7426],
            [-76.5013, 38.5321],
            [-76.4164, 38.4202],
            [-76.4388, 38.3615],
            [-76.3329, 38.1408],
            [-76.5936, 38.2282],
            [-76.8896, 38.2921],
            [-77.0767, 38.4417],
            [-77.2326, 38.4077],
            [-77.2416, 38.4948],
            [-77.0183, 38.7777],
            [-77.0303, 38.8893],
            [-76.9312, 38.8882],
            [-77.0421, 39.0118],
            [-77.1221, 38.9435],
            [-77.3012, 39.0533],
            [-77.4809, 39.113],
            [-77.4789, 39.2209],
            [-77.7266, 39.3465],
            [-77.7981, 39.5172],
            [-77.8832, 39.6107],
            [-78.1812, 39.6857],
            [-78.4068, 39.6279],
            [-78.4956, 39.5334],
            [-78.8148, 39.5701],
            [-78.9714, 39.4537],
            [-79.0752, 39.4761],
            [-79.2935, 39.3115],
            [-79.488, 39.2109],
            [-79.4776, 39.7227],
            [-78.0926, 39.7226],
            [-76.708, 39.7225],
            [-75.7847, 39.7223],
          ],
        ],
      },
      properties: {
        name: "Maryland",
        id: "US-MD",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MD",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-68.1872, 44.3325],
              [-68.4118, 44.2944],
              [-68.2995, 44.4565],
              [-68.1872, 44.3325],
            ],
          ],
          [
            [
              [-70.7331, 43.07],
              [-70.829, 43.2391],
              [-70.9557, 43.3894],
              [-71.0314, 44.5297],
              [-71.0846, 45.294],
              [-70.8369, 45.3106],
              [-70.7992, 45.4048],
              [-70.6898, 45.4283],
              [-70.7023, 45.5514],
              [-70.421, 45.7383],
              [-70.2962, 45.9061],
              [-70.3045, 46.0574],
              [-70.2483, 46.2509],
              [-70.0673, 46.4411],
              [-70.0076, 46.7089],
              [-69.2429, 47.4629],
              [-69.0502, 47.4267],
              [-69.0486, 47.2737],
              [-68.8286, 47.2033],
              [-68.4805, 47.2857],
              [-68.3109, 47.3544],
              [-68.0968, 47.2749],
              [-67.8068, 47.0828],
              [-67.7958, 46.4984],
              [-67.7846, 45.7017],
              [-67.5958, 45.6208],
              [-67.4326, 45.6031],
              [-67.4936, 45.4741],
              [-67.4279, 45.378],
              [-67.4726, 45.2759],
              [-67.3669, 45.1738],
              [-67.1249, 45.1694],
              [-67.0805, 44.9891],
              [-67.1066, 44.885],
              [-66.9871, 44.8277],
              [-67.1912, 44.6756],
              [-67.364, 44.6969],
              [-67.556, 44.6448],
              [-67.6529, 44.5623],
              [-67.8392, 44.5763],
              [-67.907, 44.4536],
              [-68.0566, 44.3842],
              [-68.1174, 44.4906],
              [-68.2774, 44.5074],
              [-68.6121, 44.3105],
              [-68.7939, 44.3818],
              [-68.8473, 44.485],
              [-68.9615, 44.4339],
              [-68.9562, 44.3482],
              [-69.0635, 44.1724],
              [-69.0685, 44.0975],
              [-69.2261, 43.9864],
              [-69.3446, 44.001],
              [-69.4809, 43.9051],
              [-69.7298, 43.8521],
              [-69.7916, 43.8053],
              [-69.9652, 43.8551],
              [-70.1789, 43.7663],
              [-70.2692, 43.6719],
              [-70.2026, 43.6262],
              [-70.5207, 43.3488],
              [-70.6423, 43.1344],
              [-70.7331, 43.07],
            ],
          ],
        ],
      },
      properties: {
        name: "Maine",
        id: "US-ME",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-ME",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-88.9552, 47.926],
              [-89.1568, 47.8408],
              [-89.1466, 47.9526],
              [-88.5883, 48.1585],
              [-88.6247, 48.042],
              [-88.9552, 47.926],
            ],
          ],
          [
            [
              [-88.2219, 47.2106],
              [-88.2368, 47.1589],
              [-88.4341, 47.1223],
              [-88.6158, 47.1741],
              [-88.5619, 47.2722],
              [-88.3944, 47.3887],
              [-88.1102, 47.4725],
              [-88.0155, 47.3208],
              [-88.2219, 47.2106],
            ],
          ],
          [
            [
              [-84.1329, 46.3622],
              [-84.2741, 46.4875],
              [-84.1423, 46.5309],
              [-84.1329, 46.3622],
            ],
          ],
          [
            [
              [-87.6059, 45.1085],
              [-87.721, 45.2427],
              [-87.6964, 45.3829],
              [-87.8653, 45.3678],
              [-87.8152, 45.5059],
              [-87.8091, 45.6861],
              [-87.9225, 45.76],
              [-88.1161, 45.8158],
              [-88.1518, 45.9454],
              [-88.4931, 46.0135],
              [-88.8374, 46.0365],
              [-89.1193, 46.1491],
              [-90.1185, 46.3416],
              [-90.2304, 46.5098],
              [-90.4077, 46.593],
              [-90.1536, 46.6546],
              [-89.8605, 46.7928],
              [-89.6951, 46.8386],
              [-89.355, 46.8819],
              [-89.1548, 46.9886],
              [-88.9482, 47.043],
              [-88.8297, 47.1459],
              [-88.6327, 47.167],
              [-88.5154, 47.0955],
              [-88.4558, 46.9628],
              [-88.4761, 46.8367],
              [-88.2162, 46.9516],
              [-87.7776, 46.8878],
              [-87.6374, 46.8265],
              [-87.3581, 46.5244],
              [-87.0341, 46.5432],
              [-86.8515, 46.4703],
              [-86.6375, 46.453],
              [-86.531, 46.5244],
              [-86.2313, 46.6563],
              [-85.9467, 46.7021],
              [-85.6161, 46.6906],
              [-85.0146, 46.7835],
              [-85.0343, 46.5058],
              [-84.7789, 46.4683],
              [-84.6973, 46.4907],
              [-84.5283, 46.436],
              [-84.3274, 46.4915],
              [-84.229, 46.3658],
              [-84.2036, 46.247],
              [-84.0409, 46.0881],
              [-83.9787, 45.9615],
              [-84.3409, 45.9998],
              [-84.5212, 45.9946],
              [-84.6314, 46.0526],
              [-84.8019, 45.8743],
              [-85.1007, 46.0461],
              [-85.3859, 46.1087],
              [-85.5551, 46.0782],
              [-85.7079, 45.9876],
              [-85.9593, 45.9407],
              [-86.1229, 45.9689],
              [-86.2717, 45.9367],
              [-86.3995, 45.8007],
              [-86.5594, 45.8063],
              [-86.5634, 45.8824],
              [-86.7985, 45.8317],
              [-86.9378, 45.7003],
              [-87.0848, 45.7298],
              [-87.2165, 45.6074],
              [-87.3989, 45.3646],
              [-87.6022, 45.157],
              [-87.6059, 45.1085],
            ],
          ],
          [
            [
              [-83.5035, 46.0131],
              [-83.518, 45.9578],
              [-83.7307, 45.9429],
              [-83.8658, 45.9791],
              [-83.6082, 46.0965],
              [-83.5035, 46.0131],
            ],
          ],
          [
            [
              [-83.4542, 41.7456],
              [-83.4643, 41.7394],
              [-84.7961, 41.7014],
              [-84.7964, 41.7597],
              [-85.4026, 41.76],
              [-86.8627, 41.7607],
              [-86.6325, 41.9114],
              [-86.4073, 42.2237],
              [-86.2995, 42.4176],
              [-86.2328, 42.65],
              [-86.2215, 42.9004],
              [-86.3292, 43.2242],
              [-86.5421, 43.6373],
              [-86.4405, 43.7943],
              [-86.4486, 43.924],
              [-86.5192, 44.0738],
              [-86.296, 44.3306],
              [-86.2422, 44.5514],
              [-86.237, 44.7202],
              [-86.1259, 44.7477],
              [-86.0577, 44.9164],
              [-85.8579, 44.9615],
              [-85.6893, 45.1281],
              [-85.5877, 45.1613],
              [-85.6472, 44.8376],
              [-85.4846, 44.8403],
              [-85.389, 45.0328],
              [-85.3756, 45.277],
              [-85.2229, 45.3561],
              [-84.9671, 45.3926],
              [-85.0877, 45.5068],
              [-85.0878, 45.6053],
              [-84.9515, 45.7753],
              [-84.772, 45.7948],
              [-84.4414, 45.6763],
              [-84.1892, 45.6112],
              [-84.1278, 45.5325],
              [-83.4785, 45.3389],
              [-83.3201, 45.1389],
              [-83.4482, 45.0246],
              [-83.3278, 44.8692],
              [-83.2944, 44.6834],
              [-83.3306, 44.3752],
              [-83.5125, 44.2707],
              [-83.614, 44.0583],
              [-83.8789, 43.9626],
              [-83.9395, 43.782],
              [-83.9157, 43.7005],
              [-83.7352, 43.6266],
              [-83.6217, 43.6365],
              [-83.4689, 43.7308],
              [-83.3382, 43.929],
              [-82.9946, 44.0612],
              [-82.8782, 44.0477],
              [-82.7161, 43.939],
              [-82.5968, 43.6344],
              [-82.4612, 43.081],
              [-82.4173, 43.0174],
              [-82.4884, 42.7394],
              [-82.5453, 42.6247],
              [-82.8678, 42.3853],
              [-83.1047, 42.2864],
              [-83.2619, 41.9463],
              [-83.3621, 41.8798],
              [-83.4542, 41.7456],
            ],
          ],
        ],
      },
      properties: {
        name: "Michigan",
        id: "US-MI",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MI",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.3778, 48.6165],
            [-93.0517, 48.6199],
            [-92.8367, 48.5678],
            [-92.5005, 48.4354],
            [-92.4147, 48.2766],
            [-92.1718, 48.3384],
            [-92.0052, 48.3018],
            [-91.8584, 48.1976],
            [-91.5183, 48.0583],
            [-91.2207, 48.1046],
            [-91.0434, 48.1938],
            [-90.8402, 48.2005],
            [-90.7444, 48.1046],
            [-90.3201, 48.0991],
            [-90.0918, 48.1181],
            [-89.9936, 48.0152],
            [-89.6338, 47.9937],
            [-89.8288, 47.9009],
            [-90.1972, 47.7864],
            [-90.5975, 47.6878],
            [-90.957, 47.5236],
            [-91.3862, 47.2147],
            [-91.6451, 47.05],
            [-92.0644, 46.8188],
            [-92.107, 46.7623],
            [-92.2917, 46.6607],
            [-92.2926, 46.0843],
            [-92.6938, 45.909],
            [-92.8747, 45.7061],
            [-92.8924, 45.5947],
            [-92.7071, 45.4937],
            [-92.6857, 45.3806],
            [-92.7661, 45.2364],
            [-92.7931, 45.0713],
            [-92.7634, 44.9342],
            [-92.7991, 44.79],
            [-92.6435, 44.6453],
            [-92.3669, 44.5522],
            [-92.2404, 44.4621],
            [-92.0551, 44.3999],
            [-91.8409, 44.1942],
            [-91.3827, 43.9908],
            [-91.2785, 43.7975],
            [-91.2453, 43.5024],
            [-92.2258, 43.5018],
            [-93.5267, 43.5016],
            [-94.5023, 43.5014],
            [-95.478, 43.5013],
            [-96.4536, 43.5012],
            [-96.4536, 44.1747],
            [-96.4536, 45.2973],
            [-96.5298, 45.3715],
            [-96.696, 45.4317],
            [-96.8455, 45.5958],
            [-96.8137, 45.6592],
            [-96.6146, 45.8],
            [-96.5563, 45.9428],
            [-96.5608, 46.1383],
            [-96.6175, 46.3271],
            [-96.7309, 46.4727],
            [-96.7867, 46.6483],
            [-96.7702, 46.9196],
            [-96.8183, 46.9742],
            [-96.8588, 47.5884],
            [-96.988, 47.8204],
            [-97.0074, 47.9255],
            [-97.1259, 48.1735],
            [-97.1439, 48.5367],
            [-97.1281, 48.6811],
            [-97.2257, 48.9932],
            [-95.8242, 48.9932],
            [-95.162, 48.9918],
            [-95.1553, 49.3697],
            [-94.8543, 49.3046],
            [-94.8425, 49.1192],
            [-94.7052, 48.8085],
            [-94.6208, 48.7425],
            [-94.0552, 48.659],
            [-93.8517, 48.6073],
            [-93.7077, 48.5255],
            [-93.4636, 48.5612],
            [-93.3778, 48.6165],
          ],
        ],
      },
      properties: {
        name: "Minnesota",
        id: "US-MN",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MN",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.4419, 40.3794],
            [-91.4998, 40.226],
            [-91.4669, 39.9428],
            [-91.3787, 39.7493],
            [-91.2099, 39.6029],
            [-90.9195, 39.3869],
            [-90.7372, 39.1818],
            [-90.6635, 38.9875],
            [-90.5771, 38.9096],
            [-90.4781, 38.9481],
            [-90.2201, 38.8977],
            [-90.1268, 38.8325],
            [-90.3165, 38.4578],
            [-90.374, 38.3097],
            [-90.299, 38.1739],
            [-90.1737, 38.069],
            [-89.823, 37.8841],
            [-89.6233, 37.7472],
            [-89.5234, 37.6445],
            [-89.462, 37.4342],
            [-89.503, 37.3005],
            [-89.414, 37.1023],
            [-89.2725, 37.08],
            [-89.1543, 36.9921],
            [-89.1225, 36.9116],
            [-89.203, 36.6256],
            [-89.3974, 36.5878],
            [-89.4503, 36.5],
            [-89.4872, 36.5031],
            [-89.5573, 36.5011],
            [-89.5946, 36.2594],
            [-89.6745, 36.2206],
            [-89.6419, 36.1045],
            [-89.7047, 36.0015],
            [-90.3811, 35.9927],
            [-90.303, 36.0993],
            [-90.0758, 36.2967],
            [-90.0744, 36.3719],
            [-90.1623, 36.5009],
            [-91.5548, 36.5009],
            [-92.3904, 36.5009],
            [-93.7829, 36.5009],
            [-94.6184, 36.5009],
            [-94.6181, 37.0009],
            [-94.62, 37.6525],
            [-94.623, 38.5647],
            [-94.6225, 39.1245],
            [-94.7778, 39.194],
            [-94.9525, 39.4264],
            [-95.058, 39.509],
            [-95.0754, 39.5974],
            [-94.8933, 39.7818],
            [-94.9841, 39.8911],
            [-95.1162, 39.8721],
            [-95.3466, 40.0015],
            [-95.4637, 40.1981],
            [-95.6071, 40.2955],
            [-95.765, 40.6018],
            [-94.5063, 40.6087],
            [-93.2451, 40.616],
            [-91.7315, 40.6246],
            [-91.682, 40.5519],
            [-91.4419, 40.3794],
          ],
        ],
      },
      properties: {
        name: "Missouri",
        id: "US-MO",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MO",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.1733, 34.999],
            [-88.0847, 34.9331],
            [-88.1688, 34.292],
            [-88.3397, 32.9988],
            [-88.4862, 31.8904],
            [-88.4443, 31.1397],
            [-88.3996, 30.3709],
            [-88.6921, 30.3554],
            [-88.9051, 30.4151],
            [-89.2237, 30.3323],
            [-89.3206, 30.3453],
            [-89.521, 30.1926],
            [-89.5917, 30.223],
            [-89.6858, 30.4627],
            [-89.82, 30.6112],
            [-89.8354, 30.7308],
            [-89.7353, 31.0011],
            [-91.1769, 31.0012],
            [-91.6565, 31.0018],
            [-91.5963, 31.1099],
            [-91.6307, 31.2176],
            [-91.529, 31.3451],
            [-91.529, 31.4488],
            [-91.4146, 31.6399],
            [-91.3725, 31.7736],
            [-91.2864, 31.884],
            [-91.1566, 31.9707],
            [-91.0887, 32.1042],
            [-90.9477, 32.2401],
            [-90.9447, 32.3307],
            [-91.1038, 32.4905],
            [-91.1424, 32.6611],
            [-91.0961, 32.9376],
            [-91.15, 33.0159],
            [-91.1068, 33.2179],
            [-91.1633, 33.2944],
            [-91.1411, 33.4386],
            [-91.2146, 33.5834],
            [-91.2076, 33.6756],
            [-91.0528, 33.8276],
            [-91.0702, 33.9743],
            [-90.9431, 34.0297],
            [-90.9097, 34.1888],
            [-90.8172, 34.2522],
            [-90.718, 34.3917],
            [-90.5841, 34.5037],
            [-90.5392, 34.7281],
            [-90.3198, 34.8992],
            [-90.2938, 35.0012],
            [-89.2418, 35],
            [-88.1733, 34.999],
          ],
        ],
      },
      properties: {
        name: "Mississippi",
        id: "US-MS",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MS",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.0111, 45.943],
            [-104.0055, 45.001],
            [-104.0372, 45.0011],
            [-105.133, 45.0011],
            [-106.0098, 45.001],
            [-106.8864, 45.001],
            [-107.9824, 45.001],
            [-109.0783, 45.001],
            [-109.7358, 45.001],
            [-111.0508, 45.001],
            [-111.0514, 44.4989],
            [-111.1976, 44.5906],
            [-111.3374, 44.7449],
            [-111.4481, 44.7111],
            [-111.4998, 44.5448],
            [-111.7972, 44.5399],
            [-112.2313, 44.5607],
            [-112.3929, 44.468],
            [-112.7159, 44.498],
            [-112.8156, 44.406],
            [-112.9998, 44.4803],
            [-113.0933, 44.7154],
            [-113.1872, 44.7986],
            [-113.3389, 44.8081],
            [-113.4693, 44.93],
            [-113.4711, 45.0729],
            [-113.697, 45.305],
            [-113.8023, 45.601],
            [-113.9426, 45.6815],
            [-114.2976, 45.4875],
            [-114.5292, 45.5757],
            [-114.4958, 45.6859],
            [-114.5338, 45.7819],
            [-114.4051, 45.8742],
            [-114.4784, 46.0356],
            [-114.4853, 46.1582],
            [-114.3459, 46.5506],
            [-114.3368, 46.6592],
            [-114.6015, 46.6603],
            [-114.7537, 46.7345],
            [-114.9357, 46.9229],
            [-115.3495, 47.2612],
            [-115.7024, 47.4377],
            [-115.6697, 47.4979],
            [-115.7337, 47.7064],
            [-116.0491, 48.0047],
            [-116.0485, 48.993],
            [-115.4379, 48.993],
            [-114.1587, 48.993],
            [-113.306, 48.993],
            [-112.0269, 48.993],
            [-110.3213, 48.993],
            [-109.4684, 48.993],
            [-107.7631, 48.9932],
            [-106.0575, 48.9932],
            [-105.2048, 48.9932],
            [-104.034, 48.9932],
            [-104.025, 47.8499],
            [-104.0195, 47.0872],
            [-104.0111, 45.943],
          ],
        ],
      },
      properties: {
        name: "Montana",
        id: "US-MT",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-MT",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.9664, 36.5514],
            [-75.9927, 36.4738],
            [-75.8666, 36.2679],
            [-75.883, 36.1757],
            [-76.1478, 36.2793],
            [-76.15, 36.1458],
            [-76.3837, 36.1336],
            [-76.4788, 36.0281],
            [-76.6789, 36.0753],
            [-76.7262, 35.9576],
            [-76.3583, 35.9528],
            [-76.2065, 35.9912],
            [-76.0698, 35.9704],
            [-76.0835, 35.6905],
            [-76.0012, 35.7222],
            [-75.979, 35.8959],
            [-75.8538, 35.9601],
            [-75.7722, 35.8999],
            [-75.7447, 35.7655],
            [-75.774, 35.6469],
            [-76.1739, 35.3542],
            [-76.4895, 35.3971],
            [-76.5326, 35.5084],
            [-76.7414, 35.4315],
            [-76.513, 35.2704],
            [-76.628, 35.0733],
            [-76.7791, 34.9902],
            [-76.745, 34.9409],
            [-76.4568, 34.9894],
            [-76.3621, 34.9365],
            [-76.5168, 34.7773],
            [-76.7071, 34.7521],
            [-76.7332, 34.7069],
            [-77.1339, 34.7079],
            [-77.2518, 34.6156],
            [-77.3583, 34.6203],
            [-77.3797, 34.5266],
            [-77.7508, 34.285],
            [-77.926, 34.0732],
            [-78.0134, 33.9118],
            [-78.4058, 33.9175],
            [-78.5643, 33.8767],
            [-78.9784, 34.2261],
            [-79.6535, 34.7968],
            [-80.7841, 34.8286],
            [-80.793, 34.9628],
            [-80.9337, 35.1136],
            [-81.0451, 35.0657],
            [-81.0874, 35.1556],
            [-82.356, 35.2015],
            [-83.1149, 35.0015],
            [-84.3247, 34.988],
            [-84.287, 35.2009],
            [-84.2324, 35.2605],
            [-84.0623, 35.286],
            [-84.0038, 35.4141],
            [-83.8712, 35.5149],
            [-83.5112, 35.5841],
            [-83.1204, 35.7746],
            [-82.9902, 35.8004],
            [-82.8878, 35.9517],
            [-82.8128, 35.9473],
            [-82.6111, 36.0474],
            [-82.5211, 35.9812],
            [-82.3682, 36.0996],
            [-82.1826, 36.1539],
            [-82.0513, 36.1229],
            [-81.9082, 36.3065],
            [-81.7342, 36.3554],
            [-81.6589, 36.6106],
            [-81.6416, 36.5951],
            [-80.1944, 36.5837],
            [-79.471, 36.5781],
            [-78.3855, 36.5697],
            [-76.9385, 36.5585],
            [-75.9664, 36.5514],
          ],
        ],
      },
      properties: {
        name: "North Carolina",
        id: "US-NC",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NC",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.5563, 45.9428],
            [-98.1871, 45.9428],
            [-99.8179, 45.9429],
            [-100.7496, 45.9429],
            [-101.9144, 45.9429],
            [-103.3122, 45.943],
            [-104.0111, 45.943],
            [-104.0195, 47.0872],
            [-104.025, 47.8499],
            [-104.034, 48.9932],
            [-103.0728, 48.9932],
            [-102.22, 48.9932],
            [-100.5145, 48.9932],
            [-99.2354, 48.9932],
            [-97.9562, 48.9932],
            [-97.2257, 48.9932],
            [-97.1281, 48.6811],
            [-97.1439, 48.5367],
            [-97.1259, 48.1735],
            [-97.0074, 47.9255],
            [-96.988, 47.8204],
            [-96.8588, 47.5884],
            [-96.8183, 46.9742],
            [-96.7702, 46.9196],
            [-96.7867, 46.6483],
            [-96.7309, 46.4727],
            [-96.6175, 46.3271],
            [-96.5608, 46.1383],
            [-96.5563, 45.9428],
          ],
        ],
      },
      properties: {
        name: "North Dakota",
        id: "US-ND",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-ND",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.4809, 42.5113],
            [-96.3837, 42.4177],
            [-96.2954, 42.1329],
            [-96.1543, 41.9652],
            [-96.0688, 41.7343],
            [-96.0586, 41.5458],
            [-95.9329, 41.4254],
            [-95.8836, 41.2876],
            [-95.8341, 40.8895],
            [-95.859, 40.7451],
            [-95.765, 40.6018],
            [-95.6071, 40.2955],
            [-95.4637, 40.1981],
            [-95.3466, 40.0015],
            [-96.7845, 40.0011],
            [-97.8325, 40.0011],
            [-99.2997, 40.0011],
            [-100.7669, 40.0011],
            [-102.0245, 40.0011],
            [-102.025, 41.0006],
            [-103.273, 41.0007],
            [-104.0217, 41.0009],
            [-104.0252, 42.0012],
            [-104.0288, 43.0013],
            [-103.3366, 43.0013],
            [-101.9522, 43.0013],
            [-100.9139, 43.0013],
            [-99.5294, 43.0012],
            [-98.4417, 43.0011],
            [-98.1432, 42.8523],
            [-97.965, 42.8058],
            [-97.6981, 42.8746],
            [-97.4166, 42.8811],
            [-97.2388, 42.8605],
            [-97.0198, 42.7601],
            [-96.8045, 42.7015],
            [-96.6328, 42.5686],
            [-96.4809, 42.5113],
          ],
        ],
      },
      properties: {
        name: "Nebraska",
        id: "US-NE",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NE",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.7331, 43.07],
            [-70.8062, 42.8767],
            [-70.974, 42.8716],
            [-71.3296, 42.7024],
            [-72.4669, 42.7303],
            [-72.5499, 42.8867],
            [-72.4736, 43.0385],
            [-72.4071, 43.3319],
            [-72.385, 43.5293],
            [-72.2967, 43.7149],
            [-72.1149, 43.9655],
            [-72.0313, 44.3007],
            [-71.683, 44.4503],
            [-71.5685, 44.6076],
            [-71.6206, 44.7719],
            [-71.5102, 44.9083],
            [-71.5175, 45.0076],
            [-71.419, 45.2004],
            [-71.3273, 45.2901],
            [-71.2017, 45.2603],
            [-71.0846, 45.294],
            [-71.0314, 44.5297],
            [-70.9557, 43.3894],
            [-70.829, 43.2391],
            [-70.7331, 43.07],
          ],
        ],
      },
      properties: {
        name: "New Hampshire",
        id: "US-NH",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NH",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9101, 40.9922],
            [-74.0256, 40.7564],
            [-74.2267, 40.608],
            [-74.2415, 40.4563],
            [-74.0498, 40.4299],
            [-73.9576, 40.3284],
            [-74.0283, 40.073],
            [-74.1177, 39.9382],
            [-74.1761, 39.7266],
            [-74.3899, 39.4869],
            [-74.4288, 39.3872],
            [-74.603, 39.2926],
            [-74.7944, 39.0019],
            [-74.9543, 38.9499],
            [-74.8971, 39.1455],
            [-75.1361, 39.2078],
            [-75.3535, 39.3399],
            [-75.5243, 39.4902],
            [-75.5235, 39.6019],
            [-75.4219, 39.7897],
            [-75.1538, 39.8706],
            [-75.0741, 39.9834],
            [-74.7349, 40.1545],
            [-75.0975, 40.5431],
            [-75.1889, 40.5958],
            [-75.1748, 40.7756],
            [-75.075, 40.8844],
            [-75.123, 40.9991],
            [-74.9123, 41.1553],
            [-74.8414, 41.2687],
            [-74.699, 41.3572],
            [-73.9101, 40.9922],
          ],
        ],
      },
      properties: {
        name: "New Jersey",
        id: "US-NJ",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NJ",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.0002, 36.5012],
            [-103.0422, 36.5006],
            [-103.048, 35.3761],
            [-103.0539, 34.2517],
            [-103.0582, 33.4084],
            [-103.0613, 32.8461],
            [-103.0656, 32.0027],
            [-103.9648, 32.0023],
            [-105.0886, 32.0019],
            [-105.9879, 32.0015],
            [-106.6683, 32.001],
            [-106.5664, 31.8196],
            [-106.4454, 31.7684],
            [-107.3326, 31.7747],
            [-108.2119, 31.7794],
            [-108.2145, 31.3295],
            [-109.0478, 31.328],
            [-109.0473, 32.3922],
            [-109.0472, 33.4558],
            [-109.0469, 34.5194],
            [-109.0469, 35.5829],
            [-109.0468, 36.2918],
            [-109.0467, 37.0009],
            [-107.9131, 37.0009],
            [-106.7795, 37.0009],
            [-106.0238, 37.0009],
            [-104.8901, 37.0009],
            [-103.7566, 37.0009],
            [-103.0008, 37.0009],
            [-103.0002, 36.5012],
          ],
        ],
      },
      properties: {
        name: "New Mexico",
        id: "US-NM",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NM",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.0402, 37.0042],
            [-114.0429, 36.1817],
            [-114.1833, 36.0303],
            [-114.4061, 36.1484],
            [-114.6691, 36.1218],
            [-114.7414, 36.0136],
            [-114.6871, 35.9173],
            [-114.6452, 35.6305],
            [-114.6485, 35.4759],
            [-114.5909, 35.3528],
            [-114.6105, 34.9912],
            [-115.3901, 35.5991],
            [-115.8978, 35.9955],
            [-116.6596, 36.5899],
            [-117.2097, 37.0033],
            [-118.1262, 37.6924],
            [-118.5948, 38.0193],
            [-119.2974, 38.5098],
            [-120.0001, 39.0002],
            [-120.0001, 39.5627],
            [-120.0006, 40.6879],
            [-120.0008, 42.0005],
            [-119.2547, 42.0005],
            [-118.1357, 42.0005],
            [-117.0166, 42.0005],
            [-115.9014, 42.0008],
            [-115.1578, 42.0009],
            [-114.0426, 42.001],
            [-114.042, 40.908],
            [-114.0418, 40.1273],
            [-114.0412, 39.1903],
            [-114.0408, 37.9411],
            [-114.0402, 37.0042],
          ],
        ],
      },
      properties: {
        name: "Nevada",
        id: "US-NV",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NV",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-72.5098, 40.9861],
              [-72.2874, 41.024],
              [-72.339, 40.8942],
              [-73.1943, 40.6542],
              [-73.2655, 40.6635],
              [-73.6209, 40.5999],
              [-73.7667, 40.5926],
              [-73.8228, 40.656],
              [-74.032, 40.6387],
              [-73.8793, 40.7916],
              [-73.5739, 40.9195],
              [-73.3727, 40.9438],
              [-73.1858, 40.9298],
              [-73.0338, 40.966],
              [-72.5098, 40.9861],
            ],
          ],
          [
            [
              [-73.2533, 42.7522],
              [-73.5072, 42.08],
              [-73.4806, 42.0556],
              [-73.5447, 41.296],
              [-73.4841, 41.2189],
              [-73.7231, 41.1045],
              [-73.6305, 40.9918],
              [-73.8513, 40.8314],
              [-73.9485, 40.8388],
              [-73.9101, 40.9922],
              [-74.699, 41.3572],
              [-74.7566, 41.4239],
              [-74.9354, 41.4743],
              [-75.0227, 41.5529],
              [-75.0537, 41.765],
              [-75.1219, 41.8534],
              [-75.2388, 41.8922],
              [-75.3513, 41.9984],
              [-76.4541, 41.999],
              [-77.9707, 41.9998],
              [-78.66, 42.0002],
              [-79.763, 42.0009],
              [-79.763, 42.2757],
              [-79.2092, 42.5551],
              [-78.8778, 42.7991],
              [-78.9197, 42.9481],
              [-78.9082, 43.0703],
              [-79.0661, 43.1061],
              [-79.0532, 43.285],
              [-78.6895, 43.3614],
              [-78.1749, 43.3946],
              [-77.8381, 43.3572],
              [-77.529, 43.2674],
              [-77.2708, 43.2945],
              [-76.9764, 43.2775],
              [-76.6929, 43.3609],
              [-76.4728, 43.5073],
              [-76.2027, 43.5745],
              [-76.1984, 43.8745],
              [-76.0987, 43.967],
              [-76.2378, 44.1832],
              [-75.8322, 44.3972],
              [-75.792, 44.4971],
              [-75.4012, 44.7723],
              [-75.1795, 44.8994],
              [-74.8565, 45.0039],
              [-73.3522, 45.0054],
              [-73.372, 44.5973],
              [-73.3098, 44.4596],
              [-73.3209, 44.2689],
              [-73.425, 44.0383],
              [-73.3757, 43.7622],
              [-73.4133, 43.6279],
              [-73.2471, 43.553],
              [-73.2666, 42.8648],
              [-73.2533, 42.7522],
            ],
          ],
        ],
      },
      properties: {
        name: "New York",
        id: "US-NY",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-NY",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.519, 40.647],
            [-80.6591, 40.5629],
            [-80.6018, 40.3639],
            [-80.6683, 40.1853],
            [-80.8722, 39.7355],
            [-80.9633, 39.5711],
            [-81.3334, 39.3628],
            [-81.4396, 39.3926],
            [-81.6571, 39.272],
            [-81.8026, 39.0861],
            [-81.7778, 38.9728],
            [-82.0722, 38.9629],
            [-82.2034, 38.7682],
            [-82.18, 38.6376],
            [-82.387, 38.4339],
            [-82.6129, 38.4482],
            [-82.7582, 38.5412],
            [-82.8805, 38.7102],
            [-83.0001, 38.7141],
            [-83.2035, 38.6389],
            [-83.357, 38.6274],
            [-83.5384, 38.6998],
            [-83.6898, 38.6495],
            [-83.9484, 38.7743],
            [-84.1133, 38.7984],
            [-84.2272, 38.8652],
            [-84.2904, 38.9747],
            [-84.43, 39.0909],
            [-84.5946, 39.0858],
            [-84.7069, 39.1289],
            [-84.8216, 39.092],
            [-84.8133, 40.0745],
            [-84.803, 41.0506],
            [-84.7961, 41.7014],
            [-83.4643, 41.7394],
            [-83.4542, 41.7456],
            [-82.9108, 41.5382],
            [-82.8308, 41.5797],
            [-82.5243, 41.407],
            [-82.1213, 41.493],
            [-81.7232, 41.511],
            [-81.4471, 41.6714],
            [-81.2286, 41.7704],
            [-80.8692, 41.8933],
            [-80.5202, 41.9895],
            [-80.5202, 41.2769],
            [-80.519, 40.647],
          ],
        ],
      },
      properties: {
        name: "Ohio",
        id: "US-OH",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-OH",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-94.6184, 36.5009],
            [-94.4391, 35.3872],
            [-94.4616, 34.5178],
            [-94.4841, 33.6485],
            [-94.7091, 33.6994],
            [-94.9624, 33.8433],
            [-95.238, 33.9565],
            [-95.3739, 33.8743],
            [-95.6228, 33.9303],
            [-95.7839, 33.8645],
            [-95.9416, 33.8828],
            [-96.2785, 33.7576],
            [-96.4111, 33.7752],
            [-96.5917, 33.8492],
            [-96.7464, 33.8542],
            [-96.9777, 33.938],
            [-97.1724, 33.7568],
            [-97.2149, 33.8971],
            [-97.4302, 33.8385],
            [-97.6604, 33.9901],
            [-97.8424, 33.8757],
            [-98.0695, 34.0404],
            [-98.0987, 34.145],
            [-98.3559, 34.1414],
            [-98.4938, 34.0888],
            [-98.6085, 34.1612],
            [-98.7989, 34.1547],
            [-98.9688, 34.2086],
            [-99.1866, 34.2355],
            [-99.1999, 34.3183],
            [-99.3603, 34.4561],
            [-99.405, 34.392],
            [-99.6321, 34.3885],
            [-99.867, 34.5434],
            [-99.9995, 34.5867],
            [-100.0005, 35.3045],
            [-100.0023, 36.5009],
            [-101.314, 36.5011],
            [-102.0636, 36.5012],
            [-103.0002, 36.5012],
            [-103.0008, 37.0009],
            [-102.0128, 37.0009],
            [-101.0885, 37.0009],
            [-99.9329, 37.0009],
            [-99.0086, 37.0009],
            [-98.0844, 37.0009],
            [-97.1601, 37.0009],
            [-96.4668, 37.0009],
            [-95.5424, 37.0009],
            [-94.6181, 37.0009],
            [-94.6184, 36.5009],
          ],
        ],
      },
      properties: {
        name: "Oklahoma",
        id: "US-OK",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-OK",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.2206, 46.1537],
            [-123.1209, 46.1787],
            [-122.942, 46.1148],
            [-122.8434, 45.9781],
            [-122.7259, 45.6737],
            [-122.6498, 45.6275],
            [-122.2026, 45.591],
            [-122.0851, 45.6026],
            [-121.882, 45.6895],
            [-121.6625, 45.7182],
            [-121.4021, 45.7118],
            [-121.1843, 45.6437],
            [-121.0451, 45.639],
            [-120.3514, 45.7251],
            [-119.9343, 45.8379],
            [-119.4292, 45.9343],
            [-119.1773, 45.9451],
            [-119.0197, 46.0003],
            [-118.2062, 46.0013],
            [-116.8965, 46.0021],
            [-116.7647, 45.873],
            [-116.6168, 45.7992],
            [-116.4771, 45.6411],
            [-116.5492, 45.4567],
            [-116.8396, 44.9851],
            [-116.9489, 44.7701],
            [-117.1189, 44.5783],
            [-117.2017, 44.4362],
            [-117.1398, 44.2903],
            [-116.9531, 44.2453],
            [-116.9113, 44.1574],
            [-117.0205, 43.8132],
            [-117.019, 43.1165],
            [-117.0166, 42.0005],
            [-118.1357, 42.0005],
            [-119.2547, 42.0005],
            [-120.0008, 42.0005],
            [-121.0554, 42.0005],
            [-122.3737, 42.0005],
            [-123.4283, 42.0005],
            [-124.2284, 42.0008],
            [-124.3553, 42.1228],
            [-124.4205, 42.381],
            [-124.4062, 42.5837],
            [-124.5396, 42.8128],
            [-124.4544, 43.0123],
            [-124.3466, 43.3415],
            [-124.288, 43.4097],
            [-124.1487, 43.6918],
            [-124.0991, 44.3338],
            [-124.0474, 44.4255],
            [-124.0592, 44.7778],
            [-123.9486, 45.4008],
            [-123.9294, 45.5771],
            [-123.9612, 45.8429],
            [-123.9471, 46.1406],
            [-123.9117, 46.1822],
            [-123.6737, 46.1826],
            [-123.5217, 46.2226],
            [-123.4023, 46.155],
            [-123.2206, 46.1537],
          ],
        ],
      },
      properties: {
        name: "Oregon",
        id: "US-OR",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-OR",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.699, 41.3572],
            [-74.8414, 41.2687],
            [-74.9123, 41.1553],
            [-75.123, 40.9991],
            [-75.075, 40.8844],
            [-75.1748, 40.7756],
            [-75.1889, 40.5958],
            [-75.0975, 40.5431],
            [-74.7349, 40.1545],
            [-75.0741, 39.9834],
            [-75.1729, 39.8948],
            [-75.4211, 39.8154],
            [-75.6346, 39.8394],
            [-75.7847, 39.7223],
            [-76.708, 39.7225],
            [-78.0926, 39.7226],
            [-79.4776, 39.7227],
            [-80.5194, 39.7226],
            [-80.519, 40.647],
            [-80.5202, 41.2769],
            [-80.5202, 41.9895],
            [-80.3344, 42.0408],
            [-79.763, 42.2757],
            [-79.763, 42.0009],
            [-78.66, 42.0002],
            [-77.9707, 41.9998],
            [-76.4541, 41.999],
            [-75.3513, 41.9984],
            [-75.2388, 41.8922],
            [-75.1219, 41.8534],
            [-75.0537, 41.765],
            [-75.0227, 41.5529],
            [-74.9354, 41.4743],
            [-74.7566, 41.4239],
            [-74.699, 41.3572],
          ],
        ],
      },
      properties: {
        name: "Pennsylvania",
        id: "US-PA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-PA",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.2338, 41.7065],
            [-71.3306, 41.7622],
            [-71.4265, 41.6333],
            [-71.4437, 41.4536],
            [-71.5229, 41.3789],
            [-71.8423, 41.3354],
            [-71.7958, 41.5199],
            [-71.8009, 42.0119],
            [-71.3871, 42.0169],
            [-71.3407, 41.7978],
            [-71.2338, 41.7065],
          ],
        ],
      },
      properties: {
        name: "Rhode Island",
        id: "US-RI",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-RI",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.5643, 33.8767],
            [-78.8414, 33.724],
            [-79.1382, 33.4059],
            [-79.1937, 33.2441],
            [-79.276, 33.1354],
            [-79.42, 33.0425],
            [-79.5871, 33.0009],
            [-79.615, 32.9093],
            [-79.8936, 32.7287],
            [-80.0217, 32.6199],
            [-80.2297, 32.5765],
            [-80.4743, 32.4228],
            [-80.4857, 32.3517],
            [-80.6083, 32.2928],
            [-80.6472, 32.3959],
            [-80.7653, 32.2984],
            [-80.6942, 32.2157],
            [-80.8492, 32.114],
            [-80.8724, 32.0295],
            [-81.0748, 32.1097],
            [-81.1351, 32.1818],
            [-81.1714, 32.3801],
            [-81.2903, 32.5573],
            [-81.377, 32.6075],
            [-81.4897, 32.9356],
            [-81.5783, 33.0686],
            [-81.6994, 33.1267],
            [-81.9329, 33.3899],
            [-82.0057, 33.5229],
            [-82.2084, 33.6636],
            [-82.2566, 33.7493],
            [-82.5894, 34.0176],
            [-82.8187, 34.3661],
            [-82.8478, 34.4369],
            [-83.0528, 34.5109],
            [-83.3557, 34.7083],
            [-83.3167, 34.8057],
            [-83.1149, 35.0015],
            [-82.356, 35.2015],
            [-81.0874, 35.1556],
            [-81.0451, 35.0657],
            [-80.9337, 35.1136],
            [-80.793, 34.9628],
            [-80.7841, 34.8286],
            [-79.6535, 34.7968],
            [-78.9784, 34.2261],
            [-78.5643, 33.8767],
          ],
        ],
      },
      properties: {
        name: "South Carolina",
        id: "US-SC",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-SC",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.5563, 45.9428],
            [-96.6146, 45.8],
            [-96.8137, 45.6592],
            [-96.8455, 45.5958],
            [-96.696, 45.4317],
            [-96.5298, 45.3715],
            [-96.4536, 45.2973],
            [-96.4536, 44.1747],
            [-96.4536, 43.5012],
            [-96.5985, 43.4976],
            [-96.5416, 43.3735],
            [-96.5555, 43.2317],
            [-96.4627, 43.0992],
            [-96.6218, 42.7296],
            [-96.5388, 42.6587],
            [-96.4809, 42.5113],
            [-96.6328, 42.5686],
            [-96.8045, 42.7015],
            [-97.0198, 42.7601],
            [-97.2388, 42.8605],
            [-97.4166, 42.8811],
            [-97.6981, 42.8746],
            [-97.965, 42.8058],
            [-98.1432, 42.8523],
            [-98.4417, 43.0011],
            [-99.5294, 43.0012],
            [-100.9139, 43.0013],
            [-101.9522, 43.0013],
            [-103.3366, 43.0013],
            [-104.0288, 43.0013],
            [-104.031, 43.5013],
            [-104.0351, 44.5012],
            [-104.0372, 45.0011],
            [-104.0055, 45.001],
            [-104.0111, 45.943],
            [-103.3122, 45.943],
            [-101.9144, 45.9429],
            [-100.7496, 45.9429],
            [-99.8179, 45.9429],
            [-98.1871, 45.9428],
            [-96.5563, 45.9428],
          ],
        ],
      },
      properties: {
        name: "South Dakota",
        id: "US-SD",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-SD",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.6676, 36.605],
            [-82.9558, 36.6021],
            [-81.9525, 36.598],
            [-81.6589, 36.6106],
            [-81.7342, 36.3554],
            [-81.9082, 36.3065],
            [-82.0513, 36.1229],
            [-82.1826, 36.1539],
            [-82.3682, 36.0996],
            [-82.5211, 35.9812],
            [-82.6111, 36.0474],
            [-82.8128, 35.9473],
            [-82.8878, 35.9517],
            [-82.9902, 35.8004],
            [-83.1204, 35.7746],
            [-83.5112, 35.5841],
            [-83.8712, 35.5149],
            [-84.0038, 35.4141],
            [-84.0623, 35.286],
            [-84.2324, 35.2605],
            [-84.287, 35.2009],
            [-84.3247, 34.988],
            [-85.6235, 35.0009],
            [-86.9071, 35.0129],
            [-88.1905, 35.0249],
            [-88.1733, 34.999],
            [-89.2418, 35],
            [-90.2938, 35.0012],
            [-90.1423, 35.1142],
            [-90.0929, 35.2036],
            [-90.066, 35.4141],
            [-89.9674, 35.5033],
            [-89.9436, 35.6791],
            [-89.7796, 35.8057],
            [-89.7037, 35.9069],
            [-89.7047, 36.0015],
            [-89.6419, 36.1045],
            [-89.6745, 36.2206],
            [-89.5946, 36.2594],
            [-89.5573, 36.5011],
            [-89.4872, 36.5031],
            [-89.4503, 36.5],
            [-88.0591, 36.4997],
            [-88.0964, 36.693],
            [-87.8392, 36.6439],
            [-86.5076, 36.6648],
            [-85.8133, 36.6453],
            [-84.4247, 36.6062],
            [-83.7304, 36.5868],
            [-83.6676, 36.605],
          ],
        ],
      },
      properties: {
        name: "Tennessee",
        id: "US-TN",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-TN",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-97.1708, 26.1594],
              [-97.2673, 26.3299],
              [-97.4021, 26.8205],
              [-97.3512, 26.8014],
              [-97.1708, 26.1594],
            ],
          ],
          [
            [
              [-97.0144, 27.9017],
              [-96.8992, 28.1174],
              [-96.8397, 28.0889],
              [-97.0144, 27.9017],
            ],
          ],
          [
            [
              [-94.4841, 33.6485],
              [-94.3778, 33.5661],
              [-94.2947, 33.5875],
              [-94.0472, 33.5544],
              [-94.0413, 33.012],
              [-94.0434, 31.9992],
              [-93.905, 31.877],
              [-93.8269, 31.7503],
              [-93.8206, 31.604],
              [-93.7376, 31.5138],
              [-93.6659, 31.3226],
              [-93.5577, 31.1804],
              [-93.5306, 31.0462],
              [-93.5792, 30.8238],
              [-93.7202, 30.5583],
              [-93.7504, 30.3448],
              [-93.7106, 30.1128],
              [-93.794, 29.9772],
              [-93.8414, 29.9798],
              [-93.9462, 29.815],
              [-93.8905, 29.6894],
              [-94.0996, 29.6704],
              [-94.7501, 29.418],
              [-94.6053, 29.5677],
              [-94.7782, 29.5478],
              [-94.7243, 29.6552],
              [-94.7419, 29.75],
              [-94.8323, 29.7526],
              [-94.8899, 29.6769],
              [-95.0229, 29.7024],
              [-94.9928, 29.531],
              [-94.8883, 29.3705],
              [-95.139, 29.1679],
              [-95.1522, 29.0792],
              [-95.2735, 28.9638],
              [-95.8534, 28.6404],
              [-96.0204, 28.5869],
              [-96.2753, 28.6552],
              [-96.4488, 28.5944],
              [-96.5756, 28.7156],
              [-96.6401, 28.7088],
              [-96.5246, 28.4887],
              [-96.5618, 28.3671],
              [-96.6764, 28.3413],
              [-96.7736, 28.4217],
              [-96.8068, 28.2202],
              [-96.8916, 28.1576],
              [-96.9667, 28.1895],
              [-97.1565, 28.1444],
              [-97.0731, 27.9862],
              [-97.1714, 27.8796],
              [-97.3741, 27.87],
              [-97.4316, 27.8372],
              [-97.2888, 27.6706],
              [-97.3804, 27.4194],
              [-97.4391, 27.3282],
              [-97.5238, 27.314],
              [-97.6821, 27.3949],
              [-97.6924, 27.2872],
              [-97.4852, 27.2373],
              [-97.4757, 27.1179],
              [-97.5547, 26.9673],
              [-97.4658, 26.6917],
              [-97.4024, 26.3965],
              [-97.1462, 25.9614],
              [-97.2818, 25.9417],
              [-97.3582, 25.8705],
              [-97.5872, 25.9843],
              [-97.8015, 26.042],
              [-98.0828, 26.0645],
              [-98.275, 26.1113],
              [-98.4859, 26.2245],
              [-98.5983, 26.2378],
              [-98.7652, 26.3404],
              [-99.0153, 26.399],
              [-99.1077, 26.4468],
              [-99.1721, 26.5641],
              [-99.2299, 26.7619],
              [-99.3025, 26.8848],
              [-99.4436, 27.0366],
              [-99.4403, 27.1701],
              [-99.5101, 27.3403],
              [-99.5053, 27.5483],
              [-99.7543, 27.73],
              [-99.8896, 27.8673],
              [-100.0014, 28.0479],
              [-100.1119, 28.1729],
              [-100.296, 28.3277],
              [-100.3317, 28.5026],
              [-100.5497, 28.8214],
              [-100.6586, 29.0686],
              [-100.7546, 29.1825],
              [-101.0164, 29.4007],
              [-101.039, 29.4605],
              [-101.3035, 29.6341],
              [-101.4405, 29.7768],
              [-101.9909, 29.7958],
              [-102.269, 29.8712],
              [-102.4763, 29.769],
              [-102.6149, 29.7523],
              [-102.7342, 29.644],
              [-102.834, 29.444],
              [-102.8919, 29.2164],
              [-103.0227, 29.1322],
              [-103.0901, 29.0419],
              [-103.2576, 29.0011],
              [-103.423, 29.0707],
              [-103.664, 29.207],
              [-104.1107, 29.3862],
              [-104.3122, 29.5425],
              [-104.4007, 29.5738],
              [-104.5041, 29.6777],
              [-104.6223, 29.8543],
              [-104.6812, 29.9905],
              [-104.6812, 30.1343],
              [-104.8359, 30.4476],
              [-104.9788, 30.6459],
              [-105.2758, 30.8073],
              [-105.514, 30.9808],
              [-105.8126, 31.241],
              [-106.0241, 31.3978],
              [-106.148, 31.4508],
              [-106.2558, 31.5446],
              [-106.4454, 31.7684],
              [-106.5664, 31.8196],
              [-106.6683, 32.001],
              [-105.9879, 32.0015],
              [-105.0886, 32.0019],
              [-103.9648, 32.0023],
              [-103.0656, 32.0027],
              [-103.0613, 32.8461],
              [-103.0582, 33.4084],
              [-103.0539, 34.2517],
              [-103.048, 35.3761],
              [-103.0422, 36.5006],
              [-103.0002, 36.5012],
              [-102.0636, 36.5012],
              [-101.314, 36.5011],
              [-100.0023, 36.5009],
              [-100.0005, 35.3045],
              [-99.9995, 34.5867],
              [-99.867, 34.5434],
              [-99.6321, 34.3885],
              [-99.405, 34.392],
              [-99.3603, 34.4561],
              [-99.1999, 34.3183],
              [-99.1866, 34.2355],
              [-98.9688, 34.2086],
              [-98.7989, 34.1547],
              [-98.6085, 34.1612],
              [-98.4938, 34.0888],
              [-98.3559, 34.1414],
              [-98.0987, 34.145],
              [-98.0695, 34.0404],
              [-97.8424, 33.8757],
              [-97.6604, 33.9901],
              [-97.4302, 33.8385],
              [-97.2149, 33.8971],
              [-97.1724, 33.7568],
              [-96.9777, 33.938],
              [-96.7464, 33.8542],
              [-96.5917, 33.8492],
              [-96.4111, 33.7752],
              [-96.2785, 33.7576],
              [-95.9416, 33.8828],
              [-95.7839, 33.8645],
              [-95.6228, 33.9303],
              [-95.3739, 33.8743],
              [-95.238, 33.9565],
              [-94.9624, 33.8433],
              [-94.7091, 33.6994],
              [-94.4841, 33.6485],
            ],
          ],
        ],
      },
      properties: {
        name: "Texas",
        id: "US-TX",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-TX",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.0503, 42.0009],
            [-111.05, 41.0009],
            [-109.7978, 41.0009],
            [-109.0464, 41.0009],
            [-109.0465, 40.0008],
            [-109.0465, 39.2509],
            [-109.0465, 38.0009],
            [-109.0467, 37.0009],
            [-109.9829, 37.0014],
            [-111.2313, 37.0022],
            [-111.8556, 37.0026],
            [-113.104, 37.0035],
            [-114.0402, 37.0042],
            [-114.0408, 37.9411],
            [-114.0412, 39.1903],
            [-114.0418, 40.1273],
            [-114.042, 40.908],
            [-114.0426, 42.001],
            [-112.7333, 42.001],
            [-111.9855, 42.0009],
            [-111.0503, 42.0009],
          ],
        ],
      },
      properties: {
        name: "Utah",
        id: "US-UT",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-UT",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-77.7266, 39.3465],
              [-77.4789, 39.2209],
              [-77.4809, 39.113],
              [-77.3012, 39.0533],
              [-77.1221, 38.9435],
              [-77.0303, 38.8893],
              [-77.0456, 38.7758],
              [-77.2603, 38.6],
              [-77.3136, 38.3966],
              [-77.2319, 38.34],
              [-77.0468, 38.3566],
              [-76.9062, 38.197],
              [-76.5496, 38.0946],
              [-76.4717, 38.0112],
              [-76.2642, 37.8935],
              [-76.3056, 37.7216],
              [-76.484, 37.6289],
              [-76.3055, 37.5714],
              [-76.2635, 37.357],
              [-76.4009, 37.3862],
              [-76.4011, 37.2127],
              [-76.2833, 37.0527],
              [-76.4008, 36.9913],
              [-76.6719, 37.173],
              [-76.6339, 37.0475],
              [-76.3996, 36.8899],
              [-76.2442, 36.9526],
              [-75.9994, 36.9126],
              [-75.8575, 36.5506],
              [-75.8891, 36.5504],
              [-75.9664, 36.5514],
              [-76.9385, 36.5585],
              [-78.3855, 36.5697],
              [-79.471, 36.5781],
              [-80.1944, 36.5837],
              [-81.6416, 36.5951],
              [-81.6589, 36.6106],
              [-81.9525, 36.598],
              [-82.9558, 36.6021],
              [-83.6676, 36.605],
              [-83.1502, 36.7624],
              [-82.8945, 36.9064],
              [-82.7319, 37.048],
              [-82.6816, 37.1381],
              [-82.3431, 37.2969],
              [-81.9652, 37.5396],
              [-81.913, 37.369],
              [-81.8293, 37.2986],
              [-81.5648, 37.2097],
              [-81.35, 37.3337],
              [-81.2272, 37.2633],
              [-80.9437, 37.3018],
              [-80.8211, 37.4231],
              [-80.7199, 37.401],
              [-80.3161, 37.5097],
              [-80.2459, 37.6208],
              [-80.2853, 37.677],
              [-80.1881, 37.8241],
              [-79.9717, 38.0461],
              [-79.9028, 38.1807],
              [-79.7153, 38.404],
              [-79.6687, 38.5443],
              [-79.5977, 38.5771],
              [-79.3958, 38.4545],
              [-79.2402, 38.4766],
              [-79.0412, 38.7896],
              [-78.8687, 38.8179],
              [-78.5815, 38.999],
              [-78.4352, 39.1608],
              [-78.3223, 39.4519],
              [-77.8358, 39.1452],
              [-77.7266, 39.3465],
            ],
          ],
          [
            [
              [-75.6592, 37.954],
              [-75.62, 37.9993],
              [-75.376, 38.025],
              [-75.5963, 37.6312],
              [-75.5871, 37.5587],
              [-75.812, 37.4251],
              [-75.9343, 37.152],
              [-75.9974, 37.2639],
              [-75.975, 37.3985],
              [-75.8882, 37.6191],
              [-75.7193, 37.8214],
              [-75.6592, 37.954],
            ],
          ],
        ],
      },
      properties: {
        name: "Virginia",
        id: "US-VA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-VA",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.4669, 42.7303],
            [-73.2533, 42.7522],
            [-73.2666, 42.8648],
            [-73.2471, 43.553],
            [-73.4133, 43.6279],
            [-73.3757, 43.7622],
            [-73.425, 44.0383],
            [-73.3209, 44.2689],
            [-73.3098, 44.4596],
            [-73.372, 44.5973],
            [-73.3522, 45.0054],
            [-72.766, 45.0061],
            [-71.5175, 45.0076],
            [-71.5102, 44.9083],
            [-71.6206, 44.7719],
            [-71.5685, 44.6076],
            [-71.683, 44.4503],
            [-72.0313, 44.3007],
            [-72.1149, 43.9655],
            [-72.2967, 43.7149],
            [-72.385, 43.5293],
            [-72.4071, 43.3319],
            [-72.4736, 43.0385],
            [-72.5499, 42.8867],
            [-72.4669, 42.7303],
          ],
        ],
      },
      properties: {
        name: "Vermont",
        id: "US-VT",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-VT",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-116.8965, 46.0021],
              [-118.2062, 46.0013],
              [-119.0197, 46.0003],
              [-119.1773, 45.9451],
              [-119.4292, 45.9343],
              [-119.9343, 45.8379],
              [-120.3514, 45.7251],
              [-121.0451, 45.639],
              [-121.1843, 45.6437],
              [-121.4021, 45.7118],
              [-121.6625, 45.7182],
              [-121.882, 45.6895],
              [-122.0851, 45.6026],
              [-122.2026, 45.591],
              [-122.6498, 45.6275],
              [-122.7259, 45.6737],
              [-122.8434, 45.9781],
              [-122.942, 46.1148],
              [-123.1209, 46.1787],
              [-123.2206, 46.1537],
              [-123.4649, 46.2712],
              [-123.6884, 46.2998],
              [-123.8957, 46.2677],
              [-124.0727, 46.2795],
              [-124.0502, 46.4906],
              [-123.9461, 46.4326],
              [-123.8891, 46.66],
              [-124.0717, 46.7448],
              [-124.1126, 46.8627],
              [-123.8429, 46.9632],
              [-124.0422, 47.0297],
              [-124.1635, 47.0153],
              [-124.1989, 47.2085],
              [-124.3093, 47.4046],
              [-124.376, 47.6587],
              [-124.46, 47.7843],
              [-124.6211, 47.9042],
              [-124.7017, 48.1517],
              [-124.6326, 48.375],
              [-123.9758, 48.1685],
              [-123.2944, 48.1196],
              [-123.1244, 48.151],
              [-123.0242, 48.0816],
              [-122.8609, 48.0901],
              [-122.7398, 48.0133],
              [-122.6567, 47.8811],
              [-122.8214, 47.7931],
              [-122.7571, 47.7005],
              [-122.5879, 47.8559],
              [-122.5239, 47.7694],
              [-122.6184, 47.7129],
              [-122.5536, 47.4049],
              [-122.5779, 47.2931],
              [-122.8125, 47.3289],
              [-122.9195, 47.2897],
              [-122.9876, 47.1726],
              [-122.7019, 47.1109],
              [-122.5422, 47.2756],
              [-122.3538, 47.3716],
              [-122.3684, 47.6039],
              [-122.4105, 47.6527],
              [-122.3928, 47.8205],
              [-122.242, 48.0108],
              [-122.353, 48.1138],
              [-122.494, 48.1304],
              [-122.5203, 48.2291],
              [-122.4086, 48.2939],
              [-122.669, 48.4653],
              [-122.4967, 48.5056],
              [-122.562, 48.778],
              [-122.653, 48.7639],
              [-122.7888, 48.993],
              [-121.4072, 48.993],
              [-119.7018, 48.993],
              [-117.9962, 48.993],
              [-117.039, 48.993],
              [-117.0317, 47.7115],
              [-117.025, 46.4291],
              [-116.943, 46.2321],
              [-116.8965, 46.0021],
            ],
          ],
          [
            [
              [-122.5728, 48.1567],
              [-122.3667, 47.9855],
              [-122.5575, 47.9925],
              [-122.5728, 48.1567],
            ],
          ],
        ],
      },
      properties: {
        name: "Washington",
        id: "US-WA",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-WA",
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-90.4077, 46.593],
              [-90.2304, 46.5098],
              [-90.1185, 46.3416],
              [-89.1193, 46.1491],
              [-88.8374, 46.0365],
              [-88.4931, 46.0135],
              [-88.1518, 45.9454],
              [-88.1161, 45.8158],
              [-87.9225, 45.76],
              [-87.8091, 45.6861],
              [-87.8152, 45.5059],
              [-87.8653, 45.3678],
              [-87.6964, 45.3829],
              [-87.721, 45.2427],
              [-87.6059, 45.1085],
              [-87.627, 45.0323],
              [-87.8302, 44.9424],
              [-88.0282, 44.6179],
              [-87.929, 44.5595],
              [-87.741, 44.6921],
              [-87.6044, 44.8469],
              [-87.4197, 44.8657],
              [-87.3374, 44.7758],
              [-87.4866, 44.5086],
              [-87.5275, 44.3857],
              [-87.5349, 44.1743],
              [-87.6584, 44.059],
              [-87.7238, 43.9073],
              [-87.6997, 43.7237],
              [-87.774, 43.5989],
              [-87.9017, 43.2449],
              [-87.8935, 43.0411],
              [-87.787, 42.8092],
              [-87.8121, 42.4971],
              [-89.2905, 42.5052],
              [-90.6506, 42.5129],
              [-90.6879, 42.6104],
              [-90.7887, 42.6769],
              [-90.9599, 42.7204],
              [-91.0695, 42.7885],
              [-91.1614, 43.1023],
              [-91.1172, 43.3311],
              [-91.2187, 43.3953],
              [-91.2453, 43.5024],
              [-91.2785, 43.7975],
              [-91.3827, 43.9908],
              [-91.8409, 44.1942],
              [-92.0551, 44.3999],
              [-92.2404, 44.4621],
              [-92.3669, 44.5522],
              [-92.6435, 44.6453],
              [-92.7991, 44.79],
              [-92.7634, 44.9342],
              [-92.7931, 45.0713],
              [-92.7661, 45.2364],
              [-92.6857, 45.3806],
              [-92.7071, 45.4937],
              [-92.8924, 45.5947],
              [-92.8747, 45.7061],
              [-92.6938, 45.909],
              [-92.2926, 46.0843],
              [-92.2917, 46.6607],
              [-92.107, 46.7623],
              [-91.9874, 46.699],
              [-91.8356, 46.7021],
              [-91.4991, 46.7662],
              [-91.2177, 46.8856],
              [-91.1272, 46.8676],
              [-90.9461, 46.9538],
              [-90.7684, 46.908],
              [-90.923, 46.6031],
              [-90.7368, 46.6888],
              [-90.5361, 46.5994],
              [-90.4077, 46.593],
            ],
          ],
          [
            [
              [-86.9919, 45.241],
              [-87.0589, 45.1025],
              [-87.2804, 44.8349],
              [-87.3965, 44.9541],
              [-87.2587, 45.138],
              [-87.122, 45.2245],
              [-86.9919, 45.241],
            ],
          ],
        ],
      },
      properties: {
        name: "Wisconsin",
        id: "US-WI",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-WI",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.4776, 39.7227],
            [-79.488, 39.2109],
            [-79.2935, 39.3115],
            [-79.0752, 39.4761],
            [-78.9714, 39.4537],
            [-78.8148, 39.5701],
            [-78.4956, 39.5334],
            [-78.4068, 39.6279],
            [-78.1812, 39.6857],
            [-77.8832, 39.6107],
            [-77.7981, 39.5172],
            [-77.7266, 39.3465],
            [-77.8358, 39.1452],
            [-78.3223, 39.4519],
            [-78.4352, 39.1608],
            [-78.5815, 38.999],
            [-78.8687, 38.8179],
            [-79.0412, 38.7896],
            [-79.2402, 38.4766],
            [-79.3958, 38.4545],
            [-79.5977, 38.5771],
            [-79.6687, 38.5443],
            [-79.7153, 38.404],
            [-79.9028, 38.1807],
            [-79.9717, 38.0461],
            [-80.1881, 37.8241],
            [-80.2853, 37.677],
            [-80.2459, 37.6208],
            [-80.3161, 37.5097],
            [-80.7199, 37.401],
            [-80.8211, 37.4231],
            [-80.9437, 37.3018],
            [-81.2272, 37.2633],
            [-81.35, 37.3337],
            [-81.5648, 37.2097],
            [-81.8293, 37.2986],
            [-81.913, 37.369],
            [-81.9652, 37.5396],
            [-82.1035, 37.5706],
            [-82.2802, 37.6872],
            [-82.5887, 38.0998],
            [-82.5784, 38.272],
            [-82.6129, 38.4482],
            [-82.387, 38.4339],
            [-82.18, 38.6376],
            [-82.2034, 38.7682],
            [-82.0722, 38.9629],
            [-81.7778, 38.9728],
            [-81.8026, 39.0861],
            [-81.6571, 39.272],
            [-81.4396, 39.3926],
            [-81.3334, 39.3628],
            [-80.9633, 39.5711],
            [-80.8722, 39.7355],
            [-80.6683, 40.1853],
            [-80.6018, 40.3639],
            [-80.6591, 40.5629],
            [-80.519, 40.647],
            [-80.5194, 39.7226],
            [-79.4776, 39.7227],
          ],
        ],
      },
      properties: {
        name: "West Virginia",
        id: "US-WV",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-WV",
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.0288, 43.0013],
            [-104.0252, 42.0012],
            [-104.0217, 41.0009],
            [-104.9638, 41.0009],
            [-105.906, 41.0009],
            [-107.1622, 41.0009],
            [-108.1044, 41.0009],
            [-109.0464, 41.0009],
            [-109.7978, 41.0009],
            [-111.05, 41.0009],
            [-111.0503, 42.0009],
            [-111.0508, 43.0938],
            [-111.051, 43.7182],
            [-111.0514, 44.4989],
            [-111.0508, 45.001],
            [-109.7358, 45.001],
            [-109.0783, 45.001],
            [-107.9824, 45.001],
            [-106.8864, 45.001],
            [-106.0098, 45.001],
            [-105.133, 45.0011],
            [-104.0372, 45.0011],
            [-104.0351, 44.5012],
            [-104.031, 43.5013],
            [-104.0288, 43.0013],
          ],
        ],
      },
      properties: {
        name: "Wyoming",
        id: "US-WY",
        CNTRY: "United States of America",
        TYPE: "State",
      },
      id: "US-WY",
    },
  ],
};
