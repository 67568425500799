<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="`Offer Letter Template | ${model.name ?? ''}`"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <a-button type="primary" :loading="renderingPdf" @click="renderPdf">
          <a-space> <i class="ti ti-file-text ti-lg"></i> Preview </a-space>
        </a-button>
      </template>
    </a-page-header>

    <template v-if="showTemplate">
      <a-card :loading="loading" size="small">
        <a-form
          :model="model"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit(false)"
        >
          <a-row :gutter="[20, 0]">
            <a-col :span="24">
              <div class="mb-sm-1">
                <template v-for="tempVar in templateVariables" :key="tempVar">
                  <a-tooltip title="Click here for copy">
                    <span
                      @click="textCopy(tempVar)"
                      class="bg-light-danger mr-sm-1 cursor-pointer"
                    >
                      {{ tempVar }}
                    </span>
                  </a-tooltip>
                </template>
              </div>

              <a-form-item
                ref="offer_letter_template"
                name="offer_letter_template"
              >
                <ckeditor
                  :editor="editor"
                  v-model="model.offer_letter_template"
                  :config="editorConfig"
                />
              </a-form-item>

              <template v-for="tempVar in templateVariables" :key="tempVar">
                <a-tooltip title="Click here for copy">
                  <span
                    @click="textCopy(tempVar)"
                    class="bg-light-danger mr-sm-1 cursor-pointer"
                  >
                    {{ tempVar }}
                  </span>
                </a-tooltip>
              </template>
            </a-col>
          </a-row>

          <a-space>
            <a-button
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
            >
              Save
            </a-button>

            <a-button
              html-type="button"
              type="primary"
              :loading="renderingPdf || submitBtnLoader"
              @click="onSubmit(true)"
            >
              Save & Preview
            </a-button>
          </a-space>
        </a-form>
      </a-card>
    </template>

    <template v-else>
      <a-row type="flex" justify="center">
        <a-col :xs="24" :lg="12">
          <a-card size="small">
            <a-form
              :model="masterModel"
              layout="vertical"
              @finish="onMasterSubmit"
            >
              <a-form-item
                label="Master Password"
                name="master_password"
                :rules="{
                  required: true,
                  message: 'This field is required.',
                }"
              >
                <a-input-password
                  v-model:value="masterModel.master_password"
                  placeholder="Enter master password here"
                />
              </a-form-item>

              <a-button
                type="primary"
                html-type="submit"
                :loading="submitMasterBtnLoader"
              >
                Submit
              </a-button>
            </a-form>
          </a-card>
        </a-col>
      </a-row>
    </template>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

export default {
  data() {
    return {
      showTemplate: false,
      submitMasterBtnLoader: false,
      masterModel: {
        master_password: "",
      },
      loading: true,
      model: {
        id: this.$route.params.id,
        offer_letter_template: "",
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
      },
      submitBtnLoader: false,
      renderingPdf: false,

      rules: {
        offer_letter_template: {
          required: true,
          message: "This field is required!",
        },
      },

      templateVariables: [
        "{{ start_date }}",
        "{{ offer_date }}",
        "{{ offer_expiry_date }}",
        "{{ full_name }}",
        "{{ first_name }}",
        "{{ last_name }}",
        "{{ compensation }}",
        "{{ compensation_uom }}",
        "{{ representative_title }}",
        "{{ representative_full_name }}",
        // "{{ candidate_sign }}",
        // "{{ candidate_sign_timestamp }}",
        // "{{ representative_sign }}",
        // "{{ representative_sign_timestamp }}",
        "{{ pagebreak }}",
      ],
    };
  },

  computed: {
    //
  },

  mounted() {
    this.getEmployeePosition();
  },

  methods: {
    getEmployeePosition() {
      commonService
        .get(this.$constants.employeePositionShowUrl, { id: this.model.id })
        .then((res) => {
          this.model = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "employee-positions" });
        })
        .finally(() => (this.loading = false));
    },

    onSubmit(renderPdf = false) {
      this.submitBtnLoader = true;
      commonService
        .store(
          this.$constants.employeePositionStoreOfferLetterTemplateUrl,
          this.model
        )
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          this.submitBtnLoader = false;
          if (renderPdf) {
            this.renderPdf();
          }
          // this.$router.push({ name: "employee-positions" });
        });
    },

    renderPdf() {
      this.renderingPdf = true;

      commonService
        .renderFile(
          this.$constants.employeePositionOfferLetterPdfUrl,
          { id: this.model.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.renderingPdf = false;
        });
    },

    textCopy(text) {
      toClipboard(text);
      this.$message.success(`${text} copied successfully.`);
    },

    onMasterSubmit() {
      this.submitMasterBtnLoader = true;
      commonService
        .store(this.$constants.checkMasterPasswrodSettingUrl, this.masterModel)
        .then((res) => {
          this.$message.success(res.message);
          this.showTemplate = true;
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.submitMasterBtnLoader = false;
        });
    },
  },
};
</script>
