<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          <template v-if="step == 1 && personId == null">
            Create New Employee
          </template>
          <template v-else>
            Update Onboarding Employee Details
          </template>
        </template>
        <template #buttons>
          <back-button
            :listeners="true"
            @handleClick="$router.push({ name: 'employee' })"
          />
        </template>
      </page-header>
      <employee-personal-info
        v-if="companies && step == 1"
        :companies="companies"
        :rules="employeeFormRules.piRules"
        :model="piForm"
        @callback="handleCallback"
        :personId="personId"
      />
      <employee-address-info
        v-if="step == 2"
        :rules="employeeFormRules.aiRules"
        :model="aiForm"
        @callback="handleCallback"
        :personId="personId"
      />
      <employee-position-info
        v-if="step == 3"
        :rules="employeeFormRules.poiRules"
        :model="poiForm"
        @callback="handleCallback"
        :personId="personId"
      />
      <employee-employment-info
        v-if="step == 4"
        :rules="employeeFormRules.eiRules"
        :model="eiForm"
        @callback="handleCallback"
        :personId="personId"
      />
      <employee-pay-info
        v-if="step == 5"
        :rules="employeeFormRules.payiRules"
        :model="payiForm"
        @callback="handleCallback"
        :personId="personId"
      />
    </a-layout-content>
  </div>
</template>
<script>
import moment from "moment";
import { employeeFormRules } from "../../helper/formRules.js";
import backButton from "../../components/backButton.vue";
import employeePersonalInfo from "../../components/employee/personal.vue";
import employeeAddressInfo from "../../components/employee/address.vue";
import employeePositionInfo from "../../components/employee/position.vue";
import employeeEmploymentInfo from "../../components/employee/employment.vue";
import employeePayInfo from "../../components/employee/pay.vue";
import { companyService } from "@/services";
import router from "../../router/index.js";
import { commonService } from "../../services/common.service.js";

export default {
  components: {
    backButton,
    employeePersonalInfo,
    employeeAddressInfo,
    employeePositionInfo,
    employeeEmploymentInfo,
    employeePayInfo,
  },
  data() {
    return {
      // step: 1,
      companies: {},
      routes: {},
      roles: {},
      employeeFormRules,
      piForm: {
        companyId: null,
        employeeStatus: null,
        insperityHireDate: "",
        clientHireDate: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        dateOfBirth: "",
        gender: "",
        ssn: "",
        cssn: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
      },
      aiForm: {
        workOption: null,
        workAddress: "",
        workCity: "",
        workState: "",
        workZip: "",
        reportingOption: null,
        reportingAddress: "",
        reportingCity: "",
        reportingState: "",
        reportingZip: "",
        deliveryOption: null,
        deliveryAddress: "",
        deliveryCity: "",
        deliveryState: "",
        deliveryZip: "",
      },
      poiForm: {
        workersCompensationCode: null,
        jobFunctionID: null,
        jobTitle: "",
        supervisesOthers: "",
        supervisorName: "",
      },
      eiForm: {
        isUnionMember: "",
        employeeAgreementCategory: null,
        ownerStatus: "",
        employeeJobCategory: null,
        workEmail: "",
        workPhone: "",
        willWorkMultiStates: "",
        willWorkInternational: "",
        willTravelInternational: "",
      },
      payiForm: {
        classification: null,
        temporaryIndicator: "",
        isExemptFromOvertime: "",
        isPaidTips: "",
        isPaidPiecework: "",
        employeePaid: [],
        isAllowanceProvided: "",
        adminEmail: "",
        employeeEmail: "",
      },
      onboardingPerson: {},
    };
  },
  computed: {
    step() {
      return this.$route.query.step;
    },
    personId() {
      if (this.$route.params.onboardingPersonId) {
        return this.$route.params.onboardingPersonId;
      } else {
        return null;
      }
    },
  },
  created() {
    this.getCompanies();
    this.get_routes();
    this.get_roles();
    this.getEmployee();
  },
  methods: {
    getCompanies() {
      companyService.store(this.$constants.getCompaniesUrl).then((res) => {
        if (res.success) {
          this.companies = res.data;
        }
      });
    },
    get_routes() {
      companyService.store(this.$constants.getAccessRoutesUrl).then((res) => {
        if (res.success) {
          this.routes = res.data;
        }
      });
    },
    get_roles() {
      companyService.store(this.$constants.getRolesUrl).then((res) => {
        if (res.success) {
          this.roles = res.data;
        }
      });
    },
    getEmployee() {
      if (this.personId != null) {
        commonService
          .get(this.$constants.onboardingEmployeeUrl, {
            onboardingPersonId: this.personId,
          })
          .then((res) => {
            if (res.success) {
              this.onboardingPerson = res.data;
              this.updateFormModal();
            } else {
              router.push({ name: "employee" });
            }
          });
      }
    },
    handleCallback(
      employeeIndex,
      pushToStep = +1,
      onboardingPersonId = null,
      res = null
    ) {
      console.log(onboardingPersonId, res);
      if (employeeIndex) {
        router.push({ name: "employee" });
      } else {
        let step = parseInt(this.step) + parseInt(pushToStep);
        if (step > 5) {
          router.push({ name: "employee" });
        }
        if (res != null) {
          this.onboardingPerson = res;
          this.updateFormModal();
        }
        router.push({
          name: "onborading-person",
          query: { step: step },
          params: { onboardingPersonId: onboardingPersonId },
        });
      }
    },
    updateFormModal() {
      // Person info form value update
      this.piForm.companyId = this.onboardingPerson.employerIdentifier;
      this.piForm.employeeStatus = this.onboardingPerson.person.employeeStatus;
      this.piForm.clientHireDate = moment(
        this.onboardingPerson.employment.hireDate
      );
      this.piForm.insperityHireDate = moment(
        this.onboardingPerson.employment.insperityHireDate
      );
      this.piForm.firstName = this.onboardingPerson.person.firstName;
      this.piForm.middleName = this.onboardingPerson.person.middleName;
      this.piForm.lastName = this.onboardingPerson.person.lastName;
      this.piForm.email = this.onboardingPerson.person.email;
      this.piForm.dateOfBirth = moment(
        this.onboardingPerson.person.dateOfBirth
      );
      this.piForm.gender = this.onboardingPerson.person.gender;
      this.piForm.ssn = this.onboardingPerson.person.ssn;
      this.piForm.cssn = this.onboardingPerson.person.ssn;

      let homeAddress = this.onboardingPerson.communication.addresses.find(
        (e) => e.useCode == "Home"
      );
      this.piForm.streetAddress = homeAddress.addressLine;
      this.piForm.city = homeAddress.city;
      this.piForm.state = homeAddress.state;
      this.piForm.zipCode = homeAddress.postalCode;

      // Address form value update
      let workAddress = this.onboardingPerson.communication.addresses.find(
        (e) => e.useCode == "Work"
      );
      if (workAddress) {
        this.aiForm.workOption = workAddress.option;
        this.aiForm.workAddress = workAddress.addressLine;
        this.aiForm.workCity = workAddress.city;
        this.aiForm.workState = workAddress.state;
        this.aiForm.workZip = workAddress.postalCode;
      }
      let reportingAddress = this.onboardingPerson.communication.addresses.find(
        (e) => e.useCode == "Reporting"
      );
      if (reportingAddress) {
        this.aiForm.reportingOption = reportingAddress.option;
        this.aiForm.reportingAddress = reportingAddress.addressLine;
        this.aiForm.reportingCity = reportingAddress.city;
        this.aiForm.reportingState = reportingAddress.state;
        this.aiForm.reportingZip = reportingAddress.postalCode;
      }
      let deliveryAddress = this.onboardingPerson.communication.addresses.find(
        (e) => e.useCode == "Delivery"
      );
      if (deliveryAddress) {
        this.aiForm.deliveryOption = deliveryAddress.option;
        this.aiForm.deliveryAddress = deliveryAddress.addressLine;
        this.aiForm.deliveryCity = deliveryAddress.city;
        this.aiForm.deliveryState = deliveryAddress.state;
        this.aiForm.deliveryZip = deliveryAddress.postalCode;
      }

      // Position form value update
      this.poiForm.workersCompensationCode = this.onboardingPerson.position.workersCompensationCode;
      this.poiForm.jobFunctionID = this.onboardingPerson.position.jobFunctionID;
      this.poiForm.jobTitle = this.onboardingPerson.position.jobTitle;
      this.poiForm.supervisesOthers = this.onboardingPerson.position.supervisesOthers;
      this.poiForm.supervisorName = this.onboardingPerson.position.supervisorID;

      // Employment form value update
      this.eiForm.isUnionMember = this.onboardingPerson.employment.isUnionMember;
      this.eiForm.employeeAgreementCategory = this.onboardingPerson.employment.employeeAgreementCategory;
      this.eiForm.ownerStatus = this.onboardingPerson.employment.ownerStatus;
      this.eiForm.employeeJobCategory = this.onboardingPerson.employment.jobCategoryID;
      this.eiForm.willWorkMultiStates = this.onboardingPerson.employment.willWorkMultiStates;
      this.eiForm.willWorkInternational = this.onboardingPerson.employment.willWorkInternational;
      this.eiForm.willTravelInternational = this.onboardingPerson.employment.willTravelInternational;
      let workEmails = this.onboardingPerson.communication.emails.find(
        (e) => e.useCode == "Work"
      );
      if (workEmails) {
        this.eiForm.workEmail = workEmails.emailAddress;
      }
      let workPhones = this.onboardingPerson.communication.phones.find(
        (e) => e.useCode == "Work"
      );
      if (workPhones) {
        this.eiForm.workPhone = workPhones.number;
      }

      // Pay form value update
      this.payiForm.classification = this.onboardingPerson.compensation.classification;
      this.payiForm.temporaryIndicator = this.onboardingPerson.compensation.temporaryIndicator;
      this.payiForm.isExemptFromOvertime = this.onboardingPerson.compensation.isExemptFromOvertime;
      this.payiForm.isPaidTips = this.onboardingPerson.compensation.isPaidTips;
      this.payiForm.isPaidPiecework = this.onboardingPerson.compensation.isPaidPiecework;
      this.payiForm.isAllowanceProvided = this.onboardingPerson.compensation.isAllowanceProvided;
      if (this.onboardingPerson.compensation.isPaidSalary == "Yes") {
        this.payiForm.employeePaid.push("isPaidSalary");
      }
      if (this.onboardingPerson.compensation.isPaidHourly == "Yes") {
        this.payiForm.employeePaid.push("isPaidHourly");
      }
      if (this.onboardingPerson.compensation.isPaidCommission == "Yes") {
        this.payiForm.employeePaid.push("isPaidCommission");
      }
      this.payiForm.adminEmail = this.onboardingPerson.adminEmail;
      let homeEmails = this.onboardingPerson.communication.emails.find(
        (e) => e.useCode == "Home"
      );
      if (homeEmails) {
        this.payiForm.employeeEmail = homeEmails.emailAddress;
      }
    },
  },
};
</script>
