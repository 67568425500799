<template>
  <div>
    <a-layout-content>
      <a-card
        size="small"
        class="page-header"
        v-if="loading"
        :loading="loading"
      />

      <a-page-header
        v-else
        class="page-header"
        style="margin-bottom: 24px; background-color: #fff"
        @back="() => $router.go(-1)"
      >
        <template #title>
          {{ hospital?.name ?? "N/A" }}
        </template>

        <template #tags>
          <a-tag v-if="hospital && hospital.status == 1" color="success">
            Active
          </a-tag>
          <a-tag v-if="hospital && hospital.status == 2" color="error">
            Inactive
          </a-tag>
        </template>

        <template #extra>
          <a-button
            type="primary"
            @click="
              open({
                title: 'Edit Hospital',
                path: 'hospital.store',
                callback: getHospitalDetails,
                record: hospital,
                extra: { companies, classes, accounts, customers },
              })
            "
          >
            <a-space size="small">
              <i class="ti ti-pencil ti-lg"></i> Edit
            </a-space>
          </a-button>
        </template>

        <template #footer>
          <a-tabs
            v-model:activeKey="activeTabKey"
            style="margin-bottom: 0"
            @tabChange="(key) => (activeTabKey = key)"
          >
            <a-tab-pane v-for="tab in tabList" :key="tab.key" :tab="tab.tab" />
          </a-tabs>
        </template>

        <a-row v-if="hospital" class="content" type="flex" :gutter="[16, 16]">
          <a-col>
            <a-space>
              <i class="ti ti-building ti-lg"></i>
              <template v-for="(v, i) in hospital.companies" :key="i">
                {{ v.name }}
              </template>
            </a-space>
          </a-col>

          <a-col v-if="hospital.email">
            <a-space>
              <i class="ti ti-mail ti-lg"></i>
              <a-typography-link
                :href="hospital.email ? `mailto:${hospital.email}` : '#'"
              >
                {{ hospital.email ?? "N/A" }}
              </a-typography-link>
            </a-space>
          </a-col>

          <a-col v-if="hospital.contact_no">
            <a-space>
              <i class="ti ti-device-mobile ti-lg"></i>
              <a-typography-link
                :href="hospital.contact_no ? `tel:${hospital.contact_no}` : '#'"
              >
                {{ hospital.contact_no ?? "N/A" }}
              </a-typography-link>
            </a-space>
          </a-col>

          <a-col>
            <a-space>
              <i class="ti ti-map-pin ti-lg"></i>
              {{ hospital.address_line_1 ? `${hospital.address_line_1},` : "" }}
              {{ hospital.address_line_2 ? `${hospital.address_line_2},` : "" }}
              {{ hospital.city ? `${hospital.city},` : "" }}
              {{ hospital.state ? `${hospital.state},` : "" }}
              {{ hospital.zipcode ? `${hospital.zipcode}` : "" }}
            </a-space>
          </a-col>
        </a-row>
      </a-page-header>

      <component v-if="is" :is="is" v-bind="{ hospital }">
        <router-view />
      </component>
    </a-layout-content>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { route } from "../../helper/route";
import { commonService, companyService } from "../../services";

export default {
  data() {
    return {
      loading: true,
      hospital: null,
      companies: [],
      classes: [],
      accounts: [],
      customers: [],
      hospital_id: this.$route.params.id,
      activeTabKey: "overview",
    };
  },

  mounted() {
    this.getQuickbooksData();
    this.getCompanies();
    this.getHospitalDetails();
  },

  computed: {
    ...mapGetters("auth", ["hasFinanceAccess"]),

    is() {
      if (this.activeTabKey != "") {
        return route(`hospital/showTabs/${this.activeTabKey}`);
      } else {
        return "";
      }
    },

    tabList() {
      const tabs = [
        {
          key: "overview",
          tab: "Overview",
        },
        // analytics
        {
          key: "services",
          tab: "Services",
        },
        {
          key: "billingTypes",
          tab: "Monthly Billing",
        },
        {
          key: "products",
          tab: "Products",
        },
        {
          key: "assets",
          tab: "Assets",
        },
        {
          key: "templates",
          tab: "Templates",
        },
        {
          key: "personnel",
          tab: "Personnel",
        },
        {
          key: "onboarding",
          tab: "Onboarding",
        },
        {
          key: "contract",
          tab: "Contract",
        },
        {
          key: "case_reporting",
          tab: "Case Reporting",
        },
        {
          key: "expense",
          tab: "Expense",
        },
        {
          key: "orders",
          tab: "Orders",
        },
        {
          key: "timesheet",
          tab: "Timesheet",
        },
        {
          key: "ftp",
          tab: "FTP/SFTP",
        },
        {
          key: "notifications",
          tab: "Notifications",
        },
      ];
      if (!this.hasFinanceAccess) return tabs;

      tabs.splice(1, 0, {
        key: "analytics",
        tab: "Analytics",
      });

      return tabs;
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getHospitalDetails() {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalDetailUrl, {
          id: this.hospital_id,
          with_companies: true,
        })
        .then((res) => {
          this.hospital = res.data;
          this.loading = false;
        });
    },

    getCompanies() {
      companyService.store(this.$constants.getCompaniesUrl).then((res) => {
        if (res.success) {
          this.companies = res.data;
        }
      });
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, {
          names: ["classes", "accounts", "customers"],
        })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "classes") {
                this.classes = JSON.parse(v.data);
              }
              if (v.name == "accounts") {
                this.accounts = JSON.parse(v.data);
              }
              if (v.name == "customers") {
                this.customers = JSON.parse(v.data);
              }
            });
          }
        });
    },
  },
};
</script>
