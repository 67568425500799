<template>
  <a-button
    size="small"
    type="primary"
    ghost
    htmlType="button"
    @click="fetchDataFromADP"
    :loading="fetching"
  >
    <a-space>
      <i class="ti ti-refresh ti-1x"></i>
      Fetch from ADP
    </a-space>
  </a-button>

  <a-form v-if="!fetching" layout="vertical" class="mt-1">
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :lg="12">
        <a-form-item label="Workers">
          <a-select
            placeholder="Workers list"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="name in workers"
              :key="name"
              :label="name"
              :value="name"
            >
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Departments">
          <a-select
            placeholder="Departments list"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="name in departments"
              :key="name"
              :label="name"
              :value="name"
            >
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Jobs">
          <a-select
            placeholder="Jobs list"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="name in jobs"
              :key="name"
              :label="name"
              :value="name"
            >
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { message } from "ant-design-vue";
import { route } from "../../../helper/route";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      fetching: false,
      data: [],
    };
  },

  computed: {
    comp() {
      return route("admin/setting/" + this.activeTabKey + ".vue");
    },
  },

  mounted() {
    this.getAdpData();
  },

  computed: {
    workers() {
      return (
        this.data
          .find((e) => e.name == "workers")
          ?.data?.map((e) => e?.person?.legalName?.formattedName ?? "") ?? []
      );
    },

    jobs() {
      return (
        this.data
          .find((e) => e.name == "jobs")
          ?.data?.map((e) => `${e.name} | ${e.code}`) ?? []
      );
    },

    departments() {
      return (
        this.data
          .find((e) => e.name == "departments")
          ?.data?.map((e) => `${e.nameCode.name} | ${e.nameCode.code}` ?? "") ??
        []
      );
    },
  },

  methods: {
    getAdpData() {
      this.fetching = true;
      commonService
        .get(this.$constants.adpGetDataUrl, {
          name: ["workers", "jobs", "departments"],
        })
        .then((res) => {
          if (res.success) {
            this.data = res.data;
          }
        })
        .finally(() => (this.fetching = false));
    },

    fetchDataFromADP() {
      this.fetching = true;
      commonService
        .store(this.$constants.adpFetchDataUrl)
        .then((res) => {
          if (res.success) {
            message.success(res.message);
            this.data = res.data;
          }
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>
