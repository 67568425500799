<template>
  <a-col :span="6">
    <a-typography-text strong>QI Reports</a-typography-text>
    <br />
    <a-typography-text type="secondary">
      Cell Saver, Open Heart and NRP QI reports send
    </a-typography-text>
    <br />
    <a-tag v-if="formDataSetting?.is_enable" color="success">Active</a-tag>
    <a-tag v-else color="error">Inactive</a-tag>
  </a-col>

  <a-col :span="18">
    <a-modal
      v-model:visible="edit"
      title="QI Report Notification Setting"
      width="650px"
      :footer="null"
    >
      <a-form
        layout="vertical"
        :model="formData"
        :rules="rules"
        @finish="onSubmit"
        @reset="updateFormData()"
      >
        <a-form-item label="Emails" ref="emails" name="emails">
          <a-select
            v-model:value="formData.emails"
            mode="tags"
            style="width: 100%"
            placeholder="Enter emails here..."
            @change="handleChange"
          >
          </a-select>
        </a-form-item>

        <a-form-item ref="frequency" label="Frequency" name="frequency">
          <a-select
            v-model:value="formData.frequency"
            placeholder="Select One..."
            optionFilterProp="label"
            showSearch
            :options="$constants.qi_report_frequency_types"
          />
        </a-form-item>

        <a-space direction="vertical">
          <a-checkbox v-model:checked="formData.is_enable">
            Do you want to enable this notification?
          </a-checkbox>

          <a-space>
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loading"
              size="small"
            >
              <a-space>
                <i v-if="!loading" class="ti ti-check ti-lg"></i>
                Submit
              </a-space>
            </a-button>

            <a-button
              v-if="!loading"
              type="primary"
              danger
              htmlType="reset"
              size="small"
            >
              <a-space> <i class="ti ti-x ti-lg"></i> Cancel </a-space>
            </a-button>
          </a-space>
        </a-space>
      </a-form>
    </a-modal>

    <template v-if="!edit">
      <div>
        <strong>Emails:</strong>
        {{ formDataSetting?.emails?.emails?.join(", ") ?? "" }}
        <br />
        <strong>Frequency:</strong>
        {{
          $comman.getValueFromObject(
            formDataSetting?.emails ?? {},
            "frequency",
            $constants.qi_report_frequency_types,
            "label"
          )
        }}
        <br />
        <br />
        <a-button type="primary" size="small" @click="updateFormData(true)">
          <a-space> <i class="ti ti-pencil ti-lg"></i> Edit </a-space>
        </a-button>
      </div>
    </template>
  </a-col>
</template>

<script>
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { commonService } from "../../../services";

export default {
  name: "qi-report-notification-setting",

  props: {
    notificationSettings: {
      default: {
        type: 3,
      },
    },
    hospitalId: { required: true },
  },

  emits: ["updateSettings"],

  data() {
    return {
      edit: false,
      loading: false,
      formData: {
        type: 3,
        emails: [],
        frequency: undefined,
        is_enable: false,
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "",
      },
      rules: {
        emails: [
          {
            required: true,
            message: "This field is required!",
            type: "array",
          },
        ],
        frequency: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
          },
        ],
      },
    };
  },

  mounted() {
    this.updateFormData();
  },

  computed: {
    formDataSetting() {
      return this.notificationSettings.find(
        (item) => item.type == this.formData.type
      );
    },
  },

  methods: {
    updateFormData(bool = false) {
      this.edit = bool;
      this.formData = {
        type: 3,
        hospital_id: this.hospitalId,
        emails: this.formDataSetting?.emails?.emails ?? [],
        frequency: this.formDataSetting?.emails?.frequency ?? undefined,
        is_enable: this.formDataSetting?.is_enable == 1 ? true : false,
      };
    },

    onSubmit() {
      this.loading = true;
      commonService
        .store(this.$constants.hospitalStoreNotificationUrl, this.formData)
        .then((res) => {
          this.$emit("updateSettings", res.data);
          setTimeout(() => {
            this.loading = false;
            this.updateFormData();
          }, 1000);
        })
        .catch((err) => this.$message.error(err.message));
    },

    handleChange(val) {
      if (val.length > 0) {
        this.rules.emails[0].fields = [];
        for (let index = 0; index < val.length; index++) {
          this.rules.emails[0].fields.push({
            type: "email",
            message: `Invalid email!`,
          });
        }
      } else {
        this.rules.emails[0].fields = [];
      }
    },
  },
};
</script>

<style scoped lang="less">
.mail-content:deep {
  p {
    margin-bottom: 0;
  }
}
</style>
