<template>
  <a-divider orientation="left">CPB Live Charting</a-divider>

  <a-card size="small" bordered>
    <a-row :gutter="[0, 0]">
      <template
        v-for="(sides, index) in [
          $constants.cpbPreByPassChecklist.filter((_, i) => i <= 4),
          $constants.cpbPreByPassChecklist.filter((_, i) => i > 4),
        ]"
        :key="index"
      >
        <a-col :span="12">
          <template v-for="(group, index) in sides" :key="group.name">
            <p class="mb-sm-0" :class="index != 0 ? 'mt-sm-1' : ''">
              <a-row type="flex">
                <a-col flex="35px"> N/A </a-col>
                <a-col flex="35px"><CheckOutlined /></a-col>
                <a-col flex="calc(100% - 70px)">
                  <strong>{{ group.name }}</strong>
                </a-col>
              </a-row>
            </p>

            <template v-for="option in group.checklist" :key="option">
              <a-row type="flex">
                <a-col flex="35px">
                  <i
                    v-if="cpb.checklist?.not_applicable?.includes(option)"
                    class="ti ti-checkbox ti-lg"
                  />
                  <i v-else class="ti ti-square ti-lg"></i>
                </a-col>
                <a-col flex="35px">
                  <i
                    v-if="cpb.checklist?.checked?.includes(option)"
                    class="ti ti-checkbox ti-lg"
                  />
                  <i v-else class="ti ti-square ti-lg"></i>
                </a-col>
                <a-col flex="calc(100% - 70px)">{{ option }}</a-col>
              </a-row>
            </template>
          </template>
        </a-col>
      </template>
    </a-row>

    <a-divider class="mt-sm-1 mb-sm-1" />

    <strong>Reported At:</strong>
    {{ cpb.checklist?.submitted_at ?? "N/A" }}
  </a-card>

  <a-table
    class="mt-sm-1"
    :rowKey="(_, index) => index"
    :columns="cpbTablecolumns"
    :data-source="cpbEvents"
    bordered
    size="small"
    :pagination="false"
    :scroll="{ x: true, y: 0 }"
  >
    <template #fio2CustomTitle> FiO<sub>2</sub> </template>
    <template #tempCustomTitle> Temp (<sup>o</sup>C) </template>
    <template #svo2CustomTitle> S<sub>V</sub>O<sub>2</sub>% </template>
    <template #rso2LeftCustomTitle> rSO<sub>2</sub><br />(Left) </template>
    <template #rso2RightCustomTitle> rSO<sub>2</sub><br />(Right) </template>
    <template #phCustomTitle> <sub>p</sub>H </template>
    <template #pco2CustomTitle> PCO<sub>2</sub> </template>
    <template #po2CustomTitle> PO<sub>2</sub> </template>
    <template #hco3CustomTitle> HCO<sub>3</sub> </template>
    <template #o2SatCustomTitle> O<sub>2</sub> SAT </template>
    <template #kplusCustomTitle> K<sup>+</sup> </template>
    <template #naplusCustomTitle> Na<sup>+</sup> </template>
  </a-table>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { h } from "vue";

export default {
  name: "cpb-live-charting",
  props: ["cpb", "record"],

  components: { CheckOutlined },

  data() {
    return {
      cpbTablecolumns: [
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          fixed: "left",
          customRender: this.cpbsColspanRender,
        },
        {
          title: "Event",
          dataIndex: "event",
          key: "event",
          fixed: "left",
          customRender: this.cpbsColspanRender,
        },
        {
          title: "Pressure",
          children: [
            {
              title: "ART",
              dataIndex: "art_pressure",
              key: "art_pressure",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Pump",
          children: [
            {
              title: "Flow (l/min.)",
              dataIndex: "blood_flow",
              key: "blood_flow",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Line Pressure",
              dataIndex: "line_pressure",
              key: "line_pressure",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Cardioplegia",
          children: [
            {
              title: "Flow",
              dataIndex: "flow",
              key: "flow",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Circuit Pressure",
              dataIndex: "pressure",
              key: "pressure",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Volume",
              dataIndex: "volume_delivered",
              key: "volume_delivered",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Sinus Pressure",
              dataIndex: "coronary_sinus_pressure",
              key: "coronary_sinus_pressure",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Gases",
          children: [
            {
              dataIndex: "fio2",
              key: "fio2",
              slots: {
                title: "fio2CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Sweep",
              dataIndex: "sweep",
              key: "sweep",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Anes. Gas %",
              dataIndex: "anesthetic_gas",
              key: "anesthetic_gas",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          slots: {
            title: "tempCustomTitle",
          },
          children: [
            {
              title: "Art.",
              dataIndex: "art_temp",
              key: "art_temp",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Ven.",
              dataIndex: "venous_temp",
              key: "venous_temp",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Core",
              dataIndex: "core_temp",
              key: "core_temp",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "ACT",
          dataIndex: "act",
          key: "act",
          customRender: this.cpbsColspanRender,
        },
        {
          dataIndex: "svo2",
          key: "svo2",
          slots: {
            title: "svo2CustomTitle",
          },
          customRender: this.cpbsColspanRender,
        },
        {
          dataIndex: "rso2_left",
          key: "rso2_left",
          slots: {
            title: "rso2LeftCustomTitle",
          },
          customRender: this.cpbsColspanRender,
        },
        {
          dataIndex: "rso2_right",
          key: "rso2_right",
          slots: {
            title: "rso2RightCustomTitle",
          },
          customRender: this.cpbsColspanRender,
        },
        {
          title: "Blood Gas Analysis",
          children: [
            {
              dataIndex: "ph",
              key: "ph",
              slots: {
                title: "phCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "pco2",
              key: "pco2",
              slots: {
                title: "pco2CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "po2",
              key: "po2",
              slots: {
                title: "po2CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "hco3",
              key: "hco3",
              slots: {
                title: "hco3CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "BASE",
              dataIndex: "base",
              key: "base",
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "o2_sat",
              key: "o2_sat",
              slots: {
                title: "o2SatCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Hb/HCT",
              dataIndex: "hb/hct",
              key: "hb/hct",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Chemistry",
          children: [
            {
              title: "iCa",
              dataIndex: "ica",
              key: "ica",
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "kplus",
              key: "kplus",
              slots: {
                title: "kplusCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "naplus",
              key: "naplus",
              slots: {
                title: "naplusCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "GLU",
              dataIndex: "glu",
              key: "glu",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
      ],
    };
  },

  computed: {
    cpbEvents() {
      if (this.cpb?.data) {
        let data = this.cpb.data
          .filter((e) => !e.deleted_at)
          .map((item) => {
            if (!item.employee_id) {
              item.employee_id = this.record.employee_id;
            }
            if (!item.date) {
              item.date = this.$customDate.mdy(this.record.date);
              item.datetime = `${item.date} ${item.time}`;
            }
            return item;
          })
          .sort((a, b) =>
            moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
              moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
            )
              ? 1
              : -1
          );

        if (data.length != 0) {
          let firstDate = data[0].date;
          let lastDate = data[data.length - 1].date;
          let range = this.$comman.getDateRange(firstDate, lastDate);
          range.forEach((date, idx) => {
            if (idx != 0) {
              data.push({
                event: "Date change",
                date: date,
                time: "00:00:00",
                datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
                comment: `Date: ${date}`,
              });
            }
          });
        }

        return data.sort((a, b) =>
          moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
            moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
          )
            ? 1
            : -1
        );
      }
      return [];
    },
  },

  methods: {
    cpbsColspanRender(data) {
      if (data.column.key == "time") {
        if (["Date change"].includes(data.record.event)) {
          return {
            children: h("div", {
              innerHTML: data.record.comment,
            }),
            props: {
              colSpan: 30,
              style: { backgroundColor: "#eee", borderColor: "#fff" },
            },
          };
        } else {
          return data.record.time;
        }
      } else if (data.column.key == "event") {
        if (["Date change"].includes(data.record.event)) {
          return {
            props: { colSpan: 0 },
          };
        } else {
          return data.record.event;
        }
      }

      if (["Bypass", "X-Clamp", "Cir. Arrest"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: `${data.record.note} ${
              data.record.duration
                ? `(${moment
                    .utc(data.record.duration * 1000)
                    .format("HH:mm:ss")})`
                : ""
            } [Date: ${data.record.date}]`,
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Cooling", "Warming"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: `<span class="text-medium">Temperature:</span> ${data.record.temperature}`,
            }),
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Comment"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: `${data.record.comment} | ${data.record.time}`,
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Medication"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.medicationEvent(data.record),
            }),
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["+ Volume"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.plusVolumeEvent(data.record),
            }),
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["- Volume"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.minusVolumeEvent(data.record),
            }),
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Blood Product"].includes(data.record.event)) {
        if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.bloodProductEvent(data.record),
            }),
            props: { colSpan: 28 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Blood Gas"].includes(data.record.event)) {
        if (data.column.key == "hb/hct") {
          let res = data.record.hb ?? "-";
          res += "/";
          res += data.record.hct ?? "-";
          return res;
        } else {
          return data.text ?? "-";
        }
      } else if (["Cardioplegia"].includes(data.record.event)) {
        if (data.column.key == "ph") {
          return {
            children: h("div", {
              innerHTML: this.cardioplegiaEvent(data.record),
            }),
            props: { colSpan: 11 },
          };
        } else {
          if (
            [
              "pco2",
              "po2",
              "hco2",
              "base",
              "o2_sat",
              "hb/hct",
              "ica",
              "kplus",
              "naplus",
              "glu",
            ].includes(data.column.key)
          ) {
            return {
              props: { colSpan: 0 },
            };
          } else {
            return data.text ?? "-";
          }
        }
      } else if (["Date change"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return {
            children: h("div", {
              innerHTML: data.record.date,
            }),
            props: { colSpan: 30 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else {
        return data.text ?? "-";
      }
    },

    medicationEvent(item) {
      return `<span class="text-medium">Drug:</span> ${
        item.drug
      } | <span class="text-medium">Dose:</span> ${item.dose} ${
        item.doseUnit
      } | <span class="text-medium">Administered By:</span> ${
        item.administered_by
      } | <span class="text-medium">Lot No#:</span> ${
        item.lot_no ?? "N/A"
      } | <span class="text-medium">Expiry Date:</span> ${
        item.expiry_date ?? "N/A"
      }`;
    },

    plusVolumeEvent(item) {
      return [
        { name: "Pre-Bypass Volume", key: "pre_bypass_volume" },
        { name: "Prime Volume", key: "prime_volume" },
        { name: "Crystalloid", key: "crystalloid" },
        { name: "Colloid", key: "colloid" },
        { name: "CPB Cell Saver", key: "cpb_cell_saver" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    minusVolumeEvent(item) {
      return [
        { name: "Autologous Prime", key: "autologous_prime" },
        { name: "Ultrafiltration", key: "ultrafiltration" },
        { name: "Urine", key: "urine" },
        { name: "CPB Volume to Cell Saver", key: "cpb_volume_to_cell_saver" },
        { name: "Residual CPB Volume", key: "residual_cpb_volume" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    bloodProductEvent(item) {
      return `<span class="text-medium"> Product Type : </span> ${
        item.product_type ?? "N/A"
      } | <span class="text-medium"> Unit Type: </span> ${
        item.unit_type ?? "N/A"
      } | <span class="text-medium"> Unit ID No: </span> ${
        item.unit_id_no ?? "N/A"
      } | <span class="text-medium"> Unit Expiration Date: </span> ${
        item.unit_expiration_date ?? "N/A"
      } | <span class="text-medium"> Volume: </span> ${
        item.blood_product_volume ?? "N/A"
      } mL`;
    },

    cardioplegiaEvent(item) {
      let res = [];
      if (item.route.length > 0) {
        res.push(
          `<span class="text-medium"> Route : </span> ${item.route.join(", ")}`
        );
      }
      if (item.radio) {
        res.push(`<span class="text-medium"> Ratio : </span> ${item.radio}`);
        if (item.radio == "Other") {
          res.push(
            `<span class="text-medium"> Other Ratio : </span> ${item.other_radio}`
          );
        }
      }
      return res.join(" | ");
    },
  },
};
</script>
