<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="title" label="Title" name="title">
            <a-textarea
              v-model:value="formState.title"
              placeholder="Enter title here"
              showCount
              :maxlength="200"
              autoSize
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="description" label="Description" name="description">
            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
              @ready="onEditorReady"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="published_date"
            label="Published Date"
            name="published_date"
          >
            <a-date-picker
              v-model:value="formState.published_date"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item ref="type" label="Type" name="type">
            <a-radio-group v-model:value="formState.type">
              <a-radio
                v-for="v in $constants.recentUpdateTypes"
                :key="v.text"
                :value="v.value"
              >
                {{ v.text }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { commonService } from "@/services";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],

  data() {
    return {
      formState: {},
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        placeholder: "",
      },

      rules: {
        title: {
          required: true,
          message: "This field is required!",
          transform(value) {
            return value.trim();
          },
        },

        published_date: {
          required: true,
          type: "date",
          message: "This field is required!",
        },
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id ?? undefined,
        title: this.record.title ?? "",
        description: this.record.description ?? "",
        published_date: this.record.published_date
          ? moment(this.record.published_date)
          : null,
        type: this.record.type ?? 1,
      };
    },

    onSubmit() {
      this.loadingStart();
      commonService
        .store(this.$constants.adminRecentUpdateStoreUrl, {
          ...this.formState,
          published_date: this.$customDate.ymd(this.formState.published_date),
          description: this.editorData,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },

    onEditorReady() {
      this.editorData = this.record.description;
    },
  },
};
</script>
