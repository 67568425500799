<template>
  <a-layout-content>
    <page-header>
      <template #title> Inventory Logs</template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card hoverable size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #record_no="{ record, text }">
          <div style="display: flex; gap: 2px">
            <span>
              {{ text }}
            </span>
            <a-tooltip
              v-if="
                [3, 4, 5].includes(record.type) &&
                record?.inventory_loggable?.bin_to_bin?.remarks
              "
            >
              <template #title>
                {{ record.inventory_loggable.bin_to_bin.remarks }}
              </template>
              <a-button type="link" size="small">
                <i class="ti ti-info-circle ti-lg"></i>
              </a-button>
            </a-tooltip>
          </div>
        </template>

        <template #direction="{ record }">
          <template v-if="record.received_qty == 0 && record.issued_qty == 0">
            -
          </template>
          <template v-else>
            <template v-if="record.received_qty == 0">
              <i class="ti ti-arrow-down ti-lg" style="color: red"></i>
            </template>
            <template v-if="record.issued_qty == 0">
              <i class="ti ti-arrow-up ti-lg" style="color: green"></i>
            </template>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminInventoryLogsUrl,
      columns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          customRender: ({ record }) => {
            let res = this.$comman.getValueFromObject(
              record,
              "type",
              this.$constants.inventoryLogTypes
            );
            // if (res == "B2B") {
            //   if (record.inventory_loggable?.bin_to_bin) {
            //     let type = record.inventory_loggable?.bin_to_bin.type;
            //     if (type == 0) {
            //       res = "B2B-T";
            //     } else if (type == 1) {
            //       res = "B2B-A";
            //     } else if (type == 2) {
            //       res = "B2B-L";
            //     }
            //   }
            // }
            return res;
          },
          filters: this.$constants.inventoryLogTypes,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.type
            ? [parseInt(this.$route.query?.type)]
            : undefined,
        },
        {
          title: "Record No",
          dataIndex: "record_no",
          key: "record_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "record_no",
          },
          defaultFilteredValue: this.$route.query?.record_no
            ? [this.$route.query?.record_no]
            : undefined,
        },
        {
          title: "_",
          dataIndex: "direction",
          key: "direction",
          slots: {
            customRender: "direction",
          },
          filters: [
            {
              text: "In",
              value: 1,
            },
            {
              text: "Out",
              value: 2,
            },
          ],
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.direction
            ? [parseInt(this.$route.query?.direction)]
            : undefined,
        },
        {
          title: "Product",
          dataIndex: "product_name",
          key: "product_name",
          // customRender: ({ record }) =>
          //   record.inventory_loggable?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.product_name
            ? [this.$route.query?.product_name]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },

        {
          title: "Opening",
          dataIndex: "opening_qty",
          key: "opening_qty",
          // sorter: true,
          // slots: {
          //   filterDropdown: "filterDropdown",
          //   filterIcon: "filterIcon",
          // },
          // defaultFilteredValue: this.$route.query?.opening_qty
          //   ? [this.$route.query?.opening_qty]
          //   : undefined,
        },
        {
          title: "Received",
          dataIndex: "received_qty",
          key: "received_qty",
          // sorter: true,
          // slots: {
          //   filterDropdown: "filterDropdown",
          //   filterIcon: "filterIcon",
          // },
          // defaultFilteredValue: this.$route.query?.received_qty
          //   ? [this.$route.query?.received_qty]
          //   : undefined,
        },
        {
          title: "Issued",
          dataIndex: "issued_qty",
          key: "issued_qty",
          // sorter: true,
          // slots: {
          //   filterDropdown: "filterDropdown",
          //   filterIcon: "filterIcon",
          // },
          // defaultFilteredValue: this.$route.query?.issued_qty
          //   ? [this.$route.query?.issued_qty]
          // : undefined,
        },
        {
          title: "Closing",
          dataIndex: "closing_qty",
          key: "closing_qty",
          // sorter: true,
          // slots: {
          //   filterDropdown: "filterDropdown",
          //   filterIcon: "filterIcon",
          // },
          // defaultFilteredValue: this.$route.query?.closing_qty
          //   ? [this.$route.query?.closing_qty]
          //   : undefined,
        },
        {
          title: "Date",
          dataIndex: "id",
          key: "id",
          sorter: true,
          width: 100,
          defaultSortOrder: this.$route.query?.sortOrder
            ? this.$route.query.sortOrder
            : "descend",
          customRender: ({ record }) => this.$customDate.mdy(record.created_at),
        },
      ],
      defaultFilterParams: {
        sortField: this.$route.query?.sortField
          ? this.$route.query?.sortField
          : "id",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query?.sortOrder
          : "descend",
      },
    };
  },

  methods: {
    //
  },
};
</script>
