<template>
  <div class="performance-evaluation-mail-logs">
    <a-spin tip="Please wait..." :spinning="loading">
      <a-descriptions
        :column="2"
        :labelStyle="{ width: '150px' }"
        bordered
        size="small"
        class="mb-sm-1"
      >
        <a-descriptions-item label="Employee">
          {{ performance_evaluation?.employee?.fl_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Created Name">
          {{ performance_evaluation?.creator?.fl_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Job Type">
          {{
            $comman.getValueFromObject(
              performance_evaluation,
              "job_type",
              $constants.performanceEvaluationTypes
            )
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Appraisal Type">
          {{
            $comman.getValueFromObject(
              performance_evaluation,
              "appraisal_type",
              $constants.performanceEvaluationTypes
            )
          }}
        </a-descriptions-item>
      </a-descriptions>

      <template v-for="log in performance_evaluation?.mail_logs" :key="log.id">
        <a-descriptions
          :column="1"
          :labelStyle="{ width: '250px' }"
          bordered
          size="small"
          class="mb-sm-1"
        >
          <a-descriptions-item label="Email">
            {{ log.to }}
          </a-descriptions-item>
        </a-descriptions>

        <mail-log :record="filterRecord(log)" />
      </template>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mailLog from "../../../components/mailLog.vue";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  components: { mailLog },

  data() {
    return {
      loading: true,
      performance_evaluation: {},
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    this.loading = true;
    commonService
      .store(this.$constants.adminPerformanceEvaluationShowUrl, {
        id: this.record.id,
      })
      .then((res) => {
        this.performance_evaluation = res.data;
      })
      .catch((err) => {
        this.$message.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    filterRecord(rec) {
      let data = JSON.parse(JSON.stringify(this.performance_evaluation));
      data.mail_logs = data.mail_logs.filter((e) => e.id == rec.id);
      return data;
    },
  },
};
</script>
