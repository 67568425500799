import { createStore, createLogger } from "vuex";
import { auth } from "./auth1.module";
import { drawer } from "./drawer.module";
import { modal } from "./modal.module";
import { cart } from "./cart.module";
import { navbarCount } from "./navbarCount.module";

export const store = createStore({
  actions: {
    init({ dispatch }) {
      const hasIdleLocked = !!localStorage.getItem("IDLE_LOCK");

      return Promise.all([
        dispatch("auth/login", { isInitStore: true }),
        dispatch("auth/set_idle_lockscreen_status", hasIdleLocked),
      ]);
    },
  },
  mutations: {
    initialiseStore(state) {
      let token = localStorage.getItem("token");

      if (typeof token != "object") {
        let access_token = JSON.parse(token).access_token;
        var base64Url = access_token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = JSON.parse(
          decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          )
        );

        state.auth.isLoggedIn = true;
        state.auth.token = access_token;
        state.auth.user = jsonPayload.user;
        state.auth.employee = jsonPayload.employee;
        state.auth.hospital = jsonPayload.hospital;
        state.auth.slugs = jsonPayload.slugs;
        state.auth.routes = jsonPayload.routes;
        state.auth.employee_hospital_ids = jsonPayload.employee_hospital_ids;

        let cartProductsString = "cartProducts" + jsonPayload.user.id;
        let products =
          localStorage.getItem(cartProductsString) == null
            ? []
            : JSON.parse(localStorage.getItem(cartProductsString));
        state.cart.products = products;
      }
    },
  },
  modules: {
    auth,
    drawer,
    modal,
    cart,
    navbarCount,
  },
});
