<template>
  <a-layout-content>
    <page-header>
      <template #title> Calculators </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col :lg="6" :sm="12">
        <a-card hoverable style="text-align: center" @click="openBMICalculator">
          <a-typography-title :level="3" style="margin-bottom: 0">
            BSA
          </a-typography-title>
        </a-card>
      </a-col>

      <a-col :lg="6" :sm="12">
        <a-card
          hoverable
          style="text-align: center"
          @click="openHeparineCalculator"
        >
          <a-typography-title :level="3" style="margin-bottom: 0">
            Heparin
          </a-typography-title>
        </a-card>
      </a-col>

      <a-col :lg="6" :sm="12">
        <a-card
          hoverable
          style="text-align: center"
          @click="openProtamineCalculator"
        >
          <a-typography-title :level="3" style="margin-bottom: 0">
            Protamine
          </a-typography-title>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("drawer", ["open"]),

    openBMICalculator() {
      this.open({
        title: "BSA Calculator",
        path: "knowledgeBase.calculators.bmi",
        callback: () => {},
      });
    },

    openHeparineCalculator() {
      this.open({
        title: "Heparin Calculator",
        path: "knowledgeBase.calculators.heparine",
        callback: () => {},
      });
    },

    openProtamineCalculator() {
      this.open({
        title: "Protamine Calculator",
        path: "knowledgeBase.calculators.protamine",
        callback: () => {},
      });
    },
  },
};
</script>
