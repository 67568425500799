<template>
  <a-layout-content>
    <page-header>
      <template #title> Balance Sheet (YTD)</template>
      <template #buttons>
        <a-space align="end" :size="10">
          <a-range-picker
            v-model:value="balanceSheetDateRange"
            :allowClear="false"
            :ranges="ranges"
            style="width: 220px"
            :format="$constants.datepickerFormat"
          />

          <a-button
            type="primary"
            ghost
            :loading="downloadPdfBtnLoading"
            @click="() => downloadPdf()"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> PDF </a-space>
          </a-button>

          <a-button
            type="primary"
            ghost
            :loading="downloadExcelBtnLoading"
            @click="downloadExcel"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> Excel </a-space>
          </a-button>
        </a-space>
      </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="8" :lg="12">
        <a-row :gutter="[16, 16]">
          <a-col
            v-for="(widget, index) in widgets"
            :xs="24"
            :lg="12"
            :key="`widgets_${index}`"
          >
            <a-card>
              <a-spin :spinning="balanceSheetDataLoading">
                <a-statistic :value="widget.value">
                  <template #title>
                    <a-space>
                      {{ widget.title }}
                      <a-tooltip v-if="widget.tooltip" placement="top">
                        <template #title>
                          {{ widget.tooltip }}
                        </template>
                        <a-button size="small" type="link">
                          <i class="ti ti-info-circle ti-lg"> </i>
                        </a-button>
                      </a-tooltip>
                    </a-space>
                  </template>
                </a-statistic>
              </a-spin>
            </a-card>
          </a-col>
        </a-row>
      </a-col>

      <a-col :xs="24" :md="16" :lg="12">
        <a-card size="small">
          <a-spin :spinning="balanceSheetDataLoading">
            <table class="balance-sheet-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th style="text-align: right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in balanceSheetData"
                  :key="`${index}_pl`"
                  :class="item.type"
                >
                  <profitAndLossAnalyticTableRows :item="item" />
                </tr>
              </tbody>
            </table>
          </a-spin>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import {
ContactsFilled,
DollarCircleFilled,
PlusCircleFilled,
SearchOutlined,
SettingFilled,
ShoppingFilled,
} from "@ant-design/icons-vue";
import moment from "moment";
import { commonService } from "../../services/common.service";
import profitAndLossAnalyticTableRows from "../hospital/showTabs/profitAndLossAnalyticTableRows.vue";

export default {
  components: {
    SearchOutlined,
    ShoppingFilled,
    DollarCircleFilled,
    SettingFilled,
    ContactsFilled,
    PlusCircleFilled,
    profitAndLossAnalyticTableRows,
  },

  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
          customRender: () => "Amount",
        },
        {
          title: "Current",
          dataIndex: "current",
          key: "current",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "1-30 Days",
          dataIndex: "1_30_days",
          key: "1_30_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "31-60 Days",
          dataIndex: "31_60_days",
          key: "31_60_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "61-90 Days",
          dataIndex: "61_90_days",
          key: "61_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Over 90 Days",
          dataIndex: "over_90_days",
          key: "over_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
      ],
      data: null,
      loading: true,

      // Balance Sheet
      balanceSheetData: [],
      balanceSheetDateRange: [moment().startOf("year"), moment().endOf("year")],
      balanceSheetDataLoading: true,
      downloadPdfBtnLoading: false,
      downloadExcelBtnLoading: false,
    };
  },

  computed: {
    widgets() {
      return [
        {
          title: "Working Capital (YTD)",
          value: this.currentWorkingCapital,
        },
        {
          title: "Current Ratio (YTD)",
          value: this.currentRatio,
          tooltip:
            "A value between 1.5 and 2 is considered ideal for current ratio; anything below 1 could be a cause of concern about your company's ability to repay short-term loans. That said, the value can differ depending on industry of operation.",
        },
        {
          title: "Quick Ratio (YTD)",
          value: this.quickRatio,
          tooltip:
            "A result of 1 is considered to be the normal quick ratio. It indicates that the company is fully equipped with exactly enough assets to be instantly liquidated to pay off its current liabilities. A company that has a quick ratio of less than 1 may not be able to fully pay off its current liabilities in the short term, while a company having a quick ratio higher than 1 can instantly get rid of its current liabilities.",
        },
        {
          title: "Return on Assets (YTD)",
          value: this.returnOnAssets,
        },
        {
          title: "Debt to Asset Ratio (YTD)",
          value: this.debtToAssetRatio,
          tooltip:
            "A ratio greater than 1 shows that a considerable portion of the assets is funded by debt. In other words, the company has more liabilities than assets. A high ratio also indicates that a company may be putting itself at risk of defaulting on its loans if interest rates were to rise suddenly. A ratio below 0.5, meanwhile, indicates that a greater portion of a company's assets is funded by equity.",
        },
        {
          title: "Debt To Equity Ratio (YTD)",
          value: this.debtToEquityRatio,
          tooltip:
            "A ratio of lower than 1 is considered good, as it indicates your company is generating funds to finance operations via equity rather than taking on debt. But if the ratio is higher than 1, it means lenders might find it risky to lend to your company, thereby hindering your ability to borrow in times of emergency or economic downturns.",
        },
      ];
    },

    currentAssets() {
      return this.retriveName("Total Current Assets");
    },

    currentLiabilities() {
      return this.retriveName("Total Current Liabilities");
    },

    inventoryAsset() {
      return this.retriveName("12100 Inventory Asset");
    },

    netIncome() {
      return this.retriveName("Net Income");
    },

    totalAssets() {
      return this.retriveName("TOTAL ASSETS");
    },

    totalLiabilities() {
      return this.retriveName("Total Liabilities");
    },

    totalEquity() {
      return this.retriveName("Total Equity");
    },

    currentWorkingCapital() {
      return this.$comman.withCurrency(
        this.currentAssets - this.currentLiabilities
      );
    },

    currentRatio() {
      return this.currentLiabilities != 0
        ? parseFloat(this.currentAssets / this.currentLiabilities).toFixed(2)
        : 0;
    },

    quickRatio() {
      return this.currentLiabilities != 0
        ? parseFloat(
            (this.currentAssets - this.inventoryAsset) / this.currentLiabilities
          ).toFixed(2)
        : 0;
    },

    returnOnAssets() {
      return `${
        this.totalAssets != 0
          ? parseFloat((this.netIncome * 100) / this.totalAssets).toFixed(2)
          : 0
      }%`;
    },

    debtToAssetRatio() {
      return this.totalAssets != 0
        ? parseFloat(this.totalLiabilities / this.totalAssets).toFixed(2)
        : 0;
    },

    debtToEquityRatio() {
      return this.totalEquity != 0
        ? parseFloat(this.totalLiabilities / this.totalEquity).toFixed(2)
        : 0;
    },

    ranges() {
      let res = {};
      for (let index = 0; index < 5; index++) {
        res[moment().subtract(index, "years").startOf("year").format("YYYY")] =
          [
            moment().subtract(index, "years").startOf("year"),
            moment().subtract(index, "years").endOf("year"),
          ];
      }
      return res;
    },
  },

  async mounted() {
    this.getBalanceSheetData();
  },

  methods: {
    downloadPdf() {
      const year = `${this.$customDate.mdy(
        this.balanceSheetDateRange[0]
      )} - ${this.$customDate.mdy(this.balanceSheetDateRange[1])}`;
      this.downloadPdfBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.financeBalanceSheet,
          {
            start_date: this.$customDate.ymd(this.balanceSheetDateRange[0]),
            end_date: this.$customDate.ymd(this.balanceSheetDateRange[1]),
            export_pdf: true,
          },
          `Balance Sheet - ${year}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadPdfBtnLoading = false;
        });
    },

    downloadExcel() {
      const year = `${this.$customDate.mdy(
        this.balanceSheetDateRange[0]
      )} - ${this.$customDate.mdy(this.balanceSheetDateRange[1])}`;
      this.downloadExcelBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.financeBalanceSheet,
          {
            start_date: this.$customDate.ymd(this.balanceSheetDateRange[0]),
            end_date: this.$customDate.ymd(this.balanceSheetDateRange[1]),
            export_excel: true,
          },
          `Balance Sheet - ${year}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadExcelBtnLoading = false;
        });
    },

    getBalanceSheetData() {
      this.balanceSheetDataLoading = true;
      commonService
        .get(this.$constants.financeBalanceSheet, {
          start_date: this.$customDate.ymd(this.balanceSheetDateRange[0]),
          end_date: this.$customDate.ymd(this.balanceSheetDateRange[1]),
        })
        .then((res) => {
          this.balanceSheetData = res.data;
        })
        .finally(() => {
          this.balanceSheetDataLoading = false;
        });
    },

    retriveName(name, data = this.balanceSheetData) {
      return data.reduce((pre, curr) => {
        if (Array.isArray(curr)) {
          return pre + this.retriveName(name, curr);
        } else {
          return pre + (curr.name == name ? Number(curr.amount) : 0);
        }
      }, 0);
    },
  },

  watch: {
    balanceSheetDateRange() {
      this.getBalanceSheetData();
    },
  },
};
</script>

<style lang="less">
.balance-sheet-table {
  width: 100%;

  thead {
    tr {
      th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    tr.summary {
      td {
        border-top: 1px solid gray;
        font-weight: 500;
      }
    }
  }
}
</style>
