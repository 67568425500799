<template>
  <a-row>
    <a-col :span="24">
      <div v-html="this.record.email" />
    </a-col>
    <modal-buttons showSlot>
      <template #custom>
        <a-space :gap="4">
          <a-button @click="closeAction"> Close </a-button>
          <a-button
            type="primary"
            ghost
            @click="saveAndClose"
            :loading="saveAndCloseLoading"
          >
            Save and Close
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            @click="saveAndSend"
            :loading="saveAndSendLoading"
          >
            Save and Send
          </a-button>
        </a-space>
      </template>
    </modal-buttons>
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalButtons from "@/components/modalButtons.vue";
import { commonService } from "@/services";

export default {
  components: { ModalButtons },
  data() {
    return {
      saveAndCloseLoading: false,
      saveAndSendLoading: false,
    };
  },

  computed: {
    ...mapGetters("modal", ["record", "extra", "callback"]),
  },

  mounted() {},

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    closeAction() {
      this.close();
    },

    storeRecord(sendEmail = false) {
      this.loadingStart();

      const data = {
        ...this.record.data,
        send_email: sendEmail,
      };

      return commonService
        .store(this.$constants.employeeCompetencyAssessmentStore, data)
        .then((res) => {
          this.$message.success(res.message);
          this.callback();
          this.close();
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },

    saveAndClose() {
      this.saveAndCloseLoading = true;
      this.storeRecord().then(() => {
        this.saveAndCloseLoading = false;
      });
    },

    saveAndSend() {
      this.saveAndSendLoading = true;
      this.storeRecord(true).then(() => {
        this.saveAndSendLoading = false;
      });
    },
  },
};
</script>
