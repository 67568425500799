<template>
  <a-space direction="vertical" style="width: 100%">
    <a-card v-if="!editTaskId" size="small">
      <add-task
        ref="addTask"
        :lead_id="lead.id"
        @fetchLeadTasks="fetchLeadTasks"
        @cancelEditTask="cancelEditTask"
      />
    </a-card>

    <template v-if="tasks.length == 0">
      <a-card size="small">
        <a-empty />
      </a-card>
    </template>

    <template v-else>
      <a-list
        size="small"
        bordered
        :data-source="tasks.map((e) => ({ ...e, key: e.id }))"
        :loading="loading"
        style="background-color: #fff"
      >
        <template #renderItem="{ item }">
          <a-list-item v-if="editTaskId != item.id">
            <a-list-item-meta>
              <template #title>
                {{ item.description }}
              </template>

              <template #description>
                Added By
                <strong> {{ item.creator?.full_name ?? "N/A" }} </strong>, Due
                date <strong>{{ $customDate.ll(item.due_date) }}</strong>

                <template v-if="item.is_completed">
                  | Completed By
                  <strong> {{ item.complete?.full_name ?? "N/A" }} </strong>, At
                  <strong>{{ $customDate.ll(item.completed_at) }}</strong>
                </template>
              </template>

              <template #avatar>
                <a-button
                  v-if="item.is_completed"
                  size="small"
                  type="link"
                  @click="completeTaskRecord(item)"
                >
                  <i class="ti ti-checkbox ti-lg primary-color" />
                </a-button>
                <a-button
                  v-else
                  size="small"
                  type="link"
                  danger
                  @click="completeTaskRecord(item)"
                >
                  <i class="ti ti-square ti-lg" />
                </a-button>
              </template>
            </a-list-item-meta>

            <template #actions>
              <a-space v-if="!item.is_completed">
                <a-tooltip title="Edit">
                <a-button
                  type="link"
                  size="small"
                  @click="editTaskRecord(item)"
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>

                <a-popconfirm
                  title="Are you sure you want to delete this task?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteTaskRecord(item)"
                >
                <a-tooltip title="Delete">
                  <a-button type="link" size="small" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
                </a-popconfirm>
              </a-space>
            </template>
          </a-list-item>

          <a-list-item v-show="editTaskId == item.id">
            <add-task
              :ref="`editTask${item.id}`"
              :lead_id="lead.id"
              @fetchLeadTasks="fetchLeadTasks"
              @cancelEditTask="cancelEditTask"
            />
          </a-list-item>
        </template>
      </a-list>
    </template>
  </a-space>
</template>

<script>
import { commonService } from "@/services";
import { InboxOutlined } from "@ant-design/icons-vue";
import addTask from "../drawerForms/task.vue";

export default {
  components: { InboxOutlined, addTask },

  props: ["lead"],

  data() {
    return {
      loading: false,
      tasks: [],
      id: this.$route.params.id,
      editTaskId: undefined,
    };
  },

  mounted() {
    this.fetchLeadTasks();
  },

  methods: {
    fetchLeadTasks() {
      this.loading = true;
      this.editTaskId = undefined;
      commonService
        .store(this.$constants.crmGetLeadTaskUrl, { id: this.id })
        .then((res) => {
          this.tasks = res.data;
        })
        .finally(() => (this.loading = false));
    },

    editTaskRecord(task) {
      if (!this.editTaskId) {
        this.editTaskId = task.id;
        setTimeout(() => this.editTaskRecord(task), 250);
      }
      this.editTaskId = task.id;
      this.$refs[`editTask${task.id}`].editTask(task);
    },

    cancelEditTask() {
      this.editTaskId = undefined;
    },

    deleteTaskRecord(record) {
      this.loading = true;
      commonService
        .store(this.$constants.crmLeadTaskRemoveUrl, { id: record.id })
        .then((res) => {
          this.tasks = res.data;
        })
        .finally(() => (this.loading = false));
    },

    completeTaskRecord(record) {
      this.loading = true;
      commonService
        .store(this.$constants.crmLeadTaskCompleteUrl, { id: record.id })
        .then((res) => {
          this.tasks = res.data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
