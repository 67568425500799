<template>
  <div>
    <a-form
      name="asset_manufacturer_form"
      ref="asset_manufacturer_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
              @change="resetErrors('name')"
            />
          </a-form-item>

          <a-form-item
            ref="email"
            label="Email"
            name="email"
            :validateStatus="validate('email', 'status')"
            :help="validate('email', 'msg')"
          >
            <a-input
              v-model:value="formState.email"
              placeholder="Enter email here"
              @change="resetErrors('email')"
            />
          </a-form-item>

          <a-form-item ref="contact_no" label="Contact No" name="contact_no">
            <a-input
              v-model:value="formState.contact_no"
              v-maska="$constants.contactNumberMask"
              :placeholder="$constants.contactNumberPlaceholder"
            />
          </a-form-item>

          <a-form-item ref="address" label="Address" name="address">
            <a-textarea
              v-model:value="formState.address"
              placeholder="Enter address here"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        name: "",
        email: "",
        contact_no: "",
        address: "",
      },
      options: [],
      rules: {
        name: [
          {
            required: true,
            message: "this field is required!",
            transform(value) {
              return value.trim();
            },
          },
        ],
        email: [
          {
            message: "this field is required!",
            type: "email",
          },
        ],
        contact_no: [
          {
            message: "This field is required!",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        email: this.record.email ?? "",
        contact_no: this.record.contact_no
          ? Number(this.record.contact_no)
          : "",
        address: this.record.address ?? "",
      };
    },

    onSubmit() {
      if (this.record.id) {
        this.formState.id = this.record.id;
      }

      this.loadingStart();
      companyService
        .store(this.$constants.assetManufacturerStoreUrl, this.formState)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
