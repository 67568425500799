<template>
  <a-card size="small">
    <a-row type="flex" justify="center" align="top">
      <a-space>
        <a-badge color="#1890ff" text="Pending" />
        |
        <a-badge color="#52c41a" text="Approved" />
      </a-space>
    </a-row>

    <a-calendar @change="onChange">
      <template #dateCellRender="{ current }">
        <template v-for="item in getListData(current)" :key="item.id">
          <a-badge
            @click="handleShowPaidTimeOffEvent(item.paid_time_off)"
            :color="item.paid_time_off.status == 1 ? '#1890ff' : '#52c41a'"
            :text="`${getTimes(item)} | ${item.paid_time_off.employee.fl_name}`"
            style="font-size: 10px"
          />
        </template>
      </template>
    </a-calendar>
  </a-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../../services";

export default {
  data() {
    return {
      value: null,
      data: [],
      currentMonth: null,
    };
  },

  mounted() {
    this.onChange(moment());
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getTimes(item) {
      let startTime = moment(String(item.start_time), "hh:mm");
      return `${startTime.clone().format("HH:mm")} - ${startTime
        .clone()
        .add(item.hours_per_day, "hours")
        .format("HH:mm")}`;
    },

    getListData(date) {
      return this.data.filter((e) => e.date == date.format("YYYY-MM-DD")) || [];
    },

    onChange(date) {
      if (date.format("YYYY-MM") == this.currentMonth) {
        return;
      }
      this.currentMonth = date.format("YYYY-MM");

      commonService
        .get(this.$constants.managerPaidTimeOffCalendarUrl, {
          date: date.format("DD-MM-YYYY"),
        })
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    handleShowPaidTimeOffEvent(record) {
      this.open({
        title: "Paid Time Off Details",
        path: "paidTimeOff.show",
        record: record,
        callback: () =>
          commonService
            .get(this.$constants.managerPaidTimeOffCalendarUrl, {
              date: moment(this.currentMonth, "YYYY-MM").format("DD-MM-YYYY"),
            })
            .then((res) => {
              this.data = res.data;
            })
            .catch((err) => {
              this.$message.error(err);
            }),
      });
    },
  },
};
</script>
