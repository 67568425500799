<template>
  <a-form
    name="live_charting_plus_volume_form"
    ref="live_charting_plus_volume_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="pre_bypass_volume"
          label="Pre-Bypass Volume"
          name="pre_bypass_volume"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.pre_bypass_volume" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="prime_volume"
          label="Prime Volume"
          name="prime_volume"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.prime_volume" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="crystalloid"
          label="Crystalloid"
          name="crystalloid"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.crystalloid" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="colloid"
          label="Colloid"
          name="colloid"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.colloid" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="cpb_cell_saver"
          label="CPB Cell Saver"
          name="cpb_cell_saver"
          :rules="{
            type: 'integer',
            message: 'This field must be integer.',
          }"
        >
          <a-input-number type="number" :min="0" v-model:value="model.cpb_cell_saver" />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item ref="prbc" label="PRBC" name="prbc">
          <a-select
            v-model:value="model.prbc"
            mode="tags"
            style="width: 100%"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item ref="ffp" label="FFP" name="ffp">
          <a-select v-model:value="model.ffp" mode="tags" style="width: 100%" />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      model: {
        time: moment().tz(this.$constants.timezone).format("HH:mm"),
        type: "+ Volume",
        pre_bypass_volume: null,
        prime_volume: null,
        crystalloid: null,
        colloid: null,
        cpb_cell_saver: null,
        prbc: [],
        ffp: [],
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      console.log("submitted::", this.model);
      return;

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
