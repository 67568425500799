import axios from 'axios'
import { api } from "../config/config"

export const companyService = {
  store: (url, val, sendAsIs = false) => {
    return axios
      .post(api.host + url, sendAsIs ? val : { ...val })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return Promise.reject(err.response.data);
      });
  }
}