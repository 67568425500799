<template>
  <a-card size="small" title="Products">
    <a-input-search
      v-model:value="searchProduct"
      enter-button
      @search="filterProducts"
      placeholder="Search product here..."
    >
      <template #addonBefore>
        <a-select
          v-model:value="searchCategory"
          show-search
          allow-clear
          placeholder="Select a category"
          option-filter-prop="label"
          style="width: 150px; text-align: left"
        >
          <a-select-option
            v-for="category in productCategoryList"
            :key="category.id"
            :value="category.id"
            :label="category.name"
          >
            {{ category.name }}
          </a-select-option>
        </a-select>
      </template>
    </a-input-search>
    <a-divider />
    <template v-if="productList.length > 0">
      <a-collapse :bordered="false">
        <a-collapse-panel
          v-for="item in productList"
          :key="item.id"
          :header="item.name"
        >
          <div class="product-spec-row">
            <span class="product-spec-name">SKU :</span>
            <span class="product-spec-value">{{ item.sku }}</span>
          </div>
          <div class="product-spec-row">
            <span class="product-spec-name">UOM :</span>
            <span class="product-spec-value">{{ item.uom.name ?? "-" }}</span>
          </div>
          <div class="product-spec-row">
            <span class="product-spec-name">Manufacturer :</span>
            <span class="product-spec-value">{{
              item.manufacturer.name ?? "-"
            }}</span>
          </div>
          <div class="product-spec-row">
            <span class="product-spec-name">Price :</span>
            <span class="product-spec-value">{{
              $comman.priceFilter(item.price)
            }}</span>
          </div>
          <div class="product-spec-row">
            <span class="product-spec-name">Ref :</span>
            <span class="product-spec-value">{{ item.ref_code ?? "-" }}</span>
          </div>
          <template #extra>
            <i class="fas fa-plus" @click="addProduct(item)"></i>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </template>
    <template v-else>
      <a-empty />
    </template>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import { commonService } from "../../services";

export default {
  data() {
    return {
      loading: false,
      searchProduct: "",
      searchCategory: null,
      productCategoryList: [],
      productList: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions("cart", ["addProduct"]),
    loadData() {
      this.loading = true;
      Promise.all([this.loadProducts(), this.loadProductCategories(), false])
        .then((values) => {
          this.loading = false;
          [this.productList, this.productCategoryList] = values;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    loadProducts() {
      let search = {
        categoryId: this.searchCategory,
        search: this.searchProduct,
      };
      return commonService
        .get(this.$constants.getProductsUrl, search)
        .then((res) => res.data);
    },
    loadProductCategories() {
      return commonService
        .get(this.$constants.getProductCategoriesUrl)
        .then((res) => res.data);
    },
    filterProducts() {
      this.loadProducts()
        .then((res) => (this.productList = res))
        .catch((err) => this.$message.error(err));
    },
  },
};
</script>

<style scoped>
.product-spec-row .product-spec-name {
  width: 120px;
  display: inline-block;
  font-weight: bold;
}
.product-spec-row .product-spec-value {
  color: #00000073;
}
</style>
