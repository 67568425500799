<template>
  <template v-if="recentUpdates.length > 0">
    <template v-for="(recentUpdate, index) in recentUpdates" :key="index">
      <span>
        <a-badge color="blue" />
        <a-typography-text type="secondary" class="mb-sm-0 mr-sm-1 text-medium">
          {{ $customDate.mdy(recentUpdate.published_date) }}
        </a-typography-text>
        <template v-if="recentUpdate.type == 1">
          <a-tag color="blue">For Admin</a-tag>
        </template>
      </span>
      <a-typography-title :level="5" style="margin-top: 5px">
        {{ recentUpdate.title }}
      </a-typography-title>

      <div v-html="recentUpdate.description"></div>

      <template v-if="recentUpdates.length != index + 1">
        <a-divider class="my-sm-1" />
      </template>
    </template>
  </template>

  <a-card v-else>
    <a-empty />
  </a-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { constants } from "../helper/constants";
import { commonService } from "../services";

const spinning = ref(true);
const recentUpdates = ref([]);

onMounted(() => {
  commonService
    .get(constants.adminRecentUpdateDatatableUrl)
    .then((res) => (recentUpdates.value = res.data))
    .finally(() => (spinning.value = false));
});
</script>
