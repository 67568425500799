<template>
  <template v-if="menu.subMenu">
    <a-sub-menu
      v-if="menu.condition && checkSubMenuConditions(menu.subMenu)"
      :key="menu.route"
    >
      <template #icon>
        <i :class="menu.icon"></i>
      </template>
      <template #title>
        {{ menu.title }}
      </template>

      <sidebarMenu v-for="sm in menu.subMenu" :menu="sm" :key="sm.route" />
    </a-sub-menu>
  </template>

  <template v-else>
    <a-menu-item v-if="menu.condition" :key="menu.route" style="width: 100%">
      <template #icon>
        <span>
          <i :class="menu.icon"></i>
        </span>
      </template>
      <router-link :to="{ name: menu.route }" style="width: 100%">
        <a-row type="flex" justify="space-between" style="width: 100%">
          <a-col>
            {{ menu.title }}
          </a-col>
          <a-col>
            <a-badge
              :count="menu.counts"
              :offset="[-5, 0]"
              :overflow-count="99"
            />
          </a-col>
        </a-row>
      </router-link>
    </a-menu-item>
  </template>
</template>

<script>
import sidebarMenu from "./sidebarMenu.vue";

export default {
  name: "sidebarMenu",

  props: ["menu"],

  components: { sidebarMenu },

  methods: {
    checkSubMenuConditions(subMenu) {
      return Object.values(subMenu).reduce(
        (bool, v) => bool || v.condition,
        false
      );
    },
  },
};
</script>

<style scoped>
.ti {
  font-size: 24px;
  line-height: 1;
}

.badge-text {
  color: rgba(255, 255, 255, 0.65);
}

.badge-text:hover {
  color: #fff;
}

/* .ant-menu-item .ant-menu-item-icon {
  margin-top: 10px;
} */
</style>
