<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="`Product Ordering ${order_no ? `#${order_no}` : ''}`"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-form
      :model="model"
      :rules="rules"
      @finish="handleFinish"
      layout="vertical"
    >
      <a-space direction="vertical" style="width: 100%">
        <a-card size="small" hoverable :loading="cardLoading">
          <a-row :gutter="[20, 0]">
            <a-col :xs="24" class="mb-sm-1">
              <a-descriptions title="" bordered size="small">
                <a-descriptions-item
                  label="Employee Name"
                  :labelStyle="{ width: '200px' }"
                >
                  {{ orderRecord?.creator?.name ?? "N/A" }}
                </a-descriptions-item>
              </a-descriptions>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-item
                ref="company_id"
                label="Billing Address"
                name="company_id"
              >
                <a-select
                  v-model:value="model.company_id"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="option in companies"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                ref="hospital_id"
                label="Where is this order shipping to?"
                name="hospital_id"
              >
                <a-select
                  v-model:value="model.hospital_id"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="option in hospitals"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="Delivery type" name="delivery_type">
                <a-select
                  v-model:value="model.delivery_type"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="option in $constants.orderDeliveryTypes"
                    :key="option.value"
                    :label="option.text"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-item
                ref="workOption"
                label="Order Notes"
                name="workOption"
              >
                <a-textarea
                  v-model:value="model.note"
                  placeholder="Notes about your order..."
                  :rows="8"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>

        <a-card size="small" title="Products" hoverable :loading="cardLoading">
          <template #extra>
            <a-button type="primary" @click="handleAddRowEvent">
              <a-space> <i class="ti ti-plus ti-lg"></i> Add Item </a-space>
            </a-button>
          </template>

          <a-table
            :columns="columns"
            :rowKey="(record, index) => index"
            :dataSource="model.products"
            :pagination="false"
            size="small"
            bordered
            :rowClassName="() => `products-table-row`"
            class="products-table"
          >
            <template #footer>
              <td
                style="
                  width: calc(100% - 100px);
                  text-align: right;
                  font-weight: bold;
                "
              >
                Total:
              </td>
              <td style="width: 100px; text-align: right; font-weight: bold">
                {{
                  model.total_amount
                    ? $comman.withCurrency(model.total_amount)
                    : "N/A"
                }}
              </td>
            </template>

            <template #product_name="{ record, index }">
              <a-form-item
                :name="['products', index, 'id']"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="record.id"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="option in unSelectedProducts(record)"
                    :key="option.product_id"
                    :label="option.product.name"
                    :value="option.product_id"
                  >
                    {{ option.product.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>

            <template #product_price="{ record }">
              {{
                record.product_price
                  ? $comman.withCurrency(record.product_price)
                  : "N/A"
              }}
            </template>

            <template #set="{ record, index }">
              <a-form-item
                :name="['products', index, 'set']"
                :rules="{
                  required: true,
                  validator: (rule, value) =>
                    customValidator(rule, value, record),
                }"
              >
                <a-input
                  class="hide-arrow"
                  type="number"
                  v-model:value="record.set"
                />
              </a-form-item>
            </template>

            <template #subtotal="{ record }">
              {{
                record.subtotal ? $comman.withCurrency(record.subtotal) : "N/A"
              }}
            </template>

            <template #action="{ index }">
              <a-button
                v-if="model.products.length > 1"
                size="small"
                type="link"
                danger
                @click="handleRowDeleteEvent(index)"
              >
                <i class="ti ti-trash ti-lg"></i>
              </a-button>
            </template>
          </a-table>
        </a-card>

        <a-card
          size="small"
          title="Purchase Order"
          hoverable
          :loading="cardLoading"
        >
          <a-space direction="vertical" style="width: 100%">
            <template v-if="[2, 3].includes(model.delivery_type)">
              <a-checkbox
                v-model:checked="model.has_permission_granted"
                required
              >
                Has permission been granted by management for <b>Express</b> or
                <b>Overnight</b> delivery?
              </a-checkbox>
            </template>

            <!-- <a-form-item ref="termsAndConditions" name="termsAndConditions">
              <a-checkbox v-model:checked="model.termsAndConditions" required>
                I have read and agree to the website
                <span class="danger-color">terms and conditions *</span>
              </a-checkbox>
            </a-form-item> -->

            <a-button
              :disabled="
                model.products.length == 0 ||
                !model.termsAndConditions ||
                ([2, 3].includes(model.delivery_type) &&
                  !model.has_permission_granted)
              "
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
            >
              Place Order
            </a-button>
          </a-space>
        </a-card>
      </a-space>
    </a-form>
  </a-layout-content>
</template>

<script>
import { commonService } from "../../../services";

export default {
  data() {
    return {
      order_no: this.$route.params?.order_no ?? null,
      enableWatch: this.$route.params?.order_no ? false : true,
      cardLoading: true,
      submitBtnLoader: false,
      companies: [],
      hospitals: [],
      products: [],
      orderRecord: null,
      model: {
        id: null,
        company_id: undefined,
        hospital_id: undefined,
        delivery_type: undefined,
        note: "",
        products: [
          {
            id: undefined,
            product_price: null,
            set: null,
            qty: null,
            uom: null,
            subtotal: null,
          },
        ],
        total_amount: 0,
        has_permission_granted: false,
        termsAndConditions: true,
      },
      rules: {
        company_id: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        hospital_id: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        delivery_type: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        termsAndConditions: [
          {
            required: true,
            message: "This  is required!",
            type: "boolean",
          },
        ],
      },

      columns: [
        {
          title: "Name",
          dataIndex: "product_name",
          key: "product_name",
          slots: {
            customRender: "product_name",
          },
        },
        {
          title: "Price",
          dataIndex: "product_price",
          key: "product_price",
          slots: {
            customRender: "product_price",
          },
          align: "right",
          width: 150,
        },
        {
          title: "UOM",
          dataIndex: "uom",
          key: "uom",
          customRender: ({ text }) => text ?? "N/A",
          width: 100,
        },
        {
          title: "Qty",
          dataIndex: "set",
          key: "set",
          slots: {
            customRender: "set",
          },
          width: 100,
        },
        {
          title: "Subtotal",
          dataIndex: "subtotal",
          key: "subtotal",
          slots: {
            customRender: "subtotal",
          },
          align: "right",
          width: 150,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
          width: 100,
        },
      ],
    };
  },

  async mounted() {
    if (this.order_no) {
      await this.getOrder();
    }
    await this.getCompanies();
    this.cardLoading = false;
  },

  methods: {
    getOrder() {
      return commonService
        .get(this.$constants.adminOrderViewUrl, {
          order_no: this.order_no,
        })
        .then((res) => {
          if (res.success) {
            this.orderRecord = res.data;

            this.model.id = res.data.id;
            this.model.company_id = res.data.company_id;
            this.model.hospital_id = res.data.hospital_id;
            this.model.delivery_type = res.data.delivery_type;
            this.model.note = res.data.note;
            this.model.total_amount = Number(res.data.total_amount);
            this.model.has_permission_granted = res.data.has_permission_granted
              ? true
              : false;
            this.model.products = res.data.products.map((item) => {
              return {
                id: item.product_id,
                product_price: Number(item.price),
                set: item.set,
                qty: item.qty,
                uom: item.uom?.name ?? "N/A",
                subtotal: Number(
                  parseFloat(Number(item.price) * Number(item.set)).toFixed(2)
                ),
              };
            });
          }
        })
        .catch((err) => this.$message.error(err))
        .finally(() => {
          this.enableWatch = true;
        });
    },

    getCompanies() {
      return commonService.get(this.$constants.getCompaniesUrl).then((res) => {
        this.companies = res.data;
      });
    },

    getHospitals() {
      commonService
        .get(this.$constants.getHospitalsUrl, {
          company_id: this.model.company_id,
        })
        .then((res) => {
          this.hospitals = res.data;
        });
    },

    getProducts() {
      commonService
        .get(this.$constants.getHospitalWiseProductsUrl, {
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.products = res.data.filter(
            (e) => e.product.is_discontinued == 0
          );
        });
    },

    handleAddRowEvent() {
      this.model.products.push({
        id: undefined,
        product_price: null,
        set: null,
        qty: null,
        uom: null,
        subtotal: null,
      });
    },

    unSelectedProducts(record) {
      let selectedProductIds = this.model.products.map((item) => item.id);
      return this.products.filter(
        (item) =>
          !selectedProductIds.includes(item.product_id) ||
          record.id == item.product_id
      );
    },

    handleRowDeleteEvent(index) {
      this.model.products = this.model.products.filter((_, i) => index != i);
    },

    resetModelProduts() {
      this.model.products = [
        {
          id: undefined,
          product_price: null,
          set: null,
          qty: null,
          uom: null,
          subtotal: null,
        },
      ];
    },

    handleFinish() {
      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.adminOrderUpdateUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          this.$message.error(err);
        });
    },

    customValidator(rule, value, rec) {
      const reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        return Promise.reject("This field is required!");
      }
      if (value == 0) {
        return Promise.reject("Order qty must be greater then 0!");
      }
      return Promise.resolve();
    },
  },

  watch: {
    "model.company_id": {
      handler: function (val) {
        if (val) {
          this.getHospitals();
        } else {
          this.hospitals = [];
        }
        if (this.enableWatch) {
          this.products = [];
          this.model.hospital_id = undefined;
          this.resetModelProduts();
        }
      },
    },
    "model.hospital_id": {
      handler: function (val) {
        if (val) {
          this.getProducts();
        } else {
          this.products = [];
        }
        if (this.enableWatch) {
          this.resetModelProduts();
        }
      },
    },
    "model.products": {
      deep: true,
      handler: function (val) {
        if (this.enableWatch) {
          let totalAmount = 0;
          val.map((item) => {
            let product = this.products.find((p) => p.product_id == item.id);
            let itemPerSet = product?.product?.uom?.no_of_items ?? 1;

            item.uom = product?.product?.uom?.name ?? "N/A";
            item.qty = item.set * itemPerSet;
            item.product_price = product ? Number(product.product.price) : null;
            item.subtotal =
              item.product_price && item.set
                ? Number(parseFloat(item.product_price * item.set).toFixed(2))
                : 0;

            totalAmount += item.subtotal;
            return item;
          });

          this.model.total_amount = totalAmount;
        }
      },
    },
  },
};
</script>

<style>
.products-table-row td {
  vertical-align: "top";
  vertical-align: -webkit-baseline-middle;
}
.products-table .ant-table-footer {
  text-align: right;
}
</style>
