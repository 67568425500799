<template>
  <a-form layout="inline" :model="model" @finish="handleFinish">
    <a-form-item>
      <a-textarea
        v-model:value="model.description"
        placeholder="Add a task..."
        style="width: 500px"
        auto-size
      />
    </a-form-item>

    <a-form-item>
      <a-date-picker
        :allowClear="false"
        v-model:value="model.date"
        :placeholder="$constants.datePickerPlaceholder"
        :format="$constants.datepickerFormat"
        :disabledDate="$comman.disabledPastDate"
      />
    </a-form-item>

    <a-form-item>
      <a-space>
        <a-button
          type="primary"
          html-type="submit"
          :disabled="model.description === '' || model.date === ''"
        >
          {{ model.id ? "Update" : "Add" }}
        </a-button>

        <a-button
          v-if="model.id"
          type="ghost"
          html-type="button"
          danger
          @click="cancelEdit"
        >
          Cancel
        </a-button>
      </a-space>
    </a-form-item>
  </a-form>
</template>

<script>
import { commonService } from "@/services";
import { message } from "ant-design-vue";
import moment from "moment-timezone";

export default {
  props: ["lead_id"],

  emits: ["fetchLeadTasks", "cancelEditTask"],

  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        description: "",
        date: null,
      },
    };
  },

  methods: {
    editTask(task) {
      this.model.id = task.id;
      this.model.description = task.description;
      this.model.date = moment(task.date);
    },

    cancelEdit() {
      this.model.id = undefined;
      this.model.description = "";
      this.model.date = null;
      this.$emit("cancelEditTask");
    },

    handleFinish() {
      this.loading = true;
      commonService
        .store(this.$constants.crmLeadTaskStoreUrl, {
          ...this.model,
          lead_id: this.lead_id,
        })
        .then((res) => {
          message.success(res.message);
          this.cancelEdit();
          this.$emit("fetchLeadTasks");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
