<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #title>
        Case Reporting Details
        {{ record?.report_no ? `(${record.report_no})` : "" }}
      </template>

      <template #extra>
        <a-popconfirm
          v-if="record?.status == 2 && record?.can_approve"
          title="Are you sure you want to approve this case report?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleStatusChangeEvent({ id: record.id, status: '3' })"
        >
          <a-button type="primary" :loading="approving">
            <a-space>
              <i class="ti ti-status-change ti-lg"></i> Approve
            </a-space>
          </a-button>
        </a-popconfirm>
      </template>
    </a-page-header>

    <a-card size="small">
      <a-skeleton :loading="cardLoading">
        <component
          v-if="is"
          :is="is"
          v-bind="{
            model,
            reportNo,
            hospitals,
            caseServices,
            isCatiInvoiceAmountShow,
            isShow: true,
            record,
            hospital,
            isDisposableOnlyCase,
          }"
        >
          <router-view />
          <template v-if="atx" #atsLiveCharting>
            <atx-live-charting :atx="atx" />
          </template>

          <template v-if="cpb" #cpbLiveCharting>
            <cpb-live-charting :cpb="cpb" :record="record" />
          </template>

          <template v-if="hipec" #hipecLiveCharting>
            <hipec-live-charting :hipec="hipec" />
          </template>

          <template v-if="ecmo" #ecmoLiveCharting>
            <ecmo-live-charting :ecmo="ecmo" />
          </template>

          <template v-if="nrp" #nrpLiveCharting>
            <nrp-live-charting :nrp="nrp" :record="record" />
          </template>
        </component>
      </a-skeleton>
    </a-card>
  </a-layout-content>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { route } from "../../helper/route";
import { commonService } from "../../services";
import atxLiveCharting from "./liveCharting/atx.vue";
import cpbLiveCharting from "./liveCharting/cpb.vue";
import ecmoLiveCharting from "./liveCharting/ecmo.vue";
import hipecLiveCharting from "./liveCharting/hipec.vue";
import nrpLiveCharting from "./liveCharting/nrp.vue";

export default {
  components: {
    atxLiveCharting,
    cpbLiveCharting,
    hipecLiveCharting,
    ecmoLiveCharting,
    nrpLiveCharting,
    CheckOutlined,
  },

  data() {
    return {
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      approving: false,

      cardLoading: true,
      submitBtnLoader: false,
      uuid: this.$route?.params?.uuid ?? null,
      record: null,
      reportNo: null,
      model: {
        // Basic Information
        date: null,
        hospital_id: undefined,
        mr_number: null,
        patient_first_name: null,
        patient_last_name: null,
        allergies: null,
        height: null,
        weight: null,
        body_surface_area: null,

        // Operative Data
        surgeon: null,
        anesthesia_provider: null,
        procedure: null,
        case_status: undefined,
        services: [],
        service_category: undefined,
        reop: undefined,
        coronary_artery_bypass: undefined,
        cab_technique: undefined,
        aortic: undefined,
        mitral: undefined,
        tricuspid: undefined,
        pulmonic: undefined,
        other_cardiac_procedure: undefined,
        cardiac_procedure: undefined,
        other_non_cardiac_procedure: undefined,
        non_cardiac_procedure: undefined,
        patient_in_room_time: null,
        surgery_start_time: null,
        surgery_end_time: null,
        patient_out_of_room_time: null,
        start_date_of_ecmo_run: null,
        start_time_of_ecmo_run: null,
        end_date_of_ecmo_run: null,
        end_time_of_ecmo_run: null,
        cpb_time: null,
        cross_clamp_time: null,
        circulatory_arrest_time: null,
        cati_invoice_amount: null,
        total_ecmo_run_time: null,
        cati_invoice_amount: null,

        // Disposible Products
        products: [],

        // Acute Normovolemic Hemodilution (ANH)
        blood_sequestered: null,
        anticoagulation_type: undefined,

        // Quality Indicators
        first_blood_glucose_level: null,
        last_blood_glucose_level: null,
        anesthesia_volumn_pre_bypass: null,
        net_prime_volume: null,
        ultrafiltration_on_cpb: null,
        modified_ultrafiltration: null,
        act_seconds_prior_to_initiation: null,
        act_seconds_while_on_cpb: null,
        lowest_act_on_cpb: null,
        post_induction_hematocrit: null,
        first_cpb_hematocrit: null,
        percent_decrease_in_hemtocrit: null,
        lowest_cpb_hematocrit: null,
        last_cpb_hematocrit: null,
        last_in_room_hematocrit: null,
        lowest_arterian_pressure_on_cpb: null,
        lowest_svo2_on_cpb: null,
        lowest_cpb_base_excess_deficit: null,
        highest_blood_glucose_level: null,
        highest_arterial_blood_temperature: null,
        lowest_core_body_temperature: null,
        minimum_cardiac_index_during_cpb: null,
        prbc_transfusion_during_cpb: null,
        additional_crystalloid_volume_during_cpb: null,
        estimated_blood_volume: null,
        predicted_cpb_hematocrit: null,
        diff_predicted_cpb_hct_vs_actual_cpb_hct: null,

        // Cell Saver Operative Data
        washed_rbcs_reinfused: null,
        type_of_anticoagulant_used: null,
        total_processed_volumn: null,
        anticoagulant_volumn_used: null,
        wash_volumn_used: null,
        estimated_blood_loss: null,
        clear_effluent_wash_line: null,
        autologous_blood_transfused_within_4_hours: null,
        cell_saver_hct: null,
        was_a_qc_sample_obtained_for_this_procedure: null,
        sources: [],

        // Procedure Info
        is_sts_data: undefined,
        endoscopic_vein_harvest_time: null,
        radial_artery_harvest_time: null,
        proximal_techniques: [],
        cardioplegia_types: [],
        coronary_bypasses: [],

        // HIPEC Quality Indicators
        cytotoxic_drug_type_and_dose: null,
        prime_type_and_amount: null,
        average_inflow_temperature: null,
        average_outflow_temperature: null,
        pump_flow_rate: null,
        average_reservoir_volume: null,
        additional_comments: null,

        // Post Procedure Maintenance
        is_heart_lung: undefined,
        heart_lung_device: undefined,
        is_heater_cooler: undefined,
        heater_cooler_device: undefined,
        is_cell_saver: undefined,
        cell_saver_device: undefined,

        // Preview
        sign: null,

        // File Upload Or Capture Image
        additional_procedural_notes: "",
        attachments: [],
      },

      hospitals: [],
      caseServices: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    is() {
      return route(`newCaseReport/steps/step9`);
    },

    hospital() {
      return this.record.hospital;
    },

    isShowAtxTab() {
      return this.$constants.caseServicesIdForATS.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowCpbTab() {
      return this.$constants.caseServicesIdForCPB.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowHipecTab() {
      return this.$constants.caseServicesIdForHIPEC.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowEcmoTab() {
      return this.$constants.caseServicesIdForECMO.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    isShowNRPTab() {
      return this.$constants.caseServicesIdForNRP.reduce(
        (bool, curr) =>
          bool || (this.record?.data?.services?.includes(curr) ?? false),
        false
      );
    },

    atx() {
      return this.isShowAtxTab && this.record?.live_chart?.atx
        ? this.record.live_chart.atx
        : null;
    },

    cpb() {
      return this.isShowCpbTab && this.record?.live_chart?.cpb
        ? this.record.live_chart.cpb
        : false;
    },

    hipec() {
      return this.isShowHipecTab && this.record?.live_chart?.hipec
        ? this.record.live_chart.hipec
        : false;
    },

    ecmo() {
      return this.isShowEcmoTab && this.record?.live_chart?.ecmo
        ? this.record.live_chart.ecmo
        : false;
    },

    nrp() {
      return this.isShowNRPTab && this.record?.live_chart?.nrp
        ? this.record.live_chart.nrp
        : false;
    },

    pgtPreChecklist() {
      return this.$constants.pgtPreProcedureChecklist;
    },

    pgtPostChecklist() {
      return this.$constants.pgtPostProcedureChecklist;
    },

    isCatiInvoiceAmountShow() {
      if (this.record?.hospital) {
        return this.record.hospital.companies[0].id == 2;
      }
      return false;
    },

    isDisposableOnlyCase() {
      return this.record.is_disposable_only_case &&
        this.record?.data?.services?.includes(
          this.$constants.disposableOnlyCase
        )
        ? true
        : false;
    },
  },

  async mounted() {
    await commonService
      .get(this.$constants.caseReportShowUrl, { uuid: this.uuid })
      .then((res) => {
        this.record = res.data;
        this.modifyModelData(res.data);
      })
      .catch((err) => {
        this.$message.error(err);
        return this.$router.go(-1);
      });

    await this.fetchCaseServices();
    this.cardLoading = false;
  },

  methods: {
    modifyModelData(report) {
      this.reportNo = report.report_no;
      this.uuid = report.uuid;
      let attachments = [];
      if (report.attachments.length > 0) {
        attachments = report.attachments.map((v) => ({
          id: v.id,
          uid: v.file_name,
          name: v.file_name_with_ext,
          url: v.full_file_path,
        }));
      }

      let oldPgtNaPreOption =
        report?.data?.pgt_na_pre_procedure_checklist ?? [];
      let oldPgtPreOption = report?.data?.pgt_pre_procedure_checklist ?? [];
      let oldPgtNaPreProcedureChecklist = {};
      let oldPgtPreProcedureChecklist = {};
      this.pgtPreChecklist.forEach((e) => {
        oldPgtNaPreProcedureChecklist[e] = oldPgtNaPreOption.includes(e)
          ? true
          : false;
        oldPgtPreProcedureChecklist[e] = oldPgtPreOption.includes(e)
          ? true
          : false;
      });

      let oldPgtNaPostOption =
        report?.data?.pgt_na_post_procedure_checklist ?? [];
      let oldPgtPostOption = report?.data?.pgt_post_procedure_checklist ?? [];
      let oldPgtNaPostProcedureChecklist = {};
      let oldPgtPostProcedureChecklist = {};
      this.pgtPostChecklist.forEach((e) => {
        oldPgtNaPostProcedureChecklist[e] = oldPgtNaPostOption.includes(e)
          ? true
          : false;
        oldPgtPostProcedureChecklist[e] = oldPgtPostOption.includes(e)
          ? true
          : false;
      });

      this.model = {
        ...report.data,
        account_number: report.data.account_number ?? null,
        date: moment(report.date),
        dob: report.data.dob ? moment(report.data.dob) : null,
        start_date_of_ecmo_run: report.data.start_date_of_ecmo_run
          ? moment(report.data.start_date_of_ecmo_run)
          : null,
        end_date_of_ecmo_run: report.data.end_date_of_ecmo_run
          ? moment(report.data.end_date_of_ecmo_run)
          : null,
        is_disposable_only_case:
          report?.is_disposable_only_case == 1 ? true : false,

        // Cardiac Procedure Info
        reop: report?.data?.reop ?? "No",
        coronary_artery_bypass: report?.data?.coronary_artery_bypass ?? "No",
        aortic: report?.data?.aortic ?? "No",
        mitral: report?.data?.mitral ?? "No",
        tricuspid: report?.data?.tricuspid ?? "No",
        pulmonic: report?.data?.pulmonic ?? "No",
        other_cardiac_procedure: report?.data?.other_cardiac_procedure ?? "No",
        other_non_cardiac_procedure:
          report?.data?.other_non_cardiac_procedure ?? "No",
        attachments,

        // Quality Indicators
        lowest_hb: report?.data?.lowest_hb ?? null,
        qi_prbc: report?.data?.qi_prbc ?? 0,
        qi_ffp: report?.data?.qi_ffp ?? 0,
        qi_platelets: report?.data?.qi_platelets ?? 0,
        qi_cryo: report?.data?.qi_cryo ?? 0,

        // NRP PROCEDURE INFO
        nrp_initiation_date: report?.data?.nrp_initiation_date
          ? moment(report.data.nrp_initiation_date)
          : null,
        nrp_end_date: report?.data?.nrp_end_date
          ? moment(report.data.nrp_end_date)
          : null,
        nrp_aortic_and_ivc_clamp_date: report?.data
          ?.nrp_aortic_and_ivc_clamp_date
          ? moment(report.data.nrp_aortic_and_ivc_clamp_date)
          : null,
        nrp_aortic_cannulation_date: report?.data?.nrp_aortic_cannulation_date
          ? moment(report.data.nrp_aortic_cannulation_date)
          : null,
        nrp_circulatory_cessation_date: report?.data
          ?.nrp_circulatory_cessation_date
          ? moment(report.data.nrp_circulatory_cessation_date)
          : null,
        nrp_cold_aortic_flush_post_date: report?.data
          ?.nrp_cold_aortic_flush_post_date
          ? moment(report.data.nrp_cold_aortic_flush_post_date)
          : null,
        nrp_declaration_of_death_date: report?.data
          ?.nrp_declaration_of_death_date
          ? moment(report.data.nrp_declaration_of_death_date)
          : null,
        nrp_heart_flush_date: report?.data?.nrp_heart_flush_date
          ? moment(report.data.nrp_heart_flush_date)
          : null,
        nrp_incision_date: report?.data?.nrp_incision_date
          ? moment(report.data.nrp_incision_date)
          : null,
        nrp_lung_flush_date: report?.data?.nrp_lung_flush_date
          ? moment(report.data.nrp_lung_flush_date)
          : null,
        nrp_withdrawal_of_life_sustaining_therapy_date: report?.data
          ?.nrp_withdrawal_of_life_sustaining_therapy_date
          ? moment(report.data.nrp_withdrawal_of_life_sustaining_therapy_date)
          : null,
        nrp_venous_cannulation_date: report?.data?.nrp_venous_cannulation_date
          ? moment(report.data.nrp_venous_cannulation_date)
          : null,
        nrp_medicines: report?.data?.nrp_medicines ?? [],
        nrp_blood_products: report?.data?.nrp_blood_products ?? [],
        organs_considered: report?.data?.organs_considered
          ? report?.data?.organs_considered.split(", ")
          : [],
        organs_recovered: report?.data?.organs_recovered
          ? report?.data?.organs_recovered.split(", ")
          : [],

        // Operator Timings
        cati_arrival_date: report?.data?.cati_arrival_date
          ? moment(report.data.cati_arrival_date)
          : null,
        cati_arrival_time: report?.data?.cati_arrival_time ?? "",
        cati_departure_date: report?.data?.cati_departure_date
          ? moment(report.data.cati_arrival_date)
          : null,
        cati_departure_time: report?.data?.cati_departure_time ?? "",
        cati_total_time: report?.data?.cati_total_time ?? 0,
        cati_total_time_in_seconds:
          report?.data?.cati_total_time_in_seconds ?? 0,

        // Platelet Gel Therapy
        pgt_na_pre_procedure_checklist: oldPgtNaPreProcedureChecklist,
        pgt_pre_procedure_checklist: oldPgtPreProcedureChecklist,
        pgt_na_post_procedure_checklist: oldPgtNaPostProcedureChecklist,
        pgt_post_procedure_checklist: oldPgtPostProcedureChecklist,
      };
    },

    fetchCaseServices() {
      if (this.model.hospital_id) {
        return commonService
          .get(this.$constants.getCaseServicesUrl, {
            hospital_id: this.model.hospital_id,
          })
          .then((res) => {
            this.caseServices = res.data.map((item) => ({
              text: item.name,
              value: item.id,
            }));
          });
      }
    },

    handleStatusChangeEvent(val) {
      this.approving = true;
      commonService
        .store(this.statusChangeUrl, val)
        .then((res) => {
          this.record.status = 3;
          this.$message.success(res.message);
          this.$router.replace({ name: "home" });
        })
        .catch((err) => {
          this.$message.error(err ?? "Something went wrong.");
        })
        .finally(() => {
          this.approving = false;
        });
    },
  },
};
</script>
