<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report PDFs </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-space direction="vertical" style="width: 100%">
      <a-form
        ref="caseReportPdfForm"
        :model="model"
        layout="vertical"
        :rules="rules"
      >
        <a-card size="small" hoverable :loading="cardLoading">
          <a-row :gutter="[16, 0]">
            <a-col :span="12">
              <a-form-item
                ref="date_range"
                label="Date Range"
                name="date_range"
              >
                <a-range-picker
                  :allowClear="false"
                  v-model:value="model.date_range"
                  :disabled-date="$comman.disabledFutureDate"
                  :format="$constants.datepickerFormat"
                  inputReadOnly
                  :ranges="ranges"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="hospital_id"
                label="Hospitals"
                name="hospital_id"
              >
                <a-select
                  v-model:value="model.hospital_id"
                  show-search
                  optionFilterProp="label"
                  allowClear
                  placeholder="Select any one"
                >
                  <a-select-option
                    v-for="option in hospitals"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-space>
            <a-button
              type="primary"
              html-type="button"
              :loading="submitBtnLoader"
              @click="generateCaseReportPDf"
            >
              Generate PDF
            </a-button>
          </a-space>
        </a-card>
      </a-form>

      <template v-if="caseReportPdfs.length > 0">
        <a-alert type="warning" showIcon message="Warning">
          <template #description>
            Records will be automatically deleted in 7 days after their creation
            date. Please ensure you save or export any necessary information
            before this time to avoid permanent loss of data.
          </template>
        </a-alert>
      </template>

      <a-card size="small">
        <a-table
          size="small"
          :loading="cardLoading"
          :columns="columns"
          :data-source="caseReportPdfs"
          :rowKey="(record) => record.id"
          :pagination="false"
          :scroll="{ x: true, y: 0 }"
        >
          <template #status="{ record }">
            <a-tag v-if="record.status == 1" color="success"> Completed </a-tag>
            <a-tag v-else-if="record.status == 2" color="error">Fail</a-tag>
            <a-tag v-else color="processing">Pending</a-tag>
          </template>

          <template #action="{ record }">
            <template v-if="record.status == 1">
              <a-space>
                <a-tooltip title="Print PDF">
                  <a-typography-link :href="record.file_path" target="_blank">
                    <i class="ti ti-printer ti-lg"></i>
                  </a-typography-link>
                </a-tooltip>

                <a-tooltip title="ZIP File">
                  <a-typography-link
                    :href="record.zip_file_path"
                    target="_blank"
                  >
                    <i class="ti ti-file-zip ti-lg"></i>
                  </a-typography-link>
                </a-tooltip>
              </a-space>
            </template>
          </template>
        </a-table>
      </a-card>
    </a-space>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      model: {
        date_range: [null, null],
        hospital_id: undefined,
        only_nrp: null,
      },
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "array",
            len: 2,
            fields: {
              0: {
                type: "date",
                required: true,
                message: "This field is required!",
              },
              1: { type: "date", required: true, message: "" },
            },
          },
        ],
        hospital_id: {
          required: true,
          message: "This field is required!",
          trigger: "change",
          type: "integer",
        },
      },
      hospitals: [],

      caseReportPdfs: [],
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "N/A",
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Status",
          dataIndex: "status",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
      caseReportFetchInterval: null,
    };
  },

  computed: {
    //
  },

  async mounted() {
    await this.getHospitals();
    await this.getCaseReportPdfs();
    this.cardLoading = false;

    var self = this;
    this.caseReportFetchInterval = setInterval(function () {
      self.getCaseReportPdfs();
    }, 5000);
  },

  unmounted() {
    clearInterval(this.caseReportFetchInterval);
  },

  methods: {
    async getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => this.$message.error(error));
    },

    generateCaseReportPDf() {
      this.$refs.caseReportPdfForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .store(this.$constants.caseReportPdfsCreateReport, {
            start_date: this.$customDate.ymd(this.model.date_range[0]),
            end_date: this.$customDate.ymd(this.model.date_range[1]),
            hospital_id: this.model.hospital_id,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.caseReportPdfs = res.data;
            this.$refs.caseReportPdfForm.resetFields();
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    async getCaseReportPdfs() {
      return commonService
        .get(this.$constants.caseReportPdfsReport)
        .then((res) => {
          this.caseReportPdfs = res.data;
        });
    },
  },
};
</script>
