import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/employee-time-tracks",
    name: "employee-time-tracks",
    component: route("employeeTimeTrack/index"),
    meta: {
      title: "Employee Time Tracks",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/employee-time-track/create",
    name: "create-employee-time-track",
    component: route("employeeTimeTrack/store"),
    meta: {
      title: "Add New Employee Time",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/employee-time-track/edit/:id",
    name: "edit-employee-time-track",
    component: route("employeeTimeTrack/store"),
    meta: {
      title: "Edit Employee Time",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/employee-time-track/show/:id",
    name: "show-employee-time-track",
    component: route("employeeTimeTrack/show"),
    meta: {
      title: "Show Employee Time",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  // TIME OFF
  {
    path: "/time-off",
    name: "time-off",
    component: route("timeOff"),
    meta: {
      title: "Time Off",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
