<template>
  <a-layout-content>
    <a-row :gutter="[10, 10]">
      <a-col :span="24">
        <page-header>
          <template #title>Customer Reports</template>
        </page-header>
      </a-col>
    </a-row>

    <a-row :gutter="[10, 10]">
      <a-col :span="24">
        <a-card
          size="small"
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="onTabChange"
        >
          <template #tabBarExtraContent>
            <a-space :size="10">
              <a-button
                type="primary"
                ghost
                :loading="downloadExcelBtnLoading"
                @click="downloadExcel"
              >
                <a-space> <i class="ti ti-download ti-lg"></i> Excel </a-space>
              </a-button>
              <a-select
                v-model:value="selectedYear"
                :options="yearOptions"
                style="width: 120px"
                placeholder="Select Year"
                @change="fetchData"
              />
            </a-space>
          </template>

          <div>
            <a-table
              :columns="columns"
              :dataSource="dataSource"
              :rowKey="(record) => record.hospital_id"
              bordered
              :loading="loading"
              :pagination="false"
              size="small"
              :scroll="{
                x: true,
                y: '500px',
              }"
            >
              <template #filterDropdown="filterDropdown">
                <xFilterInputSearchDropdown
                  :filterDropdown="filterDropdown"
                  @handleSearch="handleDatatableSearch"
                  @handleReset="handleDatatableReset"
                />
              </template>
              <template #filterIcon="filterIcon">
                <xFilterIcon :filterIcon="filterIcon" />
              </template>
              <template #hospital="{ text }">
                <a-typography-text
                  style="width: 289px"
                  :ellipsis="{ tooltip: text }"
                  :content="text"
                />
              </template>
            </a-table>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script setup>
import { message } from "ant-design-vue";
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import xFilterIcon from "../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../components/table/filterInputSearchDropdown.vue";
import { comman } from "../../helper/comman.js";
import { constants } from "../../helper/constants";
import { commonService } from "../../services";

const activeTabKey = ref("GPM");
const selectedYear = ref(null);
const tableData = ref([]);
const columns = ref([]);
const loading = ref(false);
const downloadExcelBtnLoading = ref(false);
const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: currentYear - 2022 }, (_, i) => ({
  label: (2023 + i).toString(),
  value: 2023 + i,
}));

const tabList = [
  { key: "GPM", tab: "GPM" },
  { key: "NPM", tab: "NPM" },
  { key: "Revenue", tab: "Revenue" },
];

async function fetchData() {
  loading.value = true;
  try {
    const response = await commonService.get(
      constants.financeQuickbookSalesData,
      {
        year: selectedYear.value || currentYear,
      }
    );

    // let backgroundColor = "";
    // let color = ""
    columns.value = response.data.headers.map((header) => ({
      title: header.title,
      dataIndex: header.key,
      key: header.key,
      width: header.key === "hospital_id" ? 300 : 100,
      align: header.key !== "hospital_id" ? "right" : "left",
      fixed: header.key === "hospital_id" ? "left" : undefined,
      customCell: (record) => {
        let backgroundColor = "";
        let color = "";
        const key = header.key;
        const value = record[key];
        const monthYear = moment(key, "YYYY-MM-DD");
        const currentMonthYear = moment().startOf("month");
        if (monthYear.isValid() && monthYear.isBefore(currentMonthYear)) {
          const previousMonthKey = monthYear
            .clone()
            .subtract(1, "month")
            .format("YYYY-MM-DD");
          const previousValue = record[previousMonthKey];
          if (previousValue !== undefined) {
            if (value > previousValue) {
              backgroundColor = "#00800038";
              color = "green";
            } else if (value < previousValue) {
              backgroundColor = "#ff000038";
              color = "red";
            } else if (value === previousValue) {
              backgroundColor = "#ff000038";
              color = "red";
            }
          }
        }

        return {
          style: {
            padding: "2px 5px",
            backgroundColor,
            color,
            fontSize: "13px",
          },
        };
      },
      sorter: (a, b) => {
        if (header.key != "hospital_id") {
          return a[header.key] - b[header.key];
        } else {
          return a[header.key].localeCompare(b[header.key]);
        }
      },
      ...(header.key == "hospital_id"
        ? {
            onFilter: (value, record) => {
              return record.hospital_id
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            },
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
              customRender: "hospital",
            },
          }
        : {
            customRender: ({ text }) => {
              if (activeTabKey.value === "Revenue") {
                return comman.withCurrency(text);
              }
              return formatPercentage(text);
            },
          }),
    }));

    tableData.value = response.data.rows;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    loading.value = false;
  }
}

function formatPercentage(value) {
  if (typeof value === "number") {
    return `${value.toFixed(2)}%`;
  }
  return value;
}

function updateTableData() {
  tableData.value = tableData.value.map((row) => {
    const rowData = { hospital_id: row.hospital_id };
    columns.value.forEach((col) => {
      if (col.key === "hospital_id") {
        rowData[col.key] = row[col.key];
      } else {
        rowData[col.key] = row[col.key]
          ? row[col.key][activeTabKey.value.toLowerCase()]
          : "-";
      }
    });
    return rowData;
  });
}

function handleDatatableSearch({ confirm }) {
  confirm();
}

function handleDatatableReset({ clearFilters }) {
  clearFilters();
}

function downloadExcel() {
  downloadExcelBtnLoading.value = true;
  commonService
    .downloadFile(
      `${constants.financeQuickbookSalesData}`,
      { year: selectedYear.value, export_excel: true },
      `customer_report_${selectedYear.value}_YTD.xlsx`
    )
    .then((res) => {
      message.success(res);
    })
    .catch((err) => {
      message.error(err);
    })
    .finally(() => {
      downloadExcelBtnLoading.value = false;
    });
}

onMounted(() => {
  selectedYear.value = currentYear;
  fetchData();
});

const dataSource = computed(() => {
  let temp;
  return tableData.value.map((row) => {
    temp = { hospital_id: row.hospital_id };
    columns.value.forEach((col) => {
      if (col.key === "hospital_id") {
        temp[col.key] = row[col.key];
      } else {
        temp[col.key] = row[col.key]
          ? row[col.key][activeTabKey.value.toLowerCase()]
          : "-";
      }
    });
    return temp;
  });
});

function onTabChange(key) {
  activeTabKey.value = key;
}
</script>
