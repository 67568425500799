<template>
  <a-card title="Onboarding" size="small">
    <template #extra>
      <a-button type="primary" @click="addNewAttachment">
        <a-space size="small">
          <i class="ti ti-plus ti-lg"></i>
          Add New
        </a-space>
      </a-button>
    </template>

    <a-form :model="model" layout="vertical" @finish="onSubmit">
      <a-form-item
        label="Notes"
        name="onboarding"
        :rules="{
          required: true,
          message: 'This field is required.',
        }"
      >
        <a-textarea
          v-model:value="model.onboarding"
          placeholder="Enter here..."
          :rows="8"
        />
      </a-form-item>

      <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
        Save
      </a-button>
    </a-form>

    <a-divider orientation="left">Attachments</a-divider>

    <a-table
      size="small"
      :columns="columns"
      :data-source="attachments"
      :rowKey="(record) => record.id"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: true, y: 0 }"
    >
      <template #attachment="{ record }">
        <a :href="record.full_file_path" target="_blank">
          {{ record.file }}
        </a>
      </template>

      <template #actions="{ record }">
        <a-tooltip title="Edit">
          <a-button
            type="link"
            size="small"
            class="edit-color pl-sm-0"
            @click="editAttachment(record)"
          >
            <i class="ti ti-pencil ti-lg"></i>
          </a-button>
        </a-tooltip>

        <a-popconfirm
          title="Are you sure you want to delete this attachment?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteAttachment(record.id)"
        >
          <a-tooltip title="Delete">
            <a-button type="link" size="small" class="danger-color">
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-popconfirm>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  name: "hospital-onboarding",
  components: {
    PlusOutlined,
  },

  props: {
    hospital: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      submitBtnLoader: false,
      loading: false,
      attachments: [],
      model: {
        onboarding: "",
      },
      columns: [
        {
          title: "#",
          dataIndex: "id",
          customerRender: ({ index }) => index,
        },
        {
          title: "File Name",
          dataIndex: "file_name",
        },
        {
          title: "Attachment",
          dataIndex: "attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
    };
  },

  mixins: [formMixins],

  mounted() {
    this.model.onboarding = this.hospital.onboarding;
    this.getHospitalOnboardingAttachments();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    onSubmit() {
      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.hospitalStoreOnboardingUrl, {
          onboarding: this.model.onboarding,
          id: this.hospital.id,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          }
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        })
        .finally(() => {
          this.submitBtnLoader = false;
        });
    },

    getHospitalOnboardingAttachments() {
      this.loading = true;
      return commonService
        .get(this.$constants.hospitalOnboardingAttachmentsUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.attachments = res.data;
          this.loading = false;
        });
    },

    addNewAttachment() {
      this.open({
        title: "Add new hospital onboarding attachment",
        path: "hospital.drawerForm.storeOnboardingAttachment",
        callback: this.getHospitalOnboardingAttachments,
        record: { hospital_id: this.hospital.id },
      });
    },

    editAttachment(record) {
      this.open({
        title: "Edit hospital onboarding attachment",
        path: "hospital.drawerForm.storeOnboardingAttachment",
        callback: this.getHospitalOnboardingAttachments,
        record,
      });
    },

    deleteAttachment(id) {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalOnboardingAttachmentDeleteUrl, { id })
        .then((res) => {
          this.$message.success(res.message);
          this.getHospitalOnboardingAttachments();
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
  },
};
</script>
