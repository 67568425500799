<template>
  <a-layout-content>
    <page-header>
      <template #title> Recent Updates </template>

      <template #buttons>
        <a-button type="primary" @click="createNewRecentUpdate">
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add Recent Update
          </a-space>
        </a-button>
      </template>
    </page-header>

    <template v-if="recentUpdates.length > 0">
      <template v-for="(recentUpdate, index) in recentUpdates" :key="index">
        <a-card size="small" hoverable class="mb-sm-1">
          <template #title>
            {{ recentUpdate.title }} |
            <a-typography-text type="secondary" class="mb-sm-0 text-medium">
              {{ $customDate.mdy(recentUpdate.published_date) }}
            </a-typography-text>
            <template v-if="recentUpdate.type == 1">
              | <a-tag color="blue">For Admin</a-tag>
            </template>
          </template>

          <template #extra>
            <a-space>
              <a-tooltip title="Edit">
                <a-button
                  type="link"
                  size="small"
                  @click="editRecentUpdate(recentUpdate)"
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-popconfirm
                cancel-text="No"
                ok-text="Yes"
                title="Are you sure you want to delete this recent update record?"
                @confirm="deleteRecentUpdate(recentUpdate)"
              >
                <a-tooltip title="Delete" placement="bottom">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </a-space>
          </template>

          <div v-html="recentUpdate.description"></div>
        </a-card>
      </template>
    </template>

    <a-card v-else>
      <a-empty />
    </a-card>
  </a-layout-content>
</template>

<script setup>
import { message } from "ant-design-vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";

const store = useStore();
const spinning = ref(false);
const recentUpdates = ref([]);

const fetchRecentUpdates = () => {
  commonService
    .get(constants.adminRecentUpdateDatatableUrl)
    .then((res) => (recentUpdates.value = res.data))
    .finally(() => (spinning.value = false));
};

onMounted(() => {
  fetchRecentUpdates();
});

const createNewRecentUpdate = () => {
  store.dispatch("drawer/open", {
    title: "Add Recent Update",
    path: "admin.recentUpdate.store",
    callback: fetchRecentUpdates,
  });
};

const editRecentUpdate = (record) => {
  store.dispatch("drawer/open", {
    title: "Edit Recent Update",
    path: "admin.recentUpdate.store",
    callback: fetchRecentUpdates,
    record,
  });
};

const deleteRecentUpdate = (rec) => {
  spinning.value = true;
  commonService
    .store(constants.adminRecentUpdateChangeStatusUrl, { id: rec.id })
    .then((res) => message.success(res.message))
    .finally((err) => fetchRecentUpdates());
};
</script>
