<template>
  <a-form
    name="event_booking_from"
    ref="event_booking_from"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <template
      v-if="
        record.status == 1 && $comman.access('calendar-event-assign-to-other')
      "
    >
      <a-form-item ref="employee" label="Assigned To" name="employee">
        <a-select
          v-model:value="model.assign_to"
          placeholder="Select employee"
          optionFilterProp="label"
          show-search
        >
          <a-select-option
            v-for="employee in extra.employees"
            :key="employee.id"
            :label="employee.full_name"
            :value="employee.id"
          >
            {{ employee.full_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </template>

    <a-descriptions title="" :column="2">
      <a-descriptions-item label="Title" :span="2">
        {{ record.title }}
      </a-descriptions-item>

      <a-descriptions-item label="Hospital" :span="2">
        {{ record.hospital?.name }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="record.status != 1"
        label="Assigned To"
        :span="2"
      >
        {{ record.assigned_employee?.full_name ?? "-" }}
      </a-descriptions-item>

      <a-descriptions-item label="Servie" :span="1">
        {{ record.case_service?.name ?? "-" }}
      </a-descriptions-item>

      <a-descriptions-item label="Date" :span="1">
        {{ $customDate.ll(record.date) }}
      </a-descriptions-item>

      <a-descriptions-item label="Is Full Day Event?" :span="1">
        <a-tag v-if="record.is_full_day_event == 1" color="green">Yes</a-tag>
        <a-tag v-if="record.is_full_day_event == 0" color="red">No</a-tag>
      </a-descriptions-item>

      <a-descriptions-item
        v-if="record.is_full_day_event == 0"
        label="Time"
        :span="1"
      >
        {{ $customDate.hia(record.start_time) }} -
        {{ $customDate.hia(record.end_time) }}
      </a-descriptions-item>

      <a-descriptions-item label="Status" :span="1">
        {{ getStatus }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="">
      <a-descriptions-item label="Notes">
        <span v-html="getNotes" />
      </a-descriptions-item>
    </a-descriptions>

    <template
      v-if="$comman.access('calendar-event-book') && record.status == 1"
    >
      <side-drawer-buttons name="Book" />
    </template>
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";

export default {
  data() {
    return {
      model: {
        assign_to: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    getStatus() {
      let status = this.$constants.calendarEventStatus.find(
        (v) => v.value == this.record.status
      );
      if (status) {
        return status.text;
      }
      return "-";
    },

    getNotes() {
      let notes = this.$constants.calendarEventNotesString;
      if (this.record.notes) {
        notes = this.record.notes;
      }
      return notes.replaceAll("\n", "<br />");
    },
  },

  mounted() {
    this.model.assign_to = this.employee.id;
    console.log(this.record);
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      let data = {
        assign_to: this.model.assign_to,
        event_id: this.record.id,
      };

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, data)
        .then((res) => {
          this.loadingStop();
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
          this.close();
          this.$emit("callback");
        });
    },
  },
};
</script>
