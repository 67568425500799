<template>
  <a-layout id="components-layout-demo-side">
    <!-- <a-layout-sider v-model:collapsed="collapsed">
      <div class="logo">
        <template v-if="collapsed">
          <img src="@/assets/48_48.png" alt="" class="img-fluid">
        </template>
        <template v-else>
          <img src="@/assets/168_44.png" alt="" class="img-fluid">
        </template>
      </div>
      <sidebar-nav />
    </a-layout-sider> -->
    <a-row
      :class="isLiveChartingPage ? 'live-charting' : ''"
      :gutter="[0, 0]"
      class="main-layout-row"
    >
      <a-col :lg="0" :md="0" :sm="0" :xl="4" :xxl="4" class="sidebar">
        <a-affix :offset-top="0">
          <sidebar-nav />
        </a-affix>
      </a-col>
      <a-col
        :class="isLiveChartingPage ? 'monitor-panel' : ''"
        :lg="24"
        :md="24"
        :sm="24"
        :xl="20"
        :xxl="20"
      >
        <a-layout-header>
          <div class="top-header">
            <a-row type="flex">
              <a-col v-if="isLiveChartingPage">
                <img
                  width="200"
                  alt="keyport"
                  class="img-fluid"
                  src="@/assets/keyport-header-logo.svg"
                />
              </a-col>

              <a-col class="nav-toggler">
                <menu-unfold-outlined
                  v-if="collapsed"
                  @click="() => (collapsed = !collapsed)"
                  class="trigger"
                />
                <menu-fold-outlined
                  v-else
                  @click="() => (collapsed = !collapsed)"
                  class="trigger"
                />
              </a-col>
              <!-- <a-col>
                <a-input-search
                  placeholder="Search... (Crtl+/)"
                  size="large"
                  @search="onSearch"
                />
              </a-col> -->

              <a-col class="user-login">
                <a-badge
                  v-if="!isOnboardingPage && !isExpiredCredentialsPage"
                  :count="incompleteTodoCount ?? 0"
                  :offset="[-15, 0]"
                >
                  <a-button
                    type="primary"
                    ghost
                    class="mr-sm-1"
                    @click="() => openTasksDrawer()"
                  >
                    <a-space align="center">
                      <i class="ti ti-list-check"></i> To Do
                    </a-space>
                  </a-button>
                </a-badge>

                <router-link class="mr-sm-1" :to="{ name: 'action-items' }">
                  <a-button v-if="isAdmin" ghost size="default" type="primary">
                    <a-space align="center">
                      <i class="ti ti-wand-off"></i> Action Items
                    </a-space>
                  </a-button>
                </router-link>

                <NotificationBell
                  v-if="!isOnboardingPage && !isExpiredCredentialsPage"
                />

                <a-dropdown
                  :overlayStyle="{ top: '64px' }"
                  :trigger="['click']"
                  class="navbar-dropdown"
                >
                  <a class="ant-dropdown-link" @click.prevent>
                    <template v-if="employee.profile_pic">
                      <a-avatar :src="employee.profile_pic_url">
                        {{ employee?.last_name?.charAt(0)
                        }}{{ employee?.first_name?.charAt(0) }}
                      </a-avatar>
                    </template>
                    <template v-else>
                      <a-avatar size="small">{{ username.charAt(0) }}</a-avatar>
                    </template>
                    <span class="user">
                      {{ username }}
                    </span>
                  </a>

                  <template #overlay>
                    <a-menu class="navbar-dropdown-menu">
                      <router-link
                        v-if="!isOnboardingPage && !isExpiredCredentialsPage"
                        :to="{ name: 'profile' }"
                      >
                        <a-menu-item v-if="employee" key="profile">
                          <i class="fas fa-user"></i>
                          <span class="ml-sm-1">Profile</span>
                        </a-menu-item>
                      </router-link>
                      <a-menu-item @click="logout()">
                        <i class="fas fa-sign-out-alt"></i>
                        <span class="ml-sm-1">Logout</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-col>
            </a-row>
          </div>
        </a-layout-header>
        <div
          :style="{
            marginBottom: isMobile ? '120px' : '100px',
          }"
        >
          <slot />
        </div>
        <a-layout-footer size="small">
          <LayoutFooter />
        </a-layout-footer>
        <side-drawer />
        <modal />
        <a-drawer
          v-model:visible="collapsed"
          :bodyStyle="{ padding: 0 }"
          :closable="false"
          :drawerStyle="{ backgroundColor: '#001529' }"
          placement="left"
          title=""
        >
          <sidebar-nav />
        </a-drawer>
      </a-col>
    </a-row>

    <a-modal
      v-model:visible="hippaModal"
      :bodyStyle="{ height: '500px', overflowY: 'scroll' }"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      centered
      width="1000px"
      wrapClassName="hippa-modal"
    >
      <template #title>
        <a-typography-title :level="3">
          HIPAA Employee Confidentiality Agreement
        </a-typography-title>
      </template>

      <template #footer>
        <a-button
          @click="handleAgreedButtonClick"
          :loading="agreeBtnLoading"
          type="primary"
        >
          Agree
        </a-button>
      </template>

      <a-space direction="vertical">
        <a-typography-text>
          THIS AGREEMENT entered into this
          <span class="text-medium">
            {{ moment().format("DD") }} day of {{ moment().format("MMM") }},
            {{ moment().format("YYYY") }},
          </span>
          by and between Keystone Perfusion Services, PC, known as the
          “EMPLOYER”, and
          <span class="text-medium"> {{ employee.full_name }}, </span>
          known as the “ASSOCIATE”, and known collectively as the “Parties”, set
          forth the terms and conditions under which information created or
          received by or on behalf of this EMPLOYER (known collectively as
          protected health information or “PHI”) may be used or disclosed under
          State law and the Health Insurance Portability and Accountability Act
          of 1996 and updated through HIPAA Omnibus Rule of 2013 and will also
          uphold regulations enacted there under (hereafter “HIPAA”).
        </a-typography-text>

        <a-typography-text>
          THEREFORE, in consideration of the premises and the covenants and
          agreements contained herein, the Parties hereto, intending to be
          legally bound hereby, covenant and agree as follows:
        </a-typography-text>

        <ol class="mb-sm-0">
          <a-space direction="vertical">
            <li>
              <span class="text-medium">Confidential Information.</span> The
              Parties acknowledge that meaningful employment may or will
              necessitate disclosure of Confidential Information by this
              EMPLOYER to the ASSOCIATE and use of Confidential Information by
              the ASSOCIATE. The term “Confidential Information” includes, but
              is not limited to, PHI, any information about patients or other
              employees, any computer log-on codes or passwords, any patient
              records or billing information, any patient lists, any financial
              information about this EMPLOYER or its patients that is not
              public, any intellectual property rights of Practice, any
              proprietary information of Practice and any information that
              concerns this EMPLOYER’S contractual relationships, relates to
              this EMPLOYER’S competitive advantages, or is otherwise designated
              as confidential by this EMPLOYER.
            </li>
            <li>
              <span class="text-medium">Disclosure.</span> Disclosure and use of
              Confidential Information includes oral communications as well as
              display or distribution of tangible physical documentation, in
              whole or in part, from any source or in any format (e.g., paper,
              digital, electronic, internet, social networks, magnetic or
              optical media, film, etc.). The Parties have entered into this
              Agreement to induce use and disclosure of Confidential Information
              and are relying on the covenants contained herein in making any
              such use or disclosure. This EMPLOYER, not the ASSOCIATE, is the
              records owner under state law and the ASSOCIATE has no right or
              ownership interest in any Confidential Information.
            </li>
            <li>
              <span class="text-medium">Applicable Law.</span> Confidential
              Information will not be used or disclosed by the ASSOCIATE in
              violation of applicable law, including but not limited to HIPAA
              Federal and State records owner statute; this Agreement; the
              Practice’s Notice of Privacy Practices, as amended; or other
              limitations as put in place by Practice from time to time. The
              intent of this Agreement is to ensure that the ASSOCIATE will use
              and access only the minimum amount of Confidential Information
              necessary to perform the ASSOCIATE’s duties and will not disclose
              Confidential Information outside this EMPLOYER unless expressly
              authorized in writing to do so by this EMPLOYER. All Confidential
              Information received (or which may be received in the future) by
              ASSOCIATE will be held and treated by him or her as confidential
              and will not be disclosed in any manner whatsoever, in whole or in
              part, except as authorized by this EMPLOYER and will not be used
              other than in connection with the employment relationship.
            </li>
            <li>
              <span class="text-medium">Log-on Code and Password.</span> The
              ASSOCIATE understands that he or she will be assigned a log-on
              code or password by EMPLOYER, which may be changed as this
              EMPLOYER, in its sole discretion, sees fit. The ASSOCIATE will not
              leave Confidential Information unattended (e.g., so that it
              remains visible on computer screens after the ASSOCIATE’s use).
              The ASSOCIATE agrees that his or her log-on code or password is
              equivalent to a legally-binding signature and will not be
              disclosed to or used by anyone other than the ASSOCIATE. Nor will
              the ASSOCIATE use or even attempt to learn another person’s log-on
              code or password. The ASSOCIATE immediately will notify this
              EMPLOYER’S HIPAA Privacy Officer upon suspecting that his or her
              log-on code or password no longer is confidential. The ASSOCIATE
              agrees that all computer systems are the exclusive property of
              Practice and will not be used by the ASSOCIATE for any purpose
              unrelated to his or her employment. The ASSOCIATE acknowledges
              that he or she has no right of privacy when using this EMPLOYER’S
              computer systems and that his or her computer use periodically
              will be monitored by this EMPLOYER to ensure compliance with this
              Agreement and applicable law.
            </li>
            <li>
              <span class="text-medium"
                >Returning Confidential Information.</span
              >
              Immediately upon request by this EMPLOYER, the ASSOCIATE will
              return all Confidential Information to this EMPLOYER and will not
              retain any copies of any Confidential Information, except as
              otherwise expressly permitted in writing signed by this EMPLOYER.
              All Confidential Information, including copies thereof, will
              remain and be the exclusive property of this EMPLOYER, unless
              otherwise required by applicable law. The ASSOCIATE specifically
              agrees that he or she will not, and will not allow anyone working
              on their behalf or affiliated with the ASSOCIATE in any way, use
              any or all of the Confidential Information for any purpose other
              than as expressly allowed by this Agreement. The ASSOCIATE
              understands that violating the terms of this Agreement may, in
              this EMPLOYER’S sole discretion, result in disciplinary action
              including termination of employment and/or legal action to prevent
              or recover damages for breach. Breach reporting is imperative.
            </li>
            <li>
              <span class="text-medium">Breach.</span> The Parties agree that
              any breach of any of the covenants or agreements set forth herein
              by the ASSOCIATE will result in irreparable injury to this
              EMPLOYER for which money damages are inadequate; therefore, in the
              event of a breach or an anticipatory breach, Practice will be
              entitled (in addition to any other rights and remedies which it
              may have at law or in equity, including money damages) to have an
              injunction without bond issued enjoining and restraining the
              ASSOCIATE and/or any other person involved from breaching this
              Agreement.
            </li>
            <li>
              <span class="text-medium">Binding Arrangement.</span> This
              Agreement shall be binding upon and endure to the benefit of all
              Parties hereto and to each of their successors, assigns, officers,
              agents, employees, shareholders and directors. This Agreement
              commences on the date set forth above and the terms of this
              Agreement shall survive any termination, cancellation, expiration
              or other conclusion of this Agreement unless the Parties otherwise
              expressly agree in writing.
            </li>
            <li>
              <span class="text-medium">Governing Law.</span> The Parties agree
              that the interpretation, legal effect and enforcement of this
              Agreement shall be governed by the laws in the State of South
              Carolina and by execution hereof, each party agrees to the
              jurisdiction of the courts of the State. The Parties agree that
              any suit arising out of or relation to this Agreement shall be
              brought in the county where this EMPLOYER’S principal place of
              business is located.
            </li>
            <li>
              <span class="text-medium">Severability.</span> If any provision
              under this Agreement shall be held invalid or unenforceable for
              any reason, the remaining provisions and statements shall continue
              to be valid and enforceable.
            </li>
          </a-space>
        </ol>

        <a-typography-text>
          IN WITNESS WHEREOF, and intending to be legally bound, the Parties
          hereto have executed this Agreement on the date first above written,
          when agreeing below and after training on HIPAA Law with full
          understanding this agreement shall stand.
        </a-typography-text>
      </a-space>
    </a-modal>

    <a-modal
      v-model:visible="handbookModal"
      :bodyStyle="{ height: '500px', overflowY: 'scroll' }"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      centered
      width="1000px"
      wrapClassName="handbook-modal"
    >
      <template #title>
        <a-typography-title :level="3">
          Employee Handbook Receipt Acknowledgment
        </a-typography-title>
      </template>

      <template #footer>
        <a-button
          v-if="handbookSign"
          @click="handbookSignClear"
          danger
          ghost
          htmlType="button"
          type="primary"
        >
          Clear Signature
        </a-button>

        <a-button
          @click="handleHandbookAgreedButtonClick"
          :disabled="handbookSign == null"
          :loading="handbookAgreeBtnLoading"
          type="primary"
        >
          Agree
        </a-button>
      </template>

      <a-space direction="vertical">
        <a :href="handbook ? handbook.full_file_path : '#'" target="_blank">
          [DOWNLOAD LINK OF EMPLOYEE HANDBOOK]
        </a>

        <a-typography-text>
          The employee handbook describes important information about Keystone
          Perfusion Services, PC and I understand that I should consult human
          resources regarding any questions not answered in the handbook. I have
          entered into my employment relationship with Keystone Perfusion
          Services, PC voluntarily and acknowledge that there is no specified
          length of employment.
          <span class="text-medium">
            Accordingly, either I or Keystone Perfusion Services, PC can
            terminate the relationship at will, with or without cause, at any
            time, so long as there is not violation of applicable federal or
            state law.
          </span>
        </a-typography-text>

        <a-typography-text>
          I understand and agree that no manager, supervisor, or representative
          of Keystone Perfusion Services, PC has any authority to enter into any
          agreement for employment other than at-will. Only the Executive
          Leadership team of the company has the authority to make any such
          agreement and then only in writing signed by the President or Vice
          Presidents of Keystone Perfusion Services, PC.
        </a-typography-text>

        <a-typography-text>
          This manual and the policies and procedures contained herein supersede
          any and all prior practices, oral or written representations, or
          statements regarding the terms and conditions of your employment with
          Keystone Perfusion Services, PC. By distributing this handbook,
          Keystone Perfusion Services, PC expressly revokes any and all previous
          policies and procedures which are inconsistent with those contained
          herein.
        </a-typography-text>

        <a-typography-text>
          I understand that, except for employment at-will status, any and all
          policies and practices may be changed at any time by Keystone
          Perfusion Services, PC, and the company reserves the right to change
          my hours, wages and working conditions at any time. All such changes
          will be communicated through official notices, and I understand that
          revised information may supersede, modify, or eliminate existing
          policies.
        </a-typography-text>

        <a-typography-text>
          <span class="text-medium">
            I understand and agree that nothing in the employee handbook
            creates, or is intended to create, a promise or representation of
            continued employment and that employment by Keystone Perfusion
            Services, PC is employment at-will, which may be terminated at the
            will of either Keystone Perfusion Services, PC or myself.
            Furthermore, I acknowledge that this handbook is neither a contract
            of employment nor a legal document.
          </span>
          I understand and agree that employment and compensation may be
          terminated with or without cause and with or without notice at any
          time by Keystone Perfusion Services, PC or myself.
        </a-typography-text>

        <a-space
          align="center"
          class="mt-sm-2"
          direction="vertical"
          style="width: 100%"
        >
          <vue-signature-pad
            ref="signaturePad"
            :customStyle="$constants.signPadCustomCss"
            :options="{ onEnd: handbookSignSave }"
          />

          <a-typography-paragraph style="width: 600px; text-align: center">
            I have received the handbook, and I understand that it is my
            responsibility to read and comply with the policies contained in
            this handbook and any revisions made to it.
          </a-typography-paragraph>
        </a-space>
      </a-space>
    </a-modal>

    <a-modal
      v-if="false"
      v-model:visible="showInactiveModal"
      :closable="false"
      :footer="false"
      :keyboard="false"
      :maskClosable="false"
      centered
      class="inactive-modal"
    >
      <a-space
        :size="20"
        align="center"
        direction="vertical"
        style="width: 100%"
      >
        <a-typography-title :level="3" class="mb-sm-0" type="warning">
          <exclamation-circle-outlined />
        </a-typography-title>

        <a-space :size="1" align="center" direction="vertical">
          <a-typography-title :level="3">
            Are you still there?
          </a-typography-title>
          <a-typography-title :level="4">
            We'll sign you out in
            <strong>{{ inactiveModalCountdown }} Sec</strong>
          </a-typography-title>
          <br />
          <a-typography-text class="font-16">
            For security reasons,
          </a-typography-text>
          <a-typography-text class="font-16">
            we sign you out of your KeyPort account
          </a-typography-text>
          <a-typography-text class="font-16">
            when you're inactive for 15 minutes.
          </a-typography-text>
        </a-space>
      </a-space>

      <a-divider />

      <div class="model-footer">
        <a-button @click="updateLastSeen" class="mr-sm-2" type="primary">
          I need more time
        </a-button>
        <a-button @click="logout()" danger>Sign out</a-button>
      </div>
    </a-modal>

    <a-modal
      v-model:visible="agreementAcceptModal"
      :bodyStyle="{ height: '500px', overflowY: 'scroll' }"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      centered
      width="1000px"
      wrapClassName="agreement-accept-modal"
    >
      <template #title>
        <a-typography-title :level="3">
          Acceptable Use Agreement for KeyPort
        </a-typography-title>
      </template>

      <template #footer>
        <a-button
          @click="handleAgreementAcceptButtonClick"
          :loading="agreementAcceptBtnLoading"
          type="primary"
        >
          Agree
        </a-button>
      </template>

      <a-space direction="vertical">
        <a-typography-text>
          <span class="text-medium">Purpose:</span> This acceptable use
          agreement (the "Agreement") sets forth the terms and conditions for
          the use of KeyPort, the proprietary enterprise resource planning
          software owned and provided by Keystone Perfusion Services, PC (the
          "Company").
        </a-typography-text>

        <a-typography-text>
          <span class="text-medium">Scope:</span> This Agreement applies to all
          employees of the Company who use KeyPort for any purpose, including
          but not limited to accessing, downloading, storing, or transmitting
          data, information, or other content through KeyPort.
        </a-typography-text>

        <a-typography-text>
          <span class="text-bold">Responsibilities:</span>
          <ol style="margin-bottom: 0 !important">
            <a-space :size="2" direction="vertical">
              <li>
                <span class="text-medium">Confidentiality:</span> All employees
                shall maintain the confidentiality of the information contained
                in KeyPort and shall not disclose such information to any
                unauthorized person or use it for any unauthorized purpose.
              </li>
              <li>
                <span class="text-medium">Compliance with Law:</span> All
                employees shall use KeyPort in compliance with all applicable
                laws, rules, and regulations.
              </li>
              <li>
                <span class="text-medium">Proprietary Rights:</span> All
                employees shall not copy, modify, reverse engineer, decompile,
                or otherwise attempt to extract the source code of KeyPort or
                any portion thereof.
              </li>
              <li>
                <span class="text-medium">System Security:</span> All employees
                shall use KeyPort in a manner that does not interfere with or
                disrupt the normal operation of the software or the systems on
                which it operates. Employees shall not access or attempt to
                access KeyPort without proper authorization.
              </li>
            </a-space>
          </ol>
        </a-typography-text>

        <a-typography-text>
          <span class="text-bold">
            Prohibitions: The following activities are strictly prohibited:
          </span>
          <ol style="margin-bottom: 0 !important">
            <a-space :size="2" direction="vertical">
              <li>Unauthorized access or use of KeyPort</li>
              <li>
                Attempts to interfere with the proper functioning of KeyPort
              </li>
              <li>
                Sharing login credentials or allowing unauthorized access to
                KeyPort
              </li>
              <li>Using KeyPort for illegal or unethical purposes</li>
              <li>
                Downloading, copying, or otherwise misusing proprietary
                information contained in KeyPort
              </li>
            </a-space>
          </ol>
        </a-typography-text>

        <a-typography-text>
          <span class="text-medium">Enforcement:</span> The Company may take any
          action it deems necessary to enforce this Agreement, including but not
          limited to immediate termination of access to KeyPort and termination
          of employment. Any violation of this Agreement may also result in
          civil or criminal liability.
        </a-typography-text>

        <a-typography-text>
          <span class="text-medium">Amendments:</span> This Agreement may be
          amended by the Company at any time, with or without notice to
          employees.
        </a-typography-text>

        <a-typography-text>
          <span class="text-medium">Acceptance:</span> By using KeyPort,
          employees acknowledge that they have read and agree to be bound by the
          terms and conditions of this Agreement.
        </a-typography-text>

        <a-typography-text>
          <span class="text-medium">Effective Date:</span> This Agreement is
          effective as of the date of acceptance by the employee.
        </a-typography-text>
      </a-space>
    </a-modal>

    <a-modal
      :visible="isIdleLocked"
      :bodyStyle="{ overflowY: 'hidden' }"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      :footer="false"
      centered
      :maskStyle="{
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        backdropFilter: 'blur(16px)',
      }"
    >
      <a-space direction="vertical" :size="25" style="width: 100%">
        <a-space direction="vertical" :size="10" style="width: 100%">
          <a-alert type="warning" show-icon>
            <template #description>
              You have been locked out due to inactivity.
              <br />
              You'll be logged out automatically in
              <a-typography-text type="danger" strong>
                {{ formatInactiveModalCountdown }}
              </a-typography-text>
            </template>
          </a-alert>
        </a-space>

        <a-row align="middle" justify="center" :gutter="[10, 10]">
          <a-col>
            <a-avatar :src="employee.profile_pic_url" size="large">
              {{ employee?.last_name?.charAt(0) }}
              {{ employee?.first_name?.charAt(0) }}
            </a-avatar>
          </a-col>
          <a-col>
            <a-typography-title :level="5" style="margin: 0">
              {{ username }}
            </a-typography-title>
          </a-col>
        </a-row>

        <a-form
          @finish="handleIdleLockScreenContinue"
          :model="idleLockScreenForm"
          layout="vertical"
        >
          <a-space direction="vertical" :size="10" style="width: 100%">
            <a-form-item
              name="password"
              :rules="{
                required: true,
                message: 'Password field is required!',
              }"
              :validateStatus="passwordError ? 'error' : ''"
              :help="passwordError"
            >
              <a-input-password
                v-model:value="idleLockScreenForm.password"
                placeholder="Enter your password"
                @change="passwordError = ''"
              />
            </a-form-item>

            <a-row justify="end">
              <a-button
                htmlType="submit"
                type="primary"
                :loading="idleSubmitLoading"
              >
                Continue Session
              </a-button>

              <a-button
                class="ml-sm-1"
                @click="handleIdleLockLogout"
                danger
                :loading="idleSubmitLoading"
              >
                Logout
              </a-button>
            </a-row>
          </a-space>
        </a-form>
      </a-space>
    </a-modal>

    <PolicyRuleModal
      v-if="policyRule && !isAdmin && profileStatus === 1"
      v-model:handbookModal="showPolicyRuleModal"
      disableClosing
      :agreement="policyRule"
      :closeDialog="onPolicyRuleSubmit"
    />
  </a-layout>
</template>

<script>
import LayoutFooter from "@/components/layoutFooter.vue";
import { useWindowSize } from "@/composition/useWindowSize";
import { pusher } from "@/pusher";
import { commonService } from "@/services";
import {
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons-vue";
import { Splide, SplideTrack } from "@splidejs/vue-splide";
import NotificationBell from "@/components/notificationBell.vue";
import moment from "moment";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import modal from "../components/modal.vue";
import PolicyRuleModal from "../components/policyRuleModal.vue";
import SidebarNav from "../components/sidebarNavNew.vue";

export default {
  components: {
    LayoutFooter,
    Splide,
    SplideTrack,
    NotificationBell,
    SidebarNav,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ExclamationCircleOutlined,
    modal,
    PolicyRuleModal,
  },
  data() {
    return {
      collapsed: false,
      openKeys: [],
      agreeBtnLoading: false,
      handbookAgreeBtnLoading: false,
      agreementAcceptBtnLoading: false,
      handbookSign: null,
      handbook: null,
      idleLockScreen: false,
      idleLockScreenForm: {},

      moment,

      // IDLE UI CONFIG VALUES
      interval: null,
      idleInactiveTime: this.isLiveChartingPage ? 88 * 60 : 15 * 60,
      showInactiveModal: false,
      inactiveModalTime: 2 * 60,
      autoLogoutTime: 15 * 60,
      inactiveModalCountdown: null,

      policyRule: null,
      showPolicyRuleModal: false,

      idleSubmitLoading: false,
      passwordError: "",
    };
  },

  async mounted() {
    this.fetchLastHandbook();

    this.policyRule = this.policyRuleDocument;
    if (this.policyRule) {
      this.showPolicyRuleModal = true;
    }

    // Register an event listener when the Vue component is ready
    window.addEventListener("resize", this.onResize);
    const jobs = pusher.subscribe("private-jobs" + this.user.id);
    jobs.bind(
      "sync-employees",
      function (data) {
        if (typeof data == "string") {
          data = JSON.parse(data);
        }
        if (data.success) {
          this.$notification.success({
            message: "Success",
            description: data.message,
          });
        } else {
          this.$notification.error({
            message: "Failed",
            description: data.message,
          });
        }
      },
      this
    );

    window.addEventListener("mousemove", this.updateLastSeenFromEvent);
    window.addEventListener("keydown", this.updateLastSeenFromEvent);
    window.addEventListener("wheel", function (event) {
      if (
        document.activeElement.type === "number" &&
        (document.activeElement.classList.contains("ant-input-number-input") ||
          document.activeElement.classList.contains("hide-arrow"))
      ) {
        document.activeElement.blur();
      }
    });

    this.interval = setInterval(() => this.checkLastSeen(), 1000);
  },
  // unmounted() {
  //   localStorage.removeItem("IDLE_LOCK");
  // },

  beforeUnmount() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
    pusher.unsubscribe("private-jobs" + this.user.id);

    window.removeEventListener("mousemove", this.updateLastSeenFromEvent);
    window.removeEventListener("keydown", this.updateLastSeenFromEvent);
    clearInterval(this.interval);
  },

  computed: {
    ...mapGetters("auth", [
      "employee",
      "isLoggedIn",
      "user",
      "token",
      "isAdmin",
      "policyRuleDocument",
      "profileStatus",
      "isIdleLocked",
      "incompleteTodoCount",
    ]),
    ...mapGetters("cart", ["cartQty"]),

    formatInactiveModalCountdown() {
      // format to 4m:30s using moment
      return moment
        .utc(this.inactiveModalCountdown * 1000)
        .format("m[min] s[sec]");
    },

    username() {
      if (this.isLoggedIn) {
        return this.employee.full_name;
      } else {
        return "User Name";
      }
    },
    avatarUrl() {
      return (
        "https://avatars.dicebear.com/v2/initials/" + this.username + ".svg"
      );
    },
    onSearch() {},
    hippaModal() {
      return false;
      // return this.employee.hippa_compliance_agreed_at == null ? true : false;
    },
    handbookModal() {
      return false;
      // return !this.hippaModal && this.employee.handbook_agreed_at == null;
    },
    agreementAcceptModal() {
      return (
        !this.hippaModal &&
        !this.handbookModal &&
        this.employee.agreement_accepted_at == null
      );
    },

    isLiveChartingPage() {
      return [
        "case-report-live-charting",
        "admin.case-report-live-charting",
        "manager.case-report-live-charting",
        "onboarding",
        "credentials-update",
      ].includes(this.$route.name);
    },

    isOnboardingPage() {
      return this.$route.name === "onboarding";
    },

    isExpiredCredentialsPage() {
      return this.$route.name === "credentials-update";
    },
  },

  methods: {
    ...mapActions("auth", [
      "logout",
      "update_employee",
      "set_session_logout",
      "set_idle_lockscreen_status",
    ]),
    ...mapActions("drawer", ["open"]),

    openTasksDrawer() {
      this.open({
        title: "To Do List",
        path: "todo.store",
        width: "800px",
        record: {},
        callback: () => {},
      });
    },

    handleIdleLockScreenContinue() {
      this.passwordError = "";
      this.idleSubmitLoading = true;
      commonService
        .store(this.$constants.userAfterIdleValidation, {
          password: this.idleLockScreenForm.password,
        })
        .then((res) => {
          if (res.success) {
            this.set_idle_lockscreen_status(false);
            this.idleLockScreenForm.password = null;
            localStorage.setItem("lastSeen", parseInt(Date.now() / 1000));
          } else {
            this.passwordError = res.message;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.idleSubmitLoading = false;
        });
    },

    handleIdleLockLogout() {
      this.logout({ redirectTo: this.$route.fullPath });
      // this.set_session_logout(true);
    },

    onPolicyRuleSubmit(nextPolicy) {
      this.showPolicyRuleModal = false;

      if (nextPolicy) {
        this.policyRule = nextPolicy;
        this.showPolicyRuleModal = true;
      }
    },

    onResize(event) {
      if (window.innerWidth >= "1200") {
        this.collapsed = false;
      }
    },

    handleCartDrawerEvent() {
      this.open({
        title: "Cart Items",
        path: "order.cart",
        width: "800px",
      });
    },

    fetchLastHandbook() {
      commonService.get(this.$constants.employeeHandbookUrl).then((res) => {
        this.handbook = res.data;
      });
    },

    handleAgreedButtonClick() {
      this.agreeBtnLoading = true;
      commonService
        .store(this.$constants.hippaComplianceAgreed)
        .then((res) => {
          this.$message.success(res.message);
          this.update_employee(res.employee);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.agreeBtnLoading = false;
        });
    },

    handbookSignClear() {
      this.handbookSign = null;
      this.$refs.signaturePad.clearSignature();
    },

    handbookSignSave() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.handbookSign = data;
      return Promise.resolve();
    },

    handleHandbookAgreedButtonClick() {
      this.handbookAgreeBtnLoading = true;
      commonService
        .store(this.$constants.handbookAgreed, { sign: this.handbookSign })
        .then((res) => {
          this.$message.success(res.message);
          this.update_employee(res.employee);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.handbookAgreeBtnLoading = false;
        });
    },

    handleAgreementAcceptButtonClick() {
      this.agreementAcceptBtnLoading = true;
      commonService
        .store(this.$constants.agreementAcceptUrl)
        .then((res) => {
          this.$message.success(res.message);
          this.update_employee(res.employee);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.agreementAcceptBtnLoading = false;
        });
    },

    lockscreenExpired() {
      this.logout({ redirectTo: this.$route.fullPath });
    },

    countdownExpired() {
      // if (localStorage.getItem("token")) {
      //   this.set_idle_lockscreen_status(true);
      // } else {
      //   location.reload();
      // }
    },

    updateLastSeenFromEvent(e) {
      if (!this.isIdleLocked) {
        localStorage.setItem("lastSeen", parseInt(Date.now() / 1000));
      }
    },

    updateLastSeen() {
      localStorage.setItem("lastSeen", parseInt(Date.now() / 1000));
    },

    checkLastSeen() {
      // if (this.isIdleLocked) {
      //   return;
      // }

      if (!localStorage.getItem("token")) {
        location.reload();
      }

      this.inactiveModalCountdown = null;
      let now = parseInt(Date.now() / 1000);
      let lastSeen = Number(localStorage.getItem("lastSeen"));
      if (now >= lastSeen + this.idleInactiveTime) {
        this.inactiveModalCountdown =
          this.autoLogoutTime - (now - lastSeen) + this.idleInactiveTime + 1;
        if (this.inactiveModalCountdown > 0) {
          // this.showInactiveModal = true;
          this.set_idle_lockscreen_status(true);
          this.inactiveModalCountdown -= 1;
          if (this.inactiveModalCountdown < 1) {
            this.lockscreenExpired();
          }
        } else {
          this.lockscreenExpired();
        }
      } else {
        // this.showInactiveModal = false;
        this.set_idle_lockscreen_status(false);
      }
    },
  },

  watch: {
    "$route.name": function (val) {
      if (this.isLiveChartingPage) {
        this.idleInactiveTime = 1080;
      } else {
        this.idleInactiveTime = 780;
      }
    },
    policyRuleDocument: function (val) {
      this.policyRule = val;
      if (val) {
        this.showPolicyRuleModal = true;
      }
    },
  },

  setup() {
    const { width } = useWindowSize();

    return {
      isMobile: computed(() => width.value < 500),
    };
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  background-color: #001529;
  display: none;
}

.user {
  display: none;
}

.nav-toggler {
  display: block;
}

.user-login {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-layout,
.main-layout-row {
  min-height: 100vh;
}

div.ant-dropdown.ant-dropdown-placement-bottomLeft {
  top: 64px !important;
}

.ant-layout-footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  margin-top: 40px;
  padding: 24px 30px;
  transform: translate(-50%, 0);
}

.ant-layout-header {
  background: #fff;
  padding: 0 16px;
}

@media only screen and (min-width: 1200px) {
  .sidebar {
    display: block;
  }

  .user {
    display: inline;
  }

  .nav-toggler {
    display: none;
  }
}

.hippa-modal,
.handbook-modal,
.agreement-accept-modal {
  .ant-modal-content {
    .ant-modal-header {
      .ant-typography {
        margin-bottom: 0;
        margin-top: 0;
      }

      h5 {
        text-decoration: underline;
      }
    }
  }
}

.inactive-modal {
  .ant-typography {
    margin-bottom: 0 !important;

    &.font-16 {
      font-size: 16px;
    }
  }

  .model-footer {
    text-align: center;
  }
}

.live-charting {
  .sidebar {
    display: none;
  }

  .monitor-panel {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
