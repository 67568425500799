<template>
  <a-layout-content>
    <page-header>
      <template #title> Dashboard</template>

      <template #buttons>
        <a-space>
          <a-button
            type="primary"
            @click="downloadPdf(record)"
            :loading="pdfDownloading"
          >
            <a-space>
              <i class="ti ti-download ti-lg"></i>
              PDF
            </a-space>
          </a-button>

          <a-select
            v-model:value="salesPerformanceYear"
            style="width: 80px; text-align: left"
          >
            <a-select-option
              v-for="year in $comman.getYearsListFrom()"
              :key="year"
              :value="year"
            >
              {{ year }}
            </a-select-option>
          </a-select>
        </a-space>
      </template>
    </page-header>

    <a-row type="flex" :gutter="[16, 16]">
      <a-col :xs="12" :lg="6">
        <a-card :loading="loading || salesPerformanceChartLoading">
          <a-statistic
            title="Total Sales (YTD)"
            :value="this.$comman.abbreviatedNumber(data?.total_sales ?? 0)"
          >
            <template #suffix>
              <ShoppingFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Total Due (YTD)"
            :value="this.$comman.abbreviatedNumber(data?.total_due ?? 0)"
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading || salesPerformanceChartLoading">
          <a-statistic
            title="Net Profit (YTD)"
            :value="this.$comman.abbreviatedNumber(data?.net_profit ?? 0)"
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Days Sales Outstanding"
            :value="data?.days_sales_outstanding ?? 0"
          >
            <template #suffix>
              <CalendarFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="TTM Total Revenue"
            :value="
              this.$comman.abbreviatedNumber(data?.ttm_total_revenue ?? 0)
            "
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="TTM Net Profit"
            :value="this.$comman.abbreviatedNumber(data?.ttm_total_profit ?? 0)"
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Revenue Run Rate"
            :value="this.$comman.abbreviatedNumber(data?.revenue_run_rate ?? 0)"
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="EBITDA Run Rate"
            :value="this.$comman.abbreviatedNumber(data?.ebitda_run_rate ?? 0)"
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="24">
        <sales-performance-chart
          :loading="loading || salesPerformanceChartLoading"
          :chart-data="salesPerformanceChartData"
        />
      </a-col>

      <a-col :xs="24">
        <ar-aging-summary :loading="loading" :data="data.ar_aging_summary" />
      </a-col>

      <a-col :xs="24">
        <income-by-type />
      </a-col>

      <a-col :xs="24">
        <last-3-month-income-chart />
      </a-col>

      <a-col :xs="24">
        <revenue-cagr-graph :totalIncome="data?.total_sales ?? null" />
      </a-col>

      <!--      <a-col :xs="24">
        <in-out-cash-flow />
      </a-col>-->
    </a-row>
  </a-layout-content>
</template>

<script>
import {
CalendarFilled,
DollarCircleFilled,
ShoppingFilled,
} from "@ant-design/icons-vue";
import moment from "moment";
import { commonService } from "../../services";
import arAgingSummary from "./components/arAgingSummary.vue";
import inOutCashFlow from "./components/inOutCashFlow.vue";
import incomeByType from "./components/incomeByType.vue";
import last3MonthIncomeChart from "./components/last3MonthIncomeChart.vue";
import revenueCagrGraph from "./components/revenueCagrGraph.vue";
import salesPerformanceChart from "./components/salesPerformanceChart.vue";

export default {
  name: "hospital-analytics",

  components: {
    ShoppingFilled,
    DollarCircleFilled,
    CalendarFilled,
    salesPerformanceChart,
    last3MonthIncomeChart,
    incomeByType,
    revenueCagrGraph,
    arAgingSummary,
    inOutCashFlow,
  },

  data() {
    return {
      salesPerformanceYear: moment().format("YYYY"),

      data: {
        ar_aging_summary: [],
      },
      loading: true,
      salesPerformanceChartLoading: false,

      salesPerformanceChart: null,
      salesPerformanceChartData: [],

      pdfDownloading: false,
    };
  },

  computed: {},

  mounted() {
    this.getHospitalAnalytics();
  },

  methods: {
    getHospitalAnalytics() {
      this.loading = true;
      commonService
        .get(this.$constants.financeDashboardUrl)
        .then((res) => {
          this.data = res.data;
          this.salesPerformanceChartData = this.data["sales_performance"];
          this.data.ar_aging_summary = [this.data.ar_aging_summary];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getHospitalSalesPerformanceAnalyticsUrl() {
      this.salesPerformanceChartLoading = true;
      let payload = {
        year: this.salesPerformanceYear,
      };
      commonService
        .get(this.$constants.financeDashboardAnalyticsUrl, payload)
        .then((res) => {
          this.data.total_sales = res.data.total_sales;
          this.data.net_profit = res.data.net_profit;
          this.data.sales_performance = res.data.sales_performance;
          this.data.ttm_total_revenue = res.data.ttm_total_revenue;
          this.data.revenue_run_rate = res.data.revenue_run_rate;
          this.salesPerformanceChartData = res.data.sales_performance;
        })
        .finally(() => {
          this.salesPerformanceChartLoading = false;
        });
    },

    downloadPdf() {
      this.pdfDownloading = true;

      commonService
        .downloadFile(
          this.$constants.financeDashboardUrl,
          { year: this.salesPerformanceYear, pdf_export: true },
          `Finance Dashboard.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.pdfDownloading = false;
        });
    },
  },

  watch: {
    salesPerformanceYear(curr, pre) {
      this.getHospitalSalesPerformanceAnalyticsUrl();
    },
  },
};
</script>
