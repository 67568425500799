<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        label="Start Date of ECMO Run"
        name="start_date_of_ecmo_run"
        :rules="[
          {
            required: true,
            type: 'date',
            message: 'This field is required',
          },
        ]"
      >
        <a-date-picker
          v-model:value="model.start_date_of_ecmo_run"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
          @change="calculateTotalEcmoRunTime"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        label="Start Time of ECMO Run"
        name="start_time_of_ecmo_run"
        :rules="[
          {
            required: true,
            message: 'Invalid time.',
          },
          {
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.start_time_of_ecmo_run"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
          @change="calculateTotalEcmoRunTime"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        label="End Date of ECMO Run"
        name="end_date_of_ecmo_run"
        :rules="[
          {
            required: true,
            type: 'date',
            message: 'This field is required',
          },
        ]"
      >
        <a-date-picker
          v-model:value="model.end_date_of_ecmo_run"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
          @change="calculateTotalEcmoRunTime"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        label="End Time of ECMO Run"
        name="end_time_of_ecmo_run"
        :rules="[
          {
            required: true,
            message: 'Invalid time.',
          },
          {
            message: 'Invalid time.',
            validator: $validation.timeFormat,
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.end_time_of_ecmo_run"
          v-maska="$constants.timeMaska"
          placeholder="HH:mm"
          @change="calculateTotalEcmoRunTime"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        ref="total_ecmo_run_time"
        label="Total ECMO Run Time (In Minutes)"
        name="total_ecmo_run_time"
        :rules="[
          {
            type: 'number',
            min: 0,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number type="number"
          v-model:value="model.total_ecmo_run_time"
          placeholder="Total ECMO run time"
          disabled
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        ref="lowest_hct_on_ecmo"
        label="Lowest HCT on ECMO"
        name="lowest_hct_on_ecmo"
        :rules="[
          {
            type: 'number',
            min: 0,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number type="number" v-model:value="model.lowest_hct_on_ecmo" />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        ref="lowest_ptt_on_ecmo"
        label="Lowest PTT on ECMO"
        name="lowest_ptt_on_ecmo"
        :rules="[
          {
            type: 'number',
            min: 0,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number type="number" v-model:value="model.lowest_ptt_on_ecmo" />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xl="6">
      <a-form-item
        ref="lowest_arterial_bp_on_ecmo"
        label="Lowest Arterial BP on ECMO"
        name="lowest_arterial_bp_on_ecmo"
        :rules="[
          {
            type: 'number',
            min: 0,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number type="number" v-model:value="model.lowest_arterial_bp_on_ecmo" />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import moment from "moment";

export default {
  name: "case-report-step-11",
  inheritAttrs: false,
  props: ["step", "model", "caseServices", "isCatiInvoiceAmountShow"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  computed: {
    //
  },

  mounted() {
    //
  },

  methods: {
    back() {
      this.$emit("back");
    },

    saveAndNext() {
      this.$emit("next");
    },

    calculateTotalEcmoRunTime() {
      if (
        this.model.start_date_of_ecmo_run &&
        this.model.start_time_of_ecmo_run &&
        this.model.end_date_of_ecmo_run &&
        this.model.end_time_of_ecmo_run
      ) {
        let start = moment(
          this.model.start_date_of_ecmo_run.format("YYYY-MM-DD") +
            " " +
            this.model.start_time_of_ecmo_run,
          "YYYY-MM-DD HH:mm"
        );
        let end = moment(
          this.model.end_date_of_ecmo_run.format("YYYY-MM-DD") +
            " " +
            this.model.end_time_of_ecmo_run,
          "YYYY-MM-DD HH:mm"
        );

        this.model.total_ecmo_run_time = moment
          .duration(end.diff(start))
          .asMinutes();
      } else {
        this.model.total_ecmo_run_time = null;
      }
      this.$parent.$parent.$parent.validateFields(["total_ecmo_run_time"]);
    },
  },
};
</script>
