<template>
  <a-row :gutter="[20, 0]" class="mb-sm-2">
    <a-col :span="24">
      <a-alert
        message="IMPORTANT: Please review your entry for accuracy before advancing to the next page.."
        type="error"
        class="mb-sm-1"
      />
    </a-col>

    <template v-for="(step, index) in steps" :key="index">
      <template v-if="stepCondition(index)">
        <a-divider orientation="left">{{ step.title }}</a-divider>

        <a-col v-if="index == 0" :span="24">
          <a-descriptions title="" :column="2">
            <a-descriptions-item label="Date of Service">
              {{ $customDate.mdy(model.data) }}
            </a-descriptions-item>
            <a-descriptions-item label="Facility">
              {{ model.hospital_id }}
            </a-descriptions-item>
            <a-descriptions-item v-if="isLogNumberShow" label="Log #">
              {{ model.log_number ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Patient First Name">
              {{ model.patient_first_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Patient Last Name">
              {{ model.patient_last_name }}
            </a-descriptions-item>
            <a-descriptions-item label="MR #">
              {{ model.mr_number ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Surgeon">
              {{ model.surgeon ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Procedure">
              {{ model.procedure ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Anesthesia Provider">
              {{ model.anesthesia_provider ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item v-if="hospitalIdIs(30)" label="CASE">
              {{ model.case ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item v-if="hospitalIdIs(30)" label="YTD">
              {{ model.ytd ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item v-if="isTavrInputShow" label="TAVR">
              {{ model.tavr ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Status">
              {{ model.case_status ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="TYPE OF SERVICE REQUESTED">
              {{ model.case_service_request_type ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Height (Cm)">
              {{ model.height ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Weight (Kg)">
              {{ model.weight ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label> Body Surface Area (m<sup>2</sup>) </template>
              {{ model.body_surface_area ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Allergies">
              {{ model.allergies ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Were keystone perfusion products used for this perfusion?"
            >
              {{ model.is_disposable_use ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="isCaseServiceRequestTypeHasEcmo"
              label="Hours on ECMO"
            >
              {{ model.ecmo_hours ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="isCaseServiceRequestTypeHasCpb"
              label="CPB Time (Minutes)"
            >
              {{ model.cpb_time ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="isCaseServiceRequestTypeHasCpb"
              label="Cross-Clamp Time (Minutes)"
            >
              {{ model.cross_clamp_time ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="isCaseServiceRequestTypeHasCpb"
              label="Circulatory Arrest Time (Minutes)"
            >
              {{ model.circulatory_arrest_time ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Clinician">
              {{ model.clinician ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="isCatiInvoiceAmountShow"
              label="CATI Invoice Amount"
            >
              {{ model.cati_invoice_amount ?? "-" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col v-else-if="index == 1" :span="24">
          <template
            v-for="(products, product_category_id) in model.products"
            :key="`product_table_index_${product_category_id}`"
          >
            <template v-if="products[0].product_id != null">
              <a-table
                :columns="columns"
                :data-source="products"
                :rowKey="(record, index) => record.product_id"
                :pagination="false"
                size="small"
              >
                <template #title>
                  <a-divider orientation="center">
                    {{ productCategoryName(product_category_id) }}
                  </a-divider>
                </template>
              </a-table>
            </template>
          </template>

          <!-- <a-table
            :columns="columns"
            :data-source="productList"
            :rowKey="(record, index) => record.product_id"
            :pagination="false"
            size="small"
          /> -->
        </a-col>

        <a-col v-else-if="index == 2" :span="24">
          <a-descriptions title="" :column="2">
            <a-descriptions-item label="Anesthesia Volume Pre-Bypass (mLs)">
              {{ model.anesthesia_volumn_pre_bypass ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Net Prime Volume (NPV) (mLs)">
              {{ model.net_prime_volume ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Ultrafiltration on CPB (mLs)">
              {{ model.ultrafiltration_on_cpb ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Modified Ultrafiltration (MUF) (mLs) - Post Bypass"
            >
              {{ model.modified_ultrafiltration ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Activated Clotting Time (ACT) >400 seconds prior to initiation of CPB"
            >
              {{ model.act_seconds_prior_to_initiation ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Activated Clotting Time (ACT) >400 seconds while on CPB"
            >
              {{ model.act_seconds_while_on_cpb ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lowest ACT on CPB (seconds)">
              {{ model.lowest_act_on_cpb ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Post Induction Hematocrit (%)">
              {{ model.post_induction_hematocrit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="First CPB Hematocrit (%)">
              {{ model.first_cpb_hematocrit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Percent Decrease in Hematocrit (%)">
              {{ model.percent_decrease_in_hemtocrit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lowest CPB Hematocrit (%)">
              {{ model.lowest_cpb_hematocrit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Last CPB Hematocrit (%)">
              {{ model.last_cpb_hematocrit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Last In Room Hematocrit (%)">
              {{ model.last_in_room_hematocrit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lowest Arterial Pressure on CPB (mmHg)">
              {{ model.lowest_arterian_pressure_on_cpb ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lowest SvO2 on CPB (%)">
              {{ model.lowest_svo2_on_cpb ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lowest CPB Base Excess/Deficit (mEq/L)">
              {{ model.lowest_cpb_base_excess_deficit ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Highest Blood Glucose Level (mg/dL)">
              {{ model.highest_blood_glucose_level ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Highest Venous Blood Temperature (degrees C)"
            >
              {{ model.highest_arterial_blood_temperature ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Lowest Core Body Temperature (degrees C)"
            >
              {{ model.lowest_core_body_temperature ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Minimum Cardiac Index (CI) During CPB">
              {{ model.minimum_cardiac_index_during_cpb ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Packed Red Blood Cell (PRBC) Transfusion during CPB"
            >
              {{ model.prbc_transfusion_during_cpb ?? "-" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col v-else-if="index == 3" :span="24">
          <a-descriptions title="" :column="2">
            <a-descriptions-item label="Washed RBCs Reinfused (mLs)">
              {{ model.washed_rbcs_reinfused ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Type of Anticoagulant Used">
              {{ model.type_of_anticoagulant_used ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Total Processed Volume (mLs)">
              {{ model.total_processed_volumn ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Anticoagulation Volume Used (mLs)">
              {{ model.anticoagulant_volumn_used ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Wash Volume Used (mLs)">
              {{ model.wash_volumn_used ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Estimated Blood Loss (EBL)">
              {{ model.estimated_blood_loss ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Clear Effluent Wash Line">
              {{ model.clear_effluent_wash_line ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="washedRbcsReinfusedIs0"
              label="Autologous Blood Transfused within 4 Hours"
            >
              {{ model.autologous_blood_transfused_within_4_hours ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item v-if="hospitalIdIs(30)" label="Cell Saver HCT">
              {{ model.cell_saver_hct ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="hospitalIdIs(30)"
              label="Cell Saver Device Serial Number"
            >
              {{ model.cell_saver_device_serial_number ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="!hospitalIdIs(30)"
              label="Cell Saver Device Serial Number"
            >
              {{ model.cell_saver_device_serial_number ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="washedRbcsReinfusedIs0"
              label="Was a QC sample obtained for this procedure?"
            >
              {{ model.was_a_qc_sample_obtained_for_this_procedure ?? "-" }}
            </a-descriptions-item>
            <template v-if="wasAQcSampleObtainedForThisProcedureIsYes">
              <a-descriptions-item label="Source">
                {{ model.cell_saver_qc_results_source ?? "-" }}
              </a-descriptions-item>
              <a-descriptions-item label="Parameter">
                {{ model.cell_saver_qc_results_parameter ?? "-" }}
              </a-descriptions-item>
              <a-descriptions-item label="Result">
                {{ model.cell_saver_qc_results_result ?? "-" }}
              </a-descriptions-item>
            </template>
          </a-descriptions>
        </a-col>

        <a-col v-else-if="index == 4" :span="24">
          <a-descriptions title="" :column="2">
            <a-descriptions-item label="Cytotoxic Drug Type & Dose">
              {{ model.cytotoxic_drug_type_and_dose ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Prime Type and Amount (mLs)">
              {{ model.prime_type_and_amount ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Average Inflow Temperature (degrees C)">
              {{ model.average_inflow_temperature ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Average outflow Temperature (degrees C)"
            >
              {{ model.average_outflow_temperature ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Pump Flow Rate">
              {{ model.pump_flow_rate ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Average Reservoir Volume (mLs)">
              {{ model.average_reservoir_volume ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Additional Comments">
              {{ model.additional_comments ?? "-" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col v-else-if="index == 5" :span="24">
          <a-descriptions title="" :column="2">
            <a-descriptions-item label="Any Transfusion Required">
              {{ model.any_transfusion_required ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="anyTransfusionRequiredIsYes"
              label="Packed Red Blood Cells Transfused (units) (Including CPB Transfused Units)"
            >
              {{ model.packed_red_blood_cells_transfused ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="anyTransfusionRequiredIsYes"
              label="Total Fresh Frozen Plasma (units)"
            >
              {{ model.total_fresh_frozen_plasma ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="anyTransfusionRequiredIsYes"
              label="Total Platelet (units)"
            >
              {{ model.total_platelet ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="anyTransfusionRequiredIsYes"
              label="Total Cryoprecipitate (units)"
            >
              {{ model.total_cryoprecipitate ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Notes">
              {{ model.notes ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="Patient In Room Time">
              {{
                model.patient_in_room_time
                  ? model.patient_in_room_time.format("hh:mm a")
                  : "-"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Surgery Start Time">
              {{
                model.surgery_start_time
                  ? model.surgery_start_time.format("hh:mm a")
                  : "-"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Surgery End Time">
              {{
                model.surgery_end_time
                  ? model.surgery_end_time.format("hh:mm a")
                  : "-"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Patient Out Of Room Time">
              {{
                model.patient_out_of_room_time
                  ? model.patient_out_of_room_time.format("hh:mm a")
                  : "-"
              }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col v-else-if="index == 6" :span="24">
          <a-descriptions title="" :column="2">
            <a-descriptions-item
              label="Was the heart lung bypass machine cleaned/disinfected after the procedure?"
            >
              {{ model.was_bypass_machine_cleaned ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="wasBypassMachineCleanedIsYes"
              label="Heart Lung Machine Device Identifier/Serial Number"
            >
              {{ model.heart_machine_device_number ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Was the heater cooler machine cleaned/disinfected after the procedure?"
            >
              {{ model.was_heater_cooler_machine_cleaned ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="wasHeaterCoolerMachineCleanedIsYes"
              label="Heater Cooler Device Identifier/Serial Number"
            >
              {{ model.heater_cooler_device_number ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              label="Was the cell saver device cleaned/disinfected after the procedure?"
            >
              {{ model.was_cell_saver_device_cleaned ?? "-" }}
            </a-descriptions-item>
            <a-descriptions-item
              v-if="wasCellSaverDeviceCleanedIsYes"
              label="Cell Saver Device Identifier/Serial Number"
            >
              {{ model.cell_saver_device_number ?? "-" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </template>
    </template>
  </a-row>
</template>

<script>
export default {
  props: {
    model: { require: true, type: Object },
    steps: { require: true, type: Object },
    productCategories: { require: true, type: Array },
    products: { require: true, type: Array },
    isCati: { require: true, type: Boolean },
    isCaseServiceRequestTypeHasCpb: { require: true, type: Boolean },
    isCaseServiceRequestTypeHasEcmo: { require: true, type: Boolean },
    isLogNumberShow: { require: true, type: Boolean },
    isCatiInvoiceAmountShow: { require: true, type: Boolean },
    isTavrInputShow: { require: true, type: Boolean },
    wasAQcSampleObtainedForThisProcedureIsYes: { require: true, type: Boolean },
    washedRbcsReinfusedIs0: { require: true, type: Boolean },
    anyTransfusionRequiredIsYes: { require: true, type: Boolean },
    wasHeaterCoolerMachineCleanedIsYes: { require: true, type: Boolean },
    wasCellSaverDeviceCleanedIsYes: { require: true, type: Boolean },
    wasBypassMachineCleanedIsYes: { require: true, type: Boolean },
  },

  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          customRender: ({ index }) => index + 1,
        },
        // {
        //   title: "Category Name",
        //   dataIndex: "category_id",
        //   key: "category_id",
        //   customRender: ({ record }) =>
        //     this.productCategoryName(record.category_id),
        // },
        {
          title: "Product Name",
          dataIndex: "product_id",
          key: "product_id",
          customRender: ({ record }) => this.productName(record.product_id),
        },
        {
          title: "Quantity",
          dataIndex: "qty",
          key: "qty",
        },
      ],
    };
  },

  computed: {
    productList() {
      return Object.keys(this.model.products).reduce((val, category_id) => {
        this.model.products[category_id].forEach((value) => {
          if (value.product_id != null) {
            val.push({
              category_id: category_id,
              product_id: value.product_id,
              qty: value.qty,
            });
          }
        });
        return val;
      }, []);
    },
  },

  methods: {
    stepCondition(index) {
      let bool = false;
      if (index == 0) {
        // Basic
        bool = true;
      } else if (index == 1) {
        // Disposable Products Used
        bool = this.model.is_disposable_use ?? false;
      } else if (index == 2) {
        // Quality Indicators
        let array = [
          "CPB",
          // "CPB STANDBY",
          "ECMO",
          "PERFUSION", // STANDBY
          "VAD INSERTION",
        ];
        array.forEach((val) => {
          if (!bool) {
            bool = this.model.case_service_request_type.includes(val);
          }
        });
      } else if (index == 3) {
        // Cell Saver Operative Data
        // bool = !this.model.case_service_request_type.includes("CANCELED CASE");
        bool = true;
      } else if (index == 4) {
        // HIPEC Quality Indicators
        bool = this.model.case_service_request_type.includes(
          "HYPERTHERMIC INTRAPERITONEAL EXTRACORPOREAL CHEMOTHERAPY (HIPEC)"
        );
      } else if (index == 5) {
        // Transfusion Requirements
        // bool = !this.model.case_service_request_type.includes("CANCELED CASE");
        bool = true;
      } else if (index == 6) {
        // Post Procedure Maintenance
        bool = this.model.case_service_request_type.includes("CANCELED CASE");
      } else if (index == 7) {
        // Preview
        bool = false;
      }
      return bool;
    },

    hospitalIdIs(params) {
      if (typeof params == "number") {
        params = [params];
      }

      return params.includes(this.model.hospital_id);
    },

    productCategoryName(id) {
      let category = this.productCategories.find((v) => v.id == id);
      if (category) {
        return category.name;
      }
      return "-";
    },

    productName(id) {
      let product = this.products.find((v) => v.id == id);
      if (product) {
        return product.name;
      }
      return "-";
    },
  },
};
</script>

<style>
.ant-descriptions-item {
  padding-bottom: 0 !important;
}
</style>
