<template>
  <div>
    <a-form
      name="case_service_form"
      ref="case_service_form"
      :model="model"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="model.name"
              placeholder="Enter name here"
              disabled
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="price"
            label="Price"
            name="price"
            :validateStatus="validate('price', 'status')"
            :help="validate('price', 'msg')"
          >
            <a-input-number
              v-model:value="model.price"
              placeholder="Enter price here"
              :min="0"
              :step="0.01"
              :formatter="$comman.currencyformatter"
              :parser="$comman.currencyParser"
              @blur="updatePriceEvent"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters, useStore } from "vuex";
import { commonService } from "../../services";
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { constants } from "../../helper/constants";
import { message } from "ant-design-vue";
import { caseServiceRule } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";

export default {
  mixins: [formMixins],

  components: {
    PlusOutlined,
  },

  emits: ["callback"],

  data() {
    return {
      model: {
        id: 0,
        name: null,
        price: null,
      },
      rules: caseServiceRule,
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.model.id = this.record.id ?? 0;
      this.model.name = this.record.name ?? null;
      this.model.price = this.record.price ?? null;
    },

    onSubmit() {
      this.loadingStart();
      this.updatePriceEvent();
      commonService
        .store(constants.caseServiceStoreUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.errors = err.errors;
          }
          console.log("error", err);
        })
        .finally(() => this.loadingStop());
    },

    updatePriceEvent() {
      this.model.price = Number(Number(this.model.price).toFixed(2));
    },
  },
};
</script>
