<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-form-item
        ref="any_transfusion_required"
        label="Any Transfusion Required"
        name="any_transfusion_required"
        :validateStatus="validate('any_transfusion_required', 'status')"
        :help="validate('any_transfusion_required', 'msg')"
      >
        <a-select
          v-model:value="model.any_transfusion_required"
          placeholder="Select any one"
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col v-if="anyTransfusionRequiredIsYes" :span="12">
      <a-form-item
        ref="packed_red_blood_cells_transfused"
        label="Packed Red Blood Cells Transfused (units) (Including CPB Transfused Units)"
        name="packed_red_blood_cells_transfused"
        :validateStatus="
          validate('packed_red_blood_cells_transfused', 'status')
        "
        :help="validate('packed_red_blood_cells_transfused', 'msg')"
      >
        <a-input
          v-model:value="model.packed_red_blood_cells_transfused"
          placeholder="Enter packed red blood cells transfused in units here"
        />
      </a-form-item>
    </a-col>

    <a-col v-if="anyTransfusionRequiredIsYes" :span="12">
      <a-form-item
        ref="total_fresh_frozen_plasma"
        label="Total Fresh Frozen Plasma (units)"
        name="total_fresh_frozen_plasma"
        :validateStatus="validate('total_fresh_frozen_plasma', 'status')"
        :help="validate('total_fresh_frozen_plasma', 'msg')"
      >
        <a-input
          v-model:value="model.total_fresh_frozen_plasma"
          placeholder="Enter total fresh frozen plasma in units here"
        />
      </a-form-item>
    </a-col>

    <a-col v-if="anyTransfusionRequiredIsYes" :span="12">
      <a-form-item
        ref="total_platelet"
        label="Total Platelet (units)"
        name="total_platelet"
        :validateStatus="validate('total_platelet', 'status')"
        :help="validate('total_platelet', 'msg')"
      >
        <a-input
          v-model:value="model.total_platelet"
          placeholder="Enter total platelet in units here"
        />
      </a-form-item>
    </a-col>

    <a-col v-if="anyTransfusionRequiredIsYes" :span="12">
      <a-form-item
        ref="total_cryoprecipitate"
        label="Total Cryoprecipitate (units)"
        name="total_cryoprecipitate"
        :validateStatus="validate('total_cryoprecipitate', 'status')"
        :help="validate('total_cryoprecipitate', 'msg')"
      >
        <a-input
          v-model:value="model.total_cryoprecipitate"
          placeholder="Enter total cryoprecipitate in units here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="notes"
        label="Notes"
        name="notes"
        :validateStatus="validate('notes', 'status')"
        :help="validate('notes', 'msg')"
      >
        <a-textarea
          v-model:value="model.notes"
          placeholder="Enter any notes here"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-divider orientation="left">Surgical Times</a-divider>

  <a-row :gutter="[20, 0]">
    <a-col :span="24">
      <a-alert
        message="Enter the procedure times in Military Time using a 24 Hour Time format."
        type="info"
        class="mb-sm-1"
      />

      <a-alert
        message="Please e-mail Purchase Order to accounting@keystoneperfusion.com."
        type="error"
        class="mb-sm-1"
      />
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="patient_in_room_time"
        label="Patient In Room Time"
        name="patient_in_room_time"
        :validateStatus="validate('patient_in_room_time', 'status')"
        :help="validate('patient_in_room_time', 'msg')"
      >
        <a-time-picker
          v-model:value="model.patient_in_room_time"
          format="HH:mm"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="surgery_start_time"
        label="Surgery Start Time"
        name="surgery_start_time"
        :validateStatus="validate('surgery_start_time', 'status')"
        :help="validate('surgery_start_time', 'msg')"
      >
        <a-time-picker
          v-model:value="model.surgery_start_time"
          format="HH:mm"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="surgery_end_time"
        label="Surgery End Time"
        name="surgery_end_time"
        :validateStatus="validate('surgery_end_time', 'status')"
        :help="validate('surgery_end_time', 'msg')"
      >
        <a-time-picker
          v-model:value="model.surgery_end_time"
          format="HH:mm"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="patient_out_of_room_time"
        label="Patient Out Of Room Time"
        name="patient_out_of_room_time"
        :validateStatus="validate('patient_out_of_room_time', 'status')"
        :help="validate('patient_out_of_room_time', 'msg')"
      >
        <a-time-picker
          v-model:value="model.patient_out_of_room_time"
          format="HH:mm"
          :allowClear="false"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: { require: true, type: Object },
    anyTransfusionRequiredIsYes: { require: true, type: Boolean },
  },

  computed: {},

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },
  },
};
</script>
