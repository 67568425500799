<template>
  <div>
    <a-form
      name="master_handbook_form"
      ref="master_handbook_form"
      :model="model"
      :rules="rules"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="title"
            label="Title"
            name="title"
            :validateStatus="validate('title', 'status')"
            :help="validate('title', 'msg')"
          >
            <a-input
              v-model:value="model.title"
              placeholder="Enter title here"
              @change="resetErrors('title')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="file"
            label="Attachment"
            name="file"
            :validateStatus="validate('file', 'status')"
            :help="validate('file', 'msg')"
          >
            <a-upload-dragger
              :fileList="model.file"
              :before-upload="beforeUploadFile"
              :remove="handleRemoveFile"
              accept="application/pdf"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">
                Support for a single PDF file
              </p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, InboxOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    InboxOutlined,
    PlusOutlined,
  },

  data() {
    return {
      model: {
        id: 0,
        title: "",
        file: [],
      },
      rules: {
        title: [
          {
            required: true,
            message: "title is required!",
            trigger: "blur",
          },
        ],
        file: [
          {
            required: true,
            message: "file is required!",
            trigger: "blur",
            type: "array",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  mounted() {
    this.getFormState();
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.model = {
        id: this.record.id ?? 0,
        title: this.record.title ?? "",
      };
      if (this.record.file_name) {
        this.model.file = [
          {
            id: 1,
            uid: this.record.file_name,
            name: this.record.file_name,
            url: this.record.full_file_path,
          },
        ];
      }
    },

    handleFinish(val) {
      if (this.record.id) {
        val.id = this.record.id;
      } else {
        val.id = 0;
      }

      console.log(this.model.file);

      const formData = new FormData();
      formData.append("id", this.model.id);
      formData.append("title", this.model.title);
      if (this.model.file[0].id) {
        formData.append("old_file", this.model.file[0]);
      } else {
        formData.append("file", this.model.file[0]);
      }

      this.loadingStart();

      commonService
        .store(this.$constants.masterHandbookStoreUrl, formData)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleRemoveFile() {
      this.model.file = [];
    },

    beforeUploadFile(file) {
      if (["application/pdf"].includes(file.type)) {
        this.model.file = [file];
        return false;
      }
      this.$message.error("You can only upload PDF File!");
      this.model.file = [];
    },
  },
};
</script>

<style></style>
