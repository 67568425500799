import { message } from "ant-design-vue";
import router from "../router/index";
import { authService } from "../services/auth.service";

const state = {
  isLoading: true,
  isLoggedIn: false,
  token: "",
  user: {},
  employee: {},
  slugs: [],
  routes: [],
  sessionLogout: false,
  form_i9: null,
  form_w9: null,
  onboarding_doc_counts: 0,
  idleLock: false,
  incompleted_to_do_count: 0,
};

const getters = {
  isLoading: (state) => state.isLoading,
  isLoggedIn: (state) => state.isLoggedIn,
  token: (state) => state.token,
  user: (state) => state.user,
  employee: (state) => state.employee,
  isAdmin: (state) => state.user?.role_id == 1,
  canShowOnboardingTab: (state) => state.can_show_onboarding_tab,
  employeeIdsWithFinanceAccess: (state) => state.finance_access,
  hasFinanceAccess: (state) => state.finance_access.includes(state.employee.id),
  policyRuleDocument: (state) => state.policy_rule_document,
  birthdayNames: (state) => state.birthday_names,
  workAnniversaryNames: (state) => state.work_anniversary_names,
  dashboardAlertMessages: (state) => state.dashboard_alert_messages,
  isManager: (state) => state.employee?.is_manager == 1,
  isSalesPerson: (state) => state.employee?.is_sales_person == 1,
  profileStatus: (state) => (state.employee?.profile_status == 1 ? 1 : 0),
  slugs: (state) => state.slugs,
  routes: (state) => state.routes,
  sessionLogout: (state) => state.sessionLogout,
  formI9: (state) => state.form_i9,
  formW9: (state) => state.form_w9,
  onboardingDocCounts: (state) => state.onboarding_doc_counts,
  isIdleLocked: (state) => state.idleLock,
  incompleteTodoCount: (state) => state.incompleted_to_do_count,
};

const actions = {
  login({ commit, dispatch }, payload) {
    let token = localStorage.getItem("token");
    if (token) {
      commit("SET_TOKEN", { token });
      return authService
        .me()
        .then((res) => {
          commit("LOGIN_SUCCESS", { ...res, isInitStore: payload.isInitStore });
          dispatch("navbarCount/setPendingCounts", null, { root: true });
          return Promise.resolve("User is logged in!!");
        })
        .catch((err) => {
          message.error("Something went wrong!!");
          commit("LOGOUT");
          return Promise.reject("User is not logged in!!");
        });
    } else {
      commit("LOGOUT");
      return Promise.reject("User is not logged in!!");
    }
  },

  logout({ commit, dispatch }, payload) {
    authService.logout();
    dispatch("set_idle_lockscreen_status", false);
    dispatch("navbarCount/resetPendingCountsInterval", null, { root: true });
    commit("LOGOUT", payload);
  },

  forgot_password({ commit }, payload) {
    return authService
      .forgot_password(payload)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err);
        return Promise.reject(err);
      });
  },

  reset_password({ commit }, payload) {
    return authService
      .reset_password(payload)
      .then((res) => {
        message.success(res.message);
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  set_finance_access({ commit }, payload) {
    commit("SET_FINANCE_ACCESS", payload);
  },

  update_employee({ commit }, payload) {
    commit("UPDATE_EMPLOYEE", payload);
  },

  set_session_logout({ commit }, payload) {
    commit("SET_SESSION_LOGOUT", payload);
  },

  set_form_i9({ commit }, payload) {
    commit("SET_FORM_I9", payload);
  },

  set_form_w9({ commit }, payload) {
    commit("SET_FORM_W9", payload);
  },

  set_onboarding_doc_counts({ commit }, payload) {
    commit("SET_ONBOARDING_DOC_COUNTS", payload);
  },

  set_idle_lockscreen_status({ commit }, payload) {
    commit("SET_IDLE_LOCKSCREEN_STATUS", payload);
  },

  set_incomplete_todo_count({ commit }, payload) {
    commit("SET_INCOMPLETE_TODO_COUNT", payload);
  },

  set_has_change_password({ commit }, payload) {
    commit("SET_HAS_CHANGE_PASSWORD", payload);
  },
};

const mutations = {
  SET_TOKEN(state, payload) {
    state.isLoggedIn = true;
    state.token = payload.token;
  },

  LOGIN_SUCCESS(state, payload) {
    state.isLoading = false;
    state.user = payload.user;
    state.employee = payload.employee;
    state.slugs = payload.slugs;
    state.routes = payload.routes;
    state.sessionLogout = false;
    state.can_show_onboarding_tab = payload.can_show_onboarding_tab;
    state.birthday_names = payload.birthday_names;
    state.work_anniversary_names = payload.work_anniversary_names;
    state.dashboard_alert_messages = payload.dashboard_alert_messages;
    state.policy_rule_document = payload.policy_rule_document;
    state.finance_access = payload.finance_access;
    state.form_i9 = payload.form_i9;
    state.form_w9 = payload.form_w9;
    state.incompleted_to_do_count = payload.incompleted_to_do_count;
    state.onboarding_doc_counts = payload.onboarding_doc_counts;

    if (payload.isInitStore) {
      if (router.currentRoute.value.query?.redirectTo) {
        router.push(router.currentRoute.value.query.redirectTo);
      } else {
        router.push({ name: "home" });
      }
    }
  },

  LOGOUT(state, payload) {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.token = null;
    state.user = {};
    state.employee = {};
    state.slugs = [];
    state.routes = [];
    state.finance_access = [];

    localStorage.removeItem("token");
    if (payload?.redirectTo) {
      router.push({ name: "login", query: { redirectTo: payload.redirectTo } });
    } else {
      router.push({ name: "login" });
    }
  },

  UPDATE_EMPLOYEE(state, payload) {
    state.employee = payload;
  },

  SET_FINANCE_ACCESS(state, payload) {
    state.finance_access = payload;
  },

  SET_SESSION_LOGOUT(state, bool) {
    state.sessionLogout = bool;
  },

  SET_FORM_I9(state, payload) {
    state.form_i9 = payload;
  },

  SET_FORM_W9(state, payload) {
    state.form_w9 = payload;
  },

  SET_ONBOARDING_DOC_COUNTS(state, payload) {
    state.onboarding_doc_counts = payload;
  },

  SET_IDLE_LOCKSCREEN_STATUS(state, payload) {
    if (payload) {
      localStorage.setItem("IDLE_LOCK", "true");
      state.idleLock = true;
    } else {
      localStorage.removeItem("IDLE_LOCK");
      state.idleLock = false;
    }
  },

  SET_HAS_CHANGE_PASSWORD(state, payload) {
    state.user.has_change_password = payload;
  },

  SET_INCOMPLETE_TODO_COUNT(state, payload) {
    state.incompleted_to_do_count = payload;
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
