<template>
  <a-form
    name="pre_procedure_form"
    ref="pre_procedure_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :lg="12">
        <a-form-item
          label="Collection Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Volume Processed" name="volume_processed">
          <a-input-number
            type="number"
            v-model:value="model.volume_processed"
            placeholder="Enter here..."
            :min="0"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Volume Returned" name="volume_returned">
          <a-input-number
            type="number"
            v-model:value="model.volume_returned"
            placeholder="Enter here..."
            :min="0"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Volume Anticoag" name="volume_anticoag">
          <a-input-number
            type="number"
            v-model:value="model.volume_anticoag"
            placeholder="Enter here..."
            :min="0"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item name="estimated_blood_loss">
          <template #label>
            Estimated Blood Loss (EBL)
            <a-popover overlayClassName="overlay-popover-class">
              <template #content>
                EBL Calculation: ((Bowl volume/2) / avg hct expressed as a
                fraction)) x # of Bowls returned to the Pt.
              </template>
              <a-button type="link" size="small" tabindex="-1">
                <i class="fa fa-info-circle"></i>
              </a-button>
            </a-popover>
          </template>
          <a-input-number
            type="number"
            v-model:value="model.estimated_blood_loss"
            placeholder="Enter here..."
            :min="0"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Wash Volume" name="wash_volume">
          <a-input-number
            type="number"
            v-model:value="model.wash_volume"
            placeholder="Enter here..."
            :min="0"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24">
        <a-form-item label="Comment" name="comment">
          <a-textarea
            v-model:value="model.comment"
            placeholder="Enter here..."
          />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons :name="btnText" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        time: "",
        event: "Status",
        volume_processed: 0,
        volume_returned: 0,
        volume_anticoag: 0,
        estimated_blood_loss: 0,
        wash_volume: 0,
        comment: "",
      },
    };
  },

  computed: {
    ...mapGetters("modal", ["record", "extra"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    btnText() {
      return this.extra.time ? "Update" : "Add";
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.model.uuid = this.record.uuid;

      let lastRecord = {};
      if (this.extra.time) {
        lastRecord = this.extra;
        this.model.unique_code = this.extra.unique_code;
        this.model.time = lastRecord.time;
        this.model.volume_processed = lastRecord?.volume_processed ?? 0;
        this.model.volume_returned = lastRecord?.volume_returned ?? 0;
        this.model.volume_anticoag = lastRecord?.volume_anticoag ?? 0;
        this.model.estimated_blood_loss = lastRecord?.estimated_blood_loss ?? 0;
        this.model.wash_volume = lastRecord?.wash_volume ?? 0;
        this.model.comment = lastRecord?.comment ?? "";
      } else {
        this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
      }
    },

    onSubmit() {
      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreATXRecordUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },
  },
};
</script>
