<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="24">
      <a-form-item
        ref="date"
        label="Date of Service"
        name="date"
        :rules="[
          {
            required: true,
            type: 'date',
            message: 'This field is required!',
            trigger: ['change', 'blur'],
          },
        ]"
        :validateStatus="validate('date', 'status')"
        :help="validate('date', 'msg')"
      >
        <a-date-picker
          :allowClear="false"
          v-model:value="model.date"
          :placeholder="$constants.datePickerPlaceholder"
          :format="$constants.datepickerFormat"
          :disabledDate="$comman.disabledFutureDate"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="hospital_id"
        label="Facility"
        name="hospital_id"
        :rules="[
          {
            required: true,
            type: 'number',
            message: 'This field is required!',
            trigger: ['change', 'blur'],
          },
        ]"
        :validateStatus="validate('hospital_id', 'status')"
        :help="validate('hospital_id', 'msg')"
      >
        <a-select
          v-model:value="model.hospital_id"
          placeholder="Select facility"
          show-search
          optionFilterProp="label"
          @change="handleHospitalChangeEvent"
        >
          <a-select-option
            v-for="hospital in hospitals"
            :key="hospital.id"
            :label="hospital.name"
            :value="hospital.id"
          >
            {{ hospital.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        v-if="isLogNumberShow"
        ref="log_number"
        label="Log #"
        name="log_number"
        :validateStatus="validate('log_number', 'status')"
        :help="validate('log_number', 'msg')"
      >
        <a-input
          v-model:value="model.log_number"
          placeholder="Enter log here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="patient_first_name"
        label="Patient First Name"
        name="patient_first_name"
        :rules="[
          {
            required: true,
            message: 'Patient first name is required!',
            trigger: ['change', 'blur'],
          },
        ]"
        :validateStatus="validate('patient_first_name', 'status')"
        :help="validate('patient_first_name', 'msg')"
      >
        <a-input
          v-model:value="model.patient_first_name"
          placeholder="Enter patient first name here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="patient_last_name"
        label="Patient Last Name"
        name="patient_last_name"
        :rules="[
          {
            required: true,
            message: 'Patient last name is required!',
            trigger: ['change', 'blur'],
          },
        ]"
        :validateStatus="validate('patient_last_name', 'status')"
        :help="validate('patient_last_name', 'msg')"
      >
        <a-input
          v-model:value="model.patient_last_name"
          placeholder="Enter patient last name here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="mr_number"
        label="MR #"
        name="mr_number"
        :validateStatus="validate('mr_number', 'status')"
        :help="validate('mr_number', 'msg')"
      >
        <a-input
          v-model:value="model.mr_number"
          placeholder="Enter mr number here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="surgeon"
        label="Surgeon"
        name="surgeon"
        :validateStatus="validate('surgeon', 'status')"
        :help="validate('surgeon', 'msg')"
      >
        <a-input
          v-model:value="model.surgeon"
          placeholder="Enter surgeon here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="procedure"
        label="Procedure"
        name="procedure"
        :validateStatus="validate('procedure', 'status')"
        :help="validate('procedure', 'msg')"
      >
        <a-input
          v-model:value="model.procedure"
          placeholder="Enter procedure here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="anesthesia_provider"
        label="Anesthesia Provider"
        name="anesthesia_provider"
        :validateStatus="validate('anesthesia_provider', 'status')"
        :help="validate('anesthesia_provider', 'msg')"
      >
        <a-input
          v-model:value="model.anesthesia_provider"
          placeholder="Enter anesthesia provider here"
        />
      </a-form-item>
    </a-col>

    <template v-if="hospitalIdIs(30)">
      <a-col :span="8">
        <a-form-item
          v-if="hospitalIdIs(30)"
          ref="case"
          label="CASE"
          name="case"
          :validateStatus="validate('case', 'status')"
          :help="validate('case', 'msg')"
        >
          <a-input v-model:value="model.case" placeholder="Enter case here" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          v-if="hospitalIdIs(30)"
          ref="ytd"
          label="YTD"
          name="ytd"
          :validateStatus="validate('ytd', 'status')"
          :help="validate('ytd', 'msg')"
        >
          <a-input v-model:value="model.ytd" placeholder="Enter ytd here" />
        </a-form-item>
      </a-col>

      <a-col v-if="isTavrInputShow" :span="8">
        <a-form-item
          ref="tavr"
          label="TAVR"
          name="tavr"
          :validateStatus="validate('tavr', 'status')"
          :help="validate('tavr', 'msg')"
        >
          <a-input v-model:value="model.tavr" placeholder="Enter tavr here" />
        </a-form-item>
      </a-col>
    </template>

    <a-col :span="12">
      <a-form-item
        ref="case_status"
        label="Status"
        name="case_status"
        :validateStatus="validate('case_status', 'status')"
        :help="validate('case_status', 'msg')"
      >
        <a-checkbox-group v-model:value="model.case_status">
          <template
            v-for="caseStatus in $constants.caseStatuses"
            :key="caseStatus"
          >
            <a-checkbox :value="caseStatus">
              {{ caseStatus }}
            </a-checkbox>
          </template>
        </a-checkbox-group>
      </a-form-item>
    </a-col>

    <a-col v-if="model.hospital_id" :span="24">
      <a-form-item
        ref="case_service_request_type"
        label="TYPE OF SERVICE REQUESTED"
        name="case_service_request_type"
        :validateStatus="validate('case_service_request_type', 'status')"
        :help="validate('case_service_request_type', 'msg')"
      >
        <a-checkbox-group v-model:value="model.case_service_request_type">
          <template v-if="caseServiceRequestTypes.length > 0">
            <a-row>
              <a-col
                v-for="caseServiceRequestType in caseServiceRequestTypes"
                :key="caseServiceRequestType"
                :span="12"
              >
                <a-checkbox :value="caseServiceRequestType">
                  {{ caseServiceRequestType }}
                </a-checkbox>
              </a-col>
            </a-row>
          </template>

          <template v-else>
            <a-typography-text type="danger">
              No services assigned to selected facility.
            </a-typography-text>
          </template>
        </a-checkbox-group>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="is_disposable_use"
        label="Were keystone perfusion products used for this perfusion?"
        name="is_disposable_use"
        :validateStatus="validate('is_disposable_use', 'status')"
        :help="validate('is_disposable_use', 'msg')"
      >
        <a-radio-group v-model:value="model.is_disposable_use">
          <a-radio :value="1">Yes</a-radio>
          <a-radio :value="0">No</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="height"
        label="Height (Cm)"
        name="height"
        :validateStatus="validate('height', 'status')"
        :help="validate('height', 'msg')"
      >
        <a-input-number
          type="number"
          v-model:value="model.height"
          placeholder="Enter height here"
          :min="1"
          @change="calculateBSA"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item
        ref="weight"
        label="Weight (Kg)"
        name="weight"
        :validateStatus="validate('weight', 'status')"
        :help="validate('weight', 'msg')"
      >
        <a-input-number
          type="number"
          v-model:value="model.weight"
          placeholder="Enter weight here"
          :min="1"
          @change="calculateBSA"
        />
      </a-form-item>
    </a-col>

    <a-col :span="8">
      <a-form-item ref="body_surface_area" name="body_surface_area">
        <template #label> Body Surface Area (m<sup>2</sup>) </template>

        <a-input
          v-model:value="model.body_surface_area"
          placeholder="Body serface area"
          :disabled="true"
        />
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="allergies"
        label="Allergies"
        name="allergies"
        :validateStatus="validate('allergies', 'status')"
        :help="validate('allergies', 'msg')"
      >
        <a-input
          v-model:value="model.allergies"
          placeholder="Enter allergies here"
        />
      </a-form-item>
    </a-col>

    <template v-if="isCaseServiceRequestTypeHasEcmo">
      <a-col :span="12">
        <a-form-item
          ref="ecmo_hours"
          label="Hours on ECMO"
          name="ecmo_hours"
          :validateStatus="validate('ecmo_hours', 'status')"
          :help="validate('ecmo_hours', 'msg')"
        >
          <a-input
            v-model:value="model.ecmo_hours"
            placeholder="Enter hours here"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12"></a-col>
    </template>

    <template v-if="isCaseServiceRequestTypeHasCpb">
      <a-col :span="8">
        <a-form-item
          ref="cpb_time"
          label="CPB Time (Minutes)"
          name="cpb_time"
          :validateStatus="validate('cpb_time', 'status')"
          :help="validate('cpb_time', 'msg')"
        >
          <a-input
            v-model:value="model.cpb_time"
            placeholder="Enter cpb time in minutes here"
          />
        </a-form-item>
      </a-col>

      <a-col v-if="isCaseServiceRequestTypeHasCpb" :span="8">
        <a-form-item
          ref="cross_clamp_time"
          label="Cross-Clamp Time (Minutes)"
          name="cross_clamp_time"
          :validateStatus="validate('cross_clamp_time', 'status')"
          :help="validate('cross_clamp_time', 'msg')"
        >
          <a-input
            v-model:value="model.cross_clamp_time"
            placeholder="Enter cross-clamp time in minutes here"
          />
        </a-form-item>
      </a-col>

      <a-col v-if="isCaseServiceRequestTypeHasCpb" :span="8">
        <a-form-item
          ref="circulatory_arrest_time"
          label="Circulatory Arrest Time (Minutes)"
          name="circulatory_arrest_time"
          :validateStatus="validate('circulatory_arrest_time', 'status')"
          :help="validate('circulatory_arrest_time', 'msg')"
        >
          <a-input
            v-model:value="model.circulatory_arrest_time"
            placeholder="Enter Circulatory arrest time in minutes here"
          />
        </a-form-item>
      </a-col>
    </template>

    <a-col :span="12">
      <a-form-item
        ref="clinician"
        label="Clinician"
        name="clinician"
        :validateStatus="validate('clinician', 'status')"
        :help="validate('clinician', 'msg')"
      >
        <a-input
          v-model:value="model.clinician"
          placeholder="Enter clinician here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12"></a-col>

    <template v-if="isCatiInvoiceAmountShow">
      <a-col :span="12">
        <a-form-item
          ref="cati_invoice_amount"
          label="CATI Invoice Amount"
          name="cati_invoice_amount"
          :validateStatus="validate('cati_invoice_amount', 'status')"
          :help="validate('cati_invoice_amount', 'msg')"
        >
          <a-input
            v-model:value="model.cati_invoice_amount"
            placeholder="Enter invoice amount here"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12"></a-col>
    </template>
  </a-row>
</template>

<script>
import { commonService } from "../../../services";
export default {
  props: {
    model: { require: true, type: Object },
    hospitals: { require: true, type: Array },
    isCati: { require: true, type: Boolean },
    isCaseServiceRequestTypeHasCpb: { require: true, type: Boolean },
    isCaseServiceRequestTypeHasEcmo: { require: true, type: Boolean },
    isLogNumberShow: { require: true, type: Boolean },
    isCatiInvoiceAmountShow: { require: true, type: Boolean },
    isTavrInputShow: { require: true, type: Boolean },
  },

  emits: ["validate"],

  data() {
    return {
      caseServiceRequestTypes: [],
    };
  },

  mounted() {
    this.fetchCaseServices();
  },

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },

    hospitalIdIs(params) {
      if (typeof params == "number") {
        params = [params];
      }

      return params.includes(this.model.hospital_id);
    },

    handleHospitalChangeEvent() {
      this.model.case_service_request_type = [];
      this.fetchCaseServices();
    },

    fetchCaseServices() {
      if (this.model.hospital_id) {
        commonService
          .get(this.$constants.getCaseServicesUrl, {
            hospital_id: this.model.hospital_id,
          })
          .then((res) => {
            this.caseServiceRequestTypes = res.data.map((item) => item.name);
          });
      }
    },

    calculateBSA() {
      if (this.model.weight != "" && this.model.height != "") {
        this.model.body_surface_area = Math.sqrt(
          (this.model.weight * this.model.height) / 3600
        ).toFixed(2);
      }
    },
  },
};
</script>
