<template>
  <template v-if="!ecmo">
    <a-card size="small">
      <a-empty />
    </a-card>
  </template>

  <template v-else>
    <div class="monitor">
      <div class="monitor-top">
        <div class="monitor-message">
          <h3>
            {{ caseReport.patient_first_name }}
            {{ caseReport.patient_last_name }} | MR:
            {{ caseReport.mr_number }} | Acct:
            {{ caseReport.data.account_number ?? "N/A" }} | DOB:
            {{ $customDate.mdy(caseReport.data.dob) }} | Gender:
            {{ caseReport.data.gender }} | Allergies:
            {{ caseReport.data.allergies ?? "N/A" }} | BSA :
            {{ caseReport.data.body_surface_area ?? "N/A" }}
          </h3>
        </div>
      </div>
      <div class="monitor-middle">
        <div class="monitor-left">
          <div class="timer-actions">
            <ul class="timer-action-list">
              <li class="action-item">
                <a-spin :spinning="ecmoLoading">
                  <button
                    class="timer-action"
                    :class="
                      checkTimer('ecmo') && !checkTimer('ecmo')['stop_time']
                        ? 'timer-action-active'
                        : ''
                    "
                    @click="
                      hasNotCompletedEcmo
                        ? handleTimerEvent('ecmo', 'ECMO')
                        : ''
                    "
                  >
                    <span class="title">
                      ECMO
                      <span style="font-weight: normal !important">
                        {{ timer("ecmo") }}
                      </span>
                    </span>
                    <span class="start">
                      {{
                        checkTimer("ecmo") && checkTimer("ecmo")["start_time"]
                          ? checkTimer("ecmo")["start_time"].slice(11, 16)
                          : "-"
                      }}
                    </span>
                    <span class="stop">
                      {{
                        checkTimer("ecmo") && checkTimer("ecmo")["stop_time"]
                          ? checkTimer("ecmo")["stop_time"].slice(11, 16)
                          : "-"
                      }}
                    </span>
                  </button>
                </a-spin>
              </li>
            </ul>
          </div>
          <div class="event-actions">
            <ul class="event-action-list">
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedEcmo
                      ? open({
                          title: 'Checklist',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.ecmoChecklist',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Checklist</span>
                  <span class="description">
                    {{ ecmo.checklist.submitted_at }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    canAddEvents
                      ? open({
                          title: 'ECMO Status',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.ecmoStatus',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord: lastRecord('Status') },
                        })
                      : ''
                  "
                >
                  <span class="title">Status</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    canAddEvents
                      ? open({
                          title: 'ECMO Blood Gas',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.ecmoBloodGas',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord: lastRecord('Blood Gas') },
                        })
                      : ''
                  "
                >
                  <span class="title">Blood Gas</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    canAddEvents
                      ? open({
                          title: 'ECMO Coagulation',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.ecmoCoagulation',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord: lastRecord('Coagulation') },
                        })
                      : ''
                  "
                >
                  <span class="title">Coagulation</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    open({
                      title: 'Case Data',
                      width: '90%',
                      path: 'newCaseReport.drawerForm.caseData',
                      callback: callback,
                      record: caseReport,
                    })
                  "
                >
                  <span class="title">Case Data</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action event-action-close"
                  @click="$emit('closeCaseClickEvent')"
                >
                  <span class="title">Close Case</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="monitor-right">
          <div
            class="monitor-right-middle"
            ref="ecmoDatatableRef"
            style="padding: 1em 0 0 0"
          >
            <div class="event-table scroller">
              <table class="event-table-compact">
                <thead>
                  <tr>
                    <th rowspan="2">Time</th>
                    <th colspan="4">Vital Signs</th>
                    <th colspan="7">Pump</th>
                    <th colspan="3">Temperature (°C)</th>
                    <th colspan="11">Blood Gas</th>
                    <th colspan="2">Coagulation</th>
                  </tr>
                  <tr>
                    <th>Arterial BP (mmHg)</th>
                    <th>CVP/RA (mmHG)</th>
                    <th>HR (BPM)</th>
                    <th>SpO<sub>2</sub></th>
                    <th>Pump Flow (L/min)</th>
                    <th>Pump RPM</th>
                    <th>Gas Flow (L/min)</th>
                    <th>FiO<sub>2</sub> (%)</th>
                    <th>Pre Oxy (mmHg)</th>
                    <th>Post Oxy (mmHg)</th>
                    <th>Δ P (mmHg)</th>
                    <th>Core</th>
                    <th>Post-Exchanger</th>
                    <th>Water Bath</th>

                    <th class="highlight"><sub>p</sub>H</th>
                    <th class="highlight">PCO<sub>2</sub> (mmHg)</th>
                    <th class="highlight">PO<sub>2</sub> (mmHg)</th>
                    <th class="highlight">Temp (°C)</th>
                    <th class="highlight">HCO<sub>3</sub> (mmol/l)</th>
                    <th class="highlight">BASE</th>
                    <th class="highlight">Hgb/HCT</th>
                    <th class="highlight">SaO<sub>2</sub> (%)</th>
                    <th class="highlight">S<sub>v</sub>O<sub>2</sub> (%)</th>
                    <th class="highlight">K<sup>+</sup></th>
                    <th class="highlight">Ventilator FiO<sub>2</sub></th>

                    <th>PTT (sec)</th>
                    <th>Heparin (units/HR)</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasRecords">
                    <template
                      v-for="(item, index) in events ?? []"
                      :key="index"
                    >
                      <tr v-if="item.event == 'Date change'">
                        <td
                          colspan="28"
                          align="left"
                          style="
                            font-weight: 500;
                            background-color: #eee;
                            padding-left: 10px;
                          "
                        >
                          {{ item.comment }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td
                          v-if="
                            (canAddEvents &&
                              item.employee_id == employee.id &&
                              !['Shift Change'].includes(item.event)) ||
                            isAdminLiveChartingPage
                          "
                        >
                          <a-popover placement="left">
                            <template #content>
                              <a-space>
                                <a-button
                                  type="primary"
                                  @click="handleEditEvent(item)"
                                >
                                  Edit
                                </a-button>
                                <a-popconfirm
                                  v-if="item.event != 'ECMO'"
                                  title="Confirm deletion?"
                                  ok-text="Yes"
                                  cancel-text="No"
                                  @confirm="deleteRecord(item.unique_code)"
                                >
                                  <a-button danger>Delete</a-button>
                                </a-popconfirm>
                              </a-space>
                            </template>
                            {{ item?.time?.slice(0, 5) ?? "-" }}
                          </a-popover>
                        </td>
                        <td v-else>
                          {{ item?.time?.slice(0, 5) ?? "-" }}
                        </td>
                        <template
                          v-if="['ECMO', 'Shift Change'].includes(item.event)"
                        >
                          <td colspan="27" align="left">
                            <span class="text-medium">
                              {{ item.note }}
                              {{
                                item.note == "ECMO End" ? `(${ecmoTime})` : ""
                              }}
                              [Date: {{ item.date }}]
                            </span>
                          </td>
                        </template>
                        <template v-else-if="['Comment'].includes(item.event)">
                          <td colspan="27" align="left">
                            <span>{{ item.comment }} | {{ item.time }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <td>{{ item.arterial_bp ?? "-" }}</td>
                          <td>{{ item.cvp_pa ?? "-" }}</td>
                          <td>{{ item.hr ?? "-" }}</td>
                          <td>{{ item.spo2 ?? "-" }}</td>
                          <td>{{ item.pump_flow ?? "-" }}</td>
                          <td>{{ item.pump_rpm ?? "-" }}</td>
                          <td>{{ item.gas_flow ?? "-" }}</td>
                          <td>{{ item.fio2 ?? "-" }}</td>
                          <td>{{ item.pre_oxy ?? "-" }}</td>
                          <td>{{ item.post_oxy ?? "-" }}</td>
                          <td
                            :style="
                              item.delta_oxy > 35
                                ? 'background-color: #e7b2b2'
                                : ''
                            "
                          >
                            {{ item.delta_oxy ?? "-" }}
                          </td>
                          <td>{{ item.core ?? "-" }}</td>
                          <td>{{ item.post_exchanger ?? "-" }}</td>
                          <td>{{ item.water_bath ?? "-" }}</td>

                          <td class="highlight">{{ item.ph ?? "-" }}</td>
                          <td class="highlight">{{ item.pco2 ?? "-" }}</td>
                          <td class="highlight">{{ item.po2 ?? "-" }}</td>
                          <td class="highlight">{{ item.temp ?? "-" }}</td>
                          <td class="highlight">{{ item.hco3 ?? "-" }}</td>
                          <td class="highlight">{{ item.base ?? "-" }}</td>
                          <td class="highlight">
                            {{ item.hb ?? "-" }} / {{ item.hct ?? "-" }}
                          </td>
                          <td class="highlight">{{ item.sao2 ?? "-" }}</td>
                          <td class="highlight">{{ item.svo2 ?? "-" }}</td>
                          <td class="highlight">{{ item.kplus ?? "-" }}</td>
                          <td class="highlight">
                            {{ item.ventilator ?? "-" }}
                          </td>

                          <td>{{ item.ptt ?? "-" }}</td>
                          <td>{{ item.heparin ?? "-" }}</td>
                        </template>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="28" align="left">
                        <strong>No Data Found.</strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
          <div class="monitor-right-bottom">
            <div class="quick-actions">
              <ul class="quick-actions-list">
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      canAddEvents
                        ? open({
                            title: 'ECMO Comment',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.ecmoComment',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Events
                  </button>
                </li>

                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      canAddEvents
                        ? open({
                            title: 'Shift Change',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.shiftChange',
                            callback: (e) => redirectToIndexPage(),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Shift Change
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { createVNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  props: [
    "caseReport",
    "ecmo",
    "uuid",
    "isCompleted",
    "isAdminLiveChartingPage",
  ],

  emits: ["updateCaseReport", "closeCaseClickEvent"],

  data() {
    return {
      ecmoLoading: false,

      currentDateTime: "",
      currentDateTimeInverval: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.caseReport?.hospital?.timezone ?? this.$constants.timezone;
    },

    hasNotCompletedEcmo() {
      return !this.isCompleted || this.isAdminLiveChartingPage;
    },

    onlyActiveRecords() {
      let data = this.ecmo?.data
        ? this.ecmo.data.filter((item) => !item.deleted_at)
        : [];

      if (data.length != 0) {
        let firstDate = data[0].date;
        let lastDate = data[data.length - 1].date;
        let range = this.$comman.getDateRange(firstDate, lastDate);
        range.forEach((date, idx) => {
          if (idx != 0) {
            data.push({
              event: "Date change",
              date: date,
              time: "00:00:00",
              datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
              comment: `Date: ${date}`,
            });
          }
        });
      }

      return data.sort((a, b) =>
        moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
          moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
        )
          ? 1
          : -1
      );
    },

    hasRecords() {
      return this.onlyActiveRecords.length > 0 ? true : false;
    },

    events() {
      if (!this.hasRecords) {
        return [];
      }
      return this.onlyActiveRecords.sort((a, b) =>
        moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
          moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
        )
          ? 1
          : -1
      );
    },

    hasTimers() {
      return this.ecmo?.timers ? true : false;
    },

    canAddEvents() {
      return (
        (!this.isCompleted &&
          this.checkTimer("ecmo") &&
          this.checkTimer("ecmo")["start_time"] &&
          !this.checkTimer("ecmo")["stop_time"]) ||
        this.isAdminLiveChartingPage
      );
    },

    ecmoTime() {
      if (this.ecmo?.timers?.ecmo[0]) {
        let timers = this.ecmo.timers.ecmo[0];
        let startTime = moment(timers.start_time, "MM-DD-YYYY HH:mm:ss");
        let stopTime = moment(timers.stop_time, "MM-DD-YYYY HH:mm:ss");
        let duration = stopTime.diff(startTime, "seconds");
        return this.$comman.secondsToHms1(duration);
      }
      return "";
    },
  },

  mounted() {
    this.currentDateTime = moment()
      .tz(this.timezone)
      .format("MM-DD-YYYY HH:mm:ss");
    var self = this;
    this.currentDateTimeInverval = setInterval(function () {
      self.currentDateTime = moment()
        .tz(self.timezone)
        .format("MM-DD-YYYY HH:mm:ss");
    }, 1000);
  },

  unmounted() {
    clearInterval(this.currentDateTimeInverval);
  },

  methods: {
    ...mapActions("modal", ["open"]),

    callback() {
      // console.log("callback::");
    },

    handleEditEvent(item) {
      let path = "newCaseReport.drawerForm.";
      let title = `Edit ${item.event}`;
      if (item.event == "Status") {
        path += "ecmoStatus";
      } else if (item.event == "Blood Gas") {
        path += "ecmoBloodGas";
      } else if (item.event == "Coagulation") {
        path += "ecmoCoagulation";
      } else if (item.event == "Comment") {
        path += "ecmoComment";
      } else if (item.event == "ECMO") {
        path += "ecmoTimer";
        title += " Timer";
      }
      this.open({
        title,
        path,
        callback: (e) => this.$emit("updateCaseReport", e),
        record: this.caseReport,
        extra: { record: item },
      });
    },

    deleteRecord(unique_code) {
      commonService
        .store(this.$constants.caseReportStoreECMORecordUrl, {
          unique_code,
          uuid: this.uuid,
          delete: true,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err));
    },

    checkTimer(type) {
      return this.hasTimers && this.ecmo.timers[type]
        ? this.ecmo.timers[type][0]
        : null;
    },

    timer(type) {
      let checkTimer = this.checkTimer(type);
      if (checkTimer) {
        let startDateTime = checkTimer.start_time;
        let endDateTime = checkTimer.stop_time;
        if (this.currentDateTime && startDateTime) {
          let seconds = moment(
            endDateTime ?? this.currentDateTime,
            "MM-DD-YYYY HH:mm:ss"
          ).diff(moment(startDateTime, "MM-DD-YYYY HH:mm:ss"), "seconds");
          return this.$comman.secondsToHms1(seconds);
        }
      }
      return "";
    },

    async handleTimerEvent(type, event) {
      if (this.checkTimer(type) && this.checkTimer(type)["stop_time"]) {
        return;
      }

      this.ecmoLoading = true;
      let data = { uuid: this.uuid, type, event };
      if (this.checkTimer(type) && !this.checkTimer(type)["stop_time"]) {
        data.stop_time = moment()
          .tz(this.timezone)
          .format("MM-DD-YYYY HH:mm:ss");
        data.note = `ECMO End`;
        let that = this;

        Modal.confirm({
          title: () => `Are you sure you want to stop ECMO?`,
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            that.storeTimer(data);
          },
          okText: "Confirm",
          okType: "danger",
          keyboard: false,
          onCancel() {
            that.ecmoLoading = false;
            return;
          },
        });
        return;
      } else {
        data.start_time = moment()
          .tz(this.timezone)
          .format("MM-DD-YYYY HH:mm:ss");
        data.note = `ECMO Start`;
      }

      this.storeTimer(data);
    },

    storeTimer(data) {
      data.date = moment().tz(this.timezone).format("MM-DD-YYYY");
      data.time = moment().tz(this.timezone).format("HH:mm:ss");
      data.datetime = `${data.date} ${data.time}`;
      data.employee_id = this.employee.id;
      data.employee_name = this.employee.full_name;

      commonService
        .store(this.$constants.caseReportStoreECMOTimerRecordUrl, data)
        .then((res) => {
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.ecmoLoading = false));
    },

    redirectToIndexPage() {
      this.$router.push({
        name:
          this.$route.name == "admin.case-report-live-charting"
            ? "admin.case-report"
            : "case-report",
      });
    },

    lastRecord(type) {
      let records = this.events.filter((e) => e.event == type);
      return records ? records[records.length - 1] : null;
    },
  },
};
</script>

<style lang="less" scoped>
.monitor {
  position: relative;
  display: block;
  background-color: #001529;
  overflow: hidden;

  .monitor-top {
    display: grid;
    grid-auto-flow: column;

    .monitor-message {
      display: block;
      padding: 1em;
      background-color: #002766;

      h3 {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }

  .monitor-middle {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    gap: 0px 0px;

    .monitor-left {
      display: flex;
      flex-direction: row;
      padding: 1em;
    }

    .monitor-right {
      display: flex;
      flex-direction: column;
    }
  }

  .timer-actions {
    display: flex;
    flex-direction: column;

    .timer-action-list {
      padding-left: 0;
      list-style-type: none;

      .action-item {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        .timer-action {
          display: grid;
          grid-template-columns: repeat(2, 50fr);
          grid-template-rows: repeat(2, 1fr);
          align-items: center;
          align-items: stretch;
          border: none;
          border-radius: 4px;
          font-size: 12px;
          width: 100%;
          padding: 0;
          color: #254000;
          background-color: #d3f261;
          transition: all 300ms ease-in-out;
          cursor: pointer;
          overflow: hidden;

          &.timer-action-active {
            background-color: #ff7875;
            box-shadow: inset 4px 6px 10px rgba(63, 62, 54, 0.5);
          }

          .title {
            align-content: center;
            display: grid;
            grid-area: 1 / 1 / 3 / 2;
            font-size: 14px;
            font-weight: 700;
            border-right: 1px solid #ccc;
          }

          .start {
            grid-area: 1 / 2 / 2 / 3;
            text-align: center;
            padding: 5px;
            background-color: #ffffff;
            border-bottom: 1px solid #ccc;
          }

          .stop {
            grid-area: 2 / 2 / 3 / 3;
            text-align: center;
            padding: 5px;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .event-actions {
    display: flex;
    flex-direction: column;

    .event-action-list {
      padding-left: 0;
      list-style-type: none;

      .action-item {
        margin-bottom: 1em;

        .description {
          font-weight: normal;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .event-action {
          border-radius: 4px;
          display: grid;
          align-items: center;
          align-items: stretch;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          padding: 1em;
          color: #030852;
          background-color: #bae0ff;
          border: none;
          cursor: pointer;
          overflow: hidden;

          &.event-action-close {
            color: #ffffff;
            background-color: #cf1322;
          }
        }
      }
    }
  }

  .indicators {
    position: relative;
    display: block;
    padding: 1em 0;
    overflow-x: auto;

    .indicators-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;

      .indicator-item {
        display: flex;
        flex-direction: column;
        margin-right: 1em;
        border-radius: 4px;
        padding: 5px 10px;
        color: #030852;
        background-color: #bae0ff;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }

        span {
          display: block;
          min-width: 100px;
          white-space: nowrap;
        }

        .count {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .event-table {
    display: block;
    width: 100%;
    height: 500px;
    overflow-x: auto;
    padding: 0;

    .event-table-compact {
      position: relative;
      color: #07203c;
      background-color: #ffffff;
      border-collapse: initial;
      border-spacing: 0;
      width: 100%;
      text-align: center;
      border-radius: 4px;
      overflow: scroll;

      thead {
        background-color: #fff;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

        tr {
          th {
            border: 1px solid #ccc;
            min-width: 60px;

            &.highlight {
              background-color: #eee;
            }
          }

          &:nth-child(1) {
            th:nth-child(2) {
              min-width: 100px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            border: 1px solid #ccc;

            &.highlight {
              background-color: #eee;
            }
          }
        }
      }
    }
  }

  .scroller {
    & {
      scrollbar-width: thin;
      scrollbar-color: #243d5c #001529;
    }

    &::-webkit-scrollbar {
      width: 4px;
      width: 4px;
      height: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: #001529;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #001529;
    }

    &::-webkit-scrollbar-track:active {
      background-color: #001529;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #243d5c;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #243d5c;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #243d5c;
    }
  }

  .flow-info {
    .flow-info-table {
      text-align: center;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      thead {
        background-color: #bae0ff;

        tr {
          th {
            border: 1px solid #bae0ff;
          }
        }
      }

      tbody {
        background-color: #ffffff;

        tr {
          td {
            border: 1px solid #ccc;
          }
        }
      }
    }
  }

  .quick-actions {
    position: relative;
    display: block;
    padding: 1em 0;
    overflow-x: auto;

    .quick-actions-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;

      .quick-item {
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }

        .action {
          border-radius: 4px;
          display: grid;
          align-items: center;
          align-items: stretch;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          padding: 1em;
          color: #873800;
          background-color: #ffe7ba;
          border: none;
          cursor: pointer;
          overflow: hidden;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .monitor {
    .monitor-middle {
      grid-template-columns: 15% 85%;
      grid-template-rows: 1fr;

      .monitor-left {
        flex-direction: column;
      }
    }
  }
}
</style>
