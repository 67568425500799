<template>
  <a-divider orientation="left">ATS Live Charting</a-divider>
  <a-card size="small" bordered>
    <a-row type="flex">
      <a-col flex="35px"> N/A </a-col>
      <a-col flex="35px"><CheckOutlined /></a-col>
      <a-col flex="calc(100% - 70px)"> </a-col>
    </a-row>

    <template v-for="option in $constants.preProcedureChecklist" :key="option">
      <a-row type="flex">
        <a-col flex="35px">
          <i
            v-if="atx.checklist?.not_applicable?.includes(option)"
            class="ti ti-checkbox ti-lg"
          />
          <i v-else class="ti ti-square ti-lg"></i>
        </a-col>
        <a-col flex="35px">
          <i
            v-if="atx.checklist?.checked?.includes(option)"
            class="ti ti-checkbox ti-lg"
          />
          <i v-else class="ti ti-square ti-lg"></i>
        </a-col>
        <a-col flex="calc(100% - 70px)">{{ option }}</a-col>
      </a-row>
    </template>

    <a-divider class="mt-sm-1 mb-sm-1" />

    <strong>Reported At:</strong>
    {{ atx.checklist.submitted_at }}
  </a-card>

  <a-table
    class="mt-sm-1"
    :rowKey="(_, index) => index"
    :columns="atsTablecolumns"
    :data-source="atsEvents"
    bordered
    size="small"
    :pagination="false"
    :row-class-name="
      (record) =>
        record.time == 'Total' ? 'ats-live-charting-table-footer-tr' : ''
    "
  />
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";

export default {
  name: "atx-live-charting",
  props: ["atx"],

  components: { CheckOutlined },

  data() {
    return {
      atsTablecolumns: [
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          customRender: ({ text }) =>
            text != "Total" ? text.slice(0, 5) : text,
        },
        // {
        //   title: "Event",
        //   dataIndex: "event",
        //   key: "event",
        // },
        {
          title: "Volume Processed",
          dataIndex: "volume_processed",
          key: "volume_processed",
          customRender: this.atsColspanRender,
        },
        {
          title: "Volume Returned",
          dataIndex: "volume_returned",
          key: "volume_returned",
          customRender: this.atsColspanRender,
        },
        {
          title: "Volume Anticoag",
          dataIndex: "volume_anticoag",
          key: "volume_anticoag",
          customRender: this.atsColspanRender,
        },
        {
          title: "Estimated Blood Loss",
          dataIndex: "estimated_blood_loss",
          key: "estimated_blood_loss",
          customRender: this.atsColspanRender,
        },
        {
          title: "Wash Volume",
          dataIndex: "wash_volume",
          key: "wash_volume",
          customRender: this.atsColspanRender,
        },
        {
          title: "Comment",
          dataIndex: "comment",
          key: "comment",
          customRender: this.atsColspanRender,
        },
      ],
    };
  },

  computed: {
    atsEvents() {
      let data = [];
      if (this.atx.data) {
        data = this.atx.data
          .filter((e) => !e.deleted_at)
          .sort((a, b) =>
            moment(a.time, "HH:mm:ss").isAfter(moment(b.time, "HH:mm:ss"))
              ? 1
              : -1
          );

        data.push({
          time: "Total",
          volume_processed: data.reduce(
            (pre, curr) => pre + (curr.volume_processed ?? 0),
            0
          ),
          volume_returned: data.reduce(
            (pre, curr) => pre + (curr.volume_returned ?? 0),
            0
          ),
          volume_anticoag: data.reduce(
            (pre, curr) => pre + (curr.volume_anticoag ?? 0),
            0
          ),
          estimated_blood_loss: data.reduce(
            (pre, curr) => pre + (curr.estimated_blood_loss ?? 0),
            0
          ),
          wash_volume: data.reduce(
            (pre, curr) => pre + (curr.wash_volume ?? 0),
            0
          ),
        });
      }
      return data;
    },
  },

  methods: {
    atsColspanRender(data) {
      if (data.record.event == "Shift Change") {
        if (data.column.key == "volume_processed") {
          return {
            children: `${data.record.note} ${
              data.record.duration
                ? `(${moment
                    .utc(data.record.duration * 1000)
                    .format("HH:mm:ss")})`
                : ""
            } [Date: ${data.record.date}]`,
            props: { colSpan: 6 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else {
        return data.text;
      }
    },
  },
};
</script>
