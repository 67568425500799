import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/equipment-maintenances",
    name: "equipment-maintenance",
    component: route("equipmentMaintenance/index"),
    meta: {
      title: "Heater Cooler Maintenance",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/equipment-maintenance/create",
    name: "create-equipment-maintenance",
    component: route("equipmentMaintenance/store"),
    meta: {
      title: "Create New Heater Cooler Maintenance",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/equipment-maintenance/edit/:id",
    name: "edit-equipment-maintenance",
    component: route("equipmentMaintenance/store"),
    meta: {
      title: "Edit Heater Cooler Maintenance",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/equipment-maintenance/show/:id",
    name: "show-equipment-maintenance",
    component: route("equipmentMaintenance/show"),
    meta: {
      title: "Show Heater Cooler Maintenance",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
