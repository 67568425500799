<template>
  <a-layout-content>
    <page-header>
      <template #title> Profile</template>
    </page-header>

    <!-- <a-alert
      v-if="employee.agreement_accepted_at"
      type="success"
      show-icon
      class="mb-sm-1"
    >
      <template #description>
        <a-row type="flex" justify="space-between">
          <a-typography-text>
            You have agreed to the Acceptable Use Agreement for KeyPort on
            <strong>
              {{ customDate.mdyhis(employee.agreement_accepted_at) }}
            </strong>
          </a-typography-text>
        </a-row>
      </template>
    </a-alert> -->

    <a-alert
      v-if="pendingTasks.length > 0 && employee.agreement_accepted_at"
      type="warning"
      show-icon
      class="mb-sm-1"
    >
      <template #message>
        <a-typography-text strong> Notice</a-typography-text>
      </template>

      <template #description>
        Following items are pending and need to be completed before you can
        access your account. All below documents are subject to approval.

        <ul class="mb-sm-0">
          <li v-for="task in pendingTasks" :key="task">
            <span v-if="task.includes('*')">
              {{ task.split("*")[0] }}
              <a-typography-text type="danger" strong>*</a-typography-text>
              {{ task.split("*")[1] }}
            </span>
            <span v-else>{{ task }}</span>
          </li>
        </ul>
      </template>
    </a-alert>

    <a-card title="" class="profile-card">
      <a-row>
        <a-col :xs="24" :lg="0">
          <a-menu
            v-model:selectedKeys="menu.selectedKeys"
            theme="light"
            mode="horizontal"
          >
            <a-menu-item
              v-for="m in menuItems"
              :key="m.key"
              :class="m.class ? 'ant-menu-item-highlight' : ''"
            >
              {{ m.text }}
              <span class="alert-warning-pulse">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  fill="rgba(235,0,0,1)"
                >
                  <path
                    d="M4.00001 20V14C4.00001 9.58172 7.58173 6 12 6C16.4183 6 20 9.58172 20 14V20H21V22H3.00001V20H4.00001ZM6.00001 14H8.00001C8.00001 11.7909 9.79087 10 12 10V8C8.6863 8 6.00001 10.6863 6.00001 14ZM11 2H13V5H11V2ZM19.7782 4.80761L21.1924 6.22183L19.0711 8.34315L17.6569 6.92893L19.7782 4.80761ZM2.80762 6.22183L4.22183 4.80761L6.34315 6.92893L4.92894 8.34315L2.80762 6.22183Z"
                  ></path>
                </svg>
              </span>
            </a-menu-item>
          </a-menu>
        </a-col>
      </a-row>
      <a-row>
        <a-col :xs="0" :lg="4">
          <a-menu
            v-model:selectedKeys="menu.selectedKeys"
            theme="light"
            mode="inline"
          >
            <a-menu-item
              v-for="m in menuItems"
              :key="m.key"
              :class="m.class ? 'ant-menu-item-highlight' : ''"
            >
              {{ m.text }}
              <span class="alert-warning-pulse">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  fill="rgba(235,0,0,1)"
                >
                  <path
                    d="M4.00001 20V14C4.00001 9.58172 7.58173 6 12 6C16.4183 6 20 9.58172 20 14V20H21V22H3.00001V20H4.00001ZM6.00001 14H8.00001C8.00001 11.7909 9.79087 10 12 10V8C8.6863 8 6.00001 10.6863 6.00001 14ZM11 2H13V5H11V2ZM19.7782 4.80761L21.1924 6.22183L19.0711 8.34315L17.6569 6.92893L19.7782 4.80761ZM2.80762 6.22183L4.22183 4.80761L6.34315 6.92893L4.92894 8.34315L2.80762 6.22183Z"
                  ></path>
                </svg>
              </span>
            </a-menu-item>
          </a-menu>
        </a-col>
        <a-col :xs="24" :lg="20">
          <div class="profile-content">
            <a-row>
              <a-col :xs="0" :lg="24">
                <h2>{{ selectedMenu?.text ?? "-" }}</h2>
                <a-divider />
              </a-col>
            </a-row>
            <component
              v-if="selectedMenu.component"
              :is="selectedMenu.component"
              :companies="companies"
              @changeTab="changeTab"
              @refreshCertificateData="updateCertificates"
            />
          </div>
        </a-col>
      </a-row>
    </a-card>

    <a-modal
      v-model:visible="showModal"
      centered
      :closable="false"
      :maskClosable="false"
      :footer="false"
      :keyboard="false"
    >
      <a-space
        :size="20"
        direction="vertical"
        align="center"
        style="width: 100%"
      >
        <a-typography-title type="warning" :level="3" class="mb-sm-0">
          <exclamation-circle-outlined />
        </a-typography-title>

        <a-space :size="1" direction="vertical" align="center">
          <a-typography-title
            type="danger"
            :level="5"
            style="text-align: center"
          >
            {{ formattedModalMessage }}
          </a-typography-title>
        </a-space>
      </a-space>

      <a-divider />

      <div class="model-footer" style="text-align: center">
        <a-button type="primary" @click="handleModalClose"> Ok</a-button>
      </div>
    </a-modal>
  </a-layout-content>
</template>

<script setup>
import { comman as commonFunctions } from "@/helper/comman";
import { constants } from "@/helper/constants";
import { companyService } from "@/services";
import moment from "moment/moment";
import { useStore } from "vuex";

import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { computed, onMounted, reactive, ref } from "vue";
import basic_info from "./basic_info";
import certifications from "./certificate/index";
import change_password from "./change_password";
import documents from "./documents";
import fw9 from "./fw9.vue";
import i9 from "./i9.vue";
import master_settings from "./master_settings";
import notifications from "./notifications";
import onboarding from "./onboarding";

const companies = ref([]);
const certificates = ref([]);
const certificateTypes = ref([]);

const store = useStore();

const employee = computed(() => store.getters["auth/employee"]);
const formI9 = computed(() => store.getters["auth/formI9"]);
const formW9 = computed(() => store.getters["auth/formW9"]);

const canShowOnboardingTab = computed(
  () => store.getters["auth/canShowOnboardingTab"]
);
const isAdmin = computed(() => store.getters["auth/isAdmin"]);
const isEmployeeIdOne = computed(() => employee.value?.id === 1);
const isContractor = computed(() => employee.value?.employment_type === 2);

const hasFormI9Filled = computed(() => !!formI9.value);
const hasFormI9Submitted = computed(() => formI9.value?.status === 0);
const hasFormI9Approved = computed(() => formI9.value?.status === 1);

const hasFormW9Filled = computed(() => !!formW9.value);
const hasFormW9Submitted = computed(() => formW9.value?.status === 0);
const hasFormW9Approved = computed(() => formW9.value?.status === 1);
const isFormW9Required = computed(() => isContractor.value);

function checkRequiredCertificate(cer) {
  const employeeTypesCols = constants.employee_types.reduce(
    (holder, currentEmpType) => {
      holder[currentEmpType.value] = currentEmpType.key;
      return holder;
    },
    {}
  );
  return cer[employeeTypesCols[employee.value.employee_type]];
}

const isAllRequiredCertificatesUploaded = computed(() => {
  const requiredCerts = certificateTypes.value?.filter((certificate) => {
    return checkRequiredCertificate(certificate);
  });

  return requiredCerts.every((certificate) => {
    return (
      certificate.employeeCertificates?.filter((cert) => {
        return [0, 1].includes(cert.approval_status);
      }).length > 0
    );
  });
});
const areCertificatesPendingForApproval = computed(() => {
  return certificates.value.some((cert) => {
    return cert["approval_status"] === 0;
  });
});

const isDoneLoading = ref(false);
const showModal = ref(false);

function handleModalClose() {
  showModal.value = false;
}

const formattedModalMessage = computed(() => {
  if (!isDoneLoading.value || isAdmin.value) {
    return "";
  }

  let msg = "";

  if (
    !isAllRequiredCertificatesUploaded.value ||
    canShowOnboardingTab.value ||
    !hasFormI9Filled.value ||
    (isFormW9Required.value && !hasFormW9Filled.value)
  ) {
    msg += "There are ";

    if (!isAllRequiredCertificatesUploaded.value) {
      msg += "outstanding credentialing documents that need to be uploaded";
    }

    if (canShowOnboardingTab.value) {
      if (!isAllRequiredCertificatesUploaded.value) {
        msg += ", ";
      }
      msg += "unsigned items in Onboarding";
    }

    if (isFormW9Required.value && !hasFormW9Submitted.value) {
      if (
        !isAllRequiredCertificatesUploaded.value ||
        canShowOnboardingTab.value
      ) {
        msg += ", ";
      }
      msg += "pending Form W-9 submission";
    }

    if (!hasFormI9Submitted.value) {
      if (
        !isAllRequiredCertificatesUploaded.value ||
        canShowOnboardingTab.value ||
        (isFormW9Required.value && !hasFormW9Submitted.value)
      ) {
        msg += ", ";
      }
      msg += "pending Form I-9 submission";
    }

    msg += ", which need to be fulfilled before you can proceed. ";
  }

  if (areCertificatesPendingForApproval.value) {
    msg +=
      "The uploaded credentials are pending approval. Once approved, we will notify you via email.";
  }

  if (!isAllRequiredCertificatesUploaded.value) {
    msg +=
      " Click on credentialing tab to begin uploading your required documents.";
  }

  return msg;
});

const menu = reactive({
  selectedKeys: ["basic_info"],
});
function changeTab(key) {
  menu.selectedKeys = [key];
}

// function menuItemStyle({ key }) {
//   switch (key) {
//     case "certifications":
//       return !isAllRequiredCertificatesUploaded.value
//         ? "ant-menu-item-highlight"
//         : "";
//     case "fw9":
//       return isFormW9Required.value && !hasFormW9Submitted.value
//         ? "ant-menu-item-highlight"
//         : "";
//     case "i9":
//       return !hasFormI9Submitted.value ? "ant-menu-item-highlight" : "";
//     default:
//       return "";
//   }
// }

const menuItems = computed(() => [
  { key: "basic_info", text: "Basic Details", component: basic_info },
  {
    key: "change_password",
    text: "Change Password",
    component: change_password,
  },
  {
    key: "certifications",
    text: "Credentialing",
    component: certifications,
    class: !isAllRequiredCertificatesUploaded.value,
  },
  {
    key: "notifications",
    text: "Email Notifications",
    component: notifications,
  },
  ...(canShowOnboardingTab.value
    ? [{ key: "onboarding", text: "Onboarding", component: onboarding }]
    : []),
  { key: "documents", text: "Documents", component: documents },
  ...(isEmployeeIdOne.value
    ? [
        {
          key: "master_settings",
          text: "Master Settings",
          component: master_settings,
        },
      ]
    : []),
  ...(isFormW9Required.value && !hasFormW9Approved.value
    ? [
        {
          key: "fw9",
          text: "Form W-9",
          component: fw9,
          class: isFormW9Required.value && !hasFormW9Filled.value,
        },
      ]
    : []),
  ...(!hasFormI9Approved.value
    ? [
        {
          key: "i9",
          text: "Form I-9",
          component: i9,
          class: !hasFormI9Filled.value,
        },
      ]
    : []),
]);
const selectedMenu = computed(() =>
  menuItems.value.find((m) => m.key === menu.selectedKeys[0])
);

const pendingTasks = computed(() => {
  const isFormI9Pending = !formI9.value;
  const isFormI9ApprovalPending =
    hasFormI9Submitted.value && !hasFormI9Approved.value;

  const isFormW9Pending = isFormW9Required.value && !formW9.value;
  const isFormW9ApprovalPending =
    hasFormW9Submitted.value && !hasFormW9Approved.value;

  const hasRequiredDocumentMissingUpload =
    !isAllRequiredCertificatesUploaded.value;

  const isOnboardingPending = canShowOnboardingTab.value;
  const isCredentialApprovalPending = areCertificatesPendingForApproval.value;

  const messagesHolder = {
    isFormI9Pending: "Form I-9 is pending for submission",
    isFormI9ApprovalPending: "Form I-9 is pending for approval",

    isFormW9Pending: "Form W-9 is pending for submission",
    isFormW9ApprovalPending: "Form W-9 is pending for approval",

    hasRequiredDocumentMissingUpload:
      "Required credentialing documents(marked with *) are pending for submission",
    isOnboardingPending: "Onboarding tab have items to be signed and submitted",
    isCredentialApprovalPending:
      "Credentialing documents are pending approval. You will be notified via email once approved",
  };

  const holder = [];
  if (isFormI9Pending) holder.push(messagesHolder.isFormI9Pending);
  if (isFormI9ApprovalPending)
    holder.push(messagesHolder.isFormI9ApprovalPending);
  if (isFormW9Pending) holder.push(messagesHolder.isFormW9Pending);
  if (isFormW9ApprovalPending)
    holder.push(messagesHolder.isFormW9ApprovalPending);
  if (hasRequiredDocumentMissingUpload)
    holder.push(messagesHolder.hasRequiredDocumentMissingUpload);
  if (isOnboardingPending) holder.push(messagesHolder.isOnboardingPending);
  if (isCredentialApprovalPending)
    holder.push(messagesHolder.isCredentialApprovalPending);

  return holder;
});

function updateCertificates({ credentials: certs }) {
  if (!certs) return;
  certificates.value = certs.filter((cert) => {
    if (!cert.expiry_date) return true;
    return moment(cert.expiry_date).isAfter(moment().subtract(1, "days"));
  });

  certificateTypes.value.forEach((certType, idx) => {
    certificateTypes.value[idx].employeeCertificates =
      certificates.value.filter((d) => d.certification_type_id === certType.id);
  });
}

onMounted(() => {
  Promise.all([
    commonFunctions.get_companies(),
    commonFunctions.get_certification_types(),
    companyService.store(constants.getEmployeeCertificates, {}),
  ]).then(
    ([companiesResponse, certificateTypesResponse, certificatesResponse]) => {
      companies.value = companiesResponse;
      certificateTypes.value = certificateTypesResponse;
      updateCertificates(certificatesResponse.data);
      isDoneLoading.value = true;

      if (formattedModalMessage.value.length > 0) {
        showModal.value = true;
      }
    }
  );
});
</script>

<style lang="less">
ul.ant-menu {
  li.ant-menu-item {
    & > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:not(.ant-menu-item-highlight) {
      .alert-warning-pulse {
        display: none;
      }
    }
  }

  li.ant-menu-item.ant-menu-item-highlight {
    background-color: #ff000044;
    color: red;

    &.ant-menu-item-selected {
      &::after {
        border-color: red;
      }
    }
  }
}

.alert-warning-pulse {
  display: inline-flex;
  margin: 0 0 0 10px;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (min-width: 992px) {
  .alert-warning-pulse {
    margin: 0 10px 0 0;
  }
}
</style>
