<template>
  <div>
    <a-form
      name="replace_device_form"
      ref="replace_device_form"
      :model="formState"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-space direction="vertical" style="width: 100%">
        <a-descriptions
          title=""
          bordered
          size="small"
          :labelStyle="{ width: '150px' }"
        >
          <a-descriptions-item label="Current Device">
            {{ record.product_name }} | {{ record.model_no }} |
            {{ record.serial_no }}
          </a-descriptions-item>
        </a-descriptions>

        <a-row :gutter="[20, 0]">
          <a-col :span="24">
            <a-form-item
              ref="allocated_date"
              label="Allocated Date"
              name="allocated_date"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'date',
              }"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="formState.allocated_date"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledFutureDate"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="asset_inventory_id"
              label="Asset Inventory"
              name="asset_inventory_id"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'number',
              }"
            >
              <a-select
                v-model:value="formState.asset_inventory_id"
                placeholder="Select asset"
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="option in filterdAssetInventory"
                  :key="option.id"
                  :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                  :value="option.id"
                >
                  {{ option.asset_product?.name }} | {{ option.model_no }} |
                  {{ option.serial_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="remark"
              label="Remarks"
              name="remark"
              :rules="{
                required: true,
                message: 'This field is required',
              }"
            >
              <a-textarea
                v-model:value="formState.remark"
                placeholder="Enter remark"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-space>
      <side-drawer-buttons name="Replace" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        allocated_date: null,
        asset_inventory_id: undefined,
        remark: null,
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    hospitalId() {
      return this.extra?.hospitalId ?? null;
    },

    assetInventory() {
      return this.extra?.assetInventory ?? [];
    },

    cart() {
      return this.extra?.cart ?? [];
    },

    filterdAssetInventory() {
      return this.assetInventory.filter(
        (item) => item.model_no == this.record.model_no
      );
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      let data = {
        id: this.record.id,
        old_asset_inventory_id: this.record.asset_inventory_id,
        type: this.record.type,
        name: this.record.type == 2 ? this.cart.name : null,
        unique_code: this.record.type == 2 ? this.cart.unique_code : null,
        cart_allocated_date:
          this.record.type == 2
            ? moment(this.cart.cart_allocated_date, "MM-DD-YYYY").format(
                "YYYY-MM-DD"
              )
            : null,
        hospital_id: this.hospitalId,
        allocated_date: this.$customDate.ymd(this.formState.allocated_date),
        asset_inventory_id: this.formState.asset_inventory_id,
        remark: this.formState.remark,
      };

      this.loadingStart();
      commonService
        .store(this.$constants.replaceAssetInventoryHospitalUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
