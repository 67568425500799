<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Biomed Service Record Details"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card :loading="cardLoader" size="small">
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '250px' }"
      >
        <a-descriptions-item label="Date">
          {{ $customDate.mdy(record.date) }}
        </a-descriptions-item>
        <a-descriptions-item label="Employee Name">
          {{ record.employee?.full_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Product Name">
          {{ record.asset_inventory_data?.asset_product?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Manufacturer Name">
          {{ record.asset_inventory_data?.asset_manufacturer?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Owned By">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'owned_by',
                $constants.assetInventoryOwnedBy,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "owned_by",
                $constants.assetInventoryOwnedBy
              )
            }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Owner Name">
          <template v-if="record.owned_by == 1">
            {{ record.asset_inventory_data?.company?.name ?? "N/A" }}
          </template>
          <template v-else>
            {{ record.asset_inventory_data?.hospital?.name ?? "N/A" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item
          v-if="record.asset_inventory_data?.asset_inventory_hospital"
          label="Allocated To"
          :span="2"
        >
          {{
            record.asset_inventory_data?.asset_inventory_hospital?.hospital
              ?.name ?? "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Record No">
          {{ record.asset_inventory_data?.record_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Serial No">
          {{ record.asset_inventory_data?.serial_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Asset ID Tag">
          {{ record.asset_inventory_data?.asset_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Secondary ID Tag">
          {{ record.asset_inventory_data?.secondary_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Description" :span="2">
          {{ record.asset_inventory_data?.description ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Comments" :span="2">
          <div
            class="mb-sm-0 ck-editor-content"
            v-html="record.comments ?? 'N/A'"
          />
        </a-descriptions-item>
        <a-descriptions-item label="Parts Installed" :span="2">
          <div
            class="mb-sm-0 ck-editor-content"
            v-html="record.parts_installed ?? 'N/A'"
          />
        </a-descriptions-item>
        <a-descriptions-item label="Re-enter operator settings">
          {{ record.re_enter_operator_settings }}
        </a-descriptions-item>
        <a-descriptions-item label="Inspect system visually">
          {{ record.inspect_system_visually }}
        </a-descriptions-item>
        <a-descriptions-item label="Paperwork completed">
          {{ record.paperwork_completed }}
        </a-descriptions-item>
        <a-descriptions-item label="Leakage Current">
          {{ record.leakage_current }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        v-if="record.leakage_current == 'Yes'"
        :column="3"
        size="small"
        bordered
        :labelStyle="{ width: '250px' }"
      >
        <a-descriptions-item label="Leakage Current-Open (μA)">
          {{ record.leakage_current_n }}
        </a-descriptions-item>
        <a-descriptions-item label="Leakage Current-Close (μA)">
          {{ record.leakage_current_r }}
        </a-descriptions-item>
        <a-descriptions-item label="Ground Resistance (OHM)">
          {{ record.ground_resistance }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '250px' }"
      >
        <a-descriptions-item label="Requires additional service">
          {{ record.requires_additional_service }}
        </a-descriptions-item>
        <a-descriptions-item label="Install inspection label">
          {{ record.install_inspection_label }}
        </a-descriptions-item>
        <a-descriptions-item label="Signature" :span="2">
          <img :width="200" :src="record.signature" />
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: { backButton },
  data() {
    return {
      cardLoader: false,
      id: this.$route.params.id ?? 0,
      record: {},
    };
  },

  mounted() {
    this.fetchBiomedServiceRecord();
  },

  methods: {
    fetchBiomedServiceRecord() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.biomedServiceRecordShowUrl, { id: this.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          this.record = res.data;
          console.log(this.record);
          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
