<template>
  <a-card
    :loading="loading"
    title="Hospital Info"
    size="small"
    class="hospital-info"
  >
    <a-descriptions
      size="small"
      :column="{ lg: 1, md: 1, sm: 1, xs: 1 }"
      bordered
    >
      <a-descriptions-item label="Hospital Name">
        {{ hospital?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Company">
        <template v-for="(v, i) in hospital.companies" :key="i">
          {{ v.name }}
        </template>
      </a-descriptions-item>

      <a-descriptions-item label="Email">
        <a-typography-link
          v-if="hospital.email"
          :href="`mailto:${hospital.email}`"
        >
          {{ hospital.email }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Phone">
        <a-typography-link
          v-if="hospital.contact_no"
          :href="`tel:${hospital.contact_no}`"
        >
          {{ hospital.contact_no ?? "N/A" }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Fax Number">
        <a-typography-link
          v-if="hospital.fax_no"
          :href="`tel:${hospital.fax_no}`"
        >
          {{ hospital.fax_no ?? "N/A" }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Website">
        <a-typography-link
          v-if="hospital.website"
          :href="hospital.website"
          target="_blank"
        >
          {{ hospital.website }}
        </a-typography-link>

        <a-typography-paragraph v-else> N/A </a-typography-paragraph>
      </a-descriptions-item>

      <a-descriptions-item label="Address">
        {{ hospital.address_line_1 ? `${hospital.address_line_1},` : "" }}
        {{ hospital.address_line_2 ? `${hospital.address_line_2},` : "" }}
        {{ hospital.city ? `${hospital.city},` : "" }}
        {{ hospital.state ? `${hospital.state},` : "" }}
        {{ hospital.zipcode ? `${hospital.zipcode}` : "" }}
      </a-descriptions-item>

    </a-descriptions>
  </a-card>
</template>

<script>
export default {
  name: "hospital-overview",
  props: {
    hospital: {
      required: true,
      default: null,
    },
  },

  computed: {
    loading() {
      return this.hospital == null;
    },
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>

<style scoped>
.hospital-info div.ant-typography,
.hospital-info .ant-typography p {
  margin-bottom: 0;
}
</style>
