<template>
  <a-layout id="components-layout-demo-side">
    <a-row class="main-layout-row" :gutter="[0, 0]">
      <a-col :sm="0" :lg="6" :xl="4" class="sidebar">
        <a-affix :offset-top="0">
          <sidebar-nav />
        </a-affix>
      </a-col>

      <a-col :sm="24" :lg="18" :xl="20">
        <a-layout-header>
          <div class="top-header">
            <a-row type="flex">
              <a-col class="nav-toggler">
                <menu-unfold-outlined
                  v-if="collapsed"
                  class="trigger"
                  @click="() => (collapsed = !collapsed)"
                />
                <menu-fold-outlined
                  v-else
                  class="trigger"
                  @click="() => (collapsed = !collapsed)"
                />
              </a-col>
              <!-- <a-col>
                <a-input-search
                  placeholder="Search... (Crtl+/)"
                  size="large"
                  @search="onSearch"
                />
              </a-col> -->

              <a-col class="user-login">
                <a-dropdown
                  class="navbar-dropdown"
                  :trigger="['click']"
                  :overlayStyle="{ top: '64px' }"
                >
                  <a class="ant-dropdown-link" @click.prevent>
                    <a-avatar size="small">{{ username.charAt(0) }}</a-avatar>
                    <span class="user">
                      {{ username }}
                    </span>
                  </a>

                  <template #overlay>
                    <a-menu class="navbar-dropdown-menu">
                      <a-menu-item v-if="employee" key="change-password">
                        <router-link
                          :to="{ name: 'hospital-user-change-password' }"
                        >
                          <i class="fas fa-user"></i>
                          <span class="ml-sm-1">Change Password</span>
                        </router-link>
                      </a-menu-item>

                      <a-menu-item>
                        <a href="javascript:;" @click="logout()">
                          <i class="fas fa-sign-out-alt"></i>
                          <span class="ml-sm-1">Logout</span>
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-col>
            </a-row>
          </div>
        </a-layout-header>
        <div class="main-content">
          <slot />
        </div>
        <a-layout-footer>
          Keystone Perfusion Services, P.C. ©{{ footerYear }} Created by
          Trileotech
        </a-layout-footer>

        <side-drawer />
        <a-drawer
          title=""
          placement="left"
          :closable="false"
          v-model:visible="collapsed"
          :drawerStyle="{ backgroundColor: '#001529' }"
          :bodyStyle="{ padding: 0 }"
        >
          <sidebar-nav />
        </a-drawer>
      </a-col>
    </a-row>
  </a-layout>
</template>

<script>
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import SidebarNav from "../components/hospitalUserSidebar.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    SidebarNav,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  data() {
    return {
      collapsed: false,
      openKeys: [],
    };
  },

  mounted() {
    //
  },

  beforeUnmount() {
    // Unregister the event listener before destroying this Vue instance
  },

  computed: {
    ...mapGetters("auth", ["employee", "isLoggedIn", "user"]),
    ...mapGetters("cart", ["cartQty"]),

    footerYear() {
      return moment().format("YYYY");
    },

    username() {
      if (this.isLoggedIn) {
        return this.user.name;
      } else {
        return "User Name";
      }
    },
    avatarUrl() {
      return (
        "https://avatars.dicebear.com/v2/initials/" + this.username + ".svg"
      );
    },
    onSearch() {},
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("drawer", ["open"]),

    onResize(event) {
      if (window.innerWidth >= "992") {
        this.collapsed = false;
      }
    },

    handleCartDrawerEvent() {
      this.open({
        title: "Cart Items",
        path: "order.cart",
        width: "800px",
      });
    },
  },
};
</script>

<style scoped>
.sidebar {
  background-color: #001529;
  display: none;
}
.user {
  display: none;
}
.nav-toggler {
  display: block;
}
.user-login {
  margin-left: auto;
}

.ant-layout,
.main-layout-row {
  min-height: 100vh;
}
div.ant-dropdown.ant-dropdown-placement-bottomLeft {
  top: 64px !important;
}
.main-content {
  margin-bottom: 100px;
}
.ant-layout-footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: cacl;
  left: 50%;
  width: 100%;
  margin-top: 20px;
  transform: translate(-50%, 0);
}
.ant-layout-header {
  background: #fff;
  padding: 0 16px;
}
@media only screen and (min-width: 992px) {
  .sidebar {
    display: block;
  }
  .user {
    display: inline;
  }
  .nav-toggler {
    display: none;
  }
}
</style>
