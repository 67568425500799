<template>
  <a-form
    name="live_charting_cardioplegia_form"
    ref="live_charting_cardioplegia_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-divider orientation="left" style="margin-top: 0 !important">
      Cardioplegia
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item ref="route" label="Route" name="route">
          <a-checkbox-group
            v-model:value="model.route"
            :options="['Antegrade', 'Retrograde', 'Ostial', 'Graft']"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item ref="radio" label="Ratio" name="radio">
          <a-radio-group v-model:value="model.radio">
            <a-radio value="4:1">4:1</a-radio>
            <a-radio value="1:4">1:4</a-radio>
            <a-radio value="Blood">Blood</a-radio>
            <a-radio value="Other">Other</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <template v-if="model.radio == 'Other'">
            <a-col :span="12">
              <a-form-item
                ref="other_radio"
                label="Other Ratio"
                name="other_radio"
              >
                <a-input v-model:value="model.other_radio" />
              </a-form-item>
            </a-col>
          </template>

          <a-col :span="model.radio == 'Other' ? 12 : 24">
            <a-form-item
              ref="flow"
              label="Flow (mL)"
              name="flow"
              :rules="{
                type: 'integer',
                min: 0,
                max: 999,
                message: 'This field must be valid input.',
              }"
            >
              <a-input-number type="number" v-model:value="model.flow" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pressure"
          label="Circuit Pressure (mmHg)"
          name="pressure"
          :rules="{
            type: 'integer',
            min: 0,
            max: 400,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.pressure" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="volume_delivered"
          label="Volume Delivered (mL)"
          name="volume_delivered"
          :rules="{
            type: 'integer',
            min: 0,
            max: 99999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.volume_delivered"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="temperature"
          label="Temperature (°C)"
          name="temperature"
          :rules="{
            type: 'integer',
            min: 0,
            max: 100,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.temperature" />
        </a-form-item>
      </a-col>

      <a-col v-if="model.route.includes('Retrograde')" :span="12">
        <a-form-item
          ref="coronary_sinus_pressure"
          label="Coronary Sinus Pressure (mmHg)"
          name="coronary_sinus_pressure"
          :rules="{
            type: 'integer',
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.coronary_sinus_pressure"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <status-form
      :model="model"
      @update="(value, key) => (model[key] = value)"
    />

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";
import statusForm from "../../liveCharting/components/status.vue";

export default {
  components: { statusForm },

  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Cardioplegia",
        art_pressure: null,
        cvp: null,
        pa: null,
        blood_flow: null,
        line_pressure: null,
        art_temp: null,
        venous_temp: null,
        core_temp: null,
        fio2: null,
        sweep: null,
        anesthetic_gas: null,
        act: null,
        svo2: null,
        rso2_left: null,
        rso2_right: null,

        // Cardioplegia
        route: [],
        radio: null,
        other_radio: null,
        flow: null,
        pressure: null,
        volume_delivered: null,
        temperature: null,
        coronary_sinus_pressure: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra.lastRecord) {
      this.model.blood_flow = this.extra.lastRecord.blood_flow;
      this.model.fio2 = this.extra.lastRecord.fio2;
      this.model.sweep = this.extra.lastRecord.sweep;
      this.model.anesthetic_gas = this.extra.lastRecord.anesthetic_gas;
      this.model.rso2_left = this.extra.lastRecord.rso2_left;
      this.model.rso2_right = this.extra.lastRecord.rso2_right;
    }
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.art_pressure = this.extra.record.art_pressure;
      this.model.cvp = this.extra.record.cvp;
      this.model.pa = this.extra.record.pa;
      this.model.blood_flow = this.extra.record.blood_flow;
      this.model.line_pressure = this.extra.record.line_pressure;
      this.model.art_temp = this.extra.record.art_temp;
      this.model.venous_temp = this.extra.record.venous_temp;
      this.model.core_temp = this.extra.record.core_temp;
      this.model.fio2 = this.extra.record.fio2;
      this.model.sweep = this.extra.record.sweep;
      this.model.anesthetic_gas = this.extra.record.anesthetic_gas;
      this.model.act = this.extra.record.act;
      this.model.svo2 = this.extra.record.svo2;

      // Cardioplegia
      this.model.route = this.extra.record.route;
      this.model.radio = this.extra.record.radio;
      this.model.other_radio = this.extra.record.other_radio;
      this.model.flow = this.extra.record.flow;
      this.model.pressure = this.extra.record.pressure;
      this.model.volume_delivered = this.extra.record.volume_delivered;
      this.model.temperature = this.extra.record.temperature;
      this.model.coronary_sinus_pressure =
        this.extra.record.coronary_sinus_pressure;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreCPBRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
