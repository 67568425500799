<template>
  <a-layout-content>
    <page-header>
      <template #title> Downloads </template>
    </page-header>
    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record) => record.id"
        :dataSource="data"
        :pagination="false"
        size="small"
        :scroll="{ x: true, y: 0 }"
      >
        <template #action="{ record }">
        <a-tooltip title="Download PDF">
          <a-button type="link" @click="downloadPDF(record)">
            <i class="ti ti-download ti-lg"></i>
          </a-button>
        </a-tooltip>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>
  
<script setup>
import { commonService } from "@/services";
import { constants } from "../../../helper/constants";
import { message } from "ant-design-vue";

const data = [
  {
    id: 1,
    name: "Open Heart QI Form",
    lastUpdatedAt: "11-13-2024",
    api: constants.openHeartQiPdf,
  },
];

const columns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Last updated at",
    dataIndex: "lastUpdatedAt",
    key: "lastUpdatedAt",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
  },
];

const downloadPDF = (record) => {
  const fileName = `${record.name}.pdf`;
  const apiUrl = record.api;

  commonService
    .downloadFile(apiUrl, {}, fileName)
    .then((res) => {
      message.success(res);
    })
    .catch((err) => {
      message.error(err);
    });
};
</script>
  