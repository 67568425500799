<template>
  <a-layout-content>
    <page-header>
      <template #title> Time Tracking </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-form
      ref="timeTrackingForm"
      :model="model"
      layout="vertical"
      :rules="rules"
    >
      <a-card size="small" hoverable :loading="cardLoading">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item ref="date_range" label="Date Range" name="date_range">
              <a-range-picker
                :allowClear="false"
                v-model:value="model.date_range"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                inputReadOnly
                :ranges="ranges"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item ref="hospital_id" label="Hospital" name="hospital_id">
              <a-select
                v-model:value="model.hospital_id"
                placeholder="Facility"
                show-search
                optionFilterProp="label"
                allowClear
              >
                <a-select-option
                  v-for="option in hospitals"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> 
        </a-row>

        <a-space>
          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="fetchData"
          >
            Fetch Records
          </a-button>

          <a-button
            type="primary"
            html-type="button"
            :loading="submitBtnLoader"
            @click="exportExcel"
          >
            Export Excel
          </a-button>
        </a-space>
      </a-card>
    </a-form>

    <a-card v-if="data" size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="data"
        :pagination="pagination"
        size="small"
        :scroll="{ x: true, y: 0 }"
      >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { commonService } from "../../../services";
import xFilterIcon from "@/components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "@/components/table/filterInputSearchDropdown.vue";


export default {
  components: { xFilterInputSearchDropdown, xFilterIcon },
  data() {
    return {
      cardLoading: true,
      shiftTypes: [],
      submitBtnLoader: false,
      pagination: {
        size: "normal",
        pageSize: this.pageSize,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },
      model: {
        date_range: [null, null],
        hospital_id: undefined,
      },
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "array",
            len: 2,
            fields: {
              0: {
                type: "date",
                required: true,
                message: "This field is required!",
              },
              1: { type: "date", required: true, message: "" },
            },
          },
        ],
      },
      hospitals: [],
      data: null,

      columns: [
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) => record.employee?.full_name.toString().toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) => record.hospital?.name.toString().toLowerCase().includes(value.toLowerCase()),
        }, 
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          onFilter: (value, record) =>
            record.shift_type?.name
              .toString()
              .includes(value),
          customRender: ({ record }) => record.shift_type?.name,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix(),
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
          width: 100,
        },
        {
          title: "Start time",
          dataIndex: "start_time",
          key: "start_time",
          width: 100,
        },
        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
          customRender: ({ record }) => this.$customDate.mdy(record.end_date),
          width: 100,
        },
        {
          title: "End time",
          dataIndex: "end_time",
          key: "end_time",
          width: 100,
        }, 
        {
          title: "Total Time",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time +
                record.total_on_call_time +
                record.total_travel_time ?? 0
            ).toFixed(2),
        },
        // {
        //   title: "Approved By",
        //   dataIndex: "approver_name",
        //   key: "approver_name",
        //   customRender: ({ record }) => record.approver?.full_name,
        // },
        // {
        //   title: "Approved At",
        //   dataIndex: "approved_at",
        //   key: "approved_at",
        //   customRender: ({ text }) => this.$customDate.mdy(text),
        //   width: 100,
        // },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "notes",
          customRender: ({ text }) => text ?? "-",
        },
        {
          title: "Was On Call",
          dataIndex: "was_on_call",
          key: "was_on_call",
          filters: [
            { text: 'Yes', value: '1' },
            { text: 'No', value: '0' },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.was_on_call == value,
          customRender: ({ text }) => (text == '1' ? "Yes" : "No"),
        },
        {
          title: "On Call End Date",
          dataIndex: "on_call_end_date",
          key: "on_call_end_date",
          sorter: (a, b) => moment(a.on_call_end_date).unix() - moment(b.on_call_end_date).unix(),
          customRender: ({ record }) =>
            record.was_on_call == 1
              ? this.$customDate.mdy(record.on_call_end_date)
              : "-",
          width: 100,
        },
        {
          title: "On Call End Time",
          dataIndex: "on_call_end_time",
          key: "on_call_end_time",
          customRender: ({ record }) =>
            record.was_on_call == 1 ? record.on_call_end_time : "-",
          width: 100,
        },
      ],
    };
  },

  computed: {
    //
  },

  mounted() {
    this.getHospitals();
    this.fetchShiftTypes()
  },

  methods: {
    getHospitals() {
      commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    fetchShiftTypes() {
      commonService.get(this.$constants.getShiftTypesUrl).then((res) => {
        this.shiftTypes = res.data;
        this.columns[2].filters = this.shiftTypes.map((item) => ({
          text: item.name,
          value: item.name,
        }));
      });
    },

    fetchData() {
      this.$refs.timeTrackingForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .get(this.$constants.reportTimeTracking, {
            dates: [
              this.$customDate.ymd(this.model.date_range[0]),
              this.$customDate.ymd(this.model.date_range[1]),
            ],
            hospital_id: this.model.hospital_id,
          })
          .then((res) => {
            this.data = res.data;
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    exportExcel() {
      this.$refs.timeTrackingForm.validate().then(() => {
        let dateString =
          this.model.date_range[0].clone().format("MMDDYY") +
          "-" +
          this.model.date_range[1].clone().format("MMDDYY");

        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportTimeTracking,
            {
              dates: [
                this.$customDate.ymd(this.model.date_range[0]),
                this.$customDate.ymd(this.model.date_range[1]),
              ],
              hospital_id: this.model.hospital_id,
              excel_export: true,
            },
            "hospital-time-tracking-" + dateString + ".xlsx"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },
  },
};
</script>
