<template>
  <div>
    <a-form
      name="asset_category_form"
      ref="asset_category_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
              @change="resetErrors('name')"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  mixins: [formMixins],

  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        name: "",
      },
      options: [],
      rules: {
        name: [
          {
            required: true,
            message: "this field is required!",
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
      };
    },

    onSubmit() {
      if (this.record.id) {
        this.formState.id = this.record.id;
      }

      this.loadingStart();
      companyService
        .store(this.$constants.assetCategoryStoreUrl, this.formState)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
