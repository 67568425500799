<template>
  <a-layout-content>
    <page-header>
      <template #title> Paid Time Off</template>
      <template #buttons>
        <a-button
          v-if="viewMode == 'table'"
          type="primary"
          @click="viewMode = 'calendar'"
        >
          <a-space>
            <i class="ti ti-calendar"></i>
            Calendar View
          </a-space>
        </a-button>
        <a-button
          v-if="viewMode == 'calendar'"
          type="primary"
          @click="viewMode = 'table'"
        >
          <a-space>
            <i class="ti ti-table"></i>
            Table View
          </a-space>
        </a-button>
      </template>
    </page-header>

    <template v-if="viewMode == 'table'">
      <table-view />
    </template>

    <template v-else-if="viewMode == 'calendar'">
      <calendar-view />
    </template>
  </a-layout-content>
</template>

<script>
import calendarView from "./components/calendarView.vue";
import tableView from "./components/tableView.vue";

export default {
  components: {
    tableView,
    calendarView,
  },

  data() {
    return {
      viewMode: "table",
    };
  },
};
</script>
