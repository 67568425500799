<template>
  <a-form
    name="nrp_plus_volume_form"
    ref="nrp_plus_volume_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="pre_bypass_volume"
          label="Pre-Bypass Volume (mL)"
          name="pre_bypass_volume"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.pre_bypass_volume"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="prime_volume"
          label="Prime Volume (mL)"
          name="prime_volume"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.prime_volume" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="crystalloid"
          label="Crystalloid (mL)"
          name="crystalloid"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.crystalloid" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="colloid"
          label="Colloid (mL)"
          name="colloid"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.colloid" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="normal_saline_solution"
          label="0.9% Normal Saline Solution (mL)"
          name="normal_saline_solution"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.normal_saline_solution"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="normosol_r_ph"
          label="Normosol-R, pH 7.4 (mL)"
          name="normosol_r_ph"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.normosol_r_ph" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="isolyte_s_ph"
          label="Isolyte S, pH 7.4 (mL)"
          name="isolyte_s_ph"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.isolyte_s_ph" />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "+ Volume",
        pre_bypass_volume: null,
        prime_volume: null,
        crystalloid: null,
        colloid: null,
        normal_saline_solution: null,
        normosol_r_ph: null,
        isolyte_s_ph: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.pre_bypass_volume = this.extra.record.pre_bypass_volume;
      this.model.prime_volume = this.extra.record.prime_volume;
      this.model.crystalloid = this.extra.record.crystalloid;
      this.model.colloid = this.extra.record.colloid;
      this.model.normal_saline_solution =
        this.extra.record?.normal_saline_solution ?? null;
      this.model.normosol_r_ph = this.extra.record?.normosol_r_ph ?? null;
      this.model.isolyte_s_ph = this.extra.record?.isolyte_s_ph ?? null;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },
  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreNRPRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
