<template>
  <div>
    <a-descriptions :column="2" size="small" bordered>
      <a-descriptions-item :span="2" label="Hospital Name">
        {{ record.hospital?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Employee Name">
        {{ record.employee?.full_name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Shift Name">
        {{ record.shift_type?.name ?? "N/A" }}
      </a-descriptions-item>

      <a-descriptions-item label="Start Date & Time">
        {{ $customDate.mdy(record.start_date) }}
        {{ $customDate.hi(record.start_time) }}
      </a-descriptions-item>

      <a-descriptions-item label="End Date & Time">
        {{ $customDate.mdy(record.end_date) }}
        {{ $customDate.hi(record.end_time) }}
      </a-descriptions-item>

      <a-descriptions-item :span="2" label="Total Time (In Hours)">
        {{ parseFloat(record.total_time).toFixed(2) }}
      </a-descriptions-item>

      <template
        v-if="$constants.timeTrackingOnCall.includes(record.shift_type_id)"
      >
        <a-descriptions-item
          :span="2"
          label="Was on-call coverage also provided on the same day?"
        >
          {{ record.was_on_call == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <template v-if="record.was_on_call">
          <a-descriptions-item label="Total On-Call Time (In Hours)">
            {{ parseFloat(record.total_on_call_time).toFixed(2) }}
          </a-descriptions-item>

          <a-descriptions-item label="On-Call End Date & Time">
            {{ $customDate.mdy(record.on_call_end_date) }}
            {{ $customDate.hi(record.on_call_end_time) }}
          </a-descriptions-item>
        </template>
      </template>

      <template v-if="record.was_travel">
        <a-descriptions-item
          :span="2"
          label="Is travel compensation available for hospital?"
        >
          {{ record.was_travel == 1 ? "Yes" : "No" }}
        </a-descriptions-item>

        <a-descriptions-item label="Home to Hospital">
          {{ $customDate.hi(record.home_departure) }} -
          {{ $customDate.hi(record.hospital_arrival) }}
        </a-descriptions-item>

        <a-descriptions-item label="Hospital to Home">
          {{ $customDate.hi(record.hospital_departure) }} -
          {{ $customDate.hi(record.home_arrival) }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="Total Travel Time (In Hours)">
          {{ parseFloat(record.total_travel_time).toFixed(2) }}
        </a-descriptions-item>
      </template>

      <a-descriptions-item :span="2" label="Notes">
        {{ record.notes ?? "N/A" }}
      </a-descriptions-item>
    </a-descriptions>

    <template v-if="record.status != 1">
      <a-divider orientation="left">Status Info</a-divider>

      <a-descriptions :column="2" size="small" bordered>
        <a-descriptions-item
          :label="record.status == 3 ? 'Rejected By' : 'Approved By'"
        >
          {{ record.approver?.full_name ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item
          :label="record.status == 3 ? 'Rejected At' : 'Approved At'"
        >
          {{ $customDate.ll(record.approved_at) }}
        </a-descriptions-item>

        <a-descriptions-item label="Remarks" :span="2">
          {{ record.remarks ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
