<template>
  <a-row :gutter="[20, 0]">
    <template v-if="model.job_type == 1">
      <a-col :span="24">
        <a-form-item
          :name="['section4', 'a']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4a (Weight 70%)
              <small class="text-light">
                The safe performance and management of Autotransfusion / Blood
                conservation. <br />
                Demonstrates process to set-up, start, and shut- down equipment,
                including appropriate use of checklists <br />
                Operates equipment safely, appropriately, and according to
                patient’s needs <br />
                Demonstrates the addition of anticoagulant type and amount
                required for the anticoagulant drip bag. <br />
                Cleans/disinfects and stores equipment as per manufacturer's
                recommendations and facility policy/procedures<br />
                "Accurately documents: Set-up, Preventative and routine
                maintenance, Cleaning/disinfection, Equipment malfunctions /
                failure" <br />
                Responds to alarms in a timely manner <br />
                Troubleshoots cell saver and related equipment alarms in an
                organized and timely manner <br />
                Initiates appropriate and timely interventions based upon alarm
                data and equipment assessment. <br />
                Demonstrates procedures for routine replacement of cell saver,
                PRP centrifuge and related equipment as per facility
                policy/procedure<br />
                Demonstrates procedures for emergency replacement of cell saver
                and related equipment as per facility policy/procedure <br />
                Identifies process to document equipment failure <br />
                Identifies process to have equipment repaired <br />
                Able to discuss indications / contraindications / complications
                with physician. <br />
                Anticipates periods of high blood loss and immediately responds
                to process the collected blood. <br />
                Labels transfer bag(s) and syringes with patient’s name, MR
                number, estimated volume, time of collection and expiration
                date/time.<br />
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.a"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.a - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'b']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4b (Weight 5%)
              <small class="text-light">
                Attends, participates, and engages in blood management-related
                continuing education courses.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.b"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.b - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'c']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4c (Weight 5%)
              <small class="text-light">
                Collect a QC specimen on certain procedures that blood is
                returned to the patient.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.c"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.c - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'd']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4d (Weight 20%)
              <small class="text-light">
                1. Assists Director Perfusion Technology with departmental
                duties. <br />
                2. Supports development and implementation of policies and
                procedures.<br />
                3. Ordering of cell saver supplies.<br />
                4. Inventory of cell saver supplies.<br />
                5. Stocks supplies.<br />
                6. Cleans cell saver machine and other equipment.<br />
                7. Initiates and sustains communications with surgeons.<br />
                8. Record keeping in accordance with CQI protocol.<br />
                9. Maintains CQI records / QCs on pertinent instruments.<br />
                10. Participate in routine staff meetings and in-services.<br />
                11. Professional improvement by taking part in education and
                research.<br />
                12. Provides standby for potential surgical cases.<br />
                13. Helps with preventative maintenance and safety checks on all
                equipment.<br />
                14. Sustains regular communications with KPS administration.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.d"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.d - 1] }}</span>
        </a-form-item>
      </a-col>
    </template>

    <template v-if="model.job_type == 2">
      <a-col :span="24">
        <a-form-item
          :name="['section4', 'a']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4a. CPB (Weight 25%)
              <small class="text-light">
                The safe performance and management of Cardiopulmonary Bypass /
                Extracorporeal Circulation.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.a"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.a - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'b']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4b. ECMO (Weight 20%)
              <small class="text-light">
                The safe performance and management of Extracorporeal Membrane
                Oxygenation (ECMO).
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.b"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.b - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'c']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4c. Blood Management (Weight 15%)
              <small class="text-light">
                The safe performance and management of Autotransfusion / Blood
                Conservation.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.c"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.c - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'd']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4d. Point of Care Testing (Weight 15%)
              <small class="text-light">
                Physiological monitoring and Blood gas / Chemistry analysis.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.d"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.d - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'e']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4e. Continuing Education (Weight 5%)
              <small class="text-light">
                Attends, participates, and engages in perfusion-related
                continuing education courses.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.e"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.e - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'f']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4f. Ancillary Perfusion (Weight 10%)
              <small class="text-light">
                The safe performance and management of Counterpulsation /
                Intra-aortic Balloon Pump.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.f"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.f - 1] }}</span>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          :name="['section4', 'g']"
          :rules="{
            required: true,
            message: 'This field is required!',
            type: 'number',
            min: 1,
          }"
        >
          <template #label>
            <a-space direction="vertical" :size="0">
              4g. Contract Management (Weight 10%)
              <small class="text-light">
                1. Assists Director Perfusion Technology with departmental
                duties.<br />
                2. Supports development and implementation of policies and
                procedures.<br />
                3. Ordering of perfusion supplies.<br />
                4. Inventory of perfusion supplies.<br />
                5. Stocks supplies.<br />
                6. Cleans heart-lung bypass machine and other equipment.<br />
                7. Initiates and sustains communications with surgeons.<br />
                8. Record keeping in accordance with CQI protocol.<br />
                9. Maintains CQI records / QCs on pertinent instruments.<br />
                10. Participate in routine staff meetings and in-services.<br />
                11. Professional improvement by taking part in education and
                research.<br />
                12. Provides standby for potential surgical cases.<br />
                13. Helps with preventative maintenance and safety checks on all
                equipment.<br />
                14. Sustains regular communications with KPS administration.
              </small>
            </a-space>
          </template>

          <a-rate
            v-model:value="model.section4.g"
            :tooltips="desc"
            @change="$emit('update:model', model)"
          />
          <span class="ant-rate-text">{{ desc[model.section4.g - 1] }}</span>
        </a-form-item>
      </a-col>
    </template>

    <a-col :span="24">
      <a-form-item
        label="Evaluator Comments"
        :name="['section4', 'comments']"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <a-textarea
          v-model:value="model.section4.comments"
          placeholder="Select evaluator comments"
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        :name="['section4', 'avg_score']"
        label="Job Specific Competencies Score"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-input-number type="number"
          v-model:value="model.section4.avg_score"
          disabled
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        :name="['section4', 'overall_weightage']"
        label="Overall Weightage Score"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-input-number type="number"
          v-model:value="model.section4.overall_weightage"
          disabled
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
    desc: {
      require: true,
    },
  },

  data() {
    return {
      //
    };
  },

  methods: {
    validate(...arg) {
      this.$emit("validate", ...arg);
    },
  },
};
</script>
