<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-form
      ref="serviceForm"
      :model="model"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-spin :spinning="loading">
        <a-card>
          <a-row :gutter="[16, 0]">
            <a-col :span="24">
              <a-form-item
                ref="name"
                label="Name"
                name="name"
                :rules="{
                  required: true,
                  message: 'field is required!',
                  trigger: 'change',
                }"
              >
                <a-input
                  v-model:value="model.name"
                  placeholder="Enter name here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="description"
                label="Description"
                name="description"
              >
                <a-textarea
                  v-model:value="model.description"
                  placeholder="Enter description here"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-divider orientation="left">Products for Service</a-divider>

          <a-row :gutter="[16, 0]">
            <a-col :span="8"> Name </a-col>

            <a-col :span="4"> UOM </a-col>

            <a-col :span="4"> Price </a-col>

            <a-col :span="4"> quantity </a-col>

            <a-col :span="4"></a-col>

            <template v-for="(v, i) in model.products" :key="i">
              <a-col :span="8">
                <a-form-item
                  ref="id"
                  :name="['products', i, 'id']"
                  :rules="{
                    required: true,
                    message: 'field is required!',
                    trigger: 'change',
                    type: 'number',
                  }"
                  :wrapperCol="{ style: { width: '100%' } }"
                >
                  <a-select
                    v-model:value="v.id"
                    placeholder="select product"
                    optionFilterProp="label"
                    :showSearch="true"
                    width="100%"
                    @change="handleProductSelect(i)"
                  >
                    <a-select-option
                      v-for="product in unSelectedProducts(v)"
                      :key="product.id"
                      :label="product.name"
                      :value="product.id"
                    >
                      {{ product.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-input
                  v-model:value="v.uom"
                  placeholder="product uom"
                  :disabled="true"
                />
              </a-col>

              <a-col :span="4">
                <a-input-number
                  v-model:value="v.price"
                  placeholder="product original price"
                  :formatter="$comman.currencyformatter"
                  :parser="$comman.currencyParser"
                  :disabled="true"
                />
              </a-col>

              <a-col :span="4">
                <a-form-item
                  ref="quantity"
                  :name="['products', i, 'quantity']"
                  :rules="[
                    {
                      required: true,
                      message: 'field is required!',
                      type: 'number',
                    },
                  ]"
                >
                  <a-input-number type="number"
                    v-model:value="v.quantity"
                    placeholder="product quantity"
                    :min="1"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-button
                  v-if="model.products.length > 1"
                  type="link"
                  class="danger-color"
                  @click="handleFieldRemove(i)"
                >
                  <i class="far fa-times-circle fa-2x"></i>
                </a-button>
              </a-col>
            </template>
          </a-row>

          <a-button class="mt-sm-1" type="dashed" block @click="addField()">
            <i class="fas fa-plus mr-sm-1"></i>
            Add field
          </a-button>

          <a-form-item>
            <a-button type="primary" html-type="submit" class="mt-sm-1">
              Submit
            </a-button>
          </a-form-item>
        </a-card>
      </a-spin>
    </a-form>
  </a-layout-content>
</template>

<script>
import BackButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: { BackButton },

  data() {
    return {
      loading: false,
      model: {
        id: this.$route.params.id ?? 0,
        name: null,
        description: null,
        products: [],
      },
      products: [],
      productField: {
        id: null,
        uom: null,
        price: null,
        quantity: null,
      },
      productIds: [],
    };
  },

  computed: {
    title() {
      if (this.model.id != 0) {
        return "Update Order Service";
      }
      return "Add New Order Service";
    },
  },

  mounted() {
    if (this.model.id != 0) {
      this.fetchService();
    }
    this.fetchProducts();
  },

  methods: {
    fetchProducts() {
      commonService.get(this.$constants.getProductsUrl).then((res) => {
        this.products = res.data;
        if (this.model.products.length > 0) {
          var products = this.model.products.map((v) => {
            var product = this.products.find((value) => {
              return value.id == v.id;
            });
            return {
              id: product.id,
              uom: product.uom?.name ?? null,
              price: product.price,
              quantity: v.quantity,
            };
          });
          this.model.products = products;
          this.updateProductList();
        }
      });
    },

    fetchService() {
      commonService
        .get(this.$constants.serviceShowUrl, { id: this.model.id })
        .then((res) => {
          this.model.name = res.data.name;
          this.model.description = res.data.description;

          if (res.data.products) {
            var products = res.data.products.map((v) => {
              var product = this.products.find((value) => {
                return value.id == v.product_id;
              });
              return {
                id: v.product_id,
                uom: product && product.uom ? product.uom.name : null,
                price: product ? product.price : null,
                quantity: v.quantity,
              };
            });
            this.model.products = products;
            this.updateProductList();
          }
        });
    },

    addField() {
      this.model.products.push(Object.assign({}, this.productField));
    },

    handleProductSelect(i) {
      var productRow = this.model.products[i];
      var product = this.products.find((v) => v.id == productRow.id);
      if (product) {
        productRow.uom = product.uom.name;
        productRow.price = product.price;
      }
      this.updateProductList();
    },

    updateProductList() {
      let productIds = [];
      this.model.products.forEach((v) => {
        if (v.id != null) {
          productIds.push(v.id);
        }
      });
      this.products.map((v) => {
        if (productIds.includes(v.id)) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });
    },

    handleFieldRemove(i) {
      this.model.products.splice(i, 1);
    },

    handleFinish(val) {
      if (this.model.products.length == 0) {
        this.$message.error("Please select atleast one product!");
        return;
      }

      this.loading = true;
      commonService
        .store(this.$constants.serviceStoreUrl, this.model)
        .then((res) => {
          this.loading = false;
          this.$message.success(res.message);
          this.$router.push({ name: "service" });
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    unSelectedProducts(value) {
      return this.products.filter((v) => {
        if (v.id == value.id || v.selected != true) {
          return v;
        }
      });
    },
  },
};
</script>

<style></style>
