<template>
  <a-col :span="8">
    <a-typography-text strong>{{ title }}</a-typography-text>
    <br />
    <a-typography-text type="secondary">{{ description }}</a-typography-text>
  </a-col>
  <a-col :span="12">
    <template v-if="formData.edit">
      <a-form
        layout="inline"
        :model="formData"
        :rules="rules"
        @finish="formDataSubmit"
        @reset="formDataReset"
      >
        <a-form-item ref="emails" name="emails">
          <a-select
            v-model:value="formData.emails"
            mode="tags"
            style="width: 400px"
            placeholder="Enter emails here..."
            @change="handleChange"
          >
          </a-select>
        </a-form-item>
        <a-button type="link" htmlType="submit" :loading="formData.loading">
          <i v-if="!formData.loading" class="ti ti-check ti-lg"></i>
        </a-button>
        <a-button v-if="!formData.loading" type="link" danger htmlType="reset">
          <i class="ti ti-x ti-lg"></i>
        </a-button>
      </a-form>
    </template>

    <template v-else>
      <template v-if="formData.emails.length > 0">
        <template
          v-for="email in formData.emails"
          :key="`case-report-${email}`"
        >
          <a-tag>{{ email }}</a-tag>
        </template>
      </template>
      <template v-else> N/A </template>
      <a-button type="link" size="small" @click="formData.edit = true">
        <i class="ti ti-pencil ti-lg"></i>
      </a-button>
    </template>
  </a-col>
  <a-col :span="4" align="right">
    <a-switch
      v-model:checked="formData.is_enable"
      :loading="formData.sloading"
      @change="
        (bool) => {
          formData.sloading = true;
          onSubmit(
            { is_enable: bool, type: type },
            () => (formData.sloading = false)
          );
        }
      "
    />
  </a-col>
</template>

<script>
import { commonService } from "../../../services";

export default {
  props: {
    notificationSettings: { required: true },
    title: { required: true },
    description: { required: true },
    type: { required: true },
    employeeId: { required: true },
  },

  emits: ["updateSettings"],

  data() {
    return {
      formData: {
        emails: [],
        edit: false,
        loading: false,
        is_enable: false,
        sloading: false,
      },
      rules: {
        emails: [
          {
            message: "This field is required!",
            type: "array",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.formDataReset();
  },

  methods: {
    formDataSubmit() {
      this.formData.loading = true;
      this.onSubmit(
        { emails: this.formData.emails, type: this.type },
        this.formDataReset
      );
    },

    formDataReset() {
      this.formData.edit = false;
      this.formData.loading = false;
      let formDataSetting = this.notificationSettings.find(
        (item) => item.type == this.type
      );
      if (formDataSetting) {
        this.formData.emails = JSON.parse(
          JSON.stringify(formDataSetting.emails)
        );
        this.formData.is_enable = formDataSetting.is_enable ? true : false;
      }
    },

    onSubmit(payload, callback) {
      commonService
        .store(this.$constants.employeeStoreNotificationUrl, {
          ...payload,
          employee_id: this.employeeId,
        })
        .then((res) => {
          this.$emit("updateSettings", res.data);
          setTimeout(() => {
            callback();
          }, 1000);
        })
        .catch((err) => console.log(err));
    },

    handleChange(val) {
      if (val.length > 0) {
        this.rules.emails[0].fields = [];
        for (let index = 0; index < val.length; index++) {
          if (this.rules.emails[0].fields.length == 0) {
            this.rules.emails[0].fields.push({
              type: "email",
              message: `Invalid email!`,
            });
          }
        }
      } else {
        this.rules.emails[0].fields = [];
      }
    },
  },
};
</script>
