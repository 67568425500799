<template>
  <a-layout-content>
    <page-header>
      <template #title> Action Items</template>
    </page-header>

    <template v-if="loading">
      <a-row :gutter="[10, 10]">
        <a-col :span="24">
          <a-card loading />
        </a-col>
        <a-col :span="24">
          <a-card loading />
        </a-col>
        <a-col :span="24">
          <a-card loading />
        </a-col>
      </a-row>
    </template>

    <template v-else-if="isAllEmpty">
      <a-card>
        <a-empty description="No data" />
      </a-card>
    </template>

    <a-row v-else :gutter="[10, 10]">
      <!--    LIST DATA    -->
      <template v-for="item in listData" :key="item.title">
        <a-col v-if="item.data.length" :span="24">
          <a-card>
            <a-row :gutter="[10, 10]" align="top" justify="space-between">
              <a-col :span="20">
                <a-card-meta
                  :description="item.description"
                  :title="item.title"
                  class="mb-sm-1"
                />
              </a-col>
              <a-col :span="4">
                <a-input v-model:value="item.query" placeholder="Search" />
              </a-col>
            </a-row>
            <a-list
              :dataSource="filterDataFromQuery(item)"
              :grid="{
                lg: item.gapXl ? 2 : 6,
                xl: item.gapXl ? 2 : 6,
                xxl: item.gapXl ? 3 : 8,
              }"
              :loading="loading"
              :pagination="
                item.data.length > 48
                  ? {
                      pageSize: 48,
                      position: 'bottom',
                    }
                  : false
              "
              bordered
            >
              <template #renderItem="{ item: payload }">
                <div style="display: flex; align-items: center; padding: 8px">
                  <router-link
                    v-if="item.route"
                    :to="{
                      name: item.route,
                      params: { id: payload.id },
                    }"
                    style="height: 100%; display: flex; align-items: center"
                    target="_blank"
                  >
                    <a-typography-link>
                      {{ payload.name }}
                    </a-typography-link>
                  </router-link>
                  <a-typography-text v-else>
                    {{ payload.name }}
                  </a-typography-text>
                </div>
              </template>
            </a-list>
          </a-card>
        </a-col>
      </template>

      <!--    TABLE DATA    -->
      <template v-for="item in caseReportTableData" :key="item.title">
        <a-col v-if="item.data.length" :span="24">
          <a-card>
            <a-row :gutter="[10, 10]" align="top" justify="space-between">
              <a-col :span="20">
                <a-card-meta
                  :description="item.description"
                  :title="item.title"
                  class="mb-sm-1"
                />
              </a-col>
              <a-col :span="4">
                <a-input v-model:value="item.query" placeholder="Search" />
              </a-col>
            </a-row>

            <a-table
              :columns="columns"
              :dataSource="filterDataForTableFromQuery(item)"
              :pagination="item.data.length > 10 ? { pageSize: 10 } : false"
              :rowKey="(record) => record.uuid"
              size="small"
            >
              <template #report_no="{ record, text }">
                <router-link
                  :to="{
                    name: 'admin.show-case-report',
                    params: { uuid: record.uuid },
                  }"
                  target="_blank"
                >
                  {{ text }}
                </router-link>
              </template>

              <template #action="{ record }">
                <a-tooltip title="Show Products">
                  <a-button
                    @click="showDisposableProducts(record)"
                    class="pl-sm-0"
                    size="small"
                    type="link"
                  >
                    <i class="ti ti-eye ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </template>
            </a-table>
          </a-card>
        </a-col>
      </template>

      <a-col :span="24">
        <a-card>
          <a-row :gutter="[10, 10]" align="top" justify="space-between">
            <a-col :span="18">
              <a-card-meta
                :description="missingDocument.description"
                :title="missingDocument.title"
                class="mb-sm-1"
              />
            </a-col>
            <a-col :span="6">
              <a-row :gutter="[10, 10]" align="top" justify="end">
                <a-col>
                  <a-tooltip title="Export as Excel">
                    <a-button
                      type="primary"
                      ghost
                      @click="handleExcelExportEvent"
                      :loading="excelExporting"
                    >
                      <a-space>
                        <i class="ti ti-file-spreadsheet ti-lg"></i>
                      </a-space>
                    </a-button>
                  </a-tooltip>
                </a-col>
                <a-col :span="18">
                  <a-input
                    v-model:value="missingDocument.query"
                    placeholder="Search"
                  />
                </a-col>
              </a-row>
            </a-col>
          </a-row>

          <a-table
            :columns="missingDocument.columns"
            :dataSource="missingDocumentData"
            :pagination="
              missingDocumentData.length > 10 ? { pageSize: 10 } : false
            "
            :loading="missingDocument.loading"
            :rowKey="(record) => record.id"
            size="small"
          >
            <template #employee="{ record, text }">
              <router-link
                :to="{
                  name: 'show-employee',
                  params: { id: record.id },
                }"
                target="_blank"
              >
                {{ text }}
              </router-link>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import moment from "moment/moment";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      excelExporting: false,
      listData: {
        primary_jobs: {
          title: "Employees with missing Primary Job",
          description:
            "Following list of employees have no primary job mapped. " +
            "Kindly create/map primary job for effective payroll processing.",
          route: "show-employee",
          data: [],
          query: null,
        },
        hiring_date: {
          title: "Employees/Contractors with missing Hiring Date",
          description:
            "Following list of employees or contractors not have hiring date. " +
            "Kindly add hiring date for further processing.",
          route: "show-employee",
          data: [],
          query: null,
        },
        adp_jobs: {
          title: "Employees with other jobs not mapped with ADP",
          description:
            "Following Employees don't have their other jobs mapped with ADP Hour Code. " +
            "Please map other jobs with ADP Hour Code.",
          route: "show-employee",
          data: [],
          query: null,
        },
        in_active_products: {
          title: "Products In-active on QuickBooks",
          description:
            "Following Products are currently active on KeyPort but inactive on QuickBooks. " +
            "Please activate these products on QuickBooks.",
          route: false,
          gapXl: true,
          // `${item.product.manufacturer.name} | ${item.product.name} | ${item.product.sku}`
          data: [],
          query: null,
        },
        not_mapped_products: {
          title: "Products not mapped with QuickBooks",
          description:
            "Following Products are currently active on KeyPort but not mapped with QuickBooks. " +
            "Please map these products with QuickBooks.",
          route: false,
          // `${item.product.manufacturer.name} | ${item.product.name} | ${item.product.sku}`
          data: [],
          gapXl: true,
          query: null,
        },
        not_mapped_hospitals: {
          title: "Hospital Classes not mapped on QuickBooks",
          description:
            "Following Hospital Classes are not mapped on QuickBooks. " +
            "Please map these Classes on QuickBooks for proper functioning.",
          route: "hospital-details",
          data: [],
          gapXl: true,
          query: null,
        },
        not_mapped_hospital_customers: {
          title: "Hospital Customers not mapped on QuickBooks",
          description:
            "Following Hospital Customers are not mapped on QuickBooks. " +
            "Please map these Customers on QuickBooks for proper functioning.",
          route: "hospital-details",
          data: [],
          gapXl: true,
          query: null,
        },
        low_margin_hospital_products: {
          title: "Low Margin Hospital Products",
          description:
            "Following hospitals have more then 1 product which has low margin compare to original cost",
          route: "hospital-details",
          data: [],
          gapXl: true,
          query: null,
        },
      },
      caseReportTableData: [
        {
          title: "Case Reports with Products inactive on QuickBooks",
          description:
            "Following Case Reports have products that are currently inactive on QuickBooks but are being used in Case Reports. Please activate these products on QuickBooks.",
          data: [],
          query: null,
        },
        {
          title: "Case Reports with Products not mapped with QuickBooks",
          description:
            "Following Case Reports have products that are currently not mapped to QuickBooks and are being used in Case Reports. Please map these products to their QuickBooks.",
          data: [],
          query: null,
        },
      ],
      columns: [
        {
          title: "Case Report No.",
          dataIndex: "report_no",
          slots: {
            customRender: "report_no",
          },
        },
        {
          title: "Hospital",
          dataIndex: "hospital.name",
        },
        {
          title: "MR No.",
          dataIndex: "mr_number",
        },
        {
          title: "Created By",
          dataIndex: "employee.full_name",
        },
        {
          title: "Action",
          slots: {
            customRender: "action",
          },
        },
      ],
      missingDocument: {
        title: "Employees missing Background Check or Drug Screen Document",
        description:
          "Following employees are missing Background Check or Drug Screen Documents and need to be updated. " +
          "Please update the documents for further processing.",
        columns: [
          {
            title: "Employee",
            dataIndex: "full_name",
            slots: {
              customRender: "employee",
            },
          },
          {
            title: "Missing Documents",
            dataIndex: "missing_docs",
            onFilter: (value, record) => {
              return record.missing_docs.includes(value);
            },
          },
        ],
        query: null,
        filter: [],
        data: [],
        loading: false,
      },

      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions("drawer", ["open"]),

    filterDataFromQuery({ data, query }) {
      if (!query) return data;
      return data.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
    },

    showDisposableProducts(record) {
      this.open({
        title: "Disposable Products",
        path: "actionItems.disposableProductDrawer",
        record,
      });
    },

    filterDataForTableFromQuery({ data, query }) {
      if (!query) return data;

      return data.filter(
        (item) =>
          item.report_no.toLowerCase().includes(query.toLowerCase()) ||
          item.hospital.name.toLowerCase().includes(query.toLowerCase()) ||
          item.mr_number.toLowerCase().includes(query.toLowerCase()) ||
          item.employee.full_name.toLowerCase().includes(query.toLowerCase())
      );
    },

    fetchData() {
      this.loading = true;

      commonService
        .get(this.$constants.thingsToDoApi)
        .then((response) => {
          const {
            data: {
              not_have_adp_hour_job_employees,
              not_have_primary_job_employees,
              not_mapped_products_on_quickbooks,
              inactive_products_on_quickbooks,
              inactive_products_used_in_case_reports,
              not_mapped_products_used_in_case_reports,
              not_mapped_hospital_classes_on_quickbooks,
              not_mapped_hospital_customers_on_quickbooks,
              not_have_hiring_date_employees,
              missing_bg_check_and_drug_doc_employees,
              low_margin_hospital_products,
            },
          } = response;

          this.caseReportTableData[0].data =
            inactive_products_used_in_case_reports;
          this.caseReportTableData[1].data =
            not_mapped_products_used_in_case_reports;

          not_have_primary_job_employees.forEach((item) => {
            this.listData.primary_jobs.data.push({
              id: item.id,
              name: item.full_name,
            });
          });
          not_have_adp_hour_job_employees.forEach((item) => {
            this.listData.adp_jobs.data.push({
              id: item.id,
              name: item.full_name,
            });
          });
          inactive_products_on_quickbooks.forEach((item) => {
            this.listData.in_active_products.data.push({
              id: item.id,
              name: `${item.manufacturer.name} | ${item.name} | ${item.sku}`,
            });
          });
          not_mapped_products_on_quickbooks.forEach((item) => {
            this.listData.not_mapped_products.data.push({
              id: item.id,
              name: `${item.manufacturer.name} | ${item.name} | ${item.sku}`,
            });
          });
          not_mapped_hospital_classes_on_quickbooks.forEach((item) => {
            this.listData.not_mapped_hospitals.data.push({
              id: item.id,
              name: item.name,
            });
          });
          not_mapped_hospital_customers_on_quickbooks.forEach((item) => {
            this.listData.not_mapped_hospital_customers.data.push({
              id: item.id,
              name: item.name,
            });
          });
          not_have_hiring_date_employees.forEach((item) => {
            this.listData.hiring_date.data.push({
              id: item.id,
              name: item.full_name,
            });
          });
          low_margin_hospital_products.forEach((item) => {
            this.listData.low_margin_hospital_products.data.push({
              id: item.id,
              name: `${item.name} | ${item.no_of_products}`,
            });
          });

          const uniqueDocs = [];
          missing_bg_check_and_drug_doc_employees.forEach((item) => {
            const docs = item.missing_docs.split(", ");
            docs.forEach((doc) => {
              if (!uniqueDocs.includes(doc)) uniqueDocs.push(doc);
            });

            this.missingDocument.data.push({
              id: item.id,
              full_name: item.full_name,
              missing_docs: item.missing_docs,
            });
          });
          this.missingDocument.columns[1].filters = uniqueDocs.map((doc) => ({
            text: doc,
            value: doc,
          }));
        })
        .catch((err) => this.$message.error(err))
        .finally(() => {
          this.loading = false;
        });
    },

    handleExcelExportEvent() {
      this.excelExporting = true;
      commonService
        .downloadFile(
          this.$constants.thingsToDoMissingBackgroundCheckExcel,
          {},
          `missing-background-check-${moment().format("MM-DD-YYYY")}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.excelExporting = false;
        });
    },

    handleMissingDocumentTableFilter(_, filters) {
      // filters: {missing_docs: ["Background Check"]}
      this.missingDocument.filter = filters.missing_docs;
    },
  },
  computed: {
    isAllEmpty() {
      return Object.values(this.listData).every((item) => !item.data.length);
    },

    missingDocumentData() {
      return this.missingDocument.data.filter((item) => {
        if (!this.missingDocument.query) return true;
        const query = this.missingDocument.query.trim().toLowerCase();

        return (
          item.full_name.toLowerCase().includes(query) ||
          item.missing_docs.toLowerCase().includes(query)
        );
      });
    },
  },
};
</script>
