<template>
  <a-table
    :dataSource="model.section7"
    :columns="columns"
    size="small"
    :pagination="false"
    :rowKey="(_, i) => i"
  />
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
  },

  data() {
    return {
      columns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          customRender: ({ record }) =>
            `${record.type} (${record.percentage}%)`,
        },
        {
          title: "Weightage (%)",
          dataIndex: "weightage",
          key: "weightage",
          width: 150,
          slots: {
            customRender: "weightage",
          },
        },
      ],
    };
  },

  methods: {
    //
  },
};
</script>