import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";
import hasRoles from "./middleware/hasRoles";

export default [
  {
    path: "/policy-and-procedure-manual",
    name: "policy-and-procedure-manual",
    component: route("policyAndProcedureManual/index"),
    meta: {
      title: "Policy and Procedure Manual",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/policy-and-procedure-manual/admin",
    name: "policy-and-procedure-manual-admin",
    component: route("policyAndProcedureManual/index"),
    meta: {
      title: "Policy and Procedure Manual",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1
    },
  },
];
