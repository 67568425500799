<template>
  <SearchOutlined
    :style="{ color: filterIcon.filtered ? '#108ee9' : undefined }"
  />
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,
  },

  props: {
    filterIcon: {
      type: Object,
      required: true,
    },
  },
};
</script>