<template>
  <a-layout-content>
    <page-header>
      <template #title>
        {{ title }}
      </template>

      <template #buttons>
        <back-button
          :listeners="true"
          @handleClick="$router.push({ name: 'case-report' })"
        />
      </template>
    </page-header>

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        name="case_report_form"
        ref="case_report_form"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-steps :current="currentStep" progress-dot>
          <template v-for="(item, index) in steps" :key="item.title">
            <a-step :title="item.title" v-show="stepCondition(index)" />
          </template>
        </a-steps>

        <br />

        <basic-step
          v-if="currentStep == 0"
          :model="model"
          :hospitals="hospitals"
          :isCati="isCati"
          :isCaseServiceRequestTypeHasCpb="isCaseServiceRequestTypeHasCpb"
          :isCaseServiceRequestTypeHasEcmo="isCaseServiceRequestTypeHasEcmo"
          :isLogNumberShow="isLogNumberShow"
          :isCatiInvoiceAmountShow="isCatiInvoiceAmountShow"
          :isTavrInputShow="isTavrInputShow"
          @validate="validate"
        >
        </basic-step>

        <disposable-product-used-step
          v-if="currentStep == 1"
          :model="model"
          :hospitals="hospitals"
          :productCategories="productCategories"
          :products="products"
          :isCati="isCati"
          :isKeystone="isKeystone"
          :catiProductCategories="catiProductCategories"
          :keystoneProductCategories="keystoneProductCategories"
          @validate="validate"
        />

        <quality-indicators-step
          v-if="currentStep == 2"
          :model="model"
          @validate="validate"
        />

        <cell-saver-operative-data-step
          v-if="currentStep == 3"
          :model="model"
          :wasAQcSampleObtainedForThisProcedureIsYes="
            wasAQcSampleObtainedForThisProcedureIsYes
          "
          :washedRbcsReinfusedIs0="washedRbcsReinfusedIs0"
          @validate="validate"
        />

        <hipec-quality-indicators-step
          v-if="currentStep == 4"
          :model="model"
          @validate="validate"
        />

        <transfusion-requirements-step
          v-if="currentStep == 5"
          :model="model"
          :anyTransfusionRequiredIsYes="anyTransfusionRequiredIsYes"
          @validate="validate"
        />

        <post-procedure-maintenance-step
          v-if="currentStep == 6"
          :model="model"
          :wasHeaterCoolerMachineCleanedIsYes="
            wasHeaterCoolerMachineCleanedIsYes
          "
          :wasCellSaverDeviceCleanedIsYes="wasCellSaverDeviceCleanedIsYes"
          :wasBypassMachineCleanedIsYes="wasBypassMachineCleanedIsYes"
          @validate="validate"
        />

        <preview-step
          v-if="currentStep == 7"
          :model="model"
          :steps="steps"
          :productCategories="productCategories"
          :products="products"
          :isCati="isCati"
          :isCaseServiceRequestTypeHasCpb="isCaseServiceRequestTypeHasCpb"
          :isCaseServiceRequestTypeHasEcmo="isCaseServiceRequestTypeHasEcmo"
          :isLogNumberShow="isLogNumberShow"
          :isCatiInvoiceAmountShow="isCatiInvoiceAmountShow"
          :isTavrInputShow="isTavrInputShow"
          :wasAQcSampleObtainedForThisProcedureIsYes="
            wasAQcSampleObtainedForThisProcedureIsYes
          "
          :washedRbcsReinfusedIs0="washedRbcsReinfusedIs0"
          :anyTransfusionRequiredIsYes="anyTransfusionRequiredIsYes"
          :wasHeaterCoolerMachineCleanedIsYes="
            wasHeaterCoolerMachineCleanedIsYes
          "
          :wasCellSaverDeviceCleanedIsYes="wasCellSaverDeviceCleanedIsYes"
          :wasBypassMachineCleanedIsYes="wasBypassMachineCleanedIsYes"
        />

        <div class="steps-action">
          <a-button v-if="currentStep > 0" class="mr-sm-1" @click="prev">
            Previous
          </a-button>

          <a-button
            v-if="currentStep == steps.length - 1"
            type="primary"
            html-type="submit"
            :loading="submitBtnLoader"
          >
            Done
          </a-button>

          <a-button
            v-if="currentStep < steps.length - 1"
            type="primary"
            @click="next"
          >
            Next
          </a-button>
        </div>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import BasicStep from "./steps/basic";
import DisposableProductUsedStep from "./steps/disposableProductsUsed.vue";
import QualityIndicatorsStep from "./steps/qualityIndicators.vue";
import CellSaverOperativeDataStep from "./steps/cellSaverOperativeData.vue";
import HipecQualityIndicatorsStep from "./steps/hipecQualityIndicators.vue";
import TransfusionRequirementsStep from "./steps/transfusionRequirements.vue";
import PostProcedureMaintenanceStep from "./steps/postProcedureMaintenance.vue";
import PreviewStep from "./steps/preview.vue";
import moment from "moment";

export default {
  components: {
    backButton,
    MinusCircleOutlined,
    PlusCircleOutlined,
    BasicStep,
    DisposableProductUsedStep,
    QualityIndicatorsStep,
    CellSaverOperativeDataStep,
    HipecQualityIndicatorsStep,
    TransfusionRequirementsStep,
    PostProcedureMaintenanceStep,
    PreviewStep,
  },

  data() {
    return {
      cardLoader: false,
      hospitals: [],
      productCategories: [],
      products: [],
      keystoneId: 4650400,
      catiId: 4650401,
      catiProductCategories: [2, 4, 6, 8],
      keystoneProductCategories: [3, 5, 7, 9],
      currentStep: 0,
      submitBtnLoader: false,
      steps: [
        {
          title: "Basic",
        },
        {
          title: "Disposable Products Used",
        },
        {
          title: "Quality Indicators",
        },
        {
          title: "Cell Saver Operative Data",
        },
        {
          title: "HIPEC Quality Indicators",
        },
        {
          title: "Transfusion Requirements",
        },
        {
          title: "Post Procedure Maintenance",
        },
        {
          title: "Preview",
        },
      ],

      model: {
        id: this.$route.params.id ?? 0,

        // step 1
        date: "",
        hospital_id: null,
        log_number: null,
        patient_first_name: null,
        patient_last_name: null,
        mr_number: null,
        surgeon: null,
        procedure: null,
        anesthesia_provider: null,
        case: null,
        ytd: null,
        tavr: null,
        case_status: [],
        case_service_request_type: [],
        height: "",
        weight: "",
        body_surface_area: null,
        allergies: null,
        is_disposable_use: null,
        ecmo_hours: null,
        cpb_time: null,
        cross_clamp_time: null,
        circulatory_arrest_time: null,
        clinician: null,
        cati_invoice_amount: null,

        // step 2
        products: {},
        // reservoirs: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // processing_sets: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // section_lines: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // filters: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // platelet_gel_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // miscellaneous: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // cell_saver_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // tubing_packs_and_oxygenators: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // arterial_cannula: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // venous_cannula: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // cardioplegia_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // vents_pump_suckers_and_sumps: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // platelet_gel_therapy_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // connectors_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // tubing_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // miscellaneous_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        // mcmo_disposables: [
        //   {
        //     product_id: null,
        //     qty: null,
        //   },
        // ],
        nacl_bags: null,
        heparin_vials: null,

        // step 3
        anesthesia_volumn_pre_bypass: null,
        net_prime_volume: null,
        ultrafiltration_on_cpb: null,
        modified_ultrafiltration: null,
        act_seconds_prior_to_initiation: null,
        act_seconds_while_on_cpb: null,
        lowest_act_on_cpb: null,
        post_induction_hematocrit: null,
        first_cpb_hematocrit: null,
        percent_decrease_in_hemtocrit: null,
        lowest_cpb_hematocrit: null,
        last_cpb_hematocrit: null,
        last_in_room_hematocrit: null,
        lowest_arterian_pressure_on_cpb: null,
        lowest_svo2_on_cpb: null,
        lowest_cpb_base_excess_deficit: null,
        highest_blood_glucose_level: null,
        highest_arterial_blood_temperature: null,
        lowest_core_body_temperature: null,
        minimum_cardiac_index_during_cpb: null,
        prbc_transfusion_during_cpb: null,

        // step 4
        washed_rbcs_reinfused: null,
        type_of_anticoagulant_used: null,
        total_processed_volumn: null,
        anticoagulant_volumn_used: null,
        wash_volumn_used: null,
        estimated_blood_loss: null,
        clear_effluent_wash_line: null,
        autologous_blood_transfused_within_4_hours: null,
        cell_saver_hct: null,
        cell_saver_device_serial_number: null,
        was_a_qc_sample_obtained_for_this_procedure: null,
        cell_saver_qc_results_source: null,
        cell_saver_qc_results_parameter: null,
        cell_saver_qc_results_result: null,

        // step 5
        cytotoxic_drug_type_and_dose: null,
        prime_type_and_amount: null,
        average_inflow_temperature: null,
        average_outflow_temperature: null,
        pump_flow_rate: null,
        average_reservoir_volume: null,
        additional_comments: null,

        // step 6
        any_transfusion_required: null,
        packed_red_blood_cells_transfused: null,
        total_fresh_frozen_plasma: null,
        total_platelet: null,
        total_cryoprecipitate: null,
        notes: null,
        patient_in_room_time: null,
        surgery_start_time: null,
        surgery_end_time: null,
        patient_out_of_room_time: null,

        // step 7
        was_bypass_machine_cleaned: null,
        heart_machine_device_number: null,
        was_heater_cooler_machine_cleaned: null,
        heater_cooler_device_number: null,
        was_cell_saver_device_cleaned: null,
        cell_saver_device_number: null,
      },
      rules: [],
    };
  },

  computed: {
    title() {
      if (this.model.id == 0) {
        return "Add New Case Report";
      }
      return "Edit Case Report";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },

    isCati() {
      let bool = false;
      if (this.model.hospital_id) {
        let hospital = this.hospitals.find(
          (v) => v.id == this.model.hospital_id
        );
        if (hospital) {
          hospital.companies.forEach((com) => {
            if (this.catiId == com.id) {
              bool = true;
            }
          });
        }
      }
      return bool;
    },

    isKeystone() {
      let bool = false;
      if (this.model.hospital_id) {
        let hospital = this.hospitals.find(
          (v) => v.id == this.model.hospital_id
        );
        if (hospital) {
          hospital.companies.forEach((com) => {
            if (this.keystoneId == com.id) {
              bool = true;
            }
          });
        }
      }
      return bool;
    },

    isCaseServiceRequestTypeHasCpb() {
      return this.model.case_service_request_type.includes("CPB");
    },

    isCaseServiceRequestTypeHasEcmo() {
      return this.model.case_service_request_type.includes("ECMO");
    },

    isLogNumberShow() {
      return [11, 48, 79, 85].includes(this.model.hospital_id);
    },

    isCatiInvoiceAmountShow() {
      let serviceBool = false;
      let services = [
        "AUTOTRANSFUSION",
        "ATX STANDBY",
        "PLATELET GEL THERAPY",
        "CANCELED CASE",
      ];

      services.forEach((v) => {
        if (!serviceBool) {
          let service = Object.values(this.model.case_service_request_type);
          serviceBool = service.includes(v);
        }
      });

      return serviceBool && this.isCati;
    },

    isTavrInputShow() {
      return (
        this.hospitalIdIs(30) &&
        this.model.case_service_request_type.includes("TAVR")
      );
    },

    wasAQcSampleObtainedForThisProcedureIsYes() {
      return this.model.was_a_qc_sample_obtained_for_this_procedure == "Yes";
    },

    washedRbcsReinfusedIs0() {
      return this.model.washed_rbcs_reinfused == "0";
    },

    anyTransfusionRequiredIsYes() {
      return this.model.any_transfusion_required == "Yes";
    },

    wasHeaterCoolerMachineCleanedIsYes() {
      return this.model.was_heater_cooler_machine_cleaned == "Yes";
    },

    wasCellSaverDeviceCleanedIsYes() {
      return this.model.was_cell_saver_device_cleaned == "Yes";
    },

    wasBypassMachineCleanedIsYes() {
      return this.model.was_bypass_machine_cleaned == "Yes";
    },
  },

  mounted() {
    this.catiProductCategories.forEach((v) => {
      this.model.products[v] = [
        {
          product_id: null,
          qty: null,
        },
      ];
    });
    this.keystoneProductCategories.forEach((v) => {
      this.model.products[v] = [
        {
          product_id: null,
          qty: null,
        },
      ];
    });

    this.loadData();
  },

  mixins: [formMixins],

  methods: {
    loadData() {
      this.cardLoader = true;
      Promise.all([
        this.fetchHospitals(),
        this.fetchProductCategories(),
        this.fetchProducts(),
        false,
      ])
        .then((values) => {
          [this.hospitals, this.productCategories, this.products] = values;
          if (this.model.id != 0) {
            this.fetchCaseReport();
          } else {
            this.cardLoader = false;
          }
        })
        .catch(() => this.$message.error("Somthing went wrong!"));
    },

    fetchHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl, { with_companies: true })
        .then((res) => res.data);
    },

    fetchProductCategories() {
      return commonService
        .get(this.$constants.getProductCategoriesUrl)
        .then((res) => res.data);
    },

    fetchProducts() {
      return commonService
        .get(this.$constants.getProductsUrl)
        .then((res) => res.data);
    },

    fetchCaseReport() {
      commonService
        .get(this.$constants.caseReportShowUrl, { id: this.model.id })
        .then((res) => {
          let data = res.data;
          let data_3 = data.quality_indicator;
          let data_4 = data.cell_saver_operative_data;
          let data_5 = data.hipec_quality_indicator;
          let data_6 = data.transfusion_requirement;
          let data_7 = data.post_procedure_maintenance;

          let products = Object.values(res.data.disposable_products).reduce(
            (val, item) => {
              let first = val[item.product_category_id][0];
              if (first["product_id"] == null) {
                val[item.product_category_id] = [];
              }
              val[item.product_category_id].push({
                product_id: item.product_id,
                qty: item.quantity,
              });
              return val;
            },
            this.model.products
          );

          this.model = {
            // step 1
            id: data.id,
            date: moment(data.date),
            hospital_id: data.hospital_id,
            log_number: data.log_number,
            patient_first_name: data.patient_first_name,
            patient_last_name: data.patient_last_name,
            mr_number: data.mr_number,
            surgeon: data.surgeon,
            procedure: data.procedure,
            anesthesia_provider: data.anesthesia_provider,
            case: data.case,
            ytd: data.ytd,
            tavr: data.tavr,
            case_status: data.statuses.map((v) => v.name),
            case_service_request_type: data.service_request_types.map(
              (v) => v.name
            ),
            height: data.height == null ? "" : data.height,
            weight: data.weight == null ? "" : data.weight,
            body_surface_area:
              data.body_surface_area == null ? "" : data.body_surface_area,
            allergies: data.allergies,
            is_disposable_use: data.is_disposable_use,
            ecmo_hours: data.ecmo_hours,
            cpb_time: data.cpb_time,
            cross_clamp_time: data.cross_clamp_time,
            circulatory_arrest_time: data.circulatory_arrest_time,
            clinician: data.clinician,
            cati_invoice_amount: data.cati_invoice_amount,

            // step 2
            products,
            nacl_bags: data.nacl_bags,
            heparin_vials: data.heparin_vials,

            // step 3
            anesthesia_volumn_pre_bypass: data_3.anesthesia_volumn_pre_bypass,
            net_prime_volume: data_3.net_prime_volume,
            ultrafiltration_on_cpb: data_3.ultrafiltration_on_cpb,
            modified_ultrafiltration: data_3.modified_ultrafiltration,
            act_seconds_prior_to_initiation:
              data_3.act_seconds_prior_to_initiation,
            act_seconds_while_on_cpb: data_3.act_seconds_while_on_cpb,
            lowest_act_on_cpb: data_3.lowest_act_on_cpb,
            post_induction_hematocrit: data_3.post_induction_hematocrit,
            first_cpb_hematocrit: data_3.first_cpb_hematocrit,
            percent_decrease_in_hemtocrit: data_3.percent_decrease_in_hemtocrit,
            lowest_cpb_hematocrit: data_3.lowest_cpb_hematocrit,
            last_cpb_hematocrit: data_3.last_cpb_hematocrit,
            last_in_room_hematocrit: data_3.last_in_room_hematocrit,
            lowest_arterian_pressure_on_cpb:
              data_3.lowest_arterian_pressure_on_cpb,
            lowest_svo2_on_cpb: data_3.lowest_svo2_on_cpb,
            lowest_cpb_base_excess_deficit:
              data_3.lowest_cpb_base_excess_deficit,
            highest_blood_glucose_level: data_3.highest_blood_glucose_level,
            highest_arterial_blood_temperature:
              data_3.highest_arterial_blood_temperature,
            lowest_core_body_temperature: data_3.lowest_core_body_temperature,
            minimum_cardiac_index_during_cpb:
              data_3.minimum_cardiac_index_during_cpb,
            prbc_transfusion_during_cpb: data_3.prbc_transfusion_during_cpb,

            // step 4
            washed_rbcs_reinfused: data_4.washed_rbcs_reinfused,
            type_of_anticoagulant_used: data_4.type_of_anticoagulant_used,
            total_processed_volumn: data_4.total_processed_volumn,
            anticoagulant_volumn_used: data_4.anticoagulant_volumn_used,
            wash_volumn_used: data_4.wash_volumn_used,
            estimated_blood_loss: data_4.estimated_blood_loss,
            clear_effluent_wash_line: data_4.clear_effluent_wash_line,
            autologous_blood_transfused_within_4_hours:
              data_4.autologous_blood_transfused_within_4_hours,
            cell_saver_hct: data_4.cell_saver_hct,
            cell_saver_device_serial_number:
              data_4.cell_saver_device_serial_number,
            was_a_qc_sample_obtained_for_this_procedure:
              data_4.was_a_qc_sample_obtained_for_this_procedure,
            cell_saver_qc_results_source: data_4.cell_saver_qc_results_source,
            cell_saver_qc_results_parameter:
              data_4.cell_saver_qc_results_parameter,
            cell_saver_qc_results_result: data_4.cell_saver_qc_results_result,

            // step 5
            cytotoxic_drug_type_and_dose: data_5.cytotoxic_drug_type_and_dose,
            prime_type_and_amount: data_5.prime_type_and_amount,
            average_inflow_temperature: data_5.average_inflow_temperature,
            average_outflow_temperature: data_5.average_outflow_temperature,
            pump_flow_rate: data_5.pump_flow_rate,
            average_reservoir_volume: data_5.average_reservoir_volume,
            additional_comments: data_5.additional_comments,

            // step 6
            any_transfusion_required: data_6.any_transfusion_required,
            packed_red_blood_cells_transfused:
              data_6.packed_red_blood_cells_transfused,
            total_fresh_frozen_plasma: data_6.total_fresh_frozen_plasma,
            total_platelet: data_6.total_platelet,
            total_cryoprecipitate: data_6.total_cryoprecipitate,
            notes: data_6.notes,
            patient_in_room_time: data_6.patient_in_room_time
              ? moment(data_6.patient_in_room_time, "hh:mm a")
              : null,
            surgery_start_time: data_6.surgery_start_time
              ? moment(data_6.surgery_start_time, "hh:mm a")
              : null,
            surgery_end_time: data_6.surgery_end_time
              ? moment(data_6.surgery_end_time, "hh:mm a")
              : null,
            patient_out_of_room_time: data_6.patient_out_of_room_time
              ? moment(data_6.patient_out_of_room_time, "hh:mm a")
              : null,

            // step 7
            was_bypass_machine_cleaned: data_7.was_bypass_machine_cleaned,
            heart_machine_device_number: data_7.heart_machine_device_number,
            was_heater_cooler_machine_cleaned:
              data_7.was_heater_cooler_machine_cleaned,
            heater_cooler_device_number: data_7.heater_cooler_device_number,
            was_cell_saver_device_cleaned: data_7.was_cell_saver_device_cleaned,
            cell_saver_device_number: data_7.cell_saver_device_number,
          };

          console.log("model::", this.model);
        })
        .finally(() => {
          this.cardLoader = false;
        });
    },

    next() {
      this.$refs.case_report_form.validate().then(() => {
        this.currentStep++;
        if (!this.stepCondition(this.currentStep)) {
          this.next();
        }
      });
    },

    prev() {
      this.currentStep--;
      if (!this.stepCondition(this.currentStep)) {
        this.prev();
      }
    },

    stepCondition(index) {
      let bool = false;
      if (index == 0) {
        // Basic
        bool = true;
      } else if (index == 1) {
        // Disposable Products Used
        bool = this.model.is_disposable_use ?? false;
      } else if (index == 2) {
        // Quality Indicators
        let array = [
          "CPB",
          // "CPB STANDBY",
          "ECMO",
          "PERFUSION", // STANDBY
          "VAD INSERTION",
        ];
        array.forEach((val) => {
          if (!bool) {
            bool = this.model.case_service_request_type.includes(val);
          }
        });
      } else if (index == 3) {
        // Cell Saver Operative Data
        // bool = !this.model.case_service_request_type.includes("CANCELED CASE");
        bool = true;
      } else if (index == 4) {
        // HIPEC Quality Indicators
        bool = this.model.case_service_request_type.includes(
          "HYPERTHERMIC INTRAPERITONEAL EXTRACORPOREAL CHEMOTHERAPY (HIPEC)"
        );
      } else if (index == 5) {
        // Transfusion Requirements
        // bool = !this.model.case_service_request_type.includes("CANCELED CASE");
        bool = true;
      } else if (index == 6) {
        // Post Procedure Maintenance
        bool = this.model.case_service_request_type.includes("CANCELED CASE");
      } else if (index == 7) {
        // Preview
        bool = true;
      }
      return bool;
    },

    handleFinish() {
      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.caseReportStoreUrl, {
          ...this.model,
          date: this.$customDate.ymd(this.model.date),
          patient_in_room_time: this.model.patient_in_room_time
            ? this.$customDate.his(this.model.patient_in_room_time)
            : null,
          surgery_start_time: this.model.surgery_start_time
            ? this.$customDate.his(this.model.surgery_start_time)
            : null,
          surgery_end_time: this.model.surgery_end_time
            ? this.$customDate.his(this.model.surgery_end_time)
            : null,
          patient_out_of_room_time: this.model.patient_out_of_room_time
            ? this.$customDate.his(this.model.patient_out_of_room_time)
            : null,
        })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.push({ name: "case-report" });
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    hospitalIdIs(params) {
      if (typeof params == "number") {
        params = [params];
      }

      return params.includes(this.model.hospital_id);
    },
  },
};
</script>
