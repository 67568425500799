import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";
import profile from "./middleware/profile";

export default [
  // CASE REPORTS
  {
    path: "/manager/case-reports",
    name: "manager.case-report",
    component: route("manager/caseReport/index"),
    meta: {
      title: "Case Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/case-reporting/create",
    name: "manager.create-case-report",
    component: route("newCaseReport/store"),
    meta: {
      title: "Create New Case Report",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/case-reporting/live-charting/:uuid",
    name: "manager.case-report-live-charting",
    component: route("newCaseReport/liveCharting"),
    meta: {
      title: "Case Report - Live Charting",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/case-reports/show/:uuid",
    name: "manager.show-case-report",
    component: route("newCaseReport/show"),
    meta: {
      title: "Show Case Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  // TIMESHEETS
  {
    path: "/manager/timesheets",
    name: "manager.timesheet",
    component: route("manager/timesheet/index"),
    meta: {
      title: "Manager Time Sheets",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  // ORDERS
  {
    path: "/manager/orders",
    name: "manager.order",
    component: route("manager/order/index"),
    meta: {
      title: "Manager Orders",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/order/show/:order_no",
    name: "manager.show-order",
    component: route("order/view"),
    meta: {
      title: "Show Orders",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  // PERFORMANCE EVALUATIONS
  {
    path: "/manager/performance-evaluations",
    name: "manager.performance-evaluation",
    component: route("manager/performanceEvaluation/index"),
    meta: {
      title: "Manager Performance Evaluations",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/performance-evaluation/create",
    name: "manager.create-performance-evaluation",
    component: route("admin/performanceAppraisals/store"),
    meta: {
      title: "Create Performance Evaluation",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/performance-evaluation/edit/:id",
    name: "manager.edit-performance-evaluation",
    component: route("admin/performanceAppraisals/edit"),
    meta: {
      title: "Edit Performance Evaluation",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/performance-evaluation/show/:id",
    name: "manager.show-performance-evaluation",
    component: route("admin/performanceAppraisals/show"),
    meta: {
      title: "Show Performance Evaluation",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  {
    path: "/manager/facilities",
    name: "manager.facilities",
    component: route("manager/facility"),
    meta: {
      title: "Facilities",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/facility/show/:id",
    name: "manager.show-facility",
    component: route("manager/facility/show"),
    meta: {
      title: "Show Facility",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/report/cell-saver-report",
    name: "manager.report.cell-saver-report",
    component: route("report/cellSaverReport/index"),
    meta: {
      title: "Report - Cell Saver Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/report/open-heart-report",
    name: "manager.report.open-heart-report",
    component: route("report/openHeartQualityReport/index"),
    meta: {
      title: "Report - Open Heart Quality Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  {
    path: "/manager/report/nrp-qi-report",
    name: "manager.report.nrp-qi-report",
    component: route("report/nrpQualityReport/index"),
    meta: {
      title: "Report - NRP QI Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  // EMPLOYEE
  {
    path: "/manager/employees",
    name: "manager.employee",
    component: route("manager/employee/index"),
    meta: {
      title: "Manager Employees",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
  {
    path: "/manager/employee/:id",
    name: "manager.show-employee",
    component: route("manager/employee/show"),
    meta: {
      title: "Show Employee Details",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },

  {
    path: "/manager/dispatched-orders",
    name: "manager.dispatched-orders",
    component: route("admin/dispatchedOrder/index"),
    meta: {
      title: "Dispatched Orders",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 3,
    },
  },
];
