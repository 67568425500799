<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="quickbooks_bill_id"
            label="Quickbooks Bill ID"
            name="quickbooks_bill_id"
          >
            <a-input-number type="number"
              v-model:value="formState.quickbooks_bill_id"
              placeholder=""
            />
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons name="Map" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      formState: {
        id: null,
        quickbooks_bill_id: null,
      },
      rules: {
        quickbooks_bill_id: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.formState.id = this.record.id;
      this.loadingStart();
      companyService
        .store(
          this.$constants.adminExpenseAddQuickbooksBillIdUrl,
          this.formState
        )
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
