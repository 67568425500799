function middlewarePipeline (context, middleware, index) {
  const nextMiddleware = middleware[index]
  if(!nextMiddleware){
    return context.next 
  }

  return (text) => {
    if (text) {
      if (text.name != context.to.name) {
        return context.next(text);
      }
    }

    const nextPipeline = middlewarePipeline(
      context, middleware, index + 1
    )
    
    nextMiddleware({ ...context, next: nextPipeline })
  }
}

export default middlewarePipeline