<template>
  <a-col :span="6">
    <a-typography-text strong>{{ data.title }}</a-typography-text>
    <br />
    <!-- <a-typography-text type="secondary">
      {{ data.description }}
    </a-typography-text>
    <br /> -->
    <a-tag v-if="formData.is_enable" color="success">Active</a-tag>
    <a-tag v-else color="error">Inactive</a-tag>
  </a-col>
  <a-col :span="18">
    <a-modal
      v-model:visible="edit"
      :title="data.title"
      width="1000px"
      :footer="null"
    >
      <a-form
        layout="vertical"
        :model="formData"
        :rules="rules"
        @finish="onSubmit"
        @reset="updateFormData()"
      >
        <a-divider orientation="left" style="margin-top: 0">
          Service Specific Emails
        </a-divider>
        <a-row :gutter="[16, 0]">
          <template
            v-for="hospitalService in hospitalCaseServices"
            :key="hospitalService.id"
          >
            <a-col :span="12">
              <a-form-item
                :label="hospitalService.case_service.name"
                :validateStatus="
                  computedRules(hospitalService.case_service.id, 'status')
                "
                :help="computedRules(hospitalService.case_service.id, 'help')"
              >
                <a-select
                  v-model:value="
                    formData.emails[hospitalService.case_service.id]
                  "
                  mode="tags"
                />
              </a-form-item>
            </a-col>
          </template>
        </a-row>

        <a-divider style="margin-top: 0" />

        <a-form-item ref="subject" label="Subject" name="subject">
          <a-input v-model:value="formData.subject" />
        </a-form-item>

        <a-form-item ref="content" label="Content" name="content">
          <ckeditor
            :editor="editor"
            v-model="formData.content"
            :config="editorConfig"
          />
          <template v-for="tempVar in data.template_variables" :key="tempVar">
            <a-tooltip title="Click here for copy">
              <span
                @click="textCopy(tempVar)"
                class="bg-light-danger mr-sm-1 cursor-pointer"
              >
                {{ tempVar }}
              </span>
            </a-tooltip>
          </template>
        </a-form-item>

        <a-space direction="vertical">
          <a-checkbox v-model:checked="formData.attach_pdf">
            {{ data.pdf_attachment_checkbox_text }}
          </a-checkbox>
          <a-checkbox v-model:checked="formData.is_enable">
            {{ data.enable_checkbox_text }}
          </a-checkbox>
          <a-space>
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loading"
              size="small"
            >
              <a-space>
                <i v-if="!loading" class="ti ti-check ti-lg"></i>
                Submit
              </a-space>
            </a-button>
            <a-button
              v-if="!loading"
              type="primary"
              danger
              htmlType="reset"
              size="small"
            >
              <a-space> <i class="ti ti-x ti-lg"></i> Cancel </a-space>
            </a-button>
          </a-space>
        </a-space>
      </a-form>
    </a-modal>

    <template v-if="!edit">
      <div>
        <template
          v-for="hospitalService in hospitalCaseServices"
          :key="`${hospitalService.id}_to`"
        >
          <template
            v-if="
              Array.isArray(formData.emails[hospitalService.case_service.id]) &&
              formData.emails[hospitalService.case_service.id].length > 0
            "
          >
            <strong>Emails for {{ hospitalService.case_service.name }}:</strong>
            {{
              formData.emails[hospitalService.case_service.id]?.join(", ") ?? ""
            }}
            <br />
          </template>
        </template>
        <strong>Subject:</strong> {{ formData.subject }}<br />
        <div class="mail-content" v-html="formData.content" />
        <br />
        <a-space>
          <i v-if="formData.attach_pdf" class="ti ti-checkbox ti-lg"></i>
          <i v-else class="ti ti-square ti-lg"></i>
          {{ data.pdf_attachment_checkbox_text }}
        </a-space>
        <br />
        <a-button type="primary" size="small" @click="updateFormData(true)">
          <a-space> <i class="ti ti-pencil ti-lg"></i> Edit </a-space>
        </a-button>
      </div>
    </template>
  </a-col>
</template>

<script>
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { commonService } from "../../../services";

export default {
  props: {
    notificationSettings: { required: true },
    data: {
      required: true,
      default: {
        title: "",
        description: "",
        type: 1,
        pdf_attachment_checkbox_text: "",
        template_variables: [],
      },
    },
    hospitalId: { required: true },
    hospitalCaseServices: { required: true },
  },

  emits: ["updateSettings"],

  data() {
    return {
      edit: false,
      loading: false,
      formData: {
        emails: [],
        subject: "",
        content: "",
        attach_pdf: false,
        is_enable: false,
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "",
      },
      rules: {
        subject: [
          {
            required: true,
            message: "This field is required!",
            type: "string",
            transform(value) {
              return value.trim();
            },
          },
        ],
        content: [
          {
            required: true,
            message: "This field is required!",
            type: "string",
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      caseServiceIds: [],
    };
  },

  mounted() {
    this.updateFormData();
  },

  methods: {
    updateFormData(bool = false) {
      this.caseServiceIds = [];
      this.edit = bool;
      let formDataSetting = this.notificationSettings.find(
        (item) => item.type == this.data.type
      );
      this.formData = {
        emails: [],
        subject: formDataSetting?.emails?.subject ?? "",
        content: formDataSetting?.emails?.content ?? "",
        attach_pdf: formDataSetting?.emails?.attach_pdf ?? false,
        is_enable: formDataSetting?.is_enable == 1 ? true : false,
      };
      this.hospitalCaseServices.forEach((element) => {
        this.caseServiceIds.push(element.case_service.id);
        this.formData.emails[element.case_service.id] =
          formDataSetting?.emails?.emails[element.case_service.id] ?? [];
      });
    },

    onSubmit() {
      let isValid = true;
      this.hospitalCaseServices.forEach((element) => {
        let res = this.computedRules(element.case_service.id);
        if (res != "") {
          isValid = false;
        }
      });

      if (!isValid) {
        return;
      }

      let emails = [];
      for (let index = 1; index < this.formData.emails.length; index++) {
        if (
          this.formData.emails[index] &&
          Array.isArray(this.formData.emails[index]) &&
          this.formData.emails[index].length != 0 &&
          this.caseServiceIds.includes(index)
        ) {
          emails[index] = [...this.formData.emails[index]];
        }
      }

      this.loading = true;
      commonService
        .store(this.$constants.hospitalStoreNotificationUrl, {
          ...this.formData,
          emails,
          type: this.data.type,
          hospital_id: this.hospitalId,
        })
        .then((res) => {
          this.$emit("updateSettings", res.data);
          setTimeout(() => {
            this.loading = false;
            this.updateFormData();
          }, 1000);
        })
        .catch((err) => this.$message.error(err.message));
    },

    computedRules(ref, type) {
      let val = this.formData.emails[ref];

      if (!val || val?.length == 0) {
        return "";
      }
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      for (let index = 0; index < val.length; index++) {
        if (val[index].match(validRegex)) {
          return type == "status" ? "success" : "";
        } else {
          return type == "status" ? "error" : "Invalid Email";
        }
      }
    },

    textCopy(text) {
      toClipboard(text);
      this.$message.success(`${text} copied successfully.`);
    },
  },
};
</script>

<style scoped lang="less">
.mail-content:deep {
  p {
    margin-bottom: 0;
  }
}
</style>
