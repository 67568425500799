<template>
  <template v-if="loading">
    <a-card size="small" :loading="loading"> </a-card>
  </template>

  <template v-else>
    <a-row :gutter="[16, 16]">
      <template v-for="(data, index) in settingData" :key="data.type">
        <setting-vue
          :notificationSettings="notificationSettings"
          :title="data.title"
          :description="data.description"
          :type="data.type"
          :employeeId="employee.id"
          @updateSettings="(data) => (notificationSettings = data)"
        />

        <a-divider v-if="index + 1 != settingData.length" class="my-sm-0" />
      </template>
    </a-row>
  </template>
</template>

<script>
import { mapGetters } from "vuex";
import { commonService } from "../../services";
import settingVue from "../employee/components/setting.vue";

export default {
  components: { settingVue },

  data() {
    return {
      loading: true,
      notificationSettings: [],
      settingData: this.$constants.employeeNotificationSettings,
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      commonService
        .store(this.$constants.employeeGetNotificationUrl, {
          employee_id: this.employee.id,
        })
        .then((res) => {
          this.notificationSettings = res.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
