<template>
  <a-form
    name="set_password_form"
    ref="set_password_form"
    :model="formState"
    :rules="rules"
    @finish="onSubmit()"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 14 }"
  >
    <a-row :gutter="[20, 20]" justify="space-between">
      <a-col :span="24">
        <a-typography-title :level="4"> Set Password</a-typography-title>
        <span>
          Your account has been successfully created with an autogenerated
          password for security purposes. To further secure your account, please
          set your own password now. You can choose your new password below,
          ensuring it meets the specified password requirements provided.
        </span>

        <a-alert type="warning" class="mt-sm-1">
          <template #message>
            <ul class="mb-sm-0">
              <li>Must be at least 10 characters in length</li>
              <li>Must contain at least one lowercase letter</li>
              <li>Must contain at least one uppercase letter</li>
              <li>Must contain at least one digit</li>
              <li>Must contain a special character (@$!%*#?&)</li>
            </ul>
          </template>
        </a-alert>
        <a-divider style="margin: 12px 0" />
      </a-col>
      <a-col :span="24">
        <a-form-item
          label="Old Password"
          name="old_password"
          :validateStatus="validateStatus('old_password')"
          :help="validateMessage('old_password')"
        >
          <a-input-password
            v-model:value="formState.old_password"
            placeholder="Enter old password here"
            @change="resetStatus('old_password')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          label="New Password"
          name="new_password"
          :validateStatus="validateStatus('new_password')"
          :help="validateMessage('new_password')"
        >
          <a-input-password
            v-model:value="formState.new_password"
            placeholder="Enter new password here"
            @change="resetStatus('new_password')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          label="Confirm Password"
          name="confirm_password"
          :validateStatus="validateStatus('confirm_password')"
          :help="validateMessage('confirm_password')"
        >
          <a-input-password
            v-model:value="formState.confirm_password"
            placeholder="Enter confirm password here"
            @change="resetStatus('confirm_password')"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <footer-buttons
          :current-step="currentStepComputed"
          :steps="steps"
          :loading="loading"
          :disable-next="!hasSetPassword"
          @next-step="currentStepComputed += 1"
          @previous-step="currentStepComputed -= 1"
        />
      </a-col>
    </a-row>
  </a-form>
</template>

<script setup>
import FooterButtons from "@/components/newUserOnboarding/FooterButtons.vue";
import { constants } from "@/helper/constants";
import { companyService } from "@/services";
import { message } from "ant-design-vue";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  currentStep: Number,
  steps: Array,
});
const emit = defineEmits(["update:currentStep"]);

const currentStepComputed = computed({
  get: () => props.currentStep,
  set: (value) => emit("update:currentStep", value),
});

const store = useStore();
const user = computed(() => store.getters["auth/user"]);

const rules = {
  old_password: [
    {
      required: true,
      message: "Please enter your old password",
      trigger: ["change", "blur"],
    },
  ],
  new_password: [
    {
      required: true,
      message: "Password is required",
      trigger: ["change", "blur"],
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&]).{10,}$/,
      message: "Password format is invalid",
      trigger: ["change", "blur"],
    },
  ],
  confirm_password: [
    {
      required: true,
      message: "Please enter your confirm password",
      trigger: ["change", "blur"],
    },
    {
      validator: (rule, value, callback) => {
        if (value !== formState.value.new_password) {
          return Promise.reject("Password does not match");
        } else {
          return Promise.resolve();
        }
      },
      trigger: ["change", "blur"],
    },
  ],
};
const formState = ref({});
const loading = ref(false);
const hasSetPassword = computed(() => user.value?.has_change_password);

function getFormState() {}

onMounted(() => {
  getFormState();
});

const set_password_form = ref(null);

const errors = reactive({});
const validateStatus = (key) => {
  if (key in errors) {
    return "error";
  }
};

const validateMessage = (key) => {
  if (key in errors) {
    return errors[key][0];
  }
};

const resetStatus = (key) => {
  if (key in errors) {
    delete errors[key];
  }
};

const onSubmit = () => {
  set_password_form.value
    .validate()
    .then(() => {
      loading.value = true;
      const data = {
        old_password: formState.value.old_password,
        new_password: formState.value.new_password,
        new_confirm_password: formState.value.confirm_password,
      };

      companyService
        .store(constants.changePasswordUrl, data)
        .then(async (res) => {
          if (res.success) {
            message.success(res.message);
            // currentStepComputed.value += 1;
            store.dispatch("auth/set_has_change_password", 1);
          }
        })
        .catch((err) => {
          console.log(err);
          Object.keys(err.errors).forEach((key) => {
            errors[key] = err.errors[key];
          });
        })
        .finally(() => {
          loading.value = false;
        });
    })
    .catch((error) => {
      console.log("error", error);
      loading.value = false;
    });
};
</script>

<style lang="less" scoped></style>
