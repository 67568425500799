<template>
  <a-row class="brand" type="flex" justify="center">
    <a-col>
      <img src="@/assets/168_44.png" alt="" class="img-fluid" />
    </a-col>
  </a-row>

  <a-menu
    theme="dark"
    :openKeys="openKeys"
    v-model:selectedKeys="current"
    mode="inline"
    @openChange="onOpenChange"
  >
    <template v-for="v in rootMenu">
      <template v-if="!('subMenu' in v)">
        <a-menu-item v-if="v.condition" :key="v.key">
          <router-link :to="{ name: v.key }">
            <span>{{ v.title }}</span>
          </router-link>
        </a-menu-item>
      </template>

      <template v-else>
        <a-sub-menu v-if="checkSubMenuConditions(v.subMenu)" :key="v.key">
          <template #icon> </template>
          <template #title>
            {{ v.title }}
          </template>

          <template v-for="route in v.subMenu">
            <a-menu-item v-if="route.condition" :key="route.route">
              <router-link :to="{ name: route.route }">
                <span>{{ route.title }}</span>
              </router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </template>
  </a-menu>
</template>

<script>
import router from "@/router";
import { ScheduleOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    ScheduleOutlined,
  },
  data() {
    return {
      rootMenu: [
        {
          key: "hospital-user-dashboard",
          title: "Dashboard",
          condition: true,
        },
        {
          key: "hospital-user-appointments",
          title: "Appointments",
          condition: true,
          extra: [
            "hospital-user-appointment-create",
            "hospital-user-appointment-edit",
            "hospital-user-appointment-show",
          ],
        },
        {
          key: "hospital-user-change-password",
          title: "Change Password",
          condition: true,
        },
      ],
      openKeys: [],
      current: [],
    };
  },
  mounted() {
    this.updateSidebar();
  },

  methods: {
    updateSidebar() {
      const current_route = router.currentRoute.value.name;
      this.rootMenu.map((v) => {
        if (!("subMenu" in v)) {
          if (
            v.key == current_route ||
            ("extra" in v && v.extra.indexOf(current_route) !== -1)
          ) {
            this.current = [v.key];
            this.openKeys = [];
          }
        } else {
          v.subMenu.map((route) => {
            if (
              route.route == current_route ||
              ("extra" in route && route.extra.indexOf(current_route) !== -1)
            ) {
              this.current = [route.route];
              this.openKeys = [v.key];
            }
            return route;
          });
        }
        return v;
      });
    },

    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (Object.keys(this.rootMenu).indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },

    checkSubMenuConditions(subMenu) {
      return Object.values(subMenu).reduce(
        (bool, v) => bool || v.condition,
        false
      );
    },
  },
};
</script>

<style scoped>
.brand {
  padding: 15px;
}
.ant-menu {
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 50px;
}
</style>
