<template>
  <a-typography-title :level="5"> How can we help? </a-typography-title>

  <a-typography-text>
    At Keystone Perfusion Services, we highly value your input to enhance
    KeyPort, our ERP system. Please submit your feedback using the form below,
    selecting the appropriate category such as Enhancement Request or Bug
    Report, and providing a detailed description, severity (if applicable), and
    optional screenshots/attachments. Your insights are invaluable in improving
    KeyPort for all users. Thank you for your contribution to our continuous
    improvement efforts!
  </a-typography-text>

  <a-form
    class="mt-sm-2"
    :model="model"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-form-item ref="category" label="Category" name="category">
          <a-select
            v-model:value="model.category"
            placeholder="Select One..."
            optionFilterProp="label"
            :options="$constants.feedbackCategories"
            showSearch
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item ref="message" label="Description" name="message">
          <a-textarea
            v-model:value="model.message"
            placeholder="Enter your feedback here..."
            :rows="5"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item ref="severity" label="Severity" name="severity">
          <a-radio-group
            v-model:value="model.severity"
            :options="$constants.feedbackSeverities"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item ref="attachments" label="Attachments" name="attachments">
          <a-upload-dragger
            :fileList="model.attachments"
            :multiple="true"
            :remove="handleRemoveReceipt"
            :before-upload="beforeUploadReceipt"
            accept="image/jpeg,image/jpg,image/png,application/pdf"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">
              Drag & drop files to upload or Click to select file.
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-col>
    </a-row>
    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import { commonService } from "@/services";
import { InboxOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";

export default {
  components: { InboxOutlined },

  data() {
    return {
      model: {
        category: null,
        message: null,
        severity: null,
        attachments: [],
      },
      rules: {
        category: {
          required: true,
          type: "integer",
          message: "This field is required!",
          trigger: ["change", "blur"],
        },
        message: {
          required: true,
          message: "This field is required!",
          trigger: ["change", "blur"],
        },
        severity: {
          required: true,
          type: "integer",
          message: "This field is required!",
          trigger: ["change", "blur"],
        },
      },
    };
  },

  emits: ["callback"],

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();

      const formData = new FormData();
      formData.append("category", this.model.category);
      formData.append("message", this.model.message);
      formData.append("severity", this.model.severity);
      if (this.model.attachments.length > 0) {
        this.model.attachments.forEach((attachment) => {
          formData.append("attachments[]", attachment);
        });
      }

      console.log(this.model);

      commonService
        .store(this.$constants.feedbackStoreUrl, formData)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
          }
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },

    handleRemoveReceipt(file) {
      const index = this.model.attachments.indexOf(file);
      const newFileList = this.model.attachments.slice();
      newFileList.splice(index, 1);
      this.model.attachments = newFileList;
    },

    beforeUploadReceipt(file) {
      if (file.size < 2 * 1024 * 1024) {
        this.model.attachments = [...this.model.attachments, file];
      } else {
        this.$message.error(`${file.name} size is more then 2MB`);
      }
      return false;
    },
  },
};
</script>
