<template>
  <div>
    <a-descriptions
      :column="2"
      :contentStyle="{ width: '32%' }"
      :labelStyle="{ width: '18%' }"
      bordered
      size="small"
    >
      <a-descriptions-item :span="2" label="Roles">
        {{
          record.roles.length
            ? record.roles.map((i) => i.name).join(", ")
            : "N/A"
        }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Positions">
        {{
          record.employee_positions.length
            ? record.employee_positions.map((i) => i.name).join(", ")
            : "N/A"
        }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Employment Type">
        {{
          record.employment_type
            ? $comman.getValueFromObject(
                record,
                "employment_type",
                $constants.employment_types
              )
            : "N/A"
        }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Expiry Datetime">
        {{
          moment(record.expiry_datetime).format("MMMM DD, YYYY HH:mm:ss") ??
          "N/A"
        }}
      </a-descriptions-item>
    </a-descriptions>

    <a-card size="small" class="mt-sm-1">
      <a-space :size="5" direction="vertical" style="width: 100%">
        <a-typography-title :level="4">
          {{ record.title }}
        </a-typography-title>

        <img
          v-if="record.images.length === 1"
          :src="record.images[0].full_file_path"
          class="mb-sm-1"
          style="object-fit: cover; width: 100%; aspect-ratio: 16/4"
        />

        <Splide
          :options="{
            type: 'loop',
            rewind: true,
            pagination: true,
            autoplay: true,
          }"
          class="mb-sm-1"
          v-else-if="record.images.length > 1"
        >
          <SplideSlide v-for="(image, idx) in record.images" :key="idx">
            <img
              :src="image.full_file_path"
              style="object-fit: cover; width: 100%; aspect-ratio: 16/4"
            />
          </SplideSlide>
        </Splide>

        <p v-html="record.content" />
      </a-space>
    </a-card>
  </div>
</template>

<script>
import { InboxOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { Splide } from "@splidejs/vue-splide";

export default {
  components: {
    Splide,
    PlusOutlined,
    InboxOutlined,
  },
  data() {
    return {
      formState: {
        title: null,
        content: "",
        employment_type: null,
        role_ids: [],
        position_ids: [],
        expiry_date: null,
        expiry_time: null,
        images: [],
      },
      moment,
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  async mounted() {
    this.getFormState();
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        title: this.record.title ?? null,
        content: this.record.content ?? "",
        employment_type: this.record?.employment_type ?? null,
        role_ids: this.record.roles?.map((i) => i.id) ?? [],
        position_ids: this.record?.employee_positions?.map((i) => i.id) ?? [],
        expiry_date: this.record.expiry_datetime
          ? moment(this.record.expiry_datetime)
          : null,
        expiry_time: this.record.expiry_datetime
          ? moment(this.record.expiry_datetime).format("HH:mm:ss")
          : null,
        images: this.record.id ? this.record.images : [],
      };
    },
  },
};
</script>
