<template>
  <a-form :model="model" layout="vertical" @finish="onSubmit">
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="drug"
          label="Drug"
          name="drug"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-select
            v-model:value="model.drug"
            style="width: 100%"
            show-search
            :options="medicationDrugs"
            @change="medicationDrugChange"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="Dose">
          <a-input-group compact>
            <a-form-item
              style="width: 75%"
              ref="dose"
              name="dose"
              :rules="[
                {
                  required: true,
                  message: 'This field is required!',
                },
                {
                  message: 'This field is required!',
                  type: 'number',
                  validator: $validation.decimal,
                  trigger: $constants.ruleTrigger,
                },
              ]"
            >
              <a-input-number
                type="number"
                :step="0.01"
                v-model:value="model.dose"
              />
            </a-form-item>
            <a-form-item style="width: 25%" ref="doseUnit" name="doseUnit">
              <a-input v-model:value="model.doseUnit" disabled />
            </a-form-item>
          </a-input-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="administered_by"
          label="Administered By"
          name="administered_by"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-radio-group v-model:value="model.administered_by">
            <a-radio value="Perfusion (CPB-Prime)">
              Perfusion (CPB-Prime)
            </a-radio>
            <a-radio value="Perfusion">Perfusion</a-radio>
            <a-radio value="Anesthesia">Anesthesia</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="lot_no"
          label="Lot No#"
          name="lot_no"
          :rules="{
            message: 'This field is required.',
          }"
        >
          <a-input v-model:value="model.lot_no" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="expiry_date"
          label="Expiry Date"
          name="expiry_date"
          :rules="{
            type: 'date',
            message: 'This field is required.',
          }"
        >
          <a-date-picker
            v-model:value="model.expiry_date"
            :placeholder="$constants.datePickerPlaceholder"
            :format="$constants.datepickerFormat"
            @change="$comman.pastDateWarningModal(model.expiry_date)"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      model: {
        date: null,
        time: "",
        drug: undefined,
        dose: null,
        doseUnit: undefined,
        administered_by: "",
        lot_no: null,
        expiry_date: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),

    timezone() {
      return this.extra?.timezone ?? this.$constants.timezone;
    },

    medicationDrugs() {
      return [
        ...this.$constants.medicationDrugs,
        { label: "Methylprednisolone", value: "Methylprednisolone", uom: "mg" },
      ].sort((a, b) => (a.label < b.label ? -1 : 1));
    },
  },

  mounted() {
    if (this.record.date) {
      this.model.date = moment(this.record.date, "MM-DD-YYYY");
      this.model.time = this.record.time;
      this.model.drug = this.record.drug;
      this.model.dose = this.record.dose;
      this.model.doseUnit = this.record.doseUnit;
      this.model.administered_by = this.record.administered_by;
      this.model.lot_no = this.record.lot_no;
      this.model.expiry_date = this.record.expiry_date
        ? moment(this.record.expiry_date, "MM-DD-YYYY")
        : null;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["close"]),

    medicationDrugChange(_, obj) {
      this.model.doseUnit = obj.uom;
    },

    onSubmit() {
      this.$emit("callback", this.model);
      this.close();
    },
  },
};
</script>
