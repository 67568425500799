export default function ({ next, store, roles, extra_access }) {
  let hasFinanceAccess = store.getters["auth/hasFinanceAccess"];

  if (hasFinanceAccess) {
    return next();
  }

  return next({
    name: "no-access",
  });
}
