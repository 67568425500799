<template>
  <div>
    <a-form :model="model" :rules="rules" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item
            ref="first_name"
            label="First Name"
            name="first_name"
            :validateStatus="validate('first_name', 'status')"
            :help="validate('first_name', 'msg')"
          >
            <a-input
              v-model:value="model.first_name"
              placeholder="Enter first name here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="last_name"
            label="Last Name"
            name="last_name"
            :validateStatus="validate('last_name', 'status')"
            :help="validate('last_name', 'msg')"
          >
            <a-input
              v-model:value="model.last_name"
              placeholder="Enter last name here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="company_name"
            label="Company Name"
            name="company_name"
            :validateStatus="validate('company_name', 'status')"
            :help="validate('company_name', 'msg')"
          >
            <a-input
              v-model:value="model.company_name"
              placeholder="Enter company name here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="sales_person_id"
            label="Sales Person"
            name="sales_person_id"
            :validateStatus="validate('sales_person_id', 'status')"
            :help="validate('sales_person_id', 'msg')"
          >
            <a-select
              v-model:value="model.sales_person_id"
              placeholder="Select sales person"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in salesPersons"
                :key="option.id"
                :label="option.full_name"
                :value="option.id"
              >
                {{ option.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="email"
            label="Email"
            name="email"
            :validateStatus="validate('email', 'status')"
            :help="validate('email', 'msg')"
          >
            <a-input
              v-model:value="model.email"
              placeholder="Enter email here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="contact_no"
            label="Contact No"
            name="contact_no"
            :validateStatus="validate('contact_no', 'status')"
            :help="validate('contact_no', 'msg')"
          >
            <a-input
              v-model:value="model.contact_no"
              v-maska="$constants.leadContactNumberMask"
              :placeholder="$constants.leadContactNumberPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="lead_category_id"
            label="Lead Category"
            name="lead_category_id"
            :validateStatus="validate('lead_category_id', 'status')"
            :help="validate('lead_category_id', 'msg')"
          >
            <a-select
              v-model:value="model.lead_category_id"
              placeholder="Select lead category"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in leadCategories"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="lead_source_id"
            label="Source"
            name="lead_source_id"
            :validateStatus="validate('lead_source_id', 'status')"
            :help="validate('lead_source_id', 'msg')"
          >
            <a-select
              v-model:value="model.lead_source_id"
              placeholder="Select source"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in leadSources"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="expected_close_date"
            label="Expected Closing Date"
            name="expected_close_date"
            :validateStatus="validate('expected_close_date', 'status')"
            :help="validate('expected_close_date', 'msg')"
          >
            <a-date-picker
              v-model:value="model.expected_close_date"
              :disabled-date="$comman.disabledPastDate"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            ref="expected_value"
            label="Est. Value"
            name="expected_value"
            :validateStatus="validate('expected_value', 'status')"
            :help="validate('expected_value', 'msg')"
          >
            <a-input-number
              v-model:value="model.expected_value"
              placeholder="Enter expected value here"
              :min="0"
              :step="0.01"
              :formatter="$comman.currencyformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="description"
            label="Description"
            name="description"
            :validateStatus="validate('description', 'status')"
            :help="validate('description', 'msg')"
          >
            <a-textarea
              v-model:value="model.description"
              placeholder="Enter description here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="attachment" label="Attachment" name="attachment">
            <a-upload
              :fileList="model.attachment"
              :remove="handleRemoveAttachment"
              :before-upload="beforeUploadAttachment"
              accept="application/pdf"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import { constants } from "../../../helper/constants";
import { validation } from "../../../helper/validation";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],

  components: {
    UploadOutlined,
  },

  data() {
    return {
      model: {},
      rules: {
        first_name: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        last_name: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        company_name: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        sales_person_id: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
          },
        ],
        email: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        contact_no: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        lead_source_id: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        lead_category_id: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
          },
        ],
        expected_value: [
          {
            message: "This field is required!",
            type: "number",
            min: 0,
            validator: validation.decimal,
          },
        ],
      },

      leadCategories: [],
      leadSources: [],
      salesPersons: [],
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.fetchLeadCategories();
    this.fetchLeadSources();
    this.fetchSalesPersons();
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.model = {
        first_name: this.record.first_name ?? "",
        last_name: this.record.last_name ?? "",
        company_name: this.record.company_name ?? "",
        lead_source_id: this.record.lead_source_id ?? undefined,
        email: this.record.email ?? "",
        contact_no: this.record.contact_no ?? "",
        lead_category_id: this.record.lead_category_id ?? undefined,
        expected_close_date: this.record.expected_close_date
          ? moment(this.record.expected_close_date)
          : null,
        expected_value: this.record.expected_value
          ? this.record.expected_value.toString()
          : "",
        description: this.record.description ?? "",
        sales_person_id: this.record.sales_person_id ?? undefined,
        attachment: this.record.attachment
          ? [
              {
                id: this.record.id,
                uid: this.record.attachment,
                name: this.record.attachment,
                url: this.record.attachment_file_path,
              },
            ]
          : [],
      };
    },

    onSubmit() {
      this.loadingStart();

      const formData = new FormData();
      if (this.record.id) {
        formData.append("id", this.record.id);
      }

      Object.keys(this.model).forEach((key) => {
        if (this.model[key] == null) {
          this.model[key] = "";
        }

        if (key == "attachment") {
          if (this.model[key][0]) {
            Object.values(this.model[key]).forEach((attach) => {
              if ("id" in attach) {
                formData.append(`old_${key}`, attach.id);
              } else {
                formData.append(`${key}`, attach);
              }
            });
          }
        } else {
          formData.append(key, this.model[key]);
        }
      });

      commonService
        .store(constants.crmLeadStoreUrl, formData)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.errors = err.errors;
          }
          console.log("error", err);
        })
        .finally(() => this.loadingStop());
    },

    fetchLeadCategories() {
      commonService.get(this.$constants.crmGetLeadCategoryUrl).then((res) => {
        this.leadCategories = res.data;
      });
    },

    fetchLeadSources() {
      commonService.get(this.$constants.crmGetLeadSourceUrl).then((res) => {
        this.leadSources = res.data;
      });
    },

    fetchSalesPersons() {
      commonService.get(this.$constants.crmGetSalesPersonsrl).then((res) => {
        this.salesPersons = res.data;
      });
    },

    handleRemoveAttachment() {
      this.model.attachment = [];
    },

    beforeUploadAttachment(file) {
      if (file.size < 5 * 1024 * 1024) {
        this.model.attachment = [file];
      } else {
        this.$message.error(`${file.name} size is more then 5MB`);
      }
      return false;
    },
  },
};
</script>
