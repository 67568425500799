<template>
  <a-popover
    v-model:visible="isVisible"
    trigger="click"
    overlayClassName="notif"
    placement="topRight"
  >
    <template #title>
      <a-space style="display: flex; justify-content: space-between">
        <span>Notifications</span>
        <router-link @click="isVisible = false" :to="{ name: 'notifications' }">
          See all
        </router-link>
      </a-space>
    </template>
    <template #content>
      <a-row
        :gutter="[0, 5]"
        justify="space-around"
        style="padding: 8px; width: 400px"
      >
        <template v-if="notifications.length">
          <a-col
            :span="24"
            v-for="(day, idx) in notificationsGrouped"
            :key="day"
          >
            <a-typography-text
              :level="6"
              style="margin: 0; padding: 0 6px; font-size: 13px !important"
              strong
            >
              {{ getRelativeTime(day.date) }}
            </a-typography-text>
            <a-list size="small" style="padding: 0; margin: 0" :split="false">
              <a-list-item
                v-for="notif in day.notifications"
                :key="notif.id"
                :style="{
                  backgroundColor: notif.read_at ? 'transparent' : 'aliceblue',
                  padding: '5px 6px !important',
                }"
              >
                <div class="message-item">
                  <a-typography-text
                    :strong="!notif.read_at"
                    style="width: 300px"
                  >
                    {{ notif.message }}
                  </a-typography-text>

                  <a-typography-text
                    :strong="!notif.read_at"
                    type="secondary"
                    style="font-size: 12px"
                  >
                    {{ formatTime(notif.created_at) }}
                  </a-typography-text>
                </div>
              </a-list-item>
            </a-list>
            <a-divider
              style="margin: 6px 0"
              v-if="idx !== notificationsGrouped.length - 1"
            />
          </a-col>
        </template>
      </a-row>
    </template>

    <a-badge :count="totalSystemNotifications">
      <a-button size="small" type="link" style="padding: 0">
        <i class="ti ti-bell" style="font-size: 1.5em !important" />
      </a-button>
    </a-badge>
  </a-popover>
</template>

<script setup>
// import { NotificationOutlined } from "@ant-design/icons-vue";

import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loading = ref(false);
const isVisible = ref(false);

const totalSystemNotifications = computed(
  () => store.getters["navbarCount/total_unread_system_notification_count"]
);

const notifications = ref([]);

const notificationsGrouped = computed(() => {
  return notifications.value.reduce((acc, notification) => {
    const date = moment(notification.created_at).format("YYYY-MM-DD");
    const group = acc.find((group) => group.date === date);

    if (group) {
      group.notifications.push(notification);
    } else {
      acc.push({ date, notifications: [notification] });
    }

    return acc;
  }, []);
});

onMounted(() => {});

function getAndResetNotificationCount() {
  loading.value = true;
  commonService
    .store(constants.systemNotifications, { page: 1, limit: 10 })
    .then((response) => {
      // const unreadNotifs = data.filter((notification) => !notification.read_at);
      notifications.value = response.data.data.data;

      if (!notifications.value.length) return;

      commonService.store(constants.systemNotificationMarkAsRead).then(() => {
        store.dispatch("navbarCount/setCountForKey", {
          key: "total_unread_system_notification_count",
          value: 0,
        });
      });
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
}

function formatTime(time) {
  return moment(time).format("hh:mm A");
}

function getRelativeTime(date) {
  const diffInDays = moment().diff(date, "days");

  let relativeTime;
  if (diffInDays === 0) {
    // Today
    relativeTime = "Today";
  } else if (diffInDays === 1) {
    // Yesterday
    relativeTime = "Yesterday";
  } else if (diffInDays < 0) {
    // Future date
    relativeTime = `in ${Math.abs(diffInDays)} days`;
  } else if (diffInDays > 2) {
    relativeTime = moment(date).format("MMM DD, YYYY");
  } else {
    relativeTime = `${Math.abs(diffInDays)} days ago`;
  }

  return relativeTime;
}

watch(isVisible, (value) => {
  console.log({ value });
  if (value) {
    getAndResetNotificationCount();
  } else {
    notifications.value = notifications.value.map((notif) => {
      notif.read_at = true;
      return notif;
    });
  }
});
</script>

<style lang="less">
.notif {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.message-item {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}
</style>
