<template>
  <a-row :gutter="[20, 0]">
    <!-- Scheduled OR -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item label="Scheduled OR Date" name="dbdor_2_scheduled_or_date">
        <a-date-picker
          v-model:value="model.dbdor_2_scheduled_or_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item label="Scheduled OR Time" name="dbdor_2_scheduled_or_time">
        <a-input
          v-model:value="model.dbdor_2_scheduled_or_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS Team flight mission take off -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team flight mission take off Date"
        name="dbdor_2_kps_team_flight_mission_take_off_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_kps_team_flight_mission_take_off_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team flight mission take off Time"
        name="dbdor_2_kps_team_flight_mission_take_off_time"
      >
        <a-input
          v-model:value="model.dbdor_2_kps_team_flight_mission_take_off_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS Team initial touch down -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team initial touch down Date"
        name="dbdor_2_kps_team_initial_touch_down_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_kps_team_initial_touch_down_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS Team initial touch down Time"
        name="dbdor_2_kps_team_initial_touch_down_time"
      >
        <a-input
          v-model:value="model.dbdor_2_kps_team_initial_touch_down_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS team arrival at donor facility -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team arrival at donor facility Date"
        name="dbdor_2_kps_team_arrival_at_donor_facility_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_kps_team_arrival_at_donor_facility_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team arrival at donor facility Time"
        name="dbdor_2_kps_team_arrival_at_donor_facility_time"
      >
        <a-input
          v-model:value="model.dbdor_2_kps_team_arrival_at_donor_facility_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS setup complete -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS setup complete Date"
        name="dbdor_2_kps_setup_complete_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_kps_setup_complete_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS setup complete Time"
        name="dbdor_2_kps_setup_complete_time"
      >
        <a-input
          v-model:value="model.dbdor_2_kps_setup_complete_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- OR OPO personnel in room -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="OR OPO personnel in room Date"
        name="dbdor_2_or_opo_personnel_in_room_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_or_opo_personnel_in_room_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="OR OPO personnel in room Time"
        name="dbdor_2_or_opo_personnel_in_room_time"
      >
        <a-input
          v-model:value="model.dbdor_2_or_opo_personnel_in_room_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- Donor in room -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item label="Donor in room Date" name="dbdor_2_donor_in_room_date">
        <a-date-picker
          v-model:value="model.dbdor_2_donor_in_room_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item label="Donor in room Time" name="dbdor_2_donor_in_room_time">
        <a-input
          v-model:value="model.dbdor_2_donor_in_room_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS team donor facility departure -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team donor facility departure Date"
        name="dbdor_2_kps_team_donor_facility_departure_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_kps_team_donor_facility_departure_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team donor facility departure Time"
        name="dbdor_2_kps_team_donor_facility_departure_time"
      >
        <a-input
          v-model:value="model.dbdor_2_kps_team_donor_facility_departure_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS team wheels up to recipient facility -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team wheels up to recipient facility Date"
        name="dbdor_2_kps_team_wheels_up_to_recipient_facility_date"
      >
        <a-date-picker
          v-model:value="
            model.dbdor_2_kps_team_wheels_up_to_recipient_facility_date
          "
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team wheels up to recipient facility Time"
        name="dbdor_2_kps_team_wheels_up_to_recipient_facility_time"
      >
        <a-input
          v-model:value="
            model.dbdor_2_kps_team_wheels_up_to_recipient_facility_time
          "
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS team destination touch down -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team destination touch down Date"
        name="dbdor_2_kps_team_destination_touch_down_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_kps_team_destination_touch_down_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team destination touch down Time"
        name="dbdor_2_kps_team_destination_touch_down_time"
      >
        <a-input
          v-model:value="model.dbdor_2_kps_team_destination_touch_down_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- KPS team arrival at recipient facility -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team arrival at recipient facility Date"
        name="dbdor_2_kps_team_arrival_at_recipient_facility_date"
      >
        <a-date-picker
          v-model:value="
            model.dbdor_2_kps_team_arrival_at_recipient_facility_date
          "
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="KPS team arrival at recipient facility Time"
        name="dbdor_2_kps_team_arrival_at_recipient_facility_time"
      >
        <a-input
          v-model:value="
            model.dbdor_2_kps_team_arrival_at_recipient_facility_time
          "
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>

    <!-- Hand off complete -->
    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Hand off complete Date"
        name="dbdor_2_hand_off_complete_date"
      >
        <a-date-picker
          v-model:value="model.dbdor_2_hand_off_complete_date"
          :disabled-date="$comman.disabledFutureDate"
          :format="$constants.datepickerFormat"
          :placeholder="$constants.datePickerPlaceholder"
          allowClear
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :sm="12" :md="6">
      <a-form-item
        label="Hand off complete Time"
        name="dbdor_2_hand_off_complete_time"
      >
        <a-input
          v-model:value="model.dbdor_2_hand_off_complete_time"
          v-maska="$constants.timeWithSecMaska"
          placeholder="HH:mm:ss"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "organ-recovery-procedure-info-step",
  inheritAttrs: false,
  props: ["step", "model", "record"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  mounted() {
    //
  },

  computed: {
    //
  },

  methods: {
    //
  },
};
</script>
