let url = "https://apistage.insperity.com/public";
let apikey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJBZGVwdGlhSW5jIiwiYXVkIjoiMDEwMDA0MTQ3MDE5MTQ2MDc0NzU1NTUwNTAwMDEzIiwic3ViIjoie1wiQXBpU2VjdXJpdHlLZXlcIjpcIjE0NTZmNWFlLTI2NGMtNDY5Zi04OTc5LWNhMzhhZjdmYWFhZVwifSIsImV4cCI6MTY1MDM0NDQwMH0.axZic085Aa-DsjlPfOGFI4b5g-2MA0oR_VkDTQ1J0hM";

export const EMPLOYER_IDENTIFIER = 4650400;

// GET URLS
export const WORK_LOCATION = (company_id = EMPLOYER_IDENTIFIER) =>
  `${url}/company/${company_id}/worksitelocations/v1?apikey=${apikey}`;
export const REPORTING_LOCATION = (company_id = EMPLOYER_IDENTIFIER) =>
  `${url}/company/${company_id}/reportinglocations/v1?apikey=${apikey}`;
export const DELIVERY_LOCATION = (company_id = EMPLOYER_IDENTIFIER) =>
  `${url}/company/${company_id}/deliverylocations/v1?apikey=${apikey}`;
export const WORKERS_COMP_CODE = (company_id = EMPLOYER_IDENTIFIER) =>
  `${url}/company/${company_id}/workerscompcode/v1?apikey=${apikey}`;
export const JOB_FUNCTIONS = (company_id = EMPLOYER_IDENTIFIER) =>
  `${url}/company/${company_id}/jobfunctions/v1?apikey=${apikey}`;
export const JOB_CATEGORIES = (company_id = EMPLOYER_IDENTIFIER) =>
  `${url}/company/${company_id}/jobcategories/v1?apikey=${apikey}`;

// POST URLS
export const ONBOARDING = () =>
  `${url}/employee/onboarding/v2?apikey=${apikey}`;
