import Pusher from "pusher-js";
import { store } from "@/store";
import { api, PUSHER_API_KEY, PUSHER_APP_CLUSTER } from "../config/config";
import axios from "axios";

let token = store.getters["auth/token"];

let authorizer = (channel, options) => {
  return {
    authorize: (socketId, callback) => {
      axios
        .post(
          api.host + "api/broadcasting/auth",
          {
            socket_id: socketId,
            channel_name: channel.name,
          },
          {
            headers: {
              // "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Access-Control-Allow-Credentials": true,
              "X-XSRF-TOKEN": "123",
              withCredentials: true,
            },
          }
        )
        .then((res) => {
          callback(false, res.data);
        })
        .catch((err) => {
          callback(true, err);
        });
    },
  };
};

export const pusher = new Pusher(PUSHER_API_KEY, {
  cluster: PUSHER_APP_CLUSTER,
  authorizer: authorizer,
  encrypted: true,
  // authEndpoint: api.host + "broadcasting/auth",
  // auth: {
  //   headers: { Authorization: "Bearer " + token },
  // },
});
