<template>
  <a-timeline>
    <a-timeline-item v-for="item in record.case_report_ftp_logs" :key="item.id">
      {{ $customDate.mdyhis(item.created_at) }}
      <br />
      <strong>By:</strong> {{ item?.employee?.full_name ?? "N/A" }}
      <br />
      <a-tag v-if="item.error" color="error">Error ({{ item.error }})</a-tag>
      <a-tag v-else color="success">Successful</a-tag>
    </a-timeline-item>
  </a-timeline>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["callback"],

  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
  },
};
</script>
