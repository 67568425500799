import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/cell-saver-quality-controls",
    name: "cell-saver-quality-control",
    component: route("cellSaverQC/index"),
    meta: {
      title: "Cell Saver Quality Controls",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/cell-saver-quality-control/create",
    name: "create-cell-saver-quality-control",
    component: route("cellSaverQC/store"),
    meta: {
      title: "Create Cell Saver Quality Control",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/cell-saver-quality-control/edit/:id",
    name: "edit-cell-saver-quality-control",
    component: route("cellSaverQC/store"),
    meta: {
      title: "Edit Cell Saver Quality Control",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/cell-saver-quality-control/show/:id",
    name: "show-cell-saver-quality-control",
    component: route("cellSaverQC/show"),
    meta: {
      title: "Show Cell Saver Quality Control",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
