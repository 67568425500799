<template>
  <div>
    <a-form
      name="company_form"
      ref="company_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="email" label="Email" name="email">
            <a-input
              v-model:value="formState.email"
              placeholder="Enter email here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="address_line_1"
            label="Address Line 1"
            name="address_line_1"
          >
            <a-input
              v-model:value="formState.address_line_1"
              placeholder="Enter address line 1 here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="address_line_2"
            label="Address Line 2"
            name="address_line_2"
          >
            <a-input
              v-model:value="formState.address_line_2"
              placeholder="Enter address line 2 here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="city" label="City" name="city">
            <a-input
              v-model:value="formState.city"
              placeholder="Enter city here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="state" label="State" name="state">
            <a-select
              v-model:value="formState.state"
              placeholder="Select state"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="state in $constants.states"
                :key="state.name"
                :label="state.name"
                :value="state.name"
              >
                {{ state.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="zipcode" label="Zipcode" name="zipcode">
            <a-input
              v-model:value="formState.zipcode"
              placeholder="Enter zipcode here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="website" label="Website" name="website">
            <a-input
              v-model:value="formState.website"
              placeholder="Enter website here"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="contact_no"
            label="Contact Number"
            name="contact_no"
          >
            <a-input
              v-model:value="formState.contact_no"
              v-maska="$constants.contactNumberMask"
              :placeholder="$constants.contactNumberPlaceholder"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="fax_no" label="Fax Number" name="fax_no">
            <a-input
              v-model:value="formState.fax_no"
              v-maska="$constants.contactNumberMask"
              :placeholder="$constants.contactNumberPlaceholder"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :lg="12">
          <a-form-item ref="logo" label="Logo" name="logo">
            <a-upload
                :file-list="fileList"
                :before-upload="(file) => beforeUpload(file)"
                @change="({ file }) => onFileAdd(file, 'image/jpeg,image/png')"
                accept="image/jpeg,image/png"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img
                alt="company image"
                style="width: 100%"
                :src="previewImage"
              />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { UploadOutlined } from "@ant-design/icons-vue";
import { api } from "@/config/config";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    UploadOutlined,
  },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImage: "",
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        email: [
          {
            type: "email",
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        address_line_1: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        city: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        state: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        zipcode: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: this.$validation.numeric,
            trigger: this.$constants.ruleTrigger,
          },
        ],
        website: [
          {
            type: "url",
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },
  created() {
    if (this.record.logo != null) {
      this.fileList = [
        {
          uid: this.record.id,
          status: "Done",
          url: this.record.logo_full_path,
          thumbUrl: this.record.logo_full_path,
          name: this.record.logo_file_name,
        },
      ];
    }
  },
  mounted() {
    this.getFormState();
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        email: this.record.email ?? "",
        address_line_1: this.record.address_line_1 ?? "",
        address_line_2: this.record.address_line_2 ?? "",
        city: this.record.city ?? "",
        state: this.record.state ?? undefined,
        zipcode: this.record.zipcode ?? "",
        website: this.record.website ?? "",
        contact_no: this.record.contact_no ?? "",
        fax_no: this.record.fax_no ?? "",
      };
    },
    onSubmit() {
      this.$refs.company_form
        .validate()
        .then((val) => {
          val.image = this.fileList;
          if (this.record.id) {
            val.id = this.record.id;
          }
          const formData = new FormData();
          Object.keys(val).forEach((key) => {
            if (key === "image") {
              if (this.fileList.some((v) => v.status === "Done")) {
                formData.append("old_image", this.fileList[0].uid);
              } else {
                this.fileList.forEach((v) => {
                  formData.append("image", v);
                });
              }
            } else {
              formData.append(key, val[key]);
            }
          });
          this.loadingStart();
          companyService
            .store(this.$constants.companyStoreUrl, formData, true)
            .then((res) => {
              this.loadingStart();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    beforeUpload(file) {
      const items = this.fileList;
      items.forEach(() => items.pop());
      items.push(file);
      return false;
    },
    onFileAdd(file, types) {
      const items = this.fileList;
      if (!items) return;
      const acceptedTypes = types.split(",");

      if (!acceptedTypes.includes(file.type)) {
        this.$message.error(`File type of ${file.type} is not supported.`);
        items.pop();
        return;
      }

      if (file.size / 1024 / 1024 > 5) {
        this.$message.error(`File size of ${file.name} is too large. Max: 5MB`);
        items.pop();
        return false;
      }
    },
  },
};
</script>

<style></style>
