<template>
  <a-card size="small" title="Cart">
    <template v-if="cartProducts.length > 0">
      <a-row
        v-for="(item, index) in cartProducts"
        :key="item.id"
        align="middle"
        justify="space-between"
      >
        <a-col :span="18">
          <p class="danger-color">{{ item.name }}</p>
          <p>Price: {{ $comman.priceFilter(item.price) }}</p>
          <a-input v-model:value="item.qty">
            <template #addonBefore>
              <i
                v-if="item.qty == 1"
                class="fas fa-trash danger-color"
                @click="decreaseProductQty({ item, index })"
              ></i>
              <i
                v-else
                class="fas fa-minus"
                @click="decreaseProductQty({ item, index })"
              ></i>
            </template>
            <template #addonAfter>
              <i
                class="fas fa-plus"
                @click="increaseProductQty({ item, index })"
              ></i>
            </template>
          </a-input>
        </a-col>
        <a-col :span="6">
          <a-button
            type="link"
            class="danger-color"
            @click="removeProductFromCart({ index })"
          >
            <i class="fas fa-trash"></i>
          </a-button>
          <h3>
            {{
              $comman.priceFilter(parseFloat(item.price * item.qty).toFixed(2))
            }}
          </h3>
        </a-col>
      </a-row>
      <a-divider />
      <a-row justify="space-between">
        <h2>Subtotal:</h2>
        <h2>{{ $comman.priceFilter(cartSubtotal) }}</h2>
      </a-row>
      <router-link :to="{ name: 'checkout-order' }">
        <a-button type="danger" block>Submit Order</a-button>
      </router-link>
    </template>
    <template v-else>
      <a-empty />
    </template>
  </a-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("cart", ["cartProducts", "cartSubtotal"]),
  },
  methods: {
    ...mapActions("cart", [
      "decreaseProductQty",
      "increaseProductQty",
      "removeProductFromCart",
    ]),
  },
  watch: {
    cartProducts(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
.ant-input-group-wrapper {
  width: 125px !important;
}
</style>
