<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-form-item
        ref="washed_rbcs_reinfused"
        label="Washed RBCs Reinfused (mLs)"
        name="washed_rbcs_reinfused"
        :validateStatus="validate('washed_rbcs_reinfused', 'status')"
        :help="validate('washed_rbcs_reinfused', 'msg')"
      >
        <a-input
          v-model:value="model.washed_rbcs_reinfused"
          placeholder="Enter washed rbcs reinfused mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="type_of_anticoagulant_used"
        label="Type of Anticoagulant Used"
        name="type_of_anticoagulant_used"
        :validateStatus="validate('type_of_anticoagulant_used', 'status')"
        :help="validate('type_of_anticoagulant_used', 'msg')"
      >
        <a-select
          v-model:value="model.type_of_anticoagulant_used"
          placeholder="Select anticoagulant used type"
          show-search
        >
          <a-select-option
            v-for="anticoagulantType in $constants.caseServiceAnticoagulantTypes"
            :key="anticoagulantType"
            :value="anticoagulantType"
          >
            {{ anticoagulantType }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="total_processed_volumn"
        label="Total Processed Volume (mLs)"
        name="total_processed_volumn"
        :validateStatus="validate('total_processed_volumn', 'status')"
        :help="validate('total_processed_volumn', 'msg')"
      >
        <a-input
          v-model:value="model.total_processed_volumn"
          placeholder="Enter total processed volumn in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="anticoagulant_volumn_used"
        label="Anticoagulation Volume Used (mLs)"
        name="anticoagulant_volumn_used"
        :validateStatus="validate('anticoagulant_volumn_used', 'status')"
        :help="validate('anticoagulant_volumn_used', 'msg')"
      >
        <a-input
          v-model:value="model.anticoagulant_volumn_used"
          placeholder="Enter anticoagulant volumn used in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="wash_volumn_used"
        label="Wash Volume Used (mLs)"
        name="wash_volumn_used"
        :validateStatus="validate('wash_volumn_used', 'status')"
        :help="validate('wash_volumn_used', 'msg')"
      >
        <a-input
          v-model:value="model.wash_volumn_used"
          placeholder="Enter wash volumn used in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="estimated_blood_loss"
        label="Estimated Blood Loss (EBL)"
        name="estimated_blood_loss"
        :validateStatus="validate('estimated_blood_loss', 'status')"
        :help="validate('estimated_blood_loss', 'msg')"
      >
        <template #extra>
          <b>
            Calculation: ((Bowl volume/2) / avg hct expressed as a fraction)) X
            # of Bowls returned to the Pt.
          </b>
        </template>

        <a-input
          v-model:value="model.estimated_blood_loss"
          placeholder="Enter estimated blood loss here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="clear_effluent_wash_line"
        label="Clear Effluent Wash Line"
        name="clear_effluent_wash_line"
        :validateStatus="validate('clear_effluent_wash_line', 'status')"
        :help="validate('clear_effluent_wash_line', 'msg')"
      >
        <a-select
          v-model:value="model.clear_effluent_wash_line"
          placeholder="Select any one"
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col v-if="washedRbcsReinfusedIs0" :span="12">
      <a-form-item
        ref="autologous_blood_transfused_within_4_hours"
        label="Autologous Blood Transfused within 4 Hours"
        name="autologous_blood_transfused_within_4_hours"
        :validateStatus="
          validate('autologous_blood_transfused_within_4_hours', 'status')
        "
        :help="validate('autologous_blood_transfused_within_4_hours', 'msg')"
      >
        <a-select
          v-model:value="model.autologous_blood_transfused_within_4_hours"
          placeholder="Select any one"
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col v-if="hospitalIdIs(30)" :span="12">
      <a-form-item
        ref="cell_saver_hct"
        label="Cell Saver HCT"
        name="cell_saver_hct"
        :validateStatus="validate('cell_saver_hct', 'status')"
        :help="validate('cell_saver_hct', 'msg')"
      >
        <a-input
          v-model:value="model.cell_saver_hct"
          placeholder="Enter cell saver hct here"
        />
      </a-form-item>
    </a-col>

    <a-col v-if="hospitalIdIs(30)" :span="12">
      <a-form-item
        ref="cell_saver_device_serial_number"
        label="Cell Saver Device Serial Number"
        name="cell_saver_device_serial_number"
        :validateStatus="validate('cell_saver_device_serial_number', 'status')"
        :help="validate('cell_saver_device_serial_number', 'msg')"
      >
        <a-select
          v-model:value="model.cell_saver_device_serial_number"
          placeholder="Select cell saver device serial number"
          show-search
        >
          <a-select-option
            v-for="cellSaverDeviceSerialNumber in $constants.cellSaverDeviceSerialNumbers"
            :key="cellSaverDeviceSerialNumber"
            :value="cellSaverDeviceSerialNumber"
          >
            {{ cellSaverDeviceSerialNumber }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col v-if="!hospitalIdIs(30)" :span="12">
      <a-form-item
        ref="cell_saver_device_serial_number"
        label="Cell Saver Device Serial Number"
        name="cell_saver_device_serial_number"
        :validateStatus="validate('cell_saver_device_serial_number', 'status')"
        :help="validate('cell_saver_device_serial_number', 'msg')"
      >
        <a-input
          v-model:value="model.cell_saver_device_serial_number"
          placeholder="Enter cell saver device serial number here"
        />
      </a-form-item>
    </a-col>

    <a-col v-if="washedRbcsReinfusedIs0" :span="12">
      <a-form-item
        ref="was_a_qc_sample_obtained_for_this_procedure"
        label="Was a QC sample obtained for this procedure?"
        name="was_a_qc_sample_obtained_for_this_procedure"
        :validateStatus="
          validate('was_a_qc_sample_obtained_for_this_procedure', 'status')
        "
        :help="validate('was_a_qc_sample_obtained_for_this_procedure', 'msg')"
      >
        <a-select
          v-model:value="model.was_a_qc_sample_obtained_for_this_procedure"
          placeholder="Select any one"
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <template v-if="wasAQcSampleObtainedForThisProcedureIsYes">
      <a-col :span="24">
        <b>Cell Saver QC Results</b>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="cell_saver_qc_results_source"
          label="Source"
          name="cell_saver_qc_results_source"
          :validateStatus="validate('cell_saver_qc_results_source', 'status')"
          :help="validate('cell_saver_qc_results_source', 'msg')"
        >
          <template #extra>
            <b class="danger-color">
              Add the cell saver QC results
            </b>
          </template>

          <a-input
            v-model:value="model.cell_saver_qc_results_source"
            placeholder="Enter source here"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="cell_saver_qc_results_parameter"
          label="Parameter"
          name="cell_saver_qc_results_parameter"
          :validateStatus="
            validate('cell_saver_qc_results_parameter', 'status')
          "
          :help="validate('cell_saver_qc_results_parameter', 'msg')"
        >
          <a-input
            v-model:value="model.cell_saver_qc_results_parameter"
            placeholder="Enter parameter here"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="cell_saver_qc_results_result"
          label="Result"
          name="cell_saver_qc_results_result"
          :validateStatus="validate('cell_saver_qc_results_result', 'status')"
          :help="validate('cell_saver_qc_results_result', 'msg')"
        >
          <a-input
            v-model:value="model.cell_saver_qc_results_result"
            placeholder="Enter result here"
          />
        </a-form-item>
      </a-col>
    </template>
  </a-row>
</template>

<script>
export default {
  props: {
    model: { require: true, type: Object },
    wasAQcSampleObtainedForThisProcedureIsYes: { require: true, type: Boolean },
    washedRbcsReinfusedIs0: { require: true, type: Boolean },
  },

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },

    hospitalIdIs(params) {
      if (typeof params == "number") {
        params = [params];
      }

      return params.includes(this.model.hospital_id);
    },
  },
};
</script>
