<template>
  <div class="auto-qi-report-mail-logs">
    <a-descriptions
      :column="2"
      :labelStyle="{ width: '150px' }"
      bordered
      size="small"
      class="mb-sm-1"
    >
      <a-descriptions-item label="Hospital" :span="2">
        {{ record?.hospital?.name }}
      </a-descriptions-item>
      <a-descriptions-item label="Type">
        {{
          $comman.getValueFromObject(
            record,
            "type",
            $constants.qi_report_notification_types,
            "label"
          )
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Frequency">
        {{
          $comman.getValueFromObject(
            record,
            "frequency",
            $constants.qi_report_frequency_types,
            "label"
          )
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Start Date">
        {{ $customDate.mdy(record.start_date) }}
      </a-descriptions-item>
      <a-descriptions-item label="End Date">
        {{ $customDate.mdy(record.end_date) }}
      </a-descriptions-item>
    </a-descriptions>

    <a-divider></a-divider>

    <template v-for="log in record?.mail_logs" :key="log.id">
      <a-descriptions
        :column="1"
        :labelStyle="{ width: '250px' }"
        bordered
        size="small"
        class="mb-sm-1"
      >
        <a-descriptions-item label="Email">
          {{ log.to }}
        </a-descriptions-item>
      </a-descriptions>

      <mail-log :record="filterRecord(log)" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mailLog from "../../../components/mailLog.vue";

export default {
  emits: ["callback"],

  components: { mailLog },

  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    filterRecord(rec) {
      let data = JSON.parse(JSON.stringify(this.record));
      data.mail_logs = data.mail_logs.filter((e) => e.id == rec.id);
      return data;
    },
  },
};
</script>
