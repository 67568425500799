<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Edit Email Templates"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-form
      ref="checkoutForm"
      :layout="formLayout"
      :model="email_template"
      :rules="rules"
      @finish="handleFinish"
    >
      <a-card class="mb-sm-2">
        <a-row :gutter="[20, 0]">
          <a-col :span="24">
            <a-form-item ref="name" label="Name" name="name">
              <a-input
                v-model:value="email_template.name"
                placeholder="Enter name here"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="24">
            <a-form-item ref="value" label="Template" name="value">
              <ckeditor
                :editor="editor"
                v-model="email_template.value"
                :config="editorConfig"
              />

              <template v-for="tempVar in templateVariables" :key="tempVar">
                <a-tooltip title="Click here for copy">
                  <span
                    @click="textCopy(tempVar)"
                    class="bg-light-danger mr-sm-1 cursor-pointer"
                  >
                    {{ tempVar }}
                  </span>
                </a-tooltip>
              </template>
            </a-form-item>
          </a-col>
        </a-row>
        <br />
        <br />
        <a-button type="primary" html-type="submit">Update</a-button>
      </a-card>
    </a-form>
  </a-layout-content>
</template>

<script>
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { emailTemplateRules } from "../../helper/formRules";
import router from "../../router";
import BackButton from "../../components/backButton.vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { commonService } from "../../services";

export default {
  components: { BackButton },
  data() {
    return {
      formLayout: "vertical",
      rules: emailTemplateRules,
      email_template: {
        name: "",
        value: null,
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
      },
      templateVariables: [
        "{{ order_no }}",
        "{{ order_by }}",
        "{{ company_name }}",
        "{{ hospital_name }}",
        "{{ products_table }}",
        "{{ sent_by }}",
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    loadData() {
      commonService
        .get(this.$constants.emailTemplateViewUrl, { id: this.id })
        .then((res) => {
          console.log(res.data);
          this.email_template.name = res.data.name;
          this.email_template.value = res.data.value ? res.data.value : "";
        })
        .catch((err) => this.$message.error(err));
    },
    handleFinish() {
      commonService
        .store(this.$constants.emailTemplateStoreUrl, {
          name: this.email_template.name,
          value: this.email_template.value,
          id: this.id,
        })
        .then((res) => {
          this.$message.success(res.message);
          router.push({ name: "email-templates" });
        })
        .catch((err) => this.$message.error(err));
    },
    textCopy(text) {
      toClipboard(text);
      this.$message.success(`${text} copied successfully.`);
    },
  },
};
</script>
