<template>
  <a-layout-content>
    <page-header>
      <template #title> Credentialing Approvals </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #attachment="{ record }">
          <template v-for="(item, idx) in record.attachments" :key="item.id">
            <a-typography-link :href="item.url" target="_blank">
              {{ item.file_name_with_ext }}
            </a-typography-link>
            <br v-if="idx !== record.attachments.length - 1" />
          </template>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="Edit">
              <a-button type="link" @click="editCredential(record)">
                <template #icon>
                  <i class="ti ti-pencil" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to approve this credential?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="() => approveCredential(record, true)"
            >
              <a-tooltip title="Approve">
                <a-button
                  type="link"
                  :loading="record.approveLoading"
                  :disabled="record.approveDisabled"
                >
                  <template #icon>
                    <i class="ti ti-checkbox" style="font-size: 18px"></i>
                  </template>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <a-tooltip title="Reject">
              <a-button
                type="link"
                :loading="record.rejectLoading"
                :disabled="record.rejectDisabled"
                style="color: #d4380d"
                @click="approveCredential(record, false)"
              >
                <template #icon>
                  <i class="ti ti-circle-x" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.credentialApprovalsDatatableUrl,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee.full_name",
        },
        {
          title: "Certificate",
          dataIndex: "certification_type.name",
        },
        {
          title: "Expires On",
          dataIndex: "expiry_date",
          customRender: ({ text }) => {
            return this.$customDate.ll(text);
          },
        },
        {
          title: "Attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    editCredential(record) {
      this.open({
        title: "Edit Credential",
        path: "admin.approvals.edit",
        callback: this.refresh,
        record,
      });
    },

    approveCredential(record, isApprove) {
      if (isApprove) {
        record.approveLoading = true;
        record.rejectDisabled = true;
      } else {
        this.open({
          title: "Credential Rejection Remarks",
          path: "admin.approvals.reject",
          callback: this.refresh,
          record,
        });

        return;
      }
      commonService
        .store(this.$constants.credentialApprovalsApprovalUrl, {
          id: record.id,
          approval_status: 1,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.refresh();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          record.approveLoading = false;
          record.rejectLoading = false;
          record.approveDisabled = false;
          record.rejectDisabled = false;
        });
    },
  },
};
</script>
