<template>
  <a-layout-content>
    <page-header>
      <template #title> Videos </template>

      <template #buttons>
        <a-button v-if="$comman.hasRole(1)" type="primary" @click="addVideo">
          <a-space> <i class="ti ti-plus ti-lg"></i> Add </a-space>
        </a-button>
      </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col :span="24">
        <a-input-search
          v-model:value="searchTitle"
          placeholder="Search video"
          enter-button
          @search="fetchVideos"
        />
      </a-col>

      <template v-if="loading">
        <a-col
          :md="12"
          :lg="8"
          :xxl="6"
          v-for="video in [1, 2, 3, 4, 5, 6]"
          :key="video"
        >
          <a-card size="small" loading hoverable />
        </a-col>
      </template>

      <template v-else>
        <template v-if="videos.length > 0">
          <a-col
            :md="12"
            :lg="8"
            :xxl="6"
            v-for="video in videos"
            :key="video.id"
          >
            <a-card size="small" hoverable>
              <template #cover>
                <span v-html="video.embed_code" />
              </template>
              <template v-if="$comman.hasRole(1)" #actions>
                <a-tooltip title="Edit">
                <a-button type="link" size="small" @click="editVideo(video)">
                  <i class="ti ti-pencil"></i>
                </a-button>
              </a-tooltip>
                <a-popconfirm
                  title="Are you sure you want to delete this video?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteVideo(video)"
                >
                <a-tooltip title="Delete">
                  <a-button type="link" size="small" danger>
                    <i class="ti ti-trash"></i>
                  </a-button>
                </a-tooltip>
                </a-popconfirm>
              </template>
              <a-card-meta :title="video.title" />
            </a-card>
          </a-col>
        </template>

        <template v-else>
          <a-col :span="24">
            <a-card style="text-align: center">
              <a-empty />
            </a-card>
          </a-col>
        </template>
      </template>
    </a-row>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      loading: false,
      searchTitle: "",
      videos: [],
    };
  },

  mounted() {
    this.fetchVideos();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchVideos() {
      this.loading = true;
      commonService
        .get(constants.kbVideoUrl, { title: this.searchTitle })
        .then((res) => {
          this.videos = res.data;
        })
        .finally(() => (this.loading = false));
    },

    addVideo() {
      this.open({
        title: "Add Video",
        path: "knowledgeBase.videos.store",
        callback: this.fetchVideos,
        record: null,
      });
    },

    editVideo(video) {
      this.open({
        title: "Edit Video",
        path: "knowledgeBase.videos.store",
        callback: this.fetchVideos,
        record: video,
      });
    },

    deleteVideo(video) {
      commonService
        .get(constants.kbVideoDeleteUrl, { id: video.id })
        .then(() => {
          this.fetchVideos();
        });
    },
  },
};
</script>
