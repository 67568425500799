<template>
  <a-layout-content>
    <page-header>
      <template #title> Profile Setup</template>
    </page-header>

    <a-row :gutter="[0, 40]">
      <a-col :span="24">
        <a-steps progressDot status="process" v-model:current="currentStep">
          <template v-for="(step, idx) in steps" :key="step.key">
            <a-step
              :title="step.title"
              :disabled="
                !steps[idx - 1]?.completeCondition &&
                !steps[idx].completeCondition
              "
            />
          </template>
        </a-steps>
      </a-col>

      <a-col :span="24">
        <a-row justify="space-around">
          <a-col
            :sm="24"
            :xxl="
              ['policy-rules', 'set-password'].includes(currentStepData.key)
                ? 16
                : 21
            "
          >
            <a-card size="small" hoverable :loading="loading">
              <keep-alive>
                <component
                  v-model:currentStep="currentStep"
                  v-model:logs="logs"
                  :steps="steps"
                  :is="currentStepData.component"
                  :companies="companies"
                  :onbDocsLoading="onbDocsLoading"
                />
              </keep-alive>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script setup>
import { comman as commonFunctions } from "@/helper/comman";
import { constants } from "@/helper/constants";
import { commonService, companyService } from "@/services";
import { markRaw } from "@vue/reactivity";
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
BasicDetails,
Credentials,
Final,
FormI9,
FormW9,
OnboardingDocs,
SetPassword,
Welcome,
} from "./steps";

const store = useStore();
const user = computed(() => store.getters["auth/user"]);
const employee = computed(() => store.getters["auth/employee"]);
const profileStatus = computed(() => store.getters["auth/profileStatus"]);
const formI9 = computed(() => store.getters["auth/formI9"]);
const formW9 = computed(() => store.getters["auth/formW9"]);
const isContractor = computed(() => employee.value?.employment_type === 2);

const currentStep = ref(0);
const steps = computed(() => [
  {
    title: "Welcome",
    key: "welcome",
    component: markRaw(Welcome),
  },
  ...(!hasSetPassword.value
    ? [
        {
          title: "Set Password",
          key: "set-password",
          component: markRaw(SetPassword),
          // completeCondition: true,
          completeCondition: hasSetPassword.value,
        },
      ]
    : []),
  {
    title: "Basic Details",
    key: "basicDetails",
    component: markRaw(BasicDetails),
    completeCondition: hasBasicDetailsFilled.value,
  },
  ...(!hasFormI9Approved.value
    ? [
        {
          title: "Form I-9",
          key: "formI9",
          component: markRaw(FormI9),
          completeCondition: hasFormI9Filled.value,
        },
      ]
    : []),
  ...(isContractor.value && !hasFormW9Approved.value
    ? [
        {
          title: "Form W-9",
          key: "formW9",
          component: markRaw(FormW9),
          completeCondition: hasFormW9Filled.value,
        },
      ]
    : []),
  ...(!isOnboardingDocsEmpty.value
    ? [
        {
          title: "Policy Docs",
          key: "policy-rules",
          component: markRaw(OnboardingDocs),
          completeCondition: allOnboardingDocsSigned.value,
        },
      ]
    : []),
  {
    title: "Credentialing",
    key: "credentials",
    component: markRaw(Credentials),
    completeCondition: isAllRequiredCertificatesUploaded.value,
  },
  {
    title: "Finish",
    key: "final",
    component: markRaw(Final),
  },
]);
const currentStepData = computed(() => steps.value[+currentStep.value]);

const hasSetPassword = computed(() => !!user.value?.has_change_password);
const hasBasicDetailsFilled = computed(
  () => !!employee.value?.social_security_no && !!employee.value?.license_no
);
const hasFormI9Filled = computed(() => !!formI9.value);
const hasFormI9Approved = computed(() => formI9.value?.status === 1);
const hasFormW9Filled = computed(() => !!formW9.value);
const hasFormW9Approved = computed(() => formW9.value?.status === 1);
const isOnboardingDocsEmpty = computed(
  () => logs.value.length === 0 && !onbDocsLoading.value
);
const allOnboardingDocsSigned = computed(
  () => logs.value.length > 1 && logs.value.every((log) => log.status === 1)
);

const onbDocsLoading = ref(true);

const companies = ref([]);
const router = useRouter();

onMounted(() => {
  if (profileStatus.value === 1) {
    router.push({ name: "home" });
  }

  Promise.all([
    fetchPolicyDocs(),
    commonFunctions.get_companies(),
    commonFunctions.get_certification_types(),
    companyService.store(constants.getEmployeeCertificates, {}),
  ]).then(([_, companiesData, certificationTypes, certificatesData]) => {
    companies.value = companiesData;
    certificateTypes.value = certificationTypes;
    updateCertificates(certificatesData.data);

    // go to first incomplete step
    const firstIncompleteStep = steps.value.findIndex((step) => {
      if (["welcome"].includes(step.key)) return false;
      return !step.completeCondition;
    });

    if (firstIncompleteStep !== -1) {
      currentStep.value = firstIncompleteStep;
    } else {
      currentStep.value = steps.value.length - 1;
    }

    onbDocsLoading.value = false;
  });
});

const logs = ref([]);
const loading = ref(false);

async function fetchPolicyDocs() {
  loading.value = true;
  return commonService
    .get(constants.employeeOnboardingLogs)
    .then((res) => {
      logs.value = res.data;
    })
    .catch((err) => console.log(err))
    .finally(() => (loading.value = false));
}

const certificates = ref([]);
const certificateTypes = ref([]);

function checkRequiredCertificate(cer) {
  const employeeTypesCols = constants.employee_types.reduce(
    (holder, currentEmpType) => {
      holder[currentEmpType.value] = currentEmpType.key;
      return holder;
    },
    {}
  );
  return cer[employeeTypesCols[employee.value.employee_type]];
}

const isAllRequiredCertificatesUploaded = computed(() => {
  const requiredCerts = certificateTypes.value?.filter((certificate) => {
    return checkRequiredCertificate(certificate);
  });

  return requiredCerts.every((certificate) => {
    return (
      certificate.employeeCertificates?.filter((cert) => {
        return [0, 1].includes(cert.approval_status);
      }).length > 0
    );
  });
});
const areCertificatesPendingForApproval = computed(() => {
  return certificates.value.some((cert) => {
    return cert["approval_status"] === 0;
  });
});

function updateCertificates({ credentials: certs }) {
  if (!certs) return;
  certificates.value = certs.filter((cert) => {
    if (!cert.expiry_date) return true;
    return moment(cert.expiry_date).isAfter(moment().subtract(1, "days"));
  });

  certificateTypes.value.forEach((certType, idx) => {
    certificateTypes.value[idx].employeeCertificates =
      certificates.value.filter((d) => d.certification_type_id === certType.id);
  });
}
</script>
