<template>
  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-alert type="warning" class="mb-sm-1">
      <template #message>
        <span v-html="emailChangeNotice" />
      </template>
    </a-alert>

    <a-alert type="info" class="mb-sm-1">
      <template #message>
        <b>Current Email: </b>{{ formState.old_email }}
      </template>
    </a-alert>

    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-form-item
          label="New Email"
          name="email"
          :validateStatus="validate('email', 'status')"
          :help="validate('email', 'msg')"
        >
          <a-input
            v-model:value="formState.email"
            placeholder="Enter here..."
            @change="resetErrors('email')"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons :name="submitButtonText" />
  </a-form>
</template>

<script>
import { companyService } from "@/services";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  data() {
    return {
      formState: {},
      rules: {
        email: [
          {
            required: true,
            message: "This field is required!",
            type: "email",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    emailChangeNotice() {
      return this.extra;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id,
        old_email: this.record.email,
        email: "",
      };
    },

    onSubmit() {
      this.loadingStart();
      companyService
        .store(this.$constants.employeeUpdateEmailUrl, this.formState)
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("callback", res.data);
          this.close();
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
      // .finally(() => this.loadingStop());
    },
  },
};
</script>
