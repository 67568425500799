<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Performance Evaluations"
      style="margin-bottom: 24px; background-color: #fff"
    >
      <template #extra>
        <router-link :to="{ name: 'manager.create-performance-evaluation' }">
          <a-button type="primary">
            <a-space>
              <i class="ti ti-plus ti-lg"></i> Add Performance Evaluation
            </a-space>
          </a-button>
        </router-link>
      </template>
    </a-page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'manager.show-performance-evaluation',
                params: { id: record.id },
              }"
            >
            <a-tooltip title="View">
              <a-button type="link" size="small">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
            </router-link>

            <a-tooltip title="Download">
            <a-button type="link" size="small" @click="downloadPdf(record)">
              <i class="ti ti-download ti-lg"></i>
            </a-button>
          </a-tooltip>

            <template v-if="[1, 2].includes(record.status)">
              <router-link
                :to="{
                  name: 'manager.edit-performance-evaluation',
                  params: { id: record.id },
                }"
              >
              <a-tooltip title="Edit">
                <a-button type="link" size="small">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
              </router-link>
            </template>

            <template v-if="[1].includes(record.status)">
              <a-tooltip title="Send Reminder">
                <a-button
                  type="link"
                  size="small"
                  @click="sendRemainderMail(record)"
                >
                  <i class="ti ti-mail ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>

            <a-tooltip title="Mail Logs">
              <a-button
                type="link"
                size="small"
                @click="showOfferMailLog(record)"
              >
                <i class="ti ti-history ti-lg"></i>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from 'vuex';
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.managerPerformanceEvaluationsUrl,
      statusChangeUrl:
        this.$constants.adminPerformanceEvaluationChangeStatusUrl,
      columns: [
        {
          title: "Employee",
          dataIndex: "full_name",
          key: "full_name",
          customRender: ({ record }) => record.full_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.full_name
            ? [this.$route.query?.full_name]
            : undefined,
          sorter: true,
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "full_name"
              ? this.$route.query.sortOrder
              : undefined
            : "ascend",
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
          sorter: true,
          defaultSortOrder:
            this.$route.query?.sortField == "hospital_name"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Evaluation Completed Date",
          dataIndex: "evaluation_completed_date",
          key: "evaluation_completed_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
          sorter: true,
          defaultSortOrder:
            this.$route.query?.sortField == "evaluation_completed_date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Next Performance Review Date",
          dataIndex: "next_performance_review_date",
          key: "next_performance_review_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
          sorter: true,
          defaultSortOrder:
            this.$route.query?.sortField == "next_performance_review_date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Job Type",
          dataIndex: "job_type",
          key: "job_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "job_type",
              this.$constants.performanceEvaluationJobs
            ),
          filters: this.$constants.performanceEvaluationJobs,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.job_type
            ? [parseInt(this.$route.query?.job_type)]
            : undefined,
        },
        {
          title: "Appraisal Type",
          dataIndex: "appraisal_type",
          key: "appraisal_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "appraisal_type",
              this.$constants.performanceEvaluationTypes
            ),
          filters: this.$constants.performanceEvaluationTypes,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.appraisal_type
            ? [parseInt(this.$route.query?.appraisal_type)]
            : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Under Review",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "Disputed",
          status: 2,
          slots: { tab: "customRender" },
        },
        {
          key: "3",
          counts: 0,
          text: "Signed",
          status: 3,
          slots: { tab: "customRender" },
        },
        {
          key: "4",
          counts: 0,
          text: "Completed",
          status: 4,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        full_name: this.$route.query?.full_name
          ? [this.$route.query?.full_name]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        job_type: this.$route.query?.job_type
          ? [parseInt(this.$route.query?.job_type)]
          : undefined,
        appraisal_type: this.$route.query?.appraisal_type
          ? [parseInt(this.$route.query?.appraisal_type)]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    downloadPdf(record) {
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.adminPerformanceEvaluationExportPdfUrl,
          { id: record.id },
          `${record.full_name.replace(", ", "_")}_performance_evaluation.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    sendRemainderMail(record) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminPerformanceEvaluationSendRemainderUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
          this.refresh();
        });
    },

    showOfferMailLog(record) {
      this.open({
        title: "Mail Logs",
        path: "admin.performanceAppraisals.offer_mail_logs",
        callback: this.refresh,
        record,
      });
    },
  },
};
</script>
