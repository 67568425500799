<template>
  <a-layout-content>
    <page-header>
      <template #title> Roles </template>

      <template #buttons>
        <!-- <a-button
          v-if="$comman.hasRole(1)"
          type="primary"
          @click="handleCreateNewRoleEvent"
        >
          <a-space> <i class="ti ti-plus ti-lg"></i> Add New Role </a-space>
        </a-button> -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #action="{ record }">
          <template v-if="record.id != 1">
            <template v-if="record.status == 1">
              <a-button
                v-if="$comman.hasRole(1)"
                type="link"
                size="small"
                class="pl-sm-0"
                @click="handleEditRoleEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>

              <a-popconfirm
                v-if="$comman.hasRole(1)"
                title="Are you sure delete this role?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '0' })
                "
              >
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-popconfirm>
            </template>

            <template v-else>
              <a-popconfirm
                v-if="$comman.hasRole(1)"
                title="Are you sure restored this role?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '1' })
                "
              >
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-popconfirm>
            </template>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.roleDatatableUrl,
      statusChangeUrl: this.$constants.roleChangeStatusUrl,
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          sorter: true,
          width: 50,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          // slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],

      routes: [],
    };
  },

  created() {
    this.getRoutes();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getRoutes() {
      commonService.get(this.$constants.getAccessRoutesUrl).then((res) => {
        if (res.success) {
          this.routes = res.data;
        }
      });
    },

    handleEditRoleEvent(record) {
      this.open({
        title: "Edit Role",
        path: "role.store",
        callback: this.refresh,
        record: record,
        extra: { routes: this.routes },
      });
    },

    handleCreateNewRoleEvent() {
      this.open({
        title: "Add New Role",
        path: "role.store",
        callback: this.refresh,
        extra: { routes: this.routes },
      });
    },
  },
};
</script>
