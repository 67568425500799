<template>
  <a-form
    name="pre_procedure_form"
    ref="pre_procedure_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-space direction="vertical" style="width: 100%">
      <a-row :gutter="[0, 0]">
        <template
          v-for="(sides, index) in [firstFiveCheckGroup, lastSixCheckGroup]"
          :key="index"
        >
          <a-col :span="12">
            <template v-for="(group, index) in sides" :key="group.name">
              <p class="mb-sm-0" :class="index != 0 ? 'mt-sm-1' : ''">
                <a-row type="flex">
                  <a-col flex="35px">
                    <a-tooltip title="Select all N/A option">
                      <a-button
                        type="link"
                        size="small"
                        class="p-sm-0"
                        @click="
                          selectAllOption(group.checklist, 'not_applicable')
                        "
                      >
                        N/A
                      </a-button>
                    </a-tooltip>
                  </a-col>
                  <a-col flex="35px">
                    <a-tooltip title="Select all option">
                      <a-button
                        type="link"
                        size="small"
                        class="p-sm-0"
                        @click="selectAllOption(group.checklist, 'checklist')"
                      >
                        <CheckOutlined />
                      </a-button>
                    </a-tooltip>
                  </a-col>
                  <a-col flex="auto">
                    <strong>{{ group.name }}</strong>
                  </a-col>
                </a-row>
              </p>
              <template v-for="option in group.checklist" :key="option">
                <a-row type="flex">
                  <a-col flex="35px">
                    <a-checkbox
                      v-model:checked="model.not_applicable[option]"
                      @click="(e) => optionChecked(e, option, 'not_applicable')"
                    />
                  </a-col>
                  <a-col flex="35px">
                    <a-checkbox
                      v-model:checked="model.checklist[option]"
                      @click="(e) => optionChecked(e, option, 'checklist')"
                    />
                  </a-col>
                  <a-col flex="auto">
                    {{ option }}
                  </a-col>
                </a-row>
              </template>
            </template>
          </a-col>
        </template>
      </a-row>
    </a-space>

    <modal-buttons name="Save" />
  </a-form>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  components: { CheckOutlined },

  data() {
    return {
      model: {
        uuid: null,
        checklist: [],
        not_applicable: [],
        submitted_at: null,
      },
    };
  },

  computed: {
    ...mapGetters("modal", ["record"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    firstFiveCheckGroup() {
      return this.$constants.cpbPreByPassChecklist.filter((_, i) => i <= 4);
    },

    lastSixCheckGroup() {
      return this.$constants.cpbPreByPassChecklist.filter((_, i) => i > 4);
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      let masterChecklist = JSON.parse(
        JSON.stringify(this.$constants.cpbPreByPassChecklist)
      );

      let oldCheckedOption =
        this.record?.live_chart?.cpb?.checklist?.checked ?? [];
      let oldNotApplicableOption =
        this.record?.live_chart?.cpb?.checklist?.not_applicable ?? [];
      let checklist = {};
      let not_applicable = {};
      masterChecklist.forEach((group) => {
        group.checklist.forEach((e) => {
          checklist[e] = oldCheckedOption.includes(e) ? true : false;
          not_applicable[e] = oldNotApplicableOption.includes(e) ? true : false;
        });
      });

      this.model.uuid = this.record.uuid;
      this.model.checklist = checklist;
      this.model.not_applicable = not_applicable;
      this.model.submitted_at =
        this.record?.live_chart?.cpb?.checklist?.submitted_at ?? null;
    },

    onSubmit() {
      let newCheckedOption = [];
      for (const [key, value] of Object.entries(this.model.checklist)) {
        if (value) {
          newCheckedOption.push(key);
        }
      }

      let newNotApplicableOption = [];
      for (const [key, value] of Object.entries(this.model.not_applicable)) {
        if (value) {
          newNotApplicableOption.push(key);
        }
      }

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreCPBChecklistUrl, {
          uuid: this.model.uuid,
          checklist: newCheckedOption,
          not_applicable: newNotApplicableOption,
          submitted_at:
            this.model.submitted_at ??
            moment().tz(this.timezone).format("MM-DD-YYYY HH:mm:ss"),
        })
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },

    optionChecked(e, option, key) {
      if (e.target.checked) {
        if (key == "not_applicable") {
          this.model.checklist[option] = false;
        } else {
          this.model.not_applicable[option] = false;
        }
      }
    },

    selectAllOption(options, key) {
      let allChecked = options.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      options.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key = key == "not_applicable" ? "checklist" : "not_applicable";
      options.forEach((option) => (this.model[key][option] = false));
    },
  },
};
</script>
