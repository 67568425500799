<template>
  <a-skeleton :loading="loading">
    <a-descriptions
      title="Login Info"
      bordered
      :column="1"
      :labelStyle="{ width: '200px', fontWeight: 'bold' }"
    >
      <a-descriptions-item label="Quickbooks">
        <template v-if="quickbooks">
          <a-tag
            v-if="'token' in quickbooks"
            :color="quickbooks.token ? 'green' : 'red'"
          >
            Access Token
          </a-tag>
          <a-tag
            v-if="'refresh_token' in quickbooks"
            :color="quickbooks.refresh_token ? 'green' : 'red'"
          >
            Refresh Token
          </a-tag>

          <a-button
            v-if="!quickbooks.token && !quickbooks.refresh_token"
            type="primary"
            :loading="quickbooksLoginLoading"
            @click="quickbooksLogin"
          >
            Login
          </a-button>
        </template>
      </a-descriptions-item>

      <a-descriptions-item label="ADP">
        <template v-if="adp">
          <a-tag v-if="'token' in adp" :color="adp.token ? 'green' : 'red'">
            Access Token
          </a-tag>
        </template>
      </a-descriptions-item>

      <!-- <a-descriptions-item label="Gusto">
        <template v-if="gusto">
          <a-tag v-if="'token' in gusto" :color="gusto.token ? 'green' : 'red'">
            Access Token
          </a-tag>
          <a-tag
            v-if="'refresh_token' in gusto"
            :color="gusto.refresh_token ? 'green' : 'red'"
          >
            Refresh Token
          </a-tag>

          <a-button
            v-if="!gusto.token && !gusto.refresh_token"
            type="primary"
            :loading="gustoLoginLoading"
            @click="gustoLogin"
          >
            Login
          </a-button>
        </template>
      </a-descriptions-item> -->
    </a-descriptions>
  </a-skeleton>
</template>

<script>
import { commonService } from "../../../services";
export default {
  data() {
    return {
      loading: true,
      gustoLoginLoading: false,
      quickbooksLoginLoading: false,
      quickbooks: null,
      qusto: null,
      adp: null,
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;

      commonService
        .store(this.$constants.checkThirdPartyLogins)
        .then((res) => {
          if (res.success) {
            if (res.data.quickbooks) {
              this.quickbooks = res.data.quickbooks;
            }
            if (res.data.gusto) {
              this.gusto = res.data.gusto;
            }
            if (res.data.adp) {
              this.adp = res.data.adp;
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    gustoLogin() {
      this.gustoLoginLoading = true;
      commonService
        .store(this.$constants.gustoLoginUrl)
        .then((res) => {
          if (res.success) {
            if (res.redirect_uri) {
              let that = this;
              let win = window.open(res.redirect_uri, "_blank", {
                popup: 1,
              });
              var timer = setInterval(function () {
                if (win.closed) {
                  clearInterval(timer);
                  that.fetchData();
                  this.$message.success(
                    "Login into gusto account successfully."
                  );
                  return;
                }
              }, 500);
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.gustoLoginLoading = false;
        });
    },

    quickbooksLogin() {
      this.quickbooksLoginLoading = true;
      commonService
        .store(this.$constants.quickbooksLoginUrl)
        .then((res) => {
          if (res.success) {
            if (res.redirect_uri) {
              let that = this;
              let win = window.open(res.redirect_uri, "_blank", {
                popup: 1,
              });
              var timer = setInterval(function () {
                if (win.closed) {
                  clearInterval(timer);
                  that.fetchData();
                  this.$message.success(
                    "Login into quickbooks account successfully."
                  );
                  return;
                }
              }, 500);
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.quickbooksLoginLoading = false;
        });
    },
  },
};
</script>
