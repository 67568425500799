<template>
  <a-skeleton :loading="loading" active>
    <template v-if="hospitalProducts.length == 0">
      <a-alert message="Notice" type="warning" show-icon>
        <template #description>
          No product assigned or loaded for this facility.
        </template>
      </a-alert>
    </template>

    <template v-else>
      <a-row :gutter="[0, 10]">
        <a-col :span="24">
          <a-row justify="space-between">
            <a-col>
              <a-button type="primary" @click="handleRowAddEvent">
                <a-space>
                  <i class="ti ti-plus ti-lg"></i>
                  Add Product
                </a-space>
              </a-button>
            </a-col>
            <a-col :span="6">
              <a-select
                v-model:value="formState.templateID"
                placeholder="Select Template"
                optionFilterProp="label"
                showSearch
                @select="handleTemplateSelect"
              >
                <a-select-option
                  v-for="option in templates"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-col>

        <a-col :span="24">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => index"
            :dataSource="model.products"
            :pagination="false"
            size="small"
            class="ant-table-vertical-align-baseline"
            :scroll="{ x: true, y: 0 }"
          >
            <template #product="{ record, index }">
              <a-form-item
                :name="['products', index, 'product_id']"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="record.product_id"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="option in unSelectedProducts(record)"
                    :key="option.product_id"
                    :label="option.product_name"
                    :value="option.product_id"
                  >
                    {{ option.product_d_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>

            <template #lot_number="{ record, index }">
              <a-form-item :name="['products', index, 'lot_number']">
                <a-input
                  v-model:value="record.lot_number"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </template>

            <template #expiration_date="{ record, index }">
              <a-form-item :name="['products', index, 'expiration_date']">
                <a-date-picker
                  v-model:value="record.expiration_date"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                  @change="expirationDateChange(record)"
                />
              </a-form-item>
            </template>

            <template #used_qty="{ record, index }">
              <a-form-item
                :name="['products', index, 'used_qty']"
                :rules="{
                  required: true,
                  validator: (rule, value) =>
                    customValidator(rule, value, record),
                }"
              >
                <a-input
                  class="hide-arrow"
                  type="number"
                  v-model:value="record.used_qty"
                  placeholder="qty"
                  :max="record.stock_qty"
                />
              </a-form-item>
            </template>

            <template #action="{ index }">
              <a-button
                size="small"
                type="link"
                danger
                @click="handleRowDeleteEvent(index)"
              >
                <i class="ti ti-trash ti-lg"></i>
              </a-button>

              <a-button
                v-if="model.products.length == index + 1"
                size="small"
                type="link"
                @click="handleRowAddEvent"
              >
                <i class="ti ti-plus ti-lg"></i>
              </a-button>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </template>
  </a-skeleton>
</template>

<script>
import { commonService } from "../../../services";

export default {
  name: "case-report-step-3",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      hospitalProducts: [],
      templates: [],
      loading: true,
      formState: {
        templateID: null,
      },
      columns: [
        {
          title: "Product",
          dataIndex: "product",
          key: "product",
          slots: {
            customRender: "product",
          },
        },
        {
          title: "Lot Number",
          dataIndex: "lot_number",
          key: "lot_number",
          slots: {
            customRender: "lot_number",
          },
          width: 150,
        },
        {
          title: "Expiration Date",
          dataIndex: "expiration_date",
          key: "expiration_date",
          slots: {
            customRender: "expiration_date",
          },
          width: 150,
        },
        {
          title: "Stock Qty",
          dataIndex: "stock_qty",
          key: "stock_qty",
          width: 100,
        },
        {
          title: "Used Qty",
          dataIndex: "used_qty",
          key: "used_qty",
          width: 100,
          slots: {
            customRender: "used_qty",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 100,
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  async mounted() {
    await this.fetchHospitalProducts();
    await this.fetchTemplates();
    this.loading = false;
  },

  methods: {
    fetchTemplates() {
      commonService
        .get(this.$constants.hospitalDisposableTemplate, {
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.templates = res.data;
        });
    },

    handleTemplateSelect(selectedTemplateID) {
      let template = this.templates.find(
        (item) => item.id == selectedTemplateID
      );

      if (!template) return;
      template.items.forEach((temp) => {
        const productIdx = this.model.products.findIndex(
          (item) => item.product_id == temp.product_id
        );
        if (this.model.products[productIdx]) {
          this.model.products[productIdx].used_qty += temp.qty;
        } else {
          let hospitalProduct = this.hospitalProducts.find(
            (e) => e.product_id == temp.product_id
          );
          const stockQty = hospitalProduct.stock_qty;
          const usedQty = temp.qty;
          this.model.products.push({
            product_id: temp.product_id,
            product_name: hospitalProduct.product_name,
            lot_number: null,
            expiration_date: null,
            expiry_date: null,
            stock_qty: stockQty,
            used_qty: stockQty < usedQty ? stockQty : usedQty,
          });
        }
      });

      this.formState.templateID = undefined;
    },

    fetchHospitalProducts() {
      commonService
        .get(this.$constants.getHospitalWiseProductsUrl, {
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          let products = res.data.map((item) => {
            return {
              id: item.id,
              product_id: item.product_id,
              product_name: `${item.product.manufacturer.name} | ${item.product.name} | ${item.product.sku}`,
              product_d_name: `${item.product.name} | ${item.product.sku}`,
              lot_number: item.lot_number ?? null,
              expiration_date: item.expiration_date ?? null,
              expiry_date: item.expiry_date ?? null,
              stock_qty: item.stock_qty,
              used_qty: item.used_qty,
            };
          });
          this.hospitalProducts = products;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    unSelectedProducts(record) {
      let selectedProductIds = this.model.products.map(
        (item) => item.product_id
      );
      return this.hospitalProducts.filter(
        (item) =>
          !selectedProductIds.includes(item.product_id) ||
          record.product_id == item.product_id
      );
    },

    handleRowDeleteEvent(index) {
      this.model.products = this.model.products.filter((_, i) => index != i);
    },

    handleRowAddEvent() {
      this.model.products.push({
        product_id: undefined,
        product_name: null,
        lot_number: null,
        expiration_date: null,
        expiry_date: null,
        stock_qty: 0,
        used_qty: null,
      });
    },

    expirationDateChange(item) {
      item.expiry_date = item.expiration_date
        ? this.$customDate.mdy(item.expiration_date)
        : null;
      this.$comman.pastDateWarningModal(item.expiration_date);
    },

    customValidator(rule, value, rec) {
      const reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        return Promise.reject("This field is required!");
      }
      if (value == 0) {
        return Promise.reject("Used qty must be greater then 0!");
      }
      if (rec.used_qty > rec.stock_qty) {
        return Promise.reject(
          "Used qty must be less then or equal to stock qty!"
        );
      }
      return Promise.resolve();
    },

    removeSigns(event) {
      return (
        (event.charCode != 8 && event.charCode == 0) ||
        (event.charCode >= 48 && event.charCode <= 57)
      );
    },
  },

  watch: {
    "model.products": {
      deep: true,
      handler: function (val) {
        val.map((item) => {
          let product = this.hospitalProducts.find(
            (p) => p.product_id == item.product_id
          );
          if (product) {
            item.stock_qty = product.stock_qty;
            item.product_name = product.product_name;
          }
          return item;
        });
      },
    },
  },
};
</script>
