import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasFinanceAccess from "./middleware/hasFinanceAccess.js";

export default [
  {
    path: "/finance/dashboard",
    name: "finance-dashboard",
    component: route("finance/dashboard"),
    meta: {
      title: "Finance Dashboard",
      layout: "app",
      middleware: [auth, hasFinanceAccess],
    },
  },
  {
    path: "/finance/profit-loss",
    name: "finance-profit-loss",
    component: route("finance/ProfitLoss"),
    meta: {
      title: "Profit & Loss",
      layout: "app",
      middleware: [auth, hasFinanceAccess],
    },
  },
  {
    path: "/finance/balance-sheet",
    name: "finance-balance-sheet",
    component: route("finance/BalanceSheet"),
    meta: {
      title: "Balance Sheet",
      layout: "app",
      middleware: [auth, hasFinanceAccess],
    },
  },
  {
    path: "/finance/unpaid-bills",
    name: "unpaid-bills",
    component: route("finance/unpaidBills"),
    meta: {
      title: "Unpaid Bills",
      layout: "app",
      middleware: [auth, hasFinanceAccess],
    },
  },
  {
    path: "/finance/monthly-customer-invoicing",
    name: "monthly-customer-invoicing",
    component: route("finance/monthlyCustomerInvoicing"),
    meta: {
      title: "Monthly Customer Invoicing",
      layout: "app",
      middleware: [auth, hasFinanceAccess],
    },
  },
  {
    path: "/finance/customer-reports",
    name: "customer-reports",
    component: route("finance/customerReports"),
    meta: {
      title: "Customer Reports",
      layout: "app",
      middleware: [auth, hasFinanceAccess],
    },
  },
];
