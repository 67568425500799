<template>
  <div class="mail-logs">
    <template v-for="log in logs" :key="log.id">
      <div class="mb-sm-1 open-card">
        <div style="padding: 10px">
          <a-card :bordered="false" size="small">
            <a-space>
              <a-typography-title :level="4">Opens</a-typography-title>
              <a-typography-title :level="4">
                <template v-if="deliveryStatusIcon(log) == 1">
                  <i class="ti ti-check"></i>
                </template>
                <template v-if="deliveryStatusIcon(log) == 2">
                  <i class="ti ti-checks"></i>
                </template>
                <template v-if="deliveryStatusIcon(log) == 3">
                  <i class="ti ti-checks" style="color: lightseagreen"></i>
                </template>
              </a-typography-title>
            </a-space>

            <a-row type="flex" justify="space-around" align="top">
              <div style="text-align: center">
                <a-typography-title :level="5">First</a-typography-title>
                <span style="font-weight: 400">
                  {{ firstOpenTimeStamp(log) }}
                </span>
              </div>
              <a-divider
                type="vertical"
                style="height: 60px; border: 1.5px solid #ececec"
              />
              <div style="text-align: center">
                <a-typography-title :level="5">Total</a-typography-title>
                <span style="font-weight: 400">
                  {{ totalOpens(log) }}
                </span>
              </div>
              <a-divider
                type="vertical"
                style="height: 60px; border: 1.5px solid #ececec"
              />
              <div style="text-align: center">
                <a-typography-title :level="5">Last</a-typography-title>
                <span style="font-weight: 400">
                  {{ lastOpenTimeStamp(log) }}
                </span>
              </div>
            </a-row>
          </a-card>
        </div>

        <div class="timeline-data">
          <a-row type="flex" justify="space-between" align="top">
            <a-timeline>
              <a-timeline-item
                v-for="(event, name) in withoutClickEvents(log)"
                :key="name"
              >
                <template #dot>
                  <i
                    v-if="name == 'open'"
                    class="ti ti-checks ti-lg"
                    style="color: lightseagreen"
                  />
                  <i
                    v-if="name == 'delivered'"
                    class="ti ti-checks ti-lg"
                    style="color: black"
                  />
                  <i
                    v-if="name == 'processed'"
                    class="ti ti-check ti-lg"
                    style="color: black"
                  />
                </template>
                <a-typography-title
                  :level="5"
                  style="margin-bottom: 0; text-transform: capitalize"
                >
                  {{ name }}
                </a-typography-title>
                <span style="font-weight: 400">
                  <p v-for="e in event" :key="e.id">
                    {{ $customDate.mdyhis(e.datetime) }}
                  </p>
                </span>
              </a-timeline-item>
            </a-timeline>

            <a-timeline mode="right" style="width: 50%">
              <a-timeline-item v-if="onlyClickEvents(log)?.length > 0">
                <template #dot>
                  <i class="ti ti-hand-click ti-lg" style="color: #1890ff"></i>
                </template>
                <a-typography-title :level="5">Click</a-typography-title>
                <span style="font-weight: 400">
                  <p v-for="e in onlyClickEvents(log)" :key="e.id">
                    {{ $customDate.mdyhis(e.datetime) }}
                    <template v-if="e.ip_info.city">
                      <br />
                      <i class="ti ti-world ti-lg"></i>
                      {{ e.ip_info.city }}, {{ e.ip_info.country }}
                    </template>
                  </p>
                </span>
              </a-timeline-item>
            </a-timeline>
          </a-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "mail-log-component",

  props: ["record"],

  data() {
    return {
      //
    };
  },
  computed: {
    logs() {
      if (this.record?.mail_logs) {
        return this.record.mail_logs.map((log) => {
          if (log.events.length > 0) {
            log.events = Object.groupBy(log.events, ({ event }) => event);
          }
          return log;
        });
      }
      return [];
    },
  },

  mounted() {
    //
  },

  methods: {
    deliveryStatusIcon(log) {
      if (log.events["open"]) {
        return 3;
      } else if (log.events["delivered"]) {
        return 2;
      } else if (log.events["processed"]) {
        return 1;
      }
    },

    firstOpenTimeStamp(log) {
      let event = log.events["open"];
      return event && event.length > 0
        ? this.$customDate.mdyhis(event[0].datetime)
        : "N/A";
    },

    lastOpenTimeStamp(log) {
      let event = log.events["open"];
      return event && event.length > 0
        ? this.$customDate.mdyhis(event[event.length - 1].datetime)
        : "N/A";
    },

    totalOpens(log) {
      let event = log.events["open"];
      return event ? event.length : 0;
    },

    withoutClickEvents(log) {
      let res = {};
      for (const [key, value] of Object.entries(log.events)) {
        if (key != "click") {
          res[key] = value;
        }
      }
      return res;
    },

    onlyClickEvents(log) {
      return log.events["click"] ?? [];
    },
  },
};
</script>

<style lang="less">
.mail-logs {
  .open-card {
    background-color: #ececec;
    border: 2px solid #eaeaea;
    border-radius: 10px;
  }
  .timeline-data {
    background-color: white;
    width: 100%;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    p {
      margin-bottom: 0;
    }
    .ant-timeline-item {
      padding-bottom: 5px;
    }

    .ant-timeline-item-right p:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
</style>
