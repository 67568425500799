<template>
  <a-form
    name="nrp_blood_product_form"
    ref="nrp_blood_product_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-row :gutter="[16, 0]">
          <a-col :span="12">
            <a-form-item
              ref="date"
              label="Date"
              name="date"
              :rules="{
                required: true,
                message: 'Invalid date.',
                type: 'date',
              }"
            >
              <a-date-picker
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allowClear="false"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="time"
              label="Time"
              name="time"
              :rules="{
                required: true,
                message: 'Invalid time.',
                validator: $validation.timeWithSecFormat,
              }"
            >
              <a-input
                v-model:value="model.time"
                v-maska="$constants.timeWithSecMaska"
                placeholder="HH:mm:ss"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="product_type"
          label="Product Type"
          name="product_type"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-select
            v-model:value="model.product_type"
            style="width: 100%"
            :options="
              $constants.bloodProductTypes.map((e) => ({ label: e, value: e }))
            "
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="unit_type"
          label="Unit Type"
          name="unit_type"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-select
            v-model:value="model.unit_type"
            show-search
            style="width: 100%"
            :options="
              $constants.bloodTypes
                .filter((e) => e != 'Unknown')
                .map((e) => ({
                  label: e,
                  value: e,
                }))
            "
            @change="unitTypeChange"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="unit_id_no"
          label="Unit ID No"
          name="unit_id_no"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-input v-model:value="model.unit_id_no" />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="unit_expiration_date"
          label="Unit Expiration Date"
          name="unit_expiration_date"
          :rules="{
            required: true,
            type: 'date',
            message: 'This field is required.',
          }"
        >
          <a-date-picker
            v-model:value="model.unit_expiration_date"
            :placeholder="$constants.datePickerPlaceholder"
            :format="$constants.datepickerFormat"
            :allowClear="false"
            @change="$comman.pastDateWarningModal(model.unit_expiration_date)"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="blood_product_volume"
          label="Volume (mL)"
          name="blood_product_volume"
          :rules="{
            required: true,
            type: 'integer',
            min: 0,
            message: 'This field is required.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.blood_product_volume"
          />
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item
          ref="is_checked"
          name="is_checked"
          :rules="{
            required: true,
            message: 'This field is required.',
            validator: $validation.boolRequired,
          }"
        >
          <a-checkbox v-model:checked="model.is_checked">
            Blood products have been checked according to hospital policy prior
            to administration
          </a-checkbox>
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { createVNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Blood Product",
        product_type: undefined,
        unit_type: undefined,
        unit_id_no: "",
        unit_expiration_date: null,
        blood_product_volume: null,
        is_checked: false,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.product_type = this.extra.record.product_type;
      this.model.unit_type = this.extra.record.unit_type;
      this.model.unit_id_no = this.extra.record.unit_id_no;
      this.model.unit_expiration_date = moment(
        this.extra.record.unit_expiration_date,
        "MM-DD-YYYY"
      );
      this.model.blood_product_volume = this.extra.record.blood_product_volume;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));
      data.unit_expiration_date = this.$customDate.mdy(
        this.model.unit_expiration_date
      );

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreNRPRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },

    unitTypeChange(val) {
      if (val != this.record.data.blood_type) {
        Modal.confirm({
          title: () =>
            "Donor blood type is different than the patient's blood type. Are you sure you want to record this transfusion?",
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            // console.log("OK");
          },
          okType: "danger",
          keyboard: false,
          cancelButtonProps: {
            disabled: true,
          },
        });
      }
    },
  },
};
</script>
