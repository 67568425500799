<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
    title="Case Reports"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <template #tabBarExtraContent>
      <show-hide-column v-model="columns" />
    </template>

    <a-space direction="vertical" style="width: 100%">
      <a-button
        size="small"
        type="primary"
        ghost
        htmlType="button"
        v-if="activeTabKey == 3"
        @click="uploadInvoiceOnQuickbooks"
        :loading="uploadInvoiceOnQuickbooksLoading"
      >
        <i class="ti ti-refresh ti-1x"></i>
        Upload Case Reports on QuickBooks
      </a-button>

      <a-table
        :columns="columns.filter((e) => e.show ?? true)"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #date="{ record, text }">
          {{ $customDate.mdy(text) }}
          <a-tooltip v-if="record.quickbooks_invoice_id" placement="top">
            <template #title>
              Invoice No:
              {{ JSON.parse(record.quickbooks_invoice_info).DocNumber }}
            </template>
            <a-button size="small" type="link">
              <i class="ti ti-info-circle ti-lg"> </i>
            </a-button>
          </a-tooltip>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              target="_blank"
              :to="{
                name: 'show-case-report',
                params: { uuid: record.uuid },
              }"
            >
              <a-tooltip title="View">
                <a-button
                  type="link"
                  size="small"
                  @click="
                    $comman.caseReportLogStore({
                      id: record.id,
                      action: 'View Record',
                    })
                  "
                >
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <router-link
              :to="{
                name: 'create-case-report',
                query: { uuid: record.uuid },
              }"
            >
              <a-tooltip title="Edit">
                <a-button
                  type="link"
                  size="small"
                  @click="
                    $comman.caseReportLogStore({
                      id: record.id,
                      action: 'Edit Record',
                    })
                  "
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <template v-if="record.status == 3 && record.hospital?.ftp">
              <a-tooltip title="Upload PDF On FTP Server">
                <a-button
                  type="link"
                  size="small"
                  :class="`${
                    record.case_report_ftp_success_logs_count > 0
                      ? 'primary-color'
                      : 'danger-color'
                  }`"
                  @click="uploadPDFOnServer(record)"
                >
                  <i class="ti ti-upload ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>

            <a-dropdown placement="bottomLeft">
              <a-button size="small" type="link">
                <i class="ti ti-dots-vertical ti-lg"></i>
              </a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-if="showLiveChartingButton(record)">
                    <router-link
                      :to="{
                        name: isAdmin
                          ? 'admin.case-report-live-charting'
                          : 'manager.case-report-live-charting',
                        params: { uuid: record.uuid },
                      }"
                    >
                      <a-tooltip title="Live Charting">
                        <a-button type="link" size="small">
                          <i class="ti ti-device-heart-monitor ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </router-link>
                  </a-menu-item>

                  <template v-if="record.status != 3">
                    <!-- <a-popconfirm
                      title="Are you sure you want to delete this case report?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="
                        () => {
                          $comman.caseReportLogStore({
                            id: record.id,
                            action: 'Delete Record',
                          });
                          handleStatusChangeEvent({ id: record.id, status: '0' });
                        }
                      "
                    >
                      <a-tooltip title="Delete" placement="bottom">
                        <a-button size="small" type="link" danger>
                          <i class="ti ti-trash ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-popconfirm> -->
                  </template>

                  <template v-if="record.status == 2">
                    <a-menu-item>
                      <a-popconfirm
                        title="Are you sure you want to approve this case report?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="
                          handleStatusChangeEvent({
                            id: record.id,
                            status: '3',
                          })
                        "
                      >
                        <a-tooltip title="Approve" placement="bottom">
                          <a-button size="small" type="link">
                            <i class="ti ti-status-change ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-popconfirm>
                    </a-menu-item>
                  </template>

                  <template v-if="record.status != 1">
                    <a-menu-item>
                      <a-tooltip title="Download PDF">
                        <a-button
                          type="link"
                          size="small"
                          @click="downloadPdf(record)"
                        >
                          <i class="ti ti-download ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>

                    <a-menu-item>
                      <a-tooltip title="Print">
                        <a-button
                          type="link"
                          size="small"
                          @click="renderPdf(record)"
                        >
                          <i class="ti ti-printer ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                  </template>

                  <!-- <template v-if="record.status != 1">
                    <a-tooltip title="Billing PDF">
                      <a-button
                        type="link"
                        size="small"
                        @click="downloadBillingPdf(record)"
                      >
                        <i class="ti ti-file-dollar ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </template> -->

                  <template v-if="record.quickbooks_invoice_id">
                    <a-menu-item>
                      <a-tooltip title="Send Email">
                        <a-button
                          type="link"
                          size="small"
                          @click="sendEmail(record.id)"
                        >
                          <i class="ti ti-mail-forward ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                  </template>

                  <template v-if="record.status == 1">
                    <a-menu-item>
                      <a-tooltip title="Send Reminder">
                        <a-button
                          type="link"
                          size="small"
                          @click="sendReminder(record.id)"
                        >
                          <i class="ti ti-mail-fast ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                  </template>
                </a-menu>
              </template>
            </a-dropdown>
          </a-space>
        </template>
      </a-table>
    </a-space>
  </a-card>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { h } from "vue";
import datatableMixins from "../../mixins/datatableMixins";
import showHideColumn from "../table/showHideColumn.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
    showHideColumn,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.caseReportDatatableForAdminUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            customRender: "date",
          },
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        // {
        //   title: "Service + Products = Total",
        //   dataIndex: "total_price",
        //   key: "total_price",
        //   customRender: ({ record }) =>
        //     `${this.$comman.withCurrency(
        //       record.case_service_price
        //     )} + ${this.$comman.withCurrency(
        //       record.product_price
        //     )} = ${this.$comman.withCurrency(record.total_price)}`,
        // },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
          show: false,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: "1",
      tabList: this.$constants.caseReportStatusesForAdmin.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),

      uploadInvoiceOnQuickbooksLoading: false,
      caseServices: [],
    };
  },

  mounted() {
    this.fetchCaseServices();
  },

  methods: {
    fetchCaseServices() {
      return commonService
        .get(this.$constants.getCaseServicesUrl)
        .then((res) => {
          this.caseServices = res.data.map((item) => ({
            text: item.name,
            label: item.name,
            value: item.id,
          }));
        });
    },

    resetFilters() {
      this.extraFilters.case_service_ids = [];
      this.refresh();
    },

    action(val) {
      commonService
        .store(this.$constants.caseReportChangeStatusUrl, val)
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    downloadPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "PDF Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          `${record.report_no}_case_report.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    renderPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Render Download",
      });
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    uploadInvoiceOnQuickbooks() {
      this.uploadInvoiceOnQuickbooksLoading = true;

      commonService
        .store(this.$constants.uploadInvoiceOnQuickbooksUrl)
        .then((res) => {
          if (res.success) {
            if (res.count > 0) {
              this.$notification.success({
                message: res.message,
                duration: 10,
              });
            }
            if (res.errors != "") {
              setTimeout(() => {
                this.$notification.error({
                  message: "Errors",
                  description: h("div", {
                    innerHTML: res.errors,
                  }),
                  duration: 10,
                });
              }, 1000);
            }
            this.refresh();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.uploadInvoiceOnQuickbooksLoading = false;
        });
    },

    sendEmail(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.sendInvoiceOnQuickbooksUrl, { id })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    sendReminder(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportSendReminderMailUrl, { id })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    uploadPDFOnServer(report) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportUploadPDFOnServerUrl, {
          id: report.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    downloadBillingPdf(report) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.caseReportExportBillingPdfUrl,
          { id: report.id },
          `${report.report_no}_billing.pdf`
        )
        .then((res) => {
          this.$message.success("Billing PDF downloaded successfully.");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    showLiveChartingButton(record) {
      if (record.hospital.is_live_charting_enable == 0) {
        return false;
      }
      return this.$constants.caseServicesIdForCPB
        .concat(this.$constants.caseServicesIdForATS)
        .filter((value, index, array) => array.indexOf(value) === index)
        .reduce(
          (bool, curr) => bool || record.data.services.includes(curr),
          false
        );
    },
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),
  },
};
</script>
