import { ref } from "vue";

export function useWindowSize() {
  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  window.addEventListener("resize", (e) => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  });

  return { width, height };
}
