<template>
  <a-card title="Personnel" size="small" class="personnels">
    <template #extra>
      <a-space v-if="!loading" size="small">
        <a-button
          type="primary"
          @click="handleBulkUploadHospitalPersonnelEvent"
        >
          <a-space> <i class="far fa-file-excel" /> Bulk Upload </a-space>
        </a-button>

        <a-button type="primary" @click="addNewPersonnel">
          <a-space size="small">
            <i class="ti ti-plus ti-lg"></i>
            Add New
          </a-space>
        </a-button>
      </a-space>
    </template>

    <a-table
      size="small"
      :columns="columns"
      :data-source="hospitalPersonnels"
      :rowKey="(record) => record.id"
      :pagination="false"
      :loading="loading"
    >
      <template #actions="{ record }">
        <a-tooltip title="Edit">
          <a-button
            type="link"
            size="small"
            class="edit-color pl-sm-0"
            @click="editPersonnel(record)"
          >
            <i class="ti ti-pencil ti-lg"></i>
          </a-button>
        </a-tooltip>

        <a-popconfirm
          title="Are you sure you want to remove this personnel?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteHospitalCaseReport(record.id)"
        >
          <a-tooltip title="Remove">
            <a-button type="link" size="small" class="danger-color">
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-popconfirm>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "hospital-personnels",

  props: {
    hospital: {
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      sampleFileDownloading: false,
      hospitalPersonnels: [],

      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
    };
  },

  async mounted() {
    this.getHospitalPersonnels();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getHospitalPersonnels() {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalPersonnelsUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.hospitalPersonnels = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addNewPersonnel() {
      this.open({
        title: "Add new hospital personnel",
        path: "hospital.drawerForm.storeHospitalPersonnel",
        callback: this.getHospitalPersonnels,
        record: { hospital_id: this.hospital.id },
      });
    },

    editPersonnel(record) {
      this.open({
        title: "Edit hospital personnel",
        path: "hospital.drawerForm.storeHospitalPersonnel",
        callback: this.getHospitalPersonnels,
        record,
      });
    },

    deleteHospitalCaseReport(id) {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalPersonnelDeleteUrl, { id })
        .then((res) => {
          if (res.success) {
            this.getHospitalPersonnels();
          }
        });
    },

    handleBulkUploadHospitalPersonnelEvent() {
      this.open({
        title: "Bulk Upload",
        path: "hospital.drawerForm.bulkUploadHospitalPersonnel",
        record: { hospital_id: this.hospital.id },
        callback: this.getHospitalPersonnels,
      });
    },
  },
};
</script>
