<template>
  <a-layout-content>
    <page-header>
      <template #title> Products </template>

      <template #buttons>
        <a-space>
          <a-button type="primary" @click="handleBulkUploadProductEvent">
            <a-space> <i class="far fa-file-excel" /> Bulk Upload </a-space>
          </a-button>

          <a-button type="primary" @click="handleCreateNewProductEvent">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add Product </a-space>
          </a-button>
        </a-space>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #is_discontinued="{ text }">
          <a-tag v-if="text == 1" color="success">Yes</a-tag>
          <a-tag v-else color="error">No</a-tag>
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                class="pl-sm-0"
                @click="handleEditProductEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this product?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure you want to restore this product?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
              <a-tooltip title="Restore">
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.productDatatableUrl,
      statusChangeUrl: this.$constants.productChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "QuickBooks Name",
          dataIndex: "quickbooks_name",
          key: "quickbooks_name",
          customRender: ({ record }) =>
            record.quickbooks_item_info
              ? JSON.parse(record.quickbooks_item_info)?.Name ?? "N/A"
              : "N/A",
        },
        {
          title: "Manufacturer",
          dataIndex: "manufacturer_name",
          key: "manufacturer_name",
          customRender: ({ record }) => record.manufacturer.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.manufacturer_name
            ? [this.$route.query?.manufacturer_name]
            : undefined,
        },
        {
          title: "Product Category",
          dataIndex: "product_category_name",
          key: "product_category_name",
          customRender: ({ record }) => record.product_category.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.product_category_name
            ? [this.$route.query?.product_category_name]
            : undefined,
        },
        {
          title: "UOM",
          dataIndex: "uom_name",
          key: "uom_name",
          customRender: ({ record }) => record.uom.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.uom_name
            ? [this.$route.query?.uom_name]
            : undefined,
        },
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.sku
            ? [this.$route.query?.sku]
            : undefined,
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          width: 100,
          customRender: ({ text }) => this.$comman.withCurrency(text),
          sorter: true,
        },
        {
          title: "Is Discontinued?",
          dataIndex: "is_discontinued",
          key: "is_discontinued",
          width: 120,
          slots: { customRender: "is_discontinued" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 80,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
        manufacturer_name: this.$route.query?.manufacturer_name
          ? [this.$route.query?.manufacturer_name]
          : undefined,
        product_category_name: this.$route.query?.product_category_name
          ? [this.$route.query?.product_category_name]
          : undefined,
        uom_name: this.$route.query?.uom_name
          ? [this.$route.query?.uom_name]
          : undefined,
        sku: this.$route.query?.sku ? [this.$route.query?.sku] : undefined,
        sortField: this.$route.query?.sortField ?? "name",
        sortOrder: this.$route.query?.sortOrder ?? "ascend",
      },

      manufacturers: [],
      product_categories: [],
      uoms: [],
      inventories: [],
    };
  },

  created() {
    this.getManufacturers();
    this.getProductCategories();
    this.getUoms();
    this.getQuickbooksData();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getManufacturers() {
      commonService.store(this.$constants.getManufacturersUrl).then((res) => {
        if (res.success) {
          this.manufacturers = res.data;
        }
      });
    },

    getProductCategories() {
      commonService
        .store(this.$constants.getProductCategoriesUrl)
        .then((res) => {
          if (res.success) {
            this.product_categories = res.data;
          }
        });
    },

    getUoms() {
      commonService
        .store(this.$constants.getUnitOfMeasurementsUrl)
        .then((res) => {
          if (res.success) {
            this.uoms = res.data;
            this.uoms.push({
              id: "Other",
              name: "Other",
            });
          }
        });
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, { names: "inventories" })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "inventories") {
                this.inventories = JSON.parse(v.data);
              }
            });
          }
        });
    },

    handleEditProductEvent(record) {
      this.open({
        title: "Edit Product",
        path: "product.store",
        callback: this.refresh,
        record: record,
        extra: {
          manufacturers: this.manufacturers,
          product_categories: this.product_categories,
          uoms: this.uoms,
          inventories: this.inventories,
        },
      });
    },

    handleCreateNewProductEvent() {
      this.open({
        title: "Add Product",
        path: "product.store",
        callback: this.refresh,
        extra: {
          manufacturers: this.manufacturers,
          product_categories: this.product_categories,
          uoms: this.uoms,
          inventories: this.inventories,
        },
      });
    },

    handleBulkUploadProductEvent() {
      this.open({
        title: "Bulk Upload",
        path: "product.bulkUpload",
        callback: this.refresh,
      });
    },
  },
};
</script>
