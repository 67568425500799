<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="pageTitle"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-row :gutter="[20, 20]">
      <a-col :xs="24" :xl="18">
        <a-card :loading="cardLoader" size="small">
          <a-form
            name="time_track_form"
            ref="time_track_form"
            :model="model"
            :rules="rules"
            layout="vertical"
            @finish="handleFinish"
          >
            <a-row :gutter="[20, 0]">
              <a-col v-if="total_shift_time() < 0" :span="24">
                <a-alert
                  class="mb-sm-1"
                  type="error"
                  message="Total shift time must be greater then 0 hour!"
                />
              </a-col>

              <a-col
                v-if="
                  Number(total_shift_time()) + Number(total_on_call_time()) >
                  24.0
                "
                :span="24"
              >
                <a-alert
                  class="mb-sm-1"
                  type="error"
                  message="Overall time must be less than 24 hours!"
                />
              </a-col>

              <a-col v-if="total_on_call_time() < 0" :xs="24">
                <a-alert
                  class="mb-sm-1"
                  type="error"
                  message="Total on call time must be greater then 0 hour!"
                />
              </a-col>

              <a-col :xs="24" class="mb-sm-1">
                <a-descriptions title="" bordered size="small">
                  <a-descriptions-item
                    label="Employee Name"
                    :labelStyle="{ width: '200px' }"
                  >
                    {{ timeTrackRecord?.employee?.full_name ?? "N/A" }}
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>

              <a-col :xs="24" :lg="12">
                <a-form-item
                  ref="hospital_id"
                  label="Facility"
                  name="hospital_id"
                >
                  <a-select
                    v-model:value="model.hospital_id"
                    placeholder="Select hospital"
                    show-search
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="hospital in hospitals"
                      :key="hospital.id"
                      :label="hospital.name"
                      :value="hospital.id"
                    >
                      {{ hospital.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="12">
                <a-form-item
                  ref="shift_type_id"
                  label="Type of Shift"
                  name="shift_type_id"
                >
                  <a-select
                    v-model:value="model.shift_type_id"
                    placeholder="Select shift"
                    show-search
                    optionFilterProp="label"
                  >
                    <a-select-option
                      v-for="shift in shiftTypes"
                      :key="shift.id"
                      :label="shift.name"
                      :value="shift.id"
                    >
                      {{ shift.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="6">
                <a-form-item
                  ref="start_date"
                  label="Shift Start Date"
                  name="start_date"
                  :validateStatus="validate('start_date', 'status')"
                  :help="validate('start_date', 'msg')"
                >
                  <a-date-picker
                    v-model:value="model.start_date"
                    :disabled-date="disabledStartDate"
                    :format="$constants.datepickerFormat"
                    :placeholder="$constants.datePickerPlaceholder"
                    :disabled="disabledField"
                    @change="resetErrors('start_date')"
                  />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="6">
                <a-form-item
                  ref="start_time"
                  label="Shift Start Time"
                  name="start_time"
                >
                  <a-input
                    v-model:value="model.start_time"
                    v-maska="timeMaska"
                    placeholder="HH:mm"
                    :disabled="disabledField"
                  />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="6">
                <a-form-item
                  ref="end_date"
                  label="Shift End Date"
                  name="end_date"
                  :validateStatus="validate('end_date', 'status')"
                  :help="validate('end_date', 'msg')"
                >
                  <a-date-picker
                    v-model:value="model.end_date"
                    :disabled-date="disabledEndDate"
                    :format="$constants.datepickerFormat"
                    :placeholder="$constants.datePickerPlaceholder"
                    :disabled="disabledField"
                    @change="resetErrors('end_date')"
                  />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :lg="6">
                <a-form-item
                  ref="end_time"
                  label="Shift End Time"
                  name="end_time"
                >
                  <a-input
                    v-model:value="model.end_time"
                    v-maska="timeMaska"
                    placeholder="HH:mm"
                    :disabled="disabledField"
                  />
                </a-form-item>
              </a-col>

              <template
                v-if="
                  $constants.timeTrackingOnCall.includes(model.shift_type_id)
                "
              >
                <a-col :xs="24" :lg="model.was_on_call ? 12 : 24">
                  <a-form-item
                    ref="was_on_call"
                    label="Was on-call coverage also provided on the same day?"
                    name="was_on_call"
                  >
                    <a-radio-group
                      v-model:value="model.was_on_call"
                      :disabled="disabledField"
                    >
                      <a-radio :value="0">No</a-radio>
                      <a-radio :value="1">Yes</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>

                <template v-if="model.was_on_call == 1">
                  <a-col :xs="24" :lg="6">
                    <a-form-item
                      ref="on_call_end_date"
                      label="On-Call End Date"
                      name="on_call_end_date"
                    >
                      <a-date-picker
                        v-model:value="model.on_call_end_date"
                        :disabled-date="disabledOnCallEndDate"
                        :format="$constants.datepickerFormat"
                        :placeholder="$constants.datePickerPlaceholder"
                        :disabled="disabledField"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :xs="24" :lg="6">
                    <a-form-item
                      ref="on_call_end_time"
                      label="On-Call End Time"
                      name="on_call_end_time"
                    >
                      <a-input
                        v-model:value="model.on_call_end_time"
                        v-maska="timeMaska"
                        placeholder="HH:mm"
                        :disabled="disabledField"
                      />
                    </a-form-item>
                  </a-col>
                </template>
              </template>

              <template v-if="hasTravelAllowance">
                <a-col :xs="24">
                  <a-form-item
                    ref="was_travel"
                    label="Is travel compensation available for hospital?"
                    name="was_travel"
                  >
                    <a-radio-group
                      v-model:value="model.was_travel"
                      :disabled="disabledField"
                    >
                      <a-radio :value="0">No</a-radio>
                      <a-radio :value="1">Yes</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>

                <template v-if="model.was_travel">
                  <a-col :xs="24" :lg="6">
                    <a-form-item
                      ref="home_departure"
                      label="Travel Start Time (Home)"
                      name="home_departure"
                    >
                      <a-input
                        v-model:value="model.home_departure"
                        v-maska="timeMaska"
                        placeholder="HH:mm"
                        :disabled="disabledField"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :xs="24" :lg="6">
                    <a-form-item
                      ref="hospital_arrival"
                      label="Travel End Time (Hospital)"
                      name="hospital_arrival"
                    >
                      <a-input
                        v-model:value="model.hospital_arrival"
                        v-maska="timeMaska"
                        placeholder="HH:mm"
                        :disabled="disabledField"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :lg="6">
                    <a-form-item
                      ref="hospital_departure"
                      label="Travel Start Time (Hospital)"
                      name="hospital_departure"
                    >
                      <a-input
                        v-model:value="model.hospital_departure"
                        v-maska="timeMaska"
                        placeholder="HH:mm"
                        :disabled="disabledField"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :xs="24" :lg="6">
                    <a-form-item
                      ref="home_arrival"
                      label="Travel End Time (Home)"
                      name="home_arrival"
                    >
                      <a-input
                        v-model:value="model.home_arrival"
                        v-maska="timeMaska"
                        placeholder="HH:mm"
                        :disabled="disabledField"
                      />
                    </a-form-item>
                  </a-col>
                </template>
              </template>

              <template v-if="[4].includes(model.shift_type_id)">
                <a-col :xs="24" :lg="12">
                  <a-form-item
                    ref="no_of_ecmo"
                    label="Number of patients on ECMO"
                    name="no_of_ecmo"
                  >
                    <a-input-number
                      type="number"
                      v-model:value="model.no_of_ecmo"
                      placeholder="Number of Patients"
                      :min="1"
                      :disabled="disabledField"
                    />
                  </a-form-item>
                </a-col>

                <a-col :xs="24" :lg="12">
                  <a-form-item
                    ref="mr_ecmo"
                    label="MR number(s) of patients on ECMO"
                    name="mr_ecmo"
                  >
                    <a-input
                      v-model:value="model.mr_ecmo"
                      placeholder="MR number(s)"
                      :disabled="disabledField"
                    />
                  </a-form-item>
                </a-col>
              </template>

              <a-col :xs="24">
                <a-descriptions layout="vertical" bordered size="small">
                  <a-descriptions-item label="Shift hours">
                    {{ total_shift_time() }}
                  </a-descriptions-item>
                  <template
                    v-if="
                      $constants.timeTrackingOnCall.includes(
                        model.shift_type_id
                      ) && model.was_on_call
                    "
                  >
                    <a-descriptions-item label="On-Call hours">
                      {{ total_on_call_time() }}
                    </a-descriptions-item>
                  </template>
                  <a-descriptions-item
                    v-if="hasTravelAllowance && model.was_travel"
                    label="Travel hours"
                  >
                    {{ total_travel_time() }}
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>

              <a-col :xs="24" class="mt-sm-1">
                <a-form-item ref="notes" label="Notes" name="notes">
                  <a-textarea
                    v-model:value="model.notes"
                    placeholder="Enter notes here..."
                    :auto-size="{ minRows: 3, maxRows: 6 }"
                    :disabled="disabledField"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-button
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
              :disabled="
                Number(total_shift_time()) < 0 ||
                Number(total_on_call_time()) < 0 ||
                Number(total_shift_time()) + Number(total_on_call_time()) > 24
              "
            >
              {{ submitBtnText }}
            </a-button>
          </a-form>
        </a-card>
      </a-col>

      <a-col :xs="24" :xl="6">
        <a-card>
          <a-descriptions
            title="Time Tracking Notes"
            layout="vertical"
            bordered
            size="small"
          >
            <a-descriptions-item :label="shiftType.name ?? 'N/A'">
              <div v-html="shiftType.help_text ?? 'N/A'" />
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import backButton from "../../../components/backButton.vue";
import { employeeShiftTimeRule } from "../../../helper/formRules";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: { backButton },

  mixins: [formMixins],

  data() {
    return {
      submitBtnLoader: false,
      cardLoader: false,
      hospitals: [],
      shiftTypes: [],
      timeTrackRecord: null,
      model: {
        id: this.$route.params.id ?? 0,
        hospital_id: null,
        shift_type_id: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        total_time: null,
        notes: null,
        was_on_call: 0,
        on_call_end_date: null,
        on_call_end_time: null,
        total_on_call_time: null,
        was_travel: 0,
        home_departure: null,
        hospital_arrival: null,
        hospital_departure: null,
        home_arrival: null,
        total_travel_time: 0,
        no_of_ecmo: null,
        mr_ecmo: null,
      },
      rules: employeeShiftTimeRule,
      timeMaska: {
        mask: "##:Q#",
        tokens: {
          Q: {
            pattern: /[0-5]/,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },

    pageTitle() {
      if (this.model.id == 0) {
        return "Add New Time";
      }
      return "Edit Time";
    },

    hasTravelAllowance() {
      let hospital = this.hospitals.find(
        (item) => item.id == this.model.hospital_id
      );
      if (hospital) {
        return hospital.is_travel_comp_available;
      }
      return false;
    },

    shiftType() {
      return (
        this.shiftTypes.find((item) => item.id == this.model.shift_type_id) ??
        {}
      );
    },

    disabledField() {
      return this.timeTrackRecord?.status == 2;
    },
  },

  async mounted() {
    await this.fetchHospitals();
    await this.fetchShiftTypes();
    if (this.model.id != 0) {
      await this.fetchTimeTrack();
    }
  },

  methods: {
    fetchTimeTrack() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.adminEmployeeTimeTrackShowUrl, {
          id: this.model.id,
        })
        .then((res) => {
          this.timeTrackRecord = res.data;
          this.model = {
            id: res.data.id,
            hospital_id: res.data.hospital_id,
            shift_type_id: res.data.shift_type_id,
            start_date: moment(res.data.start_date),
            start_time: moment(res.data.start_time, "HH:mm").format("HH:mm"),
            end_date: moment(res.data.end_date),
            end_time: moment(res.data.end_time, "HH:mm").format("HH:mm"),
            was_on_call: res.data.was_on_call,
            on_call_end_date: res.data.on_call_end_date
              ? moment(res.data.on_call_end_date)
              : null,
            on_call_end_time: res.data.on_call_end_time
              ? moment(res.data.on_call_end_time, "HH:mm").format("HH:mm")
              : null,
            total_time: parseFloat(res.data.total_time).toFixed(2),
            total_on_call_time: parseFloat(res.data.total_on_call_time).toFixed(
              2
            ),
            was_travel: res.data.was_travel,
            home_departure: res.data.home_departure
              ? moment(res.data.home_departure, "HH:mm").format("HH:mm")
              : null,
            hospital_arrival: res.data.hospital_arrival
              ? moment(res.data.hospital_arrival, "HH:mm").format("HH:mm")
              : null,
            hospital_departure: res.data.hospital_departure
              ? moment(res.data.hospital_departure, "HH:mm").format("HH:mm")
              : null,
            home_arrival: res.data.home_arrival
              ? moment(res.data.home_arrival, "HH:mm").format("HH:mm")
              : null,
            total_travel_time: parseFloat(res.data.total_travel_time).toFixed(
              2
            ),
            notes: res.data.notes,
            no_of_ecmo: res.data.no_of_ecmo,
            mr_ecmo: res.data.mr_ecmo,
          };
          this.cardLoader = false;
        });
    },

    fetchHospitals() {
      commonService.get(this.$constants.getHospitalsUrl).then((res) => {
        this.hospitals = res.data;
      });
    },

    fetchShiftTypes() {
      commonService.get(this.$constants.getShiftTypesUrl).then((res) => {
        this.shiftTypes = res.data;
      });
    },

    disabledStartDate(start_date) {
      if (!start_date || !this.model.end_date) {
        return start_date && start_date > moment().endOf("day");
      }
      return (
        start_date > this.model.end_date || start_date > moment().endOf("day")
      );
    },

    disabledEndDate(end_date) {
      if (!end_date || !this.model.start_date) {
        return end_date && end_date > moment().endOf("day");
      }
      return (
        this.model.start_date > end_date || end_date > moment().endOf("day")
      );
    },

    disabledOnCallEndDate(end_date) {
      if (!end_date || !this.model.end_date) {
        return end_date && end_date > moment().endOf("day");
      }
      return this.model.end_date > end_date || end_date > moment().endOf("day");
    },

    convertToMoment(time) {
      return moment(
        `${moment(time, "HH:mm").format("HH:mm:00")}`,
        "HH:mm:00",
        true
      );
    },

    total_shift_time() {
      if (
        [null, ""].includes(this.model.start_date) ||
        [null, ""].includes(this.model.start_time) ||
        [null, ""].includes(this.model.end_date) ||
        [null, ""].includes(this.model.end_time)
      ) {
        return 0;
      }

      let startDate = moment(
        `${this.model.start_date.format("DD-MM-YYYY")} ${moment(
          this.model.start_time,
          "HH:mm"
        ).format("HH:mm:00")}`,
        "DD-MM-YYYY HH:mm:00",
        true
      );
      let endDate = moment(
        `${this.model.end_date.format("DD-MM-YYYY")} ${moment(
          this.model.end_time,
          "HH:mm"
        ).format("HH:mm:00")}`,
        "DD-MM-YYYY HH:mm:00",
        true
      );

      return Number(
        Number(moment.duration(endDate.diff(startDate)).asHours()).toFixed(2)
      );
    },

    total_on_call_time() {
      if (
        this.model.was_on_call != 1 ||
        [null, ""].includes(this.model.end_date) ||
        [null, ""].includes(this.model.end_time) ||
        [null, ""].includes(this.model.on_call_end_date) ||
        [null, ""].includes(this.model.on_call_end_time)
      ) {
        return 0;
      }

      let startDate = moment(
        `${this.model.end_date.format("DD-MM-YYYY")} ${moment(
          this.model.end_time,
          "HH:mm"
        ).format("HH:mm:00")}`,
        "DD-MM-YYYY HH:mm:00",
        true
      );
      let endDate = moment(
        `${this.model.on_call_end_date.format("DD-MM-YYYY")} ${moment(
          this.model.on_call_end_time,
          "HH:mm"
        ).format("HH:mm:00")}`,
        "DD-MM-YYYY HH:mm:00",
        true
      );

      return Number(
        Number(moment.duration(endDate.diff(startDate)).asHours()).toFixed(2)
      );
    },

    total_travel_time() {
      if (
        this.model.was_travel != 1 ||
        [null, ""].includes(this.model.home_departure) ||
        [null, ""].includes(this.model.hospital_arrival) ||
        [null, ""].includes(this.model.hospital_departure) ||
        [null, ""].includes(this.model.home_arrival)
      ) {
        return 0;
      }

      let firstTravelStartTime = this.convertToMoment(
        this.model.home_departure
      );
      let firstTravelEndTime = this.convertToMoment(
        this.model.hospital_arrival
      );
      if (firstTravelStartTime.unix() > firstTravelEndTime.unix()) {
        firstTravelEndTime.add(1, "day");
      }
      let firstTravelTotalTime = Number(
        moment.duration(firstTravelEndTime.diff(firstTravelStartTime)).asHours()
      );

      let secondTravelStartTime = this.convertToMoment(
        this.model.hospital_departure
      );
      let secondTravelEndTime = this.convertToMoment(this.model.home_arrival);
      if (secondTravelStartTime.unix() > secondTravelEndTime.unix()) {
        secondTravelEndTime.add(1, "day");
      }
      let secondTravelTotalTime = Number(
        moment
          .duration(secondTravelEndTime.diff(secondTravelStartTime))
          .asHours()
      );

      let finalTime = firstTravelTotalTime + secondTravelTotalTime;
      return finalTime > 1 ? Number(finalTime - 1).toFixed(2) : 0;
    },

    handleFinish() {
      let totalTime = this.total_shift_time();
      if (totalTime < 0) {
        this.$message.error("Total shift time must be greater then 0 hour!");
        return;
      }

      let totalInCallTime = 0;
      if (
        this.$constants.timeTrackingOnCall.includes(this.model.shift_type_id)
      ) {
        totalInCallTime = this.total_on_call_time();
        if (totalInCallTime < 0) {
          this.$message.error(
            "Total on call time must be greater then 0 hour!"
          );
          return;
        }
      } else {
        this.model = {
          ...this.model,
          was_on_call: 0,
          on_call_end_date: null,
          on_call_end_time: null,
          total_on_call_time: 0,
        };
      }

      if (Number(totalTime) + Number(totalInCallTime) > 24.0) {
        this.$message.error("Overall time must be less than 24 hours!");
        return;
      }

      if (!this.hasTravelAllowance) {
        this.model = {
          ...this.model,
          was_travel: 0,
          home_departure: null,
          hospital_arrival: null,
          hospital_departure: null,
          home_arrival: null,
          total_travel_time: 0,
        };
      }

      let val = {
        ...this.model,
        // employee_id: "id" in this.employee ? this.employee.id : 1,
        total_time: totalTime,
        start_date: this.$customDate.ymd(this.model.start_date),
        start_time: moment(this.model.start_time, "HH:mm").format("HH:mm:00"),
        end_date: this.$customDate.ymd(this.model.end_date),
        end_time: moment(this.model.end_time, "HH:mm").format("HH:mm:00"),
        on_call_end_date:
          this.model.on_call_end_date != null
            ? this.$customDate.ymd(this.model.on_call_end_date)
            : null,
        on_call_end_time: ![null, ""].includes(this.model.on_call_end_time)
          ? moment(this.model.on_call_end_time, "HH:mm").format("HH:mm:00")
          : null,
        total_on_call_time: totalInCallTime,
        was_travel: this.hasTravelAllowance ? this.model.was_travel : false,
        home_departure: ![null, ""].includes(this.model.home_departure)
          ? moment(this.model.home_departure, "HH:mm").format("HH:mm:00")
          : null,
        hospital_arrival: ![null, ""].includes(this.model.hospital_arrival)
          ? moment(this.model.hospital_arrival, "HH:mm").format("HH:mm:00")
          : null,
        hospital_departure: ![null, ""].includes(this.model.hospital_departure)
          ? moment(this.model.hospital_departure, "HH:mm").format("HH:mm:00")
          : null,
        home_arrival: ![null, ""].includes(this.model.home_arrival)
          ? moment(this.model.home_arrival, "HH:mm").format("HH:mm:00")
          : null,
        total_travel_time: this.total_travel_time(),
      };

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.adminEmployeeTimeTrackUpdateUrl, val)
        .then((res) => {
          this.submitBtnLoader = false;
          this.$message.success(res.message);
          this.$router.go(-1);
        })
        .catch((err) => {
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
          this.submitBtnLoader = false;
        });
    },
  },
};
</script>
