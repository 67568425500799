export const validation = {
  numeric(rule, value) {
    const reg = /^[0-9]*$/;
    if (value && !reg.test(value)) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  decimal(rule, value) {
    const reg = /^\d+(\.\d{1,2})*$/;
    if (value && !reg.test(Math.abs(value))) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  timeFormat(rule, value) {
    const reg = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    if (value && !reg.test(value)) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  timeWithSecFormat(rule, value) {
    const reg = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (value && !reg.test(value)) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  fullTimeRequired(rule, value) {
    const reg = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (!value && !reg.test(value)) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  boolRequired(rule, value) {
    if (!value) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  otpValidation(rule, value) {
    const reg = /^(\d{6}|\d{12})$/;
    if ([null, ''].includes(value) || !reg.test(value)) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
  zipcode(rule, value) {
    const reg = /^(\d)*$/;
    if (value && !reg.test(value)) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  },
};
