<template>
  <div>
    <a-descriptions :column="2" size="small" bordered>
      <a-descriptions-item :span="2" label="QuickBooks Bill No">
        {{ record.bill_number ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="QuickBooks Bill Id">
        {{ record.quickbooks_bill_id ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Employee Name">
        {{ record.employee?.full_name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Doing Business as">
        {{ record.employee?.dba ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Bill Amount">
        {{ $comman.withCurrency(record.bill_amount) }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="QuickBooks Payment Ids">
        {{ record.quickbooks_payment_ids }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="Payroll Dates">
        {{
          `${this.$customDate.mdy(record.start_date)}
        -
        ${this.$customDate.mdy(record.end_date)}`
        }}
      </a-descriptions-item>
    </a-descriptions>

    <a-row class="mt-sm-1" type="flex" justify="end">
      <a-button
        class="float-right"
        type="primary"
        :loading="excelExporting"
        @click="exportExcel"
      >
        Export Excel
      </a-button>
    </a-row>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="record.time_tracks"
      :pagination="false"
      size="small"
      class="mt-sm-1"
      :scroll="scroll"
    >
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      columns: [
        {
          title: "Shift Date",
          dataIndex: "shift_date",
          key: "shift_date",
          customRender: ({ record }) => this.$customDate.mdy(record.start_date),
        },
        {
          title: "Shift Type",
          dataIndex: "shift_type_name",
          key: "shift_type_name",
          customRender: ({ record }) => record.shift_type?.name,
        },
        {
          title: "Regular Hours",
          dataIndex: "total_time",
          key: "total_time",
          customRender: ({ text }) => parseFloat(text).toFixed(2),
        },
        {
          title: "On-call Hours",
          dataIndex: "total_on_call_time",
          key: "total_on_call_time",
          customRender: ({ text }) => parseFloat(text).toFixed(2),
        },
        {
          title: "Total Hours",
          dataIndex: "total_hours",
          key: "total_hours",
          customRender: ({ record }) =>
            parseFloat(
              record.total_time + record.total_on_call_time ?? 0
            ).toFixed(2),
        },
        {
          title: "Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          customRender: ({ text }) => this.$comman.withCurrency(text),
        },
      ],
      scroll: { x: true, y: 0 },
      excelExporting: false,
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    exportExcel() {
      this.excelExporting = true;
      commonService
        .downloadFile(
          this.$constants.adminBillExportExcelUrl,
          { bill_id: this.record.id },
          `Bill-${this.record.quickbooks_bill_id}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.excelExporting = false;
        });
    },
  },
};
</script>
