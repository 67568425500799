<template>
  <a-form
    name="nrp_timer_form"
    ref="nrp_timer_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Update" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: null,
        note: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    showPaCvpActField() {
      return (
        this.extra?.unique_code == 0 ||
        (this.record.live_chart?.cpb?.data ? false : true)
      );
    },

    nrp() {
      return this.record?.live_chart?.nrp;
    },
  },

  mounted() {
    if (this.extra.record) {
      this.model.uuid = this.record.uuid;
      this.model.unique_code = this.extra.record.unique_code;
      this.model.event = this.extra.record.event;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.note = this.extra.record.note;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    checkTimer() {
      return this.extra.onlyActiveRecords
        .filter((e) => e.event == this.model.event)
        .reverse()
        .reduce((pre, curr) => {
          if (pre.isCheck && !pre.start_time) {
            if (pre.start_time && pre.stop_time) {
              pre = {};
            }
            if (["Start", "On"].includes(curr.note)) {
              pre.start_time = curr.time;
              pre.start_datetime = `${curr.date} ${curr.time}`;
            }
          }
          if (curr.unique_code == this.model.unique_code) {
            pre.isCheck = true;
          }
          return pre;
        }, {});
    },

    onSubmit() {
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;

      if (["Stop"].includes(this.model.note)) {
        let timers = this.checkTimer();
        let startTime = moment(timers.start_datetime, "MM-DD-YYYY HH:mm:ss");
        let endTime = moment(data.datetime, "MM-DD-YYYY HH:mm:ss");
        data.duration = endTime.diff(startTime, "seconds");
      }

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreNRPRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
