<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <template #tabBarExtraContent>
      <router-link
        :to="{
          name: 'admin-create-performance-evaluation',
          query: { employee_id: $route.params.id },
        }"
      >
        <a-button type="primary" size="small">
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add Performance Evaluation
          </a-space>
        </a-button>
      </router-link>
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>

      <template #action="{ record }">
        <router-link
          :to="{
            name: 'admin-show-performance-evaluation',
            params: { id: record.id },
          }"
        >
          <a-button type="link" size="small" class="pl-sm-0">
            <i class="ti ti-eye ti-lg"></i>
          </a-button>
        </router-link>

        <a-button type="link" size="small" @click="downloadPdf(record)">
          <i class="ti ti-download ti-lg"></i>
        </a-button>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.performanceEvaluationsUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => record.employee?.full_name ?? "N/A",
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "N/A",
        },
        {
          title: "Evaluation Completed Date",
          dataIndex: "evaluation_completed_date",
          key: "evaluation_completed_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Next Performance Review Date",
          dataIndex: "next_performance_review_date",
          key: "next_performance_review_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Job Type",
          dataIndex: "job_type",
          key: "job_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "job_type",
              this.$constants.performanceEvaluationJobs
            ),
        },
        {
          title: "Appraisal Type",
          dataIndex: "appraisal_type",
          key: "appraisal_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "appraisal_type",
              this.$constants.performanceEvaluationTypes
            ),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Under Review",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "Disputed",
          status: 2,
          slots: { tab: "customRender" },
        },
        {
          key: "3",
          counts: 0,
          text: "Signed",
          status: 3,
          slots: { tab: "customRender" },
        },
        {
          key: "4",
          counts: 0,
          text: "Completed",
          status: 4,
          slots: { tab: "customRender" },
        },
      ],
      extraFilters: {
        employee_id: parseInt(this.$route.params.id),
      },
    };
  },

  methods: {
    downloadPdf(record) {
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.performanceEvaluationExportPdfUrl,
          { id: record.id },
          `${record.full_name.replace(", ", "_")}_performance_evaluation.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.refresh({ employee_id: parseInt(val) });
      }
    },
  },
};
</script>
