export default function accessible({ to, next, store }) {
  // console.log('this is accessible middleware')
  // console.log(to.name);

  // For Admin
  if (store.getters["auth/isAdmin"]) {
    return next();
  }

  // For Manager
  if (to.name.includes('manager.') && store.getters["auth/isManager"]) {
    return next();
  }

  // For Employee
  let routes = store.getters["auth/routes"];
  if (routes.indexOf(to.name) == -1) {
    return next({
      name: "no-access",
    });
  }
  return next();
}
