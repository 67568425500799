<template>
  <div>
    <a-form
      name="formW9_comment_form"
      ref="formW9_comment_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="comment"
            label="Comment"
            name="comment"
            :validateStatus="validate('comment', 'status')"
            :help="validate('comment', 'msg')"
          >
            <a-textarea
              v-model:value="formState.comment"
              placeholder="Enter comment here"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons name="Send" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {
        comment: null,
      },
      rules: {
        comment: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    comments() {
      return this.extra.comments;
    },
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.$refs.formW9_comment_form
        .validate()
        .then((val) => {
          const data = {
            form_w9_id: this.record.id,
            comment: this.formState.comment,
          };

          this.loadingStart();
          companyService
            .store(this.$constants.formW9Comment, data)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              this.loadingStop();
              if ("errors" in err) {
                this.errors = err.errors;
              } else {
                this.$message.error(err);
              }
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
