<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Heater Cooler Maintenance Detail"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card :loading="cardLoader" size="small">
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '250px' }"
      >
        <a-descriptions-item label="Date">
          {{ $customDate.mdy(record.date) }}
        </a-descriptions-item>
        <a-descriptions-item label="Employee Name">
          {{ record.employee?.full_name }} </a-descriptions-item
        ><a-descriptions-item label="Product Name">
          {{ record.asset_inventory_data?.asset_product?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Manufacturer Name">
          {{ record.asset_inventory_data?.asset_manufacturer?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Owned By">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'owned_by',
                $constants.assetInventoryOwnedBy,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "owned_by",
                $constants.assetInventoryOwnedBy
              )
            }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Owned Name">
          <template v-if="record.owned_by == 1">
            {{ record.asset_inventory_data?.company?.name ?? "N/A" }}
          </template>
          <template v-else>
            {{ record.asset_inventory_data?.hospital?.name ?? "N/A" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item
          v-if="record.asset_inventory_data?.asset_inventory_hospital"
          label="Allocated To"
          :span="2"
        >
          {{
            record.asset_inventory_data?.asset_inventory_hospital?.hospital
              ?.name ?? "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Record No">
          {{ record.asset_inventory_data?.record_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Serial No">
          {{ record.asset_inventory_data?.serial_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Asset ID Tag">
          {{ record.asset_inventory_data?.asset_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Secondary ID Tag">
          {{ record.asset_inventory_data?.secondary_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Description" :span="2">
          {{ record.asset_inventory_data?.description ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '800px' }"
      >
        <a-descriptions-item
          label="Is the device being stored empty and free of water?"
          :span="2"
        >
          {{ record.is_device_being_stored_empty }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Equipment cleaned following the manufacturer's instructions for use?"
          :span="2"
        >
          {{ record.is_equipment_cleaned }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Was the heater-cooler tubing changed during this cleaning?"
          :span="2"
        >
          {{ record.is_heater_cooler_tubing_change }}
        </a-descriptions-item>
        <a-descriptions-item
          label="When was the tubing last changed?"
          :span="2"
        >
          {{ $customDate.mdy(record.tubing_change_date) }}
        </a-descriptions-item>
        <a-descriptions-item
          label="If using a LivaNova 3T, was the Aerosol Collection Set changed during this cleaning?"
          :span="2"
        >
          {{ record.is_livanova_3t_aerosol_collection_set_changed }}
        </a-descriptions-item>
        <a-descriptions-item
          label="If using a LivaNova 3T, when was the Aerosol Collection Set last changed?"
          :span="2"
        >
          {{
            $customDate.mdy(
              record.livanova_3t_aerosol_collection_set_change_date
            )
          }}
        </a-descriptions-item>
        <a-descriptions-item :span="2">
          <template #label>
            H<sub>2</sub>O<sub>2</sub> level measured semi-quantitatively by
            visual comparison of the reaction zone of a hydrogen peroxide test
            strip.
          </template>
          {{ record.h2o2_level_measured_semi_quantitatively }}
        </a-descriptions-item>
        <a-descriptions-item
          label="If applicable, residual peracetic acid"
          :span="2"
        >
          {{ record.residual_peracetic_acid ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Was a water sample obtained and sent for analysis?"
          :span="2"
        >
          {{ record.is_water_sample_obtained }}
        </a-descriptions-item>
        <a-descriptions-item label="Date Water Sample Shipped" :span="2">
          {{ $customDate.mdy(record.water_sample_shipped_date) }}
        </a-descriptions-item>
        <a-descriptions-item label="Minncare Lot #" :span="2">
          {{ record.minncare_lot ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Minncare Expiration Date" :span="2">
          {{ $customDate.mdy(record.minncare_expiration_date) }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Peracetic Acid 1% Indicator Test Strip Lot #"
          :span="2"
        >
          {{ record.peracetic_acid_indicator_test_strip_lot ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Peracetic Acid 1% Indicator Test Strip Expiration Date"
          :span="2"
        >
          {{
            $customDate.mdy(
              record.peracetic_acid_indicator_test_strip_expiration_date
            )
          }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Peracetic Acid Residual Indicator Test Strip Lot #"
          :span="2"
        >
          {{ record.peracetic_acid_residual_indicator_test_strip_lot ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Peracetic Acid Residual Indicator Test Strip Expiration Date"
          :span="2"
        >
          {{
            $customDate.mdy(
              record.peracetic_acid_residual_indicator_test_strip_expiration_date
            )
          }}
        </a-descriptions-item>
        <a-descriptions-item label="0.2 Micron Filter Lot #" :span="2">
          {{ record.micron_filter_lot ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        title=""
        :column="1"
        size="small"
        bordered
        :labelStyle="{ width: '250px' }"
      >
        <a-descriptions-item label="Notes/Certifications">
          <div
            class="mb-sm-0 ck-editor-content"
            v-html="record.notes ?? 'N/A'"
          />
        </a-descriptions-item>
        <a-descriptions-item v-if="record.file_name" label="Analysis Report">
          <a
            href="#"
            @click.prevent="
              $comman.downloadItem({
                url: record.full_file_path,
                name: record.file_name,
              })
            "
          >
            {{ record.file_name }}
          </a>
        </a-descriptions-item>
        <a-descriptions-item label="Signature">
          <img :width="200" :src="record.signature" />
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: { backButton },
  data() {
    return {
      cardLoader: false,
      id: this.$route.params.id ?? 0,
      record: {},
    };
  },

  mounted() {
    this.fetchCellSaverQC();
  },

  methods: {
    fetchCellSaverQC() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.equipmentMaintenanceShowUrl, { id: this.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          this.record = res.data;
          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
