<template>
  <template v-if="Array.isArray(item)">
    <td colspan="2">
      <table style="width: 100%">
        <tr v-for="(e, index) in item" :key="`${index}_pl`" :class="e.type">
          <profitAndLossAnalyticTableRows :item="e" :indent="indent + 1" />
        </tr>
      </table>
    </td>
  </template>
  <template v-else>
    <td :style="`padding-left: ${indent * 6}px;`">{{ item.name }}</td>
    <td style="text-align: right">
      {{
        item.type == "header" ? "" : this.$comman.currencyformatter(item.amount)
      }}
    </td>
  </template>
</template>

<script>
export default {
  name: "profit-and-loss-analytic-table-rows",

  props: {
    item: {
      type: [Array, Object],
      required: true,
    },
    indent: {
      type: Number,
      default: 0,
    },
  },
};
</script>
