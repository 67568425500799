<template>
  <div>
    <a-row>
      <a-col :span="12" :offset="6">
        <a-form
          name="change_password_form"
          ref="change_password_form"
          :model="formState"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit()"
        >
          <a-row :gutter="[20, 0]">
            <a-col :span="24">
              <a-form-item
                ref="password"
                label="Password"
                name="password"
                :validateStatus="validate('password', 'status')"
                :help="validate('password', 'msg')"
              >
                <a-input-password
                  v-model:value="formState.password"
                  placeholder="Enter password here"
                  @change="resetErrors('password')"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="password_confirmation"
                label="Confirm Password"
                name="password_confirmation"
              >
                <a-input-password
                  v-model:value="formState.password_confirmation"
                  placeholder="Enter confirm password here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-button type="primary" html-type="submit" :loading="loading">
                Update
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-col>
      <a-col :span="6"> </a-col>
    </a-row>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { CheckOutlined } from "@ant-design/icons-vue";
import formMixins from "../../mixins/formMixins";

export default {
  name: "change_password",
  components: {
    CheckOutlined,
  },
  mixins: [formMixins],
  data() {
    return {
      loading: false,
      formState: {
        password: "",
        password_confirmation: "",
      },
      rules: {
        password: [
          {
            required: true,
            validator: this.password_validation,
            message:
              "Password must contain at least 10 character, one lowercase, one uppercase, one digit and one special character!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: this.password_confirmation_validation,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.change_password_form
        .validate()
        .then((val) => {
          this.loading = true;
          this.errors = {};
          companyService
            .store("api/employee/password-update", val)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
              }
            })
            .catch((err) => {
              this.errors = err.errors;
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
    password_validation(rule, value) {
      const reg =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]*$/;
      if (value === "") {
        return Promise.reject("password is required!");
      } else if (value.length < 10) {
        return Promise.reject(
          "password length must be more then 10 character!"
        );
      } else if (!reg.test(value)) {
        return Promise.reject(
          "Password must contain at least one lowercase, one uppercase, one digit and one special character!"
        );
      } else {
        if (this.formState.password_confirmation !== "") {
          this.$refs.change_password_form.validateFields(
            "password_confirmation"
          );
        }
        return Promise.resolve();
      }
    },
    password_confirmation_validation(rule, value) {
      if (value === "") {
        return Promise.reject("confirm password is required!");
      } else if (value !== this.formState.password) {
        return Promise.reject("confirm password don't match with password!");
      } else {
        return Promise.resolve();
      }
    },
  },
};
</script>

<style></style>
