<template>
  <a-form
    name="ecmo_coagulation_form"
    ref="ecmo_coagulation_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-divider orientation="left" style="margin-top: 0 !important">
      Coagulation
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="ptt"
          name="ptt"
          :rules="{
            pattern: new RegExp(/^[0-9]*$/),
            message: 'This field must be valid input.',
          }"
        >
          <template #label> PTT (sec) </template>
          <a-input-number type="number" v-model:value="model.ptt" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="heparin"
          name="heparin"
          :rules="{
            pattern: new RegExp(/^[0-9]*$/),
            message: 'This field must be valid input.',
          }"
        >
          <template #label> Heparin (units/HR) </template>
          <a-input-number type="number" v-model:value="model.heparin" />
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Coagulation",
        ptt: null,
        heparin: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    let item = null;
    if (this.extra.lastRecord) {
      item = this.extra.lastRecord;
    } else if (this.extra.record) {
      item = this.extra.record;
      this.model.unique_code = item.unique_code;
      this.model.date = moment(item.date, "MM-DD-YYYY");
      this.model.time = item.time;
    }
    if (item) {
      this.model.ptt = item.ptt;
      this.model.heparin = item.heparin;
    }
    if (!this.extra.record) {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreECMORecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
