<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Case Report </template>

        <template #buttons>
          <router-link :to="{ name: 'create-case-report' }">
            <a-button type="primary">
              <PlusOutlined /> Add New Report
            </a-button>
          </router-link>
        </template>
      </page-header>

      <custom-table
        :scroll="{ x: true, y: 0 }"
        :columns="columns"
        :url="$constants.caseReportDatatableUrl"
        ref="datatable"
        :params="params"
        :default-row-selection="true"
      >
        <template #action="record">
          <template v-if="record.status == 1">
            <router-link
              :to="{
                name: 'edit-case-report',
                params: { id: record.id },
              }"
            >
              <a-button type="link" class="pl-sm-0"> Edit </a-button>
            </router-link>

            <a-popconfirm
              title="Are you sure delete this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="() => action({ id: record.id, status: '0' })"
            >
              <a-button size="small" type="link" class="danger-color">
                Trash
              </a-button>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure restored this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="() => action({ id: record.id, status: '1' })"
            >
              <a-button size="small" type="link"> Restore </a-button>
            </a-popconfirm>

            <a-popconfirm
              title="Are you sure permantly delete this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="() => action({ id: record.id, status: '2' })"
            >
              <a-button size="small" type="link" class="danger-color">
                Permanent Delete
              </a-button>
            </a-popconfirm>
          </template>
        </template>
      </custom-table>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      params: {},
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Patient Name",
          dataIndex: "patient_name",
          key: "patient_name",
          customRender: ({ record }) =>
            record.patient_first_name + " " + record.patient_last_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  methods: {
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },

    action(val) {
      commonService
        .store(this.$constants.caseReportChangeStatusUrl, val)
        .then((res) => {
          if (res.success) {
            this.refreshDatatable();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },
  },
};
</script>

<style></style>
