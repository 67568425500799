<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Form I-9 (Section-2)"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card :loading="cardLoader" size="small">
      <a-form
        :model="formState"
        :rules="rules"
        layout="vertical"
        ref="formI9"
        @finish="onSubmit"
      >
        <a-row :gutter="[10, 10]">
          <a-col :span="24" v-if="oldData.data">
            <a-descriptions
              class="mb-sm-1"
              :labelStyle="{ width: '200px' }"
              size="small"
              :bordered="true"
              :column="3"
            >
              <a-descriptions-item
                v-for="(item, index) in employeeFormatted"
                :key="index"
                :label="item.label"
              >
                {{ item.value }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8">
            <div style="display: flex; justify-content: center">
              <a-typography-text strong>LIST A</a-typography-text>
            </div>
          </a-col>
          <a-col>
            <a-divider type="vertical" style="height: 100%" />
          </a-col>
          <a-col :span="15">
            <a-row :gutter="[10, 10]">
              <a-col :span="12">
                <div style="display: flex; justify-content: center">
                  <a-typography-text strong>LIST B</a-typography-text>
                </div>
              </a-col>
              <a-col :span="12">
                <div style="display: flex; justify-content: center">
                  <a-typography-text strong>LIST C</a-typography-text>
                </div>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Document Title" name="Document Title 1">
              <a-input
                v-model:value="formState['Document Title 1']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item label="Issuing Authority" name="Issuing Authority 1">
              <a-input
                v-model:value="formState['Issuing Authority 1']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Document Number(if any)"
              name="Document Number 0 (if any)"
            >
              <a-input
                v-model:value="formState['Document Number 0 (if any)']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Expiration Date(if any)"
              name="Expiration Date if any"
            >
              <a-date-picker
                v-model:value="formState['Expiration Date if any']"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledPastDate"
              />
            </a-form-item>

            <a-divider />

            <a-form-item
              label="Document Title 2(if any)"
              name="Document Title 2 If any"
            >
              <a-input
                v-model:value="formState['Document Title 2 If any']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item label="Issuing Authority" name="Issuing Authority_2">
              <a-input
                v-model:value="formState['Issuing Authority_2']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Document Number(if any)"
              name="Document Number If any_2"
            >
              <a-input
                v-model:value="formState['Document Number If any_2']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Expiration Date(if any)"
              name="List A.  Document 2. Expiration Date (if any)"
            >
              <a-date-picker
                v-model:value="
                  formState['List A.  Document 2. Expiration Date (if any)']
                "
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledPastDate"
              />
            </a-form-item>

            <a-divider />

            <a-form-item
              label="Document Title 3(if any)"
              name="List A.   Document Title 3.  If any"
            >
              <a-input
                v-model:value="formState['List A.   Document Title 3.  If any']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Issuing Authority"
              name="List A. Document 3.  Enter Issuing Authority"
            >
              <a-input
                v-model:value="
                  formState['List A. Document 3.  Enter Issuing Authority']
                "
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Document Number(if any)"
              name="List A.  Document 3 Number.  If any"
            >
              <a-input
                v-model:value="formState['List A.  Document 3 Number.  If any']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-form-item
              label="Expiration Date(if any)"
              name="Document Number if any_3"
            >
              <a-date-picker
                v-model:value="formState['Document Number if any_3']"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledPastDate"
              />
            </a-form-item>
          </a-col>

          <a-col>
            <a-divider type="vertical" style="height: 100%" />
          </a-col>

          <a-col :span="15">
            <a-row :gutter="[10, 10]">
              <a-col :span="12">
                <a-form-item
                  label="Document Title"
                  name="List B Document 1 Title"
                >
                  <a-input
                    v-model:value="formState['List B Document 1 Title']"
                    placeholder="Enter here..."
                  />
                </a-form-item>

                <a-form-item
                  label="Document Issuing Authority"
                  name="List B Issuing Authority 1"
                >
                  <a-input
                    v-model:value="formState['List B Issuing Authority 1']"
                    placeholder="Enter here..."
                  />
                </a-form-item>

                <a-form-item
                  label="Document Number"
                  name="List B Document Number 1"
                >
                  <a-input
                    v-model:value="formState['List B Document Number 1']"
                    placeholder="Enter here..."
                  />
                </a-form-item>

                <a-form-item
                  label="Document Expiration Date"
                  name="List B Expiration Date 1"
                >
                  <a-date-picker
                    v-model:value="formState['List B Expiration Date 1']"
                    :placeholder="$constants.datePickerPlaceholder"
                    :format="$constants.datepickerFormat"
                    :disabledDate="$comman.disabledPastDate"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item
                  label="Document Title"
                  name="List C Document Title 1"
                >
                  <a-input
                    v-model:value="formState['List C Document Title 1']"
                    placeholder="Enter here..."
                  />
                </a-form-item>

                <a-form-item
                  label="Document Issuing Authority"
                  name="List C Issuing Authority 1"
                >
                  <a-input
                    v-model:value="formState['List C Issuing Authority 1']"
                    placeholder="Enter here..."
                  />
                </a-form-item>

                <a-form-item
                  label="Document Number"
                  name="List C Document Number 1"
                >
                  <a-input
                    v-model:value="formState['List C Document Number 1']"
                    placeholder="Enter here..."
                  />
                </a-form-item>

                <a-form-item
                  label="Document Expiration Date"
                  name="List C Expiration Date 1"
                >
                  <a-date-picker
                    v-model:value="formState['List C Expiration Date 1']"
                    :placeholder="$constants.datePickerPlaceholder"
                    :format="$constants.datepickerFormat"
                    :disabledDate="$comman.disabledPastDate"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-divider style="margin: 0 0 24px 0" />

            <a-form-item
              label="Additional Information"
              name="Additional Information"
            >
              <a-textarea
                v-model:value="formState['Additional Information']"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-checkbox v-model:checked="formState['CB_Alt']">
              Check here if you used an alternative procedure authorized by DHS
              to examine documents.
            </a-checkbox>

            <a-divider />

            <a-row :gutter="[10, 10]">
              <a-col :span="12">
                <a-form-item
                  label="First Day of Employment"
                  name="FirstDayEmployed mmddyyyy"
                >
                  <a-date-picker
                    v-model:value="formState['FirstDayEmployed mmddyyyy']"
                    :placeholder="$constants.datePickerPlaceholder"
                    :format="$constants.datepickerFormat"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item
                  label="Employer's Business or Organization Name"
                  name="Employers Business or Org Name"
                >
                  <a-input
                    v-model:value="formState['Employers Business or Org Name']"
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item
                  label="Employer's Business or Organization Address"
                  name="Employers Business or Org Address"
                >
                  <a-input
                    v-model:value="
                      formState['Employers Business or Org Address']
                    "
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item
                  label="Last Name First Name and Title of Employer or Authorized Representative"
                  name="Last Name First Name and Title of Employer or Authorized Representative"
                >
                  <a-input
                    v-model:value="
                      formState[
                        'Last Name First Name and Title of Employer or Authorized Representative'
                      ]
                    "
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col>

              <!-- <a-col :span="12">
                <a-form-item
                  label="Signature of Employer or AR"
                  name="Signature of Employer or AR"
                >
                  <a-input
                    v-model:value="formState['Signature of Employer or AR']"
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col> -->

              <a-col :span="12">
                <a-form-item
                  label="Today's Date"
                  name="S2 Todays Date mmddyyyy"
                >
                  <a-date-picker
                    v-model:value="formState['S2 Todays Date mmddyyyy']"
                    :placeholder="$constants.datePickerPlaceholder"
                    :format="$constants.datepickerFormat"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center">
          <a-space direction="vertical">
            <vue-signature-pad
              :options="{ onEnd: save }"
              ref="signaturePad"
              :customStyle="{
                ...$constants.signPadCustomCss,
                height: '80px',
              }"
            />

            <a-row type="flex" justify="center">
              <a-col>
                <a-typography-paragraph>
                  Employee signature indicates that the information reported is
                  complete and accurate.
                </a-typography-paragraph>
              </a-col>
            </a-row>
            <a-row v-if="formState.authorized_signature" justify="center">
              <a-space>
                <a-button
                  htmlType="button"
                  type="primary"
                  danger
                  ghost
                  @click="clear"
                  class="mb-sm-1"
                >
                  Clear
                </a-button>
              </a-space>
            </a-row>
          </a-space>
        </a-row>

        <a-space>
          <a-button :loading="pdfLoading" @click="previewPDF">
            Preview
          </a-button>

          <a-button type="primary" html-type="submit" :loading="loading">
            Submit
          </a-button>
        </a-space>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import backButton from "../../../components/backButton.vue";
import { commonService } from "../../../services";

export default {
  components: { backButton },

  data() {
    return {
      cardLoader: false,
      pdfLoading: false,
      loading: false,
      formState: {
        "Document Title 1": null,
        "Issuing Authority 1": null,
        "Document Number 0 (if any)": null,
        "Expiration Date if any": null,
        // 2
        "Document Title 2 If any": null,
        "Issuing Authority_2": null,
        "Document Number If any_2": null,
        "List A.  Document 2. Expiration Date (if any)": null,
        // 3
        "List A.   Document Title 3.  If any": null,
        "List A. Document 3.  Enter Issuing Authority": null,
        "List A.  Document 3 Number.  If any": null,
        "Document Number if any_3": null,

        // LIST B
        "List B Document 1 Title": "Driving License",
        "List B Issuing Authority 1": null,
        "List B Document Number 1": null,
        "List B Expiration Date 1": null,

        // LIST C
        "List C Document Title 1": "Social Security Card",
        "List C Issuing Authority 1": "SSA",
        "List C Document Number 1": null,
        "List C Expiration Date 1": null,

        "Additional Information": null,
        CB_Alt: null,

        "FirstDayEmployed mmddyyyy": null,
        "Employers Business or Org Name": null,
        "Employers Business or Org Address": null,
        "Last Name First Name and Title of Employer or Authorized Representative":
          null,

        "S2 Todays Date mmddyyyy": moment(),
        authorized_signature: null,
      },
      oldData: {},
      // "Document Title 1": [{ required: true, message: "This field is required" }],
    };
  },

  mounted() {
    this.fetchFields();
    this.fetchAddressAndOwnerName();
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    isListAFilled() {
      return (
        this.formState["Document Title 1"] &&
        this.formState["Issuing Authority 1"]
      );
    },

    isListBFilled() {
      return (
        this.formState["List B Document 1 Title"] &&
        this.formState["List B Issuing Authority 1"] &&
        this.formState["List B Document Number 1"]
      );
    },

    isListCFilled() {
      return (
        this.formState["List C Document Title 1"] &&
        this.formState["List C Issuing Authority 1"] &&
        this.formState["List C Document Number 1"]
      );
    },

    rules() {
      return {
        "Document Title 1": [
          {
            required: !this.isListBFilled && !this.isListCFilled,
            message: "This field is required",
          },
        ],
        "Issuing Authority 1": [
          {
            required: !this.isListBFilled && !this.isListCFilled,
            message: "This field is required",
          },
        ],

        "List B Document 1 Title": [
          { required: !this.isListAFilled, message: "This field is required" },
        ],

        "List B Issuing Authority 1": [
          { required: !this.isListAFilled, message: "This field is required" },
        ],

        "List B Document Number 1": [
          { required: !this.isListAFilled, message: "This field is required" },
        ],

        "List C Document Title 1": [
          { required: !this.isListAFilled, message: "This field is required" },
        ],

        "List C Issuing Authority 1": [
          { required: !this.isListAFilled, message: "This field is required" },
        ],

        "List C Document Number 1": [
          { required: !this.isListAFilled, message: "This field is required" },
        ],

        // "Additional Information": [
        //   { required: true, message: "This field is required" },
        // ],

        "FirstDayEmployed mmddyyyy": [
          { required: true, message: "This field is required" },
        ],

        "Employers Business or Org Name": [
          { required: true, message: "This field is required" },
        ],

        "Employers Business or Org Address": [
          { required: true, message: "This field is required" },
        ],

        "Last Name First Name and Title of Employer or Authorized Representative":
          [{ required: true, message: "This field is required" }],

        "Signature of Employer or AR": [
          { required: true, message: "This field is required" },
        ],

        "S2 Todays Date mmddyyyy": [
          { required: true, message: "This field is required" },
        ],
      };
    },

    employeeFormatted() {
      const data = this.oldData.data?.employee ?? {};

      return [
        {
          label: "Employee",
          value: data.full_name,
        },
        {
          label: "Hire Date",
          value: data.hire_date
            ? moment(data.hire_date).format("MM/DD/YYYY")
            : "-",
        },
        {
          label: "Employee Code",
          value: data.employee_code,
        },
      ];
    },
  },

  methods: {
    fetchAddressAndOwnerName() {
      this.loading = true;
      commonService
        .store(this.$constants.getSettingUrl, {
          key: ["i9_organization_name", "i9_organization_address"],
        })
        .then((res) => {
          this.formState["Employers Business or Org Name"] =
            res.data.i9_organization_name;
          this.formState["Employers Business or Org Address"] =
            res.data.i9_organization_address;
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchFields() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.formI9Show, { id: this.$route.params.id })
        .then((res) => {
          if (res.success) {
            this.oldData = res;
            const employee = res.data.employee;
            this.formState["List B Issuing Authority 1"] =
              employee.license_state;
            this.formState["List B Document Number 1"] = employee.license_no;
            this.formState["List B Expiration Date 1"] =
              employee.license_expiry_date
                ? moment(employee.license_expiry_date, "YYYY-MM-DD")
                : null;

            this.formState["List C Document Number 1"] =
              employee.social_security_no;

            this.formState["FirstDayEmployed mmddyyyy"] = moment(
              employee.hire_date,
              "YYYY-MM-DD"
            );

            const employeeName = this.employee.full_name;
            const employeeTitle = this.employee.title
              ? `(${this.employee.title})`
              : "";
            this.formState[
              "Last Name First Name and Title of Employer or Authorized Representative"
            ] = `${employeeName} ${employeeTitle}`;

            this.formState["Signature of Employer or AR"] =
              this.employee.fl_name;
            this.formState["S2 Todays Date mmddyyyy"] = moment(
              employee.hire_date
            );
          } else {
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cardLoader = false;
        });
    },

    formatData(data) {
      const holder = {
        ...this.oldData.data.data,
        ...data,
        employee_id: this.oldData.data.employee.id,
      };

      function formatDate(key) {
        if (data[key]) {
          holder[key] = moment(holder[key]).format("MM/DD/YYYY");
        }
      }

      formatDate("FirstDayEmployed mmddyyyy");
      formatDate("S2 Todays Date mmddyyyy");
      formatDate("List A.  Document 2. Expiration Date (if any)");
      formatDate("Document Number if any_3");
      formatDate("List B Expiration Date 1");
      formatDate("List C Expiration Date 1");

      if (data["CB_Alt"]) {
        holder["CB_Alt"] = "Yes";
      }

      holder["authorized_signature"] = data["authorized_signature"];
      holder["authorized_signature_at"] = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );

      return holder;
    },

    previewPDF() {
      this.$refs.formI9.validate().then(() => {
        this.pdfLoading = true;
        commonService
          .renderFile(
            this.$constants.formI9Preview,
            this.formatData(this.formState),
            "application/pdf;base64"
          )
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.pdfLoading = false;
          });
      });
    },

    onSubmit() {
      const data = this.formatData(this.formState);
      this.loading = true;
      commonService
        .store(this.$constants.formI9Update, data)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$router.push({ name: "form-i9" });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clear() {
      this.formState.authorized_signature = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.formState.authorized_signature = data;
      return Promise.resolve();
    },
  },

  watch: {
    // watch isListAFilled, isListBFilled and isListCFilled
    isListAFilled() {
      this.$refs.formI9?.clearValidate([
        "List B Document 1 Title",
        "List B Issuing Authority 1",
        "List B Document Number 1",
        "List C Document Title 1",
        "List C Issuing Authority 1",
        "List C Document Number 1",
      ]);
    },

    isListBFilled() {
      this.$refs.formI9?.clearValidate([
        "Document Title 1",
        "Issuing Authority 1",
      ]);
    },

    isListCFilled() {
      this.$refs.formI9?.clearValidate([
        "Document Title 1",
        "Issuing Authority 1",
      ]);
    },
  },
};
</script>
