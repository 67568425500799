<template>
  <a-layout-content>
    <page-header>
      <template #title> Announcements</template>

      <template #buttons>
        <a-button @click="handleCreateNewAnnouncementsEvent" type="primary">
          <a-space><i class="ti ti-plus ti-lg"></i> Add Announcement</a-space>
        </a-button>
      </template>
    </page-header>

    <a-spin :spinning="datatableLoading">
      <a-card size="small">
        <a-table
          @change="handleTableChange"
          @refresh="refresh"
          :columns="columns"
          :dataSource="dataSource"
          :loading="datatableLoading"
          :pagination="pagination"
          :rowKey="(record, index) => record.id"
          :scroll="scroll"
          size="small"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              @handleReset="handleDatatableReset"
              @handleSearch="handleDatatableSearch"
              :filterDropdown="filterDropdown"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #action="{ record }">
            <a-space :size="1">
              <a-tooltip title="Show">
                <a-button
                  @click="handleShowAnnouncementsEvent(record)"
                  size="small"
                  type="link"
                >
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-tooltip title="Edit">
                <a-button
                  @click="handleEditAnnouncementsEvent(record)"
                  size="small"
                  type="link"
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-popconfirm
                @confirm="handleDeleteAnnouncementsEvent(record)"
                cancel-text="No"
                ok-text="Yes"
                title="Are you sure you want to delete this announcement?"
              >
                <a-tooltip title="Delete">
                  <a-button danger size="small" type="link">
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </a-space>
          </template>
        </a-table>
      </a-card>
    </a-spin>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "@/services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminAnnouncementDatatableUrl,
      defaultFilterParams: {
        status: "1",
      },
      columns: [
        {
          title: "Title",
          dataIndex: "title",
        },
        {
          title: "Positions",
          customRender: ({ record }) => {
            const positions = record.employee_positions;
            if (positions.length === 0) return "-";
            return positions.map((position) => position.name).join(", ");
          },
        },
        {
          title: "Roles",
          customRender: ({ record }) => {
            const roles = record.roles;
            if (roles.length === 0) return "-";
            return roles.map((role) => role.name).join(", ");
          },
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          customRender: ({ text, record }) =>
            text
              ? this.$comman.getValueFromObject(
                  record,
                  "employment_type",
                  this.$constants.employment_types
                )
              : "-",
        },
        {
          title: "Expires At",
          dataIndex: "expiry_datetime",
          customRender: ({ text }) => this.$customDate.monthDYHIS(text),
        },
        {
          title: "Action",
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  methods: {
    handleCreateNewAnnouncementsEvent() {
      this.$store.dispatch("drawer/open", {
        title: "Add Announcements",
        path: "admin.announcements.store",
        callback: this.refresh,
      });
    },

    handleShowAnnouncementsEvent(record) {
      this.$store.dispatch("drawer/open", {
        title: "Announcements Details",
        path: "admin.announcements.show",
        callback: this.refresh,
        record: record,
      });
    },

    handleEditAnnouncementsEvent(record) {
      this.$store.dispatch("drawer/open", {
        title: "Edit Announcements",
        path: "admin.announcements.store",
        callback: this.refresh,
        record: record,
      });
    },

    handleDeleteAnnouncementsEvent(announcement) {
      commonService
        .store(this.$constants.adminAnnouncementDeleteUrl, {
          id: announcement.id,
        })
        .then((res) => {
          this.refresh();
          this.$message.success(res["message"]);
        });
    },
  },
};
</script>
