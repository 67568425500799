<template>
  <div>
    <!--  todo input with submit  -->
    <a-row type="flex" :gutter="[10, 10]">
      <a-col :span="24">
        <div style="display: flex; gap: 12px">
          <a-input
            style="width: 100%"
            v-model:value="todo"
            placeholder="What needs to be done?"
            :disabled="addLoading"
            @keyup.enter="addToDo"
          />

          <template v-if="currentlyEditing">
            <a-button
              type="primary"
              :disabled="!todo"
              :loading="saveLoading"
              @click="saveEdit"
            >
              <a-space align="center">
                <i class="ti ti-check ti-lg"></i>
              </a-space>
            </a-button>

            <a-button danger @click="cancelEditing" :loading="saveLoading">
              <close-circle-outlined />
            </a-button>
          </template>

          <a-button
            v-else
            type="primary"
            @click="addToDo"
            :disabled="!todo"
            :loading="addLoading"
          >
            <a-space align="center">
              <i class="ti ti-plus"></i>
            </a-space>
          </a-button>
        </div>
      </a-col>

      <a-divider style="margin: 5px" />

      <template v-if="listsLoading">
        <a-col :span="24">
          <a-spin
            size="large"
            :spinning="listsLoading"
            style="width: 100%; line-height: 100px"
          />
        </a-col>
      </template>

      <template v-else>
        <a-col v-if="todos.length === 0" :span="24">
          <a-empty description="No ToDo found" class="mt-sm-1" />
        </a-col>

        <a-col v-else :span="24">
          <a-row align="middle">
            <a-col
              :span="24"
              v-for="item in todoSorted"
              :key="item.id"
              @mouseenter="item.isHovering = true"
              @mouseleave="item.isHovering = false"
              class="todo-item"
              :style="{
                opacity: currentlyEditing
                  ? currentlyEditing === item.id
                    ? 1
                    : 0.5
                  : 1,
              }"
            >
              <div
                style="display: flex; gap: 8px; justify-content: space-between"
              >
                <a-checkbox
                  v-model:checked="item['is_completed']"
                  @change="() => onCheckboxClick(item)"
                  :disabled="!!currentlyEditing"
                >
                  <a-typography-text v-if="item['is_completed']" delete>
                    {{ item.note }}
                  </a-typography-text>
                  <a-typography-text v-else>{{ item.note }}</a-typography-text>
                </a-checkbox>

                <!--              <a-divider />-->

                <Transition name="fade" v-if="item.isHovering">
                  <!--                <a-row align="middle">-->
                  <!--                  <a-col>-->
                  <div>
                    <a-tooltip v-if="!item['is_completed']" title="Edit">
                      <a-button
                        @click="editTodo(item)"
                        :disabled="!!currentlyEditing"
                        size="small"
                        type="link"
                      >
                        <i class="ti ti-pencil ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                    <!--                  </a-col>-->
                    <!--                  <a-col>-->
                    <!--                  <a-popconfirm
                    title="Are you sure you want to delete this item?"
                    ok-text="Yes"
                    cancel-text="No"
                  >-->
                    <a-tooltip title="Delete">
                      <a-button
                        type="link"
                        size="small"
                        :disabled="!!currentlyEditing"
                        danger
                        @click="deleteToDo(item)"
                      >
                        <i class="ti ti-trash ti-lg" />
                      </a-button>
                    </a-tooltip>
                    <!--                  </a-popconfirm>-->
                  </div>
                  <!--                  </a-col>-->
                  <!--                </a-row>-->
                </Transition>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </template>
    </a-row>
  </div>
</template>

<script setup>
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const todo = ref(null);
const todos = ref([]);

const todoSorted = computed(() => {
  // completed on bottom, active and most recent on top
  return todos.value.toSorted((a, b) => {
    if (a.is_completed && !b.is_completed) return 1;
    if (!a.is_completed && b.is_completed) return -1;
    return moment(b["created_at"]).diff(moment(a["created_at"]));
  });
});

const store = useStore();
const setIncompleteTodoCount = (count) =>
  store.dispatch("auth/set_incomplete_todo_count", count);

function calculateAndSetIncompleteTodoCount() {
  const count = todos.value.filter((item) => !item.is_completed).length;
  setIncompleteTodoCount(count);
}

const addLoading = ref(false);
const listsLoading = ref(true);

// const viewTodoType = ref("all");

function getToDoList() {
  return commonService
    .store(constants.toDoList)
    .then((response) => {
      todos.value = response.data.map((item) => {
        return {
          ...item,
          is_completed: !!item.is_completed,
        };
      });
      calculateAndSetIncompleteTodoCount();
    })
    .catch((e) => console.log({ e }))
    .finally(() => {
      listsLoading.value = false;
    });
}

function addToDo() {
  if (!todo.value?.trim()) return;
  if (currentlyEditing.value) return saveEdit();

  addLoading.value = true;
  return commonService
    .store(constants.toDoListStore, {
      note: todo.value.trim(),
    })
    .then((response) => {
      todo.value = null;
      todos.value.push({
        ...response.data,
        is_completed: !!response.data.is_completed,
      });
      calculateAndSetIncompleteTodoCount();
    })
    .catch((e) => console.log({ e }))
    .finally(() => {
      addLoading.value = false;
    });
}

const currentlyEditing = ref(null);
function editTodo(record) {
  todo.value = record.note;
  currentlyEditing.value = record.id;
}
function cancelEditing() {
  todo.value = null;
  currentlyEditing.value = null;
}

const saveLoading = ref(false);
function saveEdit() {
  if (!todo.value?.trim()) return;

  saveLoading.value = true;
  return commonService
    .store(constants.toDoListStore, {
      id: currentlyEditing.value,
      note: todo.value.trim(),
    })
    .then((response) => {
      const index = todos.value.findIndex(
        (item) => item.id === currentlyEditing.value
      );
      todos.value[index] = response.data;
      todos.value[index].is_completed = !!response.data.is_completed;
      calculateAndSetIncompleteTodoCount();
    })
    .catch((e) => {
      getToDoList();
      calculateAndSetIncompleteTodoCount();
      console.log({ e });
    })
    .finally(() => {
      saveLoading.value = null;
      cancelEditing();
    });
}

function deleteToDo(record) {
  return commonService
    .store(constants.toDoListDelete, {
      id: record.id,
    })
    .then(() => {
      const index = todos.value.findIndex((item) => item.id === record.id);
      todos.value.splice(index, 1);
      calculateAndSetIncompleteTodoCount();
    })
    .catch((e) => {
      getToDoList();
      calculateAndSetIncompleteTodoCount();
      console.log({ e });
    })
    .finally(() => {
      saveLoading.value = null;
      cancelEditing();
    });
}

function onCheckboxClick(record) {
  return commonService
    .store(constants.toDoMarkAsComplete, {
      id: record.id,
      is_completed: record.is_completed,
    })
    .then((response) => {
      const index = todos.value.findIndex((item) => item.id === record.id);
      todos.value[index] = response.data;
      todos.value[index].is_completed = !!response.data.is_completed;
      calculateAndSetIncompleteTodoCount();
    })
    .catch((e) => console.log({ e }));
}

getToDoList();
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.todo-item {
  padding: 5px;
  border-radius: 5px;
  line-height: 26px;
}

.todo-item:hover {
  background-color: #f0f0f0;
}
</style>
