<template>
  <div>
    <div class="section-heading">
      <h2>Reset Password</h2>
      <p>Enter your registered email to get reset password link.</p>
    </div>
    <a-form
      name="forgot_password_form"
      ref="forgot_password_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-form-item ref="username" label="Username" name="username">
        <a-input
          v-model:value="formState.username"
          placeholder="Enter username here"
        />
      </a-form-item>
      <a-button
        size="large"
        type="primary"
        html-type="submit"
        :loading="loading"
        block
        >Reset Password</a-button
      >
      <router-link :to="{ name: 'login' }" class="btn-link"
        >Back To Sign In</router-link
      >
    </a-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      formState: {
        username: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "Username is required!",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Username must be a valid email!",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions("auth", ["forgot_password"]),

    onSubmit() {
      this.loading = true;
      this.forgot_password(this.formState)
        .then((res) => {
          console.log("res::", res);
        })
        .catch((err) => {
          console.log("err::", err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
