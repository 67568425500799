<template>
  <div>
    <a-descriptions title="" :column="2">
      <a-descriptions-item label="First Name">
        {{ record.first_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Middle Name">
        {{ record.middle_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Last Name">
        {{ record.last_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Email">
        {{ record.email }}
      </a-descriptions-item>
      <a-descriptions-item label="Contact Number">
        {{ record.contact_1 }}
      </a-descriptions-item>
      <a-descriptions-item label="Company">
        {{ $comman.company_name(record.company_id, extra.companies) }}
      </a-descriptions-item>
      <a-descriptions-item label="Employee Type">
        {{ $comman.employee_type(record.employee_type) }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
  },
};
</script>

<style></style>
