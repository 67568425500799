<template>
  <div>
    <div class="section-heading">
      <h2>Reset Password</h2>
    </div>
    <a-form
      name="reset_password_form"
      ref="reset_password_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-form-item
        ref="password"
        label="Password"
        name="password"
        :validateStatus="validate('password', 'status')"
        :help="validate('password', 'msg')"
      >
        <a-input-password
          v-model:value="formState.password"
          placeholder="Enter password here"
          @change="resetErrors('password')"
        />
      </a-form-item>

      <a-form-item
        ref="password_confirmation"
        label="Confirm Password"
        name="password_confirmation"
      >
        <a-input-password
          v-model:value="formState.password_confirmation"
          placeholder="Enter confirm password here"
        />
      </a-form-item>

      <a-button
        size="large"
        type="primary"
        html-type="submit"
        :loading="loading"
      >
        Reset Password
      </a-button>
    </a-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formMixins from "../../mixins/formMixins";

export default {
  mixins: [formMixins],
  data() {
    return {
      loading: false,
      formState: {
        password: "",
        password_confirmation: "",
        token: this.$route.params.token,
      },
      rules: {
        password: [
          {
            required: true,
            validator: this.password_validation,
            message:
              "Password must contain at least 10 character, one lowercase, one uppercase, one digit and one special character!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: this.password_confirmation_validation,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions("auth", ["reset_password"]),

    onSubmit() {
      this.errors = {};
      this.loading = true;
      this.reset_password(this.formState)
        .then((res) => {
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          if (typeof err !== "string") {
            this.errors = err;
          } else {
            this.$message.error(err);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    password_validation(rule, value) {
      const reg =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]*$/;
      if (value === "") {
        return Promise.reject("password is required!");
      } else if (value.length < 10) {
        return Promise.reject(
          "password length must be more then 10 character!"
        );
      } else if (!reg.test(value)) {
        return Promise.reject(
          "Password must contain at least one lowercase, one uppercase, one digit and one special character!"
        );
      } else {
        if (this.formState.password_confirmation !== "") {
          this.$refs.reset_password_form.validateFields(
            "password_confirmation"
          );
        }
        return Promise.resolve();
      }
    },
    password_confirmation_validation(rule, value) {
      if (value === "") {
        return Promise.reject("confirm password is required!");
      } else if (value !== this.formState.password) {
        return Promise.reject("confirm password don't match with password!");
      } else {
        return Promise.resolve();
      }
    },
  },
};
</script>
