<template>
  <a-form
    name="basic_info_form"
    ref="basic_info_form"
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit()"
  >
    <a-row :gutter="[20, 20]" justify="space-between">
      <a-col :span="24">
        <a-typography-title :level="4"> Basic Details </a-typography-title>
        <a-divider style="margin: 12px 0" />
      </a-col>

      <a-col :span="24">
        <a-descriptions title="" :column="1" size="small" bordered>
          <a-descriptions-item label="Email">
            {{ employee.email }}
          </a-descriptions-item>
          <a-descriptions-item label="Company Name">
            {{
              comman.company_name(
                employee.company_id,
                companies.length > 0 ? companies : []
              )
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Employee Type">
            {{ comman.employee_type(employee.employee_type) }}
          </a-descriptions-item>
        </a-descriptions>

        <a-divider />

        <a-row :gutter="[20, 0]">
          <a-col :xs="24" :lg="8">
            <a-form-item ref="first_name" label="First Name" name="first_name">
              <a-input
                v-model:value="formState.first_name"
                placeholder="Enter first name here"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item
              ref="middle_name"
              label="Middle Name"
              name="middle_name"
            >
              <a-input
                v-model:value="formState.middle_name"
                placeholder="Enter middle name here"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item ref="last_name" label="Last Name" name="last_name">
              <a-input
                v-model:value="formState.last_name"
                placeholder="Enter last name here"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item
              ref="contact_no"
              label="Contact Number"
              name="contact_no"
            >
              <a-input
                v-model:value="formState.contact_no"
                v-maska="$constants.contactNumberMask"
                :placeholder="$constants.contactNumberPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item ref="birthdate" label="Birthdate" name="birthdate">
              <a-date-picker
                v-model:value="formState.birthdate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :disabled-date="disabledDate"
                :allow-clear="false"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item ref="gender" label="Gender" name="gender">
              <a-radio-group name="radioGroup" v-model:value="formState.gender">
                <a-radio
                  v-for="(v, i) in $constants.gender"
                  :key="`${i}Gender`"
                  :value="v.value"
                >
                  {{ v.text }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col v-if="false" :xs="24" :lg="8">
            <a-form-item
              ref="blood_group"
              label="Blood Group"
              name="blood_group"
            >
              <a-select
                v-model:value="formState.blood_group"
                placeholder="select blood group type"
              >
                <a-select-option
                  v-for="type in $constants.blood_groups"
                  :key="type"
                  :value="type"
                >
                  {{ type }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="8">
            <a-form-item ref="street_address" name="street_address">
              <template #label>
                <a-row :gutter="[0]" align="middle">
                  <a-col> Street Address</a-col>
                  <a-col>
                    <a-tooltip title="Do not include City, State or ZIP Code">
                      <a-button type="link" size="small">
                        <i class="fa fa-info-circle"></i>
                      </a-button>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </template>

              <a-input
                v-model:value="formState.street_address"
                :maxlength="30"
                placeholder="Enter street address here..."
              />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="8">
            <a-form-item ref="city" label="City" name="city">
              <a-input
                v-model:value="formState.city"
                placeholder="Enter city here..."
              />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="8">
            <a-form-item ref="state" label="State" name="state">
              <a-select
                v-model:value="formState.state"
                placeholder="Select state here..."
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="state in $constants.states"
                  :key="state.name"
                  :label="state.name"
                  :value="state.name"
                >
                  {{ state.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="8">
            <a-form-item ref="zipcode" label="Zip Code" name="zipcode">
              <a-input
                v-model:value="formState.zipcode"
                placeholder="Enter zip code here..."
              />
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="16">
            <a-form-item ref="profile_pic" name="profile_pic">
              <template #label>
                <a-row :gutter="[0]" align="middle">
                  <a-col> Profile Picture </a-col>
                  <a-col>
                    <a-tooltip title="Max File Size: 5MB">
                      <a-button type="link" size="small">
                        <i class="fa fa-info-circle"></i>
                      </a-button>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </template>
              <a-upload
                v-model:file-list="formState.profile_pic"
                :before-upload="(file) => beforeUpload('profile_pic', file)"
                @change="
                  ({ file }) =>
                    onFileAdd(
                      'profile_pic',
                      file,
                      'image/jpeg,image/jpg,image/png,application/pdf'
                    )
                "
                accept="image/jpeg,image/jpg,image/png"
                :remove="() => (formState.profile_pic = [])"
              >
                <div>
                  <a-button>
                    <UploadOutlined />
                    Upload
                  </a-button>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider />

        <a-row :gutter="[20, 0]">
          <a-col :xs="24" :lg="8">
            <a-form-item
              ref="emergency_name"
              label="Emergency Contact Name"
              name="emergency_name"
            >
              <a-input
                v-model:value="formState.emergency_name"
                placeholder="Enter emergency contact name here"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item
              ref="emergency_contact"
              label="Emergency Contact Number"
              name="emergency_contact"
            >
              <a-input
                v-model:value="formState.emergency_contact"
                v-maska="$constants.contactNumberMask"
                :placeholder="$constants.contactNumberPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="8">
            <a-form-item
              ref="emergency_relation"
              label="Emergency Contact Relation"
              name="emergency_relation"
            >
              <a-input
                v-model:value="formState.emergency_relation"
                placeholder="Enter emergency contact relation here"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider />

        <a-row :gutter="[20, 0]">
          <a-col :xs="24" :lg="12">
            <a-form-item
              ref="social_security_no"
              label="Social Security Number"
              name="social_security_no"
            >
              <a-input
                v-model:value="formState.social_security_no"
                v-maska="'###-##-####'"
                placeholder="###-##-####"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="12">
            <a-form-item
              ref="social_security_attachment"
              name="social_security_attachment"
            >
              <template #label>
                <a-row :gutter="[0]" align="middle">
                  <a-col> Social Security Attachment </a-col>
                  <a-col>
                    <a-tooltip title="Max File Size: 5MB">
                      <a-button type="link" size="small">
                        <i class="fa fa-info-circle"></i>
                      </a-button>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </template>

              <a-upload
                v-model:file-list="formState.social_security_attachment"
                :before-upload="
                  (file) => beforeUpload('social_security_attachment', file)
                "
                @change="
                  ({ file }) =>
                    onFileAdd(
                      'social_security_attachment',
                      file,
                      'image/jpeg,image/jpg,image/png,application/pdf'
                    )
                "
                accept="image/jpeg,image/jpg,image/png,application/pdf"
                :remove="() => (formState.social_security_attachment = [])"
              >
                <div>
                  <a-button>
                    <UploadOutlined />
                    Upload
                  </a-button>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider />

        <a-row :gutter="[20, 0]">
          <a-col :xs="24" :lg="12">
            <a-form-item
              ref="license_no"
              label="Driver's License Number"
              name="license_no"
            >
              <a-input
                v-model:value="formState.license_no"
                placeholder="Enter driver's license number here"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="12">
            <a-form-item
              ref="license_state"
              label="Driver's License State"
              name="license_state"
            >
              <a-select
                v-model:value="formState.license_state"
                placeholder="Select license state here..."
                optionFilterProp="label"
                showSearch
                allowClear
              >
                <a-select-option
                  v-for="state in $constants.states"
                  :key="state.name"
                  :label="state.name"
                  :value="state.name"
                >
                  {{ state.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="12">
            <a-form-item
              ref="license_expiry_date"
              label="Driver's License Expiration Date"
              name="license_expiry_date"
            >
              <a-date-picker
                v-model:value="formState.license_expiry_date"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
                :allow-clear="false"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item ref="license_attachment" name="license_attachment">
              <template #label>
                <a-row :gutter="[0]" align="middle">
                  <a-col> Driver's License Attachment </a-col>
                  <a-col>
                    <a-tooltip title="Max File Size: 5MB">
                      <a-button type="link" size="small">
                        <i class="fa fa-info-circle"></i>
                      </a-button>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </template>
              <a-upload
                v-model:file-list="formState.license_attachment"
                :before-upload="
                  (file) => beforeUpload('license_attachment', file)
                "
                @change="
                  ({ file }) =>
                    onFileAdd(
                      'license_attachment',
                      file,
                      'image/jpeg,image/jpg,image/png,application/pdf'
                    )
                "
                accept="image/jpeg,image/jpg,image/png,application/pdf"
                :remove="() => (formState.license_attachment = [])"
              >
                <div>
                  <a-button>
                    <UploadOutlined />
                    Upload
                  </a-button>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="24">
        <footer-buttons
          :current-step="currentStepComputed"
          :steps="steps"
          :disable-next="!hasBasicInfoFilled"
          :loading="loading"
          @next-step="currentStepComputed += 1"
          @previous-step="currentStepComputed -= 1"
        />
      </a-col>
    </a-row>
  </a-form>
</template>

<script setup>
import FooterButtons from "@/components/newUserOnboarding/FooterButtons.vue";
import { comman, comman as commonFunctions } from "@/helper/comman";
import { companyService } from "@/services";
import { UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import moment from "moment/moment";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  currentStep: Number,
  steps: Array,
});
const emit = defineEmits(["update:currentStep"]);

const currentStepComputed = computed({
  get: () => props.currentStep,
  set: (value) => emit("update:currentStep", value),
});

const store = useStore();
const employee = computed(() => store.getters["auth/employee"]);

const rules = {
  first_name: [
    {
      required: true,
      message: "first name is required!",
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  last_name: [
    {
      required: true,
      message: "last name is required!",
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  email: [
    {
      required: true,
      message: "email is required!",
    },
    {
      type: "email",
      message: "email must be a valid email!",
    },
  ],
  contact_no: [
    {
      required: true,
      validator: (rule, value) => {
        const reg = /^\d{3}-?\d{3}-?\d{4}$/;
        value = value.trim();
        if (value == "") {
          return Promise.reject("Contact number field is required!");
        }
        if (value && !reg.test(value)) {
          return Promise.reject("Invalid contact number!");
        }
        return Promise.resolve();
      },
    },
  ],
  birthdate: [
    {
      required: true,
      type: "date",
      message: "birthdate is required!",
    },
  ],
  blood_group: [
    {
      message: "blood group is required!",
    },
  ],
  street_address: [
    {
      required: true,
      message: "street address is required!",
    },
  ],
  city: [
    {
      required: true,
      message: "city is required!",
    },
  ],
  state: [
    {
      required: true,
      message: "state is required!",
    },
  ],
  zipcode: [
    {
      required: true,
      validator: (rule, value) => {
        const reg = /^\d{5}$/;
        value = value.trim();
        if (value == "") {
          return Promise.reject("Zip code field is required!");
        }
        if (value && !reg.test(value)) {
          return Promise.reject("Invalid zip code!");
        }
        return Promise.resolve();
      },
    },
  ],
  emergency_name: [
    {
      required: true,
      message: "Emergency contact name field is required!",
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  emergency_contact: [
    {
      required: true,
      validator: (rule, value) => {
        const reg = /^\d{3}-?\d{3}-?\d{4}$/;
        value = value.trim();
        if (value == "") {
          return Promise.reject("Emergency contact number field is required!");
        }
        if (value && !reg.test(value)) {
          return Promise.reject("Invalid emergency contact number!");
        }
        return Promise.resolve();
      },
    },
  ],
  emergency_relation: [
    {
      required: true,
      message: "Emergency contact relation field is required!",
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  social_security_no: [
    {
      required: true,
      validator: (rule, value) => {
        const reg = /^\d{3}-?\d{2}-?\d{4}$/;
        value = value.trim();
        if (value == "") {
          return Promise.reject("Social security number is required!");
        }
        if (value && !reg.test(value)) {
          return Promise.reject("Invalid social security number!");
        }
        return Promise.resolve();
      },
    },
  ],
  social_security_attachment: [
    {
      required: true,
      type: "array",
      message: "Social security attachment is required!",
    },
  ],
  license_no: [
    {
      required: true,
      message: "Driver's license number is required!",
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  license_state: [
    {
      required: true,
      message: "Driver's license state is required!",
    },
  ],
  license_expiry_date: [
    {
      required: true,
      type: "date",
      message: "Driver's license expiration date is required!",
    },
  ],
  license_attachment: [
    {
      required: true,
      type: "array",
      message: "Driver's license attachment is required!",
    },
  ],
};
const formState = ref({});
const companies = ref([]);
const loading = ref(false);

function getFormState() {
  if (!employee.value) return;

  formState.value = {
    first_name: employee.value.first_name ?? "",
    middle_name: employee.value.middle_name ?? "",
    last_name: employee.value.last_name ?? "",
    email: employee.value.email ?? "",
    contact_no: employee.value.contact_no ?? "",
    birthdate: employee.value.birthdate
      ? moment(employee.value.birthdate)
      : null,
    gender: employee.value.gender ?? null,
    blood_group: employee.value.blood_group ?? null,
    street_address: employee.value.street_address ?? null,
    city: employee.value.city ?? null,
    state: employee.value.state ?? null,
    zipcode: employee.value.zipcode ?? null,
    profile_pic: employee.value.profile_pic
      ? [
          {
            uid: employee.value.id,
            status: "Done",
            name: employee.value.profile_pic.split("profile_pic/")[1],
            url: employee.value.profile_pic_url,
            thumbUrl: employee.value.profile_pic_url,
          },
        ]
      : [],
    emergency_name: employee.value.emergency_name ?? "",
    emergency_contact: employee.value.emergency_contact ?? "",
    emergency_relation: employee.value.emergency_relation ?? "",
    social_security_no: employee.value.social_security_no ?? "",
    social_security_attachment: employee.value.social_security_attachment
      ? [
          {
            uid: employee.value.id,
            status: "Done",
            name: employee.value.social_security_attachment.split(
              "social_security_no/"
            )[1],
            url: employee.value.social_security_attachment_url,
            thumbUrl: employee.value.social_security_attachment_url,
          },
        ]
      : [],
    license_no: employee.value.license_no ?? "",
    license_state: employee.value.license_state ?? undefined,
    license_expiry_date: employee.value.license_expiry_date
      ? moment(employee.value.license_expiry_date)
      : null,
    license_attachment: employee.value.license_attachment
      ? [
          {
            uid: employee.value.id,
            status: "Done",
            name: employee.value.license_attachment.split(
              "license_attachment/"
            )[1],
            url: employee.value.license_attachment_url,
            thumbUrl: employee.value.license_attachment_url,
          },
        ]
      : [],
  };
}
onMounted(() => {
  getFormState();
  commonFunctions.get_companies().then((res) => {
    companies.value = res;
  });
});

const basic_info_form = ref(null);

const disabledDate = (current) => {
  return current && current > moment().subtract(0, "years").startOf("day");
};

const beforeUpload = (property, file) => {
  const items = formState.value[property];
  items.forEach(() => items.pop());
  items.push(file);
  return false;
};

const onFileAdd = (property, file, types) => {
  const items = formState.value[property];
  if (!items) return;
  const acceptedTypes = types.split(",");

  if (!acceptedTypes.includes(file.type)) {
    message.error(`File type of ${file.type} is not supported.`);
    items.pop();
    return;
  }

  if (file.size / 1024 / 1024 > 5) {
    message.error(`File size of ${file.name} is too large. Max: 5MB`);
    items.pop();
    return false;
  }
};

const onSubmit = () => {
  basic_info_form.value
    .validate()
    .then((val) => {
      loading.value = true;

      const data = new FormData();
      // data.append("_method", "PUT")
      const plainFields = [
        "first_name",
        "middle_name",
        "last_name",
        "email",
        "contact_no",
        "gender",
        "blood_group",
        "street_address",
        "city",
        "state",
        "zipcode",
        "emergency_name",
        "emergency_contact",
        "emergency_relation",
        "social_security_no",
        "license_no",
        "license_state",
      ];
      const dateFields = ["birthdate", "license_expiry_date"];
      const attachmentFields = [
        "profile_pic",
        "social_security_attachment",
        "license_attachment",
      ];

      plainFields.forEach((field) =>
        data.append(field, formState.value[field])
      );
      dateFields.forEach((field) =>
        data.append(field, formState.value[field].format("DD-MM-YYYY"))
      );
      attachmentFields.forEach((field) => {
        if (!formState.value[field].length) return;
        const file = formState.value[field][0];
        if (file.url) data.append(`old_${field}`, true);
        else data.append(field, formState.value[field][0]);
      });

      companyService
        .store("api/employee/basic-profile-update", data, true)
        .then(async (res) => {
          if (res.success) {
            message.success(res.message);
            localStorage.setItem("employee", JSON.stringify(res.data));
            await store.dispatch("auth/login", { isInitStore: false });
            getFormState();
            currentStepComputed.value += 1;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.value = false;
        });
    })
    .catch((error) => {
      console.log("error", error);
    });
};

const hasBasicInfoFilled = computed(() => {
  const socialSecurity = employee.value.social_security_no;
  const license = employee.value.license_no;

  return !!socialSecurity && !!license;
});
</script>

<style lang="less" scoped></style>
