<template>
  <div>
    <a-form
      name="assign_single_form"
      ref="assign_single_form"
      :model="formState"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="allocated_date"
            label="Allocated Date"
            name="allocated_date"
            :rules="{
              required: true,
              message: 'This field is required',
              type: 'date',
            }"
          >
            <a-date-picker
              :allowClear="false"
              v-model:value="formState.allocated_date"
              :placeholder="$constants.datePickerPlaceholder"
              :format="$constants.datepickerFormat"
              :disabledDate="$comman.disabledFutureDate"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider class="mt-sm-0 mb-sm-1"></a-divider>

      <a-row :gutter="[20, 0]">
        <a-col :span="18">
          <b>Asset Inventory</b>
        </a-col>
        <a-col :span="6"> </a-col>

        <template v-for="(asset, index) in formState.items" :key="index">
          <a-col :span="18">
            <a-form-item
              ref="asset_inventory_id"
              :name="['items', index, 'asset_inventory_id']"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'number',
              }"
            >
              <a-select
                v-model:value="asset.asset_inventory_id"
                placeholder="Select asset"
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="option in unSelectedAssetInventory(asset)"
                  :key="option.id"
                  :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                  :value="option.id"
                >
                  {{ option.asset_product?.name }} | {{ option.model_no }} |
                  {{ option.serial_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-space>
              <a-button
                v-if="formState.items.length > 1"
                type="ghost"
                danger
                size="small"
                @click="removeItem(index)"
              >
                <i class="ti ti-trash ti-lg"></i>
              </a-button>

              <a-button
                v-if="formState.items.length == index + 1"
                type="primary"
                size="small"
                @click="addItem"
              >
                <i class="ti ti-plus ti-lg"></i>
              </a-button>
            </a-space>
          </a-col>
        </template>
      </a-row>

      <side-drawer-buttons name="Create" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        allocated_date: null,
        items: [
          {
            asset_inventory_id: undefined,
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    hospitalId() {
      return this.extra?.hospitalId ?? null;
    },

    assetInventory() {
      return this.extra?.assetInventory ?? [];
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    addItem() {
      this.formState.items.push({
        asset_inventory_id: undefined,
      });
    },

    removeItem(index) {
      this.formState.items = this.formState.items.filter((_, i) => index != i);
    },

    unSelectedAssetInventory(asset) {
      let selectedAssetInventoryIds = this.formState.items.map(
        (item) => item.asset_inventory_id
      );
      return this.assetInventory.filter(
        (item) =>
          !selectedAssetInventoryIds.includes(item.id) ||
          asset.asset_inventory_id == item.id
      );
    },

    onSubmit() {
      let data = {
        hospital_id: this.hospitalId,
        allocated_date: this.$customDate.ymd(this.formState.allocated_date),
        items: this.formState.items,
      };

      this.loadingStart();
      commonService
        .store(this.$constants.assetInventoryHospitalSingleUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
