<template>
  <div>
    <a-layout-content>
      <a-page-header
        class="page-header"
        title="Show Policy Rule Document"
        style="margin-bottom: 24px; background-color: #fff"
        @back="() => $router.go(-1)"
      >
        <template #extra>
          <template v-if="record">
            <a-button
              v-if="record?.is_issue_certificate"
              type="primary"
              @click="showCertificatePreview(record)"
              :loading="record?.previewLoader"
            >
              <a-space>
                <i class="ti ti-file-text ti-lg"></i> Preview Certificate
              </a-space>
            </a-button>
            <a-button
              v-else
              type="primary"
              @click="previewPDF(record)"
              :loading="record?.previewLoader"
            >
              <a-space>
                <i class="ti ti-file-text ti-lg"></i> Preview PDF
              </a-space>
            </a-button>
          </template>
        </template>
      </a-page-header>

      <a-card :loading="loading" size="small">
        <a-descriptions
          :column="1"
          size="small"
          bordered
          :labelStyle="{ width: '150px' }"
        >
          <a-descriptions-item label="Name">
            {{ record.name }}
          </a-descriptions-item>
          <a-descriptions-item label="Type">
            {{
              $comman.getValueFromObject(
                record,
                "type",
                $constants.onboardingDocTypes
              )
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Policy Group">
            {{ record.policy_group?.name ?? "-" }}
          </a-descriptions-item>
          <a-descriptions-item label="Date">
            {{ record.date ? $customDate.mdy(record.data) : "-" }}
          </a-descriptions-item>
          <a-descriptions-item v-if="record.attachment_file" label="Attachment">
            <a-typography-link
              :href="record.attachment_file_path"
              target="_blank"
            >
              {{ record.attachment_file }}
            </a-typography-link>
          </a-descriptions-item>
          <a-descriptions-item label="Employment Type">
            {{
              $comman.getValueFromObject(
                record,
                "employment_type",
                $constants.policyRuleEmploymentTypes
              )
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Does this apply to all positions?">
            {{ record.is_for_all_position ? "Yes" : "No" }}
          </a-descriptions-item>
          <a-descriptions-item label="Acknowledgement/Signature Message">
            {{ record.acknowledgement_message ?? "-" }}
          </a-descriptions-item>
          <a-descriptions-item
            v-if="record.is_for_all_position == 0"
            label="Positions"
          >
            {{
              record?.employee_positions?.map((e) => e.name)?.join(", ") ?? ""
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Description">
            <div class="text-content" v-html="record.description ?? 'N/A'" />
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      record: null,
    };
  },

  computed: {
    moment() {
      return moment;
    },
    //
  },

  mounted() {
    this.getOnboardingDocument();
  },

  methods: {
    getOnboardingDocument() {
      commonService
        .get(this.$constants.policyRuleDocumentShowUrl, {
          id: this.$route.params.id,
        })
        .then((res) => {
          this.record = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.loading = false));
    },
    previewPDF(record) {
      record.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.policyRuleDocumentPDFPreviewUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          delete record.previewLoader;
        });
    },
    showCertificatePreview(record) {
      record.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.policyRuleDocumentCertificatePreviewUrl,
          {
            certificate_content: record.certificate_content,
            certificate_name: record.name,
          },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          delete record.previewLoader;
        });
    },
  },
};
</script>

<style scoped>
.text-content:deep p {
  margin-bottom: 0;
}
</style>
