<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-form-item
        ref="cytotoxic_drug_type_and_dose"
        label="Cytotoxic Drug Type & Dose"
        name="cytotoxic_drug_type_and_dose"
        :validateStatus="validate('cytotoxic_drug_type_and_dose', 'status')"
        :help="validate('cytotoxic_drug_type_and_dose', 'msg')"
      >
        <a-input
          v-model:value="model.cytotoxic_drug_type_and_dose"
          placeholder="Enter cytotoxic drug type & dose here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="prime_type_and_amount"
        label="Prime Type and Amount (mLs)"
        name="prime_type_and_amount"
        :validateStatus="validate('prime_type_and_amount', 'status')"
        :help="validate('prime_type_and_amount', 'msg')"
      >
        <a-input
          v-model:value="model.prime_type_and_amount"
          placeholder="Enter prime type and amount in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="average_inflow_temperature"
        label="Average Inflow Temperature (degrees C)"
        name="average_inflow_temperature"
        :validateStatus="validate('average_inflow_temperature', 'status')"
        :help="validate('average_inflow_temperature', 'msg')"
      >
        <a-input
          v-model:value="model.average_inflow_temperature"
          placeholder="Enter average inflow temperature in degrees here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="average_outflow_temperature"
        label="Average outflow Temperature (degrees C)"
        name="average_outflow_temperature"
        :validateStatus="validate('average_outflow_temperature', 'status')"
        :help="validate('average_outflow_temperature', 'msg')"
      >
        <a-input
          v-model:value="model.average_outflow_temperature"
          placeholder="Enter average outflow temperature in degrees here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="pump_flow_rate"
        label="Pump Flow Rate"
        name="pump_flow_rate"
        :validateStatus="validate('pump_flow_rate', 'status')"
        :help="validate('pump_flow_rate', 'msg')"
      >
        <a-input
          v-model:value="model.pump_flow_rate"
          placeholder="Enter pump flow rate here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        ref="average_reservoir_volume"
        label="Average Reservoir Volume (mLs)"
        name="average_reservoir_volume"
        :validateStatus="validate('average_reservoir_volume', 'status')"
        :help="validate('average_reservoir_volume', 'msg')"
      >
        <a-input
          v-model:value="model.average_reservoir_volume"
          placeholder="Enter average reservoir volume in mls here"
        />
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        ref="additional_comments"
        label="Additional Comments"
        name="additional_comments"
        :validateStatus="validate('additional_comments', 'status')"
        :help="validate('additional_comments', 'msg')"
      >
        <a-textarea
          v-model:value="model.additional_comments"
          placeholder="Enter any additional comments here"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: { require: true, type: Object },
  },

  emits: ["validate"],

  methods: {
    validate(field, type) {
      return this.$emit("validate", field, type);
    },
  },
};
</script>
