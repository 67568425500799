<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #title>
      <a-row :gutter="[16, 0]">
        <a-col :span="6">
          <a-range-picker
            v-model:value="filters.date_range"
            :format="$constants.datepickerFormat"
            :placeholder="[
              $constants.datePickerPlaceholder,
              $constants.datePickerPlaceholder,
            ]"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            v-model:value="filters.search_text"
            placeholder="Search hospital here..."
            allowClear
          />
        </a-col>
        <a-col :span="12">
          <a-space>
            <a-button type="primary" @click="fetchData"> Search </a-button>
            <a-button ghost danger @click="reset">Reset</a-button>
            <a-button type="primary" @click="handleExportEvent('pdf')">
              Export PDF
            </a-button>
            <a-button type="primary" @click="handleExportEvent('excel')">
              Export Excel
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>

    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #amount="{ record }">
        {{ $comman.withCurrency(record.amount) }}
        <template v-if="record.quickbooks_bill_id">
          (Bill Id: {{ record.quickbooks_bill_id }})
        </template>
      </template>

      <template #action="{ record }">
        <a-button
          type="link"
          size="small"
          class="pl-sm-0 primary-color"
          @click="handleShowExpenseEvent(record)"
        >
          <i class="ti ti-eye ti-lg"></i>
        </a-button>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminExpenseDatatableUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
        },
        {
          title: "Expense Type",
          dataIndex: "expense_type_name",
          key: "expense_type_name",
          customRender: ({ record }) => record.expense_type?.name,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          slots: {
            customRender: "amount",
          },
          defaultSortOrder:
            this.$route.query.sortField == "amount"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Expense Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "start_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Expense End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          defaultSortOrder:
            this.$route.query.sortField == "end_date"
              ? this.$route.query.sortOrder
              : undefined,
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.expenseStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      extraFilters: {
        employee_id: parseInt(this.$route.params.id),
      },
      filters: {
        date_range: [],
        search_text: "",
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowExpenseEvent(record) {
      this.open({
        title: "Show Expense Details",
        path: "admin.expense.show",
        callback: this.refresh,
        record: record,
      });
    },

    fetchData() {
      this.refresh(this.filters);
    },

    reset() {
      this.filters.date_range = [];
      this.filters.search_text = "";
      this.refresh(this.filters);
    },

    handleExportEvent(type) {
      let url = this.$constants.adminExpenseExportPdfUrl;
      let fileName = "Employee Time Tracking.pdf";
      if (type == "excel") {
        url = this.$constants.adminExpenseExportExcelUrl;
        fileName = "Employee Time Tracking.xlsx";
      }

      var params = {
        employee_id: this.extraFilters.employee_id,
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
      };

      this.datatableLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.refresh({ employee_id: parseInt(val) });
      }
    },
  },
};
</script>
