<template>
  <template v-if="loading">
    <a-card size="small" :loading="loading"> </a-card>
  </template>

  <template v-else>
    <div>
      <AgreementModal
        v-model:handbookModal="showDialog"
        v-if="selectedAgreement"
        :agreement="selectedAgreement"
        :closeDialog="closeDialog"
        :employee="employee"
      />
      <a-row :gutter="[12, 12]">
        <a-col v-for="i in logs" :key="i.id" :span="24">
          <a-card
            size="small"
            :style="{ borderColor: i.status === 1 ? '#16a34a' : 'red' }"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
              "
            >
              <div>
                <span> {{ i.onboarding_document.name }} </span>
                <a-typography-text v-if="i.status === 1">
                  ({{ $customDate.mdyhis(i.document_signed_at) }})
                </a-typography-text>
              </div>
              <a-button
                v-if="i.status === 1"
                @click="openDialog(i)"
                style="
                  background-color: #16a34a;
                  border-color: #16a34a;
                  color: white;
                "
              >
                View
              </a-button>
              <a-button danger type="primary" @click="openDialog(i)" v-else>
                Pending
              </a-button>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </template>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import agreementModal from "../../components/agreementModal.vue";
import { commonService } from "../../services";

export default {
  data() {
    return {
      loading: true,
      logs: [],
      showDialog: false,
      selectedAgreement: null,
      moment,
    };
  },

  components: {
    AgreementModal: agreementModal,
  },

  props: ["companies"],

  mounted() {
    this.fetchData();
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
  },

  methods: {
    ...mapActions("auth", ["login"]),
    fetchData() {
      this.loading = true;
      commonService
        .get(this.$constants.employeeOnboardingLogs)
        .then((res) => {
          this.logs = res.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    openDialog(agreement) {
      this.showDialog = true;
      this.selectedAgreement = agreement;
    },
    async closeDialog(runCallback = false) {
      this.showDialog = false;
      this.selectedAgreement = null;
      if (runCallback) {
        await this.fetchData();
        await this.login({ isInitStore: false });
      }
    },
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.selectedAgreement = null;
      }
    },
  },
};
</script>
