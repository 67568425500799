export default function auth({ to, next, store }) {
  // console.log('this is auth middleware')
  // console.log(to.name);
  if (!store.getters["auth/isLoggedIn"]) {
    return next({
      name: "login",
      query: { redirectTo: to.fullPath }
    });
  }
  return next();
}
