<template>
  <div>
    <a-form
      name="expense_type_form"
      ref="expense_type_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
              @change="resetErrors('name')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="help_text"
            label="Help Text"
            name="help_text"
            :validateStatus="validate('help_text', 'status')"
            :help="validate('help_text', 'msg')"
          >
            <a-textarea
              v-model:value="formState.help_text"
              placeholder="Enter help text here"
              @change="resetErrors('help_text')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-descriptions title="" layout="vertical" bordered size="small">
            <a-descriptions-item :label="formState.name">
              <div v-html="formState.help_text" />
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  mounted() {
    this.getFormState();
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        help_text: this.record.help_text ?? "",
      };
    },

    handleFinish(val) {
      if (this.record.id) {
        val.id = this.record.id;
      } else {
        val.id = 0;
      }
      this.loadingStart();
      commonService
        .store(this.$constants.expenseTypeStoreUrl, val)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
