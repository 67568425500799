<template>
  <a-button class="bg-grey-color" @click="handleClick">
    <i class="fas fa-arrow-left mr-xs-1"></i> Back
  </a-button>
</template>

<script>
import router from "../router";

export default {
  props: {
    listeners: {
      default: false,
    },
  },

  emits: {
    handleClick: {
      required: false,
    },
  },

  methods: {
    handleClick() {
      if (this.listeners) {
        this.$emit("handleClick");
      } else {
        router.back();
      }
    },
  },
};
</script>
