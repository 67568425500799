<template>
  <div>
    <a-form
      name="policy_and_procedure_manual_form"
      ref="policy_and_procedure_manual_form"
      :model="model"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="pdf"
            name="pdf"
            :validateStatus="validate('pdf', 'status')"
            :help="validate('pdf', 'msg')"
          >
            <a-upload-dragger
              :fileList="model.pdf"
              :remove="handleRemoveReceipt"
              :before-upload="beforeUploadReceipt"
              accept="application/pdf"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">Max file size: 10MB<br /></p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { mapActions, mapGetters } from "vuex";
import { InboxOutlined } from "@ant-design/icons-vue";
import formMixins from "../../mixins/formMixins";

export default {
  emits: {
    callback: {
      required: false,
    },
  },

  components: { InboxOutlined },

  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImage: "",
      model: {
        id: 1,
        pdf: null,
      },
      rules: {},
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      const formData = new FormData();
      formData.append("id", this.model.id);
      formData.append("pdf", this.model.pdf[0]);
      this.loadingStart();

      commonService
        .store(this.$constants.policyAndProcedureManualStoreUrl, formData)
        .then((res) => {
          this.loadingStart();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleRemoveReceipt(file) {
      const index = this.model.pdf.indexOf(file);
      const newFileList = this.model.pdf.slice();
      newFileList.splice(index, 1);
      this.model.pdf = newFileList;
    },

    beforeUploadReceipt(file) {
      if (file.size < 10 * 1024 * 1024) {
        this.model.pdf = [file];
      } else {
        this.$message.error(`${file.name} size is more then 10MB`);
      }
      return false;
    },
  },
};
</script>
