<template>
  <div class="footer">
    <span
      :style="{
        display: 'block',
        textAlign: 'left',
        width: '100%',
      }"
    >
      Keystone Perfusion Services, PC ©{{ footerYear }},<br />
      All Rights Reserved.
    </span>

    <!--    <a-col v-bind="aColProps">
      <span
        :style="{
          display: 'block',
          textAlign: isMobile ? 'right' : 'center',
        }"
      >
        Designed & Developed by <br />
        Trileotech Pvt. Ltd.
      </span>
    </a-col>-->

    <div class="goldseal">
      <a-tooltip
        v-if="isMobile"
        placement="topRight"
        title="Keystone Perfusion Services, PC has earned the Joint Commission's Gold Seal of Approval."
      >
        <img
          src="./../assets/GoldSeal_National.png"
          alt="Joint Commission's Gold
          Seal of Approval"
          width="50"
        />
      </a-tooltip>

      <template v-else>
        <span>
          Keystone Perfusion Services, PC has earned the Joint Commission's Gold
          Seal of Approval.
        </span>
        <img
          src="./../assets/GoldSeal_National.png"
          alt="Joint Commission's Gold
          Seal of Approval"
          width="50"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import moment from "moment/moment";
import { computed, watch } from "vue";
import { useWindowSize } from "@/composition/useWindowSize";

const footerYear = computed(() => {
  return moment().format("YYYY");
});

const { width } = useWindowSize();

const isMobile = computed(() => {
  const minWidth = 500;
  return width.value < minWidth;
});
const aRowProps = computed(() => {
  // if (isMobile.value) {
  //   return {
  //     justify: "center",
  //     align: "middle",
  //     wrap: true,
  //   };
  // } else {
  return {
    justify: "space-between",
    align: "middle",
    wrap: false,
  };
  // }
});
const aColProps = computed(() => {
  if (isMobile.value) {
    return {
      span: 12,
    };
  } else return {};
});
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goldseal {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: block;
    text-align: right;
  }

  img {
    margin-left: 15px;
  }
}
</style>
