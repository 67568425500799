<template>
  <div style="padding: 8px">
    <a-range-picker
      :value="filterDropdown.selectedKeys[0]"
      :format="$constants.datepickerFormat"
      style="width: 250px; margin-bottom: 8px; display: block"
      :allow-clear="false"
      :ranges="ranges"
      @change="(e) => filterDropdown.setSelectedKeys(e ? [e] : [])"
      @pressEnter="
        handleSearch({
          selectedKeys: filterDropdown.selectedKeys,
          confirm: filterDropdown.confirm,
          dataIndex: filterDropdown.column.dataIndex,
        })
      "
    />

    <a-button
      type="primary"
      size="small"
      style="width: 90px; margin-right: 8px"
      @click="
        handleSearch({
          selectedKeys: filterDropdown.selectedKeys,
          confirm: filterDropdown.confirm,
          dataIndex: filterDropdown.column.dataIndex,
        })
      "
    >
      Search
    </a-button>

    <a-button
      size="small"
      style="width: 90px"
      @click="
        handleReset({
          clearFilters: filterDropdown.clearFilters,
        })
      "
    >
      Reset
    </a-button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    filterDropdown: {
      type: Object,
      required: true,
    },
  },

  emits: ["handleSearch", "handleReset"],

  data() {
    return {
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      },
    };
  },

  methods: {
    handleSearch(payload) {
      this.$emit("handleSearch", payload);
    },
    handleReset(payload) {
      this.$emit("handleReset", payload);
    },
  },
};
</script>