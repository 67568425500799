<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        name="biomed_service_record"
        ref="biomed_service_record"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item ref="date" label="Date" name="date">
              <a-date-picker
                :allowClear="false"
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="asset_inventory_id"
              label="Device"
              name="asset_inventory_id"
            >
              <a-select
                v-model:value="model.asset_inventory_id"
                placeholder="Search device by serial number"
                optionFilterProp="label"
                showSearch
                @search="fetchAssetInventory"
                @change="handleDeviceChange"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>

                <a-select-option
                  v-for="option in assetInventory"
                  :key="option.id"
                  :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                  :value="option.id"
                >
                  {{ option.asset_product?.name }} | {{ option.model_no }} |
                  {{ option.serial_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-descriptions
          v-if="model.asset_inventory_data"
          title="Asset Inventory Info"
          bordered
          size="small"
          :column="2"
          class="mb-sm-1"
        >
          <a-descriptions-item label="Product Name">
            {{ model.asset_inventory_data.asset_product?.name ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Manufacturer Name">
            {{ model.asset_inventory_data.asset_manufacturer?.name ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Owned By">
            <a-tag
              :color="
                $comman.getValueFromObject(
                  model,
                  'owned_by',
                  $constants.assetInventoryOwnedBy,
                  'color'
                )
              "
            >
              {{
                $comman.getValueFromObject(
                  model,
                  "owned_by",
                  $constants.assetInventoryOwnedBy
                )
              }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="Owned Name">
            <template v-if="model.owned_by == 1">
              {{ model.asset_inventory_data.company?.name ?? "N/A" }}
            </template>
            <template v-else>
              {{ model.asset_inventory_data.hospital?.name ?? "N/A" }}
            </template>
          </a-descriptions-item>
          <a-descriptions-item
            v-if="model.asset_inventory_data.asset_inventory_hospital"
            label="Allocated To"
            :span="2"
          >
            {{
              model.asset_inventory_data.asset_inventory_hospital?.hospital
                ?.name ?? "N/A"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Model No">
            {{ model.asset_inventory_data.model_no ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Serial No">
            {{ model.asset_inventory_data.serial_no ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Asset ID Tag">
            {{ model.asset_inventory_data.asset_id_tag ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Secondary ID Tag">
            {{ model.asset_inventory_data.secondary_id_tag ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Description" :span="2">
            {{ model.asset_inventory_data.description ?? "N/A" }}
          </a-descriptions-item>
        </a-descriptions>

        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item ref="comments" label="Comments" name="comments">
              <ckeditor
                :editor="editor"
                v-model="model.comments"
                :config="editorConfig"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="parts_installed"
              label="Parts Installed"
              name="parts_installed"
            >
              <ckeditor
                :editor="editor"
                v-model="model.parts_installed"
                :config="editorConfig"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="re_enter_operator_settings"
              label="Re-enter operator settings"
              name="re_enter_operator_settings"
            >
              <a-radio-group v-model:value="model.re_enter_operator_settings">
                <a-radio value="Yes">Yes</a-radio>
                <a-radio value="No">No</a-radio>
                <a-radio value="N/A">N/A</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="inspect_system_visually"
              label="Inspect system visually"
              name="inspect_system_visually"
            >
              <a-radio-group v-model:value="model.inspect_system_visually">
                <a-radio value="Yes">Yes</a-radio>
                <a-radio value="No">No</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="paperwork_completed"
              label="Paperwork completed"
              name="paperwork_completed"
            >
              <a-radio-group v-model:value="model.paperwork_completed">
                <a-radio value="Yes">Yes</a-radio>
                <a-radio value="No">No</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="leakage_current"
              label="Leakage Current"
              name="leakage_current"
            >
              <a-radio-group v-model:value="model.leakage_current">
                <a-radio value="Yes">Yes</a-radio>
                <a-radio value="N/A">N/A</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <template v-if="model.leakage_current == 'Yes'">
            <a-col :span="8">
              <a-form-item
                ref="leakage_current_n"
                label="Leakage Current-Open (μA)"
                name="leakage_current_n"
              >
                <a-input
                  v-model:value="model.leakage_current_n"
                  placeholder="Enter leakage current n here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item
                ref="leakage_current_r"
                label="Leakage Current-Closed (μA)"
                name="leakage_current_r"
              >
                <a-input
                  v-model:value="model.leakage_current_r"
                  placeholder="Enter leakage current r here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item
                ref="ground_resistance"
                label="Ground Resistance (OHM)"
                name="ground_resistance"
              >
                <a-input
                  v-model:value="model.ground_resistance"
                  placeholder="Enter ground resistance here"
                />
              </a-form-item>
            </a-col>
          </template>

          <a-col :span="12">
            <a-form-item
              ref="requires_additional_service"
              label="Requires additional service"
              name="requires_additional_service"
            >
              <a-radio-group v-model:value="model.requires_additional_service">
                <a-radio value="Yes">Yes</a-radio>
                <a-radio value="No">No</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="install_inspection_label"
              label="Install inspection label"
              name="install_inspection_label"
            >
              <a-radio-group v-model:value="model.install_inspection_label">
                <a-radio value="Yes">Yes</a-radio>
                <a-radio value="No">No</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-alert type="info">
              <template #message>
                <strong>NO</strong> complications were encountered during this
                inspection.<br />This equipment <strong>IS</strong> operating
                within manufacturer's tolerances for safe use.
              </template>
            </a-alert>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center" class="mt-sm-2">
          <a-space direction="vertical">
            <vue-signature-pad
              :options="{ onEnd: save }"
              ref="signaturePad"
              :customStyle="$constants.signPadCustomCss"
            />

            <a-row type="flex" justify="center">
              <a-col>
                <a-typography-paragraph>
                  Employee signature indicates that the information reported is
                  complete and accurate.
                </a-typography-paragraph>
              </a-col>
            </a-row>
            <a-row v-if="model.signature" justify="center">
              <a-space>
                <a-button
                  htmlType="button"
                  type="primary"
                  danger
                  ghost
                  @click="clear"
                >
                  Clear
                </a-button>
              </a-space>
            </a-row>
          </a-space>
        </a-row>

        <a-button
          class="mt-sm-1"
          type="primary"
          html-type="submit"
          :loading="submitBtnLoader"
        >
          {{ submitBtnText }}
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { biomedServiceRecordRule } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton },

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
      },
      cardLoader: true,
      submitBtnLoader: false,
      fetching: false,
      fetchingTimeOut: null,
      assetInventory: [],
      deviceId: this.$route.query?.device ?? null,
      model: {
        id: this.$route.params.id ?? 0,
        employee_id: 0,
        date: null,
        asset_inventory_id: null,
        asset_product_id: null,
        asset_manufacturer_id: null,
        owned_by: null,
        hospital_id: null,
        company_id: null,
        allocated_to: null,
        asset_inventory_data: null,
        comments: null,
        parts_installed: null,
        re_enter_operator_settings: null,
        inspect_system_visually: null,
        paperwork_completed: null,
        leakage_current: null,
        leakage_current_n: null,
        leakage_current_r: null,
        ground_resistance: null,
        requires_additional_service: null,
        install_inspection_label: null,
        signature: null,
        signatured_at: null,
      },
      rules: biomedServiceRecordRule,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Add New Biomed Service Record";
      }
      return "Edit Biomed Service Record";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  async mounted() {
    await this.fetchDevices();
    if (this.model.id != 0) {
      await this.fetchBiomedServiceRecord();
    }

    if (this.deviceId) {
      await this.fetchData({ id: this.deviceId });
      this.model.asset_inventory_id = parseInt(this.deviceId);
      this.handleDeviceChange(this.deviceId);
    }

    this.cardLoader = false;
    setTimeout(() => {
      this.$refs.signaturePad.fromDataURL(this.model.signature);
    }, 500);
  },

  methods: {
    fetchBiomedServiceRecord() {
      return commonService
        .get(this.$constants.biomedServiceRecordShowUrl, { id: this.model.id })
        .then(async (res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;

          this.model = {
            id: this.model.id,
            employee_id: data.employee_id,
            date: moment(data.date),
            asset_inventory_id: data.asset_inventory_id,
            asset_product_id: data.asset_product_id,
            asset_manufacturer_id: data.asset_manufacturer_id,
            owned_by: data.owned_by,
            hospital_id: data.hospital_id,
            company_id: data.company_id,
            allocated_to: data.allocated_to,
            asset_inventory_data: data.asset_inventory_data,
            comments: data.comments,
            parts_installed: data.parts_installed,
            re_enter_operator_settings: data.re_enter_operator_settings,
            inspect_system_visually: data.inspect_system_visually,
            paperwork_completed: data.paperwork_completed,
            leakage_current: data.leakage_current,
            leakage_current_n: data.leakage_current_n,
            leakage_current_r: data.leakage_current_r,
            ground_resistance: data.ground_resistance,
            requires_additional_service: data.requires_additional_service,
            install_inspection_label: data.install_inspection_label,
            signature: data.signature,
            signatured_at: data.signatured_at,
          };

          await this.fetchData({ id: data.asset_inventory_id });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    fetchAssetInventory(value) {
      this.fetching = true;
      clearTimeout(this.fetchingTimeOut);

      this.fetchingTimeOut = setTimeout(this.fetchData, 500, {
        search: value,
      });
    },

    fetchData(value) {
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          ...value,
          status: [1, 2],
          is_required_maintenance: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    fetchDevices() {
      this.fetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_required_maintenance: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    handleDeviceChange(id) {
      let asset = this.assetInventory.find((item) => item.id == id);
      this.model.asset_product_id = asset.asset_product_id;
      this.model.asset_manufacturer_id = asset.asset_manufacturer_id;
      this.model.company_id = asset.company_id;
      this.model.hospital_id = asset.hospital_id;
      this.model.owned_by = asset.owned_by;
      this.model.allocated_to =
        asset.asset_inventory_hospital?.hospital_id ?? null;
      this.model.asset_inventory_data = asset;
    },

    handleFinish() {
      if (this.model.signature == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      if (this.model.id == 0) {
        this.model.employee_id = this.employee.id;
      }

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.biomedServiceRecordStoreUrl, {
          ...this.model,
          date: this.$customDate.ymd(this.model.date),
        })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    clear() {
      this.model.signature = null;
      this.model.signatured_at = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.signature = data;
      this.model.signatured_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      return Promise.resolve();
    },
  },
};
</script>
