export default function hospitalUser({ to, next, store }) {
  // console.log('this is hospital user middleware')
  // console.log(to.name);
  if (!store.getters["auth/isHospitalUser"]) {
    document.title = "Healthcare | Dashboard";
    return next({
      name: "home",
    });
  }
  return next();
}
