<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="file_name" label="File Name" name="file_name">
            <a-input
              v-model:value="formState.file_name"
              placeholder="Enter file name here..."
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="attachment" label="Attachment" name="attachment">
            <a-upload
              :fileList="formState.attachment"
              :remove="handleRemoveAttachment"
              :before-upload="beforeUploadAttachment"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        file_name: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        attachment: [
          {
            required: true,
            message: "This field is required!",
            type: "array",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    products() {
      return this.extra.products;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id ?? 0,
        file_name: this.record.file_name ?? null,
        attachment: this.record.file
          ? [
              {
                id: this.record.id,
                uid: this.record.file,
                name: this.record.file,
                url: this.record.full_file_path,
              },
            ]
          : [],
      };
    },

    onSubmit() {
      this.loadingStart();

      const formData = new FormData();
      formData.append("hospital_id", this.record.hospital_id);
      if (this.formState.id != 0) {
        formData.append("id", this.formState.id);
      }
      formData.append("file_name", this.formState.file_name);
      if (this.formState.attachment[0].id) {
        //
      } else {
        formData.append("file", this.formState.attachment[0]);
      }

      commonService
        .store(this.$constants.hospitalOnboardingAttachmentStoreUrl, formData)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleRemoveAttachment(file) {
      const index = this.formState.attachment.indexOf(file);
      const newFileList = this.formState.attachment.slice();
      newFileList.splice(index, 1);
      this.formState.attachment = newFileList;
    },

    beforeUploadAttachment(file) {
      if (file.size < 15 * 1024 * 1024) {
        this.formState.attachment = [...this.formState.attachment, file];
      } else {
        this.$message.error(`${file.name} size is more then 15MB`);
      }
      return false;
    },
  },
};
</script>

<style></style>
