<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Expenses </template>

        <template #buttons>
          <router-link :to="{ name: 'create-expense' }">
            <a-button type="primary">
              <a-space>
                <i class="ti ti-plus ti-lg"></i> Add New Expense
              </a-space>
            </a-button>
          </router-link>
        </template>
      </page-header>

      <a-card
        :tabList="tabList"
        :activeTabKey="activeTabKey"
        @tabChange="(key) => (activeTabKey = key)"
        size="small"
      >
        <template #customRender="item">
          {{ item.text }} ({{ item.counts }})
        </template>

        <a-table
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="datatableLoading"
          @change="handleTableChange"
          @refresh="refresh"
          size="small"
          :scroll="scroll"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #dateRangeDropdown="filterDropdown">
            <xFilterDateRangeDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #action="{ record }">
            <a-tooltip title="View">
            <a-button
              type="link"
              size="small"
              class="pl-sm-0 primary-color"
              @click="handleShowExpenseEvent(record)"
            >
              <i class="ti ti-eye ti-lg"></i>
            </a-button>
          </a-tooltip>

            <template v-if="record.status == 1">
              <router-link
                :to="{ name: 'edit-expense', params: { id: record.id } }"
              >
              <a-tooltip title="Edit">
                <a-button type="link" size="small">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
              </router-link>

              <a-popconfirm
                title="Are you sure you want to delete this record?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleDeleteExpenseEvent(record)"
              >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.expenseDatatableUrl,
      deleteRecordUrl: this.$constants.expenseDeleteUrl,
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense Type",
          dataIndex: "expense_type_name",
          key: "expense_type_name",
          customRender: ({ record }) => record.expense_type?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          customRender: ({ text }) =>
            text || text == 0 ? this.$comman.withCurrency(text) : "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.expenseStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowExpenseEvent(record) {
      this.open({
        title: "Show Expense Details",
        path: "expense.show",
        callback: this.refresh,
        record: record,
      });
    },

    handleDeleteExpenseEvent(record) {
      commonService
        .store(this.deleteRecordUrl, { id: record.id })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },
  },
};
</script>

<style></style>
