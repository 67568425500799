import axios from "axios";
import { api } from "../config/config";

export const commonService = {
  get: (url, val = {}) => {
    return axios
      .post(api.host + url, val)
      .then((res) => {
        try {
          return res.data;
        } catch {
          return Promise.reject("Something went wrong!");
        }
      })
      .catch((err) => {
        try {
          if (typeof err.response.data == "string") {
            return Promise.reject(err.response.data);
          } else {
            if (
              err.response.data &&
              err.response.data.message &&
              err.response.data.message != ""
            ) {
              return Promise.reject(err.response.data.message);
            } else {
              return Promise.reject("Something went wrong!");
            }
          }
        } catch {
          return Promise.reject("Something went wrong!");
        }
      });
  },

  store: (url, val) => {
    return axios
      .post(api.host + url, val)
      .then((res) => {
        try {
          return res.data;
        } catch {
          return Promise.reject("Something went wrong!");
        }
      })
      .catch((err) => {
        try {
          if (err.response.status == 422) {
            return Promise.reject(err.response.data);
          }
          if (typeof err.response.data == "string") {
            return Promise.reject(err.response.data);
          } else {
            if (
              err.response.data &&
              err.response.data.message &&
              err.response.data.message != ""
            ) {
              return Promise.reject(err.response.data.message);
            } else {
              return Promise.reject("Something went wrong!");
            }
          }
        } catch {
          return Promise.reject("Something went wrong!");
        }
      });
  },

  downloadFile: (url, val, fileName, returnURL = false) => {
    return axios({
      url: api.host + url,
      method: "POST",
      data: val,
      responseType: "blob",
    })
      .then((res) => {
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          if(returnURL) return res.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          return Promise.resolve("File downloaded successfully.");
        } catch {
          return Promise.reject("Something went wrong!");
        }
      })
      .catch((err) => {
        return Promise.reject("Something went wrong!");
      });
  },

  renderFile: (url, val, fileType) => {
    return axios({
      url: api.host + url,
      method: "POST",
      data: val,
      responseType: "blob",
    })
      .then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
        window.open(fileURL);

        return Promise.resolve("File downloaded successfully.");
      }).catch((err) => {
        return Promise.reject(err?.response?.data?.message || "Something went wrong!");
      });
  }
};
