<template>
  <a-card title="Hires By Month" size="small">
    <div class="" id="hires-by-month" />
  </a-card>
</template>

<script setup>
import { Bar } from "@antv/g2plot";
import { onMounted, reactive, ref } from "vue";
import { constants } from "../../../helper/constants";
import { commonService } from "../../../services";

const loading = ref(false);
const chart = ref("");
const data = ref([]);
const config = reactive({
  xField: "amount",
  yField: "month",
  meta: {
    month: {
      alias: "Month",
    },
    amount: {
      alias: "Amount",
    },
  },
  // tooltip: {
  //   formatter: (v) => ({
  //     name: "Amount",
  //     value: comman.withCurrency(v.amount),
  //   }),
  // },
  // label: {
  //   position: "top",
  //   formatter: (v) => comman.withCurrency(v.amount),
  // },
  seriesField: "month",
  legend: {
    position: "top-left",
  },
  smooth: true,
  animation: {
    appear: {
      animation: "path-in",
      duration: 5000,
    },
  },
  // color: "#023958",
});

const renderChart = () => {
  loading.value = true;
  commonService
    .store(constants.hrHiresByMonthUrl)
    .then((res) => {
      data.value = res.data;

      chart.value.changeData(data.value);
      chart.value.render();
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  chart.value = new Bar("hires-by-month", {
    data: data.value,
    ...config,
  });
  chart.value.render();
  renderChart();
});
</script>
