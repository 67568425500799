<template>
  <div>
  <div class="theme-drawer">
    <a-drawer
      :title="title"
      :width="width"
      :wrapClassName="`ant-drawer-${placement} ant-drawer-theme`"
      @close="close()"
      :visible="sideDrawer"
      :placement="placement"
      :maskClosable="false"
      :destroyOnClose="true"
      :wrapStyle="{
        height: 'calc(100% - 0px)',
        overflow: 'auto',
        paddingBottom: '50px',
      }"
    >
      <component v-if="is" :is="is" @callback="this.callback">
        <router-view />
      </component>
    </a-drawer>
  </div>
  </div>
</template>

<script>
import { route } from "@/helper/route";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("drawer", [
      "path",
      "title",
      "width",
      "placement",
      "sideDrawer",
      "callback",
    ]),

    is() {
      var path = this.path;
      if (path != "") {
        path = path.replaceAll(".", "/");
        return route(path);
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["close"]),
  },
};
</script>

<style></style>
