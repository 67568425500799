<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Employees </template>
        <template #buttons>
          <!-- <a-button
            v-if="$comman.access('create-employee')"
            type="primary"
            @click="open({ title: 'Add New Employee', path: 'employee.store', callback: refreshDatatable, extra: { companies: companies, routes: routes, roles: roles } })"
            >
           Add New Employee
          </a-button> -->
          <!-- <a-button type="danger" class="mr-sm-1" @click="syncEmployees()">
            <i class="fas fa-sync mr-lg-1"></i> Sync Employees
          </a-button> -->
          <router-link :to="{ name: 'create-employee', query: { step: 1 } }">
            <a-button v-if="$comman.access('create-employee')" type="primary">
              <i class="fas fa-plus mr-lg-1"></i> Add New Employee
            </a-button>
          </router-link>
        </template>
      </page-header>
      <a-spin :spinning="loading">
        <custom-table
          :scroll="{ x: true, y: 0 }"
          :columns="columns"
          url="api/employees"
          ref="datatable"
          :params="params"
          :default-row-selection="true"
          :bulkAccess="$comman.access('delete-employee')"
        >
          <template #name="record">
            <a-button
              @click="
                open({
                  title: 'Emplyee Details',
                  path: 'employee.show',
                  record: record,
                  extra: { companies: companies, routes: routes, roles: roles },
                })
              "
              class="pl-sm-0"
              type="link"
            >
              {{ record.full_name }}
            </a-button>
          </template>

          <template #created_at="record">
            {{ $customDate.ll(record.created_at) }}
          </template>

          <template #role_name="record">
            <a-tag
              v-if="$comman.access('update-employee') && record.id != 1"
              @click="
                open({
                  title: 'Edit Employee Role and Rights',
                  path: 'employee.roleAndRights',
                  callback: refreshDatatable,
                  record: record,
                  extra: { routes: routes, roles: roles },
                })
              "
              color="cyan"
            >
              {{ role_name(record.user ? record.user.role_id : null) }}
            </a-tag>
            <a-tag v-else color="cyan">
              {{ role_name(record.user ? record.user.role_id : null) }}
            </a-tag>
          </template>

          <template #employee_type="record">
            <a-tag
              :color="$comman.get_employee_type(record.employee_type, 'color')"
            >
              {{ $comman.get_employee_type(record.employee_type, "text") }}
            </a-tag>
          </template>

          <template #action="record">
            <template v-if="record.id != 1">
              <template v-if="record.status == 1">
                <router-link
                  v-if="record.is_onboarding_person == 1"
                  :to="{
                    name: 'onborading-person',
                    query: { step: 1 },
                    params: { onboardingPersonId: record.onboarding_person_id },
                  }"
                >
                  <a-button class="pl-sm-0" type="link"> Edit </a-button>
                </router-link>
                <a-button
                  v-if="record.is_onboarding_person == 1"
                  type="link"
                  @click="insperitySync(record)"
                >
                  Sync
                </a-button>
                <!-- <a-button
                v-if="$comman.access('update-employee')"
                type="link"
                @click="
                  open({
                    title: 'Edit Employee',
                    path: 'employee.store',
                    callback: refreshDatatable,
                    record: record,
                    extra: { companies: companies, routes: routes, roles: roles },
                  })
                "
                class="pl-sm-0"
              >
                Edit
              </a-button> -->
                <!-- <a-divider type="vertical" /> -->
                <a-popconfirm
                  v-if="$comman.access('delete-employee')"
                  title="Are you sure delete this employee?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="() => action({ id: record.id, status: '0' })"
                >
                  <a-button size="small" type="link" class="danger-color">
                    Trash
                  </a-button>
                </a-popconfirm>
              </template>
              <template v-else>
                <a-popconfirm
                  v-if="$comman.access('delete-employee')"
                  title="Are you sure restored this employee?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="() => action({ id: record.id, status: '1' })"
                >
                  <a-button size="small" type="link"> Restore </a-button>
                </a-popconfirm>
                <a-divider type="vertical" />
                <a-popconfirm
                  v-if="$comman.access('delete-employee')"
                  title="Are you sure permantly delete this employee?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="() => action({ id: record.id, status: '2' })"
                >
                  <a-button size="small" type="link" class="danger-color">
                    Permanent Delete
                  </a-button>
                </a-popconfirm>
              </template>
            </template>
          </template>
        </custom-table>
      </a-spin>
    </a-layout-content>
  </div>
</template>

<script>
import { companyService, tableService } from "@/services";
import {
  EditOutlined,
  DeleteOutlined,
  RestOutlined,
} from "@ant-design/icons-vue";
import { mapActions } from "vuex";
import { commonService, insperityService } from "../../services";
import { ONBOARDING } from "../../helper/insperityConst";

export default {
  components: {
    EditOutlined,
    DeleteOutlined,
    RestOutlined,
  },
  data() {
    return {
      loading: false,
      params: {},
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          slots: {
            customRender: "name",
            // filterDropdown: 'filterDropdown',
            // filterIcon: 'filterIcon'
          },
        },
        {
          title: "Role",
          dataIndex: "role_name",
          key: "role_name",
          sorter: false,
          slots: {
            customRender: "role_name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Type",
          dataIndex: "employee_type",
          key: "employee_type",
          sorter: true,
          slots: {
            customRender: "employee_type",
          },
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          slots: { customRender: "created_at" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          // fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      companies: {},
      routes: {},
      roles: {},
    };
  },
  created() {
    this.getCompanies();
    this.get_routes();
    this.get_roles();
  },
  methods: {
    ...mapActions("drawer", ["open"]),
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },
    action(val) {
      companyService.store("api/employee/change-status", val).then((res) => {
        if (res.success) {
          this.refreshDatatable();
          this.$message.success(res.message);
        } else {
          this.$message.error("Something went wrong.");
        }
      });
    },
    getCompanies() {
      companyService.store(this.$constants.getCompaniesUrl).then((res) => {
        if (res.success) {
          this.companies = res.data;
        }
      });
    },
    get_routes() {
      companyService.store(this.$constants.getAccessRoutesUrl).then((res) => {
        if (res.success) {
          this.routes = res.data;
        }
      });
    },
    get_roles() {
      companyService.store(this.$constants.getRolesUrl).then((res) => {
        if (res.success) {
          this.roles = res.data;
        }
      });
    },
    role_name(id) {
      let name = "-";
      if (this.roles.length > 0) {
        this.roles.forEach((role) => {
          if (role.id == id) {
            name = role.name;
          }
        });
      }
      return name;
    },
    insperitySync(record) {
      this.loading = true;
      insperityService
        .post(ONBOARDING(), JSON.parse(record.insperity_onboarding_json))
        .then((res) => console.log(res))
        .catch((err) => this.$message.error(err))
        .finally(() => (this.loading = false));
    },
    syncEmployees() {
      this.loading = true;
      commonService
        .get(this.$constants.insSyncEmployeesUrl)
        .then((res) => {
          this.loading = false;
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>

<style></style>
