<template>
  <div>
    <a-form
      name="performance_evaluation_comment_form"
      ref="performance_evaluation_comment_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="comment"
            label="Comment"
            name="comment"
            :validateStatus="validate('comment', 'status')"
            :help="validate('comment', 'msg')"
          >
            <a-textarea
              v-model:value="formState.comment"
              placeholder="Enter comment here"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-list
        v-if="comments.length"
        :data-source="comments"
        header=""
        item-layout="horizontal"
        size="small"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta :description="item.comment">
              <template #title>
                {{ item.employee.full_name }} |
                {{ $customDate.mdy(item.created_at) }}
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        comment: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },

    comments() {
      return this.extra.comments;
    },
  },

  created() {
    //
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        no_of_items: this.record.no_of_items ?? "",
      };
    },

    onSubmit() {
      this.$refs.performance_evaluation_comment_form
        .validate()
        .then((val) => {
          if (this.record.id) {
            val.performance_evaluation_id = this.record.id;
          }
          this.loadingStart();
          companyService
            .store(
              this.$constants.adminPerformanceEvaluationCommentStoreUrl,
              val
            )
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              this.loadingStop();
              if ("errors" in err) {
                this.errors = err.errors;
              } else {
                this.$message.error(err);
              }
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
