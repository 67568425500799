const state = {
  products: [],
};

const getters = {
  cartProducts(state) {
    return state.products;
  },

  cartSubtotal(state) {
    return parseFloat(
      state.products.reduce((total, item) => {
        return total + item.price * item.qty;
      }, 0)
    ).toFixed(2);
  },

  cartQty(state) {
    return state.products.reduce((total, item) => {
      return total + item.qty;
    }, 0);
  },
};

const actions = {
  fetchProductFromLocalStorage({ commit, rootGetters }, payload = {}) {
    let user = rootGetters["auth/user"];
    payload.cartProductsString = "cartProducts" + user.id;
    commit("fetchProductFromLocalStorage", payload);
  },

  addProduct({ dispatch, commit, rootGetters }, payload) {
    dispatch("fetchProductFromLocalStorage");
    let user = rootGetters["auth/user"];
    payload.cartProductsString = "cartProducts" + user.id;
    commit("addProduct", payload);
  },

  decreaseProductQty({ dispatch, commit, rootGetters }, payload) {
    dispatch("fetchProductFromLocalStorage");
    let user = rootGetters["auth/user"];
    payload.cartProductsString = "cartProducts" + user.id;
    commit("decreaseProductQty", payload);
  },

  increaseProductQty({ dispatch, commit, rootGetters }, payload) {
    dispatch("fetchProductFromLocalStorage");
    let user = rootGetters["auth/user"];
    payload.cartProductsString = "cartProducts" + user.id;
    commit("increaseProductQty", payload);
  },

  removeProductFromCart({ dispatch, commit, rootGetters }, payload) {
    dispatch("fetchProductFromLocalStorage");
    let user = rootGetters["auth/user"];
    payload.cartProductsString = "cartProducts" + user.id;
    commit("removeProductFromCart", payload);
  },

  resetCartProducts({ dispatch, commit, rootGetters }) {
    let payload = {};
    let user = rootGetters["auth/user"];
    payload.cartProductsString = "cartProducts" + user.id;
    commit("resetCartProducts", payload);
  },
};

const mutations = {
  fetchProductFromLocalStorage(state, payload) {
    let products =
      localStorage.getItem(payload.cartProductsString) == null
        ? []
        : JSON.parse(localStorage.getItem(payload.cartProductsString));
    state.products = products;
  },

  addProduct(state, payload) {
    payload.qty = payload.qty ?? 1;
    let product = state.products.filter((list) => list.id == payload.id);
    if (product.length > 0) {
      state.products.map((list) => {
        if (list.id == payload.id) {
          list.qty = list.qty + payload.qty;
        }
      });
    } else {
      state.products.push(payload);
    }
    localStorage.setItem(
      payload.cartProductsString,
      JSON.stringify(state.products)
    );
  },

  decreaseProductQty(state, payload) {
    let product = state.products.find((_, index) => index == payload.index);
    if (product.qty == 1) {
      state.products.splice(payload.index, 1);
    } else {
      state.products.map((list) => {
        if (list.id == payload.item.id) {
          list.qty--;
        }
      });
    }
    localStorage.setItem(
      payload.cartProductsString,
      JSON.stringify(state.products)
    );
  },

  increaseProductQty(state, payload) {
    state.products.map((list) => {
      if (list.id == payload.item.id) {
        list.qty++;
      }
    });
    localStorage.setItem(
      payload.cartProductsString,
      JSON.stringify(state.products)
    );
  },

  removeProductFromCart(state, payload) {
    state.products.splice(payload.index, 1);
    localStorage.setItem(
      payload.cartProductsString,
      JSON.stringify(state.products)
    );
  },

  resetCartProducts(state, payload) {
    state.products = [];
    localStorage.setItem(
      payload.cartProductsString,
      JSON.stringify(state.products)
    );
  },
};

export const cart = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
