<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Policy and Procedure Manual </template>
        <template #buttons>
          <a-button
            v-if="data"
            type="primary"
            :href="data.file_full_path"
            target="_blank"
            class="mr-sm-1"
          >
            Open in new tab
          </a-button>

          <a-button
            v-if="
              btnText != '' &&
              $route.name == 'policy-and-procedure-manual-admin' &&
              isAdmin
            "
            @click="
              open({
                title: 'Policy And Procedure Manual',
                path: 'policyAndProcedureManual.store',
                callback: fetchData,
              })
            "
            type="primary"
          >
            {{ btnText }}
          </a-button>
        </template>
      </page-header>

      <iframe v-if="data" :src="data.file_full_path" width="100%" height="600">
      </iframe>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      btnText: "",
      data: null,
    };
  },

  mounted() {
    this.fetchData();
  },

  updated() {
    this.data = null;
    this.fetchData();
  },

  computed: {
    ...mapGetters("auth", ["employee", "isAdmin"]),
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchData() {
      commonService
        .store(this.$constants.policyAndProcedureManualShowUrl, { id: 1 })
        .then((res) => {
          if (res.success) {
            this.data = res.data;
            if (res.data) {
              this.btnText = "Update File";
            } else {
              this.btnText = "Add File";
            }
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },
  },
};
</script>
