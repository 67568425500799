<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Live Charting"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <!--  -->
      </template>
    </a-page-header>

    <!-- <a-card>
      <a-empty description="Coming Soon" />
    </a-card> -->
    <div>
      <div class="monitor">
        <div class="monitor-top">
          <div class="monitor-message">
            <h3>
              {{ caseReport.patient_first_name }}
              {{ caseReport.patient_last_name }} | MR:
              {{ caseReport.mr_number }} | Acct:
              {{ caseReport.account_number }} | DOB: 05-18-1991 | Gender: Male |
              Allergies:
              {{ caseReport.allergies ?? "N/A" }}
            </h3>
          </div>
        </div>
        <div class="monitor-middle">
          <div class="monitor-left">
            <div class="timer-actions">
              <ul class="timer-action-list">
                <li class="action-item">
                  <button class="timer-action timer-action-active">
                    <span class="title">Bypass</span
                    ><span class="start">11:00</span
                    ><span class="stop">12:00</span>
                  </button>
                </li>
                <li class="action-item">
                  <button class="timer-action">
                    <span class="title">X-Clamp</span
                    ><span class="start">-</span><span class="stop">-</span>
                  </button>
                </li>
                <li class="action-item">
                  <button class="timer-action">
                    <span class="title">Cir. Arrest</span
                    ><span class="start">11:00</span
                    ><span class="stop">12:00</span>
                  </button>
                </li>
              </ul>
            </div>
            <div class="event-actions">
              <ul class="event-action-list">
                <li class="action-item">
                  <button
                    class="event-action"
                    @click="
                      open({
                        title: 'Status',
                        path: 'liveCharting.drawerForm.status',
                        callback: callback,
                        record: null,
                      })
                    "
                  >
                    <span class="title">Status</span>
                  </button>
                </li>
                <li class="action-item">
                  <button
                    class="event-action"
                    @click="
                      open({
                        title: 'Blood Gas',
                        path: 'liveCharting.drawerForm.bloodGas',
                        callback: callback,
                        record: null,
                      })
                    "
                  >
                    <span class="title">Blood Gas</span>
                  </button>
                </li>
                <li class="action-item">
                  <button
                    class="event-action"
                    @click="
                      open({
                        title: 'Cardioplegia',
                        path: 'liveCharting.drawerForm.cardioplegia',
                        callback: callback,
                        record: null,
                      })
                    "
                  >
                    <span class="title">Cardioplegia</span>
                  </button>
                </li>
                <li class="action-item">
                  <button
                    class="event-action"
                    @click="
                      open({
                        title: 'Case Data',
                        path: 'liveCharting.drawerForm.caseData',
                        callback: callback,
                        record: caseReport,
                      })
                    "
                  >
                    <span class="title">Case Data</span>
                  </button>
                </li>
                <li class="action-item">
                  <button
                    class="event-action event-action-close"
                    @click="$router.go(-1)"
                  >
                    <span class="title">Close Case</span>
                  </button>
                </li>
              </ul>
            </div>
            <div class="flow-info">
              <table class="flow-info-table">
                <thead>
                  <tr>
                    <th>CI</th>
                    <th>LPM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.6</td>
                    <td>3.2</td>
                  </tr>
                  <tr>
                    <td>1.8</td>
                    <td>3.6</td>
                  </tr>
                  <tr>
                    <td>2.0</td>
                    <td>4.0</td>
                  </tr>
                  <tr>
                    <td>2.2</td>
                    <td>4.4</td>
                  </tr>
                  <tr>
                    <td>2.4</td>
                    <td>4.8</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="monitor-right">
            <div class="monitor-right-top">
              <div class="indicators scroller">
                <ul class="indicators-list">
                  <li class="indicator-item">
                    <span class="title">Blood Flow</span>
                    <span class="count">4.55</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">HCT</span>
                    <span class="count">25%</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">S<sub>V</sub>O<sub>2</sub></span>
                    <span class="count">72%</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Line Pres.</span>
                    <span class="count">243</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Core Temp.</span>
                    <span class="count">36</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Ven Temp.</span>
                    <span class="count">35</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Cardiac Index</span>
                    <span class="count">2.2</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Balance</span>
                    <span class="count">Σ 1250 mL</span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Pre-Bypass Vitals</span>
                    <span class="count"> CVP : 14 | PA : 25/10 </span>
                  </li>
                  <li class="indicator-item">
                    <span class="title">Height | Weight | BSA</span>
                    <span class="count">
                      182 cm | 80 kg | 2.1 m<sup>2</sup>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="monitor-right-middle">
              <div class="graph"></div>
              <div class="event-table scroller">
                <table class="event-table-compact">
                  <thead>
                    <tr>
                      <th rowspan="2">Time</th>
                      <th rowspan="2">Event</th>
                      <th colspan="3">Pressure</th>
                      <th colspan="2">Pump</th>
                      <th colspan="3">Cardioplegia</th>
                      <th colspan="3">Gases</th>
                      <th colspan="2">Temp (<sup>o</sup>C)</th>
                      <th rowspan="2">ACT</th>
                      <th rowspan="2">S<sub>V</sub>O<sub>2</sub>%</th>
                      <th colspan="7" class="highlight">Blood Gas Analysis</th>
                      <th colspan="3" class="highlight">Chemistry</th>
                    </tr>
                    <tr>
                      <th>ART</th>
                      <th>CVP</th>
                      <th>PA</th>
                      <th>Flow <small>(l/min.)</small></th>
                      <th>Line Pressure</th>
                      <th>Flow</th>
                      <th>Pressure</th>
                      <th>Volumne</th>
                      <th>FiO<sub>2</sub></th>
                      <th>Sweep</th>
                      <th>Anes. Gas %</th>
                      <th>Ven.</th>
                      <th>Core</th>
                      <th class="highlight"><sub>p</sub>H</th>
                      <th class="highlight">PCO<sub>2</sub></th>
                      <th class="highlight">PO<sub>2</sub></th>
                      <th class="highlight">HCO<sub>3</sub></th>
                      <th class="highlight">BASE</th>
                      <th class="highlight">O<sub>2</sub> SAT</th>
                      <th class="highlight">Hb/HCT</th>
                      <th class="highlight">iCa</th>
                      <th class="highlight">K<sup>+</sup></th>
                      <th class="highlight">GLU</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td>7:34</td>
                      <td>Pre-Bypass Sample</td>
                      <td>132</td>
                      <td>32</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>37</td>
                      <td>-</td>
                      <td>145</td>
                      <td>7.45</td>
                      <td>37</td>
                      <td>96</td>
                      <td>24</td>
                      <td>0</td>
                      <td>99.8</td>
                      <td>12</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                      <td>3.9</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
            <div class="monitor-right-bottom">
              <div class="quick-actions">
                <ul class="quick-actions-list">
                  <li class="quick-item">
                    <button
                      class="action"
                      @click="
                        open({
                          title: 'Medication',
                          path: 'liveCharting.drawerForm.medication',
                          callback: callback,
                          record: null,
                        })
                      "
                    >
                      Medication
                    </button>
                  </li>
                  <li class="quick-item">
                    <button
                      class="action"
                      @click="
                        open({
                          title: 'Cooling',
                          path: 'liveCharting.drawerForm.cooling',
                          callback: callback,
                          record: null,
                        })
                      "
                    >
                      Cooling
                    </button>
                  </li>
                  <li class="quick-item">
                    <button
                      class="action"
                      @click="
                        open({
                          title: 'Warming',
                          path: 'liveCharting.drawerForm.warning',
                          callback: callback,
                          record: null,
                        })
                      "
                    >
                      Warming
                    </button>
                  </li>
                  <li class="quick-item">
                    <button
                      class="action"
                      @click="
                        open({
                          title: '+ Volume',
                          path: 'liveCharting.drawerForm.plusVolume',
                          callback: callback,
                          record: null,
                        })
                      "
                    >
                      + Volume
                    </button>
                  </li>
                  <li class="quick-item">
                    <button
                      class="action"
                      @click="
                        open({
                          title: '- Volume',
                          path: 'liveCharting.drawerForm.minusVolume',
                          callback: callback,
                          record: null,
                        })
                      "
                    >
                      - Volume
                    </button>
                  </li>
                  <li class="quick-item">
                    <button class="action">Blood Products</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      caseReport: {
        date: "2022-12-13",
        report_no: "2022-12-13",
        patient_first_name: "John",
        patient_last_name: "Doe",
        facility: "AdventHealth Hendersonville",
        mr_number: "45342163534",
        account_number: "67126812",
        allergies: "",
        height: "",
        weight: "",
        body_surface: "",
        blood_type: "",
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    callback() {
      console.log("callback::");
    },
  },
};
</script>
