import { route } from "@/helper/route";
import auth from "./../middleware/auth";
import profile from "./../middleware/profile";

export default [
  {
    path: "/crm/leads",
    name: "crm.leads",
    component: route("crm/lead/index.vue"),
    meta: {
      title: "CRM | Leads",
      layout: "app",
      middleware: [auth, profile],

    },
  },
  {
    path: "/crm/lead/:id",
    name: "crm.show.lead",
    component: route("crm/lead/show.vue"),
    meta: {
      title: "CRM | Show Lead",
      layout: "app",
      middleware: [auth, profile],
    },
  },
]