import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";
import profile from "./middleware/profile";

export default [
  {
    path: "/case-reporting",
    name: "case-report",
    component: route("newCaseReport/index"),
    meta: {
      title: "Case Reporting",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/case-reporting/create",
    name: "create-case-report",
    component: route("newCaseReport/store"),
    meta: {
      title: "Create New Case Report",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/case-reporting/show/:uuid",
    name: "show-case-report",
    component: route("newCaseReport/show"),
    meta: {
      title: "Show Case Report",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/case-reporting/live-charting/:uuid",
    name: "case-report-live-charting",
    component: route("newCaseReport/liveCharting"),
    meta: {
      title: "Case Report - Live Charting",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  // Admin
  {
    path: "/admin/case-reporting",
    name: "admin.case-report",
    component: route("admin/newCaseReport/index"),
    meta: {
      title: "Case Reporting",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/case-reporting/create",
    name: "admin.create-case-report",
    component: route("newCaseReport/store"),
    meta: {
      title: "Create New Case Report",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/case-reporting/live-charting/:uuid",
    name: "admin.case-report-live-charting",
    component: route("newCaseReport/liveCharting"),
    meta: {
      title: "Case Report - Live Charting",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/case-reporting/show/:uuid",
    name: "admin.show-case-report",
    component: route("admin/newCaseReport/show"),
    meta: {
      title: "Show Case Report",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
];
