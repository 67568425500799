<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        name="equipment_maintenance"
        ref="equipment_maintenance"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item ref="date" label="Date" name="date">
              <a-date-picker
                :allowClear="false"
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="asset_inventory_id"
              label="Select heater cooler device used"
              name="asset_inventory_id"
            >
              <a-select
                v-model:value="model.asset_inventory_id"
                placeholder="Search device by serial number"
                optionFilterProp="label"
                showSearch
                @search="fetchAssetInventory"
                @change="handleDeviceChange"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>

                <a-select-option
                  v-for="option in assetInventory"
                  :key="option.id"
                  :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                  :value="option.id"
                >
                  {{ option.asset_product?.name }} | {{ option.model_no }} |
                  {{ option.serial_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-descriptions
          v-if="model.asset_inventory_data"
          title="Asset Inventory Info"
          bordered
          size="small"
          :column="2"
          class="mb-sm-1"
        >
          <a-descriptions-item label="Product Name">
            {{ model.asset_inventory_data.asset_product?.name ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Manufacturer Name">
            {{ model.asset_inventory_data.asset_manufacturer?.name ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Owned By">
            <a-tag
              :color="
                $comman.getValueFromObject(
                  model,
                  'owned_by',
                  $constants.assetInventoryOwnedBy,
                  'color'
                )
              "
            >
              {{
                $comman.getValueFromObject(
                  model,
                  "owned_by",
                  $constants.assetInventoryOwnedBy
                )
              }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="Owned Name">
            <template v-if="model.owned_by == 1">
              {{ model.asset_inventory_data.company?.name ?? "N/A" }}
            </template>
            <template v-else>
              {{ model.asset_inventory_data.hospital?.name ?? "N/A" }}
            </template>
          </a-descriptions-item>
          <a-descriptions-item
            v-if="model.asset_inventory_data.asset_inventory_hospital"
            label="Allocated To"
            :span="2"
          >
            {{
              model.asset_inventory_data.asset_inventory_hospital?.hospital
                ?.name ?? "N/A"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Model No">
            {{ model.asset_inventory_data.model_no ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Serial No">
            {{ model.asset_inventory_data.serial_no ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Asset ID Tag">
            {{ model.asset_inventory_data.asset_id_tag ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Secondary ID Tag">
            {{ model.asset_inventory_data.secondary_id_tag ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Description" :span="2">
            {{ model.asset_inventory_data.description ?? "N/A" }}
          </a-descriptions-item>
        </a-descriptions>

        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item
              ref="is_device_being_stored_empty"
              label="Is the device being stored empty and free of water?"
              name="is_device_being_stored_empty"
            >
              <a-select
                v-model:value="model.is_device_being_stored_empty"
                placeholder="Select One"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="yesNo in $constants.yesNo"
                  :key="yesNo.value"
                  :label="yesNo.text"
                  :value="yesNo.value"
                >
                  {{ yesNo.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="is_equipment_cleaned"
              label="Equipment cleaned following the manufacturer's instructions for use?"
              name="is_equipment_cleaned"
            >
              <a-select
                v-model:value="model.is_equipment_cleaned"
                placeholder="Select One"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="yesNo in $constants.yesNo"
                  :key="yesNo.value"
                  :label="yesNo.text"
                  :value="yesNo.value"
                >
                  {{ yesNo.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item
              ref="is_heater_cooler_tubing_change"
              label="Was the heater-cooler tubing changed during this cleaning?"
              name="is_heater_cooler_tubing_change"
            >
              <a-select
                v-model:value="model.is_heater_cooler_tubing_change"
                placeholder="Select One"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="yesNo in $constants.yesNo"
                  :key="yesNo.value"
                  :label="yesNo.text"
                  :value="yesNo.value"
                >
                  {{ yesNo.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <template v-if="model.is_heater_cooler_tubing_change == 'Yes'">
              <a-form-item
                ref="tubing_change_date"
                name="tubing_change_date"
                extra=""
              >
                <template #label>
                  When was the tubing last changed?
                  <a-popover>
                    <template #content>
                      The manufacturer for most heater-cooler devices suggest
                      <br />
                      tubings that are used with the heater-cooler must be
                      <br />
                      replaced at least once a year. If the tubing wasn't <br />
                      changed this cleaning cycle, select the date the tubing
                      <br />
                      was last replaced.
                    </template>
                    <a-button type="link" size="small">
                      <i class="fa fa-info-circle"></i>
                    </a-button>
                  </a-popover>
                </template>
                <a-date-picker
                  :allowClear="false"
                  v-model:value="model.tubing_change_date"
                  :disabled-date="$comman.disabledFutureDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </template>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="is_livanova_3t_aerosol_collection_set_changed"
              label="If using a LivaNova 3T, was the Aerosol Collection Set changed during this cleaning?"
              name="is_livanova_3t_aerosol_collection_set_changed"
            >
              <a-select
                v-model:value="
                  model.is_livanova_3t_aerosol_collection_set_changed
                "
                placeholder="Select One"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="yesNo in $constants.yesNoNotApplicable"
                  :key="yesNo.value"
                  :label="yesNo.text"
                  :value="yesNo.value"
                >
                  {{ yesNo.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <template
              v-if="model.is_livanova_3t_aerosol_collection_set_changed == 'No'"
            >
              <a-form-item
                ref="livanova_3t_aerosol_collection_set_change_date"
                name="livanova_3t_aerosol_collection_set_change_date"
              >
                <template #label>
                  If using a LivaNova 3T, when was the Aerosol Collection Set
                  last changed?
                  <a-popover>
                    <template #content>
                      As per 3T Aerosol Collection Sets Instructions for Use,
                      <br />
                      after 3 calendar days of installation, the Aerosol <br />
                      Collection Set has to be replaced and discarded. If the
                      <br />
                      tubing wasn't changed this cleaning cycle, select the date
                      <br />
                      the tubing was last replaced.
                    </template>
                    <a-button type="link" size="small">
                      <i class="fa fa-info-circle"></i>
                    </a-button>
                  </a-popover>
                </template>
                <a-date-picker
                  :allowClear="false"
                  v-model:value="
                    model.livanova_3t_aerosol_collection_set_change_date
                  "
                  :disabled-date="$comman.disabledFutureDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </template>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="h2o2_level_measured_semi_quantitatively"
              name="h2o2_level_measured_semi_quantitatively"
            >
              <template #label>
                <span>
                  H<sub>2</sub>O<sub>2</sub> level measured semi-quantitatively
                  by visual comparison of the reaction zone of a hydrogen
                  peroxide test strip.
                  <a-popover>
                    <template #content>
                      In 2011, at the recommendation of a water hygiene expert,
                      <br />
                      LivaNova revised the 3T Operating Instructions to include
                      <br />
                      steps to achieve water quality that meets the standard for
                      <br />
                      German drinking water (≤100 CFU/ml). These changes
                      included
                      <br />
                      the addition of hydrogen peroxide to the water as a <br />
                      preservative. During verification testing of the <br />
                      preservation, it was observed that hydrogen peroxide
                      levels
                      <br />
                      above 100 ppm kept the water within the specified drinking
                      <br />
                      water limit.
                    </template>
                    <a-button type="link" size="small">
                      <i class="fa fa-info-circle"></i>
                    </a-button>
                  </a-popover>
                </span>
              </template>

              <a-input
                v-model:value="model.h2o2_level_measured_semi_quantitatively"
                placeholder="(mg/l H2O2)"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="residual_peracetic_acid"
              name="residual_peracetic_acid"
            >
              <template #label>
                If applicable, residual peracetic acid
              </template>

              <a-input
                v-model:value="model.residual_peracetic_acid"
                placeholder="Enter here"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="is_water_sample_obtained"
              label="Was a water sample obtained and sent for analysis?"
              name="is_water_sample_obtained"
            >
              <a-select
                v-model:value="model.is_water_sample_obtained"
                placeholder="Select One"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="yesNo in $constants.yesNo"
                  :key="yesNo.value"
                  :label="yesNo.text"
                  :value="yesNo.value"
                >
                  {{ yesNo.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <template v-if="model.is_water_sample_obtained == 'Yes'">
              <a-form-item
                ref="water_sample_shipped_date"
                label="Date Water Sample Shipped"
                name="water_sample_shipped_date"
              >
                <a-date-picker
                  :allowClear="false"
                  v-model:value="model.water_sample_shipped_date"
                  :disabled-date="$comman.disabledFutureDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </template>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="minncare_lot"
              label="Minncare Lot"
              name="minncare_lot"
            >
              <a-input
                v-model:value="model.minncare_lot"
                placeholder="Minncare Lot #"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="minncare_expiration_date"
              label="Minncare Expiration Date"
              name="minncare_expiration_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.minncare_expiration_date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="peracetic_acid_indicator_test_strip_lot"
              label="Peracetic Acid 1% Indicator Test Strip Lot"
              name="peracetic_acid_indicator_test_strip_lot"
            >
              <a-input
                v-model:value="model.peracetic_acid_indicator_test_strip_lot"
                placeholder="Peracetic Acid 1% Indicator Test Strip Lot #"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="peracetic_acid_indicator_test_strip_expiration_date"
              label="Peracetic Acid 1% Indicator Test Strip Expiration Date"
              name="peracetic_acid_indicator_test_strip_expiration_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="
                  model.peracetic_acid_indicator_test_strip_expiration_date
                "
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="peracetic_acid_residual_indicator_test_strip_lot"
              label="Peracetic Acid Residual Indicator Test Strip Lot"
              name="peracetic_acid_residual_indicator_test_strip_lot"
            >
              <a-input
                v-model:value="
                  model.peracetic_acid_residual_indicator_test_strip_lot
                "
                placeholder="Peracetic Acid Residual Indicator Test Strip Lot #"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="peracetic_acid_residual_indicator_test_strip_expiration_date"
              label="Peracetic Acid Residual Indicator Test Strip Expiration Date"
              name="peracetic_acid_residual_indicator_test_strip_expiration_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="
                  model.peracetic_acid_residual_indicator_test_strip_expiration_date
                "
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="micron_filter_lot"
              label="0.2 Micron Filter Lot"
              name="micron_filter_lot"
            >
              <a-input
                v-model:value="model.micron_filter_lot"
                placeholder="0.2 Micron Filter Lot #"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item ref="notes" label="Notes/Certifications" name="notes">
              <ckeditor
                :editor="editor"
                v-model="model.notes"
                :config="editorConfig"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center" class="mt-sm-2">
          <a-space direction="vertical">
            <vue-signature-pad
              :options="{ onEnd: save }"
              ref="signaturePad"
              :customStyle="$constants.signPadCustomCss"
            />

            <a-row type="flex" justify="center">
              <a-col>
                <a-typography-paragraph>
                  Employee signature indicates that the information reported is
                  complete and accurate.
                </a-typography-paragraph>
              </a-col>
            </a-row>
            <a-row v-if="model.signature" justify="center">
              <a-space>
                <a-button
                  htmlType="button"
                  type="primary"
                  danger
                  ghost
                  @click="clear"
                >
                  Clear
                </a-button>
              </a-space>
            </a-row>
          </a-space>
        </a-row>

        <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
          {{ submitBtnText }}
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { equipmentMaintenanceRule } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton },

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
      },
      cardLoader: true,
      submitBtnLoader: false,
      fetching: false,
      fetchingTimeOut: null,
      assetInventory: [],
      deviceId: this.$route.query?.device ?? null,
      model: {
        id: this.$route.params.id ?? 0,
        employee_id: 0,
        date: null,
        asset_inventory_id: null,
        asset_product_id: null,
        asset_manufacturer_id: null,
        owned_by: null,
        hospital_id: null,
        company_id: null,
        allocated_to: null,
        asset_inventory_data: null,
        is_device_being_stored_empty: null,
        is_equipment_cleaned: null,
        is_heater_cooler_tubing_change: null,
        tubing_change_date: null,
        is_livanova_3t_aerosol_collection_set_changed: null,
        livanova_3t_aerosol_collection_set_change_date: null,
        h2o2_level_measured_semi_quantitatively: null,
        residual_peracetic_acid: null,
        is_water_sample_obtained: null,
        water_sample_shipped_date: null,
        minncare_lot: null,
        minncare_expiration_date: null,
        peracetic_acid_indicator_test_strip_lot: null,
        peracetic_acid_indicator_test_strip_expiration_date: null,
        peracetic_acid_residual_indicator_test_strip_lot: null,
        peracetic_acid_residual_indicator_test_strip_expiration_date: null,
        micron_filter_lot: null,
        notes: null,
        signature: null,
        signatured_at: null,
      },
      rules: equipmentMaintenanceRule,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Add New Heater Cooler Maintenance";
      }
      return "Edit Heater Cooler Maintenance";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  async mounted() {
    await this.fetchDevices();
    if (this.model.id != 0) {
      this.fetchEquipmentMaintenance();
    }

    if (this.deviceId) {
      await this.fetchData({ id: this.deviceId, status: [1, 2] });
      this.model.asset_inventory_id = parseInt(this.deviceId);
      this.handleDeviceChange(this.deviceId);
    }

    setTimeout(() => {
      this.$refs.signaturePad.fromDataURL(this.model.signature);
    }, 500);
    this.cardLoader = false;
  },

  methods: {
    fetchEquipmentMaintenance() {
      commonService
        .get(this.$constants.equipmentMaintenanceShowUrl, { id: this.model.id })
        .then(async (res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;

          this.model = {
            id: this.model.id,
            employee_id: data.employee_id,
            date: moment(data.date),
            asset_inventory_id: data.asset_inventory_id,
            asset_product_id: data.asset_product_id,
            asset_manufacturer_id: data.asset_manufacturer_id,
            owned_by: data.owned_by,
            hospital_id: data.hospital_id,
            company_id: data.company_id,
            allocated_to: data.allocated_to,
            asset_inventory_data: data.asset_inventory_data,
            is_device_being_stored_empty: data.is_device_being_stored_empty,
            is_equipment_cleaned: data.is_equipment_cleaned,
            is_heater_cooler_tubing_change: data.is_heater_cooler_tubing_change,
            tubing_change_date: data.tubing_change_date
              ? moment(data.tubing_change_date)
              : null,
            is_livanova_3t_aerosol_collection_set_changed:
              data.is_livanova_3t_aerosol_collection_set_changed,
            livanova_3t_aerosol_collection_set_change_date:
              data.livanova_3t_aerosol_collection_set_change_date
                ? moment(data.livanova_3t_aerosol_collection_set_change_date)
                : null,
            h2o2_level_measured_semi_quantitatively:
              data.h2o2_level_measured_semi_quantitatively,
            residual_peracetic_acid: data.residual_peracetic_acid,
            is_water_sample_obtained: data.is_water_sample_obtained,
            water_sample_shipped_date: data.water_sample_shipped_date
              ? moment(data.water_sample_shipped_date)
              : null,
            minncare_lot: data.minncare_lot,
            minncare_expiration_date: data.minncare_expiration_date
              ? moment(data.minncare_expiration_date)
              : null,
            peracetic_acid_indicator_test_strip_lot:
              data.peracetic_acid_indicator_test_strip_lot,
            peracetic_acid_indicator_test_strip_expiration_date:
              data.peracetic_acid_indicator_test_strip_expiration_date
                ? moment(
                    data.peracetic_acid_indicator_test_strip_expiration_date
                  )
                : null,
            peracetic_acid_residual_indicator_test_strip_lot:
              data.peracetic_acid_residual_indicator_test_strip_lot,
            peracetic_acid_residual_indicator_test_strip_expiration_date:
              data.peracetic_acid_residual_indicator_test_strip_expiration_date
                ? moment(
                    data.peracetic_acid_residual_indicator_test_strip_expiration_date
                  )
                : null,
            micron_filter_lot: data.micron_filter_lot,
            notes: data.notes,
            signature: data.signature,
            signatured_at: data.signatured_at,
          };

          await this.fetchData({ id: data.asset_inventory_id });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    fetchAssetInventory(value) {
      this.fetching = true;
      clearTimeout(this.fetchingTimeOut);

      this.fetchingTimeOut = setTimeout(this.fetchData, 500, {
        search: value,
      });
    },

    fetchData(value) {
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          ...value,
          status: [1, 2],
          is_heater_cooler: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    fetchDevices() {
      this.fetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_heater_cooler: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    handleDeviceChange(id) {
      let asset = this.assetInventory.find((item) => item.id == id);
      this.model.asset_product_id = asset.asset_product_id;
      this.model.asset_manufacturer_id = asset.asset_manufacturer_id;
      this.model.company_id = asset.company_id;
      this.model.hospital_id = asset.hospital_id;
      this.model.owned_by = asset.owned_by;
      this.model.allocated_to =
        asset.asset_inventory_hospital?.hospital_id ?? null;
      this.model.asset_inventory_data = asset;
    },

    handleFinish() {
      if (this.model.signature == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      if (this.model.id == 0) {
        this.model.employee_id = this.employee.id;
      }

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.equipmentMaintenanceStoreUrl, {
          ...this.model,
          date: this.$customDate.ymd(this.model.date),
          tubing_change_date:
            this.model.tubing_change_date != null
              ? this.$customDate.ymd(this.model.tubing_change_date)
              : null,
          livanova_3t_aerosol_collection_set_change_date:
            this.model.livanova_3t_aerosol_collection_set_change_date != null
              ? this.$customDate.ymd(
                  this.model.livanova_3t_aerosol_collection_set_change_date
                )
              : null,
          water_sample_shipped_date:
            this.model.water_sample_shipped_date != null
              ? this.$customDate.ymd(this.model.water_sample_shipped_date)
              : null,
          minncare_expiration_date:
            this.model.minncare_expiration_date != null
              ? this.$customDate.ymd(this.model.minncare_expiration_date)
              : null,
          peracetic_acid_indicator_test_strip_expiration_date:
            this.model.peracetic_acid_indicator_test_strip_expiration_date !=
            null
              ? this.$customDate.ymd(
                  this.model.peracetic_acid_indicator_test_strip_expiration_date
                )
              : null,
          peracetic_acid_residual_indicator_test_strip_expiration_date:
            this.model
              .peracetic_acid_residual_indicator_test_strip_expiration_date !=
            null
              ? this.$customDate.ymd(
                  this.model
                    .peracetic_acid_residual_indicator_test_strip_expiration_date
                )
              : null,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$router.go(-1);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.submitBtnLoader = false));
    },

    clear() {
      this.model.signature = null;
      this.model.signatured_at = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.signature = data;
      this.model.signatured_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      return Promise.resolve();
    },
  },
};
</script>
