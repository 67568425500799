const state = {
  sideDrawer: false,
  title: "Drawer Title",
  width: "",
  path: "",
  placement: "right",
  loading: false,
  callback: "",
  record: {},
  extra: {},
};

const getters = {
  sideDrawer: (state) => state.sideDrawer,
  title: (state) => state.title,
  width: (state) => state.width,
  path: (state) => state.path,
  placement: (state) => state.placement,
  loading: (state) => state.loading,
  callback: (state) => state.callback,
  record: (state) => state.record,
  extra: (state) => state.extra,
};

const actions = {
  open({ dispatch, commit }, values) {
    if (values.path == "order.cart") {
      dispatch("cart/fetchProductFromLocalStorage", {}, { root: true });
    }
    commit("open", values);
  },
  close({ commit }) {
    commit("reset");
  },
  loadingStart({ commit }) {
    commit("loadingStart");
  },
  loadingStop({ commit }) {
    commit("loadingStop");
  },
};

const mutations = {
  open(state, values) {
    state.sideDrawer = true;
    state.title = values.title;
    state.width = values.width ?? "";
    state.path = values.path;
    state.placement = values.placement ?? "right";
    state.loading = false;
    state.callback = values.callback ?? "";
    state.record = values.record ?? {};
    state.extra = values.extra ?? {};
  },
  reset(state) {
    state.sideDrawer = false;
    state.title = "Drawer Title";
    state.width = "50%";
    state.path = "";
    state.placement = "right";
    state.loading = false;
    state.callback = "";
    state.record = {};
    state.extra = {};
  },
  loadingStart(state) {
    state.loading = true;
  },
  loadingStop(state) {
    state.loading = false;
  },
};

export const drawer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
