<template>
  <div
    :style="{
      position: 'absolute',
      left: 0,
      bottom: '-53px',
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
    }"
  >
    <slot v-if="showSlot" name="custom" />
    <template v-else>
      <a-button
        html-type="button"
        :style="{ marginRight: '8px' }"
        @click="close()"
      >
        {{ cancelBtnText }}
      </a-button>
      <a-button
        v-if="name"
        type="primary"
        html-type="submit"
        :loading="loading"
      >
        {{ name }}
      </a-button>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    name: { type: String, default: null },
    showSlot: { type: Boolean, default: false },
    cancelBtnText: { type: String, default: "Cancel" },
  },
  computed: {
    ...mapGetters("modal", ["loading"]),
  },
  methods: {
    ...mapActions("modal", ["close"]),
  },
};
</script>
