<template>
  <a-form
    name="shift_change_form"
    ref="shift_change_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="24">
        <a-form-item
          ref="employee_id"
          label="Assign To"
          name="employee_id"
          :rules="{
            required: true,
            type: 'integer',
            message: 'This field must be valid input.',
          }"
        >
          <a-select
            v-model:value="model.employee_id"
            show-search
            optionFilterProp="label"
            allowClear
          >
            <a-select-option
              v-for="option in employees"
              :key="option.id"
              :label="option.full_name"
              :value="option.id"
            >
              {{ option.full_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <signature-box v-model:sign="model.sign" />
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="checked"
          name="checked"
          :rules="{
            required: true,
            message: 'This field is required.',
            validator: $validation.boolRequired,
          }"
        >
          <a-checkbox v-model:checked="model.checked">
            <strong>Chart Handoff Acknowledgment:</strong> The outgoing
            perfusionist has completed the chart up to this point and is
            assigning responsibility for the remaining part of the procedure to
            the incoming perfusionist. By pressing the 'Acknowledge' button, the
            outgoing perfusionist confirms that they have reviewed the chart,
            discussed any necessary information with the incoming perfusionist,
            and are handing off responsibility for the remainder of the
            procedure. The incoming perfusionist should review the chart,
            confirm the information with the outgoing perfusionist, and assume
            responsibility for the remaining part of the procedure. Any
            necessary updates or additional information should be documented by
            the incoming perfusionist.
          </a-checkbox>
        </a-form-item>
      </a-col>
    </a-row>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";
import signatureBox from "../../../components/signatureBox.vue";

export default {
  components: { signatureBox },

  data() {
    return {
      model: {
        uuid: null,
        date: null,
        time: "",
        event: "Shift Change",
        employee_id: undefined,
        sign: null,
        checked: false,
      },
      employees: [],
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },
  },

  mounted() {
    this.$comman
      .getEmployees()
      .then(
        (res) =>
          (this.employees = res.filter((item) => item.id != this.employee.id))
      );

    this.model.date = moment().tz(this.timezone);
    this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      if (!this.model.sign) {
        this.$message.error("Employee signature is required for shift change.");
        return;
      }

      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));
      data.uuid = this.record.uuid;
      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;

      commonService
        .store(this.$constants.caseReportShiftChangeUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
