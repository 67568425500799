<template>
  <div>
    <a-form
      name="employee_form"
      ref="employee_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="8">
          <a-form-item ref="first_name" label="First Name" name="first_name">
            <a-input
              v-model:value="formState.first_name"
              placeholder="Enter first name here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item ref="middle_name" label="Middle Name" name="middle_name">
            <a-input
              v-model:value="formState.middle_name"
              placeholder="Enter middle name here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item ref="last_name" label="Last Name" name="last_name">
            <a-input
              v-model:value="formState.last_name"
              placeholder="Enter last name here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="email" label="Email" name="email">
            <a-input
              v-model:value="formState.email"
              placeholder="Enter email here"
              :disabled="submitButtonText == 'Create' ? false : true"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="contact_1" label="Contact Number" name="contact_1">
            <a-input
              v-model:value="formState.contact_1"
              placeholder="Enter contact number here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="company_id" label="Company" name="company_id">
            <a-select
              v-model:value="formState.company_id"
              placeholder="select company"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="company in companies"
                :key="company.id"
                :label="company.name"
                :value="company.id"
              >
                {{ company.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="employee_type" label="Type" name="employee_type">
            <a-select
              v-model:value="formState.employee_type"
              placeholder="select employee type"
              optionFilterProp="label"
            >
              <a-select-option
                v-for="type in $constants.employee_types"
                :key="type.value"
                :label="type.text"
                :value="type.value"
              >
                {{ type.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item ref="role_id" label="Role" name="role_id">
            <a-select
              v-model:value="formState.role_id"
              placeholder="select employee role"
              optionFilterProp="label"
              @change="change_role"
            >
              <a-select-option
                v-for="role in roles"
                :key="role.id"
                :label="role.name"
                :value="role.id"
                :selected_routes="role.associated_rights"
              >
                {{ role.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-skeleton :loading="skeleton_loading" active>
            <a-checkbox-group
              :default-value="checked_routes"
              :value="checked_routes"
              @change="on_change"
              style="width: 100%"
            >
              <h3>
                System Access
                <small class="danger-color text-weight-light"
                  >(Click "<i class="fas fa-check"></i>" to select all child
                  roles)</small
                >
              </h3>
              <div
                class="ant-table ant-table-scroll-position-left ant-table-small"
              >
                <div class="ant-table-content">
                  <div class="ant-table-body">
                    <table>
                      <thead class="ant-table-thead">
                        <tr>
                          <th class="width-500">Module Name</th>
                          <th style="text-align: center !important">Create</th>
                          <th style="text-align: center !important">View</th>
                          <th style="text-align: center !important">Update</th>
                          <th style="text-align: center !important">Delete</th>
                        </tr>
                      </thead>
                      <tbody class="ant-table-tbody">
                        <template v-if="routes">
                          <child-routes
                            :routes="routes"
                            :parent_id="0"
                            :depth="0"
                            :default_selected_routes="default_selected_routes"
                            @big_checkbox_checked="big_checkbox_checked"
                          />
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </a-checkbox-group>
          </a-skeleton>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import ChildRoutes from "@/components/childRoutes";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
    "child-routes": ChildRoutes,
  },
  data() {
    return {
      formState: {},
      rules: {
        first_name: [
          {
            required: true,
            message: "first name is required!",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "last name is required!",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "email is required!",
            trigger: "blur",
          },
          {
            type: "email",
            message: "email must be a valid email!",
            trigger: "blur",
          },
        ],
        contact_1: [
          {
            required: true,
            message: "contact number is required!",
            trigger: "blur",
          },
          {
            validator: this.$validation.numeric,
            trigger: "blur",
          },
        ],
        company_id: [
          {
            required: true,
            type: "number",
            message: "company is required!",
            trigger: "blur",
          },
        ],
        employee_type: [
          {
            required: true,
            type: "number",
            message: "type is required!",
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            type: "number",
            message: "role is required!",
            trigger: "blur",
          },
        ],
      },
      checked_routes: [],
      skeleton_loading: false,
      default_selected_routes: [],
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    companies() {
      return this.extra.companies;
    },
    routes() {
      return this.extra.routes;
    },
    roles() {
      return this.extra.roles;
    },
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },
  created() {
    this.roles.find((v) => {
      if (v.id == this.formState.role_id) {
        const rights = v.associated_rights;
        this.rights_cal(rights);
      }
    });
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.formState = {
        first_name: this.record.first_name ?? "",
        middle_name: this.record.middle_name ?? "",
        last_name: this.record.last_name ?? "",
        email: this.record.email ?? "",
        contact_1: this.record.contact_1 ?? "",
        company_id: this.record.company_id ?? null,
        employee_type: this.record.employee_type ?? null,
        role_id: this.record.user ? this.record.user.role_id ?? null : null,
      };
    },
    on_change(checked_values) {
      this.checked_routes = checked_values;
    },
    change_role(e, e1) {
      const rights = e1.selected_routes;
      this.rights_cal(rights);
    },
    rights_cal(rights) {
      let extra_rights = this.record.user
        ? this.record.user.extra_rights
        : null;
      if (rights != null) {
        this.default_selected_routes = Array.from(rights.split(","), Number);
        if (extra_rights != null) {
          extra_rights = Array.from(extra_rights.split(","), Number);
          this.checked_routes = this.default_selected_routes.concat(
            extra_rights
          );
          this.checked_routes = this.checked_routes.filter(
            (value, index, self) => {
              return self.indexOf(value) === index;
            }
          );
        } else {
          this.checked_routes = this.default_selected_routes;
        }
      } else {
        this.default_selected_routes = [];
        if (extra_rights != null) {
          extra_rights = Array.from(extra_rights.split(","), Number);
          this.checked_routes = extra_rights;
        } else {
          this.checked_routes = this.default_selected_routes;
        }
      }
    },
    big_checkbox_checked(ids, type) {
      if (type == "checked") {
        ids.forEach((v) => {
          var index = this.checked_routes.indexOf(v);
          if (index == -1) {
            this.checked_routes.push(v);
          }
        });
      } else {
        ids.forEach((v) => {
          var index = this.checked_routes.indexOf(v);
          if (index != -1) {
            this.checked_routes.splice(index, 1);
          }
        });
      }
    },
    onSubmit() {
      this.$refs.employee_form
        .validate()
        .then((val) => {
          val.image = this.fileList;
          if (this.record.id) {
            val.id = this.record.id;
          }
          this.roles.find((v) => {
            if (v.id == val.role_id) {
              let associated_rights = v.associated_rights;
              if (associated_rights == null) {
                associated_rights = [];
              } else {
                associated_rights = Array.from(
                  associated_rights.split(","),
                  Number
                );
              }
              associated_rights.forEach((element) => {
                const index = this.checked_routes.indexOf(element);
                if (index > -1) {
                  this.checked_routes.splice(index, 1);
                }
              });
            }
          });
          val.rights = this.checked_routes;
          this.loadingStart();
          companyService
            .store("api/employee/store", val)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
  },
};
</script>

<style></style>
