<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #title>
        {{ submitButtonText }} Offer Letter | {{ model.full_name ?? "" }}
      </template>

      <template #extra>
        <!-- <a-button type="primary" :loading="renderingPdf" @click="renderPdf">
          <a-space> <i class="ti ti-file-text ti-lg"></i> Preview </a-space>
        </a-button> -->
      </template>
    </a-page-header>

    <a-card :loading="loading" size="small">
      <a-alert
        v-if="isOfferLetterContentEmpty"
        type="info"
        message="You can not create offer letter as template has not been set yet for this position."
        class="mb-sm-1"
        showIcon
      />

      <a-form
        ref="offer_letter_form"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="onSubmit()"
      >
        <a-row :gutter="[20, 0]">
          <a-col :sm="12">
            <a-form-item
              ref="employee_position_id"
              label="Employee Position"
              name="employee_position_id"
            >
              <a-select
                v-model:value="model.employee_position_id"
                placeholder="Select One..."
                optionFilterProp="label"
                showSearch
              >
                <a-select-option
                  v-for="positon in employeePositions"
                  :key="positon.id"
                  :label="positon.name"
                  :value="positon.id"
                >
                  {{ positon.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <template v-if="model.employee_position_id">
            <a-col :sm="12">
              <a-form-item
                ref="start_date"
                label="Start Date"
                name="start_date"
              >
                <a-date-picker
                  v-model:value="model.start_date"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                  :disabledDate="$comman.disabledPastDate"
                  :allowClear="false"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="12">
              <a-form-item
                ref="offer_date"
                label="Offer Date"
                name="offer_date"
              >
                <a-date-picker
                  v-model:value="model.offer_date"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                  :disabledDate="$comman.disabledPastDate"
                  :allowClear="false"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="12">
              <a-form-item
                ref="offer_expiry_date"
                label="Offer Expiry Date"
                name="offer_expiry_date"
              >
                <a-date-picker
                  v-model:value="model.offer_expiry_date"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                  :disabledDate="$comman.disabledPastDate"
                  :allowClear="false"
                />
              </a-form-item>
            </a-col>

            <template v-if="!isContract">
              <a-col :sm="12">
                <a-form-item
                  ref="smart_field_data"
                  label="Compensation UOM"
                  :name="['smart_field_data', 'compensation_uom']"
                  :rules="{
                    required: true,
                    message: 'This field is required.',
                  }"
                >
                  <a-select
                    v-model:value="model.smart_field_data.compensation_uom"
                    placeholder="Select One..."
                    optionFilterProp="label"
                    showSearch
                  >
                    <a-select-option value="Per Hour">Per Hour</a-select-option>
                    <a-select-option value="Per Year">Per Year</a-select-option>
                    <a-select-option value="Per Case">Per Case</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item
                  ref="smart_field_data"
                  label="Compensation ($)"
                  :name="['smart_field_data', 'compensation']"
                  :rules="{
                    required: true,
                    message: 'This field is required.',
                    type: 'number',
                  }"
                >
                  <a-input-number
                    v-model:value="model.smart_field_data.compensation"
                    placeholder="Enter here..."
                    type="number"
                    step="0.01"
                  />
                </a-form-item>
              </a-col>
            </template>
            <template v-else>
              <a-col :sm="12">
                <a-form-item
                  ref="smart_field_data"
                  label="Hourly Rate ($)"
                  :name="['smart_field_data', 'hourly_rate']"
                  :rules="{
                    required: true,
                    message: 'This field is required.',
                    type: 'number',
                  }"
                >
                  <a-input-number
                    v-model:value="model.smart_field_data.hourly_rate"
                    placeholder="Enter here..."
                    type="number"
                    step="0.01"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="12">
                <a-form-item
                  ref="smart_field_data"
                  label="Hourly Rate for Overtime ($)"
                  :name="['smart_field_data', 'hourly_rate_for_overtime']"
                  :rules="{
                    required: true,
                    message: 'This field is required.',
                    type: 'number',
                  }"
                >
                  <a-input-number
                    v-model:value="
                      model.smart_field_data.hourly_rate_for_overtime
                    "
                    placeholder="Enter here..."
                    type="number"
                    step="0.01"
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item
                  label="Address"
                  :name="['smart_field_data', 'address']"
                  :rules="{
                    required: true,
                    message: 'This field is required.',
                  }"
                >
                  <a-input
                    v-model:value="model.smart_field_data.address"
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item
                  label="DBA"
                  :name="['smart_field_data', 'dba']"
                  :rules="{
                    required: true,
                    message: 'This field is required.',
                  }"
                >
                  <a-input
                    v-model:value="model.smart_field_data.dba"
                    placeholder="Enter here..."
                  />
                </a-form-item>
              </a-col>
            </template>

            <a-col :span="24">
              <a-form-item
                ref="offer_letter"
                label="Offer Letter"
                name="offer_letter"
              >
                <ckeditor
                  :editor="editor"
                  v-model="model.offer_letter"
                  :config="editorConfig"
                />
              </a-form-item>
            </a-col>
          </template>
        </a-row>

        <template v-if="model.employee_position_id">
          <a-space>
            <a-button
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
              :disabled="isOfferLetterContentEmpty"
            >
              {{ submitButtonText }}
            </a-button>

            <a-button
              html-type="button"
              type="primary"
              :loading="renderingPdf || submitBtnLoader"
              @click="onSubmit({ pdf: true })"
              :disabled="isOfferLetterContentEmpty"
            >
              {{ submitButtonText }} & Preview
            </a-button>
          </a-space>
        </template>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      model: {
        id: this.$route.params.id,
        first_name: "",
        last_name: "",
        full_name: "",
        employee_position_id: null,
        start_date: null,
        offer_date: null,
        offer_expiry_date: null,
        smart_field_data: {
          compensation: null,
          compensation_uom: null,
          hourly_rate: null,
          hourly_rate_for_overtime: null,
          address: null,
          dba: null,
        },
        offer_letter: "",
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
      },
      submitButtonText: null,
      submitBtnLoader: false,
      renderingPdf: false,

      rules: {
        employee_position_id: {
          required: true,
          message: "This field is required!",
          type: "number",
        },
        start_date: {
          required: true,
          message: "This field is required!",
          type: "date",
        },
        offer_date: {
          required: true,
          message: "This field is required!",
          type: "date",
        },
        offer_expiry_date: {
          required: true,
          message: "This field is required!",
          type: "date",
        },
        offer_letter: {
          required: true,
          message: "This field is required!",
        },
      },

      employeePositions: [],
    };
  },

  computed: {
    isOfferLetterContentEmpty() {
      var position = this.employeePositions?.find(
        (e) => e.id == this.model.employee_position_id
      );
      if (!position) {
        return false;
      }
      let content = position.offer_letter_template;
      if (this.isContract) {
        content = position.offer_letter_template_for_contractor;
      }
      return content == null || content.length == 0;
    },

    isContract() {
      return this.model.employment_type === 2;
    },
  },

  mounted() {
    this.$comman.getEmployeePositions().then((res) => {
      this.employeePositions = res;
    });
    this.getCandidate();
  },

  methods: {
    getCandidate() {
      commonService
        .get(this.$constants.candidateShowUrl, { id: this.model.id })
        .then((res) => {
          this.model = {
            id: res.data.id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            full_name: res.data.full_name,
            employee_position_id: res?.data?.employee_position_id ?? null,
            employment_type: res?.data?.employment_type ?? null,
            start_date: res?.data?.start_date
              ? moment(res.data.start_date)
              : null,
            offer_date: res?.data?.offer_date
              ? moment(res.data.offer_date)
              : moment(),
            offer_expiry_date: res?.data?.offer_expiry_date
              ? moment(res.data.offer_expiry_date)
              : null,
            smart_field_data: {
              ...(res?.data?.smart_field_data ?? {}),
              compensation: res?.data?.smart_field_data?.compensation ?? null,
              compensation_uom:
                res?.data?.smart_field_data?.compensation_uom ?? null,
              hourly_rate: res?.data?.smart_field_data?.hourly_rate ?? null,
              hourly_rate_for_overtime:
                res?.data?.smart_field_data?.hourly_rate_for_overtime ?? null,
              address: res?.data?.smart_field_data?.address ?? null,
              dba: res?.data?.smart_field_data?.dba ?? null,
            },
            offer_letter: res?.data?.offer_letter ?? "",
          };

          this.submitButtonText = this.model.employee_position_id
            ? "Update"
            : "Create";
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "candidates" });
        })
        .finally(() => (this.loading = false));
    },

    onSubmit(obj = { pdf: false, send_to_approval: false }) {
      this.$refs.offer_letter_form.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .store(this.$constants.candidateOfferLetterCreateUrl, {
            ...this.model,
            start_date: this.$customDate.ymd(this.model.start_date),
            offer_date: this.$customDate.ymd(this.model.offer_date),
            offer_expiry_date: this.$customDate.ymd(
              this.model.offer_expiry_date
            ),
            send_to_approval: obj.send_to_approval,
          })
          .then((res) => {
            this.$message.success(res.message);
          })
          .finally((err) => {
            this.submitBtnLoader = false;
            if (obj?.pdf) {
              this.renderPdf();
            } else {
              this.$router.go(-1);
            }
          });
      });
    },

    renderPdf() {
      this.renderingPdf = true;

      commonService
        .renderFile(
          this.$constants.candidateOfferLetterPreviewUrl,
          { id: this.model.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.renderingPdf = false;
        });
    },
  },

  watch: {
    "model.employee_position_id": {
      deep: true,
      handler: function (val) {
        if (!this.loading) {
          this.model.offer_letter =
            this.employeePositions?.find((e) => e.id == val)?.[
              !this.isContract
                ? "offer_letter_template"
                : "offer_letter_template_for_contractor"
            ] ?? "";
        }
      },
    },
  },
};
</script>
