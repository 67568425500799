<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          Calendar Events List View
        </template>

        <template #buttons>
          <router-link
            v-if="$comman.access('create-calendar-event')"
            :to="{ name: 'create-calendar-event' }"
          >
            <a-button type="primary">
              <i class="fas fa-plus mr-lg-1"></i> Add New Event
            </a-button>
          </router-link>
        </template>
      </page-header>

      <custom-table
        :scroll="{ x: true, y: 0 }"
        :columns="columns"
        :url="$constants.calendarEventDatatableUrl"
        ref="datatable"
        :tabList="tabList"
        :params="params"
        :default-row-selection="$comman.access('cancel-calendar-event')"
        :bulkAccess="$comman.access('cancel-calendar-event')"
        @handleTabChangeEvent="handleTabChangeEvent"
      >
        <template #is_full_day_event="record">
          <a-tag v-if="record.is_full_day_event == 1" color="green">Yes</a-tag>
          <a-tag v-if="record.is_full_day_event == 0" color="red">No</a-tag>
        </template>

        <template #time="record">
          <template v-if="record.is_full_day_event == 1"> - </template>
          <template v-if="record.is_full_day_event == 0">
            {{ $customDate.hia(record.start_time) }} -
            {{ $customDate.hia(record.end_time) }}
          </template>
        </template>

        <template #action="record">
          <a-button
            type="link"
            size="small"
            @click="
              open({
                title: 'Event Details',
                path: 'calendarEvent.show',
                callback: refreshDatatable,
                record: record,
                extra: { employees },
              })
            "
            class="pl-sm-0 primary-color"
          >
            Show
          </a-button>

          <template v-if="record.status == 1">
            <router-link
              v-if="$comman.access('edit-calendar-event')"
              :to="{
                name: 'edit-calendar-event',
                params: { id: record.id },
              }"
            >
              <a-button type="link" size="small">
                Edit
              </a-button>
            </router-link>

            <a-popconfirm
              v-if="$comman.access('cancel-calendar-event')"
              title="Are you sure want to cancel this appointment?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="() => action({ id: record.id, status: '0' })"
            >
              <a-button type="link" size="small" class="danger-color">
                Cancel
              </a-button>
            </a-popconfirm>
          </template>
        </template>
      </custom-table>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      employees: [],
      params: {
        hospital_id: this.$store.getters["auth/hospital"].id,
        sortField: "date",
        sortOrder: "descend",
      },
      active_tab: 1,
      columns: [
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          width: 200,
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },

        {
          title: "Service",
          dataIndex: "case_service_name",
          key: "case_service_name",
          width: 200,
          customRender: ({ record }) => record.case_service?.name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },

        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.mdy(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },

        {
          title: "Full Day?",
          dataIndex: "is_full_day_event",
          key: "is_full_day_event",
          slots: {
            customRender: "is_full_day_event",
          },
          filters: [
            {
              text: "Yes",
              value: "1",
            },
            {
              text: "No",
              value: "0",
            },
          ],
        },

        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          slots: {
            customRender: "time",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 200,
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "Assigned",
          status: 2,
          slots: { tab: "customRender" },
        },
        {
          key: "3",
          counts: 0,
          text: "Completed",
          status: 3,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Canceled",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
    };
  },

  computed: {
    bulkAccess() {
      if (this.active_tab == 1) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.$comman.getEmployees().then((res) => (this.employees = res));
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    refreshDatatable() {
      this.$refs.datatable.refresh();
    },

    action(val) {
      commonService
        .store(this.$constants.calendarEventChangeStatusUrl, val)
        .then((res) => {
          if (res.success) {
            this.refreshDatatable();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message || "Something went wrong.");
            this.refreshDatatable();
          }
        });
    },

    handleTabChangeEvent(tab_value) {
      this.active_tab = tab_value;
    },
  },
};
</script>

<style></style>
