<template>
  <div>
    <a-divider orientation="left"> Disposable Products</a-divider>
    <a-table
      :columns="columns"
      :rowKey="(_, index) => index"
      :dataSource="record.disposable_products"
      :pagination="false"
      size="small"
      class="ant-table-vertical-align-baseline"
      :scroll="{ x: 768, y: 0 }"
    />
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapGetters } from "vuex";
import xFilterDateRangeDropdown from "../../components/table/filterDateRangeDropdown.vue";
import xFilterIcon from "../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../components/table/filterInputSearchDropdown.vue";

export default {
  components: {
    PlusOutlined,
    xFilterIcon,
    xFilterInputSearchDropdown,
    xFilterDateRangeDropdown,
  },

  data() {
    return {
      columns: [
        {
          title: "Product",
          dataIndex: "product_name",
          key: "product_name",
          customRender: ({ record }) =>
            record.form_data?.product_name.split(" | ")[1] ?? "N/A",
        },
        {
          title: "Lot Number",
          dataIndex: "lot_number",
          key: "lot_number",
          width: 150,
          customRender: ({ record }) => record.form_data?.lot_number ?? "N/A",
        },
        {
          title: "Expiration Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          width: 150,
          customRender: ({ record }) => record.form_data?.expiry_date ?? "N/A",
        },
        {
          title: "Stock Qty",
          dataIndex: "stock_qty",
          key: "stock_qty",
          width: 100,
          customRender: ({ record }) => record.form_data?.stock_qty ?? "N/A",
        },
        {
          title: "Used Qty",
          dataIndex: "used_qty",
          key: "used_qty",
          width: 100,
          customRender: ({ record }) => record.form_data?.used_qty ?? "N/A",
        },
        {
          title: "Amount",
          dataIndex: "price",
          key: "price",
          width: 150,
          customRender: ({ record }) => {
            let pricePerItem = record.price ?? 0;
            let qty = record.quantity ?? 0;
            return this.$comman.withCurrency(pricePerItem * qty);
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {},
};
</script>
