const state = {
  showModal: false,
  title: 'Modal Title',
  width: '1000px',
  path: '',
  loading: false,
  callback: '',
  record: {},
  extra: {},
};

const getters = {
  showModal: (state) => state.showModal,
  title: (state) => state.title,
  width: (state) => state.width,
  path: (state) => state.path,
  loading: (state) => state.loading,
  callback: (state) => state.callback,
  record: (state) => state.record,
  extra: (state) => state.extra,
};

const actions = {
  open ({ dispatch, commit }, values) {
    if (values.path == 'order.cart') {
      dispatch('cart/fetchProductFromLocalStorage', {}, { root: true });
    }
    // console.log({valuesActions: values});
    commit('open', values);
  },
  close ({ commit }) {
    commit('reset');
  },
  loadingStart ({ commit }) {
    commit('loadingStart');
  },
  loadingStop ({ commit }) {
    commit('loadingStop');
  },
};

const mutations = {
  open (state, values) {
    // console.log({ values });
    state.showModal = true;
    state.title = values.title;
    state.width = values.width ?? state.width;
    state.path = values.path;
    state.loading = false;
    state.callback = values.callback ?? '';
    state.record = values.record ?? {};
    state.extra = values.extra ?? {};
  },
  reset (state) {
    state.showModal = false;
    state.title = 'Modal Title';
    state.width = '1000px';
    state.path = '';
    state.loading = false;
    state.callback = '';
    state.record = {};
    state.extra = {};
  },
  loadingStart (state) {
    state.loading = true;
  },
  loadingStop (state) {
    state.loading = false;
  },
};

export const modal = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
