<template>
  <a-layout-content>
    <page-header>
      <template #title> Notifications </template>
    </page-header>

    <a-card size="small">
      <a-row :gutter="[20, 20]" justify="space-around">
        <template v-if="notifications.length">
          <a-col :span="24" v-for="day in notificationsGrouped" :key="day">
            <a-typography-title :level="5">
              {{ getRelativeTime(day.date) }}
            </a-typography-title>
            <a-list
              :loading="loading"
              size="small"
              style="padding: 0; margin: 0"
            >
              <a-list-item
                v-for="notif in day.notifications"
                :key="notif.id"
                :style="{
                  backgroundColor: notif.read_at ? 'transparent' : 'aliceblue',
                }"
              >
                <div class="message-item">
                  <a-typography-text :strong="!notif.read_at">
                    {{ notif.message }}
                  </a-typography-text>

                  <a-typography-text :strong="!notif.read_at" type="secondary">
                    {{ formatTime(notif.created_at) }}
                  </a-typography-text>
                </div>
              </a-list-item>
            </a-list>
          </a-col>
        </template>

        <template v-else>
          <a-col :span="24">
            <a-empty
              style="width: 300px; padding: 24px 0; margin: 0 auto"
              description="No notifications. Check later!"
            />
          </a-col>
        </template>

        <a-col>
          <a-pagination
            show-size-changer
            v-model:current="pagination.current"
            v-model:pageSize="pagination.pageSize"
            :total="pagination.total"
            @change="onShowSizeChange"
            @showSizeChange="onShowSizeChange"
          />
        </a-col>
      </a-row>
    </a-card>
  </a-layout-content>
</template>

<script setup>
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import moment from "moment";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

const notifications = ref([]);
const loading = ref(true);

const pagination = reactive({
  current: 1,
  pageSize: 20,
  total: 0,
});

function onShowSizeChange(current, pageSize) {
  pagination.current = current;
  pagination.pageSize = pageSize;
  fetchNotifications();
}

const notificationsGrouped = computed(() => {
  return notifications.value
    .sort((a, b) => {
      return moment(b.created_at).diff(a.created_at);
    })
    .reduce((acc, notification) => {
      const date = moment(notification.created_at).format("YYYY-MM-DD");
      const group = acc.find((group) => group.date === date);

      if (group) {
        group.notifications.push(notification);
      } else {
        acc.push({ date, notifications: [notification] });
      }

      return acc;
    }, []);
});

function getRelativeTime(date) {
  const diffInDays = moment().diff(date, "days");

  let relativeTime;
  if (diffInDays === 0) {
    // Today
    relativeTime = "Today";
  } else if (diffInDays === 1) {
    // Yesterday
    relativeTime = "Yesterday";
  } else if (diffInDays < 0) {
    // Future date
    relativeTime = `in ${Math.abs(diffInDays)} days`;
  } else if (diffInDays > 2) {
    relativeTime = moment(date).format("MMM DD, YYYY");
  } else {
    relativeTime = `${Math.abs(diffInDays)} days ago`;
  }

  return relativeTime;
}

function formatTime(time) {
  return moment(time).format("hh:mm A");
}

const store = useStore();
function fetchNotifications() {
  loading.value = true;
  return commonService
    .store(constants.systemNotifications, {
      page: pagination.current,
      pageSize: pagination.pageSize,
    })
    .then((response) => {
      notifications.value = response.data.data.data.reverse();
      pagination.total = response.data.data.total;

      if (!notifications.value.length) return;

      // commonService.store(constants.systemNotificationMarkAsRead).then(() => {
      //   store.dispatch("navbarCount/setCountForKey", {
      //     key: "total_unread_system_notification_count",
      //     value: 0,
      //   });
      // });
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(fetchNotifications);
</script>
