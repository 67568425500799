<template>
  <a-form
    name="live_charting_status_form"
    ref="live_charting_status_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <status-form :model="model" @update="update" />

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import statusForm from "../components/status.vue";

export default {
  components: { statusForm },

  data() {
    return {
      model: {
        time: moment().tz(this.$constants.timezone).format("HH:mm"),
        type: "Status",
        art_pressure: null,
        cvp: null,
        pa: null,
        blood_flow: null,
        line_pressure: null,
        venous_temp: null,
        core_temp: null,
        fio2: null,
        sweep: null,
        anesthetic_gas: null,
        act: null,
        svo2: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    update(value, key) {
      console.log("value::", value);
      console.log("key::", key);
      this.model[key] = value;
    },

    onSubmit() {
      console.log("submitted::", this.model);
      return;

      this.loadingStart();
      commonService
        .store(this.$constants.calendarEventBookUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
