<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #amount="{ record }">
        {{ $comman.withCurrency(record.amount) }}
        <template v-if="record.quickbooks_bill_id">
          (Bill Id: {{ record.quickbooks_bill_id }})
        </template>
      </template>

      <template #action="{ record }">
        <a-tooltip title="View">
        <a-button
          type="link"
          size="small"
          class="pl-sm-0 primary-color"
          @click="handleShowExpenseEvent(record)"
        >
          <i class="ti ti-eye ti-lg"></i>
        </a-button>
      </a-tooltip>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminExpenseDatatableUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense Type",
          dataIndex: "expense_type_name",
          key: "expense_type_name",
          customRender: ({ record }) => record.expense_type?.name,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          slots: {
            customRender: "amount",
          },
          defaultSortOrder:
            this.$route.query.sortField == "amount"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Expense Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "start_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Expense End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          defaultSortOrder:
            this.$route.query.sortField == "end_date"
              ? this.$route.query.sortOrder
              : undefined,
          customRender: ({ text }) => this.$customDate.ll(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.expenseStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      extraFilters: {
        hospital_id: parseInt(this.$route.params.id),
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowExpenseEvent(record) {
      this.open({
        title: "Show Expense Details",
        path: "admin.expense.show",
        callback: this.refresh,
        record: record,
      });
    },
  },
};
</script>
