<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="24">
      <a-form-item
        :name="['section3', 'a']"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
          min: 1,
        }"
      >
        <template #label>
          <a-space direction="vertical" :size="0">
            3a. Service
            <small class="text-light">
              1.Makes the best first impression: makes people feel welcome,
              responds to customer/patient complaints, anticipates
              customer/patient expectations, consistently communicates in a
              positive manner <br />
              2.Makes the best last impression: always provides thorough service
              by ensuring needs of customers are met<br />
              3.Escorts anyone who appears to be lost or confused to their
              destination<br />
              4. Shows awareness of behavior and conversation in the presence of
              patients & customers.<br />
              5. Anticipates needs of customers.
            </small>
          </a-space>
        </template>

        <a-rate
          v-model:value="model.section3.a"
          :tooltips="desc"
          @change="$emit('update:model', model)"
        />
        <span class="ant-rate-text">{{ desc[model.section3.a - 1] }}</span>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        :name="['section3', 'b']"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
          min: 1,
        }"
      >
        <template #label>
          <a-space direction="vertical" :size="0">
            3b. Teamwork
            <small class="text-light">
              Fosters a positive work environment through support and
              cooperation. Treats team members with respect and dignity.
              Communicates openly and directly providing necessary information
              to the team. Supports team decisions. Committed to flexibility and
              cohesiveness to accomplish department and organizational goals.
            </small>
          </a-space>
        </template>

        <a-rate
          v-model:value="model.section3.b"
          :tooltips="desc"
          @change="$emit('update:model', model)"
        />
        <span class="ant-rate-text">{{ desc[model.section3.b - 1] }}</span>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        :name="['section3', 'c']"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
          min: 1,
        }"
      >
        <template #label>
          <a-space direction="vertical" :size="0">
            3c. Compliance and Accountability
            <small class="text-light">
              Supports Keystone Perfusion Services' mission and adheres to the
              company code of conduct. Respects and uses company resources
              efficiently and effectively. Accepts responsibility for own
              actions and decisions. Demonstrates corporate citizenship by
              supporting the development of the business through strategic
              change and improvement.
            </small>
          </a-space>
        </template>

        <a-rate
          v-model:value="model.section3.c"
          :tooltips="desc"
          @change="$emit('update:model', model)"
        />
        <span class="ant-rate-text">{{ desc[model.section3.c - 1] }}</span>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        :name="['section3', 'd']"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
          min: 1,
        }"
      >
        <template #label>
          <a-space direction="vertical" :size="0">
            3d. Safety
            <small class="text-light">
              1. Inform patients and families about care, medications,
              treatments, and procedures; encourage them to ask questions and
              participate with caregivers in the development of their treatment
              plan.<br />
              2. Participate in required organizational and departmental patient
              safety education programs and other activities designed to improve
              departmental and organizational patient safety.<br />
              3. Promptly report serious events and incidents in accordance with
              established polices and procedures.<br />
              4. Assume responsibility for identifying processes or systems that
              could lead to errors and adverse events.<br />
              5. Know and follow organizational and departmental policies and
              procedures applicable to assigned duties.
            </small>
          </a-space>
        </template>

        <a-rate
          v-model:value="model.section3.d"
          :tooltips="desc"
          @change="$emit('update:model', model)"
        />
        <span class="ant-rate-text">{{ desc[model.section3.d - 1] }}</span>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item
        label="Evaluator Comments"
        :name="['section3', 'comments']"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <a-textarea
          v-model:value="model.section3.comments"
          placeholder="Select evaluator comments"
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        label="Average Performance Factors Score"
        :name="['section3', 'avg_score']"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-input-number type="number"
          v-model:value="model.section3.avg_score"
          disabled
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>

    <a-col :span="12">
      <a-form-item
        :name="['section3', 'overall_weightage']"
        label="Overall Weightage Score"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'number',
        }"
      >
        <a-input-number type="number"
          v-model:value="model.section3.overall_weightage"
          disabled
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
    desc: {
      require: true,
    },
  },

  data() {
    return {
      //
    };
  },

  methods: {
    validate(...arg) {
      this.$emit("validate", ...arg);
    },
  },
};
</script>
