export default function hasRoles({ next, store, roles, extra_access }) {
  // console.log('this is hasRoles middleware')

  let user = store.getters['auth/user'];
  let employee = store.getters['auth/employee'];
  if (roles.includes(user.role_id) || (employee.can_access && employee.can_access.includes(extra_access))) {
    return next();
  }
  return next({
    name: "no-access",
  });
}
