<template>
  <a-layout-content>
    <page-header>
      <template #title> Orders </template>
    </page-header>

    <a-card size="small" class="mb-sm-1">
      <a-row :gutter="[10]">
        <a-col :span="18">
          <a-input
            v-model:value="query"
            placeholder="Search Mfg. Name/Product Name/SKU"
          />
        </a-col>
        <a-col :span="3">
          <a-button
            type="primary"
            :disabled="!query"
            :loading="datatableLoading"
            @click="handleSearch"
            block
          >
            Search
          </a-button>
        </a-col>
        <a-col :span="3">
          <a-button
            :disabled="!query"
            type="primary"
            @click="handleReset"
            danger
            block
          >
            Reset
          </a-button>
        </a-col>
      </a-row>
    </a-card>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'manager.show-order',
                params: { order_no: record.order_no },
              }"
            >
              <a-tooltip title="View">
                <a-button type="link" size="small">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <template v-if="record.status == 0">
              <router-link
                :to="{
                  name: 'edit-order',
                  params: { order_no: record.order_no },
                }"
              >
                <a-tooltip title="Edit">
                  <a-button type="link" size="small">
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <a-popconfirm
                v-if="false"
                title="Are you sure you want to delete this order?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleOrderDeleteEvent(record)"
              >
                <a-tooltip title="Delete" placement="bottom">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-else-if="record.status == 1">
              <a-tooltip title="Add Dispatch Info">
                <a-button
                  type="link"
                  size="small"
                  @click="handleAddDispatchEvent(record)"
                >
                  <i class="ti ti-truck-loading ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      query: this.$route.query.search,
      debounceTimeout: null,
      datatableUrl: this.$constants.managerOrderDatatableUrl,
      columns: [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          customRender: ({ record }) => record.order_no_with_hash,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.order_no
            ? [this.$route.query?.order_no]
            : undefined,
          defaultSortOrder:
            this.$route.query.sortField == "order_no"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record.creator?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.creator_name
            ? [this.$route.query?.creator_name]
            : undefined,
        },
        {
          title: "Products/Dispatched/Received",
          dataIndex: "product_info",
          key: "product_info",
          customRender: ({ record }) => {
            return `${record.products.length}/${
              record.products.filter((v) => v.status >= 1).length
            }/${record.products.filter((v) => v.status >= 2).length}`;
          },
        },
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.order_date),
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "order_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.orderStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        order_no: this.$route.query?.order_no
          ? [this.$route.query?.order_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        creator_name: this.$route.query?.creator_name
          ? [this.$route.query?.creator_name]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleAddDispatchEvent(record) {
      this.open({
        title: "Add Order Dispatch Info",
        path: "admin.order.dispatch",
        callback: this.refresh,
        record: record,
        width: "1000px",
      });
    },

    handleSearch() {
      this.refresh({ search: this.query });
    },

    handleReset() {
      this.query = null;
      this.refresh({ search: "" });
    },
  },
};
</script>
