<template>
  <a-layout-content>
    <page-header>
      <template #title> Asset Inventory Logs</template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #serial_no="{ record }">
          <div style="display: flex">
            <span>
              {{ record.asset_inventory_data?.serial_no ?? "N/A" }}
            </span>
            <a-tooltip v-if="record.remarks">
              <template #title>
                <span>
                  {{ record.remarks }}
                </span>
              </template>
              <a-button type="link" size="small">
                <i class="ti ti-info-circle ti-lg"></i>
              </a-button>
            </a-tooltip>
          </div>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.assetInventoryHospitalLogDatatableUrl,
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital_data?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          customRender: ({ record }) =>
            record.asset_inventory_data?.asset_product?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "Model No",
          dataIndex: "model_no",
          key: "model_no",
          customRender: ({ record }) =>
            record.asset_inventory_data?.model_no ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.model_no
            ? [this.$route.query?.model_no]
            : undefined,
        },
        {
          title: "Serial No",
          dataIndex: "serial_no",
          key: "serial_no",
          slots: {
            customRender: "serial_no",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.serial_no
            ? [this.$route.query?.serial_no]
            : undefined,
        },
        {
          title: "Allocated Date",
          dataIndex: "allocated_date",
          key: "allocated_date",
          sorter: true,
          customRender: ({ text }) => text ?? this.$customDate.mdy(text),
        },
        {
          title: "Released Date",
          dataIndex: "released_date",
          key: "released_date",
          sorter: true,
          customRender: ({ text }) => text ?? this.$customDate.mdy(text),
        },
        {
          title: "Action By",
          dataIndex: "employee_name",
          key: "employee_name",
          sorter: true,
          customRender: ({ record }) => record.creator?.full_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: this.$route.query?.sortOrder
            ? this.$route.query.sortOrder
            : "descend",
          customRender: ({ text }) => `${this.$customDate.mdyhis(text)}`,
        },
      ],
      defaultFilterParams: {
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query.hospital_name]
          : undefined,
        name: this.$route.query?.name ? [this.$route.query.name] : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query.employee_name]
          : undefined,
        model_no: this.$route.query?.model_no
          ? [this.$route.query.model_no]
          : undefined,
        serial_no: this.$route.query?.serial_no
          ? [this.$route.query.serial_no]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : "created_at",
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : "descend",
      },
    };
  },

  methods: {
    //
  },
};
</script>
