import { route } from "@/helper/route";
import auth from "./middleware/auth";
import accessible from "./middleware/accessible";
import profile from "./middleware/profile";

export default [
  {
    path: "/email-templates",
    name: "emailTemplate",
    component: route("emailTemplate/index"),
    meta: {
      title: "Email Templates",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/email-template/edit/:id",
    name: "editEmailTemplate",
    component: route("emailTemplate/edit"),
    meta: {
      title: "Edit Email Template",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
];
