<template>
  <a-layout-content>
    <page-header>
      <template #title> Cell Saver Quality Control Log </template>

      <template #buttons>
        <router-link :to="{ name: 'create-cell-saver-quality-control' }">
          <a-button type="primary">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add New </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'show-cell-saver-quality-control',
                params: { id: record.id },
              }"
            >
            <a-tooltip title="View">
              <a-button size="small" type="link" class="primary-color">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
            </router-link>

            <a-tooltip title="Download">
            <a-button type="link" size="small" @click="downloadPdf(record)">
              <i class="ti ti-download ti-lg"></i>
            </a-button>
          </a-tooltip>

            <template v-if="record.status == 1">
              <router-link
                :to="{
                  name: 'edit-cell-saver-quality-control',
                  params: { id: record.id },
                }"
              >
              <a-tooltip title="Edit">
                <a-button size="small" type="link">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
              </router-link>

              <a-popconfirm
                title="Are you sure you want to delete this cell saver quality control?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '0' })
                "
              >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-else>
              <a-popconfirm
                title="Are you sure you want to restore this cell saver quality control?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '1' })
                "
              >
              <a-tooltip title="Restore">
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.cellSaverQCDatatableUrl,
      statusChangeUrl: this.$constants.cellSaverQCChangeStatusUrl,
      columns: [
        {
          title: "Date Sample Drawn",
          dataIndex: "date",
          key: "date",
          sorter: true,
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "MR Number",
          dataIndex: "mr_number",
          key: "mr_number",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.mr_number
            ? [this.$route.query?.mr_number]
            : undefined,
        },
        {
          title: "Device Info",
          dataIndex: "device_info",
          key: "device_info",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          customRender: ({ record }) =>
            record.asset_inventory_data
              ? `${record.asset_inventory_data.asset_product.name} | ${record.asset_inventory_data.model_no} | ${record.asset_inventory_data.serial_no}`
              : "N/A",
          defaultFilteredValue: this.$route.query?.device_info
            ? [this.$route.query?.device_info]
            : undefined,
        },
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        mr_number: this.$route.query?.mr_number
          ? [this.$route.query?.mr_number]
          : undefined,
        device_info: this.$route.query?.device_info
          ? [this.$route.query?.device_info]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
      },
    };
  },

  methods: {
    downloadPdf(data) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.cellSaverQCExportPdfUrl,
          { id: data.id },
          `cell-saver-quality-control.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },
  },
};
</script>
