<template>
  <a-layout-content>
    <page-header>
      <template #title> Orders </template>
      <template #buttons>
        <router-link :to="{ name: 'add-services-into-cart' }">
          <a-button type="primary" class="mr-sm-1">
            <i class="fas fa-plus mr-lg-1"></i> Add Service Into Cart
          </a-button>
        </router-link>

        <router-link :to="{ name: 'add-products-into-cart' }">
          <a-button type="primary">
            <i class="fas fa-plus mr-lg-1"></i> Add Products Into Cart
          </a-button>
        </router-link>
      </template>
    </page-header>

    <custom-table
      :scroll="{ x: true, y: 0 }"
      :columns="columns"
      :url="$constants.orderDatatableUrl"
      ref="datatable"
      :params="params"
      :tabList="tabList"
      :active_tab="active_tab"
    >
      <template #order_no="record">
        <router-link
          :to="{ name: 'view-order', params: { order_no: record.order_no } }"
        >
          <a-button type="link" class="pl-sm-0">
            {{ record.order_no_with_hash }}
          </a-button>
        </router-link>
      </template>

      <template #order_date="record">
        {{ $customDate.mdy(record.order_date) }}
      </template>
    </custom-table>
  </a-layout-content>
</template>

<script>
import {
  EditOutlined,
  DeleteOutlined,
  RestOutlined,
} from "@ant-design/icons-vue";

export default {
  components: {
    EditOutlined,
    DeleteOutlined,
    RestOutlined,
  },
  data() {
    return {
      params: {},
      columns: [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          slots: {
            customRender: "order_no",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          sorter: false,
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          sorter: false,
          customRender: ({ record }) => record.creator?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          sorter: false,
        },
        {
          title: "Received Products",
          dataIndex: "products_count",
          key: "products_count",
          sorter: false,
          customRender: ({ text }) => `${text} items`,
        },
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          sorter: true,
          defaultSortOrder: "descend",
          slots: { customRender: "order_date" },
        },
      ],
      tabList: [
        {
          key: "0",
          counts: 0,
          text: "Processing",
          status: 0,
          slots: { tab: "customRender" },
        },
        {
          key: "1",
          counts: 0,
          text: "Completed",
          status: 1,
          slots: { tab: "customRender" },
        },
      ],
      active_tab: "0",
    };
  },
  methods: {
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },
  },
};
</script>

<style scoped></style>
