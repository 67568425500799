<template>
  <div>
    <a-form
      name="master_password_form"
      ref="master_password_form"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      @finish="onSubmit()"
    >
      <a-row :gutter="[10, 0]">
        <a-col :span="24">
          <a-form-item
            ref="master_password"
            label="Master Password"
            name="master_password"
            :validateStatus="validate('master_password', 'status')"
            :help="validate('master_password', 'msg')"
          >
            <a-input-password
              v-model:value="formState.master_password"
              placeholder="Enter password here"
              @change="resetErrors('master_password')"
              :disabled="cardLoading"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="current_year_revenue_projection"
            :label="`FY ${currentYear} Revenue Projection ($)`"
            name="current_year_revenue_projection"
            :validateStatus="
              validate('current_year_revenue_projection', 'status')
            "
            :help="validate('current_year_revenue_projection', 'msg')"
          >
            <a-input-number
              v-model:value="formState.current_year_revenue_projection"
              placeholder="Enter here"
              :min="0"
              :step="0.01"
              :disabled="cardLoading"
              :formatter="$comman.currencyParserformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="current_year_carolina_revenue"
            :label="`FY ${currentYear} Carolina Sales ($)`"
            name="current_year_carolina_revenue"
            :validateStatus="
              validate('current_year_carolina_revenue', 'status')
            "
            :help="validate('current_year_carolina_revenue', 'msg')"
          >
            <a-input-number
              v-model:value="formState.current_year_carolina_revenue"
              placeholder="Enter here"
              :min="0"
              :step="0.01"
              :disabled="cardLoading"
              :formatter="$comman.currencyParserformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            label="Employees with Finance Access"
            name="finance_access"
            :validateStatus="validate('finance_access', 'status')"
            :help="validate('finance_access', 'msg')"
          >
            <a-select
              v-model:value="formState.finance_access"
              placeholder="Select employees"
              show-search
              optionFilterProp="label"
              mode="multiple"
            >
              <a-select-option
                v-for="option in employees"
                :key="option.id"
                :label="option.full_name"
                :value="option.id"
              >
                {{ option.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="labelCol.span" />

        <a-col>
          <a-button htmlType="submit" type="primary">Save</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { commonService, companyService } from "@/services";
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import formMixins from "../../mixins/formMixins";
import { mapActions } from "vuex";

export default {
  name: "master_password",
  components: {
    CheckOutlined,
  },
  mixins: [formMixins],
  data() {
    return {
      cardLoading: true,
      currentYear: moment().format("YYYY"),
      labelCol: { span: 10 },
      wrapperCol: { span: 8 },
      formState: {
        master_password: "",
        current_year_revenue_projection: null,
        current_year_carolina_revenue: null,
        finance_access: [],
      },
      employees: [],
      rules: {
        master_password: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        current_year_revenue_projection: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        current_year_carolina_revenue: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
            trigger: this.$constants.ruleTrigger,
          },
        ],
      },
    };
  },
  mounted() {
    this.cardLoading = true;
    const fields = [
      "master_password",
      "current_year_revenue_projection",
      "current_year_carolina_revenue",
      "finance_access",
    ];

    commonService
      .store(this.$constants.getSettingUrl, { key: fields })
      .then((res) => {
        if (res.success) {
          this.formState.master_password = res.data.master_password;
          this.formState.current_year_revenue_projection = res.data
            .current_year_revenue_projection
            ? Number(res.data.current_year_revenue_projection)
            : null;
          this.formState.current_year_carolina_revenue = res.data
            .current_year_carolina_revenue
            ? Number(res.data.current_year_carolina_revenue)
            : null;
          this.formState.finance_access = res.data.finance_access
            ? JSON.parse(res.data.finance_access)
            : [];
        }
      })
      .catch((err) => {
        this.$message.error(err);
      })
      .finally(() => {
        this.cardLoading = false;
      });
    this.fetchEmployees();
  },
  methods: {
    ...mapActions("auth", ["set_finance_access"]),

    onSubmit() {
      this.$refs.master_password_form
        .validate()
        .then((val) => {
          this.cardLoading = true;
          this.errors = {};

          const data = {
            master_password: val.master_password,
            current_year_revenue_projection:
              val.current_year_revenue_projection,
            current_year_carolina_revenue: val.current_year_carolina_revenue,
            finance_access: JSON.stringify(val.finance_access),
          };

          companyService
            .store(this.$constants.settingStoreUrl, data)
            .then((res) => {
              if (res.success) {
                this.set_finance_access(val.finance_access);
                this.$message.success(res.message);
              }
            })
            .catch((err) => {
              this.errors = err.errors;
            })
            .finally(() => {
              this.cardLoading = false;
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.cardLoading = false;
        });
    },

    fetchEmployees() {
      commonService
        .get(this.$constants.getEmployeesUrl, {
          with: ["user"],
        })
        .then((res) => {
          this.employees = res.data.filter(
            (employee) => employee?.user?.role_id === 1
          );
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
