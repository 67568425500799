<template>
  <div>
    <a-spin tip="Loading..." :spinning="loading">
      <a-card title="Employment Information">
        <a-form
          ref="eiRef"
          :layout="formLayout"
          :model="model"
          :rules="rules"
          @finish="handleFinish"
        >
          <a-row :gutter="[20, 0]">
            <a-col :sm="24">
              <a-form-item
                ref="isUnionMember"
                label="Is the employee part of a collective bargaining unit represented by a union?"
                name="isUnionMember"
              >
                <a-radio-group v-model:value="model.isUnionMember">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}IsUnionMember`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="employeeAgreementCategory"
                label="Employee Agreement Category"
                name="employeeAgreementCategory"
              >
                <a-select
                  v-model:value="model.employeeAgreementCategory"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="employeeAgreementCategory in $constants.employeeAgreementCategoryOption"
                    :key="employeeAgreementCategory.value"
                    :label="employeeAgreementCategory.text"
                    :value="employeeAgreementCategory.value"
                  >
                    {{ employeeAgreementCategory.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="16"></a-col>
            <a-col :sm="24">
              <a-form-item
                ref="ownerStatus"
                label="Is this employee an owner, partner or corporate executive officer listed by title in the corporate charter or by-laws?"
                name="ownerStatus"
              >
                <a-radio-group v-model:value="model.ownerStatus">
                  <a-row>
                    <a-col
                      :span="24"
                      v-for="(v, i) in $constants.ownerStatusOption"
                      :key="`${i}OwnerStatus`"
                    >
                      <a-radio :value="v.value">
                        {{ v.text }}
                      </a-radio>
                    </a-col>
                  </a-row>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="employeeJobCategory"
                label="Employee Job Category"
                name="employeeJobCategory"
              >
                <a-select
                  v-model:value="model.employeeJobCategory"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="jobCat in jobCategories"
                    :key="jobCat.id"
                    :label="jobCat.description"
                    :value="jobCat.id"
                  >
                    {{ jobCat.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="16"></a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="workEmail" label="Work Email" name="workEmail">
                <a-input
                  v-model:value="model.workEmail"
                  placeholder="Enter work email here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="workPhone" label="Work Phone" name="workPhone">
                <a-input
                  v-model:value="model.workPhone"
                  placeholder="Enter work phone here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="willWorkMultiStates"
                label="Will this employee work in multiple state?"
                name="willWorkMultiStates"
              >
                <a-radio-group v-model:value="model.willWorkMultiStates">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}WillWorkMultiStates`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item
                ref="willWorkInternational"
                label="Is the current or potential primary work location outside the United States?"
                name="willWorkInternational"
              >
                <a-radio-group
                  v-model:value="model.willWorkInternational"
                  @change="updateEiRules"
                >
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}WillWorkInternational`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24" v-if="model.willWorkInternational == 'No'">
              <a-form-item
                ref="willTravelInternational"
                label="Will this employee travel outside the United States for work-related perposes?"
                name="willTravelInternational"
              >
                <a-radio-group v-model:value="model.willTravelInternational">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}WillTravelInternational`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-button type="danger" class="mr-xs-1 width-125" html-type="submit">
            SAVE
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="resetEiForm">
            CANCEL
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="back">
            BACK
          </a-button>
          <a-button type="primary" class="width-125" @click="next">
            SAVE & NEXT
          </a-button>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { commonService } from "../../services";

export default {
  props: ["rules", "model", "personId"],
  data() {
    return {
      formLayout: "vertical",
      loading: false,
      jobCategories: [],
    };
  },
  mounted() {
    this.loadData();
    this.$refs.isUnionMember.$el
      .getElementsByClassName("ant-radio-input")[0]
      .focus();
  },
  methods: {
    loadData() {
      Promise.all([this.getJobCategories(), false])
        .then((values) => {
          [this.jobCategories] = values;
        })
        .catch(() => this.$message.error("Somthing went wrong!"));
    },
    getJobCategories() {
      return commonService
        .get(this.$constants.insJobCategoriesUrl)
        .then((res) => res);
    },
    updateEiRules() {
      if (this.model.willWorkInternational == "No") {
        this.rules.willTravelInternational = [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ];
      } else {
        delete this.rules.willTravelInternational;
      }
    },
    handleFinish(value) {
      this.handleSubmit(value);
    },
    resetEiForm() {
      Object.keys(this.model).forEach((el) => {
        if (el == "employeeAgreementCategory" || el == "employeeJobCategory") {
          this.model[el] = null;
        } else {
          this.model[el] = "";
        }
      });
    },
    next() {
      this.$refs.eiRef.validate().then((val) => {
        this.handleSubmit(val, "pushToNextStep");
      });
    },
    back() {
      this.$emit("callback", false, -1, this.personId);
    },
    handleSubmit(value, whereToGo = "") {
      this.loading = true;
      value.onboarding_person_id = this.personId;
      commonService
        .store(this.$constants.onboardingEmploymentUrl, value)
        .then((res) => {
          this.loading = false;
          if (whereToGo == "pushToNextStep") {
            this.$message.success(res.message);
            this.$emit(
              "callback",
              false,
              +1,
              res.onboarding_person_id,
              res.data
            );
          } else {
            this.$emit("callback", true);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped>
.width-125 {
  width: 125px;
}
@media (max-width: 767px) {
  .width-125 {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
