<template>
  <div>
    <a-layout-content>
      <a-page-header
        class="page-header"
        :title="title"
        style="margin-bottom: 24px; background-color: #fff"
        @back="() => $router.go(-1)"
      >
        <template #extra>
          <!--  -->
        </template>
      </a-page-header>

      <a-card :loading="loading" size="small">
        <a-form
          :model="model"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit"
        >
          <a-row :gutter="[20, 0]">
            <a-col :span="24">
              <a-form-item ref="name" label="Name" name="name">
                <a-input
                  v-model:value="model.name"
                  placeholder="Enter name here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item ref="type" label="Type" name="type">
                <a-radio-group v-model:value="model.type">
                  <a-radio
                    v-for="(v, i) in $constants.onboardingDocTypes"
                    :key="`${i}TemporaryIndicator`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="is_for_all_position"
                label="Does this apply to all positions?"
                name="is_for_all_position"
              >
                <a-radio-group v-model:value="model.is_for_all_position">
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}_is_for_all_position`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :sm="24" v-if="model.is_for_all_position == 'No'">
              <a-form-item
                ref="employee_position_ids"
                label="Employee Positions"
                name="employee_position_ids"
              >
                <a-select
                  v-model:value="model.employee_position_ids"
                  placeholder="Select Any..."
                  optionFilterProp="label"
                  showSearch
                  mode="multiple"
                >
                  <a-select-option
                    v-for="positon in employeePositions"
                    :key="positon.id"
                    :label="positon.name"
                    :value="positon.id"
                  >
                    {{ positon.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="acknowledgement_message"
                label="Acknowledgement/Signature Message"
                name="acknowledgement_message"
              >
                <a-textarea
                  v-model:value="model.acknowledgement_message"
                  placeholder="Enter Acknowledgement Message/Signature here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item
                ref="description"
                label="Description"
                name="description"
              >
                <ckeditor
                  :editor="editor"
                  v-model="model.description"
                  :config="editorConfig"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="need_to_send_attachment_on_offer_letter_mail"
                label="Should this document be sent with the job offer letter?"
                name="need_to_send_attachment_on_offer_letter_mail"
              >
                <a-radio-group
                  v-model:value="
                    model.need_to_send_attachment_on_offer_letter_mail
                  "
                >
                  <a-radio
                    v-for="(v, i) in $constants.yesNo"
                    :key="`${i}_need_to_send_attachment_on_offer_letter_mail`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="attachment_file"
                label="Attachment"
                name="attachment_file"
              >
                <a-upload
                  :fileList="model.attachment_file"
                  :remove="handleRemoveAttachment"
                  :before-upload="beforeUploadAttachment"
                  accept=".pdf,.ppt*"
                >
                  <div>
                    <a-button>
                      <UploadOutlined />
                      Upload
                    </a-button>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>

          <a-space>
            <a-button
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
            >
              Save
            </a-button>
          </a-space>
        </a-form>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { UploadOutlined } from "@ant-design/icons-vue";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

export default {
  components: { UploadOutlined },
  data() {
    return {
      loading: true,
      model: {
        id: this.$route.params.id,
        name: null,
        description: "",
        type: 0,
        acknowledgement_message: null,
        is_for_all_position: "Yes",
        need_to_send_attachment_on_offer_letter_mail: "No",
        employee_position_ids: [],
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Type here...!",
        link: {
          addTargetToExternalLinks: true,
        },
      },
      submitBtnLoader: false,

      rules: {
        name: {
          required: true,
          message: "This field is required!",
        },
        description: {
          required: true,
          message: "This field is required!",
        },
        acknowledgement_message: {
          required: true,
          message: "This field is required!",
        },
        employee_position_ids: {
          required: true,
          message: "This field is required!",
          type: "array",
        },
      },

      employeePositions: [],
    };
  },

  computed: {
    title() {
      let res = this.model.id ? "Update" : "Create";
      res += " Onboarding Documents";
      return res;
    },
  },

  mounted() {
    this.$comman.getEmployeePositions().then((res) => {
      this.employeePositions = res;
    });
    if (this.model.id) {
      this.getOnboardingDocument();
    } else {
      this.loading = false;
    }
  },

  methods: {
    handleRemoveAttachment() {
      this.model.attachment_file = [];
    },

    beforeUploadAttachment(file) {
      if (file.size < 5 * 1024 * 1024) {
        this.model.attachment_file = [file];
      } else {
        this.$message.error(`${file.name} size is more then 5MB`);
      }
      return false;
    },

    getOnboardingDocument() {
      commonService
        .get(this.$constants.onboardingDocumentShowUrl, { id: this.model.id })
        .then((res) => {
          let data = res.data;
          this.model = {
            id: data.id,
            name: data.name,
            description: data.description,
            acknowledgement_message: data.acknowledgement_message,
            type: data.type,
            is_for_all_position: data.is_for_all_position ? "Yes" : "No",
            need_to_send_attachment_on_offer_letter_mail:
              data.need_to_send_attachment_on_offer_letter_mail ? "Yes" : "No",
            employee_position_ids:
              data.employee_positions.map((e) => e.id) ?? [],
            attachment_file: data.attachment_file
              ? [
                  {
                    id: data.id,
                    uid: data.attachment_file,
                    name: data.attachment_file,
                    url: data.attachment_file_path,
                  },
                ]
              : [],
          };
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.loading = false));
    },

    onSubmit() {
      this.submitBtnLoader = true;

      const formData = new FormData();

      formData.append("id", this.model.id ?? "");
      formData.append("name", this.model.name);
      formData.append("description", this.model.description);
      formData.append(
        "acknowledgement_message",
        this.model.acknowledgement_message
      );
      formData.append("type", this.model.type);
      formData.append(
        "is_for_all_position",
        this.model.is_for_all_position == "No" ? 0 : 1
      );
      formData.append(
        "need_to_send_attachment_on_offer_letter_mail",
        this.model.need_to_send_attachment_on_offer_letter_mail == "No" ? 0 : 1
      );

      if (this.model.is_for_all_position === "No") {
        this.model.employee_position_ids.forEach((id, idx) => {
          formData.append(`employee_position_ids[${idx}]`, id);
        });
      }

      if (this.model.attachment_file?.[0]) {
        const attach = this.model.attachment_file[0];

        if ("id" in attach) {
          formData.append("old_attachment_file", attach.id);
        } else {
          formData.append("attachment_file", attach);
        }
      }

      commonService
        .store(this.$constants.onboardingDocumentStoreUrl, formData)
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          this.submitBtnLoader = false;
          this.$router.push({ name: "onboarding-documents" });
        });
    },
  },
};
</script>
