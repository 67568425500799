<template>
  <div>
    <a-form
      name="hospital_contract_form"
      ref="hospital_contract_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item ref="title" label="Title" name="title">
            <a-input
              v-model:value="formState.title"
              placeholder="Enter title here..."
            />
          </a-form-item>
        </a-col>

        <a-col :span="24" :md="12">
          <a-form-item
            ref="expiry_date"
            label="Expiration Date"
            name="expiry_date"
          >
            <a-date-picker
              v-model:value="formState.expiry_date"
              :allowClear="false"
              :disabled-date="$comman.disabledPastDate"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24" :md="12">
          <a-form-item
            ref="is_annual_escalator"
            label="&nbsp;"
            name="is_annual_escalator"
          >
            <a-checkbox v-model:checked="formState.is_annual_escalator">
              Annual Escalator?
            </a-checkbox>
          </a-form-item>
        </a-col>
        <a-col :span="24" :md="12" v-if="formState.is_annual_escalator">
          <a-form-item
            ref="contract_increase_date"
            label="Contract Increase Date"
            name="contract_increase_date"
          >
            <a-date-picker
              v-model:value="formState.contract_increase_date"
              :allowClear="false"
              :disabled-date="$comman.disabledPastDate"
              :format="$constants.datepickerFormat"
              :placeholder="$constants.datePickerPlaceholder"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="attachments" label="Attachments" name="attachments">
            <a-upload
              :fileList="formState.attachments"
              :remove="handleRemoveAttachment"
              :before-upload="beforeUploadAttachment"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              multiple
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        title: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        expiry_date: [
          {
            required: true,
            message: "This field is required!",
            type: "date",
          },
        ],
        contract_increase_date: [
          {
            required: true,
            message: "This field is required!",
            type: "date",
          },
        ],
        attachment: [
          {
            required: true,
            message: "This field is required!",
            type: "array",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    products() {
      return this.extra.products;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id ?? 0,
        title: this.record.title ?? null,
        is_annual_escalator:
          this.record.is_annual_escalator == 1 ? true : false,
        expiry_date: this.record.expiry_date
          ? moment(this.record.expiry_date)
          : null,
        contract_increase_date: this.record.contract_increase_date
          ? moment(this.record.contract_increase_date)
          : null,
        attachments: this.record.attachments
          ? this.record.attachments.map((item) => ({
              id: item.id,
              uid: item.file_name,
              name: item.file_name,
              url: item.full_file_path,
            }))
          : [],
      };
    },

    onSubmit() {
      this.loadingStart();

      const formData = new FormData();
      formData.append("hospital_id", this.record.hospital_id);

      Object.keys(this.formState).forEach((key) => {
        if (this.formState[key] == null) {
          this.formState[key] = "";
        }

        if (key == "attachments") {
          if (this.formState[key][0]) {
            Object.values(this.formState[key]).forEach((attach) => {
              if ("id" in attach) {
                formData.append(`old_${key}[]`, attach.id);
              } else {
                formData.append(`${key}[]`, attach);
              }
            });
          }
        } else if (key == "expiry_date") {
          formData.append(key, this.formState[key].format("YYYY-MM-DD"));
        } else if (key == "is_annual_escalator") {
          formData.append(key, this.formState.is_annual_escalator ? 1 : 0);
        } else if (key === "contract_increase_date") {
          formData.append(
            key,
            this.formState.is_annual_escalator
              ? this.formState[key]?.format("YYYY-MM-DD")
              : ""
          );
        } else {
          formData.append(key, this.formState[key]);
        }
      });

      commonService
        .store(this.$constants.hospitalContractStoreUrl, formData)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleRemoveAttachment(file) {
      const index = this.formState.attachments.indexOf(file);
      const newFileList = this.formState.attachments.slice();
      newFileList.splice(index, 1);
      this.formState.attachments = newFileList;
    },

    beforeUploadAttachment(file) {
      if (file.size < 15 * 1024 * 1024) {
        this.formState.attachments = [...this.formState.attachments, file];
      } else {
        this.$message.error(`${file.name} size is more then 15MB`);
      }
      return false;
    },
  },
};
</script>
