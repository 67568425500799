<template>
  <div>
    <a-form :model="model" :rules="rules" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="id"
            label="Employee Name"
            name="id"
            :validateStatus="validate('id', 'status')"
            :help="validate('id', 'msg')"
            :rules="{
              required: true,
              message: 'This field is required!',
              type: 'number',
            }"
          >
            <a-select
              v-model:value="model.id"
              placeholder="Select employee"
              show-search
              optionFilterProp="label"
            >
              <a-select-option
                v-for="option in employees"
                :key="option.id"
                :label="option.full_name"
                :value="option.id"
              >
                {{ option.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { constants } from "../../../../helper/constants";
import formMixins from "../../../../mixins/formMixins";
import { commonService } from "../../../../services";

export default {
  mixins: [formMixins],

  data() {
    return {
      employees: [],
      model: {
        id: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
          },
        ],
        percentage: [
          {
            required: true,
            message: "percentage is required!",
            type: "integer",
            max: 100,
            min: 0,
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.fetchEmployees();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      commonService
        .store(constants.crmSalesTeamStoreUrl, this.model)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.errors = err.errors;
          }
          console.log("error", err);
        })
        .finally(() => this.loadingStop());
    },

    fetchEmployees() {
      commonService
        .get(this.$constants.getEmployeesUrl, {
          select: ["id", "first_name", "last_name", "is_sales_person"],
        })
        .then((res) => {
          this.employees = res.data.filter((e) => e.is_sales_person == 0);
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>
