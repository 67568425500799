<template>
  <a-row type="flex" justify="center" class="mt-sm-2">
    <a-space direction="vertical">
      <vue-signature-pad
        :options="{ onEnd: saveSign }"
        ref="signPad"
        :customStyle="$constants.signPadCustomCss"
      />

      <a-row type="flex" justify="center">
        <a-col>
          <template v-if="hasNoteSlot">
            <slot name="note" />
          </template>
          <template v-else>
            <a-typography-paragraph>
              {{ note }}
            </a-typography-paragraph>
          </template>
        </a-col>
      </a-row>
      <a-row justify="center">
        <a-space>
          <a-button
            v-if="sign && sign.length > 0"
            htmlType="button"
            type="primary"
            danger
            ghost
            @click="clearSign"
          >
            Clear
          </a-button>

          <slot name="buttons" />
        </a-space>
      </a-row>
    </a-space>
  </a-row>
</template>

<script>
import { useSlots } from "vue";
export default {
  name: "signature-box",
  props: {
    sign: {
      type: [String, null],
      required: true,
    },
    note: {
      type: String,
      default:
        "Signature indicates that the information reported is complete and accurate.",
    },
  },
  emits: ["update:sign"],

  computed: {
    hasNoteSlot() {
      const slots = useSlots();
      return slots.note;
    },
  },

  methods: {
    clearSign() {
      this.$emit("update:sign", null);
      this.$refs.signPad.clearSignature();
    },

    saveSign() {
      const { isEmpty, data } = this.$refs.signPad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.$emit("update:sign", data);
      return Promise.resolve();
    },
  },
};
</script>
