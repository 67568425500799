<template>
  <a-row :gutter="[20, 20]" justify="space-between">
    <a-col :span="24">
      <div class="welcome-container">
        <div class="texts">
          <a-typography-title :level="3" align="center">
            Welcome to KeyPort 🎉
          </a-typography-title>

          <p>
            We are delighted to welcome you aboard. Our comprehensive onboarding
            process will assist you in setting up your account efficiently. You
            can take breaks and resume at your convenience; please ensure to
            save your progress as you proceed.
          </p>
        </div>
      </div>
    </a-col>

    <a-col :span="24">
      <footer-buttons
        :current-step="currentStepComputed"
        :steps="steps"
        @next-step="currentStepComputed += 1"
      />
    </a-col>
  </a-row>
</template>

<script setup>
import FooterButtons from "@/components/newUserOnboarding/FooterButtons.vue";
import { computed } from "vue";

const props = defineProps({
  currentStep: Number,
  steps: Array,
});
const emit = defineEmits(["update:currentStep"]);

const currentStepComputed = computed({
  get: () => props.currentStep,
  set: (value) => emit("update:currentStep", value),
});
</script>

<style lang="less" scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;

  .texts {
    width: 40%;

    span,
    p {
      display: block;
      text-align: center;
    }
  }
}
</style>
