<template>
  <a-layout-content>
    <page-header>
      <template #title> Expired Credentials </template>
    </page-header>

    <a-row :gutter="[0, 40]">
      <a-col :span="24">
        <a-row justify="space-around">
          <a-col :sm="24" :xxl="21">
            <Credentials />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { markRaw } from "@vue/reactivity";
import { commonService, companyService } from "@/services";
import { constants } from "@/helper/constants";
import moment from "moment";
import { comman as commonFunctions } from "@/helper/comman";
import { useRouter } from "vue-router";
import Credentials from "./credentials.vue";

const store = useStore();
const router = useRouter();
const employee = computed(() => store.getters["auth/employee"]);
const profileStatus = computed(() => store.getters["auth/profileStatus"]);

onMounted(() => {
  if (profileStatus.value === 1) {
    router.push({ name: "home" });
  }
});
</script>
