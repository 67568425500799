<template>
  <a-form :model="model" layout="vertical" @finish="onSubmit">
    <a-form-item
      name="note"
      :rules="{
        required: true,
        message: 'This field is required.',
      }"
    >
      <a-textarea v-model:value="model.note" placeholder="Enter note here..." />
    </a-form-item>

    <a-button htmlType="submit" type="primary" :loading="loading">
      Add Note
    </a-button>
  </a-form>

  <template v-if="record.notes.length > 0">
    <a-divider orientation="left">Note History</a-divider>

    <a-list
      class="note-histories"
      item-layout="horizontal"
      :data-source="record.notes"
      :rowKey="(record) => record.id"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment :author="item.creator.full_name">
            <template #content>
              <p>
                {{ item.note }}
              </p>
            </template>

            <template #datetime>
              <a-tooltip
                :title="moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')"
              >
                <span>{{ moment(item.created_at).fromNow() }}</span>
              </a-tooltip>
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
  </template>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
  },

  emits: ["callback"],

  data() {
    return {
      moment,
      model: {
        note: null,
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "loading"]),
  },

  created() {
    //
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      commonService
        .store(this.$constants.adminOrderAddNoteUrl, {
          order_id: this.record.id,
          note: this.model.note,
        })
        .then((res) => {
          this.loadingStop();
          this.$message.success(res.message);
          this.record.notes = res.data.notes;
          this.$emit("callback", res.data.notes);
          this.model.note = null;
        })
        .catch((err) => {
          this.loadingStop();
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="less">
.note-histories {
  .ant-comment {
    .ant-comment-inner {
      padding: 0;
    }
  }
}
</style>
