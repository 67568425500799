<template>
  <a-card size="small" :loading="cardLoading">
    <a-form
      name="hospital_ftp_form"
      ref="hospital_ftp_form"
      :model="model"
      @finish="onSubmit"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item ref="is_enable" label="Is Enable?" name="is_enable">
        <a-switch
          checked-children="Yes"
          un-checked-children="No"
          v-model:checked="model.is_enable"
        />
      </a-form-item>

      <template v-if="model.is_enable">
        <a-form-item
          ref="protocol"
          label="Protocol"
          name="protocol"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
        >
          <a-radio-group
            v-model:value="model.protocol"
            @change="protocalChangeEvent"
          >
            <a-radio-button value="ftp">
              File Transfer Protocol
            </a-radio-button>
            <a-radio-button value="sftp">
              SSH File Transfer Protocol
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          ref="host"
          name="host"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
          :validateStatus="validate('host', 'status')"
          :help="validate('host', 'msg')"
        >
          <template #label>
            Host
            <a-popover overlayClassName="overlay-popover-class">
              <template #content>
                Host can be domain, subdomain or IP address.
              </template>
              <a-button type="link" size="small">
                <i class="fa fa-info-circle"></i>
              </a-button>
            </a-popover>
          </template>
          <a-input v-model:value="model.host" placeholder="server.com" />
        </a-form-item>

        <a-form-item
          ref="username"
          label="Username"
          name="username"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
          :validateStatus="validate('username', 'status')"
          :help="validate('username', 'msg')"
        >
          <a-input v-model:value="model.username" />
        </a-form-item>

        <a-form-item
          ref="password"
          label="Password"
          name="password"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
          :validateStatus="validate('password', 'status')"
          :help="validate('password', 'msg')"
        >
          <a-input-password v-model:value="model.password" />
        </a-form-item>

        <a-form-item
          ref="port"
          name="port"
          :rules="{
            required: true,
            type: 'integer',
            message: 'This field is required',
          }"
          :validateStatus="validate('port', 'status')"
          :help="validate('port', 'msg')"
        >
          <template #label>
            Port
            <a-popover overlayClassName="overlay-popover-class">
              <template #content>
                The default port for FTP is 21 and for SFTP is 22.
              </template>
              <a-button type="link" size="small">
                <i class="fa fa-info-circle"></i>
              </a-button>
            </a-popover>
          </template>
          <a-input-number type="number" v-model:value="model.port" />
        </a-form-item>

        <a-form-item
          ref="path"
          label="Path"
          name="path"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
          :validateStatus="validate('path', 'status')"
          :help="validate('path', 'msg')"
        >
          <a-input v-model:value="model.path" placeholder="/relevant_path" />
        </a-form-item>

        <a-form-item
          label="File Type to Send"
          name="file_type_to_send"
          :rules="{
            required: true,
            message: 'This field is required',
          }"
        >
          <a-radio-group
            v-model:value="model.file_type_to_send"
            :options="$constants.fileTypesForFtp"
          />
        </a-form-item>
      </template>

      <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" htmlType="submit" :loading="btnLoading">
          {{ model.is_enable ? "Validate & Save" : "Save" }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      cardLoading: false,
      btnLoading: false,
      model: {
        is_enable: true,
        protocol: undefined,
        hospital_id: this.$route.params.id,
        host: "",
        username: "",
        password: "",
        port: null,
        path: "",
        file_type_to_send: 1,
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },

  mounted() {
    this.cardLoading = true;
    commonService
      .get(this.$constants.hospitalFtpUrl, {
        hospital_id: this.model.hospital_id,
      })
      .then((res) => {
        if (res.data) {
          this.model = {
            ...res.data,
            is_enable: res.data.is_enable == 1 ? true : false,
          };
        }
      })
      .catch((err) => {
        this.$message.error(err.message);
      })
      .finally(() => (this.cardLoading = false));
  },

  methods: {
    onSubmit() {
      this.btnLoading = true;
      commonService
        .store(this.$constants.hospitalFtpStoreUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          if (typeof err != "string") {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        })
        .finally(() => (this.btnLoading = false));
    },

    protocalChangeEvent(e) {
      if ([null, 21, 22].includes(this.model.port)) {
        this.model.port = e.target.value == "ftp" ? 21 : 22;
      }
      this.$refs["hospital_ftp_form"].validateFields(["port"]);
    },
  },
};
</script>
