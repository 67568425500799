<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12">
      <a-form-item
        label="Type of Preservation Solution"
        name="ps_type_of_perservation_solution"
        :rules="{
          message: 'This field is required!',
          required: true,
        }"
      >
        <a-select
          v-model:value="model.ps_type_of_perservation_solution"
          placeholder="Select any one..."
          show-search
          optionFilterProp="label"
          :options="$constants.perservation_solution_types"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Organ(s) Perfused"
        name="ps_organ_perfused"
        :rules="{
          message: 'This field is required!',
          required: true,
        }"
      >
        <a-select
          v-model:value="model.ps_organ_perfused"
          placeholder="Select any one..."
          mode="multiple"
          show-search
          optionFilterProp="label"
          :options="$constants.organ_perfused_types"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Volume"
        name="ps_volumn"
        :rules="{
          message: 'This field is required!',
          type: 'integer',
          required: true,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.ps_volumn"
          placeholder="Enter volumn here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Delivery Pressure"
        name="ps_delivery_pressure"
        :rules="{
          message: 'This field is required!',
          type: 'integer',
          required: true,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.ps_delivery_pressure"
          placeholder="Enter delivery pressure here..."
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "case-report-perservation-solution-step",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
