<template>
  <a-layout-content>
    <page-header>
      <template #title> Asset Inventory </template>

      <template #buttons>
        <a-space>
          <a-button
            type="primary"
            ghost
            @click="handleExcelExportEvent"
            :loading="excelExporting"
          >
            <a-space>
              <i class="ti ti-file-spreadsheet ti-lg"></i>
              Export Excel
            </a-space>
          </a-button>

          <router-link :to="{ name: 'asset.bulk-create-inventory' }">
            <a-button type="primary">
              <a-space>
                <i class="ti ti-plus ti-lg"></i> Add Inventory
              </a-space>
            </a-button>
          </router-link>
        </a-space>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="computedColumns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #owned_by="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'owned_by',
                $constants.assetInventoryOwnedBy,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "owned_by",
                $constants.assetInventoryOwnedBy
              )
            }}
          </a-tag>
        </template>

        <template #is_overdue="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'is_overdue',
                $constants.assetInventoryPreventiveDateOverdue,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "is_overdue",
                $constants.assetInventoryPreventiveDateOverdue
              )
            }}
          </a-tag>
        </template>

        <template #owner_name="{ record }">
          <template v-if="record.owned_by == 1">
            {{ record.company?.name ?? "N/A" }}
          </template>
          <template v-else>
            {{ record.hospital?.name ?? "N/A" }}
          </template>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                @click="handleEditAssetInventoryEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <template v-if="record.status == 1">
              <a-tooltip title="Assign to Facility">
                <a-button
                  size="small"
                  type="link"
                  @click="handleAssignToHospital(record)"
                >
                  <i class="ti ti-text-plus ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-popconfirm
                title="Are you sure you want to delete this asset?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '0' })
                "
              >
                <a-tooltip title="Delete">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-if="record.status == 2">
              <a-tooltip>
                <template #title>Return</template>
                <a-button
                  size="small"
                  type="link"
                  danger
                  @click="handleReturnDeviceEvent(record)"
                >
                  <i class="ti ti-arrow-back ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>

            <template v-else-if="record.status == 0">
              <a-popconfirm
                title="Are you sure you want to restore this asset?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '1' })
                "
              >
                <a-tooltip title="Restore">
                  <a-button size="small" type="link">
                    <i class="ti ti-rotate ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-if="record.status != 0">
              <router-link
                v-if="
                  record.asset_product &&
                  record.asset_product.is_required_maintenance
                "
                :to="{
                  name: 'create-biomed-service-record',
                  query: { device: record.id },
                }"
              >
                <a-tooltip title="Add Biomed Service Record">
                  <a-button type="link" size="small">
                    <i class="ti ti-tool ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <router-link
                v-if="
                  record.asset_product &&
                  record.asset_product.asset_category_id == 1
                "
                :to="{
                  name: 'create-cell-saver-quality-control',
                  query: { device: record.id },
                }"
              >
                <a-tooltip title="Add Cell Saver Quality Record">
                  <a-button type="link" size="small">
                    <i class="ti ti-droplet ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <router-link
                v-if="
                  record.asset_product &&
                  record.asset_product.asset_category_id == 2
                "
                :to="{
                  name: 'create-equipment-maintenance',
                  query: { device: record.id },
                }"
              >
                <a-tooltip title="Add Heater Cooler QA Record">
                  <a-button type="link" size="small">
                    <i class="ti ti-snowflake ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.assetInventoryDatatableUrl,
      statusChangeUrl: this.$constants.assetInventoryChangeStatusUrl,
      columns: [
        {
          title: "Product Name",
          dataIndex: "product_name",
          key: "product_name",
          customRender: ({ record }) => record.asset_product?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.product_name
            ? [this.$route.query?.product_name]
            : undefined,
        },
        {
          title: "Mfg. Name",
          dataIndex: "manufacturer_name",
          key: "manufacturer_name",
          customRender: ({ record }) =>
            record.asset_manufacturer?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.manufacturer_name
            ? [this.$route.query?.manufacturer_name]
            : undefined,
        },
        {
          title: "Model No",
          dataIndex: "model_no",
          key: "model_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.model_no
            ? [this.$route.query?.model_no]
            : undefined,
        },
        {
          title: "Serial No",
          dataIndex: "serial_no",
          key: "serial_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.serial_no
            ? [this.$route.query?.serial_no]
            : undefined,
        },
        {
          title: "Allocated Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) =>
            record.asset_inventory_hospital?.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Owned By",
          dataIndex: "owned_by",
          key: "owned_by",
          filters: this.$constants.assetInventoryOwnedBy,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.owned_by
            ? [parseInt(this.$route.query?.owned_by)]
            : undefined,
          slots: {
            customRender: "owned_by",
          },
        },
        {
          title: "Owner",
          dataIndex: "owner_name",
          key: "owner_name",
          slots: {
            customRender: "owner_name",
          },
        },
        {
          title: "Next PM Date",
          dataIndex: "next_preventive_measure_date",
          key: "next_preventive_measure_date",
          sorter: true,
          defaultSortOrder:
            this.$route.query?.sortField &&
            this.$route.query.sortField == "next_preventive_measure_date"
              ? this.$route.query.sortOrder
              : "",
          customRender: ({ text }) =>
            text ? this.$customDate.mdy(text) : "N/A",
        },
        {
          title: "Is Overdue?",
          dataIndex: "is_overdue",
          key: "is_overdue",
          filters: this.$constants.assetInventoryPreventiveDateOverdue,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.is_overdue
            ? [parseInt(this.$route.query?.is_overdue)]
            : undefined,
          slots: {
            customRender: "is_overdue",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 100,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Idle",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "In-Use",
          status: 2,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        product_name: this.$route.query?.product_name
          ? [this.$route.query?.product_name]
          : undefined,
        manufacturer_name: this.$route.query?.manufacturer_name
          ? [this.$route.query?.manufacturer_name]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        model_no: this.$route.query?.model_no
          ? [this.$route.query?.model_no]
          : undefined,
        serial_no: this.$route.query?.serial_no
          ? [this.$route.query?.serial_no]
          : undefined,
        owned_by: this.$route.query?.owned_by
          ? [this.$route.query?.owned_by]
          : undefined,
        is_overdue: this.$route.query?.is_overdue
          ? [this.$route.query?.is_overdue]
          : undefined,
        sortField: this.$route.query?.sortField
          ? this.$route.query.sortField
          : undefined,
        sortOrder: this.$route.query?.sortOrder
          ? this.$route.query.sortOrder
          : undefined,
      },

      excelExporting: false,
    };
  },

  async mounted() {
    await this.getCompanies();
    await this.getHospitals();
    await this.getAssetProducts();
    await this.getAssetManufacturers();
    this.cardLoading = false;
  },

  computed: {
    computedColumns() {
      if (this.activeTabKey != 2) {
        return this.columns.filter((item) => item.key != "hospital_name");
      }
      return this.columns.filter(
        (item) =>
          !["manufacturer_name", "model_no", "owned_by", "owner_name"].includes(
            item.key
          )
      );
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getCompanies() {
      return commonService
        .get(this.$constants.getCompaniesUrl)
        .then((res) => {
          if (res.success) {
            this.companies = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          if (res.success) {
            this.hospitals = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getAssetProducts() {
      return commonService
        .get(this.$constants.getAssetProductsUrl)
        .then((res) => {
          if (res.success) {
            this.assetProducts = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getAssetManufacturers() {
      return commonService
        .get(this.$constants.getAssetManufacturersUrl)
        .then((res) => {
          if (res.success) {
            this.assetManufacturers = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    handleEditAssetInventoryEvent(record) {
      this.open({
        title: "Edit Asset Inventory",
        path: "asset.inventory.update",
        callback: this.refresh,
        record: record,
        extra: {
          companies: this.companies,
          hospitals: this.hospitals,
          assetProducts: this.assetProducts,
          assetManufacturers: this.assetManufacturers,
        },
      });
    },

    handleAssignToHospital(record) {
      this.open({
        title: "Assign Asset Inventory to Facility",
        path: "asset.inventory.assign",
        callback: this.refresh,
        record: record,
        extra: {
          hospitals: this.hospitals,
        },
      });
    },

    handleReturnDeviceEvent(record) {
      if (!record.asset_inventory_hospital) {
        this.$message.error("Somthing went wrong.");
        return;
      }

      this.open({
        title: "Return Device",
        path: "asset.inventory.returnDevice",
        callback: this.refresh,
        record: record,
      });
    },

    handleExcelExportEvent() {
      this.excelExporting = true;
      commonService
        .downloadFile(
          this.$constants.assetInventoryExcelExportUrl,
          { status: this.activeTabKey },
          `asset-inventory-${moment().format("MM-DD-YYYY")}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.excelExporting = false;
        });
    },
  },
};
</script>
