<template>
  <div>
    <template v-if="cartProducts.length > 0">
      <a-row
        v-for="(item, index) in cartProducts"
        :key="item.id"
        align="top"
        justify="space-between"
        :gutter="[10, 10]"
      >
        <a-col :span="2">
          <h3>{{ index + 1 }}</h3>
        </a-col>

        <a-col :span="14">
          <h3 class="mb-sm-0 mt-sm-0">{{ item.name }}</h3>
          <span class="mb-sm-0">
            <span class="text-medium">Manufacturer:</span>
            {{ item.manufacturer?.name ?? "-" }}
          </span>
          <br />
          <span class="mb-sm-0">
            <span class="text-medium">UOM:</span> {{ item.uom?.name ?? "-" }}
          </span>
        </a-col>

        <a-col :span="6">
          <a-input v-model:value.number="item.qty">
            <template #addonBefore>
              <i
                v-if="item.qty == 1"
                class="fas fa-trash danger-color"
                @click="decreaseProductQty({ item, index })"
              ></i>
              <i
                v-else
                class="fas fa-minus"
                @click="decreaseProductQty({ item, index })"
              ></i>
            </template>
            <template #addonAfter>
              <i
                class="fas fa-plus"
                @click="increaseProductQty({ item, index })"
              ></i>
            </template>
          </a-input>
        </a-col>

        <a-col :span="2">
          <a-button
            type="link"
            class="danger-color"
            @click="removeProductFromCart({ index })"
          >
            <i class="fas fa-trash"></i>
          </a-button>
        </a-col>
      </a-row>

      <a-divider />

      <a-button type="primary" block @click="handleSubmitEvent">
        Submit Order
      </a-button>
    </template>
    <template v-else>
      <a-empty />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("cart", ["cartProducts", "cartSubtotal"]),
  },

  methods: {
    ...mapActions("cart", [
      "decreaseProductQty",
      "increaseProductQty",
      "removeProductFromCart",
    ]),

    ...mapActions("drawer", ["close"]),

    handleSubmitEvent() {
      this.close();
      this.$router.push({ name: "checkout-order" });
    },
  },

  watch: {
    cartProducts(val) {
      // console.log(val);
    },
  },
};
</script>

<style scoped>
.ant-input-group-wrapper {
  width: 125px !important;
}
</style>
