import { constants } from "../helper/constants";
import { commonService } from "../services/common.service";

const state = {
  counts: {},
  pendingCountsInterval: null,
};

const getters = {
  get_pending_expense_count: (state) =>
    state.counts?.pending_expense_count ?? 0,
  get_pending_time_tracking_count: (state) =>
    state.counts?.pending_time_tracking_count ?? 0,
  get_pending_order_count: (state) => state.counts?.pending_order_count ?? 0,
  get_pending_case_reporting_count: (state) =>
    state.counts?.pending_case_report_count ?? 0,
  get_total_pending_credentialing_approvals: (state) =>
    state.counts?.total_pending_credentialing_approvals ?? 0,
  get_total_pending_form_i9: (state) =>
    state.counts?.total_pending_form_i9 ?? 0,
  get_total_pending_form_w9: (state) =>
    state.counts?.total_pending_form_w9 ?? 0,
  total_unread_system_notification_count: (state) =>
    state.counts?.total_unread_system_notification_count ?? 0,
};

const actions = {
  setPendingCounts({ commit }) {
    commit("updatePendingCountsInterval", { interval: null });

    function fetch() {
      commonService.get(constants.getPendingCountsForAdmin).then((res) => {
        commit("updatePendingCounts", { count: res.data });
      });
    }

    fetch();
    let interval = setInterval(fetch, 1000 * 60 * 0.5);
    commit("updatePendingCountsInterval", { interval });
  },

  setCountForKey({ commit }, payload) {
    commit("SET_KEY_COUNT", payload);
  },

  resetPendingCountsInterval({ commit }) {
    commit("resetPendingCountsInterval");
  },
};

const mutations = {
  updatePendingCountsInterval(state, payload) {
    if (state.pendingCountsInterval) {
      clearInterval(state.pendingCountsInterval);
    }

    state.pendingCountsInterval = payload.interval;
  },

  updatePendingCounts(state, payload) {
    state.counts = payload.count;
  },

  resetPendingCountsInterval(state) {
    if (state.pendingCountsInterval) {
      clearInterval(state.pendingCountsInterval);
    }

    state.pendingCountsInterval = null;
  },

  SET_KEY_COUNT(state, payload) {
    state.counts[payload.key] = payload.count;
  },
};

export const navbarCount = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
