<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="`View Order #${order_no}`"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <a-tag v-if="order.is_flagged == 1" color="error">
          Marked as Flagged
        </a-tag>
      </template>
    </a-page-header>

    <template v-if="loading">
      <a-card>
        <a-skeleton :loading="loading" active />
      </a-card>
    </template>

    <template v-if="!loading">
      <a-space direction="vertical" style="width: 100%">
        <a-card size="small">
          <p>
            Order #{{ order_no }} was placed on
            {{ $customDate.monthDY(order.created_at) }} at
            {{ $customDate.hia(order.created_at) }} and is currently
            {{ order.status_in_string }}.
          </p>
          <h2>Order Details</h2>

          <a-table
            :columns="orderColumns"
            :rowKey="(record, index) => record.id"
            :dataSource="order.products"
            :pagination="false"
            size="small"
            bordered
            :scroll="{ x: true, y: 0 }"
          >
            <template #product_info="{ record }">
              <div>
                <span class="text-bold">
                  {{ record.name }}
                </span>
              </div>
              <div>
                <span class="text-medium"> ItemStatus: </span>
                {{ record.status_in_string }} <br />
                <span class="text-medium">Supplier: </span>
                {{ record.manufacturer?.name ?? "N/A" }}
              </div>
            </template>
          </a-table>

          <a-descriptions
            bordered
            :column="1"
            size="small"
            :labelStyle="{ width: '150px', fontWeight: 'bold' }"
          >
            <a-descriptions-item label="Note:">
              {{ order.note ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="Delivery type:">
              {{
                $comman.getValueFromObject(
                  order,
                  "delivery_type",
                  this.$constants.orderDeliveryTypes
                )
              }}
            </a-descriptions-item>
          </a-descriptions>
        </a-card>

        <a-row :gutter="[16, 0]">
          <a-col :span="24" :md="8">
            <a-card size="small" title="Billing Address" class="h-full">
              <p>
                {{ company.name }} <br />
                {{ company.address_line_1 }} <br />
                <template v-if="company.address_line_2">
                  {{ company.address_line_2 }} <br />
                </template>
                {{ company.city }}, {{ company.state }}, {{ company.zipcode }}
                <br />
                {{ company.contact_no }} <br />
                {{ company.email }}
              </p>
            </a-card>
          </a-col>

          <a-col :span="24" :md="8">
            <a-card size="small" title="Delivery Address" class="h-full">
              <p>
                <template v-if="hospital.is_same_as_facility_address == 1">
                  {{ hospital.name }} <br />
                  {{ hospital.address_line_1 }} <br />
                  <template v-if="hospital.address_line_2">
                    {{ hospital.address_line_2 }} <br />
                  </template>
                </template>
                <template v-else>
                  {{ hospital.shipping_name }} <br />
                  {{ hospital.shipping_address_line_1 }} <br />
                  <template v-if="company.shipping_address_line_2">
                    {{ company.shipping_address_line_2 }} <br />
                  </template>
                </template>
                {{ hospital.city }}, {{ hospital.state }},
                {{ hospital.zipcode }}
                <br />
                {{ hospital.contact_no }} <br />
                {{ hospital.email }}
              </p>
            </a-card>
          </a-col>

          <a-col :span="24" :md="8">
            <a-card size="small" title="Created By" class="h-full">
              <p>{{ creator.full_name }} <br /></p>
            </a-card>
          </a-col>
        </a-row>

        <a-card size="small" title="Dispatch Info">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => record.id"
            :dataSource="order.dispatches"
            :pagination="false"
            size="small"
            :scroll="{ x: true, y: 0 }"
          >
            <template #attachment="{ record }">
              <template v-if="record.attachment">
                <a :href="record.attachment_full_path" target="_blank">File</a>
              </template>
              <template v-else> N/A </template>
            </template>

            <template #status="{ record }">
              <a-tag
                :color="
                  $comman.getValueFromObject(
                    record,
                    'status',
                    $constants.dispatchedOrderStatus,
                    'color'
                  )
                "
              >
                {{
                  $comman.getValueFromObject(
                    record,
                    "status",
                    $constants.dispatchedOrderStatus
                  )
                }}
              </a-tag>
            </template>

            <template #tracking_no="{ record }">
              <a-typography-link
                target="_blank"
                v-if="
                  $comman.getTrackingURL(
                    record.carrier_partner,
                    record.tracking_no
                  )
                "
                :href="
                  $comman.getTrackingURL(
                    record.carrier_partner,
                    record.tracking_no
                  )
                "
              >
                {{ record.tracking_no }}
              </a-typography-link>
              <span v-else>
                {{ record.tracking_no }}
              </span>
            </template>

            <template #action="{ record }">
              <a-button
                class="pl-sm-0"
                type="link"
                size="small"
                @click="showDispatchInfo(record)"
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </template>
          </a-table>
        </a-card>
      </a-space>
    </template>
  </a-layout-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: {
    backButton,
  },

  data() {
    return {
      order: {},
      loading: false,
      orderColumns: [
        {
          title: "Product",
          dataIndex: "product_info",
          key: "product_info",
          slots: {
            customRender: "product_info",
          },
        },
        {
          title: "UOM",
          dataIndex: "uom_info",
          key: "uom_info",
          customRender: ({ record }) => record?.uom?.name ?? "N/A",
          width: 100,
        },
        {
          title: "Qty/Box",
          dataIndex: "qty_info",
          key: "qty_info",
          customRender: ({ record }) => `${record.qty}/${record.set}`,
          width: 100,
        },
        {
          title: "Rejected Qty/Box",
          dataIndex: "rejected_qty_info",
          key: "rejected_qty_info",
          customRender: ({ record }) =>
            `${record.rejected_qty}/${record.rejected_set}`,
          width: 100,
        },
        {
          title: "Damaged Qty",
          dataIndex: "damaged_qty",
          key: "damaged_qty",
          customRender: ({ record }) => {
            let qty = 0;
            this.order.dispatches.forEach((dispatch) => {
              dispatch.products.forEach((product) => {
                if (product.order_product_id == record.id) {
                  qty += product.damaged_qty;
                }
              });
            });
            return qty;
          },
          width: 100,
        },
        {
          title: "Missing Qty",
          dataIndex: "missing_qty",
          key: "missing_qty",
          customRender: ({ record }) => {
            let qty = 0;
            this.order.dispatches.forEach((dispatch) => {
              dispatch.products.forEach((product) => {
                if (product.order_product_id == record.id) {
                  qty += product.missing_qty;
                }
              });
            });
            return qty;
          },
          width: 100,
        },
        {
          title: "Received Qty",
          dataIndex: "received_qty",
          key: "received_qty",
          customRender: ({ record }) => {
            let qty = 0;
            this.order.dispatches.forEach((dispatch) => {
              dispatch.products.forEach((product) => {
                if (product.order_product_id == record.id) {
                  qty += product.received_qty;
                }
              });
            });
            return qty;
          },
          width: 100,
        },
      ],
      columns: [
        {
          title: "Mfg Order Id",
          dataIndex: "mfg_order_no",
          key: "mfg_order_no",
        },
        {
          title: "Carrier Partner",
          dataIndex: "carrier_partner",
          key: "carrier_partner",
        },
        {
          title: "Tracking No",
          dataIndex: "tracking_no",
          key: "tracking_no",
          slots: {
            customRender: "tracking_no",
          },
        },
        {
          title: "Shipment Date",
          dataIndex: "shipment_date",
          key: "shipment_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "notes",
        },
        {
          title: "Attachment",
          dataIndex: "attachment",
          key: "attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  mounted() {
    this.loadData();
  },

  computed: {
    order_no() {
      return this.$route.params.order_no;
    },
    company() {
      if (this.order.company != null) {
        return this.order.company;
      }
      return "";
    },
    hospital() {
      if (this.order.hospital != null) {
        return this.order.hospital;
      }
      return "";
    },
    creator() {
      if (this.order.creator != null) {
        return this.order.creator;
      }
      return "";
    },
    ...mapGetters("auth", ["isAdmin"]),
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    loadData() {
      this.loading = true;
      commonService
        .get(this.$constants.orderViewUrl, {
          order_no: this.order_no,
        })
        .then((res) => {
          this.order = res.data;
          this.loading = false;
        })
        .catch((err) => this.$message.error(err));
    },

    markAsReceived(id) {
      this.loading = true;
      commonService
        .store(this.$constants.orderProductMarkAsReceivedUrl, {
          order_product_id: id,
        })
        .then((res) => {
          this.loadData();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    revertReceived(id) {
      this.loading = true;
      commonService
        .store(this.$constants.orderProductRevertReceivedUrl, {
          order_product_id: id,
        })
        .then((res) => {
          this.loadData();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    showDispatchInfo(record) {
      this.open({
        title: "Show Order Dispatch Info",
        path: "order.dispatchInfo",
        callback: this.loadData,
        record: record,
        extra: { hospital_name: this.order?.hospital?.name ?? "N/A" },
        width: "1000px",
      });
    },
  },
};
</script>

<style scoped>
.h-full {
  height: 100%;
}
</style>
