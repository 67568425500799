<template>
  <a-form
    name="live_charting_hipec_vitals_form"
    ref="live_charting_hipec_vitals_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider orientation="left" style="margin-top: 0 !important">
      Vitals
    </a-divider>

    <a-row :gutter="[16, 0]">
      <a-col :span="8">
        <a-form-item
          ref="arterial_bp"
          label="Arterial BP (mmHg)"
          name="arterial_bp"
          :rules="{
            pattern: new RegExp(/^(\/)?([0-9]{2,3}\/[0-9]{2,3})$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input v-model:value="model.arterial_bp" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="hr"
          label="HR"
          name="hr"
          :rules="{
            min: 0,
            max: 999,
            type: 'integer',
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number type="number" v-model:value="model.hr" />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="spo2"
          name="spo2"
          :rules="[
            {
              pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
              message: 'This field must be valid input.',
            },
          ]"
        >
          <template #label> SpO<sub>2</sub> </template>
          <a-input-number
            type="number"
            v-model:value="model.spo2"
            :min="0"
            :max="100"
            :step="0.01"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider orientation="left" style="margin-top: 0 !important">
      Pump
    </a-divider>
    <a-row :gutter="[16, 0]">
      <a-col :span="8">
        <a-form-item
          ref="flow_rate"
          label="Flow Rate (ml/min)"
          name="flow_rate"
          :rules="{
            message: 'This field must be valid input.',
            pattern: new RegExp(/^[0-9]{3,4}$/),
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.flow_rate"
            :step="1"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="circuit_pressure"
          label="Circuit Pressure (mmHg)"
          name="circuit_pressure"
          :rules="{
            type: 'integer',
            min: 0,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.circuit_pressure"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="negative_pressure"
          label="Negative Pressure (mmHg)"
          name="negative_pressure"
          :rules="{
            type: 'integer',
            min: 0,
            max: 999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.negative_pressure"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="target_temp"
          label="Target Temp (°C)"
          name="target_temp"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.target_temp"
            :min="0"
            :max="99.9"
            :step="0.1"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="temp_pump"
          label="Temp Pump (°C)"
          name="temp_pump"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.temp_pump"
            :min="0"
            :max="99.9"
            :step="0.1"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="reservoir_volume"
          label="Reservoir Volume (ml)"
          name="reservoir_volume"
          :rules="{
            type: 'integer',
            min: 0,
            max: 9999,
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.reservoir_volume"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider orientation="left" style="margin-top: 0 !important">
      Temperature (°C)
    </a-divider>
    <a-row :gutter="[16, 0]">
      <a-col :span="8">
        <a-form-item
          ref="inflow"
          label="Inflow (°C)"
          name="inflow"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.inflow"
            :min="0"
            :max="99.9"
            :step="0.1"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="outflow"
          label="Outflow (°C)"
          name="outflow"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.outflow"
            :min="0"
            :max="99.9"
            :step="0.1"
          />
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item
          ref="other"
          label="Other (°C)"
          name="other"
          :rules="{
            pattern: new RegExp(/^\d+(\.\d{1})*$/),
            message: 'This field must be valid input.',
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.other"
            :min="0"
            :max="99.9"
            :step="0.1"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <template v-if="isFirstRecord">
      <a-divider orientation="left" style="margin-top: 0 !important">
        Volume
      </a-divider>
      <a-row :gutter="[16, 0]">
        <a-col :span="8">
          <a-form-item
            ref="prime"
            label="Prime (ml)"
            name="prime"
            :rules="{
              required: true,
              type: 'integer',
              min: 0,
              max: 9999,
              message: 'This field must be valid input.',
            }"
          >
            <a-input-number type="number" v-model:value="model.prime" />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item
            ref="plasmalyte"
            label="Plasmalyte (ml)"
            name="plasmalyte"
            :rules="{
              required: true,
              type: 'integer',
              min: 0,
              max: 9999,
              message: 'This field must be valid input.',
            }"
          >
            <a-input-number type="number" v-model:value="model.plasmalyte" />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item
            ref="nacl"
            label="NaCl 0.9% (ml)"
            name="nacl"
            :rules="{
              required: true,
              type: 'integer',
              min: 0,
              max: 9999,
              message: 'This field must be valid input.',
            }"
          >
            <a-input-number type="number" v-model:value="model.nacl" />
          </a-form-item>
        </a-col>
      </a-row>
    </template>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Status",

        // Vitals
        arterial_bp: "",
        hr: null,
        spo2: null,

        // Pump
        flow_rate: null,
        circuit_pressure: null,
        negative_pressure: null,
        target_temp: null,
        temp_pump: null,
        reservoir_volume: null,

        // Temperature
        inflow: null,
        outflow: null,
        other: null,

        // Volume
        prime: null,
        plasmalyte: null,
        nacl: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    isFirstRecord() {
      return this.extra?.index == 0 ? true : false;
    },
  },

  mounted() {
    if (this.extra?.item?.time) {
      this.model.unique_code = this.extra.item.unique_code;
      this.model.date = moment(this.extra.item.date, "MM-DD-YYYY");
      this.model.time = this.extra.item.time;

      // Vitals
      this.model.arterial_bp = this.extra.item.arterial_bp;
      this.model.hr = this.extra.item.hr;
      this.model.spo2 = this.extra.item.spo2;

      // Pump
      this.model.flow_rate = this.extra.item.flow_rate;
      this.model.circuit_pressure = this.extra.item.circuit_pressure;
      this.model.negative_pressure = -this.extra.item.negative_pressure;
      this.model.target_temp = this.extra.item.target_temp;
      this.model.temp_pump = this.extra.item.temp_pump;
      this.model.reservoir_volume = this.extra.item.reservoir_volume;

      // Temperature
      this.model.inflow = this.extra.item.inflow;
      this.model.outflow = this.extra.item.outflow;
      this.model.other = this.extra.item.other;

      // Volume
      this.model.prime = this.extra.item.prime;
      this.model.plasmalyte = this.extra.item.plasmalyte;
      this.model.nacl = this.extra.item.nacl;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      this.model.uuid = this.record.uuid;

      let data = JSON.parse(JSON.stringify(this.model));
      data.negative_pressure = -data.negative_pressure;

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.employee_id = !this.extra.item
        ? this.employee.id
        : this.extra.item.employee_id;
      data.employee_name = !this.extra.item
        ? this.employee.full_name
        : this.extra.item.employee_name;

      commonService
        .store(this.$constants.caseReportStoreHIPECRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
