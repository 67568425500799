<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Show Performance Evaluation"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <a-space>
          <a-button
            v-if="data"
            type="primary"
            :loading="downloadLoading"
            @click="downloadPdf"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> Download </a-space>
          </a-button>

          <a-button
            v-if="data && [1, 2].includes(data.status)"
            type="primary"
            @click="handleAddCommentEvent"
          >
            <a-space> <i class="ti ti-plus ti-lg"></i> Add Comment </a-space>
          </a-button>
        </a-space>
      </template>
    </a-page-header>

    <a-card size="small" hoverable :loading="loading">
      <template v-if="data">
        <a-collapse v-model:activeKey="activeKey">
          <a-collapse-panel key="1" header="Employee Info">
            <a-descriptions title="" bordered :column="2" size="small">
              <a-descriptions-item label="Job Type">
                {{
                  $comman.getValueFromObject(
                    data,
                    "job_type",
                    $constants.performanceEvaluationJobs
                  )
                }}
              </a-descriptions-item>
              <a-descriptions-item label="Appraisal Type">
                {{
                  $comman.getValueFromObject(
                    data,
                    "appraisal_type",
                    $constants.performanceEvaluationTypes
                  )
                }}
              </a-descriptions-item>

              <a-descriptions-item label="Employee">
                {{ data.employee?.full_name ?? "N/A" }}
              </a-descriptions-item>

              <a-descriptions-item label="Evaluation Date">
                {{ $customDate.mdy(data.evaluation_completed_date) }}
              </a-descriptions-item>

              <a-descriptions-item label="Hospital">
                {{ data.hospital?.name ?? "N/A" }}
              </a-descriptions-item>

              <a-descriptions-item label="Next Evaluation Date">
                {{ $customDate.mdy(data.next_performance_review_date) }}
              </a-descriptions-item>
            </a-descriptions>
          </a-collapse-panel>

          <a-collapse-panel key="2" header="Certifications">
            <a-descriptions title="" bordered :column="1" size="small">
              <a-descriptions-item
                label="Employee possesses all required licenses and/or certifications?"
              >
                {{
                  $comman.getValueFromObject(
                    formData,
                    "proccessed_all_required_license_and_certificates",
                    $constants.yesNoNotApplicable
                  )
                }}
              </a-descriptions-item>

              <a-descriptions-item>
                <template #label>
                  Employee's attendance meets department standards?
                  <small>(attendance may affect overall score)</small>
                </template>
                {{
                  $comman.getValueFromObject(
                    formData,
                    "employee_attendance_meets_department_standards",
                    $constants.yesNoNotApplicable
                  )
                }}
              </a-descriptions-item>

              <a-descriptions-item>
                <template #label>
                  For Clinical Personnel: Employee’s age and department specific
                  assessment has been performed this year and forwarded to Human
                  Resources.
                </template>
                {{
                  $comman.getValueFromObject(
                    formData,
                    "performance_forwared_to_hr",
                    $constants.yesNoNotApplicable
                  )
                }}
              </a-descriptions-item>
            </a-descriptions>
          </a-collapse-panel>

          <a-collapse-panel key="3" header="Performance Factors">
            <a-descriptions title="" bordered :column="1" size="small">
              <a-descriptions-item>
                <template #label>
                  <a-space direction="vertical" :size="0">
                    3a. Service
                    <small class="text-light">
                      1.Makes the best first impression: makes people feel
                      welcome, responds to customer/patient complaints,
                      anticipates customer/patient expectations, consistently
                      communicates in a positive manner <br />
                      2.Makes the best last impression: always provides thorough
                      service by ensuring needs of customers are met<br />
                      3.Escorts anyone who appears to be lost or confused to
                      their destination<br />
                      4. Shows awareness of behavior and conversation in the
                      presence of patients & customers.<br />
                      5. Anticipates needs of customers.
                    </small>
                  </a-space>
                </template>
                <a-rate :value="section3?.a ?? 0" disabled />
                <br />
                <span class="ant-rate-text">
                  {{ desc[(section3?.a ?? 0) - 1] }}
                </span>
              </a-descriptions-item>

              <a-descriptions-item>
                <template #label>
                  <a-space direction="vertical" :size="0">
                    3b. Teamwork
                    <small class="text-light">
                      Fosters a positive work environment through support and
                      cooperation. Treats team members with respect and dignity.
                      Communicates openly and directly providing necessary
                      information to the team. Supports team decisions.
                      Committed to flexibility and cohesiveness to accomplish
                      department and organizational goals.
                    </small>
                  </a-space>
                </template>
                <a-rate :value="section3?.b ?? 0" disabled />
                <br />
                <span class="ant-rate-text">
                  {{ desc[(section3?.b ?? 0) - 1] }}
                </span>
              </a-descriptions-item>

              <a-descriptions-item>
                <template #label>
                  <a-space direction="vertical" :size="0">
                    3c. Compliance and Accountability
                    <small class="text-light">
                      Supports Keystone Perfusion Services' mission and adheres
                      to the company code of conduct. Respects and uses company
                      resources efficiently and effectively. Accepts
                      responsibility for own actions and decisions. Demonstrates
                      corporate citizenship by supporting the development of the
                      business through strategic change and improvement.
                    </small>
                  </a-space>
                </template>
                <a-rate :value="section3?.c ?? 0" disabled />
                <br />
                <span class="ant-rate-text">
                  {{ desc[(section3?.c ?? 0) - 1] }}
                </span>
              </a-descriptions-item>

              <a-descriptions-item>
                <template #label>
                  <a-space direction="vertical" :size="0">
                    3d. Safety
                    <small class="text-light">
                      1. Inform patients and families about care, medications,
                      treatments, and procedures; encourage them to ask
                      questions and participate with caregivers in the
                      development of their treatment plan.<br />
                      2. Participate in required organizational and departmental
                      patient safety education programs and other activities
                      designed to improve departmental and organizational
                      patient safety.<br />
                      3. Promptly report serious events and incidents in
                      accordance with established polices and procedures.<br />
                      4. Assume responsibility for identifying processes or
                      systems that could lead to errors and adverse events.<br />
                      5. Know and follow organizational and departmental
                      policies and procedures applicable to assigned duties.
                    </small>
                  </a-space>
                </template>
                <a-rate :value="section3?.d ?? 0" disabled />
                <br />
                <span class="ant-rate-text">
                  {{ desc[(section3?.d ?? 0) - 1] }}
                </span>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions title="" bordered :column="1" size="small">
              <a-descriptions-item>
                <template #label> Evaluator Comments </template>
                {{ section3?.comments ?? "N/A" }}
              </a-descriptions-item>
            </a-descriptions>
          </a-collapse-panel>

          <a-collapse-panel key="4" header="Job Specific Competencies">
            <a-descriptions title="" bordered :column="1" size="small">
              <template v-if="data.job_type == 1">
                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4a (Weight 70%)
                      <small class="text-light">
                        The safe performance and management of Autotransfusion /
                        Blood conservation. <br />
                        Demonstrates process to set-up, start, and shut- down
                        equipment, including appropriate use of checklists
                        <br />
                        Operates equipment safely, appropriately, and according
                        to patient’s needs <br />
                        Demonstrates the addition of anticoagulant type and
                        amount required for the anticoagulant drip bag. <br />
                        Cleans/disinfects and stores equipment as per
                        manufacturer's recommendations and facility
                        policy/procedures<br />
                        "Accurately documents: Set-up, Preventative and routine
                        maintenance, Cleaning/disinfection, Equipment
                        malfunctions / failure" <br />
                        Responds to alarms in a timely manner <br />
                        Troubleshoots cell saver and related equipment alarms in
                        an organized and timely manner <br />
                        Initiates appropriate and timely interventions based
                        upon alarm data and equipment assessment. <br />
                        Demonstrates procedures for routine replacement of cell
                        saver, PRP centrifuge and related equipment as per
                        facility policy/procedure<br />
                        Demonstrates procedures for emergency replacement of
                        cell saver and related equipment as per facility
                        policy/procedure <br />
                        Identifies process to document equipment failure <br />
                        Identifies process to have equipment repaired <br />
                        Able to discuss indications / contraindications /
                        complications with physician. <br />
                        Anticipates periods of high blood loss and immediately
                        responds to process the collected blood. <br />
                        Labels transfer bag(s) and syringes with patient’s name,
                        MR number, estimated volume, time of collection and
                        expiration date/time.<br />
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.a ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.a ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4b (Weight 5%)
                      <small class="text-light">
                        Attends, participates, and engages in blood
                        management-related continuing education courses.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.b ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.b ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4c (Weight 5%)
                      <small class="text-light">
                        Collect a QC specimen on certain procedures that blood
                        is returned to the patient.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.c ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.c ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4d (Weight 20%)
                      <small class="text-light">
                        1. Assists Director Perfusion Technology with
                        departmental duties. <br />
                        2. Supports development and implementation of policies
                        and procedures.<br />
                        3. Ordering of cell saver supplies.<br />
                        4. Inventory of cell saver supplies.<br />
                        5. Stocks supplies.<br />
                        6. Cleans cell saver machine and other equipment.<br />
                        7. Initiates and sustains communications with
                        surgeons.<br />
                        8. Record keeping in accordance with CQI protocol.<br />
                        9. Maintains CQI records / QCs on pertinent
                        instruments.<br />
                        10. Participate in routine staff meetings and
                        in-services.<br />
                        11. Professional improvement by taking part in education
                        and research.<br />
                        12. Provides standby for potential surgical cases.<br />
                        13. Helps with preventative maintenance and safety
                        checks on all equipment.<br />
                        14. Sustains regular communications with KPS
                        administration.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.d ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.d ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>
              </template>

              <template v-if="data.job_type == 2">
                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4a. CPB (Weight 25%)
                      <small class="text-light">
                        The safe performance and management of Cardiopulmonary
                        Bypass / Extracorporeal Circulation.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.a ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.a ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4b. ECMO (Weight 20%)
                      <small class="text-light">
                        The safe performance and management of Extracorporeal
                        Membrane Oxygenation (ECMO).
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.b ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.b ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4c. Blood Management (Weight 15%)
                      <small class="text-light">
                        The safe performance and management of Autotransfusion /
                        Blood Conservation.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.c ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.c ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4d. Point of Care Testing (Weight 15%)
                      <small class="text-light">
                        Physiological monitoring and Blood gas / Chemistry
                        analysis.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.d ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.d ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4e. Continuing Education (Weight 5%)
                      <small class="text-light">
                        Attends, participates, and engages in perfusion-related
                        continuing education courses.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.e ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.e ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4f. Ancillary Perfusion (Weight 10%)
                      <small class="text-light">
                        The safe performance and management of Counterpulsation
                        / Intra-aortic Balloon Pump.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.f ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.f ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>

                <a-descriptions-item>
                  <template #label>
                    <a-space direction="vertical" :size="0">
                      4g. Contract Management (Weight 10%)
                      <small class="text-light">
                        1. Assists Director Perfusion Technology with
                        departmental duties.<br />
                        2. Supports development and implementation of policies
                        and procedures.<br />
                        3. Ordering of perfusion supplies.<br />
                        4. Inventory of perfusion supplies.<br />
                        5. Stocks supplies.<br />
                        6. Cleans heart-lung bypass machine and other
                        equipment.<br />
                        7. Initiates and sustains communications with
                        surgeons.<br />
                        8. Record keeping in accordance with CQI protocol.<br />
                        9. Maintains CQI records / QCs on pertinent
                        instruments.<br />
                        10. Participate in routine staff meetings and
                        in-services.<br />
                        11. Professional improvement by taking part in education
                        and research.<br />
                        12. Provides standby for potential surgical cases.<br />
                        13. Helps with preventative maintenance and safety
                        checks on all equipment.<br />
                        14. Sustains regular communications with KPS
                        administration.
                      </small>
                    </a-space>
                  </template>
                  <a-rate :value="section4?.g ?? 0" disabled />
                  <br />
                  <span class="ant-rate-text">
                    {{ desc[(section4?.g ?? 0) - 1] }}
                  </span>
                </a-descriptions-item>
              </template>
            </a-descriptions>
            <a-descriptions title="" bordered :column="1" size="small">
              <a-descriptions-item>
                <template #label> Evaluator Comments </template>
                {{ section4?.comments ?? "N/A" }}
              </a-descriptions-item>
            </a-descriptions>
          </a-collapse-panel>

          <a-collapse-panel key="5" header="Goal Achievement">
            <a-descriptions title="" bordered :column="1" size="small">
              <a-descriptions-item>
                <template #label>
                  Was the employee hired greater than 12 months ago and has set
                  goals from the previous year?
                </template>
                {{
                  $comman.getValueFromObject(
                    section5,
                    "is_old_employee",
                    $constants.yesNoNotApplicable
                  )
                }}
              </a-descriptions-item>

              <a-descriptions-item
                v-for="(goal, index) in section5.last_year_goals"
                :key="index"
              >
                <template #label>
                  <a-space direction="vertical" :size="0">
                    {{ goal.goal }} (Weight {{ goal.weightage }}%)
                  </a-space>
                </template>
                <a-rate :value="goal.rating ?? 0" disabled />
                <br />
                <span class="ant-rate-text">
                  {{ desc[(goal.rating ?? 0) - 1] }}
                </span>
              </a-descriptions-item>
            </a-descriptions>
          </a-collapse-panel>

          <a-collapse-panel key="6" header="Performance Planning">
            <a-table
              :dataSource="section6"
              :columns="performancePlanningcolumns"
              size="small"
              :pagination="false"
              :rowKey="(_, i) => i"
            >
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="7" header="Performance Factors Summary">
            <a-table
              :dataSource="section7"
              :columns="performanceFactorsColumns"
              size="small"
              :pagination="false"
              :rowKey="(_, i) => i"
            >
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="8" header="Signatures">
            <a-descriptions title="" bordered :column="1" size="small">
              <a-descriptions-item>
                <template #label> Employee </template>
                <template
                  v-if="
                    [1, 2].includes(data.status) &&
                    employee.id == data.employee_id
                  "
                >
                  <a-space direction="vertical">
                    <vue-signature-pad
                      ref="signaturePad"
                      :customStyle="$constants.signPadCustomCss"
                    />
                    <a-space>
                      <a-button
                        htmlType="button"
                        type="primary"
                        @click="save"
                        :loading="submitBtnLoader"
                      >
                        Agree
                      </a-button>

                      <a-button
                        v-if="data.status != 2"
                        htmlType="button"
                        type="primary"
                        danger
                        @click="rewalk"
                        :loading="submitBtnLoader"
                      >
                        Dispute
                      </a-button>

                      <a-button
                        htmlType="button"
                        type="primary"
                        danger
                        ghost
                        @click="clear"
                      >
                        Clear
                      </a-button>

                      <a-tag v-if="data.status == 2" color="red">
                        Marked as Disputed
                      </a-tag>

                      <a-button type="primary" @click="handleAddCommentEvent">
                        Add Comment
                      </a-button>
                    </a-space>
                  </a-space>
                </template>

                <template v-if="[3, 4].includes(data.status)">
                  <template v-if="section8[1]">
                    <img :width="200" :src="section8[1].sign" />
                    <span v-if="section8[1].signed_at">
                      <br />
                      {{ section8[1].signed_at }}
                    </span>
                  </template>
                </template>
              </a-descriptions-item>

              <a-descriptions-item v-if="[3, 4].includes(data.status)">
                <template #label> Evaluator </template>

                <template v-if="section8[0]">
                  <img :width="200" :src="section8[0].sign" />
                  <span v-if="section8[0].signed_at">
                    <br />
                    {{ section8[0].signed_at }}
                  </span>
                </template>
              </a-descriptions-item>
            </a-descriptions>
          </a-collapse-panel>

          <a-collapse-panel v-if="comments.length" key="9" header="Comments">
            <a-list
              :data-source="comments"
              header=""
              item-layout="horizontal"
              size="small"
            >
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta :description="item.comment">
                    <template #title>
                      {{ item.employee.full_name }} |
                      {{ $customDate.mdy(item.created_at) }}
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </a-collapse-panel>
        </a-collapse>
      </template>

      <template v-else>
        <a-empty description="Data not found." />
      </template>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";

export default {
  data() {
    return {
      loading: true,
      downloadLoading: false,
      submitBtnLoader: false,
      performance_id: this.$route.params.id,
      activeKey: ["1", "2", "3", "4", "5", "6", "7", "8"],
      text: "test",
      data: null,
      model: null,
      desc: this.$constants.performanceEvaluationStarDesc,
      performancePlanningcolumns: [
        {
          title: "Goal",
          dataIndex: "goal",
          key: "goal",
        },
        {
          title: "Weightage (%)",
          dataIndex: "weightage",
          key: "weightage",
          width: 150,
        },
      ],
      performanceFactorsColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          customRender: ({ record }) =>
            `${record.type} (${record.percentage}%)`,
        },
        {
          title: "Weightage (%)",
          dataIndex: "weightage",
          key: "weightage",
          width: 150,
          slots: {
            customRender: "weightage",
          },
        },
      ],
    };
  },

  mounted() {
    this.getData();
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    formData() {
      return this.data?.json ?? [];
    },

    section3() {
      return this.formData?.section3 ?? [];
    },

    section4() {
      return this.formData?.section4 ?? [];
    },

    section5() {
      return this.formData?.section5 ?? [];
    },

    section6() {
      return this.formData?.section6 ?? [];
    },

    section7() {
      return this.formData?.section7 ?? [];
    },

    section8() {
      return this.formData?.section8 ?? [];
    },

    comments() {
      return this.data.comments ?? [];
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getData() {
      commonService
        .get(this.$constants.performanceEvaluationShowUrl, {
          id: this.performance_id,
        })
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.model = {
            id: this.$route.params.id,
            section8: this.section8,
            status: this.data.status,
          };
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "admin-performance-evaluation" });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clear() {
      this.model.sign = null;
      this.$refs.signaturePad.clearSignature();
    },

    evaluatorSignClear() {
      this.model.sign = null;
      this.$refs.evaluatorSignaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return;
      }
      this.model.section8[1].sign = data;
      this.model.section8[1].signed_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      this.model.status = 3;
      this.handleFinish();
    },

    evaluatorSignSave() {
      const { isEmpty, data } =
        this.$refs.evaluatorSignaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return;
      }
      this.model.section8[0].sign = data;
      this.model.section8[0].signed_at = this.$customDate.mdyhis(
        this.$comman.momentTz()
      );
      this.model.status = 4;
      this.handleFinish();
    },

    rewalk() {
      this.model.status = 2;
      this.handleFinish();
    },

    handleFinish() {
      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.performanceEvaluationUpdateUrl, this.model)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.submitBtnLoader = false;
          this.getData();
        });
    },

    handleAddCommentEvent() {
      this.open({
        title: "Add Comment",
        path: "admin.performanceAppraisals.comment",
        record: this.data,
        extra: { comments: this.data.comments ?? [] },
        callback: this.getData,
      });
    },

    downloadPdf() {
      this.downloadLoading = true;

      commonService
        .downloadFile(
          this.$constants.performanceEvaluationExportPdfUrl,
          { id: this.data.id },
          `${this.data.employee.full_name.replace(
            ", ",
            "_"
          )}_performance_evaluation.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
  },
};
</script>
