<template>
  <div>
    <a-form
      name="return_cart_form"
      ref="return_cart_form"
      :model="formState"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-space direction="vertical" style="width: 100%">
        <a-descriptions
          title=""
          bordered
          size="small"
          :column="1"
          :labelStyle="{ width: '150px' }"
        >
          <a-descriptions-item label="Product Name">
            {{ record.name }} | {{ record.cart_allocated_date }}
          </a-descriptions-item>
          <a-descriptions-item label="Devices">
            <template v-for="(item, index) in record.items" :key="index">
              {{ item.product_name }} | {{ item.model_no }} |
              {{ item.serial_no }} <br />
            </template>
          </a-descriptions-item>
        </a-descriptions>

        <a-row :gutter="[20, 0]">
          <a-col :span="24">
            <a-form-item
              ref="released_date"
              label="Return Date"
              name="released_date"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'date',
              }"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="formState.released_date"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledFutureDate"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="remark"
              label="Remarks"
              name="remark"
              :rules="{
                required: true,
                message: 'This field is required',
              }"
            >
              <a-textarea
                v-model:value="formState.remark"
                placeholder="Enter remark"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-space>
      <side-drawer-buttons name="Replace" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        released_date: null,
        remark: null,
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    hospitalId() {
      return this.extra?.hospitalId ?? null;
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      let data = {
        name: this.record.name,
        unique_code: this.record.unique_code,
        hospital_id: this.hospitalId,
        released_date: this.$customDate.ymd(this.formState.released_date),
        remark: this.formState.remark,
      };

      this.loadingStart();
      commonService
        .store(this.$constants.cartReturnAssetInventoryHospitalUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
