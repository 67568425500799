<template>
  <a-layout-content>
    <page-header>
      <template #title>
        Checkout
      </template>
      <template #buttons>
        <back-button />
      </template>
    </page-header>

    <a-form
      ref="checkoutForm"
      :layout="formLayout"
      :model="model"
      :rules="rules"
      @finish="handleFinish"
    >
      <a-card class="mb-sm-2">
        <a-row :gutter="[20, 0]">
          <a-col :span="24" :md="12">
            <a-form-item
              ref="company_id"
              label="Billing Address"
              name="company_id"
            >
              <a-select
                v-model:value="model.company_id"
                placeholder="Select One..."
                optionFilterProp="label"
                @change="handleCompanyChange"
                showSearch
              >
                <a-select-option
                  v-for="company in companies"
                  :key="company.id"
                  :label="company.name"
                  :value="company.id"
                >
                  {{ company.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24" :md="12">
            <a-form-item
              ref="hospital_id"
              label="Where is this order shipping to?"
              name="hospital_id"
            >
              <a-select
                v-model:value="model.hospital_id"
                placeholder="Select One..."
                optionFilterProp="label"
                @change="handleHospitalChange"
                showSearch
              >
                <a-select-option
                  v-for="hospital in hospitals"
                  :key="hospital.id"
                  :label="hospital.name"
                  :value="hospital.id"
                >
                  {{ hospital.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item ref="workOption" label="Order Notes" name="workOption">
              <a-textarea
                v-model:value="model.note"
                placeholder="Notes about your order..."
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mb-sm-2">
        <div
          class="ant-table ant-table-small ant-table-bordered ant-table-scroll-position-left"
        >
          <div class="ant-table-content">
            <div class="ant-table-body">
              <table class="">
                <thead class="ant-table-thead">
                  <tr>
                    <th class="">
                      <span class="ant-table-header-column">
                        <div class="">
                          <span class="ant-table-column-title">
                            Product Name
                          </span>
                        </div>
                      </span>
                    </th>
                    <th class="column-money">
                      <span class="ant-table-header-column">
                        <div class="">
                          <span class="ant-table-column-title">
                            Price
                          </span>
                        </div>
                      </span>
                    </th>
                    <th class="column-money">
                      <span class="ant-table-header-column">
                        <div class="">
                          <span class="ant-table-column-title">
                            Qty
                          </span>
                        </div>
                      </span>
                    </th>
                    <th class="column-money">
                      <span class="ant-table-header-column">
                        <div class="">
                          <span class="ant-table-column-title">
                            Subtotal
                          </span>
                        </div>
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody class="ant-table-tbody">
                  <tr
                    v-for="product in cartProducts"
                    :key="product.id"
                    :class="`ant-table-row ant-table-row-level-${product.id}`"
                    :data-row-key="product.id"
                  >
                    <td>{{ product.name }}</td>
                    <td>{{ getProductPrice(product) }}</td>
                    <td>{{ product.qty }}</td>
                    <td>
                      {{ getProductSubtotal(product) }}
                    </td>
                  </tr>

                  <tr class="table-subtotal">
                    <td colspan="3" align="right" class="mr-sm-2">
                      Total Amount
                    </td>
                    <td>{{ getProductTotalAmount() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </a-card>

      <a-card title="Purchase Order">
        <a-form-item ref="termsAndConditions" name="termsAndConditions">
          <a-checkbox v-model:checked="model.termsAndConditions" required>
            I have read and agree to the website
            <span class="danger-color">terms and conditions *</span>
          </a-checkbox>
        </a-form-item>
        <a-button
          v-if="cartProducts.length > 0"
          type="danger"
          html-type="submit"
          :loading="submitBtnLoader"
        >
          Place Order
        </a-button>
      </a-card>
    </a-form>
  </a-layout-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { checkoutFormRules } from "../../helper/formRules";
import backButton from "../../components/backButton.vue";
import router from "../../router";
import { commonService } from "../../services";

export default {
  components: {
    backButton,
  },

  data() {
    return {
      submitBtnLoader: false,
      formLayout: "vertical",
      companies: [],
      hospitals: [],
      products: [],
      model: {
        company_id: null,
        hospital_id: null,
        note: "",
        termsAndConditions: false,
      },
      rules: checkoutFormRules,
    };
  },

  mounted() {
    this.loadData();
    this.fetchProductFromLocalStorage();
    this.rules.termsAndConditions[1] = {
      validator: this.termsAndConditionCheck,
      message: "This field is required!",
      trigger: ["change", "blur"],
    };
    this.$refs.company_id.$el
      .getElementsByClassName("ant-select-selection-search-input")[0]
      .focus();
  },

  computed: {
    ...mapGetters("cart", ["cartProducts", "cartSubtotal", "cartQty"]),
  },

  methods: {
    ...mapActions("cart", ["resetCartProducts"]),
    ...mapActions("cart", ["fetchProductFromLocalStorage"]),

    loadData() {
      Promise.all([this.loadCompanies(), false])
        .then((values) => ([this.companies] = values))
        .catch((err) => {
          this.$message.error(err);
        });
    },

    loadCompanies() {
      return commonService
        .get(this.$constants.getCompaniesUrl)
        .then((res) => res.data);
    },

    loadHospitals(val) {
      return commonService
        .get(this.$constants.getHospitalsUrl, val)
        .then((res) => res.data);
    },

    loadProducts(val) {
      return commonService
        .get(this.$constants.getHospitalWiseProductsUrl, val)
        .then((res) => res.data);
    },

    termsAndConditionCheck(rule, value) {
      if (!value) {
        return Promise.reject(rule.message);
      }
      return Promise.resolve();
    },

    handleCompanyChange() {
      if (this.model.company_id != null) {
        this.model.hospital_id = null;
        this.loadHospitals({ company_id: this.model.company_id })
          .then((res) => (this.hospitals = res))
          .catch((err) => this.$message.error(err));
      }
    },

    handleHospitalChange() {
      if (this.model.hospital_id != null) {
        this.loadProducts({ hospital_id: this.model.hospital_id })
          .then((res) => (this.products = res))
          .catch((err) => this.$message.error(err));
      }
    },

    handleFinish() {
      let values = {
        ...this.model,
        products: this.cartProducts,
        total_amount: this.cartProducts.reduce((total, item) => {
          return total + item.product_subtotal;
        }, 0),
        total_qty: this.cartProducts.reduce((total, item) => {
          return total + item.qty;
        }, 0),
      };

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.orderStoreUrl, values)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.resetCartProducts();
            router.push({ name: "order" });
          }
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          this.$message.error(err);
        });
    },

    getProductSubtotal(product) {
      let subtotal = "NA";
      if (this.products.length == 0 || this.model.hospital_id == null) {
        return subtotal;
      }

      let pro = this.products.find((v) => v.product_id == product.id);
      if (pro) {
        subtotal = product.qty * pro.price;
      } else {
        subtotal = product.qty * product.price;
      }
      product.product_subtotal = subtotal;

      return this.$comman.priceFilter(parseFloat(subtotal).toFixed(2));
    },

    getProductPrice(product) {
      let price = "NA";
      if (this.products.length == 0 || this.model.hospital_id == null) {
        return price;
      }

      let pro = this.products.find((v) => v.product_id == product.id);
      if (pro) {
        price = pro.price;
      } else {
        price = product.price;
      }
      product.product_price = price;

      return this.$comman.priceFilter(parseFloat(price).toFixed(2));
    },

    getProductTotalAmount() {
      let totalAmount = "NA";
      if (this.products.length == 0 || this.model.hospital_id == null) {
        return totalAmount;
      }

      totalAmount = this.cartProducts.reduce((total, item) => {
        if (item.product_subtotal) {
          return total + item.product_subtotal;
        } else {
          return total + 0;
        }
      }, 0);

      return this.$comman.priceFilter(parseFloat(totalAmount).toFixed(2));
    },
  },

  watch: {
    cartProducts(val) {
      if (val.length == 0) {
        router.push({ name: "order" });
      }
    },
  },
};
</script>

<style scoped>
tr.table-subtotal td,
tr.table-total td,
.ant-table-thead tr th {
  font-weight: bold;
}
.ant-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-size: 16px !important;
}
</style>
