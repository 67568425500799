<template>
  <div>
    <a-form
      name="hospital_template_form"
      ref="hospital_template_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="handleFinish"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="name"
            label="Name"
            name="name"
            :validateStatus="validate('name', 'status')"
            :help="validate('name', 'msg')"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
              @change="resetErrors('name')"
            />
          </a-form-item>
        </a-col>
      </a-row>


      <a-row :gutter="[16, 0]">
        <a-col :span="16">Hospital Name</a-col>
        <a-col :span="4">State</a-col>
        <a-col :span="4">Action</a-col>
      </a-row>

      <a-row class="mt-sm-1" :gutter="[16, 0]">
        <template v-for="(v, i) in formState.hospitalTemplates" :key="i">
          <a-col :span="16">
            <a-form-item
              ref="hospital_id"
              :name="['hospitalTemplates', i, 'hospital_id']"
              :rules="{
                required: true,
                message: 'field is required!',
                trigger: 'change',
                type: 'number',
              }"
              :wrapperCol="{ style: { width: '100%' } }"
            >
              <a-select
                v-model:value="v.hospital_id"
                placeholder="Select hospital"
                optionFilterProp="label"
                :showSearch="true"
                width="100%"
                @change="handleHospitalSelect(i)"
              >
                <a-select-option
                  v-for="hospital in unSelectedHospitals(v)"
                  :key="hospital.id"
                  :label="hospital.name"
                  :value="hospital.id"
                >
                  {{ hospital.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item ref="hospital_state" name="hospital_state">
              <a-input
                v-model:value="v.hospital_state"
                :disabled="true"
              />
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-button
              v-if="formState.hospitalTemplates.length > 1"
              type="link"
              class="danger-color"
              @click="handleFieldRemove(i)"
            >
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-col>
        </template>
      </a-row>

      <a-button class="mt-sm-1" type="dashed" block @click="addField()">
        <i class="fas fa-plus mr-sm-1"></i>
        Add field
      </a-button>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },
  data() {
    return {
      formState: {
        name: null,
        hospitalTemplates: [{}],
      },
      hospitals: [],
      hospitalIds: [],
      row: {
        id: 0,
        hospital_id: null,
        hospital_state: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  mounted() {
    this.getFormState();
    this.fetchData();
  },

  computed: {
    ...mapGetters("drawer", ["record"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    fetchData() {
      this.cardLoading = true;

      Promise.all([
        this.$comman.getHospitals(),
      ]).then((res) => {
        this.cardLoading = false;
        this.hospitals = res[0];
        this.formState.hospitalTemplates = this.record.hospital_ids.map((v) => {
          return {
            hospital_id: v,
            hospital_state: this.hospitals.find(
              (h) => h.id == v
            ).state,
          };
        })
        // this.record.hospitalTemplates.forEach((hospital) => {
        //   employee.hospital_state = this.hospitals.find(
        //     (v) => v.id == employee.hospital_id
        //   ).state;
        // });
        this.updateHospitalList();
      });
    },

    unSelectedHospitals(value) {
      return this.hospitals.filter((v) => {
        if (v.id == value.hospital_id || v.selected != true) {
          return v;
        }
      });
    },

    fetchEmployeeHospitals() {
      return commonService
      .get(this.$constants.employeeHospitalShowUrl, {
        id: this.$route.params.id,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        this.$message.error(err || "Something went wrong.");
        this.$router.push({ name: "employee" });
        return Promise.reject();
      });
    },

    addField() {
      this.formState.hospitalTemplates.push(Object.assign({}, this.row));
    },

    handleHospitalSelect(i) {
      var row = this.formState.hospitalTemplates[i];
      row.hospital_state = this.hospitals.find(
        (v) => v.id == row.hospital_id
      ).state;
      this.updateHospitalList();
    },

    updateHospitalList() {
      let hospitalIds = [];
      this.formState.hospitalTemplates.forEach((v) => {
        if (v.hospital_id != null) {
          hospitalIds.push(v.hospital_id);
        }
      });
      this.hospitals.map((v) => {
        v.selected = hospitalIds.includes(v.id);
      });
    },

    handleFieldRemove(i) {
      this.formState.hospitalTemplates.splice(i, 1);
    },

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        hospitalTemplates: this.record.hospitalTemplates ?? [{}],
      };
    },

    handleFinish() {
      const data = {
        id: this.record.id ?? 0,
        name: this.formState.name,
        hospital_ids: this.formState.hospitalTemplates.map((v) => v.hospital_id),
      };

      console.log({data});

      this.loadingStart();
      commonService
        .store(this.$constants.hospitalTemplatesStoreUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
