<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="job" label="Job" name="job">
            <a-select
              v-model:value="formState.job"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="job in jobTitles"
                :key="job.label"
                :label="job.label"
                :value="job.label"
              >
                {{ job.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      formState: {
        name: null,
        job: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
          },
        ],
        job: [
          {
            required: true,
            message: "job field is required!",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },

    jobTitles() {
      return this.extra.job_titles ?? {};
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        job:
          (this.record.adp_job_title ?? "N/A") +
          " | " +
          (this.record.adp_job_code ?? "N/A"),
      };

      this.formState.job =
        this.formState.job == "N/A | N/A" ? undefined : this.formState.job;
    },

    onSubmit() {
      let job = this.formState.job.split(" | ");
      let val = {
        name: this.formState.name,
        adp_job_title: job[0],
        adp_job_code: job[1],
      };

      if (this.record.id) {
        val.id = this.record.id;
      }

      this.loadingStart();
      companyService
        .store(this.$constants.employeePositionStoreUrl, val)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          this.$refs.role_form.fields.forEach((v, i) => {
            if (err.errors[v.fieldName]) {
              v.validateState = "error";
              v.validateMessage = err.errors[v.fieldName]["0"];
            }
          });
        });
    },
  },
};
</script>
