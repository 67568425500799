<template>
  <div>
    <a-form
      name="form"
      ref="form"
      :model="form"
      :rules="rules"
      layout="vertical"
      @finish="hanldFinish()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="role_id" label="Role" name="role_id">
            <a-select
              v-model:value="form.role_id"
              placeholder="select employee role"
              optionFilterProp="label"
              @change="change_role"
            >
              <a-select-option
                v-for="role in extra.roles"
                :key="role.id"
                :label="role.name"
                :value="role.id"
                :selected_routes="role.associated_rights"
              >
                {{ role.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-skeleton :loading="skeleton_loading" active>
            <a-checkbox-group
              :default-value="checked_routes"
              :value="checked_routes"
              @change="on_change"
              style="width: 100%"
            >
              <h3>
                System Access
                <small class="danger-color text-weight-light">
                  (Click "<i class="fas fa-check"></i>" to select all child
                  roles)
                </small>
              </h3>
              <div
                class="ant-table ant-table-scroll-position-left ant-table-small"
              >
                <div class="ant-table-content">
                  <div class="ant-table-body">
                    <table>
                      <thead class="ant-table-thead">
                        <tr>
                          <th class="width-500">Module Name</th>
                          <th style="text-align: center !important">Create</th>
                          <th style="text-align: center !important">View</th>
                          <th style="text-align: center !important">Update</th>
                          <th style="text-align: center !important">Delete</th>
                        </tr>
                      </thead>
                      <tbody class="ant-table-tbody">
                        <template v-if="extra.routes">
                          <child-routes
                            :routes="extra.routes"
                            :parent_id="0"
                            :depth="0"
                            :default_selected_routes="default_selected_routes"
                            @big_checkbox_checked="big_checkbox_checked"
                          />
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </a-checkbox-group>
          </a-skeleton>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import ChildRoutes from "@/components/childRoutes";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
    ChildRoutes,
  },
  data() {
    return {
      form: {},
      rules: {},
      checked_routes: [],
      skeleton_loading: false,
      default_selected_routes: [],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  created() {
    this.getform();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getform() {
      this.form = {
        role_id:
          this.record.user && this.record.user.role_id
            ? this.record.user.role_id
            : null,
      };

      let rights = null;
      this.extra.roles.find((v) => {
        if (v.id == this.form.role_id) {
          rights = v.associated_rights;
        }
      });
      this.rights_cal(rights);
    },

    on_change(checked_values) {
      this.checked_routes = checked_values;
    },

    change_role(e, e1) {
      const rights = e1.selected_routes;
      this.rights_cal(rights);
    },

    rights_cal(rights) {
      let extra_rights = this.record.user
        ? this.record.user.extra_rights
        : null;

      if (rights != null) {
        this.default_selected_routes = Array.from(rights.split(","), Number);
        if (extra_rights != null) {
          extra_rights = Array.from(extra_rights.split(","), Number);
          this.checked_routes = this.default_selected_routes.concat(
            extra_rights
          );
          this.checked_routes = this.checked_routes.filter(
            (value, index, self) => {
              return self.indexOf(value) === index;
            }
          );
        } else {
          this.checked_routes = this.default_selected_routes;
        }
      } else {
        this.default_selected_routes = [];
        if (extra_rights != null) {
          extra_rights = Array.from(extra_rights.split(","), Number);
          this.checked_routes = extra_rights;
        } else {
          this.checked_routes = this.default_selected_routes;
        }
      }
    },

    big_checkbox_checked(ids, type) {
      if (type == "checked") {
        ids.forEach((v) => {
          var index = this.checked_routes.indexOf(v);
          if (index == -1) {
            this.checked_routes.push(v);
          }
        });
      } else {
        ids.forEach((v) => {
          var index = this.checked_routes.indexOf(v);
          if (index != -1) {
            this.checked_routes.splice(index, 1);
          }
        });
      }
    },

    hanldFinish() {
      this.$refs.form
        .validate()
        .then((val) => {
          if (this.record.id) {
            val.id = this.record.id;
          }
          this.extra.roles.find((v) => {
            if (v.id == val.role_id) {
              let associated_rights = v.associated_rights;
              if (associated_rights == null) {
                associated_rights = [];
              } else {
                associated_rights = Array.from(
                  associated_rights.split(","),
                  Number
                );
              }
              associated_rights.forEach((element) => {
                const index = this.checked_routes.indexOf(element);
                if (index > -1) {
                  this.checked_routes.splice(index, 1);
                }
              });
            }
          });
          val.rights = this.checked_routes;
          console.log(val);
          this.loadingStart();
          commonService
            .store(this.$constants.updateRoleAndRightsUrl, val)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              this.loadingStop();
              this.$message.success(err);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
  },
};
</script>

<style></style>
