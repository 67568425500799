import { route } from "@/helper/route";
import { store } from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import adminReporting from "./adminReporting";
import asset from "./asset";
import biomedServiceRecord from "./biomedServiceRecord";
import calendarEvents from "./calendarEvents";
import caseReporting from "./caseReporting";
import cellSaverQC from "./cellSaverQC";
import crmRoutes from "./crm";
import crmSettingRoutes from "./crm/setting";
import emailTemplates from "./emailTemplates";
import employeeTimeTrack from "./employeeTimeTrack";
import equipmentMaintenance from "./equipmentMaintenance";
import expenses from "./expenses";
import finances from "./finances";
import handbook from "./handbook";
import hosptialUserRoute from "./hosptialUserRoute";
import humanResourceRoute from "./humanResourceRoute";
import incident from "./incident";
import knowledgeBaseRoutes from "./knowledgeBaseRoutes";
import logs from "./logs";
import managerRoutes from "./managerRoutes";
import accessible from "./middleware/accessible";
import auth from "./middleware/auth";
import guest from "./middleware/guest";
import hasRoles from "./middleware/hasRoles";
import profile from "./middleware/profile";
import middlewarePipeline from "./middlewarePipeline";
import orders from "./orders";
import paidTimeOff from "./paidTimeOff";
import performanceEvaluation from "./performanceEvaluation";
import policyAndProcedureManual from "./policyAndProcedureManual";
import report from "./report";
import service from "./service";
import userRoutes from "./userRoutes";

const routes = [
  {
    path: "/login",
    name: "login",
    component: route("auth/login"),
    meta: {
      title: "Login",
      layout: "auth",
      middleware: [guest],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: route("auth/forgot_password"),
    meta: {
      title: "Forgot Password",
      layout: "auth",
      middleware: [guest],
    },
  },
  {
    path: "/reset-password/:token",
    name: "reset_password",
    component: route("auth/reset_password"),
    meta: {
      title: "Reset Password",
      layout: "auth",
      middleware: [guest],
    },
  },
  {
    path: "/offer-letter/:token",
    name: "offer_letter",
    component: route("auth/offer_letter"),
    meta: {
      title: "Offer Letter",
      layout: "default",
      middleware: [guest],
    },
  },
  {
    path: "/",
    name: "home",
    component: route("dashboard"),
    meta: {
      title: "Dashboard",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/virtual-hr-assistant",
    name: "virtual_hr_assistant",
    component: route("chatgpt"),
    meta: {
      title: "Virtual HR Assistant",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: route("newUserOnboarding/index.vue"),
    meta: {
      title: "Get Started",
      layout: "app",
      middleware: [auth],
    },
  },
  {
    path: "/expired-credentials",
    name: "credentials-update",
    component: route("credentialsActivate/index.vue"),
    meta: {
      title: "Expired Credentials",
      layout: "app",
      middleware: [auth],
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: route("notifications.vue"),
    meta: {
      title: "Notifications",
      layout: "app",
      middleware: [auth],
    },
  },
  {
    path: "/action-items",
    name: "action-items",
    component: route("actionItems/index.vue"),
    meta: {
      title: "Action Items",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/companies",
    name: "company",
    component: route("company"),
    meta: {
      title: "Companies",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/hospitals",
    name: "hospital",
    component: route("hospital"),
    meta: {
      title: "Hospitals",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/case-services",
    name: "case-services",
    component: route("caseService/index"),
    meta: {
      title: "Case Services",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/employee-benefits",
    name: "employee-benefits-show",
    component: route("employeeBenefits/show"),
    meta: {
      title: "Employee Benefits",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/settings/employee-benefits",
    name: "employee-benefits",
    component: route("employeeBenefits/index"),
    meta: {
      title: "Employee Benefits",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/hospital/:id",
    name: "hospital-details",
    component: route("hospital/show"),
    meta: {
      title: "Hospital Details",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/clinical-site-information",
    name: "clinical-site-information",
    component: route("hospital/clinicalSiteInfo"),
    meta: {
      title: "Clinical Site Information",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/certification-types",
    name: "certificationType",
    component: route("certificationType/index"),
    meta: {
      title: "Certification Types",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/expense-types",
    name: "expenseType",
    component: route("expenseType/index"),
    meta: {
      title: "Expense Types",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/hospital-templates",
    name: "hospitalTemplates",
    component: route("hospitalTemplates/index"),
    meta: {
      title: "Hospital Templates",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/shift-types",
    name: "shiftType",
    component: route("shiftType/index"),
    meta: {
      title: "Shift Types",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/facilities",
    name: "facilities",
    component: route("facility"),
    meta: {
      title: "Facilities",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: [2, 3, 4],
    },
  },
  {
    path: "/facility/show/:id",
    name: "show-facility",
    component: route("facility/show"),
    meta: {
      title: "Show Facility",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: [2, 3, 4],
    },
  },
  {
    path: "/employee/onborading-person/:onboardingPersonId",
    name: "onborading-person",
    component: route("employee/create"),
    meta: {
      title: "On Boarding Person",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: route("profile"),
    meta: {
      title: "Profile",
      layout: "app",
      middleware: [auth],
    },
  },
  {
    path: "/manufacturers",
    name: "manufacturer",
    component: route("manufacturer"),
    meta: {
      title: "Manufacturers",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/products",
    name: "product",
    component: route("product"),
    meta: {
      title: "Products",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/product-categories",
    name: "product-category",
    component: route("product_category"),
    meta: {
      title: "Product Categories",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/unit-of-measurements",
    name: "uom",
    component: route("uom"),
    meta: {
      title: "Unit Of Measurements",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/live-charting",
    name: "live-charting",
    component: route("liveCharting"),
    meta: {
      title: "Live Charting",
      layout: "app",
      middleware: [auth, profile, accessible],
    },
  },
  {
    path: "/schedules",
    name: "schedules",
    component: route("schedule"),
    meta: {
      title: "Schedule",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  ...orders,
  ...finances,
  ...emailTemplates,
  ...calendarEvents,
  ...expenses,
  ...employeeTimeTrack,
  ...service,
  ...paidTimeOff,
  ...handbook,
  ...incident,
  ...caseReporting,
  ...cellSaverQC,
  ...equipmentMaintenance,
  ...biomedServiceRecord,
  ...policyAndProcedureManual,
  ...hosptialUserRoute,
  ...adminReporting,
  ...report,
  ...performanceEvaluation,
  ...asset,
  ...logs,
  ...managerRoutes,
  ...knowledgeBaseRoutes,
  ...userRoutes,

  // CRM
  ...crmSettingRoutes,
  ...crmRoutes,

  // HR
  ...humanResourceRoute,

  {
    path: "/employee-assessment/:token",
    name: "assessments",
    meta: {
      title: "Competency Assessment",
      layout: "default",
      middleware: [guest],
    },
    component: route("assessment"),
  },
  {
    path: "/no-access",
    name: "no-access",
    meta: {
      title: "No Access",
      layout: "app",
      middleware: [auth],
    },
    component: route("noAccess"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    meta: {
      title: "Page Not found",
      layout: "guest",
    },
    component: route("pageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // const isLoggedIn = store.getters["auth/isLoggedIn"];

  // if (isLoggedIn) {
  // const isIdleLocked = store.getters["auth/isIdleLocked"];
  // if (isIdleLocked) return next(false);
  // }

  document.title = "KeyPort | " + to.meta.title;

  await Promise.all([
    store.dispatch("drawer/close"),
    store.dispatch("drawer/loadingStop"),
    store.dispatch("modal/close"),
    store.dispatch("modal/loadingStop"),
  ]);

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const roles = Array.isArray(to.meta.roles)
      ? to.meta.roles
      : [to.meta.roles];
    const extra_access = to.meta.extra_access;
    const context = { to, from, next, store, roles, extra_access };
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  } else {
    return next();
  }
});

export default router;
