<template>
  <a-card
    title="Hospital Annual Escalator Due/Expired"
    size="small"
    :bodyStyle="{ height: '300px', overflowY: 'scroll' }"
  >
    <a-table
      :columns="columns"
      :rowKey="(record) => record.hospital_id"
      :dataSource="data"
      :pagination="paginationSettings"
      size="small"
      :scroll="{ x: true, y: 0 }"
    >
      <template #hospital_name="{ record }">
        <router-link
          :to="{
            name: 'hospital-details',
            params: { id: record.hospital_id },
          }"
        >
          {{ record.hospital?.name ?? "N/A" }}
        </router-link>
      </template>

      <template #attachment="{ record }">
        <template
          v-for="(attachment, index) in record.attachments"
          :key="attachment.id"
        >
          <a
            href="#"
            @click.prevent="
              $comman.downloadItem({
                url: attachment.full_file_path,
                name: attachment.file_name,
              })
            "
          >
            {{ index + 1 }}. Attachment
          </a>
          <br />
        </template>
      </template>
      <template #contract_increase_date="{ record }">
        {{ $customDate.ll(record.contract_increase_date) }}
        <a-tag
          color="red"
          style="margin-left: 5px"
          v-if="moment().format('YYYY-MM-DD') >= record.contract_increase_date"
        >
          Expired
        </a-tag>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    data: {
      default: [],
      type: Object,
    },
    paginationSettings: {
      type: Object,
    },
  },

  data() {
    return {
      moment,
      columns: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          slots: {
            customRender: "hospital_name",
          },
        },
        {
          title: "Attachment",
          dataIndex: "attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Annual Escalation Date",
          dataIndex: "contract_increase_date",
          slots: {
            customRender: "contract_increase_date",
          },
        },
      ],
    };
  },
};
</script>
