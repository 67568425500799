<template>
  <div>
    <a-descriptions
      size="small"
      bordered
      :labelStyle="{ color: '#666666', width: '150px' }"
      :column="1"
      class="mb-sm-1"
      ><!--:labelStyle="{ fontWeight: 500, color:'#666666' }"-->
      <a-descriptions-item label="Date:">
        {{ record.date ? $customDate.mdy(record.date) : "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Facility:">
        {{ record?.hospital?.name }}
      </a-descriptions-item>
      <a-descriptions-item label="Report No:">
        {{ record?.report_no }}
      </a-descriptions-item>
      <a-descriptions-item label="Created By:">
        {{ record?.employee?.full_name }}
      </a-descriptions-item>
    </a-descriptions>

    <a-form :model="model" layout="vertical" @finish="onSubmit">
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item
            ref="comment"
            label="Comment"
            name="comment"
            :rules="[{ required: true, message: 'This filed required' }]"
          >
            <a-textarea
              v-model:value="model.comment"
              placeholder="Enter here..."
              :rows="5"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons name="Submit" />
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "@/services";

export default {
  data() {
    return {
      model: {
        comment: "",
      },
      comment: null,
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();

      commonService
        .store(this.$constants.caseReportChangeStatusUrl, {
          id: this.record.id,
          comment: this.model.comment,
          status: 1,
        })
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },

  mounted() {
    //
  },
};
</script>
