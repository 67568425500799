<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12">
      <a-form-item
        label="Washed RBCs Reinfused"
        name="washed_rbcs_reinfused"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.washed_rbcs_reinfused"
          placeholder="mLs"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Type of Anticoagulant Used"
        name="type_of_anticoagulant_used"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.type_of_anticoagulant_used"
          placeholder="Select anticoagulant used type"
          show-search
        >
          <a-select-option
            v-for="anticoagulantType in $constants.caseServiceAnticoagulantTypes"
            :key="anticoagulantType"
            :value="anticoagulantType"
          >
            {{ anticoagulantType }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Total Processed Volume"
        name="total_processed_volumn"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.total_processed_volumn"
          placeholder="mLs"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Anticoagulation Volume Used"
        name="anticoagulant_volumn_used"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.anticoagulant_volumn_used"
          placeholder="mLs"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Wash Volume Used"
        name="wash_volumn_used"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.wash_volumn_used"
          placeholder="mLs"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        name="estimated_blood_loss"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <template #label>
          Estimated Blood Loss (EBL)
          <a-popover overlayClassName="overlay-popover-class">
            <template #content>
              Formula: ((Bowl volume/2) / avg hct expressed as a fraction)) X #
              of Bowls returned to the Pt.
            </template>
            <a-button type="link" size="small">
              <i class="fa fa-info-circle"></i>
            </a-button>
          </a-popover>
        </template>
        <a-input-number
          type="number"
          v-model:value="model.estimated_blood_loss"
          placeholder="Enter estimated blood loss here"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Clear Effluent Wash Line"
        name="clear_effluent_wash_line"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.clear_effluent_wash_line"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Autologous Blood Transfused within 4 Hours"
        name="autologous_blood_transfused_within_4_hours"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.autologous_blood_transfused_within_4_hours"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Cell Saver Non-Invasive HCT (LivaNova Xtra or Fresenius CATS Devices Only)"
        name="cell_saver_hct"
        :rules="{
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.cell_saver_hct"
          placeholder="Enter cell saver non-invasive hct here"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Was a QC sample obtained for this procedure?"
        name="was_a_qc_sample_obtained_for_this_procedure"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.was_a_qc_sample_obtained_for_this_procedure"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Air Removed from Bag (Intraop)"
        name="air_removed_from_bag"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.air_removed_from_bag"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Maximum Vacuum <= 150 (Intraop)"
        name="maximum_vaccum_less_than_150"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.maximum_vaccum_less_than_150"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
            :key="yesNoNotApplicable.value"
            :label="yesNoNotApplicable.text"
            :value="yesNoNotApplicable.value"
          >
            {{ yesNoNotApplicable.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
  </a-row>

  <template v-if="model.was_a_qc_sample_obtained_for_this_procedure == 'Yes'">
    <a-space direction="vertical" style="width: 100%">
      <a-button type="primary" @click="handleRowAddEvent">
        <a-space>
          <i class="ti ti-plus ti-lg"></i>
          Add Source
        </a-space>
      </a-button>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        :dataSource="model.sources"
        :pagination="false"
        size="small"
        class="ant-table-vertical-align-baseline"
        :scroll="{ x: true, y: 0 }"
      >
        <template #source="{ record, index }">
          <a-form-item
            :name="['sources', index, 'source']"
            :rules="{
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <a-input
              v-model:value="record.source"
              placeholder="Enter source here..."
            />
          </a-form-item>
        </template>

        <template #parameter="{ record, index }">
          <a-form-item
            :name="['sources', index, 'parameter']"
            :rules="{
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-select
              v-model:value="record.parameter"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="option in $constants.cellSaverSourcesParameters"
                :key="option"
                :label="option"
                :value="option"
              >
                {{ option }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>

        <template #result="{ record, index }">
          <a-form-item
            :name="['sources', index, 'result']"
            :rules="[
              {
                required: true,
                message: 'This field is required!',
              },
              {
                message: 'This field is required!',
                type: 'number',
                validator: $validation.decimal,
                trigger: $constants.ruleTrigger,
              },
            ]"
          >
            <a-input-number
              type="number"
              v-model:value="record.result"
              placeholder="Enter result here..."
            />
          </a-form-item>
        </template>

        <template #action="{ index }">
          <a-button
            size="small"
            type="link"
            danger
            @click="handleRowDeleteEvent(index)"
          >
            <i class="ti ti-trash ti-lg"></i>
          </a-button>

          <a-button
            v-if="model.sources.length == index + 1"
            size="small"
            type="link"
            @click="handleRowAddEvent"
          >
            <i class="ti ti-plus ti-lg"></i>
          </a-button>
        </template>
      </a-table>
    </a-space>
  </template>
</template>

<script>
export default {
  name: "case-report-step-5",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      columns: [
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
          slots: { customRender: "source" },
        },
        {
          title: "Parameter",
          dataIndex: "parameter",
          key: "parameter",
          slots: { customRender: "parameter" },
        },
        {
          title: "Result",
          dataIndex: "result",
          key: "result",
          slots: { customRender: "result" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 150,
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
    };
  },

  computed: {
    isRequired() {
      return [1, 2, 3, 7, 14, 21].reduce(
        (bool, curr) => bool || this.model.services.includes(curr),
        false
      );
    },
  },

  mounted() {
    //
  },

  methods: {
    handleRowDeleteEvent(index) {
      this.model.sources = this.model.sources.filter((_, i) => index != i);
    },

    handleRowAddEvent() {
      this.model.sources.push({
        source: null,
        parameter: undefined,
        result: null,
      });
    },
  },
};
</script>
