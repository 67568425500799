import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hospitalUser from "./middleware/hosptialUser";

export default [
  {
    path: "/hospital-user/dashboard",
    name: "hospital-user-dashboard",
    component: route("hospitalUser/dashboard"),
    meta: {
      layout: "hospital-user",
      title: "Dashboard",
      middleware: [auth, hospitalUser],
    },
  },

  {
    path: "/hospital-user/change-password",
    name: "hospital-user-change-password",
    component: route("hospitalUser/changePassword"),
    meta: {
      layout: "hospital-user",
      title: "Change Password",
      middleware: [auth, hospitalUser],
    },
  },

  {
    path: "/hospital-user/appointments",
    name: "hospital-user-appointments",
    component: route("hospitalUser/appointment/index"),
    meta: {
      layout: "hospital-user",
      title: "Appointments",
      middleware: [auth, hospitalUser],
    },
  },

  {
    path: "/hospital-user/appointment/create",
    name: "hospital-user-appointment-create",
    component: route("hospitalUser/appointment/create"),
    meta: {
      layout: "hospital-user",
      title: "Create Appointment",
      middleware: [auth, hospitalUser],
    },
  },

  {
    path: "/hospital-user/appointment/edit/:id",
    name: "hospital-user-appointment-edit",
    component: route("hospitalUser/appointment/edit"),
    meta: {
      layout: "hospital-user",
      title: "Edit Appointment",
      middleware: [auth, hospitalUser],
    },
  },

  {
    path: "/hospital-user/appointment/show/:id",
    name: "hospital-user-appointment-show",
    component: route("hospitalUser/appointment/show"),
    meta: {
      layout: "hospital-user",
      title: "Show Appointment",
      middleware: [auth, hospitalUser],
    },
  },
];
