<template>
  <a-layout-content>
    <page-header>
      <template #title> Leads </template>

      <template #buttons>
        <a-button
          v-if="$comman.hasRole(1)"
          type="primary"
          @click="createRecord"
        >
          <a-space> <i class="ti ti-plus ti-lg"></i> Add </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{ name: 'crm.show.lead', params: { id: record.id } }"
            >
            <a-tooltip title="View">
              <a-button type="link" size="small" class="primary-color">
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
            </router-link>

            <a-tooltip title="Edit">
            <a-button
              v-if="$comman.hasRole(1)"
              type="link"
              size="small"
              @click="editRecord(record)"
            >
              <i class="ti ti-pencil ti-lg"></i>
            </a-button>
          </a-tooltip>

            <!-- <a-popconfirm
            title="Are you sure you want to remove this lead?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleStatusChangeEvent({ id: record.id })"
          >
            <a-button size="small" type="link" danger>
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-popconfirm> -->
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.crmLeadDatatableUrl,
      statusChangeUrl: this.$constants.crmLeadChangeStatusUrl,
      columns: [
        {
          title: "Company Name",
          dataIndex: "company_name",
          key: "company_name",
          sorter: true,
          defaultFilteredValue: this.$route.query?.company_name
            ? [this.$route.query?.company_name]
            : undefined,
          defaultSortOrder:
            this.$route.query?.sortField == "company_name"
              ? this.$route.query.sortOrder
              : undefined,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Name",
          dataIndex: "first_name",
          key: "first_name",
          sorter: true,
          customRender: ({ record }) =>
            `${record.first_name} ${record.last_name}`,
          defaultFilteredValue: this.$route.query?.first_name
            ? [this.$route.query?.first_name]
            : undefined,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          sorter: true,
          defaultFilteredValue: this.$route.query?.email
            ? [this.$route.query?.email]
            : undefined,
          defaultSortOrder:
            this.$route.query?.sortField == "email"
              ? this.$route.query.sortOrder
              : undefined,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Stage",
          dataIndex: "stages_of_lead_id",
          key: "stages_of_lead_id",
          customRender: ({ record }) => record.stages_of_lead.name,
          defaultFilteredValue: this.$route.query?.stages_of_lead_id
            ? Array.isArray(this.$route.query?.stages_of_lead_id)
              ? this.$route.query?.stages_of_lead_id.map((e) => parseInt(e))
              : this.$route.query?.stages_of_lead_id
                  .split(",")
                  .map((e) => parseInt(e))
            : undefined,
          filters: [],
        },
        {
          title: "Probability",
          dataIndex: "probability",
          key: "probability",
          customRender: ({ record }) => `${record.stages_of_lead.percentage}%`,
        },
        {
          title: "Expected Close Date",
          dataIndex: "expected_close_date",
          key: "expected_close_date",
          customRender: ({ record }) =>
            this.$customDate.mdy(record.expected_close_date),
          sorter: true,
          defaultSortOrder: this.$route.query?.sortField
            ? this.$route.query?.sortField == "expected_close_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "Last Interacted Date",
          dataIndex: "last_interacted_date",
          key: "last_interacted_date",
          customRender: ({ record }) =>
            this.$customDate.mdy(record.last_interacted_date),
          sorter: true,
          defaultSortOrder:
            this.$route.query?.sortField == "last_interacted_date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Sales Person",
          dataIndex: "sales_person_name",
          key: "sales_person_name",
          customRender: ({ record }) => record.sales_person?.full_name ?? "N/A",
          defaultFilteredValue: this.$route.query?.sales_person_name
            ? [this.$route.query?.sales_person_name]
            : undefined,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        // {
        //   title: "Contact No",
        //   dataIndex: "contact_no",
        //   key: "contact_no",
        //   sorter: true,
        //   defaultFilteredValue: this.$route.query?.contact_no
        //     ? [this.$route.query?.contact_no]
        //     : undefined,
        //   defaultSortOrder:
        //     this.$route.query?.sortField == "contact_no"
        //       ? this.$route.query.sortOrder
        //       : undefined,
        //   slots: {
        //     filterDropdown: "filterDropdown",
        //     filterIcon: "filterIcon",
        //   },
        // },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],

      stagesOfLead: [],

      defaultFilterParams: {
        company_name: this.$route.query?.company_name
          ? [this.$route.query?.company_name]
          : undefined,
        first_name: this.$route.query?.first_name
          ? [this.$route.query?.first_name]
          : undefined,
        email: this.$route.query?.email
          ? [this.$route.query?.email]
          : undefined,
        stages_of_lead_id: this.$route.query?.stages_of_lead_id
          ? Array.isArray(this.$route.query?.stages_of_lead_id)
            ? this.$route.query?.stages_of_lead_id.map((e) => parseInt(e))
            : this.$route.query?.stages_of_lead_id
                .split(",")
                .map((e) => parseInt(e))
          : undefined,
      },
    };
  },

  mounted() {
    this.fetchStagesOfLead();

    if (!this.isSalesPerson) {
      this.$router.go(-1);
    }
  },

  computed: {
    ...mapGetters("auth", ["isSalesPerson"]),
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    createRecord() {
      this.open({
        title: "Add New Lead",
        path: "crm.lead.store",
        callback: this.refresh,
      });
    },

    editRecord(record) {
      this.open({
        title: "Edit Lead",
        path: "crm.lead.store",
        callback: this.refresh,
        record: record,
      });
    },

    fetchStagesOfLead() {
      commonService.get(this.$constants.crmGetStagesOfLeadUrl).then((res) => {
        this.stagesOfLead = res.data;

        this.columns.map((e) => {
          if (e.title == "Stage") {
            e.filters = this.stagesOfLead.map((e) => ({
              ...e,
              text: e.name,
              value: e.id,
            }));
          }
          return e;
        });
      });
    },
  },
};
</script>
