<template>
  <a-layout-content>
    <page-header>
      <template #prepend>
        <img
          alt="keyport"
          class="img-fluid"
          src="@/assets/keyport-header-logo.svg"
        />
      </template>
      <template #title> Competency Assessment </template>
    </page-header>

    <a-card :loading="loading" size="small" class="mb-sm-2">
      <a-result
        v-if="isCompleted"
        status="success"
        title="Assessment has been completed. Thank you!"
      />

      <a-descriptions
        :bordered="true"
        :title="false"
        size="small"
        :column="{ md: 2, sm: 1, xs: 1 }"
      >
        <a-descriptions-item
          v-for="item in employeeDescriptions"
          :key="item.label"
          :label="item.label"
        >
          {{ item.value }}
        </a-descriptions-item>
      </a-descriptions>

      <a-form
        v-if="!isCompleted"
        :model="formState"
        :rules="rules"
        layout="vertical"
        @finish="onSubmit"
      >
        <a-row :gutter="[16, 0]">
          <a-col :sm="24" class="mt-sm-1">
            <a-typography-title :level="4">
              Clinical Competence
            </a-typography-title>
            <a-form-item
              label="Is the perfusionist clinically competent in your assessment?"
              name="clinical_competence"
            >
              <a-radio-group
                v-model:value="formState.clinical_competence"
                :disabled="submitting"
              >
                <a-radio-button value="Yes">Yes</a-radio-button>
                <a-radio-button value="No">No</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <a-form-item
              label="Comments or Observations Regarding Clinical Competence"
              name="clinical_competence_comments"
            >
              <a-textarea
                v-model:value="formState.clinical_competence_comments"
                :rows="4"
                :disabled="submitting"
                placeholder="Comments or Observations Regarding Clinical Competence"
              />
            </a-form-item>
          </a-col>

          <a-divider />

          <a-col :sm="24">
            <a-typography-title :level="4">
              Adherence to Protocols
            </a-typography-title>
            <a-form-item
              label="Does the perfusionist consistently adhere to standard protocols and guidelines during procedures?"
              name="adherence_to_protocols"
            >
              <a-radio-group
                :disabled="submitting"
                v-model:value="formState.adherence_to_protocols"
              >
                <a-radio-button value="Yes">Yes</a-radio-button>
                <a-radio-button value="No">No</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <a-form-item
              label="Comments or Observations Regarding Adherence to Protocols"
              name="adherence_to_protocols_comments"
            >
              <a-textarea
                v-model:value="formState.adherence_to_protocols_comments"
                :rows="4"
                :disabled="submitting"
                placeholder="Comments or Observations Regarding Adherence to Protocols"
              />
            </a-form-item>
          </a-col>

          <a-divider />

          <a-col :sm="24">
            <a-typography-title :level="4">
              Communication and Teamwork
            </a-typography-title>
            <a-form-item
              label="Does the perfusionist communicate effectively and work well within the surgical team and hospital staff?"
              name="communication_and_teamwork"
            >
              <a-radio-group
                v-model:value="formState.communication_and_teamwork"
                :disabled="submitting"
              >
                <a-radio-button value="Yes">Yes</a-radio-button>
                <a-radio-button value="No">No</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <a-form-item
              label="Comments or Observations Regarding Communication and Teamwork"
              name="communication_and_teamwork_comments"
            >
              <a-textarea
                v-model:value="formState.communication_and_teamwork_comments"
                :rows="4"
                :disabled="submitting"
                placeholder="Comments or Observations Regarding Communication and Teamwork"
              />
            </a-form-item>
          </a-col>

          <a-divider />

          <a-col :sm="24">
            <a-typography-title :level="4">
              Problem Solving and Decision-Making
            </a-typography-title>
            <a-form-item
              label="Has the perfusionist demonstrated effective problem-solving skills and quick decision-making during procedures?"
              name="problem_solving_and_decision_making"
            >
              <a-radio-group
                :disabled="submitting"
                v-model:value="formState.problem_solving_and_decision_making"
              >
                <a-radio-button value="Yes">Yes</a-radio-button>
                <a-radio-button value="No">No</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <a-form-item
              label="Comments or Observations Regarding Problem Solving and Decision-Making"
              name="problem_solving_and_decision_making_comments"
            >
              <a-textarea
                v-model:value="
                  formState.problem_solving_and_decision_making_comments
                "
                :disabled="submitting"
                :rows="4"
                placeholder="Comments or Observations Regarding Problem Solving and Decision-Making"
              />
            </a-form-item>
          </a-col>

          <a-divider />

          <a-col :sm="24">
            <a-typography-title :level="4">
              Continuous Improvement
            </a-typography-title>
            <a-form-item
              label="Does the perfusionist actively seek to improve their skills and stay updated with best practices in cardiovascular perfusion?"
              name="continuous_improvement"
            >
              <a-radio-group
                :disabled="submitting"
                v-model:value="formState.continuous_improvement"
              >
                <a-radio-button value="Yes">Yes</a-radio-button>
                <a-radio-button value="No">No</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <a-form-item
              label="Comments or Observations Regarding Continuous Improvement"
              name="continuous_improvement_comments"
            >
              <a-textarea
                v-model:value="formState.continuous_improvement_comments"
                :rows="4"
                :disabled="submitting"
                placeholder="Comments or Observations Regarding Continuous Improvement"
              />
            </a-form-item>
          </a-col>

          <a-divider />

          <a-col :sm="24">
            <a-typography-title :level="4">
              Overall Assessment
            </a-typography-title>
            <a-form-item
              label="Based on your experience working with this perfusionist, would you welcome them to return to your facility for future assignments?"
              name="overall_assessment"
            >
              <a-radio-group
                :disabled="submitting"
                v-model:value="formState.overall_assessment"
              >
                <a-radio-button value="Yes">Yes</a-radio-button>
                <a-radio-button value="No">No</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <a-form-item
              label="Comments or Observations Regarding Overall Assessment"
              name="overall_assessment_comments"
            >
              <a-textarea
                v-model:value="formState.overall_assessment_comments"
                :rows="4"
                :disabled="submitting"
                placeholder="Comments or Observations Regarding Overall Assessment"
              />
            </a-form-item>
          </a-col>

          <a-col :sm="24">
            <signature-box class="mx-sm-1" v-model:sign="formState.sign">
              <template #buttons>
                <a-button
                  type="primary"
                  html-type="submit"
                  :loading="submitting"
                >
                  Submit
                </a-button>
              </template>
            </signature-box>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import signatureBox from "@/components/signatureBox.vue";
import { commonService } from "@/services";

export default {
  components: { signatureBox },
  data() {
    return {
      loading: true,
      userInfo: {},
      submitting: false,
      formState: {
        sign: null,
        email: null,
        token: null,
      },
      isCompleted: false,
      isWrong: false,
      rules: {
        clinical_competence: [
          {
            required: true,
            message: "Please select a choice",
            trigger: ["change", "blur"],
          },
        ],
        adherence_to_protocols: [
          {
            required: true,
            message: "Please select a choice",
            trigger: ["change", "blur"],
          },
        ],
        communication_and_teamwork: [
          {
            required: true,
            message: "Please select a choice",
            trigger: ["change", "blur"],
          },
        ],
        problem_solving_and_decision_making: [
          {
            required: true,
            message: "Please select a choice",
            trigger: ["change", "blur"],
          },
        ],
        continuous_improvement: [
          {
            required: true,
            message: "Please select a choice",
            trigger: ["change", "blur"],
          },
        ],
        overall_assessment: [
          {
            required: true,
            message: "Please select a choice",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      return commonService
        .get(this.$constants.employeeCompetencyAssessmentShow, {
          token: this.$route.params.token,
          email: this.$route.query.email,
        })
        .then((res) => {
          this.userInfo = res.data;
          if (res.data.status === 2) {
            this.isCompleted = true;
            return;
          }

          this.formState.email = this.$route.query.email;
          this.formState.token = this.$route.params.token;
        })
        .catch((err) => {
          // this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "page-not-found" });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSubmit() {
      if (this.formState.sign === null) {
        this.$message.error("Candidate signature is required for submission.");
        return;
      }

      this.submitting = true;

      const data = {
        clinical_competency: {
          response: this.formState.clinical_competence,
          comment: this.formState.clinical_competence_comments,
        },
        adherence_to_protocols: {
          response: this.formState.adherence_to_protocols,
          comment: this.formState.adherence_to_protocols_comments,
        },
        communication_and_teamwork: {
          response: this.formState.communication_and_teamwork,
          comment: this.formState.communication_and_teamwork_comments,
        },
        problem_solving_and_decision_making: {
          response: this.formState.problem_solving_and_decision_making
            ? "Yes"
            : "No",
          comment: this.formState.problem_solving_and_decision_making_comments,
        },
        continuous_improvement: {
          response: this.formState.continuous_improvement,
          comment: this.formState.continuous_improvement_comments,
        },
        overall_assessment: {
          response: this.formState.overall_assessment,
          comment: this.formState.overall_assessment_comments,
        },
      };

      commonService
        .store(this.$constants.employeeCompetencyAssessmentSubmitFeedback, {
          email: this.formState.email,
          token: this.formState.token,
          sign: this.formState.sign,
          answers: data,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.isCompleted = true;
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally((err) => {
          this.submitting = false;
        });
    },
  },

  computed: {
    employeeDescriptions() {
      const record = this.userInfo;
      const employee = record.employee;
      const hospital = record.hospital;

      return [
        {
          label: "Employee Name",
          value: employee?.full_name,
        },
        {
          label: "Hospital Name",
          value: hospital?.name,
        },
        {
          label: "Start Date",
          value: this.$customDate.mdy(record?.start_date),
        },
        {
          label: "End Date",
          value: this.$customDate.mdy(record?.end_date),
        },
      ];
    },
  },
};
</script>
