<template>
  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 10]">
      <a-col :span="24" class="mb-sm-1" v-if="!hasFormI9 || !hasFormW9">
        <a-radio-group v-model:value="formi9w9Holder" style="width: 100%">
          <a-radio-button
            v-for="item in formi9w9"
            :key="item.value"
            :value="item.value"
            @click="handleRadioClick"
          >
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </a-col>
      <a-col :sm="24">
        <a-form-item ref="title" label="Title" name="title">
          <a-input
            v-model:value="formState.title"
            :disabled="!!formi9w9Holder"
            placeholder="Enter here..."
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item ref="attachment" label="Attachment" name="attachment">
          <a-upload
            :before-upload="(_, fileList) => beforeUpload(fileList)"
            :file-list="formState.attachment"
            :multiple="false"
            :accept="acceptedTypes"
            :remove="onFileRemove"
          >
            <div>
              <a-button>
                <UploadOutlined />
                Upload
              </a-button>
            </div>
          </a-upload>
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons name="Create" />
  </a-form>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },
  data() {
    return {
      formState: {
        document_id: undefined,
        expiry_date: "",
        state: undefined,
        attachment: [],
      },
      formi9w9Holder: null,
      acceptedTypes: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ].join(","),
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    hasFormI9() {
      return this.extra.formI9W9?.includes("Form I-9");
    },
    hasFormW9() {
      return this.extra.formI9W9?.includes("Form W-9");
    },
    formi9w9() {
      return [
        ...(this.hasFormI9 ? [] : [{ label: "Form I-9", value: "Form I-9" }]),
        ...(this.hasFormW9 ? [] : [{ label: "Form W-9", value: "Form W-9" }]),
        {
          label: "Other",
          value: false,
        },
      ];
    },
    rules() {
      return {
        attachment: [
          {
            required: true,
            type: "array",
            message: "This field is required!",
          },
        ],
        title: [
          {
            required: true,
            trigger: ["change", "blur"],
            message: "This field is required!",
          },
          {
            trigger: ["change", "blur"],
            validator: (_, value) => {
              if (this.hasFormI9 && value === "Form I-9") {
                return Promise.reject("Form I-9 already exists.");
              }
              if (this.hasFormW9 && value === "Form W-9") {
                return Promise.reject("Form W-9 already exists.");
              }

              return Promise.resolve();
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    beforeUpload(fileList, types) {
      const acceptedTypes =
        types?.split(",") ||
        "image/jpeg,image/jpg,image/png,application/pdf".split(",");

      this.formState.attachment = fileList.filter((file) => {
        const isCorrectType = acceptedTypes.includes(file.type);
        const isCorrectSize = file.size / 1024 / 1024 < 5;

        if (!isCorrectType) {
          this.$message.error(`File type of ${file.type} is not supported.`);
          return false;
        }
        if (!isCorrectSize) {
          this.$message.error(
            `File size of ${file.name} is too large. Max: 5MB`
          );
          return false;
        }
        return true;
      });
      return false;
    },
    onFileRemove(file) {
      const items = this.formState.attachment;
      const idx = items.findIndex((v) => v.uid === file.uid);
      if (idx > -1) items.splice(idx, 1);
    },
    onSubmit() {
      this.loadingStart();
      const data = new FormData();

      data.append(`file`, this.formState.attachment[0]);
      data.append(`title`, this.formState.title);
      data.append(`employee_id`, this.extra.employee_id);

      commonService
        .store(this.$constants.employeeDocumentStore, data, true)
        .then(async (res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
    handleRadioClick() {
      this.$refs.title.clearValidate();
    },
  },

  watch: {
    "formState.document_id": function () {
      this.formState.attachment = [];
    },
    formi9w9Holder: function (val) {
      if (val === false) {
        this.formState.title = null;
      } else {
        this.formState.title = val;
      }
    },
  },
};
</script>
