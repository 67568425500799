<template>
  <a-layout-content>
    <page-header>
      <template #title> Email SMTP Settings </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card :loading="cardLoading" size="small">
      <template v-if="edit">
        <a-form
          layout="horizontal"
          :model="formData"
          :rules="rules"
          @finish="onSubmit"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-item ref="mail_mailer" label="Mailer" name="mail_mailer">
            <a-input v-model:value="formData.mail_mailer" />
          </a-form-item>

          <a-form-item ref="mail_host" label="Host" name="mail_host">
            <a-input v-model:value="formData.mail_host" />
          </a-form-item>

          <a-form-item ref="mail_port" label="Port" name="mail_port">
            <a-input v-model:value="formData.mail_port" />
          </a-form-item>

          <a-form-item
            ref="mail_username"
            label="Username"
            name="mail_username"
          >
            <a-input v-model:value="formData.mail_username" />
          </a-form-item>

          <a-form-item
            ref="mail_password"
            label="Password"
            name="mail_password"
          >
            <a-input v-model:value="formData.mail_password" />
          </a-form-item>

          <a-form-item
            ref="mail_encryption"
            label="Encryption"
            name="mail_encryption"
          >
            <a-input v-model:value="formData.mail_encryption" />
          </a-form-item>

          <a-form-item
            ref="mail_from_address"
            label="From Address"
            name="mail_from_address"
          >
            <a-input v-model:value="formData.mail_from_address" />
          </a-form-item>

          <a-form-item
            ref="mail_from_name"
            label="From Name"
            name="mail_from_name"
          >
            <a-input v-model:value="formData.mail_from_name" />
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-space direction="vertical">
              <a-space>
                <a-button
                  type="primary"
                  htmlType="submit"
                  :loading="loading"
                  size="small"
                >
                  <a-space>
                    <i v-if="!loading" class="ti ti-check ti-lg"></i>
                    Submit
                  </a-space>
                </a-button>
                <a-button
                  v-if="!loading"
                  type="primary"
                  danger
                  htmlType="button"
                  size="small"
                  @click="edit = false"
                >
                  <a-space> <i class="ti ti-x ti-lg"></i> Cancel </a-space>
                </a-button>
              </a-space>
            </a-space>
          </a-form-item>
        </a-form>
      </template>

      <template v-else>
        <a-row :gutter="[16, 16]">
          <a-col :span="4" align="right"> <strong>Mailer:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_mailer ?? "N/A" }} </a-col>

          <a-col :span="4" align="right"> <strong>Host:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_host ?? "N/A" }} </a-col>

          <a-col :span="4" align="right"> <strong>Port:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_port ?? "N/A" }} </a-col>

          <a-col :span="4" align="right"> <strong>Username:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_username ?? "N/A" }} </a-col>

          <a-col :span="4" align="right"> <strong>Password:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_password ?? "N/A" }} </a-col>

          <a-col :span="4" align="right"> <strong>Encryption:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_encryption ?? "N/A" }} </a-col>

          <a-col :span="4" align="right">
            <strong>From Address:</strong>
          </a-col>
          <a-col :span="20">
            {{ mail_smtp?.mail_from_address ?? "N/A" }}
          </a-col>

          <a-col :span="4" align="right"> <strong>From Name:</strong> </a-col>
          <a-col :span="20"> {{ mail_smtp?.mail_from_name ?? "N/A" }} </a-col>

          <a-col :span="20" :offset="4">
            <a-button type="primary" @click="updateFormData" size="small">
              <a-space>
                <i class="ti ti-pencil ti-lg"></i>
                Edit
              </a-space>
            </a-button>
          </a-col>
        </a-row>
      </template>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "../../../services";
export default {
  data() {
    return {
      cardLoading: true,
      loading: false,
      edit: false,
      mail_smtp: null,
      formData: {
        mail_mailer: "",
        mail_host: "",
        mail_port: "",
        mail_username: "",
        mail_password: "",
        mail_encryption: "",
        mail_from_address: "",
        mail_from_name: "",
      },
      rules: {
        mail_mailer: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_host: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_port: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_username: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_password: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_encryption: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_from_address: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
        mail_from_name: [
          {
            required: true,
            message: "This field is required!",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },

  mounted() {
    this.fetchSetting();
  },

  methods: {
    fetchSetting() {
      commonService
        .store(this.$constants.getSettingUrl, { key: "mail_smtp" })
        .then((res) => {
          if (res.data.mail_smtp) {
            this.mail_smtp = JSON.parse(res.data.mail_smtp);
            this.updateFormData(false);
          } else {
            this.edit = true;
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },

    updateFormData(bool = true) {
      let smtp = JSON.parse(JSON.stringify(this.mail_smtp));
      this.formData.mail_mailer = smtp?.mail_mailer ?? "";
      this.formData.mail_host = smtp?.mail_host ?? "";
      this.formData.mail_port = smtp?.mail_port ?? "";
      this.formData.mail_username = smtp?.mail_username ?? "";
      this.formData.mail_password = smtp?.mail_password ?? "";
      this.formData.mail_encryption = smtp?.mail_encryption ?? "";
      this.formData.mail_from_address = smtp?.mail_from_address ?? "";
      this.formData.mail_from_name = smtp?.mail_from_name ?? "";
      this.edit = bool;
    },

    onSubmit() {
      this.loading = true;

      commonService
        .store(this.$constants.settingStoreUrl, {
          mail_smtp: JSON.stringify(this.formData),
        })
        .then((res) => {
          this.$message.success(res.message);
          this.mail_smtp = this.formData;
          this.edit = false;
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
