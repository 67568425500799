<template>
  <div>
    <a-spin :spinning="loading">
      <a-card title="Personal Information">
        <a-form
          ref="piRef"
          :layout="formLayout"
          :model="model"
          :rules="rules"
          @finish="handleFinish"
        >
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item
                label="Select Company"
                name="companyId"
                autofocus
                ref="companyId"
              >
                <a-select
                  v-model:value="model.companyId"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="company in companies"
                    :key="company.id"
                    :label="company.name"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="employeeStatus"
                label="Employee Status"
                name="employeeStatus"
              >
                <a-select
                  v-model:value="model.employeeStatus"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="employeeStatus in $constants.employeeStatus"
                    :key="employeeStatus.value"
                    :label="employeeStatus.text"
                    :value="employeeStatus.value"
                  >
                    {{ employeeStatus.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="insperityHireDate"
                label="Insperity Hire Date"
                name="insperityHireDate"
              >
                <a-date-picker
                  v-model:value="model.insperityHireDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="clientHireDate"
                label="Client Hire Date"
                name="clientHireDate"
              >
                <a-date-picker
                  v-model:value="model.clientHireDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider type="horizontal" />
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item ref="firstName" label="First Name" name="firstName">
                <a-input
                  v-model:value="model.firstName"
                  placeholder="Enter first name here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="middleName"
                label="Middle Name/Initial"
                name="middleName"
              >
                <a-input
                  v-model:value="model.middleName"
                  placeholder="Enter middle name here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="lastName" label="Last Name" name="lastName">
                <a-input
                  v-model:value="model.lastName"
                  placeholder="Enter last name here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="email" label="Email" name="email">
                <a-input
                  v-model:value="model.email"
                  placeholder="Enter email here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="dateOfBirth"
                label="Date of Birth"
                name="dateOfBirth"
              >
                <a-date-picker
                  v-model:value="model.dateOfBirth"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="gender" label="Gender" name="gender">
                <a-radio-group name="radioGroup" v-model:value="model.gender">
                  <a-radio
                    v-for="(v, i) in $constants.gender"
                    :key="`${i}Gender`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="12">
              <a-form-item ref="ssn" label="Social Security Number" name="ssn">
                <a-input
                  v-model:value="model.ssn"
                  placeholder="(ex: 123456789)"
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="12">
              <a-form-item
                ref="cssn"
                label="Confirm Social Security Number"
                name="cssn"
              >
                <a-input
                  v-model:value="model.cssn"
                  placeholder="(ex: 123456789)"
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item
                ref="streetAddress"
                label="Street Address"
                name="streetAddress"
              >
                <a-input
                  v-model:value="model.streetAddress"
                  placeholder="Enter street address here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item ref="city" label="City" name="city">
                <a-input
                  v-model:value="model.city"
                  placeholder="Enter city here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item ref="state" label="State" name="state">
                <a-select
                  v-model:value="model.state"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="state in $constants.states"
                    :key="state.name"
                    :label="state.name"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="6">
              <a-form-item ref="zipCode" label="Zip Code" name="zipCode">
                <a-input
                  v-model:value="model.zipCode"
                  placeholder="Enter zip code here..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24">
              <a-form-item ref="overrideAddressValidation" name="override">
                <a-checkbox> Override Address Validation </a-checkbox>
              </a-form-item>
            </a-col>
          </a-row>
          <a-button type="danger" class="mr-xs-1 width-125" html-type="submit">
            SAVE
          </a-button>
          <a-button class="mr-xs-1 width-125" @click="resetPiForm">
            CANCEL
          </a-button>
          <a-button type="primary" class="width-125" @click="next">
            SAVE & NEXT
          </a-button>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import { commonService } from "../../services";

export default {
  props: ["companies", "rules", "model", "personId"],
  data() {
    return {
      formLayout: "vertical",
      moment,
      loading: false,
    };
  },
  mounted() {
    this.rules.ssn[1] = {
      validator: this.triggerConfirmSSN,
      trigger: ["change", "blur"],
    };
    this.rules.cssn[1] = {
      validator: this.confirmSSN,
      message: "Both social security number don't match!",
      trigger: ["change", "blur"],
    };
    this.$refs.companyId.$el
      .getElementsByClassName("ant-select-selection-search-input")[0]
      .focus();
  },
  methods: {
    handleFinish(value) {
      this.handleSubmit(value);
    },
    resetPiForm() {
      Object.keys(this.model).forEach((el) => {
        if (el == "companyId" || el == "employeeStatus") {
          this.model[el] = null;
        } else {
          this.model[el] = "";
        }
      });
    },
    next() {
      this.$refs.piRef.validate().then((val) => {
        this.handleSubmit(val, "pushToNextStep");
      });
    },
    triggerConfirmSSN(rule, value) {
      const reg = /^[0-9]*$/;
      if (value !== "") {
        if (this.model.cssn !== "") {
          this.$refs.piRef.validateField("cssn");
        }
      }
      if (value && !reg.test(value)) {
        return Promise.reject("Social security number must be in numeric!");
      }
      if (value.length !== 9) {
        return Promise.reject(
          "Social security number must be in a valid number!"
        );
      }
      return Promise.resolve();
    },
    confirmSSN(rule, value) {
      if (value !== this.model.ssn) {
        return Promise.reject("Both social security number don't match!");
      }
      return Promise.resolve();
    },
    handleSubmit(value, whereToGo = "") {
      this.loading = true;
      if (this.personId) {
        value.onboarding_person_id = this.personId;
      }
      commonService
        .store(this.$constants.onboardingPersonalUrl, value)
        .then((res) => {
          this.loading = false;
          if (whereToGo == "pushToNextStep") {
            this.$message.success(res.message);
            this.$emit(
              "callback",
              false,
              +1,
              res.onboarding_person_id,
              res.data
            );
          } else {
            this.$emit("callback", true);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errors) {
            let email = err.errors.email;
            if (email && email[0]) {
              this.$refs.email.validateState = "error";
              this.$refs.email.validateMessage = email[0];
              this.$refs.piRef.scrollToField("email");
            }
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>

<style scoped>
.width-125 {
  width: 125px;
}
@media (max-width: 767px) {
  .width-125 {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
