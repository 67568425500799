<template>
  <a-form
    name="heater_cooler_attachment_form"
    ref="heater_cooler_attachment_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <a-form-item ref="attachment" name="attachment">
          <a-upload-dragger
            :fileList="model.attachment"
            :remove="handleRemoveReceipt"
            :before-upload="beforeUploadReceipt"
            accept="application/pdf"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">Max file size: 10MB<br /></p>
          </a-upload-dragger>
        </a-form-item>
      </a-col>
    </a-row>
    <side-drawer-buttons name="Upload" />
  </a-form>
</template>

<script>
import { commonService } from "@/services";
import { mapActions, mapGetters } from "vuex";
import { InboxOutlined } from "@ant-design/icons-vue";

export default {
  emits: {
    callback: {
      required: false,
    },
  },

  components: { InboxOutlined },

  data() {
    return {
      fileList: [],
      model: {
        id: null,
        attachment: null,
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    this.model = {
      id: this.record.id,
      attachment: this.record.file_name
        ? [
            {
              id: this.record.id,
              uid: this.record.id,
              name: this.record.file_name,
              url: this.record.full_file_path,
              thumbUrl: this.record.full_file_path,
            },
          ]
        : [],
    };
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      const formData = new FormData();
      formData.append("id", this.model.id);
      if (!this.model.attachment[0].id) {
        formData.append("attachment", this.model.attachment[0]);
      }
      this.loadingStart();

      commonService
        .store(this.$constants.equipmentMaintenanceUploadFileUrl, formData)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },

    handleRemoveReceipt(file) {
      this.model.attachment = file;
    },

    beforeUploadReceipt(file) {
      if (file.size < 10 * 1024 * 1024) {
        this.model.attachment = [file];
      } else {
        this.$message.error(`${file.name} size is more then 10MB`);
      }
      return false;
    },
  },
};
</script>
