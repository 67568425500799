<template>
  <a-descriptions
    size="small"
    bordered
    :column="1"
    :labelStyle="{ width: '300px' }"
    class="mb-sm-1"
  >
    <a-descriptions-item
      v-for="item in bloodProductVolume"
      :label="item.name"
      :key="item.name"
    >
      {{ item.sum }} mL
    </a-descriptions-item>
  </a-descriptions>

  <a-descriptions
    size="small"
    bordered
    :column="1"
    :labelStyle="{ width: '300px' }"
    class="mb-sm-1"
  >
    <a-descriptions-item
      v-for="item in plusVolume"
      :label="item.name"
      :key="item.name"
    >
      {{ item.sum }} mL
    </a-descriptions-item>
  </a-descriptions>

  <a-descriptions
    size="small"
    bordered
    :column="1"
    :labelStyle="{ width: '300px' }"
  >
    <a-descriptions-item
      v-for="item in minusVolume"
      :label="item.name"
      :key="item.name"
    >
      {{ item.sum }} mL
    </a-descriptions-item>
  </a-descriptions>

  <modal-buttons cancelBtnText="Close" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),

    bloodProductVolume() {
      return this.extra.filter((e) => e.type == "Blood Product");
    },

    plusVolume() {
      return this.extra.filter((e) => e.type == "+ Volume");
    },

    minusVolume() {
      return this.extra.filter((e) => e.type == "- Volume");
    },
  },

  mounted() {
    //
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),
  },
};
</script>
