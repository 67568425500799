<template>
  <div>
    <a-form
      name="role_form"
      ref="role_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-form-item ref="name" label="Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Enter name here"
            />
          </a-form-item>
          <!-- <a-skeleton :loading="skeleton_loading" active>
            <a-checkbox-group
              :default-value="checked_routes"
              :value="checked_routes"
              @change="on_change"
              style="width: 100%"
            >
              <h3>
                System Access
                <small class="danger-color text-weight-light"
                  >(Click "<i class="fas fa-check"></i>" to select all child
                  roles)</small
                >
              </h3>
              <div
                class="ant-table ant-table-scroll-position-left ant-table-small"
              >
                <div class="ant-table-content">
                  <div class="ant-table-body">
                    <table>
                      <thead class="ant-table-thead">
                        <tr>
                          <th class="width-500">Module Name</th>
                          <th style="text-align: center !important">Create</th>
                          <th style="text-align: center !important">View</th>
                          <th style="text-align: center !important">Update</th>
                          <th style="text-align: center !important">Delete</th>
                        </tr>
                      </thead>
                      <tbody class="ant-table-tbody">
                        <template v-if="routes">
                          <child-routes
                            :routes="routes"
                            :parent_id="0"
                            :depth="0"
                            :default_selected_routes="[]"
                            @big_checkbox_checked="big_checkbox_checked"
                          />
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </a-checkbox-group>
          </a-skeleton> -->
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import ChildRoutes from "@/components/childRoutes";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
    "child-routes": ChildRoutes,
  },
  data() {
    return {
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "name is required!",
            trigger: "blur",
          },
        ],
      },
      checked_routes: [],
      skeleton_loading: false,
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
    routes() {
      return this.extra.routes ?? {};
    },
  },
  created() {
    const rights = this.record.associated_rights;
    if (rights != null) {
      this.checked_routes = Array.from(rights.split(","), Number);
    }
  },
  mounted() {
    this.getFormState();
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
      };
    },
    on_change(checked_values) {
      this.checked_routes = checked_values;
    },
    big_checkbox_checked(ids, type) {
      if (type == "checked") {
        ids.forEach((v) => {
          var index = this.checked_routes.indexOf(v);
          if (index == -1) {
            this.checked_routes.push(v);
          }
        });
      } else {
        ids.forEach((v) => {
          var index = this.checked_routes.indexOf(v);
          if (index != -1) {
            this.checked_routes.splice(index, 1);
          }
        });
      }
    },
    onSubmit() {
      this.$refs.role_form
        .validate()
        .then((val) => {
          if (this.record.id) {
            val.id = this.record.id;
          }
          val.rights = this.checked_routes;
          this.loadingStart();
          companyService
            .store(this.$constants.roleStoreUrl, val)
            .then((res) => {
              this.loadingStop();
              if (res.success) {
                this.$message.success(res.message);
                this.close();
                this.$emit("callback");
              }
            })
            .catch((err) => {
              this.loadingStop();
              this.$refs.role_form.fields.forEach((v, i) => {
                if (err.errors[v.fieldName]) {
                  v.validateState = "error";
                  v.validateMessage = err.errors[v.fieldName]["0"];
                }
              });
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style></style>
