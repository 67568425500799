<template>
  <a-divider orientation="left">HIPEC/ILI Live Charting</a-divider>

  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-card size="small" bordered>
        <a-row type="flex">
          <a-col flex="35px"> N/A </a-col>
          <a-col flex="35px"><CheckOutlined /></a-col>
          <a-col flex="calc(100% - 70px)">
            <strong>Pre Procedure Checklist</strong>
          </a-col>
        </a-row>

        <template
          v-for="option in $constants.hipecPreProcedureChecklist"
          :key="option"
        >
          <a-row type="flex">
            <a-col flex="35px">
              <i
                v-if="hipec?.pre_checklist?.not_applicable?.includes(option)"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="35px">
              <i
                v-if="hipec?.pre_checklist?.checked?.includes(option)"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="calc(100% - 70px)">{{ option }}</a-col>
          </a-row>
        </template>

        <a-descriptions
          class="mt-sm-1"
          size="small"
          bordered
          :labelStyle="{ color: '#666666', width: '150px' }"
          :column="{ sm: 1 }"
        >
          <a-descriptions-item label="Nurse Signature:">
            <template v-if="hipec.pre_checklist?.nurse_sign">
              <img
                :width="200"
                :src="hipec.pre_checklist.nurse_sign ?? 'N/A'"
              />
            </template>
            <template v-else> N/A </template>
          </a-descriptions-item>
          <a-descriptions-item label="Perfusionist Signature:">
            <template v-if="hipec.pre_checklist?.perfusionist_sign">
              <img
                :width="200"
                :src="hipec.pre_checklist.perfusionist_sign ?? 'N/A'"
              />
            </template>
            <template v-else> N/A </template>
          </a-descriptions-item>
          <a-descriptions-item label="Reported At:">
            {{ hipec.pre_checklist?.submitted_at ?? null }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-col>

    <a-col :span="12">
      <a-card size="small" bordered>
        <a-row type="flex">
          <a-col flex="35px"> N/A </a-col>
          <a-col flex="35px"><CheckOutlined /></a-col>
          <a-col flex="calc(100% - 70px)">
            <strong>Post Procedure Checklist</strong>
          </a-col>
        </a-row>

        <template
          v-for="option in $constants.hipecPostProcedureChecklist"
          :key="option"
        >
          <a-row type="flex">
            <a-col flex="35px">
              <i
                v-if="hipec?.post_checklist?.not_applicable?.includes(option)"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="35px">
              <i
                v-if="hipec?.post_checklist?.checked?.includes(option)"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="calc(100% - 70px)">{{ option }}</a-col>
          </a-row>
        </template>

        <a-divider class="mb-sm-1 mt-sm-1" />
        <strong>Reported At:</strong>
        {{ hipec.post_checklist?.submitted_at ?? null }}
      </a-card>

      <a-card size="small" class="mt-sm-1" bordered>
        <strong>Total HIPEC Time: {{ totalHIPECTime }} Min</strong>
      </a-card>
    </a-col>
  </a-row>

  <a-table
    class="mt-sm-1"
    :rowKey="(_, index) => index"
    :columns="hipecTablecolumns"
    :data-source="hipecEvents"
    bordered
    size="small"
    :pagination="false"
    :scroll="{ x: true, y: 0 }"
  >
    <template #spo2CustomTitle> SpO<sub>2</sub> </template>
  </a-table>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { h } from "vue";

export default {
  name: "hipec-live-charting",
  props: ["hipec"],

  components: { CheckOutlined },

  data() {
    return {
      hipecTablecolumns: [
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          fixed: "left",
          customRender: this.hipecColspanRender,
        },
        {
          title: "Vitals",
          children: [
            {
              title: "Arterial BP (mmHg)",
              dataIndex: "arterial_bp",
              key: "arterial_bp",
              customRender: this.hipecColspanRender,
            },
            {
              title: "HR",
              dataIndex: "hr",
              key: "hr",
              customRender: this.hipecColspanRender,
            },
            {
              dataIndex: "spo2",
              key: "spo2",
              slots: {
                title: "spo2CustomTitle",
              },
              customRender: this.hipecColspanRender,
            },
          ],
        },
        {
          title: "Pump",
          children: [
            {
              title: "Flow Rate (ml/min)",
              dataIndex: "flow_rate",
              key: "flow_rate",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Circuit Pressure (mmHg)",
              dataIndex: "circuit_pressure",
              key: "circuit_pressure",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Negative Pressure (mmHg)",
              dataIndex: "negative_pressure",
              key: "negative_pressure",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Target Temp (°C)",
              dataIndex: "target_temp",
              key: "target_temp",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Temp Pump (°C)",
              dataIndex: "temp_pump",
              key: "temp_pump",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Reservoir Volume (ml)",
              dataIndex: "reservoir_volume",
              key: "reservoir_volume",
              customRender: this.hipecColspanRender,
            },
          ],
        },
        {
          title: "Temperature (°C)",
          children: [
            {
              title: "Inflow (°C)",
              dataIndex: "inflow",
              key: "inflow",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Outflow (°C)",
              dataIndex: "outflow",
              key: "outflow",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Other (°C)",
              dataIndex: "other",
              key: "other",
              customRender: this.hipecColspanRender,
            },
          ],
        },
        {
          title: "Volume",
          children: [
            {
              title: "Prime (ml)",
              dataIndex: "prime",
              key: "prime",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Chemotherapy (mg)",
              dataIndex: "chemotherapy",
              key: "chemotherapy",
              customRender: this.hipecColspanRender,
            },
            {
              title: "Plasmalyte (ml)",
              dataIndex: "plasmalyte",
              key: "plasmalyte",
              customRender: this.hipecColspanRender,
            },
            {
              title: "NaCl 0.9% (ml)",
              dataIndex: "nacl",
              key: "nacl",
              customRender: this.hipecColspanRender,
            },
          ],
        },
      ],
    };
  },

  computed: {
    hipecEvents() {
      if (this.hipec?.data) {
        let data = this.hipec.data
          .filter((e) => !e.deleted_at)
          .sort((a, b) =>
            moment(a.time, "HH:mm:ss").isAfter(moment(b.time, "HH:mm:ss"))
              ? 1
              : -1
          );

        if (data.length != 0) {
          let firstDate = data[0].date;
          let lastDate = data[data.length - 1].date;
          let range = this.$comman.getDateRange(firstDate, lastDate);
          range.forEach((date, idx) => {
            if (idx != 0) {
              data.push({
                event: "Date change",
                date: date,
                time: "00:00:00",
                datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
                comment: `Date: ${date}`,
              });
            }
          });
        }

        return data.sort((a, b) =>
          moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
            moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
          )
            ? 1
            : -1
        );
      }
      return [];
    },

    totalHIPECTime() {
      if (
        this.hipec?.timers?.hipec &&
        this.hipec?.timers?.hipec[0].start_time &&
        this.hipec?.timers?.hipec[0].stop_time
      ) {
        let timers = this.hipec?.timers?.hipec[0];
        var duration = moment.duration(
          moment(timers.stop_time, "HH:mm:ss").diff(
            moment(timers.start_time, "HH:mm:ss")
          )
        );
        return Math.ceil(duration.asMinutes());
      }
      return 0;
    },

    hipecTime() {
      if (this.hipec?.timers?.hipec[0]) {
        let timers = this.hipec?.timers?.hipec[0];
        let startTime = moment(timers.start_time, "HH:mm:ss");
        let stopTime = moment(timers.stop_time, "HH:mm:ss");
        let duration = stopTime.diff(startTime, "seconds");
        return moment.utc(duration * 1000).format("HH:mm:ss");
      }
      return "";
    },
  },

  methods: {
    hipecColspanRender(data) {
      if (["HIPEC", "Comment"].includes(data.record.event)) {
        if (data.column.key == "arterial_bp") {
          let note = "";
          if (["HIPEC"].includes(data.record.event)) {
            note = `${data.record.note} ${
              data.record.note.includes("Off") ? `(${this.hipecTime})` : ""
            } [Date: ${data.record.date}]`;
          } else {
            note = `${data?.record?.comment ?? ""} | ${
              data?.record?.time ?? ""
            }`;
          }
          return {
            children: note,
            props: { colSpan: 16 },
          };
        } else if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Date change"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return {
            children: h("div", {
              innerHTML: data.record.comment,
            }),
            props: {
              colSpan: 17,
              style: { backgroundColor: "#eee", borderColor: "#fff" },
            },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else {
          return !["", null, 0].includes(data.text) ? data.text : "-";
        }
      }
    },
  },
};
</script>
