<template>
  <a-layout-content>
    <page-header>
      <template #title> Employee Positions </template>

      <template #buttons>
        <a-button type="primary" @click="addNewEmployeePosition">
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add New Employee Position
          </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                @click="editEmployeePosition(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip title="Offer Letter Template">
              <router-link
                :to="{
                  name: 'employee-position.offer-letter-template',
                  params: { id: record.id },
                }"
              >
                <a-button type="link" size="small">
                  <i class="ti ti-file-certificate ti-lg"></i>
                </a-button>
              </router-link>
            </a-tooltip>

            <a-tooltip title="Contractor Offer Letter Template">
              <router-link
                :to="{
                  name: 'employee-position.contractor-offer-letter-template',
                  params: { id: record.id },
                }"
              >
                <a-button type="link" size="small">
                  <i class="ti ti-file-certificate ti-lg"></i>
                </a-button>
              </router-link>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.employeePositionsUrl,
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          sorter: true,
          width: 50,
          customRender: ({ index }) => index + 1,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "ADP Job Title",
          dataIndex: "adp_job_title",
          key: "adp_job_title",
          sorter: true,
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "ADP Job Code",
          dataIndex: "adp_job_code",
          key: "adp_job_code",
          sorter: true,
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],

      jobTitles: [],
    };
  },

  mounted() {
    this.getJobTitles();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getJobTitles() {
      commonService
        .get(this.$constants.adpGetDataUrl, { name: "jobs" })
        .then(res => {
          if (res.success) {
            let jobs = res.data.find(e => e.name == "jobs");
            this.jobTitles = jobs.data.map(e => ({
              label: `${e.name} | ${e.code}`,
            }));
          }
        });
    },

    addNewEmployeePosition() {
      this.open({
        title: "Add New Employee Position",
        path: "admin.employeePosition.store",
        callback: this.refresh,
        extra: { job_titles: this.jobTitles },
      });
    },

    editEmployeePosition(record) {
      this.open({
        title: "Edit Employee Position",
        path: "admin.employeePosition.store",
        callback: this.refresh,
        record,
        extra: { job_titles: this.jobTitles },
      });
    },
  },
};
</script>
