<template>
  <a-form
    name="live_charting_status_form"
    ref="live_charting_status_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <status-form
      :model="model"
      :showPaCvpActField="showPaCvpActField"
      @update="(value, key) => (model[key] = value)"
    >
      <template #timeField="{ time, date, change }">
        <a-col :span="12">
          <a-row :gutter="[16, 0]">
            <a-col :span="12">
              <a-form-item
                ref="date"
                label="Date"
                name="date"
                :rules="{
                  required: true,
                  message: 'Invalid date.',
                  type: 'date',
                }"
              >
                <a-date-picker
                  :value="date"
                  :disabled-date="$comman.disabledFutureDate"
                  :format="$constants.datepickerFormat"
                  :placeholder="$constants.datePickerPlaceholder"
                  :allowClear="false"
                  @change="(e) => change(e, 'date')"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                ref="time"
                label="Time"
                name="time"
                :rules="{
                  required: true,
                  message: 'Invalid time.',
                  validator: $validation.timeWithSecFormat,
                }"
              >
                <a-input
                  :value="time"
                  v-maska="$constants.timeWithSecMaska"
                  placeholder="HH:mm:ss"
                  @change="(e) => change(e.target.value, 'time')"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </template>
    </status-form>

    <modal-buttons name="Submit" />
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";
import statusForm from "../../liveCharting/components/status.vue";

export default {
  components: { statusForm },

  data() {
    return {
      model: {
        uuid: null,
        unique_code: undefined,
        date: null,
        time: "",
        event: "Status",
        art_pressure: null,
        cvp: null,
        pa: null,
        blood_flow: null,
        line_pressure: null,
        art_temp: null,
        venous_temp: null,
        core_temp: null,
        fio2: null,
        sweep: null,
        anesthetic_gas: null,
        act: null,
        svo2: null,
        rso2_left: null,
        rso2_right: null,
      },
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("modal", ["record", "extra"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    showPaCvpActField() {
      return (
        this.extra?.unique_code == 0 ||
        (this.record.live_chart?.cpb?.data ? false : true)
      );
    },
  },

  mounted() {
    if (this.extra.lastRecord) {
      this.model.blood_flow = this.extra.lastRecord.blood_flow;
      this.model.fio2 = this.extra.lastRecord.fio2;
      this.model.sweep = this.extra.lastRecord.sweep;
      this.model.anesthetic_gas = this.extra.lastRecord.anesthetic_gas;
      this.model.rso2_left = this.extra.lastRecord.rso2_left;
      this.model.rso2_right = this.extra.lastRecord.rso2_right;
    }
    if (this.extra.record) {
      this.model.unique_code = this.extra.record.unique_code;
      this.model.date = moment(this.extra.record.date, "MM-DD-YYYY");
      this.model.time = this.extra.record.time;
      this.model.art_pressure = this.extra.record.art_pressure;
      this.model.cvp = this.extra.record.cvp;
      this.model.pa = this.extra.record.pa;
      this.model.blood_flow = this.extra.record.blood_flow;
      this.model.line_pressure = this.extra.record.line_pressure;
      this.model.art_temp = this.extra.record.art_temp;
      this.model.venous_temp = this.extra.record.venous_temp;
      this.model.core_temp = this.extra.record.core_temp;
      this.model.fio2 = this.extra.record.fio2;
      this.model.sweep = this.extra.record.sweep;
      this.model.anesthetic_gas = this.extra.record.anesthetic_gas;
      this.model.act = this.extra.record.act;
      this.model.svo2 = this.extra.record.svo2;
      this.model.rso2_left = this.extra.record.rso2_left;
      this.model.rso2_right = this.extra.record.rso2_right;
    } else {
      this.model.date = moment().tz(this.timezone);
      this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
    }
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();
      let data = JSON.parse(JSON.stringify(this.model));

      data.date = this.$customDate.mdy(this.model.date);
      data.datetime = `${data.date} ${data.time}`;
      data.uuid = this.record.uuid;
      data.employee_id = !this.extra.record
        ? this.employee.id
        : this.extra.record.employee_id;
      data.employee_name = !this.extra.record
        ? this.employee.full_name
        : this.extra.record.employee_name;
      commonService
        .store(this.$constants.caseReportStoreCPBRecordUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loadingStop();
        });
    },
  },
};
</script>
