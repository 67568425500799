<template>
  <template v-if="loading">
    <a-card size="small" :loading="loading"></a-card>
  </template>

  <template v-else>
    <div>
      <AgreementModal
        v-model:handbookModal="showDialog"
        v-if="selectedAgreement"
        :agreement="selectedAgreement"
        :closeDialog="closeDialog"
        :employee="employee"
      />
      <a-row :gutter="[12, 12]">
        <a-col :span="24">
          <a-typography-title :level="4"> Policy Documents </a-typography-title>
          <span>
            To proceed with the onboarding process, please review and sign the
            following documents:
          </span>
          <a-divider style="margin: 12px 0" />
        </a-col>

        <a-col v-if="isLogsEmpty" :span="24">
          <a-card size="small">
            <a-empty
              description="No onboarding docs found! Please proceed to next step."
            />
          </a-card>
        </a-col>

        <template v-else>
          <a-col v-for="i in onbLogs" :key="i.id" :span="24">
            <a-card
              size="small"
              :style="{ borderColor: i.status === 1 ? '#16a34a' : 'red' }"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 12px;
                "
              >
                <div>
                  <span> {{ i.onboarding_document.name }} </span>
                  <template v-if="i.status === 1">
                    <a-typography-text>
                      ({{ $customDate.mdyhis(i.document_signed_at) }})
                    </a-typography-text>
                    <a-tag color="green">Completed</a-tag>
                  </template>
                </div>
                <a-button
                  v-if="i.status === 1"
                  @click="openDialog(i)"
                  style="
                    background-color: #16a34a;
                    border-color: #16a34a;
                    color: white;
                  "
                >
                  View
                </a-button>
                <a-button danger type="primary" @click="openDialog(i)" v-else>
                  Click to Complete
                </a-button>
              </div>
            </a-card>
          </a-col>
        </template>

        <a-col :span="24">
          <footer-buttons
            :current-step="currentStepComputed"
            :steps="steps"
            hide-save-and-next
            :disable-next="(!isLogsEmpty && !isAllSigned) || onbDocsLoading"
            @next-step="currentStepComputed += 1"
            @previous-step="currentStepComputed -= 1"
          />
        </a-col>
      </a-row>
    </div>
  </template>
</template>

<script>
import agreementModal from "@/components/agreementModal.vue";
import FooterButtons from "@/components/newUserOnboarding/FooterButtons.vue";
import { commonService } from "@/services";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      showDialog: false,
      selectedAgreement: null,
      moment,
    };
  },

  emits: ["update:currentStep", "update:logs"],

  components: {
    FooterButtons,
    AgreementModal: agreementModal,
  },

  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    logs: {
      type: Array,
      default: () => [],
    },
    onbDocsLoading: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    isLogsEmpty() {
      return !this.onbDocsLoading && this.onbLogs.length === 0;
    },

    currentStepComputed: {
      get() {
        return this.currentStep;
      },
      set(value) {
        return this.$emit("update:currentStep", value);
      },
    },

    onbLogs: {
      get() {
        return this.logs;
      },
      set(value) {
        return this.$emit("update:logs", value);
      },
    },

    isAllSigned() {
      return (
        this.onbLogs.length > 1 && this.onbLogs.every((log) => log.status === 1)
      );
    },
  },

  methods: {
    ...mapActions("auth", ["login"]),
    fetchData() {
      this.loading = true;
      return commonService
        .get(this.$constants.employeeOnboardingLogs)
        .then((res) => {
          this.onbLogs = res.data;

          if (res.data[0] && res.data[0].employee?.profile_status == 1) {
            location.reload();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    openDialog(agreement) {
      this.showDialog = true;
      this.selectedAgreement = agreement;
    },
    async closeDialog(runCallback = false) {
      this.showDialog = false;
      this.selectedAgreement = null;
      if (runCallback) {
        await this.fetchData();
        await this.login({ isInitStore: false });
      }
    },
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.selectedAgreement = null;
      }
    },
    // isAllSigned(val) {
    //   if (val) {
    //     this.currentStepComputed += 1;
    //   }
    // },
  },
};
</script>
