<template>
  <a-spin :spinning="loading">
    <a-card title="Money In-Out (YTD)" size="small">
      <div ref="inOutCashFlowChart"></div>
    </a-card>
  </a-spin>
</template>

<script>
import { Column } from "@antv/g2plot";
import { commonService } from "../../../services";

export default {
  name: "in-out-cash-flow",

  data() {
    return {
      loading: false,
      data: [],
      chart: null,
      config: {
        isGroup: true,
        columnStyle: {
          radius: [5, 5, 0, 0],
        },
        xField: "month",
        yField: "value",
        seriesField: "name",
        label: {
          rotate: 11,
          offsetX: 10,
          offsetY: 5,
          autoRotate: false,
          position: "top",
          formatter: (v) => this.$comman.abbreviatedNumber(v.value),
        },
        tooltip: {
          formatter: ({ name, value }) => ({
            name: name,
            value: this.$comman.withCurrency(value),
          }),
        },
        legend: {
          layout: "horizontal",
          position: "top",
        },
      },
    };
  },

  computed: {
    //
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      commonService
        .get(this.$constants.financeDashboardInOutCashFlowUrl)
        .then(async (res) => {
          this.data = res.data;
          let amounts = this.data.map((e) => e.value);
          let maxAmount = amounts.length > 0 ? Math.max(...amounts) : 0;
          let max = Math.round(maxAmount + maxAmount * 0.1);
          let obj = {
            data: this.data,
            ...this.config,
            yAxis: {
              max,
            },
          };
          if (this.chart) {
            this.chart.update(obj);
          } else {
            if (this.$refs["inOutCashFlowChart"]) {
              this.chart = new Column(this.$refs["inOutCashFlowChart"], obj);
            }
            await this.$nextTick(() => {
              this.chart?.render();
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
