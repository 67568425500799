<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    >
      <template #title> Bulk Creation Asset Inventory </template>
    </a-page-header>

    <a-card size="small" :loading="cardLoading">
      <a-form
        name="asset_inventory_form"
        ref="asset_inventory_form"
        :model="formState"
        layout="vertical"
        @finish="onSubmit"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="8">
            <a-form-item
              ref="asset_product_id"
              label="Product"
              name="asset_product_id"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'number',
              }"
            >
              <a-select
                v-model:value="formState.asset_product_id"
                placeholder="Select product"
                optionFilterProp="label"
                allowClear
                showSearch
              >
                <a-select-option
                  v-for="option in assetProducts"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item
              ref="asset_manufacturer_id"
              label="Manufacturer"
              name="asset_manufacturer_id"
              :rules="{
                required: true,
                message: 'This field is required',
                type: 'number',
              }"
            >
              <a-select
                v-model:value="formState.asset_manufacturer_id"
                placeholder="Select product"
                optionFilterProp="label"
                allowClear
                showSearch
              >
                <a-select-option
                  v-for="option in assetManufacturers"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item
              ref="model_no"
              label="Model No"
              name="model_no"
              :rules="{
                required: true,
                message: 'This field is required',
              }"
            >
              <a-input
                v-model:value="formState.model_no"
                placeholder="Enter model no here"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <template v-for="(product, index) in formState.products" :key="index">
          <a-divider class="mt-sm-0 mb-sm-1"> </a-divider>
          <a-row type="flex" justify="space-between" align="middle">
            <a-typography-title :level="4">
              Product #{{ index + 1 }}
            </a-typography-title>

            <a-space>
              <a-button
                v-if="formState.products.length == index + 1"
                type="primary"
                size="small"
                @click="addProductRow"
              >
                <a-space> <i class="ti ti-plus ti-lg"></i> Add </a-space>
              </a-button>
              <a-button
                v-if="formState.products.length > 1"
                type="ghost"
                danger
                size="small"
                @click="removeProductRow(index)"
              >
                <a-space> <i class="ti ti-trash ti-lg"></i> Remove </a-space>
              </a-button>
            </a-space>
          </a-row>

          <a-row :gutter="[20, 0]">
            <a-col :span="8">
              <a-form-item
                label="Serial No"
                :name="['products', index, 'serial_no']"
                :rules="{
                  required: true,
                  message: 'This field is required',
                }"
              >
                <a-input
                  v-model:value="product.serial_no"
                  placeholder="Enter serial no here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item
                label="Asset ID Tag"
                :name="['products', index, 'asset_id_tag']"
              >
                <a-input
                  v-model:value="product.asset_id_tag"
                  placeholder="Enter asset id tag here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item
                label="Secondary ID Tag"
                :name="['products', index, 'secondary_id_tag']"
              >
                <a-input
                  v-model:value="product.secondary_id_tag"
                  placeholder="Enter secondary id tag here"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item
                ref="description"
                label="Description"
                :name="['products', index, 'description']"
              >
                <a-textarea
                  v-model:value="product.description"
                  placeholder="Enter Description here"
                  :rows="5"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item
                label="Owned By"
                :name="['products', index, 'owned_by']"
                :rules="{
                  required: true,
                  message: 'This field is required',
                  type: 'number',
                }"
              >
                <a-radio-group
                  name="radioGroup"
                  v-model:value="product.owned_by"
                >
                  <a-radio
                    v-for="option in $constants.assetInventoryOwnedBy"
                    :value="option.value"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="product.owned_by == 1"
                label="Company"
                :name="['products', index, 'company_id']"
                :rules="{
                  required: true,
                  message: 'This field is required',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="product.company_id"
                  placeholder="Select company"
                  optionFilterProp="label"
                  allowClear
                  showSearch
                >
                  <a-select-option
                    v-for="option in companies"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                v-if="product.owned_by == 2"
                label="Hospital"
                :name="['products', index, 'hospital_id']"
                :rules="{
                  required: true,
                  message: 'This field is required',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="product.hospital_id"
                  placeholder="Select hospital"
                  optionFilterProp="label"
                  allowClear
                  showSearch
                >
                  <a-select-option
                    v-for="option in hospitals"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <template
              v-if="selectedProduct && selectedProduct.is_required_maintenance"
            >
              <a-col :span="8">
                <a-form-item
                  label="Frequency of Preventative Maintenance"
                  :name="['products', index, 'frequency_of_preventive_measure']"
                  :rules="{
                    required: true,
                    message: 'This field is required',
                    type: 'number',
                  }"
                >
                  <a-select
                    v-model:value="product.frequency_of_preventive_measure"
                    placeholder="Select any one"
                    optionFilterProp="label"
                    allowClear
                    showSearch
                  >
                    <a-select-option
                      v-for="option in $constants.frequencyOfPreventiveMeasurements"
                      :key="option.value"
                      :label="option.text"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item
                  label="Last Preventative Maintenance Date"
                  :name="['products', index, 'last_preventive_measure_date']"
                  :rules="{
                    required: product.frequency_of_preventive_measure != 5,
                    message: 'This field is required',
                    type: 'date',
                  }"
                >
                  <a-date-picker
                    v-model:value="product.last_preventive_measure_date"
                    :disabled-date="$comman.disabledFutureDate"
                    :format="$constants.datepickerFormat"
                    :placeholder="$constants.datePickerPlaceholder"
                  />
                </a-form-item>
              </a-col>
            </template>
          </a-row>
        </template>

        <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
          Submit
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import router from "../../../router";
import { commonService, companyService } from "../../../services";
export default {
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      companies: [],
      hospitals: [],
      assetProducts: [],
      assetManufacturers: [],
      formState: {
        asset_product_id: undefined,
        asset_manufacturer_id: undefined,
        model_no: null,
        products: [
          {
            serial_no: null,
            asset_id_tag: null,
            secondary_id_tag: null,
            description: null,
            owned_by: null,
            company_id: undefined,
            hospital_id: undefined,
            frequency_of_preventive_measure: undefined,
            last_preventive_measure_date: null,
          },
        ],
      },
    };
  },

  async mounted() {
    await this.getCompanies();
    await this.getHospitals();
    await this.getAssetProducts();
    await this.getAssetManufacturers();
    this.cardLoading = false;
  },

  computed: {
    selectedProduct() {
      return this.assetProducts.find(
        (item) => item.id == this.formState.asset_product_id
      );
    },
  },

  methods: {
    getCompanies() {
      return commonService
        .get(this.$constants.getCompaniesUrl)
        .then((res) => {
          if (res.success) {
            this.companies = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          if (res.success) {
            this.hospitals = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getAssetProducts() {
      return commonService
        .get(this.$constants.getAssetProductsUrl)
        .then((res) => {
          if (res.success) {
            this.assetProducts = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    getAssetManufacturers() {
      return commonService
        .get(this.$constants.getAssetManufacturersUrl)
        .then((res) => {
          if (res.success) {
            this.assetManufacturers = res.data;
          }
        })
        .catch((err) => this.$message.error(err));
    },

    addProductRow() {
      this.formState.products.push({
        serial_no: null,
        asset_id_tag: null,
        secondary_id_tag: null,
        description: null,
        owned_by: null,
        company_id: undefined,
        hospital_id: undefined,
        frequency_of_preventive_measure: undefined,
        last_preventive_measure_date: null,
      });
    },

    removeProductRow(index) {
      this.formState.products = this.formState.products.filter(
        (_, i) => index != i
      );
    },

    scrollToFirstError(actions) {
      actions.forEach(({ el, top, left }) => {
        if (el.scrollTop > top) {
          el.scroll({ top: top - 64, behavior: "smooth" });
        } else {
          el.scroll({ top: top + 64, behavior: "smooth" });
        }
      });
    },

    onSubmit() {
      let serialNo = this.formState.products
        .map((item) => item.serial_no)
        .filter((value, index, self) => self.indexOf(value) !== index);

      if (serialNo.length > 0) {
        this.$message.error(
          `This serial no (${serialNo.join(", ")}) are entered more then once.`
        );
        return;
      }

      let products = [];
      this.formState.products.forEach((item) => {
        let product = JSON.parse(JSON.stringify(item));
        if (
          this.selectedProduct &&
          this.selectedProduct.is_required_maintenance
        ) {
          product.last_preventive_measure_date =
            product.last_preventive_measure_date
              ? this.$customDate.ymd(product.last_preventive_measure_date)
              : null;
        } else {
          product.frequency_of_preventive_measure = null;
          product.last_preventive_measure_date = null;
        }
        products.push(product);
      });

      this.submitBtnLoader = true;
      companyService
        .store(this.$constants.assetInventoryBulkStoreUrl, {
          ...this.formState,
          products,
        })
        .then((res) => {
          this.$message.success(res.message);
          router.push({ name: "asset.inventory" });
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.submitBtnLoader = false;
        });
    },
  },
};
</script>
