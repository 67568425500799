<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12">
      <a-form-item
        label="Cytotoxic Drug Type & Dose"
        name="cytotoxic_drug_type_and_dose"
      >
        <a-input
          v-model:value="model.cytotoxic_drug_type_and_dose"
          placeholder="Enter cytotoxic drug type & dose here"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Prime Volume"
        name="prime_type_and_amount"
        :rules="{
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number type="number"
          v-model:value="model.prime_type_and_amount"
          placeholder="mLs"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Average Inflow Temperature"
        name="average_inflow_temperature"
        :rules="{
          message: 'This field is required!',
          type: 'number',
          validator: $validation.decimal,
        }"
      >
        <a-input-number type="number"
          v-model:value="model.average_inflow_temperature"
          placeholder="°C"
          :min="0"
          :step="0.01"
        >
        </a-input-number>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Average outflow Temperature"
        name="average_outflow_temperature"
        :rules="{
          message: 'This field is required!',
          type: 'number',
          validator: $validation.decimal,
        }"
      >
        <a-input-number type="number"
          v-model:value="model.average_outflow_temperature"
          placeholder="°C"
          :min="0"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Pump Flow Rate (L)"
        name="pump_flow_rate"
        :rules="{
          message: 'This field is required!',
          type: 'number',
          validator: $validation.decimal,
        }"
      >
        <a-input-number type="number"
          v-model:value="model.pump_flow_rate"
          placeholder="Enter pump flow rate here"
          :min="0"
          :max="9.99"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Average Reservoir Volume"
        name="average_reservoir_volume"
        :rules="{
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number type="number"
          v-model:value="model.average_reservoir_volume"
          placeholder="mLs"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24">
      <a-form-item label="Additional Comments" name="additional_comments">
        <a-textarea
          v-model:value="model.additional_comments"
          placeholder="Enter any additional comments here"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "case-report-step-7",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
