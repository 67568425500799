<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #title>
      <a-row type="flex" :gutter="[16, 0]">
        <a-col flex="auto">
          <a-select
            v-model:value="extraFilters.case_service_ids"
            :options="caseServices"
            mode="multiple"
            allowClear
            placeholder="Search using case service type"
            optionFilterProp="label"
          />
        </a-col>
        <a-col flex="250px">
          <a-range-picker
            v-model:value="extraFilters.date_range"
            :format="$constants.datepickerFormatmat"
            :placeholder="[
              $constants.datePickerPlaceholder,
              $constants.datePickerPlaceholder,
            ]"
          />
        </a-col>
        <a-col flex="100px">
          <a-space>
            <a-button type="primary" @click="refresh"> Search </a-button>
            <a-button type="ghost" @click="resetFilters"> Reset </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>

    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>

      <template #date="{ record, text }">
        {{ $customDate.mdy(text) }}
        <a-tooltip v-if="record.quickbooks_invoice_id" placement="top">
          <template #title>
            Invoice No:
            {{ JSON.parse(record.quickbooks_invoice_info).DocNumber }}
          </template>
          <a-button size="small" type="link">
            <i class="ti ti-info-circle ti-lg"> </i>
          </a-button>
        </a-tooltip>
      </template>

      <template #services="{ record }">
        {{ getServiceCategory(record) }}
      </template>

      <template #action="{ record }">
        <a-space :size="1">
          <router-link
            :to="{
              name: 'admin.show-case-report',
              params: { uuid: record.uuid },
            }"
          >
            <a-tooltip title="View">
              <a-button
                type="link"
                size="small"
                @click="
                  $comman.caseReportLogStore({
                    id: record.id,
                    action: 'View Record',
                  })
                "
              >
                <i class="ti ti-eye ti-lg"></i>
              </a-button>
            </a-tooltip>
          </router-link>

          <router-link
            :to="{
              name: 'admin.create-case-report',
              query: { uuid: record.uuid },
            }"
          >
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                @click="
                  $comman.caseReportLogStore({
                    id: record.id,
                    action: 'Edit Record',
                  })
                "
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>
          </router-link>

          <template v-if="record.status == 2">
            <a-popconfirm
              title="Are you sure you want to approve this case report?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="
                () => {
                  $comman.caseReportLogStore({
                    id: record.id,
                    action: 'Approve Record',
                  });
                  handleStatusChangeEvent({ id: record.id, status: '3' });
                }
              "
            >
              <a-tooltip title="Approve Case Report">
                <a-button size="small" type="link">
                  <i class="ti ti-status-change ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>

          <template
            v-if="[3, 4, 5].includes(record.status) && record.hospital?.ftp"
          >
            <a-tooltip title="Upload PDF On FTP Server" placement="left">
              <a-button
                type="link"
                size="small"
                :class="`${
                  record.case_report_ftp_success_logs_count > 0
                    ? 'primary-color'
                    : 'danger-color'
                }`"
                class="text-bold"
                @click="uploadPDFOnServer(record)"
              >
                <i class="ti ti-upload ti-lg"></i>
              </a-button>
            </a-tooltip>
          </template>

          <a-dropdown placement="bottomLeft">
            <a-button size="small" type="link">
              <i class="ti ti-dots-vertical ti-lg"></i>
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-if="record.status != 1">
                  <a-popconfirm
                    v-if="record.is_flagged == 0"
                    title="Are you sure you want to flag this case report?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="flagCaseReport(record)"
                  >
                    <a-tooltip title="Mark as Flagged" placement="left">
                      <a-button type="link" size="small">
                        <i class="ti ti-flag ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>

                  <a-popconfirm
                    v-else
                    title="Are you sure you want to unflag this case report?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="unflagCaseReport(record)"
                  >
                    <a-tooltip title="Mark as Unflagged" placement="left">
                      <a-button type="link" size="small">
                        <i class="ti ti-flag-off ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </a-menu-item>

                <a-menu-item v-if="record.status == 2">
                  <a-popconfirm
                    title="Are you sure you want to move this case report to pending?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="
                      () => {
                        $comman.caseReportLogStore({
                          id: record.id,
                          action: 'Revert Back to Pending Record',
                        });
                        handleStatusChangeEvent({
                          id: record.id,
                          status: '1',
                        });
                      }
                    "
                  >
                    <a-tooltip title="Revert Back to Pending" placement="left">
                      <a-button size="small" type="link">
                        <i class="ti ti-arrow-back ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </a-menu-item>

                <a-menu-item v-if="showLiveChartingButton(record)">
                  <router-link
                    :to="{
                      name: 'admin.case-report-live-charting',
                      params: { uuid: record.uuid },
                    }"
                  >
                    <a-tooltip title="Live Charting">
                      <a-button type="link" size="small">
                        <i class="ti ti-device-heart-monitor ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </router-link>
                </a-menu-item>

                <template v-if="![3, 5].includes(record.status)">
                  <a-menu-item>
                    <a-popconfirm
                      title="Are you sure you want to delete this case report?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="
                        () => {
                          $comman.caseReportLogStore({
                            id: record.id,
                            action: 'Delete Record',
                          });
                          handleStatusChangeEvent({
                            id: record.id,
                            status: '0',
                          });
                        }
                      "
                    >
                      <a-tooltip title="Delete" placement="left">
                        <a-button size="small" type="link" danger>
                          <i class="ti ti-trash ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-popconfirm>
                  </a-menu-item>
                </template>

                <template v-if="record.status != 1">
                  <a-menu-item>
                    <a-tooltip title="Download PDF" placement="left">
                      <a-button
                        type="link"
                        size="small"
                        @click="downloadPdf(record)"
                      >
                        <i class="ti ti-download ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>

                  <a-menu-item>
                    <a-tooltip title="Download Excel" placement="left">
                      <a-button
                        type="link"
                        size="small"
                        @click="downloadExcel(record)"
                      >
                        <i class="ti ti-file-spreadsheet ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>

                  <a-menu-item>
                    <a-tooltip title="Print" placement="left">
                      <a-button
                        type="link"
                        size="small"
                        @click="renderPdf(record)"
                      >
                        <i class="ti ti-printer ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>

                  <a-menu-item>
                    <a-tooltip title="Billing PDF" placement="left">
                      <a-button
                        type="link"
                        size="small"
                        @click="downloadBillingPdf(record)"
                      >
                        <i class="ti ti-file-dollar ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>
                </template>

                <a-menu-item v-if="record.quickbooks_invoice_id">
                  <a-tooltip title="Send Email" placement="left">
                    <a-button
                      type="link"
                      size="small"
                      @click="sendEmail(record.id)"
                    >
                      <i class="ti ti-mail-forward ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </a-menu-item>

                <a-menu-item v-if="record.status == 1">
                  <a-tooltip title="Send Reminder" placement="left">
                    <a-button
                      type="link"
                      size="small"
                      @click="sendReminder(record.id)"
                    >
                      <i class="ti ti-mail-fast ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </a-menu-item>

                <a-menu-item v-if="record.mail_logs.length > 0">
                  <a-tooltip title="Mail Logs">
                    <a-button
                      type="link"
                      size="small"
                      @click="showOfferMailLog(record)"
                    >
                      <i class="ti ti-history ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.caseReportDatatableForAdminUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      modifyUrlQuery: false,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            customRender: "date",
          },
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Surgeon",
          dataIndex: "surgeon",
          key: "surgeon",
          customRender: ({ record }) => {
            let personnel = record.data.personnel;
            return personnel.find((e) => e.type == "Surgeon")?.name ?? "-";
          },
        },
        // {
        //   title: "Services",
        //   dataIndex: "services",
        //   key: "services",
        //   slots: {
        //     customRender: "services",
        //   },
        // },
        {
          title: "Service Category",
          dataIndex: "service_category",
          key: "service_category",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record.data,
              "service_category",
              this.$constants.serviceCategories
            ),
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Service + Products = Total",
          dataIndex: "total_price",
          key: "total_price",
          customRender: ({ record }) =>
            `${this.$comman.withCurrency(
              record.case_service_price
            )} + ${this.$comman.withCurrency(
              record.product_price
            )} = ${this.$comman.withCurrency(record.total_price)}`,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.caseReportStatusesForAdmin.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      extraFilters: {
        hospital_id: parseInt(this.$route.params.id),
      },

      caseServices: [],
    };
  },

  async mounted() {
    await this.fetchCaseServices();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    refreshDatatable() {
      this.$refs.datatable.refresh();
    },

    resetFilters() {
      this.extraFilters.case_service_ids = [];
      this.refresh();
    },

    fetchCaseServices() {
      return commonService
        .get(this.$constants.getCaseServicesUrl)
        .then((res) => {
          this.caseServices = res.data.map((item) => ({
            text: item.name,
            label: item.name,
            value: item.id,
          }));
        });
    },

    getServiceCategory(rec) {
      let services = Object.values(rec.data.services);
      let caseServices = this.caseServices
        .filter((e) => services.includes(e.value))
        .map((e) => e.text);
      return caseServices.join(", ");
    },

    uploadPDFOnServer(report) {
      if (report.hospital.ftp.is_enable == 0) {
        this.$message.error(
          "Please enable FTP/SFTP in order to send case report to this hospital."
        );

        return;
      }

      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportUploadPDFOnServerUrl, {
          id: report.id,
        })
        .then((res) => {
          report.case_report_ftp_success_logs_count += 1;
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    flagCaseReport(order) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportFlagUrl, {
          id: order.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refresh();
        });
    },

    unflagCaseReport(order) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportUnflagUrl, {
          id: order.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refresh();
        });
    },

    showLiveChartingButton(record) {
      if (
        // record.quickbooks_invoice_id != null ||
        // (record.status == 3 && record.total_price == 0) ||
        record.hospital.is_live_charting_enable == 0
      ) {
        return false;
      }
      return this.$constants.caseServicesIdForCPB
        .concat(this.$constants.caseServicesIdForATS)
        .concat(this.$constants.caseServicesIdForHIPEC)
        .concat(this.$constants.caseServicesIdForECMO)
        .concat(this.$constants.caseServicesIdForNRP)
        .filter((value, index, array) => array.indexOf(value) === index)
        .reduce(
          (bool, curr) => bool || record.data.services.includes(curr),
          false
        );
    },

    downloadPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "PDF Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          `${record.report_no}_case_report.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    downloadExcel(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Excel Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportExcelUrl,
          { id: record.id },
          `${record.report_no}_case_report.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    renderPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Render Download",
      });
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    downloadBillingPdf(report) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.caseReportExportBillingPdfUrl,
          { id: report.id },
          `${report.report_no}_billing.pdf`
        )
        .then((res) => {
          this.$message.success("Billing PDF downloaded successfully.");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    sendEmail(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.sendInvoiceOnQuickbooksUrl, { id })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    sendReminder(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportSendReminderMailUrl, { id })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    showOfferMailLog(record) {
      this.open({
        title: "Mail Logs",
        path: "admin.newCaseReport.mail_logs",
        callback: this.refresh,
        record,
      });
    },
  },
};
</script>
