<template>
  <!--  <div class="status-list">-->
  <!--    <a-tooltip-->
  <!--      v-for="status in Object.keys(itemsStatusCount)"-->
  <!--      :key="status"-->
  <!--      :title="tooltipTextFormat(+status)"-->
  <!--    >-->
  <!--      <div-->
  <!--        :style="{-->
  <!--          backgroundColor: getStatusData(+status).color,-->
  <!--          minWidth: `${calculateStatusPercentages(+status)}%`,-->
  <!--        }"-->
  <!--        class="status-item"-->
  <!--      />-->
  <!--    </a-tooltip>-->
  <!--  </div>-->
  <a-progress
    :percent="+calculateStatusPercentages(CustomerInvoiceStatus.Done)"
    style="width: 200px; padding: 0 12px"
    strokeColor="#04B96F"
    :success="{
      percent: +calculateStatusPercentages(CustomerInvoiceStatus.Done),
      strokeColor: '#04B96F',
    }"
  />
</template>

<script setup>
import { commonService } from "@/services";
import { constants } from "@/helper/constants";
import { computed } from "vue";

const props = defineProps({
  record: Object,
});

const CustomerInvoiceStatus = {
  NotStarted: 0,
  WorkingOnIt: 1,
  Stuck: 2,
  Done: 3,
  // NoActivity: 4,
  NoBilling: 4,
};

const statuses = [
  {
    label: "Done",
    value: CustomerInvoiceStatus.Done,
    color: "#04B96F",
  },
  {
    label: "Working on it",
    value: CustomerInvoiceStatus.WorkingOnIt,
    color: "#E9A03D",
  },
  {
    label: "Stuck",
    value: CustomerInvoiceStatus.Stuck,
    color: "#CD2F48",
  },
  {
    label: "Not Started",
    value: CustomerInvoiceStatus.NotStarted,
    color: "#B4B5B6",
  },
  // {
  //   label: "No Activity",
  //   value: CustomerInvoiceStatus.NoActivity,
  //   color: "#0476A8",
  // },
];

function getStatusData(status) {
  return statuses.find((s) => s.value === status);
}

const itemsStatusCount = computed(() => {
  return props.record.items.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = 0;
    }

    acc[item.status] += 1;

    return acc;
  }, {});
});

const calculateStatusPercentages = () => {
  const statuses = [
    CustomerInvoiceStatus.Done,
    CustomerInvoiceStatus.NoBilling,
  ]; // if status either counts as done

  const total = props.record.items.length;
  const count = statuses.reduce((acc, status) => {
    acc += itemsStatusCount.value[status] || 0;
    return acc;
  }, 0);

  return ((count / total) * 100).toFixed(2);
};

const tooltipTextFormat = (status) => {
  const statusData = getStatusData(status);
  const statusCount = itemsStatusCount.value[status] || 0;
  const total = props.record.items.length;
  const percentage = ((statusCount / total) * 100).toFixed(1);

  return `${statusData.label}(${statusCount}/${total})   ${percentage}%`;
};

const activeStatus = computed(() =>
  statuses.find((status) => status.value === 2)
);

const style = computed(() => {
  const status = activeStatus.value;
  return {
    backgroundColor: status.color,
  };
});
</script>

<style scoped lang="less">
.status-list {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4px 8px;

  div {
    content: " ";
  }
}

.status-item {
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    transform: scaleY(1.1);
  }
}
</style>
