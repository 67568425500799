<template>
  <a-descriptions
    :bordered="true"
    :column="{ md: 2, sm: 1, xs: 1 }"
    size="small"
    :labelStyle="{ width: '140px' }"
  >
    <a-descriptions-item label="Employee Name">
      {{ record.employee?.full_name }}
    </a-descriptions-item>

    <a-descriptions-item label="Type">
      {{ record.type }}
    </a-descriptions-item>

    <a-descriptions-item label="Start Date">
      {{ $customDate.mdy(record.start_date) }}
    </a-descriptions-item>

    <a-descriptions-item label="End Date">
      {{ $customDate.mdy(record.end_date) }}
    </a-descriptions-item>

    <a-descriptions-item label="Start Time">
      {{ record.start_time }}
    </a-descriptions-item>

    <a-descriptions-item label="Hours Per Day">
      {{ record.hours_per_day }}
    </a-descriptions-item>

    <a-descriptions-item label="Hours" :span="2">
      {{ record.hours ? record.hours : "-" }}
    </a-descriptions-item>

    <a-descriptions-item label="Comments" :span="2">
      {{ record.comments ?? "N/A" }}
    </a-descriptions-item>
  </a-descriptions>

  <template v-if="record.status != 1">
    <a-divider orientation="left">Status</a-divider>

    <template v-if="[2, 3].includes(record.status)">
      <a-descriptions :column="2">
        <a-descriptions-item :label="`${statusLabel} By`" :span="1">
          {{ record.approver?.full_name ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item :label="`${statusLabel} At`" :span="1">
          {{ $customDate.mdy(record.approved_at) }}
        </a-descriptions-item>
      </a-descriptions>
    </template>

    <template v-if="record.status == 4">
      <a-descriptions :column="2">
        <a-descriptions-item label="Status" :span="1">
          Pending for Cancellation
        </a-descriptions-item>
      </a-descriptions>
    </template>

    <template v-if="record.status == 5">
      <a-descriptions :column="2">
        <a-descriptions-item label="Cancelled By" :span="1">
          {{ record.canceller?.full_name ?? "-" }}
        </a-descriptions-item>

        <a-descriptions-item label="Cancelled At" :span="1">
          {{ $customDate.mdy(record.cancelled_at) }}
        </a-descriptions-item>
      </a-descriptions>
    </template>
  </template>

  <template v-if="record.status == 1">
    <template
      v-if="
        ['admin.paid-time-off', 'manager.paid-time-off'].includes($route.name)
      "
    >
      <a-divider></a-divider>
      <a-form :model="model" layout="vertical" @finish="onSubmit">
        <a-form-item
          ref="status"
          label="Status"
          name="status"
          :rules="{
            required: true,
            message: 'This field is required.',
          }"
        >
          <a-select
            v-model:value="model.status"
            placeholder="Select One"
            style="width: 100%"
          >
            <a-select-option :value="2"> Approve </a-select-option>
            <a-select-option :value="3"> Reject </a-select-option>
          </a-select>
        </a-form-item>

        <side-drawer-buttons name="Submit" />
      </a-form>
    </template>
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../services";

export default {
  emits: ["callback"],

  data() {
    return {
      model: {
        status: undefined,
      },
    };
  },

  mounted() {},

  computed: {
    ...mapGetters("drawer", ["record"]),

    statusLabel() {
      return this.record.status == 2 ? "Approved" : "Rejected";
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      this.loadingStart();

      let url = this.$constants.managerPaidTimeOffStatusChangeUrl;
      if (this.$route.name == "admin.paid-time-off") {
        url = this.$constants.adminPaidTimeOffStatusChangeUrl;
      }

      commonService
        .store(url, {
          id: this.record.id,
          status: this.model.status,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
  },
};
</script>
