<template>
  <div>
    <a-descriptions
      :column="2"
      size="small"
      bordered
      :labelStyle="{ width: '18%' }"
      :contentStyle="{ width: '32%' }"
    >
      <a-descriptions-item :span="2" label="Template Name">
        {{ formState.name }}
      </a-descriptions-item>
    </a-descriptions>

    <a-table class="mt-sm-1" :dataSource="formState.hospitals" :columns="columns" :pagination="false" size="small" :rowKey="(rec) => rec.id" />
  </div>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import { commonService } from '../../services';
import formMixins from '../../mixins/formMixins';

export default {
  components: {
    PlusOutlined,
  },
  data () {
    return {
      formState: {
        name: null,
        hospitalTemplates: [{}],
      },
      hospitals: [],
      columns: [
        {
          title: "Hospital",
          dataIndex: "name",
        },
        {
          title: "State",
          dataIndex: "state",
        },
      ],
    };
  },

  mixins: [formMixins],

  mounted () {
    this.fetchData();
  },

  computed: {
    ...mapGetters('drawer', ['record']),
  },

  methods: {
    ...mapActions('drawer', ['loadingStart', 'loadingStop', 'close']),

    async fetchData () {
      this.cardLoading = true;

      const data = await this.$comman.getHospitalTemplate({ id: this.record.id });
      this.formState = data;
      this.cardLoading = false;
    },
  },
};
</script>
