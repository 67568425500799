<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24">
      <a-divider orientation="left">Personnel</a-divider>
      <a-table
        :columns="personnelColumns"
        :rowKey="(record, index) => index"
        :dataSource="model.personnel"
        :pagination="false"
        size="small"
        class="ant-table-vertical-align-baseline mb-sm-1"
        :scroll="{ x: true, y: 0 }"
      >
        <template #type="{ record, index }">
          <a-form-item
            :name="['personnel', index, 'type']"
            :rules="{
              required: true,
              message: 'This field is required!',
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <a-select
              v-model:value="record.type"
              placeholder="Select one..."
              showSearch
              :options="
                $constants.caseReportPersonnelTypes.map((item) => ({
                  label: item,
                  value: item,
                }))
              "
              @change="record.name = undefined"
            />
          </a-form-item>
        </template>

        <template #name="{ record, index }">
          <a-form-item
            :name="['personnel', index, 'name']"
            :rules="{
              required: true,
              message: 'This field is required!',
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <template v-if="unselectedPersonnels(record).length > 0">
              <a-select
                v-model:value="record.name"
                show-search
                placeholder="Select a personnel name"
                :options="unselectedPersonnels(record)"
                :loading="personnelLoading"
              />
            </template>

            <template v-else>
              <a-input
                v-model:value="record.name"
                placeholder="Enter personnel name here..."
              />
            </template>
          </a-form-item>
        </template>

        <template #action="{ index }">
          <a-button
            v-if="model.personnel.length > 1"
            size="small"
            type="link"
            danger
            @click="removePersonnel(index)"
          >
            <i class="ti ti-trash ti-lg"></i>
          </a-button>

          <a-button
            v-if="model.personnel.length == index + 1"
            size="small"
            type="link"
            @click="addPersonnel()"
          >
            <i class="ti ti-plus ti-lg"></i>
          </a-button>
        </template>
      </a-table>
    </a-col>

    <template
      v-if="
        $comman.nrpServiceSelectedWithOrganHospital(model.services, hospital)
      "
    >
      <a-col :xs="24" :md="12">
        <a-form-item
          label="Organs Targeted for Recovery"
          name="organs_targeted_for_recovery"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.organs_targeted_for_recovery"
            placeholder="Organs Targeted for Recovery"
            show-search
            optionFilterProp="label"
            :options="$constants.organsTargetedForRecoveryOptions"
          />
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="12">
        <a-form-item
          label="Cannulation Strategy"
          name="cannulation_stratege"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-select
            v-model:value="model.cannulation_stratege"
            placeholder="Service Category"
            show-search
            optionFilterProp="label"
            :options="$constants.cannulationStrategyOptions"
          />
        </a-form-item>
      </a-col>
    </template>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Service Category"
        name="service_category"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-select
          v-model:value="model.service_category"
          placeholder="Service Category"
          show-search
          optionFilterProp="label"
        >
          <a-select-option
            v-for="option in serviceCategories"
            :key="option.value"
            :label="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="model.case_status == 2 ? 6 : 12">
      <a-form-item
        v-if="!$comman.isCanReceiveOrgansHospital(hospital)"
        label="Status"
        name="case_status"
        :rules="[
          {
            required: true,
            type: 'integer',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-select
          v-model:value="model.case_status"
          placeholder="Service case status"
          show-search
          optionFilterProp="label"
        >
          <a-select-option
            v-for="option in $constants.caseStatuses"
            :key="option.value"
            :label="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <template v-if="model.case_status == 2">
      <a-col :xs="24" :md="6">
        <a-form-item
          name="emergent_response_time"
          label="Emergent Response Time (Minutes)"
          ref="emergent_response_time"
          :rules="{
            required: true,
            message: 'Emergent response time must be greater then 0!',
            validator: emergentResponseTimeValidator,
          }"
        >
          <a-input
            v-model:value="model.emergent_response_time"
            placeholder="total time"
            :disabled="true"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Emergent Request Date"
          name="emergent_request_date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.emergent_request_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calcullateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Emergent Request Time"
          name="emergent_request_time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.emergent_request_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calcullateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Arrival Date into Facility"
          name="arrival_date_into_facility"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.arrival_date_into_facility"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calcullateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Arrival Time into Facility"
          name="arrival_time_into_facility"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.arrival_time_into_facility"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calcullateNrpTotalTime"
          />
        </a-form-item>
      </a-col>
    </template>

    <a-col v-if="model.hospital_id" :xs="24" :md="12">
      <a-form-item
        label="Services"
        name="services"
        :rules="[
          {
            required: true,
            type: 'array',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-checkbox-group v-model:value="model.services" style="width: 100%">
          <template
            v-if="
              caseServices.filter(
                (e) => e.value != this.$constants.disposableOnlyCase
              ).length > 0
            "
          >
            <a-row :gutter="[20, 0]">
              <template
                v-for="option in caseServices.filter(
                  (e) => e.value != this.$constants.disposableOnlyCase
                )"
                :key="option.value"
              >
                <a-col :span="12">
                  <a-checkbox :value="option.value">
                    {{ option.text }}
                  </a-checkbox>
                </a-col>
              </template>
            </a-row>
          </template>

          <template v-else>
            <a-typography-text type="danger">
              No services assigned to selected facility.
            </a-typography-text>
          </template>
        </a-checkbox-group>
      </a-form-item>
    </a-col>

    <a-col v-if="model.service_category != 1" :xs="24" :md="12">
      <a-form-item
        label="Procedure"
        name="procedure"
        :rules="{
          required: true,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
          transform: (value) => (value ? value.trim() : ''),
        }"
      >
        <a-input v-model:value="model.procedure" placeholder="Procedure" />
      </a-form-item>
    </a-col>
  </a-row>

  <a-alert
    v-if="model.service_category == 1"
    message="Calculated CPB Flows"
    type="info"
  >
    <template #description>{{ calculatedCPBFlows }}</template>
  </a-alert>

  <template v-if="isCatiInvoiceAmountShow">
    <a-divider orientation="left" class="mt-sm-0">
      Carolina Autotransfusion Information
    </a-divider>

    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          :label="`CATI Invoice Amount (${$constants.currencySymbol})`"
          name="cati_invoice_amount"
          :rules="[
            {
              required: true,
              message: 'This field is required!',
            },
            {
              message: 'This field is required!',
              type: 'number',
              validator: $validation.decimal,
              trigger: $constants.ruleTrigger,
            },
          ]"
        >
          <a-input-number
            type="number"
            v-model:value="model.cati_invoice_amount"
            placeholder="Enter Amount"
            :min="0"
            :step="0.01"
          />
        </a-form-item>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item label="Purchase Order Number" name="purchase_order_number">
          <a-input
            v-model:value="model.purchase_order_number"
            placeholder="PO Number"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </template>
</template>

<script>
import { Modal } from "ant-design-vue";
import moment from "moment";
import { commonService } from "../../../services";

export default {
  name: "case-report-step-2",
  inheritAttrs: false,
  props: [
    "step",
    "model",
    "caseServices",
    "isCatiInvoiceAmountShow",
    "hospital",
  ],
  emits: ["validate"],

  data() {
    return {
      personnelLoading: true,
      personnels: [],
      personnelColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          slots: {
            customRender: "type",
          },
          width: "400px",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          slots: {
            customRender: "name",
          },
          width: "400px",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  computed: {
    calculatedCPBFlows() {
      return [1.6, 1.8, 2.0, 2.2, 2.4]
        .reduce((pre, curr) => {
          pre.push(
            `${curr} = ${parseFloat(
              curr * this.model.body_surface_area
            ).toFixed(2)} LPM`
          );
          return pre;
        }, [])
        .join(" | ");
    },

    serviceCategories() {
      return this.$constants.serviceCategories.filter((e) =>
        this.$comman.isCanReceiveOrgansHospital(this.hospital)
          ? e.value > 5
          : e.value < 6
      );
    },
  },

  mounted() {
    commonService
      .get(this.$constants.getHospitalPersonnelsUrl, {
        hospital_id: this.model.hospital_id,
      })
      .then((res) => {
        this.personnels = res.data.map((e) => ({
          ...e,
          value: e.name,
          label: e.name,
        }));
      })
      .finally(() => {
        this.personnelLoading = false;
      });
  },

  methods: {
    back() {
      this.$emit("back");
    },

    saveAndNext() {
      this.$emit("next");
    },

    unselectedPersonnels(item) {
      let selectedPersonnels = this.model.personnel
        .filter((e) => e.type == item.type)
        .map((e) => e.name);
      return this.personnels.filter(
        (e) =>
          (!selectedPersonnels.includes(e.name) || item.name == e.name) &&
          e.type == item.type
      );
    },

    addPersonnel() {
      this.model.personnel.push({
        type: undefined,
        name: null,
      });
    },

    removePersonnel(index) {
      this.model.personnel = this.model.personnel.filter((_, i) => index != i);
    },

    convertToMoment(date, time) {
      return moment(date + " " + time, "YYYY-MM-DD HH:mm:ss", true);
    },

    calcullateNrpTotalTime() {
      if (!this.isUsedLiveCharting) {
        if (
          this.model.emergent_request_date &&
          this.model.emergent_request_time &&
          this.model.emergent_request_time.length == 8 &&
          this.model.arrival_date_into_facility &&
          this.model.arrival_time_into_facility &&
          this.model.arrival_time_into_facility.length == 8
        ) {
          let initiation = this.convertToMoment(
            this.$customDate.ymd(this.model.emergent_request_date),
            this.model.emergent_request_time
          );
          let end = this.convertToMoment(
            this.$customDate.ymd(this.model.arrival_date_into_facility),
            this.model.arrival_time_into_facility
          );
          this.model.emergent_response_time = Math.round(
            moment.duration(end.diff(initiation)).asMinutes()
          );
          this.$parent.$parent.$parent.validateFields("emergent_response_time");
          if (this.model.emergent_response_time > 30) {
            Modal.warning({
              title: () => "Attention",
              content: () =>
                "The standard on-call response time for most facilities is 30 minutes. You are logging a response time that exceeds this allotted timeframe. Please review and correct your entry if necessary.",
            });
          }
        }
      }
    },

    emergentResponseTimeValidator(rule, value) {
      if (value < 0) {
        return Promise.reject(rule.message);
      }
      return Promise.resolve();
    },
  },
};
</script>
