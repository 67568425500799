import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/orders",
    name: "order",
    component: route("order/index"),
    meta: {
      title: "Orders",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/order/add-products-into-cart",
    name: "add-products-into-cart",
    component: route("order/products"),
    meta: {
      title: "Add product into cart",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/order/add-services-into-cart",
    name: "add-services-into-cart",
    component: route("order/services"),
    meta: {
      title: "Add services into cart",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/order/show/:order_no",
    name: "view-order",
    component: route("order/view"),
    meta: {
      title: "View Order",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/order/checkout",
    name: "checkout-order",
    component: route("order/checkout"),
    meta: {
      title: "Checkout",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: '/order/create',
    name: 'create-order',
    component: route("order/create"),
    meta: {
      title: "Create Order",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: '/order/edit/:order_no',
    name: 'edit-order',
    component: route("order/create"),
    meta: {
      title: "Edit Order",
      layout: "app",
      middleware: [auth, profile],
    },
  },

  {
    path: "/dispatched-orders",
    name: "dispatched-orders",
    component: route("dispatchedOrder/index"),
    meta: {
      title: "Index",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
