<template>
  <a-layout-content>
    <page-header>
      <template #title> Announcements </template>

      <template #buttons>
        <a-button type="primary" @click="handleCreateNewAnnouncementEvent">
          <a-space> <i class="ti ti-plus ti-lg"></i> Add Announcement </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #image="{ record }">
          <img :width="200" :src="record.full_image_path" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <template v-if="record.status == 1">
              <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                class="pl-sm-0"
                @click="handleEditAnnouncementEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

              <a-popconfirm
                title="Are you sure you want to delete this announcement?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '0' })
                "
              >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-else>
              <a-popconfirm
                title="Are you sure you want to restore this announcement?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '1' })
                "
              >
              <a-tooltip title="Restore">
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
              </a-popconfirm>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.announcementDatatableUrl,
      statusChangeUrl: this.$constants.announcementChangeStatusUrl,
      columns: [
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          ellipsis: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          ellipsis: true,
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.description
            ? [this.$route.query?.description]
            : undefined,
        },
        {
          title: "URL",
          dataIndex: "url",
          key: "url",
          sorter: true,
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.url
            ? [this.$route.query?.url]
            : undefined,
        },
        {
          title: "Image",
          dataIndex: "image",
          key: "image",
          slots: {
            customRender: "image",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 100,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        title: this.$route.query?.title
          ? [this.$route.query?.title]
          : undefined,
        sortField: this.$route.query?.sortField ?? "created_at",
        sortOrder: this.$route.query?.sortOrder ?? "descend",
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleEditAnnouncementEvent(record) {
      this.open({
        title: "Edit Announcement",
        path: "admin.announcement.store",
        callback: this.refresh,
        record: record,
      });
    },

    handleCreateNewAnnouncementEvent() {
      this.open({
        title: "Add Announcement",
        path: "admin.announcement.store",
        callback: this.refresh,
      });
    },
  },
};
</script>
