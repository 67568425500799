<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Expenses </template>

        <template #buttons>
          <!--  -->
        </template>
      </page-header>

      <a-card
        :tabList="tabList"
        :activeTabKey="activeTabKey"
        @tabChange="(key) => (activeTabKey = key)"
        size="small"
      >
        <template #customRender="item">
          {{ item.text }} ({{ item.counts }})
        </template>

        <div class="mb-sm-1" v-if="activeTabKey == 2 && showSettingError">
          <a-alert type="warning">
            <template #message>
              To sync bills on QuickBooks, Vendor account has not been set.
              Please go to Admin > Settings > QuickBooks
            </template>
          </a-alert>
        </div>

        <a-row class="mb-sm-1" justify="space-between">
          <div>
            <div v-if="activeTabKey == 1" style="display: flex">
              <a-select
                v-model:value="bulkActionValue"
                placeholder="Select any one"
                style="width: 150px; margin-right: 20px"
              >
                <template v-if="activeTabKey == 1">
                  <a-select-option value="2"> Approve </a-select-option>
                  <a-select-option value="3"> Reject </a-select-option>
                </template>
              </a-select>

              <a-button
                html-type="button"
                type="primary"
                :disabled="
                  this.bulkActionValue == null ||
                  this.selectedRowKeys.length == 0
                "
                @click="bulkAction"
              >
                Submit
              </a-button>
            </div>

            <a-space v-if="activeTabKey == 2">
              <a-button
                size="small"
                type="primary"
                ghost
                htmlType="button"
                :disabled="showSettingError"
                @click="uploadExpenseOnQuickbooks"
                :loading="uploadExpenseOnQuickbooksLoading"
              >
                <a-space>
                  <i class="ti ti-refresh ti-1x"></i>
                  Upload expenses on QuickBooks
                </a-space>
              </a-button>

              <a-button
                size="small"
                type="primary"
                ghost
                htmlType="button"
                :disabled="showSettingError"
                @click="updateExpenseFromQuickbooks"
                :loading="updateExpenseFromQuickbooksLoading"
              >
                <a-space>
                  <i class="ti ti-refresh ti-1x"></i>
                  Update expenses from QuickBooks
                </a-space>
              </a-button>
            </a-space>
          </div>

          <div>
            <a-tooltip>
              <template #title>Export PDF</template>
              <a-button
                type="link"
                @click="handleExportEvent('pdf')"
                style="color: #f40f02"
                :loading="datatableLoading"
              >
                <template #icon>
                  <i class="far fa-file-pdf" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>Export Excel</template>
              <a-button
                type="link"
                @click="handleExportEvent('excel')"
                style="color: #1d6f42"
                :loading="datatableLoading"
              >
                <template #icon>
                  <i class="far fa-file-excel" style="font-size: 18px"></i>
                </template>
              </a-button>
            </a-tooltip>
          </div>
        </a-row>

        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="datatableLoading"
          @change="handleTableChange"
          @refresh="refresh"
          size="small"
          :scroll="scroll"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #dateRangeDropdown="filterDropdown">
            <xFilterDateRangeDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #amount="{ record }">
            {{ $comman.withCurrency(record.amount) }}
            <template v-if="record.quickbooks_bill_id">
              (Bill Id: {{ record.quickbooks_bill_id }})
            </template>
          </template>

          <template #action="{ record }">
            <a-button
              v-if="$comman.access('view-expense')"
              type="link"
              size="small"
              class="pl-sm-0 primary-color"
              @click="handleShowExpenseEvent(record)"
            >
              <i class="ti ti-eye ti-lg"></i>
            </a-button>

            <template v-if="record.status == 1">
              <router-link
                :to="{
                  name: 'admin-update-expense',
                  params: { id: record.id },
                }"
              >
                <a-button
                  v-if="$comman.access('update-expense')"
                  type="link"
                  size="small"
                >
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </router-link>
            </template>
          </template>
        </a-table>
      </a-card>
    </a-layout-content>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminExpenseDatatableUrl,
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_full_name",
          key: "employee_full_name",
          customRender: ({ record }) => record.employee?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense Type",
          dataIndex: "expense_type_name",
          key: "expense_type_name",
          customRender: ({ record }) => record.expense_type?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          slots: {
            customRender: "amount",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
          slots: {
            filterDropdown: "dateRangeDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.expenseStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
      },

      selectedRowKeys: [],
      bulkActionValue: null,
      bulkActionUrl: this.$constants.adminExpenseBulkActionsUrl,
      pdfExportUrl: this.$constants.adminExpenseExportPdfUrl,
      excelExportUrl: this.$constants.adminExpenseExportExcelUrl,

      uploadExpenseOnQuickbooksLoading: false,
      updateExpenseFromQuickbooksLoading: false,
      settings: [],
    };
  },

  mounted() {
    this.fetchSetting();
  },

  computed: {
    rowSelection() {
      return this.activeTabKey == 1
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    showSettingError() {
      return !Object.keys(this.settings).includes("vendor_bill_account");
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleShowExpenseEvent(record) {
      this.open({
        title: "Show Expense Details",
        path: "admin.expense.show",
        callback: this.refresh,
        record: record,
      });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    handleExportEvent(type) {
      let url = this.pdfExportUrl;
      let fileName = "Employee Time Tracking.pdf";
      if (type == "excel") {
        url = this.excelExportUrl;
        fileName = "Employee Time Tracking.xlsx";
      }

      var params = {
        ...this.filterParams,
        status: this.activeTabKey,
      };

      this.datatableLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },

    bulkAction() {
      this.datatableLoading = true;
      commonService
        .store(this.bulkActionUrl, {
          ids: this.selectedRowKeys,
          status: this.bulkActionValue,
        })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    uploadExpenseOnQuickbooks() {
      this.uploadExpenseOnQuickbooksLoading = true;

      commonService
        .store(this.$constants.uploadExpensesOnQuickbooksUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.uploadExpenseOnQuickbooksLoading = false;
          this.refresh();
        });
    },

    updateExpenseFromQuickbooks() {
      this.updateExpenseFromQuickbooksLoading = true;

      commonService
        .store(this.$constants.updateExpensesFromQuickbooksUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.updateExpenseFromQuickbooksLoading = false;
        });
    },

    fetchSetting() {
      this.cardLoading = true;

      commonService
        .store(this.$constants.getSettingUrl)
        .then((res) => {
          if (res.success) {
            this.settings = res.data;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },
  },
};
</script>
