<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          Update Onboarding Employee Details
        </template>
        <template #buttons>
          <back-button />
        </template>
      </page-header>
      <employee-personal-info
        v-if="companies && step == 1"
        :companies="companies"
        :rules="employeeFormRules.piRules"
        :model="piForm"
        @callback="handleCallback"
      />
      <employee-address-info
        v-if="step == 2"
        :rules="employeeFormRules.aiRules"
        :model="aiForm"
        @callback="handleCallback"
      />
      <employee-position-info
        v-if="step == 3"
        :rules="employeeFormRules.poiRules"
        :model="poiForm"
        @callback="handleCallback"
      />
      <employee-employment-info
        v-if="step == 4"
        :rules="employeeFormRules.eiRules"
        :model="eiForm"
        @callback="handleCallback"
      />
      <employee-pay-info
        v-if="step == 5"
        :rules="employeeFormRules.payiRules"
        :model="payiForm"
        @callback="handleCallback"
        @finish="handleFinish"
      />
    </a-layout-content>
  </div>
</template>
<script>
import { employeeFormRules } from "../../helper/formRules.js";
import backButton from "../../components/backButton.vue";
import employeePersonalInfo from "../../components/employee/personal.vue";
import employeeAddressInfo from "../../components/employee/address.vue";
import employeePositionInfo from "../../components/employee/position.vue";
import employeeEmploymentInfo from "../../components/employee/employment.vue";
import employeePayInfo from "../../components/employee/pay.vue";
import { companyService } from "@/services";

export default {
  components: {
    backButton,
    employeePersonalInfo,
    employeeAddressInfo,
    employeePositionInfo,
    employeeEmploymentInfo,
    employeePayInfo,
  },
  data() {
    return {
      // step: 1,
      companies: {},
      routes: {},
      roles: {},
      employeeFormRules,
      piForm: {
        companyId: 1,
        employmentStatus: null,
        insperityHireDate: null,
        clientHireDate: null,
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        dateOfBirth: null,
        gender: null,
        ssn: null,
        cssn: null,
        streetAddress: null,
        city: null,
        state: null,
        zipCode: null,
      },
      aiForm: {
        workOption: null,
        workAddress: "",
        workCity: "",
        workStatus: "",
        workZip: "",
        reportingOption: null,
        reportingAddress: "",
        reportingCity: "",
        reportingStatus: "",
        reportingZip: "",
        deliveryOption: null,
        deliveryAddress: "",
        deliveryCity: "",
        deliveryStatus: "",
        deliveryZip: "",
      },
      poiForm: {
        workersCompensationCode: null,
        jobFunctionID: null,
        jobTitle: "",
        supervisesOthers: "",
        supervisorName: "",
      },
      eiForm: {
        isUnionMember: "",
        employeeAgreementCategory: null,
        ownerStatus: "",
        employeeJobCategory: null,
        workEmail: "",
        workPhone: "",
        willWorkMultiStates: "",
        willWorkInternational: "",
        willTravelInternational: "",
      },
      payiForm: {
        classification: null,
        temporaryIndicator: "",
        isExemptFromOvertime: "",
        isPaidTips: "",
        isPaidPiecework: "",
        employeePaid: [],
        isAllowanceProvided: "",
        adminEmail: "",
        employeeEmail: "",
      },
    };
  },
  computed: {
    step() {
      return this.$route.query.step;
    },
  },
  created() {
    this.getCompanies();
    this.get_routes();
    this.get_roles();
  },
  methods: {
    getCompanies() {
      companyService.store(this.$constants.getCompaniesUrl).then((res) => {
        if (res.success) {
          this.companies = res.data;
        }
      });
    },
    get_routes() {
      companyService.store(this.$constants.getAccessRoutesUrl).then((res) => {
        if (res.success) {
          this.routes = res.data;
        }
      });
    },
    get_roles() {
      companyService.store(this.$constants.getRolesUrl).then((res) => {
        if (res.success) {
          this.roles = res.data;
        }
      });
    },
    handleCallback(arg) {
      this.step += arg;
    },
    handleFinish() {
      console.log(this.piForm);
      console.log(this.aiForm);
      console.log(this.poiForm);
      console.log(this.eiForm);
      console.log(this.payiForm);
    },
  },
};
</script>
