<template>
  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-spin :spinning="loading">
      <a-row :gutter="[20, 0]">
        <a-col :sm="24">
          <a-form-item label="Employee" name="employee_id">
            <a-select
              v-model:value="formState.employee_id"
              placeholder="Select Employee"
              show-search
              optionFilterProp="label"
              :disabled="extra.fromEmployees"
              @change="handleEmployeeChangeEvent"
            >
              <a-select-option
                v-for="employee in employees"
                :key="employee.id"
                :label="employee.full_name"
                :value="employee.id"
              >
                {{ employee.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :sm="12">
          <a-form-item label="Hospital" name="hospital_id">
            <a-select
              v-model:value="formState.hospital_id"
              placeholder="Select Hospital"
              show-search
              optionFilterProp="label"
              :options="employeeHospitals"
              :disabled="!formState.employee_id"
            >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :sm="12">
          <a-form-item label="Date Range" name="date_range">
            <a-range-picker
              v-model:value="formState.date_range"
              :format="$constants.datepickerFormat"
              :disabled="!formState.employee_id"
              :placeholder="[
                $constants.datePickerPlaceholder,
                $constants.datePickerPlaceholder,
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="12">
          <a-form-item label="Assessor Name" name="assessor_name">
            <a-input
              v-model:value="formState.assessor_name"
              placeholder="Enter Assessor Name"
              :disabled="!formState.employee_id"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="12">
          <a-form-item label="Assessor Email" name="assessor_email">
            <a-input
              v-model:value="formState.assessor_email"
              type="email"
              :disabled="!formState.employee_id"
              placeholder="Enter Assessor Email"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-spin>

    <side-drawer-buttons name="Preview Email" />
  </a-form>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },
  data() {
    return {
      loading: true,
      formState: {
        date_range: [],
        employee_id: null,
        hospital_id: null,
        assessor_name: null,
        assessor_email: null,
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "Please select date range",
          },
        ],
        employee_id: [
          {
            required: true,
            type: "number",
            message: "This field is required",
          },
        ],
        hospital_id: [
          {
            required: true,
            type: "number",
            message: "This field is required",
          },
        ],
        assessor_name: [
          {
            required: true,
            message: "This field is required",
          },
        ],
        assessor_email: [
          {
            required: true,
            message: "This field is required",
          },
          {
            type: "email",
            message: "Please enter valid email address",
          },
        ],
      },
      employees: [],
      employeeHospitals: [],
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    ...mapActions("modal", ["open"]),

    fetchEmployees() {
      return commonService
        .get(this.$constants.getEmployeesUrl, {
          select: ["id", "first_name", "last_name"],
        })
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          //
        });
    },

    fetchEmployeeHospitals(id) {
      return commonService
        .get(this.$constants.employeeHospitalShowUrl, {
          id,
          with_hospital: true,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        });
    },

    onSubmit() {
      this.loadingStart();

      const [start_date, end_date] = this.formState.date_range;

      const data = {
        ...this.formState,
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      };

      delete data.date_range;

      return commonService
        .store(this.$constants.employeeCompetencyAssessmentPreview, data)
        .then((res) => {
          this.$message.success(res.message);

          return this.open({
            title: "Competency Assessment Preview",
            path: "employee.drawerForm.previewEmail",
            record: {
              data,
              email: res.data.content,
            },
            callback: () => {
              this.$emit("callback");
              this.close();
            },
          });
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },

    async handleEmployeeChangeEvent() {
      this.formState.hospital_id = null;
      const employeeHospitals = await this.fetchEmployeeHospitals(
        this.formState.employee_id
      );
      this.employeeHospitals = employeeHospitals.map((v) => ({
        label: v.hospital.name,
        value: v.hospital_id,
      }));
      return true;
    },
  },

  async mounted() {
    if (!this.extra?.fromEmployees) {
      await this.fetchEmployees();
    } else {
      this.employees = this.extra.employees;
    }
    this.formState.employee_id = this.record?.employee_id;

    if (this.formState.employee_id) {
      await this.handleEmployeeChangeEvent();
    }

    this.formState.id = this.record?.id;
    this.formState.hospital_id = this.record?.hospital_id;
    this.formState.assessor_email = this.record?.assessor_email;
    this.formState.assessor_name = this.record?.assessor_name;
    if (this.record.start_date) {
      const { start_date, end_date } = this.record;
      this.formState.date_range = [
        moment(start_date, "YYYY-MM-DD"),
        moment(end_date, "YYYY-MM-DD"),
      ];
    }

    this.loading = false;
  },
};
</script>
