<template>
  <a-modal
    v-model:visible="showDialog"
    centered
    :closable="false"
    :maskClosable="false"
    width="1000px"
    wrapClassName="handbook-modal"
    :bodyStyle="{ height: '500px', overflowY: 'scroll' }"
    :keyboard="false"
  >
    <template #title>
      <a-typography-title :level="3" style="margin-bottom: -4px">
        {{ agreement.name }}
      </a-typography-title>
    </template>

    <template #footer>
      <a-button
        v-if="showSignPad && handbookSign"
        htmlType="button"
        type="primary"
        danger
        ghost
        @click="handbookSignClear"
      >
        Clear Signature
      </a-button>

      <a-button
        type="primary"
        @click="handleHandbookAgreedButtonClick"
        :loading="handbookAgreeBtnLoading"
        :disabled="isAgreeBtnDisabled"
      >
        Agree
      </a-button>
    </template>

    <a-space direction="vertical" style="width: 100%">
      <a-typography-text>
        <p class="policy-rule-description" v-html="agreement.description"></p>
      </a-typography-text>

      <template v-if="agreement.attachment_file">
        <ADivider style="margin: 0" />

        <a-form layout="vertical">
          <a-form-item label="Attachment">
            <a-typography-link
              :href="agreement.attachment_file_path"
              target="_blank"
            >
              Download {{ agreement.name }}
            </a-typography-link>
          </a-form-item>
        </a-form>
      </template>

      <a-space
        v-if="showSignPad"
        class="mt-sm-2"
        align="center"
        direction="vertical"
        style="width: 100%"
      >
        <vue-signature-pad
          :options="{ onEnd: handbookSignSave }"
          ref="signaturePad"
          :customStyle="$constants.signPadCustomCss"
        />
      </a-space>

      <a-space class="mt-sm-1" direction="vertical" style="width: 100%">
        <a-checkbox v-if="!showSignPad" v-model:checked="handbookAgreed">
          {{ agreement.acknowledgement_message }}
        </a-checkbox>

        <a-typography-paragraph v-else style="text-align: center">
          {{ agreement.acknowledgement_message }}
        </a-typography-paragraph>
      </a-space>
    </a-space>
  </a-modal>
</template>

<script>
import { commonService } from "@/services";
import { message } from "ant-design-vue";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      handbookAgreeBtnLoading: false,
      handbookSign: null,
      handbookAgreed: false,
    };
  },
  props: ["agreement", "handbookModal", "closeDialog"],
  emits: ["update:handbookModal"],
  computed: {
    showSignPad() {
      return this.agreement.type === 1;
    },

    isAgreeBtnDisabled() {
      if (this.showSignPad) {
        return this.handbookSign == null;
      }
      return !this.handbookAgreed;
    },

    showDialog: {
      get() {
        return this.handbookModal;
      },
      set(val) {
        this.$emit("update:handbookModal", val);
      },
    },
  },
  methods: {
    handbookSignSave() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.handbookSign = data;
      return Promise.resolve();
    },
    handbookSignClear() {
      this.handbookSign = null;
      this.$refs.signaturePad.clearSignature();
    },

    handleHandbookAgreedButtonClick() {
      if (!this.showSignPad && !this.handbookAgreed) {
        message.error("Please agree to the terms and conditions.");
        return;
      }

      this.handbookAgreeBtnLoading = true;
      commonService
        .store(this.$constants.policyRuleDocumentSign, {
          document_sign: this.handbookSign,
          policy_rule_document_id: this.agreement.id,
        })
        .then((res) => {
          this.$message.success(res.message);
          if (this.showSignPad) {
            this.handbookSignClear();
          } else {
            this.handbookAgreed = false;
          }
          this.closeDialog(res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.handbookAgreeBtnLoading = false;
        });
    },
  },
});
</script>

<style scoped>
.policy-rule-description:deep p {
  margin-bottom: 0;
}
</style>
