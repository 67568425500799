<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title>
          Clinical Site Information
        </template>
        <template #buttons>
          <!--  -->
        </template>
      </page-header>
      <custom-table
        :scroll="{ x: true, y: 0 }"
        :columns="columns"
        :url="$constants.clinicalSiteDatatableUrl"
        ref="datatable"
        :params="params"
        :tab_view="false"
        :show_hide_columns="false"
      >
        <template #name="record">
          <a :href="record.google_docs_link" target="_blank">
            {{ record.name }}
          </a>
        </template>
      </custom-table>
    </a-layout-content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        sortField: "name",
        sortOrder: "ascend",
      },
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
          slots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "State",
          dataIndex: "state",
          key: "state",
          sorter: true,
        },
      ],
    };
  },

  methods: {
    refreshDatatable() {
      this.$refs.datatable.refresh();
    },
  },
};
</script>

<style></style>
