<template>
  <div>
    <a-form
      name="job_form"
      ref="job_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item
            label="Title"
            name="title"
            :validateStatus="validate('title', 'status')"
            :help="validate('title', 'msg')"
          >
            <a-input
              v-model:value="formState.title"
              placeholder="Enter here..."
              @change="resetErrors('title')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            label="Payment Unit"
            name="payment_unit"
            :validateStatus="validate('payment_unit', 'status')"
            :help="validate('payment_unit', 'msg')"
          >
            <a-select
              v-model:value="formState.payment_unit"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="option in $constants.employeeJobPaymentUnit"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              >
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item label="Rate" name="rate">
            <a-input-number
              v-model:value="formState.rate"
              placeholder="Enter here..."
              :min="0"
              :step="0.01"
              :formatter="$comman.currencyformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>

        <a-col v-if="!formState.is_primary" :xs="24" :lg="12">
          <a-form-item
            label="ADP Code"
            name="adp_hours_and_earning_code"
            :validateStatus="validate('adp_hours_and_earning_code', 'status')"
            :help="validate('adp_hours_and_earning_code', 'msg')"
          >
            <a-select
              v-model:value="formState.adp_hours_and_earning_code"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="option in hoursAndEarningCodes"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col v-else :xs="24" :lg="12"></a-col>

        <a-col
          v-if="['Year+Overtime'].includes(formState.payment_unit)"
          :xs="24"
          :lg="12"
        >
          <a-form-item
            label="Overtime Pay Per Hour"
            name="overtime_pay_per_hour"
          >
            <a-input-number
              v-model:value="formState.overtime_pay_per_hour"
              placeholder="Enter here..."
              :min="0"
              :step="0.01"
              :formatter="$comman.currencyformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>

        <a-col
          v-if="['Year+Overtime'].includes(formState.payment_unit)"
          :xs="24"
          :lg="12"
        >
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item name="is_primary">
            <template #label> &nbsp; </template>
            <a-checkbox
              v-model:checked="formState.is_primary"
              :disabled="jobs.length == 0 || record.is_primary ? true : false"
            >
              Is primary job?
            </a-checkbox>
          </a-form-item>
        </a-col>

        <!-- v-if="['Year', 'Year+Overtime'].includes(formState.payment_unit)" -->
        <a-col v-if="formState.is_primary && formState.payment_unit !== 'Hour'" :xs="24" :lg="12">
          <a-form-item
            label="Default Hours Per Pay Period"
            name="hours_per_pay_period"
          >
            <a-input-number
              type="number"
              v-model:value="formState.hours_per_pay_period"
              placeholder="Enter here..."
              :min="0"
              :step="1"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { validation } from "../../../helper/validation";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        title: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        payment_unit: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        rate: [
          {
            required: true,
            message: "This field is required!",
          },
          {
            message: "price have max upto 2 decimal points!",
            validator: validation.decimal,
            type: "number",
          },
        ],
        hours_per_pay_period: [
          {
            required: true,
            type: "integer",
            message: "This field is required!",
          },
        ],
        overtime_pay_per_hour: [
          {
            required: true,
            message: "This field is required!",
          },
          {
            message: "price have max upto 2 decimal points!",
            validator: validation.decimal,
            type: "number",
          },
        ],
      },

      hoursAndEarningCodes: [],
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    employee() {
      return this.extra.employee;
    },

    jobs() {
      return this.extra.jobs;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  async mounted() {
    await this.getAdpHoursAndEarningCodes();
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        id: this.record.id ?? null,
        employee_id: this.employee.id,
        title: this.record.title ?? "",
        payment_unit: this.record.payment_unit ?? undefined,
        rate: this.record.rate ? Number(this.record.rate) : null,
        hours_per_pay_period: this.record.hours_per_pay_period
          ? Number(this.record.hours_per_pay_period)
          : null,
        overtime_pay_per_hour: this.record.overtime_pay_per_hour
          ? Number(this.record.overtime_pay_per_hour)
          : null,
        adp_hours_and_earning_code:
          this.record.adp_hours_and_earning_code ?? undefined,
        is_primary:
          this.record.is_primary == 1
            ? true
            : this.jobs.length == 0
            ? true
            : false,
      };
    },

    getAdpHoursAndEarningCodes() {
      return commonService
        .get(this.$constants.adpGetDataUrl, { name: "hours_and_earning_codes" })
        .then((res) => {
          if (res.success) {
            let codes = res.data.find(
              (e) => e.name == "hours_and_earning_codes"
            );
            this.hoursAndEarningCodes = codes.data.map((e) => ({
              value: e.codeValue,
              label: `${e.longName} | ${e.codeValue}`,
            }));
          }
        });
    },

    onSubmit() {
      this.loadingStart();
      companyService
        .store(this.$constants.employeeJobStoreUrl, this.formState)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
