<template>
  <a-table
    :dataSource="model.section6"
    :columns="columns"
    size="small"
    :pagination="false"
    :rowKey="(_, i) => i"
  >
    <template #goal="{ record, index }">
      <a-form-item
        :name="['section6', index, 'goal']"
        :rules="{
          required: true,
          message: 'This field is required!',
        }"
      >
        <a-textarea
          v-model:value="record.goal"
          placeholder="Enter goal..."
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </template>

    <template #weightage="{ record, index }">
      <a-form-item
        :name="['section6', index, 'weightage']"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number type="number"
          v-model:value="record.weightage"
          @change="$emit('update:model', model)"
        />
      </a-form-item>
    </template>

    <template #action="{ index }">
      <a-button
        v-if="model.section6.length != 1"
        class="pl-sm-0"
        size="small"
        type="link"
        danger
        @click="deleteGoal(index)"
      >
        <i class="ti ti-trash ti-lg"></i>
      </a-button>

      <a-button
        v-if="model.section6.length == index + 1"
        :class="model.section6.length == 1 ? 'pl-sm-0' : ''"
        size="small"
        type="link"
        @click="addGoal"
      >
        <i class="ti ti-plus ti-lg"></i>
      </a-button>
    </template>
  </a-table>
</template>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    employees: {
      require: true,
    },
    hospitals: {
      require: true,
    },
  },

  data() {
    return {
      columns: [
        {
          title: "Goal",
          dataIndex: "goal",
          key: "goal",
          slots: {
            customRender: "goal",
          },
        },
        {
          title: "Weightage (%)",
          dataIndex: "weightage",
          key: "weightage",
          width: 150,
          slots: {
            customRender: "weightage",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 100,
          slots: {
            customRender: "action",
          },
        },
      ],
    };
  },

  computed: {},

  methods: {
    validate(...arg) {
      this.$emit("validate", ...arg);
    },

    deleteGoal(index) {
      this.model.section6.splice(index, 1);
    },

    addGoal() {
      this.model.section6.push({
        goal: "",
        weight: "",
      });
    },
  },
};
</script>
