<template>
  <div>
    <a-form
      name="bulkUpload"
      ref="bulkUpload"
      :model="model"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col class="mb-sm-2" :span="24">
          <a-button
            type="primary"
            danger
            :loading="excelExportLoading"
            @click="handleHospitalProductExcelExportEvent"
          >
            Download Sample File
          </a-button>
        </a-col>

        <a-col :span="24">
          <a-form-item ref="excel_file" label="Excel File" name="excel_file">
            <a-upload-dragger
              :fileList="model.excel_file"
              :before-upload="beforeUploadImage"
              :remove="handleRemoveImage"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">
                Support for a single excel file. Strictly prohibit from
                uploading company data or other band files
              </p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { InboxOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  components: {
    InboxOutlined,
  },
  data() {
    return {
      excelExportLoading: false,
      model: {
        excel_file: [],
      },
      rules: {
        excel_file: [
          {
            required: true,
            message: "file is required!",
            trigger: "blur",
            type: "array",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    submitButtonText() {
      return "Upload";
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      const formData = new FormData();
      formData.append("file", this.model.excel_file[0]);
      formData.append("hospital_id", this.record.hospital_id);

      this.loadingStart();
      commonService
        .store(this.$constants.hospitalProductExcelImportUrl, formData)
        .then((res) => {
          this.loadingStop();
          this.close();
          this.$message.success(res.message);
          this.$emit("callback");
        })
        .catch((err) => {
          this.loadingStop();
          this.$message.error(err);
        });
    },

    handleRemoveImage() {
      this.model.excel_file = [];
    },

    beforeUploadImage(file) {
      if (
        [
          "csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ].includes(file.type)
      ) {
        this.model.excel_file = [file];
        return false;
      }
      this.$message.error("You can only upload Excel or csv Files!");
      this.model.excel_file = [];
    },

    handleHospitalProductExcelExportEvent() {
      this.excelExportLoading = true;
      commonService
        .downloadFile(
          this.$constants.hospitalProductExcelExportUrl,
          {
            hospital_id: this.record.hospital_id,
          },
          "Hospital products.xlsx"
        )
        .then((res) => {
          this.$message.success(res);
          this.excelExportLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.excelExportLoading = false;
        });
    },
  },
};
</script>
